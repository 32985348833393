import React from "react";
import { dummyData } from "./dummy-data";
import {
  CardHeader,
  CardsGridWrapper,
  Skeleton,
  SmallCardBodyWrapper,
  SmallCardFooter,
  SmallCardSkeletonWrapper,
  SmallCardWrapper,
} from "./styles";

const RecentlyActiveUsersPreview = () => {
  return (
    <CardsGridWrapper numberOfColumns={4}>
      {dummyData.map((data, idx) => (
        <SmallCardWrapper key={idx}>
          <CardHeader />
          <SmallCardBodyWrapper>
            <img src={data.avatarUrl} alt="Profile" />
            <SmallCardSkeletonWrapper>
              <Skeleton width="65px" height="6px" backgroundColor="#DFE1E6" marginTop="0" />
              <div style={{ display: "flex", gap: "4px" }}>
                <Skeleton width="32px" height="4px" backgroundColor="#F4F5F7" marginTop="0" />
                <Skeleton width="20px" height="4px" backgroundColor="#B3D4FF" marginTop="0" />
              </div>
            </SmallCardSkeletonWrapper>
          </SmallCardBodyWrapper>
          <SmallCardFooter>
            <Skeleton width="32px" height="6px" backgroundColor="#DFE1E6" marginTop="0" />
          </SmallCardFooter>
        </SmallCardWrapper>
      ))}
    </CardsGridWrapper>
  );
};

export default RecentlyActiveUsersPreview;
