import React, { useCallback } from "react";
import styled from "styled-components";

const Link = styled.a`
  all: unset;
  cursor: pointer;
  height: 100%;
`;

function OptionalLink({ link, openInNewTab, children }) {
  const buildHref = useCallback(() => {
    if (link?.source === "email") {
      return `mailto:${link.link}`;
    }
    return link.link;
  }, [link]);

  if (link && !!link?.link?.length) {
    return (
      <Link href={buildHref()} rel="noreferrer" target={openInNewTab ? "_blank" : "_parent"}>
        {children}
      </Link>
    );
  }

  return children;
}

export default OptionalLink;
