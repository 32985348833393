import React, { useEffect, useRef } from "react";
import { TabTypes } from "../../tabTypes";
import TextInputFilter from "../../../filter/TextInputFilter";
import { Divider, FieldContainer, Label } from "../../../filter/styled";
import ElementsToggle from "../../../filter/ElementsToggle";
import { useTheme } from "styled-components";
import SingleTileColorPicker from "../../../../../../../Shared/Components/SingleTileColorPicker";
import debounce from "lodash.debounce";

const PropertyKeys = {
  CHANNEL_ID: "channelId",
  ELEMENTS_TO_DISPLAY: "elementsToDisplay",
  TITLE: "title",
  SUBTITLE: "subtitle",
  COLOR: "color",
};

const ElementsOptions = [
  { label: "Header", value: "header" },
  { label: "Cover Image", value: "coverimage" },
];

const Layout = ({ panelId, data, updatePanelDataProperty }) => {
  const updateElements = (value) => {
    const newElements = !!data.elementsToDisplay.find((el) => el === value)
      ? data.elementsToDisplay.filter((el) => el !== value)
      : [...data.elementsToDisplay, value];
    updatePanelDataProperty(panelId, PropertyKeys.ELEMENTS_TO_DISPLAY, newElements);
  };

  const handleSaveDebounce = useRef(
    debounce((propertyKey, value) => updatePanelDataProperty(panelId, propertyKey, value), 500),
  ).current;

  useEffect(() => {
    return () => {
      handleSaveDebounce.cancel();
    };
  }, [handleSaveDebounce]);

  return (
    <>
      <TextInputFilter
        label="Title"
        value={data.title}
        onUpdate={(value) => handleSaveDebounce(PropertyKeys.TITLE, value)}
        isDisabled={!data.elementsToDisplay.includes("header")}
      />

      <TextInputFilter
        label="Subtitle"
        value={data.subtitle}
        onUpdate={(value) => handleSaveDebounce(PropertyKeys.SUBTITLE, value)}
        isDisabled={!data.elementsToDisplay.includes("header")}
      />

      <Divider />

      <ElementsToggle
        label="Elements to display"
        elements={data.elementsToDisplay}
        options={ElementsOptions}
        updateElements={updateElements}
      />
    </>
  );
};

const Content = ({ panelId, data, updatePanelDataProperty }) => {
  return (
    <>
      <TextInputFilter
        label="Channel ID"
        value={data.channelId}
        onUpdate={(value) => updatePanelDataProperty(panelId, PropertyKeys.CHANNEL_ID, value)}
      />
    </>
  );
};

const Design = ({ panelId, data, updatePanelDataProperty }) => {
  return (
    <FieldContainer>
      <Label>Color</Label>
      <SingleTileColorPicker
        label="UI Color"
        value={data.color}
        setValue={(value) => {
          updatePanelDataProperty(panelId, PropertyKeys.COLOR, value);
        }}
        disableAlpha
        disableTransparent
      />
    </FieldContainer>
  );
};

function ReleasedAIEditor({ panel, selectedTab, updatePanelDataProperty }) {
  const { id, data } = panel;

  return (
    <div>
      {selectedTab === TabTypes.LAYOUT && (
        <Layout panelId={id} data={data} updatePanelDataProperty={updatePanelDataProperty} />
      )}
      {selectedTab === TabTypes.CONTENT && (
        <Content panelId={id} data={data} updatePanelDataProperty={updatePanelDataProperty} />
      )}
      {selectedTab === TabTypes.DESIGN && (
        <Design panelId={id} data={data} updatePanelDataProperty={updatePanelDataProperty} />
      )}
    </div>
  );
}

export default ReleasedAIEditor;
