import React, { useState } from "react";
import TextField from "@atlaskit/textfield";
import { EditorBackground } from "../styled";

const Number = ({ initialValue, onEdit }) => {
  const [value, setValue] = useState(initialValue);

  return (
    <EditorBackground>
      <TextField
        style={{ height: "100%", zIndex: 2 }}
        appearance="none"
        type="number"
        autoFocus
        isCompact
        value={value}
        onBlur={(e) => onEdit(e.target.value)}
        onChange={(e) => setValue(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            onEdit(value);
          }
        }}
      />
    </EditorBackground>
  );
};

export default Number;
