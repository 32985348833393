export const PeoplePropertyKeys = {
  LOADING_TYPE: "loadingType",
  DYNAMIC: "dynamic",
  MANUAL: "manual",
  BACKGROUND_TYPE: "backgroundType",
  IMAGE_SIZE: "imageSize",
  COLLECTION: "collection",
  DISPLAY_TITLE: "displayTitle",
  DISPLAY_BOX: "displayBox",
  VIEW: "view",
  PAGINATION: "pagination",
  TITLE_FIELD_ON: "titleFieldOn",
  FOOTER_FIELD_ON: "footerFieldOn",
  TITLE_FIELD_ID: "titleFieldId",
  FOOTER_FIELD_ID: "footerFieldId",
  CARD: "card",
  LIST: "list",
  MAX_NUMBER_OF_ITEMS: "maxNumberOfItems",
  MANUALLY_PICKED: "manuallyPicked",
};
