import PeopleGroupIcon from "@atlaskit/icon/glyph/people-group";
import FolderFilledIcon from "@atlaskit/icon/glyph/folder-filled";
import UserAvatarCircleIcon from "@atlaskit/icon/glyph/user-avatar-circle";
import DownloadIcon from "@atlaskit/icon/glyph/download";
import MediaServicesBrushIcon from "@atlaskit/icon/glyph/media-services/brush";
import EditorHelpIcon from "@atlaskit/icon/glyph/editor/help";
import { Pages } from "../../../../pages";

const IconColors = {
  [Pages.PEOPLE]: "#36B37E",
  [Pages.NEWS]: "#0065FF",
  [Pages.SPACE]: "#FF5630",
  [Pages.PROFILE]: "#6554C0",
  [Pages.DOWNLOAD]: "#00B8D9",
  [Pages.STYLEGUIDE]: "#FFAB00",
  [Pages.HELP_CENTER]: "#FF5630",
};

export const TagColors = {
  [Pages.DOWNLOAD]: { primary: "#C1F0F5", secondary: "#206B74" },
  [Pages.STYLEGUIDE]: { primary: "#FFE2BD", secondary: "#7F5F01" },
  [Pages.HELP_CENTER]: { primary: "#FFD2CC", secondary: "#AE2A19" },
};

export const DefaultFeatures = [
  {
    id: Pages.PEOPLE,
    name: "People Network",
    activeDescription: "The feature is active and visible throughout the app for admins and users.",
    inactiveDescription: "The feature is inactive and not visible to anyone, including admins and users.",
    Icon: PeopleGroupIcon,
    color: IconColors[Pages.PEOPLE],
    isAvailable: true,
    isDisabled: false,
    link: "https://caelor.atlassian.net/wiki/spaces/COSMOS/pages/792396233/People+Network",
  },
  {
    id: Pages.NEWS,
    name: "Newsroom",
    activeDescription: "The feature is active and visible throughout the app for admins and users.",
    inactiveDescription: "The feature is inactive and not visible to anyone, including admins and users.",
    Icon: PeopleGroupIcon,
    color: IconColors[Pages.NEWS],
    isAvailable: true,
    isDisabled: false,
    link: "https://caelor.atlassian.net/wiki/spaces/COSMOS/pages/792396286/Newsroom",
  },
  {
    id: Pages.SPACE,
    name: "Space Station",
    activeDescription: "The feature is active and visible throughout the app for admins and users.",
    inactiveDescription: "The feature is inactive and not visible to anyone, including admins and users.",
    Icon: FolderFilledIcon,
    color: IconColors[Pages.SPACE],
    isAvailable: true,
    isDisabled: false,
    link: "https://caelor.atlassian.net/wiki/spaces/COSMOS/pages/792396334/Space+Station",
  },
  {
    id: Pages.PROFILE,
    name: "User Profile",
    activeDescription: "The feature is active and visible throughout the app for admins and users.",
    inactiveDescription: "The user profile is visible for admins and profile owners. It is hidden from other users.",
    Icon: UserAvatarCircleIcon,
    color: IconColors[Pages.PROFILE],
    isAvailable: true,
    isDisabled: false,
    link: "https://caelor.atlassian.net/wiki/spaces/COSMOS/pages/792396175/User+Profile",
  },
  {
    id: Pages.DOWNLOAD,
    name: "Download Center",
    activeDescription: "For greater usability and an expanded Cosmos app experience. Stay tuned for updates.",
    inactiveDescription: "",
    Icon: DownloadIcon,
    color: IconColors[Pages.DOWNLOAD],
    isAvailable: false,
    isDisabled: false,
  },
  {
    id: Pages.STYLEGUIDE,
    name: "Company Styleguide",
    activeDescription: "For greater usability and an expanded Cosmos app experience. Stay tuned for updates.",
    inactiveDescription: "",
    Icon: MediaServicesBrushIcon,
    color: IconColors[Pages.STYLEGUIDE],
    isAvailable: false,
    isDisabled: false,
  },
  {
    id: Pages.HELP_CENTER,
    name: "Help Center",
    activeDescription: "For greater usability and an expanded Cosmos app experience. Stay tuned for updates.",
    inactiveDescription: "",
    Icon: EditorHelpIcon,
    color: IconColors[Pages.HELP_CENTER],
    isAvailable: false,
    isDisabled: false,
  },
];
