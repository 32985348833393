import React, { useEffect, useState } from "react";
import Button from "@atlaskit/button";
import styled from "styled-components";

const Wrapper = styled.div`
  background-color: #fff;
  width: 300px;
  height: 120px;
  border-radius: 5px;
  margin-top: 25% !important;
  margin: 0 auto;
  position: relative;
  padding: 20px;
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #42526e;
`;

const Footer = styled.div`
  position: absolute;
  left: 20px;
  bottom: 20px;
  right: 20px;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 5px;
`;

function EmbedEditor() {
  const [isFirstLoad, setIsFirstLoad] = useState(false);

  useEffect(() => {
    window.AP.confluence.getMacroData((macroData) => {
      setIsFirstLoad(!macroData?.data);
    });
  }, []);

  const saveMacro = () => {
    window.AP.confluence.saveMacro({ data: "embed-dashboard" });
    window.AP.dialog.close();
  };

  return (
    <Wrapper>
      <Title>{isFirstLoad ? "Insert Cosmos Dashboard" : "Cosmos Dashboard"}</Title>

      <Footer>
        <Button onClick={() => window.AP.dialog.close()}>{isFirstLoad ? "Cancel" : "Close"}</Button>
        {isFirstLoad && (
          <Button appearance="primary" onClick={() => saveMacro()}>
            Insert
          </Button>
        )}
      </Footer>
    </Wrapper>
  );
}

export default EmbedEditor;
