import React, { useState } from "react";
import CustomModal from "../CustomModal";
import { updateCategorySettingById } from "../../../api";
import { useCategoriesStore } from "../../../categories-store";
import { FieldContainer, Label } from "../../../../../../../../NewsMetaDataDialog/Components/shared/styled";
import Textfield from "@atlaskit/textfield";

function EditCategory({ closeModal }) {
  const [loading, setLoading] = useState(false);

  const { selectedCategory, setSelectedCategory, allCategories, setAllCategories } = useCategoriesStore((state) => ({
    selectedCategory: state.selectedCategory,
    setSelectedCategory: state.setSelectedCategory,
    allCategories: state.allCategories,
    setAllCategories: state.setAllCategories,
  }));

  const [input, setInput] = useState(selectedCategory?.value);

  const updateCategory = async () => {
    setLoading(true);
    const newCategories = allCategories.map((cat) => (cat.id === selectedCategory.id ? { ...cat, value: input } : cat));
    setAllCategories([...newCategories]);
    setSelectedCategory({ ...selectedCategory, value: input });
    await updateCategorySettingById(selectedCategory.id, { value: input });

    closeModal();
    setLoading(false);
  };

  return (
    <CustomModal
      heading="Edit Category"
      isUpdateDone={loading}
      onCancel={closeModal}
      primaryButtonText="Save"
      onNext={() => updateCategory()}
      customHeight="auto"
      children={
        <div>
          <FieldContainer>
            <Label>Category Name</Label>
            <Textfield
              value={input}
              onChange={async (e) => {
                setInput(e.target.value);
              }}
              placeholder="Category name"
            />
          </FieldContainer>
        </div>
      }
    />
  );
}

export default EditCategory;
