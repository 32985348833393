export const DefaultTabSources = {
  Unsplash: "Unsplash",
  Link: "Link",
  Attachments: "Attachments",
};

export const ColorTabSources = {
  ...DefaultTabSources,
  Color: "Color",
};

export const AvailableTabBasedOnImageSource = {
  unsplash: DefaultTabSources.Unsplash,
  link: DefaultTabSources.Link,
  attachments: DefaultTabSources.Attachments,
  color: ColorTabSources.Color,
};
