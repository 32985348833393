import React, { useEffect, useMemo, useState } from "react";
import AvatarGroup from "@atlaskit/avatar-group";
import { createProfileLink } from "../../../../../../PeopleBase/Views/createProfileLink";
import { ContentWrapper, DateInfo, ListContentWrapper, Name, PageName } from "../styled";
import Actions from "./Actions";

function SideListContent({ locale, diagram, elementsToDisplay, content }) {
  const [picture, setPicture] = useState("");

  const { title: contentTitle, link } = content;
  const { title, editorUrl, lastModified, contributers, getPictureFromAttachments } = diagram;

  const formattedDate = useMemo(() => {
    const rawDate = new Date(lastModified);
    const date = rawDate.toLocaleDateString(locale || "en-US", {
      month: "short",
      day: "2-digit",
      year: "numeric",
    });

    const time = rawDate.toLocaleTimeString(locale || "en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hourCycle: "h24",
    });

    return [date, time];
  }, [lastModified, locale]);

  useEffect(() => {
    if (!picture && getPictureFromAttachments) {
      getPictureFromAttachments().then((rawImageUrl) => {
        if (rawImageUrl) {
          setPicture(`${window.AP._hostOrigin}/wiki${rawImageUrl}`);
        }
      });
    }
  }, [picture, getPictureFromAttachments]);

  return (
    <ListContentWrapper style={{ marginTop: "20px" }}>
      <ContentWrapper style={{ rowGap: "8px", padding: "0px" }}>
        <Name
          onClick={(e) => {
            e.preventDefault();
            window.open(editorUrl, "_blank");
          }}
          style={{ cursor: "pointer", fontSize: "16px", fontWeight: "400" }}
        >
          {title}
        </Name>
        {elementsToDisplay.includes("attachedto") && (
          <PageName
            onClick={(e) => {
              e.preventDefault();
              window.open(link, "_blank");
            }}
          >
            {contentTitle}
          </PageName>
        )}
        {elementsToDisplay.includes("contributors") && (
          <AvatarGroup
            appearance="stack"
            data={contributers}
            maxCount={3}
            onAvatarClick={(e) => {
              e.preventDefault();
              const url = e.target.src;
              const accountId = url.slice(url.lastIndexOf("/") + 1);
              window.open(createProfileLink(accountId), "_blank");
            }}
            isTooltipDisabled={false}
          />
        )}
        {elementsToDisplay.includes("date") && (
          <DateInfo>
            {formattedDate[0]} at {formattedDate[1]}
          </DateInfo>
        )}
      </ContentWrapper>
      <Actions visible editorUrl={editorUrl} pictureUrl={picture} />
    </ListContentWrapper>
  );
}

export default SideListContent;
