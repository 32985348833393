export async function checkContentVizLicense(params = { isInNavigation: false }) {
  if (process.env.REACT_APP_DISABLE_LICENCE === "true") {
    return true;
  }

  const url = "/rest/atlassian-connect/1/addons/com.caelor.confluence.cloud.cards-panels";
  try {
    const response = await window.AP.request(url);
    const data = JSON.parse(response.body);
    if (data && data.state === "ENABLED" && data.license?.active) {
      return true;
    } else {
      return false;
    }
  } catch (e) {
    // If accessing anonymously
    if (e?.xhr?.status === 401 && params?.isInNavigation) {
      return true;
    } else {
      return false;
    }
  }
}
