import React, { useEffect, useRef, useState } from "react";
import Spinner from "@atlaskit/spinner";
import Modal, { ModalTransition } from "@atlaskit/modal-dialog";
import { useQuery } from "react-query";
import { SpinnerContainer } from "../../../styled/pages";
import { getLatestSpaces, mapSpace, updateSpaceByKey } from "../apiSpaces";
import { SectionWrapper, SmallTitle } from "../styled/space";
import { useBreakpointObserver } from "../../../../../Shared/Hooks/useBreakpointObserver";
import { BREAKPOINTS } from "../breakpoints";
import UpdateSpace from "../Modal/UpdateSpace";
import { SpaceCardsList, SpaceCardsGrid } from "../Templates/SpaceCards";
import { TemplateType } from "../Templates/template-type";
import { ViewType } from "../Templates/view-type";
import { useSpaceDirectoryStore } from "../space-directory-store";

const viewCardTemplates = {
  [ViewType.GRID]: SpaceCardsGrid,
  [ViewType.LIST]: SpaceCardsList,
};

function Discover({ currentUserId, primaryColor, primaryContrastColor }) {
  const [itemLimit, setItemLimit] = useState(4);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [updatingSpace, setUpdatingSpace] = useState(undefined);

  const selectedView = useSpaceDirectoryStore((state) => state.selectedView);

  const updateSpaceInfoRef = useRef();

  const [breakpoint] = useBreakpointObserver(BREAKPOINTS);

  const closeDialog = () => setIsModalOpen(false);

  const { isError, data, error, isFetching, refetch } = useQuery(["latest-space", { limit: 8 }], getLatestSpaces, {
    retry: 0,
    cacheTime: 0,
    select: (response) => {
      const body = JSON.parse(response.body);
      const results = body.results.map(({ space }) => mapSpace(space, true));
      return { results };
    },
  });

  useEffect(() => {
    window.AP.events.on("space-content-updated", () => refetch());
  }, []);

  useEffect(() => {
    if (breakpoint === BREAKPOINTS.XSMALL) {
      setItemLimit(2);
    } else {
      setItemLimit(3);
    }
    // if (breakpoint === BREAKPOINTS.SMALL) setItemLimit(4);
    // if (breakpoint === BREAKPOINTS.MEDIUM) setItemLimit(6);
    // if (breakpoint === BREAKPOINTS.LARGE) setItemLimit(8);
  }, [breakpoint]);

  if (isError || error) return <div>Something went wrong. Please refresh and try again.</div>;

  const Template = viewCardTemplates[selectedView];

  return (
    <>
      <SectionWrapper>
        <SmallTitle>Discover new spaces</SmallTitle>

        {isFetching ? (
          <SpinnerContainer>
            <Spinner size="xlarge" />
          </SpinnerContainer>
        ) : (
          <Template
            data={data.results}
            itemLimit={itemLimit}
            template={TemplateType.SPACE_CARD}
            setIsModalOpen={setIsModalOpen}
            setUpdatingSpace={setUpdatingSpace}
            currentUserId={currentUserId}
          />
        )}
      </SectionWrapper>

      <ModalTransition>
        {isModalOpen && (
          <Modal height={500} isChromeless onClose={() => setIsModalOpen(false)}>
            <UpdateSpace
              title="Update space"
              text="Update"
              ref={updateSpaceInfoRef}
              spaceInfo={updatingSpace}
              save={() => {
                updateSpaceByKey({ ...updateSpaceInfoRef.current })
                  .then((r) => {
                    window.AP.events.emit("space-content-updated");
                    closeDialog();
                  })
                  .catch((e) => console.log(e));
              }}
              close={() => setIsModalOpen(false)}
              primaryColor={primaryColor}
              primaryContrastColor={primaryContrastColor}
            />
          </Modal>
        )}
      </ModalTransition>
    </>
  );
}

export default Discover;
