import { useMemo } from "react";
import styled, { useTheme } from "styled-components";
import SingleTileColorPicker from "../../../../Shared/Components/SingleTileColorPicker";
import { MenuStyleTypes } from "../../menu/types";
import { useNavigationColorsStore } from "../../store";
import { Text } from "@atlaskit/primitives";

const ColorsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

export const RootEntryColors = ({ isDisabled }) => {
  const { dark, light, setColor } = useNavigationColorsStore((state) => {
    return {
      dark: state.dark,
      light: state.light,
      setColor: state.setColor,
    };
  });

  const theme = useTheme();

  const colorSchemes = [
    { label: "Light theme", colors: light, variant: "light" },
    { label: "Dark theme", colors: dark, variant: "dark" },
  ];

  return (
    <ColorsContainer>
      {colorSchemes.map(({ label, colors, variant }) => (
        <ColorsContainer key={variant} style={{ gap: "10px" }}>
          <Text weight="medium" size="small">
            {label}
          </Text>
          <SingleTileColorPicker
            colorBoxBorderColor={theme.shared.form.colorpicker.colorBox}
            label="Background"
            value={colors.menuBackgroundColor}
            setValue={(value) => {
              setColor(variant, "menuBackgroundColor", value);
            }}
            isDisabled={isDisabled}
            disableAlpha
            disableTransparent
          />
          <SingleTileColorPicker
            colorBoxBorderColor={theme.shared.form.colorpicker.colorBox}
            label="Text and Icon"
            value={colors.menuTextColor}
            setValue={(value) => {
              setColor(variant, "menuTextColor", value);
            }}
            isDisabled={isDisabled}
            disableAlpha
            disableTransparent
          />
          <SingleTileColorPicker
            colorBoxBorderColor={theme.shared.form.colorpicker.colorBox}
            label="Hover"
            value={colors.menuTextHover}
            setValue={(value) => {
              setColor(variant, "menuTextHover", value);
            }}
            isDisabled={isDisabled}
            disableAlpha
          />
        </ColorsContainer>
      ))}
    </ColorsContainer>
  );
};

export const SubEntryColors = ({
  menuStyle,
  menuStyleType,
  isIconDisplayed = true,
  isHoverDisplayed = true,
  isDisabled,
  setColor,
  colors: { dark = {}, light = {} },
}) => {
  const isEnabledDescriptionColor = [MenuStyleTypes.LONDON, MenuStyleTypes.SYDNEY].includes(menuStyle);

  const shouldDisplayIcon = useMemo(() => {
    if (menuStyle === MenuStyleTypes.DENVER) {
      return menuStyleType === "icon" && isIconDisplayed;
    }
    return isIconDisplayed && menuStyle !== MenuStyleTypes.PALO_ALTO;
  }, [isIconDisplayed, menuStyle, menuStyleType]);

  const theme = useTheme();

  const colorSchemes = [
    { label: "Light theme", colors: light, variant: "light" },
    { label: "Dark theme", colors: dark, variant: "dark" },
  ];

  return (
    <ColorsContainer>
      {colorSchemes.map(({ label, colors, variant }) => (
        <ColorsContainer key={variant} style={{ gap: "10px" }}>
          <Text weight="medium" size="small">
            {label}
          </Text>
          {shouldDisplayIcon && (
            <SingleTileColorPicker
              colorBoxBorderColor={theme.shared.form.colorpicker.colorBox}
              label="Icon"
              value={colors.subentryIconColor}
              setValue={(value) => {
                setColor(value, "subentryIconColor", variant);
              }}
              isDisabled={isDisabled}
              disableAlpha
              disableTransparent
            />
          )}

          <SingleTileColorPicker
            colorBoxBorderColor={theme.shared.form.colorpicker.colorBox}
            label={menuStyle === MenuStyleTypes.DENVER ? "Title" : "Text"}
            value={
              colors.subentryTextColor === "#42526e"
                ? theme.navigation.london.menuContentTitle
                : colors.subentryTextColor
            }
            setValue={(value) => {
              setColor(value, "subentryTextColor", variant);
            }}
            isDisabled={isDisabled}
            disableAlpha
            disableTransparent
          />

          {isEnabledDescriptionColor && (
            <SingleTileColorPicker
              colorBoxBorderColor={theme.shared.form.colorpicker.colorBox}
              label="Description"
              value={
                !colors.descriptionTextColor || colors.descriptionTextColor === "#42526e"
                  ? theme.navigation.london.menuContentTitle
                  : colors.descriptionTextColor
              }
              setValue={(value) => {
                setColor(value, "descriptionTextColor", variant);
              }}
              isDisabled={isDisabled}
              disableAlpha
              disableTransparent
            />
          )}

          {menuStyle === MenuStyleTypes.DENVER && (
            <>
              <SingleTileColorPicker
                colorBoxBorderColor={theme.shared.form.colorpicker.colorBox}
                label="Links"
                value={
                  colors.subentryLinkColor === "#42526e"
                    ? theme.navigation.london.menuContentTitle
                    : colors.subentryLinkColor
                }
                setValue={(value) => {
                  setColor(value, "subentryLinkColor", variant);
                }}
                isDisabled={isDisabled}
                disableAlpha
                disableTransparent
              />

              <SingleTileColorPicker
                colorBoxBorderColor={theme.shared.form.colorpicker.colorBox}
                label="Links hover"
                value={
                  colors.subentryLinkHoverColor === "0000000"
                    ? theme.navigation.denver.childEntryBackgroundHover
                    : colors.subentryLinkHoverColor
                }
                setValue={(value) => {
                  setColor(value, "subentryLinkHoverColor", variant);
                }}
                isDisabled={isDisabled}
                disableAlpha
                disableTransparent
              />
            </>
          )}

          {isHoverDisplayed && menuStyle !== MenuStyleTypes.DENVER && (
            <SingleTileColorPicker
              colorBoxBorderColor={theme.shared.form.colorpicker.colorBox}
              label="Hover"
              value={colors.subentryBackgroundHoverColor}
              setValue={(value) => {
                setColor(value, "subentryBackgroundHoverColor", variant);
              }}
              isDisabled={isDisabled}
              presetColors={[
                "#0052CC33",
                "#172b4d33",
                "#00a2e033",
                "#00badd33",
                "#00b77933",
                "#ffc30033",
                "#FF8B0033",
                "#ff431033",
                "#6a4ec733",
                "#DFE1E633",
                "#ffffff33",
              ]}
            />
          )}
        </ColorsContainer>
      ))}
    </ColorsContainer>
  );
};
