import React, { useMemo } from "react";
import { AvailableContent } from "./consts/content";
import { useNewsMetadataStore } from "./news-metadata-store";

function Content() {
  const { selectedTab } = useNewsMetadataStore((state) => ({
    selectedTab: state.selectedTab,
  }));

  const SelectedContent = useMemo(() => AvailableContent[selectedTab?.id], [selectedTab]);

  return <SelectedContent />;
}

export default Content;
