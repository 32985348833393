import React from "react";
import styled from "styled-components";
import Toggle from "@atlaskit/toggle";
import { Label } from "../../../../ContentBuilder/Shared/Filter/styled";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
  background: ${({ theme }) => theme.shared.settings.database.background};
`;

const SelectSettings = ({ isCreatable, onUpdate }) => (
  <>
    <Container>
      <Label style={{ margin: 0 }}>User can create options</Label>
      <Toggle onChange={() => onUpdate(!isCreatable)} isChecked={isCreatable} />
    </Container>
  </>
);

export default SelectSettings;
