import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  addCurrentUserAsContentWatcher,
  getCurrentUserPageWatchStatus,
  removeCurrentUserAsContentWatcher,
} from "../../../api";
import EditIcon from "@atlaskit/icon/glyph/edit";
import WatchIcon from "@atlaskit/icon/glyph/watch";
import WatchFilledIcon from "@atlaskit/icon/glyph/watch-filled";
import CopyIcon from "@atlaskit/icon/glyph/copy";

const IconContainer = styled.div`
  display: grid;
  place-content: center;
  cursor: pointer;
`;

const ActionsWrapper = styled.div`
  display: grid;
  position: absolute;
  right: 10px;
  top: 20px;
  grid-template-columns: repeat(3, 24px);
  align-items: center;
  column-gap: 5px;
`;

export const Actions = ({ page }) => {
  const [isWatching, setIsWatching] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    getCurrentUserPageWatchStatus(page.contentId)
      .then((res) => {
        if (res.body) {
          const body = JSON.parse(res.body);
          setIsWatching(body.watching);
        }
      })
      .catch((err) => setIsError(true));
  }, []);

  const addWatcher = () => {
    setIsWatching(true);
    addCurrentUserAsContentWatcher(page.contentId)
      .then((res) => {
        if (!res?.data?.success) {
          setIsWatching(false);
        }
      })
      .catch((e) => setIsError(true));
  };

  const removeWatcher = () => {
    setIsWatching(false);
    removeCurrentUserAsContentWatcher(page.contentId)
      .then((res) => {
        if (!res?.data?.success) {
          setIsWatching(true);
        }
      })
      .catch((e) => setIsError(true));
  };

  const copyLinkToClipboard = (url) => {
    const dummy = document.createElement("input");
    document.body.appendChild(dummy);

    dummy.value = url;
    dummy.select();

    document.execCommand("copy");
    document.body.removeChild(dummy);

    const flag = window.AP.flag.create({
      title: "Page link copied to clipboard",
      type: "info",
    });

    setTimeout(() => {
      flag.close();
    }, 5000);
  };
  
  return (
    <ActionsWrapper>
      {!isError && (
        <>
          <IconContainer
            onClick={(e) => {
              e.preventDefault();
              window.open(page.editUrl, "_blank");
            }}
          >
            <EditIcon primaryColor="#42526E" />
          </IconContainer>
          <IconContainer
            onClick={async (e) => {
              e.preventDefault();
              copyLinkToClipboard(page.url);
            }}
          >
            <CopyIcon primaryColor="#42526E" />
          </IconContainer>
          <IconContainer
            onClick={(e) => {
              e.preventDefault();
              isWatching ? removeWatcher() : addWatcher();
            }}
          >
            {isWatching ? <WatchFilledIcon primaryColor="#42526E" /> : <WatchIcon primaryColor="#42526E" />}
          </IconContainer>
        </>
      )}
    </ActionsWrapper>
  );
};
