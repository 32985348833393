import React from "react";
import { TabTypes } from "../../tabTypes";
import TextInputFilter from "../../../filter/TextInputFilter";
import { Divider, FieldContainer, HelperMessage, Label } from "../../../filter/styled";
import SelectWithCustomOptions from "../../../filter/SelectWithCustomOptions";
import { ViewOptions, ViewTypes } from "../../../../Shared/Panels/edit/people/configurator/options";
import { PanelsPaginationTypes } from "../../../../Shared/PanelsPagination/panels-pagination-types";
import { PaginationOptions } from "../../../../Shared/PanelsPagination/pagination-options";
import ElementsToggle from "../../../filter/ElementsToggle";
import SpaceMultiSelect from "../../../filter/SpaceMultiSelect";
import SimpleToggle from "../../../filter/SimpleToggle";
import DefaultSortPicker from "../../../filter/DefaultSortPicker";
import OrderButton from "../../../filter/OrderButton";
import DisableDynamicFilterToggle, { LoadingTypes } from "../../../filter/DynamicFilterToggle";
import { useTheme } from "styled-components";
import SectionMessage from "@atlaskit/section-message";

const GliffyPropertyKeys = {
  SPACE_IDS: "spaceIds",
  VIEW: "view",
  PAGINATION: "pagination",
  ELEMENTS_TO_DISPLAY: "elementsToDisplay",
  ORDER_BY: "orderBy",
  SORT_ORDER: "sortOrder",
  DISPLAY_TITLE: "displayTitle",
  DISPLAY_BOX: "displayBox",
  LOADING_TYPE: "loadingType",
  IMAGE_SRC: "manualImageSrc",
};

const CardElementsOptions = [{ label: "Attached to", value: "attachedto" }];

const ListElementsOptions = [
  { label: "Attached to", value: "attachedto" },
  { label: "Contributors", value: "contributors" },
  { label: "Date", value: "date" },
];

const OPTIONS_BY_TYPE = {
  [ViewTypes.CARD]: CardElementsOptions,
  [ViewTypes.LIST]: ListElementsOptions,
};

const Layout = ({ panelId, panelTitle, data, updatePanelDataProperty, updatePanelTitle }) => {
  const updateElements = (value) => {
    const newElements = !!data.elementsToDisplay.find((el) => el === value)
      ? data.elementsToDisplay.filter((el) => el !== value)
      : [...data.elementsToDisplay, value];
    updatePanelDataProperty(panelId, GliffyPropertyKeys.ELEMENTS_TO_DISPLAY, newElements);
  };

  return (
    <>
      <TextInputFilter label="Title" value={panelTitle} onUpdate={(value) => updatePanelTitle(panelId, value)} />

      <Divider />

      <SelectWithCustomOptions
        initialValue={data.view}
        options={ViewOptions}
        label="View"
        onUpdate={(newView) => updatePanelDataProperty(panelId, GliffyPropertyKeys.VIEW, newView)}
      />

      <SelectWithCustomOptions
        initialValue={
          data.pagination === PanelsPaginationTypes.INFINITE ? PanelsPaginationTypes.SHOW_MORE : data.pagination
        }
        options={PaginationOptions}
        label="Pagination"
        onUpdate={(newValue) => updatePanelDataProperty(panelId, GliffyPropertyKeys.PAGINATION, newValue)}
      />

      <Divider />

      <ElementsToggle
        label="Elements to display"
        elements={data.elementsToDisplay}
        options={OPTIONS_BY_TYPE[data.view]}
        updateElements={updateElements}
      />
    </>
  );
};

const Content = ({ panelId, data, updatePanelDataProperty }) => {
  const { spaceIds, sortOrder, orderBy } = data;

  const theme = useTheme();

  return (
    <>
      <DisableDynamicFilterToggle
        initialValue={data.loadingType}
        onUpdate={(newLoadingType) => updatePanelDataProperty(panelId, GliffyPropertyKeys.LOADING_TYPE, newLoadingType)}
      />

      {data.loadingType === LoadingTypes.DYNAMIC && (
        <>
          <SpaceMultiSelect
            initialSpaces={spaceIds}
            isMulti
            label="Select space"
            placeholder="Search spaces"
            isDynamic
            onUpdate={(selectedSpaceKeys) => {
              updatePanelDataProperty(panelId, GliffyPropertyKeys.SPACE_IDS, selectedSpaceKeys);
            }}
          />

          <>
            <DefaultSortPicker
              initialValue={sortOrder}
              onUpdate={(sorting) => updatePanelDataProperty(panelId, GliffyPropertyKeys.SORT_ORDER, sorting)}
            />
            <HelperMessage>
              <div style={{ marginRight: "4px", color: "#6b778c" }}> Order by </div>
              <OrderButton
                sortOrder={orderBy}
                onUpdate={(newSortOrder) => updatePanelDataProperty(panelId, GliffyPropertyKeys.ORDER_BY, newSortOrder)}
              />
            </HelperMessage>
          </>
        </>
      )}

      {data.loadingType === LoadingTypes.MANUAL && (
        <>
          <TextInputFilter
            label="Embed Gliffy diagram"
            placeholder="Insert custom embed link"
            value={data.manualImageSrc}
            onUpdate={(value) => updatePanelDataProperty(panelId, GliffyPropertyKeys.IMAGE_SRC, value)}
          />
          <Label>Gliffy custom embed link will display a single diagram</Label>

          <div style={{ marginTop: "20px" }}>
            <SectionMessage title="Embed Single Gliffy Diagram">
              <p style={{ margin: 0 }}>
                To embed an individual Gliffy diagram, navigate to the page containing the Gliffy creation, select the
                diagram, choose the "Embed" option, and then select "Custom." Copy the provided embed code and paste it
                into the desired location.
              </p>
            </SectionMessage>
          </div>
        </>
      )}
    </>
  );
};

const Design = ({ panelId, data, updatePanelDataProperty }) => {
  return (
    <FieldContainer>
      <SimpleToggle
        label="Display title"
        value={data.displayTitle}
        setValue={(value) => updatePanelDataProperty(panelId, GliffyPropertyKeys.DISPLAY_TITLE, value)}
        customStyles={{ padding: "10px 0px 8px 10px" }}
      />
      <SimpleToggle
        label="Display box"
        value={data.displayBox}
        setValue={(value) => updatePanelDataProperty(panelId, GliffyPropertyKeys.DISPLAY_BOX, value)}
        customStyles={{ padding: "10px 0px 8px 10px" }}
      />
    </FieldContainer>
  );
};

function GliffyEditor({ panel, selectedTab, updatePanelDataProperty, updatePanelTitle }) {
  const { id, panelTitle, data } = panel;

  return (
    <div>
      {selectedTab === TabTypes.LAYOUT && (
        <Layout
          panelId={id}
          panelTitle={panelTitle}
          data={data}
          updatePanelDataProperty={updatePanelDataProperty}
          updatePanelTitle={updatePanelTitle}
        />
      )}
      {selectedTab === TabTypes.CONTENT && (
        <Content panelId={id} data={data} updatePanelDataProperty={updatePanelDataProperty} />
      )}
      {selectedTab === TabTypes.DESIGN && (
        <Design panelId={id} data={data} updatePanelDataProperty={updatePanelDataProperty} />
      )}
    </div>
  );
}

export default GliffyEditor;
