import dayjs from "dayjs";
import React, { Fragment, useEffect, useState } from "react";
import styled from "styled-components";
import { useCorporateIdentityStore } from "../../../Settings/General/BrandAndColors/corporate-identity-store";
import { textColorBasedOnBackgroundColor } from "../../../../../../Shared/Functions/getColorBasedOnContrast";

const CountdownWrapper = styled.div`
  padding: 24px;
  background: ${({ bgColor }) => bgColor};
  color: #fff;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
  border-radius: 3px;

  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  column-gap: 50px;
`;

const TimesWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(7, min-content);
  column-gap: 11px;
  justify-self: center;
  color: ${({ textColor }) => textColor};
`;

const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;

  color: ${({ textColor }) => textColor};
`;

const Title = styled.span`
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: inherit;
  max-width: 415px;
  word-wrap: break-word;
`;

const Description = styled.span`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: inherit;
  max-width: 415px;
  word-wrap: break-word;
`;

const TimeBox = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 3px;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: inherit;
`;

const Digits = styled.div`
  font-weight: 700;
  font-size: 36px;
  line-height: 43px;
  color: inherit;

  ${({ noNumber, borderRadius }) =>
    noNumber
      ? ""
      : `
        padding: 11px 12px;
        background: rgba(255, 255, 255, 0.1);
        border-radius: ${borderRadius};
        `};
`;

const DigitsPlaceholder = styled.div`
  min-width: 40px;
  font-weight: 700;
  font-size: 36px;
  line-height: 43px;
  color: inherit;
  padding: 11px 12px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: ${({ borderRadius }) => borderRadius};

  &::after {
    content: "--";
  }
`;

const Stamp = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: inherit;
`;

const StampPlaceholder = styled.div`
  height: 16px;
  width: auto;
`;

const Link = styled.a`
  all: unset;
  cursor: pointer;
`;

const OptionalLink = ({ link, openInNewTab, children }) => {
  if (!link?.link) return children;

  return (
    <Link href={link.link} target={openInNewTab ? "_blank" : "_parent"}>
      {children}
    </Link>
  );
};

function Countdown({ data, setShouldRemoveRowGap }) {
  const [isExpired, setIsExpired] = useState(false);
  const [timeLeft, setTimeLeft] = useState();
  const [textColor, setTextColor] = useState("#ffffff");

  const [gradientTextColor, setGradientTextColor] = useState({ firstColor: "#ffffff", secondColor: "#ffffff" });

  const borderRadius = useCorporateIdentityStore((state) => state.borderRadius);

  const [remainingStamps, setRemainingStamps] = useState({
    days: "",
    hours: "",
    minutes: "",
    seconds: "",
  });

  const getRemainingTime = (time) => {
    const chosenTime = new Date(time).getTime();
    const currentTime = new Date().getTime();

    if (!time || currentTime >= chosenTime) {
      return 0;
    }

    return chosenTime - currentTime;
  };

  const addZeroIfOneDecimal = (num) => (num.toString().length === 1 ? `0${num}` : `${num}`);

  const convertTimeToStamps = (remainingTime) => {
    const s = 1000;
    const m = 60000;
    const h = 3600000;
    const d = 86400000;

    const days = addZeroIfOneDecimal(Math.floor(remainingTime / d));
    const hours = addZeroIfOneDecimal(Math.floor((remainingTime - days * d) / h));
    const minutes = addZeroIfOneDecimal(Math.floor((remainingTime - days * d - hours * h) / m));
    const seconds = addZeroIfOneDecimal(Math.floor((remainingTime - days * d - hours * h - minutes * m) / s));

    setRemainingStamps({ ...remainingStamps, days, hours, minutes, seconds });
  };

  useEffect(() => {
    convertTimeToStamps(timeLeft);
    if (timeLeft === undefined) return;

    if (timeLeft <= 1000) {
      return;
    }

    const interval = setTimeout(() => {
      setTimeLeft(timeLeft - 1000);
    }, 1000);

    return () => clearInterval(interval);
  }, [timeLeft]);

  useEffect(() => {
    const formattedTime = dayjs.unix(data.dateandtime).format("YYYY-MM-DDTHH:mm:ssZ");
    const remainingTime = getRemainingTime(formattedTime);
    setTimeLeft(remainingTime);
  }, [data?.dateandtime]);

  useEffect(() => {
    if (typeof data.color === "string") {
      setTextColor(textColorBasedOnBackgroundColor(data.color || "#0065ff", "#ffffff", "#172B4D"));
    } else if (typeof data.color === "object") {
      const firstColor = textColorBasedOnBackgroundColor(data.color?.firstColor || "#000000");
      const secondColor = textColorBasedOnBackgroundColor(data.color?.secondColor || "#000000");

      setGradientTextColor({ firstColor, secondColor });
    } else {
      setTextColor("#ffffff");
      setGradientTextColor({ firstColor: "#fff", secondColor: "#fff" });
    }
  }, [data.color]);

  useEffect(() => {
    if (timeLeft === 0 || timeLeft < 1000) {
      setIsExpired(true);
      setShouldRemoveRowGap(true);
    } else {
      setIsExpired(false);
      setShouldRemoveRowGap(false);
    }
  }, [timeLeft, isExpired, setShouldRemoveRowGap]);

  const getBackground = () => {
    if (typeof data.color === "string") {
      return data.color || "#0065ff";
    }
    if (typeof data.color === "object" && data?.color?.firstColor) {
      return `linear-gradient(${data.color.rotation || "90deg"}, ${data.color.firstColor} ${
        data.color.firstColorPosition
      }%, ${data.color.secondColor} ${data.color.secondColorPosition}%)`;
    }
  };

  const hasGradientBackground = typeof data?.color === "object";

  if (data?.removeOnFinishedCount && isExpired) {
    return <></>;
  }

  if (!data?.dateandtime && isExpired) {
    return <></>;
  }

  return (
    <OptionalLink link={data?.link} openInNewTab={data?.openInNewTab}>
      <CountdownWrapper bgColor={getBackground()}>
        <DetailsWrapper textColor={hasGradientBackground ? gradientTextColor.firstColor : textColor}>
          <Title>{data.title}</Title>
          <Description>{data.description}</Description>
        </DetailsWrapper>

        <TimesWrapper textColor={hasGradientBackground ? gradientTextColor.secondColor : textColor}>
          {["days", "hours", "minutes", "seconds"].map((stamp, index) => (
            <Fragment key={stamp}>
              {index !== 0 && (
                <TimeBox>
                  <Digits noNumber borderRadius={borderRadius}>
                    :
                  </Digits>
                  <StampPlaceholder />
                </TimeBox>
              )}
              <TimeBox>
                {remainingStamps[stamp] === "NaN" ? (
                  <DigitsPlaceholder borderRadius={borderRadius} />
                ) : (
                  <Digits borderRadius={borderRadius}>{remainingStamps[stamp]}</Digits>
                )}
                <Stamp>{stamp}</Stamp>
              </TimeBox>
            </Fragment>
          ))}
        </TimesWrapper>
      </CountdownWrapper>
    </OptionalLink>
  );
}

export default Countdown;
