import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { BroadcastChannel } from "broadcast-channel";
import { toast } from "react-toastify";
import styled from "styled-components";
import httpClient from "../../../../../../Service/http-client";
import { useCurrentUserStore } from "../../../../current-user-store";
import { fetchUserConnectedToJira, fetchUserIssuesTotal, getCurrentUsersTasks } from "../../api";
import { usePageStore } from "../../../../page-store";
import { Pages } from "../../../../pages";
import { useCorporateIdentityStore } from "../../../Settings/General/BrandAndColors/corporate-identity-store";

const UserStartWrapper = styled.div`
  background: ${({ background }) =>
    background || "linear-gradient(270deg, #3a52c5 0%, #704ebf 24.35%, #b34cb5 50.13%, #d16b9e 77.69%, #efa37b 100%)"};
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 20px;
  padding: 28px 35px;
`;

const InfoContainer = styled.div`
  display: flex;
  align-items: flex-start;
  column-gap: 16px;
`;

const UserImage = styled.img`
  box-sizing: border-box;
  border: 2px solid #fff;
  width: 64px;
  height: 64px;
  border-radius: 50%;
`;

const TextWrapper = styled.div`
  display: grid;
  row-gap: 4px;
  color: #ffffff;
`;

const Title = styled.span`
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
`;

const Description = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  max-width: 390px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 16px;
`;

const InfoButton = styled.div`
  background-color: rgba(0 0 0 / 20%);
  border-radius: ${({ borderRadius }) => borderRadius};
  padding: 12px;
  height: 56px;
  width: 160px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  column-gap: 8px;
  cursor: pointer;
`;

const IconContainer = styled.div`
  display: grid;
  place-content: center;
  height: 24px;
  width: 24px;
  margin-top: 2px;
`;

const InfoButtonText = styled.div`
  display: flex;
  flex-direction: column;

  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #eeeeee;

  span {
    white-space: nowrap;
  }

  .details {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #fec400;
  }
`;

const AllowAccessButton = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #eeeeee;
  background-color: rgba(0 0 0 / 20%);
  border-radius: ${({ borderRadius }) => borderRadius};
  padding: 12px;
  width: 160px;
  height: 56px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  white-space: nowrap;
  cursor: pointer;
  column-gap: 8px;

  &:hover {
    background-color: rgba(0 0 0 / 30%);
  }
`;

const ConfluenceIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <rect width="24" height="24" fill="white" fillOpacity="0.01" />
    <path
      d="M4.15374 15.637C3.99242 15.9321 3.77734 16.254 3.61603 16.5223C3.4816 16.7637 3.56226 17.0856 3.80423 17.2466L7.29933 19.3928C7.56818 19.5538 7.89081 19.4733 8.05212 19.205C8.18655 18.9636 8.37475 18.6685 8.56294 18.3465C9.96098 16.0662 11.3321 16.3345 13.8594 17.5417L17.3276 19.1782C17.5964 19.3123 17.9191 19.1782 18.0535 18.9099L19.7204 15.1541C19.8279 14.8858 19.7204 14.5639 19.4515 14.4566C18.7256 14.1078 17.2738 13.4371 15.9564 12.7933C11.2246 10.5398 7.21867 10.6739 4.15374 15.637Z"
      fill="url(#paint0_linear_7012_40519)"
    />
    <path
      d="M19.828 6.8643C19.9894 6.5692 20.2044 6.24727 20.3658 5.979C20.5271 5.73755 20.4464 5.41562 20.1776 5.25466L16.6825 3.10847C16.4136 2.9475 16.091 3.02799 15.9297 3.29626C15.7952 3.53771 15.607 3.83281 15.4188 4.15474C14.0208 6.43506 12.6496 6.16679 10.1224 4.95956L6.68108 3.29626C6.41223 3.16212 6.0896 3.29626 5.95518 3.56453L4.28828 7.32037C4.18074 7.58864 4.28828 7.91057 4.55713 8.01788C5.28304 8.36663 6.73485 9.03732 8.05224 9.68117C12.7572 11.9615 16.7631 11.8274 19.828 6.8643Z"
      fill="url(#paint1_linear_7012_40519)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_7012_40519"
        x1="19.6082"
        y1="20.5069"
        x2="9.04517"
        y2="14.424"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0.48" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_7012_40519"
        x1="4.38757"
        y1="1.9921"
        x2="14.9508"
        y2="8.07497"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0.5" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
    </defs>
  </svg>
);

const JiraIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <rect width="24" height="24" fill="white" fillOpacity="0.01" />
    <path
      d="M20.9592 1.5H11.6782C11.6782 3.80357 13.5559 5.67857 15.8627 5.67857H17.5794V7.3125C17.5794 9.61607 19.457 11.4911 21.7639 11.4911V2.30357C21.7639 1.84821 21.4152 1.5 20.9592 1.5Z"
      fill="white"
    />
    <path
      d="M16.238 6.24121H6.95703C6.95703 8.54478 8.83468 10.4198 11.1415 10.4198H12.8582V12.0537C12.8582 14.3573 14.7359 16.2323 17.0427 16.2323V7.04478C17.0427 6.61621 16.694 6.24121 16.238 6.24121Z"
      fill="url(#paint0_linear_7215_40921)"
    />
    <path
      d="M11.5173 11.0088H2.23633C2.23633 13.3124 4.11398 15.1874 6.4208 15.1874H8.1375V16.8213C8.1375 19.1249 10.0152 20.9999 12.322 20.9999V11.8124C12.322 11.357 11.9464 11.0088 11.5173 11.0088Z"
      fill="url(#paint1_linear_7215_40921)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_7215_40921"
        x1="16.8454"
        y1="6.26443"
        x2="12.9052"
        y2="10.3335"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.176" stopColor="white" stopOpacity="0.4" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_7215_40921"
        x1="12.3825"
        y1="11.0398"
        x2="7.82639"
        y2="15.479"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.176" stopColor="white" stopOpacity="0.4" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
    </defs>
  </svg>
);

function UserStart({ data }) {
  const [timeOfDay, setTimeOfDay] = useState("");
  const [numberOfTasks, setNumberOfTasks] = useState(0);
  const [numberOfIssues, setNumberOfIssues] = useState(0);
  const [isUserConnectedToJira, setIsUserConnectedToJira] = useState(false);

  const { currentUser, isAnonymous } = useCurrentUserStore((state) => ({
    currentUser: state.currentUser,
    isAnonymous: state.isAnonymous,
  }));

  const setSelectedPage = usePageStore((state) => state.setSelectedPage);

  const borderRadius = useCorporateIdentityStore((state) => state.borderRadius);

  const DEFAULT_BACKGROUND =
    "linear-gradient(270deg, #3a52c5 0%, #704ebf 24.35%, #b34cb5 50.13%, #d16b9e 77.69%, #efa37b 100%)";

  const { isLoading, data: userConnectedToJiraResponse } = useQuery(
    "user-connected-to-jira",
    fetchUserConnectedToJira,
    {
      enabled: !isAnonymous,
      retry: 0,
      select: (response) => {
        const { data } = response;
        return data;
      },
    },
  );

  useEffect(() => {
    const currentHour = new Date().getHours();
    if (currentHour < 12) setTimeOfDay("morning");
    else if (currentHour < 18) setTimeOfDay("afternoon");
    else setTimeOfDay("evening");
  }, []);

  useEffect(() => {
    if (currentUser && !isAnonymous) {
      (async () => {
        try {
          const response = await getCurrentUsersTasks(currentUser.accountId, "incomplete");
          if (!!response?.results?.length) setNumberOfTasks(response.results.length);
        } catch (error) {
          console.log("failed to get tasks for user");
        }
      })();
    }
  }, [currentUser, isAnonymous]);

  useEffect(() => {
    if (currentUser && isUserConnectedToJira && !isAnonymous) {
      (async () => {
        try {
          const total = await fetchUserIssuesTotal(currentUser.accountId);
          setNumberOfIssues(total || 0);
        } catch (error) {
          console.log("failed to get jira issues for user");
        }
      })();
    }
  }, [currentUser, isUserConnectedToJira, isAnonymous]);

  useEffect(() => {
    setIsUserConnectedToJira(!!userConnectedToJiraResponse);
  }, [userConnectedToJiraResponse]);

  useEffect(() => {
    const channel = new BroadcastChannel("cosmos-jira-connection");
    channel.onmessage = (channelMessage) => {
      if (channelMessage.type === "success") {
        setIsUserConnectedToJira(true);
        toast.success(channelMessage.msg, {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      if (channelMessage.type === "error") {
        toast.error(channelMessage.msg, {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    };
  }, []);

  const getBackground = () => {
    if (typeof data.backgroundColor === "string") {
      return data.backgroundColor || DEFAULT_BACKGROUND;
    }
    if (typeof data.backgroundColor === "object" && data?.backgroundColor?.firstColor) {
      return `linear-gradient(${data.backgroundColor.rotation || "90deg"}, ${data.backgroundColor.firstColor} ${
        data.backgroundColor.firstColorPosition
      }%, ${data.backgroundColor.secondColor} ${data.backgroundColor.secondColorPosition}%)`;
    }
  };

  if (!currentUser) return <></>;

  return (
    <UserStartWrapper background={getBackground()}>
      <InfoContainer>
        <UserImage
          src={
            isAnonymous
              ? "/images/hub/dashboard/anonymous_user.png"
              : `${window.AP._hostOrigin}${currentUser.profilePicture.path}`
          }
          style={{ cursor: "pointer" }}
          onClick={() => setSelectedPage(Pages.PROFILE)}
        />
        <TextWrapper>
          <Title>
            Good {timeOfDay},{" "}
            {!isAnonymous
              ? currentUser?.publicName?.includes(" ")
                ? currentUser.publicName.slice(0, currentUser.publicName.indexOf(" "))
                : currentUser?.publicName || ""
              : "Confluence User"}
          </Title>
          <Description>{data.message}</Description>
        </TextWrapper>
      </InfoContainer>

      {!isAnonymous && (
        <ButtonsWrapper>
          {data.actionItemsEnabled && (
            <InfoButton
              borderRadius={borderRadius}
              onClick={() => window.open(`${window.AP._hostOrigin}/wiki/plugins/inlinetasks/mytasks.action`, "_blank")}
            >
              <IconContainer>
                <ConfluenceIcon />
              </IconContainer>
              <InfoButtonText>
                <span>Action items</span>
                <span className="details">
                  <strong>{numberOfTasks}</strong> incomplete
                </span>
              </InfoButtonText>
            </InfoButton>
          )}

          {!isLoading && isUserConnectedToJira && data.jiraTasksEnabled && (
            <InfoButton
              borderRadius={borderRadius}
              onClick={() =>
                window.open(
                  `${window.AP._hostOrigin}/issues/?jql=assignee = currentUser() AND statusCategory not in (Done)`,
                  "_blank",
                )
              }
            >
              <IconContainer>
                <JiraIcon />
              </IconContainer>
              <InfoButtonText>
                <span>Assigned to me</span>
                <span className="details">
                  <strong>{numberOfIssues}</strong> open issues
                </span>
              </InfoButtonText>
            </InfoButton>
          )}

          {data.jiraTasksEnabled && !isUserConnectedToJira && (
            <AllowAccessButton
              borderRadius={borderRadius}
              onClick={async () => {
                const url = `ac/confluence/jira/connect`;
                const response = await httpClient.get(url);
                const consentWindow = window.open(response.data);
                const channel = new BroadcastChannel("cosmos-jira-connection");
                channel.onmessage = (channelMessage) => {
                  consentWindow.close();
                  channel.close();
                };
              }}
            >
              <JiraIcon />
              Connect Jira
            </AllowAccessButton>
          )}
        </ButtonsWrapper>
      )}
    </UserStartWrapper>
  );
}

export default UserStart;
