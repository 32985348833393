import styled from "styled-components";
import Sidebar from "./Sidebar";
import PageHeaders from "./PageHeaders";
import Colors from "./Colors";
import Corner from "./Corner";

import { useCorporateIdentityStore } from "../BrandAndColors/corporate-identity-store";

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  column-gap: 18px;
`;

function BrandAndColors() {
  const {
    logo,
    logoName,
    colors,
    borderRadius,
    headerType,
    setHeaderType,
    setBorderRadius,
    setLogoName,
    setColors,
    setLogo,
  } = useCorporateIdentityStore((state) => ({
    logo: state.logo,
    logoName: state.logoName,
    colors: state.colors,
    borderRadius: state.borderRadius,
    headerType: state.headerType,
    setHeaderType: state.setHeaderType,
    setBorderRadius: state.setBorderRadius,
    setLogoName: state.setLogoName,
    setColors: state.setColors,
    setLogo: state.setLogo,
  }));

  return (
    <Wrapper>
      <ContentWrapper style={{ width: "628px" }}>
        <Sidebar
          colors={colors}
          logo={logo}
          logName={logoName}
          setLogo={setLogo}
          setColors={setColors}
          setLogoName={setLogoName}
        />
        <PageHeaders headerType={headerType} colors={colors} setHeaderType={setHeaderType} />
      </ContentWrapper>

      <ContentWrapper style={{ width: "calc(100% - 646px)", marginLeft: "64px" }}>
        <Colors />
        <Corner borderRadius={borderRadius} setBorderRadius={setBorderRadius} />
      </ContentWrapper>
    </Wrapper>
  );
}

export default BrandAndColors;
