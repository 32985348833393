import React from "react";
import styled from "styled-components";

const ReadButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: ${({ dimensions: { height } }) => height};
  width: ${({ dimensions: { width } }) => width};

  border-radius: 3px;
  border: 1px solid #4c9aff;
  background-color: ${({ theme, isGrayed }) =>
    isGrayed ? theme.manualOrgChart.buttonColorActive : theme.manualOrgChart.buttonColor};
  box-sizing: border-box;

  text-align: center;
  color: ${({ theme }) => theme.manualOrgChart.buttonTextColor};
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  white-space: nowrap;

  &:hover {
    ${({ hasHover, theme }) => {
      if (hasHover) {
        return `background-color: ${theme.manualOrgChart.buttonColorHover};`;
      }
    }}
  }
`;

function ReadOnlyButton({ text, dimensions = { width: "65px", height: "35px" }, isGrayed = false, onClick }) {
  return (
    <ReadButton
      style={{ cursor: onClick ? "pointer" : "default" }}
      isGrayed={isGrayed}
      dimensions={dimensions}
      hasHover={!!onClick}
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
    >
      {text}
    </ReadButton>
  );
}

export default ReadOnlyButton;
