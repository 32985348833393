import React from "react";
import SizeButtons from "../../../../../../../Shared/Components/SizeButtons";
import {
  DesignSidebarWrapper,
  LabeledContainer,
  LabeledTitle,
  LabeledToggle,
  TabContent,
  TextInputWrapper,
  ToggleTitle,
} from "../../../styled/sidebar";
import TextField from "@atlaskit/textfield";
import TextArea from "@atlaskit/textarea";
import Toggle from "@atlaskit/toggle";
import { PanelTypes } from "../../panelTypes";
import { sizeType } from "../../../../../../../Shared/Components/size-options";
import SelectWithCustomOptions from "../../../../../../ContentBuilder/Shared/Filter/SelectWithCustomOptions";
import { PaginationOptions } from "../../../../Shared/PanelsPagination/pagination-options";

const LayoutSidebar = ({ panel, updatePanelDataProperty }) => {
  return (
    <div>
      <TabContent>
        <LabeledContainer>
          <LabeledTitle>Title</LabeledTitle>
          <TextInputWrapper>
            <TextField
              value={panel.data.title}
              placeholder="Enter title.."
              type="text"
              onChange={({ target: { value } }) => updatePanelDataProperty({ id: panel.id, property: "title", value })}
            />
          </TextInputWrapper>
        </LabeledContainer>

        <SelectWithCustomOptions
          initialValue={panel.data.pagination}
          options={PaginationOptions}
          label="Pagination"
          onUpdate={(value) => updatePanelDataProperty({ id: panel.id, property: "pagination", value })}
          customContainerStyle={{ marginTop: "0px" }}
        />

        <LabeledContainer>
          <LabeledTitle>Description</LabeledTitle>
          <TextArea
            resize="none"
            minimumRows={5}
            placeholder="Enter description.."
            value={panel.data.description}
            onChange={({ target: { value } }) =>
              updatePanelDataProperty({ id: panel.id, property: "description", value })
            }
          />
        </LabeledContainer>

        <LabeledContainer>
          <LabeledTitle>Display events</LabeledTitle>
          {panel.data.events.map((event, idx) => (
            <LabeledToggle key={idx}>
              <ToggleTitle>{event.label}</ToggleTitle>
              <Toggle
                isChecked={event.value}
                value={event.value}
                onChange={() =>
                  updatePanelDataProperty({
                    id: panel.id,
                    property: "events",
                    nestedProperty: "value",
                    label: event.label,
                    value: !event.value,
                  })
                }
              />
            </LabeledToggle>
          ))}
        </LabeledContainer>
      </TabContent>
    </div>
  );
};

const DesignSidebar = ({ panel, updatePanelDataProperty }) => {
  return (
    <DesignSidebarWrapper>
      <TabContent>
        <SizeButtons
          label="Profile image size"
          types={{ panel: PanelTypes.ACTIVE_USERS, size: sizeType.PROFILE_IMAGE_SIZE }}
          input={{ min: 0, max: 150, step: 2, value: panel.data.profileImageSize }}
          setValue={(value) => updatePanelDataProperty({ id: panel.id, property: sizeType.PROFILE_IMAGE_SIZE, value })}
          customStyles={{ marginTop: "0px" }}
        />
      </TabContent>
    </DesignSidebarWrapper>
  );
};

const sidebars = {
  Layout: LayoutSidebar,
  Design: DesignSidebar,
};

const RecentlyActiveUsersEdit = ({ panel, selectedTab, updatePanelDataProperty }) => {
  const Component = sidebars[selectedTab];

  return <Component panel={panel} updatePanelDataProperty={updatePanelDataProperty} />;
};

export default RecentlyActiveUsersEdit;
