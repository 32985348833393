import httpClient from "../../../../../Service/http-client";

export async function getCosmosAppSettings() {
  const url = "ac/confluence/cosmos-app/settings";
  return httpClient.get(url);
}

export async function saveCosmosAppSettings(data) {
  const url = "ac/confluence/cosmos-app/settings";
  return httpClient.post(url, { data });
}
