import { DEFAULT_DATA_BY_PANEL_KEY } from "./panelDataByKey";
import { PanelKeys, PanelTypes } from "./panelTypes";

const defaultPanelData = [
  {
    id: crypto.randomUUID(),
    order: 0,
    type: PanelTypes.TEAM,
    data: DEFAULT_DATA_BY_PANEL_KEY[PanelKeys.KNOW_YOUR_TEAM],
  },
  {
    id: crypto.randomUUID(),
    order: 1,
    type: PanelTypes.BIRTHDAY,
    data: DEFAULT_DATA_BY_PANEL_KEY[PanelKeys.UPCOMING_BIRTHDAY],
  },
  {
    id: crypto.randomUUID(),
    order: 2,
    type: PanelTypes.ACTIVE_USERS,
    data: DEFAULT_DATA_BY_PANEL_KEY[PanelKeys.RECENTLY_ACTIVE_USERS],
  },
  {
    id: crypto.randomUUID(),
    order: 3,
    type: PanelTypes.NEW_STARTERS,
    data: DEFAULT_DATA_BY_PANEL_KEY[PanelKeys.NEW_STARTERS],
  },
  {
    id: crypto.randomUUID(),
    order: 4,
    type: PanelTypes.BLOG,
    data: DEFAULT_DATA_BY_PANEL_KEY[PanelKeys.USER_BLOG_POSTS],
  },
  {
    id: crypto.randomUUID(),
    order: 5,
    type: PanelTypes.EMPLOYEES,
    data: DEFAULT_DATA_BY_PANEL_KEY[PanelKeys.ALL_EMPLOYEES],
  },
  {
    id: crypto.randomUUID(),
    order: 6,
    type: PanelTypes.BIRTHDAY,
    data: DEFAULT_DATA_BY_PANEL_KEY[PanelKeys.WORK_ANNIVERSARY],
  },
];

export const getDefaultPeopleNetwork = () => [...defaultPanelData];
