import React from "react";
import ActiveUsers from "../Views/Sections/ActiveUsers";
import BlogPosts from "../Views/Sections/BlogPosts";
import Employees from "../Views/Sections/Employees";
import KnowYourTeam from "../Views/Sections/KnowYourTeam";
import UpcomingBirthdays from "../Views/Sections/UpcomingBirthdays";
import NewStarters from "../Views/Sections/NewStarters";
import Cards from "../../Shared/Panels/view/cards/Cards";
import { PanelTypes } from "./panelTypes";

const panels = {
  [PanelTypes.TEAM]: KnowYourTeam,
  [PanelTypes.ACTIVE_USERS]: ActiveUsers,
  [PanelTypes.BLOG]: BlogPosts,
  [PanelTypes.EMPLOYEES]: Employees,
  [PanelTypes.BIRTHDAY]: UpcomingBirthdays,
  [PanelTypes.NEW_STARTERS]: NewStarters,
  [PanelTypes.CARDS]: Cards,
};

const PanelProvider = ({ panel, ...props }) => {
  const Panel = panels[panel.type];

  return <Panel panelData={panel.data} {...props} {...panel} cardsType={panel.key} isInPeopleNetwork />;
};

export default PanelProvider;
