import styled, { useTheme } from "styled-components";
import { useSidebarStore } from "../../store";
import { DrawerTypes } from "../drawer/types";

const EmptyStateWrapper = styled.div`
  height: 100%;
  display: grid;
  place-content: center;
  flex: 1;
`;

const TextWrapper = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  margin-top: 25px;
`;

const Title = styled.span`
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: ${({ theme }) => theme.navigation.settings.navigationEmptyStateTitleColor};
`;

const Description = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: ${({ theme }) => theme.global.secondary};

  span {
    color: #2684ff;
    text-decoration: underline;
    cursor: pointer;
  }
`;

const Image = styled.img`
  display: block;
  margin: 0 auto;
`;

const EmptyNavigationState = () => {
  const { setIsOpen, setSelectedSidebarType } = useSidebarStore((state) => ({
    setIsOpen: state.setIsOpen,
    setSelectedSidebarType: state.setSelectedSidebarType,
  }));

  const theme = useTheme();

  return (
    <EmptyStateWrapper>
      <Image src={`/images/navigation/navigation-menu-empty-state-${theme.global.name}.svg`} alt="" />
      <TextWrapper>
        <Title>Set a navigation destination</Title>
        <Description>
          Click{" "}
          <span
            style={{ color: "#0065ff" }}
            onClick={() => {
              setIsOpen(true);
              setSelectedSidebarType(DrawerTypes.ADD_ENTRY);
            }}
          >
            Add entry
          </span>{" "}
          to start.
        </Description>
      </TextWrapper>
    </EmptyStateWrapper>
  );
};

export default EmptyNavigationState;
