import SectionMessage from "@atlaskit/section-message";
import styled from "styled-components";

const Wrapper = styled.div`
  height: fit-content;
  border-radius: 3px;
  margin-left: 32px;
  flex: 1;

  section > div:nth-of-type(2) > h1 {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
  }
`;

function SectionMessageCustom({ title, description }) {
  return (
    <Wrapper>
      <SectionMessage title={title}>
        <p style={{ fontWeight: "400", fontSize: "14px", lineHeight: "20px" }}>{description}</p>
      </SectionMessage>
    </Wrapper>
  );
}

export default SectionMessageCustom;
