import React from "react";

function Gliffy() {
  return (
    <div style={{ display: "grid" }}>
      <img
        style={{ width: "408px", height: "186px", margin: "0 auto" }}
        src="/images/hub/dashboard/gliffy-preview-image.png"
        alt="gliffy diagram"
      />
    </div>
  );
}

export default Gliffy;
