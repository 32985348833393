import { Ordering, Sorting } from "../../../filter/sorting-types";

export const findSpacesUserIsWatching = async () => {
  try {
    const url = "/rest/api/search?cql=type=space AND watcher = currentUser()&expand=space.icon";
    const response = await window.AP.request(url);
    if (response?.body) {
      const data = JSON.parse(response.body)?.results;
      return data;
    }
    return [];
  } catch (error) {
    return undefined;
  }
};

const buildUrlForMetadataProperty = (categoryId, isNegation) =>
  `content.property[cosmosnewsmetadata].categories${isNegation ? "!" : ""}~"${categoryId}"`;

export const getCqlForCategories = (categories, isNegation = false, operation = "OR") => {
  if (!categories?.length) return "";

  let string = "";
  categories.forEach((categoryId, index) => {
    string = string + `${buildUrlForMetadataProperty(categoryId, isNegation)}`;
    if (index + 1 < categories.length) {
      string = string + ` ${operation} `;
    }
  });
  return string;
};

export const getCqlForSpaces = (spaces) => {
  if (!spaces?.length) return "";

  let string = "";
  spaces.forEach((spaceKey, index) => {
    string = string + `space in ("${spaceKey}")`;
    if (index + 1 < spaces.length) {
      string = string + ` OR `;
    }
  });

  return string;
};

export const searchPersonalizedBlogposts = ({ queryKey, pageParam }) => {
  const [
    _key,
    {
      subscribedCategories = [],
      watchingSpaces = [],
      limit = 2,
      sorting = Sorting.CREATED,
      ordering = Ordering.DESCENDING,
    },
  ] = queryKey;

  let cql = "type = blogpost";

  const categoriesCql = getCqlForCategories(subscribedCategories);
  const spacesCql = getCqlForSpaces(watchingSpaces);

  if (categoriesCql && spacesCql) {
    cql = cql + ` AND (${spacesCql} OR ${categoriesCql})`;
  } else if (categoriesCql) {
    cql = cql + ` AND ${categoriesCql}`;
  } else if (spacesCql) {
    cql = cql + ` AND ${spacesCql}`;
  } else {
    cql = cql + ` AND creator = "-1"`;
  }

  if (sorting === Sorting.CREATED) {
    cql = cql + ` ORDER BY created`;
  }
  if (sorting === Sorting.MODIFIED) {
    cql = cql + ` ORDER BY lastmodified`;
  }
  if (sorting === Sorting.TITLE) {
    cql = cql + ` ORDER BY title`;
  }
  if (ordering === Ordering.ASCENDING) {
    cql = cql + ` asc`;
  }
  if (ordering === Ordering.DESCENDING) {
    cql = cql + ` desc`;
  }

  cql = encodeURI(cql);

  let url = `/rest/api/search?cql=${cql}&limit=${limit}&expand=content.history,content.space,content.metadata.labels,content.metadata.likes,content.children.comment`;

  if (pageParam) {
    url = `${url}&cursor=${decodeURI(pageParam)}`;
  }
  try {
    return window.AP.request(url);
  } catch (e) {
    console.log("cant fetch blogposts");
    console.log(e);
  }
};

export const searchTargetedNews = ({ queryKey, pageParam }) => {
  const [
    _key,
    {
      blogIds = [],
      blogIdsToRemove = [],
      labels = [],
      contributors = [],
      spaces = [],
      limit = 2,
      sorting = Sorting.CREATED,
      ordering = Ordering.DESCENDING,
    },
  ] = queryKey;

  let cql = "";

  if (blogIds?.length && blogIdsToRemove?.length) {
    cql = `(type=blogpost AND content in (${blogIds.join(", ")}) AND content NOT IN (${blogIdsToRemove.join(
      ", ",
    )})) OR (type = blogpost AND content NOT IN (${blogIdsToRemove.join(", ")}))`;
  } else if (blogIds?.length) {
    cql = `content in (${blogIds.join(", ")}) OR type = blogpost`;
  } else if (blogIdsToRemove?.length) {
    cql = `type = blogpost AND content NOT IN (${blogIdsToRemove.join(", ")})`;
  } else {
    cql = `type = blogpost`;
  }

  if (labels.length > 0) {
    cql = cql + ` AND label in (${labels.map((l) => `"${l}"`).join(",")})`;
  }
  if (contributors.length > 0) {
    cql = cql + ` AND contributor.accountid in (${contributors.map((c) => `"${c}"`).join(",")})`;
  }
  if (spaces.length > 0) {
    cql = cql + ` AND space in (${spaces.map((s) => `"${s}"`).join(",")})`;
  }

  if (sorting === Sorting.CREATED) {
    cql = cql + ` ORDER BY created`;
  }
  if (sorting === Sorting.MODIFIED) {
    cql = cql + ` ORDER BY lastmodified`;
  }
  if (sorting === Sorting.TITLE) {
    cql = cql + ` ORDER BY title`;
  }
  if (ordering === Ordering.ASCENDING) {
    cql = cql + ` asc`;
  }
  if (ordering === Ordering.DESCENDING) {
    cql = cql + ` desc`;
  }

  cql = encodeURI(cql);

  let url = `/rest/api/search?cql=${cql}&limit=${limit}&expand=content.history,content.space,content.metadata.labels,content.metadata.likes,content.children.comment`;

  if (pageParam) {
    url = `${url}&cursor=${decodeURI(pageParam)}`;
  }
  try {
    return window.AP.request(url);
  } catch (e) {
    console.log("cant fetch blogposts");
    console.log(e);
  }
};

export const getAllBlogIdsFromCategories = async (categories) => {
  try {
    const categoriesCql = getCqlForCategories(categories);
    const encodedCql = encodeURI(categoriesCql);

    let url = `/rest/api/search?cql=${encodedCql}&expand=content.history,content.space,content.metadata.labels,content.metadata.likes,content.children.comment`;

    const response = await window.AP.request(url);

    if (response?.body) {
      const data = JSON.parse(response.body)?.results;
      const blogIds = data.map((blog) => blog.content.id);
      return blogIds;
    }
    return [];
  } catch (error) {
    return [];
  }
};
