import styled from "styled-components";

export const EmptyIcon = styled.div`
  width: 40px;
  height: 40px;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.navigation.settings.iconBoxBackground};
  border-radius: 3px;
  border: 1px solid ${({ theme }) => theme.collections.collectionCardBorder};
  cursor: pointer;
  display: grid;
  place-items: center;

  div {
    width: 1px;
    height: 32px;
    border-left: 1px solid #ff1111;
    transform: rotate(45deg);
  }
`;

export const IconBox = styled.div`
  width: 40px;
  height: 40px;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.navigation.settings.iconBoxBackground};
  border-radius: 3px;
  border: 1px solid ${({ theme }) => theme.navigation.settings.iconBoxBorder};
  cursor: pointer;
  display: grid;
  place-items: center;
`;

export const ImageButton = styled.div`
  display: flex;
  width: 38px;
  height: 38px;
  align-items: center;
  justify-content: center;
  color: #42526e;
`;

export const EntryAction = styled.div`
  display: flex;
  align-items: center;
  column-gap: 5px;
  cursor: pointer;
  color: ${({ theme }) => theme.poll.icons};

  .edit-icon,
  .delete-icon {
    svg {
      color: #97a0af;
    }
    min-width: 24px;
    &:hover svg {
      color: ${({ theme }) => theme.navigation.settings.entryIconColor};
      opacity: ${({ theme }) => theme.navigation.settings.entryIconOpacity};
    }
  }
`;
