import React, { useEffect, useState } from "react";
import { atlassianRestService } from "../../../../../Service/AtlassianRestService";
import { htmlDecode } from "../../../../ContentBuilder/utils/decode-html-string";
import { AsyncSelect } from "@atlaskit/select";
import { SELECT_STYLES } from "./CustomSelectStyles";
import { FieldContainer, Label } from "./styled";

function mapPage(page) {
  return {
    contentId: page.content.id,
    type: page.content.type,
    name: page.content.title,
    space: { name: page.content.space.name },
    excerpt: htmlDecode(page.excerpt),
    url: `${window.AP._hostOrigin}/wiki${page.url}`,
    authorUrl: `${window.AP._hostOrigin}/wiki/people/${page.content.history.createdBy.accountId}`,
    spaceUrl: `${window.AP._hostOrigin}/wiki/spaces/${page.content.space.key}/overview`,
    createdBy: page.content.history.createdBy.displayName,
    lastModified: page.friendlyLastModified,
    label: page.content.title,
    value: page.content.id,
  };
}

const PagePicker = ({
  initialContentId,
  onUpdate,
  label = "Page",
  placeholder = "Filter by page",
  showOnlyGlobalPages = false,
  manuallyPicked,
  isDynamic = false,
}) => {
  const [selectedPage, setSelectedPage] = useState(undefined);
  const [selectedPageManual, setSelectedPageManual] = useState(manuallyPicked);

  useEffect(() => {
    if (initialContentId) {
      atlassianRestService.getPageById(initialContentId).then(mapPage).then(setSelectedPage);
    }
  }, [initialContentId]);

  const loadOptionsDynamic = async (query) => {
    return (await atlassianRestService.searchForPage(query, 25, showOnlyGlobalPages)).results.map(mapPage);
  };

  const loadOptions = async (query) => {
    const allPages = (await atlassianRestService.searchForPage(query)).results.map(mapPage);
    const filteredPages = allPages.filter(
      (page) => !selectedPageManual.some((selectedPage) => selectedPage.contentId === page.contentId),
    );
    return filteredPages;
  };

  const handleOnChangeManual = (e) => {
    if (e !== undefined) {
      setSelectedPageManual(e);
      onUpdate(e);
    } else {
      setSelectedPageManual([]);
      onUpdate([]);
    }
  };

  const handleOnChange = (e) => {
    if (e !== undefined) {
      setSelectedPage(e);
      onUpdate(e);
    } else {
      setSelectedPage(undefined);
      onUpdate(undefined);
    }
  };

  return (
    <FieldContainer style={{ marginBottom: "15px" }}>
      <Label>{label}</Label>
      <AsyncSelect
        isClearable={isDynamic}
        placeholder={placeholder}
        onChange={isDynamic ? handleOnChange : handleOnChangeManual}
        value={isDynamic ? selectedPage : undefined}
        loadOptions={isDynamic ? loadOptionsDynamic : loadOptions}
        styles={SELECT_STYLES}
        defaultOptions
        closeMenuOnSelect={false}
      />
    </FieldContainer>
  );
};
export default PagePicker;
