import React, { useEffect, useState } from "react";
import { atlassianRestService } from "../../../../../../../../Service/AtlassianRestService";
import SelectFilter from "../../../../../Dashboard/filter/SelectFilter";

const Dynamic = ({ panelId, data, updatePanelDataProperty, collectionsData }) => {
  const [loadingUserGroups, setLoadingUserGroups] = useState(true);
  const [userGroups, setUserGroups] = useState([]);
  const { collections } = collectionsData;

  const formattedCollections = collections?.map((collection) => ({
    label: collection.name,
    value: collection.id,
  }));

  const loadUserGroups = async () => {
    setLoadingUserGroups(true);
    atlassianRestService
      .getGroups()
      .then((response) => {
        if (response?.results) {
          const builtGroups = response.results.map((group) => ({
            id: group.id,
            label: group.name,
            value: group.id,
          }));
          setUserGroups([...builtGroups]);
        } else {
          setUserGroups([]);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setLoadingUserGroups(false));
  };

  useEffect(() => {
    loadUserGroups();
  }, []);

  return (
    <>
      <SelectFilter
        label="Group"
        placeholder="Filter by group.."
        value={data?.userGroup}
        isClearable
        setValue={(newValue) => updatePanelDataProperty(panelId, "userGroup", newValue)}
        options={userGroups}
        isLoading={loadingUserGroups}
      />
      <SelectFilter
        label="Collections"
        placeholder="Filter by collection.."
        value={data?.collection}
        isClearable
        setValue={(newValue) => updatePanelDataProperty(panelId, "collection", newValue)}
        options={formattedCollections}
      />
    </>
  );
};

export default Dynamic;
