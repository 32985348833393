import React from "react";
import {
  Card,
  CardBackground,
  CardInfoContainer,
  GridWrapper,
  MediumLine,
  SmallLine,
  UserImage,
  Wrapper,
} from "./styled";
import styled from "styled-components";

const Container = styled.div`
  border: 1px solid #dfe1e6;
  border-radius: 8px;
  padding: 30px 17px;

  display: flex;
  flex-direction: column;
  row-gap: 14px;
`;

const PersonWrapper = styled.div`
  display: grid;
  grid-template-columns: 40px auto;
  column-gap: 12px;
  align-items: center;
`;

const PersonImage = styled.img`
  box-sizing: border-box;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2.5px solid #e6ebf0;
  object-fit: cover;
`;

const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Name = styled.span`
  font-weight: 600;
  font-size: 11.3641px;
  line-height: 14px;
  color: #172b4d;
`;

const Today = styled.span`
  font-weight: 500;
  font-size: 9px;
  line-height: 11px;
  color: #10bd73;
`;

const Date = styled.div`
  text-align: center;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #0065ff;
`;

const Birthday = styled.span`
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  color: #0065ff;
  margin-top: 4px;
`;

export const ImportantDatesMain = () => {
  const dummyData = [
    {
      icon: "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=128&h=128&q=80",
      background: `url("https://images.unsplash.com/photo-1496715976403-7e36dc43f17b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=640&h=480&q=80")`,
      date: "TODAY",
    },
    {
      icon: "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=128&h=128&q=80",
      background: `url("https://images.unsplash.com/photo-1419242902214-272b3f66ee7a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1213&q=80")`,
      date: "TOMORROW",
    },
    {
      icon: "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=128&h=128&q=80",
      background: "linear-gradient(270deg, #84B9F8 0%, #2571CB 98.75%)",
      date: "10 Days",
    },
    {
      icon: "https://images.unsplash.com/photo-1488426862026-3ee34a7d66df?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=128&h=128&q=80",
      background: `url("https://images.unsplash.com/photo-1497032628192-86f99bcd76bc?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=640&h=480&q=80")`,
      date: "27 Days",
    },
  ];

  return (
    <GridWrapper>
      {dummyData.map(({ icon, background, date }, idx) => (
        <Card key={idx}>
          <CardBackground background={background} />
          <CardInfoContainer>
            <UserImage src={icon} />
            <MediumLine width={65} style={{ backgroundColor: "#DFE1E6" }} />
            <MediumLine width={55} style={{ backgroundColor: "#EBECF0" }} />
            <Birthday>{date}</Birthday>
          </CardInfoContainer>
        </Card>
      ))}
    </GridWrapper>
  );
};

function ImportantDates() {
  return (
    <Wrapper>
      <Container>
        <PersonWrapper>
          <PersonImage src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=128&h=128&q=80" />
          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <DetailsWrapper style={{ rowGap: "1px" }}>
              <Name>Sarah Connor</Name>
              <Today>TODAY</Today>
            </DetailsWrapper>

            <img src="/images/hub/dashboard/birthday-emoji.png" alt="" />
          </div>
        </PersonWrapper>

        <PersonWrapper>
          <PersonImage src="https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=128&h=128&q=80" />
          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <DetailsWrapper style={{ rowGap: "7px" }}>
              <SmallLine width={47} bg="#C1C7D0" />
              <SmallLine width={64} bg="#E4E4E4" />
            </DetailsWrapper>

            <Date>
              2<br />
              Days
            </Date>
          </div>
        </PersonWrapper>

        <PersonWrapper>
          <PersonImage src="https://images.unsplash.com/photo-1488426862026-3ee34a7d66df?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=128&h=128&q=80" />
          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <DetailsWrapper style={{ rowGap: "7px" }}>
              <SmallLine width={47} bg="#C1C7D0" />
              <SmallLine width={64} bg="#E4E4E4" />
            </DetailsWrapper>

            <Date>
              10
              <br />
              Days
            </Date>
          </div>
        </PersonWrapper>

        <PersonWrapper>
          <PersonImage src="https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=128&h=128&q=80" />
          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <DetailsWrapper style={{ rowGap: "7px" }}>
              <SmallLine width={47} bg="#C1C7D0" />
              <SmallLine width={64} bg="#E4E4E4" />
            </DetailsWrapper>

            <Date>
              23
              <br />
              Days
            </Date>
          </div>
        </PersonWrapper>
      </Container>
    </Wrapper>
  );
}

export default ImportantDates;
