import styled from "styled-components";

export const PanelContainer = styled.div`
  padding: 33px;
  height: min-content;
  width: calc(100% - 66px);
  background-color: ${({ theme }) => theme.shared.profile.panel.background};
  border: 1px solid ${({ theme }) => theme.shared.profile.panel.border};
  border-radius: 11px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  flex: 1;
`;

export const SidebarPanelFieldWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  margin-top: 26px;
  gap: 18px;

  .field-container:first-child * {
    font-size: 16px;
  }
`;

export const MainPanelWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 10px;
  row-gap: 20px;
`;

export const MainPanelFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
