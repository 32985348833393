import merge from "lodash.merge";

export const SELECT_STYLES = {
  control: (provided) => ({
    ...provided,
    minWidth: "120px",
    fontSize: "14px",
    borderRadius: "3px",
    boxShadow: null,
  }),
  menu: (provided) => ({
    ...provided,
    outline: "none",
    zIndex: "5",
  }),
  option: (provided) => ({
    ...provided,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    height: "28px",
    fontSize: "14px",
    boxSizing: "border-box",
    boxShadow: null,
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: "0 6px",
  }),
  input: (provided) => ({
    ...provided,
    margin: "0px",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
};

export const SIMPLE_SELECT_STYLES = {
  control: (provided) => ({
    ...provided,
    fontSize: "14px",
  }),
  option: (provided) => ({
    ...provided,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    fontSize: "14px",
    boxSizing: "border-box",
    cursor: "pointer",
  }),
};

export const MODIFIED_SELECT_STYLES = {
  control: (provided) => ({
    ...provided,
    fontSize: "14px",
  }),
  option: (provided) => ({
    ...provided,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    fontSize: "14px",
    boxSizing: "border-box",
    cursor: "pointer",
  }),
  singleValue: (provided) => ({
    ...provided,
    fontWeight: 600,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    width: "100%",
  }),
};

export const selectStylesWithOverrides = (overrides) => {
  return merge(SELECT_STYLES, overrides);
};
