import React, { useRef, useState } from "react";
import Modal, { ModalTransition } from "@atlaskit/modal-dialog";
import Toggle from "@atlaskit/toggle";
import Button from "@atlaskit/button";
import Spinner from "@atlaskit/spinner";
import { analytics } from "../../../analytics";
import { saveSyncGuestUsersState } from "../datagrid/api";
import { useRecordsStore } from "../records-store";
import { resyncUsers } from "../resync-service";

export const ReSyncUserModal = ({ visible, onClose, onSyncFinished }) => {
  const [syncing, setSyncing] = useState(false);
  const syncModalWrapperRef = useRef();

  const { syncGuestUsers, setSyncGuestUsers } = useRecordsStore((state) => ({
    syncGuestUsers: state.syncGuestUsers,
    setSyncGuestUsers: state.setSyncGuestUsers,
  }));

  const handleStart = async () => {
    setSyncing(true);
    await saveSyncGuestUsersState(syncGuestUsers);

    const syncWasNecessary = await resyncUsers(syncGuestUsers);

    if (!syncWasNecessary) {
      setSyncing(false);
      onClose();
      return;
    }

    analytics.logEvent({ name: "Cosmos Users", template: "sync" });

    setSyncing(false);
    onSyncFinished();
    onClose();
  };

  return (
    <ModalTransition>
      {visible && (
        <Modal
          width="400px"
          heading={!syncing ? "Sync users" : "Syncing in progress..."}
          onClose={() => onClose()}
          onOpenComplete={() => {
            if (syncModalWrapperRef.current) {
              syncModalWrapperRef.current.parentNode.parentNode.style.overflow = "visible";
            }
          }}
        >
          {syncing && (
            <div
              style={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spinner size={"large"} />
            </div>
          )}
          {!syncing && (
            <div tabIndex={0} style={{ outline: "none", padding: "24px 24px 22px" }} ref={syncModalWrapperRef}>
              <p>Refresh the database. This will add new users, and remove suspended or unlicensed users.</p>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  paddingBottom: "20px",
                }}
              >
                <span>Sync guest users</span>
                <Toggle
                  isDisabled={syncing}
                  isChecked={syncGuestUsers}
                  onChange={({ target: { checked: isChecked } }) => setSyncGuestUsers(isChecked)}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginBottom: "20px",
                }}
              >
                <Button appearance="link" onClick={onClose}>
                  Cancel
                </Button>

                <Button onClick={handleStart} style={{ marginLeft: "10px" }} appearance="primary">
                  Sync
                </Button>
              </div>
            </div>
          )}
        </Modal>
      )}
    </ModalTransition>
  );
};

export default ReSyncUserModal;
