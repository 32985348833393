import httpClient from "../../Service/http-client";

export async function fetchCollections({ queryKey }) {
  const url = `ac/confluence/collections/collections`;
  try {
    return httpClient.get(url);
  } catch (error) {
    return [];
  }
}

export async function createCollectionInDatabase(collection) {
  const url = `ac/confluence/collections/collection`;

  try {
    return httpClient.post(url, collection);
  } catch (error) {
    return undefined;
  }
}

export async function updateCollectionInDatabase(collection) {
  const url = `ac/confluence/collections/collection/update`;

  try {
    return httpClient.post(url, collection);
  } catch (error) {
    return undefined;
  }
}

export async function deleteCollectionInDatabase(collectionId) {
  const url = `ac/confluence/collections/collection/delete`;

  try {
    return httpClient.post(url, { collectionId });
  } catch (error) {
    return undefined;
  }
}

export async function fetchUsersInCollection(filters) {
  const url = "ac/confluence/collections/collection/records";

  try {
    return httpClient.post(url, { filters });
  } catch (error) {
    return undefined;
  }
}

export async function updateBlogToCollections(blogId, collectionChanges) {
  const url = "ac/confluence/collections/update-blog-to-collections";

  try {
    return httpClient.post(url, { blogId, collectionChanges });
  } catch (error) {
    return undefined;
  }
}

export async function getCollectionsByTargetedBlog(blogId) {
  const url = `ac/confluence/collections/collections-targeted-blog?blogId=${blogId}`;

  try {
    return httpClient.get(url);
  } catch (error) {
    return undefined;
  }
}

export async function addCategoryToCollections(categoryId, collections) {
  const url = "ac/confluence/collections/add-category-to-collections";

  try {
    return httpClient.post(url, { categoryId, collections });
  } catch (error) {
    return undefined;
  }
}

export async function getCollectionsByTargetedCategories(categoryId) {
  const url = `ac/confluence/collections/collections-targeted-categories?categoryId=${categoryId}`;

  try {
    return httpClient.get(url);
  } catch (error) {
    return undefined;
  }
}

export async function fetchDetailsForUsers(accountIds) {
  try {
    const url = `/rest/api/search?cql=user.accountId in (${accountIds
      .map((id) => `"${id}"`)
      .join(",")})&sitePermissionTypeFilter=all`;
    return window.AP.request(url);
  } catch (error) {
    return undefined;
  }
}

export async function fetchUser({ queryKey }) {
  const [_key, { accountId }] = queryKey;
  try {
    const url = `/rest/api/user?accountId=${accountId}`;
    return window.AP.request(url);
  } catch (error) {
    console.log("can't fetch user");
    return undefined;
  }
}
