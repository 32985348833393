import styled from "styled-components";

export const LabelIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const IconContainer = styled.div`
  width: 24px;
  height: 24px;
  display: grid;
  place-content: center;
`;

export const InfoPanelIconWrapper = styled.div`
  display: grid;
  place-content: center;
`;

const NotificationPanel = styled.div`
  display: flex;
  column-gap: 10px;
  padding: 10px;
  background-color: ${({ theme }) => theme.shared.form.button.background.active};
  color: ${({ theme }) => theme.notification.title};
  border-radius: 3px;
  font-size: 14px;

  a {
    color: #0065ff;
    text-decoration: none;

    &:visited {
      color: #0065ff;
    }
  }

  h3 {
    margin: 0px 0px 15px 0px;
    font-weight: 500;
  }
`;

export const InfoPanel = styled(NotificationPanel)`
  background-color: ${({ theme }) => theme.shared.form.button.background.active};
  color: ${({ theme }) => theme.notification.title};
`;

export const WarningPanel = styled(NotificationPanel)`
  background-color: #fffae6;
  color: #172b4d;
`;

export const PanelEditorWrapper = styled.div`
  height: calc(100% - 50px);
`;

export const DesignSidebarWrapper = styled.div`
  height: 100%;
  display: grid;
`;

export const BannerWrapper = styled.div`
  padding-top: 20px;
  display: grid;
  align-items: flex-end;
`;

export const TabsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 12px;
`;

export const Tab = styled.div`
  display: grid;
  place-content: center;
  padding: 9px 0px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  cursor: pointer;
  background: ${({ isSelected, primaryColor, theme }) =>
    isSelected ? theme.shared.sidebar.tabs.background.active : theme.shared.sidebar.tabs.background.inactive};
  color: ${({ isSelected, primaryColor, theme }) =>
    isSelected ? theme.shared.form.button.text.active : theme.shared.form.button.text.inactive};
  border-radius: 3px;
`;

export const ColorsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  row-gap: 10px;
  flex-wrap: wrap;
`;

export const ColorTileAndLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 12px;
`;

export const ColorLabel = styled.span`
  color: #42526e;
  font-size: 12px;
  font-weight: 500;
`;

export const ColorTile = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 3px;
  background: ${({ theme }) => theme.shared.sidebar.colorBackground};
  cursor: ${({ isDisabled }) => (isDisabled ? "not-allowed" : "pointer")};
  outline: ${({ isSelected }) => (isSelected ? "1px solid #0065ff" : "none")};

  position: relative;

  &::after {
    content: "";
    position: absolute;
    top: 4px;
    right: 4px;
    bottom: 4px;
    left: 4px;
    background: ${({ color }) => color};
  }
`;
