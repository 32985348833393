import React from "react";
import { useTheme } from "styled-components";

function Released() {
  const theme = useTheme();

  const colorScheme = theme.global.name;

  return (
    <div style={{ display: "grid" }}>
      <img
        style={{ margin: "0 auto" }}
        src={`/images/hub/dashboard/released-preview-image-${colorScheme}.png`}
        alt="released integration"
      />
    </div>
  );
}

export default Released;
