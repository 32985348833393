import { DEFAULT_DATA_BY_PANEL_KEY } from "./panelDataByKey";
import { PanelKeys, PanelPositioning, PanelTypes } from "./panelTypes";

const defaultPanelData = [
  {
    id: "main-user-start",
    order: 0,
    position: PanelPositioning.MAIN,
    type: PanelTypes.USER_START,
    panelTitle: "Welcome Banner",
    data: {
      backgroundColor:
        "linear-gradient(270deg, #3a52c5 0%, #704ebf 24.35%, #b34cb5 50.13%, #d16b9e 77.69%, #efa37b 100%)",
      actionItemsEnabled: true,
      jiraTasksEnabled: true,
      message: "Here's everything you need to stay connected and informed. Have a good one!",
    },
  },
  {
    id: "main-panel-1",
    order: 1,
    position: PanelPositioning.MAIN,
    type: PanelTypes.NEWS,
    panelTitle: "Company News",
    data: DEFAULT_DATA_BY_PANEL_KEY[PanelKeys.COMPANY_NEWS],
  },
  {
    id: "main-panel-2",
    order: 2,
    position: PanelPositioning.MAIN,
    panelTitle: "Countdown",
    type: PanelTypes.COUNTDOWN,
    data: DEFAULT_DATA_BY_PANEL_KEY[PanelKeys.COUNTDOWN],
  },
  {
    id: "main-panel-3",
    order: 3,
    position: PanelPositioning.MAIN,
    type: PanelTypes.VIDEOS,
    panelTitle: "Watch",
    data: DEFAULT_DATA_BY_PANEL_KEY[PanelKeys.VIDEOS_YT],
  },
  {
    id: "main-panel-4",
    order: 4,
    position: PanelPositioning.MAIN,
    type: PanelTypes.NEW_USERS_MAIN,
    panelTitle: "New Starters",
    data: DEFAULT_DATA_BY_PANEL_KEY[PanelKeys.NEW_STARTERS],
  },
  {
    id: "main-panel-5",
    order: 5,
    position: PanelPositioning.MAIN,
    type: PanelTypes.NEWS,
    panelTitle: "Team News",
    data: DEFAULT_DATA_BY_PANEL_KEY[PanelKeys.TEAM_NEWS],
  },
  {
    id: "side-panel-1",
    order: 1,
    position: PanelPositioning.SIDE,
    panelTitle: "Info Panel",
    type: PanelTypes.NOTIFICATION,
    data: DEFAULT_DATA_BY_PANEL_KEY[PanelKeys.INFO_PANEL],
  },
  {
    id: "side-panel-2",
    order: 2,
    position: PanelPositioning.SIDE,
    type: PanelTypes.BIRTHDAY,
    key: PanelKeys.IMPORTANT_DATES,
    panelTitle: "Upcoming Birthdays",
    data: DEFAULT_DATA_BY_PANEL_KEY[PanelKeys.IMPORTANT_DATES],
  },
  {
    id: "side-panel-3",
    order: 3,
    position: PanelPositioning.SIDE,
    type: PanelTypes.POLL,
    panelTitle: "Poll",
    data: {
      isDummy: true,
      pollId: "dashboard-poll",
    },
  },
  {
    id: "side-panel-4",
    order: 4,
    position: PanelPositioning.SIDE,
    type: PanelTypes.USEFUL_LINKS,
    panelTitle: "App Links",
    data: DEFAULT_DATA_BY_PANEL_KEY[PanelKeys.APP_LINKS],
  },
  {
    id: "side-panel-5",
    order: 5,
    position: PanelPositioning.SIDE,
    type: PanelTypes.ASSIGNED_TASKS,
    panelTitle: "Assigned to Me",
    data: DEFAULT_DATA_BY_PANEL_KEY[PanelKeys.ASSIGNED_TO_ME],
  },
  {
    id: "side-panel-6",
    order: 6,
    position: PanelPositioning.SIDE,
    type: PanelTypes.SPACES,
    panelTitle: "Important Spaces",
    data: DEFAULT_DATA_BY_PANEL_KEY[PanelKeys.IMPORTANT_SPACES],
  },
];

export const getDefaultDashboard = () => [...defaultPanelData];
