import httpClient from "../../../../../Service/http-client";
import { Ordering, Sorting } from "../../Dashboard/filter/sorting-types";
import { getCqlForCategories } from "../../Dashboard/panels/types/news/api";

export async function getCategoriesSettings({ queryKey }) {
  const url = "ac/confluence/categories/settings";
  return httpClient.get(url);
}

export async function getSubscribedToCategories({ includeAllCategories } = {}) {
  const url = `ac/confluence/categories/subscribed?includeAllCategories=${includeAllCategories}`;
  return httpClient.get(url);
}

export async function saveCategoriesSettings(data) {
  const url = "ac/confluence/categories/settings/save";
  return httpClient.post(url, { data });
}

export async function getCategorySettingById({ queryKey }) {
  const [_key, { categoryId }] = queryKey;
  const url = `ac/confluence/categories/setting?categorieId=${categoryId}`;
  return httpClient.get(url);
}

export async function changeCategorySubscriptionForUser({ categoryId, action }) {
  const url = `ac/confluence/categories/category/status`;
  return httpClient.post(url, { categoryId, action });
}

export async function updateCategorySettingById(categoryId, newData) {
  const url = `ac/confluence/categories/settings/${categoryId}`;
  return httpClient.put(url, { newData });
}

export async function getProposalsSettings({ queryKey }) {
  const url = "ac/confluence/proposals/settings";
  return httpClient.get(url);
}

export async function saveProposalsSettings(data) {
  const url = "ac/confluence/proposals/settings/save";
  return httpClient.post(url, { data });
}

export async function getProposalSettingById({ queryKey }) {
  const [_key, { proposalId }] = queryKey;
  const url = `ac/confluence/proposals/setting?proposalId=${proposalId}`;
  return httpClient.get(url);
}

export async function searchBlogs({ selectedCreator, selectedSpaces, selectedLabels }, page = 0, pageSize = 25) {
  let allBlogs = [];

  const fetchBlogs = async (url) => {
    try {
      const response = await window.AP.request(url);
      const data = JSON.parse(response.body);
      const results = data.results || [];
      allBlogs = allBlogs.concat(results);
      if (data._links && data._links.next) {
        await fetchBlogs(data._links.next);
      }
    } catch (e) {
      console.log("Error fetching blogs:", e);
    }
  };

  let cql = "type = blogpost";

  if (selectedLabels.length > 0) {
    cql = cql + ` AND label in (${selectedLabels.map((l) => `"${l}"`).join(",")})`;
  }
  if (selectedCreator.length > 0) {
    cql = cql + ` AND contributor.accountid in (${selectedCreator.map((c) => `"${c}"`).join(",")})`;
  }
  if (selectedSpaces.length > 0) {
    cql = cql + ` AND space in (${selectedSpaces.map((s) => `"${s}"`).join(",")})`;
  }

  let url = `/rest/api/search?cql=${cql}&expand=content.history,content.space,content.metadata.labels,content.metadata.likes,content.children.comment`;

  if (page > 0) {
    url = `${url}&start=${page * pageSize}`;
  }

  try {
    await fetchBlogs(url);
    return { success: true, blogs: allBlogs };
  } catch (e) {
    console.log("Error fetching blog posts:", e);
    return { success: false, error: e.message };
  }
}

export const searchBlogpostsInDetails = ({ queryKey, pageParam }) => {
  const [
    _key,
    { categoryId = "", limit = 50, searchQuery = "", sorting = Sorting.CREATED, ordering = Ordering.DESCENDING },
  ] = queryKey;

  let cql = "type = blogpost";

  const categoriesCql = getCqlForCategories([categoryId]);

  if (categoriesCql) {
    cql = cql + ` AND ${categoriesCql}`;
  }
  if (searchQuery) {
    cql = cql + ` AND title~"${searchQuery}*"`;
  }
  if (sorting === Sorting.CREATED) {
    cql = cql + ` ORDER BY created`;
  }
  if (sorting === Sorting.MODIFIED) {
    cql = cql + ` ORDER BY lastmodified`;
  }
  if (sorting === Sorting.TITLE) {
    cql = cql + ` ORDER BY title`;
  }
  if (ordering === Ordering.ASCENDING) {
    cql = cql + ` asc`;
  }
  if (ordering === Ordering.DESCENDING) {
    cql = cql + ` desc`;
  }

  cql = encodeURI(cql);

  let url = `/rest/api/search?cql=${cql}&limit=${limit}&expand=content.history,content.space,content.metadata.labels,content.metadata.likes,content.children.comment,content.space.icon`;

  if (pageParam) {
    url = `${url}&cursor=${decodeURI(pageParam)}`;
  }
  try {
    return window.AP.request(url);
  } catch (e) {
    console.log("cant fetch blogposts");
    console.log(e);
  }
};
