import React from "react";
import EditorAddIcon from "@atlaskit/icon/glyph/editor/add";
import { token } from "@atlaskit/tokens";
import { Box, Flex, Text, xcss } from "@atlaskit/primitives";
import { useNavigationStore, useSidebarStore } from "../../../store";
import { DrawerTypes } from "../../drawer/types";
import { generateDefaultEntriesForParentEntry } from "../../../functions/generateNewEntry";

const boxStyle = xcss({
  cursor: "pointer",
  borderRadius: "3px",
  background: token("color.background.information"),

  ":hover": {
    background: token("color.background.selected.hovered"),
  },

  ":active": {
    background: token("color.background.selected.pressed"),
  },
});

const subentryBoxStyle = xcss({
  cursor: "pointer",
  borderRadius: "3px",
});

function AddOption({ isChild = false, parentEntry = undefined }) {
  const { createNewSubentry, setEditingEntry } = useNavigationStore((state) => ({
    createNewSubentry: state.createNewSubentry,
    setEditingEntry: state.setEditingEntry,
  }));

  const { setIsOpen, setSelectedSidebarType } = useSidebarStore((state) => ({
    setIsOpen: state.setIsOpen,
    setSelectedSidebarType: state.setSelectedSidebarType,
  }));

  return (
    <Box
      xcss={isChild ? subentryBoxStyle : boxStyle}
      padding="space.100"
      onClick={() => {
        if (!isChild) {
          setIsOpen(true);
          setSelectedSidebarType(DrawerTypes.ADD_ENTRY);
        } else {
          const { menuStyle } = parentEntry;
          const entries = generateDefaultEntriesForParentEntry(menuStyle, parentEntry.id);
          const randomEntryIndex = Math.floor(Math.random() * entries.length);
          const newEntry = entries[randomEntryIndex];
          createNewSubentry(newEntry, parentEntry.id);
          setEditingEntry(newEntry);
          setIsOpen(true);
          setSelectedSidebarType(DrawerTypes.EDIT_ENTRY);
        }
      }}
    >
      <Flex alignItems="center" columnGap="space.100">
        <EditorAddIcon primaryColor={token("color.text.brand")} />
        <Text weight={isChild ? "regular" : "medium"} size={isChild ? "small" : "medium"} color="color.text.brand">
          {isChild ? "Add entry" : "Add Menu Option"}
        </Text>
      </Flex>
    </Box>
  );
}

export default AddOption;
