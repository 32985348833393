import React, { useState } from "react";
import Popup from "@atlaskit/popup";
import Button from "@atlaskit/button";
import styled, { useTheme } from "styled-components";
import RefreshIcon from "@atlaskit/icon/glyph/refresh";
import ReSyncUserModal from "../sync/ReSyncUserModal";
import { useRecordsStore } from "../records-store";
import SortWrapper from "./SortWrapper";
import Filters from "./Filters";
import CrossIcon from "@atlaskit/icon/glyph/cross";
import WatchIcon from "@atlaskit/icon/glyph/watch";
import shallow from "zustand/shallow";
import { ChangeOperations } from "../change-handler";
import { AddColumn } from "./AddColumn";
import { AzureIntegration } from "./azure/AzureIntegration";
import { Users } from "./Users";
import ClearFilters from "./ClearFilters";

const MenuBarWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  padding-right: 16px;
`;

const MenuBarItemIcon = styled.div`
  margin-right: 10px;
  display: flex;
`;

const UpdateHiddenUsersWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #ebecf0;
  margin-right: 10px;
`;

const UpdateSelectionButtonWrapper = styled.div`
  border-radius: 0px;
  height: 50%;
  border-left: 2px solid black;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const CustomStyledButton = styled(Button)`
  &&& {
    font-size: 13px;
    font-weight: 550;
    text-align: left;
    padding-right: 16px;
    color: ${({ theme }) => theme.shared.settings.database.menuBarColor} !important;
    background-color: ${({ isSelected, theme }) =>
      isSelected ? theme.manualOrgChart.buttonColorActive : theme.manualOrgChart.buttonColor};

    &:hover {
      background-color: ${({ theme }) => theme.manualOrgChart.buttonColorActive};
    }
  }
`;

const MenuBarItem = ({ setIsOpen, isOpen, icon, children, onOpenCallback = () => {} }) => {
  const theme = useTheme();
  return (
    <CustomStyledButton
      isSelected={isOpen}
      iconBefore={icon}
      onClick={() => {
        setIsOpen(!isOpen);
        onOpenCallback();
      }}
    >
      <span style={{ color: theme.shared.settings.database.menuBarColor }}>{children}</span>
    </CustomStyledButton>
  );
};

const Sort = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Popup
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      placement="bottom"
      content={() => <SortWrapper />}
      trigger={(triggerProps) => (
        <div {...triggerProps}>
          <MenuBarItem
            setIsOpen={setIsOpen}
            isOpen={isOpen}
            icon={
              <MenuBarItemIcon>
                <svg width="13" height="12">
                  <g stroke={isOpen ? "#fff" : "#344563"} strokeWidth="1.4">
                    <path d="M12.02 9c-.42.42-.58.62-1.97 2.04L7.92 9" />
                    <path d="M1 2.92c.41-.43.58-.62 1.97-2.05L5 2.82" />
                    <path d="M3.04 11.13V1.72" />
                    <path d="M9.98.87v9.32" />
                  </g>
                </svg>
              </MenuBarItemIcon>
            }
          >
            Sort
          </MenuBarItem>
        </div>
      )}
    />
  );
};

const ReSyncUsersTrigger = () => {
  const [isOpen, setIsOpen] = useState(false);
  const setLastResync = useRecordsStore((state) => state.setLastResync);

  const theme = useTheme();

  return (
    <>
      <ReSyncUserModal visible={isOpen} onClose={() => setIsOpen(false)} onSyncFinished={() => setLastResync()} />
      <MenuBarItem
        setIsOpen={setIsOpen}
        isOpen={isOpen}
        icon={
          <MenuBarItemIcon>
            <RefreshIcon size="small" primaryColor={theme.shared.settings.database.menuBarIcons} />
          </MenuBarItemIcon>
        }
      >
        Sync
      </MenuBarItem>
    </>
  );
};

const ToggleHiddenUsersTrigger = () => {
  const { hiddenUsersSelection, setHiddenUsersSelection, numberOfHiddenUsers } = useRecordsStore((state) => {
    return {
      hiddenUsersSelection: state.hiddenUsersSelection,
      setHiddenUsersSelection: state.setHiddenUsersSelection,
      numberOfHiddenUsers: state.numberOfHiddenUsers,
    };
  }, shallow);

  const [isOpen, setIsOpen] = useState(hiddenUsersSelection.selectionType === 1);

  return (
    <>
      <MenuBarItem
        setIsOpen={setIsOpen}
        isOpen={isOpen}
        icon={
          <MenuBarItemIcon>
            <WatchIcon size="small" />
          </MenuBarItemIcon>
        }
        onOpenCallback={() => {
          const newState = {
            selectionType: 1 - hiddenUsersSelection.selectionType,
            selectedUsers: [],
          };
          setHiddenUsersSelection(newState);
        }}
      >
        {numberOfHiddenUsers || "0"}
      </MenuBarItem>
    </>
  );
};

const UpdateHiddenStatusTrigger = () => {
  const { hiddenUsersSelection, setHiddenUsersSelection, addChange, applyChangeInUI } = useRecordsStore((state) => {
    return {
      hiddenUsersSelection: state.hiddenUsersSelection,
      setHiddenUsersSelection: state.setHiddenUsersSelection,
      addChange: state.addChange,
      applyChangeInUI: state.applyRecordChange,
    };
  }, shallow);

  return (
    <UpdateHiddenUsersWrapper
      style={{
        display: hiddenUsersSelection.selectedUsers.length ? "flex" : "none",
      }}
    >
      <Button
        appearance="subtle"
        isSelected={false}
        iconBefore={
          <MenuBarItemIcon>
            <CrossIcon size="small" />
          </MenuBarItemIcon>
        }
        onClick={() => {
          setHiddenUsersSelection({ selectionType: hiddenUsersSelection.selectionType, selectedUsers: [] });
        }}
        style={{ fontSize: "12px", fontWeight: 600, textAlign: "left", color: "#344563" }}
      >
        {`${hiddenUsersSelection.selectedUsers.length} Selected`}
      </Button>
      <UpdateSelectionButtonWrapper>
        <Button
          appearance="subtle"
          isSelected={false}
          onClick={() => {
            hiddenUsersSelection.selectedUsers.forEach((user) => {
              const change = {
                operation: ChangeOperations.UPDATE_RECORDS,
                accountId: user,
                values: {
                  hidden: hiddenUsersSelection.selectionType === 0,
                },
              };
              addChange(change);
              applyChangeInUI(user, change);
            });
            setHiddenUsersSelection({ selectionType: hiddenUsersSelection.selectionType, selectedUsers: [] });
          }}
          style={{ fontSize: "12px", fontWeight: 600, textAlign: "left", color: "#344563" }}
        >
          {`${hiddenUsersSelection.selectionType === 0 ? "Hide" : "Unhide"} selected`}
        </Button>
      </UpdateSelectionButtonWrapper>
    </UpdateHiddenUsersWrapper>
  );
};

const MenuBar = ({ records }) => {
  const theme = useTheme();

  return (
    <MenuBarWrapper>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Users records={records} />
        <div style={{ width: "1px", height: "32px", background: theme.shared.settings.database.border }}></div>
        <Filters />
        <ClearFilters />
        <ReSyncUsersTrigger />
      </div>

      <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
        <AzureIntegration />

        <AddColumn />
      </div>

      {/*<UpdateHiddenStatusTrigger /> */}
      {/*<ToggleHiddenUsersTrigger />*/}
      {/*
        <Sort />
      */}
    </MenuBarWrapper>
  );
};

export default MenuBar;
