import React, { useCallback, useContext, useEffect, useMemo } from "react";
import styled from "styled-components";
import Tooltip from "@atlaskit/tooltip";
import UnlockFilledIcon from "@atlaskit/icon/glyph/unlock-filled";
import LockFilledIcon from "@atlaskit/icon/glyph/lock-filled";
import SidebarTransition from "../../../../../../../Shared/Components/SidebarTransition";
import { usePanelsStore } from "../../../store/panels-store";
import { useChangesStore } from "../../../../../track-changes-store";
import { DashboardAPIContext } from "../../../context";
import { useSidebarStore } from "./sidebar-store";
import { SIDEBAR_TYPES } from "./types";
import ContentSelector from "./components/ContentSelector";
import PanelsList from "./components/PanelsList";
import EditPanel from "./components/EditPanel";
import AddPanels from "./components/AddPanels";
import Permissions from "../../../../Shared/Panels/shared/components/Permissions";
import { PanelTypes } from "../../panelTypes";

const CustomIconContainer = styled.div`
  width: 24px;
  height: 24px;
  cursor: pointer;
  display: grid;
  place-content: center;
  border-radius: 3px;

  transition: background 150ms linear;

  &:hover {
    background-color: ${({ bgColor }) => `${bgColor}1a`};
  }
`;

const PermissionsIcon = ({ handleClick }) => {
  const editingPanel = usePanelsStore((state) => state.editingPanel);

  const { permissions: collections, filters } = editingPanel || {};

  const hasPermissionsSet = !!collections?.length || !!filters?.length;

  return (
    <Tooltip position="auto" content={hasPermissionsSet ? "View restrictions applied" : "No view restrictions"}>
      <CustomIconContainer bgColor={hasPermissionsSet ? "#ff0000" : "#0065ff"} onClick={() => handleClick()}>
        {hasPermissionsSet ? <LockFilledIcon primaryColor="red" /> : <UnlockFilledIcon primaryColor="#0065ff" />}
      </CustomIconContainer>
    </Tooltip>
  );
};

function Sidebar() {
  const { isSidebarOpen, setIsSidebarOpen, editingPanel, setEditingPanel, isEditingAppIcon } = usePanelsStore(
    (state) => ({
      isSidebarOpen: state.isSidebarOpen,
      setIsSidebarOpen: state.setIsSidebarOpen,
      editingPanel: state.editingPanel,
      setEditingPanel: state.setEditingPanel,
      isEditingAppIcon: state.isEditingAppIcon,
    }),
  );

  const {
    selectedContentType,
    setSelectedContentType,
    sidebarType,
    setSidebarType,
    disableClickOutside,
    setDisableClickOutside,
    isEditingCardsConfig,
  } = useSidebarStore((state) => ({
    selectedContentType: state.selectedContentType,
    setSelectedContentType: state.setSelectedContentType,
    sidebarType: state.sidebarType,
    setSidebarType: state.setSidebarType,
    disableClickOutside: state.disableClickOutside,
    setDisableClickOutside: state.setDisableClickOutside,
    isEditingCardsConfig: state.isEditingCardsConfig,
  }));

  const { hasChanges, setHasChanges } = useChangesStore((state) => ({
    hasChanges: state.hasChanges,
    setHasChanges: state.setHasChanges,
  }));

  const { publishDashboard } = useContext(DashboardAPIContext);

  const handleCloseSidebar = () => {
    setIsSidebarOpen(false);
    setSelectedContentType(undefined);
    setSidebarType(SIDEBAR_TYPES.CONTENT_SELECTOR);
    setEditingPanel(undefined);
  };

  const handleArrowBack = useCallback(() => {
    const previousSidebar = {
      [SIDEBAR_TYPES.PANEL_LIST]: SIDEBAR_TYPES.CONTENT_SELECTOR,
      [SIDEBAR_TYPES.PANEL_EDIT]: SIDEBAR_TYPES.PANEL_LIST,
      [SIDEBAR_TYPES.ADD_NEW_PANEL]: SIDEBAR_TYPES.PANEL_LIST,
      [SIDEBAR_TYPES.PERMISSIONS]: SIDEBAR_TYPES.PANEL_EDIT,
    }[sidebarType];
    setSidebarType(previousSidebar);
  }, [sidebarType]);

  const SidebarContent = useMemo(
    () =>
      ({
        [SIDEBAR_TYPES.CONTENT_SELECTOR]: ContentSelector,
        [SIDEBAR_TYPES.PANEL_LIST]: PanelsList,
        [SIDEBAR_TYPES.PANEL_EDIT]: EditPanel,
        [SIDEBAR_TYPES.ADD_NEW_PANEL]: AddPanels,
        [SIDEBAR_TYPES.PERMISSIONS]: () => (
          <Permissions usePanelsStore={usePanelsStore} setDisableClickOutside={setDisableClickOutside} />
        ),
      })[sidebarType],
    [sidebarType],
  );

  const getSidebarTitle = useCallback(() => {
    if (sidebarType === SIDEBAR_TYPES.CONTENT_SELECTOR) return "Customize Dashboard";
    if (sidebarType === SIDEBAR_TYPES.PANEL_LIST)
      return `${selectedContentType.charAt(0).toUpperCase()}${selectedContentType.slice(1)} Content`;
    if (sidebarType === SIDEBAR_TYPES.PANEL_EDIT) return editingPanel?.panelTitle ?? "Panel";
    if (sidebarType === SIDEBAR_TYPES.PERMISSIONS) return "Permissions";
    if (sidebarType === SIDEBAR_TYPES.ADD_NEW_PANEL) return "Add New Widget";
  }, [sidebarType, selectedContentType, editingPanel]);

  return (
    <SidebarTransition
      isOpen={isSidebarOpen}
      close={handleCloseSidebar}
      width={360}
      title={getSidebarTitle()}
      subtitle={sidebarType === SIDEBAR_TYPES.PERMISSIONS && editingPanel?.panelTitle}
      disabledClickOutside={isEditingAppIcon || disableClickOutside || isEditingCardsConfig}
      customScrollbarHeight="calc(100% - 55px)"
      isPanelList={sidebarType === SIDEBAR_TYPES.PANEL_LIST}
      useArrowBack={sidebarType !== SIDEBAR_TYPES.CONTENT_SELECTOR}
      onArrowBackClick={() => handleArrowBack()}
      hasChanges={hasChanges}
      onSave={() => {
        publishDashboard();
        setHasChanges(false);
      }}
      showExtraIcon={sidebarType === SIDEBAR_TYPES.PANEL_EDIT && editingPanel?.type !== PanelTypes.USER_START}
      ExtraIcon={PermissionsIcon}
      handleClickOnExtraIcon={() => setSidebarType(SIDEBAR_TYPES.PERMISSIONS)}
    >
      <SidebarContent />
    </SidebarTransition>
  );
}

export default Sidebar;
