import dayjs from "dayjs";
import { FieldType } from "../../../UserDatabase/field-type";

const handleSelectField = (field, storedUserValue) => {
  const isMulti = field.type === FieldType.MULTISELECT;

  if (field.key.startsWith("azure")) {
    if (field.key === "azurePreferredLanguage") {
      return new Intl.DisplayNames(["en"], { type: "language" }).of(storedUserValue);
    }

    return storedUserValue;
  }

  if (isMulti) {
    const optionValues = [];
    storedUserValue.forEach((optionId) => {
      const foundOption = field.options.find((option) => option.id === optionId);
      if (foundOption?.label) {
        optionValues.push(foundOption.label);
      }
    });
    return optionValues.join(", ");
  }

  const foundOption = field.options.find((option) => option.id === storedUserValue);
  return foundOption?.label || "";
};

const handleTextField = (field, storedUserValue) => storedUserValue;

const handleNumberField = (field, storedUserValue) => {
  if (!storedUserValue) return "";

  if (field.format === "decimal") {
    return storedUserValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  if (field.format === "integer") {
    return storedUserValue.replace(/\D/g, "");
  }
  if (field.format === "percent") {
    return `${storedUserValue} %`;
  }
  return storedUserValue || "";
};

const handleDateField = (field, storedUserValue) => {
  if (storedUserValue) {
    return dayjs.unix(storedUserValue).format(field?.recurring ? `MMM D` : `MMM D, YYYY`);
  }
  return "";
};

const handleUndefinedField = () => "";

export const getUserValueFromField = (userValues, fields, fieldId) => {
  const storedValueById = userValues[`${fieldId}`];
  const storedField = fields?.find((field) => field.id === fieldId);

  if (!storedValueById) return "";

  const fieldHandler = {
    [FieldType.SELECT]: handleSelectField,
    [FieldType.MULTISELECT]: handleSelectField,
    [FieldType.TEXT]: handleTextField,
    [FieldType.NUMBER]: handleNumberField,
    [FieldType.URL]: handleTextField,
    [FieldType.DATE]: handleDateField,
    [undefined]: handleUndefinedField,
  };

  return fieldHandler[storedField?.type](storedField, storedValueById);
};
