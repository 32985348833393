import { atlassianRestService } from "../../../../../../../Service/AtlassianRestService";

export const getUsersFromProposals = async (allProposals) => {
  if (!allProposals?.length) return [];

  const extractedUsers = [...new Set(allProposals.map(({ accountId }) => accountId))];

  try {
    const users = await atlassianRestService.fetchUsers(extractedUsers);
    return users || [];
  } catch (error) {
    return [];
  }
};

export const getNumberOfBlogsInCategories = async (allCategories) => {
  if (!allCategories?.length) return [];

  try {
    const promises = allCategories.map(async ({ id }) => {
      try {
        const request = await window.AP.request(
          encodeURI(`/rest/api/search?cql=type=blogpost AND content.property[cosmosnewsmetadata].categories~"${id}"`),
        );
        if (request?.body) {
          const data = JSON.parse(request.body);
          return { id, numberOfBlogs: data.totalSize };
        }
        return { id, numberOfBlogs: 0 };
      } catch (error) {
        return { id, numberOfBlogs: undefined };
      }
    });
    const resolved = await Promise.all(promises);
    return resolved || [];
  } catch (error) {
    return [];
  }
};
