import React, { Fragment, useEffect, useState } from "react";
import {
  addCurrentUserAsSpaceWatcher,
  getCurrentUserSpaceWatchStatus,
  removeCurrentUserAsSpaceWatcher,
} from "../../../../SpaceDirectory/apiSpaces";
import styled, { useTheme } from "styled-components";
import WatchIcon from "@atlaskit/icon/glyph/watch";
import WatchFilledIcon from "@atlaskit/icon/glyph/watch-filled";

const IconContainer = styled.div`
  display: grid;
  place-content: center;
  cursor: pointer;
  position: ${({ view }) => (view === "list" ? "relative" : "absolute")};
  right: ${({ view }) => (view === "list" ? "20px" : "0px")};
  top: ${({ view }) => (view === "list" ? "0px" : "20px")};
`;

export const Actions = ({ data, view }) => {
  const [isError, setIsError] = useState(false);
  const [isWatching, setIsWatching] = useState(false);
  const theme = useTheme();
  useEffect(() => {
    getCurrentUserSpaceWatchStatus(data.key)
      .then((res) => {
        if (res.body) {
          const body = JSON.parse(res.body);
          setIsWatching(body.watching);
        }
      })
      .catch((err) => setIsError(true));
  }, []);

  const removeWatcher = () => {
    setIsWatching(false);
    removeCurrentUserAsSpaceWatcher(data.key)
      .then((res) => {
        if (!res?.data?.success) {
          setIsWatching(true);
        }
      })
      .catch((e) => setIsError(true));
  };

  const addWatcher = () => {
    setIsWatching(true);
    addCurrentUserAsSpaceWatcher(data.key)
      .then((res) => {
        if (!res?.data?.success) {
          setIsWatching(false);
        }
      })
      .catch((e) => setIsError(true));
  };
  return (
    <>
      {!isError && (
        <IconContainer
          view={view}
          onClick={(e) => {
            e.preventDefault();
            isWatching ? removeWatcher() : addWatcher();
          }}
        >
          {isWatching ? (
            <WatchFilledIcon primaryColor={theme.poll.iconsSelected} />
          ) : (
            <WatchIcon primaryColor={theme.poll.icons} />
          )}
        </IconContainer>
      )}
    </>
  );
};
