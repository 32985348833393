import React, { useEffect, useState } from "react";
import PanelWrapper from "../../../shared/components/PanelWrapper";
import MainGridView from "./MainGridView";
import MainListView from "./MainListView";
import Pagination from "../../../../PanelsPagination/Pagination";
import EmptyState from "../../../../../../../../Shared/Components/EmptyState";
import NoDatabaseSync from "../../../shared/components/NoDatabaseSync";
import { SpinnerContainer } from "../../../../../../styled/pages";
import Spinner from "@atlaskit/spinner";
import { usePageStore } from "../../../../../../page-store";
import { Pages } from "../../../../../../pages";
import { PanelsPaginationTypes } from "../../../../PanelsPagination/panels-pagination-types";
import { useSidebarStore } from "../../../../../Dashboard/panels/edit/sidebar/sidebar-store";
import { useChangesStore } from "../../../../../../track-changes-store";
import { useTheme } from "styled-components";
import { messageByLoadingType } from "../People";

const ViewTypes = {
  CARD: "card",
  LIST: "list",
};

const viewComponents = {
  [ViewTypes.CARD]: MainGridView,
  [ViewTypes.LIST]: MainListView,
};

const MainView = ({
  panelTitle,
  data,
  isLoading,
  records,
  people,
  createProfileLink,
  borderRadius,
  pagination,
  hasInitDatabase,
}) => {
  const { page, setPage, maxPage, hasNextPage } = pagination;
  const [showMoreClicked, setShowMoreClicked] = useState(false);

  const setSelectedPage = usePageStore((state) => state.setSelectedPage);
  const setDisableClickOutside = useSidebarStore((state) => state.setDisableClickOutside);
  const hasChanges = useChangesStore((state) => state.hasChanges);

  const ViewComponent = viewComponents[data.view];

  const theme = useTheme();

  useEffect(() => {
    setShowMoreClicked(false);
  }, [data.pagination]);

  return (
    <PanelWrapper
      panelTitle={panelTitle}
      displayOptions={{
        displayTitle: data.displayTitle,
        displayBox: data.displayBox,
      }}
    >
      {isLoading && (
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
      )}

      {!hasInitDatabase && !isLoading && <NoDatabaseSync />}

      {!records?.length && !isLoading && hasInitDatabase && (
        <EmptyState
          title="No one to show"
          description={messageByLoadingType[data.loadingType]}
          Image={() => {
            return <img src={`/images/hub/no-people-empty-state-${theme.global.name}.svg`} alt="" />;
          }}
        />
      )}

      {!!records?.length && (
        <>
          <ViewComponent
            panelTitle={panelTitle}
            data={data}
            isLoading={isLoading}
            records={records}
            people={people}
            createProfileLink={createProfileLink}
            borderRadius={borderRadius}
            pagination={pagination}
          />
          <Pagination
            type={
              data.pagination === PanelsPaginationTypes.SHOW_MORE ? PanelsPaginationTypes.INFINITE : data.pagination
            }
            showMoreClicked={showMoreClicked}
            onClick={() => {
              if (hasNextPage && !showMoreClicked) {
                setPage(2);
                setShowMoreClicked(true);
                return;
              }

              setSelectedPage(Pages.PEOPLE);
            }}
            page={page}
            hasNextPage={hasNextPage}
            updatePage={setPage}
            maxPage={maxPage}
            onWrapperHoverCallback={setDisableClickOutside}
          />
        </>
      )}
    </PanelWrapper>
  );
};

export default MainView;
