export const textColorBasedOnBackgroundColor = (bgColor, lightColor = "#ffffff", darkColor = "#000000") => {
  const color = bgColor.charAt(0) === "#" ? bgColor.substring(1, 7) : bgColor;

  const r = parseInt(color.substring(0, 2), 16);
  const g = parseInt(color.substring(2, 4), 16);
  const b = parseInt(color.substring(4, 6), 16);

  const uicolors = [r / 255, g / 255, b / 255];
  const contrast = uicolors.map((col) => {
    if (col <= 0.03928) {
      return col / 12.92;
    }
    return Math.pow((col + 0.055) / 1.055, 2.4);
  });
  const L = 0.2126 * contrast[0] + 0.7152 * contrast[1] + 0.0722 * contrast[2];

  return L > 0.35 ? darkColor : lightColor;
};
