import React, { useState } from "react";
import { SelectLabel } from "../../Shared/CheckboxSelect/checkbox-select-styles";
import SpacesSimpleSelect from "../../Shared/SimpleSelect/SpacesSimpleSelect";
import CustomModal from "./CustomModal";

function CreateBlog({ isModalOpen, closeModal }) {
  const [selectedSpaceKey, setSelectedSpaceKey] = useState(null);
  const [selectedSpaces, setSelectedSpaces] = useState([]);

  return (
    isModalOpen && (
      <CustomModal
        heading="Create a blog"
        subheading="You can only publish blogs in a space you have access to."
        onCancel={closeModal}
        onNext={() => {
          window.open(
            `${window.AP._hostOrigin}/wiki/create-content/blog?spaceKey=${selectedSpaceKey}&source=blogNavContextualCreate`,
            "_blank",
          );
          closeModal();
        }}
        children={
          <div style={{ paddingTop: "5px" }}>
            <SelectLabel style={{ paddingBottom: "5px" }}>Space</SelectLabel>
            <SpacesSimpleSelect
              isMulti={false}
              selectedSpaces={selectedSpaces}
              setSelectedSpaces={(spaces) => {
                setSelectedSpaceKey(spaces.map((s) => s?.spaceKey));
                setSelectedSpaces(spaces);
              }}
              placeholder="Select a space"
            />
          </div>
        }
        disabled={!selectedSpaceKey}
      />
    )
  );
}

export default CreateBlog;
