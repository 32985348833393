export const AlertTypes = {
  ITEM_LIMIT: "item_limit",
  UPLOADING: "uploading",
  SUCCESS: "success",
  SIZE_LIMIT: "size_limit",
  ERROR: "error",
  OFF: "off",
};

export const AlertData = {
  [AlertTypes.ITEM_LIMIT]: {
    active: true,
    msg: "Number of files for upload has been exceed, maximum is 15.",
    status: "error",
  },
  [AlertTypes.UPLOADING]: {
    active: true,
    msg: "Files are uploading.",
    status: "loading",
  },
  [AlertTypes.SUCCESS]: {
    active: true,
    msg: "Upload successful!",
    status: "success",
  },
  [AlertTypes.SIZE_LIMIT]: {
    active: true,
    msg: "File size exceeded limit.",
    status: "error",
  },
  [AlertTypes.ERROR]: {
    active: true,
    msg: "Something went wrong",
    status: "error",
  },
  [AlertTypes.OFF]: {
    active: false,
    msg: "",
    status: undefined,
  },
};
