import {
  SquaredCloudIcon,
  SquaredImagePurpleIcon,
  SquaredLetterIcon,
  AnniversaryIcon,
} from "../../Dashboard/panels/images/PanelTypeIcons";
import { CakeIcon, ClockIcon, QuoteIcon, StarIcon, UsersIcon } from "./images/PanelTypeIcons";
import { PanelKeys, PanelTypes } from "./panelTypes";

export const AvailablePanels = [
  {
    key: PanelKeys.KNOW_YOUR_TEAM,
    dataKey: PanelKeys.KNOW_YOUR_TEAM,
    type: PanelTypes.TEAM,
    title: "Know your team",
    description: "Introduce your team members",
    Icon: UsersIcon,
  },
  {
    key: PanelKeys.UPCOMING_BIRTHDAY,
    dataKey: PanelKeys.UPCOMING_BIRTHDAY,
    type: PanelTypes.BIRTHDAY,
    title: "Upcoming birthdays",
    description: "Announce upcoming birthdays",
    Icon: CakeIcon,
  },
  {
    key: PanelKeys.RECENTLY_ACTIVE_USERS,
    dataKey: PanelKeys.RECENTLY_ACTIVE_USERS,
    type: PanelTypes.ACTIVE_USERS,
    title: "Recently active users",
    description: "Display recent activities on your site",
    Icon: ClockIcon,
  },
  {
    key: PanelKeys.WORK_ANNIVERSARY,
    dataKey: PanelKeys.WORK_ANNIVERSARY,
    type: PanelTypes.BIRTHDAY,
    title: "Work Anniversary",
    description: "Highlight employee anniversary",
    Icon: AnniversaryIcon,
  },
  {
    key: PanelKeys.NEW_STARTERS,
    dataKey: PanelKeys.NEW_STARTERS,
    type: PanelTypes.NEW_STARTERS,
    title: "New Starters",
    description: "Introduce new employees",
    Icon: StarIcon,
  },
  {
    key: PanelKeys.USER_BLOG_POSTS,
    dataKey: PanelKeys.USER_BLOG_POSTS,
    type: PanelTypes.BLOG,
    title: "User blog posts",
    description: "Display recent blog creators",
    Icon: QuoteIcon,
  },
  {
    key: PanelKeys.ICON_CARDS,
    dataKey: PanelKeys.ICON_CARDS,
    type: PanelTypes.CARDS,
    title: "Icon Cards",
    description: "Group information with cards",
    Icon: SquaredCloudIcon,
  },
  {
    key: PanelKeys.TEXT_CARDS,
    dataKey: PanelKeys.TEXT_CARDS,
    type: PanelTypes.CARDS,
    title: "Text Cards",
    description: "Group information with cards",
    Icon: SquaredLetterIcon,
  },
  {
    key: PanelKeys.IMG_CARDS,
    dataKey: PanelKeys.IMG_CARDS,
    type: PanelTypes.CARDS,
    title: "Image Cards",
    description: "Group information with cards",
    Icon: SquaredImagePurpleIcon,
  },
];
