import create from "zustand";

export const useCurrentUserStore = create((set, get) => ({
  currentUser: undefined,
  setCurrentUser: (userData) => set((state) => ({ currentUser: userData })),
  isCurrentUserAdmin: false,
  setIsCurrentUserAdmin: (value) => set((state) => ({ isCurrentUserAdmin: value })),
  isAnonymous: false,
  setIsAnonymous: (value) => set((state) => ({ isAnonymous: value })),
  isGuest: false,
  setIsGuest: (value) => set((state) => ({ isGuest: value })),

  isDbInit: false,
  setIsDbInit: (val) => set((state) => ({ isDbInit: val })),
}));
