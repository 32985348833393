import styled, { useTheme } from "styled-components";
import { useEffect, useState, useRef } from "react";
import { Panel, PanelHeader } from "../General";
import Button from "@atlaskit/button";
import { Radio } from "@atlaskit/radio";
import ImageEditor from "../BrandAndColors/ImageModal/ImageEditor";

import { saveCorporateIdentitySettings } from "../../../../api";
import { textColorBasedOnBackgroundColor } from "../../../../../../Shared/Functions/getColorBasedOnContrast";
import debounce from "lodash.debounce";

import { COLOR_TYPES, ICON_BG_SHAPE_TYPES } from "../BrandAndColors/color-types";
import { DEFAULT_VALUES } from "../BrandAndColors/defaults";

const ResetToDefault = styled.p`
  color: ${({ theme }) => theme.global.primaryColor};
  font-size: 14px;
  font-weight: 400;
  margin-top: 0;
  margin-left: auto;
  position: relative;
  bottom: 18px;
  cursor: pointer;
`;

const SectionTitle = styled.p`
  color: ${({ theme }) => theme.shared.settings.general.brandAndColors.sectionTitleColor};
  font-size: 14px;
  font-weight: 500;
  margin-top: 20px;
`;

const LogoWrapper = styled.div`
  width: 64px;
  height: 64px;
  border-radius: 3px;
  background: rgba(9, 30, 66, 0.06);

  display: flex;
  align-items: center;
  justify-content: center;
`;

const LogoPreview = styled.img`
  width: 48px;
  height: 48px;
`;

const LogoText = styled.p`
  color: ${({ theme }) => theme.shared.settings.general.brandAndColors.logoNameColor};
  font-size: 11px;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
`;

const LogoMessage = styled.p`
  color: ${({ theme }) => theme.shared.settings.general.brandAndColors.logoDescriptionColor};
  font-size: 11px;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
`;

const LogoContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const Divider = styled.div`
  height: 2px;
  background: ${({ theme }) => theme.shared.sidebar.divider};
  width: 100%;
  margin-bottom: 24px;
  margin-top: 12px;
`;

const IconWrapper = styled.div`
  display: flex;
  column-gap: 16px;
`;

const IconOptionWrapper = styled.div`
  width: 160px;
  height: 40px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  padding-left: 8px;
  cursor: pointer;

  label > span > span {
    cursor: pointer;
  }

  ${({ isChecked, primaryColor, theme }) =>
    isChecked
      ? `
      background-color: ${theme.shared.settings.general.brandAndColors.editLogoBackground};
      label > input:checked {
        --radio-background-color: ${theme.global.primaryColor} !important;
        --radio-border-color: ${theme.global.primaryColor} !important;
      }
    `
      : `
      background-color: transparent;
    `}
`;

const SHAPE_TYPES = [
  {
    value: ICON_BG_SHAPE_TYPES.CIRCLE,
    label: ICON_BG_SHAPE_TYPES.CIRCLE.toUpperCase(),
  },
  {
    value: ICON_BG_SHAPE_TYPES.SQUARE,
    label: ICON_BG_SHAPE_TYPES.SQUARE.toUpperCase(),
  },
  {
    value: ICON_BG_SHAPE_TYPES.NONE,
    label: ICON_BG_SHAPE_TYPES.NONE.toUpperCase(),
  },
];

function Sidebar({ colors, logo = "/images/logo.png", logoName, setLogo, setColors, setLogoName }) {
  const [modalOpen, setModalOpen] = useState(false);
  const [showReset, setShowReset] = useState(false);
  const theme = useTheme();

  const saveImage = (img) => {
    saveCorporateIdentitySettings({ logo: img })
      .then((res) => console.log(res))
      .catch(() => {});
  };

  const saveLogoName = (name) => {
    saveCorporateIdentitySettings({ logoName: name })
      .then(() => {})
      .catch(() => {});
  };

  const RadioLabel = ({ name, color }) => {
    const capitalizedLabel = name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
    return <span style={{ color: color, fontSize: "14px" }}>{capitalizedLabel}</span>;
  };

  const handleSaveDebounce = useRef(
    debounce(async (_colors) => {
      try {
        await saveCorporateIdentitySettings({ colors: _colors });
      } catch {
        console.log("failed to save colors");
      }
    }, 500),
  ).current;

  useEffect(() => {
    return () => {
      handleSaveDebounce.cancel();
    };
  }, [handleSaveDebounce]);

  const handleUpdateColors = (key, value) => {
    const primaryContrastColor =
      key === COLOR_TYPES.PRIMARY ? textColorBasedOnBackgroundColor(value) : colors[COLOR_TYPES.PRIMARY_CONTRAST];

    const newColors = { ...colors, [key]: value, [COLOR_TYPES.PRIMARY_CONTRAST]: primaryContrastColor };

    handleSaveDebounce(newColors);
    setColors(newColors);
  };

  const resetCorporateIdentityProperty = (propertyToReset) => {
    saveCorporateIdentitySettings({ [propertyToReset]: DEFAULT_VALUES[propertyToReset] })
      .then((res) => {
        if (res.status) {
          setLogo(undefined);
        }
      })
      .catch(() => {});
  };

  useEffect(() => {
    setShowReset(logo !== "/images/logo.png" || colors.icon_bg_shape_type !== ICON_BG_SHAPE_TYPES.CIRCLE);
  }, [logo, colors]);

  return (
    <>
      <Panel>
        <PanelHeader>
          <div>Sidebar</div>
          {showReset && (
            <ResetToDefault
              onClick={() => {
                resetCorporateIdentityProperty("logo");
                handleUpdateColors(COLOR_TYPES.ICON_BG_SHAPE_TYPES, ICON_BG_SHAPE_TYPES.CIRCLE);
              }}
            >
              Reset to default
            </ResetToDefault>
          )}
        </PanelHeader>

        <>
          <SectionTitle>Logo</SectionTitle>

          <LogoContainer>
            <LogoWrapper>
              <LogoPreview src={logo} alt="" />
            </LogoWrapper>

            <Button
              appearance="default"
              style={{
                width: "fit-content",
                height: "32px",
                fontSize: "14px",
                fontWeight: "500",
                marginTop: "16px",
                marginLeft: "21px",
                backgroundColor: theme.shared.settings.general.brandAndColors.editLogoBackground,
              }}
              onClick={() => {
                setModalOpen((prev) => !prev);
              }}
            >
              <span style={{ color: theme.shared.settings.general.brandAndColors.editLogoColor }}>Edit logo</span>
            </Button>
          </LogoContainer>

          <LogoMessage>{logo ? logoName : "cosmos.png"}</LogoMessage>

          <LogoText style={{ marginTop: "0px" }}>
            For optimal display, use squared logos with equal dimensions, as they will be constrained to a maximum of 48
            px.
          </LogoText>

          <Divider />

          <SectionTitle style={{ marginTop: "0px" }}>Icon Background</SectionTitle>

          <IconWrapper>
            {SHAPE_TYPES.map((type) => (
              <IconOptionWrapper
                isChecked={colors[COLOR_TYPES.ICON_BG_SHAPE_TYPES] === type.value}
                onClick={() => handleUpdateColors(COLOR_TYPES.ICON_BG_SHAPE_TYPES, type.value)}
                primaryColor={colors[COLOR_TYPES.PRIMARY]}
                key={type.label}
              >
                <Radio
                  label={
                    <RadioLabel name={type.label} color={theme.shared.settings.general.brandAndColors.logoNameColor} />
                  }
                  value={type.value}
                  isChecked={colors[COLOR_TYPES.ICON_BG_SHAPE_TYPES] === type.value}
                />
              </IconOptionWrapper>
            ))}
          </IconWrapper>
        </>
      </Panel>

      <ImageEditor
        isOpen={modalOpen}
        saveLogoName={saveLogoName}
        setLogoName={setLogoName}
        close={() => setModalOpen(false)}
        image={logo}
        setImage={(img) => {
          saveImage(img);
          setLogo(img);
        }}
        primaryColor={theme.global.primaryColor}
      />
    </>
  );
}

export default Sidebar;
