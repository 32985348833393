import create from "zustand";
import { BG_COLOR_TYPES, HEADER_TYPES, RADIUS_TYPES, RADIUS_VALUES } from "./types";

export const useCorporateIdentityStore = create((set, get) => ({
  logo: undefined,
  setLogo: (v) => set((state) => ({ logo: v })),
  logoName: undefined,
  setLogoName: (v) => set((state) => ({logoName: v})),
  colors: undefined,
  setColors: (v) => set((state) => ({ colors: v })),
  updateColors: (key, value) => {
    const existingColors = { ...get().colors };
    return set((state) => ({
      colors: {
        ...existingColors,
        [key]: value,
      },
    }));
  },
  backgroundColor: BG_COLOR_TYPES.WHITE,
  setBackgroundColor: (bgColor) => set((state) => ({ backgroundColor: bgColor })),
  headerType: HEADER_TYPES.FULL_WIDTH,
  setHeaderType: (_headerType) => set((state) => ({ headerType: _headerType })),
  borderRadius: RADIUS_VALUES[RADIUS_TYPES.SMALL],
  setBorderRadius: (radius) => set((state) => ({ borderRadius: radius })),
}));
