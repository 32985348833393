import React from "react";
import styled from "styled-components";
import TextArea from "@atlaskit/textarea";
import { FieldContainer, Label } from "./styled";

const TextAreaWrapper = styled.div`
  textarea {
    border-color: ${({ theme }) => theme.shared.form.input.border} !important;
    color: ${({ theme }) => theme.shared.form.input.text};
    background-color: ${({ theme }) => theme.shared.form.input.background} !important;

    &:focus {
      background-color: ${({ theme }) => theme.shared.form.input.background} !important;
    }

    &:not(:focus) {
      background-color: transparent !important;
    }

    &:hover {
      background-color: ${({ theme }) => theme.shared.form.input.hover} !important;
    }
  }
`;

function TextAreaFilter({ value, onUpdate, label, placeholder = "" }) {
  const handleChange = (e) => {
    onUpdate(e.target.value);
  };

  return (
    <FieldContainer>
      <Label>{label}</Label>
      <TextAreaWrapper>
        <TextArea resize="none" value={value} placeholder={placeholder} onChange={handleChange} minimumRows={5} />
      </TextAreaWrapper>
    </FieldContainer>
  );
}

export default TextAreaFilter;
