import { Fragment, useMemo, useState } from "react";
import { handleClickOnChildEntry } from "../cardLayoutService";
import {
  ChildEntry,
  ChildMenuWrapper,
  MenuContentPositioner,
  MenuContentWrapper,
  NewsDivider,
  NewsTitle,
  RootShortDescription,
} from "./styled";
import { useTheme } from "styled-components";
import { useQuery } from "react-query";
import NewsNavigationCard from "../NewsNavigationCard";
import NewsEmptyState from "../NewsEmptyState";
import { dark } from "../../../../../dark-colors";
import { light } from "../../../../../light-colors";
import { LoadingTypes } from "../../../../Hub/Components/Dashboard/filter/DynamicFilterToggle";
import { searchBlogposts } from "../../../../Hub/Components/Dashboard/api";
import { mapBlogpost } from "../../../../Hub/Components/Dashboard/panels/types/news/handleBlogData";
import {
  DEFAULT_SYDNEY_COLORS,
  DEFAULT_SYDNEY_COLORS_DARK,
  THEME_NAME_DARK,
  THEME_NAME_LIGHT,
} from "../../../functions/generateNewEntry";
import EntryIcon from "../../../configuration/drawer/components/edit/shared/EntryIcon";
import Pagination from "../../../../Hub/Components/Shared/PanelsPagination/Pagination";
import { PanelsPaginationTypes } from "../../../../Hub/Components/Shared/PanelsPagination/panels-pagination-types";

const themes = {
  dark,
  light,
};

function Sydney({ structure, selectedRootEntry, isPreview, openOverContent, themeColorsSet, temporaryColorScheme }) {
  const rootEntry = structure.find(({ id }) => id === selectedRootEntry);

  const [currentPage, setCurrentPage] = useState(1);

  const [isNewsEnabled, newsConfig] = useMemo(
    () => [!!rootEntry?.data?.isNewsEnabled, rootEntry?.data?.news || {}],
    [rootEntry],
  );

  const { isLoading, data: blogsData } = useQuery(
    [
      `blogposts-navigation`,
      {
        loadingType: newsConfig?.loadingType,
        manullyPicked: newsConfig?.manuallySelected,
        labels: newsConfig?.labels || [],
        contributors: newsConfig?.contributors || [],
        spaces: newsConfig?.spaces || [],
        limit: newsConfig?.loadingType === LoadingTypes.MANUAL ? newsConfig?.manuallySelected?.length || 0 : 20,
      },
    ],
    searchBlogposts,
    {
      enabled: isNewsEnabled,
      retry: 0,
      select: (response) => {
        const body = JSON.parse(response.body);
        const results = body.results.map((blog) => mapBlogpost(blog));
        return results;
      },
    },
  );

  const hasNewsFilters = !!newsConfig?.labels?.length || newsConfig?.contributors || newsConfig?.spaces;

  const theme = useTheme();

  const colors = useMemo(() => {
    if (!rootEntry) return {};
    if (themeColorsSet && theme.global.name === THEME_NAME_LIGHT) {
      return DEFAULT_SYDNEY_COLORS;
    } else if (themeColorsSet && theme.global.name === THEME_NAME_DARK) {
      return DEFAULT_SYDNEY_COLORS_DARK;
    } else {
      return rootEntry?.data?.colors;
    }
  }, [theme, rootEntry, structure, themeColorsSet]);

  return (
    <MenuContentWrapper
      backgroundColor={themes[temporaryColorScheme]?.navigation.sydney.menuContentBackground}
      openOverContent={openOverContent}
    >
      <MenuContentPositioner isNewsEnabled={isNewsEnabled} customTemplate="auto 285px">
        <div>
          {rootEntry?.data?.displayDescription && (
            <RootShortDescription
              descriptionTextColor={
                colors?.[temporaryColorScheme]?.descriptionTextColor ||
                themes[temporaryColorScheme]?.navigation.sydney.rootShortDescription
              }
            >
              {rootEntry?.data?.description}
            </RootShortDescription>
          )}

          <ChildMenuWrapper
            isPreview={isPreview}
            maxNumberOfColumns={isNewsEnabled ? 3 : 4}
            numberOfColumns={structure.filter((entry) => entry.parent === selectedRootEntry)?.length}
          >
            {structure
              .filter((entry) => entry.parent === selectedRootEntry)
              .map((entry) => (
                <ChildEntry
                  key={entry.id}
                  subentryIconColor={colors?.[temporaryColorScheme]?.subentryIconColor || "#42526e"}
                  subentryTextColor={
                    colors?.[temporaryColorScheme]?.subentryTextColor === undefined ||
                    colors?.[temporaryColorScheme]?.subentryTextColor === "#42526e"
                      ? themes[temporaryColorScheme]?.navigation.london.menuContentTitle
                      : colors?.[temporaryColorScheme]?.subentryTextColor
                  }
                  subentryBackgroundHoverColor={
                    colors?.[temporaryColorScheme]?.subentryBackgroundHoverColor === undefined ||
                    colors?.[temporaryColorScheme]?.subentryBackgroundHoverColor === "0000000"
                      ? themes[temporaryColorScheme]?.navigation.sydney.childEntryBackgroundHover
                      : colors?.[temporaryColorScheme]?.subentryBackgroundHoverColor
                  }
                  subentryTextIconHover={colors?.[temporaryColorScheme]?.subentryTextIconHover}
                  backgroundColor={{
                    inactive: themes[temporaryColorScheme]?.navigation.sydney.childEntryBackground,
                    hover: themes[temporaryColorScheme]?.navigation.sydney.childEntryBackgroundHover,
                  }}
                  borderColor={{
                    hover: themes[temporaryColorScheme]?.navigation.sydney.childEntryBackgroundHoverBorder,
                  }}
                  displayIcons={rootEntry.data.displayIcons}
                  onClick={() => handleClickOnChildEntry(entry, isPreview)}
                >
                  {rootEntry.data.displayIcons && (
                    <div className="child-icon-bg-container" style={{ display: "grid", placeItems: "center" }}>
                      {(entry.data.icon.type === "icon" || !entry.data.icon.type) && (
                        <div className="child-icon-container">
                          <EntryIcon icon={entry.data.icon} />
                        </div>
                      )}
                      {entry.data.icon.type === "app" && (
                        <img src={entry.data.icon.icon} alt="" width={24} height={24} />
                      )}
                      {entry.data.icon.type === "emoji" && (
                        <div style={{ height: "20px", lineHeight: "1" }}>
                          <em-emoji shortcodes={entry?.data?.icon?.shortcodes} size="1.2em"></em-emoji>
                        </div>
                      )}
                    </div>
                  )}
                  <div>
                    <div className="child-name">{entry.data.name}</div>
                    <div className="child-description">{entry.data.description}</div>
                  </div>
                </ChildEntry>
              ))}
          </ChildMenuWrapper>
        </div>

        {isNewsEnabled && (
          <div>
            <NewsTitle
              style={{ marginLeft: "38px" }}
              descriptionTextColor={
                colors?.[temporaryColorScheme]?.descriptionTextColor ||
                themes[temporaryColorScheme]?.navigation.sydney.rootShortDescription
              }
            >
              Featured news
            </NewsTitle>

            <NewsDivider>
              {!isLoading && !!blogsData?.length ? (
                blogsData.slice(currentPage - 1, currentPage).map((blog) => (
                  <Fragment key={blog.id}>
                    <NewsNavigationCard temporaryColorScheme={temporaryColorScheme} key={blog.id} data={blog} />
                    {!!(blogsData?.length > 1) && (
                      <Pagination
                        type={PanelsPaginationTypes.ARROWS}
                        page={currentPage}
                        maxPage={blogsData.length}
                        updatePage={(newPage) => setCurrentPage(newPage)}
                        customWrapperStyles={{ placeContent: "flex-start", marginTop: "12px", marginLeft: "-5px" }}
                        customPrimaryColor={"#0065ff"}
                      />
                    )}
                  </Fragment>
                ))
              ) : (
                <NewsEmptyState hasNewsFilters={hasNewsFilters} newsConfig={newsConfig} />
              )}
            </NewsDivider>
          </div>
        )}
      </MenuContentPositioner>
    </MenuContentWrapper>
  );
}

export default Sydney;
