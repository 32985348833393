import { useQuery } from "react-query";
import { fetchCollections } from "../../Components/Collections/api";

const useSavedCollections = ({ collectionId, areOptions }) => {
  const { isLoading: collectionsLoading, data: collectionsData } = useQuery("saved-collections", fetchCollections, {
    cacheTime: 0,
    retry: 0,
    select: (response) => {
      const { data: responseData } = response;

      if (!areOptions) {
        const collection = responseData?.find((collection) => collection.id === collectionId);
        return {
          filters: collection?.filters || [],
          userGroup: collection?.userGroup || "",
        };
      }

      return { collections: responseData || [] };
    },
  });

  return {
    collectionsLoading,
    collectionsData: collectionsData || [],
  };
};

export default useSavedCollections;
