import React from "react";
import { CoverStory } from "@caelor/cards-and-panels-components";
import { MagazineGridContainer } from "../styled/newsroom";
import InfiniteScroller from "../../../../../Shared/Components/InfiniteScroller";
import { NewsElements, NewsViews } from "../defaults";
import { useNewsroomStore } from "../newsroom-store";
import { SettingPropertyKeys } from "../Sidebar/NewsDesign";

function MagazineView({ data, fetchNextPage, hasNextPage, borderRadius }) {
  const settings = useNewsroomStore((state) => state.settings);

  const getMagazineSettingsData = (prop) => settings[NewsViews.MAGAZINE][prop];

  return (
    <InfiniteScroller
      dataLength={data?.pages?.length || 0}
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage}
      endMessage=""
    >
      {data?.pages?.map((page, index) => (
        <div
          key={index}
          style={{
            marginBottom: `${getMagazineSettingsData(SettingPropertyKeys.GRID_GAP)}px`,
          }}
        >
          <MagazineGridContainer
            isLargeStory={index === 0}
            gridGap={getMagazineSettingsData(SettingPropertyKeys.GRID_GAP)}
          >
            {page.results.map((blog, idx) => (
              <div
                key={blog.id}
                className={
                  index % 2 === 0
                    ? `grid-large-story-child-${idx + 1} child`
                    : `grid-large-story-alt-child-${idx + 1} child`
                }
              >
                <CoverStory
                  coverPictureCallback={blog.coverPictureCallback}
                  name={blog.name}
                  getBlogsCategories={
                    getMagazineSettingsData(SettingPropertyKeys.ACTIVE_ELEMENTS).includes(NewsElements.CATEGORIES)
                      ? blog.getBlogsCategories
                      : undefined
                  }
                  space={
                    getMagazineSettingsData(SettingPropertyKeys.ACTIVE_ELEMENTS).includes(NewsElements.SPACE)
                      ? blog.space
                      : []
                  }
                  url={blog.url}
                  excerpt={
                    getMagazineSettingsData(SettingPropertyKeys.ACTIVE_ELEMENTS).includes(NewsElements.DESCRIPTION)
                      ? blog.excerpt
                      : null
                  }
                  labels={
                    getMagazineSettingsData(SettingPropertyKeys.ACTIVE_ELEMENTS).includes(NewsElements.LABEL)
                      ? blog.labels
                      : []
                  }
                  createdBy={
                    getMagazineSettingsData(SettingPropertyKeys.ACTIVE_ELEMENTS).includes(NewsElements.AUTHOR)
                      ? blog.createdBy
                      : null
                  }
                  lastModified={
                    getMagazineSettingsData(SettingPropertyKeys.ACTIVE_ELEMENTS).includes(NewsElements.DATE)
                      ? blog.createdFormatted
                      : null
                  }
                  avatarUrl={blog.avatarUrl}
                  labelsBackground={getMagazineSettingsData(SettingPropertyKeys.LABELS_BACKGROUND)}
                  coverPictureBorderRadius={borderRadius.replace("px", "")}
                  labelsBorderRadius={borderRadius}
                />
              </div>
            ))}
          </MagazineGridContainer>
        </div>
      ))}
    </InfiniteScroller>
  );
}

export default MagazineView;
