import React, { useEffect, useState } from "react";
import Button, { LoadingButton } from "@atlaskit/button";
import styled from "styled-components";
import Content from "./Content";
import Tabs from "./Components/Tabs";
import { DEFAULT_IMAGE, useImageStore, useNewsMetadataStore } from "./news-metadata-store";
import { useQuery, useQueryClient } from "react-query";
import { fetchIsInitialized } from "../Components/UserDatabase/datagrid/api";
import { useCurrentUserStore } from "../Components/Hub/current-user-store";
import { checkIfUserIsCosmosAdmin } from "../Components/Hub/api";
import { contentPropertyService } from "../Service/ContentPropertyService";
import { getCollectionsByTargetedBlog } from "../Components/Collections/api";
import { userBlogpostPermissions } from "./api";
import { getCategoriesSettings } from "../Components/Hub/Components/Settings/News/api";
import { saveCategories, saveCollections, saveImage } from "./helpers/save-handlers";

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.newsMetadataDialog.background};
  width: 500px;
  height: 660px;
  border-radius: 5px;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
`;

const TitleWrapper = styled.div`
  width: 500px;
  padding: 24px 24px 16px 24px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
`;

const Title = styled.div`
  color: ${({ theme }) => `${theme.newsMetadataDialog.title}`};
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 120% */
`;

const Footer = styled.div`
  position: absolute;
  bottom: 20px;
  width: 480px;
  background: ${({ theme }) => `${theme.newsMetadataDialog.background}`};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 5px;
`;

function InsertNewsMetaDataDialog() {
  const [isSaving, setIsSaving] = useState(false);

  const {
    setAllCategories,
    blogCategories,
    setBlogCategories,
    newsMetadataPropertyId,
    newsMetadataVersion,
    setNewsMetadataPropertyId,
    setNewsMetadataVersion,
    newsMetadataPropertyExists,
    setNewsMetadataPropertyExists,
    setIsLoadingMetadata,
  } = useNewsMetadataStore((state) => ({
    allCategories: state.allCategories,
    setAllCategories: state.setAllCategories,
    blogCategories: state.blogCategories,
    setBlogCategories: state.setBlogCategories,
    newsMetadataPropertyId: state.propertyId,
    newsMetadataVersion: state.version,
    setNewsMetadataPropertyId: state.setPropertyId,
    setNewsMetadataVersion: state.setVersion,
    newsMetadataPropertyExists: state.propertyExists,
    setNewsMetadataPropertyExists: state.setPropertyExists,
    setIsLoadingMetadata: state.setIsLoadingMetadata,
  }));

  const {
    imageData,
    setImageData,
    imagePropertyId,
    setImagePropertyId,
    imagePropertyVersion,
    setImagePropertyVersion,
    imagePropertyExists,
    setImagePropertyExists,
    setIsLoadingImage,
  } = useImageStore((state) => ({
    setImageData: state.setImageData,
    imageData: state.imageData,
    imagePropertyId: state.propertyId,
    setImagePropertyId: state.setPropertyId,
    imagePropertyVersion: state.version,
    setImagePropertyVersion: state.setVersion,
    imagePropertyExists: state.propertyExists,
    setImagePropertyExists: state.setPropertyExists,
    setIsLoadingImage: state.setIsLoadingImage,
  }));

  const {
    blogId,
    setBlogId,
    savedCollections,
    setSavedCollections,
    selectedCollections,
    setSelectedCollections,
    collectionChanges,
    setUserIsEditor,
  } = useNewsMetadataStore((state) => ({
    blogId: state.blogId,
    setBlogId: state.setBlogId,
    savedCollections: state.savedCollections,
    setSavedCollections: state.setSavedCollections,
    selectedCollections: state.selectedCollections,
    setSelectedCollections: state.setSelectedCollections,
    collectionChanges: state.collectionChanges,
    setUserIsEditor: state.setUserIsEditor,
  }));

  const { setIsDbInit } = useNewsMetadataStore((state) => ({
    setIsDbInit: state.setIsDbInit,
  }));

  const { setCurrentUser, setIsAnonymous, setIsGuest, setIsCurrentUserAdmin } = useCurrentUserStore((state) => ({
    setCurrentUser: state.setCurrentUser,
    setIsAnonymous: state.setIsAnonymous,
    setIsGuest: state.setIsGuest,
    setIsCurrentUserAdmin: state.setIsCurrentUserAdmin,
  }));

  const { data: isInitialized } = useQueryClient("database-is-initialized", fetchIsInitialized, {
    retry: 0,
    select: (response) => {
      const { data } = response;
      return data;
    },
  });

  const { isLoading: loadingCategories, data: categoriesResponse } = useQuery(
    "categories-settings",
    getCategoriesSettings,
    {
      retry: 0,
      select: (response) => {
        const { data } = response;
        return data?.categories?.data || [];
      },
    },
  );

  useEffect(() => {
    setIsDbInit(!!isInitialized);
  }, [isInitialized]);

  useEffect(() => {
    if (!loadingCategories && categoriesResponse) {
      setAllCategories([...(categoriesResponse || [])]);
    }
  }, [loadingCategories, categoriesResponse, setAllCategories]);

  const fetchBlogsCategories = async (allCategories) => {
    setIsLoadingMetadata(true);
    try {
      const result = await contentPropertyService.getBlogsContentProperty({
        blogPostId: blogId,
        key: "cosmosnewsmetadata",
      });

      if (result?.data?.length) {
        const savedCategories = result.data[0].value.categories.split(", ");
        const builtCategories = savedCategories
          .filter((cat) => !!cat.trim())
          .map((catId) => {
            const foundCategory = allCategories.find(({ id }) => id === catId);
            return {
              label: foundCategory?.value,
              value: foundCategory?.id,
              id: foundCategory?.id,
            };
          })
          .filter((cat) => !!cat.id);

        setNewsMetadataPropertyExists(true);
        setBlogCategories(builtCategories);
        setNewsMetadataPropertyId(result.data[0].id);
        setNewsMetadataVersion(result.data[0].version.number);
      }
    } catch {
      setNewsMetadataPropertyExists(false);
      setBlogCategories([]);
      setNewsMetadataPropertyId(undefined);
      setNewsMetadataVersion(undefined);
    } finally {
      setIsLoadingMetadata(false);
    }
  };

  const fetchBlogsImage = async () => {
    setIsLoadingImage(true);
    try {
      const result = await contentPropertyService.getBlogsContentProperty({
        blogPostId: blogId,
        key: "cosmos-news-image",
      });
      if (result?.data?.length) {
        setImagePropertyExists(true);
        setImageData(result.data[0].value);
        setImagePropertyVersion(result.data[0].version.number);
        setImagePropertyId(result.data[0].id);
      }
    } catch (error) {
      setImagePropertyExists(false);
      setImageData(DEFAULT_IMAGE);
      setImagePropertyVersion(undefined);
      setImagePropertyId(undefined);
    } finally {
      setIsLoadingImage(false);
    }
  };

  const fetchUsersPermissionsForBlog = async () => {
    try {
      const response = await userBlogpostPermissions(blogId);
      if (response?.data?.operations) {
        const { operations } = response.data;
        const hasUpdateOperation = !!operations?.find(
          (op) => op.operation === "update" && op.targetType === "blogpost",
        );
        setUserIsEditor(hasUpdateOperation);
      } else {
        setUserIsEditor(false);
      }
    } catch (error) {
      setUserIsEditor(false);
    }
  };

  const fetchSelectedCollections = () => {
    getCollectionsByTargetedBlog(blogId)
      .then((res) => {
        if (res.data?.length) {
          setSavedCollections(res.data.map(({ id }) => id));
          setSelectedCollections(res.data.map(({ id }) => id));
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (blogId && !loadingCategories && categoriesResponse) {
      fetchBlogsCategories(categoriesResponse);
    }
  }, [blogId, loadingCategories, categoriesResponse]);

  useEffect(() => {
    if (blogId) {
      fetchBlogsImage();
      fetchSelectedCollections();
      fetchUsersPermissionsForBlog();
    }
  }, [blogId]);

  useEffect(() => {
    window.AP.context.getContext().then((data) => setBlogId(data.confluence.content.id));
  }, []);

  useEffect(() => {
    (async () => {
      const [, isConfAdmin] = await window.AP.request("/rest/api/user/current?expand=operations,isExternalCollaborator")
        .then((payload) => JSON.parse(payload.body))
        .then(async (user) => {
          setIsGuest(!!user?.isExternalCollaborator);
          setIsAnonymous(user?.type === "anonymous");
          setCurrentUser(user);
          const userIsConfAdmin = user.operations.some(
            (o) => o.operation === "administer" && o.targetType === "application",
          );
          return [user, !!userIsConfAdmin];
        })
        .catch(() => {
          setCurrentUser(undefined);
          return [undefined, false];
        });

      let isCosmosAdmin = false;
      if (!isConfAdmin) {
        isCosmosAdmin = await checkIfUserIsCosmosAdmin()
          .then((res) => {
            return !!res?.data;
          })
          .catch(() => {
            return false;
          });
      }

      const isAdmin = isConfAdmin || isCosmosAdmin;

      setIsCurrentUserAdmin(isAdmin);
    })();
  }, []);

  const saveNewsMetadata = async () => {
    try {
      setIsSaving(true);

      const imageStatus = await saveImage(
        blogId,
        {
          propertyExists: imagePropertyExists,
          version: imagePropertyVersion,
          propertyId: imagePropertyId,
        },
        imageData,
      );

      const collectionStatus = await saveCollections(blogId, selectedCollections, savedCollections, collectionChanges);

      const categoriesStatus = await saveCategories(
        blogId,
        {
          propertyExists: newsMetadataPropertyExists,
          version: newsMetadataVersion,
          propertyId: newsMetadataPropertyId,
        },
        blogCategories,
      );

      if (!imageStatus || !collectionStatus || !categoriesStatus) {
        window.AP.flag.create({
          title: "Failed to save some changes",
          body: "Something went wrong, and your changes weren't all saved. Please refresh and try again.",
          type: "error",
          close: "auto",
        });
      }

      setIsSaving(false);
    } catch (error) {
      setIsSaving(false);
    }

    window.AP.dialog.close();
  };

  return (
    <Wrapper>
      <TitleWrapper>
        <Title>News Settings</Title>
      </TitleWrapper>
      <Tabs />
      <Content loadingCategories={loadingCategories} />
      <Footer>
        <Button onClick={() => window.AP.dialog.close()}>Cancel</Button>
        <LoadingButton isLoading={isSaving} appearance="primary" onClick={() => saveNewsMetadata()}>
          Save
        </LoadingButton>
      </Footer>
    </Wrapper>
  );
}

export default InsertNewsMetaDataDialog;
