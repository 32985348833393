import React from "react";
import { dummyData } from "./dummy-data";
import { ContainerWrapper, List, ListSkeletonWrapper, ListWrapper, Skeleton } from "./styles";

const UserBlogPostsPreview = () => {
  return (
    <ContainerWrapper>
      {dummyData.map((data, idx) => (
        <ListWrapper key={idx}>
          <List style={{ display: "flex", gap: "18px", placeItems: "center", position: "relative" }}>
            <img src={data.avatarUrl} alt="Profile" />
            <ListSkeletonWrapper>
              <Skeleton width="160px" height="6px" backgroundColor="#B3D4FF" marginTop="0" />
              <Skeleton width="64px" height="6px" backgroundColor="#EBECF0" marginTop="0" />
              <div style={{ position: "absolute", right: "0", bottom: "10px" }}>
                <Skeleton width="32px" height="6px" backgroundColor="#EBECF0" marginTop="0" />
              </div>
            </ListSkeletonWrapper>
          </List>
        </ListWrapper>
      ))}
    </ContainerWrapper>
  );
};

export default UserBlogPostsPreview;
