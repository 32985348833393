import React, { useMemo } from "react";
import InfoIcon from "@atlaskit/icon/glyph/info";
import { InfoPanel, PanelEditorWrapper } from "./styled";
import { TabTypes } from "../../tabTypes";
import TextInputFilter from "../../../filter/TextInputFilter";
import { Divider, FieldContainer, HelperMessage, Label } from "../../../filter/styled";
import SelectWithCustomOptions from "../../../filter/SelectWithCustomOptions";
import { NewsElementsOptionsNoSocial, SideNewsElementsOptions, ViewOptions } from "./NewsEditor";
import { PaginationOptions } from "../../../../Shared/PanelsPagination/pagination-options";
import { NewsTypes } from "../../types/news/News";
import InputFilter from "../../../filter/InputFilter";
import ElementsToggle from "../../../filter/ElementsToggle";
import { PanelPositioning } from "../../panelTypes";
import { useTheme } from "styled-components";
import { InfoIconDark } from "./VideosEditor";
import DefaultSortPicker from "../../../filter/DefaultSortPicker";
import OrderButton from "../../../filter/OrderButton";
import SimpleToggle from "../../../filter/SimpleToggle";
import SingleTileColorPicker from "../../../../../../../Shared/Components/SingleTileColorPicker";
import SizeButtons from "../../../../../../../Shared/Components/SizeButtons";

const NewsPropertyKeys = {
  VIEW: "view",
  PAGINATION: "pagination",
  NUMBER_OF_ITEMS: "maxNumberOfItems",
  ACTIVE_ELEMENTS: "activeElements",
  WATCHED_SPACES: "watchingSpaces",
  SUB_CATEGORIES: "subscribedCategories",
  SORT: "sortBy",
  ORDERING: "ordering",
  DISPLAY_TITLE: "displayTitle",
  DISPLAY_BOX: "displayBox",
  LABELS_BG_COLOR: "labelsBackground",
  TEAM_LABELS_BG_COLOR: "teamLabelsBackground",
  PADDING: "padding",
};

const ContentToggleType = {
  SPACE: "space",
  CATEGORY: "category",
};

const ContentToggleOptions = [
  { label: "Watched Spaces", value: ContentToggleType.SPACE },
  { label: "Subscribed Categories", value: ContentToggleType.CATEGORY },
];

const LayoutSidebar = ({ panelId, panelTitle, data, isSideNews, updatePanelDataProperty, updatePanelTitle }) => {
  const updateElements = (value) => {
    const newElements = !!data.activeElements.find((el) => el === value)
      ? data.activeElements.filter((el) => el !== value)
      : [...data.activeElements, value];
    updatePanelDataProperty(panelId, NewsPropertyKeys.ACTIVE_ELEMENTS, newElements, true);
  };

  return (
    <>
      <TextInputFilter label="Title" value={panelTitle} onUpdate={(value) => updatePanelTitle(panelId, value)} />

      <Divider />

      {!isSideNews && (
        <SelectWithCustomOptions
          initialValue={data.view}
          options={ViewOptions}
          label="View"
          onUpdate={(newView) => updatePanelDataProperty(panelId, NewsPropertyKeys.VIEW, newView)}
        />
      )}

      <SelectWithCustomOptions
        initialValue={data.pagination}
        options={PaginationOptions}
        label="Pagination"
        onUpdate={(newValue) => updatePanelDataProperty(panelId, NewsPropertyKeys.PAGINATION, newValue)}
      />

      {data.view !== NewsTypes.MAGAZINE && (
        <InputFilter
          numberField
          minLength={1}
          initialValue={data.maxNumberOfItems}
          label="Items per page"
          placeholder="Limit the number of pages"
          onUpdate={(value) => updatePanelDataProperty(panelId, NewsPropertyKeys.NUMBER_OF_ITEMS, value)}
        />
      )}

      <Divider />

      <ElementsToggle
        label="Elements to display"
        elements={data.activeElements}
        options={isSideNews ? SideNewsElementsOptions : NewsElementsOptionsNoSocial}
        updateElements={updateElements}
      />
    </>
  );
};

const ContentSidebar = ({ panelId, data, updatePanelDataProperty }) => {
  const { watchingSpaces, subscribedCategories } = data;
  const theme = useTheme();

  const contentToggles = useMemo(() => {
    const elements = [];
    if (watchingSpaces) elements.push(ContentToggleType.SPACE);
    if (subscribedCategories) elements.push(ContentToggleType.CATEGORY);
    return elements;
  }, [watchingSpaces, subscribedCategories]);

  const updateElements = (option) => {
    if (option === ContentToggleType.SPACE)
      updatePanelDataProperty(panelId, NewsPropertyKeys.WATCHED_SPACES, !watchingSpaces);
    if (option === ContentToggleType.CATEGORY)
      updatePanelDataProperty(panelId, NewsPropertyKeys.SUB_CATEGORIES, !subscribedCategories);
  };

  return (
    <>
      <InfoPanel style={{ marginTop: "20px" }}>
        {theme.global.name === "light" ? <InfoIcon size="large" primaryColor="#0052cc" /> : <InfoIconDark />}
        The content displayed in this widget is personalized for the logged user, tailored according to the spaces and
        categories they follow.
      </InfoPanel>

      <ElementsToggle
        label="Elements to display"
        elements={contentToggles}
        options={ContentToggleOptions}
        updateElements={updateElements}
      />

      <DefaultSortPicker
        initialValue={data.sortBy}
        onUpdate={(sorting) => updatePanelDataProperty(panelId, NewsPropertyKeys.SORT, sorting)}
      />
      <HelperMessage>
        <div style={{ marginRight: "4px", color: "#6b778c" }}> Order by </div>
        <OrderButton
          sortOrder={data.ordering}
          onUpdate={(ordering) => updatePanelDataProperty(panelId, NewsPropertyKeys.ORDERING, ordering)}
        />
      </HelperMessage>
    </>
  );
};

const DesignSidebar = ({ panelId, data, isSideNews, updatePanelDataProperty }) => {
  const { displayTitle, displayBox } = data;

  return (
    <>
      <FieldContainer>
        <Label>Display title</Label>
        <SimpleToggle
          label="Display title"
          value={displayTitle}
          setValue={(value) => updatePanelDataProperty(panelId, NewsPropertyKeys.DISPLAY_TITLE, value)}
          customStyles={{ padding: "10px 0px 8px 10px" }}
        />
        <SimpleToggle
          label="Display box"
          value={displayBox}
          setValue={(value) => updatePanelDataProperty(panelId, NewsPropertyKeys.DISPLAY_BOX, value)}
          customStyles={{ padding: "10px 0px 8px 10px" }}
        />
      </FieldContainer>

      <Divider />

      <FieldContainer>
        <Label>Color</Label>
        <SingleTileColorPicker
          label="Space and Label"
          value={data.view !== NewsTypes.LIST ? data.labelsBackground : data?.teamLabelsBackground || "#42526E"}
          setValue={(value) =>
            updatePanelDataProperty(
              panelId,
              data.view !== NewsTypes.LIST ? NewsPropertyKeys.LABELS_BG_COLOR : NewsPropertyKeys.TEAM_LABELS_BG_COLOR,
              value,
            )
          }
        />
      </FieldContainer>

      {!isSideNews && (
        <>
          <Divider />

          <SizeButtons
            label="Padding"
            types={{ panel: "news", size: "padding" }}
            input={{ min: data.view === NewsTypes.MAGAZINE ? 5 : 10, max: 40, step: 1, value: data.padding }}
            setValue={(value) => updatePanelDataProperty(panelId, NewsPropertyKeys.PADDING, value, true)}
          />
        </>
      )}
    </>
  );
};

function PersonalizedEditor({ panel, selectedTab, updatePanelDataProperty, updatePanelTitle }) {
  const { data, position } = panel;

  const isSideNews = position === PanelPositioning.SIDE;

  return (
    <PanelEditorWrapper>
      {selectedTab === TabTypes.LAYOUT && (
        <LayoutSidebar
          panelId={panel.id}
          panelTitle={panel.panelTitle}
          data={data}
          isSideNews={isSideNews}
          updatePanelDataProperty={updatePanelDataProperty}
          updatePanelTitle={updatePanelTitle}
        />
      )}

      {selectedTab === TabTypes.CONTENT && (
        <ContentSidebar panelId={panel.id} data={data} updatePanelDataProperty={updatePanelDataProperty} />
      )}

      {selectedTab === TabTypes.DESIGN && (
        <DesignSidebar
          panelId={panel.id}
          data={data}
          isSideNews={isSideNews}
          updatePanelDataProperty={updatePanelDataProperty}
        />
      )}
    </PanelEditorWrapper>
  );
}

export default PersonalizedEditor;
