import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Textfield from "@atlaskit/textfield";
import { LinkImageTextContainer, LinkText, LinkTitle, PrimaryButton } from "./styled/Components";
import xss from "xss";

const TextFieldWrapper = styled.div`
  & > div:first-child {
    background: ${({ theme }) => theme.shared.form.input.background};
    border: 2px solid ${({ theme }) => theme.shared.form.select.borderColor};
    color: ${({ theme }) => theme.shared.form.select.value};
  }

  display: flex;
  align-items: center;
  column-gap: 20px;
`;

function Email({ value, onSelected }) {
  const [input, setInput] = useState("");

  useEffect(() => {
    if (value?.source && value?.source === "email") {
      setInput(value.link);
    }
  }, [value]);

  return (
    <>
      <TextFieldWrapper>
        <Textfield
          placeholder="mail@caelor.com"
          name="email"
          value={input}
          onChange={(e) => setInput(e.target.value)}
        />
        <PrimaryButton
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            onSelected({ link: xss(input), source: "email" });
          }}
        >
          Save
        </PrimaryButton>
      </TextFieldWrapper>

      <LinkImageTextContainer>
        <img src="/img/EmailLink.svg" alt="" />
        <LinkTitle>Enter an email address</LinkTitle>
        <LinkText>
          <div>Open a default email provider with the</div>
          <div>predefined email address</div>
        </LinkText>
      </LinkImageTextContainer>
    </>
  );
}

Email.propTypes = {
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onSelected: PropTypes.func.isRequired,
};

export default Email;
