import { ATLASKIT_PROVIDER } from "./Icon";
import { getIconByName } from "./icons-service";

export const generateRandomIcon = async () => {
  const module = await import(`../Icons/providers/atlaskit`);
  const providerIcons = module.default;
  const randomIconName = providerIcons[Math.floor(Math.random() * providerIcons.length)][0];
  const foundIcon = getIconByName(ATLASKIT_PROVIDER, randomIconName, providerIcons);
  return { provider: ATLASKIT_PROVIDER, icon: `${ATLASKIT_PROVIDER}/${randomIconName}`, svg: foundIcon };
};
