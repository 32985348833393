import {
  Description,
  Title,
  LinksWrapper,
  CardContent,
  CardLabel,
  CardTitle,
  CardText,
  Parent,
} from "./SpaceportDrawer";
import { LATEST_NEWS, CAELOR_NEWS } from "./news";
import styled from "styled-components";

const DateLabel = styled.span`
  padding: 4px 8px;
  color: #6b778c;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 400;
  width: fit-content;
  position: absolute;
  right: 30px;
`;

const LinkContainer = styled.div`
  display: flex;
  align-items: center;

  max-width: 440px;
  box-sizing: border-box;

  border-radius: 3px;
  border: 0.75px solid ${({ theme }) => theme.spaceport.newsContainerBorder};
  box-shadow: 0px 1px 1px 0px #091e4240;
  overflow: hidden;
  position: relative;
`;

function NewsTab() {
  return (
    <Parent>
      <Title>News</Title>
      <Description>Explore a world of news and stay in the know with our informative articles.</Description>
      <LinksWrapper>
        {[...LATEST_NEWS, ...CAELOR_NEWS].map((item) => (
          <a
            href={item.link}
            rel="noreferrer"
            target="_blank"
            style={{ textDecoration: "none", cursor: item.link ? "pointer" : "default" }}
            key={item.title}
          >
            <LinkContainer key={item.title}>
              <div>
                <div style={{ width: "440px", height: "250px", overflow: "hidden" }}>
                  <img
                    src={item.image}
                    alt={item.title}
                    style={{ objectFit: "cover", width: "100%", height: "100%" }}
                  />
                </div>
                <CardContent style={{ padding: "15px 20px 10px" }}>
                  {item.label && <CardLabel color={item.color}>{item.label}</CardLabel>}
                  <DateLabel>{item.date}</DateLabel>
                  <CardTitle>{item.title}</CardTitle>
                  <CardText>{item.text}</CardText>
                </CardContent>
              </div>
            </LinkContainer>
          </a>
        ))}
      </LinksWrapper>
    </Parent>
  );
}

export default NewsTab;
