import { Panel, PanelHeader } from "../General";
import styled from "styled-components";
import { AsyncSelect } from "@atlaskit/select";
import { useTheme } from "styled-components";

import { useEffect, useRef } from "react";
import { atlassianRestService } from "../../../../../../Service/AtlassianRestService";
import debounce from "lodash.debounce";

import { SIMPLE_SELECT_STYLES } from "../../../Dashboard/filter/CustomSelectStyles";
import { useAdminStore } from "./admin-store";

const Label = styled.span`
  color: ${({ theme }) => theme.shared.settings.general.admin.labelColor};
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  width: 100%;
  height: 16px;
  margin-top: 20px;
  margin-bottom: 5px;
`;

const EmptyState = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  margin-top: 10%;
  color: ${({ theme }) => theme.shared.settings.general.admin.emptyStateColor};
`;

const Title = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.shared.settings.general.panelHeaderColor};
  font-weight: 400;
  font-size: 16px;
`;

const Description = styled.p`
  font-size: 14px;
  line-height: 20px;
  margin-top: 16px;
  color: ${({ theme }) => theme.shared.settings.general.brandAndColors.pageHeaderTextColor};
`;

export const formatUserData = (user) => {
  return {
    label: user.displayName,
    value: user.accountId,
    name: user.displayName,
    accountId: user.accountId,
  };
};

function AddAdmins({ loading, saveUsersToDB }) {
  const theme = useTheme();
  const selectRef = useRef();

  const { cosmosAdmins, setCosmosAdmins } = useAdminStore((state) => ({
    cosmosAdmins: state.cosmosAdmins,
    setCosmosAdmins: state.setCosmosAdmins,
  }));

  const loadOptions = async (query) => {
    try {
      const response = await atlassianRestService.findUsersByName(query);
      if (response?.results) {
        return (
          response.results
            ?.filter(({ user }) => user.accountType === "atlassian")
            .map(({ user }) => formatUserData(user)) || []
        );
      }
      return [];
    } catch (error) {
      return [];
    }
  };

  const handleSaveDebounce = useRef(
    debounce((users) => {
      saveUsersToDB(users);
    }, 250),
  ).current;

  useEffect(() => {
    return () => {
      handleSaveDebounce.cancel();
    };
  }, [handleSaveDebounce]);

  const updateSelectValue = (users) => {
    handleSaveDebounce(users ?? []);
    setCosmosAdmins(users ?? []);
  };

  return (
    <>
      <Panel>
        <PanelHeader>Cosmos Admins</PanelHeader>

        <Label>Admins</Label>
        {!loading && (
          <AsyncSelect
            ref={selectRef}
            placeholder="Select admins"
            defaultOptions
            cacheOptions
            isMulti
            isSearchable
            maxMenuHeight={200}
            styles={SIMPLE_SELECT_STYLES}
            value={cosmosAdmins}
            loadOptions={loadOptions}
            onChange={updateSelectValue}
          />
        )}

        {!cosmosAdmins.length && (
          <EmptyState>
            <img width="383" height="131" src={`/images/hub/admins-empty-state-${theme.global.name}.svg`} alt="" />

            <div style={{ width: "464px", textAlign: "center" }}>
              <Title>No Cosmos admins selected</Title>
              <Description>
                Currently, there are no users assigned as Cosmos admins. Only Confluence administrators can set up
                Cosmos by default.
              </Description>
            </div>
          </EmptyState>
        )}
      </Panel>
    </>
  );
}

export default AddAdmins;
