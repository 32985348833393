import React from "react";
import { useSpaceDirectoryStore } from "../space-directory-store";
import { FieldContainer, FieldLabel, ViewButtonsWrapper, ViewSelectButton } from "../../Shared/Filters/styled-filters";
import { GridIcon, ListIcon } from "../../Shared/Filters/Icons";
import { useCorporateIdentityStore } from "../../Settings/General/BrandAndColors/corporate-identity-store";
import { COLOR_TYPES } from "../../Settings/General/BrandAndColors/color-types";
import { ViewTypes } from "../types";
import { useTheme } from "styled-components";
import { convertColorOpacity } from "../../../../../Shared/Functions/convertColorOpacity";

function ViewSelect({ primaryColor = "#0065ff" }) {
  const { selectedView, setSelectedView } = useSpaceDirectoryStore((state) => ({
    selectedView: state.selectedView,
    setSelectedView: state.setSelectedView,
  }));
  const theme = useTheme();

  const convertedColor = convertColorOpacity(primaryColor, theme.global.name);

  return (
    <FieldContainer style={{ marginTop: "0px" }}>
      <FieldLabel style={{ textTransform: "uppercase" }}>View</FieldLabel>
      <ViewButtonsWrapper numberOfColumns={2}>
        <ViewSelectButton
          isActive={selectedView === ViewTypes.GRID}
          primaryColor={primaryColor}
          onClick={() => setSelectedView(ViewTypes.GRID)}
          backgroundColor={{
            inactive: theme.shared.form.button.background.inactive,
            active: convertedColor,
          }}
        >
          <GridIcon color={selectedView === ViewTypes.GRID ? primaryColor : theme.shared.form.button.text.inactive} />
        </ViewSelectButton>

        <ViewSelectButton
          isActive={selectedView === ViewTypes.LIST}
          primaryColor={primaryColor}
          onClick={() => setSelectedView(ViewTypes.LIST)}
          backgroundColor={{
            inactive: theme.shared.form.button.background.inactive,
            active: convertedColor,
          }}
        >
          <ListIcon color={selectedView === ViewTypes.LIST ? primaryColor : theme.shared.form.button.text.inactive} />
        </ViewSelectButton>
      </ViewButtonsWrapper>
    </FieldContainer>
  );
}

export default ViewSelect;
