import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import BigHeaderSearch from "./BigHeaderSearch";
import getCroppedImg from "./crop";
import RightHeaderSearch from "./HeaderSearch";
import { HEADER_TYPES } from "../../Settings/General/BrandAndColors/types";
import { useCorporateIdentityStore } from "../../Settings/General/BrandAndColors/corporate-identity-store";

const HeaderWrapper = styled.div`
  position: relative;
  z-index: 9;
  width: 100%;
  background: ${({ background }) => background};
  background-size: cover;

  display: flex;
  justify-content: center;
  align-items: center;

  height: ${({ isSearching, headerType }) => {
    if (isSearching) return "100px";
    if (headerType === HEADER_TYPES.FULL_WIDTH) return "400px";
    if (headerType === HEADER_TYPES.GRID) return "300px";
  }};

  ${({ headerType }) => {
    if (headerType === HEADER_TYPES.GRID) {
      return `max-width: 1330px; margin: 35px auto 0px auto;`;
    }
  }}

  opacity: 1;

  transition: height 500ms linear;

  &::before {
    ${({ backgroundOverlay }) => backgroundOverlay};
    content: "";
    position: absolute;
    z-index: 10;
    inset: 0;
    transition: 200ms;
  }
`;

const ContentWrapper = styled.div`
  position: relative;
  z-index: 11;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 30px;
  max-width: 1270px;
  width: calc(100% - 60px);
  height: 100%;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 50%;
`;

const Title = styled.span`
  color: #fff;
  font-weight: 500;
  font-size: ${({ headerType }) => {
    if (headerType === HEADER_TYPES.FULL_WIDTH) return "48px";
    if (headerType === HEADER_TYPES.GRID) return "32px";
  }};
`;

const Description = styled.p`
  margin: 0px;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;

  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 360ch;

  max-height: ${({ isSearching }) => (isSearching ? "0px" : "500px")};
  opacity: ${({ isSearching }) => (isSearching ? "0" : "1")};
  margin-top: ${({ isSearching }) => (isSearching ? "0px" : "12px")};

  transition:
    max-height 500ms linear,
    opacity 400ms linear,
    margin 500ms linear;
`;

function PageHeaderCentered({
  title,
  description,
  imageUrl,
  background = undefined,
  searchPlaceholder,
  searchValue,
  setSearchValue,
  isSearching,
  setIsSearching,
  disableSearchBar,
  showSearch = true,
  headerType = HEADER_TYPES.FULL_WIDTH,
}) {
  const [builtBackground, setBuiltBackground] = useState(null);

  const scrolledRef = useRef();
  const searchRef = useRef();
  const rightSearchRef = useRef();

  const overflowWrapper = document.getElementById("hub-overflow-container");
  const borderRadius = useCorporateIdentityStore((state) => state.borderRadius);

  useEffect(() => {
    if (isSearching) {
      scrolledRef.current = { isUserScroll: false };
      setTimeout(() => {
        overflowWrapper.scrollTo({
          top: 1,
          behavior: "smooth",
        });
      }, 350);

      setTimeout(() => {
        scrolledRef.current = { isUserScroll: true };
      }, 650);
    }

    const rightSearchElement = rightSearchRef?.current;
    if (!rightSearchElement) return;
    rightSearchElement.style.display = !isSearching ? "none" : "flex";
  }, [isSearching]);

  // useEffect(() => {
  //   if (overflowWrapper) {
  //     const scrollListener = overflowWrapper.addEventListener("scroll", () => {
  //       if (!scrolledRef?.current?.isUserScroll) return;

  //       const scrollPosition = overflowWrapper.scrollTop;
  //       if (scrollPosition < 2 && !searchValue) {
  //         scrolledRef.current = { isUserScroll: true };
  //         setIsSearching(false);
  //       }
  //     });

  //     return () => {
  //       overflowWrapper.removeEventListener("scroll", scrollListener);
  //     };
  //   }
  // }, [overflowWrapper]);

  useEffect(() => {
    (async () => {
      try {
        const bg = await getBackground(background);
        setBuiltBackground(bg);
      } catch (error) {
        setBuiltBackground(`url("${imageUrl}")`);
      }
    })();
  }, [background]);

  const getBackground = async (_background) => {
    if (_background?.image?.source === "custom") {
      return `url("${imageUrl}")`;
    }
    if (_background?.image?.source === "color") {
      return `${_background.image.value}`;
    }
    if (_background?.image?.source === "gradient") {
      return `linear-gradient(${_background.image.value?.rotation || "90deg"}, ${_background.image.value.firstColor} ${
        _background.image.value.firstColorPosition
      }%, ${_background.image.value.secondColor} ${_background.image.value.secondColorPosition}%)`;
    }
    if (_background?.image?.croppedAreaPixels) {
      try {
        const croppedImage = await getCroppedImg(_background.image.link, _background.image.croppedAreaPixels);
        return `url("${croppedImage.image}")`;
      } catch {
        return `url("${_background?.image?.link}")`;
      }
    }
    return `url("${_background?.image?.link || imageUrl}")`;
  };

  const getBackgroundOverlay = (bgOverlay) => {
    if (bgOverlay?.isActive) {
      return `
        background-color: ${bgOverlay.color};
        opacity: ${bgOverlay.opacity};
      `;
    }

    return `display: none;`;
  };

  return (
    <HeaderWrapper
      headerType={headerType}
      isSearching={isSearching}
      imageUrl={imageUrl}
      background={builtBackground}
      backgroundOverlay={getBackgroundOverlay(background?.overlay)}
      borderRadius={borderRadius}
    >
      <ContentWrapper>
        <TextWrapper>
          <Title headerType={headerType}>{title}</Title>
          <Description isSearching={isSearching}>{description}</Description>

          {showSearch && (
            <BigHeaderSearch
              ref={searchRef}
              value={searchValue}
              setValue={setSearchValue}
              placeholder={searchPlaceholder}
              isSearching={isSearching}
              setIsSearching={setIsSearching}
              disableSearchBar={disableSearchBar}
            />
          )}
        </TextWrapper>

        <RightHeaderSearch
          ref={rightSearchRef}
          value={searchValue}
          setValue={setSearchValue}
          placeholder={searchPlaceholder}
          isSearching={isSearching}
          setIsSearching={setIsSearching}
          disableSearchBar={disableSearchBar}
        />
      </ContentWrapper>
    </HeaderWrapper>
  );
}

PageHeaderCentered.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  searchPlaceholder: PropTypes.string,
  searchValue: PropTypes.string,
  setSearchValue: PropTypes.func,
  isSearching: PropTypes.bool,
  setIsSearching: PropTypes.func,
  disableSearchBar: PropTypes.bool,
};

PageHeaderCentered.defaultProps = {
  searchPlaceholder: "Search",
  searchValue: "",
  setSearchValue: () => {},
  isSearching: false,
  setIsSearching: () => {},
  disableSearchBar: false,
};

export default PageHeaderCentered;
