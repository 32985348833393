import eventBus from "../../Functions/eventBus";
import { types } from "./actions";

export const initialState = {
  translateX: 0,
  translateY: 0,
  prevMouseX: 0,
  prevMouseY: 0,
  scale: 1,
};

const reducer = (state, action) => {
  switch (action.type) {
    case types.PAN_START:
      return {
        ...state,
        prevMouseX: action.clientX,
        prevMouseY: action.clientY,
      };
    case types.PAN:
      const deltaMouseX = action.clientX - state.prevMouseX;
      const deltaMouseY = action.clientY - state.prevMouseY;

      return {
        ...state,
        translateX: state.translateX + deltaMouseX,
        translateY: state.translateY + deltaMouseY,
        prevMouseX: action.clientX,
        prevMouseY: action.clientY,
      };
    case types.ZOOM:
      const { minZoom, maxZoom } = action.zoomLimits;
      const scaledTranslate = getScaledTranslate(state, action.zoomFactor);
      const mousePositionOnScreen = { x: action.clientX, y: action.clientY };
      const zoomOffset = getZoomOffset(action.containerRect, mousePositionOnScreen, action.zoomFactor);

      let newZoomLevel = state.scale * action.zoomFactor;
      let shouldUseAsCustomZoom = false;
      if (newZoomLevel <= minZoom) {
        newZoomLevel = minZoom;
        shouldUseAsCustomZoom = true;
      }
      if (newZoomLevel >= maxZoom) {
        newZoomLevel = maxZoom;
        shouldUseAsCustomZoom = true;
      }

      eventBus.dispatch("caelor-zoom-update-in-reducer", { zoomLevel: Math.round(newZoomLevel * 100) });
      return {
        ...state,
        scale: newZoomLevel,
        translateX: shouldUseAsCustomZoom ? state.translateX : scaledTranslate.x + zoomOffset.x,
        translateY: shouldUseAsCustomZoom ? state.translateY : scaledTranslate.y + zoomOffset.y,
      };
    case types.CUSTOM_ZOOM: {
      const { zoomLevel } = action;
      return {
        ...state,
        scale: zoomLevel,
      };
    }
    case types.RESET:
      return {
        translateX: 0,
        translateY: action.initialY || 0,
        prevMouseX: 0,
        prevMouseY: 0,
        scale: 1,
      };
    case types.CENTER:
      eventBus.dispatch("caelor-zoom-update-in-reducer", { zoomLevel: Math.round(action.newZoomLevel * 100) });
      return {
        translateX: action.newHorizontalPosition || 0,
        translateY: action.initialY || 0,
        prevMouseX: 0,
        prevMouseY: 0,
        scale: action.newZoomLevel,
      };
    default:
      return state;
  }
};

const getZoomOffset = (containerRect, mousePositionOnScreen, zoomFactor) => {
  const zoomOrigin = {
    x: mousePositionOnScreen.x - containerRect.left,
    y: mousePositionOnScreen.y - containerRect.top,
  };

  const currentDistanceToCenter = {
    x: containerRect.width / 2 - zoomOrigin.x,
    y: containerRect.height / 2 - zoomOrigin.y,
  };

  const scaledDistanceToCenter = {
    x: currentDistanceToCenter.x * zoomFactor,
    y: currentDistanceToCenter.y * zoomFactor,
  };

  const zoomOffset = {
    x: currentDistanceToCenter.x - scaledDistanceToCenter.x,
    y: currentDistanceToCenter.y - scaledDistanceToCenter.y,
  };

  return zoomOffset;
};

const getScaledTranslate = (state, zoomFactor) => ({
  x: state.translateX * zoomFactor,
  y: state.translateY * zoomFactor,
});

export default reducer;
