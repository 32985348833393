import React from "react";
import styled from "styled-components";
import Spinner from "@atlaskit/spinner";
import Tag from "@atlaskit/tag";
import { SpinnerContainer } from "../../../styled/pages";
import { useNewsroomStore } from "../newsroom-store";
import NoResultsState from "../../Shared/NoResultsState";
import EmptyState from "../EmptyState";
import { NewsViews, SUBS_FILTER } from "../defaults";
import MagazineView from "./MagazineView";
import GridView from "./GridView";
import ListView from "./ListView";
import { useCorporateIdentityStore } from "../../Settings/General/BrandAndColors/corporate-identity-store";
import { SmallTitle } from "../../PeopleBase/styled/views";
import { Subtitle } from "../../PeopleBase/PeopleBase";
import TagProvider from "../Filters/TagProvider";
import { PersonalizedNewsEmptyState } from "../../Dashboard/panels/types/news/EmptyStates";
import { ModalKeys } from "../../../../Modal/modalKeys";
import { useCurrentUserStore } from "../../../current-user-store";
import { COLOR_TYPES } from "../../Settings/General/BrandAndColors/color-types";

const NewsViewWrapper = styled.div``;

function NewsEntry({ data, fetchNextPage, hasNextPage, isLoading, isSearching }) {
  const {
    settings: { selectedView },
    hasSpaceFilter,
    hasLabelsFilter,
    hasAuthorsFilter,
    hasDateFilter,
    categoryFilter,
    categories,
    setCreateBlogModalOpen,
  } = useNewsroomStore((state) => ({
    settings: state.settings,
    hasSpaceFilter: !!state.spaceFilter?.length,
    hasLabelsFilter: !!state.labelsFilter?.length,
    hasAuthorsFilter: !!state.authorsFilter?.length,
    hasDateFilter: !!state.dateFilter.start || !!state.dateFilter.end,
    categoryFilter: state.categoryFilter,
    categories: state.categories,
    setCreateBlogModalOpen: state.setCreateBlogModalOpen,
  }));

  const isCurrentUserAdmin = useCurrentUserStore((state) => state.isCurrentUserAdmin);
  const { primaryColor, borderRadius } = useCorporateIdentityStore((state) => ({
    primaryColor: state.colors[COLOR_TYPES.PRIMARY],
    borderRadius: state.borderRadius,
  }));

  const hasCategoryFilter = !!categoryFilter;
  const isShowingSubscriptions = hasCategoryFilter && categoryFilter === SUBS_FILTER;
  const hasFilters = hasSpaceFilter || hasLabelsFilter || hasAuthorsFilter || hasDateFilter;

  if (isLoading) {
    return (
      <SpinnerContainer>
        <Spinner size="xlarge" />
      </SpinnerContainer>
    );
  }

  if (!data?.pages || !data?.pages[0]?.results?.length) {
    if (categoryFilter === SUBS_FILTER) {
      return (
        <PersonalizedNewsEmptyState
          customOnClick={() =>
            window.AP.dialog.create({
              key: "caelor-custom-dialog",
              chrome: false,
              customData: {
                modalKey: ModalKeys.SUBSCRIPTIONS,
                isCurrentUserAdmin,
                primaryColor,
              },
            })
          }
        />
      );
    }
    if (hasFilters || hasCategoryFilter || isSearching) {
      return <NoResultsState removeMargin />;
    }

    return <EmptyState onClick={() => setCreateBlogModalOpen(true)} />;
  }

  const totalSize = data?.pages?.[0]?.totalSize;
  const results =
    totalSize !== 0
      ? `${totalSize > 1 ? `${totalSize} results` : `${totalSize} result`}${hasFilters ? " filtered by " : ""}`
      : "No results";

  return (
    <NewsViewWrapper>
      {(hasFilters || hasCategoryFilter) && (
        <>
          <SmallTitle>
            {isShowingSubscriptions ? (
              "My Subscriptions"
            ) : (
              <>
                {hasCategoryFilter
                  ? `${categories.find((ct) => ct.id === categoryFilter)?.value}`
                  : "Showing results for:"}
              </>
            )}
          </SmallTitle>
          <Subtitle>
            {!isLoading && results}
            <TagProvider hasResults={hasFilters && totalSize !== 0} />
          </Subtitle>
        </>
      )}
      {selectedView === NewsViews.MAGAZINE && (
        <MagazineView data={data} fetchNextPage={fetchNextPage} hasNextPage={hasNextPage} borderRadius={borderRadius} />
      )}

      {selectedView === NewsViews.GRID && (
        <GridView data={data} fetchNextPage={fetchNextPage} hasNextPage={hasNextPage} borderRadius={borderRadius} />
      )}

      {selectedView === NewsViews.LIST && (
        <ListView data={data} fetchNextPage={fetchNextPage} hasNextPage={hasNextPage} borderRadius={borderRadius} />
      )}
    </NewsViewWrapper>
  );
}

export default NewsEntry;
