import styled from "styled-components";
import { PanelPositioning } from "./panels/panelTypes";

export const DashboardWrapper = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.globalBackground};
  overflow-y: auto;
  position: relative;
  height: calc(100% - 80px);
  padding-bottom: 80px;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.scrollbar};
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #999;
  }
`;

export const ContentWrapper = styled.div`
  padding: 24px 32px;
  max-width: 1270px;
  margin: 0 auto;

  display: flex;
  align-items: flex-start;
  column-gap: 32px;
`;

export const Panel = styled.div`
  background: ${({ theme }) => theme.shared.panel.background};
  box-shadow:
    rgba(9, 30, 66, 0.25) 0px 1px 3px,
    rgba(9, 30, 66, 0.31) 0px 0px 1px;
  border-radius: 3px;
  padding: 17px 24px;
  position: relative;
  overflow: hidden;
`;

export const PanelPaginationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const PanelTitle = styled.div`
  font-weight: 400;
  font-size: 21px;
  line-height: 25px;
  color: ${({ theme }) => theme.shared.panel.title};
  max-width: ${({ panelPosition = "main" }) => (panelPosition === PanelPositioning.MAIN ? "880px" : "261px")};
  word-wrap: break-word;
`;

export const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 5px;
  min-width: 53px;
`;

export const PanelContent = styled.div`
  padding-top: ${({ noPadding }) => (noPadding ? "0px" : "16px")};
`;

export const MainPanelsWrapper = styled.div`
  flex: 0 1 75%;

  @media (max-width: 1200px) {
    flex: 0 1 65%;
  }
`;

export const SidePanelsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 24px;
  column-gap: 32px;
  flex: 0 1 25%;

  @media (max-width: 1200px) {
    flex: 0 1 35%;
  }
`;

export const PanelColumn = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
`;

export const ShowMoreButton = styled.div`
  margin-top: 16px;
  display: grid;
  place-content: center;
  text-align: center;
  cursor: pointer;

  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: ${({ primaryColor }) => primaryColor};

  &:hover {
    opacity: 0.9;
  }
`;
