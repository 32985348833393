import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled, { useTheme } from "styled-components";
import Textfield from "@atlaskit/textfield";
import { AsyncSelect } from "@atlaskit/select";
import Page16Icon from "@atlaskit/icon-object/glyph/page/16";
import Blog16Icon from "@atlaskit/icon-object/glyph/blog/16";

import { buildPageData } from "./buildData";
import {
  HeaderLabels,
  ListItemsContainer,
  ListSpace,
  ListTitle,
  ListType,
  SearchSelectContainer,
  ListItem,
} from "./styled/Components";
import { searchForSpaceWithTerm, searchPagesWithCQL } from "./api";

const PagesWrapper = styled.div`
  div:first-child {
    div:first-child {
      // border: 2px solid ${({ theme }) => theme.navigation.settings.linkSidebar.searchPagesBorder};
      border: none !important;
      background: ${({ theme }) => theme.navigation.settings.linkSidebar.searchPagesBackground};
    }
    div:first-child:focus-within {
      border: none;
    }
  }

  .search-pages-link-modal {
    outline: none;
    border: none !important;
    background: ${({ theme }) => theme.navigation.settings.linkSidebar.searchPagesBackground};
    color: ${({ theme }) => theme.navigation.settings.select.color} !important;
  }
`;

function Pages({ value, onSelected }) {
  const [input, setInput] = useState("");
  const [pages, setPages] = useState([]);
  const [selectedSpaceKey, setSelectedSpaceKey] = useState(null);

  const [selection, setSelection] = useState("");

  const theme = useTheme();

  const getPages = async () => {
    try {
      const CQLquery = selectedSpaceKey?.value ? [`space = "${selectedSpaceKey.value}"`] : [];
      const response = await searchPagesWithCQL(input, [], CQLquery);

      const builtPages = buildPageData(response?.results || []);
      setPages([...builtPages]);
    } catch (err) {
      setPages([]);
    }
  };

  const loadSpaces = async (searchTerm) => {
    try {
      const response = await searchForSpaceWithTerm(searchTerm);

      if (response?.length) {
        const options = response.map((space) => ({
          label: space.name,
          value: space.key,
        }));
        return [{ label: "All Spaces", value: null }, ...options];
      }
      return [];
    } catch (error) {
      return [];
    }
  };

  const getSpaceSelectValue = () => {
    if (!selectedSpaceKey) {
      return { label: "All Spaces", value: null };
    }
    return selectedSpaceKey;
  };

  useEffect(() => {
    getPages();
  }, [input, selectedSpaceKey]);

  useEffect(() => {
    if (value?.source && value?.source === "page") {
      setSelection(value.id || "");
    }
  }, [value]);

  return (
    <PagesWrapper>
      <SearchSelectContainer>
        <Textfield
          className="search-pages-link-modal"
          name="search-pages"
          placeholder="Type to search"
          isCompact
          value={input}
          onChange={(e) => setInput(e.target.value)}
        />

        <AsyncSelect
          defaultOptions
          cacheOptions
          loadOptions={loadSpaces}
          value={getSpaceSelectValue()}
          onChange={(v) => setSelectedSpaceKey(v)}
          styles={{
            control: (provided, state) => ({
              ...provided,
              backgroundColor: `${theme.navigation.settings.select.background} !important`,
              borderColor: state.isFocused
                ? `${theme.navigation.settings.select.borderFocused} !important`
                : theme.navigation.settings.select.border,
              borderRadius: "3px",
              borderWidth: "1px",
              minHeight: "28px",
              minWidth: "120px",
              maxWidth: "360px",
              fontSize: "14px",
              boxShadow: null,
            }),
            menu: (provided) => ({
              ...provided,
              textAlign: "left",
              minWidth: "120px",
              maxWidth: "360px",
              fontSize: "14px",
              color: `${theme.navigation.settings.select.color} !important`,
              zIndex: "9999",
            }),
            option: (provided, state) => ({
              ...provided,
              backgroundColor: state.isSelected
                ? "var(--ds-background-brand, #07145a1c)"
                : state.isFocused && theme.navigation.settings.select.background,
              color: state.isSelected && `${theme.navigation.settings.select.color} !important`,
              boxShadow: state.isFocused ? "inset 1px 0px 0px var(--ds-text-brand, #07145a);" : "",
            }),
            valueContainer: (provided) => ({
              ...provided,
              top: "-6%",
            }),
            input: (provided) => ({
              ...provided,
              margin: "0px",
            }),
            placeholder: (provided) => ({
              ...provided,
              top: "46%",
            }),
            indicatorSeparator: () => ({
              display: "none",
            }),
            indicatorsContainer: (provided) => ({
              ...provided,
              height: "inherit",
              padding: "none",
            }),
          }}
        />
      </SearchSelectContainer>

      <ListItem
        style={{
          padding: "15px 0px 5px 0px",
          borderBottom: `2px solid ${theme.navigation.settings.linkSidebar.listItemBorder}`,
        }}
      >
        <HeaderLabels>Type</HeaderLabels>
        <HeaderLabels>Title</HeaderLabels>
        <HeaderLabels>Space</HeaderLabels>
      </ListItem>

      <ListItemsContainer>
        {pages.map(({ id, type, title, space, link }) => (
          <ListItem
            key={id}
            isClickable
            isSelected={selection === id}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setSelection(id);
              onSelected({ id, link, title, type, source: "page" });
            }}
          >
            <ListType>{type === "page" ? <Page16Icon /> : <Blog16Icon />}</ListType>
            <ListTitle>{title}</ListTitle>
            <ListSpace>{space}</ListSpace>
          </ListItem>
        ))}
      </ListItemsContainer>
    </PagesWrapper>
  );
}

Pages.propTypes = {
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onSelected: PropTypes.func.isRequired,
};

export default Pages;
