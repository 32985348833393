import styled from "styled-components";
import Toggle from "@atlaskit/toggle";
import InputFilter from "../../../../Dashboard/filter/InputFilter";
import SelectFilter from "../../../../Dashboard/filter/SelectFilter";
import SelectWithCustomOptions from "../../../../Dashboard/filter/SelectWithCustomOptions";
import TextInputFilter from "../../../../Dashboard/filter/TextInputFilter";
import { FieldContainer } from "../../../../Dashboard/filter/styled";
import { useSidebarStore } from "../../../../Dashboard/panels/edit/sidebar/sidebar-store";
import { PanelPositioning } from "../../../../Dashboard/panels/panelTypes";
import { ViewOptions } from "./configurator/options";
import { PeoplePropertyKeys } from "./configurator/propertyTypes";
import { LabeledTitle } from "../active-users/ActiveUsersEditor";
import { PaginationOptions } from "../../../PanelsPagination/pagination-options";
import { Divider } from "../../../../Dashboard/filter/styled";

const Layout = ({ panelTitle, panelId, data, fieldsData, updatePanelTitle, updatePanelDataProperty }) => {
  const isInMainContent = useSidebarStore((state) => state.selectedContentType === PanelPositioning.MAIN);

  const formattedFieldOptions = fieldsData.singleValueFields.map((field) => ({
    label: field.title,
    value: field.id,
  }));

  return (
    <>
      <TextInputFilter label="Title" value={panelTitle} onUpdate={(value) => updatePanelTitle(panelId, value)} />
      <Divider />

      <FieldContainer>
        {isInMainContent && (
          <SelectWithCustomOptions
            initialValue={data.view}
            options={ViewOptions}
            label="View"
            onUpdate={(newValue) => updatePanelDataProperty(panelId, PeoplePropertyKeys.VIEW, newValue)}
          />
        )}
      </FieldContainer>

      <FieldContainer>
        <SelectWithCustomOptions
          initialValue={data.pagination}
          options={PaginationOptions}
          label="Pagination"
          onUpdate={(newValue) => updatePanelDataProperty(panelId, PeoplePropertyKeys.PAGINATION, newValue)}
        />
      </FieldContainer>

      <Divider />

      <div style={{ marginTop: "20px", display: "flex", flexDirection: "column", gap: "12px" }}>
        <LabeledTitle>{isInMainContent ? "Fields" : "Field"} to display</LabeledTitle>

        <>
          <SelectFilter
            value={data?.titleFieldId}
            setValue={(titleFieldId) =>
              updatePanelDataProperty(panelId, PeoplePropertyKeys.TITLE_FIELD_ID, titleFieldId)
            }
            options={formattedFieldOptions}
            noDetails
          />
        </>

        {isInMainContent && (
          <>
            <SelectFilter
              value={data?.footerFieldId}
              setValue={(footerFieldId) =>
                updatePanelDataProperty(panelId, PeoplePropertyKeys.FOOTER_FIELD_ID, footerFieldId)
              }
              options={formattedFieldOptions}
              noDetails
            />
          </>
        )}
      </div>

      {!isInMainContent && (
        <InputFilter
          numberField
          minLength={1}
          maxLength={5}
          initialValue={data.maxNumberOfItems}
          label="Items per page"
          placeholder="Limit the number of users"
          onUpdate={(value) => updatePanelDataProperty(panelId, PeoplePropertyKeys.MAX_NUMBER_OF_ITEMS, value)}
        />
      )}
    </>
  );
};

export default Layout;
