import { useRef, useReducer, useEffect } from "react";
import reducer, { initialState } from "../reducer";
import { pan, reset, centerView, startPan, zoom, customZoomUpdate } from "../actions";
import eventBus from "../../../Functions/eventBus";

export const usePanAndZoom = ({ minZoom = 0, maxZoom = 2, initialY = 0 }) => {
  const [state, dispatch] = useReducer(reducer, { ...initialState, translateY: initialY });
  const containerRef = useRef(null);

  const onMouseMoveInWindow = (event) => {
    event.preventDefault();
    dispatch(pan(event));
  };

  const onMouseUpInWindow = () => {
    window.removeEventListener("mouseup", onMouseUpInWindow);
    window.removeEventListener("mousemove", onMouseMoveInWindow);
  };

  const onMouseDown = (event) => {
    dispatch(startPan(event));
    window.addEventListener("mouseup", onMouseUpInWindow);
    window.addEventListener("mousemove", onMouseMoveInWindow);
  };

  const onWheel = (event) => {
    if (event.deltaY !== 0 && containerRef.current) {
      const containerRect = containerRef.current.getBoundingClientRect();
      dispatch(zoom(event, containerRect, { minZoom, maxZoom }));
    }
  };

  useEffect(() => {
    eventBus.on("caelor-pan-zoom-reset", () => dispatch(reset(initialY)));
    eventBus.on("caelor-pan-zoom-center-view", ({ newZoomLevel, newHorizontalPosition }) =>
      dispatch(centerView(initialY, newZoomLevel, newHorizontalPosition)),
    );
    eventBus.on("caelor-update-zoom", ({ zoomLevel }) => dispatch(customZoomUpdate(zoomLevel)));

    return () => {
      eventBus.remove("caelor-pan-zoom-reset");
      eventBus.remove("caelor-pan-zoom-center-view");
      eventBus.remove("caelor-update-zoom");
    };
  }, []);

  return {
    ...state,
    containerRef,
    onMouseDown,
    onWheel,
  };
};
