import BaseRestService from "./BaseRestService";

class AttachmentsService extends BaseRestService {
  async fetchAttachments(pageIds) {
    const url = `ac/confluence/attachments/output?pages=[${pageIds}]`;
    return this.client.get(url, await this.buildJWTAuthAxiosConfig());
  }

  async uploadAttachments(data) {
    const url = "ac/confluence/attachments/attachment";
    return this.client.post(url, data, await this.buildJWTAuthAxiosConfig());
  }
}

export const attachmentsService = new AttachmentsService();
