import { useEffect } from "react";

const useLinksObserver = (ref) => {
  useEffect(() => {
    if (ref.current) {
      const mutationObserver = new MutationObserver(() => {
        ref.current.querySelectorAll("a").forEach((link) => link.setAttribute("target", "_blank"));
      });

      mutationObserver.observe(ref.current, {
        childList: true,
        subtree: true,
      });

      return () => {
        mutationObserver.disconnect();
      };
    }
  }, [ref]);
};

export default useLinksObserver;
