export const DASHBOARD_WIZARD_PROPERTY_KEY = "caelor-cosmos-dashboard-wizard-seen";
export const PEOPLE_WIZARD_PROPERTY_KEY = "caelor-cosmos-people-wizard-seen";
export const PROFILE_WIZARD_PROPERTY_KEY = "caelor-cosmos-profile-wizard-seen";

export const getUserWizardConfigProperty = (accountId, wizardKey) => {
  return new Promise((resolve, reject) => {
    window.AP.request(`/rest/api/user/${accountId}/property/${wizardKey}`)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        if (error.xhr.status === 404) {
          resolve(false);
        } else {
          reject(error);
        }
      });
  });
};

export const updateUserWizardConfigProperty = (accountId, value, isNew, wizardKey) => {
  if (!accountId) return;

  window.AP.request({
    type: isNew ? "POST" : "PUT",
    contentType: "application/json",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-Atlassian-Token": "nocheck",
    },
    url: `/rest/api/user/${accountId}/property/${wizardKey}`,
    data: JSON.stringify({
      key: wizardKey,
      value,
    }),
  });
};

// for testing
export const deleteProp = (accountId, wizardKey) => {
  window.AP.request({
    type: "DELETE",
    url: `/rest/api/user/${accountId}/property/${wizardKey}`,
  });
};
