import React, { Fragment } from "react";
import { getIconSVG } from "./icons-service";
import { SingleIconContainer } from "./styled/Components";

import fontAwesomeIcons from "../../../Shared/Components/Icons/providers/font-awesome";
import { FA_PROVIDER } from "./Icon";

const FontAwesomeIcons = ({ onIconSelect, searchTerm = "" }) => {
  const icons = fontAwesomeIcons.filter((icon) => icon[0].toLowerCase().includes(searchTerm.trim().toLowerCase()));

  return (
    <>
      {icons.map((icon) => {
        return (
          <Fragment key={icon[0]}>
            <SingleIconContainer
              title={icon[0]}
              key={icon[0]}
              onClick={() => onIconSelect(`${FA_PROVIDER}/${icon[0]}`, FA_PROVIDER)}
              dangerouslySetInnerHTML={{
                __html: getIconSVG(icon, FA_PROVIDER),
              }}
            />
          </Fragment>
        );
      })}
    </>
  );
};

export default FontAwesomeIcons;
