import React, { useEffect, useState } from "react";
import styled from "styled-components";

import InfoIcon from "@atlaskit/icon/glyph/editor/info";
import NewsIcon from "@atlaskit/icon/glyph/book";

import Review from "./Review";
import { useQuery } from "react-query";
import { getUserReview } from "./review-api";
import { SimpleTabs, Tab } from "./Tabs";

import NewsTab from "./NewsTab";
import HelpTab from "./HelpTab";

import { useCorporateIdentityStore } from "../Settings/General/BrandAndColors/corporate-identity-store";
import { COLOR_TYPES } from "../Settings/General/BrandAndColors/color-types";

export const CardContent = styled.div`
  padding: 16px 32px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  ${({ justifyContent }) => justifyContent && `justify-content ${justifyContent}`}
`;

export const CardLabel = styled.span`
  padding: 4px 8px;
  color: ${({ color }) => color || "#5243aa"};
  background-color: ${({ color }) => (color ? `${color}22` : "#eae6ff")};
  border-radius: 5px;
  font-size: 12px;
  font-weight: 400;
  width: fit-content;
`;

export const CardTitle = styled.h1`
  color: ${({ theme }) => theme.spaceport.newsTitle};
  font-size: ${({ size }) => (size === "lg" ? "24px" : "18px")};
  font-weight: 700;
  margin-block: 0;
`;

export const CardText = styled.p`
  color: #6b778c;
  ${({ size }) => {
    if (size === "lg")
      return `
      font-size: 20px;
      line-height: 34px;
      width: 795px;
    `;
    return `
      font-size: 14px;
      line-height: 20px;
    `;
  }}
  font-weight: 400;
  margin-block: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const Wrapper = styled.div`
  position: relative;
  bottom: 3px;
  margin-top: 20px;
`;

export const Title = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  color: ${({ theme }) => theme.spaceport.title};
  margin: 30px 0 15px;
`;

export const LinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  gap: 20px;
`;

export const LinkInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 5px;
  right: 20px;
  position: relative;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const LinkLabel = styled.span`
  font-weight: 600;
  font-size: 15px;
  line-height: 17px;
  color: ${({ theme }) => theme.spaceport.linkLabel};
`;

export const Description = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #7c889b;
  white-space: normal;
  width: 21em;
`;

export const StaffPickWrapper = styled.div`
  background: #6b5ec0;
  white-space: nowrap;
  font-size: 11px;
  line-height: 16px;
  padding: 5px;
  padding-left: 5px;
  align-self: flex-start;
  display: inline-flex;
  margin: 0;
  top: 0px;
  right: 0px;
  position: absolute;
  width: 60px;
  border-bottom-left-radius: 5px;
`;

export const StaffPickerText = styled.span`
  color: white;
`;

export const FullScreenOverlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background-color: #253858;
  opacity: 0.5;
  z-index: 999;
`;

export const Parent = styled.div`
  padding-left: 17px;
`;

const TabType = {
  HELP: "help",
  NEWS: "news",
};

const TABS = [
  {
    id: TabType.HELP,
    label: "SPACEPORT",
    Icon: InfoIcon,
  },
  {
    id: TabType.NEWS,
    label: "NEWS",
    Icon: NewsIcon,
  },
];

function SpaceportDrawer() {
  const [feebackIsClosed, setFeedbackIsClosed] = useState(false);
  const [selectedTab, setSelectedTab] = useState("help");
  const [hasVoted, setHasVoted] = useState(false);

  const colors = useCorporateIdentityStore((state) => state.colors);
  const primaryColor = colors ? colors[COLOR_TYPES.PRIMARY] : "#0065ff";

  const { isLoading, data } = useQuery(["user-review"], getUserReview, {
    retry: 0,
    select: (response) => {
      const { data } = response;
      return { ...data };
    },
  });

  useEffect(() => {
    if (data?.exists) {
      setHasVoted(true);
    }
  }, [data]);

  return (
    <Wrapper>
      <SimpleTabs style={{ padding: "0px 15px 0px" }}>
        {TABS.map(({ label, id, Icon }) => (
          <Tab isSelected={selectedTab === id} primaryColor={primaryColor} onClick={() => setSelectedTab(id)} key={id}>
            <Icon /> {label}
          </Tab>
        ))}
      </SimpleTabs>

      {!isLoading && !hasVoted && !feebackIsClosed && <Review setFeedbackIsClosed={setFeedbackIsClosed} />}

      {selectedTab === TabType.HELP && <HelpTab primaryColor={primaryColor} />}
      {selectedTab === TabType.NEWS && <NewsTab />}
    </Wrapper>
  );
}

export default SpaceportDrawer;
