import React from "react";
import { MainPanelsWrapper, PanelColumn } from "../dashboard-styled";
import PanelProvider from "./PanelProvider";
import { PanelPositioning } from "./panelTypes";

function Main({ panels }) {
  return (
    <MainPanelsWrapper>
      <PanelColumn>
        {!!panels?.length &&
          panels
            .filter((panel) => panel.position === PanelPositioning.MAIN)
            .sort((a, b) => a.order - b.order)
            .map((panel) => <PanelProvider key={panel.id} panel={panel} position={PanelPositioning.MAIN} />)}
      </PanelColumn>
    </MainPanelsWrapper>
  );
}

export default Main;
