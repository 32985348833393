import styled from "styled-components";

export const FieldContainer = styled.div`
  margin-top: 20px;
`;

export const Label = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.shared.sidebar.colors.label};
  margin-bottom: 5px;
  background: "yellow";
`;

export const FiltersContainer = styled.div``;

export const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #5e6695;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 15px;
`;

export const ShowHideMoreButton = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme }) => theme.global.primaryColor};

  cursor: pointer;
`;

export const ImageLinkPreview = styled.div`
  background: ${({ link }) => `url("${link}")`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 0% 50%;
  width: 450px;
  height: 300px;
  min-height: 250px;
`;
