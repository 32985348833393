import React, { useState } from "react";
import styled from "styled-components";
import { CardPropertyTypes } from "../../propertyTypes";
import CustomSketchPicker from "../../../../../../../../../Shared/Components/CustomSketchPicker";
import { FieldContainer } from "../../../../../../Dashboard/filter/styled";
import { useTheme } from "styled-components";

const TwoColumnGrid = styled.div`
  display: grid;
  align-items: center;
  column-gap: 10px;
  grid-template-columns: repeat(2, minmax(0, 1fr));
`;

const ColorTileAndLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 12px;
`;

const ColorLabel = styled.span`
  color: ${({ theme }) => theme.shared.sidebar.colors.label};
  font-size: 12px;
  font-weight: 500;
`;

const ColorTile = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 3px;
  border: 2px solid ${({ theme }) => theme.shared.sidebar.divider};
  cursor: ${({ isDisabled }) => (isDisabled ? "not-allowed" : "pointer")};

  position: relative;

  &::after {
    content: "";
    position: absolute;
    top: 4px;
    right: 4px;
    bottom: 4px;
    left: 4px;
    background: ${({ color }) => color};
  }
`;

const AvailableColorTypes = {
  ELEMENTS: "elements",
  BACKGROUND: "background",
};

const LeftPosition = {
  [AvailableColorTypes.ELEMENTS]: "5px",
  [AvailableColorTypes.BACKGROUND]: "75px",
};

function Colors({ colors, updateCard }) {
  const { elements, background, defaultBackground } = colors || {};

  const [colorBeingEdited, setColorBeingEdited] = useState(undefined);

  const updateColors = (clr) => {
    if (colorBeingEdited === "background") {
      const newColors = { ...colors, [colorBeingEdited]: clr, ["defaultBackground"]: false };
      updateCard(CardPropertyTypes.COLORS, newColors);
    } else {
      const newColors = { ...colors, [colorBeingEdited]: clr };
      updateCard(CardPropertyTypes.COLORS, newColors);
    }
  };

  const theme = useTheme();
  const defaultBackgroundColor = (defaultBackground ?? true) && background === "#fff";

  return (
    <FieldContainer style={{ position: "relative " }}>
      <TwoColumnGrid>
        <ColorTileAndLabelWrapper>
          <ColorTile
            color={elements}
            onClick={() => {
              setColorBeingEdited(AvailableColorTypes.ELEMENTS);
            }}
          />
          <ColorLabel>Elements</ColorLabel>
        </ColorTileAndLabelWrapper>

        <ColorTileAndLabelWrapper>
          <ColorTile
            color={defaultBackgroundColor ? theme.shared.card.background : background}
            onClick={() => {
              setColorBeingEdited(AvailableColorTypes.BACKGROUND);
            }}
          />
          <ColorLabel>Background</ColorLabel>
        </ColorTileAndLabelWrapper>
      </TwoColumnGrid>

      {!!colorBeingEdited && (
        <CustomSketchPicker
          selectedColor={colors[colorBeingEdited]}
          setSelectedColor={(clr) => updateColors(clr)}
          callback={() => setColorBeingEdited(undefined)}
          additionalStyle={{
            top: "45px",
            left: LeftPosition[colorBeingEdited] || "0px",
          }}
          disableTransparent
        />
      )}
    </FieldContainer>
  );
}

export default Colors;
