import React from "react";
import { Panel, PanelContent, PanelTitle } from "../../shared/styled";
import { Divider, FieldContainer, Label } from "../../../../../Dashboard/filter/styled";
import SelectFilter from "../../../../../Dashboard/filter/SelectFilter";
import { OrgChartPropertyKeys } from "./available-properties";
import { useWidgetsStore } from "../../../widgets-store";
import SizeButtons from "../../../../../../../../Shared/Components/SizeButtons";
import SingleColorPicker, {
  DEFAULT_COLORS,
} from "../../../../../../../../Shared/Components/Background/SingleColorPicker";
import { ElementsToDisplayPropertyKeys, ViewTypes } from "../../../../../OrgChart/consts";

function DesignOptions({ orgChartSettings, updateOrgChartSettings }) {
  const databaseFields = useWidgetsStore((state) => state.databaseFields);

  const fieldOptions = databaseFields.filter(
    ({ fieldProps }) =>
      !!fieldProps?.type &&
      fieldProps.id !== "user" &&
      fieldProps.id !== "azureManager" &&
      fieldProps?.type !== "USER" &&
      fieldProps.id !== "hidden",
  );

  const { view, elementsToDisplay, borderRadius, lineColor } = orgChartSettings;

  return (
    <Panel>
      <PanelTitle>Layout and Design</PanelTitle>
      <PanelContent>
        <FieldContainer>
          <Label>View</Label>
          <SelectFilter
            value={view || ViewTypes.CARD}
            setValue={(selectedView) => updateOrgChartSettings(OrgChartPropertyKeys.VIEW, selectedView)}
            options={[
              { label: "Card", value: ViewTypes.CARD },
              { label: "List", value: ViewTypes.LIST },
            ]}
            noDetails
          />
        </FieldContainer>

        <FieldContainer>
          <Label>Elements to display</Label>

          <SelectFilter
            value={elementsToDisplay?.[ElementsToDisplayPropertyKeys.FIRST_FIELD] || ""}
            setValue={(selectedField) =>
              updateOrgChartSettings(OrgChartPropertyKeys.ELEMENTS_TO_DISPLAY, {
                ...elementsToDisplay,
                [ElementsToDisplayPropertyKeys.FIRST_FIELD]: selectedField,
              })
            }
            options={fieldOptions}
            noDetails
            isClearable
            placeholder="Select a field"
          />

          <FieldContainer>
            <SelectFilter
              placeholder="Select a field"
              value={elementsToDisplay?.[ElementsToDisplayPropertyKeys.SECOND_FIELD] || ""}
              setValue={(selectedField) =>
                updateOrgChartSettings(OrgChartPropertyKeys.ELEMENTS_TO_DISPLAY, {
                  ...elementsToDisplay,
                  [ElementsToDisplayPropertyKeys.SECOND_FIELD]: selectedField,
                })
              }
              options={fieldOptions}
              noDetails
              isClearable
            />
          </FieldContainer>
        </FieldContainer>

        <Divider />

        <SizeButtons
          label="Border radius"
          input={{ min: 0, max: 50, step: 1, value: borderRadius >= 0 ? borderRadius : 3 }}
          setValue={(value) => updateOrgChartSettings(OrgChartPropertyKeys.BORDER_RADIUS, value)}
          customOptions={[
            { label: "S", value: 3 },
            { label: "M", value: 8 },
            { label: "L", value: 12 },
          ]}
        />

        <FieldContainer>
          <Label>Color</Label>
          <SingleColorPicker
            label="Connecting line"
            value={lineColor || "#4C9AFF"}
            setValue={(newColor) => updateOrgChartSettings(OrgChartPropertyKeys.LINE_COLOR, newColor)}
            presetColors={DEFAULT_COLORS}
            disableAlpha
          />
        </FieldContainer>
      </PanelContent>
    </Panel>
  );
}

export default DesignOptions;
