import React, { useState } from "react";
import styled from "styled-components";
import SelectFilter from "../../../Dashboard/filter/SelectFilter";
import { Label } from "../../../Dashboard/filter/styled";
import { useSidebarPanelStore } from "../../database-store";
import { AvailableStyles } from "../../defaultSettings";
import Preview from "../../Cards/Preview";
import { useChangesStore } from "../../../../track-changes-store";
import { useRecordsStore } from "../../../../../UserDatabase/records-store";
import SidebarFooter from "../../../Shared/SidebarFooter";
import SizeButtons from "../../../../../../Shared/Components/SizeButtons";
import { LabeledTitle } from "../../styled/sidebar";

const SettingPropertyKeys = {
  STYLE: "selectedStyle",
  BORDER_RADIUS: "borderRadius",
  PADDING: "padding",
};

export const AustinPropertyKeys = {
  NAME_ENABLED: "isNameOn",
  FIELD_ONE: "fieldOne",
  FIELD_TWO: "fieldTwo",
  CARD_BG: "cardBackground",
  IMAGE_SIZE: "imageSize",
};

export const CalgaryPropertyKeys = {
  NAME_ENABLED: "isNameOn",
  FIELD_ONE: "fieldOne",
  FIELD_TWO: "fieldTwo",
  FIELD_THREE: "fieldThree",
  FIELD_FOUR: "fieldFour",
};

const ToggleWrapper = styled.div`
  width: 100%;
`;

export const AustinFilterOptions = ({ data, fields, updateSettings }) => {
  const { fieldOne, fieldTwo } = data;

  const fieldOptions = fields
    .filter((f) => f.type === "SELECT" || f.type === "TEXT" || f.type === "EMAIL")
    .map((field) => ({ label: field.title, value: field.id }));

  return (
    <>
      <div>
        <LabeledTitle>Fields to display</LabeledTitle>
        <ToggleWrapper>
          <SelectFilter
            placeholder="Select.."
            isClearable
            value={fieldOne.id}
            setValue={(newValue) => {
              updateSettings(AvailableStyles.AUSTIN, AustinPropertyKeys.FIELD_ONE, {
                id: newValue,
                enabled: fieldOne.enabled,
              });
            }}
            options={fieldOptions.filter((fieldOption) => fieldOption.value !== fieldTwo.id)}
            noDetails
            menuPlacement="bottom"
          />

          <div style={{ marginTop: "10px" }}>
            <SelectFilter
              placeholder="Select.."
              isClearable
              value={fieldTwo.id}
              setValue={(newValue) => {
                updateSettings(AvailableStyles.AUSTIN, AustinPropertyKeys.FIELD_TWO, {
                  id: newValue,
                  enabled: fieldTwo.enabled,
                });
              }}
              options={fieldOptions.filter((fieldOption) => fieldOption.value !== fieldOne.id)}
              noDetails
              menuPlacement="bottom"
            />
          </div>
        </ToggleWrapper>
      </div>
    </>
  );
};

export const CalgaryFilterOptions = ({ data, fields, updateSettings }) => {
  const { fieldOne, fieldTwo } = data;

  const simpleFieldOptions = fields
    .filter((f) => f.type === "SELECT" || f.type === "TEXT" || f.type === "EMAIL")
    .map((field) => ({ label: field.title, value: field.id }));

  return (
    <>
      <div>
        <h1 style={{ fontSize: "12px", margin: "0 0 5px 0", color: "#6b778c", fontWeight: "400" }}>
          Fields to display
        </h1>
        <ToggleWrapper>
          <SelectFilter
            placeholder="Select.."
            isClearable
            value={fieldOne.id}
            setValue={(v) =>
              updateSettings(AvailableStyles.CALGARY, CalgaryPropertyKeys.FIELD_ONE, {
                id: v,
                enabled: fieldOne.enabled,
              })
            }
            options={simpleFieldOptions.filter((fieldOption) => fieldOption.value !== fieldTwo.id)}
            noDetails
          />
        </ToggleWrapper>

        <ToggleWrapper style={{ marginTop: "10px" }}>
          <SelectFilter
            placeholder="Select.."
            isClearable
            value={fieldTwo.id}
            setValue={(v) =>
              updateSettings(AvailableStyles.CALGARY, CalgaryPropertyKeys.FIELD_TWO, {
                id: v,
                enabled: fieldTwo.enabled,
              })
            }
            options={simpleFieldOptions.filter((fieldOption) => fieldOption.value !== fieldOne.id)}
            noDetails
          />
        </ToggleWrapper>
      </div>
    </>
  );
};

function CardLayout({ saveSettings, closePanel, primaryColor = "#0065ff", primaryContrastColor = "#fff" }) {
  const [isSaving, setIsSaving] = useState(false);
  const { settings, updateSettings, updateSettingsStyle } = useSidebarPanelStore((state) => ({
    settings: state.settings,
    updateSettings: state.updateSettings,
    updateSettingsStyle: state.updateSettingsStyle,
  }));

  const setHasChanges = useChangesStore((state) => state.setHasChanges);

  const fields = useRecordsStore((state) => state.fields);

  const savingCallback = () => setIsSaving(false);

  const handleSettingsChanges = (propertyKey, value) => {
    updateSettings(propertyKey, value);
    setHasChanges(true);
  };

  const handleSettingsStyleChanges = (styleKey, propertyKey, value) => {
    updateSettingsStyle(styleKey, propertyKey, value);
    setHasChanges(true);
  };

  return (
    <div style={{ marginTop: "20px" }}>
      <div>
        <Label>Design style</Label>
        <SelectFilter
          value={settings.selectedStyle}
          setValue={(v) => handleSettingsChanges(SettingPropertyKeys.STYLE, v)}
          options={[
            { label: "Austin", value: AvailableStyles.AUSTIN },
            { label: "Calgary", value: AvailableStyles.CALGARY },
          ]}
          noDetails
        />
      </div>

      <Preview
        selectedStyle={settings.selectedStyle}
        borderRadius={settings.borderRadius}
        settingsData={settings[settings.selectedStyle]}
      />

      <div>
        {settings.selectedStyle === AvailableStyles.AUSTIN && (
          <AustinFilterOptions
            fields={fields}
            data={settings[AvailableStyles.AUSTIN]}
            updateSettings={handleSettingsStyleChanges}
          />
        )}

        {settings.selectedStyle === AvailableStyles.CALGARY && (
          <CalgaryFilterOptions
            fields={fields}
            data={settings[AvailableStyles.CALGARY]}
            updateSettings={handleSettingsStyleChanges}
          />
        )}

        <div style={{ borderTop: "1px solid #DFE1E6", marginTop: "19px" }} />

        <SizeButtons
          label="Border radius"
          customOptions={[
            { label: "S", value: 3 },
            { label: "M", value: 10 },
            { label: "L", value: 15 },
          ]}
          input={{
            value: settings.borderRadius,
            min: 0,
            max: 30,
          }}
          setValue={(value) => handleSettingsChanges(SettingPropertyKeys.BORDER_RADIUS, value)}
        />
      </div>

      <SidebarFooter
        isSaving={isSaving}
        setIsSaving={setIsSaving}
        save={saveSettings}
        close={closePanel}
        saveCallback={savingCallback}
        primaryColor={primaryColor}
        primaryContrastColor={primaryContrastColor}
      />
    </div>
  );
}

export default CardLayout;
