import React from "react";
import CardStyleChooser from "./CardStyleChooser";

function CardMapper({ paginatedCards = [], cardData, cardsType }) {
  const { cards: allCards, ...config } = cardData;

  return paginatedCards.map((card) => (
    <CardStyleChooser key={card.id} cardData={card} config={config} cardsType={cardsType} />
  ));
}

export default CardMapper;
