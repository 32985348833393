import Pages from "./Pages";
import { useCosmosLayoutStore } from "../../../../store/cosmos-layout-store";
import { useRef, useState } from "react";
import styled from "styled-components";
import SectionMessageCustom from "../Message";
import CustomPages from "./CustomPages";
import { DefaultPages } from "../../../../pages";
import { useClickOutside } from "../../../../../../Shared/Hooks/useClickOutside";
import { useEffect } from "react";

const SideWrapper = styled.div`
  display: inline-block;
`;

export const LinksNames = {
  EDIT_PAGE: "edit_page",
  LEARN_MORE: "learn_more",
};

function FeaturesPage() {
  const { cosmosLayout } = useCosmosLayoutStore((state) => ({
    cosmosLayout: state.cosmosLayout,
  }));

  const [isOpen, setIsOpen] = useState(false);

  const menuRef = useRef(null);

  useEffect(() => {
    const handleScroll = (e) => {
      if (isOpen) {
        setIsOpen(false);
      }
    };

    window.addEventListener("scroll", handleScroll, true);

    return () => {
      window.removeEventListener("scroll", handleScroll, true);
    };
  }, [isOpen]);

  useClickOutside(
    menuRef,
    () => {
      setIsOpen((prev) => !prev);
    },
    "more-button",
  );

  return (
    <>
      <SideWrapper>
        <Pages pages={cosmosLayout.filter((page) => page.isAvailable)} setIsOpen={setIsOpen} />
      </SideWrapper>

      <SideWrapper style={{ width: "calc(100% - 646px)" }}>
        <SectionMessageCustom
          title={"Pages"}
          description={
            "Enhance your intranet experince by choosing the right mix of predefined and custom pages in Cosmos. Use predefined pages like People Network and Newsroom, or to create new ones using Cosmos diverse widgets and layouts."
          }
        />

        {/* <NewPage /> Uncomment and add after Cosmos Builder is finished */}

        <CustomPages pages={cosmosLayout.filter((page) => !Object.values(DefaultPages).includes(page.id))} />
      </SideWrapper>
    </>
  );
}

export default FeaturesPage;
