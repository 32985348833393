import React, { useState, useEffect, useRef } from "react";
import Modal, { ModalTransition } from "@atlaskit/modal-dialog";
import { ContentWrapper } from "../../styled/pages";
import PageHeaderCentered from "../Shared/Header/PageHeaderCentered";
import { useInfiniteQuery } from "react-query";
import { mapSpace, searchSpaces, updateSpaceByKey } from "./apiSpaces";
import UpdateSpace from "./Modal/UpdateSpace";
import { useHeaderStore } from "../../store/header-store";
import { saveHeaderSettings } from "../../api";
import { Pages } from "../../pages";
import { useCurrentUserStore } from "../../current-user-store";
import { useCorporateIdentityStore } from "../Settings/General/BrandAndColors/corporate-identity-store";
import { useHeaderChangesStore } from "../Shared/Header/customization/header-changes-store";
import { SpaceDirectoryAPIContext } from "./context";
import AdminControls from "./AdminControls";
import FilterSidebar from "./Filters/FilterSidebar";
import { useSpaceDirectoryStore } from "./space-directory-store";
import { FilterTypes } from "./types";
import SpaceEntry from "./View/SpaceEntry";

function SpaceDirectory() {
  const [isSearching, setIsSearching] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const updateSpaceInfoRef = useRef();

  const { selectedSort, selectedFilter, updatingSpace, editSpaceModalOpen, setEditSpaceModalOpen } =
    useSpaceDirectoryStore((state) => ({
      selectedSort: state.selectedSort,
      selectedFilter: state.selectedFilter,
      updatingSpace: state.updatingSpace,
      editSpaceModalOpen: state.editSpaceModalOpen,
      setEditSpaceModalOpen: state.setEditSpaceModalOpen,
    }));

  const { headerType } = useCorporateIdentityStore((state) => ({
    headerType: state.headerType,
  }));

  const primaryColor = "#0065ff";
  const primaryContrastColor = "#ffffff";

  const setHasHeaderChanges = useHeaderChangesStore((state) => state.setHasHeaderChanges);

  const { headerData } = useHeaderStore((state) => ({
    headerData: state.headerData,
  }));

  const { isCurrentUserAdmin } = useCurrentUserStore((state) => ({
    isCurrentUserAdmin: state.isCurrentUserAdmin,
  }));

  const { isLoading, isError, fetchNextPage, refetch, hasNextPage, data } = useInfiniteQuery(
    [
      "spaces",
      {
        searchValue: isSearching ? searchValue : "",
        selectedFilter: selectedFilter,
        selectedSort: selectedSort,
      },
    ],
    searchSpaces,
    {
      retry: 0,
      cacheTime: 0,
      select: (response) => {
        const pages = [];
        response.pages.forEach((page) => {
          const body = JSON.parse(page.body);
          const resultsInPage = body.results.map((res) =>
            mapSpace(selectedFilter === FilterTypes.ARCHIVED ? res : res.space, true),
          );
          pages.push({ ...body, results: resultsInPage });
        });
        return { pages, pageParams: response.pageParams };
      },
      getNextPageParam: (lastPage) => {
        const body = JSON.parse(lastPage.body);
        if (body._links.next) {
          const urlParams = new URLSearchParams(encodeURI(body._links.next));
          const cursor = urlParams.get("cursor");
          return cursor;
        }
        return false;
      },
    },
  );

  useEffect(() => {
    if (isLoading || !data?.pages) return;

    // in case the loaded data is not overflowing fetch next page

    if (!!data && !!data.pages && hasNextPage && (!!selectedFilter || isSearching)) {
      const overflowContainer = document.getElementById("hub-overflow-container");
      if (overflowContainer.scrollHeight <= overflowContainer.clientHeight) {
        fetchNextPage();
      }
    }
  }, [data, isLoading, hasNextPage, isSearching, selectedFilter]);

  useEffect(() => {
    setIsSearching(!!selectedFilter);
  }, [selectedFilter]);

  const handleSaveHeaderSettings = (callback = () => {}) => {
    saveHeaderSettings(headerData)
      .then(() => {})
      .catch(() => {})
      .finally(() => {
        callback();
        setHasHeaderChanges(false);
      });
  };

  if (isError) {
    return <div>Something went wrong. Please refresh and try again.</div>;
  }

  return (
    <SpaceDirectoryAPIContext.Provider value={{ handleSaveHeaderSettings }}>
      <div style={{ paddingBottom: "80px" }}>
        <PageHeaderCentered
          {...headerData[Pages.SPACE]}
          searchPlaceholder="Search spaces"
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          isSearching={isSearching}
          setIsSearching={setIsSearching}
          disableSearchBar={selectedFilter === FilterTypes.ARCHIVED}
          headerType={headerType}
        />

        {isCurrentUserAdmin && <AdminControls />}

        <ContentWrapper>
          <FilterSidebar />
          <SpaceEntry
            data={data}
            fetchNextPage={fetchNextPage}
            hasNextPage={hasNextPage}
            searchValue={searchValue}
            isSearching={isSearching}
            isLoading={isLoading}
          />
        </ContentWrapper>

        <ModalTransition>
          {editSpaceModalOpen && (
            <Modal height={500} isChromeless onClose={() => setEditSpaceModalOpen(false)}>
              <UpdateSpace
                title="Edit space card"
                text="Save changes"
                ref={updateSpaceInfoRef}
                spaceInfo={updatingSpace}
                save={() => {
                  updateSpaceByKey({ ...updateSpaceInfoRef.current })
                    .then((r) => {
                      refetch();
                      setEditSpaceModalOpen(false);
                    })
                    .catch((e) => console.log(e));
                }}
                close={() => setEditSpaceModalOpen(false)}
                primaryColor={primaryColor}
                primaryContrastColor={primaryContrastColor}
              />
            </Modal>
          )}
        </ModalTransition>
      </div>
    </SpaceDirectoryAPIContext.Provider>
  );
}

export default SpaceDirectory;
