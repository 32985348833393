import { useEffect, useState } from "react";
import styled from "styled-components";
import { generateRandomIcon } from "../../../../../../../Shared/Components/SidebarIconPicker/random-icon-generator";
import { getIconByName } from "../../../../../../../Shared/Components/SidebarIconPicker/icons-service";

const IconContainer = styled.div`
  color: ${({ theme }) => theme.navigation.settings.entryIcon};
  display: grid;
  place-content: center;

  ${({ provider, useSmallerIcons }) => {
    if (provider === "font-awesome") {
      return `
        width: ${useSmallerIcons ? "17px" : "20px"};
        height: ${useSmallerIcons ? "17px" : "20px"};
      `;
    }
    return `
      width: ${useSmallerIcons ? "20px" : "24px"};
      height: ${useSmallerIcons ? "20px" : "24px"};
    `;
  }}

  & > svg {
    width: inherit !important;
    height: inherit !important;
  }
`;

const EntryIcon = ({
  icon,
  onClick = () => {},
  style = {},
  onRandomIconSelected = () => {},
  useSmallerIcons = false,
}) => {
  const [loadedIcon, setLoadedIcon] = useState(undefined);

  useEffect(() => {
    (async () => {
      if (!icon) {
        const randomIcon = await generateRandomIcon();
        setLoadedIcon(randomIcon.svg);
        onRandomIconSelected({ provider: randomIcon.provider, icon: randomIcon.icon });
        return;
      }
      if (icon.provider === "none" || !icon.provider) {
        setLoadedIcon(undefined);
        return;
      }
      const module = await import(`../../../../../../../Shared/Components/Icons/providers/${icon.provider}`);
      const foundIcon = getIconByName(icon.provider, icon.icon.split("/")[1], module.default);
      setLoadedIcon(foundIcon);
    })();
  }, [icon]);

  if (!loadedIcon) {
    return <div style={{ color: "#344563", ...style }} onClick={onClick} />;
  }

  return (
    <IconContainer
      className="icon-container"
      provider={icon?.provider || "none"}
      useSmallerIcons={useSmallerIcons}
      style={{ ...style }}
      onClick={onClick}
      dangerouslySetInnerHTML={{
        __html: loadedIcon,
      }}
    />
  );
};

export default EntryIcon;
