import {
  createRecordsWithAccountIds,
  deleteRecordsWithAccountIds,
  fetchUsersInSystem,
  getAllExistingUserAccountIds,
} from "./datagrid/api";

import { refreshAzureData, assignAzureDataToRecordsWithAccountIds, updateAzureConfig } from "./menubar/azure/api";

export async function resyncUsers(syncGuestUsers) {
  const response = await getAllExistingUserAccountIds();
  const activeUsers = await fetchUsersInSystem(syncGuestUsers);
  const userIdsInDatabase = response.data;

  const usersToRemove = activeUsers?.length ? userIdsInDatabase.filter((x) => !activeUsers.includes(x)) : [];
  const newUsers = activeUsers.filter((x) => !userIdsInDatabase.includes(x));

  if (newUsers.length > 0) {
    const chunks = activeUsers.reduce((acc, _, i) => (i % 90 ? acc : [...acc, activeUsers.slice(i, i + 90)]), []);

    for (let i = 0; i < chunks.length; i++) {
      await createRecordsWithAccountIds(chunks[i], false);
    }
  }
  if (usersToRemove.length > 0) {
    await deleteRecordsWithAccountIds(usersToRemove);
  }

  return newUsers.length > 0 || usersToRemove.length > 0;
}

export async function assignAzureDataToAllUsers(syncGuestUsers, isCacheEnabled, emailProperty) {
  const response = await getAllExistingUserAccountIds();
  const activeUsers = await fetchUsersInSystem(syncGuestUsers);
  const userIdsInDatabase = response.data;
  const usersToRemove = activeUsers?.length ? userIdsInDatabase.filter((x) => !activeUsers.includes(x)) : [];

  const chunks = activeUsers.reduce((acc, _, i) => (i % 90 ? acc : [...acc, activeUsers.slice(i, i + 90)]), []);

  await updateAzureConfig(true, false, emailProperty);

  for (let i = 0; i < chunks.length; i++) {
    await assignAzureDataToRecordsWithAccountIds(chunks[i], isCacheEnabled, emailProperty);
  }

  if (usersToRemove.length) {
    await deleteRecordsWithAccountIds(usersToRemove);
  }
}

export async function resyncAzureUsers(syncGuestUsers) {
  const response = await getAllExistingUserAccountIds();
  const activeUsers = await fetchUsersInSystem(syncGuestUsers);
  const userIdsInDatabase = response.data;
  const usersToRemove = activeUsers?.length ? userIdsInDatabase.filter((x) => !activeUsers.includes(x)) : [];

  const chunks = activeUsers.reduce((acc, _, i) => (i % 90 ? acc : [...acc, activeUsers.slice(i, i + 90)]), []);

  for (let i = 0; i < chunks.length; i++) {
    await refreshAzureData(chunks[i]);
  }

  if (usersToRemove.length) {
    await deleteRecordsWithAccountIds(usersToRemove);
  }
}
