import React from "react";
import { MenuStyleTypes } from "../../../../menu/types";
import EntryIcon from "../../../drawer/components/edit/shared/EntryIcon";

function ItemIcon({ entry, parentEntry, menuStyle }) {
  const hasIcon = () =>
    (menuStyle === MenuStyleTypes.DENVER &&
      entry.parent !== "0" &&
      parentEntry?.data?.type !== "text" &&
      (parentEntry?.data?.type === "image" || entry.data.icon?.type === "icon" || !entry.data.icon?.type)) ||
    ((entry.data.icon?.type === "icon" || !entry.data.icon?.type) &&
      parentEntry?.data?.displayIcons !== false &&
      (![MenuStyleTypes.PALO_ALTO, MenuStyleTypes.DENVER].includes(menuStyle) ||
        parentEntry?.data?.type === "icon" ||
        entry.parent === "0"));

  const hasAppIcon = () =>
    (menuStyle === MenuStyleTypes.DENVER &&
      entry.parent !== "0" &&
      parentEntry?.data?.type !== "text" &&
      entry.data.icon?.type === "app") ||
    (entry.data.icon?.type === "app" &&
      parentEntry?.data?.displayIcons !== false &&
      (![MenuStyleTypes.PALO_ALTO, MenuStyleTypes.DENVER].includes(menuStyle) ||
        parentEntry?.data?.type === "app" ||
        entry.parent === "0"));

  const hasEmoji = () =>
    (menuStyle === MenuStyleTypes.DENVER &&
      entry.parent !== "0" &&
      parentEntry?.data?.type !== "text" &&
      parentEntry?.data?.type !== "image" &&
      entry.data?.icon?.type === "emoji") ||
    (entry.data.icon?.type === "emoji" &&
      parentEntry?.data?.displayIcons !== false &&
      (![MenuStyleTypes.PALO_ALTO, MenuStyleTypes.DENVER].includes(menuStyle) ||
        parentEntry?.data?.type === "emoji" ||
        entry.parent === "0"));

  return (
    <>
      {hasIcon() && (
        <EntryIcon
          icon={
            menuStyle === MenuStyleTypes.DENVER && entry.parent !== "0" && parentEntry?.data?.type === "image"
              ? {
                  provider: "atlaskit",
                  icon: "atlaskit/ImageIcon",
                }
              : entry.data.icon
          }
          style={{
            marginRight: "10px",
          }}
          useSmallerIcons
        />
      )}

      {hasAppIcon() && (
        <div style={{ marginRight: "10px", width: "20px", height: "20px" }}>
          <img src={entry.data.icon?.icon} alt="" width={20} height={20} style={{ objectFit: "contain" }} />
        </div>
      )}

      {hasEmoji() && (
        <div
          style={{
            marginRight: "10px",
            width: "20px",
            height: "20px",
            lineHeight: "1",
            display: "grid",
            placeItems: "center",
            fontSize: "1.2em",
          }}
        >
          <em-emoji shortcodes={entry.data.icon?.shortcodes} />
        </div>
      )}

      {menuStyle === MenuStyleTypes.PALO_ALTO && entry.parent !== "0" && (
        <img
          width="16"
          height="16"
          alt=""
          src={
            typeof entry.data.icon === "string"
              ? entry.data.icon
              : "https://caelor-apps.web.app/images/icons/Caelor_Black_Symbol.png"
          }
          style={{ marginRight: "10px", objectFit: "contain" }}
        />
      )}
    </>
  );
}

export default ItemIcon;
