import React, { useEffect, useState } from "react";

import { DatePicker } from "@atlaskit/datetime-picker";
import Select from "@atlaskit/select";
import dayjs from "dayjs";
import { useTheme } from "styled-components";
import { COLOR_TYPES } from "../../../Hub/Components/Settings/General/BrandAndColors/color-types";
import { useCorporateIdentityStore } from "../../../Hub/Components/Settings/General/BrandAndColors/corporate-identity-store";
import styled from "styled-components";
import { convertColorOpacity } from "../../../../Shared/Functions/convertColorOpacity";
import { customDatePickerStyles } from "./styled";
import { MODIFIED_SELECT_STYLES } from "../../../Hub/Components/Dashboard/filter/CustomSelectStyles";

import { FieldLabel } from "../../../Hub/Components/Shared/Filters/styled-filters";

const DeprecatedPeriods = [{ label: "This week", value: "THIS_WEEK" }];

const PeriodOptions = [
  { label: "Next 7 days", value: "NEXT_WEEK" },
  { label: "Next 14 days", value: "NEXT_TWO_WEEKS" },
  { label: "Next 30 days", value: "NEXT_30_DAYS" },
  { label: "Next 90 days", value: "NEXT_THREE_MONTH" },
  { label: "This month", value: "THIS_MONTH" },
  { label: "Next month", value: "NEXT_MONTH" },
];

const DatePickerContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;
`;

const DateRange = ({ onChange, value, field, placeholder = null }) => {
  const [selectKey, setSelectKey] = useState(Math.random().toString(36).substring(7));
  const filter = value || { range: [] };

  const [start, end] = filter.range;
  const isRecurring = field.recurring;

  const theme = useTheme();
  const colors = useCorporateIdentityStore((state) => state.colors);
  const primaryColor = colors[COLOR_TYPES.PRIMARY] || "#0065ff";

  const convertedColor = convertColorOpacity(primaryColor, theme.global.name);

  useEffect(() => {
    window.AP.events.on("restore-filters-people-base", () => {
      setSelectKey(Math.random().toString(36).substring(7));
    });
  }, []);

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "12px" }}>
      {isRecurring ? (
        <>
          <Select
            className="select-search"
            placeholder={placeholder}
            isClearable
            key={selectKey}
            value={[...PeriodOptions, ...DeprecatedPeriods].find((opt) => opt.value === filter.period)}
            onChange={(e) => onChange({ ...filter, period: e?.value })}
            options={PeriodOptions}
            styles={MODIFIED_SELECT_STYLES}
          />
        </>
      ) : (
        <div style={{ margin: "16px 0px" }}>
          <FieldLabel style={{ textTransform: "uppercase" }}>{field.title}</FieldLabel>
          <DatePickerContainer color={primaryColor} backgroundColor={convertedColor}>
            <DatePicker
              selectProps={{
                styles: customDatePickerStyles(primaryColor, theme),
              }}
              value={start ? dayjs.unix(start).toISOString() : null}
              dateFormat="DD/MM/YYYY"
              placeholder="From date"
              onChange={(date) => {
                const unix = dayjs(date).unix();
                onChange({ ...filter, range: [unix, end] });
              }}
            />

            <DatePicker
              selectProps={{ styles: customDatePickerStyles(primaryColor, theme) }}
              value={end ? dayjs.unix(end).toISOString() : null}
              dateFormat="DD/MM/YYYY"
              placeholder="To date"
              onChange={(date) => {
                const unix = dayjs(date).unix();
                onChange({ ...filter, range: [start, unix] });
              }}
            />
          </DatePickerContainer>
        </div>
      )}
    </div>
  );
};

export default DateRange;
