import React, { useEffect } from "react";
import styled from "styled-components";
import { Divider, Header, Wrapper } from "../styled/sections";
import TitleWithBack from "../../../../../Shared/Components/TitleWithBack";
import { usePageStore } from "../../../page-store";
import { Pages } from "../../../pages";
import Content from "./components/Content";
import LoadingScreen from "../Widgets/components/LoadingScreen";
import { getCategoriesSettings, getProposalsSettings } from "./api";
import { useQuery } from "react-query";
import { useCategoriesStore, useProposalsStore } from "./categories-store";

const Spacer = styled.div`
  padding: 32px 0px;
`;

function CategoriesOverview() {
  const setSelectedPage = usePageStore((state) => state.setSelectedPage);

  const { setAllCategories, selectedCategory, setSelectedCategory } = useCategoriesStore((state) => ({
    setAllCategories: state.setAllCategories,
    selectedCategory: state.selectedCategory,
    setSelectedCategory: state.setSelectedCategory,
  }));

  const { setAllProposals } = useProposalsStore((state) => ({
    setAllProposals: state.setAllProposals,
  }));

  const { isLoading: loadingCategories, data: categoriesResponse } = useQuery(
    "categories-settings",
    getCategoriesSettings,
    {
      retry: 0,
      select: (response) => {
        const { data } = response;
        return data?.categories?.data || [];
      },
    },
  );

  const { isLoading: loadingProposals, data: proposalsResponse } = useQuery(
    "proposals-settings",
    getProposalsSettings,
    {
      retry: 0,
      select: (response) => {
        const { data } = response;
        return data?.proposals?.data || [];
      },
    },
  );

  useEffect(() => {
    if (!loadingCategories && categoriesResponse) {
      setAllCategories([...(categoriesResponse || [])]);
    }
  }, [loadingCategories, categoriesResponse, setAllCategories]);

  useEffect(() => {
    if (!loadingProposals && proposalsResponse) {
      setAllProposals([...(proposalsResponse || [])]);
    }
  }, [loadingProposals, proposalsResponse, setAllProposals]);

  const isLoading = loadingCategories || loadingProposals;

  return (
    <Wrapper id="categories-overview-wrapper">
      <Header>
        {!!selectedCategory ? (
          <TitleWithBack
            handleOnClick={() => {
              setSelectedCategory(undefined);
              window.AP.events.emit("refetch-number-of-blogs");
            }}
            title={selectedCategory?.value}
            detailOpen={true}
          />
        ) : (
          <TitleWithBack handleOnClick={() => setSelectedPage(Pages.SETTINGS)} title="Categories" />
        )}
      </Header>
      <Divider />

      <Spacer>{isLoading ? <LoadingScreen /> : <Content />}</Spacer>
    </Wrapper>
  );
}

export default CategoriesOverview;
