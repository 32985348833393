import React from "react";

import {
  FilterButton,
  FilterContainer,
  ScreenContainer,
  ScreenTitle,
  SearchContainer,
  SearchContent,
  SearchLabel,
  PeopleList,
  PersonContainer,
  PersonData,
  PersonName,
  PersonJob,
  PersonInfo,
  PeopleHeading,
} from "../../styles";
import { PEOPLE_DATA } from "./defaults";
import Navigation from "../Components/Navigation";
import { useCosmosAppStore } from "../../cosmos-app-store";

const People = ({ screenType, userDatabaseFieldsData }) => {
  const cosmosAppSettings = useCosmosAppStore((state) => state.cosmosAppSettings);
  const selectedField = userDatabaseFieldsData?.find((field) => field.key === cosmosAppSettings?.people?.databaseField);

  return (
    <ScreenContainer>
      <ScreenTitle>People</ScreenTitle>
      <FilterContainer>
        <SearchContainer>
          <SearchContent>
            <img src="/images/cosmos-app/filters/search-icon.svg" alt="" />
            <SearchLabel>Search</SearchLabel>
          </SearchContent>
        </SearchContainer>
        <FilterButton backgroundColor={cosmosAppSettings?.brand?.color}>
          <img src="/images/cosmos-app/filters/filter-icon.svg" alt="" />
        </FilterButton>
      </FilterContainer>

      <PeopleHeading>Explore</PeopleHeading>

      <PeopleList>
        {PEOPLE_DATA.map(({ id, name, job, imagePath }) => (
          <PersonContainer key={id}>
            <PersonData>
              <img src={imagePath} alt={name} />
              <PersonInfo>
                <PersonName>{name}</PersonName>
                <PersonJob>{selectedField?.title}</PersonJob>
              </PersonInfo>
            </PersonData>
            <img src="/images/cosmos-app/people/chevron-right-icon.svg" alt="Chevron" />
          </PersonContainer>
        ))}
      </PeopleList>

      <Navigation screenType={screenType} />
    </ScreenContainer>
  );
};

export default People;
