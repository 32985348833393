import { useEffect } from "react";
import { useQuery } from "react-query";
import { fetchSpaces } from "../functions/api";

export const useFetchSpaces = ({ spaces = null, setSpaces = () => {}, returnRawData = false }) => {
  const { isLoading, data } = useQuery(["spaces", { spaces }], fetchSpaces, {
    retry: 0,
    select: (response) => {
      if (returnRawData) return response;
      return response
        ?.sort((a, b) => (a.type < b.type ? -1 : 1))
        .slice(0, 8)
        ?.map((opt) => ({ label: opt.name, value: opt.id }));
    },
  });

  useEffect(() => {
    if (!isLoading && data) {
      setSpaces(data || []);
    }
  }, [isLoading, data?.length]);

  return [isLoading];
};
