import httpClient from "../../../../../Service/http-client";

export const fetchRecentContent = () => {
  const cql = `type in (page, blogpost, comment) order by created desc, lastmodified desc&expand=content.history.lastUpdated`;
  return window.AP.request(`/rest/api/search?cql=${cql}`);
};

export const fetchLatestBlogs = ({ queryKey }) => {
  const [_key, { labels = [], contributors = [], spaces = [] }] = queryKey;
  let cql = "type = blogpost";
  if (labels.length > 0) {
    cql = cql + ` AND label in (${labels.map((l) => `"${l}"`).join(",")})`;
  }
  if (contributors.length > 0) {
    cql = cql + ` AND contributor.accountid in (${contributors.map((c) => `"${c}"`).join(",")})`;
  }
  if (spaces.length > 0) {
    cql = cql + ` AND space in (${spaces.map((s) => `"${s}"`).join(",")})`;
  }
  return window.AP.request(`/rest/api/search?cql=${cql} order by created desc&expand=content.history&limit=4`);
};

export const fetchRandomTwoUsers = () => {
  const url = `ac/confluence/userdatabase//collection/random-two-users`;
  return httpClient.get(url);
};

export async function fetchDetailsForUsers(accountIds) {
  try {
    const url = `/rest/api/search?cql=user.accountId in (${accountIds
      .map((id) => `"${id}"`)
      .join(",")})&limit=50&sitePermissionTypeFilter=all`;
    return window.AP.request(url);
  } catch (error) {
    return undefined;
  }
}
