import styled from "styled-components";
import { useState, useEffect } from "react";

import { Panel, PanelHeader } from "../General";
import Textfield from "@atlaskit/textfield";
import { Link } from "./Link";

import { saveUnderConstructionData } from "../../../../api";

const TextLabel = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.shared.settings.general.brandAndColors.underConstructionText};
`;

const Divider = styled.div`
  height: 2px;
  background: ${({ theme }) => theme.shared.sidebar.divider};
  width: 100%;
  margin-bottom: 24px;
  margin-top: 24px;
`;

const Wrapper = styled.div`
  margin-top: 20px;
  .user-input {
    margin-top: 12px !important;
  }
`;

const LinkWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-basis: 50%;
  flex: 1;
  padding-top: 12px;

  .user-input {
    max-width: 65% !important;
    height: 40px;
  }
`;

function Options({
  isEnabled,
  title,
  description,
  buttonText,
  link,
  setTitle,
  setDescription,
  setButtonText,
  setLink,
}) {
  const [linkSidebarVisible, setLinkSidebarVisible] = useState(false);

  const saveOptions = async (propetyToSave, value, setValue) => {
    setValue(value);

    try {
      await saveUnderConstructionData({ [propetyToSave]: value });
      setLinkSidebarVisible(false);
    } catch (err) {
      console.log(err);
    }
  };

  if (!isEnabled) {
    return null;
  }

  return (
    <Panel>
      <PanelHeader>Options</PanelHeader>

      <Wrapper>
        <TextLabel>Title</TextLabel>
        <Textfield
          className="user-input"
          value={title}
          onChange={(e) => {
            saveOptions("messageTitle", e.target.value, setTitle);
          }}
        />

        <div style={{ marginTop: "16px" }}>
          <TextLabel>Text</TextLabel>
          <Textfield
            className="user-input"
            value={description}
            onChange={(e) => {
              saveOptions("messageDescription", e.target.value, setDescription);
            }}
          />
        </div>
      </Wrapper>
      <Divider />

      <TextLabel>Link</TextLabel>
      <LinkWrapper>
        <Textfield
          className="user-input"
          value={buttonText}
          onChange={(e) => {
            saveOptions("messageButton", e.target.value, setButtonText);
          }}
        />
        <div style={{ width: "200px" }}>
          <Link
            link={link}
            onSelected={(newLink) => {
              if (newLink) {
                saveOptions("messageLink", newLink, setLink);
              } else {
                saveOptions("messageLink", "", setLink);
              }
              return;
            }}
            hasLabel={false}
            linkSidebarVisible={linkSidebarVisible}
            setLinkSidebarVisible={setLinkSidebarVisible}
          />
        </div>
      </LinkWrapper>
    </Panel>
  );
}
export default Options;
