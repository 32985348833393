import { useEffect, useState } from "react";

export const withWatching = (BaseComponent) => {
  return function WithWatching(props) {
    const [isWatching, setIsWatching] = useState(false);
    const [refetch, setRefetch] = useState(true);
    const { fetchWatchingStatus, handleNewStatus } = props;

    useEffect(() => {
      if (fetchWatchingStatus && refetch) {
        (async () => {
          const response = await fetchWatchingStatus();
          if (response?.body) {
            const { watching } = JSON.parse(response.body);
            setIsWatching(!!watching);
          } else {
            setIsWatching(false);
          }
          setRefetch(false);
        })();
      }
    }, [fetchWatchingStatus, refetch]);

    return (
      <BaseComponent
        isSelected={isWatching}
        onSelection={() => {
          handleNewStatus(!isWatching, setRefetch);
          setIsWatching(!isWatching);
        }}
        {...props}
      />
    );
  };
};
