import EmptyState from "../../../../../../../Shared/Components/EmptyState";
import { Panel, PanelContent, PanelTitle } from "../../../dashboard-styled";
import NoPagesSVG from "../../images/NoPagesSVG";

export const EmptyManual = ({ panelTitle }) => (
  <Panel>
    <PanelTitle>{panelTitle}</PanelTitle>
    <PanelContent>
      <EmptyState title="Add your pages" description="Try selecting some pages in the editor." Image={NoPagesSVG} />
    </PanelContent>
  </Panel>
);

export const EmptyForFilters = ({ panelTitle }) => (
  <Panel>
    <PanelTitle>{panelTitle}</PanelTitle>
    <PanelContent>
      <EmptyState
        title="We couldn't find anything matching filters"
        description="Try clearing some filters."
        Image={NoPagesSVG}
      />
    </PanelContent>
  </Panel>
);

export const EmptyNoNews = ({ panelTitle }) => (
  <Panel>
    <PanelTitle>{panelTitle}</PanelTitle>
    <PanelContent>
      <EmptyState
        title="Set up your pages"
        description="There are currently no pages on your instance."
        Image={NoPagesSVG}
      />
    </PanelContent>
  </Panel>
);
