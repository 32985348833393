import { useRef, useMemo, useEffect } from "react";

export const useEventListener = (
  eventName,
  handler,
  element = window,
  isListenerActive = true
) => {
  const savedHandler = useRef();
  const isSupported = useMemo(
    () => element && element.addEventListener,
    [element]
  );

  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    if (!isSupported || !isListenerActive) return;

    const eventListener = (event) => savedHandler.current(event);

    element.addEventListener(eventName, eventListener);

    return () => {
      element.removeEventListener(eventName, eventListener);
    };
  }, [eventName, element, isListenerActive]);
};
