import React, { useEffect, useRef } from "react";

import { usePageStore } from "../../../../page-store";
import { Pages } from "../../../../pages";
import { Content, Divider, Header, Wrapper } from "../styles";
import TitleWithBack from "../../../../../../Shared/Components/TitleWithBack";
import TabsContent from "./tabs/TabsContent";
import debounce from "lodash.debounce";
import { saveCosmosAppSettings } from "../api";
import { useDatabaseInitialization } from "../../../../../../Shared/Hooks/useDatabaseInitialization";
import { useCosmosAppSettings } from "../use-cosmos-app-settings";
import { useCosmosAppStore } from "../cosmos-app-store";
import { COSMOS_APP_DEFAULT_DATA, COSMOS_APP_KEY } from "../defaults";

const AppSetupAndControl = () => {
  const setSelectedPage = usePageStore((state) => state.setSelectedPage);
  const { cosmosAppSettings, setCosmosAppSettings } = useCosmosAppStore((state) => ({
    cosmosAppSettings: state.cosmosAppSettings,
    setCosmosAppSettings: state.setCosmosAppSettings,
  }));

  const { isDatabaseLoading, isDatabaseInitialized } = useDatabaseInitialization();
  const { isLoadingCosmosAppSettings, cosmosAppSettingsData } = useCosmosAppSettings({ isDatabaseInitialized });

  useEffect(() => {
    if (!isLoadingCosmosAppSettings && cosmosAppSettingsData.exists) {
      setCosmosAppSettings(cosmosAppSettingsData.cosmosAppSettings);
    }
  }, [isDatabaseLoading, isLoadingCosmosAppSettings]);

  const handleSaveCosmosAppSettings = useRef(
    debounce((settings) => {
      saveCosmosAppSettings(settings);
    }, 500),
  ).current;

  useEffect(() => {
    return () => {
      handleSaveCosmosAppSettings.cancel();
    };
  }, [handleSaveCosmosAppSettings]);

  useEffect(() => {
    if (!isLoadingCosmosAppSettings) {
      if (!(COSMOS_APP_KEY.PUBLISH_UNPUBLISH in cosmosAppSettings)) {
        setCosmosAppSettings({
          ...cosmosAppSettings,
          [COSMOS_APP_KEY.PUBLISH_UNPUBLISH]: [COSMOS_APP_KEY.PUBLISH_UNPUBLISH],
        });
      }

      if (!(COSMOS_APP_KEY.UNDER_CONSTRUCTION in cosmosAppSettings)) {
        setCosmosAppSettings({
          ...cosmosAppSettings,
          [COSMOS_APP_KEY.UNDER_CONSTRUCTION]: COSMOS_APP_DEFAULT_DATA[COSMOS_APP_KEY.UNDER_CONSTRUCTION],
        });
      }

      if (!(COSMOS_APP_KEY.MAINTENANCE in cosmosAppSettings)) {
        setCosmosAppSettings({
          ...cosmosAppSettings,
          [COSMOS_APP_KEY.MAINTENANCE]: COSMOS_APP_DEFAULT_DATA[COSMOS_APP_KEY.MAINTENANCE],
        });
      }
    }
  }, [isLoadingCosmosAppSettings, cosmosAppSettings]);

  useEffect(() => {
    if (Object.keys(cosmosAppSettings || {}).length && !isLoadingCosmosAppSettings) {
      handleSaveCosmosAppSettings(cosmosAppSettings);
    }
  }, [cosmosAppSettings, isLoadingCosmosAppSettings, handleSaveCosmosAppSettings]);

  return (
    <Wrapper>
      <Header>
        <TitleWithBack
          handleOnClick={() => setSelectedPage(Pages.SETTINGS)}
          title="App Setup & Control"
          description="Manage app availability and status with options to publish, unpublish, or set maintenance modes"
        />
      </Header>

      <Divider />

      <Content>
        <TabsContent isLoadingCosmosAppSettings={isLoadingCosmosAppSettings} />
      </Content>
    </Wrapper>
  );
};

export default AppSetupAndControl;
