import { useEffect } from "react";
import { useQuery } from "react-query";
import { getNavigationMenu } from "../functions/api";
import { loadNavigation } from "../functions/loader";

export const useFetchNavigation = ({ setNavigationData = () => {} }) => {
  const { isLoading, isError, data } = useQuery("menu", getNavigationMenu, {
    retry: 0,
    select: (response) => {
      const { data: responseData } = response;
      return responseData;
    },
  });

  useEffect(() => {
    if (!isLoading) {
      setNavigationData(loadNavigation(data));
    }
  }, [isLoading, data?.length]);

  return [isLoading, isError];
};
