import React from "react";
import EmptyStateContent from "../../../../../../Shared/Components/EmptyState";
import styled, { useTheme } from "styled-components";

const Image = styled.img`
  margin: 0 auto;
  object-fit: contain;
  max-width: 100%;
`;

function EmptyState({ title, description, button }) {
  const theme = useTheme();
  const colorScheme = theme.global.name;

  return (
    <EmptyStateContent
      Image={() => <Image src={`/images/hub/dashboard/select-empty-${colorScheme}.png`} alt="select" />}
      title={title}
      description={description}
      direction="column"
      button={button}
    />
  );
}

export default EmptyState;
