import React from "react";
import styled from "styled-components";
import Textfield from "@atlaskit/textfield";
import Textarea from "@atlaskit/textarea";
import Select from "@atlaskit/select";
const Label = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.space.updateSpaceModal.modalLabelColor};
  margin-bottom: 5px;
`;

const ModalWrapper = styled.div`
  height: 500px;
  background-color: ${({ theme }) => theme.space.updateSpaceModal.modalBackground};
  border-radius: 6px;
  padding: 24px;
`;

const ModalHeader = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: ${({ theme }) => theme.space.updateSpaceModal.modalHeaderColor};
  padding-bottom: 24px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  column-gap: 10px;
`;

const Button = styled.div`
  border-radius: 5px;
  padding: 6px 12px;
  font-size: 14px;
  cursor: pointer;
  text-align: center;
  background: ${({ bg }) => bg};
  color: ${({ color }) => color};
  width: fit-content;

  &:hover {
    background: ${({ bg }) => bg}cc;
  }
  &:active {
    background: ${({ bg }) => bg}ee;
  }
`;

const ModalFooter = styled.div`
  margin-top: 35px;
`;

const STATUS_OPTIONS = [
  { label: "Current", value: "current" },
  { label: "Archived", value: "archived" },
];

const UpdateSpace = React.forwardRef(
  ({ spaceInfo, title, text, save, close, primaryColor, primaryContrastColor }, updateSpaceInfoRef) => {
    updateSpaceInfoRef.current = {
      key: spaceInfo.key,
      name: spaceInfo.name,
      description: spaceInfo.description,
      status: spaceInfo.status,
    };

    return (
      <ModalWrapper>
        <ModalHeader>{title}</ModalHeader>

        <Label>Space name</Label>
        <Textfield
          placeholder="Type space name here..."
          defaultValue={spaceInfo.name}
          onChange={(e) => (updateSpaceInfoRef.current = { ...updateSpaceInfoRef.current, name: e.target.value })}
        />
        <br />
        <Label>Space description</Label>
        <Textarea
          placeholder="Type space description here..."
          defaultValue={spaceInfo.description}
          onChange={(e) =>
            (updateSpaceInfoRef.current = { ...updateSpaceInfoRef.current, description: e.target.value })
          }
        />
        <br />
        <Label>Space status</Label>
        <Select
          defaultValue={STATUS_OPTIONS.find((op) => op.value === spaceInfo.status)}
          onChange={(opt) => (updateSpaceInfoRef.current = { ...updateSpaceInfoRef.current, status: opt.value })}
          options={STATUS_OPTIONS}
        />

        <ModalFooter>
          <ButtonsWrapper>
            <Button
              bg="#F1F2F4"
              color="#42526E"
              onClick={() => {
                close();
              }}
            >
              Cancel
            </Button>
            <Button bg={primaryColor} color={primaryContrastColor} onClick={() => save()}>
              {text}
            </Button>
          </ButtonsWrapper>
        </ModalFooter>
      </ModalWrapper>
    );
  },
);

export default UpdateSpace;
