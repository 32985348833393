import { getCoverPictureOfContent } from "../../../../../../ContentBuilder/Shared/cover-picture-provider";
import { htmlDecode } from "../../../../../../ContentBuilder/utils/decode-html-string";

export const getBlogsCategories = async (allCategories, blogId) => {
  if (!allCategories?.length) return [];

  try {
    const request = await window.AP.request(`/api/v2/blogposts/${blogId}/properties`);
    if (request?.body) {
      const data = JSON.parse(request.body)?.results;

      if (data) {
        const propertyCategories = data.find((property) => property.key === "cosmosnewsmetadata");
        if (propertyCategories) {
          const {
            value: { categories },
          } = propertyCategories;

          const categoriesAsArray = categories.split(",");

          if (categoriesAsArray?.length) {
            const categoriesOfBlog = [];
            for (const categoryId of categoriesAsArray) {
              const foundCategory = allCategories.find((cat) => cat.id === categoryId);
              if (foundCategory) categoriesOfBlog.push(foundCategory.value);
            }

            return categoriesOfBlog;
          }
        }
      }
    }

    return [];
  } catch (error) {
    return [];
  }
};

export const mapBlogpost = (blogpost, locale, categories) => {
  return {
    id: blogpost.content.id,
    type: blogpost.content.type,
    name: blogpost.content.title,
    space: { name: blogpost.content.space.name },
    excerpt: htmlDecode(blogpost.excerpt),
    coverPictureCallback: async () => getCoverPictureOfContent(blogpost.content.id),
    getBlogsCategories: async () => getBlogsCategories(categories, blogpost.content.id),
    labels: blogpost.content.metadata?.labels?.results.map((l) => l.label) ?? [],
    url: `${window.AP._hostOrigin}/wiki${blogpost.url}`,
    createdBy: blogpost.content.history.createdBy.displayName,
    avatarUrl: `${window.AP._hostOrigin}${blogpost.content.history.createdBy.profilePicture.path}`,
    lastModified: blogpost.friendlyLastModified,
    created: blogpost.content.history.createdDate,
    numberOfComments: blogpost.content.children.comment.results.length,
    numberOfLikes: blogpost.content.metadata.likes.count,
    createdFormatted: new Date(blogpost.content.history.createdDate).toLocaleString(`${locale ?? "en-US"}`, {
      year: "numeric",
      month: "short",
      day: "numeric",
    }),
  };
};
