import { useEffect, useState } from "react";
import { atlassianRestService } from "../../Service/AtlassianRestService";

const useUserGroups = () => {
  const [loadingUserGroups, setLoadingUserGroups] = useState(true);
  const [userGroups, setUserGroups] = useState([]);

  useEffect(() => {
    const loadUserGroups = async () => {
      try {
        setLoadingUserGroups(true);
        const response = await atlassianRestService.getGroups();

        if (response?.results) {
          const builtGroups = response.results.map(({ id, name }) => ({
            id,
            label: name,
            value: id,
          }));
          setUserGroups(builtGroups);
        } else {
          setUserGroups([]);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingUserGroups(false);
      }
    };

    loadUserGroups();
  }, []);

  return { loadingUserGroups, userGroups };
};

export default useUserGroups;
