import React, { useEffect, useState } from "react";
import Spinner from "@atlaskit/spinner";
import { useInfiniteQuery, useQueryClient } from "react-query";
import { useAtlassianLocale } from "../../../../../../../Shared/Hooks/useAtlassianLocale";
import { searchPages } from "../../../api";
import { Panel, PanelContent, PanelTitle } from "../../../dashboard-styled";
import styled, { useTheme } from "styled-components";
import { MiniPage, BasicPage, SidePage } from "@caelor/cards-and-panels-components";
import { LoadingTypes } from "../../../filter/DynamicFilterToggle";
import { PagesManual } from "./PagesManual";
import { mapPage, usePages } from "./handlePageData";
import { EmptyForFilters, EmptyManual, EmptyNoNews } from "./EmptyStates";
import Pagination from "../../../../Shared/PanelsPagination/Pagination";
import { SideNewsListContainer } from "./styled";
import { useCorporateIdentityStore } from "../../../../Settings/General/BrandAndColors/corporate-identity-store";
import { COLOR_TYPES } from "../../../../Settings/General/BrandAndColors/color-types";
import { useSidebarStore } from "../../edit/sidebar/sidebar-store";
import { Actions } from "./PagesActions";
import { PanelsPaginationTypes } from "../../../../Shared/PanelsPagination/panels-pagination-types";
import PanelWrapper from "../../../../Shared/Panels/shared/components/PanelWrapper";

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  column-gap: 14px;
  row-gap: 20px;
`;

export const MiniGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 15px 15px;
  margin-top: 15px;
`;

export const CompactListContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PagesTypes = {
  CARD: "card",
  MINI_CARD: "mini-card",
};

function Pages({ id, panelTitle, data, position }) {
  const [page, setPage] = useState(1);
  const [locale] = useAtlassianLocale();
  const [showMoreClicked, setShowMoreClicked] = useState(false);

  const queryClient = useQueryClient();

  const theme = useTheme();

  const setDisableClickOutside = useSidebarStore((state) => state.setDisableClickOutside);
  const borderRadius = useCorporateIdentityStore((state) => state.borderRadius);
  const colors = useCorporateIdentityStore((state) => state.colors);
  const primaryColor = colors ? colors[COLOR_TYPES.PRIMARY] : "#0065ff";
  const maxNumberOfItems = data.maxNumberOfItems || 4;

  const numberOfItems = {
    [PagesTypes.CARD]: data.isSideNews ? maxNumberOfItems : 3,
    [PagesTypes.MINI_CARD]: data.isSideNews ? maxNumberOfItems : 6,
  };

  const {
    isLoading,
    fetchNextPage,
    hasNextPage,
    data: pagesData,
  } = useInfiniteQuery(
    [
      `pages-${id}`,
      {
        labels: data.labels,
        contributors: data.contributors,
        spaces: data.spaces,
        parentPageId: data.parentPageId,
        limit: position === "side" ? maxNumberOfItems : data.view === PagesTypes.CARD ? 3 : 6,
        excludePersonalSpaces: data.excludePersonalSpaces || false,
        sorting: data.sortBy,
        ordering: data.ordering,
      },
    ],
    searchPages,
    {
      enabled: data.loadingType === LoadingTypes.DYNAMIC,
      retry: 0,
      select: (response) => {
        const pages = [];
        response.pages.forEach((page) => {
          const body = JSON.parse(page.body);
          const resultsInPage = body.results.map((page) => mapPage(page, locale));
          pages.push({ ...body, results: resultsInPage });
        });
        return { pages, pageParams: response.pageParams };
      },
      getNextPageParam: (lastPage) => {
        const body = JSON.parse(lastPage.body);
        if (body._links.next) {
          const urlParams = new URLSearchParams(encodeURI(body._links.next));
          const cursor = urlParams.get("cursor");
          return cursor;
        }
        return false;
      },
    },
  );

  const paginatedPagesData =
    data.pagination === PanelsPaginationTypes.ARROWS ? [pagesData?.pages[page - 1]] : pagesData?.pages;

  const pageQueriesUnfiltered = usePages(data.manuallyPicked || [], locale);
  const pageQueries = pageQueriesUnfiltered.filter((page) => page.data && !!Object.keys(page.data || {}).length);

  const numOfTotalPages = Math.ceil(pagesData?.pages[0]?.totalSize / numberOfItems[data.view]);

  useEffect(() => {
    setPage(1);
    setShowMoreClicked(false);
    queryClient.resetQueries([`pages-${id}`]);
  }, [
    data.pagination,
    data.maxNumberOfItems,
    data.contributors,
    data.spaces,
    data.labels,
    data.ordering,
    data.view,
    data.sortBy,
  ]);

  if (data.loadingType === LoadingTypes.MANUAL) {
    if (!pageQueries?.length) {
      return <EmptyManual panelTitle={panelTitle} />;
    }
    return (
      <PanelWrapper
        panelTitle={panelTitle}
        displayOptions={{
          displayTitle: data?.displayTitle ?? true,
          displayBox: data?.displayBox ?? true,
        }}
      >
        <PanelContent>
          <PagesManual primaryColor={primaryColor} data={data} pages={pageQueries} showMoreClicked={showMoreClicked} />
        </PanelContent>
      </PanelWrapper>
    );
  }

  const activeElements = data.activeElements;

  const hasFilters = !!data.labels || !!data.contributors || !!data.spaces;

  if (!!pagesData?.pages?.length && !pagesData.pages[0].results.length) {
    if (hasFilters) {
      return <EmptyForFilters panelTitle={panelTitle} />;
    }

    return <EmptyNoNews panelTitle={panelTitle} />;
  }
  return (
    <PanelWrapper
      panelTitle={panelTitle}
      displayOptions={{
        displayTitle: data?.displayTitle ?? true,
        displayBox: data?.displayBox ?? true,
      }}
    >
      {isLoading && (
        <div style={{ display: "grid", placeContent: "center" }}>
          <Spinner size="xlarge" />
        </div>
      )}

      <>
        {!!data?.isSideNews &&
          !isLoading &&
          !!pagesData?.pages?.length &&
          paginatedPagesData?.map((pages, index) => (
            <SideNewsListContainer key={`page-${index}`} style={{ marginTop: index === 0 ? "20px" : "16px" }}>
              {pages?.results?.map((page) => (
                <SidePage
                  key={page.id}
                  name={page.name}
                  spaceName={activeElements?.includes("space") ? page.space.name : null}
                  url={page.url}
                  authorUrl={page.authorUrl}
                  spaceUrl={page.spaceUrl}
                  excerpt={page.excerpt}
                  linkColor={primaryColor}
                  createdBy={activeElements?.includes("author") ? page.createdBy : null}
                  lastModified={activeElements?.includes("date") ? page.createdFormatted : null}
                  avatarUrl={page.avatarUrl}
                  darkTheme={{
                    titleColor: theme.shared.card.title,
                  }}
                />
              ))}
            </SideNewsListContainer>
          ))}

        {data.view === PagesTypes.CARD &&
          !isLoading &&
          !!pagesData?.pages?.length &&
          paginatedPagesData?.map((pages, index) => (
            <Grid style={{ marginTop: index === 0 ? "16px" : "20px" }}>
              {pages?.results?.map((page, idx) => (
                <BasicPage
                  key={page.id}
                  name={page.name}
                  spaceName={activeElements?.includes("space") ? page.space.name : null}
                  numberOfComments={activeElements?.includes("comments") ? page.numberOfComments : null}
                  numberOfLikes={activeElements?.includes("likes") ? page.numberOfLikes : null}
                  url={page.url}
                  authorUrl={page.authorUrl}
                  spaceUrl={page.spaceUrl}
                  excerpt={page.excerpt}
                  linkColor={primaryColor}
                  createdBy={activeElements?.includes("author") ? page.createdBy : null}
                  lastModified={activeElements?.includes("date") ? page.createdFormatted : null}
                  avatarUrl={page.avatarUrl}
                  coverPictureBorderRadius={borderRadius.replace("px", "")}
                  darkTheme={{
                    backgroundColor: theme.shared.card.background,
                    titleColor: theme.shared.card.title,
                    descriptionColor: theme.shared.card.text,
                    borderColor: theme.shared.card.border.box,
                    authorColor: primaryColor,
                    modifiedColor: theme.shared.card.text,
                    boxShadow: theme.manualOrgChart.boxShadow,
                  }}
                >
                  <Actions page={page} />
                </BasicPage>
              ))}
            </Grid>
          ))}

        {data.view === PagesTypes.MINI_CARD &&
          !data?.isSideNews &&
          !isLoading &&
          !!pagesData?.pages?.length &&
          paginatedPagesData?.map((pages, index) => (
            <MiniGrid style={{ marginTop: index === 0 ? "16px" : "20px" }}>
              {pages?.results?.map((page, idx) => (
                <MiniPage
                  key={page.id}
                  name={page.name}
                  spaceName={activeElements?.includes("space") ? page.space.name : null}
                  url={page.url}
                  authorUrl={page.authorUrl}
                  spaceUrl={page.spaceUrl}
                  linkColor={primaryColor}
                  excerpt={page.excerpt}
                  createdBy={activeElements?.includes("author") ? page.createdBy : null}
                  avatarUrl={page.avatarUrl}
                  coverPictureBorderRadius={borderRadius.replace("px", "")}
                  darkTheme={{
                    backgroundColor: theme.shared.card.background,
                    titleColor: theme.shared.card.title,
                    textColor: theme.shared.card.text,
                    borderColor: theme.shared.card.border.box,
                    authorColor: primaryColor,
                    boxShadow: theme.manualOrgChart.boxShadow,
                  }}
                >
                  <Actions page={page} />
                </MiniPage>
              ))}
            </MiniGrid>
          ))}
      </>

      <Pagination
        showMoreClicked={showMoreClicked}
        hasNextPage={page < numOfTotalPages}
        type={data.pagination === PanelsPaginationTypes.SHOW_MORE ? PanelsPaginationTypes.INFINITE : data.pagination}
        page={page}
        updatePage={setPage}
        maxPage={numOfTotalPages}
        onWrapperHoverCallback={setDisableClickOutside}
        infiniteQuery={{ shouldFetchNext: hasNextPage, fetchNextPage }}
        onClick={() => {
          if (hasNextPage && !showMoreClicked) {
            fetchNextPage();
            setShowMoreClicked(true);
            return;
          }
          window.open(`${window.AP._hostOrigin}/wiki/home/recent`, "_top");
        }}
      />
    </PanelWrapper>
  );
}

export default Pages;
