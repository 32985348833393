import dayjs from "dayjs";
import { FieldType } from "../../../../UserDatabase/field-type";

const textHandler = (value) => value;

const urlHandler = (value) => value?.link || "";

const selectHandler = (value, options) => options.find((op) => op.id === value)?.label || "";

const multiSelectHandler = (value, options) => {
  if (value?.length) {
    const fieldValues = value
      .map((optionId) => {
        const foundOption = options.find((op) => op.id === optionId);
        return foundOption;
      })
      .filter((op) => op);
    return fieldValues;
  }
  return [];
};

const numberHandler = (value, format) => {
  if (!value) return "";

  let number = "";
  if (format === "decimal") {
    number = value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  if (format === "integer") {
    number = value.replace(/\D/g, "");
  }
  if (format === "percent") {
    number = `${value} %`;
  }

  return number;
};

const dateHandler = (value) => (value ? dayjs.unix(value).format("DD/MM/YYYY") : "");

export const fieldValueHandlers = {
  [FieldType.TEXT]: textHandler,
  [FieldType.URL]: urlHandler,
  [FieldType.SELECT]: selectHandler,
  [FieldType.MULTISELECT]: multiSelectHandler,
  [FieldType.NUMBER]: numberHandler,
  [FieldType.DATE]: dateHandler,
};

export const handlerOptions = {
  [FieldType.TEXT]: (field) => undefined,
  [FieldType.URL]: (field) => undefined,
  [FieldType.SELECT]: (field) => field.options,
  [FieldType.MULTISELECT]: (field) => field.options,
  [FieldType.NUMBER]: (field) => field.format,
  [FieldType.DATE]: (field) => undefined,
};
