import React from "react";
import styled from "styled-components";
import { useTabPanel } from "@atlaskit/tabs";

const TabContainer = styled.div`
  display: block !important;
  padding-left: 0 !important;
  transition:
    display 1s,
    opacity 0.5s linear;
  &[hidden] {
    display: none !important;
    transition:
      visibility 1s,
      opacity 0.5s linear;
  }
`;
export const TabsWrapper = styled.div`
  margin-bottom: 15px;
  margin-top: 15px;
  div:first-child {
    flex-basis: inherit !important;
    font-size: 14px;
    font-weight: 600;
    &:before {
      left: 2px;
    }
    div:first-child {
      padding-left: 0px !important;
      &:after {
        left: 0px !important;
      }
    }
  }
`;

export const SimpleTabs = styled.div`
  display: flex;
  column-gap: 15px;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: -2px;
  font-size: 12px;
  gap: 0;
`;

export const Tab = styled.span`
  opacity: ${({ isSelected, isDisabled }) => (isSelected ? 1 : isDisabled ? 0.2 : 0.5)};
  cursor: ${({ isDisabled }) => (isDisabled ? "initial" : "pointer")};
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  flex: 1;
  text-align: center;
  padding-bottom: 16px;
  border-bottom: 2px solid
    ${({ isSelected, theme }) =>
      isSelected ? theme.shared.sidebar.tabs.border.active : theme.shared.sidebar.tabs.border.inactive};
  color: ${({ isSelected, primaryColor, theme }) =>
    isSelected ? primaryColor : theme.shared.sidebar.tabs.text.inactive};
  border-bottom: 2px solid
    ${({ isSelected, primaryColor, theme }) => (isSelected ? primaryColor : theme.shared.sidebar.tabs.border.inactive)};
`;

export const CustomTabPanel = ({ children }) => {
  const tabPanelAttributes = useTabPanel();

  return <TabContainer {...tabPanelAttributes}>{children}</TabContainer>;
};
