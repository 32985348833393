/* eslint-disable react/forbid-prop-types */
import React, { useRef } from "react";
import shallow from "zustand/shallow";

import styled from "styled-components";
import { useClickOutside } from "../../../../../../Shared/Hooks/useClickOutside";
import { applyChangesForUser } from "../../../../../UserDatabase/datagrid/api";
import { useRecordsStore } from "../../../../../UserDatabase/records-store";
import { useChangesStore } from "../../../../track-changes-store";

import { useTheme } from "styled-components";

const SidebarContainer = styled.div`
  display: flex;
  position: fixed;
  overflow: hidden;
  top: 0;
  bottom: 0;
  right: 0;
  justify-content: start;
  width: ${({ width, isOpen }) => (isOpen ? width : 0)}px;
  background: ${({ theme }) => theme.shared.sidebar.background};
  z-index: 10005;
  box-shadow: -1px 0px 4px rgba(0, 0, 0, 0.25);
  transition:
    visibility 0.1s,
    width 0.2s;
`;

const Sidebar = styled.div`
  box-sizing: border-box;
  height: 100%;
  padding: 20px;
  min-width: ${({ width }) => width}px;
  position: relative;
`;

const ScrollbarWrapper = styled.div`
  height: calc(100% - ${({ hasNote }) => (hasNote ? 110 : 97)}px);
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.scrollbar};
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #999;
  }
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  color: #7a869a;
  border-top: 1px solid ${({ theme }) => theme.shared.sidebar.divider};
  padding-top: 20px;
  font-size: 12px;
`;

const Button = styled.div`
  border-radius: 8px;
  padding: 12px;
  font-size: 14px;
  cursor: pointer;
  text-align: center;
  flex: 1;
  background: ${({ bg }) => bg};
  color: ${({ color }) => color};

  &:hover {
    background: ${({ bg }) => bg}cc;
  }
  &:active {
    background: ${({ bg }) => bg}ee;
  }
`;

const SidebarTransition = ({
  isOpen,
  close,
  width,
  children,
  disabledClickOutside,
  containerStyles,
  scrollbarWrapperStyles,
  note,
  onSubmit,
}) => {
  const theme = useTheme();
  const dialogRef = useRef();

  const { changes, resetChanges } = useRecordsStore((state) => {
    return {
      changes: state.changes,
      resetChanges: state.resetChanges,
    };
  }, shallow);

  const { setHasChanges, hasChanges } = useChangesStore(
    (state) => ({
      setHasChanges: state.setHasChanges,
      hasChanges: state.hasChanges,
    }),
    shallow,
  );

  useClickOutside(dialogRef, () => {
    if (!disabledClickOutside) {
      close();
    }
  });

  return (
    <SidebarContainer ref={dialogRef} isOpen={isOpen} width={width} style={{ ...containerStyles }}>
      <Sidebar width={width}>
        <ScrollbarWrapper hasNote={note} style={{ ...scrollbarWrapperStyles }}>
          <>{children}</>
        </ScrollbarWrapper>

        {hasChanges && (
          <Footer>
            <div style={{ display: "flex", gap: "10px" }}>
              <Button
                bg={theme.shared.sidebar.cancelBackground}
                color={theme.shared.sidebar.positioning.title}
                onClick={() => close()}
              >
                Cancel
              </Button>
              <Button
                bg={theme.shared.sidebar.button.confirmBackground}
                color={theme.shared.sidebar.button.confirmText}
                onClick={async () => {
                  if (onSubmit) {
                    onSubmit();
                  } else {
                    await applyChangesForUser(changes);
                    setHasChanges(false);
                    resetChanges();
                    close();

                    const flag = window.AP.flag.create({
                      title: "Changes successfully saved",
                      type: "info",
                    });

                    setTimeout(() => {
                      flag.close();
                    }, 5000);
                  }
                }}
              >
                Save Changes
              </Button>
            </div>

            <span style={{ textAlign: "center", color: theme.emptyState.text }}>{note}</span>
          </Footer>
        )}
      </Sidebar>
    </SidebarContainer>
  );
};

export default SidebarTransition;
