import React, { useEffect, useRef, useState } from "react";
import shallow from "zustand/shallow";
import { CreatableSelect } from "@atlaskit/select";
import { nanoid } from "nanoid";

import { SELECT_STYLE } from "./selectStyles";
import { useRecordsStore } from "../../records-store";
import { useTheme } from "styled-components";

export const OptionColors = [
  "#FBD7D4",
  "#FFE6CE",
  "#FFF5C5",
  "#D1FBE0",
  "#C6FFF3",
  "#C3FAFF",
  "#E0EEFF",
  "#EAE6FF",
  "#FFE1FF",
  "#F1F1F6",
];

const SelectEditor = ({
  initialValue,
  field,
  isMulti = false,
  onEdit,
  onSelect = () => {},
  onAbort = () => {},
  onKeepEdit = () => {},
}) => {
  const options = field.options || [];

  const theme = useTheme();

  const [selected, setSelected] = useState();
  const [availableOptions, setAvailableOptions] = useState([]);

  const selectContainerRef = useRef();
  const selectRef = useRef();

  const updateField = useRecordsStore((state) => state.updateField, shallow);

  const createOption = (value) => {
    const handleCreate = (label) => {
      const option = {
        id: nanoid(7),
        color: OptionColors[Number(`${options.length}`.slice(-1))],
        label: label,
        position: options.length,
      };

      updateField(field.id, { options: [...options, option] });

      return option;
    };

    if (isMulti) {
      return value.map((option) => {
        if (!options.find(({ id }) => option.id === id)) {
          return handleCreate(option.label);
        }

        return option;
      });
    }

    if (!value?.id) {
      return handleCreate(value.label);
    }

    return value;
  };

  useEffect(() => {
    if (options) {
      const mappedOptions = options.map((opt) => ({ ...opt, value: opt.id }));

      if (initialValue) {
        let value;

        if (isMulti) {
          value = initialValue.map((val) => mappedOptions.find(({ id }) => id === val));
        } else {
          value = mappedOptions.find(({ id }) => id === initialValue);
        }

        if (value) {
          setSelected(value);
        }
      } else {
        setSelected();
      }
      setAvailableOptions(mappedOptions);
    }
  }, []);

  return (
    <div style={{ width: "100%" }} ref={selectContainerRef}>
      <CreatableSelect
        ref={selectRef}
        value={selected}
        autoFocus
        openMenuOnFocus
        isClearable={true}
        closeMenuOnSelect={!isMulti}
        options={availableOptions}
        menuShouldScrollIntoView={false}
        isMulti={isMulti}
        styles={{
          ...SELECT_STYLE,
          control: (provided) => ({
            ...provided,
            fontSize: "14px",
            fontWeight: "400",
            minHeight: "33px",
            color: "#42526E",
            backgroundColor: `${theme.shared.profile.selectBackground} !important`,
            borderColor: "rgba(9, 30, 66, 0.04)",
            borderRadius: 0,
          }),
          indicatorsContainer: (provided) => ({
            ...provided,
            height: "30px",
          }),
        }}
        onBlur={(e) => {
          if (selected === initialValue) {
            onAbort();
            return;
          }
          if (!selected) {
            onEdit(undefined);
            return;
          }
          onEdit(isMulti ? selected.map((r) => r.id) : selected.id);
        }}
        onChange={(value) => {
          if (value === null) {
            setSelected();
            onSelect(undefined);
            return;
          }
          const values = createOption(value);
          if (!values) {
            setSelected([]);
            onSelect(undefined);
            return;
          }
          setSelected(values);
          if (isMulti) {
            onSelect(values.map((r) => r.id));
            onKeepEdit();
          } else {
            onSelect(values.id);
          }
        }}
      />
    </div>
  );
};

export default SelectEditor;
