import React from "react";
import { ModalFooter } from "@atlaskit/modal-dialog";
import SectionMessage from "@atlaskit/section-message";
import { Button, ModalBody, ModalHeader, ModalTitle } from "./styled";
import { useHelpersStore, useNavigationColorsStore, useNavigationStore } from "../../store";

export const DeleteNavigation = () => {
  const closeModal = useHelpersStore((state) => state.closeModal);
  const deleteNavigation = useNavigationStore((state) => state.deleteNavigation);
  const resetColors = useNavigationColorsStore((state) => state.resetColors);

  return (
    <>
      <ModalHeader>
        <ModalTitle>Delete navigation</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <SectionMessage appearance="warning">Are you sure you want to delete this navigation?</SectionMessage>
      </ModalBody>
      <ModalFooter>
        <Button onClick={() => closeModal()} bg="#F1F2F4" color="#42526E">
          Cancel
        </Button>
        <Button
          bg="#DE350B"
          color="#fff"
          onClick={() => {
            deleteNavigation();
            resetColors();
            closeModal();
          }}
        >
          Delete
        </Button>
      </ModalFooter>
    </>
  );
};
