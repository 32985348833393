import React from "react";
import { useDrag, useDrop } from "react-dnd";
import DragHandlerIcon from "@atlaskit/icon/glyph/drag-handler";
import TrashIcon from "@atlaskit/icon/glyph/trash";
import styled, { useTheme } from "styled-components";
import { DeleteIcon } from "../../../../../Dashboard/panels/edit/sidebar/components/Icons";

const Item = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
  border-bottom: 1px solid ${({ borderColor }) => borderColor};
  ${({ isHoveringOver, orderDirection }) => {
    if (!isHoveringOver) {
      return null;
    }
    return orderDirection === "top-bottom" ? `border-bottom: 1px solid #aaa;` : `border-top: 1px solid #aaa;`;
  }};
  padding: 12px 0px;
  font-size: 14px;
  font-weight: 500;
  color: #172b4d;

  .actions {
    visibility: hidden;
  }
  &:hover {
    
    .actions {
      visibility: visible;
    }
`;

const IconContainer = styled.div`
  display: grid;
  place-content: center;
  cursor: grab;
  svg {
    color: #8993a4 !important;
    fill: #8993a4 !important;
  }
  &:hover {
    opacity: 0.9;
  }
`;

const RemoveContainer = styled.div`
  cursor: pointer;
  width: 24px;
  height: 24px;
  display: grid;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
  justify-self: flex-end;
`;

const Reorderable = ({ user, reorderPanels, index, removeUserManually }) => {
  const theme = useTheme();
  const [{ isOver }, drop] = useDrop(() => ({
    accept: ["user"],
    drop: () => ({ id: index, ...user }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  }));

  const [{ draggedItem }, drag] = useDrag({
    type: "user",
    item: () => {
      return { id: index, type: "user", ...user };
    },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (item && dropResult) {
        reorderPanels(item.id, dropResult.id);
      }
    },
    collect: (monitor) => ({
      draggedItem: monitor.getItem(),
    }),
  });

  const draggedItemPosition = draggedItem?.order ?? 0;
  const potentialTargetPosition = isOver ? user?.order : 0;

  return (
    <Item
      ref={drop}
      isHoveringOver={isOver}
      orderDirection={draggedItemPosition < potentialTargetPosition ? "top-bottom" : "bottom-top"}
      borderColor={theme.shared.sidebar.divider}
    >
      <IconContainer ref={drag}>
        <DragHandlerIcon />
      </IconContainer>
      <img src={user.icon} style={{ borderRadius: "5px", width: "24px", height: "24px" }} alt="" />
      <span style={{ color: theme.shared.card.title }}>{user.name}</span>
      <RemoveContainer className="actions" onClick={() => removeUserManually(user.accountId)}>
        <DeleteIcon />
      </RemoveContainer>
    </Item>
  );
};

export default Reorderable;
