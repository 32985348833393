import React from "react";
import Spinner from "@atlaskit/spinner";
import { StartScreenWrapper, SpinnerContainer } from "../styled/views";
import Employees from "./Sections/Employees";
import { usePanelsStore } from "../store/panels-store";
import PanelProvider from "../Panel/PanelProvider";
import { PanelTypes } from "../Panel/panelTypes";

function StartScreen({ totalSize, page, fetchNextPage, hasNextPage, hasActiveFilters, isSearching, isLoading }) {
  const { panels } = usePanelsStore((state) => ({
    panels: state.panels,
  }));

  const allEmployeesPanel = panels.find((panel) => panel.type === PanelTypes.EMPLOYEES);

  return hasActiveFilters || isSearching ? (
    <StartScreenWrapper>
      {isLoading && page === 1 ? (
        <SpinnerContainer>
          <Spinner size="large" />
        </SpinnerContainer>
      ) : (
        <Employees totalSize={totalSize} fetchNextPage={fetchNextPage} hasNextPage={hasNextPage} hasActiveFilters />
      )}
    </StartScreenWrapper>
  ) : (
    <StartScreenWrapper>
      {panels
        .filter((panel) => panel.type !== PanelTypes.EMPLOYEES)
        .map((panel) => (
          <PanelProvider
            key={panel.id}
            panel={panel}
            totalSize={totalSize}
            fetchNextPage={fetchNextPage}
            hasNextPage={hasNextPage}
            hasActiveFilters={false}
          />
        ))}
      {allEmployeesPanel && (
        <PanelProvider
          key={allEmployeesPanel.id}
          panel={allEmployeesPanel}
          totalSize={totalSize}
          fetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage}
          hasActiveFilters={false}
        />
      )}
    </StartScreenWrapper>
  );
}

export default StartScreen;
