import { useEffect } from "react";
import { useQuery } from "react-query";
import { fetchAllSpaces } from "../functions/api";

export const useFetchAllSpaces = ({ setSpaceOptions = () => {} }) => {
  const { isLoading, data } = useQuery(["all-spaces", {}], fetchAllSpaces, {
    retry: 0,
    select: (response) => {
      return response;
    },
  });

  useEffect(() => {
    if (!isLoading && data) {
      setSpaceOptions(data || []);
    }
  }, [isLoading, data, setSpaceOptions]);

  return [isLoading];
};
