import React, { useState, useEffect } from "react";
import Textfield from "@atlaskit/textfield";
import Range from "@atlaskit/range";

import styled from "styled-components";
import PropTypes from "prop-types";
import { Label } from "./Filter/styled";

const Container = styled.div`
  display: grid;
  grid-template-columns: ${({ shouldShowInput, useFragmentsLayout }) => {
    if (shouldShowInput) {
      return useFragmentsLayout ? "4fr 0.5fr" : "auto 40px";
    }
    return "100%";
  }};
  gap: 20px;
  align-items: center;

  input:first-child {
    height: 30px;
    --track-fg: #2f7aff;
    --thumb-border: #ffffff;
    text-align: center;
  }

  div:nth-child(2) {
    background: ${({ theme }) => theme.sidebar.inputBackground};
    border: 1px solid ${({ theme }) => theme.sidebar.rangeBorder};
    border-radius: 4px;
    height: 40px;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }
`;

function SidebarCustomRange({
  label,
  step,
  min,
  max,
  value,
  setValue,
  useFragmentsLayout,
  customContainerStyle,
  shouldShowInput = false,
  isDisabled = false,
}) {
  const [inputValue, setInputValue] = useState(value);

  useEffect(() => setInputValue(value), [value]);

  return (
    <>
      <Label style={{ marginBottom: "0px" }}>{label}</Label>
      <Container
        shouldShowInput={shouldShowInput}
        useFragmentsLayout={useFragmentsLayout}
        style={{ ...customContainerStyle }}
      >
        <Range
          value={inputValue}
          step={step}
          min={min}
          max={max}
          isDisabled={isDisabled}
          onChange={(val) => {
            if (!isDisabled) {
              setValue(val);
            }
          }}
        />

        {shouldShowInput && (
          <Textfield
            type="number"
            className="custom-range-field"
            value={inputValue}
            min={min}
            max={max}
            isDisabled={isDisabled}
            onChange={(e) => {
              const valString = Number(e.target.value).toString();
              const val = Number(valString);

              if (val >= Number(max)) {
                setValue(Number(max));
              } else if (val >= Number(min)) {
                setValue(val);
              } else {
                setInputValue(val);
              }
            }}
            onBlur={() => {
              if (inputValue < value) {
                setValue(min);
              }
            }}
          />
        )}
      </Container>
    </>
  );
}

SidebarCustomRange.propTypes = {
  step: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  trackColor: PropTypes.string,
  emptyTrackColor: PropTypes.string,
  value: PropTypes.number.isRequired,
  setValue: PropTypes.func.isRequired,
  customContainerStyle: PropTypes.instanceOf(Object),
  shouldShowInput: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

SidebarCustomRange.defaultProps = {
  trackColor: "#2F7AFF",
  emptyTrackColor: "#EBECF0",
  customContainerStyle: {},
  shouldShowInput: false,
  isDisabled: false,
};

export default SidebarCustomRange;
