import React from "react";
import { usePageStore } from "../../../../../page-store";
import { useCurrentUserStore } from "../../../../../current-user-store";
import { Pages } from "../../../../../pages";
import { SectionKeys, SectionPagesIDs } from "../../../../Settings/sections";
import { Container, Description, Title } from "../../view/people/styles/people";
import { useTheme } from "styled-components";

const AdminView = () => {
  const setSelectedPage = usePageStore((state) => state.setSelectedPage);
  return (
    <div>
      <Title>Launch your Database</Title>
      <Description>
        <span>There are currently no users in your Database. </span>
        <span
          className="create-link"
          onClick={() => setSelectedPage(`${Pages.SETTINGS}-${SectionKeys.PEOPLE_BASE}-${SectionPagesIDs.DATABASE}`)}
        >
          Sync users
        </span>
        <span> and start editing your Dashboard.</span>
      </Description>
    </div>
  );
};
const UserView = () => (
  <div>
    <Title>Hang in there!</Title>
    <Description>
      <span>You won't be alone for too long. Standing by until your admin launches People Network.</span>
    </Description>
  </div>
);

const NoDatabaseSync = () => {
  const isCurrentUserAdmin = useCurrentUserStore((state) => state.isCurrentUserAdmin);
  const theme = useTheme();

  return (
    <Container>
      <img src={`/images/hub/no-active-users-empty-state-${theme.global.name}.svg`} alt="Empty people network" />
      {isCurrentUserAdmin ? <AdminView /> : <UserView />}
    </Container>
  );
};

export default NoDatabaseSync;
