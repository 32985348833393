export const AddBlogIds = {
  DYNAMIC: "dynamic",
  MANUAL: "manual",
};

export const AvailableTabs = [
  {
    id: AddBlogIds.DYNAMIC,
    label: "Filters",
  },
  {
    id: AddBlogIds.MANUAL,
    label: "Manual Selection",
  },
];
