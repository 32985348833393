import styled from "styled-components";
import { Panel, PanelHeader } from "../General";
import { IconButton } from "@atlaskit/button/new";
import MoreIcon from "@atlaskit/icon/glyph/more";
import Lozenge from "@atlaskit/lozenge";
import DropdownMenu, { DropdownItem, DropdownItemGroup } from "@atlaskit/dropdown-menu";
import { useRef } from "react";
import { NewsroomIcon } from "../../../../icons/SidebarIcons";
import { Pages as cosmosPages } from "../../../../pages";
import { useCosmosLayoutStore } from "../../../../store/cosmos-layout-store";
import { saveCosmosLayout } from "./api";

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const DetailsStatusContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const DetailsHeading = styled.h1`
  color: ${({ theme }) => theme.shared.settings.general.features.featureNameColor};
  margin: 0;
  font-weight: 400;
  font-size: 16px;
`;

const DetailsDescription = styled.p`
  color: ${({ theme }) => theme.shared.settings.general.features.featureDescriptionColor};
  font-size: 14px;
  margin: 0;
`;

const PageWrapper = styled.div`
  width: 100%;
  margin-top: 35px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const PageIconContainer = styled.div`
  background: ${({ theme }) => theme.shared.settings.general.features.iconBackground};
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  color: #44546f;
  margin-right: 16px;
`;

function Page({
  id,
  name,
  activeDescription,
  inactiveDescription,
  Icon,
  isDisabled,
  setPageDisabled,
  setPage,
  learnLink,
}) {
  const { cosmosLayout, setCosmosLayout } = useCosmosLayoutStore((state) => ({
    cosmosLayout: state.cosmosLayout,
    setCosmosLayout: state.setCosmosLayout,
  }));

  const pageWrapperRef = useRef(null);

  const FinalIcon =
    id === cosmosPages.NEWS ? (
      <NewsroomIcon isActive={false} activeColor="#44546f" inactiveColor="#44546f" />
    ) : (
      <Icon />
    );

  const handleOnLinkClick = ({ learn = false }) => {
    if (id) {
      const link = learn
        ? learnLink
        : `${window.AP._hostOrigin}/wiki/plugins/servlet/ac/com.caelor.confluence.cloud.cosmos/caelor-cosmos-portal?ac.cosmosPage=${id}`;

      window.open(link);
      setPageDisabled(isDisabled);
      setPage(id);
    }
  };

  const handleSaveToDB = (newFeatureState) => {
    const _features = newFeatureState.map(({ id, isDisabled }) => ({ id, isDisabled }));
    saveCosmosLayout(_features)
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
  };

  const handleOnItemClick = ({ id, value }) => {
    const newFeaturesState = [...cosmosLayout.map((f) => (f.id === id ? { ...f, isDisabled: value } : f))];
    setCosmosLayout([...newFeaturesState]);
    handleSaveToDB(newFeaturesState);
  };

  return (
    <PageWrapper ref={pageWrapperRef} id={id}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <PageIconContainer>{FinalIcon}</PageIconContainer>
        <DetailsContainer>
          <DetailsStatusContainer>
            <DetailsHeading>{name}</DetailsHeading>
            {isDisabled && <Lozenge appearance="removed">Page disabled</Lozenge>}
          </DetailsStatusContainer>
          <DetailsDescription>{!isDisabled ? activeDescription : inactiveDescription}</DetailsDescription>
        </DetailsContainer>
      </div>

      <DropdownMenu
        trigger={({ triggerRef, ...props }) => <IconButton {...props} icon={MoreIcon} label="more" ref={triggerRef} />}
        shouldRenderToParent
      >
        <DropdownItemGroup>
          <DropdownItem onClick={handleOnLinkClick}>Edit page</DropdownItem>
          <DropdownItem onClick={() => handleOnLinkClick({ learn: true })}>Learn more</DropdownItem>
          <DropdownItem onClick={() => handleOnItemClick({ id, value: !isDisabled })}>
            {isDisabled ? "Enable page" : "Disable page"}
          </DropdownItem>
        </DropdownItemGroup>
      </DropdownMenu>
    </PageWrapper>
  );
}

const Pages = ({ pages, pageDisabled, setPageDisabled, setPage }) => (
  <Panel>
    <PanelHeader>Cosmos Pages</PanelHeader>
    {pages.map(({ id, name, activeDescription, inactiveDescription, Icon, isDisabled, link }) => (
      <Page
        key={id}
        id={id}
        name={name}
        activeDescription={activeDescription}
        inactiveDescription={inactiveDescription}
        Icon={Icon}
        isDisabled={isDisabled}
        pageDisabled={pageDisabled}
        setPageDisabled={setPageDisabled}
        setPage={setPage}
        learnLink={link}
      />
    ))}
  </Panel>
);

export default Pages;
