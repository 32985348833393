export const PropertyTypes = {
  DISPLAY_TITLE: "displayTitle",
  DISPLAY_BOX: "displayBox",
  STYLE: "style",
  ALIGNMENT: "alignment",
  NUMBER_OF_COLUMNS: "numberOfColumns",
  ELEMENTS_TO_DISPLAY: "elementsToDisplay",
  CARDS: "cards",
  IMAGE_HEIGHT: "imageHeight",
  PAGINATION: "pagination",
};

export const CardPropertyTypes = {
  TITLE: "title",
  DESCRIPTION: "description",
  IMAGE: "image",
  ICON: "icon",
  LINK: "link",
  OPEN_NEW_TAB: "openInNewTab",
  COLORS: "colors",
};
