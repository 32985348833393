import React, { useEffect, useMemo, useState } from "react";
import styled, { useTheme } from "styled-components";
import Select from "@atlaskit/select";
import { usePageStore } from "../../../../../page-store";
import { useChangesStore } from "../../../../../track-changes-store";
import { Pages } from "../../../../../pages";
import { useQuery } from "react-query";
import { fetchCollections } from "../../../../../../Collections/api";
import { fetchUserDatabaseFields } from "../../../../../../UserDatabase/datagrid/api";
import filterService, { FilterActionTypes } from "../../../../../../UserDatabase/filter-service";
import { PanelsHiddenFromAnonymousAndGuestsUsers } from "../../../../Dashboard/panels/hiddenPanelsForAnonymousAndGuests";
import { FieldContainer } from "../../../../Dashboard/filter/styled";
import SimpleToggle from "../../../../Dashboard/filter/SimpleToggle";
import { SELECT_STYLES, SIMPLE_SELECT_STYLES } from "../../../../Dashboard/filter/CustomSelectStyles";
import { Filter } from "../../../../../../Collections/selected/Filters";

const PermissionWrapper = styled.div`
  padding: 0px 5px;
  height: 100%;
  display: grid;
`;

const Label = styled.div`
  color: #6b778c;
  font-size: 12px;
  font-weight: 500;
  margin-top: 5px;
  margin-bottom: 5px;

  .link {
    color: #0065ff;
    text-decoration: none;

    &:visited {
      color: #0065ff;
    }
  }
`;

const Container = styled.div`
  display: grid;
  place-content: center;
  justify-items: center;
  text-align: center;
  margin-top: 30px;
`;

const Title = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #07145a;
  margin-top: 20px;
  margin-bottom: 6px;
`;

const Description = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #44526d;

  .create-link {
    cursor: pointer;
    color: #2684ff;
  }
`;

const DatabaseNotSynced = () => {
  const setSelectedPage = usePageStore((state) => state.setSelectedPage);
  const hasChanges = useChangesStore((state) => state.hasChanges);

  const theme = useTheme();

  return (
    <Container>
      <img
        src={`/images/hub/collections-empty-state-${theme.global.name}.svg`}
        alt="Empty people network"
        style={{ margin: "0 auto", maxWidth: "100%" }}
      />

      <div>
        <Title>Launch your Employee database</Title>
        <Description>
          <span>There are currently no users in your Database. </span>
          <span
            className="create-link"
            onClick={() => {
              if (hasChanges) {
                setSelectedPage(Pages.SETTINGS);
              } else {
                setSelectedPage(`${Pages.SETTINGS}-db`);
              }
            }}
          >
            Sync users
          </span>
          <span> to enable permissions.</span>
        </Description>
      </div>
    </Container>
  );
};

function Permissions({ usePanelsStore, hasToggleForAnonymous = true, setDisableClickOutside = () => {} }) {
  const [collectionOptions, setCollectionOptions] = useState([]);

  const [loadedExistingFilterData, setLoadedExistingFilterData] = useState(false);
  const [filters, setFilters] = useState([]);
  const [fields, setFields] = useState([]);

  const { editingPanel, setEditingPanel, panels, setPanels, isDbInitalized } = usePanelsStore((state) => ({
    editingPanel: state.editingPanel,
    setEditingPanel: state.setEditingPanel,
    panels: state.panels,
    setPanels: state.setPanels,
    isDbInitalized: state.hasInitEmployeeDatabase,
  }));

  const setHasChanges = useChangesStore((state) => state.setHasChanges);

  const { isLoading, data } = useQuery("saved-collections", fetchCollections, {
    enabled: isDbInitalized,
    cacheTime: 0,
    retry: 0,
    select: (response) => {
      const { data } = response;
      return { collections: data || [] };
    },
  });

  const { isLoading: isLoadingFields, data: fieldsData } = useQuery(
    ["userdatabase-fields", { includeEmail: false }],
    fetchUserDatabaseFields,
    {
      retry: 0,
      select: (response) => {
        const { data } = response;
        return data;
      },
    },
  );

  useEffect(() => {
    if (fieldsData) {
      const allFilters = filterService.buildFilters(fieldsData || []);
      setFilters(allFilters);
      setFields(fieldsData || []);
    }
  }, [fieldsData]);

  useEffect(() => {
    if (data?.collections?.length) {
      setCollectionOptions(
        data.collections.map(({ name, id }) => ({
          label: name || "Collection without name",
          value: id,
        })),
      );
    }
  }, [data]);

  useEffect(() => {
    if (isLoadingFields || loadedExistingFilterData) return;

    if (editingPanel.filters?.length && filters.length) {
      const existingFilters = editingPanel.filters || [];

      const updatedBaseFilters = filters.map((f) => {
        const savedFilter = existingFilters.find((savedFil) => savedFil.column === f.column);
        if (savedFilter) {
          return { ...f, value: savedFilter.value };
        }

        return f;
      });

      setFilters(updatedBaseFilters);
      setLoadedExistingFilterData(true);
    }
  }, [filters, loadedExistingFilterData, isLoadingFields, editingPanel]);

  const selectedValues = useMemo(() => {
    const { permissions } = editingPanel || {};
    if (permissions?.length && collectionOptions?.length) {
      return permissions
        .map((collectionId) => collectionOptions.find((cl) => cl.value === collectionId))
        .filter((exists) => exists);
    }
    return [];
  }, [editingPanel, collectionOptions]);

  const updatePanels = (newPanel) => {
    const newPanels = panels.map((panel) => {
      return panel.id === newPanel.id ? { ...newPanel } : { ...panel };
    });

    setEditingPanel({ ...newPanel });
    setPanels([...newPanels]);
    setHasChanges(true);
  };

  const updatePanelAnonymousAccess = (newToggleValue) => {
    const editedPanel = {
      ...editingPanel,
      isHiddenFromAnonymous: newToggleValue,
    };

    updatePanels(editedPanel);
  };

  const updatePanelViewPermissions = (collections) => {
    const editedPanel = {
      ...editingPanel,
      permissions: [...collections],
    };

    updatePanels(editedPanel);
  };

  const updatePanelFilters = (filter) => {
    let newFilters = [];
    const existingFilters = editingPanel.filters || [];
    const actionType = filterService.getActionTypeBasedOnFilterValue(filter, existingFilters);

    if (actionType === FilterActionTypes.REMOVE) {
      newFilters = existingFilters.filter((f) => f.column !== filter.column);
    } else if (actionType === FilterActionTypes.CREATE) {
      newFilters = [...existingFilters, filter];
    } else {
      newFilters = existingFilters.map((f) => (f.column === filter.column ? { ...f, value: filter.value } : f));
    }

    const editedPanel = {
      ...editingPanel,
      filters: newFilters,
    };

    updatePanels(editedPanel);
  };

  const updateFilter = (newFilter) => {
    const newFilters = filters.map((filter) => {
      if (filter.column === newFilter.column) {
        return {
          ...filter,
          value: newFilter.value,
        };
      }
      return filter;
    });

    setFilters([...newFilters]);
    updatePanelFilters(newFilter);
  };

  if (!isDbInitalized) {
    return <DatabaseNotSynced />;
  }

  return (
    <PermissionWrapper>
      <div>
        {hasToggleForAnonymous && !PanelsHiddenFromAnonymousAndGuestsUsers.includes(editingPanel?.type) && (
          <FieldContainer>
            <SimpleToggle
              label="Visible for anonymous users"
              value={!editingPanel.isHiddenFromAnonymous}
              setValue={(newToggleValue) => updatePanelAnonymousAccess(!newToggleValue)}
              customStyles={{
                padding: "8px 0px",
              }}
            />
          </FieldContainer>
        )}

        <FieldContainer>
          <Label>View permissions</Label>
          <Select
            placeholder="Select Collections"
            menuPlacement="auto"
            styles={SIMPLE_SELECT_STYLES}
            isClearable={false}
            options={collectionOptions}
            value={selectedValues}
            onChange={(op) => {
              if (op) {
                updatePanelViewPermissions(op.map(({ value }) => value));
              }
            }}
            isDisabled={!isDbInitalized}
            isLoading={isLoading}
            isMulti
          />
        </FieldContainer>
        <Label>
          Personalize Dashboard widgets with&nbsp;
          <a className="link" href="https://caelor-apps.atlassian.net/wiki/x/cRj6" target="_blank" rel="noreferrer">
            Cosmos Collections
          </a>
          &nbsp;- show only relevant information.
        </Label>

        {!isLoadingFields && (
          <FieldContainer
            onMouseEnter={() => setDisableClickOutside(true)}
            onMouseLeave={() => setDisableClickOutside(false)}
          >
            <Label>Filter by</Label>
            <Filter filters={filters} fields={fields} updateFilter={updateFilter} showAllFilters />
          </FieldContainer>
        )}
      </div>
    </PermissionWrapper>
  );
}

export default Permissions;
