import React, { useContext, useMemo } from "react";
import SidebarTransition from "../../../../../Shared/Components/SidebarTransition";
import { useSidebarStore } from "../newsroom-store";
import { SidebarTabs } from "../../Shared/SidebarTabSelector";
import ContentSelector from "./ContentSelector";
import NewsDesign from "./NewsDesign";
import HeaderDesign from "./HeaderDesign";
import { useHeaderStore } from "../../../store/header-store";
import { useHeaderChangesStore } from "../../Shared/Header/customization/header-changes-store";
import { useChangesStore } from "../../../track-changes-store";
import { NewsroomAPIContext } from "../context";

function Sidebar() {
  const { isSidebarOpen, setIsSidebarOpen, selectedSidebar, setSelectedSidebar } = useSidebarStore((state) => ({
    isSidebarOpen: state.isSidebarOpen,
    setIsSidebarOpen: state.setIsSidebarOpen,
    selectedSidebar: state.selectedSidebar,
    setSelectedSidebar: state.setSelectedSidebar,
  }));

  const isEditingHeader = useHeaderStore((state) => state.isEditingHeader);

  const { hasHeaderChanges } = useHeaderChangesStore((state) => ({
    hasHeaderChanges: state.hasHeaderChanges,
  }));

  const { hasChanges } = useChangesStore((state) => ({
    hasChanges: state.hasChanges,
  }));

  const { handleSaveHeaderSettings, handleSaveNewsroomSettings } = useContext(NewsroomAPIContext);

  const sidebarTitle = useMemo(
    () =>
      ({
        [SidebarTabs.NOT_SELECTED]: "Customize Newsroom",
        [SidebarTabs.NEWS_DESIGN]: "Edit news design",
        [SidebarTabs.HEADER_DESIGN]: "Edit header design",
      })[selectedSidebar],
    [selectedSidebar],
  );

  const SidebarContent = useMemo(
    () =>
      ({
        [SidebarTabs.NOT_SELECTED]: ContentSelector,
        [SidebarTabs.NEWS_DESIGN]: NewsDesign,
        [SidebarTabs.HEADER_DESIGN]: HeaderDesign,
      })[selectedSidebar],
    [selectedSidebar],
  );

  const handleSaveChanges = () => {
    if (hasChanges) return handleSaveNewsroomSettings();
    if (hasHeaderChanges) return handleSaveHeaderSettings();
  };

  return (
    <SidebarTransition
      isOpen={isSidebarOpen}
      close={() => {
        setIsSidebarOpen(false);
        setSelectedSidebar(undefined);
      }}
      width={360}
      title={sidebarTitle}
      customScrollbarHeight="calc(100% - 120px)"
      useArrowBack={!!selectedSidebar}
      onArrowBackClick={() => setSelectedSidebar(undefined)}
      disabledClickOutside={isEditingHeader}
      hasChanges={hasChanges || hasHeaderChanges}
      onSave={handleSaveChanges}
    >
      <SidebarContent />
    </SidebarTransition>
  );
}

export default Sidebar;
