import httpClient from "../../../../Service/http-client";

const PROPERTY_KEY = "caelor-cosmos-user";

export const getAccountId = () => {
  return new URLSearchParams(window.location.search).get("accountId");
};

export const getExternalLink = (link) => {
  if (link?.includes?.("://")) {
    return link;
  }

  return `https://${link}`;
};

export async function getUserProperty(accountId) {
  const url = `ac/confluence/api/user/get-user-properties?accountId=${accountId}`;
  return httpClient.get(url);
}

export const updateUserProperty = (accountId, value, isNew) => {
  window.AP.request({
    type: isNew ? "POST" : "PUT",
    contentType: "application/json",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-Atlassian-Token": "nocheck",
    },
    url: `/rest/api/user/${accountId}/property/${PROPERTY_KEY}`,
    data: JSON.stringify({
      version: { number: 1 },
      key: PROPERTY_KEY,
      value,
    }),
  }).then(() => {
    const flag = window.AP.flag.create({
      title: "Changes successfully saved",
      type: "info",
    });

    setTimeout(() => {
      flag.close();
    }, 5000);
  });
};
