import React from "react";
import { useNavigationStore } from "../../../../../store";
import { useTheme } from "styled-components";
import Toggle from "@atlaskit/toggle";
import Checkbox from "@atlaskit/checkbox";
import { PARENT_ENTRY_IDENTIFIER } from "../../../../../defaults";
import { SidebarTabs, TabTypes } from "../tabs";
import { Box, Flex } from "@atlaskit/primitives";
import NativeTabs from "../../../../../../../Shared/Components/NativeTabs";
import IconTitle from "../shared/IconTitle";
import { FieldColumn, FieldContainer, FieldInline, FieldLabel } from "../shared/Components";
import CustomInput from "../../../../../../ContentBuilder/Shared/Filter/CustomInput";
import ContentVizNews from "../shared/ContentVizNews";
import Design from "./tabs/DesignTab";
import { Link } from "../shared/LinkConfig";

function Sydney() {
  const { editingEntry, updateEntry } = useNavigationStore((state) => ({
    editingEntry: state.editingEntry,
    updateEntry: state.updateEntry,
  }));

  const theme = useTheme();

  const isParent = editingEntry.parent === PARENT_ENTRY_IDENTIFIER;

  const [selectedTab, setSelectedTab] = React.useState(TabTypes.LAYOUT);

  return (
    <Box>
      {isParent && <NativeTabs tabs={SidebarTabs} useNative={false} returnSelection={setSelectedTab} />}

      <Box paddingBlockStart={isParent ? "space.300" : "space.0"}>
        {selectedTab === TabTypes.LAYOUT && (
          <>
            <Flex direction="column">
              <IconTitle />
            </Flex>

            <FieldContainer>
              <FieldLabel>{isParent ? "Entry description" : "Subentry description"}</FieldLabel>
              <CustomInput
                isTextarea={false}
                initialValue={editingEntry?.data?.description ?? ""}
                placeholder={isParent ? "Type entry description here..." : "Type subentry description here..."}
                onChange={(newDescription) => updateEntry(editingEntry.id, "description", newDescription, true)}
              />
            </FieldContainer>

            {isParent ? (
              <FieldContainer>
                <FieldInline>
                  <FieldLabel>Display icons</FieldLabel>
                  <Toggle
                    id="toggle-controlled-icons"
                    onChange={(e) => {
                      updateEntry(editingEntry.id, "displayIcons", e.target.checked, true);
                    }}
                    isChecked={editingEntry?.data?.displayIcons}
                  />
                </FieldInline>

                <FieldInline>
                  <FieldLabel>Display description</FieldLabel>
                  <Toggle
                    id="toggle-controlled-description"
                    onChange={(e) => {
                      updateEntry(editingEntry.id, "displayDescription", e.target.checked, true);
                    }}
                    isChecked={editingEntry?.data?.displayDescription}
                  />
                </FieldInline>

                <FieldInline>
                  <FieldLabel>Display news</FieldLabel>
                  <Toggle
                    id="toggle-controlled-news"
                    onChange={(e) => {
                      updateEntry(editingEntry.id, "isNewsEnabled", e.target.checked, true);
                    }}
                    isChecked={editingEntry?.data?.isNewsEnabled}
                  />
                </FieldInline>
              </FieldContainer>
            ) : (
              <>
                <div
                  style={{
                    margin: "15px 0 19px 0",
                    borderBottom: `1px solid ${theme.navigation.settings.iconBoxBorder}`,
                    width: "100%",
                  }}
                />
                <Link
                  link={editingEntry.data.link}
                  onSelected={(selected) => {
                    updateEntry(editingEntry.id, "link", selected, true);
                  }}
                />
                <div>
                  <FieldColumn>
                    <Checkbox
                      id="checkbox-controlled"
                      isChecked={editingEntry.data.openNewTab}
                      onChange={(e) => {
                        updateEntry(editingEntry.id, "openNewTab", e.target.checked, true);
                      }}
                    />
                    <FieldLabel style={{ marginBottom: "2px" }}>Open in new tab</FieldLabel>
                  </FieldColumn>
                </div>
              </>
            )}

            {editingEntry?.data?.isNewsEnabled && <ContentVizNews />}
          </>
        )}

        {selectedTab === TabTypes.DESIGN && <Design />}
      </Box>
    </Box>
  );
}

export default Sydney;
