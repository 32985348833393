import React, { useMemo, useRef } from "react";
import ArrowRightIcon from "@atlaskit/icon/glyph/arrow-right";
import {
  CardContent,
  CardDescription,
  CardTitle,
  CardWrapper,
  ColoredDivider,
  BigIconContainer,
  IconContainer,
} from "../shared/styled";
import xss from "xss";

import IconRenderer, { IconSizes } from "../../../../../../../../../Shared/Components/Icons/IconRenderer";
import { useTheme } from "styled-components";
import useLinksObserver from "../shared/use-links-observer";

function IconCardLasVegas({ cardData, config }) {
  const { alignment, elementsToDisplay } = config;
  const {
    title,
    description,
    icon,
    colors: { elements: elementsColor, background: backgroundColor, defaultBackground },
  } = cardData;

  const [isTextActive, isArrowActive] = useMemo(
    () => [
      elementsToDisplay.find(({ id }) => id === "text").isChecked,
      elementsToDisplay.find(({ id }) => id === "arrow").isChecked,
    ],
    [elementsToDisplay],
  );

  const cardRef = useRef(null);
  const theme = useTheme();

  useLinksObserver(cardRef);

  const defaultBackgroundColor = (defaultBackground ?? true) && backgroundColor === "#fff";

  return (
    <CardWrapper ref={cardRef} bgClr={defaultBackgroundColor ? theme.shared.card.background : backgroundColor}>
      <CardContent alignment={alignment}>
        <BigIconContainer bgClr={elementsColor} style={{ marginBottom: "20px" }}>
          <IconRenderer icon={icon} iconSize={IconSizes.MEDIUM} />
        </BigIconContainer>
        <CardTitle style={{ marginBottom: "8px" }} alignment={alignment}>
          {title}
        </CardTitle>
        <ColoredDivider width="48px" bgClr={elementsColor} style={{ marginBottom: "12px" }} />
        {isTextActive && (
          <CardDescription alignment={alignment} dangerouslySetInnerHTML={{ __html: xss(description) }} />
        )}

        {isArrowActive && (
          <IconContainer style={{ marginTop: "12px", alignSelf: "flex-end" }}>
            <ArrowRightIcon primaryColor={elementsColor} />
          </IconContainer>
        )}
      </CardContent>
    </CardWrapper>
  );
}

export default IconCardLasVegas;
