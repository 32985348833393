import React from "react";
import ArrowLeftIcon from "@atlaskit/icon/glyph/arrow-left";
import { token } from "@atlaskit/tokens";
import { Inline, Text, xcss } from "@atlaskit/primitives";

const headerCss = xcss({
  cursor: "pointer",
});

function ClickableHeader({ title, onClick = () => {} }) {
  return (
    <div onClick={onClick}>
      <Inline xcss={headerCss} space="space.150">
        <ArrowLeftIcon primaryColor={token("color.text.accent.gray.bolder")} size="medium" />
        <Text size="large" weight="medium" color="color.text.accent.gray.bolder">{title}</Text>
      </Inline>
    </div>
  );
}

export default ClickableHeader;
