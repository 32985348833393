import { useEffect, useState } from "react";
import styled, { useTheme } from "styled-components";
import PageIcon from "@atlaskit/icon-object/glyph/page/16";
import BlogIcon from "@atlaskit/icon-object/glyph/blog/16";
import CrossIcon from "@atlaskit/icon/glyph/cross";

import { EmailIcon, LinkIcon } from "../../../../../../Shared/Components/Icons/LinkFieldIcons";
import LinkDrawer from "../../../../../../Shared/Components/LinkDrawer";
import { EntryAction } from "../../../../../Navigation/configuration/drawer/components/edit/shared/styled";

const Box = styled.div`
  box-sizing: border-box;
  background: ${({ theme }) => theme.navigation.settings.iconBoxBackground};
  border: 1px solid ${({ theme }) => theme.navigation.settings.iconBoxBorder};
  border-radius: 3px;
  width: 100%;
  height: 40px;
  overflow: hidden;
`;

export const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
`;

export const FieldLabel = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #6b778c;
`;

export const Link = ({ link, onSelected, hasLabel = true, linkSidebarVisible, setLinkSidebarVisible }) => {
  const theme = useTheme();

  const getLinkValue = () => {
    if (link?.source === "page") {
      return link.title;
    }
    return link?.link || "";
  };

  const getIcon = () => {
    if (link?.source) {
      if (link.source === "page") {
        if (link.type === "page") {
          return <PageIcon />;
        }
        return <BlogIcon />;
      }

      if (link.source === "email") {
        return <EmailIcon />;
      }
    }
    return <LinkIcon />;
  };

  const handleLinkSidebarVisibilityUpdate = (newVisibleState) => {
    setLinkSidebarVisible(newVisibleState);
  };

  return (
    <FieldContainer>
      {hasLabel && <FieldLabel>Link</FieldLabel>}

      <Box
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          handleLinkSidebarVisibilityUpdate(true);
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            background: theme.navigation.settings.addLinkBackground,
            height: "100%",
            padding: "0 10px",
            columnGap: "6px",
          }}
        >
          <EntryAction
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleLinkSidebarVisibilityUpdate(true);
            }}
          >
            {getIcon()}
          </EntryAction>
          {link && (
            <span
              style={{
                fontSize: "14px",
                flex: 1,
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
                color: theme.navigation.settings.linkValue,
              }}
            >
              {getLinkValue()}
            </span>
          )}
          {!link && (
            <span
              style={{
                cursor: "pointer",
                flex: 1,
                fontSize: "14px",
                color: "#7A869A",
              }}
            >
              Add link
            </span>
          )}
          {!!getLinkValue() && (
            <EntryAction
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onSelected(undefined);
              }}
            >
              <CrossIcon size="small" />
            </EntryAction>
          )}
        </div>

        <LinkDrawer
          value={link}
          visible={linkSidebarVisible}
          onSelected={onSelected}
          onClose={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleLinkSidebarVisibilityUpdate(false);
          }}
          close={() => {
            handleLinkSidebarVisibilityUpdate(false);
          }}
        />
      </Box>
    </FieldContainer>
  );
};
