import React from "react";
import { LoadingTypes } from "../../../Hub/Components/Dashboard/filter/DynamicFilterToggle";
import {
  EmptyForFilters,
  EmptyManual,
  EmptyNoNews,
} from "../../../Hub/Components/Dashboard/panels/types/news/EmptyStates";

const customStyles = {
  customWrapperStyles: { rowGap: "24px", columnGap: "24px" },
  customTitleStyles: { fontSize: "14px", fontWeight: "500", lineHeight: "20px" },
  customDescriptionStyles: { fontSize: "10px", fontWeight: "400", lineHeight: "normal" },
  customTextContainerStyles: { rowGap: "4px" },
};

function NewsEmptyState({ newsConfig, hasNewsFilters, temporaryColorScheme, isInPreview }) {
  return (
    <>
      {!newsConfig?.loadingType || newsConfig?.loadingType === LoadingTypes.DYNAMIC ? (
        <>
          {hasNewsFilters ? (
            <EmptyForFilters
              returnWithPanel={false}
              customStyles={customStyles}
              previewTheme={temporaryColorScheme}
              isInNavigation={true}
              isInPreview={isInPreview}
            />
          ) : (
            <EmptyNoNews
              returnWithPanel={false}
              customStyles={customStyles}
              previewTheme={temporaryColorScheme}
              isInNavigation={true}
              isInPreview={isInPreview}
            />
          )}
        </>
      ) : (
        <EmptyManual
          returnWithPanel={false}
          customStyles={customStyles}
          previewTheme={temporaryColorScheme}
          isInNavigation={true}
          isInPreview={isInPreview}
        />
      )}
    </>
  );
}

export default NewsEmptyState;
