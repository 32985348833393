import React, { useEffect, useState } from "react";
import Spinner from "@atlaskit/spinner";
import styled, { useTheme } from "styled-components";
import { Section, SmallTitle, SpinnerContainer, Subtitle } from "../../styled/views";
import { useQuery } from "react-query";
import { fetchDetailsForUsers, fetchRandomTwoUsers } from "../api";
import { useRecordsStore } from "../../../../../UserDatabase/records-store";
import {
  handleBirthdayField,
  handleConfluenceUserData,
  handleNewStarter,
  handleWorkAnniversary,
} from "./fieldDataHandlers";
import { applyChanges, fetchRecords, fetchUserDatabaseFields } from "../../../../../UserDatabase/datagrid/api";
import TeamCard from "../../Cards/TeamCard";
import { buildAndGetUserBackground } from "../../handleRecordsData";
import dayjs from "dayjs";
import { getUserProperty } from "../../../UserProfile/profile.service";
import { useCorporateIdentityStore } from "../../../Settings/General/BrandAndColors/corporate-identity-store";
import { getUserValuesFromTwoFields } from "../../../Shared/Panels/shared/utils/user-values-extractor";
import { getWidgetsSettings } from "../../../Settings/Widgets/api";
import { WidgetIds } from "../../../Settings/Widgets/consts/tabs";
import EmptyState from "../../../../../../Shared/Components/EmptyState";

const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  column-gap: 24px;
`;

const handleResponse = async (results, fieldData, widgetSettings) => {
  if (!results?.length) return [];

  let changes = {};
  const builtData = results.map((userObject) => {
    const { image, change } = buildAndGetUserBackground(userObject);

    if (change) {
      changes["UPDATE_RECORDS"] = { ...changes["UPDATE_RECORDS"], ...change };
    }

    const birthday = handleBirthdayField(fieldData, userObject, widgetSettings[WidgetIds.BIRTHDAY]?.selectedField);
    if (birthday.hasBirthday) {
      return {
        ...userObject,
        image,
        additionalData: birthday,
      };
    }

    const anniversary = handleWorkAnniversary(
      fieldData,
      userObject,
      widgetSettings[WidgetIds.WORK_ANNIVERSARY]?.selectedField,
    );
    if (anniversary.hasAnniversary) {
      return {
        ...userObject,
        image,
        additionalData: anniversary,
      };
    }

    const newStarter = handleNewStarter(fieldData, userObject, widgetSettings[WidgetIds.NEW_STARTERS]?.selectedField);
    if (newStarter.isNewStarter) {
      return {
        ...userObject,
        image,
        additionalData: newStarter,
      };
    }

    return { ...userObject, image };
  });

  if (changes && Object.keys(changes || {}).length) {
    applyChanges(changes);
  }

  const usersToFetch = builtData.map((userRecord) => userRecord.accountId);

  try {
    const userPropertiesPromises = usersToFetch.map(async (accountId) => {
      const { data } = await getUserProperty(accountId);

      if (!data) {
        return { accountId, userProperty: undefined };
      }
      return {
        accountId,
        userProperty: data,
      };
    });

    const userProperties = await Promise.all(userPropertiesPromises);

    const confUsers = handleConfluenceUserData(builtData);

    return builtData.map((userObject) => {
      return {
        ...userObject,
        confluenceData: confUsers.find((user) => user.accountId === userObject.accountId),
        socialLinks: userProperties?.find((user) => user.accountId === userObject.accountId)?.userProperty?.socialLinks,
      };
    });
  } catch {
    return [];
  }
};

function KnowYourTeam({ panelData }) {
  const [loading, setLoading] = useState(false);
  const [records, setRecords] = useState([]);
  const fields = useRecordsStore((state) => state.fields);
  const borderRadius = useCorporateIdentityStore((state) => state.borderRadius);

  const { isFetching, data } = useQuery(["random-users"], fetchRandomTwoUsers, {
    retry: 0,
    select: (response) => {
      if (!response?.data?.length) return [];
      return response.data;
    },
  });

  const { data: fieldsData } = useQuery(["userdatabase-fields", { includeEmail: false }], fetchUserDatabaseFields, {
    retry: 0,
    select: (response) => {
      const { data } = response;
      const matchingFieldsByType = data?.filter(
        (field) => field.type === "SELECT" || field.type === "TEXT" || field.type === "DATE",
      );
      return matchingFieldsByType;
    },
  });

  const { isFetching: fetchingRecords, data: recordsData } = useQuery(
    [
      "random-users-data",
      {
        filters: [
          {
            type: "USER",
            isMultiUser: true,
            value: data,
          },
          { isMultiUser: true, type: "BOOLEAN", value: true, column: "hidden", condition: "NOT" },
        ],
      },
    ],
    fetchRecords,
    {
      enabled: !!data?.length,
      retry: 0,
      select: (response) => {
        const { data } = response;
        return data;
      },
    },
  );

  const { data: widgetSettingsData } = useQuery("widgets-settings", getWidgetsSettings, {
    retry: 0,
    select: (response) => {
      const { data } = response;
      return data;
    },
  });

  useEffect(() => {
    if (recordsData && !!fieldsData) {
      const widgetSettings = widgetSettingsData?.exists ? widgetSettingsData.widgetSettings : {};

      setLoading(true);
      handleResponse(recordsData?.hits || [], fields, widgetSettings)
        .then((builtData) => {
          const formattedRecords = builtData.map((record) => {
            const userValues = builtData?.find((r) => r.accountId === record.accountId)?.values;

            const {
              firstField: titleValue,
              secondField: footerValue,
              footerType,
            } = getUserValuesFromTwoFields(userValues, fieldsData, panelData.titleFieldId, panelData.footerFieldId);

            return { ...record, titleValue, footerValue, footerType };
          });
          setRecords([...formattedRecords]);
        })
        .catch(() => setRecords([]))
        .finally(() => setLoading(false));
    }
  }, [recordsData, fieldsData, fields, widgetSettingsData, panelData.titleFieldId, panelData.footerFieldId]);

  const isLoading = isFetching || fetchingRecords || loading;

  const theme = useTheme();

  if (!records?.length)
    return (
      <EmptyState
        Image={() => {
          return <img src={`/images/hub/no-recently-active-users-empty-state-${theme.global.name}.svg`} alt="" />;
        }}
        title="No employees to display"
        description="Add, remove or adjust filters to show employees."
      />
    );

  return (
    <Section>
      <SmallTitle>{panelData.title}</SmallTitle>
      <Subtitle>{panelData.description}</Subtitle>
      <>
        {isLoading && (
          <SpinnerContainer>
            <Spinner size="xlarge" />
          </SpinnerContainer>
        )}

        {!isLoading && (
          <GridWrapper>
            {records.map((record) => (
              <TeamCard key={record.accountId} panelData={panelData} data={record} borderRadius={borderRadius} />
            ))}
          </GridWrapper>
        )}
      </>
    </Section>
  );
}

export default KnowYourTeam;
