import React, { useMemo, useEffect, useState, useRef } from "react";

import Tooltip from "@atlaskit/tooltip";
import Spinner from "@atlaskit/spinner";
import { SpotlightManager, SpotlightTarget } from "@atlaskit/onboarding";
import { useTheme } from "styled-components";
import { useQuery } from "react-query";
import ContentLoader from "react-content-loader";

import { PeopleNetworkIcon, NewsroomIcon, SettingsIcon, SpaceFolderIcon, InfoIcon } from "./icons/SidebarIcons";
import {
  FooterIconContainer,
  FooterIcons,
  HubMainWrapper,
  HubSecondaryWrapper,
  HubSidebar,
  IconLink,
  Icons,
  LogoImage,
  LogoImageLink,
  PageWrapper,
  SidebarIconContainer,
  UserProfileImage,
} from "./styled/entry";
import { Pages } from "./pages";
import Dashboard from "./Components/Dashboard/Dashboard";
import Settings from "./Components/Settings/Settings";
import Newsroom from "./Components/Newsroom/Newsroom";
import PeopleBase from "./Components/PeopleBase/PeopleBase";
import SpaceDirectory from "./Components/SpaceDirectory/SpaceDirectory";
import UserProfile from "./Components/UserProfile/UserProfile";
import { useCurrentUserStore } from "./current-user-store";
import { useMacroSuiteConnectionStore } from "../../Shared/store/macrosuite-connection-store";
import { useContentVizConnectionStore } from "../../Shared/store/contentviz-connection-store";
import { checkMacroSuiteLicense } from "../../Shared/Functions/checkMacrosuiteLicense";
import { usePageStore } from "./page-store";
import { Labels } from "./labels";
import NavigationMenu from "../Navigation/menu/NavigationMenu";
import { usePanelsStore as useDashboardPanelsStore } from "./Components/Dashboard/store/panels-store";
import { usePanelsStore as usePeopleNetworkPanelsStore } from "./Components/PeopleBase/store/panels-store";
import { checkContentVizLicense } from "../../Shared/Functions/checkContentvizLicense";
import { fetchIsInitialized } from "../UserDatabase/datagrid/api";
import Spaceport from "./Components/SpaceportSidebar/Spaceport";
import { useChangesStore } from "./track-changes-store";
import DashboardWizard from "./Components/Shared/ConfigurationWizard/DashboardWizard";
import {
  DASHBOARD_WIZARD_PROPERTY_KEY,
  PEOPLE_WIZARD_PROPERTY_KEY,
  PROFILE_WIZARD_PROPERTY_KEY,
  getUserWizardConfigProperty,
} from "./Components/Shared/ConfigurationWizard/wizard-service";
import {
  checkIfUserIsCosmosAdmin,
  getCorporateIdentitySettings,
  getHeaderSettings,
  getUnderConstructionData,
} from "./api";
import { useHeaderStore } from "./store/header-store";
import { useCorporateIdentityStore } from "./Components/Settings/General/BrandAndColors/corporate-identity-store";
import {
  COLOR_TYPES,
  ICON_BG_TYPES,
  ICON_BG_SHAPE_TYPES,
  getDefaultColorsBasedOnTheme,
} from "./Components/Settings/General/BrandAndColors/color-types";
import { useHeaderChangesStore } from "./Components/Shared/Header/customization/header-changes-store";
import { useNavigationChangesStore } from "../Navigation/store";
import { addIconTypeAsProperty } from "./Components/Settings/General/BrandAndColors/defaults";
import { useCosmosLayoutStore } from "./store/cosmos-layout-store";
import { getCosmosLayout } from "./Components/Settings/General/Features/api";
import { DefaultFeatures as DefaultCosmosLayout } from "./Components/Settings/General/Features/features";
import {
  BG_COLOR_TYPES,
  HEADER_TYPES,
  RADIUS_TYPES,
  RADIUS_VALUES,
} from "./Components/Settings/General/BrandAndColors/types";
import { useSettingsStore } from "./Components/Settings/settings-store";
import { useUnderConstructionStore } from "./Components/Settings/under-construction-store";
import { UNDER_CONSTRUCTION_DEFAULT } from "./Components/Settings/General/UnderConstruction/default_values";
import UnderConstructionFull from "./Components/Settings/General/UnderConstruction/UnderConstructionFull";
import ConstructionFlag from "./Components/Settings/General/UnderConstruction/ConstructionFlag";

import { useEventListener } from "../../Shared/Hooks/useEventListener";

function Hub() {
  const [isNavActiveInHub, setIsNavActiveInHub] = useState(false);

  const [loadingUser, setLoadingUser] = useState(true);
  const [loadingPage, setLoadingPage] = useState(false);
  const [loadingConstruction, setLoadingConstruction] = useState(true);
  const [loadConstructionFlag, setShouldLoadConstructionFlag] = useState(true);

  const [spaceportOpen, setSpaceportOpen] = useState(false);

  const [dashboardCurrentWizardStep, setDashboardCurrentWizardStep] = useState(null);
  const [peopleCurrentWizardStep, setPeopleCurrentWizardStep] = useState(null);
  const [profileCurrentWizardStep, setProfileCurrentWizardStep] = useState(null);

  const theme = useTheme();

  const spaceportRef = useRef();
  spaceportRef.current = setSpaceportOpen;

  const { selectedPage, setSelectedPage } = usePageStore((state) => ({
    selectedPage: state.selectedPage,
    setSelectedPage: state.setSelectedPage,
  }));

  const setSettingsSubpage = useSettingsStore((state) => state.setSelectedSettingsPage);

  const [hasLoadedWizardProperties, setHasLoadedWizardProperties] = useState(false);
  const wizardProperties = useRef({
    dashboard: false,
    people: false,
    profile: false,
  });

  function updateWizardProperties(property, value) {
    wizardProperties.current = {
      ...wizardProperties.current,
      [property]: value,
    };
  }

  const {
    currentUser,
    isCurrentUserAdmin,
    isAnonymous,
    isGuest,
    setCurrentUser,
    setIsAnonymous,
    setIsGuest,
    setIsCurrentUserAdmin,
    setIsDbInit,
  } = useCurrentUserStore((state) => ({
    currentUser: state.currentUser,
    isCurrentUserAdmin: state.isCurrentUserAdmin,
    isAnonymous: state.isAnonymous,
    isGuest: state.isGuest,
    setCurrentUser: state.setCurrentUser,
    setIsAnonymous: state.setIsAnonymous,
    setIsGuest: state.setIsGuest,
    setIsCurrentUserAdmin: state.setIsCurrentUserAdmin,
    setIsDbInit: state.setIsDbInit,
  }));

  const { cosmosLayout, setCosmosLayout } = useCosmosLayoutStore((state) => ({
    cosmosLayout: state.cosmosLayout,
    setCosmosLayout: state.setCosmosLayout,
  }));

  const { setHasInitEmployeeDatabase: setDashboardSetHasInitEmployeeDatabase } = useDashboardPanelsStore((state) => ({
    setHasInitEmployeeDatabase: state.setHasInitEmployeeDatabase,
  }));

  const { setHasInitEmployeeDatabase: setPeopleNetworkSetHasInitEmployeeDatabase } = usePeopleNetworkPanelsStore(
    (state) => ({
      setHasInitEmployeeDatabase: state.setHasInitEmployeeDatabase,
    }),
  );

  const { headerData, setHeaderData } = useHeaderStore((state) => ({
    headerData: state.headerData,
    setHeaderData: state.setHeaderData,
  }));

  const {
    logo,
    setLogo,
    colors,
    setColors,
    backgroundColor,
    setBackgroundColor,
    setHeaderType,
    setBorderRadius,
    setLogoName,
  } = useCorporateIdentityStore((state) => ({
    logo: state.logo,
    setLogo: state.setLogo,
    colors: state.colors,
    setColors: state.setColors,
    backgroundColor: state.backgroundColor,
    setBackgroundColor: state.setBackgroundColor,
    setHeaderType: state.setHeaderType,
    setBorderRadius: state.setBorderRadius,
    setLogoName: state.setLogoName,
  }));

  const hasChanges = useChangesStore((state) => state.hasChanges);
  const { hasHeaderChanges, setHasHeaderChanges } = useHeaderChangesStore((state) => ({
    hasHeaderChanges: state.hasHeaderChanges,
    setHasHeaderChanges: state.setHasHeaderChanges,
  }));
  const hasNavigationChanges = useNavigationChangesStore((state) => state.hasChanges);

  const setIsMacroSuiteInstalled = useMacroSuiteConnectionStore((state) => state.setIsMacroSuiteInstalled);
  const setIsContentVizInstalled = useContentVizConnectionStore((state) => state.setIsContentVizInstalled);

  const {
    underConstructionActivated,
    messageTitle,
    messageDescription,
    messageButton,
    messageLink,
    setUnderConstructionActivated,
    setMessageTitle,
    setMessageDescription,
    setMessageButon,
    setMessageLink,
  } = useUnderConstructionStore((state) => ({
    underConstructionActivated: state.underConstructionActivated,
    messageTitle: state.messageTitle,
    messageDescription: state.messageDescription,
    messageButton: state.messageButton,
    messageLink: state.messageLink,
    setUnderConstructionActivated: state.setUnderConstructionActivated,
    setMessageTitle: state.setMessageTitle,
    setMessageDescription: state.setMessageDescription,
    setMessageButon: state.setMessageButon,
    setMessageLink: state.setMessageLink,
  }));

  const { data: isInitialized } = useQuery("database-is-initialized", fetchIsInitialized, {
    retry: 0,
    select: (response) => {
      const { data } = response;
      return data;
    },
  });

  const { isLoading: loadingHeaderSettings, data: headerDataResponse } = useQuery(
    "header-settings",
    getHeaderSettings,
    {
      retry: 0,
      select: (response) => {
        const { data } = response;
        return data;
      },
    },
  );

  const { isLoading: loadingCorporateIdentity, data: corporateDataResponse } = useQuery(
    "corporate-settings",
    getCorporateIdentitySettings,
    {
      retry: 0,
      select: (response) => {
        const { data } = response;
        return data;
      },
    },
  );
  const { isLoading: loadingLayout, data: cosmosLayoutDataResponse } = useQuery("cosmos-layout", getCosmosLayout, {
    retry: 0,
    select: (response) => {
      const { data } = response;
      return data;
    },
  });

  const { isLoading: loadingUnderConstruction, data: underConstructionData } = useQuery(
    "under-construction",
    getUnderConstructionData,
    {
      retry: 0,
      select: (response) => {
        const { data } = response;
        return data;
      },
    },
  );

  useEffect(() => {
    if (selectedPage === Pages.SETTINGS) {
      setShouldLoadConstructionFlag(false);
    }
  }, [selectedPage]);

  useEffect(() => {
    if (underConstructionData?.exists) {
      setLoadingConstruction(false);
      setUnderConstructionActivated(underConstructionData?.data.underConstructionActivated);
      setMessageTitle(underConstructionData?.data.messageTitle || UNDER_CONSTRUCTION_DEFAULT.TITLE);
      setMessageDescription(underConstructionData?.data.messageDescription || UNDER_CONSTRUCTION_DEFAULT.DESCRIPTION);
      setMessageButon(underConstructionData?.data.messageButton || UNDER_CONSTRUCTION_DEFAULT.BUTTON_TEXT);
      setMessageLink(underConstructionData?.data.messageLink || UNDER_CONSTRUCTION_DEFAULT.LINK);
    } else {
      setLoadingConstruction(false);
      setMessageTitle(UNDER_CONSTRUCTION_DEFAULT.TITLE);
      setMessageDescription(UNDER_CONSTRUCTION_DEFAULT.DESCRIPTION);
      setMessageButon(UNDER_CONSTRUCTION_DEFAULT.BUTTON_TEXT);
      setMessageLink(UNDER_CONSTRUCTION_DEFAULT.LINK);
    }
  }, [loadingUnderConstruction, underConstructionData]);

  useEffect(() => {
    if (cosmosLayoutDataResponse?.length) {
      setCosmosLayout([
        ...DefaultCosmosLayout.map((feature) => {
          const { isDisabled } = cosmosLayoutDataResponse.find((f) => f.id === feature.id);
          return {
            ...feature,
            isDisabled,
          };
        }),
      ]);
    } else {
      setCosmosLayout([...DefaultCosmosLayout]);
    }
  }, [cosmosLayoutDataResponse]);

  const getCorporateIdentityColors = (colorData, defaultColors) => {
    if (theme.global.name === "light") return colorData?.colors || defaultColors;

    return Object.keys(colorData.colors_dark || {}).length ? colorData.colors_dark : colorData?.colors || defaultColors;
  };

  useEffect(() => {
    const DEFAULT_COLORS = getDefaultColorsBasedOnTheme(theme.global.name);

    if (corporateDataResponse?.exists) {
      setLogo(corporateDataResponse?.data?.logo || undefined);
      setLogoName(corporateDataResponse?.data?.logoName || undefined);
      setBackgroundColor(corporateDataResponse?.data?.backgroundColor || BG_COLOR_TYPES.WHITE);
      setHeaderType(corporateDataResponse?.data?.headerType || HEADER_TYPES.FULL_WIDTH);
      setBorderRadius(corporateDataResponse?.data?.borderRadius || RADIUS_VALUES[RADIUS_TYPES.SMALL]);

      const colorsToUse = getCorporateIdentityColors(corporateDataResponse?.data, DEFAULT_COLORS);
      setColors(addIconTypeAsProperty(colorsToUse, theme));
    } else {
      setColors(DEFAULT_COLORS);
    }
  }, [corporateDataResponse, theme]);

  useEffect(() => {
    if (headerDataResponse?.exists) {
      setHeaderData(headerDataResponse.data);
    }
  }, [headerDataResponse]);

  useEffect(() => {
    window.AP.events.on("caelor-navigation-status-update", ({ isActive }) => setIsNavActiveInHub(isActive));
  }, []);

  useEffect(() => {
    setDashboardSetHasInitEmployeeDatabase(!!isInitialized);
    setPeopleNetworkSetHasInitEmployeeDatabase(!!isInitialized);
    setIsDbInit(!!isInitialized);
  }, [isInitialized]);

  const checkIfAdminSeenWizard = (accountId) => {
    if (!accountId) return;
    Promise.all([
      getUserWizardConfigProperty(accountId, DASHBOARD_WIZARD_PROPERTY_KEY),
      getUserWizardConfigProperty(accountId, PEOPLE_WIZARD_PROPERTY_KEY),
      getUserWizardConfigProperty(accountId, PROFILE_WIZARD_PROPERTY_KEY),
    ])
      .then(([dashboardData, peopleData, profileData]) => {
        const dashboard = dashboardData ? JSON.parse(dashboardData.body)?.value?.userSeenWizard : dashboardData;
        const people = peopleData ? JSON.parse(peopleData.body)?.value?.userSeenWizard : peopleData;
        const profile = profileData ? JSON.parse(profileData.body)?.value?.userSeenWizard : profileData;

        wizardProperties.current = {
          dashboard,
          people,
          profile,
        };
      })
      .catch(() => {
        wizardProperties.current = {
          dashboard: false,
          people: false,
          profile: false,
        };
      })
      .finally(() => {
        setHasLoadedWizardProperties(true);
      });
  };

  useEffect(() => {
    if (hasLoadedWizardProperties) {
      if (selectedPage === Pages.DASHBOARD && !wizardProperties.current.dashboard) {
        setDashboardCurrentWizardStep(0);
      }

      if (selectedPage === Pages.PEOPLE && !wizardProperties.current.people) {
        setPeopleCurrentWizardStep(0);
      }

      if (selectedPage === Pages.PROFILE && !wizardProperties.current.profile) {
        setProfileCurrentWizardStep(0);
      }
    }
  }, [selectedPage, hasLoadedWizardProperties, wizardProperties]);

  useEffect(() => {
    (async () => {
      setLoadingUser(true);
      const [currentUser, isConfAdmin] = await window.AP.request(
        "/rest/api/user/current?expand=operations,isExternalCollaborator",
      )
        .then((payload) => JSON.parse(payload.body))
        .then(async (user) => {
          setIsGuest(!!user?.isExternalCollaborator);
          setIsAnonymous(user?.type === "anonymous");
          setCurrentUser(user);
          const userIsConfAdmin = user.operations.some(
            (o) => o.operation === "administer" && o.targetType === "application",
          );
          return [user, !!userIsConfAdmin];
        })
        .catch(() => {
          setCurrentUser(undefined);
          return [undefined, false];
        });

      let isCosmosAdmin = false;
      if (!isConfAdmin) {
        isCosmosAdmin = await checkIfUserIsCosmosAdmin()
          .then((res) => {
            return !!res?.data;
          })
          .catch(() => {
            return false;
          });
      }

      const isAdmin = isConfAdmin || isCosmosAdmin;

      setIsCurrentUserAdmin(isAdmin);
      setLoadingUser(false);
      if (isAdmin) {
        checkIfAdminSeenWizard(currentUser?.accountId);
      }
    })();
  }, []);

  useEffect(() => {
    if (!cosmosLayout?.length) return;
    setLoadingPage(true);
    const urlParams = new URLSearchParams(window.location.search);
    const accountId = urlParams.get("accountId");
    const cosmosPage = urlParams.get("cosmosPage");

    if (cosmosPage) {
      const [page, sectionKey, sectionPageId] = cosmosPage.split("-");
      if (page === Pages.SETTINGS && sectionKey && sectionPageId) {
        setSettingsSubpage(`${sectionKey}-${sectionPageId}`);
      }

      if (
        (isAnonymous && [Pages.PROFILE, Pages.SETTINGS, Pages.PEOPLE].includes(page)) ||
        (isGuest && [Pages.SETTINGS, Pages.PEOPLE].includes(page))
      ) {
        setSelectedPage(Pages.DASHBOARD);
        return;
      }

      const isPageDisabled = !!cosmosLayout?.find((layoutFeature) => layoutFeature.id === page)?.isDisabled;
      if (isPageDisabled) {
        if ((!accountId || accountId === currentUser?.accountId || isCurrentUserAdmin) && page === Pages.PROFILE) {
          setSelectedPage(page, false);
        } else {
          setSelectedPage(Pages.DASHBOARD, false);
        }

        setLoadingPage(false);
        return;
      }

      if (isCurrentUserAdmin) {
        setSelectedPage(page, false);
      } else {
        setSelectedPage(page === Pages.SETTINGS ? Pages.DASHBOARD : page, false);
      }
    } else if (accountId) {
      const isProfileDisabled = !!cosmosLayout?.find((layoutFeature) => layoutFeature.id === Pages.PROFILE)?.isDisabled;

      if (isAnonymous) {
        setSelectedPage(Pages.DASHBOARD);
        return;
      }

      if (isProfileDisabled) {
        if (accountId === currentUser?.accountId || isCurrentUserAdmin) {
          setSelectedPage(Pages.PROFILE, false);
        } else {
          setSelectedPage(Pages.DASHBOARD, false);
        }
      } else {
        setSelectedPage(Pages.PROFILE, false);
      }
    }
    setLoadingPage(false);
  }, [currentUser, isCurrentUserAdmin, isAnonymous, cosmosLayout]);

  useEffect(() => {
    setTimeout(() => {
      window.AP.sizeToParent();
    }, 150);

    (async () => {
      try {
        const isMacroSuiteInstalled = await checkMacroSuiteLicense();
        setIsMacroSuiteInstalled(!!isMacroSuiteInstalled);

        const isContentVizInstalled = await checkContentVizLicense();
        setIsContentVizInstalled(!!isContentVizInstalled);
      } catch (error) {
        setIsMacroSuiteInstalled(false);
        setIsContentVizInstalled(false);
      }
    })();
  }, []);

  const icons = useMemo(
    () => [
      {
        Icon: PeopleNetworkIcon,
        page: Pages.PEOPLE,
        label: Labels.PEOPLE,
        isDisabled:
          !!cosmosLayout?.find((layoutFeature) => layoutFeature.id === Pages.PEOPLE)?.isDisabled ||
          isAnonymous ||
          isGuest,
      },
      {
        Icon: NewsroomIcon,
        page: Pages.NEWS,
        label: Labels.NEWS,
        isDisabled: !!cosmosLayout?.find((layoutFeature) => layoutFeature.id === Pages.NEWS)?.isDisabled,
      },
      {
        Icon: SpaceFolderIcon,
        page: Pages.SPACE,
        label: Labels.SPACE,
        isDisabled: !!cosmosLayout?.find((layoutFeature) => layoutFeature.id === Pages.SPACE)?.isDisabled,
      },
    ],
    [cosmosLayout, isAnonymous],
  );

  useEventListener("beforeunload", (evt) => {
    if (hasChanges || hasHeaderChanges || hasNavigationChanges) {
      evt.preventDefault();
      evt.returnValue = "There are unsaved changes. Are you sure you want to leave?";
      return;
    }
  });

  const createHrefLinkForPage = (page) =>
    `${window.AP._hostOrigin}/wiki/plugins/servlet/ac/com.caelor.confluence.cloud.cosmos/caelor-cosmos-portal?ac.cosmosPage=${page}`;

  if (loadingHeaderSettings || loadingCorporateIdentity || loadingLayout || loadingPage || loadingConstruction) {
    return (
      <div style={{ height: "100vh", overflow: "hidden", display: "grid", placeContent: "center" }}>
        <Spinner size="xlarge" />
      </div>
    );
  }

  if (underConstructionActivated && !isCurrentUserAdmin) {
    return (
      <UnderConstructionFull
        title={messageTitle}
        description={messageDescription}
        buttonText={messageButton}
        link={messageLink}
      />
    );
  }
  return (
    <SpotlightManager>
      <HubMainWrapper>
        {isNavActiveInHub && <NavigationMenu openOverContent={true} isHub />}
        <HubSecondaryWrapper isNavActive={isNavActiveInHub}>
          <HubSidebar>
            <LogoImageLink target="_top" href={createHrefLinkForPage(Pages.DASHBOARD)}>
              <LogoImage src={logo || "./images/hub/sidebar-logo.svg"} alt="Logo" />
            </LogoImageLink>

            <Icons>
              {icons
                .filter((icon) => !icon.isDisabled)
                .map(({ Icon, page, label }) => (
                  <div key={label} style={{ position: "relative" }}>
                    <Tooltip content={label} position="right">
                      <IconLink target="_top" href={createHrefLinkForPage(page)}>
                        <SidebarIconContainer
                          isActive={selectedPage === page}
                          activeColor={colors ? colors[COLOR_TYPES.PRIMARY] : "#2F7AFF"}
                          inactiveColor={theme.shared.sidebar.icons.bg}
                          iconType={colors ? colors[COLOR_TYPES.ICON_TYPE] : ICON_BG_TYPES.COLOR}
                          iconShapeType={colors ? colors[COLOR_TYPES.ICON_BG_SHAPE_TYPES] : ICON_BG_SHAPE_TYPES.CIRCLE}
                        >
                          <Icon
                            isActive={selectedPage === page}
                            activeColor={colors ? colors[COLOR_TYPES.PRIMARY] : "#2F7AFF"}
                            inactiveColor={theme.shared.sidebar.icons.color}
                          />
                        </SidebarIconContainer>
                      </IconLink>
                    </Tooltip>
                  </div>
                ))}
            </Icons>

            <FooterIcons>
              <div
                className="spaceport-icon"
                style={{
                  borderTop: `1px solid ${theme.shared.sidebar.icons.divider}`,
                  paddingTop: "16px",
                  marginBottom: "24px",
                }}
              >
                <Tooltip content={Labels.INFO} position="right">
                  <FooterIconContainer
                    isActive={spaceportOpen}
                    activeColor={colors ? colors[COLOR_TYPES.PRIMARY] : "#2F7AFF"}
                    inactiveColor={theme.shared.sidebar.icons.bg}
                    iconType={colors ? colors[COLOR_TYPES.ICON_TYPE] : ICON_BG_TYPES.COLOR}
                    iconShapeType={colors ? colors[COLOR_TYPES.ICON_BG_SHAPE_TYPES] : ICON_BG_SHAPE_TYPES.CIRCLE}
                    onClick={() => setSpaceportOpen(!spaceportOpen)}
                  >
                    <InfoIcon
                      isActive={spaceportOpen}
                      activeColor={colors ? colors[COLOR_TYPES.PRIMARY] : "#2F7AFF"}
                      inactiveColor={theme.shared.sidebar.icons.color}
                    />
                  </FooterIconContainer>
                </Tooltip>
              </div>

              {isCurrentUserAdmin && (
                <div style={{ marginBottom: "24px" }}>
                  <Tooltip content={Labels.SETTINGS} position="right">
                    <SpotlightTarget name="settings">
                      <IconLink target="_top" href={createHrefLinkForPage(Pages.SETTINGS)}>
                        <FooterIconContainer
                          isActive={selectedPage === Pages.SETTINGS}
                          inactiveColor={theme.shared.sidebar.icons.bg}
                          iconType={colors ? colors[COLOR_TYPES.ICON_TYPE] : ICON_BG_TYPES.COLOR}
                          iconShapeType={colors ? colors[COLOR_TYPES.ICON_BG_SHAPE_TYPES] : ICON_BG_SHAPE_TYPES.CIRCLE}
                        >
                          <SettingsIcon
                            isActive={selectedPage === Pages.SETTINGS}
                            activeColor={colors ? colors[COLOR_TYPES.PRIMARY] : "#2F7AFF"}
                            inactiveColor={theme.shared.sidebar.icons.color}
                          />
                        </FooterIconContainer>
                      </IconLink>
                    </SpotlightTarget>
                  </Tooltip>
                </div>
              )}

              {loadingUser ? (
                <ContentLoader
                  speed={2}
                  width={38}
                  height={38}
                  viewBox="0 0 38 38"
                  backgroundColor="#f5f8fB"
                  foregroundColor="#ecebeb"
                >
                  <circle cx="19" cy="19" r="19" />
                </ContentLoader>
              ) : (
                <div style={{ display: "grid", placeContent: "center", paddingBottom: "24px" }}>
                  {isAnonymous && <UserProfileImage src={"/images/hub/dashboard/anonymous_user.png"} alt="" />}
                  {!isAnonymous && (
                    <LogoImageLink target="_top" href={createHrefLinkForPage(Pages.PROFILE)}>
                      <UserProfileImage src={`${window.AP._hostOrigin}${currentUser?.profilePicture?.path}`} alt="" />
                    </LogoImageLink>
                  )}
                </div>
              )}
            </FooterIcons>

            <DashboardWizard
              currentWizardStep={dashboardCurrentWizardStep}
              setCurrentWizardStep={setDashboardCurrentWizardStep}
              updateWizardProperties={updateWizardProperties}
            />
          </HubSidebar>

          <PageWrapper
            id="hub-overflow-container"
            isNavActive={isNavActiveInHub}
            isInSettings={selectedPage === Pages.SETTINGS}
            backgroundColor={backgroundColor}
          >
            {selectedPage === Pages.DASHBOARD && <Dashboard />}
            {selectedPage === Pages.NEWS && <Newsroom />}
            {selectedPage === Pages.PROFILE && (
              <UserProfile
                currentWizardStep={profileCurrentWizardStep}
                setCurrentWizardStep={setProfileCurrentWizardStep}
                updateWizardProperties={updateWizardProperties}
              />
            )}
            {selectedPage === Pages.PEOPLE && (
              <PeopleBase
                currentWizardStep={peopleCurrentWizardStep}
                setCurrentWizardStep={setPeopleCurrentWizardStep}
                updateWizardProperties={updateWizardProperties}
              />
            )}
            {selectedPage === Pages.SPACE && <SpaceDirectory />}
            {selectedPage === Pages.SETTINGS && <Settings />}
          </PageWrapper>
        </HubSecondaryWrapper>

        <Spaceport spaceportOpen={spaceportOpen} setSpaceportOpen={setSpaceportOpen} />

        {underConstructionActivated && (
          <ConstructionFlag
            bottom={selectedPage === Pages.SETTINGS ? "48px" : "120px"}
            shouldRender={loadConstructionFlag}
          />
        )}
      </HubMainWrapper>
    </SpotlightManager>
  );
}

export default Hub;
