import React from "react";
import { useMacroSuiteConnectionStore } from "../../../../../Shared/store/macrosuite-connection-store";
import { Box, Flex } from "@atlaskit/primitives";
import MacroSuiteInstallationBanner from "../../../../ContentBuilder/Shared/MacroSuiteInstallationBanner";
import { RootEntryColors } from "../../shared/MacrosuitePoweredColors";
import { useNavigationStore } from "../../../store";

function Design() {
  const structure = useNavigationStore((state) => state.structure);
  const isMacroSuiteInstalled = useMacroSuiteConnectionStore((state) => state.isMacroSuiteInstalled);

  return (
    <Box style={{ width: "100%", display: "grid", gridTemplateRows: "auto 142px" }}>
      <Flex direction="column">
        <RootEntryColors isDisabled={!isMacroSuiteInstalled || !structure?.length} />
      </Flex>

      {!isMacroSuiteInstalled && (
        <Box>
          <MacroSuiteInstallationBanner />
        </Box>
      )}
    </Box>
  );
}

export default Design;
