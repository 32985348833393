import React from "react";
import styled, { useTheme } from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-flow: row;
  gap: 10px;
`;

const IconContainer = styled.div`
  display: inline-flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 30px;
  color: ${({ theme }) => theme.newsMetadataSettings.categoryIcon};
`;

const TextWrapper = styled.div`
  display: flex;
  flex-flow: column;
  gap: 4px;
`;

const Title = styled.div`
  color: ${({ theme }) => theme.newsMetadataSettings.title};

  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Description = styled.div`
  color: ${({ theme }) => theme.newsMetadataSettings.description};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
`;

function SectionHeader({ Icon, title, description }) {
  return (
    <Wrapper>
      <IconContainer>
        <Icon primaryColor="rgba(0, 101, 255, 1)" />
      </IconContainer>
      <TextWrapper>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </TextWrapper>
    </Wrapper>
  );
}

export default SectionHeader;
