import React, { useEffect, useState } from "react";
import { PeoplePropertyKeys } from "../configurator/propertyTypes";
import { useQuery } from "react-query";
import { fetchRecords } from "../../../../../../../UserDatabase/datagrid/api";
import { fetchDetailsForUsersIndividually } from "../../../../../Dashboard/api";
import styled from "styled-components";
import SelectFilter from "../../../../../Dashboard/filter/SelectFilter";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import AddIcon from "@atlaskit/icon/glyph/add";
import Reorderable from "../configurator/Reorderable";

const SelectButtonWrapper = styled.div`
  display: flex;
  gap: 16px;

  & > div:first-of-type {
    flex: 1;
  }
`;

const IconWrapper = styled.button`
  width: 40px;
  height: 40px;
  border-radius: 3px;
  margin-top: 25px;
  background-color: ${({ theme }) => theme.shared.form.button.background.inactive};
  display: grid;
  place-items: center;
  transition:
    background-color 200ms ease-in-out,
    border-color 200ms ease-in-out;
  cursor: pointer;
  border: none;

  &:hover {
    background-color: ${({ theme }) => theme.shared.form.button.background.hover};
  }
`;

const Manual = ({ panelId, data, updatePanelDataProperty }) => {
  const [loading, setLoading] = useState(true);
  const [people, setPeople] = useState([]);

  const addNewUserManually = (selectedUser) => {
    if (!selectedUser) return;
    const newManuallyPicked = [...data.manuallyPicked, selectedUser];
    updatePanelDataProperty(panelId, PeoplePropertyKeys.MANUALLY_PICKED, newManuallyPicked);
  };

  const removeUserManually = (itemId) => {
    const newManuallyPicked = data.manuallyPicked.filter((item) => item.accountId !== itemId);
    updatePanelDataProperty(panelId, PeoplePropertyKeys.MANUALLY_PICKED, newManuallyPicked);
  };

  const formattedPeople = people
    ?.filter(
      (user) => !data.manuallyPicked.some((manuallyPickedUser) => user.accountId === manuallyPickedUser.accountId),
    )
    .map((user) => ({
      label: user.name,
      value: user,
    }));

  const { isLoading: recordsLoading, data: recordsData } = useQuery(
    [
      "userdatabase-records",
      {
        filters: [{ isMultiUser: true, type: "BOOLEAN", value: true, column: "hidden", condition: "NOT" }],
      },
    ],
    fetchRecords,
    {
      retry: 0,
      select: (response) => {
        const { data } = response;
        return data?.hits;
      },
    },
  );

  const getDetailsForUsers = (users) => {
    if (users?.length) {
      setLoading(true);
      fetchDetailsForUsersIndividually(users.map((u) => u.accountId))
        .then((resUsers) => {
          if (resUsers?.length) {
            const formattedUsers = resUsers.map((user) => {
              return {
                accountId: user.accountId,
                name: user.displayName,
                icon: `${window.AP._hostOrigin}${user.profilePicture.path}`,
              };
            });

            setPeople([...formattedUsers]);
          }
        })
        .catch(() => setPeople([]))
        .finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    if (!!recordsData?.length) {
      getDetailsForUsers(recordsData);
    }
  }, [recordsData, recordsLoading]);

  const resetOrder = (list) => {
    const resetList = list.map((c, index) => {
      const slide = c;
      slide.order = index + 1;
      return slide;
    });
    return resetList;
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return resetOrder(result);
  };

  const reorderPanels = (sourceId, targetId) => {
    if (sourceId === targetId) {
      return;
    }
    const copyOfLinks = [...data.manuallyPicked];
    const sourceIndex = sourceId;
    const targetIndex = targetId;
    const items = reorder(copyOfLinks, sourceIndex, targetIndex);
    updatePanelDataProperty(panelId, PeoplePropertyKeys.MANUALLY_PICKED, items);
  };

  return (
    <>
      <div style={{ marginTop: "20px" }}>
        <DndProvider backend={HTML5Backend}>
          {data?.manuallyPicked.map((manuallyPickedUser, _idx) => (
            <Reorderable
              user={manuallyPickedUser}
              reorderPanels={reorderPanels}
              index={_idx}
              removeUserManually={removeUserManually}
            />
          ))}
        </DndProvider>
      </div>
      <SelectButtonWrapper>
        <SelectFilter
          hideValueFromSelect={true}
          placeholder="Select people.."
          setValue={(selectedUser) => addNewUserManually(selectedUser)}
          options={formattedPeople}
          closeMenuOnSelect={false}
        />
      </SelectButtonWrapper>
    </>
  );
};

export default Manual;
