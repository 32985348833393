import { useQueries } from "react-query";
import {
  getCoverPictureOfContent,
  getIconOfContent,
} from "../../../../../../ContentBuilder/Shared/cover-picture-provider";
import { htmlDecode } from "../../../../../../ContentBuilder/utils/decode-html-string";
import { getPageById } from "../../../api";

export const mapPage = (page, locale) => {
  return {
    id: page.content.id,
    type: page.content.type,
    name: page.content.title,
    numberOfComments: page.content.children.comment.results.length,
    numberOfLikes: page.content.metadata.likes.count,
    space: { name: page.content.space.name },
    excerpt: htmlDecode(page.excerpt),
    coverPictureCallback: async () => getCoverPictureOfContent(page.content.id),
    labels: page.content.metadata?.labels?.results.map((l) => l.label) ?? [],
    url: `${window.AP._hostOrigin}/wiki${page.url}`,
    authorUrl: `${window.AP._hostOrigin}/wiki/people/${page.content.history.createdBy.accountId}`,
    spaceUrl: `${window.AP._hostOrigin}/wiki/spaces/${page.content.space.key}/overview`,
    editUrl: `${window.AP._hostOrigin}/wiki/spaces/${page.content.space.key}/${
      page.content.type === "blogpost" ? "blog" : "pages"
    }/edit-v2/${page.content.id}`,
    createdBy: page.content.history.createdBy.displayName,
    avatarUrl: `${window.AP._hostOrigin}${page.content.history.createdBy.profilePicture.path}`,
    lastModified: page.friendlyLastModified,
    created: page.content.history.createdDate,
    contentId: page.content.id,
    emojiCallback: async () => getIconOfContent(page.content.id),
    createdFormatted: new Date(page.content.history.createdDate).toLocaleString(`${locale ?? "en-US"}`, {
      year: "numeric",
      month: "short",
      day: "numeric",
    }),
  };
};

export const usePages = (data) =>
  useQueries(
    data.map((page) => {
      return {
        queryKey: ["page", page.contentId, page.id],
        queryFn: () => {
          if (page.contentId === 0) {
            return { isNew: true, loading: false, id: page.id };
          }
          return getPageById(page.contentId);
        },
        retry: 0,
        placeholderData: {
          contentId: page.contentId,
          loading: true,
          isPlaceholder: true,
        },
        select: (response) => {
          if (response.isPlaceholder || response.isNew) {
            return response;
          }
          const body = JSON.parse(response.body);
          const result = body.results.map((p) => {
            return {
              ...mapPage(p),
              id: data.find((x) => x.id.toString() === page.id).id,
            };
          });
          return result[0];
        },
      };
    }),
  );
