import React, { useCallback, useState } from "react";
import CustomModal from "../CustomModal";
import { useEffect } from "react";
import { useQuery } from "react-query";
import styled from "styled-components";
import { FieldContainer, Label } from "../../../../../../../../NewsMetaDataDialog/Components/shared/styled";
import Select from "@atlaskit/select";
import {
  addCategoryToCollections,
  fetchCollections,
  getCollectionsByTargetedCategories,
} from "../../../../../../../Collections/api";
import { isEqual } from "lodash";
import { sortArray } from "../../helpers/sortArray";
import { useCategoriesStore } from "../../../categories-store";

const Description = styled.div`
  color: ${({ theme }) => theme.newsMetadataSettings.description};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  padding-top: 12px;
`;

function TargetCollections({ closeModal }) {
  const [loading, setLoading] = useState(false);

  const [collections, setCollections] = useState([]);
  const [selectedCollections, setSelectedCollections] = useState([]);
  const [savedCollections, setSavedCollections] = useState([]);

  const { selectedCategory } = useCategoriesStore((state) => ({ selectedCategory: state.selectedCategory }));

  const { isLoading, data } = useQuery("saved-collections", fetchCollections, {
    cacheTime: 0,
    retry: 0,
    select: (response) => {
      const { data } = response || {};
      return data || [];
    },
  });

  const fetchSelectedCollections = useCallback(() => {
    getCollectionsByTargetedCategories(selectedCategory.id)
      .then((res) => {
        if (res.data?.length) {
          setSavedCollections(res.data.map(({ id }) => id));
          setSelectedCollections(
            res.data.map(({ id, name }) => ({ label: name || "Collection without name", value: id, id })),
          );
        }
      })
      .catch((err) => console.log(err));
  }, [selectedCategory?.id]);

  useEffect(() => {
    fetchSelectedCollections();
  }, [fetchSelectedCollections]);

  useEffect(() => {
    if (!isLoading && data?.length) {
      setCollections([...data]);
    }
  }, [isLoading, data]);

  const saveSelectedCollections = async () => {
    try {
      await addCategoryToCollections(
        selectedCategory.id,
        selectedCollections.map(({ id }) => id),
      );
      return true;
    } catch (error) {
      return false;
    }
  };

  const saveCollections = async () => {
    try {
      setLoading(true);
      if (
        selectedCollections?.length &&
        !isEqual(savedCollections, sortArray(selectedCollections.map(({ id }) => id)))
      ) {
        await saveSelectedCollections();
      }
      closeModal();
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
      const flag = window.AP.flag.create({
        title: "Targeted Collection has been updated",
        type: "success",
      });

      setTimeout(() => {
        flag.close();
      }, 3000);
    }
  };

  return (
    <CustomModal
      heading="Target Collection"
      isUpdateDone={loading}
      primaryButtonText="Save"
      onCancel={closeModal}
      onNext={() => saveCollections()}
      children={
        <div>
          <Description>
            By selecting relevant Cosmos collections, you ensure a personalized news experience across all news widgets,
            enhancing content relevance and engagement.
          </Description>
          <FieldContainer>
            <Label>Collections</Label>
            <Select
              placeholder="Select collections"
              menuPlacement="auto"
              isClearable={false}
              options={collections.map(({ id, name }) => ({
                label: name || "Collection without name",
                value: id,
                id,
              }))}
              value={selectedCollections}
              onChange={(options) => {
                setSelectedCollections([...(options || [])]);
              }}
              isDisabled={isLoading}
              isLoading={isLoading}
              isMulti
            />
          </FieldContainer>
        </div>
      }
    />
  );
}

export default TargetCollections;
