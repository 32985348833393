import React, { useEffect, useState } from "react";
import Spinner from "@atlaskit/spinner";
import { useInfiniteQuery, useQuery, useQueryClient } from "react-query";
import { useAtlassianLocale } from "../../../../../../../Shared/Hooks/useAtlassianLocale";
import { searchBlogposts } from "../../../api";
import styled, { useTheme } from "styled-components";
import { InlineNews, CoverStory } from "@caelor/cards-and-panels-components";
import { LoadingTypes } from "../../../filter/DynamicFilterToggle";
import { useBlogs } from "../../../filter/useBlogs";
import { NewsManual } from "./NewsManual";
import { usePageStore } from "../../../../../page-store";
import { Pages } from "../../../../../pages";
import { mapBlogpost } from "./handleBlogData";
import { EmptyForFilters, EmptyManual, EmptyNoNews } from "./EmptyStates";
import Pagination from "../../../../Shared/PanelsPagination/Pagination";
import { OverlineWrapper, PersonDetails, PersonIcon, SideNewsListContainer, SpaceLabel, Title } from "./styled";
import { useCorporateIdentityStore } from "../../../../Settings/General/BrandAndColors/corporate-identity-store";
import { useSidebarStore } from "../../edit/sidebar/sidebar-store";
import { PanelsPaginationTypes } from "../../../../Shared/PanelsPagination/panels-pagination-types";
import { htmlDecode } from "../../../../../../ContentBuilder/utils/decode-html-string";
import PanelWrapper from "../../../../Shared/Panels/shared/components/PanelWrapper";
import { getCategoriesSettings } from "../../../../Settings/News/api";

export const MagazineGridContainer = styled.div`
  display: grid;
  grid-template-columns: ${({ isLargeStory }) => (isLargeStory ? "repeat(4, 1fr)" : "repeat(2, 1fr)")};
  grid-template-rows: ${({ isLargeStory }) => (isLargeStory ? "repeat(4, 1fr)" : "1fr")};
  grid-column-gap: ${({ padding }) => `${padding || 24}px`};
  grid-row-gap: ${({ padding }) => `${padding || 24}px`};
  height: ${({ isLargeStory }) => (isLargeStory ? "500px" : "240px")};
  .child {
    > div,
    > a > div {
      height: calc(100% - 35px) !important;
    }

    .news-title {
      transition: all 250ms ease-in-out !important;
    }

    &:hover {
      .news-excerpt {
        height: 60px;
      }

      .news-title {
        font-size: 20px;
      }

      .news-metadata,
      .news-title {
        transform: translateY(-60px);
      }
    }
  }
  .grid-large-story-child-1 {
    grid-area: 1 / 1 / 5 / 3;
  }
  .grid-large-story-child-2 {
    grid-area: 1 / 3 / 3 / 5;
  }
  .grid-large-story-child-3 {
    grid-area: 3 / 3 / 5 / 5;
  }

  .grid-child-3 {
    display: none;
  }

  .news-excerpt {
    height: 0px;
    bottom: 40px;
    position: absolute;
    transition: height 250ms ease-in-out;
  }

  .news-title,
  .news-metadata {
    transition: transform 250ms ease-in-out !important;
  }
`;

export const GridContainer = styled.div`
  display: inline-grid;
  grid-template-columns: repeat(2, minmax(0px, 1fr));
  grid-template-rows: auto;
  justify-content: flex-start;
  grid-auto-rows: 1fr;
  gap: ${({ padding }) => `${padding || 24}px`};
  grid-auto-flow: dense;
  place-self: center;
  margin: auto;
  width: 100%;
`;

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;

  & a > div {
    border-color: ${({ theme }) => theme.shared.panel.divider};
  }

  & > a:first-child {
    & > div:first-child {
      padding-top: 0px;
    }
  }
`;

export const NewsTypes = {
  MAGAZINE: "magazine",
  LIST: "list",
  GRID: "grid",
  SIDE_NEWS: "side_news",
};

const DummyPreview = ({ data, borderRadius, version }) => {
  const pictureForBlogpostOne = async () => {
    return "https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1171&q=80";
  };

  const pictureForBlogpostTwo = async () => {
    return "https://images.unsplash.com/photo-1559361815-8b418ee8721b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTd8fG9mZmljZSUyMGRhcmt8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60";
  };

  const pictureForBlogpostThree = async () => {
    return "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80";
  };

  const pictureForBlogpostFour = async () => {
    return "https://images.unsplash.com/photo-1497366811353-6870744d04b2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTF8fG9mZmljZXxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60";
  };

  const getPictureFunction = (blogId) => {
    return {
      "blogpost-1": pictureForBlogpostOne,
      "blogpost-2": pictureForBlogpostTwo,
      "blogpost-3": pictureForBlogpostThree,
      "blogpost-4": pictureForBlogpostFour,
    }[blogId];
  };

  const activeElements = version ? data.activeElements : ["image", ...data.activeElements];

  const theme = useTheme();

  return (
    <>
      {data.view === NewsTypes.MAGAZINE && !!data.isDummy && (
        <MagazineGridContainer isLargeStory padding={data.padding}>
          {data.content.map((blog, idx) => (
            <div key={blog.id} className={`grid-large-story-child-${idx + 1} child`}>
              <CoverStory
                key={blog.id}
                coverPictureCallback={getPictureFunction(blog.id)}
                name={blog.name}
                space={activeElements?.includes("space") ? blog.space : {}}
                url={blog.url}
                excerpt={activeElements?.includes("description") ? blog.excerpt : null}
                labels={activeElements?.includes("label") ? blog.labels : null}
                createdBy={activeElements?.includes("author") ? blog.createdBy : null}
                avatarUrl={blog.avatarUrl}
                lastModified={activeElements?.includes("date") ? blog.createdFormatted : null}
                labelsBackground={data.labelsBackground}
                coverPictureBorderRadius={borderRadius.replace("px", "")}
                labelsBorderRadius={borderRadius}
                darkTheme={{
                  shadowColors: {
                    default: theme.shared.news.shadows.default,
                    hover: theme.shared.news.shadows.hover,
                  },
                }}
              />
            </div>
          ))}
        </MagazineGridContainer>
      )}

      {data.view === NewsTypes.LIST && !!data.isDummy && (
        <ListContainer>
          {data.content.map((blog, idx) => (
            <InlineNews
              key={blog.id}
              isFirstCard={idx === 0}
              coverPictureCallback={getPictureFunction(blog.id)}
              name={blog.name}
              url={blog.url}
              space={activeElements?.includes("space") ? blog.space : {}}
              excerpt={activeElements?.includes("description") ? blog.excerpt : null}
              labels={activeElements?.includes("label") ? blog.labels : null}
              createdBy={activeElements?.includes("author") ? blog.createdBy : null}
              numberOfComments={activeElements?.includes("comments") ? blog.numberOfComments : null}
              numberOfLikes={activeElements?.includes("likes") ? blog.numberOfLikes : null}
              lastModified={activeElements?.includes("date") ? blog.createdFormatted : null}
              avatarUrl={blog.avatarUrl}
              labelsBackground={data?.teamLabelsBackground}
              coverPictureBorderRadius={borderRadius.replace("px", "")}
              imagePosition={activeElements?.includes("image") ? "left" : "none"}
              maxWidthOfImage={30}
              labelsBorderRadius={borderRadius}
              darkTheme={{
                backgroundColor: theme.shared.news.background,
                titleColor: theme.shared.news.title,
                descriptionColor: theme.shared.news.text,
                authorColor: theme.shared.news.text,
                borderColor: theme.shared.sidebar.divider,
                textColor: theme.shared.news.text,
                borderBottom: theme.shared.sidebar.divider,
              }}
            />
          ))}
        </ListContainer>
      )}
    </>
  );
};

export const DEFAULT_IMAGE_SIZE = 180;
export const DEFAULT_BORDER_RADIUS = 8;
export const DEFAULT_LABELS_COLOR = "#c6c6c6";

function News({ id, panelTitle, data, position, version }) {
  const [locale] = useAtlassianLocale();
  const [page, setPage] = useState(1);

  const queryClient = useQueryClient();

  const [showMoreClicked, setShowMoreClicked] = useState(false);

  const [allCategories, setAllCategories] = useState([]);

  const borderRadius = useCorporateIdentityStore((state) => state.borderRadius);

  const setSelectedPage = usePageStore((state) => state.setSelectedPage);
  const setDisableClickOutside = useSidebarStore((state) => state.setDisableClickOutside);

  const theme = useTheme();

  const { isLoading: isLoadingCategories, data: categoriesSettingsData } = useQuery(
    "categories-settings",
    getCategoriesSettings,
    {
      retry: 0,
      select: (response) => {
        const { data } = response;
        return data;
      },
    },
  );

  const {
    isLoading,
    fetchNextPage,
    hasNextPage,
    data: blogsData,
  } = useInfiniteQuery(
    [
      `blogposts-${id}`,
      {
        labels: data.labels,
        contributors: data.contributors,
        spaces: data.spaces,
        limit: !!data.isSideNews
          ? data.maxNumberOfItems
          : data.view === NewsTypes.MAGAZINE
          ? 3
          : data.maxNumberOfItems || 4,
        sorting: data.sortBy,
        ordering: data.ordering,
      },
    ],
    searchBlogposts,
    {
      enabled: !data.isDummy || data.loadingType === LoadingTypes.DYNAMIC,
      retry: 0,
      select: (response) => {
        const pages = [];
        response.pages.forEach((page) => {
          const body = JSON.parse(page.body);
          const resultsInPage = body.results.map((blog) => mapBlogpost(blog, locale, allCategories));
          pages.push({ ...body, results: resultsInPage });
        });
        return { pages, pageParams: response.pageParams };
      },
      getNextPageParam: (lastPage) => {
        const body = JSON.parse(lastPage.body);
        if (body._links.next) {
          const urlParams = new URLSearchParams(encodeURI(body._links.next));
          const cursor = urlParams.get("cursor");
          return cursor;
        }
        return false;
      },
    },
  );

  useEffect(() => {
    if (!isLoadingCategories && categoriesSettingsData?.exists) {
      setAllCategories(categoriesSettingsData?.categories?.data || []);
    }
  }, [isLoadingCategories, categoriesSettingsData]);

  useEffect(() => {
    setPage(1);
    setShowMoreClicked(false);
    queryClient.resetQueries([`blogposts-${id}`]);
  }, [
    data.pagination,
    data.maxNumberOfItems,
    data.view,
    data.sortBy,
    data.ordering,
    data.contributors,
    data.spaces,
    data.labels,
  ]);

  const numberOfItems = {
    [NewsTypes.GRID]: data.maxNumberOfItems,
    [NewsTypes.LIST]: data.maxNumberOfItems,
    [NewsTypes.MAGAZINE]: 3,
    [NewsTypes.SIDE_NEWS]: data.maxNumberOfItems,
  };

  const paginatedBlogsData =
    data.pagination === PanelsPaginationTypes.ARROWS ? [blogsData?.pages[page - 1]] : blogsData?.pages;

  const blogQueriesUnfiltered = useBlogs(data.manuallyPicked || [], locale, allCategories);
  const blogQueries = blogQueriesUnfiltered.filter((blog) => blog.data && !!Object.keys(blog.data || {}).length);

  if (data.loadingType === LoadingTypes.MANUAL && !data.isDummy) {
    if (!blogQueries?.length) {
      return <EmptyManual panelTitle={panelTitle} />;
    }

    return (
      <PanelWrapper
        panelTitle={panelTitle}
        displayOptions={{
          displayTitle: data?.displayTitle ?? true,
          displayBox: data?.displayBox ?? true,
        }}
      >
        <NewsManual data={data} blogs={blogQueries} version={version} />
      </PanelWrapper>
    );
  }

  const activeElements = version ? data.activeElements : ["image", ...data.activeElements];

  const hasFilters = !!data.labels || !!data.contributors || !!data.spaces;

  if (!!blogsData?.pages?.length && !blogsData.pages[0].results.length && !data.isDummy) {
    if (hasFilters) {
      return <EmptyForFilters panelTitle={panelTitle} />;
    }

    return <EmptyNoNews panelTitle={panelTitle} />;
  }
  const displayTitleFallback = data.displayTitle ?? true;

  const numOfTotalPages = Math.ceil(blogsData?.pages[0]?.totalSize / numberOfItems[data.view]);
  return (
    <PanelWrapper
      panelTitle={panelTitle}
      displayOptions={{
        displayTitle: data?.displayTitle ?? true,
        displayBox: data?.displayBox ?? true,
      }}
    >
      {isLoading && (
        <div style={{ display: "grid", placeContent: "center" }}>
          <Spinner size="xlarge" />
        </div>
      )}

      {!!data.isDummy ? (
        <DummyPreview data={data} borderRadius={borderRadius} version={version} />
      ) : (
        <>
          {!!data?.isSideNews &&
            !isLoading &&
            !!blogsData?.pages?.length &&
            paginatedBlogsData?.map((page, index) => (
              <SideNewsListContainer key={`page-${index}`} style={{ marginTop: index === 0 ? "0px" : "20px" }}>
                {page?.results?.map((blog) => (
                  <div
                    key={blog.id}
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      e.stopPropagation();
                      window.open(blog.url, "_blank");
                    }}
                  >
                    <OverlineWrapper isTwoColumns={activeElements?.includes("author")}>
                      {activeElements?.includes("author") && <PersonIcon src={blog.avatarUrl} />}
                      <PersonDetails>
                        {activeElements?.includes("author") && `${blog.createdBy}`}
                        {activeElements?.includes("author") && activeElements?.includes("date") && " · "}
                        {activeElements?.includes("date") && `${blog.createdFormatted}`}
                      </PersonDetails>
                    </OverlineWrapper>
                    <Title color={theme.shared.card.title}>{blog.name}</Title>
                    {activeElements?.includes("space") && (
                      <SpaceLabel backgroundColor={data.labelsBackground}>{blog.space.name}</SpaceLabel>
                    )}
                  </div>
                ))}
              </SideNewsListContainer>
            ))}

          {data.view === NewsTypes.MAGAZINE &&
            !isLoading &&
            !!blogsData?.pages?.length &&
            paginatedBlogsData?.map((page, index) => (
              <div key={`page-${index}`} style={{ marginTop: index !== 0 ? "24px" : "0px" }}>
                <MagazineGridContainer isLargeStory={index === 0} padding={data.padding}>
                  {page?.results?.map((blog, idx) => (
                    <div
                      key={blog.id}
                      className={`${index === 0 ? `grid-large-story-child-${idx + 1}` : `grid-child-${idx + 1}`} child`}
                    >
                      <CoverStory
                        key={blog.id}
                        coverPictureCallback={blog.coverPictureCallback}
                        getBlogsCategories={
                          activeElements?.includes("categories") ? blog.getBlogsCategories : undefined
                        }
                        name={blog.name}
                        space={activeElements?.includes("space") ? blog.space : {}}
                        url={blog.url}
                        excerpt={activeElements?.includes("description") ? htmlDecode(blog.excerpt) : null}
                        labels={activeElements?.includes("label") ? blog.labels : null}
                        createdBy={activeElements?.includes("author") ? blog.createdBy : null}
                        lastModified={activeElements?.includes("date") ? blog.createdFormatted : null}
                        numberOfComments={activeElements?.includes("comments") ? blog.numberOfComments : null}
                        numberOfLikes={activeElements?.includes("likes") ? blog.numberOfLikes : null}
                        avatarUrl={blog.avatarUrl}
                        labelsBackground={data.labelsBackground}
                        coverPictureBorderRadius={borderRadius.replace("px", "")}
                        labelsBorderRadius={borderRadius}
                        darkTheme={{
                          shadowColors: {
                            default: theme.shared.news.shadows.default,
                            hover: theme.shared.news.shadows.hover,
                          },
                        }}
                      />
                    </div>
                  ))}
                </MagazineGridContainer>
              </div>
            ))}

          {data.view === NewsTypes.GRID && !isLoading && !!blogsData?.pages?.length && (
            <GridContainer padding={data.padding}>
              {paginatedBlogsData?.map((page, index) => (
                <>
                  {page?.results?.map((blog, idx) => (
                    <CoverStory
                      key={blog.id}
                      coverPictureCallback={blog.coverPictureCallback}
                      getBlogsCategories={activeElements?.includes("categories") ? blog.getBlogsCategories : undefined}
                      name={blog.name}
                      space={activeElements?.includes("space") ? blog.space : {}}
                      url={blog.url}
                      excerpt={activeElements?.includes("description") ? htmlDecode(blog.excerpt) : null}
                      labels={activeElements?.includes("label") ? blog.labels : null}
                      createdBy={activeElements?.includes("author") ? blog.createdBy : null}
                      lastModified={activeElements?.includes("date") ? blog.createdFormatted : null}
                      numberOfComments={activeElements?.includes("comments") ? blog.numberOfComments : null}
                      numberOfLikes={activeElements?.includes("likes") ? blog.numberOfLikes : null}
                      avatarUrl={blog.avatarUrl}
                      labelsBackground={data.labelsBackground}
                      coverPictureSize={data.coverPictureSize}
                      coverPictureBorderRadius={borderRadius.replace("px", "")}
                      labelsBorderRadius={borderRadius}
                      darkTheme={{
                        shadowColors: {
                          default: theme.shared.news.shadows.default,
                          hover: theme.shared.news.shadows.hover,
                        },
                      }}
                    />
                  ))}
                </>
              ))}
            </GridContainer>
          )}

          {data.view === NewsTypes.LIST &&
            !isLoading &&
            !!blogsData?.pages?.length &&
            paginatedBlogsData?.map((page, index) => (
              <ListContainer key={`page-${index}`} style={{ marginTop: index !== 0 ? "24px" : "0px" }}>
                {page?.results?.map((blog, idx) => (
                  <>
                    <InlineNews
                      key={blog.id}
                      coverPictureCallback={blog.coverPictureCallback}
                      getBlogsCategories={activeElements?.includes("categories") ? blog.getBlogsCategories : undefined}
                      name={blog.name}
                      space={activeElements?.includes("space") ? blog.space : {}}
                      url={blog.url}
                      excerpt={activeElements?.includes("description") ? htmlDecode(blog.excerpt) : null}
                      labels={activeElements?.includes("label") ? blog.labels : null}
                      createdBy={activeElements?.includes("author") ? blog.createdBy : null}
                      numberOfComments={activeElements?.includes("comments") ? blog.numberOfComments : null}
                      numberOfLikes={activeElements?.includes("likes") ? blog.numberOfLikes : null}
                      lastModified={activeElements?.includes("date") ? blog.createdFormatted : null}
                      avatarUrl={blog.avatarUrl}
                      labelsBackground={data?.teamLabelsBackground}
                      coverPictureBorderRadius={borderRadius.replace("px", "")}
                      imagePosition={activeElements?.includes("image") ? "left" : "none"}
                      maxWidthOfImage={30}
                      labelsBorderRadius={borderRadius}
                      darkTheme={{
                        backgroundColor: theme.shared.news.background,
                        titleColor: theme.shared.news.title,
                        descriptionColor: theme.shared.news.text,
                        authorColor: theme.shared.news.text,
                        borderColor: theme.shared.sidebar.divider,
                        textColor: theme.shared.news.text,
                        borderBottom: theme.shared.sidebar.divider,
                      }}
                    />
                  </>
                ))}
              </ListContainer>
            ))}
        </>
      )}

      <Pagination
        showMoreClicked={showMoreClicked}
        hasNextPage={page < numOfTotalPages}
        // INFINITE pagination type has Show more and view more, which directs the user to Newsroom
        // this is now disabled so there is infinite loading with Show more (Continuous show more button)
        // type={data.pagination === PanelsPaginationTypes.SHOW_MORE ? PanelsPaginationTypes.INFINITE : data.pagination}
        type={data.pagination === PanelsPaginationTypes.INFINITE ? PanelsPaginationTypes.SHOW_MORE : data.pagination}
        page={page}
        updatePage={(v) => {
          setPage(v);
          if(hasNextPage) fetchNextPage();
        }}
        maxPage={numOfTotalPages}
        onWrapperHoverCallback={setDisableClickOutside}
        infiniteQuery={{ shouldFetchNext: hasNextPage, fetchNextPage }}
        // onClick={() => {
        //   if (!!data.isDummy) return;

        //   if (hasNextPage && !showMoreClicked) {
        //     fetchNextPage();
        //     setPage(2);
        //     setShowMoreClicked(true);
        //     return;
        //   }

        //   setSelectedPage(Pages.NEWS);
        // }}
      />
    </PanelWrapper>
  );
}

export default News;
