import React, { useEffect, useState } from "react";
import Spinner from "@atlaskit/spinner";
import { useQuery } from "react-query";
import { WidgetIds } from "../../Settings/Widgets/consts/tabs";
import { getWidgetSettingById } from "../../Settings/Widgets/api";
import { useContentVizConnectionStore } from "../../../../../Shared/store/contentviz-connection-store";
import { Panel, PanelContent } from "../../Dashboard/dashboard-styled";
import OrgChartBuilder from "../../OrgChart/OrgChartBuilder";
import { SpinnerContainer } from "../../../styled/pages";
import { useCurrentUserStore } from "../../../current-user-store";
import { OrgChartPropertyKeys } from "../../Settings/Widgets/components/types/orgchart/available-properties";
import EmptyState, { EmptyStateTypes } from "../../OrgChart/components/EmptyState";
import { createProfileLink } from "./createProfileLink";

function OrgChart() {
  const isContentVizInstalled = useContentVizConnectionStore((state) => state.isContentVizInstalled);
  const isCurrentUserAdmin = useCurrentUserStore((state) => state.isCurrentUserAdmin);

  const [isOrgChartSetup, setIsOrgChartSetup] = useState(false);
  const [orgChartSettings, setOrgChartSettings] = useState();

  const { isLoading: loadingOrgChartSettings, data: widgetSettingData } = useQuery(
    ["org-chart-settings", { widgetId: WidgetIds.ORG_CHART }],
    getWidgetSettingById,
    {
      retry: 0,
      select: (response) => {
        const { data } = response;
        return data;
      },
    },
  );

  useEffect(() => {
    if (!loadingOrgChartSettings && widgetSettingData?.exists) {
      setOrgChartSettings(widgetSettingData.widget);
      setIsOrgChartSetup(
        widgetSettingData.widget?.[OrgChartPropertyKeys.IS_ACTIVE] &&
          !!widgetSettingData.widget?.[OrgChartPropertyKeys.PARENT_USERS]?.length &&
          !!widgetSettingData.widget?.[OrgChartPropertyKeys.SELECTED_FIELD],
      );
    } else {
      setOrgChartSettings(undefined);
      setIsOrgChartSetup(false);
    }
  }, [loadingOrgChartSettings, widgetSettingData]);

  const handleClickOnUser = (accountId) => {
    const url = createProfileLink(accountId);
    window.open(url, "_parent");
  };

  if (loadingOrgChartSettings) {
    return (
      <SpinnerContainer>
        <Spinner size="xlarge" />
      </SpinnerContainer>
    );
  }

  return (
    <Panel style={{ padding: "0px" }}>
      <PanelContent noPadding>
        {isContentVizInstalled && isOrgChartSetup && (
          <OrgChartBuilder
            orgChartSettings={orgChartSettings}
            showControls={false}
            showLevels={true}
            onItemClick={handleClickOnUser}
            minZoomOnCenterView={0.75}
            initialY={-40}
          />
        )}

        {!isContentVizInstalled && isCurrentUserAdmin && <EmptyState type={EmptyStateTypes.NO_LICENSE} />}

        {isContentVizInstalled && !isOrgChartSetup && isCurrentUserAdmin && (
          <EmptyState type={EmptyStateTypes.NOT_SETUP} />
        )}

        {!isCurrentUserAdmin && (!isContentVizInstalled || !isOrgChartSetup) && (
          <EmptyState type={EmptyStateTypes.REGULAR_USER} />
        )}
      </PanelContent>
    </Panel>
  );
}

export default OrgChart;
