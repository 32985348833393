import React, { useEffect, useState, useRef } from "react";
import { AsyncSelect } from "@atlaskit/select";
import { FieldContainer, Label } from "./styled";
import { atlassianRestService } from "../../../../../Service/AtlassianRestService";
import { SELECT_STYLES, SIMPLE_SELECT_STYLES } from "./CustomSelectStyles";

const SpaceMultiSelect = ({
  initialSpaces,
  onUpdate,
  isMulti = true,
  label = "Spaces",
  placeholder = "Filter by spaces",
  containerStyle = {},
  manuallyPicked,
  isDynamic = false,
}) => {
  const [selectedSpacesManual, setSelectedSpacesManual] = useState(manuallyPicked);
  const [selectedSpaces, setSelectedSpaces] = useState(undefined);

  useEffect(() => {
    if ((initialSpaces ?? []).length > 0) {
      const promises = [];
      for (const spaceKey of initialSpaces) {
        promises.push(atlassianRestService.getSpaceByKey(spaceKey));
      }
      Promise.all(promises)
        .then((results) => {
          setSelectedSpaces(results.map((r) => _toSpaceField(r)));
        })
        .catch((e) => {
          // Handle errors here
        });
    }
  }, [initialSpaces]);

  useEffect(() => {
    setSelectedSpacesManual(manuallyPicked);
  }, [manuallyPicked]);

  const loadOptions = async (query) => {
    const searchResults = await atlassianRestService.searchForSpace(query);
    const filteredOptions = searchResults.results
      .map(({ space }) => _toSpaceField(space))
      .filter((space) => !selectedSpacesManual.some((selected) => selected.spaceKey === space.spaceKey));

    return filteredOptions;
  };

  const loadOptionsDynamic = async (query) => {
    return (await atlassianRestService.searchForSpace(query)).results.map(({ space }) => _toSpaceField(space));
  };

  return (
    <FieldContainer style={containerStyle}>
      <Label>{label}</Label>

      <AsyncSelect
        isMulti={isMulti}
        placeholder={placeholder}
        onChange={(e) => {
          if (e === undefined) {
            setSelectedSpaces([]);
            onUpdate([]);
            return;
          }
          if (isMulti) {
            setSelectedSpaces(e);
            onUpdate(e.map((s) => s.spaceKey));
            return;
          }
          setSelectedSpaces([e]);
          onUpdate(e);
        }}
        value={isDynamic ? selectedSpaces : []}
        defaultOptions
        loadOptions={isDynamic ? loadOptionsDynamic : loadOptions}
        styles={SIMPLE_SELECT_STYLES}
        closeMenuOnSelect={false}
      />
    </FieldContainer>
  );
};

function _toSpaceField(space) {
  return {
    icon: space.icon,
    name: space.name,
    spaceKey: space.key,
    type: "space",
    label: space.name,
    value: space.key,
    homepageId: space.homepage.id,
    coverPictureCallback: async () => {},
  };
}

export default SpaceMultiSelect;
