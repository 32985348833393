import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import xss from "xss";
import ArrowUpIcon from "@atlaskit/icon/glyph/hipchat/chevron-up";
import ArrowDownIcon from "@atlaskit/icon/glyph/hipchat/chevron-down";
import London from "./London/London";
import Sydney from "./Sydney/Sydney";
import PaloAlto from "./PaloAlto/PaloAlto";
import Houston from "./Houston/Houston";
import Denver from "./Denver/Denver";
import { MenuStyleTypes } from "../types";
import { handleOpeningLinkByDefault } from "./cardLayoutService";
import { useTheme } from "styled-components";
import { dark } from "../../../../dark-colors";
import { light } from "../../../../light-colors";
import { Pages } from "../../../Hub/pages";
import EntryIcon from "../../configuration/drawer/components/edit/shared/EntryIcon";
import { useNavigationStore } from "../../store";

const themes = {
  dark,
  light,
};

const RootWrapper = styled.div`
  width: calc(100% - 85px);
  padding: 0 25px;
  border-bottom: ${({ isExpanded, menuBorderColor }) =>
    isExpanded ? `1px solid ${menuBorderColor}` : "1px solid transparent"};
  background: ${({ menuBackgroundColor }) => menuBackgroundColor};
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 50px;
  padding-left: 60px;
`;

const RootMenuEntry = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 57px;
  box-sizing: border-box;
  padding: 0px 6px;

  border-bottom: ${({ isSelected, borderColor }) => `2px solid ${isSelected ? borderColor : "transparent"}`};
`;

const RootMenuItemWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  column-gap: 4px;
  color: ${({ menuTextColor, isSelected, menuTextActive }) => {
    if (isSelected && menuTextActive) {
      return menuTextActive;
    }
    return menuTextColor;
  }};
  font-weight: 500;
  font-size: 14px;

  &:hover {
    color: ${({ menuTextHover, menuTextColor }) => {
      if (menuTextHover === "#00000000") return menuTextColor;
      return menuTextHover;
    }};
  }
`;

const CardMenuWrapper = styled.div`
  width: 100%;
  height: fit-content;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
`;

const CardMenu = ({
  isHub,
  colors,
  structure,
  isPreview = false,
  openOverContent,
  themeColorsSet,
  temporaryColorScheme = undefined,
}) => {
  const selectedEntryRef = useRef();
  const [selectedRootEntry, setSelectedRootEntry] = useState(undefined);
  const [tempStructure, setTempStructure] = useState(undefined);

  selectedEntryRef.current = selectedRootEntry;

  const { creatingEntry } = useNavigationStore((state) => ({
    creatingEntry: state.creatingEntry,
  }));

  useEffect(() => {
    if (!creatingEntry) {
      if (!structure.some((entry) => entry.id === selectedRootEntry)) {
        setSelectedRootEntry(undefined);
      }

      setTempStructure(structure);
      return;
    }

    if (isPreview) {
      setTempStructure([...structure, creatingEntry.parentEntry, ...creatingEntry.children]);
      setSelectedRootEntry(creatingEntry.parentEntry.id);
    }
  }, [structure, creatingEntry]);

  useEffect(() => {
    if (isPreview) {
      window.AP.events.on("opened-menu-entry", ({ entryId }) => {
        if (!isHub) setSelectedRootEntry(entryId);
      });

      window.AP.events.on("closed-menu-entry", ({ entryId }) => {
        if (!isHub && selectedEntryRef.current === entryId) {
          setSelectedRootEntry(undefined);
        }
      });
    }
  }, [isPreview]);

  const theme = useTheme();

  const handleClickOnRootEntryLink = (rootEntry) => {
    try {
      const shouldOpenInNewTab = !!rootEntry?.data?.openNewTab;
      const isOpenInNewTabUndefined = rootEntry?.data?.openNewTab === undefined;

      if (rootEntry?.data?.link?.type === "intranet") {
        window.AP.navigator.go("addonModule", {
          addonKey: "com.caelor.confluence.cloud.cosmos",
          moduleKey: "caelor-cosmos-portal",
          customData: { cosmosPage: Pages.DASHBOARD },
        });
        return;
      }

      if (isOpenInNewTabUndefined) {
        handleOpeningLinkByDefault(rootEntry);
        return;
      }

      let link = xss(rootEntry?.data?.link?.link || "");
      link = link.startsWith("http://") || link.startsWith("https://") ? link : `https://${link}`;

      if (rootEntry?.data?.link.source === "email") {
        window.open("mailto:" + link, "_blank").focus();
        return;
      }

      window.open(link, shouldOpenInNewTab ? "_blank" : "_parent").focus();
      return;
    } catch (error) {
      return;
    }
  };

  const getSelectedRootEntryMenuStyle = () => {
    if (!tempStructure || !selectedRootEntry) {
      return MenuStyleTypes.LONDON;
    }
    const result = tempStructure.find(({ id }) => id === selectedRootEntry);
    if (!result?.menuStyle) {
      return MenuStyleTypes.LONDON;
    }

    return result.menuStyle;
  };

  return (
    <>
      <CardMenuWrapper>
        <>
          <RootWrapper
            isExpanded={!!selectedRootEntry}
            menuBackgroundColor={
              colors?.[temporaryColorScheme ?? theme.global.name]?.menuBackgroundColor ||
              theme.navigation.london.publishedMenuBackground
            }
            menuBorderColor={themes[temporaryColorScheme]?.navigation?.london?.publishedMenuBorder}
          >
            {tempStructure &&
              tempStructure
                .filter((entry) => entry.parent === "0")
                .map((entry) => (
                  <RootMenuEntry
                    isSelected={selectedRootEntry === entry.id}
                    borderColor={colors?.[temporaryColorScheme ?? theme.global.name]?.menuTextHover || "#42526e"}
                    key={entry.id}
                    onClick={() => {
                      if (entry.menuStyle === MenuStyleTypes.HUM) {
                        if (isPreview) return;
                        handleClickOnRootEntryLink(entry);
                        return;
                      }
                      if (entry.id === selectedRootEntry) {
                        setSelectedRootEntry(undefined);
                        return;
                      }
                      setSelectedRootEntry(entry.id);
                    }}
                  >
                    <RootMenuItemWrapper
                      isSelected={selectedRootEntry === entry.id}
                      menuTextColor={colors?.[temporaryColorScheme ?? theme.global.name]?.menuTextColor || "#42526e"}
                      menuTextHover={colors?.[temporaryColorScheme ?? theme.global.name]?.menuTextHover || "#42526e"}
                      menuTextActive={colors?.menuTextActive}
                    >
                      {entry.data.icon && (entry.data.icon.type === "icon" || !entry.data.icon.type) && (
                        <EntryIcon
                          icon={entry.data.icon}
                          style={{
                            color: "inherit",
                            display: "flex",
                            marginTop: "1px",
                          }}
                        />
                      )}

                      {entry.data.icon && entry.data.icon.type === "app" && (
                        <div style={{ width: "20px", height: "20px" }}>
                          <img
                            src={entry.data.icon.icon}
                            alt=""
                            width={20}
                            height={20}
                            style={{ objectFit: "contain" }}
                          />
                        </div>
                      )}

                      {entry.data.icon && entry.data.icon.type === "emoji" && (
                        <div
                          style={{
                            width: "20px",
                            height: "20px",
                            lineHeight: "1",
                            display: "grid",
                            placeItems: "center",
                            fontSize: "1.2em",
                          }}
                        >
                          <em-emoji shortcodes={entry?.data?.icon?.shortcodes}></em-emoji>
                        </div>
                      )}
                      <span style={{ userSelect: "none", whiteSpace: "nowrap" }}>{entry.data.name}</span>
                      {entry.menuStyle !== MenuStyleTypes.HUM && (
                        <span style={{ display: "flex", marginTop: "2px" }}>
                          {selectedRootEntry === entry.id && (
                            <ArrowUpIcon size="small" primaryColor={colors?.menuTextActive} />
                          )}
                          {selectedRootEntry !== entry.id && <ArrowDownIcon size="small" />}
                        </span>
                      )}
                    </RootMenuItemWrapper>
                  </RootMenuEntry>
                ))}
          </RootWrapper>
        </>
      </CardMenuWrapper>
      {selectedRootEntry && (
        <>
          {getSelectedRootEntryMenuStyle() === MenuStyleTypes.DENVER && (
            <Denver
              structure={tempStructure}
              colors={colors}
              selectedRootEntry={selectedRootEntry}
              isPreview={isPreview}
              openOverContent={openOverContent}
              themeColorsSet={themeColorsSet}
              temporaryColorScheme={temporaryColorScheme}
            />
          )}
          {getSelectedRootEntryMenuStyle() === MenuStyleTypes.PALO_ALTO && (
            <PaloAlto
              structure={tempStructure}
              colors={colors}
              selectedRootEntry={selectedRootEntry}
              isPreview={isPreview}
              openOverContent={openOverContent}
              themeColorsSet={themeColorsSet}
              temporaryColorScheme={temporaryColorScheme}
            />
          )}
          {getSelectedRootEntryMenuStyle() === MenuStyleTypes.HOUSTON && (
            <Houston
              structure={tempStructure}
              colors={colors}
              selectedRootEntry={selectedRootEntry}
              isPreview={isPreview}
              openOverContent={openOverContent}
              temporaryColorScheme={temporaryColorScheme}
            />
          )}
          {getSelectedRootEntryMenuStyle() === MenuStyleTypes.LONDON && (
            <London
              structure={tempStructure}
              colors={colors}
              selectedRootEntry={selectedRootEntry}
              isPreview={isPreview}
              openOverContent={openOverContent}
              themeColorsSet={themeColorsSet}
              temporaryColorScheme={temporaryColorScheme}
            />
          )}
          {getSelectedRootEntryMenuStyle() === MenuStyleTypes.SYDNEY && (
            <Sydney
              structure={tempStructure}
              colors={colors}
              selectedRootEntry={selectedRootEntry}
              isPreview={isPreview}
              openOverContent={openOverContent}
              themeColorsSet={themeColorsSet}
              temporaryColorScheme={temporaryColorScheme}
            />
          )}
        </>
      )}
    </>
  );
};

export default CardMenu;
