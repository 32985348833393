import React, { useContext } from "react";
import Button from "@atlaskit/button";
import styled, { useTheme } from "styled-components";
import { useCurrentUserStore } from "../../../Hub/current-user-store";
import { usePageStore } from "../../../Hub/page-store";
import { Pages } from "../../../Hub/pages";
import { SectionKeys, SectionPagesIDs } from "../../../Hub/Components/Settings/sections";
import { subscriptionsContext } from "../context";

const Wrapper = styled.div`
  display: grid;
  place-content: center;
  width: 100%;
  margin-top: 50px;
  row-gap: 10px;

  #button {
    width: fit-content !important;
    margin: 10px auto;
  }
`;

const Image = styled.img`
  margin: 0 auto;
`;

const Title = styled.div`
  color: ${({ theme }) => theme.notification.title};
  text-align: center;
  font-size: 29px;
  font-weight: 500;
  line-height: 32px;
`;

const Description = styled.div`
  color: ${({ theme }) => theme.shared.profile.text};
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`;

function EmptyState() {
  const setSelectedPage = usePageStore((state) => state.setSelectedPage);

  const { isCurrentUserAdmin } = useContext(subscriptionsContext);

  const theme = useTheme();
  const colorScheme = theme.global.name;

  return (
    <Wrapper>
      <Image src={`/images/news/no-categories-${colorScheme}.svg`} />
      <Title>No Categories Created</Title>
      <Description>
        {isCurrentUserAdmin
          ? "No news categories have been created yet. Use the 'Manage Categories' button to create new categories from Cosmos settings."
          : "There are no news categories available at the moment. Check back soon to customize your feed with content that interests you."}
      </Description>
      {isCurrentUserAdmin && (
        <Button
          id="button"
          onClick={() => setSelectedPage(`${Pages.SETTINGS}-${SectionKeys.NEWSROOM}-${SectionPagesIDs.CATEGORIES}`)}
          appearance="primary"
        >
          Manage Categories
        </Button>
      )}
    </Wrapper>
  );
}

export default EmptyState;
