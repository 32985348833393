import { useCallback, useEffect } from "react";
import { useNavigationColorsStore } from "../store";

export const useLoadColors = ({ data }) => {
  const {
    setColor,
    setSubentryIconColor,
    setSubentryTextColor,
    setSubentryBackgroundHoverColor,
    setDescriptionTextColor,
    setSavedColors,
  } = useNavigationColorsStore((state) => ({
    setColor: state.setColor,
    setSubentryIconColor: state.setSubentryIconColor,
    setSubentryTextColor: state.setSubentryTextColor,
    setSubentryBackgroundHoverColor: state.setSubentryBackgroundHoverColor,
    setDescriptionTextColor: state.setDescriptionTextColor,
    setSavedColors: state.setSavedColors,
  }));

  const setAllColors = useCallback(
    (allColors) => {
      setColor("dark", "menuBackgroundColor", allColors.dark.menuBackgroundColor);
      setColor("dark", "menuTextColor", allColors.dark.menuTextColor);
      setColor("dark", "menuTextHover", allColors.dark.menuTextHover);
      setColor("light", "menuBackgroundColor", allColors.light.menuBackgroundColor);
      setColor("light", "menuTextColor", allColors.light.menuTextColor);
      setColor("light", "menuTextHover", allColors.light.menuTextHover);
      setSubentryIconColor(allColors.subentryIconColor);
      setSubentryTextColor(allColors.subentryTextColor);
      setSubentryBackgroundHoverColor(allColors.subentryBackgroundHoverColor);
      setDescriptionTextColor(allColors.descriptionTextColor);
    },
    [setColor, setSubentryBackgroundHoverColor, setSubentryIconColor, setSubentryTextColor, setDescriptionTextColor],
  );

  const loadColors = useCallback(
    (rawData) => {
      if (!rawData) return;
      if (!(rawData.dark && rawData.light)) {
        if (rawData.themeColor) setSubentryBackgroundHoverColor(rawData.themeColor);
        if (rawData.backgroundColor) setColor("dark", "menuBackgroundColor", rawData.dark.backgroundColor);
        if (rawData.backgroundColor) setColor("light", "menuBackgroundColor", rawData.light.backgroundColor);
        setSavedColors({ dark: rawData.colors, light: rawData.colors });
      } else {
        setSavedColors({ dark: rawData.dark, light: rawData.light });
        setAllColors({ ...rawData.colors, dark: rawData.dark, light: rawData.light });
      }
    },
    [setAllColors, setColor, setSubentryBackgroundHoverColor, setSavedColors],
  );

  useEffect(() => {
    loadColors(data);
  }, [data, loadColors]);
};
