import { useQuery } from "react-query";
import { getCosmosAppSettings } from "./api";

export const useCosmosAppSettings = ({ isDatabaseInitialized }) => {
  const { isLoading: isLoadingCosmosAppSettings, data: cosmosAppSettingsData } = useQuery(
    "cosmos-app-settings",
    getCosmosAppSettings,
    {
      enabled: isDatabaseInitialized,
      retry: 0,
      select: (response) => {
        const { data } = response;
        return data;
      },
    },
  );

  return { isLoadingCosmosAppSettings, cosmosAppSettingsData };
};
