export const Sorting = {
  CREATED: "created",
  MODIFIED: "modified",
  TITLE: "title",
};

export const Ordering = {
  ASCENDING: "ascending",
  DESCENDING: "descending",
};
