import React from "react";
import styled from "styled-components";
import { useCorporateIdentityStore } from "../../Hub/Components/Settings/General/BrandAndColors/corporate-identity-store";

const LinkWrapper = styled.a`
  all: unset;
`;

const Card = styled.div`
  background: ${({ theme }) => theme.shared.card.background};
  border: 1px solid ${({ theme }) => theme.shared.card.border.box};
  box-shadow: 0px 0px 4px rgba(85, 85, 85, 0.06);
  border-radius: ${({ borderRadius }) => `${borderRadius}`};
  overflow: hidden;

  cursor: pointer;
`;

const CardBackground = styled.div`
  width: 100%;
  background: ${({ background }) => background};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 80px;
`;

const CardInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  align-items: center;
  justify-content: center;
  padding: 0px 16px 16px 16px;
  margin-top: ${({ imageHeight }) => `-${imageHeight / 2}px`};
  text-align: center;
`;

const Image = styled.img`
  width: ${({ imageSize }) => `${imageSize || 80}px`};
  height: ${({ imageSize }) => `${imageSize || 80}px`};
  box-sizing: border-box;
  border: 4px solid #fff;
  border-radius: 50%;
  object-fit: cover;
  justify-self: center;
`;

const Name = styled.span`
  font-weight: 500;
  font-size: 15px;
  line-height: 19px;
  color: ${({ theme }) => theme.shared.card.title};
  margin-top: 8px;
`;

const Position = styled.span`
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme }) => theme.shared.card.subtitle};
  height: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
`;

const Location = styled.span`
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: ${({ isBlue, theme }) => (isBlue ? theme.global.text.blue : theme.shared.card.footer)};
  margin-top: 13px;
  flex: 1;
  display: flex;
  align-items: flex-end;
  min-height: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 32px;
`;

const GridCenter = styled.div`
  height: 32px;
  display: grid;
  place-content: center;
  margin-top: 8px;
`;

function PersonCard({ user, createProfileLink }) {
  const getBackground = () => {
    if (user?.background?.image) return user.background.image;
    return user?.background?.color || "#fff";
  };

  const borderRadius = useCorporateIdentityStore((state) => state.borderRadius);

  return (
    <LinkWrapper key={user.accountId} target="_top" href={createProfileLink(user.accountId)}>
      <Card borderRadius={borderRadius}>
        <CardBackground background={getBackground()} />
        <CardInfoContainer imageHeight={80}>
          <Image imageSize={80} src={user.icon} />
          <Name>{user.name}</Name>
          <Position>{user.jobTitle}</Position>
          <GridCenter>
            <Location>{user.location}</Location>
          </GridCenter>
        </CardInfoContainer>
      </Card>
    </LinkWrapper>
  );
}

export default PersonCard;
