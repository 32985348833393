import React, { useEffect, useRef, useState } from "react";
import styled, { useTheme } from "styled-components";
import StarFilledIcon from "@atlaskit/icon/glyph/star-filled";
import EditIcon from "@atlaskit/icon/glyph/edit";
import WatchIcon from "@atlaskit/icon/glyph/watch";
import WatchFilledIcon from "@atlaskit/icon/glyph/watch-filled";
import {
  addCurrentUserAsSpaceWatcher,
  getCurrentUserSpaceFavoriteStatus,
  getCurrentUserSpaceWatchStatus,
  getUsersForArrayOfGroups,
  removeCurrentUserAsSpaceWatcher,
} from "../apiSpaces";
import { getAdminGroups, getAdminUsers } from "./dataHelpers";
import { useClickOutside } from "../../../../../Shared/Hooks/useClickOutside";
import { useCorporateIdentityStore } from "../../Settings/General/BrandAndColors/corporate-identity-store";
import { COLOR_TYPES } from "../../Settings/General/BrandAndColors/color-types";
import { useCurrentUserStore } from "../../../current-user-store";
import { createProfileLink } from "../../PeopleBase/Views/createProfileLink";

const PeopleGroupIcon = ({ primaryColor }) => (
  <svg width="16" height="15" viewBox="0 0 16 15" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.81221 2.125C5.72722 2.125 6.48483 2.79897 6.61567 3.67757C5.92811 4.08409 5.45809 4.81955 5.42223 5.66734C5.23149 5.73503 5.02615 5.77188 4.81221 5.77188C3.80515 5.77188 2.98877 4.9555 2.98877 3.94844C2.98877 2.94138 3.80515 2.125 4.81221 2.125ZM7.85187 7.59532C8.85893 7.59532 9.67531 6.77894 9.67531 5.77188C9.67531 4.76482 8.85893 3.94844 7.85187 3.94844C6.84481 3.94844 6.02843 4.76482 6.02843 5.77188C6.02843 6.77894 6.84481 7.59532 7.85187 7.59532ZM2.98883 6.38088H5.49691C5.61991 6.85876 5.88455 7.27977 6.2434 7.59651H6.02833C5.02441 7.59651 4.20469 8.41182 4.20469 9.41755V11.2186C2.91266 11.1117 1.77344 10.6601 1.77344 9.86357V7.59638C1.77344 6.92508 2.31283 6.38088 2.98883 6.38088ZM10.2063 6.38088H12.7144C13.3857 6.38088 13.9298 6.91907 13.9298 7.5967V9.86357C13.9298 10.6601 12.7906 11.1117 11.4986 11.2186V9.41759C11.4986 8.41293 10.6821 7.59651 9.67491 7.59651H9.45984C9.8187 7.27977 10.0833 6.85876 10.2063 6.38088ZM10.2811 5.66738C10.4718 5.73505 10.6771 5.77188 10.891 5.77188C11.898 5.77188 12.7144 4.9555 12.7144 3.94844C12.7144 2.94138 11.898 2.125 10.891 2.125C9.97633 2.125 9.21898 2.79841 9.08768 3.67647C9.77583 4.08282 10.2461 4.81893 10.2811 5.66738ZM6.63565 8.20432C5.62859 8.20432 4.81221 9.02067 4.81221 10.0277V11.687C4.81221 13.5268 10.8903 13.5268 10.8903 11.687V10.0275C10.8903 9.02045 10.074 8.20432 9.0669 8.20432H6.63565Z"
      fill={primaryColor}
    />
    <mask
      id="people-group-1"
      style={{ maskType: "luminance" }}
      maskUnits="userSpaceOnUse"
      x="1"
      y="2"
      width="13"
      height="12"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.81221 2.125C5.72722 2.125 6.48483 2.79897 6.61567 3.67757C5.92811 4.08409 5.45809 4.81955 5.42223 5.66734C5.23149 5.73503 5.02615 5.77188 4.81221 5.77188C3.80515 5.77188 2.98877 4.9555 2.98877 3.94844C2.98877 2.94138 3.80515 2.125 4.81221 2.125ZM7.85187 7.59532C8.85893 7.59532 9.67531 6.77894 9.67531 5.77188C9.67531 4.76482 8.85893 3.94844 7.85187 3.94844C6.84481 3.94844 6.02843 4.76482 6.02843 5.77188C6.02843 6.77894 6.84481 7.59532 7.85187 7.59532ZM2.98883 6.38088H5.49691C5.61991 6.85876 5.88455 7.27977 6.2434 7.59651H6.02833C5.02441 7.59651 4.20469 8.41182 4.20469 9.41755V11.2186C2.91266 11.1117 1.77344 10.6601 1.77344 9.86357V7.59638C1.77344 6.92508 2.31283 6.38088 2.98883 6.38088ZM10.2063 6.38088H12.7144C13.3857 6.38088 13.9298 6.91907 13.9298 7.5967V9.86357C13.9298 10.6601 12.7906 11.1117 11.4986 11.2186V9.41759C11.4986 8.41293 10.6821 7.59651 9.67491 7.59651H9.45984C9.8187 7.27977 10.0833 6.85876 10.2063 6.38088ZM10.2811 5.66738C10.4718 5.73505 10.6771 5.77188 10.891 5.77188C11.898 5.77188 12.7144 4.9555 12.7144 3.94844C12.7144 2.94138 11.898 2.125 10.891 2.125C9.97633 2.125 9.21898 2.79841 9.08768 3.67647C9.77583 4.08282 10.2461 4.81893 10.2811 5.66738ZM6.63565 8.20432C5.62859 8.20432 4.81221 9.02067 4.81221 10.0277V11.687C4.81221 13.5268 10.8903 13.5268 10.8903 11.687V10.0275C10.8903 9.02045 10.074 8.20432 9.0669 8.20432H6.63565Z"
        fill="white"
      />
    </mask>
    <g mask="url(#people-group-1)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.557617 14.8883H15.1451V0.300781H0.557617V14.8883Z"
        fill={primaryColor}
      />
    </g>
  </svg>
);

const SpaceCardWrapper = styled.div`
  position: relative;
  cursor: pointer;

  background-color: ${({ theme }) => theme.shared.card.background};
  height: calc(100% - 36px);
  padding: 18px;

  box-shadow:
    rgba(9, 30, 66, 0.25) 0px 1px 3px,
    rgba(9, 30, 66, 0.31) 0px 0px 1px;
  border-radius: ${({ borderRadius }) => borderRadius};
  border: 1px solid ${({ theme }) => theme.shared.card.border.box};
  max-width: 300px;

  display: flex;
  flex-direction: column;
  row-gap: 10px;

  transition: box-shadow linear 75ms;
  &:hover {
    box-shadow:
      rgba(9, 30, 66, 0.31) 0px 0px 1px 0px,
      rgba(9, 30, 66, 0.25) 0px 8px 12px -3px;
  }
`;

const SpaceIcon = styled.img`
  width: 60px;
  height: 60px;
  border-radius: ${({ isPersonal, borderRadius }) => (isPersonal ? "50%" : borderRadius)};
  box-sizing: border-box;
`;

const SpaceName = styled.span`
  height: 32px;
  font-weight: 500;
  font-size: 14.5875px;
  line-height: 16px;
  color: ${({ theme }) => theme.shared.card.title};
  margin-top: 9px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const SpaceDescription = styled.div`
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: ${({ theme }) => theme.shared.card.footer};
  overflow: hidden;
  text-overflow: ellipsis;
  height: 32px;
`;

const SpaceCreator = styled.div`
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: ${({ theme }) => theme.shared.card.footer};
  margin-top: 2px;
  flex: 1;
`;

const CreatorLink = styled.span`
  position: relative;
  color: ${({ theme }) => theme.global.primaryColor};
  cursor: pointer;

  display: flex;
  align-items: center;
`;

const IconContainer = styled.div`
  display: grid;
  place-content: center;
  cursor: pointer;

  ${({ small }) => small && `svg{ width: 21px; height: 21px;}`};
`;

const ActionsWrapper = styled.div`
  position: absolute;
  top: 18px;
  right: 18px;

  display: flex;
  align-items: center;
  column-gap: 5px;
`;

const AdminsTooltip = styled.div`
  position: absolute;
  top: 25px;
  right: 0px;
  z-index: 111;

  background: #172b4d;
  border-radius: 3px;
  padding: 2px 6px;
  display: flex;
  flex-direction: column;

  font-weight: 400;
  font-size: 12px;
  line-height: 20px;

  .title {
    color: #ffffff;
  }

  .admins-container {
    max-width: 150px;
    max-height: 150px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.scrollbar};
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #999;
    }

    .admin {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #8993a4;
      line-height: 20px;
    }
  }
`;

const Actions = ({ isWatching, isFavorite, updateWatching, setIsModalOpen, setUpdatingSpace, hasEditPermissions }) => {
  const theme = useTheme();

  return (
    <ActionsWrapper>
      {hasEditPermissions && (
        <IconContainer
          small
          onClick={(e) => {
            e.preventDefault();
            setUpdatingSpace();
            setIsModalOpen(true);
          }}
        >
          <EditIcon primaryColor={theme.poll.icons} />
        </IconContainer>
      )}
      <IconContainer>{isFavorite ? <StarFilledIcon primaryColor="#FFC400" /> : <div />}</IconContainer>

      <IconContainer
        small
        onClick={(e) => {
          e.preventDefault();
          updateWatching(isWatching);
        }}
      >
        {isWatching ? (
          <WatchFilledIcon primaryColor={theme.poll.iconsSelected} />
        ) : (
          <WatchIcon primaryColor={theme.poll.icons} />
        )}
      </IconContainer>
    </ActionsWrapper>
  );
};

function SpaceCard({ spaceInfo, setIsModalOpen, setUpdatingSpace, currentUserId }) {
  const [isError, setIsError] = useState(false);
  const [isLoadingWatchStatus, setIsLoadingWatchStatus] = useState(true);
  const [isLoadingFavoriteStatus, setIsLoadingFavoriteStatus] = useState(true);
  const [isWatching, setIsWatching] = useState(false);
  const [isFavorite, setIsFavorite] = useState(false);

  const [showAdminTooltip, setShowAdminTooltip] = useState(false);
  const [admins, setAdmins] = useState([]);
  const adminTooltipRef = useRef();

  useClickOutside(adminTooltipRef, () => setShowAdminTooltip(false));

  const isAnonymous = useCurrentUserStore((state) => state.isAnonymous);

  useEffect(() => {
    const getAllAdministrators = async () => {
      try {
        const adminUsers = getAdminUsers(spaceInfo.permissions);
        const adminGroups = getAdminGroups(spaceInfo.permissions);
        const allUsersFromGroups = await getUsersForArrayOfGroups(adminGroups);
        const users = allUsersFromGroups.flat();

        const allUsers = [...new Set([...adminUsers, ...users])].filter(
          (value, index, self) => index === self.findIndex((t) => t.id === value.id),
        );

        setAdmins([...allUsers]);
      } catch (error) {
        setAdmins([]);
      }
    };

    getAllAdministrators();
  }, []);

  useEffect(() => {
    setIsLoadingWatchStatus(true);
    setIsLoadingFavoriteStatus(true);
    getCurrentUserSpaceWatchStatus(spaceInfo.key)
      .then((res) => {
        if (res.body) {
          const body = JSON.parse(res.body);
          setIsWatching(body.watching);
        }
      })
      .catch((err) => setIsError(true))
      .finally(() => setIsLoadingWatchStatus(false));

    getCurrentUserSpaceFavoriteStatus(spaceInfo.key)
      .then((res) => {
        if (res.body) {
          const parsed = JSON.parse(res.body);
          if (parsed?.results?.length) {
            setIsFavorite(true);
          } else {
            setIsFavorite(false);
          }
        }
      })
      .catch((err) => setIsError(true))
      .finally(() => setIsLoadingFavoriteStatus(false));
  }, []);

  const removeWatcher = () => {
    setIsWatching(false);
    removeCurrentUserAsSpaceWatcher(spaceInfo.key)
      .then((res) => {
        if (!res?.data?.success) {
          setIsWatching(true);
        }
      })
      .catch((e) => setIsError(true));
  };

  const addWatcher = () => {
    setIsWatching(true);
    addCurrentUserAsSpaceWatcher(spaceInfo.key)
      .then((res) => {
        if (!res?.data?.success) {
          setIsWatching(false);
        }
      })
      .catch((e) => setIsError(true));
  };

  const checkIfCurrentUserHasPermissionToEdit = () => {
    if (!spaceInfo?.permissions?.length || !currentUserId || spaceInfo?.type === "personal") return false;

    let hasPermission;
    spaceInfo?.permissions?.forEach((permission) => {
      const permissionForUser = permission?.subjects?.user?.results?.find((user) => user.accountId === currentUserId);
      if (permissionForUser) hasPermission = true;
    });
    return !!hasPermission;
  };

  const { borderRadius, colors } = useCorporateIdentityStore((state) => ({
    borderRadius: state.borderRadius,
    colors: state.colors,
  }));
  const primaryColor = colors ? colors[COLOR_TYPES.PRIMARY] : "#0065ff";
  const primaryContrastColor = colors ? colors[COLOR_TYPES.PRIMARY_CONTRAST] : "#ffffff";

  const theme = useTheme();

  return (
    <a href={spaceInfo.url} style={{ textDecoration: "none" }} target="_blank" rel="noreferrer">
      <SpaceCardWrapper key={spaceInfo.key} borderRadius={borderRadius}>
        {!isLoadingWatchStatus && !isLoadingFavoriteStatus && !isError && (
          <Actions
            isWatching={isWatching}
            isFavorite={isFavorite}
            updateWatching={(watching) => {
              watching ? removeWatcher() : addWatcher();
            }}
            setIsModalOpen={setIsModalOpen}
            setUpdatingSpace={() => setUpdatingSpace(spaceInfo)}
            hasEditPermissions={checkIfCurrentUserHasPermissionToEdit(currentUserId, spaceInfo.permissions)}
          />
        )}

        <SpaceIcon src={spaceInfo.icon} isPersonal={spaceInfo.type === "personal"} borderRadius={borderRadius} />
        <SpaceName>{spaceInfo.name}</SpaceName>
        <SpaceDescription>{spaceInfo.description}</SpaceDescription>
        {!isAnonymous && (
          <SpaceCreator>
            <span style={{ whiteSpace: "nowrap", color: primaryColor }}>Created by&nbsp;</span>
            <CreatorLink>
              <span
                onClick={(e) => {
                  e.preventDefault();
                  window.open(createProfileLink(spaceInfo.creatorId));
                }}
                style={{ color: primaryColor }}
              >
                {spaceInfo.createdBy}&nbsp;
              </span>
              <IconContainer
                onClick={(e) => {
                  e.preventDefault();
                  setShowAdminTooltip(!showAdminTooltip);
                }}
              >
                <PeopleGroupIcon primaryColor={primaryColor} />
              </IconContainer>

              {showAdminTooltip && (
                <AdminsTooltip ref={adminTooltipRef}>
                  <span className="title">Admins: </span>
                  <div className="admins-container">
                    {admins.map((admin) => (
                      <div className="admin" key={admin.id}>
                        {admin.name}
                      </div>
                    ))}
                  </div>
                </AdminsTooltip>
              )}
            </CreatorLink>
          </SpaceCreator>
        )}
      </SpaceCardWrapper>
    </a>
  );
}

export default SpaceCard;
