import React, { useMemo } from "react";
import styled from "styled-components";
import { useSidebarStore } from "../../sidebar-store";
import { PreviewContentByPanelKey } from "./preview-content";
import PreviewProvider from "./PreviewProvider";
import { PanelPositioning } from "../../../../panelTypes";

const HoverWrapper = styled.div`
  position: fixed;
  right: 375px;
  top: 100px;

  width: 600px;
  height: auto;

  background: ${({ theme }) => theme.shared.sidebar.preview.background};
  box-shadow: 0px 2px 8px rgba(0 0 0 / 8%);
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.shared.sidebar.preview.border};
  padding: 24px 40px;
`;

const Header = styled.div`
  display: grid;
  grid-template-columns: 20px auto;
  column-gap: 12px;
  padding-bottom: 24px;
  border-bottom: 0.75px solid ${({ theme }) => theme.shared.sidebar.preview.divider};
`;

const HeaderIconWrapper = styled.div`
  width: 20px;
  height: 20px;
  display: grid;
  place-content: center;

  svg {
    width: 20px;
    height: 20px;
  }
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.shared.sidebar.preview.title};
`;

const Text = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`;

const Description = styled(Text)`
  color: ${({ theme }) => theme.shared.sidebar.preview.description};
`;

const PreviewContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  margin-top: ${({ isFullLayout }) => (isFullLayout ? "25px" : "10px")};
`;

const ColumnsWrapper = styled.div`
  display: grid;
  grid-template-columns: ${({ isOneColumn }) => {
    if (isOneColumn) {
      return "1fr";
    }
    return "220px auto";
  }};
  column-gap: 36px;

  padding: 30px 0px;
`;

function HoverDialog() {
  const { hoveringPanel, selectedContentType } = useSidebarStore((state) => ({
    hoveringPanel: state.hoveringPanel,
    selectedContentType: state.selectedContentType,
  }));

  const panel = useMemo(() => {
    if (!hoveringPanel) return undefined;

    const content = PreviewContentByPanelKey[hoveringPanel.key];
    if (!content) return undefined;

    return {
      ...hoveringPanel,
      ...content,
    };
  }, [hoveringPanel]);

  if (!panel) return null;

  const { key, title, description, fullLayout, Icon } = panel;

  const isFullLayout = fullLayout || selectedContentType === PanelPositioning.MAIN;

  return (
    <HoverWrapper>
      <Header>
        <HeaderIconWrapper>
          <Icon />
        </HeaderIconWrapper>

        <Title>{title}</Title>
      </Header>

      <ColumnsWrapper isOneColumn={isFullLayout}>
        <PreviewContainer>
          <PreviewProvider panelKey={key} />
        </PreviewContainer>
        <InfoContainer isFullLayout={isFullLayout}>
          <Title>Widget details</Title>
          <Description>{description}</Description>
        </InfoContainer>
      </ColumnsWrapper>
    </HoverWrapper>
  );
}

export default HoverDialog;
