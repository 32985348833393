import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
`;

export const CategoriesWrapper = styled.div`
  display: flex;
  flex-flow: row;
  gap: 10px;
  justify-content: space-between;
`;

export const CategoriesWrapperText = styled.div`
  display: flex;
  flex-flow: row;
  gap: 10px;
`;

export const CategoryIcon = styled.div`
  display: inline-flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 30px;
  background: #deebff;
`;

export const CategoryTextWrapper = styled.div`
  display: flex;
  gap: 4px;
  flex-flow: column;
`;

export const CategoryTitle = styled.div`
  color: ${({ theme }) => theme.newsMetadataSettings.title};

  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

export const CategoryDescription = styled.div`
  color: #172b4d;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
`;

export const CategoryCard = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;
  padding: 15px;
  align-items: center;
  cursor: pointer;
  border-radius: 3px;
  border: ${({ theme }) => `1px solid ${theme.newsMetadataSettings.categoryCardBorder}`};
  background: ${({ theme }) => theme.newsMetadataSettings.categoryCard};
  &:hover {
    background: ${({ theme }) => theme.newsMetadataSettings.categoryCardHover};

    .trash-icon-wrapper {
      opacity: 1;
    }
  }
`;

export const CategoryCardWrapper = styled.div`
  padding-top: 28px;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-gap: 20px;
`;

export const BlogsNumber = styled.div`
  color: ${({ theme }) => theme.newsMetadataSettings.title};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
`;

export const ProposalCard = styled.div`
  display: flex;
  flex-flow: row;
  padding: 15px;
  border-radius: 3px;
  border: ${({ theme }) => `1px solid ${theme.newsMetadataSettings.categoryCardBorder}`};
  background: ${({ theme }) => theme.newsMetadataSettings.categoryCard};
  justify-content: space-between;
`;

export const ProposalCardWrapper = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  gap: 5px;
`;

export const ProposalWrapper = styled.div`
  display: flex;
  flex-flow: column;
  gap: 15px;
  padding-top: 30px;
`;

export const ProposalName = styled.div`
  color: ${({ theme }) => theme.newsMetadataSettings.title};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 114.286% */
`;

export const ProposalText = styled.div`
  color: ${({ theme }) => theme.newsMetadataSettings.description};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
`;

export const TrashIconWrapper = styled.div`
  position: absolute;
  top: 30px;
  cursor: pointer;
  right: 15px;
  opacity: 0;
  transition: opacity 0.3s ease;
`;

export const Container = styled.div`
  display: grid;
  place-content: center;
  text-align: center;
  padding-top: ${({ paddingTop }) => `${paddingTop}px`};
  #get-started-button {
    width: fit-content !important;
    margin: 0 auto;
  }
`;

export const CenteredImage = styled.img`
  margin: 0 auto;
`;

export const Title = styled.div`
  color: ${({ theme }) => theme.shared.profile.title};
  font-size: 29px;
  font-weight: 500;
  line-height: 32px;
`;

export const Description = styled.div`
  color: #626f86;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-top: 10px;
  margin-bottom: 20px;
`;

export const DetailWrapper = styled.div`
  display: flex;
  flex-flow: column;
  gap: 20px;
`;

export const DetailActions = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
`;

export const SearchAction = styled.div``;

export const ButtonActions = styled.div`
  display: flex;
  gap: 15px;
`;

export const BlogsCounter = styled.div`
  color: ${({ theme }) => theme.newsMetadataSettings.title};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
`;

export const BlogsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0px, 1fr));
  grid-gap: 20px;
`;

export const BlogCard = styled.div`
  display: flex;
  position: relative;
  flex-flow: column;
  padding: 15px;
  gap: 8px;
  border-radius: 3px;
  cursor: pointer;
  border: ${({ theme }) => `1px solid ${theme.newsMetadataSettings.categoryCardBorder}`};
  background: ${({ theme }) => theme.newsMetadataSettings.categoryCard};
  &:hover {
    background: ${({ theme }) => theme.newsMetadataSettings.categoryCardHover};
    .trash-icon-blog {
      opacity: 1;
    }
  }
`;

export const BlogCardRow = styled.div`
  display: flex;
  flex-flow: row;
  gap: 16px;
`;

export const BlogCardDetails = styled.div`
  display: flex;
  flex-flow: row;
`;

export const BlogTitle = styled.div`
  color: ${({ theme }) => theme.newsMetadataSettings.title};
  font-size: 16px;
  font-style: normal;
  width: 80%;
  font-weight: 500;
  line-height: 20px; /* 125% */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  word-break: break-word;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const LargePageIcon = styled.img`
  width: 24px;
  height: 24px;
`;

export const BlogDescription = styled.div`
  display: flex;
  flex-flow: row;
  gap: 6px;
  padding-left: 41px;
`;

export const BlogDescriptionText = styled.div`
  display: flex;
  align-self: center;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  color: ${({ theme }) => theme.newsMetadataSettings.description};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
`;

export const TrashIconWrapperDetails = styled.div`
  position: absolute;
  cursor: pointer;
  top: 20px;
  right: 15px;
  opacity: 0;
  transition: opacity 0.3s ease;
`;
