import React, { useState } from "react";
import shallow from "zustand/shallow";
import TextField from "@atlaskit/textfield";

import { useDatabaseUIStore } from "../../database-ui-store";
import { useRecordsStore } from "../../records-store";

const TextEditor = ({ initialValue, onEdit }) => {
  const [value, setValue] = useState(initialValue || "");

  const { editingCell } = useDatabaseUIStore((state) => {
    return {
      editingCell: state.editingCell,
    };
  }, shallow);

  const records = useRecordsStore((state) => state.records, shallow);

  const handleEnterKey = (e) => {
    if (e.key === "Enter") {
      if (editingCell) {
        onEdit(value);
      }
    }
  };

  return (
    <TextField
      autoFocus
      value={value}
      onBlur={(e) => onEdit(e.target.value)}
      onChange={(e) => setValue(e.target.value)}
      onKeyDown={handleEnterKey}
    />
  );
};

export default TextEditor;
