import styled from "styled-components";

export const ContentColumns = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  align-items: flex-start;
  column-gap: 24px;
  row-gap: 24px;
`;

export const Column = styled.div`
  flex: 0 1 50%;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
`;

export const Panel = styled.div`
  background: ${({ theme }) => theme.shared.panel.background};
  box-shadow:
    rgba(9, 30, 66, 0.25) 0px 1px 3px,
    rgba(9, 30, 66, 0.31) 0px 0px 1px;
  border-radius: 3px;
  padding: 17px 24px;
  position: relative;
`;

export const PanelContent = styled.div`
  padding-bottom: 16px;
`;

export const PanelTitle = styled.div`
  color: ${({ theme }) => theme.shared.settings.title};
  font-size: 16px;
  font-weight: 500;
  padding-bottom: 12px;
  border-bottom: ${({ theme }) => `1px solid ${theme.shared.sidebar.tabs.background.inactive}`};
`;

export const PanelDescription = styled.p`
  all: unset;
  color: ${({ theme }) => theme.shared.emptyState.text};
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  width: 375px;

  a {
    color: #1d7afc;
    text-decoration: none;

    &:visited {
      color: #1d7afc;
    }
  }
`;

export const Center = styled.div`
  display: grid;
  place-content: center;
  row-gap: 12px;
  text-align: center;
`;

export const Title = styled.h6`
  all: unset;
  color: ${({ theme }) => theme.shared.emptyState.text};
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  width: 375px;
`;
