import React from "react";
import shallow from "zustand/shallow";
import { GridViewWrapper } from "../styled/views";
import Spinner from "@atlaskit/spinner";
import Card from "../Cards/Card";
import InfiniteScroller from "../../../../../Shared/Components/InfiniteScroller";
import { useDatabaseStore, useSidebarPanelStore } from "../database-store";
import { AvailableStyles } from "../defaultSettings";
import { SpinnerContainer } from "../../../styled/pages";

const getNumberOfColumns = (style) => {
  if (style === AvailableStyles.AUSTIN) return 4;
  if (style === AvailableStyles.CALGARY) return 3;
};

function GridView({ totalSize, fetchNextPage, hasNextPage }) {
  const settings = useSidebarPanelStore((state) => state.settings);
  const numberOfColumns = getNumberOfColumns(settings.selectedStyle);

  const { builtRecords, builtRecordsLoading } = useDatabaseStore(
    (state) => ({
      builtRecords: state.builtRecords,
      builtRecordsLoading: state.builtRecordsLoading,
    }),
    shallow,
  );

  const Content = () => (
    <GridViewWrapper numberOfColumns={numberOfColumns}>
      {builtRecords?.map((person) => (
        <Card key={person.accountId} {...person} />
      ))}
    </GridViewWrapper>
  );

  return builtRecordsLoading ? (
    <>
      <Content />
      <SpinnerContainer>
        <Spinner size="large" />
      </SpinnerContainer>
    </>
  ) : (
    <InfiniteScroller dataLength={totalSize} hasNextPage={hasNextPage} fetchNextPage={fetchNextPage} endMessage="">
      <Content />
    </InfiniteScroller>
  );
}

export default GridView;
