import { nanoid } from "nanoid";
import React, { useContext, useEffect, useState } from "react";
import { useTheme } from "styled-components";
import EmptyState from "../shared/EmptyState";
import { useCurrentUserStore } from "../../../../current-user-store";
import { DashboardAPIContext } from "../../context";
import { TabTypes } from "../tabTypes";
import PanelWrapper from "../../../Shared/Panels/shared/components/PanelWrapper";

function ReleasedAI({ id, data, position }) {
  const [key, setKey] = useState("random-key");

  const theme = useTheme();
  const colorScheme = theme.global.name;

  const { channelId, title, subtitle, elementsToDisplay, color } = data;

  const isAdmin = useCurrentUserStore((state) => state.isCurrentUserAdmin);

  const { editPanelExternally } = useContext(DashboardAPIContext);

  useEffect(() => {
    // force rerender
    setKey(nanoid(4));
  }, [data]);

  // available options: https://docs.released.so/guide/product-tour/settings/announcement-page

  if (!channelId) {
    return (
      <PanelWrapper
        panelTitle="Released"
        displayOptions={{
          displayTitle: true,
          displayBox: true,
        }}
      >
        <EmptyState
          title="Channel ID is required"
          description="Add a Channel ID to display Released."
          button={{
            isAvailable: isAdmin,
            appearance: "primary",
            buttonText: "Add Channel ID",
            onClick: () => editPanelExternally(id, position, TabTypes.CONTENT),
          }}
        />
      </PanelWrapper>
    );
  }

  return (
    <div key={key}>
      <released-page
        channel-id={channelId}
        color-primary={color}
        color-scheme={colorScheme}
        header={elementsToDisplay.includes("header")}
        cover-images={elementsToDisplay.includes("coverimage")}
        title={title}
        sub-title={subtitle}
      ></released-page>
    </div>
  );
}

export default ReleasedAI;
