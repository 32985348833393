import React from "react";
import { Flex, Grid, xcss } from "@atlaskit/primitives";
import Spinner from "@atlaskit/spinner";
import { FlagsProvider } from "@atlaskit/flag";
import Sidebar from "./sidebar/Sidebar";
import Preview from "./preview/Preview";
import { useFetchNavigation } from "../hooks/fetchNavigation";
import { useHelpersStore, useNavigationStore, useNavigationThemeColorSetStore } from "../store";
import Drawer from "./drawer/Drawer";
import { useLoadColors } from "../hooks/loadColors";
import ModalEntry from "./modals/modal.entry";
import { useFetchAllSpaces } from "../hooks/fetchSpaceOptions";
import { useFetchSpaces } from "../hooks/fetchSpaces";

const containerStyle = xcss({ flex: "1 1 auto", height: "100%" });

function Config() {
  const [rawData, setRawData] = React.useState(null);

  const { setStructure, setSavedStructure, setVersion, setActive, setSavedActive } = useNavigationStore((state) => ({
    setStructure: state.setStructure,
    setSavedStructure: state.setSavedStructure,
    setVersion: state.setVersion,
    setActive: state.setActive,
    setSavedActive: state.setSavedActive,
  }));

  const { setSpaces, setSpaceOptions } = useHelpersStore((state) => ({
    setSpaces: state.setSpaces,
    setSpaceOptions: state.setSpaceOptions,
  }));

  const { returnThemeColorsSet, setThemeColorsSet } = useNavigationThemeColorSetStore((state) => {
    return {
      returnThemeColorsSet: state.returnThemeColorsSet,
      setThemeColorsSet: state.setThemeColorsSet,
    };
  });

  const [isLoading] = useFetchNavigation({
    setNavigationData: (data) => {
      const savedStructure = data?.publishedStructure || [];
      setStructure(JSON.parse(JSON.stringify(savedStructure)));
      setSavedStructure(JSON.parse(JSON.stringify(savedStructure)));
      setVersion(data?.version);
      setActive(data?.active);
      setSavedActive(data?.active);
      setRawData(data);
    },
  });

  useFetchAllSpaces({ setSpaceOptions });
  useFetchSpaces({ setSpaces });

  useLoadColors({ data: rawData });

  if (isLoading) {
    return (
      <Flex xcss={containerStyle}>
        <Grid alignItems="center" justifyContent="center" xcss={{ flex: "1" }}>
          <Spinner size="xlarge" />
        </Grid>
      </Flex>
    );
  }

  return (
    <FlagsProvider>
      <Flex xcss={containerStyle}>
        <Sidebar />
        <Preview themeColorsSet={returnThemeColorsSet()} setThemeColorsSet={setThemeColorsSet} />

        <Drawer />

        <ModalEntry />
      </Flex>
    </FlagsProvider>
  );
}

export default Config;
