import React, { useRef } from "react";
import styled from "styled-components";
import FilterService from "../../UserDatabase/filter-service";
import { FieldType } from "../../UserDatabase/field-type";
import AsyncSelect from "./Filter/AsyncSelect";
import Select from "./Filter/Select";
import User from "./Filter/User";
import { useCollectionsStore, useSingleCollectionStore } from "../collection-store";
import DateRange from "./Filter/DateRange";

const CustomFilterContentWrapper = styled.div`
  background-color: transparent;

  display: flex;
  flex-direction: column;
  row-gap: 12px;
  z-index: 5;
`;

const Container = styled.div`
  width: 258px;

  & > div:first-child {
    margin-bottom: 12px;
  }

  & > div > button {
    font-weight: 500 !important;
    font-size: 14px !important;

    &:only-child {
      color: #0065ff !important;
    }

    span:first-child {
      display: none;
    }
  }
`;

const Wrapper = styled.div`
  min-height: 0;
`;

const Topbar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  column-gap: 15px;
  row-gap: 10px;
  margin-bottom: 7px;
  padding-bottom: 32px;

  .title {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
  }

  .manage {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #0065ff;
    margin-right: 9px;

    cursor: pointer;
  }
`;

const Button = styled.div`
  user-select: none;
  padding: 6px 12px;
  border-radius: 3px;
  cursor: pointer;
  width: fit-content;
  display: grid;
  place-content: center;

  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  opacity: 1;
  transition: opacity 150ms linear;

  &:hover {
    opacity: 0.85;
  }
`;
export const Cancel = styled(Button)`
  color: #42526e;
  background-color: transparent;
  margin-right: 10px;

  transition: background-color 150ms linear;

  &:hover {
    background-color: #f3f3f3;
    opacity: 1;
  }
`;

function Filter({ fields, filters, updateFilter, showAllFilters = true }) {
  const scrollRef = useRef();

  return (
    <CustomFilterContentWrapper>
      {filters
        .filter((x) => [FieldType.SELECT, FieldType.MULTISELECT, FieldType.USER, FieldType.DATE].includes(x.type))
        .filter((_, index) => showAllFilters || index < 5)
        .map((filter) => {
          const field = fields.find((field) => field.id === filter.column);

          return (
            <div key={filter.id}>
              {filter.column?.includes("azure") ? (
                <>
                  {filter.type === FieldType.SELECT && (
                    <AsyncSelect
                      scrollRef={scrollRef}
                      filter={filter}
                      onChange={(v) => updateFilter({ ...filter, value: v })}
                      placeholder={filter.fieldTitle}
                    />
                  )}
                </>
              ) : (
                <>
                  {(filter.type === FieldType.SELECT || filter.type === FieldType.MULTISELECT) && (
                    <Select
                      scrollRef={scrollRef}
                      value={filter.value}
                      onChange={(v) => updateFilter({ ...filter, value: v })}
                      options={filter.options || []}
                      placeholder={filter.fieldTitle}
                    />
                  )}
                </>
              )}
              <>
                {filter.type === FieldType.USER && (
                  <User
                    scrollRef={scrollRef}
                    initialValue={filter.value}
                    onChange={(v) => updateFilter({ ...filter, value: v })}
                    placeholder={filter.fieldTitle}
                  />
                )}
              </>
              {filter.type === FieldType.DATE && (
                <DateRange
                  scrollRef={scrollRef}
                  onChange={(v) => updateFilter({ ...filter, value: v, recurring: field.recurring || false })}
                  value={filter.value}
                  field={field}
                  placeholder={field.recurring ? field.title : { from: "Entry Date", to: "Exit Date?" }}
                />
              )}
            </div>
          );
        })}
    </CustomFilterContentWrapper>
  );
}

function Filters({ updateCollection, updateCollectionInDatabase, userGroups, loadingUserGroups }) {
  const fields = useCollectionsStore((state) => state.databaseFields);

  const { filters, setFilters, updateFilter, removeFilter } = useSingleCollectionStore((state) => ({
    filters: state.filters,
    setFilters: state.setFilters,
    updateFilter: state.updateFilter,
    removeFilter: state.removeFilter,
  }));

  const { selectedCollection, setSelectedCollection } = useCollectionsStore((state) => ({
    selectedCollection: state.selectedCollection,
    setSelectedCollection: state.setSelectedCollection,
  }));

  const getAllFilters = () => {
    const array = [...filters, ...FilterService.buildFilters(fields)]
      .filter((v1, i, arr) => arr.findIndex((v2) => v2.column === v1.column) === i)
      .sort(
        (a, z) =>
          fields.findIndex((field) => field.id === a.column) - fields.findIndex((field) => field.id === z.column),
      );

    return array.map((filter) => {
      const { options, title } = fields.find((field) => field.id === filter.column);

      if (filter.type.includes("SELECT")) {
        return {
          ...filter,
          fieldTitle: title,
          options: options || [],
        };
      }

      return { ...filter, fieldTitle: title };
    });
  };

  const resetFilters = () => {
    setFilters([]);
  };

  const handleUpdateFilter = (filter) => {
    let newState = undefined;
    if (filter.type === "MULTISELECT" || filter.type === "SELECT") {
      if (!!filter.value?.length) {
        newState = updateFilter(filter, true);
      } else {
        newState = removeFilter(filter.id, true);
      }
      return updateCollection(newState);
    }

    if (filter.type === "DATE") {
      if (filter.recurring) {
        if (filter.value?.period) {
          newState = updateFilter(filter, true);
        } else {
          newState = removeFilter(filter.id, true);
        }
      } else {
        if (filter.value?.range[0] || filter.value?.range[1]) {
          newState = updateFilter(filter, true);
        } else {
          newState = removeFilter(filter.id, true);
        }
      }
      return updateCollection(newState);
    }

    if (!!filter.value) {
      newState = updateFilter(filter, true);
    } else {
      newState = removeFilter(filter.id, true);
    }
    updateCollection(newState);
  };

  return (
    <Wrapper>
      <Topbar>
        <Container>
          {!loadingUserGroups && (
            <Select
              options={userGroups}
              value={selectedCollection.userGroup || []}
              onChange={(value) => {
                setSelectedCollection({ ...selectedCollection, userGroup: value });
                updateCollectionInDatabase("userGroup", value);
              }}
              placeholder="Confluence group"
              maxWidth="260px"
              isMulti={false}
              closeOnChange
            />
          )}

          <Filter
            fields={fields}
            filters={getAllFilters(filters)}
            applyFilters={updateCollection}
            resetFilters={resetFilters}
            updateFilter={handleUpdateFilter}
            label="Manage Filters"
          />
        </Container>
      </Topbar>
    </Wrapper>
  );
}

export { Filters, Filter };
