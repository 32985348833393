import React from "react";
import Select from "@atlaskit/select";
import { Center, Panel, PanelContent, PanelDescription, PanelTitle, Title } from "./styled";
import { FieldContainer, Label } from "../../../../Dashboard/filter/styled";
import { selectStylesWithOverrides } from "../../../../Dashboard/filter/CustomSelectStyles";
import { useWidgetsStore } from "../../widgets-store";
import { Pages } from "../../../../../pages";
import { SectionKeys, SectionPagesIDs } from "../../../sections";
import { useTheme } from "styled-components";
import { FieldType } from "../../../../../../UserDatabase/field-type";
import { EnableStates } from "../types/defaults";

const EmptyStateTypes = {
  NOT_SELECTED: "not-selected",
  NOT_INITIALIZED: "not-initialized",
};

const ImageBasedOnType = {
  [EmptyStateTypes.NOT_SELECTED]: "/images/hub/column-not-selected.svg",
  [EmptyStateTypes.NOT_INITIALIZED]: "/images/hub/db-not-init.svg",
};

const DescriptionBasedOnType = {
  [EmptyStateTypes.NOT_SELECTED]: (title) => {
    return (
      <span>
        {`Select a date column to fetch data for the ${title} widget. Without this, the widget won't work. Only date fields are applicable.`}
      </span>
    );
  },
  [EmptyStateTypes.NOT_INITIALIZED]: () => (
    <span>
      Database not synced. Please sync your{" "}
      <a
        rel="noreferrer"
        target="_parent"
        href={`${window.AP._hostOrigin}/wiki/plugins/servlet/ac/com.caelor.confluence.cloud.cosmos/caelor-cosmos-portal?ac.cosmosPage=${Pages.SETTINGS}-${SectionKeys.PEOPLE_BASE}-${SectionPagesIDs.DATABASE}`}
      >
        employee database
      </a>{" "}
      first.
    </span>
  ),
};

const EmptyState = ({ type, title }) => {
  return (
    <Center style={{ padding: "70px 0px 50px 0px" }}>
      <img style={{ margin: "0 auto", height: "150px" }} src={ImageBasedOnType[type]} alt="" />
      <Title>Database column is not selected</Title>
    </Center>
  );
};

function FieldSelector({ title, selectedField, onChange, filterFieldsByTypes = [], withRecurring = undefined }) {
  const { databaseFields, isDbInit } = useWidgetsStore();

  const fields = databaseFields.filter(({ fieldProps: { type, recurring }, value }) => {
    const isSelectedType = filterFieldsByTypes?.length ? filterFieldsByTypes.includes(type) : true;

    if (filterFieldsByTypes.includes(FieldType.USER)) {
      return isSelectedType || value === "azureManager";
    }

    if (withRecurring === EnableStates.ENABLED) {
      return isSelectedType && recurring;
    }

    if (withRecurring === EnableStates.DISABLED) {
      return isSelectedType && !recurring;
    }

    return isSelectedType;
  });

  const theme = useTheme();

  const colorSchemeSelect = selectStylesWithOverrides({
    control: (provided, { isFocused }) => ({
      ...provided,
      backgroundColor: theme.shared.form.select.background,
      borderColor: theme.shared.form.select.border,
      fontSize: "14px",
      "&:hover": {
        backgroundColor: `${theme.shared.form.select.hover} !important`,
        borderColor: `${theme.shared.form.select.hover} !important`,
      },
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: theme.shared.form.select.options,
      border: `1px solid ${theme.shared.form.select.border}`,
    }),
    option: (provided, { isSelected, isFocused }) => ({
      ...provided,
      fontSize: "14px",
      color: isSelected ? theme.shared.form.select.selected.secondaryText : theme.shared.form.select.option,
      backgroundColor: isSelected
        ? theme.shared.form.select.selected.background
        : isFocused
        ? theme.shared.form.select.hover
        : theme.shared.form.select.options,
    }),
    singleValue: (provided) => ({
      ...provided,
      color: theme.shared.form.select.value,
    }),
  });

  return (
    <Panel>
      <PanelTitle>Database column</PanelTitle>
      <PanelContent>
        <FieldContainer>
          <Label>Database column</Label>
          {fields && (
            <Select
              placeholder="Select a field"
              isClearable
              value={fields?.find((field) => field.value === selectedField)}
              options={fields}
              onChange={(selectedOption) => onChange(selectedOption?.value)}
              styles={colorSchemeSelect}
              isDisabled={!isDbInit}
            />
          )}
        </FieldContainer>

        {(!selectedField || !isDbInit) && (
          <EmptyState type={!isDbInit ? EmptyStateTypes.NOT_INITIALIZED : EmptyStateTypes.NOT_SELECTED} title={title} />
        )}
      </PanelContent>
    </Panel>
  );
}

export default FieldSelector;
