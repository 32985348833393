import React, { useEffect, useRef, useState } from "react";

import Button from "@atlaskit/button";
import SectionMessage from "@atlaskit/section-message";
import Modal, { ModalTransition, ModalFooter } from "@atlaskit/modal-dialog";
import { css } from "@emotion/react";
import shallow from "zustand/shallow";
import { useQuery } from "react-query";

import { useDatabaseUIStore } from "./database-ui-store";
import HeaderRow from "./datagrid/header/HeaderRow";
import Records from "./datagrid/Records";
import { useRecordsStore } from "./records-store";
import {
  applyChanges,
  fetchIsInitialized,
  fetchUserDatabaseFields,
  getShouldSyncGuestUsersStatus,
} from "./datagrid/api";
import styled from "styled-components";
import MenuBar from "./menubar/MenuBar";
import SyncUserModal from "./sync/SyncUserModal";
import EmptyState from "./sync/EmptyState";
import useKeyboardShortcuts from "./useKeyboardShortcuts";
import { usePageStore } from "../../Components/Hub/page-store";
import { useChangesStore } from "../../Components/Hub/track-changes-store";
import TitleWithBack from "../../Shared/Components/TitleWithBack";
import { Pages } from "../Hub/pages";
import { useTheme } from "styled-components";
import { SectionKeys, SectionPagesIDs } from "../Hub/Components/Settings/sections";
import { ChangeOperations } from "./change-handler";
import { SelectionType } from "./menubar/Users";

const ModalButton = styled.button`
  all: unset;
  border-radius: 4px;
  padding: 6px 12px;
  font-size: 14px;
  cursor: pointer;
  text-align: center;
  background: ${({ bg }) => bg};
  color: ${({ color }) => color};

  &:hover {
    background: ${({ bg }) => bg}cc;
  }
  &:active {
    background: ${({ bg }) => bg}ee;
  }
`;

const ModalTitle = styled.h1`
  color: ${({ theme }) => theme.confirmDeleteModal.modalHeaderText};
  font-weight: 500;
  font-size: 20px;
  font-style: inherit;
  line-height: 1;

  min-width: 0;
  flex: 1 1 auto;
  word-wrap: break-word;
  padding-bottom: 0px;
`;

const ModalBody = styled.h1`
  color: ${({ theme }) => theme.confirmDeleteModal.modalBodyText};
  font-weight: 400;
  font-size: 16px;
  font-style: inherit;
  line-height: 1;

  min-width: 0;
  flex: 1 1 auto;
  padding: 2px 24px;
`;

const ModalHeader = styled.div`
  display: flex;
  padding: 24px;
  padding-bottom: 0px;
  position: relative;
  align-items: center;
  justify-content: space-between;
`;

const HeaderWrapper = styled.div`
  padding: ${({ padding }) => padding ?? "0"};
  border-bottom: 1px solid ${({ theme }) => theme.shared.settings.database.border};
`;

const Footer = styled.div`
  overflow: hidden;
  font-size: 14px;
  height: 30px;
  padding: 10px;
  padding-right: 40px;
  border-top: 1px solid ${({ theme }) => theme.shared.settings.divider};
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 10px;
`;

const ToggleWrapper = styled.div`
  width: fit-content;
  height: 52px;
  flex-shrink: 0;
  border-radius: 6px;
  border: 1px solid rgba(166, 197, 226, 0.16);
  background: #172b4d;
  color: #c7d1db;
  display: flex;
  align-items: center;

  position: absolute;
  top: 93%;
  left: 50%;
  transform: translate(-50%, -50%);
  justify-content: space-around;
  z-index: 3;
`;

const ToggleGroup = styled.div`
  height: inherit;
  display: flex;
  cursor: pointer;
`;

const SelectedMessage = styled.p`
  color: #c7d1db;
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  white-space: nowrap;
  padding-left: 4px;
`;

const MenuDivider = styled.div`
  background: #738496;
  width: 1px;
  height: 32px;

  position: relative;
`;

const IconContainer = styled.div`
  align-self: center;
  display: flex;
`;

const CloseIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.1846 7.4L12.0006 10.585L8.81464 7.399C8.71938 7.30584 8.59127 7.25393 8.45803 7.25449C8.32478 7.25505 8.19712 7.30804 8.10264 7.402L7.40264 8.103C7.35472 8.14897 7.31652 8.20409 7.2903 8.2651C7.26408 8.3261 7.25038 8.39175 7.25001 8.45815C7.24963 8.52455 7.2626 8.59035 7.28813 8.65165C7.31366 8.71295 7.35124 8.7685 7.39864 8.815L10.5836 11.999L7.39964 15.185C7.30661 15.2804 7.25488 15.4086 7.25563 15.5418C7.25638 15.6751 7.30955 15.8027 7.40364 15.897L8.10364 16.597C8.30964 16.804 8.61964 16.797 8.81564 16.601L12.0016 13.416L15.1856 16.601C15.281 16.694 15.4092 16.7458 15.5425 16.745C15.6757 16.7443 15.8033 16.6911 15.8976 16.597L16.5986 15.897C16.6465 15.851 16.6846 15.7958 16.7108 15.7347C16.7369 15.6737 16.7505 15.608 16.7508 15.5416C16.7511 15.4752 16.738 15.4095 16.7124 15.3482C16.6868 15.2869 16.6491 15.2314 16.6016 15.185L13.4156 11.999L16.6016 8.815C16.6948 8.71974 16.7467 8.59163 16.7462 8.45838C16.7456 8.32514 16.6926 8.19748 16.5986 8.103L15.8986 7.403C15.8517 7.35497 15.7958 7.31672 15.734 7.29046C15.6722 7.2642 15.6058 7.25045 15.5386 7.25C15.4728 7.25054 15.4078 7.26407 15.3472 7.28981C15.2866 7.31555 15.2317 7.35299 15.1856 7.4H15.1846Z"
        fill="#9FADBC"
      />
    </svg>
  );
};

const WatchIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 18C7.464 18 4.001 13.74 4.001 12C4.001 9.999 7.46 6 12.001 6C16.377 6 19.999 9.973 19.999 12C19.999 13.74 16.537 18 12.001 18H12ZM12.001 4C6.48 4 2 8.841 2 12C2 15.086 6.576 20 12 20C17.423 20 22 15.086 22 12C22 8.841 17.52 4 12 4"
        fill="#9FADBC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.977 13.984C10.874 13.984 9.977 13.087 9.977 11.984C9.977 10.881 10.874 9.984 11.977 9.984C13.081 9.984 13.977 10.881 13.977 11.984C13.977 13.087 13.081 13.984 11.977 13.984ZM11.977 7.984C9.771 7.984 7.977 9.778 7.977 11.984C7.977 14.19 9.771 15.984 11.977 15.984C14.184 15.984 15.977 14.19 15.977 11.984C15.977 9.778 14.184 7.984 11.977 7.984Z"
        fill="#9FADBC"
      />
    </svg>
  );
};

const WatchFilledIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.983 15.984C10.9222 15.9832 9.90503 15.5616 9.15474 14.8117C8.40445 14.0618 7.98232 13.0448 7.981 11.984C7.981 9.778 9.776 7.984 11.983 7.984C13.0438 7.98479 14.061 8.40641 14.8113 9.15633C15.5615 9.90624 15.9837 10.9232 15.985 11.984C15.985 14.19 14.19 15.984 11.983 15.984ZM12 4C6.48 4 2 8.84 2 12C2 15.086 6.577 20 12 20C17.423 20 22 15.086 22 12C22 8.84 17.519 4 12 4Z"
        fill="#9FADBC"
      />
      <path
        d="M12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14Z"
        fill="#9FADBC"
      />
    </svg>
  );
};

const columnPositions = {
  user: -8,
  email: -6,
};

const fixColumnPositions = (column) => {
  if (column.id.includes("azure")) {
    column.ui.isExternal = true;
    column.ui.readonly = true;
    column.ui.position = 0;
  }

  if (columnPositions[column.id] !== undefined) {
    return {
      ...column,
      ui: {
        ...column.ui,
        position: columnPositions[column.id],
      },
    };
  }

  return column;
};

const FooterActions = ({ setIsInitialRecordsRefSet, setVisible }) => {
  const [savingChanges, setSavingChanges] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);

  const theme = useTheme();
  const { changes, resetChanges } = useRecordsStore((state) => {
    return {
      changes: state.changes,
      resetChanges: state.resetChanges,
    };
  }, shallow);

  const showErrorMessage = () => {
    const flag = window.AP.flag.create({
      title: "Something went wrong",
      body: `Refresh the page and try again. If this keeps
            happening, let us know using the link below.`,
      type: "error",
      actions: { databaseSaveError: "Caelor App Support" },
    });

    setTimeout(() => {
      flag.close();
    }, 5000);
  };

  const flagActionEvenListener = (event) => {
    if (event.actionIdentifier === "databaseSaveError") {
      window.top.location.href = "https://caelor.atlassian.net/servicedesk/customer/portal/1";
    }
  };

  useEffect(() => {
    window.AP.events.on("flag.action", flagActionEvenListener);
  }, []);

  useEffect(() => {
    if (Object.keys(changes).length > 0) setIsDisabled(false);
    else setIsDisabled(true);
  }, [changes]);

  return (
    <Footer>
      {!isDisabled && (
        <div style={{ color: theme.shared.settings.database.text, marginRight: "20px", fontSize: "14px" }}>
          {!savingChanges ? "You have unsaved changes." : "Saving..."}
        </div>
      )}

      <Button
        appearance="primary"
        css={css`
          background-color: ${theme.shared.sidebar.tabs.text.active};
          margin-top: 5px;
          margin-bottom: 5px;

          &:not(:disabled):hover {
            background-color: ${theme.shared.sidebar.tabs.text.active};
            opacity: 0.9;
          }
        `}
        onClick={async () => {
          let errored = false;
          setSavingChanges(true);
          try {
            await applyChanges(changes);
          } catch (err) {
            console.error(err);
            errored = true;
            showErrorMessage();
          }
          setSavingChanges(false);
          !errored && resetChanges();
          setIsInitialRecordsRefSet(false);
        }}
        isDisabled={isDisabled}
      >
        Save
      </Button>

      <Button
        appearance="link"
        css={css`
          &:hover {
            text-decoration-color: ${theme.shared.sidebar.tabs.text.active};
          }
          span {
            color: ${theme.shared.sidebar.tabs.text.active};
          }
        `}
        onClick={() => setVisible(true)}
        isDisabled={isDisabled}
      >
        Discard
      </Button>
    </Footer>
  );
};

const ToggleUserVisiblity = () => {
  const { hiddenUsersSelection, setHiddenUsersSelection, records, addChange, changes, addMultipleChange, setRecords } =
    useRecordsStore((state) => {
      return {
        hiddenUsersSelection: state.hiddenUsersSelection,
        setHiddenUsersSelection: state.setHiddenUsersSelection,
        records: state.records,
        addChange: state.addChange,
        changes: state.changes,
        addMultipleChange: state.addMultipleChange,
        setRecords: state.setRecords,
      };
    }, shallow);

  const allRecords = records.filter((record) =>
    hiddenUsersSelection.selectionType === SelectionType.SHOW_ALL_USERS
      ? true
      : hiddenUsersSelection.selectionType === SelectionType.SHOW_HIDDEN_USERS
      ? record.values.hidden
      : !record.values.hidden,
  );

  const [showHideUser, setShowHideUser] = useState();
  const [showUnhideUser, setShowUnhideUser] = useState();

  let hasHiddenUsers = false;
  let hasVisibleUsers = false;

  const closeMenu = () => {
    setHiddenUsersSelection({ selectionType: hiddenUsersSelection.selectionType, selectedUsers: [] });
  };

  const hideUsers = async () => {
    const selectedUsersId = hiddenUsersSelection?.selectedUsers;

    if (selectedUsersId.length === 1) {
      const accountId = selectedUsersId[0];

      if (accountId) {
        const change = {
          operation: ChangeOperations.UPDATE_RECORDS,
          accountId: accountId,
          values: {
            hidden: true,
          },
        };
        addChange(change);
      }
    } else {
      const multipleChanges = [];
      selectedUsersId.forEach((selectedUserId) => {
        const change = {
          operation: ChangeOperations.UPDATE_RECORDS,
          accountId: selectedUserId,
          values: {
            hidden: true,
          },
        };
        multipleChanges.push(change);
      });
      addMultipleChange(multipleChanges);
    }
  };

  const showUsers = () => {
    const selectedUsersId = hiddenUsersSelection?.selectedUsers;

    if (selectedUsersId.length === 1) {
      const accountId = selectedUsersId[0];

      if (accountId) {
        const change = {
          operation: ChangeOperations.UPDATE_RECORDS,
          accountId: accountId,
          values: {
            hidden: false,
          },
        };
        addChange(change);
      }
    } else {
      const multipleChanges = [];
      selectedUsersId.forEach((selectedUserId) => {
        const change = {
          operation: ChangeOperations.UPDATE_RECORDS,
          accountId: selectedUserId,
          values: {
            hidden: false,
          },
        };
        multipleChanges.push(change);
      });
      addMultipleChange(multipleChanges);
    }
  };

  useEffect(() => {
    if (Object.keys(changes).length === 0) {
      return;
    }
    const updatedRecords = [...records];

    Object.entries(changes).forEach(([operation, operationsData]) => {
      if (operation === ChangeOperations.UPDATE_RECORDS) {
        Object.entries(operationsData).forEach(([accountId, { values }]) => {
          updatedRecords.forEach((record) => {
            if (record.accountId === values.user.accountId) {
              record.values = values;
            }
          });
        });
      }
    });

    setRecords(updatedRecords);

    closeMenu();
  }, [changes]);

  useEffect(() => {
    if (allRecords) {
      records.forEach((record) => {
        if (hiddenUsersSelection.selectedUsers.find((userId) => userId === record.accountId)) {
          if (record.values.hidden) {
            hasHiddenUsers = true;
          } else {
            hasVisibleUsers = true;
          }
        }
      });

      setShowHideUser(hasVisibleUsers);
      setShowUnhideUser(hasHiddenUsers);
    }
  }, [allRecords, hiddenUsersSelection, records]);

  if (!hiddenUsersSelection.selectedUsers.length) {
    return <></>;
  }

  return (
    <ToggleWrapper>
      <ToggleGroup style={{ width: "180px" }} onClick={closeMenu}>
        <IconContainer style={{ padding: "0px 8px 0px 12px" }}>
          <CloseIcon />
        </IconContainer>

        <SelectedMessage>
          {hiddenUsersSelection.selectedUsers.length}
          {hiddenUsersSelection.selectedUsers.length === 1 ? " user" : " users"} selected
        </SelectedMessage>
      </ToggleGroup>

      <MenuDivider />

      {showHideUser && (
        <ToggleGroup style={{ width: showUnhideUser ? "160px" : "180px" }} onClick={hideUsers}>
          <IconContainer style={{ paddingLeft: "28px" }}>
            <WatchIcon />
          </IconContainer>
          <SelectedMessage>Hide {hiddenUsersSelection.selectedUsers.length === 1 ? " user" : " users"}</SelectedMessage>
        </ToggleGroup>
      )}

      {showUnhideUser && (
        <ToggleGroup style={{ width: "180px" }} onClick={showUsers}>
          <IconContainer style={{ paddingLeft: "28px" }}>
            <WatchFilledIcon />
          </IconContainer>

          <SelectedMessage>
            Unhide {hiddenUsersSelection.selectedUsers.length === 1 ? " user" : " users"}
          </SelectedMessage>
        </ToggleGroup>
      )}
    </ToggleWrapper>
  );
};

const UserDatabaseOverview = () => {
  useKeyboardShortcuts();
  const [userSyncModalVisible, setUserSyncModalVisible] = useState(false);
  const [discardDialogVisible, setDiscardDialogVisible] = useState(false);
  const [leftOffset, setLeftOffset] = useState(0);

  const { setFields, syncedGroup, setSyncedGroup, resetChanges, changes, setSyncGuestUsers, records, setRecords } =
    useRecordsStore((state) => {
      return {
        changes: state.changes,
        resetChanges: state.resetChanges,
        setFields: state.setFields,
        syncedGroup: state.syncedGroup,
        setSyncedGroup: state.setSyncedGroup,
        setSyncGuestUsers: state.setSyncGuestUsers,
        records: state.records,
        setRecords: state.setRecords,
      };
    }, shallow);

  const [isInitialRecordsRefSet, setIsInitialRecordsRefSet] = useState(false);

  const initialRecordsRef = useRef(null);

  useEffect(() => {
    if (!isInitialRecordsRefSet && records.length !== 0) {
      initialRecordsRef.current = records;
      setIsInitialRecordsRefSet(true);
    }
  }, [records, isInitialRecordsRefSet]);

  const setColumns = useDatabaseUIStore((state) => state.setColumns);
  const setSelectedPage = usePageStore((state) => state.setSelectedPage);

  const { hasChanges, setHasChanges } = useChangesStore(
    (state) => ({
      hasChanges: state.hasChanges,
      setHasChanges: state.setHasChanges,
    }),
    shallow,
  );

  const hasChangesRef = useRef();
  const tableContainerRef = useRef();
  hasChangesRef.current = hasChanges;

  const { isLoading: isInitializedLoading, data: isInitialized } = useQuery(
    "database-is-initialized",
    fetchIsInitialized,
    {
      retry: 0,
      select: (response) => {
        const { data } = response;
        return data;
      },
    },
  );

  const {
    isLoading: fieldsLoading,
    data: fieldsData,
    refetch,
  } = useQuery(
    ["userdatabase-fields", { syncedGroup, includeEmail: true, includeHiddenField: true }],
    fetchUserDatabaseFields,
    {
      retry: 0,
      select: (response) => {
        const { data } = response;
        const reorderedColumns = data.map(fixColumnPositions).sort((a, b) => a.ui.position - b.ui.position);
        return reorderedColumns;
      },
    },
  );

  useEffect(() => {
    getShouldSyncGuestUsersStatus()
      .then((res) => {
        setSyncGuestUsers(!!res?.data?.shouldSyncGuestUsers);
      })
      .catch(() => setSyncGuestUsers(false));
  }, [setSyncGuestUsers]);

  useEffect(() => {
    if (fieldsData) {
      setFields(fieldsData);
      setColumns(
        JSON.parse(JSON.stringify(fieldsData)).reduce(
          (obj, { id, ui, type }) => ({ ...obj, [id]: { ...ui, id, type } }),
          {},
        ),
      );
    }
  }, [fieldsData]);

  useEffect(() => {
    window.AP.events.on("azure-sync-complete", () => {
      refetch();
    });
  }, []);

  useEffect(() => {
    setHasChanges(Object.keys(changes).length);
  }, [changes]);

  if (fieldsLoading || isInitializedLoading) {
    return <></>;
  }

  const handleDiscard = () => {
    if (Object.keys(changes).length > 0) {
      setRecords(initialRecordsRef.current);
      resetChanges();
    }
  };

  if (!isInitialized && !syncedGroup) {
    return (
      <>
        <SyncUserModal
          visible={userSyncModalVisible}
          onClose={() => setUserSyncModalVisible(false)}
          onSyncFinished={() => setSyncedGroup(true)}
        />

        <EmptyState
          setUserSyncModalVisible={setUserSyncModalVisible}
          onButtonClick={setUserSyncModalVisible}
          onGoBack={() => setSelectedPage(Pages.SETTINGS)}
        />
      </>
    );
  }

  return (
    <>
      <div
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          overflow: "hidden",
        }}
      >
        <HeaderWrapper padding="30px 20px 0px">
          <div style={{ display: "flex", alignItems: "center", columnGap: "16px" }}>
            <TitleWithBack
              handleOnClick={() => {
                setSelectedPage(Pages.SETTINGS);
              }}
              title="Employee Database"
              description={
                isInitialized
                  ? "Assign columns to people widgets"
                  : "Customize and personalize your intranet experience."
              }
              linkDescription={`${window.AP._hostOrigin}/wiki/plugins/servlet/ac/com.caelor.confluence.cloud.cosmos/caelor-cosmos-portal?ac.cosmosPage=${Pages.SETTINGS}-${SectionKeys.PEOPLE_BASE}-${SectionPagesIDs.WIDGETS}`}
            />
          </div>
        </HeaderWrapper>
        <HeaderWrapper padding="5px 10px">
          <MenuBar records={records} />
        </HeaderWrapper>
        <div
          id="records-database"
          style={{
            height: "100%",
            overflow: "scroll",
            zIndex: 3,
            position: "relative",
            overscrollBehaviorX: "none",
          }}
          ref={tableContainerRef}
        >
          <HeaderRow containerRef={tableContainerRef} setLeftOffset={setLeftOffset} leftOffset={leftOffset} />
          <Records leftOffset={leftOffset} />
        </div>
        <FooterActions setIsInitialRecordsRefSet={setIsInitialRecordsRefSet} setVisible={setDiscardDialogVisible} />
        <ToggleUserVisiblity />
      </div>

      <ConfirmDiscardChanges
        visible={discardDialogVisible}
        onClose={() => setDiscardDialogVisible(false)}
        confirmDiscard={handleDiscard}
      />
    </>
  );
};

export default UserDatabaseOverview;

export const ConfirmDiscardChanges = ({ visible, onClose, confirmDiscard }) => {
  const handleConfirm = () => {
    confirmDiscard();
    onClose();
  };

  return (
    <ModalTransition>
      {visible && (
        <Modal height="250px" width="400px" title={`Discard changes?`}>
          <ModalHeader>
            <ModalTitle>Discard changes?</ModalTitle>
          </ModalHeader>
          <ModalBody>
            <SectionMessage appearance="warning">Changes you made won't be saved.</SectionMessage>
          </ModalBody>
          <ModalFooter>
            <ModalButton onClick={onClose} bg="#F1F2F4" color="#42526E">
              Cancel
            </ModalButton>
            <ModalButton onClick={handleConfirm} bg="#DE350B" color="#fff" appearance="danger">
              Discard
            </ModalButton>
          </ModalFooter>
        </Modal>
      )}
    </ModalTransition>
  );
};
