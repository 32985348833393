import httpClient from "../../../../Service/http-client";

export async function getAzureIntegrationStatus() {
  const url = `ac/confluence/azure/config`;
  return httpClient.get(url);
}

export async function getAuthUrlForAzureIntegration(tenantId) {
  const url = `ac/confluence/azure/signin`;
  const response = await httpClient.post(url, { tenantId });
  return response.data;
}

export async function updateAzureIntegration(isAzureEnabled, isCacheEnabled) {
  const url = `ac/confluence/azure/config/update`;
  const response = await httpClient.post(url, { isAzureEnabled, isCacheEnabled });
  return response.data;
}

export async function refreshAzureData(accountIds) {
  const url = `ac/confluence/azure/refresh`;
  return httpClient.post(url, { accountIds });
}

export async function assignAzureDataToRecordsWithAccountIds(accountIds, isCacheEnabled) {
  const url = `ac/confluence/userdatabase/assign-azuredata-to-records`;
  return httpClient.post(url, { accountIds, isCacheEnabled });
}

export async function getFilterOptionsFromAzure(field, query) {
  const url = `ac/confluence/userdatabase/azure-filter-options?field=${field}&query=${query}`;
  return httpClient.get(url);
}
