import React, { useState, useMemo, useEffect } from "react";
import shallow from "zustand/shallow";
import styled from "styled-components";
import ImageIcon from "@atlaskit/icon/glyph/image";
import { getAccountId } from "./profile.service";
import { useCurrentUserStore } from "../../current-user-store";
import { useRecordsStore } from "../../../UserDatabase/records-store";
import BackgroundPopup from "../../../../Shared/Components/Background/BackgroundPopup";
import {
  IMAGE_TYPES,
  getCssForBackground,
  getRandomUserBackground,
} from "../../../../Shared/Functions/user-bg-provider";
import { useCorporateIdentityStore } from "../Settings/General/BrandAndColors/corporate-identity-store";

const BackgroundChangeContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  gap: 12px;
  z-index: 15;
  inset: 0;
  color: ${({ theme }) => theme.shared.sidebar.button.headerText};
`;

const Button = styled.span`
  background: ${({ theme }) => theme.global.background.surfaceOverlay};
  color: ${({ theme }) => theme.global.secondary};
  padding: 12px 22px;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.shared.sidebar.button.headerButtonHover};
  }
  &:active {
    background: ${({ theme }) => theme.shared.sidebar.button.headerButtonActive};
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  z-index: 5;
  height: 20vh;
  min-height: 175px;
  background: ${({ backgroundImage }) => backgroundImage};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  -webkit-print-color-adjust: exact;

  @media (min-width: 768px) {
    height: 20vh;
  }
  @media (min-width: 1024px) {
    height: 20vh;
  }
  @media (min-width: 1440px) {
    height: 25vh;
  }
  @media (min-width: 2560px) {
    height: 30vh;
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    z-index: 10;
    inset: 0;
    transition: 200ms;
  }

  &::before {
    ${({ backgroundOverlay }) => backgroundOverlay};
  }

  &:hover {
    &::after {
      background: rgba(23, 43, 77, ${(props) => (props.isEditable ? 0.4 : 0)});
    }

    ${BackgroundChangeContainer} {
      visibility: ${(props) => (props.isEditable ? "visible" : "hidden")};
    }
  }
`;

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  z-index: 1;
`;

const DEFAULT_BACKGROUND = {
  source: "color",
  value: "#000000",
};

const DEFAULT_OVERLAY = {
  color: "#000000",
  opacity: 0.2,
  isActive: false,
};

function Header() {
  const [isEditingImage, setIsEditingImage] = useState(false);
  const currentUser = useCurrentUserStore((state) => state.currentUser);

  const { records, addChange, setRecords } = useRecordsStore(
    (state) => ({
      records: state.records,
      addChange: state.addChange,
      setRecords: state.setRecords,
    }),
    shallow,
  );

  const colors = useCorporateIdentityStore((state) => state.colors);

  const isEditable = useMemo(() => {
    const accountId = getAccountId();

    if (accountId) {
      return currentUser.accountId === accountId;
    }

    return true;
  }, [currentUser]);

  const { backgroundImage, backgroundOverlay } = records[0]?.values?.user || {};

  const onEdit = (value) => {
    const record = {
      ...records[0],
      values: {
        ...records[0].values,
        user: { ...(records[0].values.user || {}), ...value },
      },
    };

    setRecords([record]);
    addChange({ ...record, operation: "UPDATE_RECORDS" });
  };

  const getBackgroundImage = (bgImg) => {
    if (bgImg) {
      return getCssForBackground(bgImg);
    }

    const newImage = getRandomUserBackground(IMAGE_TYPES.GRADIENT);
    onEdit({ backgroundImage: { ...newImage } });

    return getCssForBackground(newImage);
  };

  const getBackgroundOverlay = (bgOverlay) => {
    if (bgOverlay?.isActive) {
      return `
        background-color: ${bgOverlay.color};
        opacity: ${bgOverlay.opacity};
      `;
    }

    return `display: none;`;
  };

  return (
    <Wrapper>
      <ImageWrapper
        isEditable={isEditable}
        backgroundImage={getBackgroundImage(backgroundImage)}
        backgroundOverlay={getBackgroundOverlay(backgroundOverlay)}
      >
        <BackgroundChangeContainer>
          <ImageIcon size="large" />
          Add your header image
          <Button onClick={() => setIsEditingImage(true)}>Upload an image</Button>
        </BackgroundChangeContainer>
      </ImageWrapper>

      <BackgroundPopup
        linkToDocs="https://caelor.atlassian.net/wiki/x/jYCV"
        position="absolute"
        dialogPositionCss={{ top: 0, bottom: 0, right: 0 }}
        isOpen={isEditingImage}
        image={backgroundImage || DEFAULT_BACKGROUND}
        overlay={backgroundOverlay || DEFAULT_OVERLAY}
        onClose={() => setIsEditingImage(false)}
        resetBackground={() => onEdit({ backgroundImage: DEFAULT_BACKGROUND })}
        setImage={(image) => onEdit({ backgroundImage: image })}
        setOverlay={(overlay) => onEdit({ backgroundOverlay: overlay })}
        showCurrentPageInAttachments={false}
        defaultImage={DEFAULT_BACKGROUND}
        primaryColor={"#0065ff"}
        primaryColorContrast={"#fff"}
        showVideoInAttachments={false}
        hasColorTabs
        hasUpload
      />
    </Wrapper>
  );
}

export default Header;
