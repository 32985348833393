import { CardDefaultData } from "../../Shared/Panels/data/cards";
import { PanelKeys } from "./panelTypes";

export const DEFAULT_DATA_BY_PANEL_KEY = {
  [PanelKeys.WORK_ANNIVERSARY]: {
    title: "Work Anniversary",
    isDummy: false,
    maxNumberOfItems: 4,
    useYears: true,
    backgroundType: "image",
    rounding: 0,
    imageSize: 80,
    collection: "",
    userGroup: "",
    pagination: "show-more",
    view: "card",
  },
  [PanelKeys.KNOW_YOUR_TEAM]: {
    title: "Know your team",
    description: "",
    events: [
      { label: "Birthdays", value: true, key: "hasBirthday" },
      { label: "New Starters", value: true, key: "isNewStarter" },
      { label: "Work Anniversary", value: true, key: "hasAnniversary" },
      { label: "Profile update", value: true, key: "profileUpdate" },
      { label: "Blogs created", value: true, key: "publishedBlogs" },
    ],
    titleFieldId: "FF2Vrog",
    footerFieldId: "dc49cu8",
    titleFieldOn: true,
    footerFieldOn: true,
    socialLinks: true,
    cardRounding: 0,
    profileImageSize: 80,
  },
  [PanelKeys.RECENTLY_ACTIVE_USERS]: {
    title: "Recently active users",
    pagination: "show-more",
    description: "",
    events: [
      { label: "Blog published", value: true, status: "Created", type: "blog" },
      { label: "Blog update", value: true, status: "Updated", type: "blog" },
      { label: "Page published", value: true, status: "Created", type: "page" },
      { label: "Page update", value: true, status: "Updated", type: "page" },
      { label: "Comment posted", value: true },
    ],
    cardRounding: 0,
    profileImageSize: 80,
  },
  [PanelKeys.USER_BLOG_POSTS]: {
    title: "User blog posts",
    pagination: "show-more",
    description: "",
    cardRounding: 0,
    profileImageSize: 80,
    labels: [],
    spaces: [],
    contributors: [],
  },
  [PanelKeys.ALL_EMPLOYEES]: {
    title: "All Employees",
    description: "",
    sortByName: "firstName",
    sortBy: "releaseDate",
    orderBy: "ascending",
    designStyle: "austin",
    isDummy: false,
    titleFieldId: "FF2Vrog",
    footerFieldId: "dc49cu8",
    titleFieldOn: true,
    footerFieldOn: true,
    backgroundType: "image",
    cardRounding: 0,
    profileImageSize: 80,
    maxNumberOfItems: 4,
  },
  [PanelKeys.UPCOMING_BIRTHDAY]: {
    title: "Upcoming birthdays",
    description: "",
    isDummy: false,
    titleFieldId: "FF2Vrog",
    footerFieldId: "dc49cu8",
    pagination: "show-more",
    titleFieldOn: true,
    footerFieldOn: true,
    backgroundType: "image",
    cardRounding: 0,
    profileImageSize: 80,
    maxNumberOfItems: 4,
    collection: "",
  },
  [PanelKeys.NEW_STARTERS]: {
    title: "New starters",
    description: "",
    isDummy: false,
    titleFieldId: "FF2Vrog",
    footerFieldId: "dc49cu8",
    titleFieldOn: true,
    footerFieldOn: true,
    backgroundType: "image",
    cardRounding: 0,
    profileImageSize: 80,
    maxNumberOfItems: 4,
    pagination: "show-more",
    collection: "",
  },
  [PanelKeys.ICON_CARDS]: CardDefaultData[PanelKeys.ICON_CARDS],
  [PanelKeys.TEXT_CARDS]: CardDefaultData[PanelKeys.TEXT_CARDS],
  [PanelKeys.IMG_CARDS]: CardDefaultData[PanelKeys.IMG_CARDS],
};
