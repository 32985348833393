import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { ModalKeys } from "./modalKeys";
import ManageSubscriptions from "./Subscriptions/ManageSubscriptions";
import Preview from "./PicturePreview/Preview";

const EntryWrapper = styled.div`
  height: calc(100vh - 20px);
`;

const AvailableComponents = {
  [ModalKeys.SUBSCRIPTIONS]: ManageSubscriptions,
  [ModalKeys.PICTURE_PREVIEW]: Preview,
  [undefined]: () => <></>,
};

function ModalEntry() {
  const [modalKey, setModalKey] = useState("");
  const [additionalData, setAdditionalData] = useState({});

  useEffect(() => {
    window.AP.dialog.getCustomData((data) => {
      const { modalKey: receivedKey, ...rest } = data;
      setAdditionalData({ ...(rest || {}) });
      if (!receivedKey) {
        throw Error("To create a dialog a modal key is required.");
      } else {
        setModalKey(receivedKey);
      }
    });
  }, []);

  const ModalContent = useMemo(() => AvailableComponents[modalKey], [modalKey]);

  if (!modalKey) {
    return <></>;
  }

  return (
    <EntryWrapper>
      <ModalContent {...additionalData} />
    </EntryWrapper>
  );
}

export default ModalEntry;
