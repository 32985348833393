import React from "react";

export const MainContentIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
    <rect x="2" y="2" width="10.4" height="4.8" rx="1" fill="#4C9AFF" />
    <rect x="2" y="7.59998" width="10.4" height="4.8" rx="1" fill="#4C9AFF" />
    <rect x="2" y="13.2" width="10.4" height="4.8" rx="1" fill="#4C9AFF" />
    <rect x="13.2002" y="7.6001" width="4.8" height="4.8" rx="1" fill="#B3BAC5" />
    <rect x="13.2002" y="2" width="4.8" height="4.8" rx="1" fill="#B3BAC5" />
    <rect x="13.2002" y="13.2" width="4.8" height="4.8" rx="1" fill="#B3BAC5" />
  </svg>
);

export const SideContentIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
    <rect x="2" y="2" width="10.4" height="4.8" rx="1" fill="#B3BAC5" />
    <rect x="2" y="7.59998" width="10.4" height="4.8" rx="1" fill="#B3BAC5" />
    <rect x="2" y="13.2" width="10.4" height="4.8" rx="1" fill="#B3BAC5" />
    <rect x="13.2002" y="7.6001" width="4.8" height="4.8" rx="1" fill="#4C9AFF" />
    <rect x="13.2002" y="2" width="4.8" height="4.8" rx="1" fill="#4C9AFF" />
    <rect x="13.2002" y="13.2" width="4.8" height="4.8" rx="1" fill="#4C9AFF" />
  </svg>
);

export const EditIcon = () => (
  <svg width="13" height="13" viewBox="0 0 13 13" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.14806 0.852087C9.24265 0.759064 9.37022 0.707266 9.50288 0.708016C9.63555 0.708765 9.76253 0.762001 9.85606 0.856087L12.1441 3.14409C12.1909 3.19027 12.2281 3.24524 12.2537 3.30584C12.2792 3.36644 12.2926 3.43149 12.293 3.49726C12.2933 3.56302 12.2807 3.62822 12.2559 3.68911C12.231 3.74999 12.1944 3.80538 12.1481 3.85209L5.00006 11.0001L2.00006 8.00009L9.14806 0.852087ZM1.00006 9.00009L4.00006 12.0001L0.490058 12.8771C0.220058 12.9451 0.0540588 12.7851 0.123059 12.5101L1.00006 9.00009Z"
      fill="#97A0AF"
    />
  </svg>
);

export const DeleteIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.00016 14.6663C11.6821 14.6663 14.6668 11.6816 14.6668 7.99967C14.6668 4.31778 11.6821 1.33301 8.00016 1.33301C4.31826 1.33301 1.3335 4.31778 1.3335 7.99967C1.3335 11.6816 4.31826 14.6663 8.00016 14.6663ZM7.06136 8.00993L6.07816 8.99899C6.01469 9.06068 5.96413 9.13438 5.92944 9.21581C5.89475 9.29724 5.87662 9.38476 5.87611 9.47327C5.87561 9.56178 5.89273 9.6495 5.92648 9.73133C5.96023 9.81315 6.00994 9.88743 6.0727 9.94984C6.13546 10.0122 6.21003 10.0615 6.29204 10.0948C6.37405 10.1281 6.46187 10.1447 6.55037 10.1437C6.63888 10.1427 6.72629 10.1241 6.80752 10.0889C6.88876 10.0538 6.96217 10.0028 7.0235 9.939L8.00199 8.95466L8.9615 9.91835C9.08624 10.0437 9.25567 10.1144 9.43251 10.1148C9.60936 10.1153 9.77914 10.0454 9.9045 9.92069C10.0299 9.79595 10.1005 9.62652 10.101 9.44967C10.1014 9.27282 10.0316 9.10305 9.90683 8.97769L8.94198 8.00907L9.93016 7.01499C10.0513 6.88893 10.1181 6.72032 10.1161 6.5455C10.1141 6.37068 10.0435 6.20364 9.91951 6.08036C9.79553 5.95709 9.6281 5.88745 9.45327 5.88645C9.27844 5.88544 9.11021 5.95315 8.98483 6.075L8.00113 7.06456L7.00016 6.05969C6.87469 5.93795 6.70643 5.87038 6.53161 5.87151C6.35679 5.87265 6.18942 5.94241 6.06554 6.06576C5.94166 6.18912 5.8712 6.35619 5.86932 6.531C5.86744 6.70581 5.9343 6.87437 6.0555 7.00035L7.06136 8.00993Z"
      fill="#97A0AF"
    />
  </svg>
);

export const TrashIcon = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 1H11C11.2652 1 11.5196 1.10536 11.7071 1.29289C11.8946 1.48043 12 1.73478 12 2C12 2.26522 11.8946 2.51957 11.7071 2.70711C11.5196 2.89464 11.2652 3 11 3H1C0.734784 3 0.48043 2.89464 0.292893 2.70711C0.105357 2.51957 0 2.26522 0 2C0 1.73478 0.105357 1.48043 0.292893 1.29289C0.48043 1.10536 0.734784 1 1 1ZM3.78 12C3.55707 12 3.34054 11.9255 3.16479 11.7883C2.98905 11.6512 2.86417 11.4592 2.81 11.243L1.156 4.62C1.13782 4.54646 1.13659 4.46975 1.15239 4.39566C1.1682 4.32157 1.20064 4.25204 1.24726 4.19232C1.29387 4.13261 1.35345 4.08427 1.42149 4.05095C1.48953 4.01763 1.56424 4.00021 1.64 4H10.36C10.4359 4.00002 10.5109 4.01734 10.5791 4.05065C10.6474 4.08396 10.7072 4.13238 10.7539 4.19224C10.8007 4.2521 10.8332 4.32182 10.8489 4.39611C10.8647 4.4704 10.8634 4.54731 10.845 4.621L9.189 11.243C9.13483 11.4592 9.00995 11.6512 8.83421 11.7883C8.65846 11.9255 8.44193 12 8.219 12H3.781H3.78ZM5 0H7C7.26522 0 7.51957 0.105357 7.70711 0.292893C7.89464 0.48043 8 0.734784 8 1H4C4 0.734784 4.10536 0.48043 4.29289 0.292893C4.48043 0.105357 4.73478 0 5 0Z"
      fill="#97A0AF"
    />
  </svg>
);

export const AddIcon = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 5V1C7 0.734784 6.89464 0.48043 6.70711 0.292893C6.51957 0.105357 6.26522 0 6 0C5.73478 0 5.48043 0.105357 5.29289 0.292893C5.10536 0.48043 5 0.734784 5 1V5H1C0.734784 5 0.48043 5.10536 0.292893 5.29289C0.105357 5.48043 0 5.73478 0 6C0 6.26522 0.105357 6.51957 0.292893 6.70711C0.48043 6.89464 0.734784 7 1 7H5V11C5 11.2652 5.10536 11.5196 5.29289 11.7071C5.48043 11.8946 5.73478 12 6 12C6.26522 12 6.51957 11.8946 6.70711 11.7071C6.89464 11.5196 7 11.2652 7 11V7H11C11.2652 7 11.5196 6.89464 11.7071 6.70711C11.8946 6.51957 12 6.26522 12 6C12 5.73478 11.8946 5.48043 11.7071 5.29289C11.5196 5.10536 11.2652 5 11 5H7Z"
      fill="#0065FF"
    />
  </svg>
);

export const SmallRightIcon = () => (
  <svg width="5" height="12" viewBox="0 0 5 8" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.293802 1.69793C0.200588 1.60601 0.12657 1.49649 0.0760489 1.37572C0.0255278 1.25495 -0.000488281 1.12534 -0.000488281 0.994431C-0.000488281 0.863519 0.0255278 0.733913 0.0760489 0.613143C0.12657 0.492373 0.200588 0.382848 0.293802 0.290931C0.482821 0.104364 0.737718 -0.000244141 1.0033 -0.000244141C1.26889 -0.000244141 1.52378 0.104364 1.7128 0.290931L4.6778 3.23093C4.78 3.33226 4.86111 3.45283 4.91647 3.58568C4.97182 3.71852 5.00032 3.86101 5.00032 4.00493C5.00032 4.14885 4.97182 4.29134 4.91647 4.42418C4.86111 4.55703 4.78 4.6776 4.6778 4.77893L1.7228 7.70893C1.53374 7.8958 1.27863 8.0006 1.0128 8.0006C0.746974 8.0006 0.491865 7.8958 0.302803 7.70893C0.209588 7.61701 0.13557 7.50749 0.0850487 7.38672C0.0345277 7.26595 0.00851154 7.13634 0.00851154 7.00543C0.00851154 6.87452 0.0345277 6.74491 0.0850487 6.62414C0.13557 6.50337 0.209588 6.39385 0.302803 6.30193L2.6208 4.00493L0.293802 1.69793Z"
      fill="#97A0AF"
    />
  </svg>
);

export const MediumBlueRightIcon = ({ primaryColor }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.2943 9.69817C10.2011 9.60626 10.1271 9.49673 10.0765 9.37596C10.026 9.25519 10 9.12559 10 8.99467C10 8.86376 10.026 8.73416 10.0765 8.61339C10.1271 8.49262 10.2011 8.38309 10.2943 8.29117C10.4833 8.10461 10.7382 8 11.0038 8C11.2694 8 11.5243 8.10461 11.7133 8.29117L14.6783 11.2312C14.7805 11.3325 14.8616 11.4531 14.917 11.5859C14.9723 11.7188 15.0008 11.8613 15.0008 12.0052C15.0008 12.1491 14.9723 12.2916 14.917 12.4244C14.8616 12.5573 14.7805 12.6778 14.6783 12.7792L11.7233 15.7092C11.5342 15.896 11.2791 16.0008 11.0133 16.0008C10.7475 16.0008 10.4924 15.896 10.3033 15.7092C10.2101 15.6173 10.1361 15.5077 10.0855 15.387C10.035 15.2662 10.009 15.1366 10.009 15.0057C10.009 14.8748 10.035 14.7452 10.0855 14.6244C10.1361 14.5036 10.2101 14.3941 10.3033 14.3022L12.6213 12.0052L10.2943 9.69817Z"
      fill={primaryColor}
    />
  </svg>
);

export const LockIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <rect width="24" height="24" fill="white" fillOpacity="0.01" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 10V8.002C8.00053 6.94111 8.42206 5.9238 9.17204 5.17345C9.92201 4.4231 10.9391 4.00106 12 4C12.5255 4 13.0458 4.10353 13.5312 4.30467C14.0166 4.50582 14.4577 4.80063 14.8291 5.17228C15.2006 5.54393 15.4952 5.98512 15.6961 6.47065C15.897 6.95619 16.0003 7.47654 16 8.002V10C16.5304 10 17.0391 10.2107 17.4142 10.5858C17.7893 10.9609 18 11.4696 18 12V18C18 19.105 17.098 20 15.991 20H8.01C7.74652 20.0013 7.48537 19.9506 7.24156 19.8506C6.99776 19.7507 6.7761 19.6036 6.58932 19.4178C6.40254 19.2319 6.25433 19.011 6.15319 18.7677C6.05206 18.5244 6 18.2635 6 18V12C6 10.898 6.897 10.005 8 10ZM10 10H14V8.002C14.0001 7.73922 13.9485 7.479 13.8481 7.23617C13.7476 6.99335 13.6003 6.77269 13.4146 6.58679C13.2289 6.40088 13.0084 6.25338 12.7657 6.1527C12.523 6.05202 12.2628 6.00013 12 6C10.898 6 10 6.898 10 8.002V10ZM12 17C12.5304 17 13.0391 16.7893 13.4142 16.4142C13.7893 16.0391 14 15.5304 14 15C14 14.4696 13.7893 13.9609 13.4142 13.5858C13.0391 13.2107 12.5304 13 12 13C11.4696 13 10.9609 13.2107 10.5858 13.5858C10.2107 13.9609 10 14.4696 10 15C10 15.5304 10.2107 16.0391 10.5858 16.4142C10.9609 16.7893 11.4696 17 12 17Z"
      fill="#0065FF"
    />
  </svg>
);
