import React from "react";
import { token } from "@atlaskit/tokens";
import { Flex, xcss } from "@atlaskit/primitives";
import Toggle from "@atlaskit/toggle";
import { usePageStore } from "../../../Hub/page-store";
import ClickableHeader from "../shared/ClickableHeader";
import NativeTabs from "../../../../Shared/Components/NativeTabs";
import { Pages } from "../../../Hub/pages";
import { SidebarTabs } from "./tabs/options";
import { FieldInline, FieldLabel } from "../drawer/components/edit/shared/Components";
import { useNavigationStore } from "../../store";

const sidebarCss = xcss({
  width: "340px",
  borderRight: `1px solid ${token("color.border.disabled")}`,
  backgroundColor: token("color.text.inverse"),
  padding: "32px 32px 12px 32px",
});

function Sidebar() {
  const setSelectedPage = usePageStore((state) => state.setSelectedPage);

  const { active, setActive } = useNavigationStore((state) => ({
    active: state.active,
    setActive: state.setActive,
  }));

  return (
    <Flex rowGap="space.300" direction="column" xcss={sidebarCss}>
      <ClickableHeader title="Configuration" onClick={() => setSelectedPage(Pages.SETTINGS)} />

      <FieldInline>
        <FieldLabel>Published</FieldLabel>
        <Toggle id="toggle-controlled" onChange={() => setActive(!active)} isChecked={active} />
      </FieldInline>

      <NativeTabs tabs={SidebarTabs} useNative={false} />
    </Flex>
  );
}

export default Sidebar;
