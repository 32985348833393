import { Ordering } from "./sorting-types";
import Button from "@atlaskit/button";

const OrderButton = ({ sortOrder, onUpdate }) => {
  const onSortOrderChange = () => {
    const newSortOrder = sortOrder === Ordering.DESCENDING ? Ordering.ASCENDING : Ordering.DESCENDING;
    onUpdate(newSortOrder);
  };

  return (
    <Button appearance="link" onClick={onSortOrderChange} spacing="none">
      {sortOrder === Ordering.DESCENDING ? "ascending" : "descending"}
    </Button>
  );
};

export default OrderButton;   