import React, { useEffect, useMemo, useState } from "react";
import Select, { CreatableSelect, components } from "@atlaskit/select";
import Button from "@atlaskit/button";
import SectionMessage from "@atlaskit/section-message";
import { useQuery } from "react-query";
import styled from "styled-components";
import { useNewsMetadataStore } from "../news-metadata-store";
import { FieldContainer, Label } from "./shared/styled";
import {
  getCategoriesSettings,
  getProposalsSettings,
  saveCategoriesSettings,
  saveProposalsSettings,
} from "../../Components/Hub/Components/Settings/News/api";
import { useCurrentUserStore } from "../../Components/Hub/current-user-store";
import { contentPropertyService } from "../../Service/ContentPropertyService";
import Tag from "@atlaskit/tag";
import { nanoid } from "nanoid";

const Wrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  height: 485px;
  overflow-y: auto;
  padding: 20px 25px 0px 25px;
  gap: 10px;
`;

const CategoryDescription = styled.div`
  color: ${({ theme }) => `${theme.newsMetadataDialog.categoryText}`};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 150% */
`;

const AddedCategories = styled.div`
  color: ${({ theme }) => `${theme.newsMetadataDialog.categoryText}`};
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
`;

const CategoryTagWrapper = styled.div`
  width: 443px;
  display: flex;
  flex-flow: row;
  align-items: center;
  align-content: center;
  gap: 10px 5px;
  flex-wrap: wrap;
`;

const SuggestWrapper = styled.div`
  display: flex;
  flex-flow: column;
  border-radius: 3px;
  padding: 30px 30px;
  gap: 10px;
  background: #f4f5f7;
`;

const SuggestTitle = styled.div`
  color: ${({ theme }) => theme.newsMetadataSettings.title};

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
`;

const SuggestDescription = styled.div`
  color: ${({ theme }) => theme.newsMetadataSettings.description};

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
`;

function Categories({ loadingCategories }) {
  const [createdCategories, setCreatedCategories] = useState([]);
  const [successMessage, setSuccessMessage] = useState(false);

  const {
    blogId,
    allCategories,
    setAllCategories,
    blogCategories,
    setBlogCategories,
    userIsEditor,
    isLoadingMetadata,
  } = useNewsMetadataStore((state) => ({
    blogId: state.blogId,
    allCategories: state.allCategories,
    setAllCategories: state.setAllCategories,
    blogCategories: state.blogCategories,
    setBlogCategories: state.setBlogCategories,
    userIsEditor: state.userIsEditor,
    isLoadingMetadata: state.isLoadingMetadata,
  }));

  const selectOptions = useMemo(
    () =>
      allCategories.filter((cat) => !!cat.id && !!cat.value).map(({ id, value }) => ({ id, value: id, label: value })),
    [allCategories],
  );

  const { currentUser, isCurrentUserAdmin } = useCurrentUserStore((state) => ({
    currentUser: state.currentUser,
    isCurrentUserAdmin: state.isCurrentUserAdmin,
  }));

  const { data: proposalsSettingsData, refetch: refetchProposal } = useQuery(
    "proposals-settings",
    getProposalsSettings,
    {
      retry: 0,
      select: (response) => {
        const { data } = response;
        return data;
      },
    },
  );

  const createButton = (categoryName) => {
    const id = nanoid(8);
    const data = [...(allCategories || []), { id, value: categoryName }];

    saveCategoriesSettings(data);
    setAllCategories(data);
    setBlogCategories([...blogCategories, { id, value: id, label: categoryName }]);
  };

  const proposalButton = () => {
    if (!proposalsSettingsData?.proposals?.data) {
      const newData = createdCategories.map((el) => ({
        id: el.value,
        name: el.label,
        accountId: currentUser.accountId,
        blogId: blogId,
      }));
      saveProposalsSettings(newData);
      refetchProposal();
    } else {
      const newData = createdCategories.map((el) => ({
        id: el.value,
        name: el.label,
        accountId: currentUser.accountId,
        blogId: blogId,
      }));

      const mergedData = [...proposalsSettingsData.proposals.data, ...newData];
      saveProposalsSettings(mergedData);
      refetchProposal();
    }
    setSuccessMessage(true);
    setCreatedCategories([]);
  };

  return (
    <>
      <Wrapper>
        {!loadingCategories && isCurrentUserAdmin && (
          <>
            <CategoryDescription>
              Categories will be displayed across all news widgets and serve as filters in the newsroom, enhancing
              navigation and personalized content delivery.
            </CategoryDescription>
            <FieldContainer style={{ marginTop: 0 }}>
              <Label>Select news categories</Label>
              <CreatableSelect
                isSearchable
                isClearable
                isDisabled={!isCurrentUserAdmin}
                isLoading={isLoadingMetadata}
                placeholder="Select a category"
                isMulti
                value={blogCategories}
                options={selectOptions}
                onCreateOption={createButton}
                onChange={(selectedOption) => setBlogCategories(selectedOption)}
              />
            </FieldContainer>
          </>
        )}
        {!loadingCategories && userIsEditor && !isCurrentUserAdmin && (
          <>
            {successMessage && (
              <SectionMessage title="Request sent" appearance="success">
                New categories have been requested succesfully.
              </SectionMessage>
            )}
            <CategoryDescription>
              Categories will be displayed across all news widgets and serve as filters in the newsroom, enhancing
              navigation and personalized content delivery.
            </CategoryDescription>
            <FieldContainer style={{ marginTop: 0 }}>
              <Label>Select categories</Label>
              <Select
                isSearchable
                isLoading={isLoadingMetadata}
                placeholder="Select a category"
                isMulti
                value={blogCategories}
                defaultOptions
                options={selectOptions}
                onChange={(selectedOption) => {
                  setBlogCategories(selectedOption);
                }}
              />
            </FieldContainer>

            <SuggestWrapper>
              <SuggestTitle>Suggest new categories</SuggestTitle>
              <SuggestDescription>
                Use the request field below to propose new categories. Cosmos administrators will review your requests.
              </SuggestDescription>
              <FieldContainer style={{ marginTop: 0 }}>
                <Label>New category</Label>
                <CreatableSelect
                  placeholder="Create a category"
                  isMulti
                  value={createdCategories}
                  onCreateOption={(inputValue) => {
                    const id = nanoid(8);
                    setCreatedCategories([...createdCategories, { label: inputValue, value: id }]);
                  }}
                  onChange={(selectedOption) => {
                    setCreatedCategories(selectedOption);
                  }}
                />
              </FieldContainer>
              <Button
                onClick={() => {
                  proposalButton();
                }}
                style={{ width: 388 }}
                appearance="primary"
              >
                Request category
              </Button>
            </SuggestWrapper>
          </>
        )}
        {!loadingCategories && !userIsEditor && !isCurrentUserAdmin && (
          <>
            <SectionMessage title="Editing permission required">
              Editing is limited to users who have page editing permissions and Cosmos administrators.
            </SectionMessage>
            <CategoryDescription>
              Categories will be visible in news overviews and as filters in the newsroom, allowing users to subscribe
              for personalized content delivery.
            </CategoryDescription>
            {!blogCategories.length && <AddedCategories>Added Categories</AddedCategories>}
            <CategoryTagWrapper>
              {blogCategories.map((option) => (
                <Tag text={option.label} isRemovable={false} />
              ))}
            </CategoryTagWrapper>
          </>
        )}
      </Wrapper>
    </>
  );
}

export default Categories;
