import React, { useEffect, useState } from "react";
import TrashIcon from "@atlaskit/icon/glyph/trash";
import PeopleGroupIcon from "@atlaskit/icon/glyph/people-group";
import EditIcon from "@atlaskit/icon/glyph/edit";
import EditorAddIcon from "@atlaskit/icon/glyph/editor/add";
import Textfield from "@atlaskit/textfield";
import Spinner from "@atlaskit/spinner";
import Button from "@atlaskit/button";
import {
  BlogCard,
  BlogCardDetails,
  BlogCardRow,
  BlogDescription,
  BlogDescriptionText,
  BlogTitle,
  BlogsCounter,
  BlogsWrapper,
  ButtonActions,
  DetailActions,
  DetailWrapper,
  LargePageIcon,
  SearchAction,
  TrashIconWrapperDetails,
} from "../styled";
import { useCategoriesStore, useModalStore } from "../../categories-store";
import { useInfiniteQuery } from "react-query";
import { searchBlogpostsInDetails } from "../../api";
import InfiniteScroller from "../../../../../../../Shared/Components/InfiniteScroller";
import { getProperty, updateProperty } from "../../../../../../../Shared/Functions/newsPropertiesHandlers";
import { ModalTypes } from "../modal/types";
import EmptyState from "../EmptyState";
import { useTheme } from "styled-components";
import EditorSearchIcon from "@atlaskit/icon/glyph/editor/search";
import { ConfirmDeleteModal } from "../../../../Shared/ConfirmDeleteModal";

const mapBlogpost = (blog) => {
  const createdDateTime = new Date(blog.content.history.createdDate);
  const createdDateOnly = createdDateTime.toISOString().split("T")[0];

  return {
    id: blog.content.id,
    title: blog.content.title,
    author: blog.content.history.createdBy.displayName,
    avatarUrl: `${window.AP._hostOrigin}${blog.content.history.createdBy.profilePicture.path}`,
    spaceUrl: `${window.AP._hostOrigin}/wiki${blog.content.space.icon.path}`,
    blogUrl: `${window.AP._hostOrigin}/wiki${blog.url}`,
    space: blog.content.space.name,
    createdDate: createdDateOnly,
  };
};

function CategoryDetails({ refetchCategoryDetails }) {
  const [searchQuery, setSearchQuery] = useState("");

  const [foundBlogs, setFoundBlogs] = useState([]);
  const [totalSize, setTotalSize] = useState(0);
  const [temporaryDeleteBlog, setTemporaryDeleteBlog] = useState({});
  const theme = useTheme();

  const { selectedCategory, categoriesBlogCountData, setCategoriesBlogCountData } = useCategoriesStore((state) => ({
    selectedCategory: state.selectedCategory,
    categoriesBlogCountData: state.categoriesBlogCountData,
    setCategoriesBlogCountData: state.setCategoriesBlogCountData,
  }));

  const { setIsModalOpen, setModalKey, isDeleteModalOpen, setIsDeleteModalOpen } = useModalStore((state) => ({
    setIsModalOpen: state.setIsModalOpen,
    setModalKey: state.setModalKey,
    setIsDeleteModalOpen: state.setIsDeleteModalOpen,
    isDeleteModalOpen: state.isDeleteModalOpen,
  }));

  const {
    fetchNextPage,
    hasNextPage,
    data: blogsData,
    refetch,
    isLoading,
  } = useInfiniteQuery(
    [
      `blogposts-category-${selectedCategory?.id}`,
      {
        categoryId: selectedCategory?.id,
        searchQuery,
      },
    ],
    searchBlogpostsInDetails,
    {
      enabled: !!selectedCategory?.id,
      cacheTime: 0,
      retry: 0,
      select: (response) => {
        const pages = [];
        response.pages.forEach((page) => {
          const body = JSON.parse(page.body);
          const resultsInPage = body.results.map((blog) => mapBlogpost(blog));
          pages.push({ ...body, results: resultsInPage });
        });
        return { pages, pageParams: response.pageParams };
      },
      getNextPageParam: (lastPage) => {
        const body = JSON.parse(lastPage.body);
        if (body._links.next) {
          const urlParams = new URLSearchParams(encodeURI(body._links.next));
          const cursor = urlParams.get("cursor");
          return cursor;
        }
        return false;
      },
    },
  );

  useEffect(() => {
    window.AP.events.on("refetch-blogs", () => refetch());
  }, []);

  useEffect(() => {
    if (categoriesBlogCountData?.length && selectedCategory.id) {
      const foundCategory = categoriesBlogCountData.find(({ id }) => id === selectedCategory.id);
      if (!foundCategory) return;

      if (foundCategory.numberOfBlogs !== totalSize) {
        setCategoriesBlogCountData([
          ...categoriesBlogCountData.map((cat) =>
            cat.id === selectedCategory.id ? { ...cat, numberOfBlogs: totalSize } : cat,
          ),
        ]);
      }
    }
  }, [selectedCategory, totalSize]);

  useEffect(() => {
    if (blogsData?.pages?.length) {
      setTotalSize(blogsData.pages[0].totalSize);
      const blogs = [];
      for (const page of blogsData.pages) {
        const blogsInPage = page.results;
        blogs.push(...(blogsInPage || []));
      }

      setFoundBlogs([...blogs]);
    } else {
      setFoundBlogs([]);
      setTotalSize(0);
    }
  }, [blogsData]);

  const deleteBlog = async (blogId, blogTitle) => {
    try {
      const newBlogs = foundBlogs.filter((blog) => blog.id !== blogId);
      setFoundBlogs([...newBlogs]);
      setTotalSize(totalSize - 1);

      const blogsContentProperty = await getProperty(blogId, "cosmosnewsmetadata");
      const blogsCategories = (blogsContentProperty?.value?.categories || "").split(", ");

      const removedSelectedCategory = blogsCategories.filter((id) => id !== selectedCategory?.id);
      const newCategories = removedSelectedCategory.join(", ");

      await updateProperty(
        blogId,
        blogsContentProperty.id,
        "cosmosnewsmetadata",
        blogsContentProperty?.version?.number,
        { categories: newCategories },
      );

      setTimeout(() => {
        refetchCategoryDetails();
      }, 250);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      const flag = window.AP.flag.create({
        title: `${blogTitle} has been removed from category`,
        type: "success",
      });

      setTimeout(() => {
        flag.close();
      }, 3000);
    }
  };

  const openModal = (modalKey) => {
    setModalKey(modalKey);
    setIsModalOpen(true);
  };

  return (
    <InfiniteScroller
      dataLength={blogsData?.pages?.length || 0}
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage}
      scrollableTargetId="categories-overview-wrapper"
      endMessage=""
    >
      <DetailWrapper>
        <DetailActions>
          <SearchAction>
            <Textfield
              value={searchQuery}
              className="search-blogs"
              placeholder="Search blogs"
              elemBeforeInput={<EditorSearchIcon />}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </SearchAction>
          <ButtonActions>
            <Button
              appearance="subtle"
              iconAfter={<PeopleGroupIcon />}
              onClick={() => openModal(ModalTypes.TARGET_AUDIENCE)}
            >
              Target Audience
            </Button>
            <Button appearance="subtle" iconAfter={<EditIcon />} onClick={() => openModal(ModalTypes.EDIT_CATEGORY)}>
              Rename Category
            </Button>
            {!!foundBlogs?.length && (
              <Button
                appearance="primary"
                iconAfter={<EditorAddIcon />}
                onClick={() => openModal(ModalTypes.ASSIGN_BLOGS)}
              >
                Assign blogs
              </Button>
            )}
          </ButtonActions>
        </DetailActions>
        {totalSize >= 0 && (
          <BlogsCounter>
            {totalSize} {totalSize === 1 ? "blog" : "blogs"}
          </BlogsCounter>
        )}
        {isLoading ? (
          <div style={{ display: "grid", placeContent: "center" }}>
            <Spinner size="xlarge" />
          </div>
        ) : foundBlogs?.length ? (
          <BlogsWrapper>
            {foundBlogs.map((blog) => (
              <>
                <BlogCard
                  key={blog.id}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    window.open(blog.blogUrl, "_blank");
                  }}
                >
                  <BlogCardRow>
                    <LargePageIcon src={"/images/blogIcon.svg"} />
                    <BlogTitle>{blog.title}</BlogTitle>
                  </BlogCardRow>
                  <BlogCardDetails>
                    <BlogDescription>
                      <BlogDescriptionText>
                        Created by {blog.author} · {blog.createdDate} · {blog.space}
                      </BlogDescriptionText>
                      <BlogDescriptionText></BlogDescriptionText>
                    </BlogDescription>
                  </BlogCardDetails>
                  <TrashIconWrapperDetails
                    className="trash-icon-blog"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setTemporaryDeleteBlog({ id: blog.id, title: blog.title });
                      setIsDeleteModalOpen(true);
                    }}
                  >
                    <TrashIcon primaryColor="rgba(137, 147, 164, 1)" size="small" />
                  </TrashIconWrapperDetails>
                </BlogCard>
              </>
            ))}
          </BlogsWrapper>
        ) : (
          <EmptyState
            title="No blogs assigned"
            description={`No new blogs assigned yet. Click “Assign blogs” button to get started.`}
            imageSrc={`/images/emptyCategories-${theme.global.name}.svg`}
            buttonText="Assign Blogs"
            onBtnClick={() => openModal(ModalTypes.ASSIGN_BLOGS)}
            paddingTop={50}
          />
        )}
      </DetailWrapper>
      <ConfirmDeleteModal
        onClickOutside={() => setIsDeleteModalOpen(false)}
        onDiscard={() => {
          setIsDeleteModalOpen(false);
        }}
        onConfirm={() => {
          deleteBlog(temporaryDeleteBlog.id, temporaryDeleteBlog.title);
          setIsDeleteModalOpen(false);
        }}
        visible={isDeleteModalOpen}
        deleteInfo={{
          title: "Remove blog",
          description: `Are you sure you want to remove blog ${temporaryDeleteBlog.title} from this category?`,
        }}
        buttonInfo={{
          mainText: "Remove",
          secondaryText: "Cancel",
          appearance: "danger",
        }}
        height="200px"
      />
    </InfiniteScroller>
  );
}

export default CategoryDetails;
