import React from "react";
import styled from "styled-components";
import SelectFilter from "../../Dashboard/filter/SelectFilter";
import OrderButton from "../../Dashboard/filter/OrderButton";
import { HelperMessage } from "../../Dashboard/filter/styled";
import { atlassianRestService } from "../../../../../Service/AtlassianRestService";

const ToggleWrapper = styled.div`
  width: 100%;
`;

const OrderLabel = styled.div`
  margin-right: 4px;
  color: #6b778c;
`;

export const sortRecords = async (sorting, records) => {
  const { field, order } = sorting || {};
  const accounts = records.map((record) => record.accountId);
  const accountRecords = records.reduce((obj, record) => ({ ...obj, [record.accountId]: record }), {});

  if (!records.length) {
    return [];
  }

  if (field === "USER") {
    let sortedUsers = await atlassianRestService.fetchUsers(accounts);

    sortedUsers = [...sortedUsers].sort((a, b) => {
      const userA = a.displayName.toLowerCase();
      const userB = b.displayName.toLowerCase();

      if (order === "ascending") {
        return userB > userA ? 1 : -1;
      }
      return userA > userB ? 1 : -1;
    });

    return sortedUsers.map((sortedUser) => accountRecords[sortedUser.accountId]);
  }

  if (field === "EMAIL") {
    let sortedEmails = await atlassianRestService.fetchEmails(accounts);

    sortedEmails = [...sortedEmails].sort((a, b) => {
      const emailA = a.email.toLowerCase();
      const emailB = b.email.toLowerCase();

      if (order === "ascending") {
        return emailB > emailA ? 1 : -1;
      }
      return emailA > emailB ? 1 : -1;
    });

    return sortedEmails.map((sortedEmail) => accountRecords[sortedEmail.accountId]);
  }

  return records;
};

const Sort = ({ sortOrder, sortField, onSortChange, onOrderChange }) => (
  <ToggleWrapper>
    <SelectFilter
      placeholder="Select.."
      isClearable
      noDetails
      value={sortField}
      setValue={(value) => onSortChange(value)}
      options={[
        { label: "Name", value: "USER" },
        { label: "Email", value: "EMAIL" },
      ]}
    />

    <HelperMessage>
      <OrderLabel>Order by</OrderLabel>
      <OrderButton sortOrder={sortOrder} onUpdate={(value) => onOrderChange(value)} />
    </HelperMessage>
  </ToggleWrapper>
);

export default Sort;
