/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import Button from "@atlaskit/button";
import Spinner from "@atlaskit/spinner";
import SectionMessage from "@atlaskit/section-message";
import React, { useEffect, useState } from "react";
import styled, { useTheme } from "styled-components";
import { useImageStore, useNewsMetadataStore } from "../news-metadata-store";
import BackgroundPopup from "../../Shared/Components/Background/BackgroundPopup";
import { ImageLinkPreview } from "./shared/styled";
import ImageIcon from "@atlaskit/icon/glyph/image";
import { useCurrentUserStore } from "../../Components/Hub/current-user-store";
import { SpinnerContainer } from "../../Components/Hub/styled/pages";

const Wrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  height: 485px;
  overflow-y: auto;
  padding: ${({ shouldAddPadding }) => (!shouldAddPadding ? "95px 25px 0px 25px" : "20px 25px 0px 25px")};
`;

const TextWrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  gap: 10px;
`;

const UploadImageTitle = styled.div`
  color: ${({ theme }) => `${theme.newsMetadataDialog.coverImage}`};
  font-size: 24px;
  font-style: normal;
  align-self: center;
  font-weight: 500;
  line-height: 28px; /* 116.667% */
`;

const UploadImageDescription = styled.div`
  color: ${({ theme }) => `${theme.newsMetadataDialog.imageDescription}`};
  text-align: center;
  padding: 0px 30px;
  font-size: 14px;
  font-style: normal;
  align-self: center;
  font-weight: 400;
  line-height: 130%; /* 18.2px */
`;

const ImageLinkPreviewOpacity = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  pointer-events: none;
`;

function UnsplashImage() {
  const imageData = useImageStore((state) => state.imageData);
  const [blogId, setBlogId] = useState("");
  const [hovered, setHovered] = useState(false);

  const { image, overlay } = imageData.background || {};

  const theme = useTheme();

  const { updateImageData, isEditingImage, setIsEditingImage, isLoadingImage } = useImageStore((state) => ({
    updateImageData: state.updateImageData,
    isEditingImage: state.isEditingImage,
    setIsEditingImage: state.setIsEditingImage,
    isLoadingImage: state.isLoadingImage,
  }));

  const { userIsEditor } = useNewsMetadataStore((state) => ({
    userIsEditor: state.userIsEditor,
  }));

  const { isCurrentUserAdmin } = useCurrentUserStore((state) => ({
    isCurrentUserAdmin: state.isCurrentUserAdmin,
  }));

  useEffect(() => {
    window.AP.context.getContext().then((data) => setBlogId(data.confluence.content.id));
  });

  const handleUpdates = (key, value) => {
    const newHeader = { ...imageData, [key]: value };
    updateImageData(newHeader);
  };

  const updateOverlay = (overlayData) => {
    const newBackground = { ...imageData.background, overlay: { ...overlayData } };
    handleUpdates("background", newBackground);
  };

  const updateImage = (imageData) => {
    const newBackground = { ...imageData.background, image: { ...imageData } };
    handleUpdates("background", newBackground);
  };

  if (isLoadingImage)
    return (
      <SpinnerContainer>
        <Spinner size="xlarge" />
      </SpinnerContainer>
    );

  return (
    <>
      {imageData.imageUrl === "" && image.link === "" ? (
        <Wrapper shouldAddPadding={!userIsEditor && !isCurrentUserAdmin}>
          {!userIsEditor && !isCurrentUserAdmin && (
            <SectionMessage title="Editing permission required">
              Editing is limited to users who have page editing permissions and Cosmos administrators.
            </SectionMessage>
          )}
          {theme.background === "#22272B" ? (
            <img
              src="/images/coverImagePreviewDark.svg"
              alt=""
              width={240}
              height={180}
              style={{ alignSelf: "center", paddingTop: userIsEditor && isCurrentUserAdmin ? 0 : 30 }}
            />
          ) : (
            <img
              src="/images/coverImagePreview.svg"
              alt=""
              width={240}
              height={180}
              style={{ alignSelf: "center", paddingTop: userIsEditor && isCurrentUserAdmin ? 0 : 30 }}
            />
          )}
          <TextWrapper>
            <UploadImageTitle>Cover image</UploadImageTitle>
            <UploadImageDescription>
              Choose a cover image or leave empty if you want to use the blog header image. If unspecified, a random
              image picked from{" "}
              <a
                style={{ color: "rgba(12, 102, 228, 1)", textDecoration: "none" }}
                href="https://unsplash.com/"
                target="_blank"
                rel="noreferrer"
              >
                Unsplash
              </a>{" "}
              ensures consistency.
            </UploadImageDescription>
          </TextWrapper>
          {(userIsEditor || isCurrentUserAdmin) && (
            <Button
              appearance="primary"
              style={{ width: "315px", alignSelf: "center", marginTop: 20 }}
              onClick={() => {
                setIsEditingImage(true);
              }}
            >
              Add cover image
            </Button>
          )}
        </Wrapper>
      ) : (
        <Wrapper shouldAddPadding={!userIsEditor && !isCurrentUserAdmin}>
          {!userIsEditor && !isCurrentUserAdmin && (
            <SectionMessage title="Editing permission required">
              Editing is limited to users who have page editing permissions and Cosmos administrators.
            </SectionMessage>
          )}
          <div
            style={{ position: "relative" }}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
          >
            <ImageLinkPreview link={image.link} hovered={hovered} />
            {hovered && <ImageLinkPreviewOpacity />}
            {(userIsEditor || isCurrentUserAdmin) && hovered && (
              <Button
                iconAfter={<ImageIcon primaryColor="white" />}
                appearance="default"
                css={css`
                  position: absolute;
                  top: 50%;
                  left: 150px;
                  color: white !important;
                  &:hover {
                    color: white !important;
                  }
                `}
                onClick={() => setIsEditingImage(true)}
              >
                Change Image
              </Button>
            )}
          </div>
          {(userIsEditor || isCurrentUserAdmin) && (
            <Button
              appearance="danger"
              style={{ width: "100%", marginTop: 20 }}
              onClick={() => {
                handleUpdates("background", {
                  image: {
                    source: "custom",
                    link: imageData.imageUrl,
                  },
                  overlay: {},
                });
              }}
            >
              Remove image
            </Button>
          )}
        </Wrapper>
      )}

      <BackgroundPopup
        position="absolute"
        dialogPositionCss={{ top: "-10px", bottom: 0, right: "-10px", height: "100vh" }}
        image={image}
        overlay={overlay}
        resetBackground={() =>
          handleUpdates("background", {
            image: {
              source: "custom",
              link: imageData.imageUrl,
            },
            overlay: {},
          })
        }
        setImage={updateImage}
        setOverlay={updateOverlay}
        isOpen={isEditingImage}
        onClose={() => setIsEditingImage(false)}
        defaultImage={imageData.imageUrl}
        showOverlay={false}
        showCurrentPageInAttachments={true}
        showVideoInAttachments={false}
        hasColorTabs={false}
        hasUpload
        boxWidth="30px"
        boxHeight="32px"
      />
    </>
  );
}

export default UnsplashImage;
