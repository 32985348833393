export const FieldType = {
  URL: "URL",
  TEXT: "TEXT",
  NUMBER: "NUMBER",
  SELECT: "SELECT",
  MULTISELECT: "MULTISELECT",
  DATE: "DATE",
  USER: "USER",
  EMAIL: "EMAIL",
};
