import React, { useContext, useMemo } from "react";
import { getUserValueFromField } from "../../../Components/Shared/cosmosFieldValueExtractor";
import { ViewTypes } from "../consts";
import { ChartContext } from "../context";
import { ChartCardView, ChartListView } from "@caelor/cards-and-panels-components";
import { useTheme } from "styled-components";

const AvailableComponents = {
  [ViewTypes.CARD]: ChartCardView,
  [ViewTypes.LIST]: ChartListView,
  [undefined]: () => <></>,
};

function ViewSelector({ selectedView, componentProps }) {
  const { viewOptions, userRecords, fields } = useContext(ChartContext);

  const theme = useTheme();

  const { node, userInfo, isLoading, onItemClick } = componentProps;

  const chartPreviewProps = {
    node,
    userInfo,
    getUserValueFromField,
    isLoading,
    additionalProps: {
      shouldCheckCosmosLicense: false,
      shouldCheckMacrosuiteLicense: false,
      isCosmosInstalled: true,
      isMacrosuiteInstalled: false,
      fields,
      userRecords,
      viewOptions,
      onItemClick,
    },
    darkTheme: {
      backgroundColor: theme.background,
      titleColor: theme.manualOrgChart.name,
      descriptionColor: theme.manualOrgChart.description,
      boxShadow: theme.manualOrgChart.boxShadow,
      minusPlusColor: theme.manualOrgChart.minusPlusColor,
    },
  };

  const Content = useMemo(() => {
    return AvailableComponents[`${selectedView}`];
  }, [selectedView]);

  return <Content {...chartPreviewProps} />;
}

export default ViewSelector;
