import create from "zustand";

export const useSubscriptionsStore = create((set, get) => ({
  usersCategories: [],
  setUsersCategories: (usersCategories) => set((state) => ({ usersCategories })),

  usersSpaces: [],
  setUsersSpaces: (usersSpaces) => set((state) => ({ usersSpaces })),

  categories: [],
  setCategories: (categories) => set((state) => ({ categories })),

  categoryBlogCountData: [],
  setCategoryBlogCountData: (categoryBlogCountData) => set((state) => ({ categoryBlogCountData })),

  spaces: [],
  setSpaces: (spaces) => set((state) => ({ spaces })),

  addCategoryToSubscription: (categoryId) => {
    const currentCategories = [...get().usersCategories];
    const newCategories = [categoryId, ...currentCategories];
    return set((state) => ({ usersCategories: [...newCategories] }));
  },

  removeCategoryFromSubscription: (categoryId) => {
    const currentCategories = [...get().usersCategories];
    return set((state) => ({ usersCategories: [...currentCategories.filter((catId) => catId !== categoryId)] }));
  },

  addSpaceToUserSpaces: (space) => {
    const currentSpaces = [...get().usersSpaces];
    const newSpaces = [space, ...currentSpaces];
    return set((state) => ({ usersSpaces: [...newSpaces] }));
  },

  removeSpaceFromUserSpaces: (spaceKey) => {
    const currentSpaces = [...get().usersSpaces];
    return set((state) => ({ usersSpaces: [...currentSpaces.filter((space) => space.key !== spaceKey)] }));
  },
}));
