import React from "react";
import Tag from "@atlaskit/tag";
import styled from "styled-components";
import { useNewsroomStore } from "../newsroom-store";
import dayjs from "dayjs";

const LabelsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 8px;
  row-gap: 8px;
`;

const SLICE_INDEX = 15;

function TagProvider({ hasResults }) {
  const { getter, setter } = useNewsroomStore((state) => ({
    getter: {
      spaceFilter: state.spaceFilter,
      labelsFilter: state.labelsFilter,
      authorsFilter: state.authorsFilter,
      dateFilter: state.dateFilter,
    },
    setter: {
      setSpaceFilter: state.setSpaceFilter,
      setLabelsFilter: state.setLabelsFilter,
      setAuthorsFilter: state.setAuthorsFilter,
      setDateFilter: state.setDateFilter,
    },
  }));

  const { spaceFilter, labelsFilter, authorsFilter, dateFilter } = getter;
  const { setSpaceFilter, setLabelsFilter, setAuthorsFilter, setDateFilter } = setter;

  if (!hasResults) {
    return <></>;
  }

  return (
    <LabelsWrapper>
      {!!spaceFilter?.length && (
        <>
          {spaceFilter.map((space) => (
            <Tag
              key={space.spaceKey}
              text={`${space.name?.slice(0, SLICE_INDEX)}${space.name?.length > SLICE_INDEX ? "..." : ""}`}
              onAfterRemoveAction={() =>
                setSpaceFilter([...spaceFilter].filter(({ spaceKey }) => spaceKey !== space.spaceKey))
              }
            />
          ))}
        </>
      )}

      {!!labelsFilter?.length && (
        <>
          {labelsFilter.map((label, idx) => (
            <Tag
              key={idx}
              text={`${label?.slice(0, SLICE_INDEX)}${label?.length > SLICE_INDEX ? "..." : ""}`}
              onAfterRemoveAction={() =>
                setLabelsFilter([...labelsFilter].filter((labelFilter) => labelFilter !== label))
              }
            />
          ))}
        </>
      )}

      {!!authorsFilter?.length && (
        <>
          {authorsFilter.map(({ id, name }, idx) => (
            <Tag
              key={idx}
              text={`${name?.slice(0, SLICE_INDEX)}${name?.length > SLICE_INDEX ? "..." : ""}`}
              onAfterRemoveAction={() =>
                setAuthorsFilter([...authorsFilter].filter(({ id: accountId }) => accountId !== id))
              }
            />
          ))}
        </>
      )}

      {!!dateFilter?.start && (
        <Tag
          text={`From ${dayjs.unix(dateFilter.start).format("DD/MM/YYYY")}`}
          onAfterRemoveAction={() => setDateFilter({ start: undefined, end: dateFilter.end })}
        />
      )}

      {!!dateFilter?.end && (
        <Tag
          text={`To ${dayjs.unix(dateFilter.end).format("DD/MM/YYYY")}`}
          onAfterRemoveAction={() => setDateFilter({ start: dateFilter.start, end: undefined })}
        />
      )}
    </LabelsWrapper>
  );
}

export default TagProvider;
