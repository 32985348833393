import React from "react";
import { Panel, Wrapper } from "./styled";
import styled from "styled-components";
import { MediumLine } from "./styled";

const Container = styled.div`
  column-gap: 25px;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
`;

const BackgroundWithOverlay = styled.div`
  border-radius: 4px;
  background: ${({ imageUrl }) => `url("${imageUrl}")`};
  background-size: cover;

  position: relative;
  z-index: 105;

  height: 90px;
  width: 100%;

  overflow: hidden;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #1d1d1d;
    opacity: 0.4;
    z-index: 106;
  }
`;

const PlayButtonWrapper = styled.div`
  position: absolute;
  top: calc(50% - 8px);
  left: calc(50% - 7.5px);

  z-index: 107;

  display: grid;
  place-content: center;
`;

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
`;

const ListItemWrapper = styled.div`
  display: grid;
  grid-template-columns: 90px auto;
  align-items: center;
  column-gap: 16px;
`;

const PlayButton = () => (
  <svg width="15" height="16" viewBox="0 0 15 16" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.64383 15.5219C1.21083 16.3639 0.0488281 15.7019 0.0488281 14.0399V1.96289C0.0488281 0.299891 1.20683 -0.365109 2.64383 0.478891L13.7268 6.98889C14.6778 7.54789 14.6848 8.44889 13.7268 9.01189L2.64383 15.5219Z"
      fill="white"
    />
  </svg>
);

export const ListVideoYT = () => {
  return (
    <Wrapper>
      <Panel>
        <ListWrapper>
          <ListItemWrapper>
            <BackgroundWithOverlay
              style={{ height: "50px" }}
              imageUrl="https://images.unsplash.com/photo-1535957998253-26ae1ef29506?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=640&h=480&q=80"
            >
              <PlayButtonWrapper>
                <PlayButton />
              </PlayButtonWrapper>
            </BackgroundWithOverlay>
            <MediumLine width={74} bg="#DFE1E6" />
          </ListItemWrapper>

          <ListItemWrapper>
            <BackgroundWithOverlay
              style={{ height: "50px" }}
              imageUrl="https://images.unsplash.com/photo-1485871981521-5b1fd3805eee?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=640&h=480&q=80"
            >
              <PlayButtonWrapper>
                <PlayButton />
              </PlayButtonWrapper>
            </BackgroundWithOverlay>
            <MediumLine width={74} bg="#DFE1E6" />
          </ListItemWrapper>

          <ListItemWrapper>
            <BackgroundWithOverlay
              style={{ height: "50px" }}
              imageUrl="https://images.unsplash.com/photo-1522071820081-009f0129c71c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=640&h=480&q=80"
            >
              <PlayButtonWrapper>
                <PlayButton />
              </PlayButtonWrapper>
            </BackgroundWithOverlay>
            <MediumLine width={74} bg="#DFE1E6" />
          </ListItemWrapper>
        </ListWrapper>
      </Panel>
    </Wrapper>
  );
};

function VideoYT() {
  return (
    <Wrapper>
      <Container>
        <BackgroundWithOverlay imageUrl="https://images.unsplash.com/photo-1535957998253-26ae1ef29506?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=640&h=480&q=80">
          <PlayButtonWrapper>
            <PlayButton />
          </PlayButtonWrapper>
        </BackgroundWithOverlay>

        <BackgroundWithOverlay imageUrl="https://images.unsplash.com/photo-1485871981521-5b1fd3805eee?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=640&h=480&q=80">
          <PlayButtonWrapper>
            <PlayButton />
          </PlayButtonWrapper>
        </BackgroundWithOverlay>

        <BackgroundWithOverlay imageUrl="https://images.unsplash.com/photo-1522071820081-009f0129c71c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=640&h=480&q=80">
          <PlayButtonWrapper>
            <PlayButton />
          </PlayButtonWrapper>
        </BackgroundWithOverlay>
      </Container>
    </Wrapper>
  );
}

export default VideoYT;
