import React, { useState, useEffect } from "react";
import DefaultSortPicker from "../../../filter/DefaultSortPicker";
import DisableDynamicFilterToggle, { LoadingTypes } from "../../../filter/DynamicFilterToggle";
import InputFilter from "../../../filter/InputFilter";
import LabelMultiSelect from "../../../filter/LabelMultiSelect";
import OrderButton from "../../../filter/OrderButton";
import PeoplePicker from "../../../filter/PeoplePicker";
import SelectWithCustomOptions from "../../../filter/SelectWithCustomOptions";
import SpaceMultiSelect from "../../../filter/SpaceMultiSelect";
import { FieldContainer, HelperMessage, Label, Divider } from "../../../filter/styled";
import { NewsTypes } from "../../types/news/News";
import BlogPicker from "../../../filter/BlogPicker";
import ManualBlogsList from "../../../filter/ManualBlogsList";
import TextInputFilter from "../../../filter/TextInputFilter";
import ElementsToggle from "../../../filter/ElementsToggle";
import SingleTileColorPicker from "../../../../../../../Shared/Components/SingleTileColorPicker";
import { TabTypes } from "../../tabTypes";
import SizeButtons from "../../../../../../../Shared/Components/SizeButtons";
import { DesignSidebarWrapper, PanelEditorWrapper } from "./styled";
import { Ordering } from "../../../../Dashboard/filter/sorting-types";
import { sizeType } from "../../../../../../../Shared/Components/size-options";
import { PaginationOptions } from "../../../../Shared/PanelsPagination/pagination-options";
import { PanelsPaginationTypes } from "../../../../Shared/PanelsPagination/panels-pagination-types";
import SimpleToggle from "../../../filter/SimpleToggle";

export const NewsPropertyTypes = {
  VIEW: "view",
  LOADING_TYPE: "loadingType",
  LABELS: "labels",
  SPACES: "spaces",
  CONTRIBUTORS: "contributors",
  NUMBER_OF_ITEMS: "maxNumberOfItems",
  PAGINATION: "pagination",
  SORT: "sortBy",
  ORDERING: "ordering",
  MANUALLY_PICKED: "manuallyPicked",
  ACTIVE_ELEMENTS: "activeElements",
  LABELS_BG_COLOR: "labelsBackground",
  TEAM_LABELS_BG_COLOR: "teamLabelsBackground",
  PIC_SIZE: "coverPictureSize",
  PIC_BORDER_RADIUS: "coverPictureBorderRadius",
  PADDING: "padding",
  DISPLAY_TITLE: "displayTitle",
  DISPLAY_BOX: "displayBox",
};

export const getNewsElementsOptions = (view) => {
  const options = [
    { label: "Image", value: "image" },
    { label: "Author", value: "author" },
    { label: "Label", value: "label" },
    { label: "Space", value: "space" },
    { label: "Description", value: "description" },
    { label: "Date", value: "date" },
    { label: "Comments", value: "comments" },
    { label: "Thumbs Up (Likes)", value: "likes" },
    { label: "Categories", value: "categories" },
  ];

  return options.filter(({ value }) => view === NewsTypes.LIST || value !== "image");
};

export const NewsElementsOptionsNoSocial = [
  { label: "Author", value: "author" },
  { label: "Label", value: "label" },
  { label: "Space", value: "space" },
  { label: "Description", value: "description" },
  { label: "Date", value: "date" },
  { label: "Categories", value: "categories" },
];

export const SideNewsElementsOptions = [
  {
    label: "Author",
    value: "author",
  },
  {
    label: "Space",
    value: "space",
  },
  { label: "Date", value: "date" },
];

export const ViewOptions = [
  { label: "Grid", value: NewsTypes.GRID },
  { label: "Magazine", value: NewsTypes.MAGAZINE },
  { label: "List", value: NewsTypes.LIST },
];

const DynamicSidebar = ({ panelId, data, updatePanelDataProperty }) => {
  const [sortOrder, setSortOrder] = useState(data.ordering || Ordering.ASCENDING);

  const handleSortOrderUpdate = (newSortOrder) => {
    setSortOrder(newSortOrder);
    updatePanelDataProperty(panelId, NewsPropertyTypes.ORDERING, newSortOrder);
  };

  return (
    <>
      <LabelMultiSelect
        label="Labels"
        placeholder="Filter by labels"
        initialLabels={data.labels}
        onUpdate={(labels) => {
          updatePanelDataProperty(panelId, NewsPropertyTypes.LABELS, labels);
        }}
      />
      <SpaceMultiSelect
        initialSpaces={data.spaces}
        isDynamic={true}
        onUpdate={(spaces) => updatePanelDataProperty(panelId, NewsPropertyTypes.SPACES, spaces)}
      />
      <PeoplePicker
        isMulti
        initialAccountIDs={data.contributors}
        label="Contributors"
        placeholder="Filter by contributors"
        onUpdate={(users) => updatePanelDataProperty(panelId, NewsPropertyTypes.CONTRIBUTORS, users)}
      />
      {data.loadingType === LoadingTypes.DYNAMIC && (
        <>
          <DefaultSortPicker
            initialValue={data.sortBy}
            onUpdate={(sorting) => updatePanelDataProperty(panelId, NewsPropertyTypes.SORT, sorting)}
          />
          <HelperMessage>
            <div style={{ marginRight: "4px", color: "#6b778c" }}> Order by </div>
            <OrderButton sortOrder={sortOrder} onUpdate={handleSortOrderUpdate} />
          </HelperMessage>
        </>
      )}
    </>
  );
};

const ManualSidebar = ({ panelId, data, updatePanelDataProperty }) => {
  const [pickerKey, setPickerKey] = useState(Math.random().toString(36).substring(7));

  const addNewContentManually = (newItem) => {
    const newManuallyPicked = [...data.manuallyPicked, newItem];
    updatePanelDataProperty(panelId, NewsPropertyTypes.MANUALLY_PICKED, newManuallyPicked);
  };

  const removeContentManually = (itemId) => {
    const newManuallyPicked = data.manuallyPicked.filter((item) => item.id !== itemId);
    updatePanelDataProperty(panelId, NewsPropertyTypes.MANUALLY_PICKED, newManuallyPicked);
  };

  function generateId() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
      const r = (Math.random() * 16) | 0,
        v = c === "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  useEffect(() => {
    setPickerKey(Math.random().toString(36).substring(7));
  }, [data.manuallyPicked]);
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <BlogPicker
        manuallyPicked={data.manuallyPicked}
        style={{ paddingBottom: "60px" }}
        key={pickerKey}
        onUpdate={(selectedBlog) => {
          if (data.manuallyPicked.some((item) => item.contentId === selectedBlog.contentId)) return;
          if (data.manuallyPicked.length > 0) {
            const currentLastPicked = data.manuallyPicked[data.manuallyPicked.length - 1];
            const position = currentLastPicked.position + 1;
            addNewContentManually({ id: generateId(), position, contentId: selectedBlog.contentId });
            setPickerKey(Math.random().toString(36).substring(7));
            return;
          }
          addNewContentManually({ id: generateId(), position: 0, contentId: selectedBlog.contentId });
          setPickerKey(Math.random().toString(36).substring(7));
        }}
        label=""
        placeholder="Select blog(s)"
      />
      <ManualBlogsList
        manuallyPicked={data.manuallyPicked}
        onRemove={(id) => removeContentManually(id)}
        updateOrder={(items) => {
          const newManuallyPicked = items.map(({ id, position, contentId }) => ({
            id,
            position,
            contentId,
          }));

          updatePanelDataProperty(panelId, NewsPropertyTypes.MANUALLY_PICKED, newManuallyPicked);
        }}
      />
    </div>
  );
};

const LayoutSidebar = ({ panelId, data, version, updatePanelDataProperty, panelTitle, updatePanelTitle }) => {
  const updateElements = (value) => {
    const newElements = !!data.activeElements.find((el) => el === value)
      ? data.activeElements.filter((el) => el !== value)
      : [...data.activeElements, value];
    updatePanelDataProperty(panelId, NewsPropertyTypes.ACTIVE_ELEMENTS, newElements, true);
  };

  const isSideNews = !!data?.isSideNews;

  return (
    <>
      <TextInputFilter label="Title" value={panelTitle} onUpdate={(value) => updatePanelTitle(panelId, value)} />
      <Divider />
      {!isSideNews && (
        <SelectWithCustomOptions
          initialValue={data.view}
          options={ViewOptions}
          label="View"
          onUpdate={(newView) => updatePanelDataProperty(panelId, NewsPropertyTypes.VIEW, newView)}
        />
      )}

      {data.loadingType === LoadingTypes.DYNAMIC && (
        <>
          <SelectWithCustomOptions
            initialValue={
              data.pagination === PanelsPaginationTypes.INFINITE ? PanelsPaginationTypes.SHOW_MORE : data.pagination
            }
            options={PaginationOptions}
            label="Pagination"
            onUpdate={(newValue) => updatePanelDataProperty(panelId, NewsPropertyTypes.PAGINATION, newValue)}
          />
          {data.view !== NewsTypes.MAGAZINE && (
            <InputFilter
              numberField
              minLength={1}
              initialValue={data.maxNumberOfItems}
              label="Items per page"
              placeholder="Limit the number of pages"
              onUpdate={(value) => updatePanelDataProperty(panelId, NewsPropertyTypes.NUMBER_OF_ITEMS, value)}
            />
          )}
        </>
      )}
      <Divider />
      <ElementsToggle
        label="Elements to display"
        elements={version ? data.activeElements : ["image", ...(data.activeElements || [])]}
        options={isSideNews ? SideNewsElementsOptions : getNewsElementsOptions(data.view)}
        updateElements={updateElements}
      />
    </>
  );
};

const FilterSidebar = ({ panelId, data, updatePanelDataProperty }) => {
  return (
    <>
      <DisableDynamicFilterToggle
        initialValue={data.loadingType}
        onUpdate={(newLoadingType) => updatePanelDataProperty(panelId, NewsPropertyTypes.LOADING_TYPE, newLoadingType)}
      />

      {data.loadingType === LoadingTypes.DYNAMIC && (
        <DynamicSidebar panelId={panelId} data={data} updatePanelDataProperty={updatePanelDataProperty} />
      )}

      {data.loadingType === LoadingTypes.MANUAL && (
        <ManualSidebar panelId={panelId} data={data} updatePanelDataProperty={updatePanelDataProperty} />
      )}
    </>
  );
};

const DesignSidebar = ({ panelId, data, type, updatePanelDataProperty }) => {
  const isSideNews = !!data?.isSideNews;
  const isDisabledPadding = [NewsTypes.LIST].includes(data.view);
  const displayTitleFallback = data.displayTitle ?? true;

  return (
    <DesignSidebarWrapper>
      <div>
        <>
          <FieldContainer>
            <Label>Display title</Label>
            <SimpleToggle
              label="Display title"
              value={displayTitleFallback}
              setValue={(value) => updatePanelDataProperty(panelId, NewsPropertyTypes.DISPLAY_TITLE, value)}
              customStyles={{ padding: "10px 0px 8px 10px" }}
            />
            <SimpleToggle
              label="Display box"
              value={data?.displayBox ?? true}
              setValue={(value) => updatePanelDataProperty(panelId, NewsPropertyTypes.DISPLAY_BOX, value)}
              customStyles={{ padding: "10px 0px 8px 10px" }}
            />
          </FieldContainer>

          <Divider />

          <FieldContainer>
            <Label>Color</Label>
            <SingleTileColorPicker
              label="Space and Label"
              value={data.view !== NewsTypes.LIST ? data.labelsBackground : data?.teamLabelsBackground || "#42526E"}
              setValue={(value) =>
                updatePanelDataProperty(
                  panelId,
                  data.view !== NewsTypes.LIST
                    ? NewsPropertyTypes.LABELS_BG_COLOR
                    : NewsPropertyTypes.TEAM_LABELS_BG_COLOR,
                  value,
                  true,
                )
              }
            />
          </FieldContainer>
          {!isSideNews && <Divider />}
          {data.view === NewsTypes.GRID && (
            <FieldContainer>
              <SizeButtons
                label="Image size"
                types={{ panel: "news", size: sizeType.COVER_PICTURE_SIZE }}
                input={{ min: 120, max: 280, step: 10, value: data.coverPictureSize }}
                setValue={(value) => updatePanelDataProperty(panelId, NewsPropertyTypes.PIC_SIZE, value, true)}
              />
            </FieldContainer>
          )}

          {!isDisabledPadding && !isSideNews && (
            <SizeButtons
              label="Padding"
              types={{ panel: type, size: sizeType.PADDING }}
              input={{ min: data.view === NewsTypes.MAGAZINE ? 5 : 10, max: 40, step: 1, value: data.padding }}
              setValue={(value) => updatePanelDataProperty(panelId, NewsPropertyTypes.PADDING, value, true)}
            />
          )}
        </>
      </div>
    </DesignSidebarWrapper>
  );
};

function NewsEditor({ panel, selectedTab, updatePanelDataProperty, updatePanelTitle }) {
  const { data, type, version } = panel;

  return (
    <PanelEditorWrapper>
      {selectedTab === TabTypes.LAYOUT && (
        <LayoutSidebar
          version={version}
          panelId={panel.id}
          data={data}
          updatePanelDataProperty={updatePanelDataProperty}
          panelTitle={panel.panelTitle}
          updatePanelTitle={updatePanelTitle}
        />
      )}
      {selectedTab === TabTypes.CONTENT && (
        <FilterSidebar panelId={panel.id} data={data} updatePanelDataProperty={updatePanelDataProperty} />
      )}
      {selectedTab === TabTypes.DESIGN && (
        <DesignSidebar panelId={panel.id} data={data} type={type} updatePanelDataProperty={updatePanelDataProperty} />
      )}
    </PanelEditorWrapper>
  );
}

export default NewsEditor;
