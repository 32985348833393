import React, { useState, useEffect } from "react";
import styled from "styled-components";
import InfoIcon from "@atlaskit/icon/glyph/info";
import TextInputFilter from "../../../filter/TextInputFilter";
import DragHandlerIcon from "@atlaskit/icon/glyph/drag-handler";
import { useDrag, useDrop, DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import InputFilter from "../../../filter/InputFilter";
import { TabTypes } from "../../tabTypes";
import SelectWithCustomOptions from "../../../filter/SelectWithCustomOptions";
import { VideosTypes } from "../../types/Videos";
import { Divider, FieldContainer, Label } from "../../../filter/styled";
import SimpleToggle from "../../../filter/SimpleToggle";
import { PanelPositioning } from "../../panelTypes";
import { PanelsPaginationTypes } from "../../../../Shared/PanelsPagination/panels-pagination-types";
import { useTheme } from "styled-components";
import TrashIcon from "@atlaskit/icon/glyph/trash";
import { token } from "@atlaskit/tokens";

const PanelEditorWrapper = styled.div`
  height: calc(100% - 50px);
`;

const AddLinkWrapper = styled.div`
  margin-top: 15px;
  display: flex;
  align-items: center;
  column-gap: 8px;
  cursor: pointer;
`;

const AddLinkText = styled.button`
  all: unset;
  padding: 6px 12px;
  width: calc(100% - 24px);
  color: ${({ theme }) => theme.shared.sidebar.button.text};
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.shared.sidebar.button.background};
  transition: background-color 150ms linear;

  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.shared.sidebar.button.hover};
  }
`;

const VideoLinksTitle = styled.div`
  width: 100%;
  display: flex;
  margin-top: 15px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #6b778c;
`;

const FeaturedVideoStyle = styled.div`
  font-size: 14px;
  border: 2px solid;
  border-color: #ebecf0;
  border-radius: 3px;
  transition: background-color 150ms linear;
  white-space: nowrap;
  overflow: hidden;
  margin-top: 5px;
  padding: 8px;
  user-select: text;
  cursor: text;
  overflow-x: scroll;
  color: ${({ theme }) => theme.shared.form.input.text};

  ::-webkit-scrollbar {
    display: none;
  }

  scrollbar-width: none;
  -ms-overflow-style: none;

  &:hover {
    background-color: rgba(9, 30, 66, 0.1);
  }
`;

const LinkWrapper = styled.div`
  display: grid;
  grid-template-columns: 24px auto 24px;
  column-gap: 15px;
  align-items: flex-end;

  ${({ isHoveringOver, orderDirection }) => {
    if (!isHoveringOver) {
      return null;
    }
    return orderDirection === "top-bottom" ? `border-bottom: 1px solid #aaa;` : `border-top: 1px solid #aaa;`;
  }};

  .actions {
    visibility: hidden;
    height: 40%;
  }
  &:hover {
    .actions {
      visibility: visible;
    }
  }
`;

const IconContainer = styled.div`
  display: grid;
  place-content: center;
  cursor: pointer;
  margin-bottom: 9px;
`;

const InfoPanel = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
  padding: 8px;
  background-color: ${({ theme }) => theme.shared.form.button.background.active};
  color: ${({ theme }) => theme.notification.title};
  border-radius: 3px;
  margin-top: 15px;
  font-size: 14px;
`;

export const InfoIconDark = ({ size = "24" }) => (
  <svg width={size} height={size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="24" cy="24" r="19" fill="#082145" />
    <path
      d="M24 32C24.4533 32 24.8336 31.8464 25.1408 31.5392C25.4469 31.2331 25.6 30.8533 25.6 30.4V23.96C25.6 23.5067 25.4469 23.1333 25.1408 22.84C24.8336 22.5467 24.4533 22.4 24 22.4C23.5467 22.4 23.1669 22.5531 22.8608 22.8592C22.5536 23.1664 22.4 23.5467 22.4 24V30.44C22.4 30.8933 22.5536 31.2667 22.8608 31.56C23.1669 31.8533 23.5467 32 24 32ZM24 19.2C24.4533 19.2 24.8336 19.0464 25.1408 18.7392C25.4469 18.4331 25.6 18.0533 25.6 17.6C25.6 17.1467 25.4469 16.7664 25.1408 16.4592C24.8336 16.1531 24.4533 16 24 16C23.5467 16 23.1669 16.1531 22.8608 16.4592C22.5536 16.7664 22.4 17.1467 22.4 17.6C22.4 18.0533 22.5536 18.4331 22.8608 18.7392C23.1669 19.0464 23.5467 19.2 24 19.2ZM24 40C21.7867 40 19.7067 39.5797 17.76 38.7392C15.8133 37.8997 14.12 36.76 12.68 35.32C11.24 33.88 10.1003 32.1867 9.2608 30.24C8.42027 28.2933 8 26.2133 8 24C8 21.7867 8.42027 19.7067 9.2608 17.76C10.1003 15.8133 11.24 14.12 12.68 12.68C14.12 11.24 15.8133 10.0997 17.76 9.2592C19.7067 8.41973 21.7867 8 24 8C26.2133 8 28.2933 8.41973 30.24 9.2592C32.1867 10.0997 33.88 11.24 35.32 12.68C36.76 14.12 37.8997 15.8133 38.7392 17.76C39.5797 19.7067 40 21.7867 40 24C40 26.2133 39.5797 28.2933 38.7392 30.24C37.8997 32.1867 36.76 33.88 35.32 35.32C33.88 36.76 32.1867 37.8997 30.24 38.7392C28.2933 39.5797 26.2133 40 24 40Z"
      fill="#85B8FF"
    />
  </svg>
);

const VideosPropertyKeys = {
  LINKS: "links",
  MAX_ITEMS: "maxNumberOfItems",
  VIEW: "view",
  PAGINATION: "pagination",
  DISPLAY_TITLE: "displayTitle",
  DISPLAY_BOX: "displayBox",
  PLAYER_CONTROLS: "playerControls",
  MUTE: "mute",
  LOOP: "loop",
  AUTOPLAY: "autoplay",
};

const VideosEditorOption = ({ singleVideoData, reorderPanels, data, panel, index, updatePanelDataProperty }) => {
  const handleUpdateVideoLink = (value, itemPosition) => {
    const copyOfLinks = [...data.links];
    copyOfLinks[itemPosition] = { url: value };
    updatePanelDataProperty(panel.id, VideosPropertyKeys.LINKS, copyOfLinks);
  };

  const handleRemoveVideoLink = (itemPosition) => {
    const copyOfLinks = [...data.links];
    copyOfLinks.splice(itemPosition, 1);
    updatePanelDataProperty(panel.id, VideosPropertyKeys.LINKS, copyOfLinks);
  };

  const [{ isOver }, drop] = useDrop(() => ({
    accept: ["video-option"],
    drop: () => ({ id: index, ...singleVideoData }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  }));

  const [{ draggedItem }, drag] = useDrag({
    type: "video-option",
    item: () => {
      return { id: index, type: "video-option", ...singleVideoData };
    },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (item && dropResult) {
        reorderPanels(item.id, dropResult.id);
      }
    },
    collect: (monitor) => ({
      draggedItem: monitor.getItem(),
    }),
  });

  const draggedItemPosition = draggedItem?.order ?? 0;
  const potentialTargetPosition = isOver ? singleVideoData?.order : 0;

  return (
    <LinkWrapper
      ref={drop}
      isHoveringOver={isOver}
      orderDirection={draggedItemPosition < potentialTargetPosition ? "top-bottom" : "bottom-top"}
    >
      <IconContainer style={{ cursor: "grab" }} ref={drag}>
        <DragHandlerIcon primaryColor="#42526E" />
      </IconContainer>

      <TextInputFilter
        value={singleVideoData.url}
        placeholder="Insert link here"
        onUpdate={(v) => handleUpdateVideoLink(v, index)}
      />

      <IconContainer className="actions" style={{ cursor: "pointer" }} onClick={() => handleRemoveVideoLink(index)}>
        <TrashIcon primaryColor={token("color.icon")} size="small" />
      </IconContainer>
    </LinkWrapper>
  );
};

const ContentSidebar = ({ panel, updatePanelDataProperty, updatePanelTitle }) => {
  const theme = useTheme();
  const { data } = panel;

  const [featuredVideo, setFeaturedVideo] = useState(data.links.length !== 0 ? data.links[0].url : "");

  useEffect(() => {
    if (data.links.length) {
      setFeaturedVideo(data.links[0].url);
    } else {
      setFeaturedVideo("");
    }
  }, [data.links]);

  const resetOrder = (list) => {
    const resetList = list.map((c, index) => {
      const slide = c;
      slide.order = index + 1;
      return slide;
    });
    return resetList;
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return resetOrder(result);
  };

  const reorderPanels = (sourceId, targetId) => {
    if (sourceId === targetId) {
      return;
    }
    const copyOfLinks = [...data.links];
    const sourceIndex = sourceId;
    const targetIndex = targetId;
    const items = reorder(copyOfLinks, sourceIndex, targetIndex);
    updatePanelDataProperty(panel.id, VideosPropertyKeys.LINKS, items);
  };

  const handleChange = (value) => {
    if (value > 0 && value <= data.links.length) {
      updatePanelDataProperty(panel.id, VideosPropertyKeys.MAX_ITEMS, value);
    }
  };

  return (
    <div>
      <InfoPanel style={{ alignItems: "center" }}>
        {theme.global.name === "light" ? <InfoIcon size="large" primaryColor="#0052cc" /> : <InfoIconDark />}
        Only YouTube videos are supported.
      </InfoPanel>

      {panel.position === PanelPositioning.MAIN &&
        (data.view === VideosTypes.MAGAZINE || data.view == VideosTypes.ONE_ITEM) &&
        !!data?.links?.length &&
        featuredVideo !== "" && (
          <>
            <VideoLinksTitle>Featured Video</VideoLinksTitle>
            <FeaturedVideoStyle theme={theme}>{featuredVideo}</FeaturedVideoStyle>
          </>
        )}

      {panel.position === PanelPositioning.SIDE && (
        <>
          <TextInputFilter
            value={panel.panelTitle}
            label="Title"
            onUpdate={(value) => updatePanelTitle(panel.id, value)}
          />
          <InputFilter
            numberField
            minLength={1}
            initialValue={!data.maxNumberOfItems ? data.links.length : data.maxNumberOfItems}
            maxLength={data.links.length}
            label="Items per page"
            placeholder="Limit the number of videos"
            onUpdate={(value) => handleChange(value)}
          />
        </>
      )}

      {!!data?.links?.length && (
        <>
          <VideoLinksTitle>Video Links</VideoLinksTitle>
          <DndProvider backend={HTML5Backend}>
            {data.links.map((link, index) => (
              <VideosEditorOption
                data={data}
                singleVideoData={link}
                key={index}
                index={index}
                panel={panel}
                updatePanelDataProperty={updatePanelDataProperty}
                reorderPanels={reorderPanels}
              />
            ))}
          </DndProvider>
        </>
      )}
      <Divider />
      <AddLinkWrapper
        onClick={() => updatePanelDataProperty(panel.id, VideosPropertyKeys.LINKS, [...data.links, { url: "" }])}
      >
        <AddLinkText>Add new link</AddLinkText>
      </AddLinkWrapper>
    </div>
  );
};

const LayoutSidebar = ({ panel, updatePanelDataProperty, updatePanelTitle }) => {
  const { data } = panel;

  useEffect(() => {
    if (data.view !== VideosTypes.GRID && data.pagination === PanelsPaginationTypes.ARROWS) {
      updatePanelDataProperty(panel.id, VideosPropertyKeys.PAGINATION, PanelsPaginationTypes.SHOW_MORE_LESS);
    }
  }, [data.view]);

  return (
    <div>
      <TextInputFilter value={panel.panelTitle} label="Title" onUpdate={(v) => updatePanelTitle(panel.id, v)} />
      <Divider />
      <SelectWithCustomOptions
        initialValue={!data.view ? VideosTypes.GRID : data.view}
        options={[
          { label: "Grid", value: VideosTypes.GRID },
          { label: "Magazine", value: VideosTypes.MAGAZINE },
          { label: "Highlight", value: VideosTypes.ONE_ITEM },
        ]}
        label="View"
        onUpdate={(newView) => updatePanelDataProperty(panel.id, VideosPropertyKeys.VIEW, newView)}
      />

      {(!data.view || data.view === VideosTypes.GRID) && (
        <SelectWithCustomOptions
          label="Pagination"
          initialValue={!data.pagination ? PanelsPaginationTypes.SHOW_MORE_LESS : data.pagination}
          options={[
            {
              label: "Inactive",
              value: PanelsPaginationTypes.NONE,
            },
            { label: "Show more button", value: PanelsPaginationTypes.SHOW_MORE_LESS },
            { label: "Previous / Next buttons", value: PanelsPaginationTypes.ARROWS },
          ]}
          onUpdate={(paginationType) =>
            updatePanelDataProperty(panel.id, VideosPropertyKeys.PAGINATION, paginationType)
          }
        />
      )}
      {(data.view === VideosTypes.ONE_ITEM || data.view === VideosTypes.MAGAZINE) && (
        <>
          <SelectWithCustomOptions
            label="Pagination"
            initialValue={
              data.pagination === PanelsPaginationTypes.ARROWS || !data.pagination
                ? PanelsPaginationTypes.SHOW_MORE_LESS
                : data.pagination
            }
            options={[
              {
                label: "Inactive",
                value: PanelsPaginationTypes.NONE,
              },
              { label: "Show more button", value: PanelsPaginationTypes.SHOW_MORE_LESS },
            ]}
            onUpdate={(paginationType) =>
              updatePanelDataProperty(panel.id, VideosPropertyKeys.PAGINATION, paginationType)
            }
          />
          <Divider />
          <FieldContainer>
            <Label>Video options</Label>
            <SimpleToggle
              label="Player controls"
              value={data.playerControls}
              setValue={(value) => updatePanelDataProperty(panel.id, VideosPropertyKeys.PLAYER_CONTROLS, value)}
              customStyles={{ padding: "10px 0px 8px 10px" }}
            />
            <SimpleToggle
              label="Mute"
              value={data.mute}
              setValue={(value) => updatePanelDataProperty(panel.id, VideosPropertyKeys.MUTE, value)}
              customStyles={{ padding: "15px 0px 8px 10px" }}
            />
            <SimpleToggle
              label="Loop"
              value={data.loop}
              setValue={(value) => updatePanelDataProperty(panel.id, VideosPropertyKeys.LOOP, value)}
              customStyles={{ padding: "15px 0px 8px 10px" }}
            />
            <SimpleToggle
              label="Autoplay"
              value={data.autoplay}
              setValue={(value) => updatePanelDataProperty(panel.id, VideosPropertyKeys.AUTOPLAY, value)}
              customStyles={{ padding: "15px 0px 8px 10px" }}
            />
          </FieldContainer>
        </>
      )}
    </div>
  );
};

const DesignSidebar = ({ panel, updatePanelDataProperty, updatePanelTitle }) => {
  const { data } = panel;
  return (
    <>
      <FieldContainer>
        <Label>Panel options</Label>
        <SimpleToggle
          label="Display title"
          value={typeof data.displayTitle === "undefined" ? true : data.displayTitle}
          setValue={(value) => updatePanelDataProperty(panel.id, VideosPropertyKeys.DISPLAY_TITLE, value)}
          customStyles={{ padding: "10px 0px 8px 10px" }}
        />
        <SimpleToggle
          label="Display box"
          value={typeof data.displayBox === "undefined" ? true : data.displayBox}
          setValue={(value) => updatePanelDataProperty(panel.id, VideosPropertyKeys.DISPLAY_BOX, value)}
          customStyles={{ padding: "15px 0px 8px 10px" }}
        />
      </FieldContainer>
    </>
  );
};

function VideosEditor({ panel, selectedTab, isPositionedInMainContent, updatePanelDataProperty, updatePanelTitle }) {
  return (
    <PanelEditorWrapper>
      {selectedTab === TabTypes.LAYOUT && (
        <LayoutSidebar
          panel={panel}
          updatePanelDataProperty={updatePanelDataProperty}
          updatePanelTitle={updatePanelTitle}
        />
      )}
      {(selectedTab === TabTypes.CONTENT || !isPositionedInMainContent) && (
        <ContentSidebar
          panel={panel}
          updatePanelDataProperty={updatePanelDataProperty}
          updatePanelTitle={updatePanelTitle}
        />
      )}
      {selectedTab === TabTypes.DESIGN && (
        <DesignSidebar
          panel={panel}
          updatePanelDataProperty={updatePanelDataProperty}
          updatePanelTitle={updatePanelTitle}
        />
      )}
    </PanelEditorWrapper>
  );
}
export default VideosEditor;
