export const ViewTypes = {
  GRID: "grid",
  LIST: "list",
};

export const SortTypes = {
  NEWEST: "newest",
  OLDEST: "oldest",
};

export const SORT_OPTIONS = [
  { label: "Newest", value: SortTypes.NEWEST },
  { label: "Oldest", value: SortTypes.OLDEST },
];

export const FilterTypes = {
  ALL: "All",
  STARRED: "Starred",
  WATCHING: "Watching",
  COMMUNAL: "Communal",
  PERSONAL: "Personal",
  ARCHIVED: "Archived",

  LATEST_ACTIVITY: "Latest Activity",
  STAR_AND_WATCH: "Starred and Watching",
};
