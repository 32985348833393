import React from "react";
import { useCorporateIdentityStore } from "../../../../../../Settings/General/BrandAndColors/corporate-identity-store";
import Content from "../content/Content";
import { CardWrapper } from "../styled";

function Card({ content, diagram, elementsToDisplay, locale, isPositionedInSide }) {
  const borderRadius = useCorporateIdentityStore((state) => state.borderRadius);

  return (
    <CardWrapper borderRadius={borderRadius}>
      <Content
        diagram={diagram}
        content={content}
        elementsToDisplay={elementsToDisplay}
        locale={locale}
        isPositionedInSide={isPositionedInSide}
      />
    </CardWrapper>
  );
}

export default Card;
