import React, { useEffect, useState } from "react";
import Textfield from "@atlaskit/textfield";
import styled from "styled-components";
import { sizesByPanelType } from "./size-options";
import { convertHexToRGB } from "./Background/convertHexToRgb";

const TextInputWrapper = styled.div`
  div:first-child {
    border-color: ${({ theme }) => theme.shared.form.input.border};
    color: ${({ theme }) => theme.shared.form.input.text};
    background-color: ${({ theme }) => theme.shared.form.input.background};

    &:hover,
    &:focus,
    &:active {
      background-color: ${({ theme }) => theme.shared.form.input.hover};
    }
  }
`;

export const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  gap: 4px;
`;

export const SectionLabel = styled.h1`
  margin: 0;
  font-size: 12px;
  font-weight: 400;
  color: #626f86;
`;

export const ButtonsSectionWrapper = styled.div`
  display: flex;
  place-items: center;
  gap: 15px;

  input[type="number"] {
    -moz-appearance: textfield;
    text-align: center;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

export const Button = styled.button`
  display: grid;
  place-items: center;
  width: 72px;
  height: 40px;
  background: ${({ active, color, theme }) =>
    active ? convertHexToRGB({ color, opacity: 0.1 }) : theme.shared.form.button.background.inactive};
  color: ${({ active, color, theme }) => (active ? color : theme.shared.form.button.text.inactive)};
  border: none;
  font-size: 14px;
  line-height: 17px;
  cursor: ${({ isDisabled }) => (isDisabled ? "not-allowed" : "pointer")};
  border-radius: 3px;
  transition: background 0.25s ease;
`;

const SizeButtons = ({
  label,
  types,
  customOptions = undefined,
  input,
  setValue,
  isDisabled = false,
  customStyles = {},
  customSectionStyles = {},
  hasInputField = true,
}) => {
  const [inputValue, setInputValue] = useState(input.value);

  const primaryColor = "#0065ff";
  const slicedPrimaryColor = primaryColor.length > 7 ? primaryColor.slice(0, -2) : primaryColor;

  useEffect(() => setInputValue(input.value), [input.value]);

  const handleOnChange = (e) => {
    const value = Number(e.target.value);

    if (value >= input.max) {
      setValue(input.max);
    } else if (value >= input.min) {
      setValue(value);
    } else {
      setInputValue(value);
    }
  };

  const handleOnBlur = () => {
    if (inputValue < input.value) {
      setValue(input.min);
    }
  };

  return (
    <SectionWrapper style={{ ...customStyles }}>
      {label && <SectionLabel>{label}</SectionLabel>}
      <ButtonsSectionWrapper style={{ ...customSectionStyles }}>
        <ButtonsWrapper style={{ opacity: isDisabled ? "0.7" : "1" }}>
          {customOptions ? (
            <>
              {customOptions.map((option) => (
                <Button
                  key={option?.key || option.label}
                  color={slicedPrimaryColor}
                  onClick={() => {
                    if (!isDisabled) {
                      setValue(option.value);
                    }
                  }}
                  active={inputValue === option.value}
                  isDisabled={isDisabled}
                >
                  {option.label}
                </Button>
              ))}
            </>
          ) : (
            <>
              {sizesByPanelType[types.panel][types.size].map((size) => (
                <Button
                  key={size.label}
                  color={slicedPrimaryColor}
                  onClick={() => {
                    if (!isDisabled) {
                      setValue(size.value);
                    }
                  }}
                  active={inputValue === size.value}
                  isDisabled={isDisabled}
                >
                  {size.label}
                </Button>
              ))}
            </>
          )}
        </ButtonsWrapper>
        {hasInputField && (
          <TextInputWrapper>
            <Textfield
              type="number"
              value={String(inputValue)}
              min={input.min}
              max={input.max}
              isDisabled={isDisabled}
              onChange={(e) => handleOnChange(e)}
              onBlur={handleOnBlur}
            />
          </TextInputWrapper>
        )}
      </ButtonsSectionWrapper>
    </SectionWrapper>
  );
};

export default SizeButtons;
