import styled from "styled-components";

export const LinkWrapper = styled.a`
  all: unset;

  & > span:hover {
    cursor: pointer;
  }
`;

export const PeopleContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`;

export const PersonWrapper = styled.div`
  display: grid;
  grid-template-columns: 56px auto;
  column-gap: 16px;
  align-items: center;
`;

export const PersonImage = styled.img`
  box-sizing: border-box;
  width: 52px;
  height: 52px;
  border-radius: 50%;
  object-fit: cover;
  user-select: none;
`;

export const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
`;

export const Name = styled.span`
  font-weight: 500;
  font-size: 15px;
  line-height: 19px;
  color: ${({ theme }) => theme.shared.card.title};
`;

export const ListName = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: ${({ theme }) => theme.shared.card.title};

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const CardLinkWrapper = styled.a`
  all: unset;
`;

export const TitleLabel = styled.span`
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme }) => theme.shared.card.subtitle};
  height: 28px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const FooterLabel = styled.span`
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: ${({ isBlue, theme }) => (isBlue ? theme.global.text.blue : theme.shared.card.footer)};
  flex: 1;
  display: flex;
  align-items: flex-end;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const GridPeopleContainer = styled.div`
  display: grid;
  grid-template-columns: ${({ numberOfItems }) => `repeat(${numberOfItems}, minmax(0, 1fr))`};
  grid-template-rows: 1fr;
  grid-gap: 24px;
`;

export const Card = styled.div`
  background: ${({ theme }) => theme.shared.card.background};
  box-shadow: 0px 0px 4px rgba(85, 85, 85, 0.06);
  border-radius: ${({ borderRadius }) => `${borderRadius}`};
  overflow: hidden;
  border: 1px solid ${({ theme }) => theme.shared.card.border.box};

  cursor: pointer;
`;

export const CardBackground = styled.div`
  width: 100%;
  background: ${({ background }) => background};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 80px;
`;

export const CardInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  align-items: center;
  justify-content: center;
  padding: 0px 16px 16px 16px;
  margin-top: ${({ imageHeight }) => `-${imageHeight / 2}px`};
  text-align: center;
`;

export const Image = styled.img`
  width: ${({ imageSize }) => `${imageSize || 80}px`};
  height: ${({ imageSize }) => `${imageSize || 80}px`};
  box-sizing: border-box;
  border: 4px solid ${({ theme }) => theme.shared.card.border.avatar};
  border-radius: 50%;
  object-fit: cover;
  justify-self: center;
`;

export const GridCenter = styled.div`
  height: 32px;
  display: grid;
  place-content: end;
`;

export const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 3px;
  box-shadow:
    rgba(9, 30, 66, 0.2) 0px 1px 3px,
    rgba(9, 30, 66, 0.1) 0px 0px 1px;

  & > div {
    padding: 10px 18px;

    &:not(:last-child) {
      border-bottom: 1px solid #f0f0f0;
    }
  }
`;

export const ListItem = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
`;

export const ListUser = styled.div`
  display: flex;
  place-items: center;
  gap: 18px;
`;

export const ListLabel = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 12px;
  color: ${({ color }) => color || "#6B778C"};
`;

export const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 25px;

  & > div:first-child {
    flex: 1;
  }
`;

export const IconWrapper = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 3px;
  margin-top: 25px;
  background-color: #f4f5f7;
  display: grid;
  place-items: center;
  transition:
    background-color 200ms ease-in-out,
    border-color 200ms ease-in-out;
  cursor: pointer;

  &:hover {
    background-color: #ebecf0;
  }
`;

export const SelectButtonWrapper = styled.div`
  display: flex;
  gap: 16px;

  & > div:first-of-type {
    flex: 1;
  }
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;

  border-bottom: 1px solid rgba(223 225 230 / 50%);

  ${({ isHoveringOver, orderDirection }) => {
    if (!isHoveringOver) {
      return null;
    }

    return orderDirection === "top-bottom" ? `border-bottom: 1px solid #aaa;` : `border-top: 1px solid #aaa;`;
  }};

  padding: 12px 0px;

  font-size: 14px;
  font-weight: 500;
  color: #172b4d;
`;

export const IconContainer = styled.div`
  display: grid;
  place-content: center;
  cursor: grab;

  svg {
    color: #8993a4 !important;
    fill: #8993a4 !important;
  }

  &:hover {
    opacity: 0.9;
  }
`;

export const RemoveContainer = styled.div`
  cursor: pointer;
  width: 24px;
  height: 24px;
  display: grid;
  align-items: center;
  justify-content: flex-end;

  flex: 1;
  justify-self: flex-end;

  svg {
    display: none;
  }

  &:hover {
    opacity: 0.9;

    svg {
      display: block;
    }
  }
`;

export const Container = styled.div`
  display: grid;
  place-content: center;
  justify-items: center;
  text-align: center;
  height: 100%;
  width: 100%;
`;

export const Title = styled.p`
  font-weight: 590;
  font-size: 20px;
  line-height: 23.87px;
  color: #2684ff;
  margin-top: 30px;
  margin-bottom: 12px;
`;

export const Description = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #44526d;

  .create-link {
    cursor: pointer;
    color: #2684ff;
  }
`;
