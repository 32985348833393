import React from "react";

import Toggle from "@atlaskit/toggle";
import SectionMessage from "@atlaskit/section-message";
import Textfield from "@atlaskit/textfield";
import LinkIcon from "@atlaskit/icon/glyph/link";
import { token } from "@atlaskit/tokens";

import {
  ContentHeader,
  ContentPanel,
  ContentSectionForm,
  ContentSectionToggleContainer,
  ContentTitle,
  FormField,
  FormFieldLabel,
  FormFieldLinksContainer,
  PreviewContainer,
  TabContainer,
  ToggleContainerDescription,
  ToggleContainerHeading,
  ToggleContainerTitle,
} from "../../styles";
import { useCosmosAppStore } from "../../cosmos-app-store";
import { COSMOS_APP_KEY } from "../../defaults";
import Phone from "../../Shared/Phone";
import { SCREEN_TYPE } from "../../Shared/PhoneScreens/defaults";

const UnderConstruction = ({ isLoadingCosmosAppSettings }) => {
  const { cosmosAppSettings, updateCosmosAppSettings } = useCosmosAppStore((state) => ({
    cosmosAppSettings: state.cosmosAppSettings,
    updateCosmosAppSettings: state.updateCosmosAppSettings,
  }));

  return (
    <TabContainer style={{ marginTop: "24px", display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gap: "32px" }}>
      <div style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
        <ContentSectionToggleContainer>
          <ToggleContainerHeading>
            <ToggleContainerTitle>Under Construction</ToggleContainerTitle>
            <Toggle
              isDisabled={isLoadingCosmosAppSettings}
              isChecked={cosmosAppSettings?.underConstruction?.enabled}
              onChange={() =>
                updateCosmosAppSettings(COSMOS_APP_KEY.UNDER_CONSTRUCTION, {
                  ...cosmosAppSettings?.underConstruction,
                  enabled: !cosmosAppSettings?.underConstruction?.enabled,
                })
              }
            />
          </ToggleContainerHeading>
          <ToggleContainerDescription>Activated</ToggleContainerDescription>
        </ContentSectionToggleContainer>

        <ContentPanel style={{ width: "auto" }}>
          <ContentHeader>
            <ContentTitle>Content</ContentTitle>
          </ContentHeader>
          <ContentSectionForm>
            <FormField>
              <FormFieldLabel>Title</FormFieldLabel>
              <Textfield
                placeholder="Enter title"
                value={cosmosAppSettings?.underConstruction?.title}
                onChange={({ target: { value: title } }) =>
                  updateCosmosAppSettings(COSMOS_APP_KEY.UNDER_CONSTRUCTION, {
                    ...cosmosAppSettings?.underConstruction,
                    title,
                  })
                }
              />
            </FormField>
            <FormField>
              <FormFieldLabel>Description</FormFieldLabel>
              <Textfield
                placeholder="Enter description"
                value={cosmosAppSettings?.underConstruction?.text}
                onChange={({ target: { value: text } }) =>
                  updateCosmosAppSettings(COSMOS_APP_KEY.UNDER_CONSTRUCTION, {
                    ...cosmosAppSettings?.underConstruction,
                    text,
                  })
                }
              />
            </FormField>
            <FormFieldLinksContainer>
              <FormFieldLabel>Button</FormFieldLabel>
              <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gap: "16px" }}>
                <Textfield
                  placeholder="Enter label"
                  value={cosmosAppSettings?.underConstruction?.button?.label}
                  onChange={({ target: { value: label } }) =>
                    updateCosmosAppSettings(COSMOS_APP_KEY.UNDER_CONSTRUCTION, {
                      ...cosmosAppSettings?.underConstruction,
                      button: {
                        ...cosmosAppSettings?.underConstruction?.button,
                        label,
                      },
                    })
                  }
                />
                <Textfield
                  elemBeforeInput={
                    <div style={{ paddingLeft: "10px" }}>
                      <LinkIcon size="small" primaryColor={token("color.icon.accent.teal")} />
                    </div>
                  }
                  placeholder="Enter link"
                  value={cosmosAppSettings?.underConstruction?.button?.path}
                  onChange={({ target: { value: path } }) =>
                    updateCosmosAppSettings(COSMOS_APP_KEY.UNDER_CONSTRUCTION, {
                      ...cosmosAppSettings?.underConstruction,
                      button: {
                        ...cosmosAppSettings?.underConstruction?.button,
                        path,
                      },
                    })
                  }
                />
              </div>
            </FormFieldLinksContainer>
            {!(
              cosmosAppSettings?.underConstruction?.button?.label && cosmosAppSettings?.underConstruction?.button?.path
            ) && (
              <SectionMessage>
                <p style={{ margin: 0, fontSize: "14px" }}>
                  The button must have a label and a defined path in order to be displayed.
                </p>
              </SectionMessage>
            )}
          </ContentSectionForm>
        </ContentPanel>
      </div>
      <div style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
        <SectionMessage title="Under Construction">
          <p style={{ margin: 0, fontSize: "14px" }}>
            While this mode is enabled, Cosmos administrators can work on setup without regular users seeing the
            work-in-progress. Instead, users will see a customizable message indicating that the mobile intranet
            experience is "Under Construction" and coming soon.
          </p>
        </SectionMessage>
        <PreviewContainer>
          <Phone screenType={SCREEN_TYPE.UNDER_CONSTRUCTION} />
        </PreviewContainer>
      </div>
    </TabContainer>
  );
};

export default UnderConstruction;
