import React, { useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import Select from "@atlaskit/select";
import CustomSketchPicker from "./CustomSketchPicker";
import debounce from "lodash.debounce";

const GradientBar = styled.div`
  height: 16px;
  width: calc(100% - 2px);
  margin: 0 auto;
  border-radius: 10px;
  background: ${({ rotation, firstColor, firstColorPosition, secondColor, secondColorPosition }) =>
    `linear-gradient(${rotation}, ${firstColor} ${firstColorPosition}%, ${secondColor} ${secondColorPosition}%)`};

  position: relative;

  ${({ isDisabled }) => isDisabled && "cursor: not-allowed"};
`;

const ColorPositioner = styled.div`
  box-sizing: border-box;
  height: 28px;
  width: 28px;
  border-radius: 50%;
  border: 6px solid ${({ theme }) => theme.shared.form.colorpicker.border};
  position: absolute;
  z-index: 5;
  top: -6px;
  box-shadow: 0px 0px 4px rgba(0 0 0 / 25%);

  transform: ${({ position }) => `translateX(${position}px);`};
  outline: ${({ isActive }) => (isActive ? "2px solid #0065ff" : "none")};
`;

const Button = styled.div`
  margin-top: 35px;
  border-radius: 8px;
  padding: 12px;
  font-size: 14px;
  cursor: pointer;
  text-align: center;
  flex: 1;
  background: ${({ bg }) => bg};
  color: ${({ color }) => color};

  &:hover {
    background: ${({ bg }) => bg}cc;
  }
  &:active {
    background: ${({ bg }) => bg}ee;
  }
`;

const FieldsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;
`;

const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;

const Label = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme }) => theme.shared.sidebar.colors.inputLabel};
`;

const ColorSelectorWrapper = styled.div`
  background: ${({ theme }) => theme.shared.form.button.background.inactive};
  border-radius: 3px;
  padding: 4px;
  height: 40px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  column-gap: 12px;
  position: relative;

  input {
    max-width: 80px;
    border: none;
    outline: none;
    background-color: transparent;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: ${({ theme }) => theme.shared.sidebar.colors.input};
  }
`;

const ColorBox = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 1px;
  background-color: ${({ color }) => color};
  cursor: pointer;
`;

const SelectContainer = styled.div`
  & > div:first-child {
    font-size: 14px;

    .rotation-select__control {
      background: ${({ theme }) => theme.shared.form.button.background.inactive};
      color: ${({ theme }) => `${theme.shared.sidebar.label} !important`};
      border-radius: 3px;
      border: none;
    }
  }
`;

const DEFAULT_COLOR_ONE = "#1D58F8";
const DEFAULT_COLOR_ONE_POSITION = 0;
const DEFAULT_COLOR_TWO = "#FF48CA";
const DEFAULT_COLOR_TWO_POSITION = 100;
const DEFAULT_ROTATION = "90deg";

const POSITIONER_WIDTH = 28;

const rotationOptions = [
  { label: "0°", value: "0deg" },
  { label: "45°", value: "45deg" },
  { label: "90°", value: "90deg" },
  { label: "135°", value: "135deg" },
  { label: "180°", value: "180deg" },
];

function GradientColorPicker({
  value,
  setValue,
  presetColors = [],
  isInitalized = false,
  setIsInitialized = () => {},
  isDisabled = false,
}) {
  const [firstColor, setFirstColor] = useState(DEFAULT_COLOR_ONE);
  const [firstColorPosition, setFirstColorPosition] = useState(DEFAULT_COLOR_ONE_POSITION);

  const [secondColor, setSecondColor] = useState(DEFAULT_COLOR_TWO);
  const [secondColorPosition, setSecondColorPosition] = useState(DEFAULT_COLOR_TWO_POSITION);

  const [rotation, setRotation] = useState(DEFAULT_ROTATION);

  const [selectedColor, setSelectedColor] = useState("first");
  const [isColorPickerOpen, setIsColorPickerOpen] = useState(false);

  const [containerWidth, setContainerWidth] = useState(0);

  const containerRef = useRef();
  const mousePositionRef = useRef();

  const isMovingFirstBox = useRef();
  const isMovingSecondBox = useRef();

  const [inputValueOne, setInputValueOne] = useState(DEFAULT_COLOR_ONE);
  const [inputValueTwo, setInputValueTwo] = useState(DEFAULT_COLOR_TWO);

  const [firstPositionPixels, setFirstPositionPixels] = useState(0);
  const firstPositionPixelsRef = useRef();
  firstPositionPixelsRef.current = firstPositionPixels;

  const [secondPositionPixels, setSecondPositionPixels] = useState(100);
  const secondPositionPixelsRef = useRef();
  secondPositionPixelsRef.current = secondPositionPixels;

  useEffect(() => {
    if (containerRef?.current?.clientWidth) {
      setContainerWidth(containerRef?.current?.clientWidth - 10);
    }
  }, [containerRef]);

  useEffect(() => {
    if (Object.keys(value || {}).length) {
      setFirstColor(value.firstColor);
      setFirstColorPosition(value.firstColorPosition);

      setSecondColor(value.secondColor);
      setSecondColorPosition(value.secondColorPosition);
      setRotation(value?.rotation || "90deg");
    }
  }, []);

  useEffect(() => {
    if (Object.keys(value || {}).length) {
      setFirstColor(value.firstColor);
      setSecondColor(value.secondColor);
      setRotation(value?.rotation || "90deg");
      setIsInitialized(true);
    }
  }, [value]);

  useEffect(() => {
    if (containerWidth) {
      const initialTransformFirst = (firstColorPosition / 100) * containerWidth;
      const isBiggerThanGradientBarFirst = initialTransformFirst > containerWidth - POSITIONER_WIDTH;
      setFirstPositionPixels(
        isBiggerThanGradientBarFirst ? initialTransformFirst - POSITIONER_WIDTH : initialTransformFirst,
      );

      const initialTransformSecond = (secondColorPosition / 100) * containerWidth;
      const isBiggerThanGradientBarSecond = initialTransformSecond > containerWidth - POSITIONER_WIDTH;
      setSecondPositionPixels(
        isBiggerThanGradientBarSecond ? containerWidth - POSITIONER_WIDTH / 2 : initialTransformSecond,
      );
    }
  }, [containerWidth, firstColorPosition, secondColorPosition]);

  const handleMouseMovement = (newPosition, oldPosition) => {
    if (typeof newPosition === "undefined" || typeof oldPosition === "undefined") return;

    if (isMovingFirstBox?.current) {
      if (newPosition > oldPosition) {
        if (firstPositionPixelsRef.current < containerRef?.current?.clientWidth - POSITIONER_WIDTH) {
          const newValue = firstPositionPixelsRef.current + newPosition - oldPosition;
          setFirstPositionPixels(newValue);
        }
      } else {
        if (firstPositionPixelsRef.current > 0) {
          const newValue = firstPositionPixelsRef.current + newPosition - oldPosition;
          setFirstPositionPixels(newValue);
        }
      }
    }

    if (isMovingSecondBox?.current) {
      if (newPosition > oldPosition) {
        if (secondPositionPixelsRef.current < containerRef?.current?.clientWidth - POSITIONER_WIDTH) {
          const newValue = secondPositionPixelsRef.current + newPosition - oldPosition;
          setSecondPositionPixels(newValue);
        }
      } else {
        if (secondPositionPixelsRef.current > 0) {
          const newValue = secondPositionPixelsRef.current + newPosition - oldPosition;
          setSecondPositionPixels(newValue);
        }
      }
    }
  };

  useEffect(() => {
    window.addEventListener("mouseup", () => {
      isMovingFirstBox.current = false;
      isMovingSecondBox.current = false;
    });

    window.addEventListener("mousemove", (e) => {
      if (isDisabled) return;
      handleMouseMovement(e.clientX, mousePositionRef?.current || 0);
      mousePositionRef.current = e.clientX;
    });

    return () => {
      window.removeEventListener("mouseup", () => {});
      window.removeEventListener("mousemove", () => {});
    };
  }, []);

  const handleSetValueDebounce = useRef(
    debounce((newValue) => {
      setValue({ ...newValue });
    }, 500),
  ).current;

  useEffect(() => {
    if (!isDisabled) {
      handleSetValueDebounce({
        firstColor,
        firstColorPosition: (firstPositionPixels / containerRef?.current?.clientWidth) * 100,
        secondColor,
        secondColorPosition: (secondPositionPixels / containerRef?.current?.clientWidth) * 100,
        rotation,
      });
    }
  }, [firstColor, firstPositionPixels, secondColor, secondPositionPixels, rotation]);

  useEffect(() => {
    return () => {
      handleSetValueDebounce.cancel();
    };
  }, [handleSetValueDebounce]);

  return (
    <>
      <GradientBar
        ref={containerRef}
        firstColor={firstColor}
        secondColor={secondColor}
        firstColorPosition={
          containerRef?.current?.clientWidth
            ? (firstPositionPixels / containerRef?.current?.clientWidth) * 100 // pixels to percent of total width
            : 0
        }
        secondColorPosition={
          containerRef?.current?.clientWidth
            ? (secondPositionPixels / containerRef?.current?.clientWidth) * 100 // pixels to percent of total width
            : 0
        }
        rotation={rotation}
        isDisabled={isDisabled}
      >
        <ColorPositioner
          position={firstPositionPixels || 0}
          onMouseDown={() => {
            if (isDisabled) return;
            isMovingSecondBox.current = false;
            isMovingFirstBox.current = true;
            setSelectedColor("first");
          }}
          onClick={() => setIsInitialized(true)}
          totalWidth={containerWidth || 0}
          isActive={selectedColor === "first"}
        />

        <ColorPositioner
          position={secondPositionPixels || 0}
          onMouseDown={() => {
            if (isDisabled) return;
            isMovingFirstBox.current = false;
            isMovingSecondBox.current = true;
            setSelectedColor("second");
          }}
          onClick={() => setIsInitialized(true)}
          totalWidth={containerWidth || 0}
          isActive={selectedColor === "second"}
        />
      </GradientBar>

      <FieldsWrapper style={{ marginTop: "25px", cursor: isDisabled ? "not-allowed" : "default" }}>
        <FieldContainer>
          <Label>Color {selectedColor === "first" ? "1" : "2"}</Label>
          <ColorSelectorWrapper>
            <ColorBox
              style={{ cursor: isDisabled ? "not-allowed" : "pointer" }}
              color={selectedColor === "first" ? firstColor : secondColor}
              onClick={() => {
                if (!isDisabled) {
                  setIsColorPickerOpen(true);
                }
              }}
            />
            {isColorPickerOpen && (
              <CustomSketchPicker
                selectedColor={selectedColor === "first" ? firstColor : secondColor}
                setSelectedColor={selectedColor === "first" ? setFirstColor : setSecondColor}
                presetColors={[...presetColors, "transparent"]}
                callback={() => setIsColorPickerOpen(false)}
                disableAlpha
                setInputValues={selectedColor === "first" ? setInputValueOne : setInputValueTwo}
              />
            )}

            <input
              maxLength={9}
              value={selectedColor === "first" ? inputValueOne : inputValueTwo}
              onChange={(e) => {
                const newValue = e.target.value;
                const isValidInput = /^#?([A-Fa-f0-9]*)$/.test(newValue);
                const validHexLengths = [4, 5, 7, 9];

                if (isValidInput) selectedColor === "first" ? setInputValueOne(newValue) : setInputValueTwo(newValue);

                if (isValidInput && validHexLengths.includes(e.target.value.length))
                  selectedColor === "first" ? setFirstColor(newValue) : setSecondColor(newValue);
              }}
              onBlur={() => {
                selectedColor === "first" ? setInputValueOne(firstColor) : setInputValueTwo(secondColor);
              }}
            ></input>
          </ColorSelectorWrapper>
        </FieldContainer>

        <FieldContainer>
          <Label>Rotation</Label>
          <SelectContainer>
            <Select
              value={rotationOptions.find((op) => op.value === rotation)}
              options={rotationOptions}
              onChange={({ value }) => setRotation(value)}
              placeholder="90°"
              classNamePrefix="rotation-select"
              isDisabled={isDisabled}
            />
          </SelectContainer>
        </FieldContainer>
      </FieldsWrapper>
    </>
  );
}

export default GradientColorPicker;
