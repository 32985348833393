import React from "react";
import styled, { useTheme } from "styled-components";
import PanelWrapper from "../../../Shared/Panels/shared/components/PanelWrapper";
import EmptyState from "../../../../../../Shared/Components/EmptyState";
import { formatNewPath } from "../../filter/SidebarAppPicker";

const LinksWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-gap: 16px;
`;

const LinkCard = styled.div`
  background: ${({ theme }) => theme.links.background};
  padding: 25px 0px 16px 0px;

  display: flex;
  row-gap: 16px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  cursor: pointer;
`;

const LinkImage = styled.img`
  justify-self: center;
  width: auto;
  max-width: 120px;
  height: 56px;
  user-select: none;
  object-fit: contain;
`;

const LinkTitle = styled.span`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.links.title};
  max-width: 140px;
  word-break: break-word;
`;

function Links({ panelTitle = "", data }) {
  const theme = useTheme();

  if (!data?.links?.length) {
    return (
      <PanelWrapper
        panelTitle={panelTitle}
        displayOptions={{
          displayBox: true,
          displayTitle: !!data?.displayTitle,
        }}
      >
        <EmptyState
          Image={() => {
            return <img src={`/images/hub/app-links-empty-state-${theme.global.name}.svg`} />;
          }}
          title="No links available."
          description="Add some links to display."
        />
      </PanelWrapper>
    );
  }
  return (
    <PanelWrapper
      panelTitle={panelTitle}
      displayOptions={{
        displayBox: true,
        displayTitle: !!data?.displayTitle,
      }}
    >
      <LinksWrapper>
        {data.links.map((linkCard, index) => (
          <LinkCard
            key={`${linkCard.title}-${index}`}
            onClick={() => {
              const { link } = linkCard;

              const validLink = link?.startsWith("http://") || link?.startsWith("https://") ? link : `https://${link}`;

              if (validLink) {
                window.open(validLink, "_blank");
              }
            }}
          >
            <LinkImage
              src={linkCard.icon.includes("admin.caelor.com") ? formatNewPath(linkCard.icon) : linkCard.icon}
            />
            <LinkTitle>{linkCard.title}</LinkTitle>
          </LinkCard>
        ))}
      </LinksWrapper>
    </PanelWrapper>
  );
}

export default Links;
