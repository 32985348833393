const createSVG = (viewBox, content, fill, color = "") =>
  `<svg width="24" height="24" version="1.1" xmlns="http://www.w3.org/2000/svg" color="${color}" fill="${fill}" viewBox="${viewBox}">${content}</svg>`;

/**
 * Format provider icon.
 */

export const getIconSVG = (icon, provider, color = null) => {
  const svgColor = color ? `#${color}` : "currentcolor";

  let svg;

  switch (provider) {
    case "material-design":
      svg = createSVG("-0.5 -0.5 25 25", icon[1], svgColor);
      break;
    case "font-awesome":
      svg = createSVG(`0 0 ${icon[1]} 512`, `<path d="${icon[2]}"/>`, svgColor);
      break;
    case "bootstrap":
      svg = createSVG("-2.5 -2.5 21 21", icon[1], svgColor);
      break;
    case "atlaskit":
      svg = createSVG("0 0 24 24", icon[1], "#fff", svgColor);
      break;
    case "caelor":
      svg = createSVG("0 0 24 24", icon[1], "#fff", svgColor);
      break;
    default:
      svg = null;
  }

  return svg;
};

/**
 * Get provider icon by name.
 */

export const getIconByName = (provider, name, icons, color) => {
  // Support for older icon picker names.
  const formattedName = name.replace(/-/g, "").toLowerCase();

  const icon = icons.find(([iconName]) => iconName.toLowerCase() === formattedName);

  if (!icon) return null;

  return getIconSVG(icon, provider, color);
};
