import create from "zustand";

export const useDatabaseStore = create((set, get) => ({
  fields: [],
  records: [],
  setRecords: (_records) => set((state) => ({ records: _records })),
  addRecords: (records) => {
    const newRecords = [...get().records, ...records];
    const uniqueResults = newRecords.filter(
      (record, index) => index === newRecords.findIndex((recordIdx) => recordIdx.accountId === record.accountId)
    );
    return set((state) => ({ records: uniqueResults }));
  },
  builtRecords: [],
  builtRecordsLoading: true,
  setBuiltRecords: (value, currentPage) =>
    set((state) => {
      if (currentPage === 1) {
        return { builtRecords: [...value] };
      }

      const currentRecords = [...get().builtRecords];
      const newRecords = [...currentRecords, ...value].filter(
        (value, index, self) => index === self.findIndex((t) => t.accountId === value.accountId)
      );
      return { builtRecords: [...newRecords] };
    }),
  setFields: (fields) => set((state) => ({ fields })),
  setBuiltRecordsLoading: (value) => set((state) => ({ builtRecordsLoading: value })),
}));

export const useDatabaseFilters = create((set, get) => ({
  filters: [],
  setFilters: (filters) => set((state) => ({ filters })),
  updateFilter: (filter) => {
    const newFilters = [...get().filters];
    const indexOfUpdatedFilter = newFilters.findIndex((f) => f.id === filter.id);
    newFilters[indexOfUpdatedFilter] = filter;
    set((state) => ({ filters: newFilters }));
    return newFilters;
  },
  selectedFilters: [],
  setSelectedFilters: (selectedFilters) => set((state) => ({ selectedFilters })),
  reset: () => set({ filters: [], selectedFilters: [] }),
}));

export const useSidebarPanelStore = create((set, get) => ({
  settings: {},
  setSettings: (v) => set((state) => ({ settings: { ...v } })),
  updateSettings: (key, value) =>
    set((state) => {
      const copyOfSettings = { ...get().settings, [key]: value };
      return set((state) => ({ settings: copyOfSettings }));
    }),
  updateSettingsStyle: (styleKey, propertyKey, value) =>
    set((state) => {
      const existingSettings = { ...get().settings };
      return set((state) => ({
        settings: {
          ...existingSettings,
          [styleKey]: {
            ...existingSettings[styleKey],
            [propertyKey]: value,
          },
        },
      }));
    }),

  isEditing: false,
  setIsEditing: (v) => set((state) => ({ isEditing: v })),
}));
