import React from "react";
import InfoIcon from "@atlaskit/icon/glyph/info";
import { DesignSidebarWrapper, InfoPanel, PanelEditorWrapper } from "./styled";
import { TabTypes } from "../../tabTypes";
import TextInputFilter from "../../../filter/TextInputFilter";
import { Divider, FieldContainer, HelperMessage, Label } from "../../../filter/styled";
import InputFilter from "../../../filter/InputFilter";
import ElementsToggle from "../../../filter/ElementsToggle";
import { NewsTypes } from "../../types/news/News";
import SimpleToggle from "../../../filter/SimpleToggle";
import SingleTileColorPicker from "../../../../../../../Shared/Components/SingleTileColorPicker";
import SizeButtons from "../../../../../../../Shared/Components/SizeButtons";
import { sizeType } from "../../../../../../../Shared/Components/size-options";
import SelectWithCustomOptions from "../../../filter/SelectWithCustomOptions";
import { PaginationOptions } from "../../../../Shared/PanelsPagination/pagination-options";
import DefaultSortPicker from "../../../filter/DefaultSortPicker";
import OrderButton from "../../../filter/OrderButton";
import { NewsElementsOptionsNoSocial, ViewOptions } from "./NewsEditor";
import LabelMultiSelect from "../../../filter/LabelMultiSelect";
import SpaceMultiSelect from "../../../filter/SpaceMultiSelect";
import PeoplePicker from "../../../filter/PeoplePicker";
import { useTheme } from "styled-components";
import { InfoIconDark } from "./VideosEditor";
import { PanelPositioning } from "../../panelTypes";

const PropertyKeys = {
  VIEW: "view",
  LABELS: "labels",
  SPACES: "spaces",
  CONTRIBUTORS: "contributors",
  NUMBER_OF_ITEMS: "maxNumberOfItems",
  ACTIVE_ELEMENTS: "activeElements",
  LABELS_BG: "labelsBackground",
  TEAM_LABELS_BG: "teamLabelsBackground",
  COVER_PIC_SIZE: "coverPictureSize",
  COVER_PIC_RADIUS: "coverPictureBorderRadius",
  PADDING: "padding",
  DISPLAY_TITLE: "displayTitle",
  DISPLAY_BOX: "displayBox",
  PAGINATION: "pagination",
  SORT: "sortBy",
  ORDERING: "ordering",
};

const DesignSidebar = ({ panelId, data, type, updatePanelDataProperty }) => {
  const { displayTitle, displayBox, view, labelsBackground, teamLabelsBackground, coverPictureSize, padding } =
    data || {};

  const isDisabledPadding = [NewsTypes.LIST].includes(view);

  return (
    <DesignSidebarWrapper>
      <div>
        <>
          <FieldContainer>
            <Label>Display title</Label>
            <SimpleToggle
              label="Display title"
              value={displayTitle}
              setValue={(value) => updatePanelDataProperty(panelId, PropertyKeys.DISPLAY_TITLE, value)}
              customStyles={{ padding: "10px 0px 8px 10px" }}
            />
            <SimpleToggle
              label="Display box"
              value={displayBox}
              setValue={(value) => updatePanelDataProperty(panelId, PropertyKeys.DISPLAY_BOX, value)}
              customStyles={{ padding: "10px 0px 8px 10px" }}
            />
          </FieldContainer>

          <Divider />

          <FieldContainer>
            <Label>Color</Label>
            <SingleTileColorPicker
              label="Space and Label"
              value={view !== NewsTypes.LIST ? labelsBackground : teamLabelsBackground || "#42526E"}
              setValue={(value) =>
                updatePanelDataProperty(
                  panelId,
                  view !== NewsTypes.LIST ? PropertyKeys.LABELS_BG : PropertyKeys.TEAM_LABELS_BG,
                  value,
                  true,
                )
              }
            />
          </FieldContainer>

          <Divider />

          {data.view === NewsTypes.GRID && (
            <FieldContainer>
              <SizeButtons
                label="Image size"
                types={{ panel: "news", size: sizeType.COVER_PICTURE_SIZE }}
                input={{ min: 120, max: 280, step: 10, value: coverPictureSize }}
                setValue={(value) => updatePanelDataProperty(panelId, PropertyKeys.COVER_PIC_SIZE, value, true)}
              />
            </FieldContainer>
          )}

          {!isDisabledPadding && (
            <SizeButtons
              label="Padding"
              types={{ panel: "news", size: sizeType.PADDING }}
              input={{ min: view === NewsTypes.MAGAZINE ? 5 : 10, max: 40, step: 1, value: padding }}
              setValue={(value) => updatePanelDataProperty(panelId, PropertyKeys.PADDING, value, true)}
            />
          )}
        </>
      </div>
    </DesignSidebarWrapper>
  );
};

const ContentSidebar = ({ panelId, data, updatePanelDataProperty }) => {
  const theme = useTheme();

  return (
    <>
      <InfoPanel style={{ marginTop: "20px" }}>
        {theme.global.name === "light" ? (
          <InfoIcon size="large" primaryColor="#0052cc" />
        ) : (
          <div style={{ minWidth: "30px" }}>
            <InfoIconDark size={30} />
          </div>
        )}
        <span>
          Employees receive news precisely targeted to their collections, ensuring irrelevant content is filtered out.
          <br />
          <br />
          <a href="https://caelor-apps.atlassian.net/wiki/x/AYCtBQ" target="_blank" rel="noreferrer">
            Learn more
          </a>
        </span>
      </InfoPanel>

      <LabelMultiSelect
        label="Labels"
        placeholder="Filter by labels"
        initialLabels={data.labels}
        onUpdate={(labels) => {
          updatePanelDataProperty(panelId, PropertyKeys.LABELS, labels);
        }}
      />
      <SpaceMultiSelect
        initialSpaces={data.spaces}
        isDynamic={true}
        onUpdate={(spaces) => updatePanelDataProperty(panelId, PropertyKeys.SPACES, spaces)}
      />
      <PeoplePicker
        isMulti
        initialAccountIDs={data.contributors}
        label="Contributors"
        placeholder="Filter by contributors"
        onUpdate={(users) => updatePanelDataProperty(panelId, PropertyKeys.CONTRIBUTORS, users)}
      />
      <DefaultSortPicker
        initialValue={data.sortBy}
        onUpdate={(sorting) => updatePanelDataProperty(panelId, PropertyKeys.SORT, sorting)}
      />
      <HelperMessage>
        <div style={{ marginRight: "4px", color: "#6b778c" }}> Order by </div>
        <OrderButton
          sortOrder={data.ordering}
          onUpdate={(ordering) => updatePanelDataProperty(panelId, PropertyKeys.ORDERING, ordering)}
        />
      </HelperMessage>
    </>
  );
};

const LayoutSidebar = ({ panelId, data, isSideNews, updatePanelDataProperty, panelTitle, updatePanelTitle }) => {
  const updateElements = (value) => {
    const newElements = !!data.activeElements.find((el) => el === value)
      ? data.activeElements.filter((el) => el !== value)
      : [...data.activeElements, value];
    updatePanelDataProperty(panelId, PropertyKeys.ACTIVE_ELEMENTS, newElements, true);
  };

  return (
    <>
      <TextInputFilter label="Title" value={panelTitle} onUpdate={(value) => updatePanelTitle(panelId, value)} />

      <Divider />

      {!isSideNews && (
        <SelectWithCustomOptions
          initialValue={data.view}
          options={ViewOptions}
          label="View"
          onUpdate={(newView) => updatePanelDataProperty(panelId, PropertyKeys.VIEW, newView)}
        />
      )}

      <SelectWithCustomOptions
        initialValue={data.pagination}
        options={PaginationOptions}
        label="Pagination"
        onUpdate={(newValue) => updatePanelDataProperty(panelId, PropertyKeys.PAGINATION, newValue)}
      />

      <InputFilter
        numberField
        minLength={1}
        maxLength={12}
        initialValue={data.maxNumberOfItems}
        label="Items per page"
        placeholder="Limit the number of pages"
        onUpdate={(value) => updatePanelDataProperty(panelId, PropertyKeys.NUMBER_OF_ITEMS, value)}
      />

      <Divider />

      <ElementsToggle
        label="Elements to display"
        elements={data.activeElements}
        options={NewsElementsOptionsNoSocial}
        updateElements={updateElements}
      />
    </>
  );
};

function TargetedNewsEditor({ panel, selectedTab, updatePanelDataProperty, updatePanelTitle }) {
  const { data, position, type } = panel;

  const isSideNews = position === PanelPositioning.SIDE;

  return (
    <PanelEditorWrapper>
      {selectedTab === TabTypes.LAYOUT && (
        <LayoutSidebar
          panelId={panel.id}
          data={data}
          isSideNews={isSideNews}
          updatePanelDataProperty={updatePanelDataProperty}
          panelTitle={panel.panelTitle}
          updatePanelTitle={updatePanelTitle}
        />
      )}
      {selectedTab === TabTypes.CONTENT && (
        <ContentSidebar panelId={panel.id} data={data} updatePanelDataProperty={updatePanelDataProperty} />
      )}
      {selectedTab === TabTypes.DESIGN && (
        <DesignSidebar panelId={panel.id} data={data} type={type} updatePanelDataProperty={updatePanelDataProperty} />
      )}
    </PanelEditorWrapper>
  );
}

export default TargetedNewsEditor;
