import React from "react";
import { ColumnContainer, Ellipse, InlineContainer, LargeLine, MediumLine, Wrapper } from "./styled";
import styled from "styled-components";

const TeamNewsWrapper = styled.div`
  display: flex;
  column-gap: 27px;
  background-color: #fff;
`;

const Image = styled.img`
  height: 95px;
  width: 140px;
  object-fit: cover;
  border-radius: 6px;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
`;

function TeamNews() {
  return (
    <Wrapper>
      <TeamNewsWrapper>
        <Image src="https://img.freepik.com/free-photo/two-female-one-male-business-partners-looking-computer-screen-discussing-their-project_93675-134807.jpg?w=996&t=st=1684318106~exp=1684318706~hmac=c19a4e08b7dc9b0270665eaa2c8d2a8b59dddf2248ad8db5973cdb9967b93cb5" />
        <InfoWrapper>
          <InlineContainer>
            <Ellipse bg="#DFE1E6" />
            <MediumLine width={53} bg="#DFE1E6" />
            <MediumLine width={50} bg="#DFE1E6" />
          </InlineContainer>

          <ColumnContainer>
            <MediumLine width={310} bg="rgba(9, 30, 66, 0.25)" />
            <MediumLine width={300} bg="#DFE1E6" />
            <MediumLine width={250} bg="#DFE1E6" />
          </ColumnContainer>

          <InlineContainer style={{ marginTop: "2px" }}>
            <LargeLine width={40} bg="rgba(9, 30, 66, 0.08)" />
            <LargeLine width={37} bg="rgba(9, 30, 66, 0.08)" />
          </InlineContainer>
        </InfoWrapper>
      </TeamNewsWrapper>

      <TeamNewsWrapper style={{ marginTop: "24px" }}>
        <Image src="https://images.unsplash.com/photo-1600880292203-757bb62b4baf?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=640&h=480&q=80" />
        <InfoWrapper>
          <InlineContainer>
            <Ellipse bg="#DFE1E6" />
            <MediumLine width={53} bg="#DFE1E6" />
            <MediumLine width={50} bg="#DFE1E6" />
          </InlineContainer>

          <ColumnContainer>
            <MediumLine width={310} bg="rgba(9, 30, 66, 0.25)" />
            <MediumLine width={300} bg="#DFE1E6" />
            <MediumLine width={250} bg="#DFE1E6" />
          </ColumnContainer>

          <InlineContainer style={{ marginTop: "2px" }}>
            <LargeLine width={40} bg="rgba(9, 30, 66, 0.08)" />
            <LargeLine width={37} bg="rgba(9, 30, 66, 0.08)" />
          </InlineContainer>
        </InfoWrapper>
      </TeamNewsWrapper>
    </Wrapper>
  );
}

export default TeamNews;
