import { useState } from "react";
import styled, { useTheme } from "styled-components";
import PageIcon from "@atlaskit/icon-object/glyph/page/16";
import BlogIcon from "@atlaskit/icon-object/glyph/blog/16";
import DragIcon from "@atlaskit/icon/glyph/drag-handler";
import DeleteIcon from "@atlaskit/icon/glyph/editor/remove";
import AddIcon from "@atlaskit/icon/glyph/editor/add";
import CrossIcon from "@atlaskit/icon/glyph/cross";
import Button from "@atlaskit/button";
import { Checkbox } from "@atlaskit/checkbox";

import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import { EmailIcon, LinkIcon } from "./Icons";
import { FieldColumn, FieldContainer, FieldLabel } from "./Components";
import { EntryAction } from "./styled";
import CustomInput from "../../../../../../ContentBuilder/Shared/Filter/CustomInput";
import Transition from "../../../Transition";
import LinkSidebar from "../../../../../../../Shared/Components/LinkSidebar/LinkSidebar";
import LinkTypes from "../../../../../../../Shared/Components/LinkSidebar/LinkTypes";

const LinkEntryInput = styled.div`
  display: grid;
  grid-template-columns: 24px auto 24px;
  row-gap: 4px;
  margin-top: 10px;
  align-items: center;

  * {
    border: none !important;
  }
`;

const DragContainer = styled.div`
  cursor: grab;
  margin-left: -6px;
`;

const LinkEntryWrapper = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.navigation.settings.linkEntryWrapperBorderBottom};
  border-top: 1px solid ${({ theme }) => theme.navigation.settings.linkEntryWrapperBorderTop};
  padding-bottom: 15px;

  ${({ isHoveringOver, orderDirection }) => {
    if (!isHoveringOver) {
      return null;
    }

    return `border-${orderDirection === "top-bottom" ? "bottom" : "top"}: 1px solid #aaa;`;
  }}

  .delete-icon {
    visibility: hidden;
  }

  &:hover {
    .delete-icon {
      visibility: visible;
    }
  }

  & > div:nth-child(2) {
    margin-top: 0 !important;
  }
`;

const Box = styled.div`
  box-sizing: border-box;
  background: ${({ theme }) => theme.navigation.settings.iconBoxBackground};
  border: 1px solid ${({ theme }) => theme.navigation.settings.iconBoxBorder};
  border-radius: 3px;
  width: 100%;
  height: 40px;
  overflow: hidden;
`;

const DeleteIconContainer = styled.div`
  display: grid;
  place-content: center;
  width: 24px;
  height: 24px;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }
`;

export const Link = ({ link, onSelected, hasLabel = true }) => {
  const [linkSidebarVisible, setLinkSidebarVisible] = useState(false);

  const theme = useTheme();

  const getLinkValue = () => {
    if (link?.source === "page") {
      return link.title;
    }
    return link?.link || "";
  };

  const getIcon = () => {
    if (link?.source) {
      if (link.source === "page") {
        if (link.type === "page") {
          return <PageIcon />;
        }
        return <BlogIcon />;
      }

      if (link.source === "email") {
        return <EmailIcon />;
      }
    }
    return <LinkIcon />;
  };

  return (
    <FieldContainer>
      {hasLabel && <FieldLabel>Link</FieldLabel>}

      <Box
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setLinkSidebarVisible(true);
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            background: theme.navigation.settings.addLinkBackground,
            height: "100%",
            padding: "0 10px",
            columnGap: "6px",
          }}
        >
          <EntryAction
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setLinkSidebarVisible(true);
            }}
          >
            {getIcon()}
          </EntryAction>
          {link && (
            <span
              style={{
                fontSize: "14px",
                flex: 1,
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
                color: theme.navigation.settings.linkValue,
              }}
            >
              {getLinkValue()}
            </span>
          )}
          {!link && (
            <span
              style={{
                cursor: "pointer",
                flex: 1,
                fontSize: "14px",
                color: "#7A869A",
              }}
            >
              Add link
            </span>
          )}
          {!!getLinkValue() && (
            <EntryAction
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onSelected(undefined);
              }}
            >
              <CrossIcon size="small" />
            </EntryAction>
          )}
        </div>

        <Transition
          isOpen={linkSidebarVisible}
          close={(e) => {
            e.stopPropagation();
            setLinkSidebarVisible(false);
          }}
          width={415}
          title="Links"
          customScrollbarHeight="100%"
        >
          <LinkSidebar
            value={link}
            onClose={() => setLinkSidebarVisible(false)}
            hasClose={false}
            selectedTab={LinkTypes.PAGES}
            onSelected={(selected) => {
              setLinkSidebarVisible(false);
              onSelected(selected);
            }}
            showAttachments={false}
          />
        </Transition>
      </Box>
    </FieldContainer>
  );
};

const ReorderableLink = ({ entry, link, idx, reorderLinks, removeLink, updateEntry }) => {
  const theme = useTheme();

  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: ["entry-link"],
      drop: () => ({ ...link }),

      collect: (monitor) => ({
        isOver: monitor.isOver(),
      }),
    }),
    [link],
  );

  const [{ draggedItem }, drag] = useDrag({
    type: "entry-link",
    item: () => ({ ...link, type: "entry-link" }),
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (item && dropResult) {
        reorderLinks(item.id, dropResult.id);
      }
    },
    collect: (monitor) => ({
      draggedItem: monitor.getItem(),
    }),
  });

  const draggedItemPosition = draggedItem?.position ?? 0;
  const potentialTargetPosition = isOver ? link?.position : 0;

  return (
    <LinkEntryWrapper
      ref={drop}
      isHoveringOver={isOver}
      orderDirection={draggedItemPosition < potentialTargetPosition ? "top-bottom" : "bottom-top"}
    >
      <LinkEntryInput style={{ paddingBottom: "20px" }}>
        <DragContainer ref={drag}>
          <DragIcon size="large" primaryColor={theme.poll.icons} />
        </DragContainer>

        <CustomInput
          initialValue={link.linkName ?? ""}
          placeholder="Add the link title"
          backgroundColor={theme.navigation.settings.customInputBackground}
          onChange={(newName) => {
            const links = entry.data.link;
            links[idx].linkName = newName;

            updateEntry(entry.id, "link", links, true);
          }}
        />

        <DeleteIconContainer className="delete-icon" onClick={() => removeLink(link.id)}>
          <DeleteIcon primaryColor={theme.poll.icons} />
        </DeleteIconContainer>
      </LinkEntryInput>

      <Link
        link={link}
        hasLabel={false}
        onSelected={(selected) => {
          const links = entry.data.link;
          if (selected?.link?.link) {
            links[idx].openNewTab = selected.source !== "page";
          }

          links[idx] = {
            ...selected,
            linkName: links[idx].linkName,
            position: links[idx].position,
          };

          updateEntry(entry.id, "link", links, true);
        }}
      />

      <div>
        <FieldColumn style={{ marginTop: "5px", columnGap: "3px" }}>
          <Checkbox
            id="checkbox-controlled"
            isChecked={link.openNewTab}
            onChange={(e) => {
              const links = entry.data.link;
              links[idx].openNewTab = e.target.checked;
              updateEntry(entry.id, "link", links, true);
            }}
          />
          <FieldLabel style={{ marginBottom: "2px" }}>Open in new tab</FieldLabel>
        </FieldColumn>
      </div>
    </LinkEntryWrapper>
  );
};

const LinkConfig = ({ entry, updateEntry }) => {
  const resetOrder = (list) => {
    const resetList = list.map((c, index) => {
      const slide = c;
      slide.position = index + 1;
      return slide;
    });
    return resetList;
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return resetOrder(result);
  };

  const reorderLinks = (sourceId, targetId) => {
    if (sourceId === targetId) {
      return;
    }
    const content = [...entry.data.link];

    const sourceIndex = content.findIndex(({ id }) => id === sourceId);
    const targetIndex = content.findIndex(({ id }) => id === targetId);

    const items = reorder(content, sourceIndex, targetIndex);

    updateEntry(entry.id, "link", items, true);
  };

  const removeLink = (linkId) => {
    updateEntry(
      entry.id,
      "link",
      entry.data.link.filter((link) => link.id !== linkId),
      true,
    );
  };

  return (
    <>
      <div style={{ margin: "15px 0" }}>
        <DndProvider backend={HTML5Backend}>
          {(entry.data.link || [])
            .sort((a, b) => a.position - b.position)
            .map((link, idx) => (
              <ReorderableLink
                entry={entry}
                key={idx}
                idx={idx}
                link={link}
                reorderLinks={reorderLinks}
                removeLink={removeLink}
                updateEntry={updateEntry}
              />
            ))}
        </DndProvider>
      </div>

      <Button
        appearance="subtle-link"
        iconBefore={<AddIcon primaryColor="#0065ff" />}
        onClick={() => {
          const newData = {
            ...entry.data,
            linksCount: (entry.data.linksCount || 0) + 1,
            link: [
              ...(entry.data.link || []),
              {
                id: (entry.data.linksCount || 0) + 1,
                position: (entry.data.linksCount || 0) + 1,
              },
            ],
          };

          updateEntry(entry.id, "data", newData, false);
        }}
      >
        <div style={{ color: "#0065ff" }}>Add Link</div>
      </Button>
    </>
  );
};

export default LinkConfig;
