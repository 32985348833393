import styled from "styled-components";

export const MenuContentWrapper = styled.div`
  padding: 20px 25px 40px 25px;
  box-sizing: border-box;
  background-color: ${({ backgroundColor }) => backgroundColor};
  box-shadow: 0 4px 6px -6px rgba(0 0 0 / 25%);
  ${({ openOverContent }) =>
    openOverContent &&
    `position: fixed;
    z-index: 300;
    width: 100%;
    `}
`;

export const MenuContentPositioner = styled.div`
  display: grid;
  place-content: center;
`;

export const ChildMenuWrapper = styled.div`
  @media (max-width: 1440px) {
    max-height: 320px;
  }

  .simple-space-card {
    box-shadow: none;

    &__title {
      font-weight: 500;
      font-size: 16px;
    }

    &__creator {
      font-size: 14px;
      margin-top: 5px;
    }
  }

  display: grid;
  overflow-y: auto;
  max-height: 600px;
  padding: 5px 10px 15px 10px;
  column-gap: 12px;
  row-gap: 12px;
  background: transparent;
  grid-auto-rows: 1fr;
  grid-template-columns: ${({ numberOfColumns }) =>
    `repeat(${numberOfColumns > 0 && numberOfColumns < 4 ? numberOfColumns : 4}, minmax(190px, 370px))`};

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.scrollbar};
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #999;
  }

  @media (max-width: ${({ isPreview }) => (isPreview ? 1600 : 1200)}px) {
    grid-template-columns: ${({ numberOfColumns }) =>
      `repeat(${numberOfColumns > 0 && numberOfColumns < 3 ? numberOfColumns : 3}, minmax(190px, 370px))`};
  }
`;
