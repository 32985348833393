import React, { useEffect, useState } from "react";
import { CheckboxSelect } from "@atlaskit/select";
import { CustomPlaceholder } from "../../Shared/CheckboxSelect/CustomCheckboxSelectComponents";

import { getFilterOptionsFromAzure } from "../../../../UserDatabase/menubar/azure/api";
import { SIMPLE_SELECT_STYLES } from "../../Dashboard/filter/CustomSelectStyles";

const loadingOption = {
  label: "Loading...",
};

function AsyncSelectFilter({ filter, onChange, isMulti = true }) {
  const [selectKey, setSelectKey] = useState(Math.random().toString(36).substring(7));
  const [selectedValue, setSelectedValue] = useState();

  const selectedCount = selectedValue?.length;
  const selectedItem = isMulti ? selectedValue?.[0].label : selectedValue?.label;

  const [optionsList, setOptionsList] = useState([]);

  useEffect(() => {
    const loadOptions = async () => {
      try {
        const res = await getFilterOptionsFromAzure(filter.column, "");

        setOptionsList(res.data.options);
      } catch (err) {
        console.error(err.message);
      }
    };

    loadOptions();
  }, []);

  useEffect(() => {
    if (isMulti) {
      if (!filter.value || !filter.value?.length) {
        setSelectedValue(undefined);
      } else {
        const mappedValue = filter.value.map((value) => {
          const { label } = optionsList.find((opt) => opt.value === value) || {};
          return { label, value };
        });

        if (mappedValue.some((opt) => !opt.label)) {
          return setSelectedValue([loadingOption]);
        }

        setSelectedValue([...mappedValue]);
      }
    } else {
      const { label } = optionsList.find((opt) => opt.value === filter.value) || loadingOption;
      setSelectedValue({ label, value: filter.value });
    }
  }, [filter.value, optionsList]);

  useEffect(() => {
    window.AP.events.on("restore-filters-people-base", () => {
      setSelectKey(Math.random().toString(36).substring(7));
    });
  }, []);

  const handlePlaceholder = (props) => {
    return (
      <CustomPlaceholder
        item={selectedItem}
        count={selectedCount}
        isMulti={isMulti}
        fieldName={filter.fieldTitle}
        {...props}
      />
    );
  };

  return (
    <CheckboxSelect
      key={selectKey}
      isMulti
      isSearchable
      menuPlacement="bottom"
      backspaceRemovesValue
      styles={SIMPLE_SELECT_STYLES}
      maxMenuHeight={200}
      value={selectedValue}
      onChange={(arr) => {
        onChange((arr || []).map(({ value }) => value));
      }}
      controlShouldRenderValue={false}
      options={optionsList?.map((op) => ({ ...op })) || []}
      components={{
        Placeholder: handlePlaceholder,
      }}
    />
  );
}

export default AsyncSelectFilter;
