import React, { useState, useEffect, useMemo } from "react";
import styled from "styled-components";
import Tooltip from "@atlaskit/tooltip";
import AddIcon from "@atlaskit/icon/glyph/editor/add";
import CopyIcon from "@atlaskit/icon/glyph/copy";
import FieldProvider from "../fields/FieldProvider";
import { getAccountId, getExternalLink } from "../../profile.service";
import { useCurrentUserStore } from "../../../../current-user-store";
import { emailService } from "../../../../../../Service/EmailService";
import { SidebarPanelFieldWrapper } from "./styled";
import { useTheme } from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  z-index: 2;

  .field-container {
    margin-bottom: 8px;
    display: flex;
    justify-content: center;

    span {
      display: inline-block;
      width: auto;
    }
  }
`;

const UserImage = styled.img`
  box-sizing: border-box;
  width: 128px;
  height: 128px;
  border: ${({ theme }) => theme.global.background.surface} 2px solid;
  background: ${({ theme }) => theme.global.background.surface};
  border-radius: 50%;
  justify-self: center;
`;

const UserContainer = styled.div`
  width: fit-content;
  display: grid;
  place-content: center;
  text-align: center;
  row-gap: 25px;
  margin-top: -100px;
`;

const UserName = styled.div`
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  color: ${({ theme }) => theme.shared.profile.title};
  max-width: 260px;
`;

const UserEmailDialog = styled.span`
  position: absolute;
  left: 50px;
  top: 16px;
  z-index: 100;

  background: #fff;
  box-shadow: 0px 0px 4px rgba(0 0 0 / 15%);
  border: 0.75px solid #f4f5f7;
  border-radius: 5px;
  padding: 15px;

  font-size: 14px;
  font-weight: 400;
  color: #344563;

  white-space: nowrap;

  visibility: hidden;
  opacity: 0;
  transition: opacity 50ms linear;

  a {
    text-decoration: none;
  }
`;

const UserEmail = styled.span`
  font-weight: 400;
  font-size: 15px;
  margin-top: 18px;
  color: ${({ primaryColor }) => primaryColor};

  position: relative;

  a {
    color: ${({ primaryColor }) => primaryColor};
    text-decoration: none;
  }

  &:hover {
    ${UserEmailDialog} {
      visibility: visible;
      opacity: 1;
    }
  }
`;

const LinkButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: #fff;
  color: #42526e;
  transition: 200ms;

  &:hover {
    background: transparent;
  }
`;

const AddLinkButton = styled.div`
  display: flex;
  color: ${({ theme }) => theme.global.secondary};
  cursor: pointer;
  line-height: 32px;

  & > *:first-child {
    padding: 3.5px 4px;
    margin-right: 8px;
    border-radius: 50%;
    transition: 200ms;
    background: ${({ theme }) => theme.global.background.surfaceOverlay};
    color: ${({ theme }) => theme.global.secondary};
  }

  @media print {
    display: none !important;
  }

  &:hover * {
    background: transparent;
  }
`;

const AddLinkText = styled.div`
  line-height: 32px;
`;

const SocialLinks = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 8px;

  ${({ hasLinks, isOwnProfile, theme }) => {
    if (hasLinks || isOwnProfile) {
      return `
          padding: 30px 0;
          margin-bottom: 30px;
          border-top: 1px solid ${theme.shared.profile.divider};
          border-bottom: 1px solid ${theme.shared.profile.divider};
        `;
    }
  }};

  margin-top: 15px;

  @media print {
    display: ${({ hasLinks }) => (hasLinks ? "flex" : "none !important")};
  }

  @media print {
    display: ${({ hasLinks }) => (hasLinks ? "flex" : "none !important")};
  }

  ${AddLinkButton}:not(:only-child) ${AddLinkText} {
    display: none;
  }
`;

const Button = styled.span`
  background: ${({ theme }) => theme.global.background.surfaceOverlay};
  color: ${({ theme }) => theme.global.secondary};
  padding: 12px 15px;
  border-radius: 8px;
  cursor: pointer;
  transition: 200ms;
  display: flex;
  align-items: center;
  gap: 10px;

  @media print {
    display: none !important;
  }

  &:hover {
    background: transparent;
  }
`;

function SidebarPanel({ values, socialLinks, fields, setIsSocialPanelOpen, primaryColor }) {
  const [selectedUser, setSelectedUser] = useState();
  const [userEmail, setUserEmail] = useState("");

  const theme = useTheme();

  const { currentUser, isCurrentUserAdmin } = useCurrentUserStore((state) => ({
    currentUser: state.currentUser,
    isCurrentUserAdmin: state.isCurrentUserAdmin,
  }));

  const sortedFields = fields.sort((a, b) => a?.profile?.order - b?.profile?.order);

  const sidebarFields = sortedFields.filter((field) => field?.profile?.section === "sidebar");
  const userField = sortedFields.find((field) => field.id === "user");

  const { isEmailEnabled, areSocialLinksEnabled } = userField?.profile || {
    isEmailEnabled: false,
    areSocialLinksEnabled: false,
  };

  const socialFields =
    userField?.profile?.socialLinks?.filter((key) => socialLinks?.[key]?.link && socialLinks?.[key]?.isSocial) || [];

  const isOwnProfile = () => {
    const accountId = getAccountId();

    if (accountId) {
      return currentUser.accountId === accountId;
    }

    return true;
  };

  const isEditable = useMemo(() => {
    return isOwnProfile() || isCurrentUserAdmin;
  }, [currentUser, isCurrentUserAdmin]);

  const getAvatarUrl = () => {
    const accountId = getAccountId();

    if (accountId) {
      if (selectedUser) {
        return `${window.AP._hostOrigin}${selectedUser?.profilePicture?.path}`;
      }
    } else {
      return `${window.AP._hostOrigin}${currentUser.profilePicture.path}`;
    }

    return null;
  };

  const getUserName = () => {
    const accountId = getAccountId();

    if (accountId) {
      if (selectedUser) {
        return selectedUser.displayName;
      }
    } else {
      return currentUser.displayName;
    }

    return null;
  };

  const getCorrectUserId = () => {
    const accountId = getAccountId();
    let correctUserId;

    if (accountId) {
      if (selectedUser) {
        correctUserId = selectedUser.accountId;
      }
    } else {
      correctUserId = currentUser.accountId;
    }

    return correctUserId;
  };

  const copyLinKToClipboard = () => {
    const accountId = getCorrectUserId();

    const dummy = document.createElement("input");
    document.body.appendChild(dummy);

    dummy.value = `${window.AP._hostOrigin}/wiki/plugins/servlet/ac/com.caelor.confluence.cloud.cosmos/caelor-cosmos-portal?ac.accountId=${accountId}`;
    dummy.select();

    document.execCommand("copy");
    document.body.removeChild(dummy);

    const flag = window.AP.flag.create({
      title: "Profile link copied to clipboard",
      type: "info",
    });

    setTimeout(() => {
      flag.close();
    }, 5000);
  };

  useEffect(() => {
    const getUserEmail = async () => {
      const accountId = getCorrectUserId();

      if (accountId) {
        try {
          const response = await emailService.getUserEmail(accountId);
          const data = response?.data;
          setUserEmail(data?.email || "");
        } catch (error) {}
      }
    };

    getUserEmail();
  }, [selectedUser, currentUser]);

  useEffect(() => {
    const accountId = getAccountId();

    if (accountId) {
      window.AP.request(`/rest/api/user?accountId=${accountId}`).then((response) => {
        const body = JSON.parse(response.body);
        setSelectedUser(body);
      });
    }
  }, []);

  return (
    <Container>
      <UserContainer>
        <UserImage src={getAvatarUrl()} />
        <UserName>{getUserName()}</UserName>
      </UserContainer>

      {isEmailEnabled && (
        <UserEmail primaryColor={primaryColor}>
          {isEditable && isOwnProfile() ? (
            <>
              {userEmail}
              <UserEmailDialog>
                To edit your email, go to{" "}
                <a
                  href="https://id.atlassian.com/manage-profile/profile-and-visibility"
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: primaryColor }}
                >
                  Manage your account
                </a>
              </UserEmailDialog>
            </>
          ) : (
            <a href={`mailto:${userEmail}`} target="_blank" rel="noreferrer" onClick={(e) => e.stopPropagation()}>
              {userEmail}
            </a>
          )}
        </UserEmail>
      )}

      <SidebarPanelFieldWrapper>
        {sidebarFields.map((field) =>
          field.id.startsWith("azure") ? (
            <Tooltip content="Entra ID Field - Read Only" position="top-start">
              <FieldProvider isSidebar key={field.id} currentField={field} values={values} />
            </Tooltip>
          ) : (
            <FieldProvider isSidebar key={field.id} currentField={field} values={values} />
          ),
        )}

        {areSocialLinksEnabled && (
          <SocialLinks hasLinks={socialFields.length} isOwnProfile={isOwnProfile()}>
            {socialFields.map((key) => (
              <LinkButton key={key} href={getExternalLink(socialLinks?.[key]?.link)} target="_blank">
                <img src={`/images/social-links/${key}.svg`} alt={key} width="20" height="20" />
              </LinkButton>
            ))}

            {isOwnProfile() && (
              <AddLinkButton onClick={() => setIsSocialPanelOpen(true)}>
                <AddIcon />
                <AddLinkText>Add social link</AddLinkText>
              </AddLinkButton>
            )}
          </SocialLinks>
        )}
      </SidebarPanelFieldWrapper>

      <div style={{ display: "flex", gap: "5px" }}>
        <Button onClick={copyLinKToClipboard}>
          <CopyIcon primaryColor={theme.shared.sidebar.iconColor} />
          Copy profile link
        </Button>
      </div>
    </Container>
  );
}

export default SidebarPanel;
