import { MenuStyleTypes } from "../../../../menu/types";
import Denver from "./entries/Denver";
import Houston from "./entries/Houston";
import Hum from "./entries/Hum";
import London from "./entries/London";
import PaloAlto from "./entries/PaloAlto";
import Sydney from "./entries/Sydney";

export const EditEntriesComponents = {
  [MenuStyleTypes.LONDON]: London,
  [MenuStyleTypes.SYDNEY]: Sydney,
  [MenuStyleTypes.PALO_ALTO]: PaloAlto,
  [MenuStyleTypes.HOUSTON]: Houston,
  [MenuStyleTypes.DENVER]: Denver,
  [MenuStyleTypes.HUM]: Hum,
};
