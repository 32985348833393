import axios from "axios";

const httpClient = axios.create();
httpClient.interceptors.request.use(
  async function (config) {
    let jwt = await window.AP.context.getToken();
    if (jwt === "") {
      jwt = new URLSearchParams(window.location.search).get("jwt") || "";
    }
    if (jwt === "") {
      console.warn(`Could not get JWT from URL ${window.location}! Backend will not accept occurring requests.`);
    }
    config.headers.Authorization = `JWT ${jwt}`;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default httpClient;
