import React from "react";
import shallow from "zustand/shallow";
import { useWidgetsStore } from "../../../widgets-store";
import { Column, ContentColumns } from "../../shared/styled";
import InformationPanel, { StyledContent } from "../../shared/InformationPanel";
import WidgetActivator from "../../shared/WidgetActivator";
import { WidgetIds } from "../../../consts/tabs";
import FieldSelector from "../../shared/FieldSelector";
import UserSelector from "../../shared/UserSelector";
import { OrgChartPropertyKeys } from "./available-properties";
import DesignOptions from "./DesignOptions";
import Preview from "./Preview";
import { FieldType } from "../../../../../../../UserDatabase/field-type";
import { useContentVizConnectionStore } from "../../../../../../../../Shared/store/contentviz-connection-store";

function OrgChart() {
  const { widgetSettings, updateWidgetSettings, isDbInit } = useWidgetsStore(
    (state) => ({
      widgetSettings: state.widgetSettings,
      updateWidgetSettings: state.updateWidgetSettings,
      isDbInit: state.isDbInit,
    }),
    shallow,
  );

  const isContentVizInstalled = useContentVizConnectionStore((state) => state.isContentVizInstalled);

  const orgChartSettings = widgetSettings[WidgetIds.ORG_CHART] || {};

  const { isActive, selectedField, parentUsers } = orgChartSettings;

  const updateOrgChartSettings = (key, value) => {
    const newOrgChartSetting = { ...orgChartSettings, [key]: value };
    updateWidgetSettings(WidgetIds.ORG_CHART, { ...newOrgChartSetting });
  };

  return (
    <ContentColumns>
      <Column>
        <WidgetActivator
          panelTitle="Org Charts"
          isActive={!!isActive}
          setIsActive={(newActiveState) => updateOrgChartSettings(OrgChartPropertyKeys.IS_ACTIVE, newActiveState)}
          isDisabled={!isContentVizInstalled}
        />

        {isActive && isContentVizInstalled && (
          <>
            <FieldSelector
              title="Org Charts"
              selectedField={selectedField}
              onChange={(newField) => updateOrgChartSettings(OrgChartPropertyKeys.SELECTED_FIELD, newField)}
              filterFieldsByTypes={[FieldType.USER]}
            />

            <UserSelector
              title="Top Level"
              description="Define one or more users at the top level who don't have superiors. Employees without selected supervisors in the employee database will not appear in the Org Charts."
              accountIds={parentUsers || []}
              onUpdate={(newUsers) => updateOrgChartSettings(OrgChartPropertyKeys.PARENT_USERS, newUsers || [])}
              isDisabled={!isDbInit}
            />
          </>
        )}
      </Column>

      <Column>
        <InformationPanel
          content={
            <StyledContent
              title="Org Charts"
              description="As a Cosmos admin, you can enable and configure the organizational charts, powered by Pulse. Once enabled, Org Charts will be visible within the people network and user profiles. Choose the employee database column and define one or more users as top level. Be aware that users without selected supervisors in the database will not appear in the Org Charts."
            />
          }
        />

        {isActive && isContentVizInstalled && (
          <>
            <DesignOptions orgChartSettings={orgChartSettings} updateOrgChartSettings={updateOrgChartSettings} />

            <Preview orgChartSettings={orgChartSettings} />
          </>
        )}
      </Column>
    </ContentColumns>
  );
}

export default OrgChart;
