import create from "zustand";
import { SortTypes, ViewTypes } from "./types";

export const useSpaceDirectoryStore = create((set, get) => ({
  selectedView: ViewTypes.GRID,
  setSelectedView: (v) => set((state) => ({ selectedView: v })),
  selectedSort: SortTypes.NEWEST,
  setSelectedSort: (v) => set((state) => ({ selectedSort: v })),
  selectedFilter: undefined,
  setSelectedFilter: (v) => set((state) => ({ selectedFilter: v })),

  updatingSpace: undefined,
  setUpdatingSpace: (v) => set((state) => ({ updatingSpace: v })),
  editSpaceModalOpen: false,
  setEditSpaceModalOpen: (v) => set((state) => ({ editSpaceModalOpen: v })),
}));

export const useSidebarStore = create((set, get) => ({
  isSidebarOpen: false,
  setIsSidebarOpen: (v) => set((state) => ({ isSidebarOpen: v })),
  selectedSidebar: undefined,
  setSelectedSidebar: (v) => set((state) => ({ selectedSidebar: v })),
}));
