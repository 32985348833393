import React, { useEffect, useState } from "react";
import shallow from "zustand/shallow";
import { CreatableSelect } from "@atlaskit/select";
import { nanoid } from "nanoid";
import { useRecordsStore } from "../../../../../../UserDatabase/records-store";
import {
  CustomDropdownIndicator,
  CustomOption,
  Dropdown,
} from "../../../../Shared/CheckboxSelect/CustomCheckboxSelectComponents";
import { selectStylesWithOverrides } from "../../../../Shared/CheckboxSelect/checkbox-select-styles";
import { useTheme } from "styled-components";

export const OptionColors = [
  "#FBD7D4",
  "#FFE6CE",
  "#FFF5C5",
  "#D1FBE0",
  "#C6FFF3",
  "#C3FAFF",
  "#E0EEFF",
  "#EAE6FF",
  "#FFE1FF",
  "#F1F1F6",
];

const MultiSelectEditor = ({ initialValue, field, onEdit }) => {
  const options = field.options || [];

  const [selected, setSelected] = useState();
  const [availableOptions, setAvailableOptions] = useState([]);

  const updateField = useRecordsStore((state) => state.updateField, shallow);

  const theme = useTheme();

  const createOption = (value) => {
    const handleCreate = (label) => {
      const option = {
        id: nanoid(7),
        color: OptionColors[Number(`${options.length}`.slice(-1))],
        label: label,
        position: options.length,
      };

      updateField(field.id, { options: [...options, option] });

      return option;
    };

    return value.map((option) => {
      if (!options.find(({ id }) => option.id === id)) {
        const opt = handleCreate(option.label);
        setAvailableOptions([...availableOptions, { ...opt, value: opt.id }]);
        setSelected([...(selected || []), { ...opt, value: opt.id }]);
        return opt;
      }

      return option;
    });
  };

  useEffect(() => {
    if (options) {
      const mappedOptions = options.map((opt) => ({ ...opt, value: opt.id }));

      if (initialValue) {
        const value = initialValue.map((val) => mappedOptions.find(({ id }) => id === val));

        if (value) {
          setSelected(value);
        }
      } else {
        setSelected(undefined);
      }

      setAvailableOptions(mappedOptions);
    }
  }, []);

  return (
    <Dropdown isOpen onClose={() => {}}>
      <CreatableSelect
        value={selected}
        isValidNewOption={(value) => {
          if (
            value.trim().length === 0 ||
            availableOptions.find(({ label }) => label.toLowerCase() === value.toLowerCase())
          ) {
            return false;
          }

          return field.isCreatable;
        }}
        autoFocus
        menuIsOpen
        isClearable
        isSearchable
        options={availableOptions}
        controlShouldRenderValue={false}
        hideSelectedOptions={false}
        maxMenuHeight={200}
        backspaceRemovesValue
        isOptionSelected={(option) => selected?.some((selectedOption) => selectedOption.id === option.id)}
        isMulti
        components={{
          Option: CustomOption,
          DropdownIndicator: CustomDropdownIndicator,
        }}
        styles={selectStylesWithOverrides({
          control: (provided) => ({
            backgroundColor: `${theme.shared.profile.selectBackground} !important`,
            fontSize: "14px",
            fontWeight: "400",
            lineHeight: 1,
            width: "200px",
            minHeight: "32px",
            maxHeight: "32px",
            color: "#42526E",
            borderColor: "transparent !important",
            borderRadius: "9px",
            padding: "0 4px",
            ...provided,
          }),
        })}
        onBlur={() => {
          if (!selected) {
            onEdit(undefined);
          } else {
            onEdit(selected.filter((val) => val).map(({ id }) => id));
          }
        }}
        onChange={(value) => {
          const values = createOption(value);
          setSelected(values);
        }}
      />
    </Dropdown>
  );
};

export default MultiSelectEditor;
