import styled from "styled-components";

export const GridItems = styled.div`
  display: grid;
  grid-gap: 20px;
  margin-top: 30px;

  ${({ centerItems }) => {
    if (centerItems) {
      return `place-content: center;`;
    } else {
      return `grid-template-columns: repeat(auto-fit, 300px);`;
    }
  }}
`;
