import React from "react";
import styled from "styled-components";
import { ColumnContainer, Ellipse, InlineContainer, LargeLine, MediumLine, Panel, SmallLine, Wrapper } from "./styled";

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
`;

function SideNews() {
  return (
    <Wrapper>
      <Panel>
        <InfoWrapper>
          <InlineContainer>
            <Ellipse bg="#DFE1E6" />
            <SmallLine width={53} bg="#DFE1E6" />
            <SmallLine width={50} bg="#DFE1E6" />
          </InlineContainer>

          <ColumnContainer>
            <MediumLine width={170} bg="rgba(9, 30, 66, 0.25)" />
            <MediumLine width={120} bg="#DFE1E6" />
            <MediumLine width={80} bg="#DFE1E6" />
          </ColumnContainer>

          <InlineContainer style={{ marginTop: "2px" }}>
            <LargeLine width={40} bg="#DEEBFF" />
            <LargeLine width={37} bg="#DEEBFF" />
          </InlineContainer>
        </InfoWrapper>

        <InfoWrapper style={{ marginTop: "20px" }}>
          <InlineContainer>
            <Ellipse bg="#DFE1E6" />
            <SmallLine width={53} bg="#DFE1E6" />
            <SmallLine width={50} bg="#DFE1E6" />
          </InlineContainer>

          <ColumnContainer>
            <MediumLine width={170} bg="rgba(9, 30, 66, 0.25)" />
            <MediumLine width={120} bg="#DFE1E6" />
            <MediumLine width={80} bg="#DFE1E6" />
          </ColumnContainer>

          <InlineContainer style={{ marginTop: "2px" }}>
            <LargeLine width={40} bg="#DEEBFF" />
            <LargeLine width={37} bg="#DEEBFF" />
          </InlineContainer>
        </InfoWrapper>
      </Panel>
    </Wrapper>
  );
}

export default SideNews;
