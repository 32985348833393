/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useEffect } from "react";

const NoPermissions = () => {
  useEffect(() => {
    window.AP.sizeToParent();
  }, []);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        maxWidth: "700px",
        margin: "0 auto",
        marginTop: "200px",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          background: `url(${"https://confluence-v1.prod.atl-paas.net/master/GzzVRzuRDd.svg"}) no-repeat center/contain`,
          width: "40%",
          height: "200px",
          margin: "0 auto",
          marginRight: "50px",
        }}
      />
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div
          css={css`
            margin: 24px 0px;
            font-size: 35px;
            font-weight: 400;
            width: 460px;
            color: rgb(66, 82, 110);
          `}
        >
          You've stumbled on restricted content
        </div>
        <div
          css={css`
            font-size: 14px;
            font-weight: 300;
            color: rgb(66, 82, 110);
          `}
        >
          Only some people can view this. If you think you should have access, try asking the person who sent you here
          for help.
        </div>
      </div>
    </div>
  );
};

export default NoPermissions;
