import React from "react";
import { useNavigationStore } from "../../../../../store";
import { PARENT_ENTRY_IDENTIFIER } from "../../../../../defaults";
import { SidebarTabs, TabTypes } from "../tabs";
import { Box, Flex } from "@atlaskit/primitives";
import NativeTabs from "../../../../../../../Shared/Components/NativeTabs";
import IconTitle from "../shared/IconTitle";
import Design from "./tabs/DesignTab";

function PaloAlto() {
  const { editingEntry } = useNavigationStore((state) => ({
    editingEntry: state.editingEntry,
    updateEntry: state.updateEntry,
  }));

  const isParent = editingEntry.parent === PARENT_ENTRY_IDENTIFIER;

  const [selectedTab, setSelectedTab] = React.useState(TabTypes.LAYOUT);

  return (
    <Box>
      {isParent && <NativeTabs tabs={SidebarTabs} useNative={false} returnSelection={setSelectedTab} />}

      <Box paddingBlockStart={isParent ? "space.300" : "space.0"}>
        {selectedTab === TabTypes.LAYOUT && (
          <Flex direction="column">
            <IconTitle />
          </Flex>
        )}

        {selectedTab === TabTypes.DESIGN && <Design />}
      </Box>
    </Box>
  );
}

export default PaloAlto;
