import styled from "styled-components";
import ToggleUnderConstruction from "./ToggleUnderConstruction";
import Options from "./Options";
import SectionMessageCustom from "../Message";
import Preview from "./Preview";

import { useUnderConstructionStore } from "../../under-construction-store";

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  row-gap: 32px;
`;

function UnderConstruction() {
  const {
    underConstructionActivated,
    setUnderConstructionActivated,
    messageTitle,
    setMessageTitle,
    messageDescription,
    setMessageDescription,
    messageButton,
    setMessageButon,
    messageLink,
    setMessageLink,
  } = useUnderConstructionStore((state) => ({
    underConstructionActivated: state.underConstructionActivated,
    setUnderConstructionActivated: state.setUnderConstructionActivated,
    messageTitle: state.messageTitle,
    setMessageTitle: state.setMessageTitle,
    messageDescription: state.messageDescription,
    setMessageDescription: state.setMessageDescription,
    messageButton: state.messageButton,
    setMessageButon: state.setMessageButon,
    messageLink: state.messageLink,
    setMessageLink: state.setMessageLink,
  }));

  return (
    <Wrapper>
      <ContentWrapper>
        <ToggleUnderConstruction
          underConstructionActivated={underConstructionActivated}
          setUnderConstructionActivated={setUnderConstructionActivated}
        />
        <Options
          isEnabled={underConstructionActivated}
          title={messageTitle}
          description={messageDescription}
          buttonText={messageButton}
          link={messageLink}
          setTitle={setMessageTitle}
          setDescription={setMessageDescription}
          setButtonText={setMessageButon}
          setLink={setMessageLink}
        />
      </ContentWrapper>

      <ContentWrapper>
        <SectionMessageCustom
          title={"Under Construction"}
          description={
            "While this mode is enabled, Cosmos administrators can view and configure the content, but regular users will not see the work-in-progress. Instead, they will be presented with a customisable message indicating that the intranet is currently under construction."
          }
        />
        <Preview
          title={messageTitle}
          description={messageDescription}
          buttonText={messageButton}
          link={messageLink}
          isEnabled={underConstructionActivated}
        />
      </ContentWrapper>
    </Wrapper>
  );
}

export default UnderConstruction;
