import React from "react";
import ArrowRightIcon from "@atlaskit/icon/glyph/arrow-right";
import styled from "styled-components";
import { MediumLine } from "./styled";

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  column-gap: 14px;
`;

const CardWrapper = styled.div`
  border-radius: 4px;
  border: 0.5px solid #d8dce3;
  background: #fff;
  box-shadow: 0px 0.5473684072494507px 3.284210443496704px 0px rgba(68, 68, 68, 0.12);
  overflow: hidden;

  display: flex;
  flex-direction: column;
`;

const CardImage = styled.img`
  height: 50px;
  width: 100%;
  object-fit: cover;
`;

const CardContent = styled.div`
  padding: 20px 16px;
  display: flex;
  flex-direction: column;
`;

const SmallIcon = styled.div`
  display: grid;
  place-content: center;
  width: 24px;
  height: 24px;
  align-self: flex-end;
  margin-top: 8px;
`;

function ImageCards() {
  return (
    <Grid>
      <CardWrapper>
        <CardImage src="https://img.freepik.com/free-photo/low-angle-shot-modern-building-facade_181624-49557.jpg?w=200" />

        <CardContent>
          <MediumLine width={72} bg="#E4E4E4" style={{ marginTop: "15px" }} />
          <MediumLine width={64} bg="#E4E4E4" style={{ marginTop: "8px" }} />

          <SmallIcon>
            <ArrowRightIcon primaryColor="#00A59B" />
          </SmallIcon>
        </CardContent>
      </CardWrapper>

      <CardWrapper>
        <CardImage src="https://img.freepik.com/free-photo/low-angle-shot-steel-cables-with-clear-blue-sky_181624-21922.jpg?w=200" />

        <CardContent>
          <MediumLine width={72} bg="#E4E4E4" style={{ marginTop: "15px" }} />
          <MediumLine width={64} bg="#E4E4E4" style={{ marginTop: "8px" }} />

          <SmallIcon>
            <ArrowRightIcon primaryColor="#FF9458" />
          </SmallIcon>
        </CardContent>
      </CardWrapper>

      <CardWrapper>
        <CardImage src="https://images.unsplash.com/photo-1560840067-ddcaeb7831d2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=640&h=480&q=80" />

        <CardContent>
          <MediumLine width={72} bg="#E4E4E4" style={{ marginTop: "15px" }} />
          <MediumLine width={64} bg="#E4E4E4" style={{ marginTop: "8px" }} />

          <SmallIcon>
            <ArrowRightIcon primaryColor="#FF991F" />
          </SmallIcon>
        </CardContent>
      </CardWrapper>
    </Grid>
  );
}

export default ImageCards;
