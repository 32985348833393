import mixpanel from "mixpanel-browser";
import { mixpanelConfig } from "./analytics-config/config";

const isProduction = process.env.REACT_APP_ANALYTICS_ENV === "production";

if (isProduction) {
  mixpanel.init(mixpanelConfig.apiKey, {
    ip: false,
  });
}

const templates = {
  load: (name) => `${name} loaded`,
  sync: (name) => `${name} synced`,
  insert: (name) => `${name} inserted`,
  create: (name) => `${name} created`,
  edit: (name) => `${name} edited`,
  default: (name) => `${name}`,
};

export const analytics = {
  logEvent: ({ name, template = "default", params = {} }) => {
    if (!isProduction) return;
    const host = window.AP._hostOrigin;

    if (!name?.length) throw new Error("Can not log analytics data without name.");

    try {
      mixpanel.track(templates[template](name), { ...params, host });
    } catch (error) {
      console.log("Failed to log event.");
    }
  },
};
