import React, { useEffect } from "react";
import Spinner from "@atlaskit/spinner";
import { useInfiniteQuery } from "react-query";
import { SpinnerContainer } from "../../../styled/pages";
import { getLatestActivityContent, mapSpace } from "../apiSpaces";
import { SectionWrapper, SmallTitle, Subtitle } from "../styled/space";
import { SpaceCardsGrid, SpaceCardsList } from "../Templates/SpaceCards";
import { TemplateType } from "../Templates/template-type";
import { ViewType } from "../Templates/view-type";
import { useSpaceDirectoryStore } from "../space-directory-store";

const viewCardTemplates = {
  [ViewType.GRID]: SpaceCardsGrid,
  [ViewType.LIST]: SpaceCardsList,
};

function LatestActivity() {
  const selectedView = useSpaceDirectoryStore((state) => state.selectedView);

  const { isError, data, error, isLoading, hasNextPage, fetchNextPage, refetch } = useInfiniteQuery(
    ["latest-activity-space", { limit: 12 }],
    getLatestActivityContent,
    {
      retry: 0,
      cacheTime: 0,
      select: (response) => {
        const pages = [];
        response.pages.forEach((page) => {
          const body = JSON.parse(page.body);
          const results = body.results.map(({ space }) => mapSpace(space, true));
          pages.push({ ...body, results });
        });
        return { pages, pageParams: response.pageParams };
      },
      getNextPageParam: (lastPage) => {
        const body = JSON.parse(lastPage.body);
        if (body._links.next) {
          const urlParams = new URLSearchParams(encodeURI(body._links.next));
          const cursor = urlParams.get("cursor");
          return cursor;
        }
        return false;
      },
    }
  );

  useEffect(() => {
    window.AP.events.on("space-content-updated", () => refetch());
  }, []);

  if (isError || error) return <div>Something went wrong. Please refresh and try again.</div>;

  const Template = viewCardTemplates[selectedView];

  return (
    <SectionWrapper>
      <SmallTitle>Latest activity</SmallTitle>

      {isLoading ? (
        <SpinnerContainer>
          <Spinner size="xlarge" />
        </SpinnerContainer>
      ) : (
        <Template
          data={data.pages}
          template={TemplateType.SPACE_SIMPLE_CARD}
          paginated
          hasNextPage={hasNextPage}
          fetchNextPage={fetchNextPage}
        />
      )}
    </SectionWrapper>
  );
}

export default LatestActivity;
