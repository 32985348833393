import httpClient from "../../../../../../Service/http-client";

export async function getCosmosAdmins({ queryKey }) {
  const url = `ac/confluence/api/admin/cosmos-admins`;
  return httpClient.get(url);
}

export async function saveCosmosAdmins(users) {
  const url = `ac/confluence/api/admin/cosmos-admins`;
  return httpClient.post(url, { data: users });
}

export async function fetchDetailsForUsers(accountIds) {
  try {
    const url = `/rest/api/search?cql=user.accountId in (${accountIds.map((id) => `"${id}"`).join(",")})&limit=50`;
    return window.AP.request(url);
  } catch (error) {
    return undefined;
  }
}
