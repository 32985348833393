import styled from "styled-components";

export const ViewCreateWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-self: flex-end;
`;

export const SortSelectWrapper = styled.div`
  width: 150px;
  justify-self: flex-end;

  .sort-select__control {
    background: rgba(9, 30, 66, 0.04);
    border-radius: 3px;

    .sort-select__single-value {
      font-weight: 400;
      font-size: 14px;
      color: #42526e;
    }
  }

  .sort-select__option {
    font-size: 14px;
  }
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: ${(props) => props.gridGap || 20}px;
  margin-bottom: ${(props) => props.gridGap || 20}px;
`;

export const ListGridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  grid-gap: 20px;

  & > a:last-child > div:first-child {
    border-bottom: 0px;
  }
`;

export const MagazineGridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-column-gap: ${(props) => props.gridGap || 20}px;
  grid-row-gap: ${(props) => props.gridGap || 20}px;
  height: 500px;
  .child {
    > div,
    > a > div {
      height: calc(100% - 35px) !important;
    }
  }
  .grid-large-story-child-1 {
    grid-area: 1 / 1 / 5 / 3;
  }
  .grid-large-story-child-2 {
    grid-area: 1 / 3 / 3 / 5;
  }
  .grid-large-story-child-3 {
    grid-area: 3 / 3 / 5 / 5;
  }

  .grid-large-story-alt-child-1 {
    grid-area: 1 / 1 / 3 / 3;
  }

  .grid-large-story-alt-child-2 {
    grid-area: 3 / 1 / 5 / 3;
  }

  .grid-large-story-alt-child-3 {
    grid-area: 1 / 3 / 5 / 5;
  }

  .grid-child-3 {
    display: none;
  }
`;

export const ListDateGroupWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  column-gap: 30px;
  margin: ${({ isFirstPage }) => (isFirstPage ? "0px 0px 15px 0px" : "35px 0px 15px 0px")};
`;

export const ListDateTitle = styled.span`
  width: fit-content;
  font-weight: 600;
  font-size: 12px;
  line-height: 13px;
  color: #b3bac5;
`;

export const ListDateSeparator = styled.div`
  flex: 1;
  border-bottom: 1px solid ${({ borderColor }) => borderColor};
`;

export const FilterSidebarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  span {
    flex-grow: 0;
  }
`;

export const PrimaryButton = styled.div`
  display: grid;
  place-content: center;
  width: 100%;
  box-sizing: border-box;
  padding: 10px 12px;
  background: ${({ primaryColor }) => primaryColor};
  border-radius: 3px;
  color: ${({ theme }) => theme.shared.news.primaryButtonColor};
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }
`;

export const FiltersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
`;
