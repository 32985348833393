import create from "zustand";

export const useCategoriesStore = create((set, get) => ({
  selectedTab: { id: "dynamic", label: "Filters" },
  setSelectedTab: (selectedTab) => set((state) => ({ selectedTab })),

  allCategories: [],
  setAllCategories: (allCategories) => set((state) => ({ allCategories })),

  categoriesBlogCountData: [],
  setCategoriesBlogCountData: (categoriesBlogCountData) => set((state) => ({ categoriesBlogCountData })),

  selectedCategory: undefined,
  setSelectedCategory: (selectedCategory) => set((state) => ({ selectedCategory })),
}));

export const useProposalsStore = create((set, get) => ({
  selectedTab: undefined,
  setSelectedTab: (selectedTab) => set((state) => ({ selectedTab })),

  allProposals: [],
  setAllProposals: (allProposals) => set((state) => ({ allProposals })),

  allUsersInProposals: [],
  setAllUsersInProposals: (allUsersInProposals) => set((state) => ({ allUsersInProposals })),
}));

export const useModalStore = create((set, get) => ({
  isModalOpen: false,
  setIsModalOpen: (isModalOpen) => set((state) => ({ isModalOpen })),

  modalKey: undefined,
  setModalKey: (modalKey) => set((state) => ({ modalKey })),

  isDeleteModalOpen: false,
  setIsDeleteModalOpen: (isDeleteModalOpen) => set((state) => ({ isDeleteModalOpen })),
}));
