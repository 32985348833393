import React from "react";
import styled from "styled-components";
import TitleWithBack from "../../Shared/Components/TitleWithBack";
import { usePageStore } from "../Hub/page-store";
import { Pages } from "../Hub/pages";

const CollectionHeader = styled.div`
  width: calc(100% - 60px);
  padding: 35px 30px 40px 30px;
  border-bottom: 2px solid ${({ theme }) => theme.shared.settings.divider};
`;

const Description = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #8993a4;
  margin-top: 12px;
  margin-bottom: 0px;
`;

function Header() {
  const setSelectedPage = usePageStore((state) => state.setSelectedPage);

  return (
    <CollectionHeader>
      <div style={{ display: "flex", alignItems: "center", columnGap: "16px" }}>
        <TitleWithBack handleOnClick={() => setSelectedPage(Pages.SETTINGS)} title="Collections" />
      </div>
      <Description>
        Create people collections based on filters available in the Database and Confluence Groups.
      </Description>
    </CollectionHeader>
  );
}

export default Header;
