import React from "react";
import styled from "styled-components";
import Select from "@atlaskit/select";
import { SIMPLE_SELECT_STYLES, selectStylesWithOverrides } from "../Dashboard/filter/CustomSelectStyles";

const SortSelectWrapper = styled.div`
  /* width: 175px; */

  .sort-select__control {
    min-height: 32px;
    height: 32px;
    background: rgba(9, 30, 66, 0.04);
    border-radius: 3px;
    border-color: rgba(9, 30, 66, 0.04);

    .sort-select__value-container {
      position: unset;
      overflow: hidden;
      box-sizing: border-box;
      height: 32px;
      padding-left: 6px;
      padding-right: 6px;
      padding-bottom: 0px;
      padding-top: 0px;
    }

    .sort-select__indicator {
      padding-top: 0px;
      padding-bottom: 3px;
    }

    .sort-select__single-value {
      font-weight: 400;
      font-size: 14px;
      color: #42526e;
    }

    .sort-select__placeholder {
      font-size: 14px;
    }
  }

  .sort-select__option {
    font-size: 14px;
  }
`;

const DEFAULT_SORT_OPTIONS = [
  { label: "Name • A - Z", value: "name-az" },
  { label: "Name • Z - A", value: "name-za" },
];

function SortSelect({
  placeholder = "Sort by",
  selectedOption = DEFAULT_SORT_OPTIONS[0].value,
  onChange,
  options = DEFAULT_SORT_OPTIONS,
  isDisabled = false,
  isSearchable = true,
}) {
  return (
    <Select
      value={options.find((op) => op.value === selectedOption)}
      options={options}
      onChange={(so) => onChange(so?.value || undefined)}
      classNamePrefix="sort-select"
      placeholder={placeholder}
      isClearable={false}
      isSearchable={isSearchable}
      isDisabled={isDisabled}
      styles={SIMPLE_SELECT_STYLES}
    />
  );
}

export default SortSelect;
