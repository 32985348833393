import React, { useContext, useEffect, useRef, useState } from "react";
import { BackgroundDialogContext } from "../Context";
import { ImageLinkPreview, VideoPreview } from "../styled/StyledComponents";
import { IMAGE_TYPES } from "../ImageSourceTypes";
import ImageEditor from "./ImageEditor/ImageEditor";

function ImagePreview({ moveableImage }) {
  const { image, setImage } = useContext(BackgroundDialogContext);

  const [isInit, setIsInit] = useState(false);

  const [crop, setCrop] = useState({ x: 0, y: 0 });

  const imageRef = useRef();
  imageRef.current = image;

  useEffect(() => {
    if (!isInit && image?.crop) {
      setCrop(image?.crop || { x: 0, y: 0 });

      setIsInit(true);
    }
  }, [image]);

  const handleChangeInCroppedArea = (v) => {
    if (imageRef?.current) {
      setImage({ ...imageRef.current, croppedAreaPixels: v });
    }
  };

  return (
    <>
      {!Object.keys(image || {}).length && <div style={{ padding: "15px 0px" }}>Select an image to see preview</div>}

      {image?.source === IMAGE_TYPES.VIDEO && (
        <VideoPreview autoPlay muted loop>
          <source src={image.link} type="video/mp4" />
        </VideoPreview>
      )}

      {image?.source === IMAGE_TYPES.COLOR && (
        <div
          style={{
            backgroundColor: `${image.value}`,
            width: "500px",
            height: "250px",
          }}
        />
      )}

      {image?.source === IMAGE_TYPES.GRADIENT && (
        <div
          style={{
            background: `linear-gradient(90deg, ${image.value.firstColor} ${image.value.firstColorPosition}%, ${image.value.secondColor} ${image.value.secondColorPosition}%)`,
            width: "500px",
            height: "250px",
          }}
        />
      )}

      {image?.source === IMAGE_TYPES.ATTACHMENTS && <ImageLinkPreview link={image.link} />}

      {(image?.source === IMAGE_TYPES.UNSPLASH || image?.source === IMAGE_TYPES.LINK) && (
        <>
          <div style={{ position: "absolute", width: "100%", height: "100%" }} />
          {moveableImage ? (
            <>
              <ImageEditor
                image={image}
                aspectRatio={{ width: 20, height: 5 }}
                crop={crop}
                setCrop={setCrop}
                zoom={image?.zoom || 1}
                setZoom={(v) => setImage({ ...imageRef.current, zoom: v })}
                setCroppedAreaPixels={handleChangeInCroppedArea}
              />
            </>
          ) : (
            <ImageLinkPreview link={image.link} />
          )}
        </>
      )}
    </>
  );
}

export default ImagePreview;
