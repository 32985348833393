import React from "react";
import SizeButtons from "../../../../../../../Shared/Components/SizeButtons";
import {
  DesignSidebarWrapper,
  LabeledContainer,
  LabeledTitle,
  TabContent,
  TextInputWrapper,
} from "../../../styled/sidebar";
import TextField from "@atlaskit/textfield";
import TextArea from "@atlaskit/textarea";
import LabelMultiSelect from "../../../../Dashboard/filter/LabelMultiSelect";
import SpaceMultiSelect from "../../../../Dashboard/filter/SpaceMultiSelect";
import PeoplePicker from "../../../../Dashboard/filter/PeoplePicker";
import { PanelTypes } from "../../panelTypes";
import { sizeType } from "../../../../../../../Shared/Components/size-options";

const LayoutSidebar = ({ panel, updatePanelDataProperty }) => {
  return (
    <div>
      <TabContent>
        <LabeledContainer>
          <LabeledTitle>Title</LabeledTitle>
          <TextInputWrapper>
            <TextField
              value={panel.data.title}
              placeholder="Enter title.."
              type="text"
              onChange={({ target: { value } }) => updatePanelDataProperty({ id: panel.id, property: "title", value })}
            />
          </TextInputWrapper>
        </LabeledContainer>

        <LabeledContainer>
          <LabeledTitle>Description</LabeledTitle>
          <TextArea
            resize="none"
            minimumRows={5}
            placeholder="Enter description.."
            value={panel.data.description}
            onChange={({ target: { value } }) =>
              updatePanelDataProperty({ id: panel.id, property: "description", value })
            }
          />
        </LabeledContainer>

        <LabelMultiSelect
          containerStyle={{ marginTop: "0" }}
          label="Labels"
          placeholder="Filter by labels"
          initialLabels={panel.data.labels}
          onUpdate={(labels) => {
            updatePanelDataProperty({ id: panel.id, property: "labels", value: labels });
          }}
        />
        <SpaceMultiSelect
          containerStyle={{ marginTop: "0" }}
          initialSpaces={panel.data.spaces}
          isDynamic={true}
          onUpdate={(spaces) => updatePanelDataProperty({ id: panel.id, property: "spaces", value: spaces })}
        />
        <PeoplePicker
          containerStyle={{ marginTop: "0" }}
          isMulti
          initialAccountIDs={panel.data.contributors}
          label="Contributors"
          placeholder="Filter by contributors"
          onUpdate={(users) => updatePanelDataProperty({ id: panel.id, property: "contributors", value: users })}
        />
      </TabContent>
    </div>
  );
};

const DesignSidebar = ({ panel, updatePanelDataProperty }) => {
  return (
    <DesignSidebarWrapper>
      <TabContent>
        <SizeButtons
          label="Profile image size"
          types={{ panel: PanelTypes.BLOG, size: sizeType.PROFILE_IMAGE_SIZE }}
          input={{ min: 0, max: 86, step: 2, value: panel.data.profileImageSize }}
          setValue={(value) => updatePanelDataProperty({ id: panel.id, property: sizeType.PROFILE_IMAGE_SIZE, value })}
        />
      </TabContent>
    </DesignSidebarWrapper>
  );
};

const sidebars = {
  Layout: LayoutSidebar,
  Design: DesignSidebar,
};

const UserBlogPostsEdit = ({ panel, selectedTab, updatePanelDataProperty }) => {
  const Component = sidebars[selectedTab];

  return <Component panel={panel} updatePanelDataProperty={updatePanelDataProperty} />;
};

export default UserBlogPostsEdit;
