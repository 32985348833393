import { ModalFooter } from "@atlaskit/modal-dialog";
import SectionMessage from "@atlaskit/section-message";
import { Button, ModalBody, ModalHeader, ModalTitle } from "./styled";
import { useHelpersStore, useNavigationStore } from "../../store";
import { MenuStyleTypes } from "../../menu/types";
import { PARENT_ENTRY_IDENTIFIER } from "../../defaults";

export const DeleteEntry = () => {
  const { closeModal, deletingEntry, setDeletingEntry } = useHelpersStore((state) => ({
    closeModal: state.closeModal,
    deletingEntry: state.deletingEntry,
    setDeletingEntry: state.setDeletingEntry,
  }));

  const { findParentEntry, deleteEntry } = useNavigationStore((state) => ({
    findParentEntry: state.findParentEntry,
    deleteEntry: state.deleteEntry,
  }));

  const parentEntry =
    deletingEntry && deletingEntry?.parent !== PARENT_ENTRY_IDENTIFIER
      ? findParentEntry(deletingEntry?.parent)
      : undefined;

  const hasChildren = !!deletingEntry?.children?.length;
  const isHumStyle = [MenuStyleTypes.HUM].includes(parentEntry?.menuStyle);

  return (
    <>
      <ModalHeader>
        <ModalTitle>{hasChildren ? `Delete entry` : `Delete subentry`}</ModalTitle>
      </ModalHeader>
      <ModalBody>
        {isHumStyle && <SectionMessage appearance="warning">Menu entry will be deleted.</SectionMessage>}
        {hasChildren && !isHumStyle && (
          <SectionMessage appearance="warning">All subentries will be deleted.</SectionMessage>
        )}
        {!hasChildren && !isHumStyle && <div>Are you sure you want to delete this subentry?</div>}
      </ModalBody>
      <ModalFooter>
        <Button bg="#F1F2F4" color="#42526E" onClick={() => closeModal()}>
          Cancel
        </Button>
        <Button
          bg="#DE350B"
          color="#fff"
          onClick={() => {
            deleteEntry(deletingEntry.id);
            setDeletingEntry(undefined);
            closeModal();
          }}
          appearance="danger"
        >
          Delete
        </Button>
      </ModalFooter>
    </>
  );
};
