import { createDefaultNavigation } from "./create-navigation";

const CURRENT_VERSION = 2;

export const convertNavigationStructureToLatestVersion = (navigationMenu) => {
  if (!navigationMenu) return;

  if (navigationMenu.version === CURRENT_VERSION) {
    return navigationMenu;
  }

  const parsedStructure = JSON.parse(navigationMenu?.publishedStructure || "[]");

  let newStructure = [];
  if (parsedStructure?.length) {
    newStructure = parsedStructure.map((x) => ({
      ...x,
      menuStyle: x.menuStyle,
      data: {
        ...x.data,
        displayIcons: x.parent === "0" ? true : undefined,
        displayDescription: x.parent === "0" ? true : undefined,
        link: Object.keys(x?.data?.link || {}).length
          ? x.parent === "0"
            ? [{ ...x.data.link, linkName: x.data.link.title, position: 1 }]
            : x.data.link
          : [],
        linksCount: Object.keys(x?.data?.link || {}).length ? 1 : 0,
        colors:
          x.parent === "0"
            ? {
                subentryBackgroundHoverColor: navigationMenu?.colors?.subentryBackgroundHoverColor,
                subentryIconColor: navigationMenu?.colors?.subentryIconColor,
                subentryTextColor: navigationMenu?.colors?.subentryTextColor,
              }
            : undefined,
      },
    }));
  } else {
    newStructure = createDefaultNavigation();
  }

  const convertedNavigation = {
    ...navigationMenu,
    publishedStructure: JSON.stringify([...newStructure]),
    version: CURRENT_VERSION,
  };

  return convertedNavigation;
};
