import React, { useEffect, useState } from "react";
import Textfield from "@atlaskit/textfield";
import Button from "@atlaskit/button";
import styled from "styled-components";
import { Label } from "./styled";
import { usePanelsStore } from "../store/panels-store";
import { setMacroSuiteStyleguide } from "../api";

const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const AppWrapperButtons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
`;

function SidebarAppAddPicker({ customIcons, setCustomIcons, updateIcon, dialogAdd }) {
  const [titleInput, setTitleInput] = useState("");
  const [sourceInput, setSourceInput] = useState("");

  useEffect(() => {
    if (!dialogAdd) {
      setTitleInput(updateIcon?.title);
      setSourceInput(updateIcon?.path);
    } else {
      setTitleInput("");
      setSourceInput("");
    }
  }, [dialogAdd, updateIcon]);

  const saveAppIcons = async (data) => {
    setMacroSuiteStyleguide("appicons", data)
      .then(() => {})
      .catch(() =>
        window.AP.flag.create({
          title: "Failed to save changes",
          body: "Something went wrong, and your changes weren't saved. Please refresh and try again.",
          type: "error",
          close: "auto",
        })
      );
  };

  const addAppIcon = (title, path) => {
    const newAppIcons = [{ id: Math.random().toString(36).slice(2, 9), title, path }, ...customIcons];
    setCustomIcons([...newAppIcons]);
    saveAppIcons(newAppIcons);
  };

  const updateAppIcon = (id, title, path) => {
    const copyAppIcons = [...customIcons];
    const index = copyAppIcons.findIndex((appIcon) => appIcon.id === id);
    copyAppIcons[index] = { id, title, path };

    setCustomIcons([...copyAppIcons]);
    saveAppIcons([...copyAppIcons]);
  };

  const { setIsEditingAppAddIcon } = usePanelsStore((state) => ({
    setIsEditingAppAddIcon: state.setIsEditingAppAddIcon,
  }));

  return (
    <>
      <AppWrapper>
        <div>
          <Label>Title</Label>
          <Textfield
            maxLength={25}
            value={titleInput}
            onChange={(e) => setTitleInput(e.target.value)}
            placeholder="Name your App"
            className="textinput-caelor"
          />
        </div>
        <div>
          <Label>URL</Label>
          <Textfield
            value={sourceInput}
            onChange={(e) => setSourceInput(e.target.value)}
            placeholder="Insert App source link"
            className="textinput-caelor"
          />
        </div>
        <AppWrapperButtons>
          <Button
            shouldFitContainer
            appearance="primary"
            className="button-caelor"
            onClick={() => {
              dialogAdd ? addAppIcon(titleInput, sourceInput) : updateAppIcon(updateIcon?.id, titleInput, sourceInput);
              setIsEditingAppAddIcon(false);
            }}
          >
            {dialogAdd ? "Add new" : "Update"}
          </Button>
          <Button
            shouldFitContainer
            appearance="default"
            className="button-caelor"
            onClick={() => {
              setIsEditingAppAddIcon(false);
            }}
          >
            Cancel
          </Button>
        </AppWrapperButtons>
      </AppWrapper>
    </>
  );
}

export default SidebarAppAddPicker;
