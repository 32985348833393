import React from "react";
import { EditorBackground } from "../styled";
import CosmosDatePicker from "../../../../../../../Shared/Components/DatePicker/CosmosDatePicker";

const Date = ({ initialValue, onEdit, onAbort, useDayjs, maxDate, minDate }) => {
  return (
    <EditorBackground style={{ padding: "0 2px" }}>
      <CosmosDatePicker
        initialValue={initialValue}
        onEdit={onEdit}
        onAbort={onAbort}
        maxDate={maxDate}
        minDate={minDate}
        useDayjs={useDayjs}
      />
    </EditorBackground>
  );
};

export default Date;
