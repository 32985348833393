import React from "react";
import styled, { useTheme } from "styled-components";
import Button from "@atlaskit/button";
import TitleWithBack from "../../../Shared/Components/TitleWithBack";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
`;

const Header = styled.div`
  margin: 35px 30px;
  padding-bottom: 5px;
  border-bottom: 1px solid ${({ theme }) => theme.apps.seperator};
  display: flex;
  align-items: center;
  column-gap: 16px;
`;

const Content = styled.div`
  display: grid;
  justify-items: center;
  text-align: center;
  height: 100%;
  width: 100%;
  padding-top: 50px;
`;

const Heading = styled.p`
  font-weight: 500;
  font-size: 29px;
  line-height: 32px;
  color: ${({ color }) => color};
  margin-top: 30px;
  margin-bottom: 12px;
`;

const Description = styled.p`
  width: 435px;
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${({ color }) => color};

  .launch-link {
    cursor: pointer;
    color: #0c66e4;
  }
`;

const SyncButton = styled.button`
  all: unset;
  border-radius: 4px;
  width: 135px;
  height: 32px;
  background: ${({ bg }) => bg};
  color: ${({ color }) => color};
  font-size: 14px;
  cursor: pointer;
  text-align: center;
`;

const EmptyState = ({ setUserSyncModalVisible, onButtonClick, onGoBack }) => {
  const theme = useTheme();

  const openSyncModal = () => {
    setUserSyncModalVisible(true);
  };

  return (
    <Container>
      <Header>
        <TitleWithBack
          handleOnClick={onGoBack}
          title="Employee Database"
          description="Customize and personalize your intranet experience."
        />
      </Header>

      <Content>
        <img width="525" height="184.5" src={`/images/database/database-empty-state-${theme.global.name}.svg`} alt="" />
        <div style={{ paddingBottom: "350px" }}>
          <Heading color={theme.global.text.title}>Sync Employee Database</Heading>

          <Description color={theme.global.secondary}>
            <span>
              Sync your employee database with active Confluence users. Once completed, you can connect Azure Active
              Directory to enhance integration. For detailed steps,
            </span>

            <span className="launch-link" onClick={() => onButtonClick(true)}>
              {" check our app documentation."}
            </span>
          </Description>

          <Button
            appearance="primary"
            onClick={openSyncModal}
            style={{ fontSize: "13px", marginTop: "40px", fontWeight: 550 }}
          >
            Sync Employee Database
          </Button>
        </div>
      </Content>
    </Container>
  );
};

export default EmptyState;
