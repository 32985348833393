/* eslint-disable react/display-name */

import { memo, useLayoutEffect, useState, useEffect } from "react";
import { createPortal } from 'react-dom';
import { bool, node } from 'prop-types';

const PORTAL_ID = "portal";

const Portal = memo(({ children, contentVisible }) => {
  const [wrapperElement, setWrapperElement] = useState(null);

  useLayoutEffect(() => {
    const element = document.getElementById(PORTAL_ID);
    setWrapperElement(element);
  }, [PORTAL_ID]);

  useEffect(() => {
    if (wrapperElement && !contentVisible) {
      wrapperElement.replaceChildren();
    }
  }, [contentVisible]);

  if (wrapperElement === null || !contentVisible) {
    return null;
  }

  return createPortal(children, wrapperElement);
});

Portal.propTypes = {
  children: node.isRequired,
  contentVisible: bool.isRequired,
};

export default Portal;
