import styled from "styled-components";
import { ActionsWrapper } from "./content/Actions";

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-gap: 24px;

  ${({ isPositionedInSide }) => {
    if (isPositionedInSide) {
      return "grid-template-columns: repeat(1, minmax(0, 1fr));";
    }
    return `
      grid-template-columns: repeat(3, minmax(0, 1fr)); 
    
      @media (max-width: 1220px) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }
      @media (max-width: 820px) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
      }
    `;
  }}
`;

export const CardWrapper = styled.div`
  background: ${({ theme }) => theme.shared.card.background};
  border: 1px solid ${({ theme }) => theme.shared.card.border.box};
  box-shadow: 0px 0px 4px rgba(85, 85, 85, 0.06);
  border-radius: ${({ borderRadius }) => `${borderRadius}`};
  overflow: hidden;

  transition: box-shadow 200ms linear;

  &:hover {
    box-shadow: 0px 0px 4px rgba(85, 85, 85, 0.26);

    ${ActionsWrapper} {
      opacity: 1;
    }
  }
`;

export const ListContentWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 56px;
  column-gap: 12px;
  align-items: flex-start;
`;

export const ContentWrapper = styled.div`
  box-sizing: border-box;
  padding: 20px;
  display: grid;
  row-gap: 16px;
`;

export const NameWrapper = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: auto 56px;
  column-gap: 8px;
`;

export const Name = styled.span`
  font-size: 20px;
  font-weight: 500;
  color: ${({ theme }) => theme.shared.card.title};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const PageName = styled.span`
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: ${({ theme }) => theme.shared.settings.general.admin.emptyMessageColor};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    color: ${({ theme }) => theme.confirmDeleteModal.primaryBackground};
  }
`;

export const InlineLink = styled.a`
  text-decoration: none;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  max-width: 100%;

  display: flex;
  align-items: center;
  width: fit-content;

  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: ${({ theme }) => theme.shared.settings.general.admin.emptyMessageColor};

  .title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:hover {
    color: ${({ theme }) => theme.confirmDeleteModal.primaryBackground};
  }
`;

export const DateInfo = styled.span`
  font-size: 11px;
  font-weight: 400;
  line-height: 14px;
  color: ${({ theme }) => theme.shared.settings.general.admin.labelColor};
`;

export const ImagePlaceholder = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 158px;
  display: grid;
  place-content: center;
`;

export const Inline = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
`;

export const ListImagePlaceholder = styled.div`
  width: 40px;
  height: 40px;
`;

export const ExpandIcon = styled.div`
  display: grid;
  place-content: center;

  opacity: 0;
  position: absolute;
  top: 12px;
  right: 12px;
  padding: 4px;
  border-radius: 3px;

  cursor: pointer;

  background-color: ${({ theme }) => theme.shared.form.button.background.inactive};

  &:hover {
    background-color: ${({ theme }) => theme.shared.form.button.background.hover};
  }
  &:active {
    background-color: ${({ theme }) => theme.shared.form.button.background.active};
  }

  transition: opacity 150ms linear;
`;

export const ImageContainer = styled.div`
  position: relative;
`;

export const Image = styled.img`
  box-sizing: border-box;
  width: 100%;
  height: 156px;
  object-fit: contain;
  cursor: pointer;
`;

export const ListImage = styled(Image)`
  width: 40px;
  height: 40px;
`;

export const ListViewWrapper = styled.div`
  background: ${({ theme }) => theme.shared.card.background};
  border: 1px solid ${({ theme }) => theme.shared.card.border.box};
  box-shadow: 0px 0px 4px rgba(85, 85, 85, 0.06);
  border-radius: ${({ borderRadius }) => `${borderRadius}`};
  padding-bottom: 8px;
`;

export const Row = styled.div`
  display: grid;
  grid-template-columns: ${({ numberOfItems }) => `repeat(${numberOfItems}, minmax(0, 1fr))`};
  column-gap: 18px;
  padding: 8px 16px;
  align-items: center;

  transition: background-color 150ms linear;

  ${ActionsWrapper} {
    justify-content: flex-end;
  }

  ${({ hasHover, color }) => {
    if (hasHover) {
      return `
        &:hover {
          background-color: ${color};
        }
      `;
    }
  }}
`;

export const HeaderRow = styled(Row)`
  border-bottom: 1px solid ${({ theme }) => theme.shared.card.border.box};
  padding: 20px 16px 12px 16px;
  margin-bottom: 8px;
`;

export const HeaderItem = styled.div`
  font-size: 11px;
  font-weight: 500;
  color: ${({ theme }) => theme.space.listView.headerTitleColor};
`;
