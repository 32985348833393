import React, { useEffect, useState } from "react";
import CrossIcon from "@atlaskit/icon/glyph/cross";
import Tabs, { Tab, TabList, TabPanel } from "@atlaskit/tabs";

import DirectLink from "./DirectLink";
import Email from "./Email";
import Pages from "./Pages";

import "./scss/style.scss";
import styled, { useTheme } from "styled-components";
import Attachments from "./Attachments";

import { useCosmosLayoutStore } from "../../../Components/Hub/store/cosmos-layout-store";

const TabContentWrapper = styled.div`
  margin-top: 20px;
  width: 100%;
`;

const TabListWrapper = styled.div`
  & > div > div[aria-selected="true"]::after {
    border-bottom: 2px solid #0065ff !important;
  }
`;

const TabTitle = styled.span`
  color: ${({ theme }) => theme.navigation.settings.linkSidebar.tabColor};
  cursor: pointer;
  user-select: none;
  font-weight: 500;
  font-size: 14px;
`;

const DrawerAction = styled.div`
  cursor: pointer;
  text-align: right;
`;

const AvailableTabsBasedOnSource = {
  page: 0,
  direct: 1,
  email: 2,
  attachments: 3,
};

const LinkSidebar = React.forwardRef((props, ref) => {
  const { cosmosLayout, setCosmosLayout } = useCosmosLayoutStore((state) => ({
    cosmosLayout: state.cosmosLayout,
    setCosmosLayout: state.setCosmosLayout,
  }));
  const [isTabInit, setIsTabInit] = useState(false);
  const { selectedTab, value, onSelected, onClose, showAttachments, hasClose = true } = props;
  const [currentTab, setCurrentTab] = useState(selectedTab);

  const theme = useTheme();

  useEffect(() => {
    if (value?.source && !isTabInit) {
      setCurrentTab(AvailableTabsBasedOnSource[value.source] || 0);
      setIsTabInit(true);
    }
  }, [value?.source, isTabInit]);

  return (
    <Tabs
      onChange={(index) => {
        setCurrentTab(index);
      }}
      selected={currentTab}
    >
      <div style={{ position: "relative" }}>
        <TabListWrapper>
          <TabList>
            <Tab>
              <TabTitle>Pages</TabTitle>
            </Tab>
            <Tab>
              <TabTitle>Direct Link</TabTitle>
            </Tab>
            <Tab>
              <TabTitle>Email</TabTitle>
            </Tab>
            {showAttachments && (
              <Tab>
                <TabTitle>Attachments</TabTitle>
              </Tab>
            )}
          </TabList>
        </TabListWrapper>

        {hasClose && (
          <DrawerAction onClick={onClose} style={{ position: "absolute", right: "0px", top: "0px" }}>
            <CrossIcon size="small" primaryColor={theme.poll.icons} />
          </DrawerAction>
        )}
      </div>

      <TabPanel>
        <TabContentWrapper>
          <Pages value={value} onSelected={onSelected} />
        </TabContentWrapper>
      </TabPanel>
      <TabPanel>
        <TabContentWrapper>
          <DirectLink value={value} onSelected={onSelected} cosmosLayout={cosmosLayout} />
        </TabContentWrapper>
      </TabPanel>
      <TabPanel>
        <TabContentWrapper>
          <Email value={value} onSelected={onSelected} />
        </TabContentWrapper>
      </TabPanel>
      <TabPanel>
        <TabContentWrapper>
          <Attachments value={value} setValue={onSelected} ref={ref} />
        </TabContentWrapper>
      </TabPanel>
    </Tabs>
  );
});

LinkSidebar.displayName = "LinkSidebar";

export default LinkSidebar;
