import React, { useEffect, useState } from "react";
import styled from "styled-components";
import EditorEditIcon from "@atlaskit/icon/glyph/editor/edit";
import Button from "@atlaskit/button";
import ArrowLeftIcon from "@atlaskit/icon/glyph/arrow-left";
import Modal, { ModalTransition } from "@atlaskit/modal-dialog";
import { useCollectionsStore, useSingleCollectionStore, useCreateCollectionStore } from "../collection-store";
import { Filters } from "./Filters";
import FilterProvider from "./FilterProvider";
import { useQuery } from "react-query";
import { applyChanges, fetchRecords } from "../../UserDatabase/datagrid/api";
import { getFilterOptionsFromAzure } from "../../UserDatabase/menubar/azure/api";
import Records from "./Records";
import { fetchDetailsForUsers, updateCollectionInDatabase } from "../api";
import CollectionInfoModal from "../modal/CollectionInfoModal";
import { IMAGE_TYPES, getCssForBackground, getRandomUserBackground } from "../../../Shared/Functions/user-bg-provider";
import NoFoundCollections from "./EmptyState";
import { atlassianRestService } from "../../../Service/AtlassianRestService";
import Labels from "./fields/Labels";

const Wrapper = styled.div`
  width: calc(100% - 60px);
  height: calc(100vh - 75px);
  padding: 35px 30px 40px 30px;

  display: flex;
  flex-direction: column;
`;

const BreadcrumbsContainer = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #8993a4;

  white-space: nowrap;
  display: flex;
  align-items: center;
  column-gap: 10px;

  .main {
    cursor: pointer;
    &:hover {
      color: #42526e;
    }
  }
  .selected {
    color: #42526e;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  justify-content: space-between;
`;

const TitleNameIconContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  margin-top: 20px;
  justify-content: flex-start;

  .title {
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    color: #07145a;
    margin: 0px;
  }
`;

const Description = styled.p`
  margin-top: 18px;
  margin-bottom: 45px;

  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #8993a4;
`;

const IconContainer = styled.div`
  display: grid;
  place-content: center;
  cursor: pointer;
`;

const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 1.5fr 5fr;
  flex: 1;
  min-width: 0;
  min-height: 0;
  border-top: 1px solid ${({ theme }) => theme.collections.contentWrapperBorderTop};
  padding-top: 10px;
`;

const FilteredRecordsContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
`;

const FilteredRecordsFiltersContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 10px;
  flex-wrap: wrap;
  margin-left: 10px;
`;

const TotalSizeContainer = styled.div`
  width: fit-content;
  margin-right: 10px;
  height: 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #344563;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 28px;
`;

const NameTitle = styled.h1`
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  color: ${({ theme }) => theme.shared.settings.section.title};
  margin: 0;
`;

function SelectedCollection() {
  const [isReset, setIsReset] = useState(false);
  const [loadingUserGroups, setLoadingUserGroups] = useState(true);
  const [userGroups, setUserGroups] = useState([]);

  const { selectedCollection, setSelectedCollection } = useCollectionsStore((state) => ({
    selectedCollection: state.selectedCollection,
    setSelectedCollection: state.setSelectedCollection,
  }));

  const fields = useCollectionsStore((state) => state.databaseFields);

  const [totalSize, setTotalSize] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);

  const [isEditingCollection, setIsEditingCollection] = useState(false);
  const [azureManagerOptions, setAzureManagerOptions] = useState([]);

  const { isEditingIcon } = useCreateCollectionStore((state) => ({
    isEditingIcon: state.isEditingIcon,
  }));

  const { setRecords, resetRecords, filters, records, setFilters, setIsInit, resetOnFirstLoad } =
    useSingleCollectionStore((state) => ({
      setRecords: state.setRecords,
      resetRecords: state.resetRecords,
      filters: state.filters,
      records: state.records,
      setFilters: state.setFilters,
      setIsInit: state.setIsInit,
      resetOnFirstLoad: state.reset,
    }));

  useEffect(() => {
    resetOnFirstLoad();
    setIsReset(true);
  }, []);

  const { data: recordsData } = useQuery(
    [
      "userdatabase-records",
      {
        filters: [
          ...(selectedCollection?.filters || []),
          { isMultiUser: true, type: "BOOLEAN", value: true, column: "hidden", condition: "NOT" },
        ],
        confluenceGroup: selectedCollection?.userGroup || "",
        page: page,
      },
    ],
    fetchRecords,
    {
      cacheTime: 0,
      enabled: isReset,
      retry: 0,
      select: ({ data }) => ({
        users: data?.hits || [],
        totalSize: data?.totalSize || 0,
        totalPages: data?.totalPages || 1,
      }),
    },
  );

  const primaryColor = "#0065ff";

  const buildAndGetUserBackground = (userRecordData) => {
    const backgroundImage = userRecordData?.values?.user?.backgroundImage;
    const hasBgData = !!backgroundImage && !!Object.keys(backgroundImage || {}).length;
    const correctImage = hasBgData ? backgroundImage : getRandomUserBackground(IMAGE_TYPES.GRADIENT);

    if (!hasBgData) {
      const newUserValues = {
        ...userRecordData.values.user,
        backgroundImage: correctImage,
      };

      const change = {
        UPDATE_RECORDS: {
          [userRecordData.accountId]: {
            accountId: userRecordData.accountId,
            values: {
              ...userRecordData.values,
              user: {
                ...newUserValues,
              },
            },
          },
        },
      };

      applyChanges(change);
    }

    const image = getCssForBackground(correctImage);
    return {
      image,
    };
  };

  const buildFieldValue = (fieldId, recordUser) => {
    const field = fields.find((f) => f.id === fieldId);
    const userValue = recordUser?.values[fieldId] || undefined;

    if (!userValue || !field) return null;

    if (field.type === "SELECT") {
      return field?.options?.find((o) => o.id === userValue)?.label || "";
    }

    return "";
  };

  const getDetailsForUsers = (users) => {
    fetchDetailsForUsers(users.map((u) => u.accountId))
      .then((res) => {
        if (res?.body) {
          const data = JSON.parse(res.body);
          const builtUsers = data.results.map(({ user }) => {
            const recordUser = users.find((u) => u.accountId === user.accountId);

            return {
              accountId: user.accountId,
              name: user.displayName,
              email: user.email,
              icon: `${window.AP._hostOrigin}${user.profilePicture.path}`,
              background: buildAndGetUserBackground(recordUser),
              jobTitle: buildFieldValue("FF2Vrog", recordUser),
              location: buildFieldValue("qADc-c1x", recordUser),
            };
          });
          setRecords([...records, ...builtUsers]);
        }
      })
      .catch((e) => console.log(e));
  };

  const updateCollectionInDatabaseHandler = async (property, value) => {
    try {
      await updateCollectionInDatabase({ ...selectedCollection, [property]: value });
      window.AP.events.emit("single-collection-updated");
    } catch (error) {
      console.log(error);
    }
  };

  const updateCollection = async (filtersOverride = null) => {
    const filteredFilters = (filtersOverride === null ? filters : filtersOverride)
      .filter((f) => {
        if (f.type === "MULTISELECT" || f.type === "SELECT") {
          return !!f.value.length;
        }
        if (f.type === "DATE") {
          return !!f.value?.range?.length || !!f.value?.period;
        }
        return !!f.value;
      })
      .map(({ fieldTitle, options, ...rest }) => ({ ...rest }));

    setIsInit(false);
    resetRecords();
    setSelectedCollection({ ...selectedCollection, filters: filteredFilters });
    updateCollectionInDatabaseHandler("filters", filteredFilters);
  };

  const fetchNextPage = () => {
    if (totalPages !== page) {
      setPage(page + 1);
    }
  };

  useEffect(() => {
    if (recordsData?.users?.length) {
      getDetailsForUsers(recordsData.users);
    }

    setTotalSize(recordsData?.totalSize || 0);
    setTotalPages(recordsData?.totalPages || 1);
  }, [recordsData]);

  useEffect(() => {
    setFilters(selectedCollection?.filters || []);

    resetRecords();

    setPage(1);
    setTotalSize(0);
    setTotalPages(1);
  }, [selectedCollection.filters]);

  const loadUserGroups = async () => {
    setLoadingUserGroups(true);
    atlassianRestService
      .getGroups()
      .then((response) => {
        if (response?.results) {
          const builtGroups = response.results.map((group) => ({
            id: group.id,
            label: group.name,
            value: group.id,
          }));
          setUserGroups([...builtGroups]);
        } else {
          setUserGroups([]);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setLoadingUserGroups(false));
  };

  useEffect(() => {
    getFilterOptionsFromAzure("azureManager", "").then((res) =>
      setAzureManagerOptions(res.data.options?.map((opt) => ({ ...opt, id: opt.value }))),
    );
  }, []);

  useEffect(() => {
    loadUserGroups();
  }, []);

  return (
    <>
      <Wrapper>
        <BreadcrumbsContainer>
          <span className="main" onClick={() => setSelectedCollection("")}>
            Settings
          </span>
          <span>&#47;</span>
          <span className="main" onClick={() => setSelectedCollection(undefined)}>
            Collections
          </span>
          <span>&#47;</span>
          <span className="selected">{selectedCollection.name}</span>
        </BreadcrumbsContainer>

        <TitleWrapper>
          <TitleNameIconContainer>
            <IconContainer onClick={() => setSelectedCollection(undefined)}>
              <ArrowLeftIcon size="medium" primaryColor="#42526E" />
            </IconContainer>
            <NameTitle>{selectedCollection.name}</NameTitle>
          </TitleNameIconContainer>

          <Button
            isSelected={false}
            appearance="subtle"
            title="Edit Collection"
            onClick={() => setIsEditingCollection(true)}
            iconAfter={<EditorEditIcon primaryColor={primaryColor} />}
          >
            <div style={{ color: primaryColor }}>Edit Collection</div>
          </Button>
        </TitleWrapper>

        <Description>{selectedCollection.description}</Description>

        <ContentWrapper>
          <Filters
            updateCollection={updateCollection}
            updateCollectionInDatabase={updateCollectionInDatabaseHandler}
            userGroups={userGroups}
            loadingUserGroups={loadingUserGroups}
          />
          <FilteredRecordsContainer>
            <FilteredRecordsFiltersContainer>
              {filters.length && records?.length ? (
                <TotalSizeContainer>{`${totalSize} result${totalSize > 1 ? "s" : ""} filtered by`}</TotalSizeContainer>
              ) : (
                <></>
              )}
              {!!selectedCollection?.userGroup && (
                <Labels
                  isMulti={false}
                  options={userGroups}
                  value={selectedCollection.userGroup}
                  removeCallback={() => {
                    setSelectedCollection({ ...selectedCollection, userGroup: "" });
                    updateCollectionInDatabase("userGroup", "");
                  }}
                />
              )}
              {filters.map((filter) => {
                return (
                  <FilterProvider
                    key={filter.id}
                    filter={filter}
                    azureManagerOptions={azureManagerOptions}
                    field={fields.find((field) => field.id === filter.column)}
                    removeCallback={async (value) => {
                      let newFilters = [...filters];
                      if (!value) {
                        newFilters = [...filters.filter((f) => f.id !== filter.id)];
                      } else {
                        const currentFilter = newFilters.find((x) => x.id === filter.id);
                        currentFilter.value = currentFilter.value.filter((x) => x !== value);
                        if (!currentFilter.value.length) {
                          newFilters = [...filters.filter((f) => f.id !== filter.id)];
                        }
                      }

                      setFilters(newFilters);
                      await updateCollection(newFilters);
                      return true;
                    }}
                  />
                );
              })}
            </FilteredRecordsFiltersContainer>
            {records.length ? (
              <Records fetchNextPage={fetchNextPage} hasNextPage={totalPages > page} totalSize={totalSize} />
            ) : (
              <NoFoundCollections />
            )}
          </FilteredRecordsContainer>
        </ContentWrapper>
      </Wrapper>

      <ModalTransition>
        {isEditingCollection && (
          <Modal
            height="auto"
            width="auto"
            isChromeless
            shouldCloseOnOverlayClick={!isEditingIcon}
            onClose={() => setIsEditingCollection(false)}
          >
            <CollectionInfoModal
              close={() => setIsEditingCollection(false)}
              initialData={selectedCollection}
              onSaved={async (newData) => {
                await updateCollectionInDatabase({ ...selectedCollection, ...newData });
                setSelectedCollection({ ...selectedCollection, ...newData });
                window.AP.events.emit("single-collection-updated");
                setIsEditingCollection(false);
              }}
            />
          </Modal>
        )}
      </ModalTransition>
    </>
  );
}

export default SelectedCollection;
