import React from "react";
import { LabeledContainer, LabeledTitle, LabeledToggle, TabContent, ToggleTitle } from "./ActiveUsersEditor";
import Toggle from "@atlaskit/toggle";
import { ActiveUsersPropertyKeys } from "./configurator/propertyTypes";
import InputFilter from "../../../../Dashboard/filter/InputFilter";
import SelectFilter from "../../../../Dashboard/filter/SelectFilter";
import { PaginationOptions } from "../../../PanelsPagination/pagination-options";
import TextInputFilter from "../../../../Dashboard/filter/TextInputFilter";
import { Divider } from "../../../../Dashboard/filter/styled";

const Layout = ({ panel, panelTitle, updatePanelDataProperty, isPositionedInMainContent, updatePanelTitle }) => {
  return (
    <div>
      <TabContent>
        <TextInputFilter label="Title" value={panelTitle} onUpdate={(value) => updatePanelTitle(panel.id, value)} />

        <Divider />

        <SelectFilter
          label="Pagination"
          value={panel.data.pagination}
          setValue={(newValue) => updatePanelDataProperty(panel.id, ActiveUsersPropertyKeys.PAGINATION, newValue)}
          options={PaginationOptions}
        />
        <Divider />
        <LabeledContainer style={{ marginTop: "20px" }}>
          <LabeledTitle>Display events</LabeledTitle>
          {panel.data.events.map((event, idx) => (
            <LabeledToggle key={idx}>
              <ToggleTitle>{event.label}</ToggleTitle>
              <Toggle
                isChecked={event.value}
                value={event.value}
                onChange={() => {
                  const updatedEvents = [...panel.data.events];
                  updatedEvents[idx].value = !event.value;
                  updatePanelDataProperty(panel.id, ActiveUsersPropertyKeys.EVENTS, updatedEvents);
                }}
              />
            </LabeledToggle>
          ))}
        </LabeledContainer>

        {!isPositionedInMainContent && (
          <InputFilter
            numberField
            minLength={1}
            maxLength={5}
            initialValue={panel.data.maxNumberOfItems}
            label="Items per page"
            placeholder="Limit the number of users"
            onUpdate={(value) =>
              updatePanelDataProperty(panel.id, ActiveUsersPropertyKeys.MAX_NUMBER_OF_ITEMS, Number(value))
            }
          />
        )}
      </TabContent>
    </div>
  );
};

export default Layout;
