const selectStyles = (theme) => ({
  control: (provided) => ({
    ...provided,
    backgroundColor: `${theme.shared.profile.selectBackground} !important`,
    fontSize: "14px",
    width: "200px",
    height: "30px",
    boxShadow: "0 0 0 1px hsla(218, 50%, 10%, 0.1), 0 4px 11px hsla(218, 50%, 10%, 0.1)",
    borderColor: "transparent !important",
    borderRadius: "9px",
  }),
  option: (provided) => ({
    ...provided,
    fontSize: "14px",
    backgroundColor: "none",
    "&:hover": {
      backgroundColor: theme.shared.form.select.hover,
    },
  }),
  container: (provided) => ({
    ...provided,
    position: "absolute",
    zIndex: 10,
  }),
  selectContainer: (provided) => ({
    ...provided,
    fontSize: "14px",
  }),
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 900,
    position: "absolute",
    left: "0px",
    top: "20px",
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 900,
  }),
  placeholder: (provided) => ({
    ...provided,
    fontWeight: 500,
  }),
  input: (provided) => ({
    ...provided,
    fontSize: "14px",
    fontWeight: 400,
  }),
  valueContainer: (provided) => ({
    ...provided,
    flexWrap: "nowrap",
    overflow: "hidden",
    fontWeight: 400,
  }),
});

export default selectStyles;
