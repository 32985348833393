import create from "zustand";
import { COSMOS_APP_DEFAULT_DATA } from "./defaults";

export const useCosmosAppStore = create((set, get) => ({
  cosmosAppSettings: COSMOS_APP_DEFAULT_DATA,
  setCosmosAppSettings: (value) => set(() => ({ cosmosAppSettings: value })),
  updateCosmosAppSettings: (key, value) => {
    const cosmosAppSettings = { ...get().cosmosAppSettings };
    return set(() => ({ cosmosAppSettings: { ...cosmosAppSettings, [key]: value } }));
  },
}));
