import React from "react";
import { useDrag, useDrop } from "react-dnd";
import styled from "styled-components";
import { TrashIcon, EditIcon } from "./Icons";
import DragHandlerIcon from "@atlaskit/icon/glyph/drag-handler";

const Wrapper = styled.div`
  ${({ isHoveringOver, orderDirection }) => {
    if (!isHoveringOver) {
      return null;
    }

    return orderDirection === "top-bottom" ? `border-bottom: 1px solid #aaa;` : `border-top: 1px solid #aaa;`;
  }};
`;

const Panel = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.shared.sidebar.item.title};
  border-radius: 8px;
  background-color: ${({ theme }) => theme.shared.sidebar.item.background};
  transition: background-color 50ms linear;

  .actions {
    visibility: hidden;
    margin-right: 15px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.shared.sidebar.item.hover};
    transition: background-color 50ms linear;

    .actions {
      visibility: visible;
    }
  }
`;

const IconContainer = styled.div`
  display: grid;
  place-content: center;
  cursor: pointer;

  svg {
    color: #97a0af !important;
    fill: #97a0af !important;
  }

  &:hover {
    opacity: 0.9;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 14px;
`;

const PanelTitle = styled.span`
  max-width: 162px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  cursor: default;
`;

export const RegularPanel = ({ panel, handleClickOnEdit }) => {
  return (
    <Wrapper>
      <Panel key={panel.id}>
        <FlexContainer>
          <IconContainer style={{ cursor: "not-allowed", opacity: "0.5" }}>
            <DragHandlerIcon />
          </IconContainer>
          <PanelTitle>{panel.panelTitle}</PanelTitle>
        </FlexContainer>
        <FlexContainer className="actions">
          <IconContainer onClick={() => handleClickOnEdit(panel)}>
            <EditIcon />
          </IconContainer>
          <IconContainer style={{ opacity: 0.5, cursor: "not-allowed" }}>
            <TrashIcon />
          </IconContainer>
        </FlexContainer>
      </Panel>
    </Wrapper>
  );
};

export const ReorderablePanel = ({ panel, reorderPanels, handleClickOnEdit, handleClickOnDelete }) => {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: ["panel"],
    drop: () => ({ id: panel.id, ...panel }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  }));

  const [{ draggedItem }, drag] = useDrag({
    type: "panel",
    item: () => {
      return { id: panel.id, type: "panel", ...panel };
    },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (item && dropResult) {
        reorderPanels(item.id, dropResult.id);
      }
    },
    collect: (monitor) => ({
      draggedItem: monitor.getItem(),
    }),
  });

  const draggedItemPosition = draggedItem?.order ?? 0;
  const potentialTargetPosition = isOver ? panel?.order : 0;

  return (
    <Wrapper
      ref={drop}
      isHoveringOver={isOver}
      orderDirection={draggedItemPosition < potentialTargetPosition ? "top-bottom" : "bottom-top"}
    >
      <Panel key={panel.id}>
        <FlexContainer>
          <IconContainer style={{ cursor: "grab" }} ref={drag}>
            <DragHandlerIcon />
          </IconContainer>
          <PanelTitle>{panel.panelTitle}</PanelTitle>
        </FlexContainer>
        <FlexContainer className="actions">
          <IconContainer onClick={() => handleClickOnEdit(panel)}>
            <EditIcon />
          </IconContainer>
          <IconContainer onClick={() => handleClickOnDelete(panel.id)}>
            <TrashIcon />
          </IconContainer>
        </FlexContainer>
      </Panel>
    </Wrapper>
  );
};
