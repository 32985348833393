import React, { useCallback } from "react";
import { PropertyTypes } from "./propertyTypes";
import TextInputFilter from "../../../../Dashboard/filter/TextInputFilter";
import { Divider } from "../../../../Dashboard/filter/styled";
import SelectFilter from "../../../../Dashboard/filter/SelectFilter";
import ColumnNumberPicker from "../../../../Dashboard/filter/ColumnNumberPicker";
import SimpleElementsToggle from "../../../../Dashboard/filter/SimpleElementsToggle";
import { PanelsPaginationTypes } from "../../../PanelsPagination/panels-pagination-types";
import SelectWithCustomOptions from "../../../../Dashboard/filter/SelectWithCustomOptions";
import { PaginationOptions } from "../../../PanelsPagination/pagination-options";

function Layout({ panel, updatePanelDataProperty, updatePanelTitle }) {
  const {
    id,
    panelTitle,
    data: { style, numberOfColumns, pagination, elementsToDisplay },
  } = panel;

  const updatePanel = useCallback(
    (propertyKey, value) => {
      updatePanelDataProperty(id, propertyKey, value);
    },
    [id, updatePanelDataProperty],
  );

  const primaryColor = "#0065ff";
  const slicedPrimaryColor = primaryColor.length > 7 ? primaryColor.slice(0, -2) : primaryColor;

  return (
    <>
      <TextInputFilter label="Title" value={panelTitle} onUpdate={(value) => updatePanelTitle(id, value)} />

      <Divider style={{ margin: "20px 0px 5px 0px" }} />

      <SelectWithCustomOptions
        initialValue={pagination || "none"}
        options={PaginationOptions}
        label="Pagination"
        onUpdate={(newValue) => updatePanel(PropertyTypes.PAGINATION, newValue)}
      />

      <Divider style={{ margin: "20px 0px 5px 0px" }} />

      <ColumnNumberPicker
        label="Cards in row"
        value={numberOfColumns}
        setValue={(newNumberOfColumns) => updatePanel(PropertyTypes.NUMBER_OF_COLUMNS, newNumberOfColumns)}
        primaryColor={slicedPrimaryColor}
      />

      <Divider style={{ margin: "20px 0px 0px 0px" }} />

      <SimpleElementsToggle
        label="Elements to display"
        elements={elementsToDisplay}
        onChange={(newElements) => updatePanel(PropertyTypes.ELEMENTS_TO_DISPLAY, newElements)}
      />
    </>
  );
}

export default Layout;
