import React, { useEffect, useState } from "react";
import Tabs, { Tab, TabList, TabPanel } from "@atlaskit/tabs";
import styled from "styled-components";
import SidebarIconPicker from "./SidebarIconPicker";
import SidebarEmojiPicker from "./SidebarEmojiPicker";
import SidebarAppPicker from "../../../Components/Hub/Components/Dashboard/filter/SidebarAppPicker";
import { getMacroSuiteStyleguide } from "../../../Components/Hub/Components/Dashboard/api";
import { AppLinks } from "../../../Components/Hub/Components/Dashboard/filter/appLinks";

const TabContentWrapper = styled.div`
  margin-top: 20px;
  width: 100%;
  height: 100%;
`;

const TabTitle = styled.span`
  color: ${({ theme }) => theme.icons.tabs};
  cursor: pointer;
  user-select: none;
  font-weight: 500;
  font-size: 14px;
`;

export const IconTypes = {
  ICON: "icon",
  EMOJI: "emoji",
  APP: "app",
};

const IconEmojiSidebar = React.forwardRef(
  (
    {
      hasEmojis,
      appIconsDrawer = { available: true, modifiers: true },
      selectedTab,
      value,
      onSelected,
      onClose,
      showNoIconBox,
    },
    ref,
  ) => {
    const [currentTab, setCurrentTab] = useState(selectedTab);

    const [appIcons, setAppIcons] = useState([]);

    useEffect(() => {
      getMacroSuiteStyleguide()
        .then((res) => {
          if (res?.data?.exists && res?.data?.body?.appicons) {
            setAppIcons(res.data.body.appicons);
          } else {
            setAppIcons(AppLinks);
          }
        })
        .catch((err) => setAppIcons(AppLinks));
    }, []);

    if (!hasEmojis) {
      return (
        <SidebarIconPicker
          showNoIconBox={showNoIconBox}
          onClose={onClose}
          onIconSelect={(selected) => {
            onSelected(selected);
          }}
        />
      );
    }

    return (
      <Tabs
        onChange={(index) => {
          setCurrentTab(index);
        }}
        selected={currentTab}
      >
        <TabList>
          <Tab>
            <TabTitle>Icons</TabTitle>
          </Tab>
          <Tab>
            <TabTitle>Emojis</TabTitle>
          </Tab>
          {appIconsDrawer.available && (
            <Tab>
              <TabTitle>Apps</TabTitle>
            </Tab>
          )}
        </TabList>
        <TabPanel>
          <TabContentWrapper>
            <SidebarIconPicker
              isInTabs
              showNoIconBox={showNoIconBox}
              onIconSelect={(selected) => {
                onSelected(selected, IconTypes.ICON);
              }}
            />
          </TabContentWrapper>
        </TabPanel>
        <TabPanel>
          <TabContentWrapper>
            <SidebarEmojiPicker onSelect={(v) => onSelected(v, IconTypes.EMOJI)} />
          </TabContentWrapper>
        </TabPanel>
        {appIconsDrawer.available && (
          <TabPanel>
            <TabContentWrapper>
              <SidebarAppPicker
                hasModifiers={appIconsDrawer.modifiers}
                customIcons={appIcons}
                onIconSelect={({ path }) => {
                  onSelected(path, IconTypes.APP);
                }}
              />
            </TabContentWrapper>
          </TabPanel>
        )}
      </Tabs>
    );
  },
);

IconEmojiSidebar.displayName = "IconEmojiSidebar";

export default IconEmojiSidebar;
