import React, { useCallback, useEffect, useState } from "react";
import Spinner from "@atlaskit/spinner";
import { useSubscriptionsStore } from "../subscriptions-store";
import Tabs from "../../../../Shared/Components/Tabs";
import { GridItems } from "./styled";
import Item from "../components/Item";
import { SpaceIcon } from "../components/SpaceIcon";
import { changeCategorySubscriptionForUser } from "../../../Hub/Components/Settings/News/api";
import {
  addCurrentUserAsSpaceWatcher,
  removeCurrentUserAsSpaceWatcher,
} from "../../../Hub/Components/SpaceDirectory/apiSpaces";
import EmptyState from "../components/EmptyState";
import { withWatching } from "../hoc/withWatching";

const SpaceItem = withWatching(Item);

function Subscriptions({ isLoading }) {
  const [builtSpaces, setBuiltSpaces] = useState([]);
  const [selectedTab, setSelectedTab] = useState("Categories");

  const {
    usersCategories,
    usersSpaces,
    categories,
    spaces,
    categoryBlogCountData,
    removeCategoryFromSubscription,
    addCategoryToSubscription,
  } = useSubscriptionsStore();

  useEffect(() => {
    const spacesToUse = [...usersSpaces, ...spaces];
    setBuiltSpaces(
      spacesToUse.filter(
        (item, index, originalArray) => originalArray.findIndex((origItem) => origItem.key === item.key) === index,
      ),
    );
  }, [spaces, usersSpaces]);

  const getCategoryNumberOfBlogs = useCallback(
    (categoryId) => {
      const found = categoryBlogCountData.find(({ id }) => id === categoryId)?.numberOfBlogs;
      if (found >= 0) {
        return `${found} News`;
      } else {
        return "";
      }
    },
    [categoryBlogCountData],
  );

  return (
    <div>
      <Tabs
        tabs={["Categories", "Spaces"]}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        showAsButtons={false}
        fitContent={true}
      />

      <GridItems centerItems={(!categories?.length && selectedTab === "Categories") || isLoading}>
        {isLoading ? (
          <Spinner size="xlarge" />
        ) : (
          <>
            {selectedTab === "Categories" && (
              <>
                {categories?.length ? (
                  [...categories]
                    ?.sort((a, b) => a.value.localeCompare(b.value))
                    ?.map((category) => (
                      <Item
                        key={category?.id}
                        isSelected={usersCategories.find((catId) => catId === category.id)}
                        additionalInfo={getCategoryNumberOfBlogs(category.id)}
                        onSelection={() => {
                          const isRemoving = !!usersCategories.find((ctId) => ctId === category.id);
                          if (isRemoving) {
                            removeCategoryFromSubscription(category.id);
                          } else {
                            addCategoryToSubscription(category.id);
                          }
                          changeCategorySubscriptionForUser({
                            categoryId: category.id,
                            action: isRemoving ? "unsubscribe" : "subscribe",
                          });
                        }}
                        title={category?.value}
                      />
                    ))
                ) : (
                  <EmptyState />
                )}
              </>
            )}

            {selectedTab === "Spaces" &&
              !!builtSpaces?.length &&
              [...builtSpaces]
                ?.sort((a, b) => a.name.localeCompare(b.name))
                ?.map((space) => (
                  <SpaceItem
                    key={space?.key}
                    title={space?.name}
                    hasIcon
                    Icon={() => <SpaceIcon path={space?.icon} />}
                    fetchWatchingStatus={space.fetchWatchingStatus}
                    handleNewStatus={async (isAdding, refetchStatus) => {
                      if (isAdding) {
                        await addCurrentUserAsSpaceWatcher(space.key);
                      } else {
                        await removeCurrentUserAsSpaceWatcher(space.key);
                      }
                      refetchStatus(true);
                    }}
                  />
                ))}
          </>
        )}
      </GridItems>
    </div>
  );
}

export default Subscriptions;
