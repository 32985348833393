import { AvailableStyles } from "./types";
import { PanelKeys } from "../../../../../Dashboard/panels/panelTypes";

import IconCardLasVegas from "./icon-card/LasVegas";
import ImageCardLasVegas from "./image-card/LasVegas";
import TextCardLasVegas from "./text-card/LasVegas";

export const AvailableCards = {
  [PanelKeys.ICON_CARDS]: {
    [AvailableStyles.LAS_VEGAS]: IconCardLasVegas,
  },
  [PanelKeys.IMG_CARDS]: {
    [AvailableStyles.LAS_VEGAS]: ImageCardLasVegas,
  },
  [PanelKeys.TEXT_CARDS]: {
    [AvailableStyles.LAS_VEGAS]: TextCardLasVegas,
  },
};
