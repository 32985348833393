import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #42526e;
  user-select: none;
  display: block;
`;

export const TextInputWrapper = styled.div`
  div:first-child {
    border-color: ${({ theme }) => theme.shared.form.input.border};
    color: ${({ theme }) => theme.shared.form.input.text};
    background-color: ${({ theme }) => theme.shared.form.input.background};

    &:hover,
    &:focus,
    &:active {
      background-color: ${({ theme }) => theme.shared.form.input.hover};
    }
  }
`;

export const Description = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #6b778c;
  margin-top: 12px;
`;

export const IconContainer = styled.div`
  display: grid;
  place-content: center;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }
`;

export const DividerBorder = styled.div`
  margin-top: 24px;
  margin-bottom: 23px;
  border-bottom: 0.75px solid ${({ theme }) => theme.shared.sidebar.divider};
`;

export const AddNewText = styled.span`
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: ${({ theme }) => theme.global.text.blue};
`;

export const AddNewSectionWrapper = styled.div`
  position: sticky;
  bottom: 0px;
  background-color: ${({ theme }) => theme.shared.sidebar.background};
`;

export const AddNewContainer = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 24px auto 24px;
  column-gap: 12px;
  cursor: pointer;

  &:hover {
    ${AddNewText} {
      opacity: 0.9;
    }
  }
`;

export const LabeledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const LabeledToggle = styled.div`
  display: flex;
  place-items: center;
  justify-content: space-between;
  gap: 1rem;
`;

export const ToggleTitle = styled.h1`
  margin: 0;
  font-size: 14px;
  color: #626f86;
  font-weight: 400;
  padding: 12px 8px;
`;

export const LabeledTitle = styled.h1`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #6b778c;
  margin: 0;
`;

export const TabContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-block: 20px;
`;

export const SelectWithToggle = styled.div`
  display: flex;
  justify-content: space-between;
  place-items: center;
  gap: 1rem;

  & > div:first-child {
    flex: 1;
  }
`;

export const DesignSidebarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 30px);
`;
