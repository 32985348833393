import { PanelTypes as DashboardPanelType } from "../../Components/Hub/Components/Dashboard/panels/panelTypes";
import { PanelTypes as PeopleNetworkPanelType } from "../../Components/Hub/Components/PeopleBase/Panel/panelTypes";

export const sizeType = {
  PADDING: "padding",
  NEWSROOM_PADDING: "newsroom_padding",
  BORDER_RADIUS: "borderRadius",
  CARD_ROUNDING: "cardRounding",
  IMAGE_SIZE: "imageSize",
  PROFILE_IMAGE_SIZE: "profileImageSize",
  COVER_PICTURE_SIZE: "coverPictureSize",
};

const NewsData = {
  [sizeType.NEWSROOM_PADDING]: [
    { label: "S", value: 10 },
    { label: "M", value: 20 },
    { label: "L", value: 30 },
  ],
  [sizeType.PADDING]: [
    { label: "S", value: 20 },
    { label: "M", value: 24 },
    { label: "L", value: 30 },
  ],
  [sizeType.BORDER_RADIUS]: [
    { label: "S", value: 3 },
    { label: "M", value: 8 },
    { label: "L", value: 12 },
  ],
  [sizeType.COVER_PICTURE_SIZE]: [
    { label: "S", value: 120 },
    { label: "M", value: 180 },
    { label: "L", value: 280 },
  ],
};

const DatesData = {
  [sizeType.CARD_ROUNDING]: [
    { label: "S", value: 3 },
    { label: "M", value: 6 },
    { label: "L", value: 8 },
  ],
  [sizeType.IMAGE_SIZE]: [
    { label: "S", value: 60 },
    { label: "M", value: 80 },
    { label: "L", value: 100 },
  ],
};

const PeopleData = {
  [sizeType.IMAGE_SIZE]: [
    { label: "S", value: 60 },
    { label: "M", value: 72 },
    { label: "L", value: 96 },
  ],
};

const KnowYourTeamData = {
  [sizeType.CARD_ROUNDING]: [
    { label: "S", value: 3 },
    { label: "M", value: 8 },
    { label: "L", value: 16 },
  ],
  [sizeType.PROFILE_IMAGE_SIZE]: [
    { label: "S", value: 60 },
    { label: "M", value: 80 },
    { label: "L", value: 96 },
  ],
};

const RecentlyActiveUsersData = {
  [sizeType.CARD_ROUNDING]: [
    { label: "S", value: 3 },
    { label: "M", value: 8 },
    { label: "L", value: 16 },
  ],
  [sizeType.PROFILE_IMAGE_SIZE]: [
    { label: "S", value: 60 },
    { label: "M", value: 80 },
    { label: "L", value: 96 },
  ],
};

const NewStartersData = {
  [sizeType.CARD_ROUNDING]: [
    { label: "S", value: 3 },
    { label: "M", value: 8 },
    { label: "L", value: 16 },
  ],
  [sizeType.PROFILE_IMAGE_SIZE]: [
    { label: "S", value: 60 },
    { label: "M", value: 80 },
    { label: "L", value: 96 },
  ],
};

const UpcomingBirthdaysData = {
  [sizeType.CARD_ROUNDING]: [
    { label: "S", value: 3 },
    { label: "M", value: 8 },
    { label: "L", value: 16 },
  ],
  [sizeType.PROFILE_IMAGE_SIZE]: [
    { label: "S", value: 60 },
    { label: "M", value: 80 },
    { label: "L", value: 96 },
  ],
};

const UserBlogPostsData = {
  [sizeType.CARD_ROUNDING]: [
    { label: "S", value: 3 },
    { label: "M", value: 8 },
    { label: "L", value: 16 },
  ],
  [sizeType.PROFILE_IMAGE_SIZE]: [
    { label: "S", value: 60 },
    { label: "M", value: 80 },
    { label: "L", value: 86 },
  ],
};

const NavigationDenver = {
  [sizeType.IMAGE_SIZE]: [
    { label: "S", value: 80 },
    { label: "M", value: 120 },
    { label: "L", value: 160 },
  ],
};

export const sizesByPanelType = {
  [DashboardPanelType.NEWS]: NewsData,
  [DashboardPanelType.NEW_USERS_MAIN]: DatesData,
  [DashboardPanelType.BIRTHDAY]: DatesData,
  [DashboardPanelType.PEOPLE]: PeopleData,
  [PeopleNetworkPanelType.TEAM]: KnowYourTeamData,
  [PeopleNetworkPanelType.ACTIVE_USERS]: RecentlyActiveUsersData,
  [PeopleNetworkPanelType.NEW_STARTERS]: NewStartersData,
  [PeopleNetworkPanelType.BIRTHDAY]: UpcomingBirthdaysData,
  [PeopleNetworkPanelType.BLOG]: UserBlogPostsData,
  denver: NavigationDenver,
};
