import React, { Suspense } from "react";
import PropTypes from "prop-types";
import SelectProviders from "./SelectProviders";
import styled from "styled-components";
import { useIconStore } from "./icon-store";
import shallow from "zustand/shallow";
import { ATLASKIT_PROVIDER, BOOTSTRAP_PROVIDER, FA_PROVIDER, MD_PROVIDER } from "./Icon";
import BootstrapIcons from "./BootstrapIcons";
import MaterialDesignIcons from "./MaterialDesignIcons";
import FontAwesomeIcons from "./FontAwesomeIcons";
import { IconsContainer, OverflowContainer } from "./styled/Components";

const NoIconBox = styled.span`
  box-sizing: border-box;
  width: 36px;
  height: 36px;
  cursor: pointer;

  div:first-child {
    width: 32px;
    height: 32px;
    background: ${({ theme }) => theme.navigation.settings.iconBoxBackground};
    border: 1px solid ${({ theme }) => theme.collections.collectionCardBorder};
    border-radius: 3px;
    display: grid;
    place-content: center;

    span {
      width: 1px;
      height: 32px;
      border-left: 1px solid #ff1111;
      transform: rotate(45deg);
    }
  }
`;

const AtlaskitIconCollection = React.lazy(() => import("./AtlaskitIcons"));
function SidebarIconPicker({ onIconSelect, onClose, showNoIconBox, isInTabs }) {
  const { provider, searchTerm } = useIconStore((state) => {
    return {
      provider: state.provider,
      searchTerm: state.searchTerm,
    };
  }, shallow);

  return (
    <div style={{ height: "100%" }}>
      <SelectProviders isInTabs={isInTabs} onClose={onClose} />
      <OverflowContainer>
        <IconsContainer>
          <Suspense fallback={<div>loading...</div>}>
            {showNoIconBox && (
              <NoIconBox onClick={() => onIconSelect({ icon: "none", provider: "none" })}>
                <div>
                  <span />
                </div>
              </NoIconBox>
            )}

            {provider === ATLASKIT_PROVIDER && (
              <AtlaskitIconCollection
                searchTerm={searchTerm}
                onIconSelect={(icon, provider) => {
                  onIconSelect({ icon, provider });
                }}
              />
            )}
            {provider === BOOTSTRAP_PROVIDER && (
              <BootstrapIcons
                searchTerm={searchTerm}
                onIconSelect={(icon, provider) => {
                  onIconSelect({ icon, provider });
                }}
              />
            )}
            {provider === FA_PROVIDER && (
              <FontAwesomeIcons
                searchTerm={searchTerm}
                onIconSelect={(icon, provider) => {
                  onIconSelect({ icon, provider });
                }}
              />
            )}
            {provider === MD_PROVIDER && (
              <MaterialDesignIcons
                searchTerm={searchTerm}
                onIconSelect={(icon, provider) => {
                  onIconSelect({ icon, provider });
                }}
              />
            )}
          </Suspense>
        </IconsContainer>
      </OverflowContainer>
    </div>
  );
}

SidebarIconPicker.propTypes = {
  onIconSelect: PropTypes.func.isRequired,
};

export default SidebarIconPicker;
