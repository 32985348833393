/* eslint-disable react/jsx-wrap-multilines */
import React from "react";
import styled from "styled-components";
import Page16Icon from "@atlaskit/icon-object/glyph/page/16";
import Blog16Icon from "@atlaskit/icon-object/glyph/blog/16";
import Textfield from "@atlaskit/textfield";
import { getIconBasedOnType } from "./LinkSidebar/icons/getIconBasedOnType";
import { DeleteIcon, EmailIcon, LinkIcon } from "./Icons/LinkFieldIcons";
import Checkbox from "@atlaskit/checkbox";

const LinkWrapper = styled.div`
  div:first-child {
    border-color: ${({ theme }) => theme.shared.form.input.border};
    color: ${({ theme }) => theme.shared.form.input.text};
    background-color: ${({ theme }) => theme.shared.form.input.background};

    &:hover,
    &:focus,
    &:active {
      background-color: ${({ theme }) => theme.shared.form.input.hover};
    }
  }

  .button-textinput-styles {
    cursor: pointer;
  }
`;

const Label = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #6b778c;
`;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 6px;
`;

const LinkField = ({ value, setValue, openInNewTab, setOpenInNewTab, setIsEditingLink }) => {
  const getLinkValue = () => {
    if (!value) return "";
    if (typeof value === "string") {
      return value.link;
    }
    if (value.source === "attachments" || value.source === "page") {
      return value.title;
    }
    return value.link || "";
  };

  const getIcon = () => {
    if (value?.source) {
      if (value.source === "attachments") {
        return getIconBasedOnType(value.type);
      }

      if (value.source === "page") {
        if (value.type === "page") {
          return <Page16Icon />;
        }
        return <Blog16Icon />;
      }

      if (value.source === "email") {
        return <EmailIcon />;
      }
    }
    return <LinkIcon />;
  };

  return (
    <LinkWrapper>
      <Textfield
        readOnly
        elemBeforeInput={
          <div
            role="none"
            onClick={() => setIsEditingLink(true)}
            style={{ width: "24px", display: "grid", placeContent: "flex-end" }}
          >
            {getIcon()}
          </div>
        }
        elemAfterInput={
          <>
            {!!getLinkValue().length && (
              <div
                role="none"
                onClick={() => setValue("")}
                style={{
                  width: "24px",
                  display: "grid",
                  cursor: "pointer",
                }}
              >
                <DeleteIcon />
              </div>
            )}
          </>
        }
        placeholder="Add link"
        autoComplete="off"
        value={getLinkValue()}
        isReadOnly
        onClick={() => setIsEditingLink(true)}
        className="button-textinput-styles"
      />
      <CheckboxWrapper>
        <Checkbox
          style={{ cursor: "pointer " }}
          isChecked={openInNewTab}
          onChange={({ target: { checked } }) => setOpenInNewTab(checked)}
        />
        <Label>Open in new tab</Label>
      </CheckboxWrapper>
    </LinkWrapper>
  );
};

export default LinkField;
