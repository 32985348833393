import React from "react";
import { useTheme } from "styled-components";
import Tooltip from "@atlaskit/tooltip";

import FieldProvider from "../fields/FieldProvider";
import { MainPanelWrapper, MainPanelFieldWrapper, PanelContainer } from "./styled";
import EmptyState from "../../../../../../Shared/Components/EmptyState";

function MainPanel({ values, fields }) {
  const theme = useTheme();

  const mainPanelFields = fields
    .filter((field) => field?.profile?.section === "main")
    .sort((a, b) => a?.profile?.order - b?.profile?.order);

  const customStyles = {
    customDescriptionStyles: { width: "464px" },
    customTextContainerStyles: { rowGap: "16px" },
  };

  if (!mainPanelFields.length) {
    return (
      <PanelContainer>
        <EmptyState
          title="Complete your profile"
          description="Your profile is looking a little empty! Add some details to help others get to know you better."
          Image={() => {
            return <img src={`/images/hub/no-fields-empty-state-${theme.global.name}.svg`} alt="" />;
          }}
          customStyles={customStyles}
        />
      </PanelContainer>
    );
  }

  return (
    <PanelContainer>
      <MainPanelWrapper>
        <MainPanelFieldWrapper>
          {mainPanelFields
            .filter((field) => field?.profile?.alignment === "left")
            .map((field) =>
              field.id.startsWith("azure") ? (
                <Tooltip content="Entra ID Field - Read Only" position="top-start">
                  <FieldProvider key={field.id} currentField={field} values={values} />
                </Tooltip>
              ) : (
                <FieldProvider key={field.id} currentField={field} values={values} />
              ),
            )}
        </MainPanelFieldWrapper>

        <MainPanelFieldWrapper>
          {mainPanelFields
            .filter((field) => field?.profile?.alignment === "right")
            .map((field) =>
              field.id.startsWith("azure") ? (
                <Tooltip content="Entra ID Field - Read Only" position="top-start">
                  <FieldProvider key={field.id} currentField={field} values={values} />
                </Tooltip>
              ) : (
                <FieldProvider key={field.id} currentField={field} values={values} />
              ),
            )}
        </MainPanelFieldWrapper>
      </MainPanelWrapper>
    </PanelContainer>
  );
}

export default MainPanel;
