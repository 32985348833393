import xss from "xss";
import { DEFAULT_BACKGROUND } from "../../functions/generateNewEntry";

export const getImage = (image) => {
  if (!image) {
    return `url("${DEFAULT_BACKGROUND.link}")`;
  }

  if (image.source === "color") {
    return image.value;
  }

  if (image.source === "gradient") {
    const { rotation, firstColor, secondColor, firstColorPosition, secondColorPosition } = image.value;

    return `linear-gradient(${
      rotation || "90deg"
    }, ${firstColor} ${firstColorPosition}%, ${secondColor} ${secondColorPosition}%)`;
  }

  return `url("${image.link}")`;
};

export const handleClickOnEntryLink = (rawLink) => {
  const shouldOpenInNewTab = !!rawLink.openNewTab;

  let { link, source } = rawLink;
  link = link.startsWith("http://") || link.startsWith("https://") ? link : `https://${link}`;

  if (source === "email") {
    window.open("mailto:" + link, "_blank").focus();
    return;
  }

  window.open(link, shouldOpenInNewTab ? "_blank" : "_parent").focus();
  return;
};

export const handleOpeningLinkByDefault = (entry) => {
  if (!entry?.data?.link) return;

  let link = xss(entry?.data?.link?.link || "");

  if (entry?.data?.link?.source === "email") {
    window.open("mailto:" + link).focus();
    return;
  }

  link = link.startsWith("http://") || link.startsWith("https://") ? link : `https://${link}`;

  window.open(link, "_parent").focus();
};

export const handleClickOnChildEntry = (entry, isPreview) => {
  if (isPreview) return;
  if (entry.data?.link && entry.data.link?.link) {
    const shouldOpenInNewTab = !!entry.data?.openNewTab;
    let link = xss(entry?.data?.link?.link || "");
    if (entry.data.link.source === "email") {
      window.open("mailto:" + link).focus();
      return;
    }
    link = link.startsWith("http://") || link.startsWith("https://") ? link : `https://${link}`;

    window.open(link, shouldOpenInNewTab ? "_blank" : "_parent").focus();
  }
};
