import React from "react";
import { CardPropertyTypes } from "../../propertyTypes";
import InputFilter from "../../../../../../Dashboard/filter/InputFilter";

function TextCardFields({ title, updateCard }) {
  return (
    <InputFilter
      initialValue={title || ""}
      onUpdate={(newTitle) => updateCard(CardPropertyTypes.TITLE, newTitle)}
      label="Card title"
      placeholder="Edit card title"
      containerStyle={{ flex: "1" }}
    />
  );
}

export default TextCardFields;
