import { useCallback, useEffect, useMemo, useState } from "react";
import styled, { useTheme } from "styled-components";
import Select, { AsyncSelect } from "@atlaskit/select";
import ArrowLeftIcon from "@atlaskit/icon/glyph/arrow-left";
import { useQuery } from "react-query";
import { usePageStore } from "../../../../../Hub/page-store";
import { useChangesStore } from "../../../../../Hub/track-changes-store";
import { Pages } from "../../../../../Hub/pages";
import { SectionKeys, SectionPagesIDs } from "../../../../../Hub/Components/Settings/sections";
import { useCurrentUserStore } from "../../../../../Hub/current-user-store";
import { fetchCollections } from "../../../../../Collections/api";
import { fetchUserDatabaseFields } from "../../../../../UserDatabase/datagrid/api";
import filterService, { FilterActionTypes } from "../../../../../UserDatabase/filter-service";
import { atlassianRestService } from "../../../../../../Service/AtlassianRestService";
import { selectStylesWithOverrides } from "../../../../../Hub/Components/Dashboard/filter/CustomSelectStyles";
import { FieldContainer } from "../../components/edit/shared/Components";
import SimpleToggle from "../../../../../Hub/Components/Dashboard/filter/SimpleToggle";
import { SIMPLE_SELECT_STYLES } from "../../../../../Hub/Components/Dashboard/filter/CustomSelectStyles";
import { Filter } from "../../../../../Collections/selected/Filters";
import { useNavigationStore, useSidebarStore } from "../../../../store";
import { PARENT_ENTRY_IDENTIFIER } from "../../../../defaults";

const PermissionWrapper = styled.div`
  padding: 0 5px;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Label = styled.div`
  color: #6b778c;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 5px;

  .link {
    color: ${({ theme }) => theme.global.primaryColor};
    text-decoration: none;

    &:visited {
      color: #0065ff;
    }
  }
`;

const Container = styled.div`
  display: grid;
  place-content: center;
  justify-items: center;
  text-align: center;
  margin-top: 30px;
`;

const Title = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  color: ${({ theme }) => theme.global.text.blue};
  margin-top: 20px;
  margin-bottom: 6px;
`;

const Description = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.global.text.title};

  .create-link {
    cursor: pointer;
    color: #2684ff;
  }
`;

const Button = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;

  font-weight: 500;
  font-size: 14px;
  color: ${({ theme }) => theme.global.primaryColor};

  cursor: pointer;

  svg {
    padding-left: 0;
  }

  span {
    line-height: 1.2;
  }
`;

const DatabaseNotSynced = () => {
  const setSelectedPage = usePageStore((state) => state.setSelectedPage);
  const hasChanges = useChangesStore((state) => state.hasChanges);

  return (
    <Container>
      <img
        src="/images/hub/collections-empty-state.svg"
        alt="Empty people network"
        style={{ margin: "0 auto", maxWidth: "100%" }}
      />

      <div>
        <Title>Launch your Employee database</Title>
        <Description>
          <span>There are currently no users in your Database. </span>
          <span
            className="create-link"
            onClick={() => {
              if (hasChanges) {
                setSelectedPage(Pages.SETTINGS);
              } else {
                setSelectedPage(`${Pages.SETTINGS}-${SectionKeys.PEOPLE_BASE}-${SectionPagesIDs.DATABASE}`);
              }
            }}
          >
            Sync users
          </span>
          <span> to enable permissions.</span>
        </Description>
      </div>
    </Container>
  );
};

function Permissions() {
  const [selectedCollections, setSelectedCollections] = useState([]);
  const [collectionOptions, setCollectionOptions] = useState([]);
  const [isHiddenFromAnonymous, setIsHiddenFromAnonymous] = useState(false);

  const [loadedExistingFilterData, setLoadedExistingFilterData] = useState(false);
  const [filters, setFilters] = useState([]);
  const [fields, setFields] = useState([]);

  const [selectedSpaces, setSelectedSpaces] = useState([]);

  const { editingEntry, updateEntry } = useNavigationStore((state) => ({
    editingEntry: state.editingEntry,
    updateEntry: state.updateEntry,
  }));

  const { setIsHelperDrawerOpen, setSelectedHelperDrawerType } = useSidebarStore((state) => ({
    setIsHelperDrawerOpen: state.setIsHelperDrawerOpen,
    setSelectedHelperDrawerType: state.setSelectedHelperDrawerType,
  }));

  const isDbInitalized = useCurrentUserStore((state) => state.isDbInit);

  const theme = useTheme();

  const { isLoading, data } = useQuery("saved-collections", fetchCollections, {
    enabled: isDbInitalized,
    cacheTime: 0,
    retry: 0,
    select: (response) => {
      const { data } = response;
      return { collections: data || [] };
    },
  });

  const { isLoading: isLoadingFields, data: fieldsData } = useQuery(
    ["userdatabase-fields", { includeEmail: false }],
    fetchUserDatabaseFields,
    {
      retry: 0,
      select: (response) => {
        const { data } = response;
        return data;
      },
    },
  );

  useEffect(() => {
    if (fieldsData) {
      const allFilters = filterService.buildFilters(fieldsData || []);
      setFilters(allFilters);
      setFields(fieldsData || []);
    }
  }, [fieldsData]);

  useEffect(() => {
    if (data?.collections?.length) {
      setCollectionOptions(
        data.collections.map(({ name, id }) => ({
          label: name || "Collection without name",
          value: id,
        })),
      );
    }
  }, [data]);

  useEffect(() => {
    if (!isLoading) {
      const { collections } = editingEntry || {};
      if (collections?.length && collectionOptions?.length) {
        setSelectedCollections(
          collections
            .map((collectionId) => collectionOptions.find((cl) => cl.value === collectionId))
            .filter((exists) => exists),
        );
      }
    }
  }, [collectionOptions, editingEntry, isLoading]);

  useEffect(() => {
    if (isLoadingFields || loadedExistingFilterData) return;

    if (editingEntry.filters?.length && filters.length) {
      const existingFilters = editingEntry.filters || [];

      const updatedBaseFilters = filters.map((f) => {
        const savedFilter = existingFilters.find((savedFil) => savedFil.column === f.column);
        if (savedFilter) {
          return { ...f, value: savedFilter.value };
        }

        return f;
      });

      setFilters(updatedBaseFilters);
      setLoadedExistingFilterData(true);
    }
  }, [filters, loadedExistingFilterData, isLoadingFields, editingEntry]);

  useEffect(() => {
    if ((editingEntry?.spaces ?? []).length) {
      const promises = [];
      for (const spaceKey of editingEntry.spaces) {
        promises.push(
          atlassianRestService
            .getSpaceByKey(spaceKey)
            .then((res) => toSpaceField(res))
            .catch(() => {}),
        );
      }
      Promise.all(promises).then((results) => {
        setSelectedSpaces(results.filter((res) => !!res));
      });
    }
  }, [editingEntry?.spaces]);

  const updateEntryFilters = (filter) => {
    let newFilters = [];
    const existingFilters = editingEntry.filters || [];
    const actionType = filterService.getActionTypeBasedOnFilterValue(filter, existingFilters);

    if (actionType === FilterActionTypes.REMOVE) {
      newFilters = existingFilters.filter((f) => f.column !== filter.column);
    } else if (actionType === FilterActionTypes.CREATE) {
      newFilters = [...existingFilters, filter];
    } else {
      newFilters = existingFilters.map((f) => (f.column === filter.column ? { ...f, value: filter.value } : f));
    }

    updateEntry(editingEntry.id, "filters", newFilters, false);
  };

  const updateFilter = (newFilter) => {
    const newFilters = filters.map((filter) => {
      if (filter.column === newFilter.column) {
        return {
          ...filter,
          value: newFilter.value,
        };
      }
      return filter;
    });

    setFilters([...newFilters]);
    updateEntryFilters(newFilter);
  };

  useEffect(() => {
    setIsHiddenFromAnonymous(editingEntry?.isHiddenFromAnonymous);
  }, [editingEntry?.isHiddenFromAnonymous]);

  const permissionSetter = useMemo(
    () => ({
      spaces: setSelectedSpaces,
      collections: setSelectedCollections,
      isHiddenFromAnonymous: setIsHiddenFromAnonymous,
    }),
    [],
  );

  const updateViewPermissions = (permissionKey, data = []) => {
    updateEntry(
      editingEntry.id,
      permissionKey,
      data.map(({ value }) => value),
      false,
    );

    permissionSetter[permissionKey](data);
  };

  const updateAnonToggle = (toggleState) => {
    updateEntry(editingEntry.id, "isHiddenFromAnonymous", toggleState, false);
    permissionSetter["isHiddenFromAnonymous"](toggleState);
  };

  const toSpaceField = useCallback((space) => {
    return {
      icon: space.icon,
      name: space.name,
      spaceKey: space.key,
      type: "space",
      label: space.name,
      value: space.key,
      homepageId: space.homepage.id,
      coverPictureCallback: async () => {},
    };
  }, []);

  const loadOptions = async (query) => {
    return (await atlassianRestService.searchForSpace(query)).results.map(({ space }) => toSpaceField(space));
  };

  if (!isDbInitalized) {
    return <DatabaseNotSynced />;
  }

  return (
    <PermissionWrapper>
      <div style={{ paddingBottom: "10px" }}>
        <Button
          onClick={() => {
            setIsHelperDrawerOpen(false);
            setSelectedHelperDrawerType(undefined);
          }}
        >
          <ArrowLeftIcon size="medium" primaryColor={theme.global.primaryColor} />
          <span>Go back</span>
        </Button>
      </div>

      {editingEntry?.parent === PARENT_ENTRY_IDENTIFIER && (
        <FieldContainer style={{ margin: "12px 0px" }}>
          <SimpleToggle
            label="Visible for anonymous users"
            value={!isHiddenFromAnonymous}
            setValue={(newToggleValue) => updateAnonToggle(!newToggleValue)}
            customStyles={{
              padding: "8px 0px",
            }}
          />
        </FieldContainer>
      )}

      <div>
        <FieldContainer>
          <Label>Visible in</Label>
          <AsyncSelect
            defaultOptions
            placeholder="Select Spaces"
            className="select-search"
            isSearchable
            styles={{
              ...SIMPLE_SELECT_STYLES,
              menu: (base) => ({
                ...base,
                zIndex: 100,
              }),
            }}
            value={selectedSpaces}
            onChange={(op) => {
              updateViewPermissions("spaces", op === undefined ? [] : op);
            }}
            loadOptions={loadOptions}
            isMulti
          />
        </FieldContainer>
      </div>
      <div style={{ marginTop: "25px" }}>
        <FieldContainer>
          <Label>View permissions</Label>
          <Select
            placeholder="Select Collections"
            menuPlacement="auto"
            styles={{
              ...SIMPLE_SELECT_STYLES,
              menu: (base) => ({
                ...base,
                zIndex: 100,
              }),
            }}
            isClearable={false}
            options={collectionOptions}
            value={selectedCollections}
            onChange={(op) => {
              if (op) {
                updateViewPermissions("collections", op);
              }
            }}
            isDisabled={!isDbInitalized}
            isLoading={isLoading}
            isMulti
          />
        </FieldContainer>
        <Label>
          Learn more about&nbsp;
          <a className="link" href="https://caelor-apps.atlassian.net/wiki/x/cRj6" target="_blank" rel="noreferrer">
            Cosmos Collections
          </a>
          &nbsp;and&nbsp;
          <a className="link" href="https://caelor-apps.atlassian.net/l/cp/m1aWV0zu" target="_blank" rel="noreferrer">
            features powered by Pulse.
          </a>
        </Label>

        {!isLoadingFields && (
          <FieldContainer style={{ marginTop: "25px", marginBottom: "32px" }}>
            <Label>Filter by</Label>
            <Filter filters={filters} fields={fields} updateFilter={updateFilter} showAllFilters />
          </FieldContainer>
        )}
      </div>
    </PermissionWrapper>
  );
}

export default Permissions;
