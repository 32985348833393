import styled from "styled-components";
import InfoIcon from "@atlaskit/icon/glyph/editor/info";
import Flag from "@atlaskit/flag";
import { useEffect, useState } from "react";

import { SectionKeys, SectionPagesIDs } from "../../sections";

const Wrapper = styled.div`
  width: 368px;
  font-size: 14px;
  position: fixed;
  bottom: ${({ bottom }) => bottom}; // 120px
  right: 48px;
`;

function ConstructionFlag({ bottom, shouldRender }) {
  const [flag, setFlag] = useState([]);

  useEffect(() => {
    setFlag([
      {
        title: "This site is under construction",
        description: "Users can only see the Under Construction page. To change this, go to Under Construction.",
      },
    ]);
  }, []);

  const dissmissFlag = () => {
    setFlag([]);
  };

  if (!shouldRender) {
    return null;
  }

  return (
    <Wrapper bottom={bottom}>
      {flag.map((flag) => (
        <Flag
          appearance="normal"
          id={0}
          icon={<InfoIcon label="Info" />}
          title={flag.title}
          description={flag.description}
          actions={[
            {
              content: "Go to Under Construction",
              href: `${window.AP._hostOrigin}/wiki/plugins/servlet/ac/com.caelor.confluence.cloud.cosmos/caelor-cosmos-portal?ac.cosmosPage=settings-${SectionKeys.CONFIGURATION}-${SectionPagesIDs.UNDER_CONSTRUCTION}`,
              target: "_blank",
            },
            {
              content: "Dismiss",
              onClick: () => {
                dissmissFlag();
              },
            },
          ]}
        />
      ))}
    </Wrapper>
  );
}

export default ConstructionFlag;
