import React, { useCallback } from "react";
import styled from "styled-components";
import { useChangesStore } from "../../../track-changes-store";
import { AvailablePanels } from "../Panel/availablePanels";
import { SIDEBAR_TYPES } from "./types";
import { MediumBlueRightIcon } from "./Icons";
import { useSidebarStore } from "../store/sidebar-store";
import { DEFAULT_DATA_BY_PANEL_KEY } from "../Panel/panelDataByKey";
import { usePanelsStore } from "../store/panels-store";
import HoverDialog from "../Panel/panels/HoverDialog";
import { analytics } from "../../../../../analytics";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ActionIconContainer = styled.div`
  width: 24px;
  height: 24px;
  display: grid;
  place-content: center;

  visibility: hidden;
`;

const PanelWrapper = styled.div`
  padding: 12px;
  display: grid;
  grid-template-columns: 24px auto 24px;
  column-gap: 12px;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.shared.sidebar.preview.background};

  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.shared.sidebar.preview.hover};
    transition: background-color 50ms linear;

    ${ActionIconContainer} {
      visibility: visible;
    }
  }
`;

const Title = styled.span`
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: ${({ theme }) => theme.shared.sidebar.preview.title};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Description = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  color: ${({ theme }) => theme.shared.sidebar.preview.description};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

function AddPanels() {
  const { panels, setPanels, setEditingPanel } = usePanelsStore((state) => ({
    panels: state.panels,
    setPanels: state.setPanels,
    setEditingPanel: state.setEditingPanel,
  }));

  const primaryColor = "#0065ff";

  const { setSidebarType, setHoveringPanel } = useSidebarStore((state) => ({
    setSidebarType: state.setSidebarType,
    setHoveringPanel: state.setHoveringPanel,
  }));

  const { setHasChanges } = useChangesStore((state) => ({
    setHasChanges: state.setHasChanges,
  }));

  const generateNewPanel = (key, type, title) => {
    const newPanel = {
      id: crypto.randomUUID(),
      order: panels.length + 1,
      type,
      key,
      panelTitle: title,
      data: DEFAULT_DATA_BY_PANEL_KEY[key],
    };

    window.AP.events.emit("new-panel-added", { newPanelId: newPanel.id });
    analytics.logEvent({ name: "People Network module", template: "create", params: { module: newPanel.data.title } });

    setPanels([...panels, newPanel]);
    setEditingPanel(newPanel);
    setSidebarType(SIDEBAR_TYPES.PANEL_EDIT);
    setHasChanges(true);
  };

  const onMouseEnter = useCallback((event, key, Icon) => {
    event.preventDefault();
    setHoveringPanel({ key, Icon });
  }, []);

  const onMouseLeave = useCallback((event) => {
    event.preventDefault();
    setHoveringPanel(undefined);
  }, []);

  return (
    <Wrapper style={{ marginTop: "20px" }}>
      {AvailablePanels?.map(({ key, dataKey, type, title, description, Icon }) => (
        <PanelWrapper
          key={key}
          onClick={() => generateNewPanel(dataKey || key, type, title)}
          onMouseMove={(e) => onMouseEnter(e, key, Icon)}
          onMouseEnter={(e) => onMouseEnter(e, key, Icon)}
          onMouseLeave={(e) => onMouseLeave(e)}
        >
          <Icon />
          <Wrapper>
            <Title>{title}</Title>
            <Description>{description}</Description>
          </Wrapper>
          <ActionIconContainer>
            <MediumBlueRightIcon primaryColor={primaryColor} />
          </ActionIconContainer>
        </PanelWrapper>
      ))}

      <HoverDialog />
    </Wrapper>
  );
}

export default AddPanels;
