import { useMemo } from "react";
import styled, { useTheme } from "styled-components";

import {
  Description,
  Title,
  LinksWrapper,
  LinkInfoWrapper,
  LinkLabel,
  IconContainer,
  StaffPickWrapper,
  StaffPickerText,
  Parent,
} from "./SpaceportDrawer";
import { DemoIcon, DocumentationIcon, SupportIcon, LockIcon, LinkIcon, FilledStarIcon, StarIcon } from "./Icons";
import Tooltip from "@atlaskit/tooltip";

const LinkIconWrapper = styled.div`
  display: flex;
  align-items: end;
  margin-left: auto;
`;

const StarsContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
  flex-direction: row;
  margin: 20px 0px;
`;

const StarsWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  margin-top: 40px;
`;

const ImageStarsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  img {
    width: 70px;
    margin-left: 12px;
    position: relative;
    top: 20px;
  }
`;

const LinkLabelApps = styled.span`
  font-weight: 550;
  font-size: 17px;
  line-height: 17px;
  color: ${({ theme }) => theme.spaceport.appTitle};
`;

const LinkContainerLinks = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  width: 452px;
  box-sizing: border-box;
  padding: 8px 20px;
  cursor: pointer;
  overflow: hidden;
`;

const LinkContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  max-width: 440px;
  box-sizing: border-box;
  padding: 8px 20px 10px;
  border-radius: 5px;
  border: 0.75px solid ${({ theme }) => theme.spaceport.appContainerBorder};
  box-shadow: 0px 1px 1px 0px #091e4240;
  cursor: pointer;
  overflow: hidden;
  position: relative;
`;

const LinkInfoWrapperApps = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 10px;
  position: relative;
  bottom: 23px;
  padding-top: 4px;
  right: 10px;
`;

const Installs = styled.span`
  position: relative;
  right: 115px;
  top: 55px;
  font-size: 15px;
  line-height: 14px;
  color: #7c889b;
  white-space: nowrap;
`;

const LinksWrapperApps = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  gap: 20px;
`;

function HelpTab({ primaryColor }) {
  const theme = useTheme();

  const links = useMemo(
    () => [
      {
        label: "Support",
        Icon: SupportIcon,
        url: "https://caelor.atlassian.net/servicedesk/customer/portal/1",
      },
      {
        label: "Book a demo",
        Icon: DemoIcon,
        url: "https://calendly.com/caelor/demo",
      },
      {
        label: "Documentation",
        Icon: DocumentationIcon,
        url: "https://caelor-apps.atlassian.net/wiki/x/LRL6",
      },
      {
        label: "Security & Data privacy",
        Icon: LockIcon,
        url: "https://caelor-apps.atlassian.net/wiki/x/UwCZ",
      },
    ],
    [],
  );

  const apps = useMemo(
    () => [
      {
        label: "Optics",
        description:
          "Perfectly formatted Confluence content: Button, Cards, Panel, Page Divider, Tabs & more macros | Styleguide | Templates | Library",
        url: "https://marketplace.atlassian.com/apps/1222649?tab=overview&hosting=cloud",
        icon: "/images/apps/optic-icon.svg",
        installs: "3k installs",
        hasSpotlight: true,
      },
      {
        label: "Pulse",
        description:
          "Attractive Confluence blogs, pages, people & spaces overviews. Organize content with Filters, News Modules, OrgCharts and more",
        url: "https://marketplace.atlassian.com/apps/1224103?tab=overview&hosting=cloud",
        icon: "/images/apps/pulse-icon.svg",
        installs: "1.8k installs",
        hasSpotlight: false,
      },
    ],
    [],
  );

  const stars = useMemo(() => {
    let defaultStars = [];

    for (let idx = 0; idx < 4; idx += 1) {
      defaultStars[idx] = { isFilled: true };
    }

    return defaultStars;
  }, []);

  return (
    <Parent>
      <Title style={{ margin: "25px 0px 15px" }}>Spaceport</Title>
      <Description>Need help? Looking for updates? Contact us!</Description>
      <LinksWrapper>
        {links.map(({ label, Icon, url }) => (
          <LinkContainerLinks key={label} onClick={() => window.open(url, "_blank")}>
            <LinkInfoWrapper>
              <IconContainer>
                <Icon primaryColor={primaryColor} />
                <LinkLabel>{label}</LinkLabel>
              </IconContainer>
            </LinkInfoWrapper>
            <LinkIconWrapper>
              <LinkIcon primaryColor={theme.poll.icons} />
            </LinkIconWrapper>
          </LinkContainerLinks>
        ))}
      </LinksWrapper>

      <Title
        style={{
          borderTop: `2px solid ${theme.spaceport.divider}`,
          paddingTop: "20px",
        }}
      >
        Achieve more with
      </Title>
      <LinksWrapperApps>
        {apps.map(({ label, description, icon, url, installs, hasSpotlight }) => (
          <LinkContainer key={label} onClick={() => window.open(url, "_blank")}>
            <ImageStarsWrapper>
              <img src={icon} alt={label} />

              <StarsWrapper>
                <StarsContainer>
                  {stars.map(({ isFilled }, idx) => (
                    <Tooltip key={idx} position="top">
                      <IconContainer key={idx}>{isFilled ? <FilledStarIcon /> : <StarIcon />}</IconContainer>
                    </Tooltip>
                  ))}
                </StarsContainer>
              </StarsWrapper>
            </ImageStarsWrapper>

            <LinkInfoWrapperApps>
              <LinkLabelApps>{label}</LinkLabelApps>
              <Description>{description}</Description>
            </LinkInfoWrapperApps>

            <Installs>{installs}</Installs>

            {hasSpotlight && (
              <StaffPickWrapper>
                <StaffPickerText>SPOTLIGHT</StaffPickerText>
              </StaffPickWrapper>
            )}
          </LinkContainer>
        ))}
      </LinksWrapperApps>
    </Parent>
  );
}

export default HelpTab;
