import { PanelTypes } from "../../panelTypes";

export const sizeType = {
  PADDING: "padding",
  BORDER_RADIUS: "borderRadius",
  CARD_ROUNDING: "cardRounding",
  IMAGE_SIZE: "imageSize",
};

const NewsData = {
  [sizeType.PADDING]: [
    { label: "S", value: 20 },
    { label: "M", value: 24 },
    { label: "L", value: 30 },
  ],
  [sizeType.BORDER_RADIUS]: [
    { label: "S", value: 3 },
    { label: "M", value: 8 },
    { label: "L", value: 12 },
  ],
};

const DatesData = {
  [sizeType.CARD_ROUNDING]: [
    { label: "S", value: 3 },
    { label: "M", value: 6 },
    { label: "L", value: 8 },
  ],
  [sizeType.IMAGE_SIZE]: [
    { label: "S", value: 60 },
    { label: "M", value: 80 },
    { label: "L", value: 100 },
  ],
};

const PeopleData = {
  [sizeType.IMAGE_SIZE]: [
    { label: "S", value: 60 },
    { label: "M", value: 80 },
    { label: "L", value: 100 },
  ],
};

export const sizesByPanelType = {
  [PanelTypes.NEWS]: NewsData,
  [PanelTypes.NEW_USERS_MAIN]: DatesData,
  [PanelTypes.BIRTHDAY]: DatesData,
  [PanelTypes.PEOPLE]: PeopleData,
};
