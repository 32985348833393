import React from "react";

import { ControlButton, ControlContainer, ControlDescription, ControlTitle, ScreenContainer } from "../../styles";
import { useCosmosAppStore } from "../../cosmos-app-store";

const Maintenance = () => {
  const cosmosAppSettings = useCosmosAppStore((state) => state.cosmosAppSettings);

  return (
    <ScreenContainer style={{ display: "grid", placeContent: "center" }}>
      <ControlContainer>
        <img src="/images/cosmos-app/logos/cosmos-logo.svg" alt="Cosmos" width={64} />
        <ControlTitle>{cosmosAppSettings?.maintenance?.title}</ControlTitle>
        <ControlDescription>{cosmosAppSettings?.maintenance?.text}</ControlDescription>
      </ControlContainer>

      {cosmosAppSettings?.maintenance?.button?.label && cosmosAppSettings?.maintenance?.button?.path && (
        <ControlButton backgroundColor={cosmosAppSettings?.brand?.color}>
          {cosmosAppSettings?.maintenance?.button?.label}
        </ControlButton>
      )}
    </ScreenContainer>
  );
};

export default Maintenance;
