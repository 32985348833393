import React from "react";

export const IconGENERIC = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" role="presentation">
    <path
      fill="#5E6C84"
      fillRule="evenodd"
      d="M8 3H5.333C4.597 3 4 3.617 4 4.378v7.244C4 12.382 4.597 13 5.333 13h5.334c.736 0 1.333-.617 1.333-1.378V8h-1.333v3.622H5.333V4.378H8v1.414c0 .736.597 1.333 1.333 1.333h2.334c.184 0 .333-.15.333-.333v-.903a.333.333 0 00-.093-.231l-2.164-2.25A1.333 1.333 0 008.782 3H8zM0 1.994C0 .893.895 0 1.994 0h12.012C15.107 0 16 .895 16 1.994v12.012A1.995 1.995 0 0114.006 16H1.994A1.995 1.995 0 010 14.006V1.994z"
    />
  </svg>
); // other types

export const IconPDF = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" role="presentation">
    <path
      fill="#FF5630"
      fillRule="evenodd"
      d="M2 0h12a2 2 0 012 2v12a2 2 0 01-2 2H2a2 2 0 01-2-2V2a2 2 0 012-2zm1.23 8.113h.323c.744 0 .802-.214.802-.517 0-.346-.179-.514-.545-.514h-.58v1.03zm4.194 1.456h.443c.494 0 1-.147 1-1.237 0-1.1-.534-1.24-1-1.24h-.443v2.477zM2.975 10.66h-.72A.256.256 0 012 10.404V6.255c0-.14.114-.255.255-.255h1.663c.989 0 1.68.656 1.68 1.596 0 .953-.677 1.593-1.683 1.593H3.23v1.215a.256.256 0 01-.256.256zm4.968 0H6.448a.256.256 0 01-.255-.256V6.255c0-.14.114-.255.255-.255h1.495c1.404 0 2.178.825 2.178 2.323 0 1.507-.774 2.337-2.178 2.337zm3.85 0h-.72a.256.256 0 01-.256-.256V6.255c0-.14.114-.255.255-.255h2.673c.14 0 .255.115.255.255v.587a.256.256 0 01-.255.255h-1.697v.76h1.528c.141 0 .255.115.255.256v.577c0 .14-.114.256-.255.256h-1.528v1.458a.256.256 0 01-.255.256z"
    />
  </svg>
); // pdf

export const IconIMG = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" role="presentation">
    <path
      fill="#FFAB00"
      fillRule="evenodd"
      d="M2 0h12a2 2 0 012 2v12a2 2 0 01-2 2H2a2 2 0 01-2-2V2a2 2 0 012-2zm4.75 11.75l-.543-.543a1 1 0 00-1.414 0L3 13h10v-2.25l-1.795-1.974a1 1 0 00-1.447-.034L6.75 11.75zM4.667 6.333a1.667 1.667 0 100-3.333 1.667 1.667 0 000 3.333z"
    />
  </svg>
); // image

export const IconARCHIVE = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" role="presentation">
    <path
      fill="#5E6C84"
      fillRule="evenodd"
      d="M2 0h12a2 2 0 012 2v12a2 2 0 01-2 2H2a2 2 0 01-2-2V2a2 2 0 012-2zm4 3v2h2V3H6zm2 2v2h2V5H8zM6 7v2h2V7H6zm2 2v2h2V9H8zm-2.307 2v2h2v-2h-2z"
    />
  </svg>
); // zip, rar

export const IconAUDIO = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" role="presentation">
    <path
      fill="#FF5630"
      fillRule="evenodd"
      d="M2 0h12a2 2 0 012 2v12a2 2 0 01-2 2H2a2 2 0 01-2-2V2a2 2 0 012-2zm11 4.195v-.753c0-.271-.23-.468-.514-.44l-6.115.634a.565.565 0 00-.514.545v4.616a2 2 0 00-.571-.083H5a2 2 0 00-2 2V11a2 2 0 002 2h.286a2 2 0 002-2V5.76l4.285-.444v2.767A2 2 0 0011 8h-.286a2 2 0 00-2 2v.286a2 2 0 002 2H11a2 2 0 002-2V4.195z"
    />
  </svg>
); // audio

export const IconEXE = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" role="presentation">
    <path
      fill="#5E6C84"
      fillRule="evenodd"
      d="M0 1.994C0 .893.895 0 1.994 0h12.012C15.107 0 16 .895 16 1.994v12.012A1.995 1.995 0 0114.006 16H1.994A1.995 1.995 0 010 14.006V1.994zm3 6.72v3.572c0 .394.32.714.714.714h8.572c.394 0 .714-.32.714-.714V8.714H3zM4.345 3a.714.714 0 00-.709.626L3 8.714h10l-.636-5.088A.714.714 0 0011.655 3h-7.31zm.798 7.143h5.714a.714.714 0 010 1.428H5.143a.714.714 0 110-1.428z"
    />
  </svg>
); // exe

export const IconGIF = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" role="presentation">
    <path
      fill="#FFAB00"
      fillRule="evenodd"
      d="M2 0h12a2 2 0 012 2v12a2 2 0 01-2 2H2a2 2 0 01-2-2V2a2 2 0 012-2zm7.042 11.09H7.755a.162.162 0 01-.162-.161v-5.34c0-.09.072-.162.162-.162h1.287c.09 0 .163.072.163.163v5.339c0 .09-.073.162-.163.162m2.528 0h-1.288a.162.162 0 01-.162-.162v-5.34c0-.09.072-.162.162-.162h3.556c.089 0 .162.072.162.163v1.048c0 .09-.073.162-.162.162h-2.106v.947h1.898c.09 0 .162.073.162.162v1.013c0 .09-.072.162-.162.162h-1.898v1.845c0 .09-.074.162-.162.162m-7.045.097C2.944 11.188 2 10.207 2 8.562v-.641c0-1.597.968-2.588 2.528-2.588 1.544 0 2.291.978 2.349 1.948a.161.161 0 01-.162.172H5.463a.162.162 0 01-.16-.133c-.066-.367-.368-.595-.786-.595-.74 0-.894.657-.894 1.207v.626c0 .822.312 1.238.929 1.238.59 0 .8-.359.8-.667v-.06h-.77a.162.162 0 01-.161-.163v-.915c0-.09.072-.162.162-.162h2.144c.09 0 .162.072.162.162v1.036c0 .997-.62 2.161-2.364 2.161"
    />
  </svg>
); // gifs

export const IconVIDEO = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" role="presentation">
    <path
      fill="#FF5630"
      fillRule="evenodd"
      d="M2 0h12a2 2 0 012 2v12a2 2 0 01-2 2H2a2 2 0 01-2-2V2a2 2 0 012-2zm11.37 10.954c.285.138.63-.05.63-.343V5.39c0-.293-.345-.481-.63-.343L11 6.194v3.613l2.37 1.147zM2 5.99v4.018c0 .54.449.991 1.003.991h4.994A.99.99 0 009 10.01V5.99C9 5.452 8.551 5 7.997 5H3.003A.99.99 0 002 5.99z"
    />
  </svg>
);
