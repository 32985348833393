/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useEffect, useState } from "react";
import TextField from "@atlaskit/textfield";
import { css, jsx } from "@emotion/react";
import { FieldContainer, Label } from "./styled";
import styled, { useTheme } from "styled-components";
import { TextEditor } from "@caelor/cards-and-panels-components";
import TextArea from "@atlaskit/textarea";

const TextInputWrapper = styled.div`
  div:first-child,
  textarea:first-child {
    border-color: ${({ theme }) => theme.shared.form.input.border};
    color: ${({ theme }) => theme.shared.form.input.text};
    background-color: ${({ theme }) => theme.shared.form.input.background};

    &:hover,
    &:focus,
    &:active {
      background-color: ${({ theme }) => theme.shared.form.input.hover};
    }
  }
`;
const TextEditorContainer = styled.div`
  margin-top: 20px;

  & > div:nth-child(2) {
    border-color: ${({ theme }) => theme.shared.form.input.border};

    .text-options-wrapper {
      background-color: ${({ theme }) => theme.shared.form.textarea.background};
    }

    .icon-container {
      svg {
        color: ${({ theme }) => theme.shared.form.textarea.actions};
      }
    }

    svg.border {
      path {
        stroke: ${({ theme }) => theme.shared.form.textarea.divider};
      }
    }
  }
`;

const InputFilter = ({
  initialValue,
  onUpdate,
  label = "",
  placeholder = "",
  numberField = false,
  multiLine = false,
  minLength = 0,
  maxLength = 9999,
  isDisabled = false,
  minRows = 2,
  textFormatting = false,
  containerStyle = {},
}) => {
  const [inputValue, setInputValue] = useState("");

  const theme = useTheme();

  useEffect(() => {
    if (numberField) {
      setInputValue(isNaN(initialValue) ? "" : initialValue);
    } else {
      setInputValue(initialValue);
    }
  }, [initialValue]);

  const handleChange = (e) => {
    if (numberField && minLength > 0 && Number(e.target.value) > 0) {
      if (maxLength < e.target.value) {
        setInputValue(maxLength);
        onUpdate(maxLength);
      } else {
        setInputValue(e.target.value);
        onUpdate(e.target.value);
      }
    } else {
      setInputValue(e.target.value);
      onUpdate(e.target.value);
    }
    if (numberField && e.target.value === "0") {
      setInputValue(minLength);
      onUpdate(minLength);
    }
  };
  const handleChangeDesc = (value) => {
    if (numberField && minLength > 0 && Number(value) > 0) {
      setInputValue(value);
      onUpdate(value);
    } else {
      setInputValue(value);
      onUpdate(value);
    }
  };

  return (
    <FieldContainer style={{ ...containerStyle }}>
      {textFormatting ? (
        <TextEditorContainer>
          {label && <Label>{label}</Label>}
          <TextEditor
            linkBackgroundColor={theme.manualOrgChart.buttonColor}
            linkButtonsColor={theme.manualOrgChart.buttonColorActive}
            linkIconColor={theme.manualOrgChart.iconsColor}
            linkActionBackgroundColor={theme.manualOrgChart.minusPlusColor}
            value={inputValue}
            onChangeComplete={handleChangeDesc}
            textColor={theme.shared.form.input.text}
          />
        </TextEditorContainer>
      ) : (
        <>
          {multiLine ? (
            <>
              {label && <Label>{label}</Label>}
              <TextInputWrapper>
                <TextArea
                  resize="none"
                  value={inputValue}
                  placeholder={placeholder}
                  onChange={handleChange}
                  minimumRows={minRows}
                  maxLength={maxLength}
                />
              </TextInputWrapper>
            </>
          ) : (
            <>
              {label && <Label>{label}</Label>}
              <TextInputWrapper>
                <TextField
                  aria-haspopup={false}
                  appearance="subtle"
                  value={inputValue}
                  min={minLength}
                  minLength={minLength}
                  max={maxLength}
                  maxLength={maxLength}
                  placeholder={placeholder}
                  type={numberField ? "number" : "text"}
                  onChange={handleChange}
                />
              </TextInputWrapper>
            </>
          )}
        </>
      )}
    </FieldContainer>
  );
};

export default InputFilter;
