import React, { useEffect, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import CustomSketchPicker from "./CustomSketchPicker";

export const MultipleColorsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 20px;

  div:nth-child(3) {
    #sketch-picker-custom-color {
      right: 5px !important;
      left: auto !important;
    }
  }
`;

const ColorContainer = styled.div`
  display: ${({ isHidden }) => (isHidden ? "none" : "flex")};
  align-items: center;
  column-gap: 10px;
  overflow: visible;
  position: relative;
`;

const ColorBox = styled.div`
  flex: 1 1 100%;
  box-sizing: border-box;
  width: 48px;
  max-width: 48px;
  height: 48px;
  background: ${({ color, showColorIsTransparent }) => {
    return showColorIsTransparent ? "rgba(9, 30, 66, 0.06)" : color;
  }};
  border: 4px solid ${({ theme }) => theme.shared.form.colorpicker.colorBox};
  border-radius: 3px;
  cursor: ${({ isDisabled }) => (isDisabled ? "not-allowed" : "pointer")};
  opacity: ${({ isDisabled }) => (isDisabled ? "0.7" : "1")};

  ${({ showColorIsTransparent }) => {
    if (showColorIsTransparent) {
      return `
        &::before {
          content: "|";
          display: grid;
          place-content: center;
          color: red;
          height: 21px;
          font-size: 40px;
          padding-left: 5px;
          padding-top:1px;
           transform: rotateZ(30deg);
         
        }
      `;
    }
    return "";
  }}
`;

const ColorLabel = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.shared.sidebar.colors.label};
  font-weight: 400;
  opacity: ${({ isDisabled }) => (isDisabled ? "0.7" : "1")};
`;

const DEFAULT_COLORS = [
  "#0052CC",
  "#172b4d",
  "#00a2e0",
  "#00badd",
  "#00b779",
  "#ffc300",
  "#FF8B00",
  "#ff4310",
  "#6a4ec7",
  "#DFE1E6",
  "#ffffff",
];

function SingleTileColorPicker({
  label,
  value,
  setValue,
  additionalStyle,
  presetColors,
  isDisabled,
  disableAlpha,
  disableTransparent,
  isHidden,
  closeOnSelect,
  onOpen = () => {},
  onClose = () => {},
}) {
  const [isColorPickerOpen, setIsColorPickerOpen] = useState(false);
  const [showColorIsTransparent, setShowColorIsTransparent] = useState(false);

  useEffect(() => {
    if (value) {
      const hasOpacity = value.length >= 8;
      if (value === "transparent" || (hasOpacity && value.slice(-2) === "00")) {
        setShowColorIsTransparent(true);
      } else {
        setShowColorIsTransparent(false);
      }
    }
  }, [value]);

  return (
    <ColorContainer isHidden={isHidden}>
      <ColorBox
        color={value}
        isDisabled={isDisabled}
        showColorIsTransparent={showColorIsTransparent}
        onClick={() => {
          if (!isDisabled) {
            setIsColorPickerOpen(true);
            onOpen();
          }
        }}
      />
      {label && <ColorLabel isDisabled={isDisabled}>{label}</ColorLabel>}
      {isColorPickerOpen && (
        <CustomSketchPicker
          selectedColor={value}
          setSelectedColor={(value) => {
            setValue(value);

            if (closeOnSelect) {
              setIsColorPickerOpen(false);
              onClose();
            }
          }}
          additionalStyle={additionalStyle}
          presetColors={[...presetColors, "transparent"]}
          callback={() => {
            setIsColorPickerOpen(false);
            onClose();
          }}
          disableAlpha={disableAlpha}
          disableTransparent={disableTransparent}
        />
      )}
    </ColorContainer>
  );
}

SingleTileColorPicker.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  presetColors: PropTypes.arrayOf(PropTypes.string),
  additionalStyle: PropTypes.object,
  isDisabled: PropTypes.bool,
  isHidden: PropTypes.bool,
  closeOnSelect: PropTypes.bool,
  disableAlpha: PropTypes.bool,
};

SingleTileColorPicker.defaultProps = {
  presetColors: DEFAULT_COLORS,
  additionalStyle: {
    top: "5px",
    left: "5px",
  },
  closeOnSelect: false,
  isDisabled: false,
  isHidden: false,
  disableAlpha: false,
};

export default SingleTileColorPicker;
