import React, { useEffect, useRef, useState } from "react";
import styled, { useTheme } from "styled-components";
import { OrganizationChart } from "primereact/organizationchart";
import { ChartToggler } from "@caelor/cards-and-panels-components";
import PanAndZoomWrapper from "../../../../../Shared/Components/PanAndZoom/PanAndZoom";
import { ChartContext } from "../context";
import UserTemplate from "./UserTemplate";
import eventBus from "../../../../../Shared/Functions/eventBus";
import { useStructureStore } from "../structure-store";

const Wrapper = styled.div`
  height: 100%;
  overflow: hidden;

  ${({ isPreview }) => {
    if (isPreview) {
      return `
        min-height: 500px;
        padding-bottom: 50px;

        .p-organizationchart-table {
          cursor: grab;

          &:active {
            cursor: grabbing;
          }
        }
    `;
    }
  }}

  .p-node-toggler {
    ${({ isPreview }) => {
      if (!isPreview) {
        return "left: calc(50% - 28px);";
      }
    }}
  }

  .p-organizationchart > .p-organizationchart-table:first-child > tbody {
    ${({ hasMultipleFirstLevelNodes }) => {
      return `
          & > tr:nth-child(-n + ${hasMultipleFirstLevelNodes ? "2" : "3"}) {
            visibility: hidden !important;
          }
        `;
    }}
  }

  .p-organizationchart-line-down {
    width: 4px;
    background-color: ${({ connectingLineColor }) => connectingLineColor};
  }

  .p-organizationchart-lines {
    .p-organizationchart-line-right.p-organizationchart-line-top:not(:nth-child(2)):not(:nth-last-child(2)) {
      border-top: ${({ connectingLineColor }) => `4px solid ${connectingLineColor}`};
      border-left: ${({ connectingLineColor }) => `2px solid ${connectingLineColor}`};
    }
    .p-organizationchart-line-left.p-organizationchart-line-top:not(:nth-child(2)):not(:nth-last-child(2)) {
      border-top: ${({ connectingLineColor }) => `4px solid ${connectingLineColor}`};
      border-right: ${({ connectingLineColor }) => `2px solid ${connectingLineColor}`};
    }

    .p-organizationchart-line-right.p-organizationchart-line-top:not(:not(:nth-child(2)):not(:nth-last-child(2))) {
      border-top: ${({ connectingLineColor }) => `4px solid ${connectingLineColor}`};
      border-left: ${({ connectingLineColor }) => `4px solid ${connectingLineColor}`};
    }
    .p-organizationchart-line-left.p-organizationchart-line-top:not(:not(:nth-child(2)):not(:nth-last-child(2))) {
      border-top: ${({ connectingLineColor }) => `4px solid ${connectingLineColor}`};
      border-right: ${({ connectingLineColor }) => `4px solid ${connectingLineColor}`};
    }
  }
`;

export const Title = styled.h6`
  color: ${({ theme }) => theme.shared.emptyState.text};
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin: 0px;
`;

function Chart({
  isPreview = true,
  userRecords,
  structure,
  fields,
  viewOptions,
  onItemClick,
  minZoomOnCenterView = undefined,
  initialY = undefined,
}) {
  const [resizedInit, setResizedInit] = useState(false);

  const { storedStructure, setStoredStructure, updateNodeExpandedState } = useStructureStore((state) => ({
    storedStructure: state.structure,
    setStoredStructure: state.setStructure,
    updateNodeExpandedState: state.updateNodeExpandedState,
  }));

  const chartRef = useRef();

  const theme = useTheme();

  useEffect(() => {
    if (structure) {
      setStoredStructure({ ...structure });
    }
  }, [structure, setStoredStructure]);

  useEffect(() => {
    if (chartRef?.current && !resizedInit && storedStructure?.children?.length) {
      eventBus.dispatch("caelor-handle-center-view", { minZoomOnCenterView });
      setResizedInit(true);
    }
  }, [chartRef, resizedInit, storedStructure]);

  if (!storedStructure?.children?.length) {
    return (
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
        <img src={`/images/hub/people-empty-state-${theme.global.name}.svg`} alt="" />
        <Title>Select database column to see the preview</Title>
      </div>
    );
  }

  return (
    <ChartContext.Provider
      value={{
        isPreview,
        userRecords,
        fields,
        viewOptions,
        onItemClick,
      }}
    >
      <Wrapper
        isPreview={isPreview}
        connectingLineColor={viewOptions?.lineColor || "#4C9AFF"}
        hasMultipleFirstLevelNodes={storedStructure?.children?.length > 1}
      >
        <PanAndZoomWrapper
          isPreview={isPreview}
          initialY={typeof initialY === "number" ? initialY : 55}
          mainWrapperStyle={{ height: "100%" }}
          minZoom={0.3}
          maxZoom={2}
        >
          <OrganizationChart
            ref={chartRef}
            value={[storedStructure]}
            nodeTemplate={UserTemplate}
            togglerIcon={ChartToggler}
            pt={{
              nodeTogglerIcon: { isPreview, updateNodeExpandedState },
            }}
          />
        </PanAndZoomWrapper>
      </Wrapper>
    </ChartContext.Provider>
  );
}

export default Chart;
