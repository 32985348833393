export const PANEL_VERSION = 2;

export const PanelPositioning = {
  MAIN: "main",
  SIDE: "side",
};

export const PanelTypes = {
  USER_START: "user_start",
  NEWS: "news",
  // PERSONALIZED_NEWS: "personalized_news",
  // TARGETED_NEWS: "targeted_news",
  PAGES: "pages",
  NOTIFICATION: "notification",
  BIRTHDAY: "birthday",
  POLL: "poll",
  COUNTDOWN: "countdown",
  SPACES: "spaces",
  ASSIGNED_TASKS: "assigned_tasks",
  USEFUL_LINKS: "useful_links",
  NEW_USERS_MAIN: "new_users_main",
  VIDEOS: "videos",
  CARDS: "cards",
  PEOPLE: "people",
  ACTIVE_USERS: "recently_active_users",
  GLIFFY: "gliffy",
  RELEASED_AI: "released",
};

export const PanelKeys = {
  COMPANY_NEWS: "company-news",
  // PERSONALIZED_NEWS: "personalized-news",
  PAGES: "pages",
  PAGES_SIDE: "pages-side",
  TEAM_NEWS: "team-news",
  SIDE_NEWS: "side-news",
  // TARGETED_NEWS: "targeted-news",
  COUNTDOWN: "countdown",
  VIDEOS_YT: "videos-yt",
  VIDEOS_YT_SIDE: "videos-yt-side",
  NEW_STARTERS: "new-starters",
  NEW_STARTERS_SIDE: "new-starters-side",
  INFO_PANEL: "info-panel",
  INFO_PANEL_MAIN: "info-panel-main",
  IMPORTANT_DATES: "important-dates",
  IMPORTANT_DATES_MAIN: "important-dates-main",
  POLL: "poll",
  APP_LINKS: "app-links",
  ASSIGNED_TO_ME: "assigned-to-me",
  IMPORTANT_SPACES: "important-spaces",
  IMPORTANT_SPACES_MAIN: "important-spaces-main",
  WORK_ANNIVERSARY_MAIN: "work-aniversary-main",
  WORK_ANNIVERSARY_SIDE: "work-aniversary-side",
  ICON_CARDS: "icon-cards",
  TEXT_CARDS: "text-cards",
  IMG_CARDS: "img-cards",
  PEOPLE_MAIN: "people-main",
  PEOPLE_SIDE: "people-side",
  RECENTLY_ACTIVE_USERS_MAIN: "recently_active_users_main",
  RECENTLY_ACTIVE_USERS_SIDE: "recently_active_users_side",
  GLIFFY: "gliffy",
  RELEASED_AI: "released",
};

export const PanelCategories = {
  ALL: "all",
  FORMATTING: "formatting",
  NEWS: "news",
  PEOPLE: "people",
  PAGES: "pages",
  SPACES: "spaces",
  INTEGRATIONS: "integrations",
  OTHER: "other",
};

export const PanelFilters = {
  [PanelCategories.ALL]: Object.values(PanelKeys),
  [PanelCategories.FORMATTING]: [
    PanelKeys.COUNTDOWN,
    PanelKeys.INFO_PANEL,
    PanelKeys.INFO_PANEL_MAIN,
    PanelKeys.ICON_CARDS,
    PanelKeys.TEXT_CARDS,
    PanelKeys.IMG_CARDS,
  ],
  [PanelCategories.PEOPLE]: [
    PanelKeys.IMPORTANT_DATES,
    PanelKeys.NEW_STARTERS,
    PanelKeys.IMPORTANT_DATES_MAIN,
    PanelKeys.NEW_STARTERS_SIDE,
    PanelKeys.WORK_ANNIVERSARY_MAIN,
    PanelKeys.WORK_ANNIVERSARY_SIDE,
    PanelKeys.PEOPLE_MAIN,
    PanelKeys.PEOPLE_SIDE,
    PanelKeys.RECENTLY_ACTIVE_USERS_MAIN,
    PanelKeys.RECENTLY_ACTIVE_USERS_SIDE,
  ],
  [PanelCategories.NEWS]: [
    PanelKeys.COMPANY_NEWS,
    PanelKeys.PERSONALIZED_NEWS,
    PanelKeys.TARGETED_NEWS,
    PanelKeys.TEAM_NEWS,
    PanelKeys.SIDE_NEWS,
  ],
  [PanelCategories.OTHER]: [
    PanelKeys.ASSIGNED_TO_ME,
    PanelKeys.POLL,
    PanelKeys.VIDEOS_YT,
    PanelKeys.VIDEOS_YT_SIDE,
    PanelKeys.APP_LINKS,
  ],
  [PanelCategories.SPACES]: [PanelKeys.IMPORTANT_SPACES, PanelKeys.IMPORTANT_SPACES_MAIN],
  [PanelCategories.PAGES]: [PanelKeys.PAGES, PanelKeys.PAGES_SIDE],
  [PanelCategories.INTEGRATIONS]: [PanelKeys.GLIFFY, PanelKeys.RELEASED_AI],
};
