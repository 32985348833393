import React from "react";
import { dummyData } from "./dummy-data";
import {
  Birthday,
  CardHeader,
  CardsGridWrapper,
  Skeleton,
  SmallCardBodyWrapper,
  SmallCardSkeletonWrapper,
  SmallCardWrapper,
} from "./styles";

const UpcomingBirthdaysPreview = () => {
  return (
    <CardsGridWrapper numberOfColumns={4}>
      {dummyData.map((data, idx) => (
        <SmallCardWrapper key={idx}>
          <CardHeader backgroundUrl={data.backgroundUrl} />
          <SmallCardBodyWrapper>
            <img src={data.avatarUrl} alt="Profile" />
            <SmallCardSkeletonWrapper>
              <Skeleton width="68px" height="6px" backgroundColor="#DFE1E6" marginTop="0" />
              <Skeleton width="56px" height="6px" backgroundColor="#EBECF0" marginTop="0" />
            </SmallCardSkeletonWrapper>
          </SmallCardBodyWrapper>
          <Birthday>10 Days</Birthday>
        </SmallCardWrapper>
      ))}
    </CardsGridWrapper>
  );
};

export default UpcomingBirthdaysPreview;
