import { Box, Flex, Text } from "@atlaskit/primitives";
import ArrowLeftIcon from "@atlaskit/icon/glyph/arrow-left";
import { token } from "@atlaskit/tokens";
import React from "react";
import styled from "styled-components";
import Divider from "../shared/Divider";
import { useClickOutside } from "../../../../Shared/Hooks/useClickOutside";

const SidebarContainer = styled.div`
  display: flex;
  position: fixed;
  overflow: hidden;
  top: 0;
  bottom: 0;
  left: 0;
  box-shadow: 2px 2px 4px rgba(0 0 0 / 8%);
  justify-content: start;
  width: ${({ width, isOpen }) => (isOpen ? width : 0)}px;
  background: ${token("elevation.surface.raised")};
  z-index: 101;
  transition:
    visibility 0.1s,
    width 0.2s;
`;

const Sidebar = styled.div`
  box-sizing: border-box;
  height: 100%;
  min-width: ${({ width }) => width}px;
  position: relative;
`;

const ScrollbarWrapper = styled.div`
  height: ${({ customScrollbarHeight }) => (customScrollbarHeight ? customScrollbarHeight : "calc(100% - 60px)")};
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  scrollbar-gutter: stable;
  padding: 0px 32px 16px 32px;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.scrollbar};
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #999;
  }
`;

function Transition({
  isOpen,
  close,
  width,
  children,
  title,
  subtitle,
  disabledClickOutside,
  hasExtraIcon,
  ExtraIcon,
  handleClickOnExtraIcon,
  customScrollbarHeight,
}) {
  const sidebarRef = React.useRef();

  useClickOutside(sidebarRef, (e) => {
    if (disabledClickOutside) return;
    close(e);
  });

  return (
    <SidebarContainer ref={sidebarRef} isOpen={isOpen} width={width}>
      <Sidebar width={width}>
        <Box paddingInline="space.400" paddingBlockStart="space.400">
          <Flex columnGap="space.100" alignItems="center" justifyContent="space-between">
            <Flex columnGap="space.150" alignItems="center">
              <Box style={{ cursor: "pointer", minWidth: "24px" }} onClick={(e) => close(e)}>
                <ArrowLeftIcon primaryColor={token("color.text")} />
              </Box>
              <Text color="color.text" weight="medium" size="large" maxLines={1}>
                {title}
              </Text>
            </Flex>

            {hasExtraIcon && <ExtraIcon handleClick={handleClickOnExtraIcon} />}
          </Flex>

          {!!subtitle && (
            <Box paddingBlockStart="space.200">
              <Text color="color.text.accent.gray">{subtitle}</Text>
            </Box>
          )}
        </Box>

        <Divider />

        <ScrollbarWrapper customScrollbarHeight={customScrollbarHeight}>{children}</ScrollbarWrapper>
      </Sidebar>
    </SidebarContainer>
  );
}

export default Transition;
