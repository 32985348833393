import React from "react";
import { CenteredImage, Container, Description, Title } from "./styled";
import Button from "@atlaskit/button";

function EmptyState({ title, description, imageSrc, buttonText, onBtnClick = () => {}, paddingTop = 100 }) {
  return (
    <Container paddingTop={paddingTop}>
      <CenteredImage src={imageSrc} />
      <Title>{title}</Title>
      <Description>{description}</Description>
      <Button onClick={onBtnClick} style={{ width: 150, justifySelf: "center" }} appearance="primary">
        {buttonText}
      </Button>
    </Container>
  );
}

export default EmptyState;
