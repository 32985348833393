export const StylingOptions = [
  { label: "None", value: "none" },
  { label: "Profile header image", value: "image" },
];

export const ViewTypes = {
  CARD: "card",
  LIST: "list",
};

export const ViewOptions = [
  { label: "Card", value: ViewTypes.CARD },
  { label: "List", value: ViewTypes.LIST },
];
