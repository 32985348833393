import React, { useRef, useState } from "react";
import { DatePicker } from "@atlaskit/datetime-picker";
import styled from "styled-components";
import dayjs from "dayjs";
import { useClickOutside } from "../../../../Shared/Hooks/useClickOutside";

const DateEditorContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-inline: 2px;
  transform: translateZ(0);

  & > div > div > div {
    border: none;
    background: transparent;

    &:hover {
      background: transparent;
    }
  }

  & > div > div > div:nth-child(5) > div {
    position: absolute;
    left: 0;
    top: 45px;
  }
`;

const DateEditor = ({ initialValue, onEdit, onAbort }) => {
  const [isOpened, setIsOpened] = useState(true);
  const datePickerRef = useRef();

  const defaultValue = initialValue ? dayjs.unix(initialValue).format("YYYY-MM-DD") : dayjs().format("YYYY-MM-DD");

  useClickOutside(datePickerRef, () => setIsOpened(false));

  return (
    <DateEditorContainer ref={datePickerRef} onClick={() => setIsOpened(true)}>
      <DatePicker
        dateFormat="DD/MM/YYYY"
        defaultValue={defaultValue}
        locale="en-GB"
        isOpen={isOpened}
        onChange={(date) => {
          if (!date || date.length === 0) {
            onEdit(undefined);
            return;
          }
          onEdit(dayjs(date).unix());
          onAbort();
        }}
      />
    </DateEditorContainer>
  );
};

export default DateEditor;
