import { DEFAULT_DENVER_COLORS_DARK, DEFAULT_LONDON_COLORS } from "./generateNewEntry";

export const createDefaultNavigation = () => {
  const linkToIntranet = `${window.AP._hostOrigin}/wiki/plugins/servlet/ac/com.caelor.confluence.cloud.cosmos/caelor-cosmos-portal`;
  const linkToNewsroom = `${linkToIntranet}?ac.cosmosPage=news`;

  return [
    {
      data: {
        icon: {
          icon: "none",
          provider: "none",
        },
        link: {
          link: linkToIntranet,
          source: "direct",
        },
        name: "Intranet",
      },
      parent: "0",
      menuStyle: "hum",
      id: "c9f1056c-6ed7-4390-ac0e-11e11c3dd0ec",
      droppable: true,
    },
    {
      data: {
        icon: {
          icon: "none",
          provider: "none",
        },
        colors: {
          dark: {
            subentryBackgroundHoverColor: "#00000000",
            subentryIconColor: "#0c60ff",
            subentryTextColor: "#C7D1DB",
            subentryLinkColor: "#9FADBC",
            subentryLinkHoverColor: "#2684FF",
          },
          light: {
            subentryBackgroundHoverColor: "#00000000",
            subentryIconColor: "#0c60ff",
            subentryTextColor: "#42526e",
            subentryLinkColor: "#42526e",
            subentryLinkHoverColor: "#0065ff",
          },
        },
        name: "Confluence Apps",
        displayIcons: true,
        displayDescription: true,
        description: "Powerful apps to improve the\nConfluence cloud experience.",
        isLinkOn: true,
        linksCount: 1,
        link: [
          {
            id: 1,
            position: 1,
            linkName: "DOCUMENTATION",
          },
        ],
      },
      parent: "0",
      menuStyle: "london",
      id: "718adf28-9867-4b5b-9d82-a05821fead3a",
      droppable: true,
    },
    {
      data: {
        icon: {
          icon: "caelor/MacroSuite",
          provider: "caelor",
        },
        name: "Optics",
        description:
          "Perfectly formatted Confluence content: Button, Cards, Panel, Page Divider, Tabs & more macros | Styleguide | Templates | Library",
      },
      parent: "718adf28-9867-4b5b-9d82-a05821fead3a",
      id: "6e224156-550c-4d4a-8be8-07aeec62755e",
      droppable: true,
    },
    {
      data: {
        icon: {
          icon: "caelor/ContentViz",
          provider: "caelor",
        },
        name: "Pulse",
        description:
          "Attractive Confluence blogs, pages, people & spaces overviews. Organize content with Filters, News Modules, OrgCharts and more.",
      },
      parent: "718adf28-9867-4b5b-9d82-a05821fead3a",
      id: "7ce2546c-5a00-4ca5-8e92-4ee6e64e469b",
    },
    {
      data: {
        icon: {
          icon: "caelor/Cosmos",
          provider: "caelor",
        },
        name: "Cosmos",
        description: "Bring teams together with Confluence Cloud Intranet.",
      },
      parent: "718adf28-9867-4b5b-9d82-a05821fead3a",
      id: "e559c326-3d0b-4d40-a54d-2f4f2dfcc9f7",
    },
    {
      data: {
        icon: {
          icon: "none",
          provider: "none",
        },
        colors: {
          dark: {
            subentryBackgroundHoverColor: "#00000000",
            subentryIconColor: "#2684FF",
            subentryTextColor: "#C7D1DB",
            descriptionTextColor: "#9FADBC",
          },
          light: {
            subentryBackgroundHoverColor: "#00000000",
            subentryIconColor: "#0065ff",
            subentryTextColor: "#42526e",
            descriptionTextColor: "#44546F",
          },
        },
        name: "Resources",
        displayIcons: true,
        displayDescription: true,
        description: "Content that inspires and helps teams get work done.",
      },
      parent: "0",
      menuStyle: "sydney",
      id: "41beeb75-9b6c-4c9d-9bd0-ad90f703e0e0",
      droppable: true,
    },
    {
      data: {
        name: "Webinars",
        description: "Interactive sessions with experts on various topics.",
        icon: {
          icon: "atlaskit/PeopleGroupIcon",
          provider: "atlaskit",
        },
      },
      parent: "41beeb75-9b6c-4c9d-9bd0-ad90f703e0e0",
      id: "0ae0d91e-076d-40d0-8d12-e610b82f1cd5",
      droppable: true,
    },
    {
      data: {
        name: "Case Studies",
        description: "Real-world solutions showcased in different industries.",
        icon: {
          icon: "atlaskit/SearchIcon",
          provider: "atlaskit",
        },
      },
      parent: "41beeb75-9b6c-4c9d-9bd0-ad90f703e0e0",
      id: "0a43031a-0673-4755-ab6a-7b7d01ebf145",
    },
    {
      data: {
        name: "Ebooks and Onepagers",
        description: "Comprehensive guides and resources for your challenges.",
        icon: {
          icon: "atlaskit/BookIcon",
          provider: "atlaskit",
        },
      },
      parent: "41beeb75-9b6c-4c9d-9bd0-ad90f703e0e0",
      id: "8f5314d7-d0b9-4d95-898d-b6e18c470e79",
    },
    {
      data: {
        icon: {
          icon: "none",
          provider: "none",
        },
        colors: {
          dark: {
            subentryBackgroundHoverColor: "#00000000",
            subentryIconColor: "#0c60ff",
            subentryTextColor: "#C7D1DB",
            subentryLinkColor: "#9FADBC",
            subentryLinkHoverColor: "#2684FF",
          },
          light: {
            subentryBackgroundHoverColor: "#00000000",
            subentryIconColor: "#0c60ff",
            subentryTextColor: "#42526e",
            subentryLinkColor: "#42526e",
            subentryLinkHoverColor: "#0065ff",
          },
        },
        name: "Departments",
        type: "image",
      },
      parent: "0",
      menuStyle: "denver",
      id: "5f4e2bba-0d80-4af7-8432-a62fde2207dc",
      droppable: true,
    },
    {
      data: {
        icon: {
          icon: "atlaskit/EditorMediaWrapLeftIcon",
          provider: "atlaskit",
        },
        name: "Human resources",
        linksCount: 4,
        link: [
          {
            id: 1,
            position: 1,
            linkName: "HR Policies",
          },
          {
            id: 2,
            position: 2,
            linkName: "Employee Benefits",
          },
          {
            id: 3,
            position: 3,
            linkName: "Recruitment Process",
          },
          {
            id: 4,
            position: 4,
            linkName: "Open Positions",
          },
        ],
        image: {
          source: "link",
          link: "https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2971&q=80",
          id: "",
        },
      },
      parent: "5f4e2bba-0d80-4af7-8432-a62fde2207dc",
      id: "cc71d0e0-cb7d-4dda-ac93-52d0827d4898",
    },
    {
      data: {
        icon: {
          icon: "atlaskit/BookIcon",
          provider: "atlaskit",
        },
        name: "Marketing",
        linksCount: 3,
        link: [
          {
            id: 1,
            position: 1,
            linkName: "Brand Guidelines",
          },
          {
            id: 2,
            position: 2,
            linkName: "Marketing Strategies",
          },
          {
            id: 3,
            position: 3,
            linkName: "Campaign Results",
          },
        ],
        image: {
          source: "link",
          link: "https://images.unsplash.com/photo-1529119368496-2dfda6ec2804?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2970&q=80",
          id: "",
        },
      },
      parent: "5f4e2bba-0d80-4af7-8432-a62fde2207dc",
      id: "77e99244-cf73-40ee-9205-7e311c108360",
    },
    {
      data: {
        icon: {
          icon: "atlaskit/PeopleGroupIcon",
          provider: "atlaskit",
        },
        name: "Sales",
        linksCount: 4,
        link: [
          {
            id: 1,
            position: 1,
            linkName: "Sales Playbook",
          },
          {
            id: 2,
            position: 2,
            linkName: "Target Accounts",
          },
          {
            id: 3,
            position: 3,
            linkName: "Quota Achievements",
          },
          {
            id: 4,
            position: 4,
            linkName: "Trainings",
          },
        ],
        image: {
          source: "link",
          link: "https://images.unsplash.com/photo-1503551723145-6c040742065b-v2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=3000&q=80",
          id: "",
        },
      },
      parent: "5f4e2bba-0d80-4af7-8432-a62fde2207dc",
      id: "770db1df-76cd-4ee7-b6fd-b74da4df1f9d",
    },
    {
      data: {
        name: "IT Support",
        linksCount: 2,
        link: [
          {
            id: 1,
            position: 1,
            linkName: "System Updates",
          },
          {
            id: 2,
            position: 2,
            linkName: "IT Policies",
          },
        ],
        image: {
          source: "link",
          link: "https://plus.unsplash.com/premium_photo-1665203652761-8df896b837a8?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=3132&q=80",
          id: "",
        },
      },
      parent: "5f4e2bba-0d80-4af7-8432-a62fde2207dc",
      id: "e26e98bc-fec4-430f-8208-cebdb920927b",
    },
    {
      data: {
        icon: {
          icon: "none",
          provider: "none",
        },
        colors: {
          dark: {
            subentryBackgroundHoverColor: "#00000000",
            subentryIconColor: "#0c60ff",
            subentryTextColor: "#C7D1DB",
          },
          light: {
            subentryBackgroundHoverColor: "#00000000",
            subentryIconColor: "#0c60ff",
            subentryTextColor: "#42526e",
          },
        },
        name: "App Links",
      },
      parent: "0",
      menuStyle: "palo_alto",
      id: "30e964e7-915e-4cd0-a175-d3c0d5c2ac08",
      droppable: true,
    },
    {
      data: {
        icon: "https://caelor-apps.web.app/images/icons/atlassian.svg",
        name: "Atlassian",
      },
      parent: "30e964e7-915e-4cd0-a175-d3c0d5c2ac08",
      id: "79470c43-68c0-4ce5-b2c9-9b7de0504e0d",
    },
    {
      data: {
        icon: "https://caelor-apps.web.app/images/icons/jira.svg",
        name: "Jira Software",
      },
      parent: "30e964e7-915e-4cd0-a175-d3c0d5c2ac08",
      id: "18ef4ef3-1a65-4523-b2d0-7e55424b88b3",
    },
    {
      data: {
        icon: "https://caelor-apps.web.app/images/icons/teams.svg",
        name: "MS Teams",
      },
      parent: "30e964e7-915e-4cd0-a175-d3c0d5c2ac08",
      id: "c71989c4-8baa-4249-93ad-b9a84be885cc",
    },
    {
      data: {
        icon: "https://caelor-apps.web.app/images/icons/trello.svg",
        name: "Trello",
      },
      parent: "30e964e7-915e-4cd0-a175-d3c0d5c2ac08",
      id: "2de0394f-c003-44ce-b005-52c9c16d9b7c",
    },
    {
      data: {
        icon: "https://caelor-apps.web.app/images/icons/slack.svg",
        name: "Slack",
      },
      parent: "30e964e7-915e-4cd0-a175-d3c0d5c2ac08",
      id: "4b94c8e1-d2ab-4176-9d1c-d1473a14bf3b",
    },
    {
      data: {
        icon: "https://caelor-apps.web.app/images/icons/drive.svg",
        name: "Google Drive",
      },
      parent: "30e964e7-915e-4cd0-a175-d3c0d5c2ac08",
      id: "bb16f5c8-6eea-4116-a30e-a66903134ab7",
    },
    {
      data: {
        icon: "https://caelor-apps.web.app/images/icons/cloud.svg",
        name: "Google Cloud",
      },
      parent: "30e964e7-915e-4cd0-a175-d3c0d5c2ac08",
      id: "f8edbc62-4528-45f3-af27-32b2a0457b0a",
    },
    {
      data: {
        icon: "https://caelor-apps.web.app/images/icons/maps.svg",
        name: "Google Maps",
      },
      parent: "30e964e7-915e-4cd0-a175-d3c0d5c2ac08",
      id: "f3eba614-aae9-4033-86b9-ea15f9c8b6c7",
    },
    {
      data: {
        icon: {
          icon: "none",
          provider: "none",
        },
        link: {
          link: linkToNewsroom,
          source: "direct",
        },
        name: "Newsroom",
      },
      parent: "0",
      menuStyle: "hum",
      id: "266ca208-ccca-4690-af62-0cecbecd2619",
      droppable: true,
    },
  ];
};
