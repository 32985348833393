import styled from "styled-components";

export const ButtonsWrapper = styled.div`
  display: flex;
  column-gap: 10px;
  justify-content: flex-end;
  margin-bottom: 17px;
`;

export const Button = styled.button`
  all: unset;
  border-radius: 4px;
  padding: 6px 12px;
  font-size: 14px;
  cursor: pointer;
  text-align: center;
  background: ${({ bg }) => bg};
  color: ${({ color }) => color};

  &:hover {
    background: ${({ bg }) => bg}cc;
  }
  &:active {
    background: ${({ bg }) => bg}ee;
  }
`;

export const ModalTitle = styled.h1`
  color: ${({ theme }) => theme.confirmDeleteModal.modalHeaderText};
  font-weight: 500;
  font-size: 20px !important;
  font-style: inherit;
  line-height: 1;

  min-width: 0;
  flex: 1 1 auto;
  word-wrap: break-word;
  padding-bottom: 0px;
`;

export const Description = styled.p`
  all: unset;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-top: 12px;
`;

export const ModalHeader = styled.div`
  display: flex;
  padding: 24px;
  padding-bottom: 0px;
  position: relative;
  align-items: center;
  justify-content: space-between;
  padding-top: 8px;
`;

export const ModalBody = styled.h1`
  color: ${({ theme }) => theme.confirmDeleteModal.modalBodyText};
  font-weight: 400;
  font-size: 16px;
  font-style: inherit;
  line-height: 1;

  min-width: 0;
  flex-grow: 1;
  flex: 1 1 auto;
  padding: 2px 24px;
`;
