import BaseRestService from "./BaseRestService";

class ContentPropertyService extends BaseRestService {
  async getBlogsContentProperty(data) {
    const url = `ac/confluence/api/blogs/get-content-property?blogpostId=${data.blogPostId}&key=${data.key}`;
    return this.client.get(url, await this.buildJWTAuthAxiosConfig());
  }
  async createBlogsContentProperty(data) {
    const url = `ac/confluence/api/blogs/create-content-property`;
    return this.client.post(url, data, await this.buildJWTAuthAxiosConfig());
  }

  async updateBlogsContentProperty(data) {
    const url = `ac/confluence/api/blogs/update-content-property`;
    return this.client.put(url, data, await this.buildJWTAuthAxiosConfig());
  }

  async deleteBlogsContentProperty(data) {
    const url = `ac/confluence/api/blogs/delete-content-property`;
    return this.client.post(url, data, await this.buildJWTAuthAxiosConfig());
  }
}

export const contentPropertyService = new ContentPropertyService();
