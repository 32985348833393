import Collections from "../../../Collections/Collections";
import NavigationConfig from "../../../Navigation/configuration/Config";
import UserDatabaseOverview from "../../../UserDatabase/UserDatabaseOverview";
import WidgetOverview from "./Widgets/WidgetOverview";
import General from "./General/General";
import News from "./CosmosApp/News/News";
import People from "./CosmosApp/People/People";
import Brand from "./CosmosApp/Brand/Brand";
import AppSetupAndControl from "./CosmosApp/AppSetupAndControl/AppSetupAndControl";

import BackgroundColor from "@atlaskit/icon/glyph/editor/background-color";
import BulletList from "@atlaskit/icon/glyph/editor/bullet-list";
import Menu from "@atlaskit/icon/glyph/menu";
import Lock from "@atlaskit/icon/glyph/lock";
import Settings from "@atlaskit/icon/glyph/settings";
import Documents from "@atlaskit/icon/glyph/documents";
import PeopleGroup from "@atlaskit/icon/glyph/people-group";
import QueuesIcon from "@atlaskit/icon/glyph/queues";
import CanvasIcon from "@atlaskit/icon/glyph/canvas";
import PeopleGroupIcon from "@atlaskit/icon/glyph/people-group";
import NotificationIcon from "@atlaskit/icon/glyph/notification";
import QuoteIcon from "@atlaskit/icon/glyph/quote";
import CategoriesOverview from "./News/CategoriesOverview";
import Notifications from "./CosmosApp/Notifications/Notifications";
import SettingsIcon from "@atlaskit/icon/glyph/settings";

export const SectionKeys = {
  NAVIGATION: "navigation",
  PEOPLE_BASE: "people_base",
  NEWSROOM: "newsroom",
  EXTERNAL_APPS: "external_apps",
  INTRANET: "corporate_identity",
  CONFIGURATION: "configuration",
  COSMOS_APP: "cosmos_app",
};

export const SectionPagesIDs = {
  MENU_ENTRIES: "menu_entries",
  PERMISSIONS: "permissions",
  DATABASE: "database",
  GROUPS: "groups",
  CARDS: "cards",
  USER_PROFILE: "user_profile",
  COLLECTIONS: "collections",
  CATEGORIES: "categories",
  APPS: "apps",
  CORPORATE_IDENTITY: "corporate_identity",
  ADMINS: "admins",
  LAYOUT: "layout",
  WIDGETS: "widgets",
  GENERAL_SETTINGS: "general_settings",
  UNDER_CONSTRUCTION: "under_construction",
  NEWS: "news",
  BRAND: "brand",
  PEOPLE: "people",
  NOTIFICATIONS: "notifications",
  APP_SETUP_AND_CONTROL: "app_setup_and_control",
};

export const SectionColors = {
  BLUE: "#0065FF",
  PURPLE: "#6554C0",
  GREEN: "#36B37E",
  PINE: "#00875a",
  RED: "#FF7654",
  ORANGE: "#FF991F",
  TEAL: "#00B8D9",
  VIOLET: "#B35C94",
  AMETHYST: "#8777D9",
  SCARLET: "#DE350B",
};

export const SectionIcons = {
  [SectionPagesIDs.CORPORATE_IDENTITY]: BackgroundColor,
  [SectionPagesIDs.MENU_ENTRIES]: Menu,
  [SectionPagesIDs.PERMISSIONS]: Lock,
  [SectionPagesIDs.DATABASE]: BulletList,
  [SectionPagesIDs.COLLECTIONS]: PeopleGroup,
  [SectionPagesIDs.ADMINS]: Settings,
  [SectionPagesIDs.LAYOUT]: Documents,
  [SectionPagesIDs.CATEGORIES]: QueuesIcon,
  [SectionPagesIDs.GENERAL_SETTINGS]: Settings,
  [SectionPagesIDs.NEWS]: QuoteIcon,
  [SectionPagesIDs.BRAND]: CanvasIcon,
  [SectionPagesIDs.PEOPLE]: PeopleGroupIcon,
  [SectionPagesIDs.NOTIFICATIONS]: NotificationIcon,
  [SectionPagesIDs.APP_SETUP_AND_CONTROL]: SettingsIcon,
};

export const SectionComponents = {
  [`${SectionKeys.CONFIGURATION}-${SectionPagesIDs.ADMINS}`]: General, // redirecting to new tab in general
  [`${SectionKeys.CONFIGURATION}-${SectionPagesIDs.LAYOUT}`]: General, // redirecting to new tab in general
  [`${SectionKeys.INTRANET}-${SectionPagesIDs.CORPORATE_IDENTITY}`]: General, // redirecting to new tab in general
  [`${SectionKeys.CONFIGURATION}-${SectionPagesIDs.UNDER_CONSTRUCTION}`]: General,
  [`${SectionKeys.NAVIGATION}-${SectionPagesIDs.MENU_ENTRIES}`]: NavigationConfig,
  [`${SectionKeys.PEOPLE_BASE}-${SectionPagesIDs.DATABASE}`]: UserDatabaseOverview,
  [`${SectionKeys.PEOPLE_BASE}-${SectionPagesIDs.COLLECTIONS}`]: Collections,
  [`${SectionKeys.NEWSROOM}-${SectionPagesIDs.CATEGORIES}`]: CategoriesOverview,
  [`${SectionKeys.PEOPLE_BASE}-${SectionPagesIDs.WIDGETS}`]: WidgetOverview,
  [`${SectionKeys.CONFIGURATION}-${SectionPagesIDs.GENERAL_SETTINGS}`]: General,
  [`${SectionKeys.COSMOS_APP}-${SectionPagesIDs.NEWS}`]: News,
  [`${SectionKeys.COSMOS_APP}-${SectionPagesIDs.PEOPLE}`]: People,
  [`${SectionKeys.COSMOS_APP}-${SectionPagesIDs.BRAND}`]: Brand,
  [`${SectionKeys.COSMOS_APP}-${SectionPagesIDs.NOTIFICATIONS}`]: Notifications,
  [`${SectionKeys.COSMOS_APP}-${SectionPagesIDs.APP_SETUP_AND_CONTROL}`]: AppSetupAndControl,
};

export const Sections = [
  {
    key: [SectionKeys.CONFIGURATION],
    title: "Cosmos",
    pages: [
      {
        id: SectionPagesIDs.GENERAL_SETTINGS,
        title: "General Settings",
        description: "Configure general settings including Cosmos admins, branding and more.",
        color: SectionColors.BLUE,
        icon: SectionIcons[SectionPagesIDs.GENERAL_SETTINGS],
      },
    ],
  },
  {
    key: SectionKeys.NAVIGATION,
    title: "Navigation Menu",
    pages: [
      {
        id: SectionPagesIDs.MENU_ENTRIES,
        title: "Configuration",
        description: "Set up and edit the navigation bar.",
        color: SectionColors.RED,
        icon: SectionIcons[SectionPagesIDs.MENU_ENTRIES],
      },
    ],
  },
  {
    key: SectionKeys.PEOPLE_BASE,
    title: "People Directory",
    pages: [
      {
        id: SectionPagesIDs.DATABASE,
        title: "Employee Database",
        description: "Sync, hide/show users and edit table.",
        color: SectionColors.TEAL,
        icon: SectionIcons[SectionPagesIDs.DATABASE],
      },
      {
        id: SectionPagesIDs.COLLECTIONS,
        title: "Collections",
        description: "Group users and create people collections.",
        color: SectionColors.VIOLET,
        icon: SectionIcons[SectionPagesIDs.COLLECTIONS],
      },
      {
        id: SectionPagesIDs.WIDGETS,
        title: "Widgets & Org Charts",
        description: "Manage people widgets and organizational charts.",
        color: SectionColors.ORANGE,
        icon: SectionIcons[SectionPagesIDs.COLLECTIONS],
      },
    ],
  },
  {
    key: SectionKeys.NEWSROOM,
    title: "News",
    pages: [
      {
        id: SectionPagesIDs.CATEGORIES,
        title: "Categories",
        description: "Organize news with categories.",
        color: SectionColors.GREEN,
        icon: SectionIcons[SectionPagesIDs.CATEGORIES],
      },
    ],
  },
  {
    key: SectionKeys.COSMOS_APP,
    title: "Cosmos App",
    pages: [
      {
        id: SectionPagesIDs.APP_SETUP_AND_CONTROL,
        title: "App Setup & Control",
        description: "Manage app availability and status with options to publish, unpublish, or set maintenance modes.",
        color: SectionColors.PINE,
        icon: SectionIcons[SectionPagesIDs.APP_SETUP_AND_CONTROL],
      },
      {
        id: SectionPagesIDs.NEWS,
        title: "News",
        description: "Customize news experience.",
        color: SectionColors.AMETHYST,
        icon: SectionIcons[SectionPagesIDs.NEWS],
      },
      {
        id: SectionPagesIDs.BRAND,
        title: "Brand",
        description: "Apply company colors for a consistent app appearance.",
        color: SectionColors.TEAL,
        icon: SectionIcons[SectionPagesIDs.BRAND],
      },
      {
        id: SectionPagesIDs.PEOPLE,
        title: "People",
        description: "Select key database columns to display in people cards.",
        color: SectionColors.ORANGE,
        icon: SectionIcons[SectionPagesIDs.PEOPLE],
      },
      {
        id: SectionPagesIDs.NOTIFICATIONS,
        title: "Notifications",
        description: "In-app notifications can be manual or dynamic for new blogs and key updates.",
        color: SectionColors.SCARLET,
        icon: SectionIcons[SectionPagesIDs.NOTIFICATIONS],
      },
    ],
  },
];
