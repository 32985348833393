import React from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useChangesStore } from "../../../track-changes-store";
import { usePanelsStore } from "../store/panels-store";
import { useSidebarStore } from "../store/sidebar-store";
import {
  AddNewContainer,
  AddNewSectionWrapper,
  AddNewText,
  DividerBorder,
  IconContainer,
  Wrapper,
} from "../styled/sidebar";
import { RegularPanel, ReorderablePanel } from "../Sidebar/Panels";
import { SIDEBAR_TYPES, SIDEBAR_CONTENTS } from "./types";
import { AddIcon, SmallRightIcon } from "../Sidebar/Icons";
import HeaderSidebarDesign from "../../Shared/Header/customization/HeaderSidebarDesign";
import { useHeaderStore } from "../../../store/header-store";
import { Pages } from "../../../pages";
import { useSidebarPanelStore } from "../database-store";
import { saveHeaderSettings } from "../../../api";
import { useHeaderChangesStore } from "../../Shared/Header/customization/header-changes-store";
import { PanelTypes } from "../Panel/panelTypes";

function PanelsList() {
  const { selectedContentType, setSidebarType } = useSidebarStore((state) => ({
    selectedContentType: state.selectedContentType,
    setSidebarType: state.setSidebarType,
  }));
  const { panels, setPanels, setEditingPanel } = usePanelsStore((state) => ({
    panels: state.panels,
    setPanels: state.setPanels,
    setEditingPanel: state.setEditingPanel,
  }));
  const { headerData } = useHeaderStore((state) => ({
    headerData: state.headerData,
  }));
  const { setIsEditing } = useSidebarPanelStore((state) => ({
    setIsEditing: state.setIsEditing,
  }));

  const setHasChanges = useChangesStore((state) => state.setHasChanges);

  const updateContentState = () => {
    setHasChanges(true);
  };

  const resetOrder = (list) => {
    const resetList = list.map((c, index) => {
      const slide = c;
      slide.order = index + 1;
      return slide;
    });
    return resetList;
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return resetOrder(result);
  };

  const reorderPanels = (sourceId, targetId) => {
    if (sourceId === targetId) {
      return;
    }

    const sourceIndex = panels.findIndex((p) => p.id === sourceId);
    const targetIndex = panels.findIndex((p) => p.id === targetId);

    const items = reorder(panels, sourceIndex, targetIndex);

    setPanels(items);

    updateContentState();
  };

  const handleDeletePanel = (panelId) => {
    const newPanels = panels.filter((p) => p.id !== panelId);
    setPanels([...newPanels]);
    updateContentState();
  };

  const setHasHeaderChanges = useHeaderChangesStore((state) => state.setHasHeaderChanges);

  const primaryColor = "#0065ff";
  const primaryContrastColor = "#ffffff";

  const handleSaveHeaderSettings = (callback = () => {}) => {
    saveHeaderSettings(headerData)
      .then(() => {})
      .catch(() => {})
      .finally(() => {
        callback();
        setHasHeaderChanges(false);
      });
  };

  return selectedContentType === SIDEBAR_CONTENTS.HEADER ? (
    <HeaderSidebarDesign
      header={headerData[Pages.PEOPLE]}
      saveHeaderSettings={handleSaveHeaderSettings}
      pageToUpdate={Pages.PEOPLE}
      closePanel={() => setIsEditing(false)}
      primaryColor={primaryColor}
      primaryContrastColor={primaryContrastColor}
    />
  ) : (
    <Wrapper style={{ paddingTop: "16px" }}>
      <div>
        <DndProvider backend={HTML5Backend}>
          {panels
            .filter((panel) => panel.type !== PanelTypes.EMPLOYEES)
            .map((panel) => (
              <ReorderablePanel
                key={panel.id}
                panel={panel}
                reorderPanels={reorderPanels}
                handleClickOnEdit={(panel) => {
                  setEditingPanel(panel);
                  setSidebarType(SIDEBAR_TYPES.PANEL_EDIT);
                }}
                handleClickOnDelete={(panelId) => handleDeletePanel(panelId)}
              />
            ))}
        </DndProvider>
        <RegularPanel
          panel={panels.find((panel) => panel.type === PanelTypes.EMPLOYEES)}
          handleClickOnEdit={(panel) => {
            setEditingPanel(panel);
            setSidebarType(SIDEBAR_TYPES.PANEL_EDIT);
          }}
        />
      </div>

      <AddNewSectionWrapper>
        <DividerBorder />

        <AddNewContainer onClick={() => setSidebarType(SIDEBAR_TYPES.ADD_NEW_PANEL)}>
          <IconContainer>
            <AddIcon color={primaryColor} />
          </IconContainer>
          <AddNewText color={primaryColor}>Add new</AddNewText>
          <IconContainer>
            <SmallRightIcon />
          </IconContainer>
        </AddNewContainer>
      </AddNewSectionWrapper>
    </Wrapper>
  );
}

export default PanelsList;
