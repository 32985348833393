import BaseRestService from "./BaseRestService";

class EmailService extends BaseRestService {
  async getUserEmail(accountId) {
    const url = `ac/confluence/api/email/single?accountId=${accountId}`;
    return this.client.get(url, await this.buildJWTAuthAxiosConfig());
  }
  async getUsersEmailBulk(accountIds) {
    const url = `ac/confluence/api/email/bulk`;
    return this.client.post(url, { accountIds }, await this.buildJWTAuthAxiosConfig());
  }
}

export const emailService = new EmailService();
