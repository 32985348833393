import dayjs from "dayjs";

export const handleConfluenceUserData = (records) => {
  if (!records.length) return [];

  const builtData = records.map((record) => ({
    accountId: record.accountId,
    name: record.displayName,
    icon: record.profilePicture,
  }));

  return builtData || [];
};

export const handleBirthdayField = (availableFields, userData, globalWidgetFieldId) => {
  const BIRTHDAY_FIELD_ID = globalWidgetFieldId || "uHqwkcx";
  if (
    availableFields.find((field) => field.id === BIRTHDAY_FIELD_ID) &&
    userData?.values[`recurring_${BIRTHDAY_FIELD_ID}`]
  ) {
    const today = dayjs().unix();
    const todayUnix = dayjs.unix(today).year(1970).hour(0).minute(0).second(0).unix();

    const ONE_DAY_UNIX = 86400;

    if (todayUnix === userData?.values[`recurring_${BIRTHDAY_FIELD_ID}`]) {
      return {
        hasBirthday: true,
        daysLeft: 0,
      };
    }

    if (todayUnix + ONE_DAY_UNIX === userData?.values[`recurring_${BIRTHDAY_FIELD_ID}`]) {
      return {
        hasBirthday: true,
        daysLeft: 1,
      };
    }
  }
  return { hasBirthday: false };
};

export const handleWorkAnniversary = (availableFields, userData, globalWidgetFieldId) => {
  const ENTRY_FIELD_ID = globalWidgetFieldId || "dc49cu8";
  if (availableFields.find((field) => field.id === ENTRY_FIELD_ID) && userData?.values[ENTRY_FIELD_ID]) {
    const dateValueCurrentYear = dayjs.unix(userData.values[ENTRY_FIELD_ID]).set("year", dayjs().get("year"));
    const daysFromTodayToDate = dateValueCurrentYear.diff(dayjs().hour(0).minute(0).second(0).millisecond(0), "day");

    if (daysFromTodayToDate >= 0 && daysFromTodayToDate <= 1) {
      return {
        hasAnniversary: true,
        daysLeft: daysFromTodayToDate,
      };
    }
  }
  return { hasAnniversary: false };
};

export const handleNewStarter = (availableFields, userData, globalWidgetFieldId) => {
  const ENTRY_FIELD_ID = globalWidgetFieldId || "dc49cu8";
  if (availableFields.find((field) => field.id === ENTRY_FIELD_ID) && userData?.values[ENTRY_FIELD_ID]) {
    const dateValue = userData.values[ENTRY_FIELD_ID];

    if (dayjs.unix(dateValue).get("year") === dayjs().get("year")) {
      const FOUR_WEEKS_IN_DAYS = 28;
      const daysFromTodayToDate = dayjs.unix(dateValue).diff(dayjs().hour(0).minute(0).second(0).millisecond(0), "day");

      if (daysFromTodayToDate >= -FOUR_WEEKS_IN_DAYS && daysFromTodayToDate <= FOUR_WEEKS_IN_DAYS) {
        return {
          isNewStarter: true,
          dateValue,
          daysLeft: daysFromTodayToDate,
        };
      }
    }
  }
  return {
    isNewStarter: false,
  };
};
export const handleBlogCreation = async (userAccountId) => {};
