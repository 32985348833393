export const light = {
  scrollbar: "#d4d4d4",
  background: "#ffffff",
  globalBackground: "#ffffff",
  manualOrgChart: {
    buttonBorderColor: "#4c9aff",
    buttonColor: "#fff",
    buttonTextColor: "#172b4d",
    buttonColorHover: "#f4f5f7",
    buttonColorActive: "#e2e4e7",
    iconsColor: "#0065ff",
    minusPlusColor: "#F4F5F7",
    name: "#344563",
    info: "#6b778c",
    boxShadow: "0 0 1px 0 rgb(9 30 66 / 31%), 0 1px 1px 0 rgb(9 30 66 / 25%)",
    actions: {
      button: {
        border: "#4c9aff",
        color: "#172b4d",
        background: {
          active: "#e2e4e7",
          hover: "#f4f5f7",
        },
      },
    },
  },
  global: {
    name: "light",
    primaryColor: "#0065ff",
    background: {
      surface: "#FFF",
      surfaceOverlay: "#FFF",
    },
    text: { title: "#172B4D", blue: "#0065ff", red: "#AE2E24" },
    divider: "rgba(166, 197, 226, 0.16)",
    secondary: "#5E6695",
  },
  shared: {
    subtitle: "#344563",
    label: {
      background: "#c6c6c6",
      text: "#fff",
      filter: "#626F86",
    },
    form: {
      textarea: {
        background: "#f4f5f7",
        divider: "#DFE1E6",
        actions: "#42526e",
      },
      input: {
        background: "#FFF",
        border: "#ebecf0",
        text: "#172B4D",
        hover: "#ebecf0",
      },
      colorpicker: {
        background: "#F4F5F7",
        border: "#FFF",
        colorTile: "#f4f5f7",
        colorBox: "#F4F5F7",
      },
      imageEditor: {
        cropperBackground: "#efeff1",
        cropperBorder: "#c2c7d0",
      },
      select: {
        background: "#F4F5F7",
        borderColor: "#8590A2",
        border: "#F4F5F7",
        text: "#9FADBC",
        hover: "#ebecf0",
        selected: { background: "#f4f5f7", secondaryBackground: "#DFE1E6", text: "#FFF", secondaryText: "#172b4d" },
        options: "#FFF",
        option: "#172b4d",
        optionContrast: "#172b4d",
        value: "#172b4d",
        userName: "#172b4d",
      },
      button: {
        background: { active: "#DEEBFF", inactive: "#F4F5F7", hover: "#ebecf0" },
        text: { active: "#2f7aff", inactive: "#42526E" },
      },
      calendar: {
        background: "#FFF",
        border: "#f0f0f0",
        disabled: "#f0f0f0",
        hover: "#f4f4f7",
        inputBackground: "none",
        inputDivider: "#343a43",
        inputWrapperBackground: "none",
        inputWrapperColor: "#8696A7",
        closeIconColor: "#8696A7",
      },
      datePicker: {
        hover: "transparent",
      },
    },
    profile: {
      title: "#172b4d",
      subtitle: "#7a869a",
      footer: "#7a869a",
      text: "#172b4d",
      divider: "#edf0f3",
      panel: { background: "#FFF", border: "transparent" },
      field: {
        title: "#344563",
        value: "#7a869a",
      },
      socialLinkText: "#42526e",
      sectionText: "#42526e",
      selectBackground: "#ebecf0",
      reorderablePlaceholderOverBackground: "#f4f5f7",
      reorderablePlaceholderBackground: "#fff",
      optionMouseOverColor: "inherit",
      optionMouseOutColor: "inherit",
      editText: "#42526E",
    },
    settings: {
      title: "#172b4d",
      description: "#8993a4",
      divider: "#f4f5f7",
      titleDivider: "rgba(9, 30, 66, 0.09)",
      item: { background: "#FFF", border: "transparent" },
      section: { title: "#172B4D", description: "#8993a4" },
      admins: {
        icon: { background: "#FFF", hover: "#f4f5f7" },
        user: { title: "#000", footer: "#6b778c" },
        table: { header: "#42526e" },
        addBorder: "#626f86",
      },
      database: {
        background: "#FAFBFC",
        hover: "#f5f9ff",
        border: "#dfe1e6",
        header: "#f4f5f7",
        text: "#42526e",
        addOptionText: "#0055CC",
        addOptionHover: "#f4f4f4",
        basicFade: "linear-gradient(to left, #fff, transparent)",
        hiddenFade: "linear-gradient(to left,#f4f4f4,transparent)",
        customFade: "linear-gradient(to left, #f5f9ff, transparent)",
        entraModal: {
          textColor: "#172b4d",
          buttonColor: "unset",
          gradient: "transparent",
        },
        records: {
          headerName: "#172b4d",
          hiddenRowBackground: "#f4f4f4",
          headerDropdown: { active: "#1D7AFC", inactive: "#44546F" },
          watchIconColor: "#091E42",
          moreIconColor: "#44546F",
          mainLabelColor: "#172B4D",
          sideLabelColor: "rgba(9, 30, 66, 0.31)",
        },
        menuBorder: "none",
        menuBarIcons: "rgb(66, 82, 110)",
        menuBarColor: "rgb(66, 82, 110)",
        filtersBackgroundColor: "#eee",
        filtersTextColor: "#22272B",
        filtersUserTextColor: "#172b4d",
      },
      configuration: {
        entries: { text: "#9FADBC", background: { default: "#fafbfc", hover: "#deebff" } },
      },
      general: {
        panelBackground: "#fff",
        panelHeaderColor: "black",
        panelBorderBottom: "rgba(9, 30, 66, 0.14)",
        admin: {
          labelColor: "#626f86",
          emptyMessageColor: "#44546f",
          emptyStateColor: "#172B4D",
        },
        features: {
          iconBackground: "rgba(9, 30, 66, 0.06)",
          featureNameColor: "black",
          featureDescriptionColor: "#626f86",
          featureMenuBackground: "white",
        },
        brandAndColors: {
          sectionTitleColor: "#44546f",
          editLogoBackground: "rgba(9, 30, 66, 0.06)",
          editLogoColor: "#172B4D",
          logoNameColor: "#626f86",
          logoDescriptionColor: "#626f86",
          pageHeaderTextColor: "#626f86",
          underConstructionTitle: "#44546f",
          underConstructionDescription: "#44546f",
          underConstructionText: "#44546f",
        },
      },
    },
    sidebar: {
      icons: {
        bg: "#E3E5E9",
        color: "#44536F",
        divider: "#F5F8FB",
      },
      background: "#FFF",
      title: "#5e6695",
      divider: "#f4f5f7",
      label: "#6b778c",
      colorBackground: "rgb(244, 245, 247)",
      customColorInput: "rgb(244, 245, 247)",
      iconColor: "#44546F",
      cancelBackground: "rgb(241, 242, 244)",
      iconCloseColor: "#5e6695",
      positioning: {
        title: "#42526e",
        description: "#6b778c",
        icon: "#97A0AF",
      },
      item: { background: "#FFF", title: "#42526e", hover: "#f5f8fb" },
      list: {
        background: "#FFF",
        title: "#42526e",
        description: "#6b778c",
        hover: "#f5f8fb",
      },
      tabs: {
        background: { active: "#DEEBFF", inactive: "rgba(9, 30, 66, 0.06)" },
        text: { active: "#0065ff", inactive: "#44546f", hover: "#3b4961" },
        border: { active: "#0065ff", inactive: "#DFE1E6" },
      },
      profileTabs: {
        background: { active: "#DEEBFF", inactive: "#EDEDED" },
        text: { active: "#0052CC", inactive: "#42526E" },
        border: { active: "transparent", inactive: "transparent" },
        hover: {
          background: "#deebff",
          color: "#0052cc",
        },
      },
      button: {
        background: "#091e420f",
        text: "#172b4d",
        hover: "#E6E8EB",
        confirmBackground: "#0065FF",
        confirmText: "#FFFFFF",
        headerButtonHover: "#EEE",
        headerButtonActive: "#DDD",
        headerText: "white",
      },
      preview: {
        background: "#FFF",
        title: "#5e6695",
        subtitle: "",
        description: "#7a869a",
        divider: "#f4f5f7",
        border: "transparent",
        tabs: {
          active: { background: "#DEEBFF", text: "#0065FF" },
          inactive: { background: "#F4F5F7", text: "#42526E" },
        },
      },
      hub: {
        background: "#FFF",
        icon: {
          inactive: "#5E6695",
          active: "#2F7AFF",
        },
      },
      colors: {
        label: "#6b778c",
        input: "#6b778c",
        inputLabel: "#6b778c",
      },
    },
    panel: {
      background: "#FFF",
      title: "#344563",
      hover: "#eee",
      active: "#ddd",
      space: {
        headerTitle: "#9FADBC",
      },
      divider: "#f4f5f7",
    },
    card: {
      title: "#172b4d",
      titleSecondary: "#C7D1DB",
      subtitle: "#7a869a",
      footer: "#7a869a",
      text: "#172b4d",
      background: "#FFF",
      divider: "#f4f5f7",
      creatorLink: "#0065FF",
      border: {
        avatar: "#FFF",
        box: "#d8dce3",
      },
    },
    news: {
      primaryButtonColor: "#ffffff",
      title: "#172b4d",
      subtitle: "#7a869a",
      footer: "#7a869a",
      text: "#172b4d",
      background: "#FFF",
      border: "#dfe1e6",
      shadows: {
        default: "rgb(179 186 197) 0px 0px 1px 0px, rgb(193 199 208) 0px 1px 1px 0px;",
        hover: "rgb(179,186,197) 0px 0px 1px 0px, rgb(193,199,208) 0px 8px 16px -6px;",
      },
      labelsDefault: "#dfe1e6",
    },
    emptyState: {
      text: "#172b4d",
      buttonBackground: "#0c66e4",
      buttonText: "#fff",
    },
  },
  poll: {
    description: "#172b4d",
    option: "#42526e",
    icons: "#42526E",
    iconsSelected: "#42526E",
    removeVote: {
      background: "#fff",
      color: "#fff",
    },
  },
  links: {
    background: "#FFF",
    title: "#42526e",
  },
  assignedToMe: {
    task: "#42526e",
  },
  notification: {
    title: "#091e42",
    description: "#344563",
    secondaryTitle: "#344563",
    secondaryTitleInfo: "#344563",
  },
  newUsers: {
    positionText: "#7a869a",
    dateTodayText: "#579DFF",
    dateText: "#9FADBC",
  },
  emptyState: {
    title: "#172b4d",
    text: "#172b4d",
    secondaryText: "#42526e",
    description: "#44526d",
    database: {
      buttonBackground: "#0c66e4",
      buttonColor: "#fff",
      title: "#172b4d",
      description: "#172b4d",
    },
  },
  videos: {
    title: "#172b4d",
  },
  confirmDeleteModal: {
    cancelBackground: "transparent",
    cancelText: "#CA3521",
    cancelTextSecundary: "#172B4D",
    primaryBackground: "#0C66E4",
    primaryColor: "#FFFFFF",
    modalHeaderText: "#172B4D",
    modalBodyText: "#44546F",
  },
  apps: {
    seperator: "#dfe1e6",
  },
  sidebar: {
    background: "#fff",
    seperator: "#f2f3f7",
    searchBackground: "#f4f5f7",
    inputBackground: "rgb(244, 245, 247)",
    mainTab: "#0065FF",
    inactiveTab: "#07145a",
    atttachmentsText: "#07145a",
    backgroundTab: "#f4f5f7",
    colorTab: "#172B4D",
    rangeBorder: "#e5e7ef",
    filterBackground: "white",
    filterSelectBackground: "none",
    filterLabelColor: "#6b778c",
  },
  collections: {
    searchBackground: "#F7F8F9",
    searchBorder: "#dfe1e6",
    collectionCardTitle: "#344563",
    collectionCardBackground: "#FFF",
    collectionCardBorder: "#E8E8E8",
    collectionCardBorderHover: "#dadada",
    createCollectionBackground: "#fff",
    createCollectionTitle: "#07145a",
    createCollectionSelectBackground: "#f4f5f7",
    createCollectionSelectColor: "#344563",
    iconColor: "#42526e",
    createCollectionSelectBorder: "#f4f5f7",
    createCollectionDescription: "#F4F5F7",
    createCollectionToggleLabelColor: "#344563",
    createCollectionCancel: "#42526e",
    createCollectionCancelHover: "#f3f3f3",
    createCollectionCancelDisabledText: "#172b4d",
    contentWrapperBorderTop: "#e9ebf1",
  },
  icons: {
    tabs: "#07145a",
    providerPickerBackground: "#F5F6F8",
    providerBorder: "#f2f3f7",
    providerPickerColor: "#42526E",
    seperator: "#f1f1f6",
  },
  space: {
    selectableLabel: "#0065FF",
    fieldLabelColor: "#5E6695",
    listView: {
      headerTitleColor: "#5e6c84",
      cardWrapperBorderBottomColor: "#DFE1E6",
      cardWrapperTitle: "#344563",
      cardAvatarShadow: "#ffffff",
      resultsColor: "#344563",
    },
    updateSpaceModal: {
      modalBackground: "#fff",
      modalHeader: "#333333",
      modalLabelColor: "#6b778c",
    },
  },
  navigation: {
    previewBackgrond: "#FFF",
    emojiBackground: "255, 255, 255",
    london: {
      publishedMenuBackground: "#fff",
      publishedMenuBorder: "rgb(240, 241, 247)",
      menuContentBackground: "#fff",
      menuContentTitle: "#42526e",
      menuContentSeperator: "#dfe1e6",
      menuContentEntryHoverBackground: "#fff",
    },
    sydney: {
      menuContentBackground: "#fff",
      rootShortDescription: "#8993a4",
      childEntryBackground: "transparent",
      iconBackground: "#fff",
      childEntryBackgroundHover: "#fff",
      childEntryBackgroundHoverBorder: "#ebecf0",
    },
    denver: {
      menuContentBackground: "#fff",
      childEntryBorder: "#fff",
      childEntryBackgroundHover: "#fff",
      childEntryBackgroundHoverBorder: "#ebecf0",
    },
    paloAlto: {
      menuContentBackground: "#fff",
      childEntryBorder: "#f4f5f7",
      childEntryBackgroundHover: "#fff",
      childEntryBackgroundHoverBorder: "#ebecf0",
    },
    houston: {
      menuContentBackground: "#fff",
      childEntryBorder: "#f4f5f7",
    },
    settings: {
      menuEntryBackgroundColor: "#fafbfc",
      subMenuEntryBackgroundColor: "#fff",
      menuEntryColor: "#42526e",
      menuEntryHoverBackgroundColor: "#f1f1f6",
      menuEntryDivider: "#f1f1f6",
      entryIcon: "#344563",
      addMainEntryButtonBackground: "#deebff",
      addMainEntryButtonBackgroundHover: "#cfe0fa",
      addMainEntryButtonColor: "#0065ff",
      entryIconColor: "#42526e",
      entryIconOpacity: "1",
      dropTargetBackground: "#e8f0fe",
      entryAndSubEntryInputBackground: "#fafbfc",
      entryAndSubEntryInputBorder: "#dfe1e6",
      entryAndSubEntryDrawerTitle: "#172b4d",
      iconBoxBackground: "#fafbfc",
      iconBoxBorder: "#dfe1e6",
      addLinkBackground: "white",
      customInputBackground: "#fff",
      linkEntryWrapperBorderBottom: "#f4f5f7",
      linkEntryWrapperBorderTop: "#fff",
      linkValue: "#07145a",
      typeBackground: "#f4f5f7",
      typeColor: "#42526e",
      listItemBackgroundHover: "#deebff",
      premissionAsyncSelectBackground: "rgb(244, 245, 247)",
      premissionViewPremissionselectBackground: "rgb(244, 245, 247)",
      sidebarSectionDescriptionColor: "#dfe1e6",
      sidebarMenuSelectButton: "#42526e",
      sidebarMenuSelectButtonHover: "#fafbfc",
      savedBarUnsavedChangesText: "rgb(52, 69, 99)",
      navigationEmptyStateTitleColor: "#07145a",
      select: {
        background: "#fff",
        border: "#F4F5F7",
        borderFocused: "#ebecf0",
        color: "#07145a",
        text: "#9FADBC",
        hover: "#ebecf0",
        selected: { background: "#42516", text: "#FFF" },
        options: "#FFF",
        option: "#172b4d",
        value: "#172b4d",
        linkButton: "#69A6FC",
        linkWrapperHover: "#FAFBFC",
        emailTitle: "#172b4d",
        emailDescription: "#172b4d",
      },
      linkSidebar: {
        tabColor: "#07145a",
        searchPagesBackground: "#fff",
        searchPagesBorder: "#fff",
        listItemBorder: "#F2F3F7",
        headerLabel: "#07145a",
        listTitle: "#0052cc",
        listSpace: "#07145a",
        seperator: "rgba(0, 0, 0, 0.1)",
      },
      navigationPreview: {
        pseudoTopBarBackground: "#fff",
        appSwitcherIcon: "#344563",
        pseudoTopBarEntry: "#dfe1e6",
      },
    },
  },
  spaceport: {
    title: "#41526e",
    linkLabel: "#4b5b73",
    divider: "#f4f5f7",
    appContainerBorder: "#f1f2f5",
    appTitle: "#4b5b73",
    newsContainerBorder: "#f1f2f5",
    newsTitle: "#42526e",
    reviewBorder: "rgba(193 199 208 / 50%)",
    reviewTitle: "#42526e",
  },
  orgCharts: {
    tempTitle: "#42526e",
  },
  newsMetadataDialog: {
    background: "#ffffff",
    title: "rgba(23, 43, 77, 1)",
    tab: "rgba(68, 84, 111, 1)",
    tabActive: "rgba(12, 102, 228, 1)",
    coverImage: "rgba(23, 43, 77, 1)",
    imageDescription: "rgba(98, 111, 134, 1)",
    coverButtonText: "rgba(255, 255, 255, 1)",
    covertButton: "rgba(12, 102, 228, 1)",
    categoryText: "rgba(98, 111, 134, 1)",
    categoryField: "rgba(117, 129, 149, 1)",
  },
  newsMetadataSettings: {
    title: "rgba(23, 43, 77, 1)",
    description: "rgba(98, 111, 134, 1)",
    categoryCard: "rgba(255, 255, 255, 1)",
    categoryCardBorder: "rgba(166, 197, 226, 0.16)",
    categoryCardHover: "rgba(241, 242, 244, 1)",
    categoryIcon: "rgba(233, 242, 255, 1)",
    blogIcon: "rgba(12, 102, 228, 1)",
  },
  installationBanners: {
    optics: {
      background: "radial-gradient(123.55% 150.36% at 50% 100%, #fff 0%, #e9f2ff 100%)",
      text: "#2e2e2e",
      highlightedText: "#0c66E4",
      buttonBackground: "#0c66e4",
      buttonText: "white",
    },
  },
};
