import React, { useEffect, useState } from "react";
import Spinner from "@atlaskit/spinner";
import { findSpacesUserIsWatching, searchPersonalizedBlogposts } from "./api";
import { getSubscribedToCategories } from "../../../../Settings/News/api";
import { useInfiniteQuery, useQueryClient } from "react-query";
import { GridContainer, ListContainer, NewsTypes } from "./News";
import { mapBlogpost } from "./handleBlogData";
import { PanelPositioning } from "../../panelTypes";
import { usePageStore } from "../../../../../page-store";
import { useSidebarStore } from "../../edit/sidebar/sidebar-store";
import PanelWrapper from "../../../../Shared/Panels/shared/components/PanelWrapper";
import { PanelsPaginationTypes } from "../../../../Shared/PanelsPagination/panels-pagination-types";
import { OverlineWrapper, PersonDetails, PersonIcon, SideNewsListContainer, SpaceLabel, Title } from "./styled";
import styled, { useTheme } from "styled-components";
import { CoverStory, InlineNews } from "@caelor/cards-and-panels-components";
import { htmlDecode } from "../../../../../../ContentBuilder/utils/decode-html-string";
import Pagination from "../../../../Shared/PanelsPagination/Pagination";
import { Pages } from "../../../../../pages";
import { PersonalizedNewsEmptyState } from "./EmptyStates";
import { useCorporateIdentityStore } from "../../../../Settings/General/BrandAndColors/corporate-identity-store";

const MagazineGridContainer = styled.div`
  display: grid;
  grid-template-columns: ${({ isLargeStory }) => (isLargeStory ? "repeat(4, 1fr)" : "repeat(2, 1fr)")};
  grid-template-rows: ${({ isLargeStory }) => (isLargeStory ? "repeat(4, 1fr)" : "1fr")};
  grid-column-gap: ${({ padding }) => `${padding || 24}px`};
  grid-row-gap: ${({ padding }) => `${padding || 24}px`};
  height: ${({ isLargeStory }) => (isLargeStory ? "500px" : "240px")};
  .child {
    > div,
    > a > div {
      height: calc(100% - 35px) !important;
    }

    .news-title {
      transition: all 250ms ease-in-out !important;
    }

    &:hover {
      .news-excerpt {
        height: 60px;
      }

      .news-title {
        font-size: 20px;
      }

      .news-metadata,
      .news-title {
        transform: translateY(-60px);
      }
    }
  }
  .grid-large-story-child-1 {
    grid-area: ${({ shouldAlternatePattern }) => (shouldAlternatePattern ? "1 / 1 / 3 / 3" : "1 / 1 / 5 / 3")};
  }
  .grid-large-story-child-2 {
    grid-area: ${({ shouldAlternatePattern }) => (shouldAlternatePattern ? "3 / 1 / 5 / 3" : "1 / 3 / 3 / 5")};
  }
  .grid-large-story-child-3 {
    grid-area: ${({ shouldAlternatePattern }) => (shouldAlternatePattern ? "1 / 3 / 5 / 5" : "3 / 3 / 5 / 5")};
  }

  .grid-child-3 {
    display: none;
  }

  .news-excerpt {
    height: 0px;
    bottom: 40px;
    position: absolute;
    transition: height 250ms ease-in-out;
  }

  .news-title,
  .news-metadata {
    transition: transform 250ms ease-in-out !important;
  }
`;

function PersonalizedNews({ id, panelTitle, data, position }) {
  const [page, setPage] = useState(1);
  const [showMoreClicked, setShowMoreClicked] = useState(false);

  const [loadingSpaces, setLoadingSpaces] = useState(false);
  const [usersSpaces, setUsersSpaces] = useState([]);

  const [loadingCategories, setLoadingCategories] = useState(false);
  const [usersCategories, setUsersCategories] = useState([]);

  const [allCategories, setAllCategories] = useState([]);

  const queryClient = useQueryClient();

  const setSelectedPage = usePageStore((state) => state.setSelectedPage);
  const setDisableClickOutside = useSidebarStore((state) => state.setDisableClickOutside);
  const borderRadius = useCorporateIdentityStore((state) => state.borderRadius);

  const theme = useTheme();

  const {
    view,
    pagination,
    maxNumberOfItems,
    activeElements,
    watchingSpaces,
    subscribedCategories,
    sortBy,
    ordering,
    displayTitle,
    displayBox,
    labelsBackground,
    teamLabelsBackground,
    coverPictureSize,
    padding,
  } = data;

  const isSideNews = position === PanelPositioning.SIDE;

  const {
    isLoading: loadingBlogsData,
    fetchNextPage,
    hasNextPage,
    data: blogsData,
  } = useInfiniteQuery(
    [
      `blogposts-personalized-${id}`,
      {
        subscribedCategories: usersCategories,
        watchingSpaces: usersSpaces,
        limit: isSideNews ? maxNumberOfItems : view === NewsTypes.MAGAZINE ? 3 : maxNumberOfItems || 4,
        sorting: sortBy,
        ordering: ordering,
      },
    ],
    searchPersonalizedBlogposts,
    {
      enabled: !loadingSpaces && !loadingCategories,
      retry: 0,
      select: (response) => {
        const pages = [];
        response.pages.forEach((page) => {
          const body = JSON.parse(page.body);
          const resultsInPage = body.results.map((blog) => mapBlogpost(blog, undefined, allCategories));
          pages.push({ ...body, results: resultsInPage });
        });
        return { pages, pageParams: response.pageParams };
      },
      getNextPageParam: (lastPage) => {
        const body = JSON.parse(lastPage.body);
        if (body._links.next) {
          const urlParams = new URLSearchParams(encodeURI(body._links.next));
          const cursor = urlParams.get("cursor");
          return cursor;
        }
        return false;
      },
    },
  );

  useEffect(() => {
    setPage(1);
    setShowMoreClicked(false);
    queryClient.resetQueries([`blogposts-personalized-${id}`]);
  }, [view, pagination, maxNumberOfItems, sortBy, ordering]);

  useEffect(() => {
    if (!subscribedCategories) {
      setUsersCategories([]);
      return;
    }

    (async () => {
      setLoadingCategories(true);
      const usersCategories = await getSubscribedToCategories({ includeAllCategories: true });
      if (usersCategories?.data) {
        setUsersCategories(usersCategories.data?.subscribedTo || []);
        setAllCategories(usersCategories.data?.categories || []);
      } else {
        setUsersCategories([]);
        setAllCategories([]);
      }
      setLoadingCategories(false);
    })();
  }, [subscribedCategories]);

  useEffect(() => {
    if (!watchingSpaces) {
      setUsersSpaces([]);
      return;
    }

    (async () => {
      setLoadingSpaces(true);
      const foundSpaces = await findSpacesUserIsWatching();
      if (foundSpaces?.length) {
        setUsersSpaces(foundSpaces.map(({ space }) => space.key));
      } else {
        setUsersSpaces([]);
      }
      setLoadingSpaces(false);
    })();
  }, [watchingSpaces]);

  const numberOfItems = {
    [NewsTypes.GRID]: maxNumberOfItems,
    [NewsTypes.LIST]: maxNumberOfItems,
    [NewsTypes.MAGAZINE]: 3,
    [NewsTypes.SIDE_NEWS]: maxNumberOfItems,
  };
  const paginatedBlogsData =
    pagination === PanelsPaginationTypes.ARROWS ? [blogsData?.pages[page - 1]] : blogsData?.pages;
  const numOfTotalPages = Math.ceil(blogsData?.pages[0]?.totalSize / numberOfItems[view]);

  const isLoading = loadingBlogsData || loadingCategories || loadingSpaces;

  const isEmpty = !blogsData?.pages?.length || !blogsData?.pages[0]?.results?.length;

  return (
    <PanelWrapper
      panelTitle={panelTitle}
      displayOptions={{
        displayTitle: displayTitle,
        displayBox: displayBox,
      }}
    >
      {isLoading && (
        <div style={{ display: "grid", placeContent: "center" }}>
          <Spinner size="xlarge" />
        </div>
      )}

      {isEmpty && !isLoading && <PersonalizedNewsEmptyState />}

      <>
        {isSideNews &&
          !isLoading &&
          !isEmpty &&
          paginatedBlogsData?.map((page, index) => (
            <SideNewsListContainer key={`page-${index}`} style={{ marginTop: index === 0 ? "0px" : "20px" }}>
              {page?.results?.map((blog) => (
                <div
                  key={blog.id}
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    window.open(blog.url, "_blank");
                  }}
                >
                  <OverlineWrapper isTwoColumns={activeElements?.includes("author")}>
                    {activeElements?.includes("author") && <PersonIcon src={blog.avatarUrl} />}
                    <PersonDetails>
                      {activeElements?.includes("author") && `${blog.createdBy}`}
                      {activeElements?.includes("author") && activeElements?.includes("date") && " · "}
                      {activeElements?.includes("date") && `${blog.createdFormatted}`}
                    </PersonDetails>
                  </OverlineWrapper>
                  <Title color={theme.shared.card.title}>{blog.name}</Title>
                  {activeElements?.includes("space") && (
                    <SpaceLabel backgroundColor={labelsBackground}>{blog.space.name}</SpaceLabel>
                  )}
                </div>
              ))}
            </SideNewsListContainer>
          ))}

        {view === NewsTypes.MAGAZINE &&
          !isLoading &&
          !isEmpty &&
          !isSideNews &&
          paginatedBlogsData?.map((page, index) => (
            <div key={`page-${index}`} style={{ marginTop: index !== 0 ? "24px" : "0px" }}>
              <MagazineGridContainer isLargeStory={true} shouldAlternatePattern={index % 2 !== 0} padding={padding}>
                {page?.results?.map((blog, idx) => (
                  <div key={blog.id} className={`grid-large-story-child-${idx + 1} child`}>
                    <CoverStory
                      key={blog.id}
                      coverPictureCallback={blog.coverPictureCallback}
                      getBlogsCategories={activeElements?.includes("categories") ? blog.getBlogsCategories : undefined}
                      name={blog.name}
                      space={activeElements?.includes("space") ? blog.space : {}}
                      url={blog.url}
                      excerpt={activeElements?.includes("description") ? htmlDecode(blog.excerpt) : null}
                      labels={activeElements?.includes("label") ? blog.labels : null}
                      createdBy={activeElements?.includes("author") ? blog.createdBy : null}
                      lastModified={activeElements?.includes("date") ? blog.createdFormatted : null}
                      numberOfComments={activeElements?.includes("comments") ? blog.numberOfComments : null}
                      numberOfLikes={activeElements?.includes("likes") ? blog.numberOfLikes : null}
                      avatarUrl={blog.avatarUrl}
                      labelsBackground={labelsBackground}
                      coverPictureBorderRadius={borderRadius.replace("px", "")}
                      labelsBorderRadius={borderRadius}
                      darkTheme={{
                        shadowColors: {
                          default: theme.shared.news.shadows.default,
                          hover: theme.shared.news.shadows.hover,
                        },
                      }}
                    />
                  </div>
                ))}
              </MagazineGridContainer>
            </div>
          ))}

        {view === NewsTypes.GRID && !isLoading && !isEmpty && !isSideNews && (
          <GridContainer padding={padding}>
            {paginatedBlogsData?.map((page, index) => (
              <>
                {page?.results?.map((blog, idx) => (
                  <CoverStory
                    key={blog.id}
                    coverPictureCallback={blog.coverPictureCallback}
                    getBlogsCategories={activeElements?.includes("categories") ? blog.getBlogsCategories : undefined}
                    name={blog.name}
                    space={activeElements?.includes("space") ? blog.space : {}}
                    url={blog.url}
                    excerpt={activeElements?.includes("description") ? htmlDecode(blog.excerpt) : null}
                    labels={activeElements?.includes("label") ? blog.labels : null}
                    createdBy={activeElements?.includes("author") ? blog.createdBy : null}
                    lastModified={activeElements?.includes("date") ? blog.createdFormatted : null}
                    numberOfComments={activeElements?.includes("comments") ? blog.numberOfComments : null}
                    numberOfLikes={activeElements?.includes("likes") ? blog.numberOfLikes : null}
                    avatarUrl={blog.avatarUrl}
                    labelsBackground={labelsBackground}
                    coverPictureSize={coverPictureSize}
                    coverPictureBorderRadius={borderRadius.replace("px", "")}
                    labelsBorderRadius={borderRadius}
                    darkTheme={{
                      shadowColors: {
                        default: theme.shared.news.shadows.default,
                        hover: theme.shared.news.shadows.hover,
                      },
                    }}
                  />
                ))}
              </>
            ))}
          </GridContainer>
        )}

        {view === NewsTypes.LIST &&
          !isLoading &&
          !isEmpty &&
          !isSideNews &&
          paginatedBlogsData?.map((page, index) => (
            <ListContainer key={`page-${index}`} style={{ marginTop: index !== 0 ? "24px" : "0px" }}>
              {page?.results?.map((blog, idx) => (
                <>
                  <InlineNews
                    key={blog.id}
                    coverPictureCallback={blog.coverPictureCallback}
                    getBlogsCategories={activeElements?.includes("categories") ? blog.getBlogsCategories : undefined}
                    name={blog.name}
                    space={activeElements?.includes("space") ? blog.space : {}}
                    url={blog.url}
                    excerpt={activeElements?.includes("description") ? htmlDecode(blog.excerpt) : null}
                    labels={activeElements?.includes("label") ? blog.labels : null}
                    createdBy={activeElements?.includes("author") ? blog.createdBy : null}
                    numberOfComments={activeElements?.includes("comments") ? blog.numberOfComments : null}
                    numberOfLikes={activeElements?.includes("likes") ? blog.numberOfLikes : null}
                    lastModified={activeElements?.includes("date") ? blog.createdFormatted : null}
                    avatarUrl={blog.avatarUrl}
                    labelsBackground={teamLabelsBackground}
                    coverPictureBorderRadius={borderRadius.replace("px", "")}
                    imagePosition="left"
                    maxWidthOfImage={30}
                    labelsBorderRadius={borderRadius}
                    darkTheme={{
                      backgroundColor: theme.shared.news.background,
                      titleColor: theme.shared.news.title,
                      descriptionColor: theme.shared.news.text,
                      authorColor: theme.shared.news.text,
                      borderColor: theme.shared.sidebar.divider,
                      textColor: theme.shared.news.text,
                      borderBottom: theme.shared.sidebar.divider,
                    }}
                  />
                </>
              ))}
            </ListContainer>
          ))}
      </>

      {!isEmpty && (
        <Pagination
          showMoreClicked={showMoreClicked}
          hasNextPage={page < numOfTotalPages}
          // INFINITE pagination type has Show more and view more, which directs the user to Newsroom
          // this is now disabled so there is infinite loading with Show more (Continuous show more button)
          // type={pagination === PanelsPaginationTypes.SHOW_MORE ? PanelsPaginationTypes.INFINITE : pagination}
          type={pagination}
          page={page}
          updatePage={(v) => {
            setPage(v);
            if (hasNextPage) fetchNextPage();
          }}
          maxPage={numOfTotalPages}
          onWrapperHoverCallback={setDisableClickOutside}
          infiniteQuery={{ shouldFetchNext: hasNextPage, fetchNextPage }}
          // onClick={() => {
          //   if (hasNextPage && !showMoreClicked) {
          //     fetchNextPage();
          //     setPage(2);
          //     setShowMoreClicked(true);
          //     return;
          //   }

          //   setSelectedPage(Pages.NEWS);
          // }}
        />
      )}
    </PanelWrapper>
  );
}

export default PersonalizedNews;
