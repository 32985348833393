import React from "react";
import { AvailableStyles } from "../defaultSettings";
import AustinCard from "./AustinCard";
import CalgaryCard from "./CalgaryCard";

function Preview({ selectedStyle, borderRadius, settingsData }) {
  return (
    <>
      {selectedStyle === AvailableStyles.AUSTIN && (
        <AustinCard isPreview borderRadius={borderRadius} settingsData={settingsData} />
      )}
      {selectedStyle === AvailableStyles.CALGARY && (
        <CalgaryCard isPreview borderRadius={borderRadius} settingsData={settingsData} />
      )}
    </>
  );
}

export default Preview;
