import styled from "styled-components";
import { ICON_BG_SHAPE_TYPES, ICON_BG_TYPES } from "../Components/Settings/General/BrandAndColors/color-types";

export const HubMainWrapper = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

export const HubSecondaryWrapper = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;

  display: flex;
  flex: 1;

  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
`;

export const HubSidebar = styled.nav`
  position: sticky;
  top: 0px;
  height: inherit;
  box-sizing: border-box;
  padding: 24px 12px;
  background: ${({ theme }) => theme.shared.sidebar.hub.background};
  /* background: ${({ sidebarColor }) => sidebarColor}; */
  box-shadow: 2px 0px 4px rgba(0 0 0 / 8%);

  position: relative;
  z-index: 100;

  display: flex;
  flex-direction: column;
  align-items: center;

  @media print {
    display: none !important;
  }
`;

export const LogoImageLink = styled.a`
  all: unset;
`;

export const LogoImage = styled.img`
  box-sizing: border-box;
  width: 48px;
  height: 48px;
  cursor: pointer;
`;

export const Icons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  row-gap: 24px;
  margin-top: 24px;
  padding-bottom: 16px;

  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
`;

export const FooterIcons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  flex: 1;
`;

export const Divider = styled.div`
  margin-top: 23px;
  margin-bottom: 22px;
  width: 100%;
  border-bottom: 1px solid #d5d5d5;
`;

export const UserProfileImage = styled.img`
  box-sizing: border-box;
  width: 37px;
  height: 37px;
  border-radius: 50%;
  cursor: pointer;

  transition: all 150ms linear;
  box-shadow: 0px 1px 3px rgba(0 0 0 / 0%);

  &:hover {
    opacity: 0.9;
    box-shadow: 0px 1px 3px rgba(0 0 0 / 20%);
  }
`;

export const IconLink = styled.a`
  all: unset;
`;

export const IconContainer = styled.div`
  display: grid;
  place-content: center;
  width: 40px;
  height: 40px;
  opacity: 1;
  cursor: pointer;

  ${({ iconShapeType }) => {
    if (iconShapeType === ICON_BG_SHAPE_TYPES.CIRCLE) {
      return "border-radius: 50%";
    }
    if (iconShapeType === ICON_BG_SHAPE_TYPES.SQUARE) {
      return "border-radius: 3px";
    }
    if (iconShapeType === ICON_BG_SHAPE_TYPES.NONE) {
      return "background: transparent !important";
    }
  }};
`;

export const SidebarIconContainer = styled(IconContainer)`
  ${({ isActive, activeColor, inactiveColor, iconType, iconShapeType }) => {
    if (iconType === ICON_BG_TYPES.NONE) return "";
    if (iconType === ICON_BG_TYPES.TRANSPARENT) {
      return `
        background: #ffffff26;

        ${
          !isActive &&
          `
          &:hover {
            background: #ffffff33; 
          }
        `
        }
      `;
    }
    if (iconType === ICON_BG_TYPES.COLOR) {
      return `
        background: ${isActive ? `${activeColor.slice(0, 7)}15` : `${inactiveColor}`};
        
        ${
          !isActive &&
          `
          &:hover {
            background: ${inactiveColor.slice(0, 7)}1a; 
          }
        `
        }
      `;
    }
  }};
`;

export const FooterIconContainer = styled(IconContainer)`
  ${({ isActive, activeColor, iconType }) => {
    if (isActive) return `background-color: #2F7AFF15`;
  }};

  &:hover {
    ${({ isActive, activeColor, inactiveColor, iconType }) => {
      if (iconType === ICON_BG_TYPES.NONE) return "";
      if (iconType === ICON_BG_TYPES.TRANSPARENT)
        return `
        background-color: #ffffff26;
      `;
      return `background-color: ${inactiveColor.slice(0, 7)}1a`;
    }}
  }
`;

export const PageWrapper = styled.div`
  width: 100%;
  overflow-y: auto;
  background-color: ${({ theme }) => theme.globalBackground};

  @media print {
    overflow: hidden;
  }
`;
