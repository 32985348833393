import React, { useEffect, useMemo, useState } from "react";

import { SkeletonItem } from "@atlaskit/side-navigation";
import Tooltip from "@atlaskit/tooltip";
import styled, { useTheme } from "styled-components";
import shallow from "zustand/shallow";
import dayjs from "dayjs";

import { OptionColors } from "./editor/SelectEditor";
import { atlassianRestService } from "../../../Service/AtlassianRestService";
import { FieldType } from "../field-type";
import { useRecordsStore } from "../records-store";

export const fontColorAccordingToHex = (hexColor) => {
  return;
};

const TagWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  gap: 6px;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;

  ::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;

  ${({ showFade = true, afterBackground }) =>
    showFade &&
    `
      &:after {
        content: "";
        position: absolute;
        background: ${afterBackground};
        width: 20px;
        right: -8px;
        top: -8px;
        bottom: -8px;
        z-index: -5;
      }
  `}

  &:hover:after {
    background: ${({ theme }) => theme.shared.settings.database.customFade};
  }
`;

const Tag = styled.div`
  cursor: pointer;
  border-radius: 3px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  padding: 2px 5px;
  color: #42526e;
  background: ${({ color }) => (color ? color : "#f4f5f7")};
  z-index: -10;
`;

const EmailLink = styled.a`
  color: #0065ff;
  text-decoration: none;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;

  &:active,
  &:visited {
    color: #0065ff;
  }

  &:hover {
    text-decoration: underline;
  }
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
`;

const NameLabel = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
`;

const WatchIcon = ({ primaryColor }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0003 14.9999C6.22033 14.9999 3.33449 11.4499 3.33449 9.99992C3.33449 8.33242 6.21699 4.99992 10.0012 4.99992C13.6478 4.99992 16.6662 8.31075 16.6662 9.99992C16.6662 11.4499 13.7812 14.9999 10.0012 14.9999H10.0003ZM10.0012 3.33325C5.40033 3.33325 1.66699 7.36742 1.66699 9.99992C1.66699 12.5716 5.48033 16.6666 10.0003 16.6666C14.5195 16.6666 18.3337 12.5716 18.3337 9.99992C18.3337 7.36742 14.6003 3.33325 10.0003 3.33325"
        fill={primaryColor}
        fillOpacity="0.31"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.98116 11.6533C9.06199 11.6533 8.31449 10.9058 8.31449 9.98659C8.31449 9.06742 9.06199 8.31992 9.98116 8.31992C10.9012 8.31992 11.6478 9.06742 11.6478 9.98659C11.6478 10.9058 10.9012 11.6533 9.98116 11.6533ZM9.98116 6.65325C8.14283 6.65325 6.64783 8.14825 6.64783 9.98659C6.64783 11.8249 8.14283 13.3199 9.98116 13.3199C11.8203 13.3199 13.3145 11.8249 13.3145 9.98659C13.3145 8.14825 11.8203 6.65325 9.98116 6.65325Z"
        fill={primaryColor}
        fillOpacity="0.31"
      />
    </svg>
  );
};

const SelectRenderer = ({ value, isMulti, options, recordIsHidden }) => {
  const { records } = useRecordsStore((state) => {
    return {
      records: state.records,
    };
  }, shallow);
  const [selected, setSelected] = useState([]);
  const theme = useTheme();

  const afterBackground = recordIsHidden
    ? theme.shared.settings.database.hiddenFade
    : theme.shared.settings.database.basicFade;

  useEffect(() => {
    if (value) {
      let selected;

      if (isMulti) {
        selected = value.map((v) => options.find((o) => o.id === v));
      } else {
        selected = [options.find((o) => o.id === value)];
      }
      setSelected(selected);
    } else {
      setSelected([]);
    }
  }, [options, records]);

  const hasSelectedOptions = selected.some(Boolean);

  return (
    <TagWrapper afterBackground={afterBackground} showFade={hasSelectedOptions}>
      {selected.map((opt) => {
        if (opt) {
          return (
            <Tag color={opt.color} key={opt.id}>
              {opt.label}
            </Tag>
          );
        }
        return null;
      })}
    </TagWrapper>
  );
};

const NumberRenderer = ({ value, format }) => {
  let number;

  if (!value) return null;

  if (format === "decimal") {
    number = value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  if (format === "integer") {
    number = value.replace(/\D/g, "");
  }
  if (format === "percent") {
    number = `${value} %`;
  }

  return (
    <span
      style={{
        textOverflow: "ellipsis",
        overflow: "hidden",
      }}
    >
      {number}
    </span>
  );
};

const UserRenderer = ({ value, recordIsHidden, width }) => {
  const [user, setUser] = useState(undefined);
  const theme = useTheme();

  const userHiddenIcon = recordIsHidden && typeof value === "object";

  const spacing = width >= 200 ? 100 : width >= 100 ? 70 : 40;

  const maxWidth = width - spacing;

  useEffect(() => {
    (async () => {
      const fetchAndSetUser = async (accountId) => {
        const result = await atlassianRestService.getUserByAccountId(accountId);
        if (result) {
          setUser({
            avatarUrl: atlassianRestService.getProfilePictureUrl(result.profilePicture.path),
            id: result.accountId,
            name: `${result.publicName} ${result.isExternalCollaborator ? " (Guest)" : ""}`,
            isGuestUser: result.isExternalCollaborator,
            type: "user",
          });
        }
      };
      if (typeof value === "string") {
        await fetchAndSetUser(value);
      } else if (value?.accountId) {
        await fetchAndSetUser(value.accountId);
      }
    })();
  }, [value]);

  if (!value) {
    return <></>;
  }

  if (!user) {
    return (
      <SkeletonItem
        cssFn={(currentState) => {
          return {
            padding: "0",
            width: "120px",
            "&:after": {
              flexBasis: "100%!important",
            },
          };
        }}
        isShimmering
      />
    );
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          position: "relative",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <span
            role="img"
            style={{
              backgroundImage: `url(${user.avatarUrl})`,
              backgroundPosition: "center center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              borderRadius: "50%",
              minWidth: "20px",
              width: "20px",
              height: "20px",
              display: "flex",
            }}
          />
          <span style={{ marginLeft: "6px" }}>
            <NameLabel style={{ maxWidth: maxWidth }}>{user.name}</NameLabel>
          </span>
        </div>

        {userHiddenIcon && (
          <Tooltip content="Hidden" position="top">
            <IconContainer>
              <WatchIcon primaryColor={theme.shared.settings.database.records.watchIconColor} />
            </IconContainer>
          </Tooltip>
        )}
      </div>
    </>
  );
};

const EmailRenderer = ({ accountId }) => {
  const emails = useRecordsStore((state) => state.recordEmails);

  const userEmail = useMemo(() => {
    if (!emails?.length) return "";

    const foundUser = emails.find((userEmailData) => userEmailData.accountId === accountId);
    return !!foundUser ? foundUser.email : "";
  }, [accountId, emails]);

  return <EmailLink href={`mailto:${userEmail}`}>{userEmail}</EmailLink>;
};

const CellRenderer = ({ value, field, rowId, recordIsHidden, width }) => {
  if (field.type === FieldType.TEXT) {
    return (
      <span
        style={{
          textOverflow: "ellipsis",
          overflow: "hidden",
        }}
      >
        {value}
      </span>
    );
  }

  if (field.type === FieldType.URL) {
    const link = value?.includes?.("://") ? value : `https://${value}`;

    return (
      <a href={link} target="_parent" rel="noreferrer">
        {value}
      </a>
    );
  }

  if (field.type === FieldType.SELECT) {
    let options = field.options;

    if (field.id.includes("azure")) {
      const index = String((value || "").split("").reduce((i, char) => char.charCodeAt() + i, 0)).slice(-1);

      options = [{ id: value, label: value, color: OptionColors[index] }];
    }

    return <SelectRenderer value={value} isMulti={false} options={options} recordIsHidden={recordIsHidden} />;
  }

  if (field.type === FieldType.MULTISELECT) {
    return <SelectRenderer value={value} isMulti options={field.options} recordIsHidden={recordIsHidden} />;
  }

  if (field.type === FieldType.USER) {
    return <UserRenderer value={value} recordIsHidden={recordIsHidden} width={width} />;
  }

  if (field.type === FieldType.EMAIL) {
    return <EmailRenderer accountId={rowId} />;
  }

  if (field.type === FieldType.NUMBER) {
    return <NumberRenderer value={value} format={field.format} />;
  }

  if (field.type === FieldType.DATE) {
    if (!value) {
      return <></>;
    }
    return (
      <span
        style={{
          textOverflow: "ellipsis",
          overflow: "hidden",
        }}
      >
        {dayjs.unix(value).format("DD/MM/YYYY")}
      </span>
    );
  }
  return <div></div>;
};

export default CellRenderer;
