import React, { useEffect, useRef, useState } from "react";
import { AsyncSelect } from "@atlaskit/select";

import { searchUsers } from "../../PeopleBase/apiPeople";
import { CustomPlaceholder } from "./CustomCheckboxSelectComponents";
import { atlassianRestService } from "../../../../../Service/AtlassianRestService";
import { useClickOutside } from "../../../../../Shared/Hooks/useClickOutside";

import { useTheme } from "styled-components";

import { UserLabel } from "../../PeopleBase/Filter/User";
import { useCorporateIdentityStore } from "../../Settings/General/BrandAndColors/corporate-identity-store";
import { COLOR_TYPES } from "../../Settings/General/BrandAndColors/color-types";
import { SIMPLE_SELECT_STYLES } from "../../Dashboard/filter/CustomSelectStyles";

function UsersCheckboxSelect({ initialUsers, isMulti = true, label = "Authors", placeholder = "Search...", onChange }) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState();
  const [selectKey, setSelectKey] = useState(Math.random().toString(36).substring(7));

  const usersCount = selectedUsers?.length;
  const userItem = selectedUsers?.map((u) => u.name)[0];

  const selectWrapperRef = useRef();
  const theme = useTheme();

  const colors = useCorporateIdentityStore((state) => state.colors);
  const primaryColor = colors[COLOR_TYPES.PRIMARY] || "#0065ff";

  useClickOutside(selectWrapperRef, () => setIsOpen(false));

  useEffect(() => {
    if ((initialUsers ?? []).length > 0) {
      const promises = initialUsers.map(async (u) => atlassianRestService.getUserByAccountId(u));

      Promise.all(promises)
        .then((results) => {
          setSelectedUsers(results.map((r) => _toUserField(r)));
        })
        .catch((e) => {
          // Handle errors here
        });
    } else {
      setSelectedUsers([]);
    }
  }, [initialUsers]);

  useEffect(() => {
    window.AP.events.on("restore-filters-newsroom", () => {
      setSelectKey(Math.random().toString(36).substring(7));
    });
  }, []);

  const [optionsList, setOptionsList] = useState([]);
  useEffect(() => {
    async function loadOptions(query) {
      try {
        const res = (await atlassianRestService.findUsersByName(query)).results
          .filter(({ user }) => user.accountType === "atlassian")
          .map(({ user }) => _toUserField(user));

        setOptionsList(res);
      } catch (err) {
        console.error(err.message);
      }
    }

    loadOptions();
  }, []);

  const loadOptions = async (query) => {
    try {
      const response = await searchUsers(query);
      const { results } = JSON.parse(response.body);

      const users = results
        .filter(({ user }) => user.accountType === "atlassian")
        .map(({ user }) => ({
          label: <UserLabel name={user.displayName} icon={`${window.AP._hostOrigin}${user.profilePicture.path}`} />,
          value: user.accountId,
          name: user.displayName,
        }));

      return users;
    } catch (error) {
      return [];
    }
  };

  const handlePlaceholder = (props) => {
    return <CustomPlaceholder item={userItem} count={usersCount} isMulti={isMulti} fieldName="Authors" {...props} />;
  };

  return (
    <AsyncSelect
      key={selectKey}
      id="users-select"
      defaultOptions
      className="select-search"
      classNamePrefix="select-search"
      isSearchable
      isMulti
      cacheOptions
      loadOptions={loadOptions}
      hideSelectedOptions={false}
      backspaceRemovesValue
      openMenuOnFocus
      menuPlacement="bottom"
      maxMenuHeight={200}
      styles={SIMPLE_SELECT_STYLES}
      controlShouldRenderValue={false}
      closeMenuOnSelect={false}
      isClearable
      value={selectedUsers}
      onChange={(val) => {
        if (val) {
          onChange(isMulti ? val : val?.value);
        } else {
          onChange(isMulti ? [] : undefined);
        }
      }}
      components={{
        Placeholder: handlePlaceholder,
      }}
    />
  );
}

function _toUserField(user) {
  return {
    avatarUrl: atlassianRestService.getProfilePictureUrl(user.profilePicture.path),
    id: user.accountId,
    name: user.publicName,
    type: "user",
    label: user.publicName,
    value: user.accountId,
  };
}

export default UsersCheckboxSelect;
