import React, { useCallback } from "react";
import { useChangesStore } from "../../../track-changes-store";
import { usePanelsStore } from "../store/panels-store";
import ConfigProvider from "../Panel/ConfigProvider";

function EditPanel() {
  const { panels, setPanels, editingPanel, setEditingPanel } = usePanelsStore((state) => ({
    panels: state.panels,
    setPanels: state.setPanels,
    editingPanel: state.editingPanel,
    setEditingPanel: state.setEditingPanel,
  }));

  const setHasChanges = useChangesStore((state) => state.setHasChanges);

  const updateContentState = () => {
    setHasChanges(true);
  };

  const updatePanelDataProperty = ({ id, property, nestedProperty, label, value }) => {
    const editedPanel = {
      ...editingPanel,
      data: {
        ...editingPanel.data,
        [property]: nestedProperty
          ? editingPanel.data[property].map((option) =>
              option.label === label ? { ...option, [nestedProperty]: value } : option
            )
          : value,
      },
    };

    const newPanels = panels.map((panel) => (panel.id === id ? { ...editedPanel } : { ...panel }));

    setEditingPanel({ ...editedPanel });
    setPanels([...newPanels]);
    updateContentState();
  };

  const simpleUpdateProperty = useCallback(
    (panelId, panelProperty, newValue) => {
      const editedPanel = {
        ...editingPanel,
        data: {
          ...editingPanel.data,
          [panelProperty]: newValue,
        },
      };

      const newPanels = panels.map((panel) => {
        return panel.id === panelId ? { ...editedPanel } : { ...panel };
      });

      setEditingPanel({ ...editedPanel });
      setPanels([...newPanels]);
      updateContentState();
    },
    [editingPanel, panels, setEditingPanel, setPanels, updateContentState]
  );

  const updatePanelTitle = (panelId, value) => {
    const newPanels = panels.map((panel) => {
      return panel.id === panelId ? { ...panel, panelTitle: value } : { ...panel };
    });
    const selectedPanel = newPanels.find((p) => p.id === panelId);

    setEditingPanel({ ...selectedPanel });
    setPanels([...newPanels]);
    updateContentState();
  };

  return (
    <div style={{ marginTop: "20px", height: "calc(100% - 20px)" }}>
      <ConfigProvider
        selectedPanel={editingPanel}
        simpleUpdateProperty={simpleUpdateProperty}
        updatePanelDataProperty={updatePanelDataProperty}
        updatePanelTitle={updatePanelTitle}
      />
    </div>
  );
}

export default EditPanel;
