import React from "react";
import { Box } from "@atlaskit/primitives";
import { token } from "@atlaskit/tokens";

function Divider({ padding = "space.075", margin = "space.250" }) {
  return (
    <Box paddingBlock={margin}>
      <Box paddingBlock={padding}>
        <div
          style={{
            height: "1px",
            alignSelf: "stretch",
            borderRadius: "1px",
            background: `${token("color.border")}`,
          }}
        />
      </Box>
    </Box>
  );
}

export default Divider;
