import React from "react";
import InputFilter from "../../../filter/InputFilter";
import PaginationSelect from "../../../filter/PaginationSelect";
import TextInputFilter from "../../../filter/TextInputFilter";

function TasksEditor({ panel, updatePanelDataProperty, updatePanelTitle }) {
  const { data } = panel;

  return (
    <div>
      <TextInputFilter label="Title" value={panel.panelTitle} onUpdate={(value) => updatePanelTitle(panel.id, value)} />

      <InputFilter
        numberField
        minLength={1}
        initialValue={!data.isDummy ? data.maxNumberOfItems : undefined}
        label="Items per page"
        placeholder="Limit the number of pages"
        onUpdate={(value) => updatePanelDataProperty(panel.id, "maxNumberOfItems", value)}
      />

      <PaginationSelect
        initialValue={data.pagination}
        onUpdate={(pagination) => updatePanelDataProperty(panel.id, "pagination", pagination)}
      />
    </div>
  );
}

export default TasksEditor;
