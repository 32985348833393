import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { useInfiniteQuery } from "react-query";
import { nanoid } from "nanoid";
import styled from "styled-components";
import shallow from "zustand/shallow";
import { ChangeOperations } from "../change-handler";
import { useDatabaseUIStore } from "../database-ui-store";
import { useRecordsStore } from "../records-store";
import { fetchRecords } from "./api";
import CellRenderer from "./CellRenderer";
import EditorProvider from "./EditorProvider";
import Skeleton from "./Skeleton";
import dayjs from "dayjs";
import InfiniteScroller from "../../../Shared/Components/InfiniteScroller";
import Checkbox from "@atlaskit/checkbox";
import Tooltip from "@atlaskit/tooltip";
import { emailService } from "../../../Service/EmailService";

import { SelectionType } from "../menubar/Users";

const useClickOutside = (ref, field, handler, excluded = []) => {
  useEffect(() => {
    const eventListener = (event) => {
      if (
        !ref.current ||
        ref.current.contains(event.target) ||
        (event?.target?.classList?.length > 0 && Array.from(event.target.classList).some((c) => excluded.includes(c)))
      ) {
        return;
      }
      handler(event);
    };

    ref.current?.addEventListener("mousedown", eventListener);
    return () => {
      ref.current?.removeEventListener("mousedown", eventListener);
    };
  }, [ref, handler]);
};

const CellWrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  ${({ isEditing }) => (isEditing ? "" : "align-items: center")};
  margin: 0px;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 400;
  border-right: 1px solid ${({ theme }) => theme.shared.settings.database.border};
  border-bottom: 1px solid ${({ theme }) => theme.shared.settings.database.border};
  font-size: 14px;
  background: ${({ theme }) => theme.shared.settings.database.background};
  z-index: ${({ isEditing }) => (isEditing ? 1 : 0)};
  padding: ${({ isEditing }) => (isEditing ? 0 : "8px")};
  overflow: ${({ isEditing }) => (isEditing ? "initial" : "hidden")};
  width: ${({ isEditing, width }) => (isEditing ? width + 16 : width)}px;
  position: relative;

  &.active {
    background-color: ${({ theme }) => theme.shared.settings.database.background} !important;
  }
`;

const RowWrapper = styled.div`
  display: flex;
  height: 34px;
  color: ${({ theme }) => theme.shared.settings.database.text};

  & > div {
    background-color: ${({ recordIsHidden, theme }) =>
      recordIsHidden
        ? theme.shared.settings.database.records.hiddenRowBackground
        : theme.shared.settings.database.background};
    &:nth-child(1) {
      position: sticky;
      z-index: 2;
      left: 0;
    }
    &:nth-child(2) {
      position: sticky !important;
      z-index: 2;
      left: 41px;
    }
    &:nth-child(3) {
      position: sticky !important;
      z-index: 2;
      left: 108px;
      overflow: unset;
    }
    &:nth-child(4) {
      position: sticky !important;
      z-index: 2;
      overflow: unset;
      left: ${({ left }) => left}px;
    }

    .cell {
      background-color: ${({ recordIsHidden, theme }) =>
        recordIsHidden ? "yellow" : theme.shared.settings.database.background} !important;
    }
  }

  &:hover > div.cell {
    cursor: pointer;
    background-color: ${({ theme }) => theme.shared.settings.database.hover};
  }
`;

const CellMask = styled.div`
  position: absolute;
  border: 2px solid #0065ff;
  inset: 0;
`;

const HideUserCellWrapper = styled.div`
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 400;
  border-right: 1px solid ${({ theme }) => theme.shared.settings.database.border};
  border-bottom: 1px solid ${({ theme }) => theme.shared.settings.database.border};
  font-size: 14px;
  background: ${({ theme }) => theme.shared.settings.database.background};
`;

const CounterCell = ({ counter }) => {
  return (
    <CellWrapper className="cell" isEditing={false} width={50}>
      <div style={{ overflow: "hidden", textAlign: "center", width: "100%" }}>
        <span>{counter}</span>
      </div>
    </CellWrapper>
  );
};

const Cell = ({ rowId, value, columnId, recordIsHidden }) => {
  const [isActiveCell, setIsActiveCell] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const { columns, activeCell, setActiveCell, editingCell, setEditingCell } = useDatabaseUIStore((state) => {
    return {
      columns: state.columns,
      activeCell: state.activeCell,
      editingCell: state.editingCell,
      setActiveCell: state.setActiveCell,
      setEditingCell: state.setEditingCell,
    };
  }, shallow);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      if (field.type === "TEXT" && isEditing) {
        return;
      } else if (activeCell && !editingCell) {
        setEditingCell([activeCell[0], activeCell[1]]);
      } else if (activeCell && editingCell) {
        setEditingCell(undefined);
      }
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const { addChange, applyChangeInUI } = useRecordsStore((state) => {
    return {
      addChange: state.addChange,
      applyChangeInUI: state.applyRecordChange,
    };
  }, shallow);

  useEffect(() => {
    setIsActiveCell(
      activeCell && String(activeCell[0]) === String(columnId) && String(activeCell[1]) === String(rowId),
    );
    setIsEditing(
      editingCell && String(editingCell[0]) === String(columnId) && String(editingCell[1]) === String(rowId),
    );
  }, [columnId, rowId, activeCell, editingCell]);

  const field = useRecordsStore((state) => state.fields.find((f) => f.id === columnId));
  const cellRef = useRef(null);

  useClickOutside(
    cellRef,
    field,
    () => {
      setActiveCell(undefined);
      setEditingCell(undefined);
    },
    ["resizer"],
  );

  if (Object.keys(columns).length === 0) {
    return <></>;
  }

  return (
    <CellWrapper
      id={`cell-${columnId}-${rowId}`}
      key={columnId}
      ref={cellRef}
      onClick={(e) => {
        if (columns[columnId].readonly) {
          return;
        }
        if (e.detail === 1) {
          if (isActiveCell && !isEditing) {
            setEditingCell([columnId, rowId]);
          } else if (!isActiveCell && !isEditing) {
            setEditingCell([columnId, rowId]);
            setActiveCell(field.type !== "TEXT" ? [columnId, rowId] : undefined);
          }
        }
      }}
      className={`cell`}
      isEditing={isEditing}
      width={columns[columnId].width}
    >
      {isActiveCell && <CellMask />}
      {!isEditing && (
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          {field.id.includes("azure") ? (
            <Tooltip content="Entra ID Field - Read Only" position="top">
              <CellRenderer
                value={value}
                field={field}
                columnId={columnId}
                rowId={rowId}
                recordIsHidden={recordIsHidden}
                width={columns[columnId].width}
              />
            </Tooltip>
          ) : (
            <CellRenderer
              value={value}
              field={field}
              columnId={columnId}
              rowId={rowId}
              recordIsHidden={recordIsHidden}
              width={columns[columnId].width}
            />
          )}
        </div>
      )}
      {isEditing && (
        <EditorProvider
          initialValue={value}
          field={field}
          onEdited={(newValue) => {
            const change = {
              operation: ChangeOperations.UPDATE_RECORDS,
              accountId: rowId,
              values: {
                [columnId]: newValue || null,
              },
            };
            if (field.type === "DATE") {
              change.values[`recurring_${columnId}`] = !!newValue
                ? dayjs.unix(newValue).year(1970).hour(0).minute(0).second(0).unix()
                : null;
            }
            addChange(change);
            applyChangeInUI(rowId, change);
            if (field.type === "TEXT" || field.type === "SELECT" || field.type === "MULTISELECT") {
              setActiveCell([columnId, rowId]);
              setEditingCell(undefined);
            }
          }}
          abortEdit={() => {
            setEditingCell(undefined);
            setActiveCell([columnId, rowId]);
          }}
          keepEdit={() => {
            setEditingCell([columnId, rowId]);
            setActiveCell(field.type !== "TEXT" ? [columnId, rowId] : undefined);
          }}
        />
      )}
    </CellWrapper>
  );
};

const HideUserCell = ({ rowId, index }) => {
  const { hiddenUsersSelection, setHiddenUsersSelection } = useRecordsStore((state) => {
    return {
      hiddenUsersSelection: state.hiddenUsersSelection,
      setHiddenUsersSelection: state.setHiddenUsersSelection,
    };
  }, shallow);

  return (
    <HideUserCellWrapper className="cell">
      {index >= 0 ? (
        <Checkbox
          isChecked={hiddenUsersSelection.selectedUsers.includes(rowId)}
          onChange={(e) => {
            const newCheckedState = !hiddenUsersSelection.selectedUsers.includes(rowId);
            let newState = [...hiddenUsersSelection.selectedUsers];
            if (newCheckedState) {
              if (!newState.includes(String(rowId))) {
                newState.push(rowId);
              }
            } else {
              newState = newState.filter((x) => x !== rowId);
            }

            setHiddenUsersSelection({ selectionType: hiddenUsersSelection.selectionType, selectedUsers: newState });
          }}
        />
      ) : (
        <></>
      )}
    </HideUserCellWrapper>
  );
};

const Record = ({ recordHidden = false, record, fieldIds, index, leftOffset }) => {
  const values = record.values;
  const recordIsHidden = !!recordHidden;

  const offsetDifference = 200 - leftOffset;
  const calculatedLeftOffset = leftOffset === 0 ? 325 : 325 - offsetDifference;

  return (
    <RowWrapper recordIsHidden={recordIsHidden} left={calculatedLeftOffset}>
      <HideUserCell rowId={record.accountId} index={index} />
      <CounterCell counter={index} />
      {fieldIds
        .filter((x) => x !== "hidden")
        .map((columnId, i) => (
          <Cell
            key={`${record.accountId} - ${columnId}`}
            rowId={record.accountId}
            value={values[columnId]}
            columnId={columnId}
            recordIsHidden={recordIsHidden}
          />
        ))}

      <CellWrapper className="cell" width="190" />
    </RowWrapper>
  );
};

const Records = ({ leftOffset }) => {
  const {
    records,
    syncedGroup,
    setRecords,
    setRecordEmails,
    appendRecordEmails,
    setEmailsOfAccountIdsAlreadyFetched,
    emailsOfAccountIdsAlreadyFetched,
    lastResync,
    hiddenUsersSelection,
    setNumberOfHiddenUsers,
    setNumberOfActiveUsers,
    setNumberOfTotalUsers,
  } = useRecordsStore((state) => {
    return {
      records: state.records,
      syncedGroup: state.syncedGroup,
      setRecords: state.setRecords,
      setRecordEmails: state.setRecordEmails,
      appendRecordEmails: state.appendRecordEmails,
      setEmailsOfAccountIdsAlreadyFetched: state.setEmailsOfAccountIdsAlreadyFetched,
      emailsOfAccountIdsAlreadyFetched: state.emailsOfAccountIdsAlreadyFetched,
      lastResync: state.lastResync,
      hiddenUsersSelection: state.hiddenUsersSelection,
      setNumberOfHiddenUsers: state.setNumberOfHiddenUsers,
      setNumberOfActiveUsers: state.setNumberOfActiveUsers,
      setNumberOfTotalUsers: state.setNumberOfTotalUsers,
    };
  }, shallow);

  const { sort, filters } = useDatabaseUIStore(
    (state) => ({
      sort: state.sort,
      filters: state.selectedFilters,
    }),
    shallow,
  );

  const fields = useRecordsStore(
    (state) => state.fields,
    (old, newFields) => JSON.stringify(old.map((f) => f.id)) === JSON.stringify(newFields.map((f) => f.id)),
  );

  const {
    isLoading,
    data: recordsData,
    fetchNextPage,
    hasNextPage,
    refetch,
  } = useInfiniteQuery(["userdatabase-records", { syncedGroup, filters, sort }], fetchRecords, {
    retry: 0,
    select: (response) => {
      const pages = [];
      response.pages.forEach((page) => {
        pages.push({ ...page.data });
      });
      return { pages, pageParams: response.pageParams };
    },
    getNextPageParam: (lastPage) => {
      if (lastPage.data.page >= lastPage.data.totalPages) {
        return false;
      }
      return Number(lastPage.data.page + 1);
    },
  });

  const getAndSetUserEmailInBulk = async (accountIds) => {
    try {
      const response = await emailService.getUsersEmailBulk(accountIds);
      if (!response?.data?.length) return;

      appendRecordEmails(response.data);
      setEmailsOfAccountIdsAlreadyFetched(accountIds);
    } catch (error) {
      setRecordEmails([]);
    }
  };

  useEffect(() => {
    window.AP.events.on("azure-sync-complete", () => {
      refetch();
    });
  }, []);

  useEffect(() => {
    if (lastResync) {
      refetch();
    }
  }, [lastResync]);

  useEffect(() => {
    if (recordsData) {
      const allRecords = recordsData.pages.map((p) => p.hits).flat();
      setRecords(allRecords);

      const totalUsers = recordsData.pages?.length > 0 ? recordsData.pages[0].totalSize : 0 || 0;
      const hiddenUsers = recordsData.pages?.length > 0 ? recordsData.pages[0].totalNumberOfHiddenUsers : 0;
      const activeUsers = totalUsers - hiddenUsers >= 0 ? totalUsers - hiddenUsers : 0;

      setNumberOfTotalUsers(totalUsers);
      setNumberOfHiddenUsers(hiddenUsers);
      setNumberOfActiveUsers(activeUsers);

      const accountIdsOfFetchedRecords = allRecords.map(({ accountId }) => accountId);
      const accountIds = accountIdsOfFetchedRecords.filter((x) => !emailsOfAccountIdsAlreadyFetched.includes(x));
      getAndSetUserEmailInBulk(accountIds);
    }
  }, [recordsData]);

  if (isLoading) {
    return <></>;
  }

  return (
    <div
      style={{
        height: "100%",
        position: "relative",
      }}
    >
      <InfiniteScroller
        dataLength={recordsData?.pages?.length || 0}
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        endMessage=""
        scrollThreshold="3000px"
        scrollableTargetId="records-database"
        hasFixedSpinner
      >
        <Skeleton>
          <Record
            record={{ values: {}, accountId: nanoid(7) }}
            fieldIds={fields.map(({ id }) => id)}
            leftOffset={leftOffset}
          />
        </Skeleton>

        <div style={{ position: "absolute", marginTop: "40px" }}>
          {records
            .filter((record) =>
              hiddenUsersSelection.selectionType === SelectionType.SHOW_ALL_USERS
                ? true
                : hiddenUsersSelection.selectionType === SelectionType.SHOW_HIDDEN_USERS
                ? record.values.hidden
                : !record.values.hidden,
            )
            .map((record, idx) => (
              <Record
                recordHidden={record.values.hidden}
                key={record.accountId}
                record={record}
                fieldIds={fields.map(({ id }) => id)}
                index={idx + 1}
                leftOffset={leftOffset}
              />
            ))}

          <div
            style={{
              position: "absolute",
              zIndex: "10",
            }}
            id={`portal-user-visibility`}
          />
        </div>
      </InfiniteScroller>
    </div>
  );
};

export default Records;
