import styled from "styled-components";

export const FieldContainer = styled.div`
  margin-top: 10px;
`;

export const Label = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.shared.sidebar.colors.label};
  margin-bottom: 5px;
`;

export const OneRow = styled.div`
  display: flex;
  align-items: center;
  column-gap: 12px;
`;

export const Divider = styled.div`
  border-top: 1px solid ${({ theme }) => theme.shared.sidebar.divider};
  margin-top: 24px;
  margin-bottom: 5px;
`;

export const Border = styled.div`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.shared.sidebar.divider};
`;

export const HelperMessage = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.86em;
  line-height: 1.33;
  margin-top: 4px;
`;

export const AddNewContentContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-top: 30px;
  width: 100%;
  margin-bottom: 10px;
  position: relative;

  > div > div {
    margin-top: 0px;
  }

  :focus {
    outline: none;
  }
`;

export const AddManuallyPickedButton = styled.div`
  cursor: pointer;
  background: ${({ theme }) => theme.shared.form.button.background.inactive};
  width: 40px;
  height: 40px;
  position: relative;
  top: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ primaryColor }) => `${primaryColor}`};

  &:hover {
    color: #0041a3;
  }
`;

export const PoweredByMacrosuiteBanner = styled.div`
  height: 52px;
  width: 100%;
  border-radius: 8px;
  background: url("/images/hub/dashboard/macrosuite-sidebar-header.svg");
  background-size: cover;
  cursor: pointer;

  position: relative;
  z-index: 1;

  &::after {
    content: "Powered By Pulse";
    position: absolute;
    top: 18px;
    left: 20px;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #ffffff;
  }
`;
