import React, { useMemo } from "react";
import styled from "styled-components";
import TextInputFilter from "../../../filter/TextInputFilter";
import WarningIcon from "@atlaskit/icon/glyph/warning";
import EditorNoteIcon from "@atlaskit/icon/glyph/editor/note";
import InfoIcon from "@atlaskit/icon/glyph/info";
import EditorSuccessIcon from "@atlaskit/icon/glyph/editor/success";
import EditorErrorIcon from "@atlaskit/icon/glyph/editor/error";
import { TextEditor } from "@caelor/cards-and-panels-components";
import { TabTypes } from "../../tabTypes";
import {
  DEFAULT_ICON_SIZE,
  DEFAULT_TEXT_SIZE,
  DEFAULT_TITLE_SIZE,
  IconsBasedOnStyleType,
  NotificationTypes,
} from "../../types/Notification";
import { PanelEditorWrapper } from "./styled";
import ElementsToggle from "../../../filter/ElementsToggle";
import IconEmojiSelector from "../../../filter/IconEmojiSelector";
import { useSidebarStore } from "../sidebar/sidebar-store";
import { Divider, Label, OneRow } from "../../../filter/styled";
import { useTheme } from "styled-components";
import SizeButtons from "../../../../../../../Shared/Components/SizeButtons";

const NotificationPropertyKeys = {
  TITLE: "title",
  DESCRIPTION: "description",
  TYPE: "type",
  HAS_ICON: "hasIcon",
  HAS_TITLE: "hasTitle",
  ICON: "icon",
  ICON_SIZE: "iconSize",
  TITLE_SIZE: "titleSize",
  TEXT_SIZE: "textSize",
  ALIGNMENT: "alignment",
};

const StyleSelectOptions = [
  { label: <InfoIcon />, value: NotificationTypes.INFO, key: "info" },
  { label: <EditorNoteIcon />, value: NotificationTypes.NOTE, key: "note" },
  { label: <EditorSuccessIcon />, value: NotificationTypes.SUCCESS, key: "success" },
  { label: <WarningIcon />, value: NotificationTypes.WARNING, key: "warning" },
  { label: <EditorErrorIcon />, value: NotificationTypes.ERROR, key: "error" },
];

const ElementsToDisplayKeys = {
  ICON: "icon",
  TITLE: "title",
};

const ElementsToDisplayOptions = [
  { label: "Icon", value: ElementsToDisplayKeys.ICON },
  { label: "Title", value: ElementsToDisplayKeys.TITLE },
];

const IconSizeOptions = [
  { label: "S", value: 32 },
  { label: "M", value: DEFAULT_ICON_SIZE },
  { label: "L", value: 60 },
];

const TitleSizeOptions = [
  { label: "S", value: 16 },
  { label: "M", value: DEFAULT_TITLE_SIZE },
  { label: "L", value: 32 },
];

const TextSizeOptions = [
  { label: "S", value: 12 },
  { label: "M", value: DEFAULT_TEXT_SIZE },
  { label: "L", value: 20 },
];

const TextEditorContainer = styled.div`
  margin-top: 20px;

  & > div:nth-child(2) {
    border-color: ${({ theme }) => theme.shared.form.input.border};

    .text-options-wrapper {
      background-color: ${({ theme }) => theme.shared.form.textarea.background};
    }

    .icon-container {
      svg {
        color: ${({ theme }) => theme.shared.form.textarea.actions};
      }
    }

    svg.border {
      path {
        stroke: ${({ theme }) => theme.shared.form.textarea.divider};
      }
    }
  }
`;

const LayoutSidebar = ({ panelId, data, updatePanelDataProperty }) => {
  const hasIcon = data.hasIcon === undefined || data.hasIcon;
  const hasTitle = data.hasTitle === undefined || data.hasTitle;

  const elements = useMemo(() => {
    const builtElements = [];
    if (hasIcon) builtElements.push(ElementsToDisplayKeys.ICON);
    if (hasTitle) builtElements.push(ElementsToDisplayKeys.TITLE);
    return builtElements;
  }, [hasIcon, hasTitle]);

  const handleUpdateInElementsToDisplay = (elementKey) => {
    if (elementKey === ElementsToDisplayKeys.ICON) {
      updatePanelDataProperty(panelId, NotificationPropertyKeys.HAS_ICON, !hasIcon);
    }
    if (elementKey === ElementsToDisplayKeys.TITLE) {
      updatePanelDataProperty(panelId, NotificationPropertyKeys.HAS_TITLE, !hasTitle);
    }
  };

  return (
    <>
      <ElementsToggle
        label="Elements to display"
        elements={elements}
        options={ElementsToDisplayOptions}
        updateElements={handleUpdateInElementsToDisplay}
      />
    </>
  );
};

const ContentSidebar = ({ panelId, data, updatePanelDataProperty }) => {
  const theme = useTheme();
  const setDisableClickOutsideInDashboardSidebar = useSidebarStore((state) => state.setDisableClickOutside);

  const handleUpdateIcon = (data, type) => {
    const newIcon = { data, type };
    updatePanelDataProperty(panelId, NotificationPropertyKeys.ICON, newIcon);
  };

  const icon = data?.icon || IconsBasedOnStyleType[data.type];
  const hasIcon = data.hasIcon === undefined || data.hasIcon;

  return (
    <>
      <OneRow>
        {hasIcon && (
          <IconEmojiSelector
            label="Icon"
            icon={icon}
            setIcon={handleUpdateIcon}
            closeOnSelected={true}
            disableClickOutsideCallback={setDisableClickOutsideInDashboardSidebar}
            customScrollbarHeight="100%"
          />
        )}

        <TextInputFilter
          label="Title"
          value={data.title}
          onUpdate={(v) => updatePanelDataProperty(panelId, NotificationPropertyKeys.TITLE, v)}
          containerStyles={{ flex: "1" }}
        />
      </OneRow>

      <TextEditorContainer>
        <Label>Description</Label>
        <TextEditor
          linkBackgroundColor={theme.manualOrgChart.buttonColor}
          linkButtonsColor={theme.manualOrgChart.buttonColorActive}
          linkIconColor={theme.manualOrgChart.iconsColor}
          linkActionBackgroundColor={theme.manualOrgChart.minusPlusColor}
          value={data.description}
          onChangeComplete={(value) => updatePanelDataProperty(panelId, NotificationPropertyKeys.DESCRIPTION, value)}
          textColor={theme.shared.form.input.text}
        />
      </TextEditorContainer>
    </>
  );
};

const DesignSidebar = ({ panelId, data, updatePanelDataProperty }) => {
  const iconSize = data?.iconSize || 48;
  const titleSize = data?.titleSize || 24;
  const textSize = data?.textSize || 16;
  const hasIcon = data.hasIcon === undefined || data.hasIcon;
  const hasTitle = data.hasTitle === undefined || data.hasTitle;

  return (
    <>
      <SizeButtons
        label="Style"
        hasInputField={false}
        input={{ value: data.type }}
        setValue={(newType) => updatePanelDataProperty(panelId, NotificationPropertyKeys.TYPE, newType)}
        customOptions={StyleSelectOptions}
        customSectionStyles={{
          display: "block",
        }}
      />

      <Divider />

      {hasIcon && (
        <SizeButtons
          label="Icon size"
          input={{
            value: iconSize,
            min: 16,
            max: 72,
          }}
          setValue={(newSize) => updatePanelDataProperty(panelId, NotificationPropertyKeys.ICON_SIZE, newSize)}
          customOptions={IconSizeOptions}
        />
      )}

      {hasTitle && (
        <SizeButtons
          label="Title size"
          input={{
            value: titleSize,
            min: 12,
            max: 72,
          }}
          setValue={(newSize) => updatePanelDataProperty(panelId, NotificationPropertyKeys.TITLE_SIZE, newSize)}
          customOptions={TitleSizeOptions}
        />
      )}

      <SizeButtons
        label="Text size"
        input={{
          value: textSize,
          min: 8,
          max: 32,
        }}
        setValue={(newSize) => updatePanelDataProperty(panelId, NotificationPropertyKeys.TEXT_SIZE, newSize)}
        customOptions={TextSizeOptions}
      />
    </>
  );
};

function NotificationEditor({ panel, selectedTab, updatePanelDataProperty }) {
  const { data } = panel;

  return (
    <PanelEditorWrapper>
      {selectedTab === TabTypes.LAYOUT && (
        <LayoutSidebar panelId={panel.id} data={data} updatePanelDataProperty={updatePanelDataProperty} />
      )}

      {selectedTab === TabTypes.CONTENT && (
        <ContentSidebar panelId={panel.id} data={data} updatePanelDataProperty={updatePanelDataProperty} />
      )}

      {selectedTab === TabTypes.DESIGN && (
        <DesignSidebar panelId={panel.id} data={data} updatePanelDataProperty={updatePanelDataProperty} />
      )}
    </PanelEditorWrapper>
  );
}

export default NotificationEditor;
