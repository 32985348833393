import React from "react";
import styled, { useTheme } from "styled-components";
import TrashIcon from "@atlaskit/icon/glyph/trash";
import DragHandlerIcon from "@atlaskit/icon/glyph/drag-handler";
import { useSpaces } from "./useSpaces";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useDrag, useDrop } from "react-dnd";
import { token } from "@atlaskit/tokens";

const RemoveContainer = styled.div`
  cursor: pointer;
  width: 24px;
  height: 24px;
  display: grid;
  align-items: center;
  justify-content: flex-end;

  flex: 1;
  justify-self: flex-end;

  &:hover {
    opacity: 0.9;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;

  border-bottom: 1px solid rgba(223 225 230 / 50%);

  ${({ isHoveringOver, orderDirection }) => {
    if (!isHoveringOver) {
      return null;
    }

    return orderDirection === "top-bottom" ? `border-bottom: 1px solid #aaa;` : `border-top: 1px solid #aaa;`;
  }};

  padding: 7px 0px;

  font-size: 14px;
  font-weight: 500;
  color: #172b4d;

  .actions {
    visibility: hidden;
    height: 40%;
  }
  &:hover {
    .actions {
      visibility: visible;
    }
  }
`;

const IconContainer = styled.div`
  display: grid;
  place-content: center;
  cursor: grab;

  svg {
    color: #8993a4 !important;
    fill: #8993a4 !important;
  }

  &:hover {
    opacity: 0.9;
  }
`;

const ItemTitle = styled.span`
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 70%;
`;

function ReorderableItem({ space, reorderSpaces, handleDelete }) {
  const theme = useTheme();
  const [{ isOver }, drop] = useDrop(() => ({
    accept: ["space"],
    drop: () => ({ id: space.id, ...space }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  }));

  const [{ draggedItem }, drag] = useDrag({
    type: "space",
    item: () => {
      return { id: space.id, type: "space", ...space };
    },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (item && dropResult) {
        reorderSpaces(item.id, dropResult.id);
      }
    },
    collect: (monitor) => ({
      draggedItem: monitor.getItem(),
    }),
  });

  const draggedItemPosition = draggedItem?.position ?? 0;
  const potentialTargetPosition = isOver ? space?.position : 0;

  return (
    <Item
      key={space.id}
      ref={drop}
      isHoveringOver={isOver}
      orderDirection={draggedItemPosition < potentialTargetPosition ? "top-bottom" : "bottom-top"}
    >
      <IconContainer ref={drag}>
        <DragHandlerIcon />
      </IconContainer>

      <img src={space.icon} style={{ borderRadius: "5px", width: "24px", height: "24px" }} alt="" />

      <ItemTitle style={{ color: theme.shared.card.title }}>{space.name}</ItemTitle>

      <RemoveContainer className="actions" onClick={() => handleDelete(space.id)}>
        <TrashIcon primaryColor={token("color.icon")} />
      </RemoveContainer>
    </Item>
  );
}

function ManualSpacesList({ manuallyPicked, onRemove, updateOrder }) {
  const spaceQueries = useSpaces(manuallyPicked);

  const spaceData = spaceQueries.map((q) => q.data);

  const resetOrder = (list) => {
    const resetList = list.map((c, index) => {
      const slide = c;
      slide.position = index + 1;
      return slide;
    });
    return resetList;
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return resetOrder(result);
  };

  const reorderSpaces = (sourceId, targetId) => {
    if (sourceId === targetId) {
      return;
    }
    const content = [...spaceData];

    const sourceIndex = content.findIndex((p) => p.id === sourceId);
    const targetIndex = content.findIndex((p) => p.id === targetId);

    const items = reorder(content, sourceIndex, targetIndex);
    updateOrder(items);
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <Wrapper>
        {!!spaceData?.length &&
          spaceData
            .sort((a, b) => a.position - b.position)
            .map((data) => (
              <ReorderableItem key={data.id} space={data} reorderSpaces={reorderSpaces} handleDelete={onRemove} />
            ))}
      </Wrapper>
    </DndProvider>
  );
}

export default ManualSpacesList;
