export const SpaceIcon = ({ path }) => {
  const builtPath = path.replace("/wiki", "");
  return (
    <img
      style={{ minWidth: "32px", width: "32px", height: "32px", objectFit: "cover", borderRadius: "3px" }}
      src={`${window.AP._hostOrigin}/wiki${builtPath}`}
      alt="space"
    />
  );
};
