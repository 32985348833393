import React, { useEffect, useState } from "react";
import Form from "@atlaskit/form";
import selectStyles from "./selectStyles";
import { atlassianRestService } from "../../../../../../../Service/AtlassianRestService";
import { useTheme } from "styled-components";
import styled from "styled-components";
import { AsyncSelect } from "@atlaskit/select";

const UserLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 5px;
`;

const UserImage = styled.img`
  width: 26px;
  height: 26px;
  border-radius: 50%;
  box-sizing: border-box;
`;

const UserName = styled.div`
  font-size: 14px;
  white-space: nowrap;
  user-select: none;
`;

const UserLabel = ({ name, icon }) => {
  return (
    <UserLabelWrapper>
      <UserImage src={icon} />
      <UserName>{name}</UserName>
    </UserLabelWrapper>
  );
};

function mapUser(user) {
  return {
    avatarUrl: atlassianRestService.getProfilePictureUrl(user.profilePicture.path),
    id: user.accountId,
    name: user.publicName,
    type: "user",
    label: (
      <UserLabel name={user.publicName} icon={atlassianRestService.getProfilePictureUrl(user.profilePicture.path)} />
    ),
    value: user.accountId,
  };
}

const User = ({ initialValue, onEdit, onAbort }) => {
  const [selectedUser, setSelectedUser] = useState([]);

  useEffect(() => {
    (async () => {
      const result = await atlassianRestService.getUserByAccountId(initialValue);
      if (result) {
        setSelectedUser(mapUser(result));
      }
    })();
  }, [initialValue]);

  const loadOptions = async (query) => {
    if (query.trim().length === 0) {
      return [];
    }
    return (await atlassianRestService.findUsersByName(query)).results
      .filter(({ user }) => user.accountType === "atlassian")
      .map(({ user }) => mapUser(user));
  };

  const theme = useTheme();

  return (
    <Form name="userpicker">
      {({ fieldProps, options }) => (
        <AsyncSelect
          placeholder="Search user..."
          autoFocus
          isClearable
          options={options}
          styles={selectStyles(theme, selectedUser)}
          onBlur={() => {
            onAbort();
          }}
          onChange={(e) => {
            setSelectedUser(e);
            if (e) {
              onEdit(e.id);
              return;
            }
            onEdit(undefined);
          }}
          components={{
            DropdownIndicator: null,
          }}
          value={selectedUser}
          loadOptions={loadOptions}
        />
      )}
    </Form>
  );
};

export default User;
