export const initResizeOnErrorObserver = () => {
  var ro = new ResizeObserver((entries) => {
    if (window.AP && window.AP.resize) {
      window.AP.resize("100%", `0px`);
    }
  });
  ro.observe(document.body);
};

export const initResizeObserver = (setResized = undefined, shouldDisableHeight = false) => {
  let { scrollHeight } = document.body;
  const observer = new MutationObserver(() => {
    if (scrollHeight !== document.body.scrollHeight) {
      scrollHeight = document.body.scrollHeight;
      if (window.AP && window.AP.resize) {
        let height = scrollHeight + (shouldDisableHeight ? 0 : 10);
        window.AP.resize("100%", `${height.toString()}px`);
        window.AP.sizeToParent();
        if (setResized && document.body.offsetWidth > 300) {
          setResized(true);
        }
      }
    }
  });
  observer.observe(document.body, {
    attributes: true,
    childList: true,
    subtree: true,
  });

  var ro = new ResizeObserver((entries) => {
    if (window.AP && window.AP.resize) {
      window.AP.resize("100%", `${(document.body.scrollHeight + (shouldDisableHeight ? 0 : 10)).toString()}px`);
      window.AP.sizeToParent();
    }
  });
  ro.observe(document.body);
};
