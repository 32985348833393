import React, { useState, useEffect } from "react";
import styled from "styled-components";
import SelectEditor from "../editor/Select";
import MultiSelectEditor from "../editor/Multiselect";
import { FieldValue, hoverStyle, EditorContainer } from "../styled";
import { FieldType } from "../../../../../../UserDatabase/field-type";
import { OptionColors } from "../editor/Select";

const TagWrapper = styled.div`
  display: inline-flex;
  gap: 12px;
  flex-wrap: wrap;
  opacity: ${({ isEditing }) => (isEditing ? 0 : 1)};

  ${({ isEditable }) => (isEditable ? hoverStyle : "")}
`;

const Tag = styled.div`
  border-radius: 3px;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  padding: 2px 5px;
  color: #42526e;
  white-space: nowrap;
  background: ${({ color }) => (color ? color : "#f4f5f7")};
`;

function Select({ field, value, onEdit, isSidebar, isEditable, getPrefixBasedOnRole }) {
  const [isEditing, setIsEditing] = useState(false);
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    let val = [];

    if (field.options?.length) {
      if (field.type === FieldType.MULTISELECT) {
        val = (value || []).map((id) => field.options.find((opt) => opt.id === id));
      } else {
        val = [field.options.find((opt) => opt.id === value)];
      }
    }

    if (field.id.startsWith("azure") && value) {
      const index = String(value.split("").reduce((i, char) => char.charCodeAt() + i, 0)).slice(-1);

      val = [{ id: value, label: value, color: OptionColors[index] }];
    }

    setSelected(val.filter((opt) => opt));
  }, [field, value]);

  return (
    <>
      {isEditing && (
        <EditorContainer>
          {field.type === FieldType.MULTISELECT ? (
            <MultiSelectEditor
              field={field}
              initialValue={value}
              onEdit={(val) => {
                onEdit(field.id, val);
                setIsEditing(false);
              }}
            />
          ) : (
            <SelectEditor
              field={field}
              initialValue={value}
              onAbort={() => setIsEditing(false)}
              onEdit={(val) => {
                onEdit(field.id, val);
                setIsEditing(false);
              }}
            />
          )}
        </EditorContainer>
      )}

      {selected.length ? (
        <>
          {isSidebar ? (
            <FieldValue
              isEditing={isEditing}
              isEditable={isEditable}
              onClick={() => {
                if (isEditable) {
                  setIsEditing(true);
                }
              }}
            >
              {selected.map((opt, index) => {
                const label = opt.label;
                const isLast = index === selected.length - 1;
                return isLast ? label : `${label}, `;
              })}
            </FieldValue>
          ) : (
            <TagWrapper
              isEditing={isEditing}
              isEditable={isEditable}
              onClick={() => {
                if (isEditable) {
                  setIsEditing(true);
                }
              }}
            >
              {selected.map((opt) => {
                if (opt) {
                  return (
                    <Tag color={opt.color} key={opt.id}>
                      {opt.label}
                    </Tag>
                  );
                }

                return null;
              })}
            </TagWrapper>
          )}
        </>
      ) : (
        <FieldValue
          isEditing={isEditing}
          isEditable={isEditable}
          onClick={() => {
            if (isEditable) {
              setIsEditing(true);
            }
          }}
        >
          {getPrefixBasedOnRole()} {field.title}
        </FieldValue>
      )}
    </>
  );
}

export default Select;
