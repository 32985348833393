import React from "react";
import { Center, Panel, PanelContent, PanelDescription, PanelTitle } from "./styled";
import PeoplePicker from "../../../../Dashboard/filter/PeoplePicker";
import { FieldContainer } from "../../../../Dashboard/filter/styled";
import { Pages } from "../../../../../pages";
import { SectionKeys, SectionPagesIDs } from "../../../sections";

function UserSelector({ title, description, accountIds, onUpdate, isDisabled }) {
  return (
    <Panel>
      <PanelTitle>{title}</PanelTitle>
      <PanelContent>
        <FieldContainer>
          <PanelDescription>{description}</PanelDescription>
        </FieldContainer>

        <PeoplePicker
          placeholder="Select users"
          label={title}
          initialAccountIDs={accountIds}
          onUpdate={onUpdate}
          isMulti
          isDisabled={isDisabled}
        />
      </PanelContent>

      {!accountIds?.length && !isDisabled && (
        <Center style={{ padding: "70px 0px 50px 0px" }}>
          <img style={{ margin: "0 auto", height: "150px" }} src="/images/hub/db-not-init.svg" alt="" />
          <PanelDescription>No users are selected.</PanelDescription>
        </Center>
      )}

      {isDisabled && (
        <Center style={{ padding: "50px 100px" }}>
          <img src="/images/hub/db-not-init.svg" alt="" style={{ margin: "0 auto", height: "150px" }} />

          <PanelDescription>
            <span>
              Database not synced. Please sync your{" "}
              <a
                rel="noreferrer"
                target="_parent"
                href={`${window.AP._hostOrigin}/wiki/plugins/servlet/ac/com.caelor.confluence.cloud.cosmos/caelor-cosmos-portal?ac.cosmosPage=${Pages.SETTINGS}-${SectionKeys.PEOPLE_BASE}-${SectionPagesIDs.DATABASE}`}
              >
                employee database
              </a>{" "}
              first.
            </span>
          </PanelDescription>
        </Center>
      )}
    </Panel>
  );
}

export default UserSelector;
