import React, { useEffect } from "react";
import styled from "styled-components";
import { AvailableTabs } from "../consts/tabs";
import { useCategoriesStore } from "../categories-store";

const Container = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
  padding-top: 20px;
  border-bottom: ${({ theme }) => `2px solid ${theme.shared.sidebar.tabs.background.inactive}`};
`;

const Tab = styled.button`
  all: unset;
  color: ${({ theme }) => theme.shared.sidebar.tabs.text.inactive};
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  padding: 4px 8px;
  position: relative;
  cursor: pointer;
  display: flex;
  gap: 5px;

  ${({ isSelected }) => {
    if (isSelected) {
      return `
          font-weight: bold;
          font-size: 14px;
          color: #0c66e4;
    `;
    }
  }}
`;

const TabLine = styled.div`
  width: 100%;
  height: 2px;
  box-sizing: border-box;
  border-bottom: 2px solid #0c66e4;
  position: absolute;
  bottom: -2px;
  left: 0px;
`;

function Tabs() {
  const { selectedTab, setSelectedTab } = useCategoriesStore((state) => ({
    selectedTab: state.selectedTab,
    setSelectedTab: state.setSelectedTab,
  }));

  return (
    <Container>
      {AvailableTabs.map(({ id, label }) => (
        <Tab key={id} isSelected={selectedTab?.id === id} title={label} onClick={() => setSelectedTab({ id, label })}>
          <span className="tab-label">{label}</span>
          {selectedTab?.id === id && <TabLine />}
        </Tab>
      ))}
    </Container>
  );
}

export default Tabs;
