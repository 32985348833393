import React from "react";
import SidebarTabSelector, { SidebarTabs } from "../../Shared/SidebarTabSelector";
import { CardLayoutIcon } from "../../../icons/SidebarIcons";
import { useSidebarStore } from "../newsroom-store";

function ContentSelector() {
  const setSelectedSidebar = useSidebarStore((state) => state.setSelectedSidebar);

  return (
    <SidebarTabSelector
      tab={{
        title: "Layout Design",
        description: "Match news design with your brand style guide.",
        value: SidebarTabs.NEWS_DESIGN,
      }}
      Icon={CardLayoutIcon}
      setSelectedSidebarTab={setSelectedSidebar}
    />
  );
}

export default ContentSelector;
