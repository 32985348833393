import React, { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import styled from "styled-components";
import { AvailableTabs } from "./consts/tabs";
import { Divider, Header, Wrapper } from "../styled/sections";
import TitleWithBack from "../../../../../Shared/Components/TitleWithBack";
import { usePageStore } from "../../../page-store";
import { Pages } from "../../../pages";
import LoadingScreen from "./components/LoadingScreen";
import Content from "./components/Content";
import { useWidgetsStore } from "./widgets-store";
import debounce from "lodash.debounce";
import { getWidgetsSettings, saveWidgetSettings } from "./api";
import { fetchIsInitialized } from "../../../../UserDatabase/datagrid/api";

const Spacer = styled.div`
  padding: 32px 0px;
`;

function WidgetOverview() {
  const [loadingSelectedTab, setLoadingSelectedTab] = useState(true);

  const { widgetSettings, setWidgetSettings, setIsDbInit } = useWidgetsStore((state) => ({
    widgetSettings: state.widgetSettings,
    setWidgetSettings: state.setWidgetSettings,
    setIsDbInit: state.setIsDbInit,
  }));

  const setSelectedTab = useWidgetsStore((state) => state.setSelectedTab);
  const setSelectedPage = usePageStore((state) => state.setSelectedPage);

  const { isLoading: loadingInitDbState, data: isInitialized } = useQuery(
    "database-is-initialized",
    fetchIsInitialized,
    {
      retry: 0,
      select: (response) => {
        const { data } = response;
        return data;
      },
    },
  );

  const { isLoading: isLoadingWidgetSettings, data: widgetSettingsData } = useQuery(
    "widgets-settings",
    getWidgetsSettings,
    {
      retry: 0,
      select: (response) => {
        const { data } = response;
        return data;
      },
    },
  );

  useEffect(() => {
    if (!isLoadingWidgetSettings && widgetSettingsData?.exists) {
      setWidgetSettings({ ...widgetSettingsData.widgetSettings });
    }
  }, [isLoadingWidgetSettings, widgetSettingsData, setWidgetSettings]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const foundTab = urlParams.get("widgetTab");

    setSelectedTab(AvailableTabs.find(({ id }) => id === foundTab) ?? AvailableTabs[0]);
    setLoadingSelectedTab(false);
  }, [setSelectedTab, setWidgetSettings]);

  useEffect(() => {
    if (!loadingInitDbState) {
      setIsDbInit(!!isInitialized);
    }
  }, [loadingInitDbState, isInitialized, setIsDbInit]);

  const handleSaveWidgetSettings = useRef(
    debounce((settings) => {
      saveWidgetSettings(settings);
    }, 250),
  ).current;

  useEffect(() => {
    return () => {
      handleSaveWidgetSettings.cancel();
    };
  }, [handleSaveWidgetSettings]);

  useEffect(() => {
    if (Object.keys(widgetSettings || {}).length && !isLoadingWidgetSettings) {
      handleSaveWidgetSettings(widgetSettings);
    }
  }, [widgetSettings, isLoadingWidgetSettings, handleSaveWidgetSettings]);

  const isLoading = loadingSelectedTab || isLoadingWidgetSettings || loadingInitDbState;

  return (
    <Wrapper>
      <Header>
        <TitleWithBack handleOnClick={() => setSelectedPage(Pages.SETTINGS)} title="Widgets & Org Charts" />
      </Header>
      <Divider />

      <Spacer>{isLoading ? <LoadingScreen /> : <Content />}</Spacer>
    </Wrapper>
  );
}

export default WidgetOverview;
