import React from "react";
import styled from "styled-components";
import Tag from "@atlaskit/tag";

const LabelContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 8px;
  row-gap: 8px;
`;

const getLabel = (column, options, value) => {
  const option = options?.find(({ id }) => id === value);
  const label = column.startsWith("azure") ? value : "Deleted option";

  return option?.label || label;
};

const getLabelColor = (column, options, value) => {
  const option = options?.find(({ id }) => id === value);

  return column.startsWith("azure") || option ? "standard" : "red";
};

const Labels = ({ isMulti, column, value, options, removeCallback }) => (
  <LabelContainer>
    {isMulti && (
      <>
        {value.map((val) => (
          <Tag
            key={val}
            text={getLabel(column, options, val)}
            color={getLabelColor(column, options, val)}
            onAfterRemoveAction={() => removeCallback(val)}
          />
        ))}
      </>
    )}

    {!isMulti && (
      <Tag
        text={getLabel(column, options, value)}
        color={getLabelColor(column, options, value)}
        onAfterRemoveAction={() => removeCallback(value)}
      />
    )}
  </LabelContainer>
);

export default Labels;
