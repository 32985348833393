import React from "react";
import styled from "styled-components";
import Spinner from "@atlaskit/spinner";

export const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0px;
  column-gap: 10px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  column-gap: 10px;
`;

const Button = styled.div`
  border-radius: 8px;
  padding: 12px;
  font-size: 14px;
  cursor: pointer;
  text-align: center;
  flex: 1;
  background: ${({ bg }) => bg};
  color: ${({ color }) => color};

  &:hover {
    background: ${({ bg }) => bg}cc;
  }
  &:active {
    background: ${({ bg }) => bg}ee;
  }
`;

function SidebarFooter({
  isSaving,
  setIsSaving,
  save,
  close,
  saveCallback,
  primaryColor = "#0065ff",
  primaryContrastColor = "#ffffff",
}) {
  return (
    <div
      style={{
        position: "absolute",
        bottom: "20px",
        left: "20px",
        right: "20px",
        paddingTop: "16px",
        borderTop: "1px solid #DFE1E6",
      }}
    >
      {isSaving && (
        <SpinnerContainer>
          <span>Saving...</span>
          <Spinner size="medium" />
        </SpinnerContainer>
      )}

      <ButtonsWrapper>
        <Button bg="#F1F2F4" color="#42526E" onClick={() => close()}>
          Cancel
        </Button>
        <Button
          bg={primaryColor}
          color={primaryContrastColor}
          onClick={() => {
            setIsSaving(true);
            save(saveCallback);
          }}
        >
          Save changes
        </Button>
      </ButtonsWrapper>
    </div>
  );
}

export default SidebarFooter;
