import styled from "styled-components";

export const SettingsWrapper = styled.main`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const SectionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 36px 64px;
`;

export const SectionWrapper = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;
`;

export const SectionItem = styled.a`
  all: unset;
  display: flex;
  place-items: center;
  padding: 20px;
  border-radius: 3px;
  box-shadow:
    0px 0px 1px 0px rgba(9, 30, 66, 0.31),
    0px 1px 3px 0px rgba(9, 30, 66, 0.25);
  border: 1px solid ${({ theme }) => theme.shared.settings.item.border};
  background: ${({ theme }) => theme.shared.settings.item.background};
  cursor: pointer;
`;

export const ItemDivider = styled.div`
  width: 2px;
  height: 40px;
  background-color: ${({ backgroundColor }) => (backgroundColor ? backgroundColor : "black")};
`;

export const ItemIconAndTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  max-width: 240px;
  width: 100%;
`;

export const ItemIconWrapper = styled.div`
  display: grid;
  place-items: center;
  background-color: ${({ backgroundColor }) => (backgroundColor ? backgroundColor : "black")};
  width: 40px;
  height: 40px;
  border-radius: 3px;
`;

export const ItemTitle = styled.h1`
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  color: ${({ theme }) => theme.shared.settings.title};
`;

export const ItemDescription = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.shared.settings.description};
  padding-left: 24px;
`;

export const Header = styled.header`
  display: flex;
  flex-direction: column;
  gap: 12px;
  border-bottom: 1px solid ${({ theme }) => theme.shared.settings.divider};
  padding: 36px 64px;
`;

export const Title = styled.h1`
  padding: 0;
  margin: 0;
  font-weight: 500;
  font-size: 18px;
  color: ${({ theme }) => theme.shared.settings.title};
`;

export const Description = styled.p`
  padding: 0;
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  color: ${({ theme }) => theme.shared.settings.description};
`;

export const SidebarExpander = styled.div`
  position: absolute;
  z-index: 2;
  top: 60px;
  right: -12px;

  display: grid;
  place-content: center;

  width: 24px;
  height: 24px;
  border-radius: 50%;

  cursor: pointer;

  box-shadow:
    rgb(9 30 66 / 8%) 0px 0px 0px 1px,
    rgb(9 30 66 / 8%) 0px 2px 4px 1px;
  opacity: 0;
  color: #6b778c;
  background-color: #fff;
  transition:
    background-color 100ms linear 0s,
    color 100ms linear 0s,
    opacity 350ms cubic-bezier(0.2, 0, 0, 1) 0s;

  &:hover {
    color: #fff;
    background-color: #4c9aff;
    opacity: 1;
  }
`;

export const SidebarExpanderContainer = styled.div`
  position: relative;
  height: 100%;
  z-index: 10;

  ${SidebarExpander} {
    ${({ isSidebarExpanded }) => (!isSidebarExpanded ? "opacity: 1;" : "")};
  }

  &:hover {
    ${SidebarExpander} {
      opacity: 1;
    }
  }
`;

export const SidebarSection = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  opacity: 1;
  transition: opacity 300ms linear 0s;
`;

export const Sidebar = styled.nav`
  position: relative;
  z-index: 1;
  padding: 35px 10px;
  height: calc(100% - 70px);

  display: flex;
  flex-direction: column;
  row-gap: 28px;

  border-right: 1px solid #e9ebf1;

  width: ${({ isSidebarExpanded }) => (isSidebarExpanded ? "170px" : "0px")};
  transition: width 300ms linear 0s;

  ${SidebarSection} {
    opacity: ${({ isSidebarExpanded }) => (isSidebarExpanded ? "1" : "0")};
  }

  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.scrollbar};
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #999;
  }
`;

export const SidebarTitle = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #2f7aff;
  margin-bottom: -6px;
  padding-left: 13px;
`;

export const SidebarSectionTitle = styled.span`
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #07145a;
  padding-left: 13px;
  margin-bottom: 7px;
`;

export const SidebarSectionItem = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;

  width: fit-content;
  border-radius: 3px;

  padding: 4px 13px 5px 13px;
  color: ${({ isSelected }) => (isSelected ? "#2F7AFF" : "#516382")};
  background-color: ${({ isSelected }) => (isSelected ? "#e9f2ff" : "#ffffff")};

  max-height: ${({ isSelected }) => (isSelected ? "25px" : "17px")};
  transition: max-height 150ms ease-in-out;

  cursor: pointer;
`;
