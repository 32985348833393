import React, { useEffect, useState } from "react";
import { AsyncSelect } from "@atlaskit/select";
import { htmlDecode } from "../../../../ContentBuilder/utils/decode-html-string";
import { atlassianRestService } from "../../../../../Service/AtlassianRestService";
import { SELECT_STYLES, SIMPLE_SELECT_STYLES } from "./CustomSelectStyles";
import { FieldContainer, Label } from "./styled";

function mapBlogpost(blogpost) {
  return {
    contentId: blogpost.content.id,
    id: blogpost.content.id,
    type: blogpost.content.type,
    name: blogpost.content.title,
    space: { name: blogpost.content.space.name },
    numberOfComments: blogpost.content.children.comment.results.length,
    numberOfLikes: blogpost.content.metadata.likes.count,
    excerpt: htmlDecode(blogpost.excerpt),
    labels: blogpost.content.metadata?.labels?.results.map((l) => l.label) ?? [],
    url: `${window.AP._hostOrigin}/wiki${blogpost.url}`,
    createdBy: blogpost.content.history.createdBy.displayName,
    avatarUrl: `${window.AP._hostOrigin}${blogpost.content.history.createdBy.profilePicture.path}`,
    lastModified: blogpost.friendlyLastModified,
    label: blogpost.content.title,
    value: blogpost.content.id,
  };
}

const BlogPicker = ({
  onUpdate,
  label = "Blog",
  placeholder = "Filter by blog",
  isDisabled = false,
  manuallyPicked,
  closeMenuOnSelect = false,
}) => {
  const [selectedBlogManual, setSelectedBlogManual] = useState(manuallyPicked);

  useEffect(() => {
    setSelectedBlogManual(manuallyPicked);
  }, [manuallyPicked]);

  const loadOptions = async (query) => {
    const allBlogs = (await atlassianRestService.searchForBlog(query)).results.map(mapBlogpost);

    const filteredBlogs = allBlogs.filter(
      (blog) => !selectedBlogManual.some((selected) => selected.contentId === blog.contentId),
    );
    return filteredBlogs;
  };

  const handleOnChangeManual = (e) => {
    if (e !== undefined) {
      setSelectedBlogManual(e);
      onUpdate(e);
    } else {
      setSelectedBlogManual([]);
      onUpdate([]);
    }
  };

  return (
    <FieldContainer>
      <Label>{label}</Label>
      <AsyncSelect
        isDisabled={isDisabled}
        placeholder={placeholder}
        onChange={handleOnChangeManual}
        loadOptions={loadOptions}
        defaultOptions
        styles={SIMPLE_SELECT_STYLES}
        closeMenuOnSelect={closeMenuOnSelect}
      />
    </FieldContainer>
  );
};
export default BlogPicker;
