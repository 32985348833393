import React from "react";
import { token } from "@atlaskit/tokens";
import DragHandlerIcon from "@atlaskit/icon/glyph/drag-handler";
import ChevronRightIcon from "@atlaskit/icon/glyph/chevron-right";
import EditorEditIcon from "@atlaskit/icon/glyph/editor/edit";
import EditorRemoveIcon from "@atlaskit/icon/glyph/editor/remove";
import { Box, Flex, Grid, xcss } from "@atlaskit/primitives";
import { PARENT_ENTRY_IDENTIFIER } from "../../../../defaults";
import { useDrag, useDrop } from "react-dnd";
import { useHelpersStore, useNavigationStore, useSidebarStore } from "../../../../store";
import { ModalTypes } from "../../../modals/types";
import { DrawerTypes } from "../../../drawer/types";
import ItemIcon from "./ItemIcon";
import SpaceItem from "./SpaceItem";
import {
  EntryBox,
  EntryName,
  Placeholder,
  childItemStyle,
  expandableChildrenStyle,
  iconStyle,
  parentItemStyle,
  rotatableIconStyle,
} from "./styled";
import AddOption from "../AddOption";
import { MenuStyleTypes } from "../../../../menu/types";

const ITEM_HEIGHT = 40;
const ITEM_GAP = 4;

function MenuItem({ entry, itemLevel = 0, index }) {
  const {
    id,
    parent,
    data: { name },
  } = entry;

  const { reorderEntry, setEditingEntry, findParentEntry } = useNavigationStore((state) => ({
    reorderEntry: state.reorderEntry,
    setEditingEntry: state.setEditingEntry,
    findParentEntry: state.findParentEntry,
  }));

  const { setDeletingEntry, setModalType, setModalVisible } = useHelpersStore((state) => ({
    setDeletingEntry: state.setDeletingEntry,
    setModalType: state.setModalType,
    setModalVisible: state.setModalVisible,
  }));

  const { setIsOpen, setSelectedSidebarType } = useSidebarStore((state) => ({
    setIsOpen: state.setIsOpen,
    setSelectedSidebarType: state.setSelectedSidebarType,
  }));

  const [isExpanded, setIsExpanded] = React.useState(false);

  const width = `calc(100% - ${itemLevel * 24}px)`;
  const marginLeft = `${itemLevel * 24}px`;

  const isParent = parent === PARENT_ENTRY_IDENTIFIER;
  const parentEntry = isParent ? undefined : findParentEntry(parent);
  const entryType = isParent ? "parent" : `child-${entry.parent}`;
  const hasChildren = !!entry?.children?.length || !!entry?.data?.spaces?.length;
  const numberOfChildren = entry?.children?.length || entry?.data?.spaces?.length || 0;

  const [{ isOver, canDrop }, drop] = useDrop(() => ({
    accept: [entryType],
    drop: () => ({ type: entryType, entryId: entry.id, parentId: entry.parent }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));

  const [{ draggedItem }, drag] = useDrag({
    type: entryType,
    item: () => {
      return { type: entryType, entryId: entry.id, parentId: entry.parent, index };
    },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (item && dropResult) {
        reorderEntry(item, dropResult);
      }
    },
    collect: (monitor) => ({
      draggedItem: monitor.getItem(),
    }),
  });

  const entryHasIcon = entry?.data?.icon?.icon !== "none" || !isParent;
  const entryIsTextDenver = parentEntry?.menuStyle === MenuStyleTypes.DENVER && parentEntry?.data?.type === "text";

  const gridColumns = `24px ${hasChildren ? "24px" : ""} ${entryHasIcon && !entryIsTextDenver ? "24px" : ""} auto`;

  const draggedItemPosition = draggedItem?.index ?? 0;
  const potentialTargetPosition = isOver ? index : 0;

  const orderDirection = draggedItemPosition > potentialTargetPosition ? "top-bottom" : "bottom-top";

  return (
    <>
      {orderDirection === "top-bottom" && <Placeholder style={{ width, marginLeft }} isOver={isOver && canDrop} />}

      <EntryBox style={{ width, marginLeft }} gridColumnsOnHover={`${gridColumns} 60px`} ref={drag} key={id}>
        <Box padding="space.100" xcss={isParent ? parentItemStyle : childItemStyle} ref={drop}>
          <Grid id="content-grid" templateColumns={gridColumns} alignItems="center">
            <Box xcss={iconStyle}>
              <DragHandlerIcon primaryColor={token("color.icon")} />
            </Box>
            {hasChildren && (
              <Box
                xcss={rotatableIconStyle}
                style={{ transform: `rotate(${isExpanded ? "90deg" : "0deg"})`, height: "24px" }}
                onClick={() => {
                  window.AP.events.emit(isExpanded ? "closed-menu-entry" : "opened-menu-entry", { entryId: entry.id });

                  setIsExpanded(!isExpanded);
                }}
              >
                <ChevronRightIcon primaryColor={token("color.icon")} />
              </Box>
            )}

            {entryHasIcon && <ItemIcon entry={entry} parentEntry={parentEntry} menuStyle={parentEntry?.menuStyle} />}

            <EntryName>{name}</EntryName>

            <Box id="entry-actions" style={{ marginLeft: token("space.050") }}>
              <Flex alignItems="center" columnGap="space.100">
                <Box
                  xcss={iconStyle}
                  onClick={() => {
                    setEditingEntry(entry);
                    setSelectedSidebarType(DrawerTypes.EDIT_ENTRY);
                    setIsOpen(true);
                    if (isParent) window.AP.events.emit("opened-menu-entry", { entryId: entry.id });
                  }}
                >
                  <EditorEditIcon primaryColor={token("color.icon.accent.gray")} />
                </Box>
                <Box
                  xcss={iconStyle}
                  onClick={() => {
                    setDeletingEntry(entry);
                    setModalType(ModalTypes.DELETE_ENTRY);
                    setModalVisible(true);
                  }}
                >
                  <EditorRemoveIcon primaryColor={token("color.icon.accent.gray")} />
                </Box>
              </Flex>
            </Box>
          </Grid>
        </Box>
      </EntryBox>

      {orderDirection === "bottom-top" && <Placeholder style={{ width, marginLeft }} isOver={isOver && canDrop} />}

      {!!entry?.children?.length && (
        <Box
          xcss={expandableChildrenStyle}
          style={{ maxHeight: isExpanded ? ITEM_HEIGHT * numberOfChildren + ITEM_GAP * numberOfChildren + 40 : 0 }}
        >
          <Flex direction="column">
            {entry.children.map((entry, idx) => (
              <MenuItem key={entry.id} entry={entry} itemLevel={itemLevel + 1} index={idx} />
            ))}
          </Flex>

          <Box style={{ width: `calc(100% - ${(itemLevel + 1) * 24}px)`, marginLeft: `${(itemLevel + 1) * 24}px` }}>
            <AddOption isChild parentEntry={entry} />
          </Box>
        </Box>
      )}

      {!!entry?.data?.spaces?.length && (
        <Box
          xcss={expandableChildrenStyle}
          style={{ maxHeight: isExpanded ? ITEM_HEIGHT * numberOfChildren + ITEM_GAP * numberOfChildren : 0 }}
        >
          <Flex direction="column">
            {entry.data.spaces.map((space, idx) => (
              <SpaceItem
                key={space.value}
                space={space}
                parentId={entry.id}
                itemLevel={itemLevel + 1}
                index={idx}
                reorderEntry={reorderEntry}
              />
            ))}
          </Flex>
        </Box>
      )}
    </>
  );
}

export default MenuItem;
