import React, { useCallback, useEffect, useState } from "react";
import { useCategoriesStore, useProposalsStore } from "../categories-store";
import { Divider } from "../../styled/sections";
import CategoryDetail from "./sections/CategoryDetails";
import { Wrapper } from "./styled";
import { getNumberOfBlogsInCategories, getUsersFromProposals } from "./helpers/datafetchers";
import Categories from "./sections/Categories";
import Proposals from "./sections/Proposals";
import EntryModal from "./modal/entry.modal";

function Content() {
  const [initializedProposals, setInitializedProposals] = useState(false);

  const { allCategories, selectedCategory, setCategoriesBlogCountData } = useCategoriesStore((state) => ({
    allCategories: state.allCategories,
    selectedCategory: state.selectedCategory,
    setCategoriesBlogCountData: state.setCategoriesBlogCountData,
  }));

  const { allProposals, setAllUsersInProposals } = useProposalsStore((state) => ({
    allProposals: state.allProposals,
    setAllUsersInProposals: state.setAllUsersInProposals,
  }));

  const fetchCategoriesNumberOfBlogs = useCallback(async () => {
    const categoryData = await getNumberOfBlogsInCategories(allCategories);
    setCategoriesBlogCountData([...categoryData]);
  }, [allCategories, setCategoriesBlogCountData]);

  useEffect(() => {
    if (allProposals?.length && !initializedProposals) {
      (async () => {
        const usersInProposals = await getUsersFromProposals(allProposals);
        setAllUsersInProposals([...usersInProposals]);
        setInitializedProposals(true);
      })();
    }
  }, [allProposals, initializedProposals, setAllUsersInProposals]);

  useEffect(() => {
    if (allCategories?.length) {
      fetchCategoriesNumberOfBlogs();
    }
  }, [allCategories, fetchCategoriesNumberOfBlogs]);

  useEffect(() => {
    window.AP.events.on("refetch-number-of-blogs", () => fetchCategoriesNumberOfBlogs());
  }, []);

  return (
    <Wrapper>
      {!selectedCategory ? (
        <>
          <Categories />

          {!!allProposals?.length && <Divider style={{ margin: "30px 0px" }} />}

          <Proposals />
        </>
      ) : (
        <CategoryDetail refetchCategoryDetails={fetchCategoriesNumberOfBlogs} />
      )}

      <EntryModal />
    </Wrapper>
  );
}

export default Content;
