import create from "zustand";
import { Pages } from "../pages";

export const DEFAULT_HEADER = {
  [Pages.PEOPLE]: {
    title: "People Network",
    description: "Find and connect with colleagues at the speed of light",
    imageUrl: "/images/hub/people-illustration.svg",
    background: {
      image: {
        source: "custom",
        link: "/images/hub/people-illustration.svg",
      },
      overlay: {},
    },
  },
  [Pages.NEWS]: {
    title: "Newsroom",
    description: "Explore and discover stellar knowledge in your Confluence",
    imageUrl: "/images/hub/newsroom-illustration.svg",
    background: {
      image: {
        source: "custom",
        link: "/images/hub/newsroom-illustration.svg",
      },
      overlay: {},
    },
  },
  [Pages.SPACE]: {
    title: "Space Station",
    description: "Explore the universe of ideas and discover new spaces",
    imageUrl: "/images/hub/space-illustration.svg",
    background: {
      image: {
        source: "custom",
        link: "/images/hub/space-illustration.svg",
      },
      overlay: {},
    },
  },
};

export const useHeaderStore = create((set, get) => ({
  headerData: DEFAULT_HEADER,
  setHeaderData: (headerData) => set((state) => ({ headerData })),
  updateHeaderData: (pageKey, value) => {
    const currentData = { ...get().headerData };
    const newData = { ...currentData, [pageKey]: { ...value } };
    return set((state) => ({ headerData: newData }));
  },
  isEditingHeader: false,
  setIsEditingHeader: (v) => set((state) => ({ isEditingHeader: v })),
}));
