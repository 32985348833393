import { useEffect, useState } from "react";

export const useAtlassianLocale = () => {
  const [locale, setLocale] = useState();

  useEffect(() => {
    window.AP.user.getLocale((loc) => setLocale(loc.replace("_", "-")));
  }, []);

  return [locale];
};
