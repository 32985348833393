import React, { useEffect, useRef } from "react";

import debounce from "lodash.debounce";

import { saveCosmosAppSettings } from "../api";
import { useCosmosAppStore } from "../cosmos-app-store";
import { useCosmosAppSettings } from "../use-cosmos-app-settings";
import { COSMOS_APP_KEY } from "../defaults";
import { usePageStore } from "../../../../page-store";
import { Pages } from "../../../../pages";
import {
  Content,
  ContentContainer,
  Divider,
  Header,
  ContentPanel,
  ContentHeader,
  PreviewContainer,
  Wrapper,
  ContentTitle,
  ContentSectionContainer,
  ContentSection,
  ContentSectionTitle,
  ContentSectionHeading,
  ContentSectionDescription,
} from "../styles";
import SelectWithCustomOptions from "../../../../../ContentBuilder/Shared/Filter/SelectWithCustomOptions";
import TitleWithBack from "../../../../../../Shared/Components/TitleWithBack";
import { useDatabaseInitialization } from "../../../../../../Shared/Hooks/useDatabaseInitialization";
import { useUserDatabaseFields } from "../../../../../../Shared/Hooks/useUserDatabaseFields";
import Phone from "../Shared/Phone";
import { SCREEN_TYPE } from "../Shared/PhoneScreens/defaults";

const People = () => {
  const setSelectedPage = usePageStore((state) => state.setSelectedPage);
  const { cosmosAppSettings, setCosmosAppSettings, updateCosmosAppSettings } = useCosmosAppStore((state) => ({
    cosmosAppSettings: state.cosmosAppSettings,
    setCosmosAppSettings: state.setCosmosAppSettings,
    updateCosmosAppSettings: state.updateCosmosAppSettings,
  }));

  const { isDatabaseLoading, isDatabaseInitialized } = useDatabaseInitialization();
  const { isLoadingCosmosAppSettings, cosmosAppSettingsData } = useCosmosAppSettings({ isDatabaseInitialized });
  const { isDatabaseFieldsLoading, userDatabaseFieldsData } = useUserDatabaseFields({ isDatabaseInitialized });

  useEffect(() => {
    if (!isLoadingCosmosAppSettings && cosmosAppSettingsData.exists) {
      setCosmosAppSettings(cosmosAppSettingsData.cosmosAppSettings);
    }
  }, [isDatabaseLoading, isLoadingCosmosAppSettings]);

  const handleSaveCosmosAppSettings = useRef(
    debounce((settings) => {
      saveCosmosAppSettings(settings);
    }, 250),
  ).current;

  useEffect(() => {
    return () => {
      handleSaveCosmosAppSettings.cancel();
    };
  }, [handleSaveCosmosAppSettings]);

  useEffect(() => {
    if (Object.keys(cosmosAppSettings || {}).length && !isLoadingCosmosAppSettings) {
      handleSaveCosmosAppSettings(cosmosAppSettings);
    }
  }, [cosmosAppSettings, isLoadingCosmosAppSettings, handleSaveCosmosAppSettings]);

  return (
    <Wrapper>
      <Header>
        <TitleWithBack
          handleOnClick={() => setSelectedPage(Pages.SETTINGS)}
          title="People"
          description="Select key database columns to display in people cards"
        />
      </Header>

      <Divider />

      <Content>
        <ContentContainer>
          <ContentPanel>
            <ContentHeader>
              <ContentTitle>Key Profile Details</ContentTitle>
            </ContentHeader>
            <ContentSectionContainer>
              <ContentSection>
                <ContentSectionHeading>
                  <ContentSectionTitle>Define main information</ContentSectionTitle>
                </ContentSectionHeading>
                <ContentSectionDescription>
                  Choose what to display under an employee's name, like job title, department, location, or other key
                  details. This information will also be shown as the main detail on the profile screen.
                </ContentSectionDescription>
                <SelectWithCustomOptions
                  customContainerStyle={{ marginTop: "32px" }}
                  label="Database Column"
                  placeholder="Select column"
                  loadingPlaceholder="Loading user database fields.."
                  isLoading={isDatabaseFieldsLoading || isLoadingCosmosAppSettings}
                  initialValue={cosmosAppSettings?.people?.databaseField}
                  options={userDatabaseFieldsData?.singleValueFields?.map((userDatabaseField) => ({
                    label: userDatabaseField.title,
                    value: userDatabaseField.id,
                  }))}
                  onUpdate={(databaseField) =>
                    updateCosmosAppSettings(COSMOS_APP_KEY.PEOPLE, { ...cosmosAppSettings?.people, databaseField })
                  }
                />
              </ContentSection>
            </ContentSectionContainer>
          </ContentPanel>
          <PreviewContainer>
            <Phone screenType={SCREEN_TYPE.PEOPLE} userDatabaseFieldsData={userDatabaseFieldsData?.singleValueFields} />
          </PreviewContainer>
        </ContentContainer>
      </Content>
    </Wrapper>
  );
};

export default People;
