import React, { useEffect, useState } from "react";
import Select from "@atlaskit/select";
import { Sorting } from "./sorting-types";
import { FieldContainer, Label } from "./styled";
import { selectStylesWithOverrides } from "./CustomSelectStyles";

const DefaultSortPicker = ({ initialValue, onUpdate }) => {
  const [selected, setSelected] = useState();

  const sortingOptions = [
    { label: "Title", value: Sorting.TITLE },
    { label: "Creation date", value: Sorting.CREATED },
    { label: "Modification date", value: Sorting.MODIFIED },
  ];

  useEffect(() => {
    if (initialValue) {
      const foundOption = sortingOptions.find((p) => p.value === initialValue);
      if (foundOption) {
        setSelected(foundOption);
      }
    }
  }, [initialValue]);

  return (
    <FieldContainer>
      <Label>Sort by</Label>
      <Select
        styles={selectStylesWithOverrides({
          option: (provided) => ({
            ...provided,
            fontSize: "14px",
          }),
        })}
        options={sortingOptions}
        value={selected}
        onChange={(newOption) => {
          onUpdate(newOption.value);
        }}
      />
    </FieldContainer>
  );
};

export default DefaultSortPicker;
