import styled, { useTheme } from "styled-components";
import Lozenge from "@atlaskit/lozenge";
import { SidePanel, SidePanelHeader } from "../General";

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const DetailsStatusContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const DetailsHeading = styled.h1`
  color: ${({ theme }) => theme.shared.settings.general.features.featureNameColor};
  margin: 0;
  font-weight: 400;
  font-size: 16px;
`;

const DetailsDescription = styled.p`
  color: ${({ theme }) => theme.shared.settings.general.features.featureDescriptionColor};
  font-size: 14px;
  margin: 0;
`;

const PageWrapper = styled.div`
  width: 100%;
  margin-top: 35px;
  display: flex;
`;

const PageIconContainer = styled.div`
  background: ${({ theme }) => theme.shared.settings.general.features.iconBackground};
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  color: #44546f;
  margin-right: 16px;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

function CustomPage({ id, name, activeDescription, Icon }) {
  return (
    <PageWrapper id={id}>
      <PageIconContainer>
        <Icon />
      </PageIconContainer>
      <DetailsContainer>
        <DetailsStatusContainer>
          <DetailsHeading>{name}</DetailsHeading>
          <Lozenge appearance="inprogress">Coming soon</Lozenge>
        </DetailsStatusContainer>
        <DetailsDescription>{activeDescription}</DetailsDescription>
      </DetailsContainer>
    </PageWrapper>
  );
}

function CustomPages({ pages }) {
  return (
    <SidePanel>
      <SidePanelHeader>Custom Pages</SidePanelHeader>

      <div style={{ paddingLeft: "25px", paddingRight: "25px", paddingBottom: "35px" }}>
        {pages.map(({ id, name, activeDescription, Icon }) => (
          <CustomPage key={id} id={id} name={name} activeDescription={activeDescription} Icon={Icon} />
        ))}
      </div>
    </SidePanel>
  );
}

export default CustomPages;
