import React from "react";
import { useTheme } from "styled-components";
import { THEME_NAME_LIGHT } from "../../../../../Navigation/functions/generateNewEntry";

function NoMatchingNews({ isInNavigation, previewTheme, isInPreview }) {
  const theme = useTheme();

  if (isInNavigation) {
    if (isInPreview) {
      if (previewTheme === THEME_NAME_LIGHT) {
        return (
          <svg width="160" height="160" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_3097_3108)">
              <g filter="url(#filter0_d_3097_3108)">
                <rect x="43" y="36" width="82" height="96" rx="12" fill="#E9F2FF" />
                <rect x="44" y="37" width="80" height="94" rx="11" stroke="#0C66E4" strokeWidth="2" />
              </g>
              <g filter="url(#filter1_dd_3097_3108)">
                <rect x="35" y="28" width="82" height="95" rx="12" fill="#E9F2FF" />
                <rect x="36" y="29" width="80" height="93" rx="11" stroke="#0C66E4" strokeWidth="2" />
              </g>
              <rect
                x="99.8096"
                y="61.6787"
                width="25.2381"
                height="3.80951"
                rx="1.90476"
                transform="rotate(-180 99.8096 61.6787)"
                fill="#579DFF"
              />
              <rect
                x="106"
                y="61.6787"
                width="4.28571"
                height="3.80952"
                rx="1.90476"
                transform="rotate(-180 106 61.6787)"
                fill="#579DFF"
              />
              <rect
                x="81.2383"
                y="96.917"
                width="35.238"
                height="3.80951"
                rx="1.90476"
                transform="rotate(-180 81.2383 96.917)"
                fill="#579DFF"
              />
              <rect
                x="89.8096"
                y="96.917"
                width="6.19047"
                height="3.80952"
                rx="1.90476"
                transform="rotate(-180 89.8096 96.917)"
                fill="#579DFF"
              />
              <rect
                x="106"
                y="68.8218"
                width="20"
                height="4.28571"
                rx="2.14285"
                transform="rotate(-180 106 68.8218)"
                fill="#579DFF"
              />
              <rect
                x="83.1611"
                y="68.6003"
                width="8.58916"
                height="3.9367"
                rx="1.96835"
                transform="rotate(-180 83.1611 68.6003)"
                fill="#579DFF"
              />
              <rect
                x="96.4766"
                y="104.06"
                width="39.0476"
                height="4.2857"
                rx="2.14285"
                transform="rotate(-180 96.4766 104.06)"
                fill="#579DFF"
              />
              <rect
                x="54.5889"
                y="103.838"
                width="8.58916"
                height="3.9367"
                rx="1.96835"
                transform="rotate(-180 54.5889 103.838)"
                fill="#579DFF"
              />
              <rect
                x="83.1611"
                y="82.2825"
                width="8.58916"
                height="3.9367"
                rx="1.96835"
                transform="rotate(-180 83.1611 82.2825)"
                fill="#579DFF"
              />
              <rect
                x="99.8096"
                y="75.4883"
                width="25.2381"
                height="3.80951"
                rx="1.90476"
                transform="rotate(-180 99.8096 75.4883)"
                fill="#579DFF"
              />
              <rect
                x="72.667"
                y="110.726"
                width="26.6666"
                height="3.80951"
                rx="1.90476"
                transform="rotate(-180 72.667 110.726)"
                fill="#579DFF"
              />
              <rect
                x="89.8887"
                y="89.7739"
                width="43.8888"
                height="4.2857"
                rx="2.14285"
                transform="rotate(-180 89.8887 89.7739)"
                fill="#579DFF"
              />
              <rect
                x="106"
                y="89.7739"
                width="12.2222"
                height="4.28571"
                rx="2.14285"
                transform="rotate(-180 106 89.7739)"
                fill="#579DFF"
              />
              <path
                fillRule="evenodd"
                clip-rule="evenodd"
                d="M51.4417 66.973C50.8861 66.4155 49.9842 66.4125 49.4249 66.9662L46.4235 69.9375C46.1525 70.2058 46 70.5713 46 70.9527V79.3076C46 81.1486 47.4924 82.641 49.3333 82.641H67.1904C69.0314 82.641 70.5238 81.1486 70.5238 79.3076V76.3725L64.8554 70.6856C64.2972 70.1255 63.3901 70.1255 62.8318 70.6856L59.9988 73.5279C59.4406 74.088 58.5335 74.088 57.9752 73.5279L51.4417 66.973Z"
                fill="#579DFF"
              />
              <circle cx="60.0626" cy="63.6808" r="2.77939" fill="#579DFF" />
              <path
                d="M46.2857 61.4119C46.2857 59.6132 47.7438 58.1551 49.5425 58.1551H67.2485C69.0472 58.1551 70.5053 59.6132 70.5053 61.4119V79.0888C70.5053 80.8874 69.0472 82.3455 67.2485 82.3455H49.5425C47.7438 82.3455 46.2857 80.8874 46.2857 79.0888V61.4119Z"
                stroke="#579DFF"
                strokeWidth="0.571428"
              />
              <path
                fillRule="evenodd"
                clip-rule="evenodd"
                d="M99.3336 53.1072C103.016 53.1072 106 50.1225 106 46.4406C106 42.7587 103.016 39.7739 99.3336 39.7739L53.1432 39.7739C49.4613 39.7739 46.4766 42.7587 46.4766 46.4406C46.4766 50.1225 49.4613 53.1072 53.1432 53.1072L99.3336 53.1072ZM91.2674 50.606C89.3939 50.606 88.16 49.723 88.0638 48.257L88.0581 48.1721H89.4561L89.4675 48.2401C89.575 48.9419 90.2938 49.3777 91.3183 49.3777C92.3484 49.3777 92.9937 48.9193 92.9937 48.2174V48.2118C92.9937 47.6005 92.5692 47.2722 91.5164 47.0514L90.6391 46.8703C89.0316 46.5364 88.2392 45.7892 88.2392 44.5723V44.5667C88.2449 43.129 89.5071 42.1555 91.2674 42.1555C93.0503 42.1555 94.2162 43.0951 94.3012 44.4931L94.3068 44.5893H92.9088L92.8975 44.5214C92.8012 43.8082 92.173 43.3837 91.256 43.3837C90.2995 43.3894 89.7052 43.8365 89.7052 44.4874V44.4931C89.7052 45.0704 90.1523 45.41 91.1372 45.6138L92.0201 45.7949C93.7182 46.1458 94.4596 46.8364 94.4596 48.1099V48.1155C94.4596 49.6381 93.2597 50.606 91.2674 50.606ZM77.7262 50.4645L75.5358 42.297H77.0527L78.4734 48.4608H78.5017L80.1374 42.297H81.4619L83.0976 48.4608H83.1259L84.5466 42.297H86.0635L83.8731 50.4645H82.4637L80.8166 44.6063H80.7827L79.1356 50.4645H77.7262ZM68.0283 42.297V50.4645H73.3148V49.2362H69.4886V46.9099H73.1054V45.727H69.4886V43.5252H73.3148V42.297H68.0283ZM58.3813 50.4645V42.297H59.6945L63.6735 48.0193H63.7074V42.297H65.1564V50.4645H63.8546L59.8643 44.7195H59.836V50.4645H58.3813Z"
                fill="#579DFF"
              />
              <rect width="80" height="160" transform="translate(80)" fill="url(#paint0_linear_3097_3108)" />
            </g>
            <defs>
              <filter
                id="filter0_d_3097_3108"
                x="21.1882"
                y="26.3059"
                width="125.624"
                height="139.624"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="12.1176" />
                <feGaussianBlur stdDeviation="10.9059" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0.403111 0 0 0 0 0.524415 0 0 0 0 0.709375 0 0 0 0.15 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3097_3108" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3097_3108" result="shape" />
              </filter>
              <filter
                id="filter1_dd_3097_3108"
                x="23"
                y="24"
                width="106"
                height="119"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="8" />
                <feGaussianBlur stdDeviation="6" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0.0352941 0 0 0 0 0.117647 0 0 0 0 0.258824 0 0 0 0.15 0"
                />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3097_3108" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset />
                <feGaussianBlur stdDeviation="0.5" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0.0352941 0 0 0 0 0.117647 0 0 0 0 0.258824 0 0 0 0.31 0"
                />
                <feBlend mode="normal" in2="effect1_dropShadow_3097_3108" result="effect2_dropShadow_3097_3108" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_3097_3108" result="shape" />
              </filter>
              <linearGradient
                id="paint0_linear_3097_3108"
                x1="0"
                y1="160"
                x2="80"
                y2="160"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="white" stopOpacity="0" />
                <stop offset="0.5" stopColor="white" />
              </linearGradient>
              <clipPath id="clip0_3097_3108">
                <rect width="160" height="160" fill="white" />
              </clipPath>
            </defs>
          </svg>
        );
      } else {
        return (
          <svg width="160" height="160" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_3125_814)">
              <g filter="url(#filter0_d_3125_814)">
                <rect x="43" y="36" width="82" height="96" rx="12" fill="#1C2B41" />
                <rect x="44" y="37" width="80" height="94" rx="11" stroke="#579DFF" strokeWidth="2" />
              </g>
              <g filter="url(#filter1_dd_3125_814)">
                <rect x="35" y="28" width="82" height="95" rx="12" fill="#1C2B41" />
                <rect x="36" y="29" width="80" height="93" rx="11" stroke="#579DFF" strokeWidth="2" />
              </g>
              <rect
                x="99.8096"
                y="61.6788"
                width="25.2381"
                height="3.80951"
                rx="1.90476"
                transform="rotate(-180 99.8096 61.6788)"
                fill="#0055CC"
              />
              <rect
                x="106"
                y="61.6788"
                width="4.28571"
                height="3.80952"
                rx="1.90476"
                transform="rotate(-180 106 61.6788)"
                fill="#0055CC"
              />
              <rect
                x="81.2383"
                y="96.917"
                width="35.238"
                height="3.80951"
                rx="1.90476"
                transform="rotate(-180 81.2383 96.917)"
                fill="#0055CC"
              />
              <rect
                x="89.8096"
                y="96.917"
                width="6.19047"
                height="3.80952"
                rx="1.90476"
                transform="rotate(-180 89.8096 96.917)"
                fill="#0055CC"
              />
              <rect
                x="106"
                y="68.8218"
                width="20"
                height="4.28571"
                rx="2.14285"
                transform="rotate(-180 106 68.8218)"
                fill="#0055CC"
              />
              <rect
                x="83.1611"
                y="68.6003"
                width="8.58916"
                height="3.9367"
                rx="1.96835"
                transform="rotate(-180 83.1611 68.6003)"
                fill="#0055CC"
              />
              <rect
                x="96.4766"
                y="104.06"
                width="39.0476"
                height="4.2857"
                rx="2.14285"
                transform="rotate(-180 96.4766 104.06)"
                fill="#0055CC"
              />
              <rect
                x="54.5889"
                y="103.838"
                width="8.58916"
                height="3.9367"
                rx="1.96835"
                transform="rotate(-180 54.5889 103.838)"
                fill="#0055CC"
              />
              <rect
                x="83.1611"
                y="82.2825"
                width="8.58916"
                height="3.9367"
                rx="1.96835"
                transform="rotate(-180 83.1611 82.2825)"
                fill="#0055CC"
              />
              <rect
                x="99.8096"
                y="75.4883"
                width="25.2381"
                height="3.80951"
                rx="1.90476"
                transform="rotate(-180 99.8096 75.4883)"
                fill="#0055CC"
              />
              <rect
                x="72.667"
                y="110.726"
                width="26.6666"
                height="3.80951"
                rx="1.90476"
                transform="rotate(-180 72.667 110.726)"
                fill="#0055CC"
              />
              <rect
                x="89.8887"
                y="89.7738"
                width="43.8888"
                height="4.2857"
                rx="2.14285"
                transform="rotate(-180 89.8887 89.7738)"
                fill="#0055CC"
              />
              <rect
                x="106"
                y="89.7738"
                width="12.2222"
                height="4.28571"
                rx="2.14285"
                transform="rotate(-180 106 89.7738)"
                fill="#0055CC"
              />
              <path
                fillRule="evenodd"
                clip-rule="evenodd"
                d="M51.4417 66.9728C50.8861 66.4154 49.9842 66.4124 49.4249 66.9661L46.4235 69.9373C46.1525 70.2056 46 70.5712 46 70.9526V79.3075C46 81.1485 47.4924 82.6408 49.3333 82.6408H67.1904C69.0314 82.6408 70.5238 81.1485 70.5238 79.3075V76.3723L64.8554 70.6854C64.2972 70.1254 63.3901 70.1254 62.8318 70.6855L59.9988 73.5277C59.4406 74.0878 58.5335 74.0878 57.9752 73.5277L51.4417 66.9728Z"
                fill="#0055CC"
              />
              <circle cx="60.0626" cy="63.6805" r="2.77939" fill="#0055CC" />
              <path
                d="M46.2857 61.4118C46.2857 59.6131 47.7438 58.155 49.5425 58.155H67.2485C69.0472 58.155 70.5053 59.6131 70.5053 61.4118V79.0886C70.5053 80.8873 69.0472 82.3454 67.2485 82.3454H49.5425C47.7438 82.3454 46.2857 80.8873 46.2857 79.0886V61.4118Z"
                stroke="#0055CC"
                strokeWidth="0.571428"
              />
              <path
                fillRule="evenodd"
                clip-rule="evenodd"
                d="M99.3336 53.1072C103.016 53.1072 106 50.1225 106 46.4406C106 42.7587 103.016 39.7739 99.3336 39.7739L53.1432 39.7739C49.4613 39.7739 46.4766 42.7587 46.4766 46.4406C46.4766 50.1225 49.4613 53.1072 53.1432 53.1072L99.3336 53.1072ZM91.2674 50.606C89.3939 50.606 88.16 49.723 88.0638 48.257L88.0581 48.1721H89.4561L89.4675 48.2401C89.575 48.9419 90.2938 49.3777 91.3183 49.3777C92.3484 49.3777 92.9937 48.9193 92.9937 48.2174V48.2118C92.9937 47.6005 92.5692 47.2722 91.5164 47.0514L90.6391 46.8703C89.0316 46.5364 88.2392 45.7892 88.2392 44.5723V44.5667C88.2449 43.129 89.5071 42.1555 91.2674 42.1555C93.0503 42.1555 94.2162 43.0951 94.3012 44.4931L94.3068 44.5893H92.9088L92.8975 44.5214C92.8012 43.8082 92.173 43.3837 91.256 43.3837C90.2995 43.3894 89.7052 43.8365 89.7052 44.4874V44.4931C89.7052 45.0704 90.1523 45.41 91.1372 45.6138L92.0201 45.7949C93.7182 46.1458 94.4596 46.8364 94.4596 48.1099V48.1155C94.4596 49.6381 93.2597 50.606 91.2674 50.606ZM77.7262 50.4645L75.5358 42.297H77.0527L78.4734 48.4608H78.5017L80.1374 42.297H81.4619L83.0976 48.4608H83.1259L84.5466 42.297H86.0635L83.8731 50.4645H82.4637L80.8166 44.6063H80.7827L79.1356 50.4645H77.7262ZM68.0283 42.297V50.4645H73.3148V49.2362H69.4886V46.9099H73.1054V45.727H69.4886V43.5252H73.3148V42.297H68.0283ZM58.3813 50.4645V42.297H59.6945L63.6735 48.0193H63.7074V42.297H65.1564V50.4645H63.8546L59.8643 44.7195H59.836V50.4645H58.3813Z"
                fill="#0055CC"
              />
              <rect width="80" height="160" transform="translate(80)" fill="url(#paint0_linear_3125_814)" />
            </g>
            <defs>
              <filter
                id="filter0_d_3125_814"
                x="21.1882"
                y="26.3059"
                width="125.624"
                height="139.624"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="12.1176" />
                <feGaussianBlur stdDeviation="10.9059" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0.403111 0 0 0 0 0.524415 0 0 0 0 0.709375 0 0 0 0.15 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3125_814" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3125_814" result="shape" />
              </filter>
              <filter
                id="filter1_dd_3125_814"
                x="23"
                y="24"
                width="106"
                height="119"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="8" />
                <feGaussianBlur stdDeviation="6" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0.0352941 0 0 0 0 0.117647 0 0 0 0 0.258824 0 0 0 0.15 0"
                />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3125_814" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset />
                <feGaussianBlur stdDeviation="0.5" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0.0352941 0 0 0 0 0.117647 0 0 0 0 0.258824 0 0 0 0.31 0"
                />
                <feBlend mode="normal" in2="effect1_dropShadow_3125_814" result="effect2_dropShadow_3125_814" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_3125_814" result="shape" />
              </filter>
              <linearGradient
                id="paint0_linear_3125_814"
                x1="0"
                y1="160"
                x2="80"
                y2="160"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#1D2125" stopOpacity="0" />
                <stop offset="0.64" stopColor="#1D2125" />
              </linearGradient>
              <clipPath id="clip0_3125_814">
                <rect width="160" height="160" fill="white" />
              </clipPath>
            </defs>
          </svg>
        );
      }
    }
    if (theme.global.name === THEME_NAME_LIGHT) {
      return (
        <svg width="160" height="160" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_3097_3108)">
            <g filter="url(#filter0_d_3097_3108)">
              <rect x="43" y="36" width="82" height="96" rx="12" fill="#E9F2FF" />
              <rect x="44" y="37" width="80" height="94" rx="11" stroke="#0C66E4" strokeWidth="2" />
            </g>
            <g filter="url(#filter1_dd_3097_3108)">
              <rect x="35" y="28" width="82" height="95" rx="12" fill="#E9F2FF" />
              <rect x="36" y="29" width="80" height="93" rx="11" stroke="#0C66E4" strokeWidth="2" />
            </g>
            <rect
              x="99.8096"
              y="61.6787"
              width="25.2381"
              height="3.80951"
              rx="1.90476"
              transform="rotate(-180 99.8096 61.6787)"
              fill="#579DFF"
            />
            <rect
              x="106"
              y="61.6787"
              width="4.28571"
              height="3.80952"
              rx="1.90476"
              transform="rotate(-180 106 61.6787)"
              fill="#579DFF"
            />
            <rect
              x="81.2383"
              y="96.917"
              width="35.238"
              height="3.80951"
              rx="1.90476"
              transform="rotate(-180 81.2383 96.917)"
              fill="#579DFF"
            />
            <rect
              x="89.8096"
              y="96.917"
              width="6.19047"
              height="3.80952"
              rx="1.90476"
              transform="rotate(-180 89.8096 96.917)"
              fill="#579DFF"
            />
            <rect
              x="106"
              y="68.8218"
              width="20"
              height="4.28571"
              rx="2.14285"
              transform="rotate(-180 106 68.8218)"
              fill="#579DFF"
            />
            <rect
              x="83.1611"
              y="68.6003"
              width="8.58916"
              height="3.9367"
              rx="1.96835"
              transform="rotate(-180 83.1611 68.6003)"
              fill="#579DFF"
            />
            <rect
              x="96.4766"
              y="104.06"
              width="39.0476"
              height="4.2857"
              rx="2.14285"
              transform="rotate(-180 96.4766 104.06)"
              fill="#579DFF"
            />
            <rect
              x="54.5889"
              y="103.838"
              width="8.58916"
              height="3.9367"
              rx="1.96835"
              transform="rotate(-180 54.5889 103.838)"
              fill="#579DFF"
            />
            <rect
              x="83.1611"
              y="82.2825"
              width="8.58916"
              height="3.9367"
              rx="1.96835"
              transform="rotate(-180 83.1611 82.2825)"
              fill="#579DFF"
            />
            <rect
              x="99.8096"
              y="75.4883"
              width="25.2381"
              height="3.80951"
              rx="1.90476"
              transform="rotate(-180 99.8096 75.4883)"
              fill="#579DFF"
            />
            <rect
              x="72.667"
              y="110.726"
              width="26.6666"
              height="3.80951"
              rx="1.90476"
              transform="rotate(-180 72.667 110.726)"
              fill="#579DFF"
            />
            <rect
              x="89.8887"
              y="89.7739"
              width="43.8888"
              height="4.2857"
              rx="2.14285"
              transform="rotate(-180 89.8887 89.7739)"
              fill="#579DFF"
            />
            <rect
              x="106"
              y="89.7739"
              width="12.2222"
              height="4.28571"
              rx="2.14285"
              transform="rotate(-180 106 89.7739)"
              fill="#579DFF"
            />
            <path
              fillRule="evenodd"
              clip-rule="evenodd"
              d="M51.4417 66.973C50.8861 66.4155 49.9842 66.4125 49.4249 66.9662L46.4235 69.9375C46.1525 70.2058 46 70.5713 46 70.9527V79.3076C46 81.1486 47.4924 82.641 49.3333 82.641H67.1904C69.0314 82.641 70.5238 81.1486 70.5238 79.3076V76.3725L64.8554 70.6856C64.2972 70.1255 63.3901 70.1255 62.8318 70.6856L59.9988 73.5279C59.4406 74.088 58.5335 74.088 57.9752 73.5279L51.4417 66.973Z"
              fill="#579DFF"
            />
            <circle cx="60.0626" cy="63.6808" r="2.77939" fill="#579DFF" />
            <path
              d="M46.2857 61.4119C46.2857 59.6132 47.7438 58.1551 49.5425 58.1551H67.2485C69.0472 58.1551 70.5053 59.6132 70.5053 61.4119V79.0888C70.5053 80.8874 69.0472 82.3455 67.2485 82.3455H49.5425C47.7438 82.3455 46.2857 80.8874 46.2857 79.0888V61.4119Z"
              stroke="#579DFF"
              strokeWidth="0.571428"
            />
            <path
              fillRule="evenodd"
              clip-rule="evenodd"
              d="M99.3336 53.1072C103.016 53.1072 106 50.1225 106 46.4406C106 42.7587 103.016 39.7739 99.3336 39.7739L53.1432 39.7739C49.4613 39.7739 46.4766 42.7587 46.4766 46.4406C46.4766 50.1225 49.4613 53.1072 53.1432 53.1072L99.3336 53.1072ZM91.2674 50.606C89.3939 50.606 88.16 49.723 88.0638 48.257L88.0581 48.1721H89.4561L89.4675 48.2401C89.575 48.9419 90.2938 49.3777 91.3183 49.3777C92.3484 49.3777 92.9937 48.9193 92.9937 48.2174V48.2118C92.9937 47.6005 92.5692 47.2722 91.5164 47.0514L90.6391 46.8703C89.0316 46.5364 88.2392 45.7892 88.2392 44.5723V44.5667C88.2449 43.129 89.5071 42.1555 91.2674 42.1555C93.0503 42.1555 94.2162 43.0951 94.3012 44.4931L94.3068 44.5893H92.9088L92.8975 44.5214C92.8012 43.8082 92.173 43.3837 91.256 43.3837C90.2995 43.3894 89.7052 43.8365 89.7052 44.4874V44.4931C89.7052 45.0704 90.1523 45.41 91.1372 45.6138L92.0201 45.7949C93.7182 46.1458 94.4596 46.8364 94.4596 48.1099V48.1155C94.4596 49.6381 93.2597 50.606 91.2674 50.606ZM77.7262 50.4645L75.5358 42.297H77.0527L78.4734 48.4608H78.5017L80.1374 42.297H81.4619L83.0976 48.4608H83.1259L84.5466 42.297H86.0635L83.8731 50.4645H82.4637L80.8166 44.6063H80.7827L79.1356 50.4645H77.7262ZM68.0283 42.297V50.4645H73.3148V49.2362H69.4886V46.9099H73.1054V45.727H69.4886V43.5252H73.3148V42.297H68.0283ZM58.3813 50.4645V42.297H59.6945L63.6735 48.0193H63.7074V42.297H65.1564V50.4645H63.8546L59.8643 44.7195H59.836V50.4645H58.3813Z"
              fill="#579DFF"
            />
            <rect width="80" height="160" transform="translate(80)" fill="url(#paint0_linear_3097_3108)" />
          </g>
          <defs>
            <filter
              id="filter0_d_3097_3108"
              x="21.1882"
              y="26.3059"
              width="125.624"
              height="139.624"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="12.1176" />
              <feGaussianBlur stdDeviation="10.9059" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0.403111 0 0 0 0 0.524415 0 0 0 0 0.709375 0 0 0 0.15 0" />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3097_3108" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3097_3108" result="shape" />
            </filter>
            <filter
              id="filter1_dd_3097_3108"
              x="23"
              y="24"
              width="106"
              height="119"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="8" />
              <feGaussianBlur stdDeviation="6" />
              <feColorMatrix type="matrix" values="0 0 0 0 0.0352941 0 0 0 0 0.117647 0 0 0 0 0.258824 0 0 0 0.15 0" />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3097_3108" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset />
              <feGaussianBlur stdDeviation="0.5" />
              <feColorMatrix type="matrix" values="0 0 0 0 0.0352941 0 0 0 0 0.117647 0 0 0 0 0.258824 0 0 0 0.31 0" />
              <feBlend mode="normal" in2="effect1_dropShadow_3097_3108" result="effect2_dropShadow_3097_3108" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_3097_3108" result="shape" />
            </filter>
            <linearGradient
              id="paint0_linear_3097_3108"
              x1="0"
              y1="160"
              x2="80"
              y2="160"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="white" stopOpacity="0" />
              <stop offset="0.5" stopColor="white" />
            </linearGradient>
            <clipPath id="clip0_3097_3108">
              <rect width="160" height="160" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
    } else {
      return (
        <svg width="160" height="160" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_3125_814)">
            <g filter="url(#filter0_d_3125_814)">
              <rect x="43" y="36" width="82" height="96" rx="12" fill="#1C2B41" />
              <rect x="44" y="37" width="80" height="94" rx="11" stroke="#579DFF" strokeWidth="2" />
            </g>
            <g filter="url(#filter1_dd_3125_814)">
              <rect x="35" y="28" width="82" height="95" rx="12" fill="#1C2B41" />
              <rect x="36" y="29" width="80" height="93" rx="11" stroke="#579DFF" strokeWidth="2" />
            </g>
            <rect
              x="99.8096"
              y="61.6788"
              width="25.2381"
              height="3.80951"
              rx="1.90476"
              transform="rotate(-180 99.8096 61.6788)"
              fill="#0055CC"
            />
            <rect
              x="106"
              y="61.6788"
              width="4.28571"
              height="3.80952"
              rx="1.90476"
              transform="rotate(-180 106 61.6788)"
              fill="#0055CC"
            />
            <rect
              x="81.2383"
              y="96.917"
              width="35.238"
              height="3.80951"
              rx="1.90476"
              transform="rotate(-180 81.2383 96.917)"
              fill="#0055CC"
            />
            <rect
              x="89.8096"
              y="96.917"
              width="6.19047"
              height="3.80952"
              rx="1.90476"
              transform="rotate(-180 89.8096 96.917)"
              fill="#0055CC"
            />
            <rect
              x="106"
              y="68.8218"
              width="20"
              height="4.28571"
              rx="2.14285"
              transform="rotate(-180 106 68.8218)"
              fill="#0055CC"
            />
            <rect
              x="83.1611"
              y="68.6003"
              width="8.58916"
              height="3.9367"
              rx="1.96835"
              transform="rotate(-180 83.1611 68.6003)"
              fill="#0055CC"
            />
            <rect
              x="96.4766"
              y="104.06"
              width="39.0476"
              height="4.2857"
              rx="2.14285"
              transform="rotate(-180 96.4766 104.06)"
              fill="#0055CC"
            />
            <rect
              x="54.5889"
              y="103.838"
              width="8.58916"
              height="3.9367"
              rx="1.96835"
              transform="rotate(-180 54.5889 103.838)"
              fill="#0055CC"
            />
            <rect
              x="83.1611"
              y="82.2825"
              width="8.58916"
              height="3.9367"
              rx="1.96835"
              transform="rotate(-180 83.1611 82.2825)"
              fill="#0055CC"
            />
            <rect
              x="99.8096"
              y="75.4883"
              width="25.2381"
              height="3.80951"
              rx="1.90476"
              transform="rotate(-180 99.8096 75.4883)"
              fill="#0055CC"
            />
            <rect
              x="72.667"
              y="110.726"
              width="26.6666"
              height="3.80951"
              rx="1.90476"
              transform="rotate(-180 72.667 110.726)"
              fill="#0055CC"
            />
            <rect
              x="89.8887"
              y="89.7738"
              width="43.8888"
              height="4.2857"
              rx="2.14285"
              transform="rotate(-180 89.8887 89.7738)"
              fill="#0055CC"
            />
            <rect
              x="106"
              y="89.7738"
              width="12.2222"
              height="4.28571"
              rx="2.14285"
              transform="rotate(-180 106 89.7738)"
              fill="#0055CC"
            />
            <path
              fillRule="evenodd"
              clip-rule="evenodd"
              d="M51.4417 66.9728C50.8861 66.4154 49.9842 66.4124 49.4249 66.9661L46.4235 69.9373C46.1525 70.2056 46 70.5712 46 70.9526V79.3075C46 81.1485 47.4924 82.6408 49.3333 82.6408H67.1904C69.0314 82.6408 70.5238 81.1485 70.5238 79.3075V76.3723L64.8554 70.6854C64.2972 70.1254 63.3901 70.1254 62.8318 70.6855L59.9988 73.5277C59.4406 74.0878 58.5335 74.0878 57.9752 73.5277L51.4417 66.9728Z"
              fill="#0055CC"
            />
            <circle cx="60.0626" cy="63.6805" r="2.77939" fill="#0055CC" />
            <path
              d="M46.2857 61.4118C46.2857 59.6131 47.7438 58.155 49.5425 58.155H67.2485C69.0472 58.155 70.5053 59.6131 70.5053 61.4118V79.0886C70.5053 80.8873 69.0472 82.3454 67.2485 82.3454H49.5425C47.7438 82.3454 46.2857 80.8873 46.2857 79.0886V61.4118Z"
              stroke="#0055CC"
              strokeWidth="0.571428"
            />
            <path
              fillRule="evenodd"
              clip-rule="evenodd"
              d="M99.3336 53.1072C103.016 53.1072 106 50.1225 106 46.4406C106 42.7587 103.016 39.7739 99.3336 39.7739L53.1432 39.7739C49.4613 39.7739 46.4766 42.7587 46.4766 46.4406C46.4766 50.1225 49.4613 53.1072 53.1432 53.1072L99.3336 53.1072ZM91.2674 50.606C89.3939 50.606 88.16 49.723 88.0638 48.257L88.0581 48.1721H89.4561L89.4675 48.2401C89.575 48.9419 90.2938 49.3777 91.3183 49.3777C92.3484 49.3777 92.9937 48.9193 92.9937 48.2174V48.2118C92.9937 47.6005 92.5692 47.2722 91.5164 47.0514L90.6391 46.8703C89.0316 46.5364 88.2392 45.7892 88.2392 44.5723V44.5667C88.2449 43.129 89.5071 42.1555 91.2674 42.1555C93.0503 42.1555 94.2162 43.0951 94.3012 44.4931L94.3068 44.5893H92.9088L92.8975 44.5214C92.8012 43.8082 92.173 43.3837 91.256 43.3837C90.2995 43.3894 89.7052 43.8365 89.7052 44.4874V44.4931C89.7052 45.0704 90.1523 45.41 91.1372 45.6138L92.0201 45.7949C93.7182 46.1458 94.4596 46.8364 94.4596 48.1099V48.1155C94.4596 49.6381 93.2597 50.606 91.2674 50.606ZM77.7262 50.4645L75.5358 42.297H77.0527L78.4734 48.4608H78.5017L80.1374 42.297H81.4619L83.0976 48.4608H83.1259L84.5466 42.297H86.0635L83.8731 50.4645H82.4637L80.8166 44.6063H80.7827L79.1356 50.4645H77.7262ZM68.0283 42.297V50.4645H73.3148V49.2362H69.4886V46.9099H73.1054V45.727H69.4886V43.5252H73.3148V42.297H68.0283ZM58.3813 50.4645V42.297H59.6945L63.6735 48.0193H63.7074V42.297H65.1564V50.4645H63.8546L59.8643 44.7195H59.836V50.4645H58.3813Z"
              fill="#0055CC"
            />
            <rect width="80" height="160" transform="translate(80)" fill="url(#paint0_linear_3125_814)" />
          </g>
          <defs>
            <filter
              id="filter0_d_3125_814"
              x="21.1882"
              y="26.3059"
              width="125.624"
              height="139.624"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="12.1176" />
              <feGaussianBlur stdDeviation="10.9059" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0.403111 0 0 0 0 0.524415 0 0 0 0 0.709375 0 0 0 0.15 0" />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3125_814" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3125_814" result="shape" />
            </filter>
            <filter
              id="filter1_dd_3125_814"
              x="23"
              y="24"
              width="106"
              height="119"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="8" />
              <feGaussianBlur stdDeviation="6" />
              <feColorMatrix type="matrix" values="0 0 0 0 0.0352941 0 0 0 0 0.117647 0 0 0 0 0.258824 0 0 0 0.15 0" />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3125_814" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset />
              <feGaussianBlur stdDeviation="0.5" />
              <feColorMatrix type="matrix" values="0 0 0 0 0.0352941 0 0 0 0 0.117647 0 0 0 0 0.258824 0 0 0 0.31 0" />
              <feBlend mode="normal" in2="effect1_dropShadow_3125_814" result="effect2_dropShadow_3125_814" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_3125_814" result="shape" />
            </filter>
            <linearGradient id="paint0_linear_3125_814" x1="0" y1="160" x2="80" y2="160" gradientUnits="userSpaceOnUse">
              <stop stopColor="#1D2125" stopOpacity="0" />
              <stop offset="0.64" stopColor="#1D2125" />
            </linearGradient>
            <clipPath id="clip0_3125_814">
              <rect width="160" height="160" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
    }
  }

  if (theme.global.name === THEME_NAME_LIGHT) {
    return (
      <svg width="160" height="156" viewBox="0 0 160 156" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_3193_3596)">
          <mask
            id="mask0_3193_3596"
            style={{ maskType: "alpha" }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="160"
            height="160"
          >
            <rect
              width="160"
              height="160"
              transform="matrix(1 1.74846e-07 1.74846e-07 -1 0 160)"
              fill="url(#paint0_linear_3193_3596)"
            />
          </mask>
          <g mask="url(#mask0_3193_3596)">
            <g filter="url(#filter0_d_3193_3596)">
              <rect x="37.707" y="30.708" width="92.2931" height="107.966" rx="14.0201" fill="#E9F2FF" />
              <rect
                x="38.707"
                y="31.708"
                width="90.2931"
                height="105.966"
                rx="13.0201"
                stroke="#0C66E4"
                strokeWidth="2"
              />
            </g>
            <g filter="url(#filter1_d_3193_3596)">
              <rect x="29" y="22" width="92.2931" height="107.966" rx="14.0201" fill="#E9F2FF" />
              <rect x="30" y="23" width="90.2931" height="105.966" rx="13.0201" stroke="#0C66E4" strokeWidth="2" />
            </g>
            <rect
              x="111.426"
              y="48.7012"
              width="72.5575"
              height="16.2529"
              rx="8.12643"
              transform="rotate(-180 111.426 48.7012)"
              fill="#579DFF"
            />
            <rect
              x="103.88"
              y="59.1504"
              width="30.7644"
              height="4.64367"
              rx="2.32184"
              transform="rotate(-180 103.88 59.1504)"
              fill="#579DFF"
            />
            <rect
              x="111.426"
              y="59.1504"
              width="5.22414"
              height="4.64368"
              rx="2.32184"
              transform="rotate(-180 111.426 59.1504)"
              fill="#579DFF"
            />
            <rect
              x="81.2412"
              y="102.104"
              width="42.954"
              height="4.64367"
              rx="2.32184"
              transform="rotate(-180 81.2412 102.104)"
              fill="#579DFF"
            />
            <rect
              x="91.6895"
              y="102.104"
              width="7.54598"
              height="4.64368"
              rx="2.32184"
              transform="rotate(-180 91.6895 102.104)"
              fill="#579DFF"
            />
            <rect
              x="111.425"
              y="67.8564"
              width="24.3793"
              height="5.22414"
              rx="2.61207"
              transform="rotate(-180 111.425 67.8564)"
              fill="#579DFF"
            />
            <rect
              x="83.584"
              y="67.5859"
              width="10.4699"
              height="4.79871"
              rx="2.39935"
              transform="rotate(-180 83.584 67.5859)"
              fill="#579DFF"
            />
            <rect
              x="99.8154"
              y="110.811"
              width="47.5977"
              height="5.22413"
              rx="2.61207"
              transform="rotate(-180 99.8154 110.811)"
              fill="#579DFF"
            />
            <rect
              x="48.7578"
              y="110.541"
              width="10.4699"
              height="4.79871"
              rx="2.39935"
              transform="rotate(-180 48.7578 110.541)"
              fill="#579DFF"
            />
            <rect
              x="83.584"
              y="84.2646"
              width="10.4699"
              height="4.79871"
              rx="2.39935"
              transform="rotate(-180 83.584 84.2646)"
              fill="#579DFF"
            />
            <rect
              x="103.88"
              y="75.9834"
              width="30.7644"
              height="4.64368"
              rx="2.32184"
              transform="rotate(-180 103.88 75.9834)"
              fill="#579DFF"
            />
            <rect
              x="70.793"
              y="118.938"
              width="32.5057"
              height="4.64368"
              rx="2.32184"
              transform="rotate(-180 70.793 118.938)"
              fill="#579DFF"
            />
            <rect
              x="91.7852"
              y="93.3965"
              width="53.499"
              height="5.22413"
              rx="2.61207"
              transform="rotate(-180 91.7852 93.3965)"
              fill="#579DFF"
            />
            <rect
              x="111.424"
              y="93.3965"
              width="14.8985"
              height="5.22414"
              rx="2.61207"
              transform="rotate(-180 111.424 93.3965)"
              fill="#579DFF"
            />
            <path
              fillRule="evenodd"
              clip-rule="evenodd"
              d="M45.8059 66.4916C44.6391 65.3209 42.7451 65.3146 41.5705 66.4774L39.1765 68.8474C38.6073 69.4109 38.2871 70.1785 38.2871 70.9794V77.702C38.2871 81.568 41.4211 84.702 45.2871 84.702H61.1808C65.0468 84.702 68.1808 81.568 68.1808 77.702V77.0609L62.1627 71.0231C60.9903 69.8469 59.0855 69.8469 57.9131 71.0231L56.2427 72.699C55.0703 73.8752 53.1655 73.8752 51.9931 72.699L45.8059 66.4916Z"
              fill="#579DFF"
            />
            <circle cx="55.429" cy="61.5892" r="3.38799" fill="#579DFF" />
            <path
              d="M38.8871 58.824C38.8871 56.7705 40.5518 55.1059 42.6053 55.1059H64.1884C66.2419 55.1059 67.9066 56.7705 67.9066 58.824V80.3716C67.9066 82.4251 66.2419 84.0898 64.1884 84.0898H42.6053C40.5518 84.0898 38.8871 82.4251 38.8871 80.3716V58.824Z"
              stroke="#579DFF"
              strokeWidth="1.2"
            />
            <path
              fillRule="evenodd"
              clip-rule="evenodd"
              d="M89.5618 42.7882C89.6791 44.5751 91.1832 45.6514 93.4669 45.6514C95.8955 45.6514 97.3582 44.4716 97.3582 42.6157V42.6088C97.3582 41.0564 96.4543 40.2147 94.3845 39.7869L93.3082 39.5661C92.1077 39.3178 91.5626 38.9038 91.5626 38.2001V38.1932C91.5626 37.3997 92.2871 36.8547 93.4531 36.8478C94.5708 36.8478 95.3366 37.3652 95.4539 38.2346L95.4677 38.3173H97.1719L97.165 38.2001C97.0615 36.4959 95.6402 35.3506 93.4669 35.3506C91.3212 35.3506 89.7826 36.5373 89.7757 38.2897V38.2966C89.7757 39.78 90.7416 40.6908 92.7011 41.0978L93.7705 41.3186C95.0538 41.5877 95.5712 41.9878 95.5712 42.733V42.7399C95.5712 43.5954 94.7847 44.1543 93.529 44.1543C92.2802 44.1543 91.404 43.623 91.2729 42.7675L91.2591 42.6847H89.5549L89.5618 42.7882ZM74.2906 35.5231L76.9607 45.479H78.6787L80.6864 38.338H80.7278L82.7355 45.479H84.4535L87.1236 35.5231H85.2745L83.5428 43.0366H83.5083L81.5143 35.5231H79.8999L77.9059 43.0366H77.8714L76.1397 35.5231H74.2906ZM65.1393 45.479V35.5231H71.5833V37.0202H66.9193V39.7041H71.328V41.1461H66.9193V43.9818H71.5833V45.479H65.1393ZM53.3799 35.5231V45.479H55.153V38.476H55.1875L60.0516 45.479H61.6385V35.5231H59.8723V42.4984H59.8309L54.9806 35.5231H53.3799Z"
              fill="#F3FBFF"
            />
          </g>
        </g>
        <defs>
          <filter
            id="filter0_d_3193_3596"
            x="15.8953"
            y="21.0139"
            width="135.916"
            height="151.589"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="12.1176" />
            <feGaussianBlur stdDeviation="10.9059" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.403111 0 0 0 0 0.524415 0 0 0 0 0.709375 0 0 0 0.15 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3193_3596" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3193_3596" result="shape" />
          </filter>
          <filter
            id="filter1_d_3193_3596"
            x="7.18824"
            y="12.3059"
            width="135.916"
            height="151.589"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="12.1176" />
            <feGaussianBlur stdDeviation="10.9059" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.403111 0 0 0 0 0.524415 0 0 0 0 0.709375 0 0 0 0.15 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3193_3596" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3193_3596" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear_3193_3596"
            x1="59.5973"
            y1="160"
            x2="133.691"
            y2="160"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </linearGradient>
          <clipPath id="clip0_3193_3596">
            <rect width="160" height="155.98" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  } else {
    return (
      <svg width="160" height="156" viewBox="0 0 160 156" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_3193_3601)">
          <mask
            id="mask0_3193_3601"
            style={{ maskType: "alpha" }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="160"
            height="160"
          >
            <rect
              width="160"
              height="160"
              transform="matrix(1 1.74846e-07 1.74846e-07 -1 0 160)"
              fill="url(#paint0_linear_3193_3601)"
            />
          </mask>
          <g mask="url(#mask0_3193_3601)">
            <g filter="url(#filter0_d_3193_3601)">
              <rect x="37.707" y="30.708" width="92.2931" height="107.966" rx="14.0201" fill="#1C2B41" />
              <rect
                x="38.707"
                y="31.708"
                width="90.2931"
                height="105.966"
                rx="13.0201"
                stroke="#579DFF"
                strokeWidth="2"
              />
            </g>
            <g filter="url(#filter1_d_3193_3601)">
              <rect x="29" y="22" width="92.2931" height="107.966" rx="14.0201" fill="#1C2B41" />
              <rect x="30" y="23" width="90.2931" height="105.966" rx="13.0201" stroke="#579DFF" strokeWidth="2" />
            </g>
            <rect
              x="111.426"
              y="48.7012"
              width="72.5575"
              height="16.2529"
              rx="8.12643"
              transform="rotate(-180 111.426 48.7012)"
              fill="#0055CC"
            />
            <rect
              x="103.88"
              y="59.1504"
              width="30.7644"
              height="4.64367"
              rx="2.32184"
              transform="rotate(-180 103.88 59.1504)"
              fill="#0055CC"
            />
            <rect
              x="111.426"
              y="59.1504"
              width="5.22414"
              height="4.64368"
              rx="2.32184"
              transform="rotate(-180 111.426 59.1504)"
              fill="#0055CC"
            />
            <rect
              x="81.2412"
              y="102.104"
              width="42.954"
              height="4.64367"
              rx="2.32184"
              transform="rotate(-180 81.2412 102.104)"
              fill="#0055CC"
            />
            <rect
              x="91.6895"
              y="102.104"
              width="7.54598"
              height="4.64368"
              rx="2.32184"
              transform="rotate(-180 91.6895 102.104)"
              fill="#0055CC"
            />
            <rect
              x="111.425"
              y="67.8564"
              width="24.3793"
              height="5.22414"
              rx="2.61207"
              transform="rotate(-180 111.425 67.8564)"
              fill="#0055CC"
            />
            <rect
              x="83.584"
              y="67.5859"
              width="10.4699"
              height="4.79871"
              rx="2.39935"
              transform="rotate(-180 83.584 67.5859)"
              fill="#0055CC"
            />
            <rect
              x="99.8154"
              y="110.811"
              width="47.5977"
              height="5.22413"
              rx="2.61207"
              transform="rotate(-180 99.8154 110.811)"
              fill="#0055CC"
            />
            <rect
              x="48.7578"
              y="110.541"
              width="10.4699"
              height="4.79871"
              rx="2.39935"
              transform="rotate(-180 48.7578 110.541)"
              fill="#0055CC"
            />
            <rect
              x="83.584"
              y="84.2646"
              width="10.4699"
              height="4.79871"
              rx="2.39935"
              transform="rotate(-180 83.584 84.2646)"
              fill="#0055CC"
            />
            <rect
              x="103.88"
              y="75.9834"
              width="30.7644"
              height="4.64368"
              rx="2.32184"
              transform="rotate(-180 103.88 75.9834)"
              fill="#0055CC"
            />
            <rect
              x="70.793"
              y="118.938"
              width="32.5057"
              height="4.64368"
              rx="2.32184"
              transform="rotate(-180 70.793 118.938)"
              fill="#0055CC"
            />
            <rect
              x="91.7852"
              y="93.3965"
              width="53.499"
              height="5.22413"
              rx="2.61207"
              transform="rotate(-180 91.7852 93.3965)"
              fill="#0055CC"
            />
            <rect
              x="111.424"
              y="93.3965"
              width="14.8985"
              height="5.22414"
              rx="2.61207"
              transform="rotate(-180 111.424 93.3965)"
              fill="#0055CC"
            />
            <path
              fillRule="evenodd"
              clip-rule="evenodd"
              d="M45.8059 66.4916C44.6391 65.3209 42.7451 65.3146 41.5705 66.4774L39.1765 68.8474C38.6073 69.4109 38.2871 70.1785 38.2871 70.9794V77.702C38.2871 81.568 41.4211 84.702 45.2871 84.702H61.1808C65.0468 84.702 68.1808 81.568 68.1808 77.702V77.0609L62.1627 71.0231C60.9903 69.8469 59.0855 69.8469 57.9131 71.0231L56.2427 72.699C55.0703 73.8752 53.1655 73.8752 51.9931 72.699L45.8059 66.4916Z"
              fill="#0055CC"
            />
            <circle cx="55.429" cy="61.5892" r="3.38799" fill="#0055CC" />
            <path
              d="M38.8871 58.824C38.8871 56.7705 40.5518 55.1059 42.6053 55.1059H64.1884C66.2419 55.1059 67.9066 56.7705 67.9066 58.824V80.3716C67.9066 82.4251 66.2419 84.0898 64.1884 84.0898H42.6053C40.5518 84.0898 38.8871 82.4251 38.8871 80.3716V58.824Z"
              stroke="#0055CC"
              strokeWidth="1.2"
            />
            <path
              fillRule="evenodd"
              clip-rule="evenodd"
              d="M89.5618 42.7882C89.6791 44.5751 91.1832 45.6514 93.4669 45.6514C95.8955 45.6514 97.3582 44.4716 97.3582 42.6157V42.6088C97.3582 41.0564 96.4543 40.2147 94.3845 39.7869L93.3082 39.5661C92.1077 39.3178 91.5626 38.9038 91.5626 38.2001V38.1932C91.5626 37.3997 92.2871 36.8547 93.4531 36.8478C94.5708 36.8478 95.3366 37.3652 95.4539 38.2346L95.4677 38.3173H97.1719L97.165 38.2001C97.0615 36.4959 95.6402 35.3506 93.4669 35.3506C91.3212 35.3506 89.7826 36.5373 89.7757 38.2897V38.2966C89.7757 39.78 90.7416 40.6908 92.7011 41.0978L93.7705 41.3186C95.0538 41.5877 95.5712 41.9878 95.5712 42.733V42.7399C95.5712 43.5954 94.7847 44.1543 93.529 44.1543C92.2802 44.1543 91.404 43.623 91.2729 42.7675L91.2591 42.6847H89.5549L89.5618 42.7882ZM74.2906 35.5231L76.9607 45.479H78.6787L80.6864 38.338H80.7278L82.7355 45.479H84.4535L87.1236 35.5231H85.2745L83.5428 43.0366H83.5083L81.5143 35.5231H79.8999L77.9059 43.0366H77.8714L76.1397 35.5231H74.2906ZM65.1393 45.479V35.5231H71.5833V37.0202H66.9193V39.7041H71.328V41.1461H66.9193V43.9818H71.5833V45.479H65.1393ZM53.3799 35.5231V45.479H55.153V38.476H55.1875L60.0516 45.479H61.6385V35.5231H59.8723V42.4984H59.8309L54.9806 35.5231H53.3799Z"
              fill="#F3FBFF"
            />
          </g>
        </g>
        <defs>
          <filter
            id="filter0_d_3193_3601"
            x="15.8953"
            y="21.0139"
            width="135.916"
            height="151.589"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="12.1176" />
            <feGaussianBlur stdDeviation="10.9059" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.403111 0 0 0 0 0.524415 0 0 0 0 0.709375 0 0 0 0.15 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3193_3601" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3193_3601" result="shape" />
          </filter>
          <filter
            id="filter1_d_3193_3601"
            x="7.18824"
            y="12.3059"
            width="135.916"
            height="151.589"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="12.1176" />
            <feGaussianBlur stdDeviation="10.9059" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.403111 0 0 0 0 0.524415 0 0 0 0 0.709375 0 0 0 0.15 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3193_3601" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3193_3601" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear_3193_3601"
            x1="59.5973"
            y1="160"
            x2="133.691"
            y2="160"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </linearGradient>
          <clipPath id="clip0_3193_3601">
            <rect width="160" height="155.98" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  }
}

export default React.memo(NoMatchingNews);
