import React from "react";
import { useNavigationStore } from "../../../../../store";
import TextIcon from "@atlaskit/icon/glyph/editor/text-style";
import ImageIcon from "@atlaskit/icon/glyph/image";
import { CloudIcon } from "../shared/Icons";
import { PARENT_ENTRY_IDENTIFIER } from "../../../../../defaults";
import { SidebarTabs, TabTypes } from "../tabs";
import { Box, Flex } from "@atlaskit/primitives";
import NativeTabs from "../../../../../../../Shared/Components/NativeTabs";
import IconTitle from "../shared/IconTitle";
import { FieldContainer, FieldLabel } from "../shared/Components";
import SizeButtons from "../../../../../../../Shared/Components/SizeButtons";
import { sizeType } from "../../../../../../../Shared/Components/size-options";
import Design from "./tabs/DesignTab";
import TypeSwitcher from "../shared/TypeSwitcher";
import LinkConfig from "../shared/LinkConfig";

const MenuTypes = [
  {
    label: "",
    hasIcon: true,
    value: "text",
    SelectedIcon: TextIcon,
    DefaultIcon: TextIcon,
  },
  {
    label: "",
    hasIcon: true,
    value: "icon",
    SelectedIcon: CloudIcon,
    DefaultIcon: CloudIcon,
  },
  {
    label: "",
    hasIcon: true,
    SelectedIcon: ImageIcon,
    DefaultIcon: ImageIcon,
    value: "image",
  },
];

function Denver() {
  const { editingEntry, updateEntry } = useNavigationStore((state) => ({
    editingEntry: state.editingEntry,
    updateEntry: state.updateEntry,
  }));

  const isParent = editingEntry.parent === PARENT_ENTRY_IDENTIFIER;

  const [selectedTab, setSelectedTab] = React.useState(TabTypes.LAYOUT);

  return (
    <Box>
      {isParent && <NativeTabs tabs={SidebarTabs} useNative={false} returnSelection={setSelectedTab} />}

      <Box paddingBlockStart={isParent ? "space.300" : "space.0"}>
        {selectedTab === TabTypes.LAYOUT && (
          <>
            <Flex direction="column">
              <IconTitle />
            </Flex>

            {isParent && (
              <FieldContainer>
                <FieldLabel>Type</FieldLabel>
                <TypeSwitcher
                  options={MenuTypes}
                  onClick={(value) => updateEntry(editingEntry.id, "type", value, true)}
                  activeType={editingEntry.data.type}
                />
              </FieldContainer>
            )}

            {editingEntry?.data?.type === "image" && isParent && (
              <FieldContainer style={{ marginTop: "20px" }}>
                <FieldLabel>Image size</FieldLabel>

                <SizeButtons
                  types={{ panel: "denver", size: sizeType.IMAGE_SIZE }}
                  input={{
                    value: editingEntry?.data?.imageSize || 120,
                    min: 60,
                    max: 200,
                  }}
                  setValue={(value) => updateEntry(editingEntry.id, "imageSize", value, true)}
                  customStyles={{ marginTop: 0 }}
                />
              </FieldContainer>
            )}

            {!isParent && <LinkConfig entry={editingEntry} updateEntry={updateEntry} />}
          </>
        )}

        {selectedTab === TabTypes.DESIGN && <Design />}
      </Box>
    </Box>
  );
}

export default Denver;
