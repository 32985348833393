import React, { useEffect, useState } from "react";
import shallow from "zustand/shallow";
import { CreatableSelect } from "@atlaskit/select";
import { nanoid } from "nanoid";
import { useRecordsStore } from "../../../../../../UserDatabase/records-store";
import { useTheme } from "styled-components";

export const OptionColors = [
  "#FBD7D4",
  "#FFE6CE",
  "#FFF5C5",
  "#D1FBE0",
  "#C6FFF3",
  "#C3FAFF",
  "#E0EEFF",
  "#EAE6FF",
  "#FFE1FF",
  "#F1F1F6",
];

const SelectEditor = ({ initialValue, field, onEdit, onAbort, onSelect = () => {} }) => {
  const options = field.options || [];

  const [selected, setSelected] = useState();
  const [availableOptions, setAvailableOptions] = useState([]);

  const updateField = useRecordsStore((state) => state.updateField, shallow);

  const theme = useTheme();

  const createOption = (value) => {
    const handleCreate = (label) => {
      const option = {
        id: nanoid(7),
        color: OptionColors[Number(`${options.length}`.slice(-1))],
        label: label,
        position: options.length,
      };

      updateField(field.id, { options: [...options, option] });

      return option;
    };

    if (!value?.id) {
      const opt = handleCreate(value.label);
      setAvailableOptions([...availableOptions, { ...opt, value: opt.id }]);
      setSelected({ ...opt, value: opt.id });
      return opt;
    }

    return value;
  };

  useEffect(() => {
    if (options) {
      const mappedOptions = options.map((opt) => ({ ...opt, value: opt.id }));

      if (initialValue) {
        const value = mappedOptions.find(({ id }) => id === initialValue);

        if (value) {
          setSelected(value);
        }
      } else {
        setSelected();
      }

      setAvailableOptions(mappedOptions);
    }
  }, []);

  return (
    <CreatableSelect
      value={selected}
      autoFocus
      openMenuOnFocus
      isClearable={false}
      closeMenuOnSelect
      isValidNewOption={(value) => {
        if (
          value.trim().length === 0 ||
          availableOptions.find(({ label }) => label.toLowerCase() === value.toLowerCase())
        ) {
          return false;
        }

        return field.isCreatable;
      }}
      options={availableOptions}
      styles={{
        control: (provided) => ({
          backgroundColor: `${theme.shared.profile.selectBackground} !important`,
          fontSize: "14px",
          fontWeight: "400",
          lineHeight: 1,
          width: "200px",
          minHeight: "32px",
          maxHeight: "32px",
          color: "#42526E",
          borderColor: "transparent !important",
          borderRadius: "9px",
          padding: "0 4px",
          ...provided,
        }),
        indicatorsContainer: (provided) => ({
          ...provided,
          height: "30px",
        }),
      }}
      onBlur={(e) => {
        if (selected === initialValue) {
          onAbort();
          return;
        }
        if (!selected) {
          onEdit(undefined);
          return;
        }
        onEdit(selected.id);
      }}
      onChange={(value) => {
        const values = createOption(value);

        if (!values) {
          setSelected();
          onSelect(undefined);
        } else {
          setSelected(values);
          onSelect(values.id);
        }
      }}
    />
  );
};

export default SelectEditor;
