import React, { useCallback } from "react";
import { CardPropertyTypes } from "../propertyTypes";
import IconCardFields from "./types/IconCardFields";
import TextCardFields from "./types/TextCardFields";
import ImageCardFields from "./types/ImageCardFields";
import { useConfiguratorStore } from "./configurator-store";
import Colors from "./components/Colors";
import { PanelKeys } from "../../../../../Dashboard/panels/panelTypes";
import InputFilter from "../../../../../Dashboard/filter/InputFilter";
import { Divider, FieldContainer, Label } from "../../../../../Dashboard/filter/styled";
import LinkField from "../../../../../../../../Shared/Components/LinkField";
import SidebarTransition from "../../../../../../../../Shared/Components/SidebarTransition";
import LinkSidebar from "../../../../../../../../Shared/Components/LinkSidebar/LinkSidebar";
import LinkTypes from "../../../../../../../../Shared/Components/LinkSidebar/LinkTypes";

function CardConfiguration({ card, cardType, updateCardById }) {
  const { id, title, description, link, openInNewTab, colors } = card || {};

  const { isEditingLink, setIsEditingLink } = useConfiguratorStore((state) => ({
    isEditingLink: state.isEditingLink,
    setIsEditingLink: state.setIsEditingLink,
  }));

  const updateCard = useCallback(
    (property, value) => {
      updateCardById(id, property, value);
    },
    [id, updateCardById],
  );

  return (
    <div>
      {cardType === PanelKeys.ICON_CARDS && <IconCardFields icon={card?.icon} title={title} updateCard={updateCard} />}
      {cardType === PanelKeys.TEXT_CARDS && <TextCardFields title={title} updateCard={updateCard} />}
      {cardType === PanelKeys.IMG_CARDS && (
        <ImageCardFields image={card?.image} title={title} updateCard={updateCard} />
      )}

      {card?.id && (
        <InputFilter
          initialValue={description || ""}
          onUpdate={(newDescription) => updateCard(CardPropertyTypes.DESCRIPTION, newDescription)}
          label="Card text"
          placeholder="Edit card text"
          textFormatting={true}
          minRows={5}
        />
      )}

      <FieldContainer>
        <Label>Link</Label>
        <LinkField
          value={link}
          setValue={(newValue) => updateCard(CardPropertyTypes.LINK, newValue)}
          openInNewTab={openInNewTab}
          setOpenInNewTab={(shouldOpenInNewTab) => updateCard(CardPropertyTypes.OPEN_NEW_TAB, shouldOpenInNewTab)}
          setIsEditingLink={setIsEditingLink}
        />
      </FieldContainer>

      <Divider style={{ margin: "20px 0px 5px 0px" }} />

      <Colors colors={colors} updateCard={updateCard} />

      <SidebarTransition
        isOpen={isEditingLink}
        close={() => setIsEditingLink(false)}
        width={500}
        title="Edit link"
        customScrollbarHeight="calc(100% - 45px)"
        useArrowBack
        onArrowBackClick={() => setIsEditingLink(false)}
      >
        <LinkSidebar
          selectedTab={LinkTypes.PAGES}
          value={link}
          onSelected={(newLink) => {
            updateCard(CardPropertyTypes.LINK, newLink);
            setIsEditingLink(false);
          }}
          onClose={() => setIsEditingLink(false)}
          showAttachments={false}
        />
      </SidebarTransition>
    </div>
  );
}

export default CardConfiguration;
