import React from "react";
import { PanelColumn, SidePanelsWrapper } from "../dashboard-styled";
import PanelProvider from "./PanelProvider";
import { PanelPositioning } from "./panelTypes";

function Side({ panels }) {
  return (
    <SidePanelsWrapper>
      <PanelColumn>
        {!!panels?.length &&
          panels
            .filter((panel) => panel.position === PanelPositioning.SIDE)
            .sort((a, b) => a.order - b.order)
            .map((panel) => <PanelProvider key={panel.id} panel={panel} position={PanelPositioning.SIDE} />)}
      </PanelColumn>
    </SidePanelsWrapper>
  );
}

export default Side;
