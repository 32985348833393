import Design from "./Design";
import Layout from "./Layout";

export const SidebarTabs = [
  {
    id: "layout",
    label: "Layout",
    Panel: Layout,
  },
  {
    id: "design",
    label: "Design",
    Panel: Design,
  },
];
