import React, { useEffect, useMemo, useState } from "react";
import { getNewsroomSettings, saveNewsroomSettings, searchBlogposts } from "./api";
import { htmlDecode } from "../../../ContentBuilder/utils/decode-html-string";
import { getCoverPictureOfContent } from "../../../ContentBuilder/Shared/cover-picture-provider";
import { useInfiniteQuery, useQuery } from "react-query";
import { useAtlassianLocale } from "../../../../Shared/Hooks/useAtlassianLocale";
import CreateBlog from "./Modal/CreateBlog";
import { NewsViews } from "./defaults";
import PageHeaderCentered from "../Shared/Header/PageHeaderCentered";
import { useCurrentUserStore } from "../../current-user-store";
import { defaultSettings } from "../Newsroom/defaults";
import { useNewsroomStore } from "./newsroom-store";
import { useChangesStore } from "../../track-changes-store";
import { useHeaderStore } from "../../store/header-store";
import { saveHeaderSettings } from "../../api";
import { Pages } from "../../pages";
import { useHeaderChangesStore } from "../Shared/Header/customization/header-changes-store";
import { NewsroomAPIContext } from "./context";
import AdminControls from "./AdminControls";
import NewsEntry from "./Views/NewsEntry";
import { ContentWrapper } from "../../styled/pages";
import FilterSidebar from "./Filters/FilterSidebar";
import { findSpacesUserIsWatching } from "../Dashboard/panels/types/news/api";
import { getCategoriesSettings, getSubscribedToCategories } from "../Settings/News/api";
import { useCorporateIdentityStore } from "../Settings/General/BrandAndColors/corporate-identity-store";
import { getBlogsCategories } from "../Dashboard/panels/types/news/handleBlogData";

const FALLBACK_LOCALE = "en-US";
const CLIENT_HEIGHT_OVERFLOW_THRESHOLD = 100;

function mapBlogpost(blogpost, locale, categories) {
  return {
    id: blogpost.content.id,
    type: blogpost.content.type,
    name: blogpost.content.title,
    space: { name: blogpost.content.space.name },
    excerpt: htmlDecode(blogpost.excerpt),
    coverPictureCallback: async () => getCoverPictureOfContent(blogpost.content.id),
    getBlogsCategories: async () => getBlogsCategories(categories, blogpost.content.id),
    labels: blogpost.content.metadata?.labels?.results.map((l) => l.label) ?? [],
    url: `${window.AP._hostOrigin}/wiki${blogpost.url}`,
    createdBy: blogpost.content.history.createdBy.displayName,
    avatarUrl: `${window.AP._hostOrigin}${blogpost.content.history.createdBy.profilePicture.path}`,
    lastModified: blogpost.friendlyLastModified,
    created: blogpost.content.history.createdDate,
    createdFormatted: new Date(blogpost.content.history.createdDate).toLocaleString(`${locale ?? FALLBACK_LOCALE}`, {
      year: "numeric",
      month: "short",
      day: "numeric",
    }),
  };
}

function Newsroom() {
  const [isSearching, setIsSearching] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const [usersSpaces, setUsersSpaces] = useState([]);

  const [allCategories, setAllCategories] = useState([]);
  const [usersCategories, setUsersCategories] = useState([]);

  const [locale] = useAtlassianLocale();

  const { setHasChanges } = useChangesStore((state) => ({
    hasChanges: state.hasChanges,
    setHasChanges: state.setHasChanges,
  }));

  const setHasHeaderChanges = useHeaderChangesStore((state) => state.setHasHeaderChanges);

  const { isCurrentUserAdmin } = useCurrentUserStore((state) => ({
    isCurrentUserAdmin: state.isCurrentUserAdmin,
  }));

  const headerType = useCorporateIdentityStore((state) => state.headerType);

  const headerData = useHeaderStore((state) => state.headerData);

  const {
    settings,
    setSettings,
    sorting,
    categoryFilter,
    spaceFilter,
    labelsFilter,
    authorsFilter,
    dateFilter,
    createBlogModalOpen,
    setCategories,
    setCreateBlogModalOpen,
  } = useNewsroomStore((state) => ({
    settings: state.settings,
    setSettings: state.setSettings,
    sorting: state.sorting,
    categoryFilter: state.categoryFilter,
    spaceFilter: state.spaceFilter,
    labelsFilter: state.labelsFilter,
    authorsFilter: state.authorsFilter,
    dateFilter: state.dateFilter,
    createBlogModalOpen: state.createBlogModalOpen,
    setCategories: state.setCategories,
    setCreateBlogModalOpen: state.setCreateBlogModalOpen,
  }));

  const hasFilters = useMemo(() => {
    return (
      !!spaceFilter?.length ||
      !!categoryFilter ||
      !!labelsFilter?.length ||
      !!authorsFilter?.length ||
      !!dateFilter.start ||
      !!dateFilter.end
    );
  }, [spaceFilter, labelsFilter, authorsFilter, dateFilter, categoryFilter]);

  const { isLoading: isLoadingCategories, data: categoriesSettingsData } = useQuery(
    "categories-settings",
    getCategoriesSettings,
    {
      retry: 0,
      select: (response) => {
        const { data } = response;
        return data;
      },
    },
  );

  const { isLoading, isFetching, fetchNextPage, hasNextPage, data } = useInfiniteQuery(
    [
      "blogposts",
      {
        spaces: spaceFilter?.map(({ value }) => value),
        labels: labelsFilter,
        authorsFilter: authorsFilter?.map(({ value }) => value),
        dateFilter: dateFilter,
        limit: settings?.selectedView === NewsViews.MAGAZINE ? 3 : 8,
        sorting: sorting,
        searchValue: isSearching ? searchValue : "",
        category: categoryFilter,
        subscribedCategories: usersCategories,
        watchingSpaces: usersSpaces,
      },
    ],
    searchBlogposts,
    {
      enabled: !isLoadingCategories,
      retry: 0,
      select: (response) => {
        const pages = [];
        response.pages.forEach((page) => {
          const body = JSON.parse(page.body);
          const resultsInPage = body.results.map((blog) => mapBlogpost(blog, locale, allCategories));
          pages.push({ ...body, results: resultsInPage });
        });
        return { pages, pageParams: response.pageParams };
      },
      getNextPageParam: (lastPage) => {
        const body = JSON.parse(lastPage.body);
        if (body._links.next) {
          const urlParams = new URLSearchParams(encodeURI(body._links.next));
          const cursor = urlParams.get("cursor");
          return cursor;
        }
        return false;
      },
    },
  );

  useEffect(() => {
    if (!isLoadingCategories && categoriesSettingsData?.exists) {
      setAllCategories(categoriesSettingsData?.categories?.data || []);
    }
  }, [isLoadingCategories, categoriesSettingsData]);

  const { isLoading: newsroomLoading, data: newsroomSettingsData } = useQuery(
    ["newsroom-settings"],
    getNewsroomSettings,
    {
      retry: 0,
      select: (response) => {
        const { data } = response;
        return data;
      },
    },
  );

  useEffect(() => {
    if (isLoading || isFetching || (!data?.pages && !data?.pages?.[0]?.results?.length)) {
      return;
    }

    // in case the loaded data is not overflowing fetch next page
    if (data && data.pages && hasNextPage) {
      const overflowContainer = document.getElementById("hub-overflow-container");

      const clientHeight = overflowContainer?.clientHeight + CLIENT_HEIGHT_OVERFLOW_THRESHOLD;

      if (overflowContainer.scrollHeight <= clientHeight) {
        fetchNextPage();
      }
    }
  }, [data, isLoading, isFetching, hasNextPage]);

  useEffect(() => {
    if (newsroomSettingsData?.exists) {
      setSettings({ ...newsroomSettingsData.settings });
    } else {
      setSettings({ ...defaultSettings });
    }
  }, [newsroomSettingsData]);

  useEffect(() => {
    setIsSearching(hasFilters);
  }, [hasFilters]);

  useEffect(() => {
    (async () => {
      const usersCategories = await getSubscribedToCategories({ includeAllCategories: true });
      if (usersCategories?.data) {
        setUsersCategories(usersCategories?.data?.subscribedTo || []);
        setCategories(usersCategories?.data?.categories || []);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const foundSpaces = await findSpacesUserIsWatching();
      setUsersSpaces(foundSpaces?.length ? foundSpaces.map(({ space }) => space.key) : []);
    })();
  }, []);

  const resetSettingsData = () => {
    getNewsroomSettings({ queryKey: "newsroom-settings" })
      .then((res) => {
        if (res?.data?.exists) {
          setSettings({ ...res.data.settings });
        } else {
          setSettings({ ...defaultSettings });
        }
      })
      .catch((err) => console.log(err));
  };

  const handleSaveNewsroomSettings = async (callback = () => {}) => {
    try {
      await saveNewsroomSettings(settings);
    } catch (err) {
      console.log(err);
    } finally {
      callback();
      setHasChanges(false);
    }
  };

  const handleSaveHeaderSettings = async (callback = () => {}) => {
    try {
      await saveHeaderSettings(headerData);
    } catch (err) {
      console.log(err);
    } finally {
      callback();
      setHasHeaderChanges(false);
    }
  };

  return (
    <NewsroomAPIContext.Provider value={{ handleSaveNewsroomSettings, handleSaveHeaderSettings, resetSettingsData }}>
      <div>
        <PageHeaderCentered
          {...headerData[Pages.NEWS]}
          searchPlaceholder="Search blogs"
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          isSearching={isSearching}
          setIsSearching={setIsSearching}
          headerType={headerType}
        />

        {isCurrentUserAdmin && !isLoading && !newsroomLoading && <AdminControls />}

        <ContentWrapper>
          <FilterSidebar />

          <NewsEntry
            data={data}
            fetchNextPage={fetchNextPage}
            hasNextPage={hasNextPage}
            isFetching={isFetching}
            isLoading={isLoading}
            isSearching={isSearching}
          />
        </ContentWrapper>

        <CreateBlog isModalOpen={createBlogModalOpen} closeModal={() => setCreateBlogModalOpen(false)} />
      </div>
    </NewsroomAPIContext.Provider>
  );
}

export default Newsroom;
