import React, { useEffect, useMemo, useRef, useState } from "react";
import { useQuery } from "react-query";
import Spinner from "@atlaskit/spinner";
import { SpotlightTarget } from "@atlaskit/onboarding";
import { analytics } from "../../../../analytics";
import { useCurrentUserStore } from "../../current-user-store";
import { SpinnerContainer } from "../../styled/pages";
import { getDashboard, saveDashboard } from "./api";
import { ContentWrapper, DashboardWrapper } from "./dashboard-styled";
import EditPanelsButton from "../Shared/EditPanelsButton";
import { getDefaultDashboard } from "./panels/defaultPanelData";
import MainPanels from "./panels/Main";
import SidePanels from "./panels/Side";
import Sidebar from "./panels/edit/sidebar/Sidebar";
import { usePanelsStore } from "./store/panels-store";
import { formatPanelData } from "./handlePanelData";
import { usePageStore } from "../../page-store";
import { useChangesStore } from "../../track-changes-store";
import { useCorporateIdentityStore } from "../Settings/General/BrandAndColors/corporate-identity-store";
import { DashboardAPIContext } from "./context";
import SaveChangesButton from "../Shared/SaveChangesButton";
import Tooltip from "@atlaskit/tooltip";
import { fetchCollections } from "../../../Collections/api";
import { getUserCollections } from "../../api";
import {
  buildPanelCollectionsFilters,
  getAllCollectionsFromPanels,
  separateNonExistingCollections,
  simulateCollectionsBasedOnFiltersInPanels,
} from "../../../../Shared/Functions/handleCollectionFilters";
import { useSidebarStore } from "./panels/edit/sidebar/sidebar-store";
import { SIDEBAR_TYPES } from "./panels/edit/sidebar/types";

function Dashboard({ isInMacro }) {
  const [loadingFilteredCollections, setLoadingFilteredCollections] = useState(false);
  const [permissionPanels, setPermissionPanels] = useState([]);

  const [rawDashboardResponse, setRawDashboardResponse] = useState(null);

  const { isCurrentUserAdmin, isAnonymous, isGuest } = useCurrentUserStore((state) => ({
    isCurrentUserAdmin: state.isCurrentUserAdmin,
    isAnonymous: state.isAnonymous,
    isGuest: state.isGuest,
  }));

  const { backgroundColor } = useCorporateIdentityStore((state) => ({
    backgroundColor: state.backgroundColor,
  }));

  const primaryColor = "#0065ff";
  const primaryContrastColor = "#ffffff";

  const {
    panels,
    setPanels,
    isSidebarOpen,
    setIsSidebarOpen,

    setExitEditModalVisible,

    setEditingPanel,

    setIsInMacro,
    isDbInitalized,
  } = usePanelsStore((state) => ({
    panels: state.panels,
    setPanels: state.setPanels,
    isSidebarOpen: state.isSidebarOpen,
    setIsSidebarOpen: state.setIsSidebarOpen,

    setExitEditModalVisible: state.setExitEditModalVisible,

    setEditingPanel: state.setEditingPanel,

    setIsInMacro: state.setIsInMacro,
    isDbInitalized: state.hasInitEmployeeDatabase,
  }));

  const { setSidebarType, setSelectedContentType } = useSidebarStore((state) => ({
    setSidebarType: state.setSidebarType,
    setSelectedContentType: state.setSelectedContentType,
  }));

  const { hasChanges, setHasChanges } = useChangesStore((state) => ({
    hasChanges: state.hasChanges,
    setHasChanges: state.setHasChanges,
  }));

  const setSelectedPage = usePageStore((state) => state.setSelectedPage);

  const hasChangesRef = useRef();
  hasChangesRef.current = hasChanges;

  const redirectPage = useRef();

  const { isLoading, data: dashboardResponseData } = useQuery("dashboard-get", getDashboard, {
    retry: 0,
    select: (response) => {
      const { data } = response;
      return data;
    },
  });

  const { isLoading: collectionsLoading, data: collections } = useQuery("saved-collections", fetchCollections, {
    enabled: isDbInitalized,
    cacheTime: 0,
    retry: 0,
    select: (response) => {
      const { data } = response;
      return data || [];
    },
  });

  useEffect(() => {
    if (!isLoading && dashboardResponseData) {
      setRawDashboardResponse(dashboardResponseData);
    }
  }, [isLoading, dashboardResponseData]);

  useEffect(() => {
    (async () => {
      if (isLoading || collectionsLoading || !rawDashboardResponse) return;
      setLoadingFilteredCollections(true);

      const savedPanels = formatPanelData(
        !!rawDashboardResponse?.exists ? rawDashboardResponse.dashboard : getDefaultDashboard(),
        isAnonymous,
        isGuest,
      );

      const foundCollections = getAllCollectionsFromPanels(savedPanels);
      const simulatedCollections = simulateCollectionsBasedOnFiltersInPanels(savedPanels);

      const [withoutDeletedCollections, deletedCollections] = separateNonExistingCollections(
        foundCollections,
        collections,
      );

      const foundCollectionsWithFilters = buildPanelCollectionsFilters(withoutDeletedCollections, collections);

      let collectionsUserBelongsTo = [];
      try {
        const response = await getUserCollections([...foundCollectionsWithFilters, ...simulatedCollections]);
        collectionsUserBelongsTo = response?.data?.length ? response.data : [];
      } catch {
        collectionsUserBelongsTo = [];
      }

      const filteredPanels = savedPanels.filter((panel) => {
        if (!panel?.permissions?.length && !panel?.filters?.length) return true;

        const availableCollectionsInPanel =
          panel?.permissions?.filter((collectionId) => !deletedCollections.find((id) => id === collectionId)) || [];

        if (!availableCollectionsInPanel?.length && !simulatedCollections?.length) return true;

        const collectionThatMatchesPanel = simulatedCollections?.find((clc) => clc.panelId === panel.id)?.id;

        const matchAgainst = collectionThatMatchesPanel
          ? [...(availableCollectionsInPanel || []), collectionThatMatchesPanel]
          : availableCollectionsInPanel;

        return matchAgainst.some((collectionId) => collectionsUserBelongsTo.includes(collectionId));
      });

      setPermissionPanels([...filteredPanels]);
      setPanels([...savedPanels]);

      setLoadingFilteredCollections(false);
    })();
  }, [
    rawDashboardResponse,
    collections,
    isCurrentUserAdmin,
    isAnonymous,
    isGuest,
    setPanels,
    isLoading,
    collectionsLoading,
  ]);

  const publishDashboard = async (callback = () => {}) => {
    try {
      await saveDashboard(panels);
    } catch (err) {
      console.log(err);
    } finally {
      callback();
      setRawDashboardResponse({ exists: true, dashboard: panels });
      setHasChanges(false);
    }
  };

  const resetData = (shouldCheckIfHasChanges) => {
    if (shouldCheckIfHasChanges && hasChanges) {
      redirectPage.current = undefined;
      setExitEditModalVisible(true);
    } else {
      getDashboard({ queryKey: "dashboard-get" })
        .then((res) => {
          if (res?.data?.dashboard) {
            setPanels([...res.data.dashboard]);
          } else {
            setPanels([...getDefaultDashboard()]);
          }
          setIsSidebarOpen(false);
        })
        .catch((err) => console.log(err));
    }
  };

  const editPanelExternally = (panelId, panelPosition, tabToOpen) => {
    if (!panelId || !panelPosition) return;
    const foundPanel = panels.find((panel) => panel.id === panelId);
    if (!foundPanel) return;

    setSelectedContentType(panelPosition);
    setSidebarType(SIDEBAR_TYPES.PANEL_EDIT);
    setEditingPanel({ ...foundPanel });
    setIsSidebarOpen(true);
    if (tabToOpen) {
      setTimeout(() => {
        window.AP.events.emit("change-tab-externally", tabToOpen);
      }, 150);
    }
  };

  useEffect(() => {
    analytics.logEvent({ name: "Cosmos Dashboard", template: "load" });
  }, []);

  useEffect(() => {
    setIsInMacro(isInMacro);
  }, [isInMacro]);

  const isDashboardLoading = useMemo(
    () => isLoading || collectionsLoading || loadingFilteredCollections,
    [isLoading, collectionsLoading, loadingFilteredCollections],
  );

  const panelsToShow = {
    [true]: panels,
    [false]: permissionPanels,
  };

  return (
    <DashboardAPIContext.Provider value={{ publishDashboard, resetData, editPanelExternally }}>
      <DashboardWrapper isInMacro={isInMacro} dashboardBgColor={backgroundColor}>
        {isDashboardLoading ? (
          <SpinnerContainer style={{ height: "100%", marginTop: "0px" }}>
            <Spinner size="xlarge" />
          </SpinnerContainer>
        ) : (
          <ContentWrapper>
            <MainPanels panels={panelsToShow[isSidebarOpen]} />
            <SidePanels panels={panelsToShow[isSidebarOpen]} />
          </ContentWrapper>
        )}

        {isCurrentUserAdmin && (
          <>
            {!isDashboardLoading && (
              <>
                {hasChanges && (
                  <Tooltip content="Save" position="left">
                    <SaveChangesButton
                      primaryColor={primaryColor}
                      primaryContrastColor={primaryContrastColor}
                      onClick={() => publishDashboard(() => {})}
                    />
                  </Tooltip>
                )}
                <Tooltip content="Edit" position="left">
                  <SpotlightTarget name="edit-dashboard">
                    <EditPanelsButton
                      primaryColor={primaryColor}
                      primaryContrastColor={primaryContrastColor}
                      openSidebar={() => {
                        setIsSidebarOpen(true);
                      }}
                    />
                  </SpotlightTarget>
                </Tooltip>
              </>
            )}

            <Sidebar />
          </>
        )}
      </DashboardWrapper>
    </DashboardAPIContext.Provider>
  );
}

export default Dashboard;
