import httpClient from "../../../../Service/http-client";

export async function getUserReview({ queryKey }) {
  const url = `ac/confluence/reviews/user`;
  return httpClient.get(url);
}

export async function setUserReview(score) {
  const url = `ac/confluence/reviews/user/save`;
  return httpClient.post(url, { score });
}
