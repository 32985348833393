import React from "react";
import styled from "styled-components";
import SearchInput from "../../Hub/Components/Shared/SearchInput";
import { useCollectionsStore } from "../collection-store";

const ToolbarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Button = styled.div`
  width: fit-content;
  cursor: pointer;
  background: ${({ color }) => color};
  border-radius: 3px;
  padding: 6px 12px;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;

  opacity: 1;
  transition: opacity 150ms linear;

  &:hover {
    opacity: 0.85;
  }
`;

function Toolbar() {
  const { searchValue, setSearchValue, setIsCreatingCollection } = useCollectionsStore((state) => ({
    searchValue: state.searchValue,
    setSearchValue: state.setSearchValue,
    setIsCreatingCollection: state.setIsCreatingCollection,
  }));

  const primaryColor = "#0065ff";

  return (
    <ToolbarWrapper>
      <SearchInput value={searchValue} setValue={setSearchValue} placeholder="Search" />
      <Button color={primaryColor} onClick={() => setIsCreatingCollection(true)}>
        Create collection
      </Button>
    </ToolbarWrapper>
  );
}

export default Toolbar;
