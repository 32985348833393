import React from "react";
import BackgroundPopup from "../../../../../../Shared/Components/Background/BackgroundPopup";
import { useNavigationStore, useSidebarStore } from "../../../../store";
import { DEFAULT_BACKGROUND } from "../../../../functions/generateNewEntry";

function Background() {
  const { isHelperDrawerOpen, setIsHelperDrawerOpen } = useSidebarStore((state) => ({
    isHelperDrawerOpen: state.isHelperDrawerOpen,
    setIsHelperDrawerOpen: state.setIsHelperDrawerOpen,
  }));

  const { editingEntry, updateEntry } = useNavigationStore((state) => ({
    editingEntry: state.editingEntry,
    updateEntry: state.updateEntry,
  }));

  return (
    <BackgroundPopup
      linkToDocs="https://caelor.atlassian.net/wiki/x/jYCV"
      position="absolute"
      dialogPositionCss={{ top: "0", bottom: "0", right: "0" }}
      isOpen={isHelperDrawerOpen}
      image={editingEntry?.data?.image || DEFAULT_BACKGROUND}
      showOverlay={false}
      onClose={() => {
        setIsHelperDrawerOpen(false);
      }}
      resetBackground={() => {
        if (editingEntry?.data) {
          updateEntry(editingEntry.id, "image", DEFAULT_BACKGROUND, true);
        }
      }}
      zIndex="501"
      setImage={(image) => {
        if (editingEntry?.data) {
          updateEntry(editingEntry.id, "image", image, true);
        }
      }}
      showCurrentPageInAttachments={false}
      defaultImage={DEFAULT_BACKGROUND}
      showVideoInAttachments={false}
      hasColorTabs
      hasUpload
    />
  );
}

export default Background;
