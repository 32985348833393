import React from "react";
import { FieldContainer, FieldLabel, ViewButtonsWrapper, ViewSelectButton } from "../../Shared/Filters/styled-filters";
import { NewsViews } from "../defaults";
import { useNewsroomStore } from "../newsroom-store";
import { SettingPropertyKeys } from "../Sidebar/NewsDesign";
import { GridIcon, ListIcon, MagazineIcon } from "../../Shared/Filters/Icons";
import { useTheme } from "styled-components";
import { convertColorOpacity } from "../../../../../Shared/Functions/convertColorOpacity";

function ViewSelect({ mgTop, primaryColor = "#0065ff" }) {
  const {
    settings: { selectedView },
    updateSettings,
  } = useNewsroomStore((state) => ({
    settings: state.settings,
    updateSettings: state.updateSettings,
  }));

  const theme = useTheme();

  const updateView = (view) => updateSettings(SettingPropertyKeys.VIEW, view);
  const convertedColor = convertColorOpacity(primaryColor, theme.global.name);

  return (
    <FieldContainer style={{ marginTop: mgTop }}>
      <FieldLabel style={{ textTransform: "uppercase" }}>View</FieldLabel>
      <ViewButtonsWrapper numberOfColumns={3}>
        <ViewSelectButton
          isActive={selectedView === NewsViews.MAGAZINE}
          primaryColor={primaryColor}
          onClick={() => updateView(NewsViews.MAGAZINE)}
          backgroundColor={{
            inactive: theme.shared.form.button.background.inactive,
            active: convertedColor,
          }}
        >
          <MagazineIcon
            color={selectedView === NewsViews.MAGAZINE ? primaryColor : theme.shared.form.button.text.inactive}
          />
        </ViewSelectButton>

        <ViewSelectButton
          isActive={selectedView === NewsViews.GRID}
          primaryColor={primaryColor}
          onClick={() => updateView(NewsViews.GRID)}
          backgroundColor={{
            inactive: theme.shared.form.button.background.inactive,
            active: convertedColor,
          }}
        >
          <GridIcon color={selectedView === NewsViews.GRID ? primaryColor : theme.shared.form.button.text.inactive} />
        </ViewSelectButton>

        <ViewSelectButton
          isActive={selectedView === NewsViews.LIST}
          primaryColor={primaryColor}
          onClick={() => updateView(NewsViews.LIST)}
          backgroundColor={{
            inactive: theme.shared.form.button.background.inactive,
            active: convertedColor,
          }}
        >
          <ListIcon color={selectedView === NewsViews.LIST ? primaryColor : theme.shared.form.button.text.inactive} />
        </ViewSelectButton>
      </ViewButtonsWrapper>
    </FieldContainer>
  );
}

export default ViewSelect;
