import { useRef, useEffect } from "react";
import styled, { useTheme } from "styled-components";
import { Panel, PanelHeader } from "../General";
import { Radio } from "@atlaskit/radio";

import { saveCorporateIdentitySettings } from "../../../../api";
import debounce from "lodash.debounce";

import { HEADER_TYPES } from "../BrandAndColors/types";
import { COLOR_TYPES } from "../BrandAndColors/color-types";

const Text = styled.p`
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.shared.settings.general.brandAndColors.pageHeaderTextColor};
`;

const Wrapper = styled.div`
  display: flex;
  column-gap: 16px;
`;

const WidthOptionWrapper = styled.div`
  width: 160px;
  height: 40px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  padding-left: 8px;
  cursor: pointer;

  label > span > span {
    cursor: pointer;
  }

  ${({ isChecked, primaryColor, theme }) =>
    isChecked
      ? `
      background-color: ${theme.shared.settings.general.brandAndColors.editLogoBackground};
      label > input:checked {
        --radio-background-color: ${theme.global.primaryColor} !important;
        --radio-border-color: ${theme.global.primaryColor} !important;
      }
    `
      : `
      background-color: transparent;
    `}
`;

const WIDTH_TYPES = [
  {
    value: HEADER_TYPES.FULL_WIDTH,
    label: "Full Width",
  },
  {
    value: HEADER_TYPES.GRID,
    label: "Grid Width",
  },
];

function PageHeaders({ headerType, colors, setHeaderType }) {
  const theme = useTheme();

  const handleSaveDebounce = useRef(
    debounce(async (_headerType) => {
      try {
        await saveCorporateIdentitySettings({ headerType: _headerType });
      } catch {
        console.log("failed to save header type");
      }
    }, 500),
  ).current;

  useEffect(() => {
    return () => {
      handleSaveDebounce.cancel();
    };
  }, [handleSaveDebounce]);

  const handleUpdateHeaderType = (_headerType) => {
    setHeaderType(_headerType);
    handleSaveDebounce(_headerType);
  };

  const RadioLabel = ({ name, color }) => {
    return <span style={{ color: color, fontSize: "14px", textTransform: "capitalize" }}>{name}</span>;
  };

  return (
    <Panel>
      <PanelHeader>Page headers</PanelHeader>

      <Text>Applied for headers in all Cosmos screens, except for the Dashboard page.</Text>

      <Wrapper>
        {WIDTH_TYPES.map((type) => (
          <WidthOptionWrapper
            onClick={() => {
              handleUpdateHeaderType(type.value);
            }}
            isChecked={headerType === type.value}
            primaryColor={colors[COLOR_TYPES.PRIMARY]}
            key={type.value}
          >
            <Radio
              value={type.value}
              label={
                <RadioLabel name={type.label} color={theme.shared.settings.general.brandAndColors.logoNameColor} />
              }
              isChecked={headerType === type.value}
            />
          </WidthOptionWrapper>
        ))}
      </Wrapper>
    </Panel>
  );
}

export default PageHeaders;
