import React from "react";
import { Box } from "@atlaskit/primitives";
import MenuEntries from "../entries/MenuEntries";
import Divider from "../../shared/Divider";
import AddOption from "../entries/AddOption";
import DeleteNavigation from "../components/DeleteNavigation";
import { useNavigationStore } from "../../../store";

function Layout() {
  const structure = useNavigationStore((state) => state.structure);

  return (
    <Box style={{ width: "100%", display: "grid", gridTemplateRows: "auto 40px" }}>
      <Box>
        <MenuEntries />
        <Divider margin="space.200" padding="space.0" />
        <AddOption />
      </Box>

      {!!structure?.length && <DeleteNavigation />}
    </Box>
  );
}

export default Layout;
