import dayjs from "dayjs";
import httpClient from "../../../../Service/http-client";
import { getCqlForCategories, getCqlForSpaces } from "../Dashboard/panels/types/news/api";
import { SUBS_FILTER } from "./defaults";

export async function searchBlogposts({ queryKey, pageParam }) {
  const [
    _key,
    {
      spaces = [],
      labels = [],
      authorsFilter = [],
      dateFilter = { start: undefined, end: undefined },
      limit = 10,
      sorting = "newest",
      searchValue,
      category,
      subscribedCategories = [],
      watchingSpaces = [],
    },
  ] = queryKey;

  let cql = "type = blogpost";

  if (category === SUBS_FILTER) {
    const categoriesCql = getCqlForCategories(subscribedCategories);
    const spacesCql = getCqlForSpaces(watchingSpaces);

    if (categoriesCql && spacesCql) {
      cql = cql + ` AND (${spacesCql} OR ${categoriesCql})`;
    } else if (categoriesCql) {
      cql = cql + ` AND ${categoriesCql}`;
    } else if (spacesCql) {
      cql = cql + ` AND ${spacesCql}`;
    } else {
      cql = cql + ` AND creator = "-1"`;
    }
  }

  if (searchValue?.length) {
    cql = cql + ` AND (title ~ "${searchValue}*" OR space = "${searchValue.toUpperCase()}")`;
  }
  if (!!category && category !== SUBS_FILTER) {
    cql = cql + ` AND content.property[cosmosnewsmetadata].categories~"${category}"`;
  }
  if (spaces?.length) {
    cql = cql + ` AND space in (${spaces.map((s) => `"${s}"`).join(",")})`;
  }

  if (labels?.length) {
    cql = cql + ` AND label in (${labels.map((l) => `"${l}"`).join(",")})`;
  }

  if (authorsFilter?.length) {
    cql = cql + ` AND creator.accountid in (${authorsFilter.map((c) => `"${c}"`).join(",")})`;
  }

  if (dateFilter?.start) {
    cql = cql + ` AND created >= ${dayjs.unix(dateFilter.start).format("YYYY-MM-DD")}`;
  }

  if (dateFilter?.end) {
    cql = cql + ` AND created <= ${dayjs.unix(dateFilter.end).format("YYYY-MM-DD")}`;
  }

  cql = cql + ` ORDER BY created`;
  if (sorting === "newest") {
    cql = cql + ` desc`;
  }
  if (sorting === "oldest") {
    cql = cql + ` asc`;
  }
  cql = encodeURI(cql);
  let url = `/rest/api/search?cql=${cql}&limit=${limit}&expand=content.history,content.space,content.metadata.labels`;

  if (pageParam) {
    url = `${url}&cursor=${decodeURI(pageParam)}`;
  }
  try {
    return window.AP.request(url);
  } catch (e) {
    console.log("cant fetch blogposts");
    console.log(e);
  }
}

export async function getNewsroomSettings({ queryKey }) {
  const url = "ac/confluence/newsroom";
  return httpClient.get(url);
}

export async function saveNewsroomSettings(settings) {
  const url = "ac/confluence/newsroom/save";
  return httpClient.post(url, { data: settings });
}
