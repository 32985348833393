import { PanelKeys } from "../../../Dashboard/panels/panelTypes";

export const WidgetIds = {
  BIRTHDAY: PanelKeys.IMPORTANT_DATES,
  WORK_ANNIVERSARY: PanelKeys.WORK_ANNIVERSARY_MAIN,
  NEW_STARTERS: PanelKeys.NEW_STARTERS,
  ORG_CHART: "org-chart",
};

export const AvailableTabs = [
  {
    id: WidgetIds.BIRTHDAY,
    label: "Upcoming Birthdays",
  },
  {
    id: WidgetIds.WORK_ANNIVERSARY,
    label: "Work Anniversary",
  },
  {
    id: WidgetIds.NEW_STARTERS,
    label: "New Starters",
  },
  {
    id: WidgetIds.ORG_CHART,
    label: "Org Charts",
  },
];
