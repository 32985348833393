import { useQuery } from "react-query";
import { getWidgetsSettings } from "../../Components/Hub/Components/Settings/Widgets/api";

export const useWidgetSettings = () => {
  const { isLoading: isWidgetSettingsLoading, data: widgetSettingsData } = useQuery(
    "widgets-settings",
    getWidgetsSettings,
    {
      retry: 0,
      select: (response) => {
        const { data } = response;
        return data;
      },
    },
  );

  return { isWidgetSettingsLoading, widgetSettingsData };
};
