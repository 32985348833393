import { PanelKeys } from "../../../../../Dashboard/panels/panelTypes";

export const getRandomCardImage = () => {
  const availableImages = [
    "https://img.freepik.com/free-photo/helpful-tips-information-knowledge-concept_53876-122416.jpg?w=750",
    "https://img.freepik.com/free-photo/vibrant-colors-swirling-futuristic-underwater-chaos-generated-by-ai_188544-9692.jpg?w=750",
    "https://img.freepik.com/free-photo/vertical-shot-white-building-clear-sky_181624-4575.jpg?w=740",
    "https://img.freepik.com/free-photo/new-york-skycraper-sunset-usa_268835-758.jpg?w=740",
  ];

  const min = 0;
  const max = availableImages.length - 1;

  const randomNumber = Math.floor(Math.random() * (max - min + 1) + min);

  return { source: "link", link: availableImages[randomNumber] };
};

export const createCardContent = () => {
  const availableContent = [
    {
      title: "Employee Handbook",
      description: "Your go-to guide for company policies, cultural norms, and everything in between.",
    },
    {
      title: "Intranet Help & Tutorials",
      description: "Explore our tutorials to navigate and utilize new Intranet. From basic navigation to expert tips.",
    },
    {
      title: "Benefits & Compensation",
      description: "Explore details about your employment benefits, health plans, compensation structures, and more.",
    },
    {
      title: "Personal Development",
      description:
        "Access training modules, workshops, and resources tailored to boost your skills and career progression.",
    },
    {
      title: "Work-Life Balance Resources",
      description: "Ensuring you're at your best, both in and out of work.",
    },
  ];

  const min = 0;
  const max = availableContent.length - 1;

  const randomNumber = Math.floor(Math.random() * (max - min + 1) + min);

  return availableContent[randomNumber];
};

export const CardDataByKey = {
  [PanelKeys.ICON_CARDS]: {
    icon: { icon: "atlaskit/FolderFilledIcon", provider: "atlaskit" },
    link: "",
    openInNewTab: true,
    colors: {
      elements: "#00A59B",
      background: "#fff",
      defaultBackground: true,
    },
  },
  [PanelKeys.TEXT_CARDS]: {
    link: "",
    openInNewTab: true,
    colors: {
      elements: "#00A59B",
      background: "#fff",
      defaultBackground: true,
    },
  },
  [PanelKeys.IMG_CARDS]: {
    link: "",
    openInNewTab: true,
    imageHeight: 100,
    colors: {
      elements: "#00A59B",
      background: "#fff",
      defaultBackground: true,
    },
  },
};
