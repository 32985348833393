import React from "react";

import {
  CategoryLabel,
  FilterButton,
  FilterContainer,
  LabelsContainer,
  ScreenContainer,
  ScreenTitle,
  SearchContainer,
  SearchContent,
  SearchLabel,
  NewsroomHeading,
  NewsroomCategory,
  NewsroomTitle,
  NewsroomLink,
  NewsroomDescription,
  NewsroomList,
  NewsroomItem,
  NewsroomItemImage,
  NewsroomItemData,
  NewsroomItemLabel,
  NewsroomItemTitle,
  NewsroomItemInfo,
} from "../../styles";
import { NEWSROOM_DATA } from "./defaults";
import Navigation from "../Components/Navigation";
import { useCosmosAppStore } from "../../cosmos-app-store";

const sourceLabel = {
  categories: "Category",
  spaces: "Space",
};

const Newsroom = ({ screenType }) => {
  const cosmosAppSettings = useCosmosAppStore((state) => state.cosmosAppSettings);

  return (
    <ScreenContainer>
      <ScreenTitle>Newsroom</ScreenTitle>
      <FilterContainer>
        <SearchContainer>
          <SearchContent>
            <img src="/images/cosmos-app/filters/search-icon.svg" alt="" />
            <SearchLabel>Search</SearchLabel>
          </SearchContent>
        </SearchContainer>
        <FilterButton>
          <img src="/images/cosmos-app/filters/filter-icon.svg" alt="" />
        </FilterButton>
      </FilterContainer>
      <LabelsContainer>
        <CategoryLabel>All News</CategoryLabel>
        <CategoryLabel>Following</CategoryLabel>
        <CategoryLabel style={{ background: cosmosAppSettings?.brand?.color, color: "white" }}>
          {sourceLabel[cosmosAppSettings?.news?.source]}
        </CategoryLabel>
      </LabelsContainer>
      <NewsroomHeading>
        <NewsroomCategory>
          <NewsroomTitle>{sourceLabel[cosmosAppSettings?.news?.source]}</NewsroomTitle>
          <NewsroomLink>Follow</NewsroomLink>
        </NewsroomCategory>
        <NewsroomDescription>12 Articles</NewsroomDescription>
      </NewsroomHeading>
      <NewsroomList>
        {NEWSROOM_DATA.map(({ id, label, imagePath, title, info }) => (
          <NewsroomItem key={id}>
            <NewsroomItemImage>
              <img src={imagePath} alt="Shoppie" />
            </NewsroomItemImage>
            <NewsroomItemData>
              <NewsroomItemLabel>{label}</NewsroomItemLabel>
              <NewsroomItemTitle>{title}</NewsroomItemTitle>
              <NewsroomItemInfo>{info}</NewsroomItemInfo>
            </NewsroomItemData>
          </NewsroomItem>
        ))}
      </NewsroomList>

      <Navigation screenType={screenType} />
    </ScreenContainer>
  );
};

export default Newsroom;
