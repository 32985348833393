import React, { useState, useEffect } from "react";
import DefaultSortPicker from "../../../filter/DefaultSortPicker";
import EditorAddIcon from "@atlaskit/icon/glyph/editor/add";
import DisableDynamicFilterToggle, { LoadingTypes } from "../../../filter/DynamicFilterToggle";
import LabelMultiSelect from "../../../filter/LabelMultiSelect";
import OrderButton from "../../../filter/OrderButton";
import PeoplePicker from "../../../filter/PeoplePicker";
import SelectWithCustomOptions from "../../../filter/SelectWithCustomOptions";
import SpaceMultiSelect from "../../../filter/SpaceMultiSelect";
import {
  AddManuallyPickedButton,
  AddNewContentContainer,
  Divider,
  FieldContainer,
  HelperMessage,
  Label,
} from "../../../filter/styled";
import TextInputFilter from "../../../filter/TextInputFilter";
import ElementsToggle from "../../../filter/ElementsToggle";
import { TabTypes } from "../../tabTypes";
import { DesignSidebarWrapper, PanelEditorWrapper } from "./styled";
import { Ordering } from "../../../../Dashboard/filter/sorting-types";
import { PagesTypes } from "../../types/pages/Pages";
import SimpleToggle from "../../../filter/SimpleToggle";
import PagePicker from "../../../filter/PagePicker";
import ManualPagesList from "../../../filter/ManualPagesList";
import { generateId } from "../../../../../../ContentBuilder/utils/id-generator";
import InputFilter from "../../../filter/InputFilter";
import { PanelsPaginationTypes } from "../../../../Shared/PanelsPagination/panels-pagination-types";
import { PaginationOptions } from "../../../../Shared/PanelsPagination/pagination-options";

const PagesPropertyTypes = {
  VIEW: "view",
  LOADING_TYPE: "loadingType",
  LABELS: "labels",
  SPACES: "spaces",
  CONTRIBUTORS: "contributors",
  PAGINATION: "pagination",
  MAX_ITEMS: "maxNumberOfItems",
  SORT: "sortBy",
  ORDERING: "ordering",
  MANUALLY_PICKED: "manuallyPicked",
  ACTIVE_ELEMENTS: "activeElements",
  DISPLAY_TITLE: "displayTitle",
  PARENT_PAGE_ID: "parentPageId",
  EXCLUDE_PERSONAL_SPACES: "excludePersonalSpaces",
  DISPLAY_BOX: "displayBox",
};

const defaultElementsOptions = [
  {
    label: "Author",
    value: "author",
  },
  {
    label: "Space",
    value: "space",
  },
];

const PageElementsOptions = {
  [PagesTypes.CARD]: [
    ...defaultElementsOptions,
    { label: "Date", value: "date" },
    { label: "Comments", value: "comments" },
    { label: "Thumbs Up (Likes)", value: "likes" },
  ],
  [PagesTypes.MINI_CARD]: defaultElementsOptions,
};

const DynamicSidebar = ({ panelId, data, updatePanelDataProperty }) => {
  const [sortOrder, setSortOrder] = useState(data.ordering || Ordering.ASCENDING);

  const handleSortOrderUpdate = (newSortOrder) => {
    setSortOrder(newSortOrder);
    updatePanelDataProperty(panelId, PagesPropertyTypes.ORDERING, newSortOrder);
  };
  return (
    <>
      <PagePicker
        initialContentId={data?.parentPageId}
        isDynamic={true}
        onUpdate={(page) => updatePanelDataProperty(panelId, PagesPropertyTypes.PARENT_PAGE_ID, page?.contentId)}
        label="Pages under"
        showOnlyGlobalPages={data?.excludePersonalSpaces || false}
      />
      <LabelMultiSelect
        label="Labels"
        placeholder="Filter by labels"
        initialLabels={data.labels}
        onUpdate={(labels) => {
          updatePanelDataProperty(panelId, PagesPropertyTypes.LABELS, labels);
        }}
      />
      <SpaceMultiSelect
        initialSpaces={data.spaces}
        onUpdate={(spaces) => updatePanelDataProperty(panelId, PagesPropertyTypes.SPACES, spaces)}
        isDynamic={true}
      />
      <PeoplePicker
        isMulti
        initialAccountIDs={data.contributors}
        label="Contributors"
        placeholder="Filter by contributors"
        onUpdate={(users) => updatePanelDataProperty(panelId, PagesPropertyTypes.CONTRIBUTORS, users)}
      />

      <DefaultSortPicker
        initialValue={data.sortBy}
        onUpdate={(sorting) => updatePanelDataProperty(panelId, PagesPropertyTypes.SORT, sorting)}
      />

      <HelperMessage>
        <div style={{ marginRight: "4px", color: "#6b778c" }}> Order by </div>
        <OrderButton sortOrder={sortOrder} onUpdate={handleSortOrderUpdate} />
      </HelperMessage>

      <Divider />

      <SimpleToggle
        label="Exclude personal spaces"
        value={data?.excludePersonalSpaces}
        setValue={(value) => updatePanelDataProperty(panelId, PagesPropertyTypes.EXCLUDE_PERSONAL_SPACES, value)}
      />
    </>
  );
};

const ManualSidebar = ({ panelId, data, updatePanelDataProperty }) => {
  const [pickerKey, setPickerKey] = useState(Math.random().toString(36).substring(7));

  const addNewContentManually = (newItem) => {
    const newManuallyPicked = [...data.manuallyPicked, newItem];
    updatePanelDataProperty(panelId, PagesPropertyTypes.MANUALLY_PICKED, newManuallyPicked);
  };

  const removeContentManually = (itemId) => {
    const newManuallyPicked = data.manuallyPicked.filter((item) => item.id !== itemId);
    updatePanelDataProperty(panelId, PagesPropertyTypes.MANUALLY_PICKED, newManuallyPicked);
  };

  useEffect(() => {
    setPickerKey(Math.random().toString(36).substring(7));
  }, [data.manuallyPicked]);

  return (
    <div style={{ display: "flex", flexDirection: "column", marginTop: "0px" }}>
      <PagePicker
        manuallyPicked={data.manuallyPicked}
        key={pickerKey}
        onUpdate={(selectedPage) => {
          if (data.manuallyPicked.some((item) => item.contentId === selectedPage.contentId)) return;
          if (data.manuallyPicked.length > 0) {
            const currentLastPicked = data.manuallyPicked[data.manuallyPicked.length - 1];
            const position = currentLastPicked.position + 1;
            addNewContentManually({ id: generateId(), position, contentId: selectedPage.contentId });
            setPickerKey(Math.random().toString(36).substring(7));
            return;
          }
          addNewContentManually({ id: generateId(), position: 0, contentId: selectedPage.contentId });
          setPickerKey(Math.random().toString(36).substring(7));
        }}
        label=""
        placeholder="Select page(s)"
        closeMenuOnSelect={false}
      />
      <ManualPagesList
        manuallyPicked={data.manuallyPicked}
        onRemove={(id) => removeContentManually(id)}
        updateOrder={(items) => {
          const newManuallyPicked = items.map(({ id, position, contentId }) => ({
            id,
            position,
            contentId: contentId,
          }));
          updatePanelDataProperty(panelId, PagesPropertyTypes.MANUALLY_PICKED, newManuallyPicked);
        }}
      />
    </div>
  );
};

const LayoutSidebar = ({ panelId, data, updatePanelDataProperty, panelTitle, updatePanelTitle }) => {
  const isSideNews = !!data?.isSideNews;

  const updateElements = (value) => {
    const newElements = !!data.activeElements.find((el) => el === value)
      ? data.activeElements.filter((el) => el !== value)
      : [...data.activeElements, value];
    updatePanelDataProperty(panelId, PagesPropertyTypes.ACTIVE_ELEMENTS, newElements, true);
  };

  return (
    <>
      <TextInputFilter label="Title" value={panelTitle} onUpdate={(value) => updatePanelTitle(panelId, value)} />

      {isSideNews ? data.loadingType === LoadingTypes.DYNAMIC ? <Divider /> : null : <Divider />}

      {!isSideNews && (
        <SelectWithCustomOptions
          initialValue={data.view}
          options={[
            {
              label: "Compact Card",
              value: PagesTypes.CARD,
            },
            {
              label: "Mini Card",
              value: PagesTypes.MINI_CARD,
            },
          ]}
          label="View"
          onUpdate={(newView) => updatePanelDataProperty(panelId, PagesPropertyTypes.VIEW, newView)}
        />
      )}

      {data.loadingType === LoadingTypes.DYNAMIC && (
        <>
          <SelectWithCustomOptions
            initialValue={
              data.pagination === PanelsPaginationTypes.INFINITE ? PanelsPaginationTypes.SHOW_MORE : data.pagination
            }
            options={PaginationOptions}
            label="Pagination"
            onUpdate={(newValue) => updatePanelDataProperty(panelId, PagesPropertyTypes.PAGINATION, newValue)}
          />
          <Divider />
        </>
      )}

      {!isSideNews && (
        <ElementsToggle
          label="Elements to display"
          elements={data.activeElements}
          options={PageElementsOptions[data.view]}
          updateElements={updateElements}
        />
      )}

      {isSideNews && data.loadingType === LoadingTypes.DYNAMIC && (
        <InputFilter
          numberField
          minLength={1}
          initialValue={data.maxNumberOfItems}
          label="Items per page"
          placeholder="Limit the number of pages"
          onUpdate={(value) => updatePanelDataProperty(panelId, PagesPropertyTypes.MAX_ITEMS, value)}
        />
      )}
    </>
  );
};

const FilterSidebar = ({ panelId, data, updatePanelDataProperty }) => {
  return (
    <>
      <DisableDynamicFilterToggle
        initialValue={data.loadingType}
        onUpdate={(newLoadingType) => updatePanelDataProperty(panelId, PagesPropertyTypes.LOADING_TYPE, newLoadingType)}
      />

      {data.loadingType === LoadingTypes.DYNAMIC && (
        <DynamicSidebar panelId={panelId} data={data} updatePanelDataProperty={updatePanelDataProperty} />
      )}

      {data.loadingType === LoadingTypes.MANUAL && (
        <ManualSidebar panelId={panelId} data={data} updatePanelDataProperty={updatePanelDataProperty} />
      )}
    </>
  );
};

const DesignSidebar = ({ panelId, data, type, updatePanelDataProperty }) => {
  const isSideNews = !!data?.isSideNews;
  return (
    <DesignSidebarWrapper>
      <div>
        <FieldContainer>
          <Label>Widget options</Label>
          <SimpleToggle
            label="Display title"
            value={data.displayTitle}
            setValue={(value) => updatePanelDataProperty(panelId, PagesPropertyTypes.DISPLAY_TITLE, value)}
            customStyles={{ padding: "10px 0px 8px 10px" }}
          />
          {!isSideNews && (
            <SimpleToggle
              label="Display box"
              value={data?.displayBox ?? true}
              setValue={(value) => updatePanelDataProperty(panelId, PagesPropertyTypes.DISPLAY_BOX, value)}
              customStyles={{ padding: "10px 0px 8px 10px" }}
            />
          )}
        </FieldContainer>
      </div>
    </DesignSidebarWrapper>
  );
};

function PagesEditor({ panel, selectedTab, updatePanelDataProperty, updatePanelTitle }) {
  const { data, type } = panel;
  return (
    <PanelEditorWrapper>
      {selectedTab === TabTypes.LAYOUT && (
        <LayoutSidebar
          panelId={panel.id}
          data={data}
          updatePanelDataProperty={updatePanelDataProperty}
          panelTitle={panel.panelTitle}
          updatePanelTitle={updatePanelTitle}
        />
      )}
      {selectedTab === TabTypes.CONTENT && (
        <FilterSidebar panelId={panel.id} data={data} updatePanelDataProperty={updatePanelDataProperty} />
      )}
      {selectedTab === TabTypes.DESIGN && (
        <DesignSidebar panelId={panel.id} data={data} type={type} updatePanelDataProperty={updatePanelDataProperty} />
      )}
    </PanelEditorWrapper>
  );
}

export default PagesEditor;
