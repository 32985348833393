import Birthday from "../components/types/Birthday";
import NewStarters from "../components/types/NewStarters";
import OrgChart from "../components/types/orgchart/OrgChart";
import WorkAnniversary from "../components/types/WorkAnniversary";
import { WidgetIds } from "./tabs";

export const AvailableContent = {
  [WidgetIds.BIRTHDAY]: Birthday,
  [WidgetIds.WORK_ANNIVERSARY]: WorkAnniversary,
  [WidgetIds.NEW_STARTERS]: NewStarters,
  [WidgetIds.ORG_CHART]: OrgChart,
  [undefined]: () => <></>,
};
