import React from "react";
import styled from "styled-components";

const StyledButton = styled.button`
  all: unset;
  display: flex;
  align-items: center;
  column-gap: 4px;

  padding: ${({ customPadding }) => customPadding ?? "6px 12px"};
  border-radius: 3px;
  border: ${({ theme }) => `1px solid ${theme.manualOrgChart.buttonBorderColor}`};
  background-color: ${({ theme }) => theme.manualOrgChart.buttonColor};
  box-sizing: border-box;

  cursor: ${({ isDisabled }) => (isDisabled ? "not-allowed" : "pointer")};

  text-align: center;
  color: ${({ theme }) => theme.manualOrgChart.buttonTextColor};
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  white-space: nowrap;

  &:hover {
    background-color: ${({ theme }) => theme.manualOrgChart.buttonColorHover};
  }

  &:active {
    background-color: ${({ theme }) => theme.manualOrgChart.buttonColorActive};
  }
`;

const IconContainer = styled.div`
  width: 24px;
  height: 24px;
  display: grid;
  place-content: center;

  svg,
  rect,
  path {
    color: ${({ iconColor }) => iconColor} !important;
    fill: ${({ iconColor }) => iconColor} !important;
  }
`;

function Button({ onClick, Icon = undefined, iconColor = "#0065ff", text, customPadding, isDisabled = false }) {
  return (
    <StyledButton
      isDisabled={isDisabled}
      customPadding={customPadding}
      onClick={(e) => {
        if (!isDisabled) {
          e.preventDefault();
          onClick();
        }
      }}
    >
      {Icon && (
        <IconContainer iconColor={iconColor}>
          <Icon />
        </IconContainer>
      )}
      {text ?? <></>}
    </StyledButton>
  );
}

export default Button;
