import React from "react";
import styled from "styled-components";
import { Panel, SmallLine, Wrapper } from "./styled";

export const CardsGridWrapper = styled.div`
  display: grid;
  grid-template-columns: ${({ numberOfColumns }) => `repeat(${numberOfColumns}, 1fr)`};
  gap: 1rem;
`;

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 14px;
`;

const PersonWrapper = styled.div`
  display: grid;
  grid-template-columns: 40px auto 32px;
  column-gap: 12px;
  align-items: center;
`;

const PersonImage = styled.img`
  box-sizing: border-box;
  width: 40px;
  height: 40px;
  border: 2px solid #e6ebf0;
  border-radius: 50%;
  object-fit: cover;
  user-select: none;
`;

const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ListName = styled.span`
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #172b4d;
  white-space: nowrap;
`;

const ListField = styled.span`
  font-weight: 500;
  font-size: 9px;
  line-height: 11px;
  color: #c1c7d0;
  margin-top: 1.5px;
`;

export const SmallCardWrapper = styled.div`
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 2.4px 0px rgba(85, 85, 85, 0.25);
`;

export const CardHeader = styled.div`
  height: 55px;
  border-radius: 8px 8px 0 0;
  ${({ backgroundUrl }) =>
    backgroundUrl &&
    `
    background: url("${backgroundUrl}")
  `}
`;

export const SmallCardSkeletonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 6px;
  margin-top: 8px;
`;

export const SmallCardBodyWrapper = styled.div`
  height: 84px;
  border-radius: 0 0 8px 8px;
  position: relative;
  display: grid;
  place-items: center;
  margin-top: -30px;

  img {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    border: 2px solid white;
    object-fit: cover;
  }
`;

export const Skeleton = styled.div`
  width: ${({ width }) => `${width}`};
  height: ${({ height }) => `${height}`};
  border-radius: 8px;
  margin-top: ${({ marginTop }) => `${marginTop}`};
  background: ${({ backgroundColor }) => `${backgroundColor}`};
`;

export const SmallCardFooter = styled.div`
  color: #0065ff;
  font-size: 10px;
  font-weight: 400;
  padding: 8px 0 8px 0;
  display: flex;
  justify-content: center;
`;

export const dummyData = [
  {
    backgroundUrl:
      "https://images.unsplash.com/photo-1484589065579-248aad0d8b13?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=640&h=480&q=80",
    avatarUrl:
      "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=128&h=128&q=80",
  },
  {
    backgroundUrl:
      "https://images.unsplash.com/photo-1497032628192-86f99bcd76bc?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&w=640&h=480&q=80",
    avatarUrl:
      "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&w=128&h=128&q=80",
  },
  {
    backgroundUrl:
      "https://images.unsplash.com/photo-1432821596592-e2c18b78144f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=640&h=480&q=80",
    avatarUrl:
      "https://images.unsplash.com/photo-1607746882042-944635dfe10e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=128&h=128&q=80",
  },
  {
    backgroundUrl:
      "https://images.unsplash.com/photo-1534447677768-be436bb09401?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=640&h=480&q=80",
    avatarUrl:
      "https://images.unsplash.com/photo-1628157588553-5eeea00af15c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=128&h=128&q=80",
  },
];

export const ActiveUsersMain = () => {
  return (
    <CardsGridWrapper numberOfColumns={4}>
      {dummyData.map((data, idx) => (
        <SmallCardWrapper key={idx}>
          <CardHeader />
          <SmallCardBodyWrapper>
            <img src={data.avatarUrl} alt="Profile" />
            <SmallCardSkeletonWrapper>
              <Skeleton width="65px" height="6px" backgroundColor="#DFE1E6" marginTop="0" />
              <div style={{ display: "flex", gap: "4px" }}>
                <Skeleton width="32px" height="4px" backgroundColor="#F4F5F7" marginTop="0" />
                <Skeleton width="20px" height="4px" backgroundColor="#B3D4FF" marginTop="0" />
              </div>
            </SmallCardSkeletonWrapper>
          </SmallCardBodyWrapper>
          <SmallCardFooter>
            <Skeleton width="32px" height="6px" backgroundColor="#DFE1E6" marginTop="0" />
          </SmallCardFooter>
        </SmallCardWrapper>
      ))}
    </CardsGridWrapper>
  );
};

export const ActiveUsersSide = () => {
  return (
    <Wrapper>
      <Panel>
        <ListWrapper>
          <PersonWrapper>
            <PersonImage src="https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=128&h=128&q=80" />

            <DetailsWrapper>
              <ListName>Sarah Connor</ListName>
              <ListField>
                Updated a <span style={{ color: "#0065FF" }}>blog</span>.
              </ListField>
            </DetailsWrapper>

            <SmallLine style={{ marginLeft: "10px" }} width={16} bg="#E4E4E4" />
          </PersonWrapper>

          <PersonWrapper>
            <PersonImage src="https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&w=128&h=128&q=80" />

            <DetailsWrapper>
              <SmallLine width={47} bg="#C1C7D0" />
              <SmallLine style={{ marginTop: "7px" }} width={64} bg="#E4E4E4" />
            </DetailsWrapper>

            <SmallLine width={32} bg="#E4E4E4" />
          </PersonWrapper>

          <PersonWrapper>
            <PersonImage src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&w=128&h=128&q=80" />

            <DetailsWrapper>
              <SmallLine width={47} bg="#C1C7D0" />
              <SmallLine style={{ marginTop: "7px" }} width={64} bg="#E4E4E4" />
            </DetailsWrapper>

            <SmallLine width={32} bg="#E4E4E4" />
          </PersonWrapper>

          <PersonWrapper>
            <PersonImage src="https://images.unsplash.com/photo-1488426862026-3ee34a7d66df?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&w=128&h=128&q=80" />

            <DetailsWrapper>
              <SmallLine width={47} bg="#C1C7D0" />
              <SmallLine style={{ marginTop: "7px" }} width={64} bg="#E4E4E4" />
            </DetailsWrapper>

            <SmallLine width={32} bg="#E4E4E4" />
          </PersonWrapper>
        </ListWrapper>
      </Panel>
    </Wrapper>
  );
};
