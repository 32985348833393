import React from "react";

import { ControlButton, ControlContainer, ControlDescription, ControlTitle, ScreenContainer } from "../../styles";
import { useCosmosAppStore } from "../../cosmos-app-store";

const UnderConstruction = () => {
  const cosmosAppSettings = useCosmosAppStore((state) => state.cosmosAppSettings);

  return (
    <ScreenContainer style={{ display: "grid", placeContent: "center" }}>
      <ControlContainer>
        <img src="/images/cosmos-app/logos/cosmos-logo.svg" alt="Cosmos" width={64} />
        <ControlTitle>{cosmosAppSettings?.underConstruction?.title}</ControlTitle>
        <ControlDescription>{cosmosAppSettings?.underConstruction?.text}</ControlDescription>
      </ControlContainer>

      {cosmosAppSettings?.underConstruction?.button?.label && cosmosAppSettings?.underConstruction?.button?.path && (
        <ControlButton backgroundColor={cosmosAppSettings?.brand?.color}>
          {cosmosAppSettings?.underConstruction?.button?.label}
        </ControlButton>
      )}
    </ScreenContainer>
  );
};

export default UnderConstruction;
