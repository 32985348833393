import React, { useEffect, useRef, useState } from "react";
import Textfield from "@atlaskit/textfield";
import TextArea from "@atlaskit/textarea";
import Toggle from "@atlaskit/toggle";
import IconRenderer from "../../../Shared/Components/Icons/IconRenderer";
import IconDrawer from "../IconDrawer";
import Spinner from "@atlaskit/spinner";
import { useCollectionsStore, useCreateCollectionStore } from "../collection-store";
import { nanoid } from "nanoid";
import { useQuery } from "react-query";
import { fetchRecords } from "../../UserDatabase/datagrid/api";
import { createCollectionInDatabase } from "../api";
import {
  Add,
  Cancel,
  Description,
  FieldWrapper,
  FlexContainer,
  Footer,
  IconBox,
  SpinnerContainer,
  TextfieldWrapper,
  Title,
  Wrapper,
  ToggleWrapper,
  ToggleLabel,
  BackgroundColorWrapper,
  BackgroundColorItem,
  BackgroundColorsWrapper,
} from "./styled-modal";
import { IconTypes } from "../../../Shared/Components/SidebarIconPicker/IconEmojiSidebar";
import { analytics } from "../../../analytics";
import { useTheme } from "styled-components";

const backgroundColors = [
  "#FFFFFF",
  "#ACACF6",
  "#F6BBFD",
  "#C8C0FD",
  "#BED9FD",
  "#A8F3FE",
  "#ACFDEA",
  "#B4FAC7",
  "#FDEFA4",
  "#FBD5AB",
  "#FFE1DE",
];

function CollectionInfoModal({ initialData = undefined, onSaved = undefined, close = undefined }) {
  const { filters, resetFilters, isEditingIcon, setIsEditingIcon } = useCreateCollectionStore((state) => ({
    filters: state.filters,
    resetFilters: state.resetFilters,
    isEditingIcon: state.isEditingIcon,
    setIsEditingIcon: state.setIsEditingIcon,
  }));

  const { setIsCreatingCollection, addCollection, databaseFields } = useCollectionsStore((state) => ({
    setIsCreatingCollection: state.setIsCreatingCollection,
    addCollection: state.addCollection,
    databaseFields: state.databaseFields,
  }));

  const [icon, setIcon] = useState(undefined);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [backgroundColor, setBackgroundColor] = useState("");
  const [hiddenPeopleNetwork, setHiddenPeopleNetwork] = useState(false); //using negative logic because we want existing collections not to be hidden
  const [approxNumberOfUsers, setApproxNumberOfUsers] = useState(0);
  const [loaded, setLoaded] = useState(false);

  const theme = useTheme();

  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (initialData) {
      setIcon(initialData.icon);
      setName(initialData.name);
      setDescription(initialData.description);
      setBackgroundColor(initialData.backgroundColor);
      setHiddenPeopleNetwork(initialData.hiddenPeopleNetwork);
    }
    setLoaded(true);
  }, []);

  const { data: totalSize } = useQuery(["userdatabase-records", { filters }], fetchRecords, {
    enabled: !!filters && filters.every((f) => !!f.value),
    retry: 0,
    select: (response) => {
      const { data } = response;
      if (data.totalSize) return data.totalSize;
      return 0;
    },
  });

  useEffect(() => {
    // reset zIndex for atlaskit-portals
    const portalsContainer = document.getElementsByClassName("atlaskit-portal-container")[0];
    let counter = 1;

    if (portalsContainer) {
      const portals = portalsContainer.getElementsByClassName("atlaskit-portal");
      if (portals) {
        Array.from(portals).forEach((portal) => {
          portal.style.zIndex = 100 * counter;
          counter += 1;
        });
      }
    }
  }, [isEditingIcon]);

  useEffect(() => {
    if (totalSize && filters.length) {
      setApproxNumberOfUsers(totalSize);
    }
  }, [totalSize]);

  const createCollection = () => {
    const newCollection = {
      id: nanoid(16),
      name,
      description,
      backgroundColor,
      icon,
      filters: filters.filter((filter) => filter.value).map(({ fieldTitle, options, ...rest }) => ({ ...rest })),
      approxNumberOfUsers,
      hiddenPeopleNetwork,
    };

    setIsSaving(true);
    createCollectionInDatabase(newCollection)
      .then((res) => {
        addCollection(newCollection);
        resetFilters();
        setIsCreatingCollection(false);
        analytics.logEvent({ name: "Collection", template: "create" });
      })
      .catch((e) => console.log(e))
      .finally(() => setIsSaving(false));
  };

  return loaded ? (
    <>
      <Wrapper style={{ maxWidth: "500px" }}>
        <Title>{initialData ? "Edit collection" : "Create new collection"}</Title>
        <Description style={{ width: "500px" }}>{initialData ? "" : "Start by naming your collection."}</Description>
        <>
          <FlexContainer>
            <FieldWrapper>
              <span className="label">Icon</span>
              <IconBox
                onClick={() => {
                  setIsEditingIcon(true);
                }}
              >
                {(!icon || icon?.type === IconTypes.ICON) && (
                  <IconRenderer
                    icon={icon?.data}
                    onRandomIconSelected={(rndIcon) => setIcon({ data: { ...rndIcon }, type: IconTypes.ICON })}
                  />
                )}
                {icon?.type === IconTypes.EMOJI && <em-emoji shortcodes={icon.data.shortcodes} size="1.2em"></em-emoji>}
              </IconBox>
            </FieldWrapper>

            <FieldWrapper style={{ flex: 1 }}>
              <span className="label">
                Collection name <span style={{ color: "#FF5630" }}>*</span>
              </span>
              <TextfieldWrapper>
                <Textfield
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  placeholder="Type name here"
                  className="collection-text-input"
                />
              </TextfieldWrapper>
            </FieldWrapper>
          </FlexContainer>

          <FieldWrapper style={{ marginTop: "20px", flex: 1 }}>
            <span className="label">Background color</span>
            <BackgroundColorsWrapper>
              {backgroundColors.map((color) => (
                <BackgroundColorWrapper
                  key={color}
                  onClick={() => setBackgroundColor(color)}
                  isSelected={color === backgroundColor}
                >
                  <BackgroundColorItem backgroundColor={color} />
                </BackgroundColorWrapper>
              ))}
            </BackgroundColorsWrapper>
            <span className="description">Helps you to visually connect collections</span>
          </FieldWrapper>

          <FieldWrapper style={{ marginTop: "20px", flex: 1 }}>
            <span className="label">Collection description</span>
            <TextfieldWrapper>
              <TextArea
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Type description here"
                className="collection-text-input"
                style={{
                  maxHeight: "100px",
                  border: "none",
                  backgroundColor: theme.collections.createCollectionDescription,
                  color: theme.collections.createCollectionSelectColor,
                  borderRadius: "3px",
                  "::placeholder": { color: theme.collections.createCollectionSelectColor },
                }}
                minimumRows={4}
              />
            </TextfieldWrapper>
          </FieldWrapper>

          <ToggleWrapper>
            <ToggleLabel>Use in People Network</ToggleLabel>
            <Toggle isChecked={!hiddenPeopleNetwork} onChange={() => setHiddenPeopleNetwork(!hiddenPeopleNetwork)} />
          </ToggleWrapper>
        </>

        <Footer>
          <Cancel
            onClick={() => {
              initialData ? close() : setIsCreatingCollection(false);
              return;
            }}
          >
            Cancel
          </Cancel>
          <Add
            isDisabled={name.trim().length === 0}
            isSaving={isSaving}
            onClick={() => {
              if (name.trim().length === 0) return;

              initialData
                ? onSaved({ icon, name, backgroundColor, description, hiddenPeopleNetwork })
                : createCollection();
            }}
          >
            {isSaving ? (
              <SpinnerContainer>
                <Spinner />
              </SpinnerContainer>
            ) : (
              <>{initialData ? "Save Changes" : "Create collection"}</>
            )}
          </Add>
        </Footer>
      </Wrapper>

      <IconDrawer
        onSelected={(selected, type) => {
          setIcon({ data: selected, type });
        }}
        visible={isEditingIcon}
        onClose={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setIsEditingIcon(false);
        }}
        close={(e) => {
          setIsEditingIcon(false);
        }}
        appIconsDrawer={{ available: false, modifiers: true }}
      />
    </>
  ) : (
    <></>
  );
}

export default CollectionInfoModal;
