import React, { useEffect, useMemo, useState } from "react";
import Grid from "./components/Grid";
import CardMapper from "./components/CardMapper";
import { PanelsPaginationTypes } from "../../../PanelsPagination/panels-pagination-types";
import Pagination from "../../../PanelsPagination/Pagination";
import { useSidebarStore } from "../../../../Dashboard/panels/edit/sidebar/sidebar-store";
import { useSidebarStore as useSidebarStorePeople } from "../../../../PeopleBase/store/sidebar-store";
import PanelWrapper from "../../shared/components/PanelWrapper";
import EmptyState from "../../../../../../../Shared/Components/EmptyState";
import { useTheme } from "styled-components";

function Cards({ cardsType, panelTitle, data, isInPeopleNetwork }) {
  const [page, setPage] = useState(1);
  const setDisableClickOutside = useSidebarStore((state) => state.setDisableClickOutside);
  const setDisableClickOutsidePeople = useSidebarStorePeople((state) => state.setDisableClickOutside);

  const { displayTitle, displayBox, pagination, numberOfColumns, cards } = data;

  const maxItemsPerPage = numberOfColumns;
  const maxPage = Math.ceil(cards?.length / maxItemsPerPage);
  const theme = useTheme();

  const paginatedCards = useMemo(() => {
    if (pagination === PanelsPaginationTypes.NONE || !pagination) return cards;

    const copyOfCards = [...cards];
    const indexOfLast = page * maxItemsPerPage;

    if (pagination === PanelsPaginationTypes.SHOW_MORE) {
      return copyOfCards.slice(0, indexOfLast);
    }

    if (pagination === PanelsPaginationTypes.ARROWS) {
      const indexOfFirst = indexOfLast - maxItemsPerPage;
      return copyOfCards.slice(indexOfFirst, indexOfLast);
    }
  }, [cards, page, maxItemsPerPage, pagination]);

  const hasNextPage = cards > paginatedCards;

  useEffect(() => {
    setPage(1);
  }, [pagination, data.numberOfColumns, cards]);

  return (
    <PanelWrapper panelTitle={panelTitle} displayOptions={{ displayTitle, displayBox }}>
      {cards.length ? (
        <Grid numberOfColumns={numberOfColumns}>
          <CardMapper paginatedCards={paginatedCards} cardData={data} cardsType={cardsType} />
        </Grid>
      ) : (
        <EmptyState
          Image={() => {
            return <img src={`/images/hub/no-cards-empty-state-${theme.global.name}.svg`} alt="" />;
          }}
          title={`No ${panelTitle}`}
          description="There are no cards available."
        />
      )}

      {!!cards.length && (
        <Pagination
          type={pagination}
          page={page}
          updatePage={(newPage) => setPage(newPage)}
          maxPage={maxPage}
          hasNextPage={hasNextPage}
          onWrapperHoverCallback={isInPeopleNetwork ? setDisableClickOutsidePeople : setDisableClickOutside}
        />
      )}
    </PanelWrapper>
  );
}

export default Cards;
