import React, { useEffect, useState } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import styled, { useTheme } from "styled-components";
import { nanoid } from "nanoid";
import Textfield from "@atlaskit/textfield";
import AddIcon from "@atlaskit/icon/glyph/add";
import DragHandlerIcon from "@atlaskit/icon/glyph/drag-handler";
import InlineEdit from "@atlaskit/inline-edit";
import EditorRemoveIcon from "@atlaskit/icon/glyph/editor/remove";
import TextInputFilter from "../../../filter/TextInputFilter";
import SidebarTransition from "../../../../../../../Shared/Components/SidebarTransition";
import SidebarAppPicker, { formatNewPath } from "../../../filter/SidebarAppPicker";
import { usePanelsStore } from "../../../store/panels-store";
import { getMacroSuiteStyleguide } from "../../../api";
import { AppLinks } from "../../../filter/appLinks";
import MacroSuiteInstallationBanner from "../../../../../../ContentBuilder/Shared/MacroSuiteInstallationBanner";
import { FieldContainer } from "../../../filter/styled";
import SimpleToggle from "../../../filter/SimpleToggle";
import { COLOR_TYPES } from "../../../../Settings/General/BrandAndColors/color-types";
import { useCorporateIdentityStore } from "../../../../Settings/General/BrandAndColors/corporate-identity-store";

const AddLinkWrapper = styled.div`
  margin-top: 15px;
  display: flex;
  align-items: center;
  column-gap: 8px;
  cursor: pointer;
`;

const AddLinkText = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: ${({ theme }) => theme.shared.sidebar.title};
`;

const LinkWrapper = styled.div`
  padding-top: ${({ noPaddingTop }) => (noPaddingTop ? "0px" : "20px")};
  padding-bottom: 20px;
  border-bottom: ${({ theme }) => `1px solid ${theme.apps.seperator}`};

  display: flex;
  flex-direction: column;

  ${({ isHoveringOver, orderDirection, theme }) => {
    if (!isHoveringOver) {
      return null;
    }

    return orderDirection === "top-bottom"
      ? `border-bottom: 1px solid ${theme.apps.seperator}`
      : `border-top: 1px solid ${theme.apps.seperator};`;
  }};

  .delete {
    opacity: 0;
    transition: opacity 50ms linear;
  }

  &:hover {
    .delete {
      opacity: 1;
    }
  }
`;

const TopRowWrapper = styled.div`
  display: grid;
  grid-template-columns: 24px 36px auto 24px;
  align-items: center;
  column-gap: 10px;

  form {
    & > div:first-child {
      margin-top: 0px;
    }

    div[data-read-view-fit-container-width~="false"] {
      width: 100%;
    }
  }
`;

const ImageContainer = styled.div`
  border-radius: 5px;
  background: #fff;
  height: 36px;
  width: 36px;
  box-sizing: border-box;
  display: grid;
  place-content: center;
  cursor: pointer;

  transition: background 0.2s;

  &:hover {
    background: #ebecf0;
  }
`;

const AppIconImage = styled.img`
  height: 24px;
  width: 24px;
  object-fit: contain;
`;

const IconContainer = styled.div`
  display: grid;
  place-content: center;
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

const BannerWrapper = styled.div`
  position: sticky;
  bottom: 0px;
  left: 0px;
  padding-top: 25px;
`;

const AppLinksPropertyKeys = {
  LINKS: "links",
  DISPLAY_TITLE: "displayTitle",
};

const LinkPropertyKeys = {
  ICON: "icon",
  TITLE: "title",
  URL: "link",
};

const TopRow = ({
  link,
  reorderPanels,
  setEditingIcon,
  setIsEditingAppIcon,
  handleUpdateEditingLink,
  handleRemoveVideoLink,
  noPaddingTop,
}) => {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: ["panel"],
    drop: () => ({ id: link.id, ...link }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  }));

  const [{ draggedItem }, drag] = useDrag({
    type: "panel",
    item: () => {
      return { id: link.id, type: "panel", ...link };
    },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (item && dropResult) {
        reorderPanels(item.id, dropResult.id);
      }
    },
    collect: (monitor) => ({
      draggedItem: monitor.getItem(),
    }),
  });

  const draggedItemPosition = draggedItem?.order ?? 0;
  const potentialTargetPosition = isOver ? link?.order : 0;

  const theme = useTheme();

  return (
    <LinkWrapper
      ref={drop}
      isHoveringOver={isOver}
      orderDirection={draggedItemPosition < potentialTargetPosition ? "top-bottom" : "bottom-top"}
      noPaddingTop={noPaddingTop}
    >
      <TopRowWrapper>
        <IconContainer ref={drag}>
          <DragHandlerIcon primaryColor={theme.poll.icons} />
        </IconContainer>

        <ImageContainer
          onClick={() => {
            setEditingIcon({ ...link });
            setIsEditingAppIcon(true);
          }}
        >
          <AppIconImage
            src={link.icon.includes("admin.caelor.com") ? formatNewPath(link.icon) : link.icon}
            alt={link.title}
          />
        </ImageContainer>

        <InlineEdit
          defaultValue={link.title}
          editView={({ errorMessage, ...fieldProps }) => <Textfield {...fieldProps} autoFocus />}
          readView={() => (
            <div
              style={{
                display: "flex",
                fontWeight: "400",
                fontSize: "14px",
                lineHeight: "17px",
                color: "#6b778c",
                height: "36px",
                padding: "0px 5px",
                alignItems: "center",
              }}
              data-testid="read-view"
            >
              <div>{link.title || "Click to enter a value"}</div>
            </div>
          )}
          onConfirm={(value) => handleUpdateEditingLink(LinkPropertyKeys.TITLE, value, link.id)}
        />
        <IconContainer className="delete" onClick={() => handleRemoveVideoLink(link.id)}>
          <EditorRemoveIcon primaryColor={theme.poll.icons} />
        </IconContainer>
      </TopRowWrapper>

      <TextInputFilter
        value={link.link}
        placeholder="Insert link here"
        onUpdate={(v) => handleUpdateEditingLink(LinkPropertyKeys.URL, v, link.id)}
        customMargin="10px"
      />
    </LinkWrapper>
  );
};

const InfoBannerText = () => (
  <>
    Upload more logos with <span>Optics</span> Styleguide
  </>
);

function AppLinksEditor({ panel, updatePanelDataProperty, updatePanelTitle, isMacroSuiteInstalled }) {
  const [editingIcon, setEditingIcon] = useState({});
  const [appIcons, setAppIcons] = useState([]);
  const { data } = panel;

  const colors = useCorporateIdentityStore((state) => state.colors);
  const primaryColor = colors[COLOR_TYPES.PRIMARY] || "#0065ff";

  useEffect(() => {
    if (isMacroSuiteInstalled) {
      getMacroSuiteStyleguide()
        .then((res) => {
          if (res?.data?.exists && res?.data?.body?.appicons) {
            setAppIcons(res.data.body.appicons);
          } else {
            setAppIcons(AppLinks);
          }
        })
        .catch((err) => setAppIcons(AppLinks));
    } else {
      setAppIcons(AppLinks);
    }
  }, []);

  const { isEditingAppIcon, setIsEditingAppIcon } = usePanelsStore((state) => ({
    isEditingAppIcon: state.isEditingAppIcon,
    setIsEditingAppIcon: state.setIsEditingAppIcon,
  }));

  const handleUpdateEditingLink = (key, value, id) => {
    const copyOfLinks = [
      ...data.links.map((link) => {
        return link.id === id ? { ...link, [key]: value } : { ...link };
      }),
    ];
    updatePanelDataProperty(panel.id, AppLinksPropertyKeys.LINKS, copyOfLinks);
  };

  const handleUpdateIcon = (iconPath, title, id) => {
    const copyOfLinks = [
      ...data.links.map((link) => {
        return link.id === id
          ? { ...link, [LinkPropertyKeys.ICON]: iconPath, [LinkPropertyKeys.TITLE]: title }
          : { ...link };
      }),
    ];
    updatePanelDataProperty(panel.id, AppLinksPropertyKeys.LINKS, copyOfLinks);
  };

  const handleRemoveVideoLink = (linkId) => {
    const copyOfLinks = [...data.links.filter((link) => link.id !== linkId)];
    const orderedLinks = resetOrder(copyOfLinks);
    updatePanelDataProperty(panel.id, AppLinksPropertyKeys.LINKS, orderedLinks);
  };

  const resetOrder = (list) => {
    const resetList = list.map((c, index) => {
      const slide = c;
      slide.order = index + 1;
      return slide;
    });
    return resetList;
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return resetOrder(result);
  };

  const reorderPanels = (sourceId, targetId) => {
    if (sourceId === targetId) {
      return;
    }

    const content = [...data.links];
    const sourceIndex = content.findIndex((p) => p.id === sourceId);
    const targetIndex = content.findIndex((p) => p.id === targetId);

    const items = reorder(content, sourceIndex, targetIndex);
    updatePanelDataProperty(panel.id, AppLinksPropertyKeys.LINKS, items);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <FieldContainer style={{ marginTop: "0px" }}>
        <SimpleToggle
          label="Display title"
          value={!!data?.displayTitle}
          setValue={(value) => updatePanelDataProperty(panel.id, AppLinksPropertyKeys.DISPLAY_TITLE, value)}
          customStyles={{ padding: "0px" }}
        />
      </FieldContainer>

      {data?.displayTitle && (
        <TextInputFilter
          label="Title"
          value={panel?.panelTitle || ""}
          onUpdate={(value) => updatePanelTitle(panel.id, value)}
        />
      )}

      <div style={{ marginTop: "20px", paddingRight: "5px" }}>
        <DndProvider backend={HTML5Backend}>
          {data.links
            .sort((a, b) => a.order - b.order)
            .map((link, index) => (
              <TopRow
                key={link.id}
                link={link}
                reorderPanels={reorderPanels}
                handleRemoveVideoLink={handleRemoveVideoLink}
                handleUpdateEditingLink={handleUpdateEditingLink}
                setEditingIcon={setEditingIcon}
                setIsEditingAppIcon={setIsEditingAppIcon}
                noPaddingTop={index === 0}
              />
            ))}
        </DndProvider>

        <AddLinkWrapper
          onClick={() => {
            const orderedNewPanels = resetOrder([
              ...data.links,
              {
                id: nanoid(6),
                icon: "https://caelor-apps.web.app/images/icons/Caelor_Black_Symbol.png",
                title: "Caelor",
                link: "https://www.caelor.com",
                order: data.links.length + 1,
              },
            ]);
            updatePanelDataProperty(panel.id, AppLinksPropertyKeys.LINKS, orderedNewPanels);
          }}
        >
          <AddIcon primaryColor={primaryColor} />
          <AddLinkText>Add new link</AddLinkText>
        </AddLinkWrapper>
      </div>

      <SidebarTransition
        key="app-links-app-icon"
        isOpen={isEditingAppIcon}
        close={() => setIsEditingAppIcon(false)}
        width={360}
        title="App Icons"
        containerStyles={{ zIndex: "10015" }}
        onArrowBackClick={() => setIsEditingAppIcon(false)}
        useArrowBack
      >
        <SidebarAppPicker
          customIcons={appIcons}
          setCustomIcons={setAppIcons}
          appIcon={editingIcon?.icon}
          isMacroSuiteInstalled={isMacroSuiteInstalled}
          onIconSelect={({ path, title }) => {
            handleUpdateIcon(path, title, editingIcon.id);
            setIsEditingAppIcon(false);
            setEditingIcon({});
          }}
        />
        {!isMacroSuiteInstalled && (
          <BannerWrapper>
            <MacroSuiteInstallationBanner HeaderParagraphText={InfoBannerText} />
          </BannerWrapper>
        )}
      </SidebarTransition>
    </div>
  );
}

export default AppLinksEditor;
