import React, { useEffect, useRef, useState } from "react";
import styled, { useTheme } from "styled-components";

const InputContainer = styled.div`
  box-sizing: border-box;
  background-color: ${({ backgroundColor, theme }) =>
    backgroundColor ?? theme.navigation.settings.entryAndSubEntryInputBackground};
  border: 1px solid ${({ theme }) => theme.navigation.settings.entryAndSubEntryInputBorder};
  border-radius: 3px;
  width: 100%;
  height: ${({ isTextarea }) => (isTextarea ? "auto" : "40px")};

  display: flex;
  align-items: center;
`;

const StyledInput = styled.input`
  outline: none;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  background-color: transparent;
  flex: 1;
  padding: 8px 3px 8px 8px;

  border: none;

  ::placeholder {
    color: #7a869a;
  }
`;

const StyledTextarea = styled.textarea`
  outline: none;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  background-color: transparent;
  flex: 1;
  padding: 8px 3px 8px 8px;
  font-family: Rubik, system-ui, sans-serif;

  border: none;

  ::placeholder {
    color: #7a869a;
  }
`;

function CustomInput({ initialValue, placeholder, onChange, onBlur = () => {}, isTextarea = false, backgroundColor }) {
  const [inputValue, setInputValue] = useState("");
  const theme = useTheme();
  const [bgColor, setBgColor] = useState(backgroundColor || theme.navigation.settings.entryAndSubEntryInputBackground);

  useEffect(() => {
    setInputValue(initialValue || "");
  }, [initialValue]);

  const handleChange = (value) => {
    setInputValue(value);
    onChange(value);
  };

  return (
    <InputContainer className="input-container" isTextarea={isTextarea} backgroundColor={bgColor}>
      {isTextarea ? (
        <StyledTextarea
          rows={5}
          placeholder={placeholder || "Write text"}
          value={inputValue}
          onClick={(e) => e.target.focus()}
          onChange={(e) => handleChange(e.target.value)}
          onBlur={onBlur}
          onMouseDown={(e) => e.stopPropagation()}
        />
      ) : (
        <StyledInput
          type="text"
          placeholder={placeholder || "Write text"}
          value={inputValue}
          onChange={(e) => handleChange(e.target.value)}
          onClick={(e) => e.target.focus()}
          onBlur={onBlur}
          onMouseDown={(e) => e.stopPropagation()}
        />
      )}
    </InputContainer>
  );
}

export default CustomInput;
