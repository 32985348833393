import create from "zustand";
import { Pages } from "./pages";

export const usePageStore = create((set, get) => ({
  selectedPage: Pages.DASHBOARD,
  setSelectedPage: (newPage, shouldRefresh = true) => {
    if (shouldRefresh) {
      window.AP.navigator.go("addonModule", {
        addonKey: "com.caelor.confluence.cloud.cosmos",
        moduleKey: "caelor-cosmos-portal",
        customData: { cosmosPage: newPage },
      });
    } else {
      set((state) => ({ selectedPage: newPage }));
    }
  },
}));
