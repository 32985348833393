/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect, useContext, useCallback, useRef } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import PageIcon from "@atlaskit/icon-object/glyph/page/16";
import BlogIcon from "@atlaskit/icon-object/glyph/blog/16";

import Textfield from "@atlaskit/textfield";
import Spinner from "@atlaskit/spinner";
import { AsyncSelect } from "@atlaskit/select";
import ImgIcon from "@atlaskit/icon/glyph/media-services/image";
import VideoIcon from "@atlaskit/icon/glyph/media-services/video";

import { BackgroundDialogContext } from "../Context";

import { getPages, searchPages, getPageAttachments } from "../RestService";

import { AttachmentsTable } from "../styled/StyledComponents";

import UploadSection from "./UploadSection";
import { IMAGE_TYPES } from "../ImageSourceTypes";

import { useTheme } from "styled-components";

const TextfieldContainer = styled.div`
  & > div:first-child {
    background: ${({ theme }) => theme.sidebar.inputBackground};
    border-radius: 3px;
    border-width: 0px;
    height: 40px;

    .input-link {
      font-weight: 400 !important;
      font-size: 14px !important;
      line-height: 17px !important;
      color: #172b4d !important;
      height: 40px !important;
    }

    &:focus-within {
      background: ${({ theme }) => theme.sidebar.inputBackground} !important;
    }
  }
`;

const SelectContainer = styled.div`
  & > div:first-child {
    font-size: 14px;

    .page-select__control {
      background: ${({ theme }) => theme.sidebar.background};
      border-radius: 3px;
      border-color: ${({ theme }) => theme.sidebar.seperator};
    }

    .page-select__menu {
      margin-left: 2px;
    }
  }
`;

const IconContainer = styled.div`
  display: grid;
  place-content: center;
  width: 16px;
  height: 16px;
`;

const Label = ({ label, type }) => (
  <div
    style={{
      fontSize: "14px",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      maxWidth: "340px",
      overflow: "hidden",
      display: "flex",
      alignItems: "center",
      columnGap: "8px",
    }}
  >
    <IconContainer>{type === "blogpost" ? <BlogIcon /> : <PageIcon />}</IconContainer>
    {label}
  </div>
);

function Attachments({ showCurrentPageInAttachments, hasUpload, showVideoInAttachments }) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [input, setInput] = useState("");
  const [page, setPage] = useState({});
  const [macroLocation, setMacroLocation] = useState();
  const [availablePages, setAvailablePages] = useState([]);
  const [availableAttachments, setAvailableAttachments] = useState([]);

  const { image, setImage, isMulti } = useContext(BackgroundDialogContext);

  const theme = useTheme();

  const createOptions = useCallback(
    (unstyledPages) =>
      unstyledPages.map((p) => ({
        value: p.value,
        label: <Label label={p.label} type={p.type} />,
      })),
    [],
  );

  const isRowSelected = (img) => {
    if (isMulti) {
      return image.find(({ id }) => id === img.id);
    }

    return image.id === img.id;
  };

  useEffect(() => {
    (async () => {
      if (page?.value) {
        try {
          const pageAttachments = await getPageAttachments(page.value);
          if (!pageAttachments.length) {
            setAvailableAttachments([]);
          } else {
            const formattedAttachments = pageAttachments
              .map((att) => ({
                id: att.id,
                title: att.title,
                type: att.extensions.mediaType,
                size: Math.round((att.extensions.fileSize / 1048576 + Number.EPSILON) * 100) / 100,
                url: `${window.AP._hostOrigin}/wiki${att._links.download}`,
                isVideo: att.metadata.mediaType === "video/mp4",
              }))
              .filter((att) => {
                if (showVideoInAttachments) return true;
                return !att.isVideo;
              });
            setAvailableAttachments([...formattedAttachments]);
          }
        } catch (err) {
          setError(true);
          setIsLoading(false);
          setAvailableAttachments([]);
        }
      }
    })();
  }, [page]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      window.AP.navigator.getLocation(async (location) => {
        const { contentId } = location.context;
        const currentPage = {
          value: contentId,
          label: location?.context?.contentType === "blogpost" ? "Current blog" : "Current page",
          type: location?.context?.contentType,
        };
        const [pageForSelect] = createOptions([currentPage]);

        setMacroLocation(location);
        setPage(showCurrentPageInAttachments ? { ...pageForSelect } : undefined);

        let pages = await getPages();
        pages = pages.filter((opt) => opt.value !== contentId);

        const pagesForSelect = createOptions(showCurrentPageInAttachments ? [currentPage, ...pages] : [...pages]);

        setAvailablePages([...pagesForSelect]);
        setIsLoading(false);
      });
    })();
  }, []);

  const handlePageSearch = async (e) => {
    const pages = await searchPages(e);
    const pagesForSelect = createOptions(pages);
    return pagesForSelect;
  };

  if (isLoading) {
    return (
      <div style={{ marginTop: "125px", display: "grid", placeContent: "center" }}>
        <Spinner size="large" />
      </div>
    );
  }

  return (
    <div>
      <div style={{ display: "grid", rowGap: "16px" }}>
        <SelectContainer>
          <AsyncSelect
            cacheOptions
            placeholder="Select a page"
            defaultValue={page}
            defaultOptions={availablePages}
            loadOptions={handlePageSearch}
            onChange={setPage}
            classNamePrefix="page-select"
          />
        </SelectContainer>
      </div>

      {hasUpload && (
        <UploadSection
          selectedPage={page?.value}
          isDraft={macroLocation?.target !== "contentedit"}
          loadAttachments={() => setPage({ ...page })}
          isDisabled={!page?.value}
        />
      )}

      {availableAttachments.length ? (
        <AttachmentsTable>
          <thead>
            <tr>
              <td width="40">Type</td>
              <td>Title</td>
              <td>Size</td>
            </tr>
          </thead>
          <tbody>
            {availableAttachments
              .filter((img) => img.title.toLowerCase().includes(input.toLowerCase()))
              .map((img) => (
                <tr
                  key={img.id}
                  style={{
                    color: isRowSelected(img)
                      ? theme.global.primaryColor
                      : theme.shared.settings.general.admin.emptyMessageColor,
                  }}
                  onClick={() => {
                    if (img.isVideo) {
                      const video = {
                        source: IMAGE_TYPES.VIDEO,
                        link: img.url,
                        id: img.id,
                      };
                      setImage(video);
                    } else if (isMulti) {
                      if (image.find(({ id }) => id === img.id)) {
                        setImage([...image.filter(({ id }) => id !== img.id)]);
                      }

                      const imageToSave = [
                        ...image,
                        {
                          source: IMAGE_TYPES.ATTACHMENTS,
                          link: img.url,
                          id: img.id,
                        },
                      ];
                      setImage(imageToSave);
                    } else {
                      setImage({
                        source: IMAGE_TYPES.ATTACHMENTS,
                        link: img.url,
                        id: img.id,
                      });
                    }
                  }}
                >
                  <td>{["video/mp4", "image/gif"].includes(img.type) ? <VideoIcon /> : <ImgIcon />}</td>
                  <td>{img.title}</td>
                  <td>{`${img.size} MB`}</td>
                </tr>
              ))}
          </tbody>
        </AttachmentsTable>
      ) : (
        <div style={{ marginTop: "20px", fontSize: "12px", textAlign: "center" }}>
          {error && "Something went wrong. Please try refreshing."}
        </div>
      )}
    </div>
  );
}

Label.propTypes = {
  label: PropTypes.string.isRequired,
};

export default Attachments;
