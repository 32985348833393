import create from "zustand";

export const useWidgetsStore = create((set, get) => ({
  selectedTab: undefined,
  setSelectedTab: (selectedTab) => set((state) => ({ selectedTab })),

  widgetSettings: {},
  setWidgetSettings: (savedSettings) => set((state) => ({ widgetSettings: savedSettings })),
  updateWidgetSettings: (widgetId, widgetSetting) => {
    const currentWidgetSettings = { ...get().widgetSettings };
    return set((state) => ({ widgetSettings: { ...currentWidgetSettings, [widgetId]: widgetSetting } }));
  },

  databaseFields: [],
  setDatabaseFields: (fields) => set((state) => ({ databaseFields: fields })),

  isDbInit: false,
  setIsDbInit: (isInit) => set((state) => ({ isDbInit: isInit })),
}));
