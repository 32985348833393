import React, { useEffect, useState } from "react";
import Spinner from "@atlaskit/spinner";
import { useInfiniteQuery } from "react-query";
import { SpinnerContainer } from "../../../styled/pages";
import { getFavoriteAndWatchedSpaces, mapSpace } from "../apiSpaces";
import { SectionWrapper, SmallTitle, Subtitle } from "../styled/space";
import { SpaceCardsGrid, SpaceCardsList } from "../Templates/SpaceCards";
import { TemplateType } from "../Templates/template-type";
import { ViewType } from "../Templates/view-type";
import { useSpaceDirectoryStore } from "../space-directory-store";

const viewCardTemplates = {
  [ViewType.GRID]: SpaceCardsGrid,
  [ViewType.LIST]: SpaceCardsList,
};

const ITEM_LIMIT = 3;

function StarredAndWatching() {
  const [spaceResults, setSpaceResults] = useState([]);

  const selectedView = useSpaceDirectoryStore((state) => state.selectedView);

  const { isError, data, error, isFetching, hasNextPage, fetchNextPage, refetch } = useInfiniteQuery(
    ["favorite-space", { limit: 12 }],
    getFavoriteAndWatchedSpaces,
    {
      retry: 0,
      cacheTime: 0,
      select: (response) => {
        const pages = [];
        response.pages.forEach((page) => {
          const body = JSON.parse(page.body);
          const resultsInPage = body.results.map(({ space }) => mapSpace(space));
          pages.push({ ...body, results: resultsInPage });
        });
        return { pages, pageParams: response.pageParams };
      },
      getNextPageParam: (lastPage) => {
        const body = JSON.parse(lastPage.body);
        if (body._links.next) {
          const urlParams = new URLSearchParams(encodeURI(body._links.next));
          const cursor = urlParams.get("cursor");
          return cursor;
        }
        return false;
      },
    }
  );

  useEffect(() => {
    if (data?.pages?.length) {
      const allPages = data.pages.flat();
      const results = [];
      for (const page of allPages) {
        results.push(...page.results);
      }

      setSpaceResults(results.filter((value, index, self) => index === self.findIndex((t) => t.key === value.key)));
    }
  }, [data]);

  useEffect(() => {
    window.AP.events.on("space-content-updated", () => refetch());
  }, []);

  if (isError || error) return <div>Something went wrong. Please refresh and try again.</div>;

  const Template = viewCardTemplates[selectedView];

  return (
    <SectionWrapper>
      <SmallTitle>Starred and Watching</SmallTitle>

      {isFetching ? (
        <SpinnerContainer>
          <Spinner size="xlarge" />
        </SpinnerContainer>
      ) : (
        <Template data={spaceResults} itemLimit={ITEM_LIMIT} template={TemplateType.SPACE_SIMPLE_CARD} hasActions />
      )}
    </SectionWrapper>
  );
}

export default StarredAndWatching;
