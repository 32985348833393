import {
  createRecordsWithAccountIds,
  deleteRecordsWithAccountIds,
  fetchUsersInSystem,
  getAllExistingUserAccountIds,
} from "./datagrid/api";

import { refreshAzureData, assignAzureDataToRecordsWithAccountIds } from "./menubar/azure/api";

export async function resyncUsers(syncGuestUsers) {
  const response = await getAllExistingUserAccountIds();
  const usersInSystem = await fetchUsersInSystem(syncGuestUsers);
  const userIdsInDatabase = response.data;

  const usersToRemove = usersInSystem?.length ? userIdsInDatabase.filter((x) => !usersInSystem.includes(x)) : [];
  const newUsers = usersInSystem.filter((x) => !userIdsInDatabase.includes(x));

  if (newUsers.length > 0) {
    await createRecordsWithAccountIds(newUsers, false);
  }
  if (usersToRemove.length > 0) {
    await deleteRecordsWithAccountIds(usersToRemove);
  }

  return newUsers.length > 0 || usersToRemove.length > 0;
}

export async function assignAzureDataToAllUsers(syncGuestUsers, isCacheEnabled) {
  const response = await getAllExistingUserAccountIds();
  const usersInSystem = await fetchUsersInSystem(syncGuestUsers);
  const userIdsInDatabase = response.data;
  const usersToRemove = usersInSystem?.length ? userIdsInDatabase.filter((x) => !usersInSystem.includes(x)) : [];

  await assignAzureDataToRecordsWithAccountIds(usersInSystem, isCacheEnabled);

  if (usersToRemove.length) {
    await deleteRecordsWithAccountIds(usersToRemove);
  }
}

export async function resyncAzureUsers(syncGuestUsers) {
  const response = await getAllExistingUserAccountIds();
  const usersInSystem = await fetchUsersInSystem(syncGuestUsers);
  const userIdsInDatabase = response.data;
  const usersToRemove = usersInSystem?.length ? userIdsInDatabase.filter((x) => !usersInSystem.includes(x)) : [];

  await refreshAzureData(usersInSystem);

  if (usersToRemove.length) {
    await deleteRecordsWithAccountIds(usersToRemove);
  }
}
