import React from "react";

export const Landscape = ({ isActive }) => (
  <svg width="16" height="12" viewBox="0 0 16 12" fill="none">
    <rect y="0.666992" width="16" height="10.6667" rx="2" fill={isActive ? "#0065ff" : "#7A869A"} />
  </svg>
);

export const Portrait = ({ isActive }) => (
  <svg width="12" height="16" viewBox="0 0 12 16" fill="none">
    <rect x="0.667969" width="10.6667" height="16" rx="2" fill={isActive ? "#0065ff" : "#7A869A"} />
  </svg>
);

export const Square = ({ isActive }) => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none">
    <rect x="0.332031" y="0.333008" width="13.3333" height="13.3333" rx="2" fill={isActive ? "#0065ff" : "#7A869A"} />
  </svg>
);
