import React, { useState, useEffect } from "react";
import styled, { useTheme } from "styled-components";
import StarFilledIcon from "@atlaskit/icon/glyph/star-filled";
import EditIcon from "@atlaskit/icon/glyph/edit";
import WatchIcon from "@atlaskit/icon/glyph/watch";
import WatchFilledIcon from "@atlaskit/icon/glyph/watch-filled";
import AvatarGroup from "@atlaskit/avatar-group";
import {
  addCurrentUserAsSpaceWatcher,
  getCurrentUserSpaceFavoriteStatus,
  getCurrentUserSpaceWatchStatus,
  getUsersForArrayOfGroups,
  removeCurrentUserAsSpaceWatcher,
} from "../apiSpaces";
import { getAdminGroups, getAdminUsers } from "./dataHelpers";
import { useCorporateIdentityStore } from "../../Settings/General/BrandAndColors/corporate-identity-store";
import { COLOR_TYPES } from "../../Settings/General/BrandAndColors/color-types";
import { useCurrentUserStore } from "../../../current-user-store";
import { createProfileLink } from "../../PeopleBase/Views/createProfileLink";

const HeaderTitle = styled.span`
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  color: ${({ theme }) => theme.space.listView.headerTitleColor};
`;

const CardWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: ${({ isAnonymous }) => (isAnonymous ? "5fr 3fr 90px" : "4fr 3fr 2.5fr 5fr 90px")};
  align-items: center;
  column-gap: 10px;
  padding: 8px 0px;
`;

const IconTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 11px;

  cursor: pointer;
`;

const Title = styled.span`
  font-weight: 600;
  font-size: 13.1299px;
  line-height: 17px;
  color: ${({ theme }) => theme.space.listView.cardWrapperTitle};
`;

const SpaceIcon = styled.img`
  width: 36px;
  height: 36px;
  border-radius: ${({ isPersonal, borderRadius }) => (isPersonal ? "50%" : borderRadius)};
  box-sizing: border-box;
`;

const CreatorLink = styled.span`
  color: #0065ff;
  cursor: pointer;

  font-weight: 400;
  font-size: 12.7641px;
  line-height: 16px;

  display: flex;
  align-items: center;
`;

const IconContainer = styled.div`
  display: grid;
  place-content: center;
  cursor: pointer;

  ${({ small }) => small && `svg{ width: 21px; height: 21px;}`};
`;

const ActionsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 24px);
  align-items: center;
  column-gap: 5px;
`;

const LabelContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 8px;
  row-gap: 4px;
`;

const Label = styled.div`
  display: inline-block;
  margin-bottom: 0;
  border-radius: 12px;
  padding: 3px 10px;
  max-width: 100%;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: ${({ textColor }) => textColor};
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

const AvatarLink = styled.a`
  height: 32px;
  width: 32px;
  -webkit-box-align: stretch;
  align-items: stretch;
  background-color: ${({ theme }) => theme.space.listView.cardAvatarShadow};
  border-radius: 50%;
  box-sizing: content-box;
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  outline: none;
  overflow: hidden;
  transform: translateZ(0px);
  transition:
    transform 200ms ease 0s,
    opacity 200ms ease 0s;
  box-shadow: 0 0 0 2px ${({ theme }) => theme.space.listView.cardAvatarShadow};
  border: none;
  margin: 2px;
  padding: 0px;
  font-size: inherit;
  font-family: inherit;
  position: relative;
  cursor: pointer;
`;

const AvatarImage = styled.img`
  display: flex;
  width: 100%;
  height: 100%;
  flex: 1 1 100%;
  border-radius: 50%;
  max-width: 100%;
`;

export const ListHeader = () => {
  const isAnonymous = useCurrentUserStore((state) => state.isAnonymous);
  const theme = useTheme();

  return (
    <CardWrapper
      isAnonymous={isAnonymous}
      style={{
        padding: "0px 0px 5px 0px",
        marginBottom: "7px",
        borderBottom: `1px solid ${theme.space.listView.cardWrapperBorderBottomColor}`,
      }}
    >
      <HeaderTitle>Space name</HeaderTitle>
      {!isAnonymous && (
        <>
          <HeaderTitle>Created by</HeaderTitle>
          <HeaderTitle>Admins</HeaderTitle>
        </>
      )}
      <HeaderTitle>Categories</HeaderTitle>
      <HeaderTitle>Actions</HeaderTitle>
    </CardWrapper>
  );
};

const Actions = ({ isWatching, isFavorite, updateWatching, setIsModalOpen, setUpdatingSpace, hasEditPermissions }) => {
  const theme = useTheme();

  return (
    <ActionsWrapper>
      <IconContainer
        onClick={(e) => {
          if (hasEditPermissions) {
            e.preventDefault();
            setUpdatingSpace();
            setIsModalOpen(true);
          }
        }}
      >
        {hasEditPermissions && <EditIcon primaryColor={theme.poll.icons} />}
      </IconContainer>

      <IconContainer
        onClick={(e) => {
          e.preventDefault();
          updateWatching(isWatching);
        }}
      >
        {isWatching ? (
          <WatchFilledIcon primaryColor={theme.poll.iconsSelected} />
        ) : (
          <WatchIcon primaryColor={theme.poll.icons} />
        )}
      </IconContainer>

      <IconContainer style={{ cursor: "default" }}>
        {isFavorite && <StarFilledIcon primaryColor="#FFC400" />}
      </IconContainer>
    </ActionsWrapper>
  );
};

const CustomAvatar = (props) => {
  return (
    <AvatarLink>
      <AvatarImage src={props.icon} />
    </AvatarLink>
  );
};

function SpaceListCard({ spaceInfo, setIsModalOpen, setUpdatingSpace, currentUserId }) {
  const [isError, setIsError] = useState(false);
  const [isLoadingWatchStatus, setIsLoadingWatchStatus] = useState(true);
  const [isLoadingFavoriteStatus, setIsLoadingFavoriteStatus] = useState(true);
  const [isWatching, setIsWatching] = useState(false);
  const [isFavorite, setIsFavorite] = useState(false);

  const [admins, setAdmins] = useState([]);

  const isAnonymous = useCurrentUserStore((state) => state.isAnonymous);

  const { colors, borderRadius } = useCorporateIdentityStore((state) => ({
    colors: state.colors,
    borderRadius: state.borderRadius,
  }));
  const primaryColor = colors ? colors[COLOR_TYPES.PRIMARY] : "#0065ff";
  const theme = useTheme();

  useEffect(() => {
    const getAllAdministrators = async () => {
      try {
        const adminUsers = getAdminUsers(spaceInfo.permissions);
        const adminGroups = getAdminGroups(spaceInfo.permissions);
        const allUsersFromGroups = await getUsersForArrayOfGroups(adminGroups);
        const users = allUsersFromGroups.flat();

        const allUsers = [...new Set([...adminUsers, ...users])].filter(
          (value, index, self) => index === self.findIndex((t) => t.id === value.id),
        );
        setAdmins([...allUsers]);
      } catch (error) {
        setAdmins([]);
      }
    };

    getAllAdministrators();
  }, []);

  useEffect(() => {
    setIsLoadingWatchStatus(true);
    setIsLoadingFavoriteStatus(true);
    getCurrentUserSpaceWatchStatus(spaceInfo.key)
      .then((res) => {
        if (res.body) {
          const body = JSON.parse(res.body);
          setIsWatching(body.watching);
        }
      })
      .catch((err) => setIsError(true))
      .finally(() => setIsLoadingWatchStatus(false));

    getCurrentUserSpaceFavoriteStatus(spaceInfo.key)
      .then((res) => {
        if (res.body) {
          const parsed = JSON.parse(res.body);
          if (parsed?.results?.length) {
            setIsFavorite(true);
          } else {
            setIsFavorite(false);
          }
        }
      })
      .catch((err) => setIsError(true))
      .finally(() => setIsLoadingFavoriteStatus(false));
  }, []);

  const removeWatcher = () => {
    setIsWatching(false);
    removeCurrentUserAsSpaceWatcher(spaceInfo.key)
      .then((res) => {
        if (!res?.data?.success) {
          setIsWatching(true);
        }
      })
      .catch((e) => setIsError(true));
  };

  const addWatcher = () => {
    setIsWatching(true);
    addCurrentUserAsSpaceWatcher(spaceInfo.key)
      .then((res) => {
        if (!res?.data?.success) {
          setIsWatching(false);
        }
      })
      .catch((e) => setIsError(true));
  };

  const checkIfCurrentUserHasPermissionToEdit = () => {
    if (!spaceInfo?.permissions?.length || !currentUserId || spaceInfo?.type === "personal") return false;

    let hasPermission;
    spaceInfo?.permissions?.forEach((permission) => {
      const permissionForUser = permission?.subjects?.user?.results?.find((user) => user.accountId === currentUserId);
      if (permissionForUser) hasPermission = true;
    });
    return !!hasPermission;
  };

  return (
    <CardWrapper isAnonymous={isAnonymous}>
      <IconTitleWrapper onClick={() => window.open(spaceInfo.url, "_blank")}>
        <SpaceIcon src={spaceInfo.icon} isPersonal={spaceInfo.type === "personal"} borderRadius={borderRadius} />
        <Title>{spaceInfo.name}</Title>
      </IconTitleWrapper>

      {!isAnonymous && (
        <>
          <CreatorLink
            onClick={(e) => {
              e.preventDefault();
              window.open(createProfileLink(spaceInfo.creatorId));
            }}
          >
            <span style={{ color: primaryColor }}>{spaceInfo.createdBy}</span>
          </CreatorLink>

          <AvatarGroup
            appearance="stack"
            data={admins}
            overrides={{
              Avatar: {
                render: (Component, props, index) => {
                  return <CustomAvatar {...props} />;
                },
              },
            }}
          />
        </>
      )}

      <LabelContainer>
        {spaceInfo.labels?.map((label) => (
          <Label backgroundColor={theme.shared.label.background} textColor={theme.shared.label.text}>
            {label}
          </Label>
        ))}
      </LabelContainer>

      {!isLoadingWatchStatus && !isLoadingFavoriteStatus && !isError ? (
        <Actions
          isWatching={isWatching}
          isFavorite={isFavorite}
          updateWatching={(watching) => {
            watching ? removeWatcher() : addWatcher();
          }}
          setIsModalOpen={setIsModalOpen}
          setUpdatingSpace={() => setUpdatingSpace(spaceInfo)}
          hasEditPermissions={checkIfCurrentUserHasPermissionToEdit(currentUserId, spaceInfo.permissions)}
        />
      ) : (
        <div />
      )}
    </CardWrapper>
  );
}

export default SpaceListCard;
