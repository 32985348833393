import React, { useEffect, useState, useRef } from "react";
import { CheckboxSelect } from "@atlaskit/select";

import { FilterFieldWrapper } from "./styled";
import { CustomPlaceholder } from "../../../Hub/Components/Shared/CheckboxSelect/CustomCheckboxSelectComponents";
import { SIMPLE_SELECT_STYLES } from "../../../Hub/Components/Dashboard/filter/CustomSelectStyles";

import { getFilterOptionsFromAzure } from "../../../UserDatabase/menubar/azure/api";

const loadingOption = {
  label: "Loading...",
};

function AsyncSelectFilter({
  onChange,
  filter,
  placeholder,
  fieldTitle,
  isMulti = true,
  maxWidth = "100%",
  closeOnChange = false,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectKey, setSelectKey] = useState(Math.random().toString(36).substring(7));
  const [selectedValue, setSelectedValue] = useState();

  const selectedCount = selectedValue?.length;
  const selectedItem = isMulti ? selectedValue?.[0].label : selectedValue?.label;

  const labelRef = useRef();

  const [optionsList, setOptionsList] = useState([]);

  useEffect(() => {
    const loadOptions = async () => {
      try {
        const res = await getFilterOptionsFromAzure(filter.column, "");

        setOptionsList(res.data.options);
      } catch (err) {
        console.error(err.message);
      }
    };

    loadOptions();
  }, []);

  useEffect(() => {
    if (isMulti) {
      if (!filter.value || !filter.value?.length) {
        setSelectedValue(undefined);
      } else {
        const mappedValue = filter.value.map((value) => {
          const { label } = optionsList.find((opt) => opt.value === value) || {};
          return { label, value };
        });

        if (mappedValue.some((opt) => !opt.label)) {
          return setSelectedValue([loadingOption]);
        }

        setSelectedValue([...mappedValue]);
      }
    } else {
      const { label } = optionsList.find((opt) => opt.value === filter.value) || loadingOption;
      setSelectedValue({ label, value: filter.value });
    }
  }, [filter.value, optionsList]);

  useEffect(() => {
    window.AP.events.on("restore-filters-people-base", () => {
      setSelectKey(Math.random().toString(36).substring(7));
    });
  }, []);

  const handlePlaceholder = (props) => {
    return (
      <CustomPlaceholder
        item={selectedItem}
        count={selectedCount}
        isMulti={isMulti}
        fieldName={filter.fieldTitle}
        {...props}
      />
    );
  };

  return (
    <FilterFieldWrapper ref={labelRef} hasTitle={!!fieldTitle}>
      <CheckboxSelect
        key={selectKey}
        defaultOptions
        isMulti={isMulti}
        isSearchable
        backspaceRemovesValue
        closeMenuOnSelect={false}
        styles={SIMPLE_SELECT_STYLES}
        value={selectedValue}
        onChange={(options) => {
          if (isMulti) {
            onChange((options || []).map(({ value }) => value));
          } else {
            onChange(options?.value || "");
          }
          if (closeOnChange) {
            setIsOpen(false);
          }
        }}
        controlShouldRenderValue={false}
        options={optionsList?.map((op) => ({ ...op })) || []}
        components={{
          Placeholder: handlePlaceholder,
        }}
      />
    </FilterFieldWrapper>
  );
}

export default AsyncSelectFilter;
