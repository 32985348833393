import React from "react";
import { dummyData } from "./dummy-data";
import {
  CardHeader,
  CardsGridWrapper,
  Skeleton,
  SmallCardBodyWrapper,
  SmallCardFooter,
  SmallCardSkeletonWrapper,
  SmallCardWrapper,
} from "./styles";

const NewStartersPreview = () => {
  return (
    <CardsGridWrapper numberOfColumns={4}>
      {dummyData.map((data, idx) => (
        <SmallCardWrapper key={idx}>
          <CardHeader backgroundUrl={data.backgroundUrl} />
          <SmallCardBodyWrapper>
            <img src={data.avatarUrl} alt="Profile" />
            <SmallCardSkeletonWrapper>
              <Skeleton width="68px" height="6px" backgroundColor="#DFE1E6" marginTop="0" />
              <Skeleton width="57px" height="6px" backgroundColor="#EBECF0" marginTop="0" />
            </SmallCardSkeletonWrapper>
          </SmallCardBodyWrapper>
          <SmallCardFooter>
            <Skeleton width="32px" backgroundColor="#DFE1E6" marginTop="0" />
          </SmallCardFooter>
        </SmallCardWrapper>
      ))}
    </CardsGridWrapper>
  );
};

export default NewStartersPreview;
