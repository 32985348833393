import React, { useMemo } from "react";
import Tooltip from "@atlaskit/tooltip";
import UnlockFilledIcon from "@atlaskit/icon/glyph/unlock-filled";
import LockFilledIcon from "@atlaskit/icon/glyph/lock-filled";
import styled from "styled-components";
import { useNavigationStore, useSidebarStore } from "../../store";
import { DrawerTypes } from "./types";
import { getSubtitle, getTitle } from "./helpers/titles";
import Transition from "./Transition";
import AddEntry from "./components/AddEntry";
import EditEntry from "./components/edit/EditEntry";
import HelperDrawer from "./helper_drawers/HelperDrawer";
import { PARENT_ENTRY_IDENTIFIER } from "../../defaults";
import { HelperDrawerTypes } from "./helper_drawers/types";

const CustomIconContainer = styled.div`
  width: 24px;
  height: 24px;
  cursor: pointer;
  display: grid;
  place-content: center;
  border-radius: 3px;

  transition: background 150ms linear;

  &:hover {
    background-color: ${({ bgColor }) => `${bgColor}1a`};
  }
`;

const PermissionsIcon = ({ handleClick }) => {
  const editingEntry = useNavigationStore((state) => state.editingEntry);

  const { spaces, collections, filters } = editingEntry || {};

  const hasPermissionsSet = !!spaces?.length || !!collections?.length || !!filters?.length;

  return (
    <Tooltip position="auto" content={hasPermissionsSet ? "View restrictions applied" : "No view restrictions"}>
      <CustomIconContainer bgColor={hasPermissionsSet ? "#ff0000" : "#0065ff"} onClick={() => handleClick()}>
        {hasPermissionsSet ? <LockFilledIcon primaryColor="red" /> : <UnlockFilledIcon primaryColor="#0065ff" />}
      </CustomIconContainer>
    </Tooltip>
  );
};

function Drawer() {
  const {
    isOpen,
    selectedSidebarType,
    closeSidebar,
    isHelperDrawerOpen,
    setIsHelperDrawerOpen,
    setSelectedHelperDrawerType,
  } = useSidebarStore((state) => ({
    isOpen: state.isOpen,
    selectedSidebarType: state.selectedSidebarType,
    closeSidebar: state.closeSidebar,
    isHelperDrawerOpen: state.isHelperDrawerOpen,
    setIsHelperDrawerOpen: state.setIsHelperDrawerOpen,
    setSelectedHelperDrawerType: state.setSelectedHelperDrawerType,
  }));

  const { editingEntry, setEditingEntry } = useNavigationStore((state) => ({
    editingEntry: state.editingEntry,
    setEditingEntry: state.setEditingEntry,
  }));

  const handleCloseSidebar = () => {
    if (editingEntry) {
      setEditingEntry(undefined);
    }

    closeSidebar();
  };

  const SidebarContent = useMemo(
    () =>
      ({
        [DrawerTypes.ADD_ENTRY]: AddEntry,
        [DrawerTypes.EDIT_ENTRY]: EditEntry,
        [undefined]: () => <></>,
      })[selectedSidebarType],
    [selectedSidebarType],
  );

  return (
    <Transition
      isOpen={isOpen}
      close={() => handleCloseSidebar()}
      width={415}
      title={getTitle(selectedSidebarType, editingEntry)}
      subtitle={getSubtitle(selectedSidebarType)}
      disabledClickOutside={isHelperDrawerOpen}
      hasExtraIcon={selectedSidebarType === DrawerTypes.EDIT_ENTRY && editingEntry?.parent === PARENT_ENTRY_IDENTIFIER}
      ExtraIcon={PermissionsIcon}
      handleClickOnExtraIcon={() => {
        setIsHelperDrawerOpen(true);
        setSelectedHelperDrawerType(HelperDrawerTypes.PERMISSIONS);
      }}
      customScrollbarHeight="calc(100% - 110px)"
    >
      <SidebarContent />

      <HelperDrawer />
    </Transition>
  );
}

export default Drawer;
