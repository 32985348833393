import React, { useState, useEffect, useMemo } from "react";
import shallow from "zustand/shallow";
import { FieldContainer, FieldTitle } from "./styled";
import Date from "./type/Date";
import Select from "./type/Select";
import Number from "./type/Number";
import Text from "./type/Text";
import Url from "./type/Url";
import User from "./type/User";
import dayjs from "dayjs";
import { getAccountId } from "../../profile.service";
import { useCurrentUserStore } from "../../../../current-user-store";
import { useRecordsStore } from "../../../../../UserDatabase/records-store";
import { FieldType } from "../../../../../UserDatabase/field-type";

function FieldProvider({ currentField, values, isSidebar }) {
  const [value, setValue] = useState(undefined);
  const { currentUser, isCurrentUserAdmin } = useCurrentUserStore(
    (state) => ({ currentUser: state.currentUser, isCurrentUserAdmin: state.isCurrentUserAdmin }),
    shallow,
  );

  const isEditable = useMemo(() => {
    const accountId = getAccountId();

    const isAzureField = currentField.id.startsWith("azure");

    if (accountId) {
      return (currentUser.accountId === accountId || isCurrentUserAdmin) && !isAzureField;
    }

    return !isAzureField;
  }, [currentUser, isCurrentUserAdmin]);

  const { fields, records, addChange, setRecords } = useRecordsStore(
    (state) => ({
      fields: state.fields,
      records: state.records,
      addChange: state.addChange,
      setRecords: state.setRecords,
    }),
    shallow,
  );

  const onEdit = (fieldId, val) => {
    const record = {
      ...records[0],
      values: {
        ...records[0].values,
        [fieldId]: val,
      },
    };

    if (currentField.type === "DATE") {
      record.values[`recurring_${currentField.id}`] = dayjs.unix(val).year(1970).hour(0).minute(0).second(0).unix();
    }

    addChange({ ...record, operation: "UPDATE_RECORDS" });
    setRecords([record]);
  };

  useEffect(() => {
    if (currentField && !!fields?.length && !!Object.keys(values || {}).length) {
      const selectedField = fields.find((field) => field.id === currentField.id);
      const selectedValue = values[currentField.id];

      if (selectedField) {
        setValue(selectedValue);
      }
    } else if (value) {
      setValue(undefined);
    }
  }, [currentField, fields, values]);

  const getPrefixBasedOnRole = () => {
    const accountId = getAccountId();

    if (isCurrentUserAdmin) {
      return "Edit";
    }

    if (!isCurrentUserAdmin && (!accountId || currentUser.accountId === accountId)) {
      return "Your";
    }

    return "";
  };

  return (
    <FieldContainer className="field-container">
      {!isSidebar && <FieldTitle>{currentField.title}</FieldTitle>}

      {currentField.type === FieldType.URL && (
        <Url
          field={currentField}
          value={value}
          isEditable={isEditable}
          getPrefixBasedOnRole={getPrefixBasedOnRole}
          onEdit={onEdit}
        />
      )}
      {currentField.type === FieldType.USER && (
        <User
          field={currentField}
          value={value}
          isEditable={isEditable}
          getPrefixBasedOnRole={getPrefixBasedOnRole}
          onEdit={onEdit}
        />
      )}
      {currentField.type === FieldType.NUMBER && (
        <Number
          field={currentField}
          value={value}
          isEditable={isEditable}
          getPrefixBasedOnRole={getPrefixBasedOnRole}
          onEdit={onEdit}
        />
      )}
      {currentField.type === FieldType.DATE && (
        <Date
          field={currentField}
          value={value}
          isEditable={isEditable}
          getPrefixBasedOnRole={getPrefixBasedOnRole}
          isSidebar={isSidebar}
          onEdit={onEdit}
        />
      )}
      {currentField.type === FieldType.TEXT && (
        <Text
          field={currentField}
          value={value}
          isEditable={isEditable}
          getPrefixBasedOnRole={getPrefixBasedOnRole}
          isSidebar={isSidebar}
          onEdit={onEdit}
        />
      )}
      {currentField.type === FieldType.MULTISELECT && (
        <Select
          field={currentField}
          value={value}
          isEditable={isEditable}
          getPrefixBasedOnRole={getPrefixBasedOnRole}
          isSidebar={isSidebar}
          onEdit={onEdit}
        />
      )}
      {currentField.type === FieldType.SELECT && (
        <Select
          field={currentField}
          value={value}
          isEditable={isEditable}
          getPrefixBasedOnRole={getPrefixBasedOnRole}
          isSidebar={isSidebar}
          onEdit={onEdit}
          onSelect={onEdit}
        />
      )}
    </FieldContainer>
  );
}

export default FieldProvider;
