import { createProfileLink } from "../../../../PeopleBase/Views/createProfileLink";
import { Ordering, Sorting } from "../../../filter/sorting-types";

export const searchDiagramsForSpaceId = ({ queryKey, pageParam }) => {
  const [
    _key,
    { spaceIds, searchTerm = "", orderBy = Ordering.DESCENDING, sortOrder = Sorting.MODIFIED, pageSize = 10 },
  ] = queryKey;

  if (!spaceIds?.length) throw new Error("Space is required");

  const _pageSize = `&limit=${pageSize}`;
  const _searchTerm = buildGetDiagramsSearchTerm(searchTerm);

  let _orderBy = ``;
  if (sortOrder === Sorting.CREATED) {
    _orderBy += ` ORDER BY created`;
  }
  if (sortOrder === Sorting.MODIFIED) {
    _orderBy += ` ORDER BY lastmodified`;
  }
  if (sortOrder === Sorting.TITLE) {
    _orderBy += ` ORDER BY title`;
  }
  if (orderBy === Ordering.ASCENDING) {
    _orderBy += ` asc`;
  }
  if (orderBy === Ordering.DESCENDING) {
    _orderBy += ` desc`;
  }

  let url = buildGetDiagramsUrl(spaceIds, _searchTerm, _orderBy, _pageSize);

  if (pageParam) {
    url = `${url}&cursor=${decodeURI(pageParam)}`;
  }
  try {
    return window.AP.request(url);
  } catch (e) {
    console.log("cant fetch diagrams");
    console.log(e);
  }
};

const buildGetDiagramsSearchTerm = (searchTerm) => {
  if (!searchTerm) {
    return "";
  }

  return `and (title ~ "${searchTerm}" or title ~ "*${searchTerm}*" or title ~ "*${searchTerm}" or title ~ "${searchTerm}*" or title ~ "${searchTerm}~")`;
};

const buildGetDiagramsUrl = (spaceIds, searchTerm, orderBy, pageSize) => {
  const cql = `type = "ac:com.gliffy.integration.confluence:gliffy-diagram" and space in (${spaceIds.join(
    ", ",
  )}) ${searchTerm} ${orderBy}${pageSize}`;
  return `/rest/api/search?cql=${cql}&mediaType=application/gliffy+json&expand=content.container,content.history.contributors.publishers.users,content.body.raw`; // expand fields important to get all available information about diagram
};

const getAttachmentPreviewUrl = async ({ pageId, attachmentName }) => {
  if (!pageId || !attachmentName) {
    throw new Error("Missing required argument: pageId or attachmentName");
  }

  // Make API request to get attachment data
  const encodedAttachmentName = encodeURIComponent(attachmentName);
  const url = `/api/v2/pages/${pageId}/attachments?filename=${encodedAttachmentName}.png&expand=version,container,metadata`; // Here expand probably doesn't have effect as v2 API used
  try {
    const response = await window.AP.request({ url, type: "GET" });
    const { results } = JSON.parse(response.body);

    // Extract preview URL from attachment data and return it
    let previewUrl = null;
    if (results && results[0] && results[0].downloadLink) {
      previewUrl = results[0].downloadLink;
    }

    if (previewUrl) return previewUrl;
    else {
      return null;
    }
  } catch (error) {
    return null;
  }
};

const mapContributors = (user) => {
  return {
    id: user.accountId,
    accountId: user.accountId,
    name: user.displayName,
    href: createProfileLink(user.accountId),
    src: `${window.AP._hostOrigin}${user.profilePicture.path}`,
  };
};

export const mapDiagramResponse = (result) => {
  const { content, title, lastModified } = result;

  const {
    id,
    container: { id: contentId, title: contentTitle, type, _links },
    history: { contributors },
    _links: { webui },
  } = content;

  const gliffyBaseUrl = `${window.AP._hostOrigin}/wiki${webui}`;
  const gliffyEditorUrl = gliffyBaseUrl.replace("fullscreen-viewer", "editor");

  return {
    content: {
      id: contentId,
      title: contentTitle,
      type,
      link: `${window.AP._hostOrigin}/wiki${_links.webui}`,
    },
    diagram: {
      id,
      title,
      fullscreenUrl: gliffyBaseUrl,
      editorUrl: gliffyEditorUrl,
      lastModified,
      contributers: contributors?.publishers?.users?.map(mapContributors) || [],
      getPictureFromAttachments: async () => getAttachmentPreviewUrl({ pageId: contentId, attachmentName: title }),
    },
  };
};

export const fetchUsers = async (accounts) => {
  if (!accounts.length) {
    return [];
  }

  const remainder = accounts.splice(25);

  const cql = `user.accountid = ${accounts.map((id) => `"${id}"`).join(" OR user.accountid = ")}`;
  const res = await window.AP.request(`/rest/api/search/user?cql=${cql}`);

  const data = JSON.parse(res.body);
  const bulk = data.results.map((data) => data.user);

  if (remainder.length) {
    const nextBulk = await fetchUsers(remainder);

    return [...bulk, ...nextBulk];
  }

  return bulk;
};
