import React, { useState } from "react";
import Textfield from "@atlaskit/textfield";
import CustomModal from "../CustomModal";
import { FieldContainer, Label } from "../../../../../Dashboard/filter/styled";
import { useCategoriesStore } from "../../../categories-store";
import { nanoid } from "nanoid";
import { saveCategoriesSettings } from "../../../api";

function CreateCategory({ closeModal }) {
  const { allCategories, setAllCategories } = useCategoriesStore((state) => ({
    allCategories: state.allCategories,
    setAllCategories: state.setAllCategories,
  }));

  const [input, setInput] = useState("");

  const createCategory = () => {
    const newCategory = { id: nanoid(8), value: input };
    setAllCategories([...allCategories, newCategory]);
    saveCategoriesSettings([...allCategories, newCategory]);
    closeModal();
  };

  return (
    <CustomModal
      heading="Create Category"
      isUpdateDone={false}
      onCancel={closeModal}
      onNext={() => createCategory()}
      disabled={!input}
      customHeight="auto"
      primaryButtonText="Create"
      children={
        <div>
          <FieldContainer>
            <Label>Name</Label>
            <Textfield
              value={input}
              onChange={async (e) => {
                setInput(e.target.value);
              }}
              placeholder="Type category name here"
            />
          </FieldContainer>
        </div>
      }
    />
  );
}

export default CreateCategory;
