import React from "react";
import styled from "styled-components";
import Toggle from "@atlaskit/toggle";
import { useChangesStore } from "../../../../track-changes-store";
import { useRecordsStore } from "../../../../../UserDatabase/records-store";

const Field = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 0px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.shared.profile.socialLinkText};
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
`;

function SocialLink({ id, title, fields, socialLinks, setFields }) {
  const updateField = useRecordsStore((state) => state.updateField);
  const setHasChanges = useChangesStore((state) => state.setHasChanges);

  const profileFields = fields?.find((field) => field.id === "user")?.profile || {};

  const handleUpdate = () => {
    const profile = {
      ...profileFields,
      socialLinks: socialLinks.includes(id) ? socialLinks.filter((link) => link !== id) : [...socialLinks, id],
    };

    const newFields = fields.map((field) => {
      if (field.id === "user") {
        return { ...field, profile };
      }
      return field;
    });

    setFields([...newFields]);
    setHasChanges(true);
    updateField("user", { profile });
  };

  return (
    <Field>
      <FlexContainer>
        <img src={`/images/social-links/${id}.svg`} alt={id} width="20" height="20" />
        {title}
      </FlexContainer>

      <Toggle id={id} isChecked={socialLinks.includes(id)} onChange={handleUpdate} />
    </Field>
  );
}

export default SocialLink;
