import React, { useCallback } from "react";
import {
  SettingsWrapper,
  SectionsWrapper,
  SectionWrapper,
  Header,
  ItemIconAndTitle,
  ItemIconWrapper,
  ItemTitle,
  ItemDescription,
  Title,
  Description,
  SectionItem,
  ItemDivider,
} from "./styled/settings";
import { SectionComponents, Sections } from "./sections";
import { useCurrentUserStore } from "../../current-user-store";
import { useSettingsStore } from "./settings-store";
import { Pages } from "../../pages";

const SelectedSettingsPage = (props) => {
  if (!props.selectedSettingsPage) return <></>;

  const FoundComponent = SectionComponents[props.selectedSettingsPage];

  return FoundComponent ? <FoundComponent {...props} /> : <></>;
};

function Settings() {
  const isCurrentUserAdmin = useCurrentUserStore((state) => state.isCurrentUserAdmin);
  const { selectedSettingsPage } = useSettingsStore((state) => ({
    selectedSettingsPage: state.selectedSettingsPage,
  }));

  const createSectionItemLink = useCallback(
    (cosmosPage) =>
      `${window.AP._hostOrigin}/wiki/plugins/servlet/ac/com.caelor.confluence.cloud.cosmos/caelor-cosmos-portal?ac.cosmosPage=${cosmosPage}`,
    [],
  );

  const isPageSelected = !!selectedSettingsPage.length;

  return (
    <SettingsWrapper>
      {isPageSelected ? (
        <SelectedSettingsPage selectedSettingsPage={selectedSettingsPage} currentUserIsAdmin={isCurrentUserAdmin} />
      ) : (
        <>
          <Header>
            <Title>Settings</Title>
            <Description>Customize and personalize your intranet experience.</Description>
          </Header>
          <SectionsWrapper>
            {Sections.map((section) => (
              <SectionWrapper key={section.key}>
                <Title>{section.title}</Title>
                {section.pages.map((page) => (
                  <SectionItem
                    key={page.id}
                    rel="noreferrer"
                    href={createSectionItemLink(`${Pages.SETTINGS}-${section.key}-${page.id}`)}
                    target="_parent"
                  >
                    <ItemIconAndTitle>
                      <ItemIconWrapper backgroundColor={page.color}>
                        {page.icon && <page.icon size="medium" primaryColor="white" />}
                      </ItemIconWrapper>
                      <ItemTitle>{page.title}</ItemTitle>
                    </ItemIconAndTitle>
                    <ItemDivider backgroundColor={page.color} />
                    <ItemDescription>{page.description}</ItemDescription>
                  </SectionItem>
                ))}
              </SectionWrapper>
            ))}
          </SectionsWrapper>
        </>
      )}
    </SettingsWrapper>
  );
}

export default Settings;
