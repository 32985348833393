import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContentSelector = styled.div`
  display: ${({ isVisible }) => (isVisible ? "none" : "grid")};
  grid-template-columns: 20px auto 20px;
  column-gap: 16px;
  row-gap: 12px;
  margin-top: 32px;
  align-items: flex-start;
  cursor: pointer;
`;

export const Section = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.shared.sidebar.positioning.title};
  user-select: none;
  display: block;
`;

export const Description = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.shared.sidebar.positioning.description};
  margin-top: 12px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 19px;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  color: ${({ theme }) => theme.shared.sidebar.title};
`;

export const BorderDivider = styled.div`
  width: 100%;
  opacity: 0.5;
  border: 0.75px solid ${({ theme }) => theme.shared.sidebar.divider};
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

export const IconContainer = styled.div`
  display: grid;
  place-content: center;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }
`;

export const Divider = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.shared.sidebar.divider};

  &:after {
    content: "${({ label }) => label}";
    line-height: 1;
    margin-bottom: -5px;
    background: ${({ theme }) => theme.global.background.surfaceOverlay};
    padding-left: 10px;
    color: #7a869a;
    font-weight: 500;
    font-size: 10px;
    float: right;
  }
`;

export const ItemToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 0px;
  margin-left: 32px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.shared.settings.title};
`;

export const NoTextDivider = styled.div`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.shared.sidebar.divider};
`;
