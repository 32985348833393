import React from "react";
import styled from "styled-components";
import BackgroundPopup from "../../../../../../Shared/Components/Background/BackgroundPopup";
import { useHeaderStore } from "../../../../store/header-store";
import { FieldContainer, Label } from "../../../Dashboard/filter/styled";

const Button = styled.span`
  background: ${({ theme }) => theme.global.background.surfaceOverlay};
  color: ${({ theme }) => theme.global.secondary};
  padding: 6px 12px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.shared.panel.hover};
  }
  &:active {
    background: ${({ theme }) => theme.shared.panel.active};
  }
`;

const HeaderEditButtonContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  gap: 12px;
  z-index: 150;
  inset: 0;
  color: white;
`;

const HeaderPreview = styled.div`
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  z-index: 100;

  &::after {
    content: "";
    position: absolute;
    z-index: 110;
    inset: 0;
    transition: 200ms;
  }

  &:hover {
    &::after {
      background: rgba(23, 43, 77, 0.4);
    }

    ${HeaderEditButtonContainer} {
      visibility: visible;
    }
  }
`;

function HeaderBackground({
  defaultImage,
  background,
  handleUpdates,
  primaryColor = "#0065ff",
  primaryColorContrast = "#fff",
}) {
  const { image, overlay } = background || {};

  const { isEditingHeader, setIsEditingHeader } = useHeaderStore((state) => ({
    isEditingHeader: state.isEditingHeader,
    setIsEditingHeader: state.setIsEditingHeader,
  }));

  const updateImage = (imageData) => {
    const newBackground = { ...background, image: { ...imageData } };
    handleUpdates("background", newBackground);
  };

  const updateOverlay = (overlayData) => {
    const newBackground = { ...background, overlay: { ...overlayData } };
    handleUpdates("background", newBackground);
  };

  const getCorrectImage = () => {
    if (!Object.keys(image || {}).length) return <div style={{ padding: "15px 0px" }}>Select an image to preview</div>;
    if (image?.source === "custom") {
      return (
        <img
          src={defaultImage}
          style={{ width: "100%", height: "auto", maxHeight: "150px", display: "block", objectFit: "cover" }}
          alt=""
        />
      );
    }
    if (image?.source === "color") {
      return (
        <div
          style={{
            backgroundColor: `${image.value}`,
            width: "100%",
            height: "200px",
          }}
        />
      );
    }
    if (image?.source === "gradient") {
      return (
        <div
          style={{
            background: `linear-gradient(${image.value?.rotation || "90deg"}, ${image.value.firstColor} ${
              image.value.firstColorPosition
            }%, ${image.value.secondColor} ${image.value.secondColorPosition}%)`,
            width: "100%",
            height: "200px",
          }}
        />
      );
    }

    const imageUrl = image.link;

    return (
      <>
        <img
          src={imageUrl}
          alt="Preview"
          style={{
            display: "block",
            width: "100%",
            height: "170px",
            objectFit: "cover",
            borderRadius: "5px",
          }}
        />
      </>
    );
  };

  return (
    <>
      <FieldContainer>
        <Label>Header background</Label>
        <HeaderPreview>
          <HeaderEditButtonContainer>
            <Button onClick={() => setIsEditingHeader(true)}>Edit header</Button>
          </HeaderEditButtonContainer>
          {getCorrectImage()}
        </HeaderPreview>
      </FieldContainer>

      <BackgroundPopup
        image={image}
        overlay={overlay}
        resetBackground={() =>
          handleUpdates("background", {
            image: {
              source: "custom",
              link: defaultImage,
            },
            overlay: {},
          })
        }
        setImage={updateImage}
        setOverlay={updateOverlay}
        isOpen={isEditingHeader}
        onClose={() => setIsEditingHeader(false)}
        defaultImage={defaultImage}
        showCurrentPageInAttachments={false}
        primaryColor={primaryColor}
        primaryColorContrast={primaryColorContrast}
        showVideoInAttachments={false}
        hasColorTabs
        showZoom
        moveableImage
        hasUpload
        boxWidth="30px"
        boxHeight="32px"
      />
    </>
  );
}

export default HeaderBackground;
