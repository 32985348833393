import { Spotlight, SpotlightTransition } from "@atlaskit/onboarding";
import { DASHBOARD_WIZARD_PROPERTY_KEY, updateUserWizardConfigProperty } from "./wizard-service";
import { useCurrentUserStore } from "../../../current-user-store";

const DashboardWizard = ({ currentWizardStep, setCurrentWizardStep, updateWizardProperties }) => {
  const next = () => setCurrentWizardStep((currentWizardStep || 0) + 1);
  const end = () => setCurrentWizardStep(null);

  const currentUser = useCurrentUserStore((state) => state.currentUser);

  const renderActiveSpotlight = () => {
    const spotlights = [
      <Spotlight
        actions={[
          {
            onClick: () => {
              next();
            },
            text: "Next",
          },
          {
            onClick: () => {
              updateWizardProperties("dashboard", true);
              updateUserWizardConfigProperty(
                currentUser?.accountId,
                { userSeenWizard: true },
                true,
                DASHBOARD_WIZARD_PROPERTY_KEY
              );
              end();
            },
            text: "Skip",
            appearance: "subtle",
          },
        ]}
        target="settings"
        heading="Employee Database"
        key="employee-database"
        targetRadius={50}
        targetBgColor={"white"}
      >
        It seems you're alone here. Sync your users - we need your whole team on board for this Cosmos mission.
      </Spotlight>,
      <Spotlight
        actions={[
          {
            onClick: () => {
              updateWizardProperties("dashboard", true);
              updateUserWizardConfigProperty(
                currentUser?.accountId,
                { userSeenWizard: true },
                true,
                DASHBOARD_WIZARD_PROPERTY_KEY
              );
              end();
            },
            text: "Done",
            appearance: "subtle",
          },
        ]}
        heading="Edit Content"
        target="edit-dashboard"
        key="edit-dashboard-content"
        targetRadius={3}
      >
        You can replace the dummy content with your stellar creations. It's like having your very own mission control
        center.
      </Spotlight>,
    ];

    if (currentWizardStep < 0) {
      return null;
    }

    return spotlights[currentWizardStep];
  };

  return <SpotlightTransition>{renderActiveSpotlight()}</SpotlightTransition>;
};

export default DashboardWizard;
