import React, { useState } from "react";
import styled from "styled-components";
import TextField from "@atlaskit/textfield";
import Textarea from "@atlaskit/textarea";
import { EditorBackground } from "../styled";

const Container = styled.div`
  width: 100%;

  * {
    text-align: center;
    padding: 0 6px !important;
    line-height: 24px !important;
  }
`;

const TextEditor = ({ initialValue, onEdit, isSidebar }) => {
  const [value, setValue] = useState(initialValue);

  if (isSidebar) {
    return (
      <EditorBackground style={{ height: "auto", minHeight: "32px", padding: "4px 6px", margin: "0 -12px" }}>
        <Container>
          <Textarea
            autoFocus
            isCompact
            appearance="none"
            value={value}
            onBlur={(e) => onEdit(e.target.value)}
            onChange={(e) => setValue(e.target.value)}
          />
        </Container>
      </EditorBackground>
    );
  }

  return (
    <EditorBackground>
      <TextField
        autoFocus
        isCompact
        appearance="none"
        value={value}
        onBlur={(e) => onEdit(e.target.value)}
        onChange={(e) => setValue(e.target.value)}
      />
    </EditorBackground>
  );
};

export default TextEditor;
