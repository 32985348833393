export const DEFAULT_ICON = "font-awesome/RegImage";
export const ATLASKIT_PROVIDER = "atlaskit";
export const BOOTSTRAP_PROVIDER = "bootstrap";
export const FA_PROVIDER = "font-awesome";
export const MD_PROVIDER = "material-design";
export const DEFAULT_PROVIDER = ATLASKIT_PROVIDER;
export const PROVIDERS = [
  {
    name: "Atlaskit",
    key: ATLASKIT_PROVIDER,
  },
  {
    name: "Bootstrap",
    key: BOOTSTRAP_PROVIDER,
  },
  {
    name: "Font Awesome",
    key: FA_PROVIDER,
  },
  {
    name: "Material Design",
    key: MD_PROVIDER,
  },
];
