import React from "react";
import SortSelect from "../../Shared/SortSelect";
import { useNewsroomStore } from "../newsroom-store";
import { FieldContainer, FieldLabel } from "../../Shared/Filters/styled-filters";
import { useCorporateIdentityStore } from "../../Settings/General/BrandAndColors/corporate-identity-store";
import { COLOR_TYPES } from "../../Settings/General/BrandAndColors/color-types";
import { useTheme } from "styled-components";
import { convertColorOpacity } from "../../../../../Shared/Functions/convertColorOpacity";
export const SortTypes = {
  NEWEST: "newest",
  OLDEST: "oldest",
};

const NewsSortingOptions = [
  { label: "Newest", value: SortTypes.NEWEST },
  { label: "Oldest", value: SortTypes.OLDEST },
];

function Sort() {
  const { sorting, setSorting } = useNewsroomStore((state) => ({
    sorting: state.sorting,
    setSorting: state.setSorting,
  }));

  const theme = useTheme();
  const colors = useCorporateIdentityStore((state) => state.colors);
  const primaryColor = colors[COLOR_TYPES.PRIMARY] || "#0065ff";

  const convertedColor = convertColorOpacity(primaryColor, theme.global.name);

  return (
    <FieldContainer>
      <FieldLabel>SORT BY</FieldLabel>
      <SortSelect
        color={primaryColor}
        selectedOption={sorting}
        onChange={setSorting}
        options={NewsSortingOptions}
        useStandardSelect
        convertedColor={convertedColor}
        theme={theme}
      />
    </FieldContainer>
  );
}

export default Sort;
