import { PanelKeys, PanelPositioning, PanelTypes } from "./panelTypes";

import {
  AnniversaryIcon,
  BirthdayIcon,
  ClockIcon,
  CountdownIcon,
  FolderIcon,
  InfoIcon,
  LinkIcon,
  NewStartersIcon,
  NewsIcon,
  PagesIcon,
  QuestionIcon,
  SquaredCloudIcon,
  SquaredImagePurpleIcon,
  SquaredLetterIcon,
  PeopleGroupIcon,
  TaskIcon,
  VideoIcon,
  CustomNewsIcon,
  GliffyIcon,
  ReleasedIcon,
  NewsForYou,
} from "./images/PanelTypeIcons";

export const AvailablePanelsBasedOnPosition = {
  [PanelPositioning.MAIN]: [
    {
      key: PanelKeys.COMPANY_NEWS,
      dataKey: PanelKeys.COMPANY_NEWS,
      type: PanelTypes.NEWS,
      title: "Company News",
      description: "Display blogs from your site",
      Icon: NewsIcon,
    },
    {
      key: PanelKeys.PERSONALIZED_NEWS,
      dataKey: PanelKeys.PERSONALIZED_NEWS,
      type: PanelTypes.PERSONALIZED_NEWS,
      title: "Personalized News Feed",
      description: "News from spaces and categories you follow",
      Icon: CustomNewsIcon,
    },
    {
      key: PanelKeys.TARGETED_NEWS,
      dataKey: PanelKeys.TARGETED_NEWS,
      type: PanelTypes.TARGETED_NEWS,
      title: "News For You",
      description: "Displays news targeted to people collections.",
      Icon: NewsForYou,
    },
    {
      key: PanelKeys.PAGES,
      dataKey: PanelKeys.PAGES,
      type: PanelTypes.PAGES,
      title: "Important Pages",
      description: "Display pages from your site",
      Icon: PagesIcon,
    },
    {
      key: PanelKeys.IMPORTANT_SPACES_MAIN,
      dataKey: PanelKeys.IMPORTANT_SPACES_MAIN,
      type: PanelTypes.SPACES,
      title: "Important Spaces",
      description: "Display spaces from your site",
      Icon: FolderIcon,
    },
    {
      key: PanelKeys.GLIFFY,
      dataKey: PanelKeys.GLIFFY,
      type: PanelTypes.GLIFFY,
      title: "Gliffy Diagrams",
      description: "Simplify technical documentation",
      Icon: GliffyIcon,
    },
    {
      key: PanelKeys.RELEASED_AI,
      dataKey: PanelKeys.RELEASED_AI,
      type: PanelTypes.RELEASED_AI,
      title: "Released",
      description: "Display release notes from Jira",
      Icon: ReleasedIcon,
    },
    {
      key: PanelKeys.ICON_CARDS,
      dataKey: PanelKeys.ICON_CARDS,
      type: PanelTypes.CARDS,
      title: "Icon Cards",
      description: "Group information with cards",
      Icon: SquaredCloudIcon,
    },
    {
      key: PanelKeys.TEXT_CARDS,
      dataKey: PanelKeys.TEXT_CARDS,
      type: PanelTypes.CARDS,
      title: "Text Cards",
      description: "Group information with cards",
      Icon: SquaredLetterIcon,
    },
    {
      key: PanelKeys.IMG_CARDS,
      dataKey: PanelKeys.IMG_CARDS,
      type: PanelTypes.CARDS,
      title: "Image Cards",
      description: "Group information with cards",
      Icon: SquaredImagePurpleIcon,
    },
    {
      key: PanelKeys.COUNTDOWN,
      dataKey: PanelKeys.COUNTDOWN,
      type: PanelTypes.COUNTDOWN,
      title: "Countdown",
      description: "Count time before an event",
      Icon: CountdownIcon,
    },
    {
      key: PanelKeys.VIDEOS_YT,
      dataKey: PanelKeys.VIDEOS_YT,
      type: PanelTypes.VIDEOS,
      title: "Watch",
      description: "Broadcast YouTube videos",
      Icon: VideoIcon,
    },
    {
      key: PanelKeys.NEW_STARTERS,
      dataKey: PanelKeys.NEW_STARTERS,
      type: PanelTypes.NEW_USERS_MAIN,
      title: "New Starters",
      description: "Introduce new employees",
      Icon: NewStartersIcon,
    },
    {
      key: PanelKeys.TEAM_NEWS,
      dataKey: PanelKeys.TEAM_NEWS,
      type: PanelTypes.NEWS,
      title: "Team News",
      description: "Display blogs from your site",
      Icon: NewsIcon,
    },
    {
      key: PanelKeys.INFO_PANEL_MAIN,
      dataKey: PanelKeys.INFO_PANEL,
      type: PanelTypes.NOTIFICATION,
      title: "Info Panel",
      description: "Display info in short form",
      Icon: InfoIcon,
    },
    {
      key: PanelKeys.IMPORTANT_DATES_MAIN,
      dataKey: PanelKeys.IMPORTANT_DATES_MAIN,
      type: PanelTypes.BIRTHDAY,
      title: "Upcoming Birthdays",
      description: "Highlight upcoming birthdays",
      Icon: BirthdayIcon,
    },
    {
      key: PanelKeys.WORK_ANNIVERSARY_MAIN,
      dataKey: PanelKeys.WORK_ANNIVERSARY_MAIN,
      type: PanelTypes.BIRTHDAY,
      title: "Work Anniversary",
      description: "Highlight employee anniversary",
      Icon: AnniversaryIcon,
    },
    {
      key: PanelKeys.PEOPLE_MAIN,
      dataKey: PanelKeys.PEOPLE_MAIN,
      type: PanelTypes.PEOPLE,
      title: "People",
      description: "Highlight teams and their structure",
      Icon: PeopleGroupIcon,
    },
    {
      key: PanelKeys.RECENTLY_ACTIVE_USERS_MAIN,
      dataKey: PanelKeys.RECENTLY_ACTIVE_USERS_MAIN,
      type: PanelTypes.ACTIVE_USERS,
      title: "Recently active users",
      description: "Display recent activities on your site",
      Icon: ClockIcon,
    },
  ],
  [PanelPositioning.SIDE]: [
    {
      key: PanelKeys.INFO_PANEL,
      dataKey: PanelKeys.INFO_PANEL,
      type: PanelTypes.NOTIFICATION,
      title: "Info Panel",
      description: "Display info in short form",
      Icon: InfoIcon,
    },
    {
      key: PanelKeys.PAGES_SIDE,
      dataKey: PanelKeys.PAGES_SIDE,
      type: PanelTypes.PAGES,
      title: "Important Pages",
      description: "Display pages from your site",
      Icon: PagesIcon,
    },
    {
      key: PanelKeys.PERSONALIZED_NEWS,
      dataKey: PanelKeys.PERSONALIZED_NEWS,
      type: PanelTypes.PERSONALIZED_NEWS,
      title: "Personalized News Feed",
      description: "News from spaces and categories you follow",
      Icon: CustomNewsIcon,
    },
    {
      key: PanelKeys.TARGETED_NEWS,
      dataKey: PanelKeys.TARGETED_NEWS,
      type: PanelTypes.TARGETED_NEWS,
      title: "Targeted News Feed",
      description: "Targeted News simplifies staying updated",
      Icon: CustomNewsIcon,
    },
    {
      key: PanelKeys.IMPORTANT_DATES,
      dataKey: PanelKeys.IMPORTANT_DATES,
      type: PanelTypes.BIRTHDAY,
      title: "Upcoming Birthdays",
      description: "Highlight upcoming birthdays",
      Icon: BirthdayIcon,
    },
    {
      key: PanelKeys.POLL,
      dataKey: PanelKeys.POLL,
      type: PanelTypes.POLL,
      title: "Poll",
      description: "Collect votes and feedback",
      Icon: QuestionIcon,
    },
    {
      key: PanelKeys.APP_LINKS,
      dataKey: PanelKeys.APP_LINKS,
      type: PanelTypes.USEFUL_LINKS,
      title: "App Links",
      description: "Create shortcuts to apps and tools",
      Icon: LinkIcon,
    },
    {
      key: PanelKeys.GLIFFY,
      dataKey: PanelKeys.GLIFFY,
      type: PanelTypes.GLIFFY,
      title: "Gliffy Diagrams",
      description: "Simplify technical documentation",
      Icon: GliffyIcon,
    },
    {
      key: PanelKeys.ASSIGNED_TO_ME,
      dataKey: PanelKeys.ASSIGNED_TO_ME,
      type: PanelTypes.ASSIGNED_TASKS,
      title: "Assigned to me",
      description: "Preview your  Confluence action items",
      Icon: TaskIcon,
    },
    {
      key: PanelKeys.IMPORTANT_SPACES,
      dataKey: PanelKeys.IMPORTANT_SPACES,
      type: PanelTypes.SPACES,
      title: "Important Spaces",
      description: "Display spaces from your site",
      Icon: FolderIcon,
    },
    {
      key: PanelKeys.VIDEOS_YT_SIDE,
      dataKey: PanelKeys.VIDEOS_YT,
      type: PanelTypes.VIDEOS,
      title: "Watch",
      description: "Broadcast YouTube videos",
      Icon: VideoIcon,
    },
    {
      key: PanelKeys.NEW_STARTERS_SIDE,
      dataKey: PanelKeys.NEW_STARTERS,
      type: PanelTypes.NEW_USERS_MAIN,
      title: "New Starters",
      description: "Introduce new employees",
      Icon: NewStartersIcon,
    },
    {
      key: PanelKeys.SIDE_NEWS,
      dataKey: PanelKeys.SIDE_NEWS,
      type: PanelTypes.NEWS,
      title: "Latest News",
      description: "Latest News",
      Icon: NewsIcon,
    },
    {
      key: PanelKeys.WORK_ANNIVERSARY_SIDE,
      dataKey: PanelKeys.WORK_ANNIVERSARY_MAIN,
      type: PanelTypes.BIRTHDAY,
      title: "Work Anniversary",
      description: "Highlight employee anniversary",
      Icon: AnniversaryIcon,
    },
    {
      key: PanelKeys.PEOPLE_SIDE,
      dataKey: PanelKeys.PEOPLE_SIDE,
      type: PanelTypes.PEOPLE,
      title: "People",
      description: "Highlight teams and their structure",
      Icon: PeopleGroupIcon,
    },
    {
      key: PanelKeys.RECENTLY_ACTIVE_USERS_SIDE,
      dataKey: PanelKeys.RECENTLY_ACTIVE_USERS_SIDE,
      type: PanelTypes.ACTIVE_USERS,
      title: "Recently active users",
      description: "Display recent activities on your site",
      Icon: ClockIcon,
    },
  ],
};
