import MediaServicesGridIcon from "@atlaskit/icon/glyph/media-services/grid";
import PeopleGroupIcon from "@atlaskit/icon/glyph/people-group";
import ImageIcon from "@atlaskit/icon/glyph/image";

export const NewsMetadataTabs = {
  PHOTO: "photo",
  CATEGORIES: "categories",
  TARGETING: "targeting",
};

export const AvailableTabs = [
  {
    id: NewsMetadataTabs.PHOTO,
    label: "Cover image",
    icon: <ImageIcon />,
  },
  {
    id: NewsMetadataTabs.CATEGORIES,
    label: "Categories",
    icon: <MediaServicesGridIcon />,
  },
  {
    id: NewsMetadataTabs.TARGETING,
    label: "Audience",
    icon: <PeopleGroupIcon />,
  },
];
