export const DEFAULT_COLORS = ["#0052CC", "#091E42", "#00A2E0", "#36B37E", "#FF8B00", "#6554C0"];

export const DEFAULT_GRADIENTS = [
  {
    rotation: "45deg",
    firstColor: "#FF8B00",
    firstColorPosition: 0,
    secondColor: "#FF5630",
    secondColorPosition: 100,
  },
  {
    rotation: "45deg",
    firstColor: "#FFC400",
    firstColorPosition: 0,
    secondColor: "#FF8B00",
    secondColorPosition: 100,
  },
  {
    rotation: "45deg",
    firstColor: "#2684FF",
    firstColorPosition: 0,
    secondColor: "#00B8D9",
    secondColorPosition: 100,
  },
  {
    rotation: "45deg",
    firstColor: "#6554C0",
    firstColorPosition: 0,
    secondColor: "#2684FF",
    secondColorPosition: 100,
  },
  {
    rotation: "45deg",
    firstColor: "#36B37E",
    firstColorPosition: 0,
    secondColor: "#6554C0",
    secondColorPosition: 100,
  },
  {
    rotation: "45deg",
    firstColor: "#FFC400",
    firstColorPosition: 0,
    secondColor: "#00B8D9",
    secondColorPosition: 100,
  },
];
