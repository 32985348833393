import React from "react";
import Modal, { ModalTransition, ModalFooter } from "@atlaskit/modal-dialog";
import styled from "styled-components";
import SectionMessage from "@atlaskit/section-message";

const Button = styled.button`
  all: unset;
  border-radius: 4px;
  padding: 6px 12px;
  font-size: 14px;
  cursor: pointer;
  text-align: center;
  background: ${({ bg }) => bg};
  color: ${({ color }) => color};

  &:hover {
    background: ${({ bg }) => bg}cc;
  }
  &:active {
    background: ${({ bg }) => bg}ee;
  }
`;

const ModalTitle = styled.h1`
  color: ${({ theme }) => theme.confirmDeleteModal.modalHeaderText};
  font-weight: 500;
  font-size: 20px;
  font-style: inherit;
  line-height: 1;

  min-width: 0;
  flex: 1 1 auto;
  word-wrap: break-word;
  padding-bottom: 0px;
`;

const ModalBody = styled.h1`
  color: ${({ theme }) => theme.confirmDeleteModal.modalBodyText};
  font-weight: 400;
  font-size: 16px;
  font-style: inherit;
  line-height: 1;

  min-width: 0;
  flex: 1 1 auto;
  padding: 2px 24px;
`;

const ModalHeader = styled.div`
  display: flex;
  padding: 24px;
  padding-bottom: 0px;
  position: relative;
  align-items: center;
  justify-content: space-between;
`;

export const ConfirmDeleteCollection = ({ onClose, onConfirm, deleteInfo: { visible, collectionId } }) => {
  const handleConfirm = async () => {
    onConfirm(collectionId);
    onClose();
  };

  return (
    <ModalTransition>
      {visible && (
        <Modal height="250px" width="400px" title={`Delete collection?`} onClose={() => onClose()}>
          <ModalHeader>
            <ModalTitle>Delete collection</ModalTitle>
          </ModalHeader>
          <ModalBody>
            <SectionMessage appearance="warning">Are you sure you want to delete this collection?</SectionMessage>
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose} bg="#F1F2F4" color="#42526E">
              Cancel
            </Button>
            <Button onClick={handleConfirm} bg="#DE350B" color="#fff" appearance="danger">
              Delete
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </ModalTransition>
  );
};
