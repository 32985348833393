import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import debounce from "lodash.debounce";
import Spinner from "@atlaskit/spinner";
import DragHandlerIcon from "@atlaskit/icon/glyph/drag-handler";
import InfoIcon from "@atlaskit/icon/glyph/info";
import EditorAddIcon from "@atlaskit/icon/glyph/editor/add";
import EditorRemoveIcon from "@atlaskit/icon/glyph/editor/remove";
import TextAreaFilter from "../../../filter/TextAreaFilter";
import TextInputFilter from "../../../filter/TextInputFilter";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useDrag, useDrop } from "react-dnd";
import { FieldContainer, Label } from "../../../filter/styled";
import { nanoid } from "nanoid";
import { useQuery } from "react-query";
import { getPollById, savePoll } from "../../../api";
import { SpinnerContainer } from "../../../../../styled/pages";
import InlineTextEditor from "../../../filter/InlineTextEditor";
import { InfoPanel } from "./styled";

import { useTheme } from "styled-components";

const PollDataPropertyKeys = {
  USER_VOTES: "userVotes",
  OPTIONS: "options",
};

const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  margin-top: 14px;
`;

const OptionWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 5px;

  ${({ isHoveringOver, orderDirection }) => {
    if (!isHoveringOver) {
      return null;
    }

    return orderDirection === "top-bottom" ? `border-bottom: 1px solid #aaa;` : `border-top: 1px solid #aaa;`;
  }};

  .title {
    flex: 1;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #091e42;
  }
`;

const IconContainer = styled.div`
  display: grid;
  place-content: center;
`;

const AddOptionWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  column-gap: 5px;
  font-weight: 600;
  font-size: 12px;
  line-height: 25px;
  color: #344563;

  margin-top: 18px;
`;

export const InfoIconDark = () => (
  <svg width="24" height="24" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="24" cy="24" r="19" fill="#082145" />
    <path
      d="M24 32C24.4533 32 24.8336 31.8464 25.1408 31.5392C25.4469 31.2331 25.6 30.8533 25.6 30.4V23.96C25.6 23.5067 25.4469 23.1333 25.1408 22.84C24.8336 22.5467 24.4533 22.4 24 22.4C23.5467 22.4 23.1669 22.5531 22.8608 22.8592C22.5536 23.1664 22.4 23.5467 22.4 24V30.44C22.4 30.8933 22.5536 31.2667 22.8608 31.56C23.1669 31.8533 23.5467 32 24 32ZM24 19.2C24.4533 19.2 24.8336 19.0464 25.1408 18.7392C25.4469 18.4331 25.6 18.0533 25.6 17.6C25.6 17.1467 25.4469 16.7664 25.1408 16.4592C24.8336 16.1531 24.4533 16 24 16C23.5467 16 23.1669 16.1531 22.8608 16.4592C22.5536 16.7664 22.4 17.1467 22.4 17.6C22.4 18.0533 22.5536 18.4331 22.8608 18.7392C23.1669 19.0464 23.5467 19.2 24 19.2ZM24 40C21.7867 40 19.7067 39.5797 17.76 38.7392C15.8133 37.8997 14.12 36.76 12.68 35.32C11.24 33.88 10.1003 32.1867 9.2608 30.24C8.42027 28.2933 8 26.2133 8 24C8 21.7867 8.42027 19.7067 9.2608 17.76C10.1003 15.8133 11.24 14.12 12.68 12.68C14.12 11.24 15.8133 10.0997 17.76 9.2592C19.7067 8.41973 21.7867 8 24 8C26.2133 8 28.2933 8.41973 30.24 9.2592C32.1867 10.0997 33.88 11.24 35.32 12.68C36.76 14.12 37.8997 15.8133 38.7392 17.76C39.5797 19.7067 40 21.7867 40 24C40 26.2133 39.5797 28.2933 38.7392 30.24C37.8997 32.1867 36.76 33.88 35.32 35.32C33.88 36.76 32.1867 37.8997 30.24 38.7392C28.2933 39.5797 26.2133 40 24 40Z"
      fill="#85B8FF"
    />
  </svg>
);

const Option = ({ option, removePanel, reorderPanels, updatePanelLabel }) => {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: ["poll-option"],
    drop: () => ({ id: option.id, ...option }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  }));

  const [{ draggedItem }, drag] = useDrag({
    type: "poll-option",
    item: () => {
      return { id: option.id, type: "poll-option", ...option };
    },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (item && dropResult) {
        reorderPanels(item.id, dropResult.id);
      }
    },
    collect: (monitor) => ({
      draggedItem: monitor.getItem(),
    }),
  });

  const draggedItemPosition = draggedItem?.order ?? 0;
  const potentialTargetPosition = isOver ? option?.order : 0;

  const theme = useTheme();

  return (
    <OptionWrapper
      ref={drop}
      isHoveringOver={isOver}
      orderDirection={draggedItemPosition < potentialTargetPosition ? "top-bottom" : "bottom-top"}
    >
      <IconContainer style={{ cursor: "grab" }} ref={drag}>
        <DragHandlerIcon primaryColor="#42526E" />
      </IconContainer>

      <InlineTextEditor value={option.label} setValue={(v) => updatePanelLabel(option.id, v)} />

      <IconContainer style={{ cursor: "pointer" }} onClick={() => removePanel(option.id)}>
        <EditorRemoveIcon primaryColor={theme.poll.icons} />
      </IconContainer>
    </OptionWrapper>
  );
};

const PollOptions = ({ options, updatePollData, resetUsers }) => {
  const theme = useTheme();

  const resetOrder = (list) => {
    const resetList = list.map((c, index) => {
      const slide = c;
      slide.order = index + 1;
      return slide;
    });
    return resetList;
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return resetOrder(result);
  };

  const reorderPanels = (sourceId, targetId, isMain) => {
    if (sourceId === targetId) {
      return;
    }
    const copyOfContent = [...options];
    const sourceIndex = copyOfContent.findIndex((p) => p.id === sourceId);
    const targetIndex = copyOfContent.findIndex((p) => p.id === targetId);
    const items = reorder(copyOfContent, sourceIndex, targetIndex);
    updatePollData(PollDataPropertyKeys.OPTIONS, items);
  };

  const removePanel = (optionId) => {
    const newOptions = options.filter((option) => option.id !== optionId);
    resetUsers();
    updatePollData(PollDataPropertyKeys.OPTIONS, newOptions);
  };

  const updatePanelLabel = (optionId, value) => {
    const newOptions = options.map((option) => (option.id === optionId ? { ...option, label: value } : { ...option }));
    updatePollData(PollDataPropertyKeys.OPTIONS, newOptions);
  };

  const createPanel = () => {
    const newOption = {
      id: nanoid(6),
      label: "New option",
      order: options.length + 1,
    };

    resetUsers();
    updatePollData(PollDataPropertyKeys.OPTIONS, [...options, newOption]);
  };

  return (
    <>
      <FieldContainer>
        <Label>Poll options</Label>

        <DndProvider backend={HTML5Backend}>
          <OptionsContainer>
            {options
              .sort((a, b) => a.order - b.order)
              .map((option) => (
                <Option
                  key={option.id}
                  option={option}
                  removePanel={(optionId) => removePanel(optionId)}
                  reorderPanels={reorderPanels}
                  updatePanelLabel={updatePanelLabel}
                />
              ))}
          </OptionsContainer>
        </DndProvider>

        <AddOptionWrapper onClick={() => createPanel()}>
          <EditorAddIcon primaryColor={theme.poll.icons} />
          <span style={{ color: theme.poll.icons }}>Add an option</span>
        </AddOptionWrapper>
      </FieldContainer>
    </>
  );
};

function PollEditor({ panel, updatePanelDataProperty, updatePanelTitle }) {
  const [pollData, setPollData] = useState({});
  const [isInitialized, setIsInitialized] = useState(false);
  const [shouldResetUsers, setShouldResetUsers] = useState(false);

  const { data } = panel;

  const theme = useTheme();

  const pollDataRef = useRef();
  pollDataRef.current = { ...pollData };

  const pollIdRef = useRef();
  pollIdRef.current = data.pollId;

  const { isLoading, data: pollResponseData } = useQuery(
    [
      `get-poll-${data.pollId}`,
      {
        pollId: data.pollId,
      },
    ],
    getPollById,
    {
      enabled: !!data.pollId,
      retry: 0,
      select: (response) => {
        const { data } = response;
        return data;
      },
    },
  );

  useEffect(() => {
    if (data && !data.pollId) {
      updatePanelDataProperty(panel.id, "pollId", nanoid(6), true);
    }
  }, [data?.pollId]);

  const savePollData = () => {
    if (pollIdRef.current) {
      savePoll(pollIdRef.current, {
        ...pollDataRef.current,
        userVotes: shouldResetUsers ? [] : pollDataRef.current.userVotes,
      })
        .then((res) => {
          window.AP.events.emit("updated-poll-settings", pollIdRef.current);
        })
        .catch((err) => console.log(err));
    }
  };

  const updatePollData = (key, value) => {
    setPollData({ ...pollData, [key]: value });
  };

  const resetUsers = () => {
    setShouldResetUsers(true);
  };

  const handleSaveDebounce = useRef(
    debounce(() => {
      savePollData();
    }, 500),
  ).current;

  useEffect(() => {
    return () => {
      handleSaveDebounce.cancel();
    };
  }, [handleSaveDebounce]);

  useEffect(() => {
    if (pollResponseData) {
      if (!pollResponseData.exists) {
        setPollData({
          description:
            "We're no Van Gogh, that's plain to see. So lend us your eye, and help us choose our Content Viz dashboard design style.",
          options: [
            { id: nanoid(6), label: "Style Tokyo", order: 1 },
            { id: nanoid(6), label: "Style Amsterdam", order: 2 },
            { id: nanoid(6), label: "Style New York", order: 3 },
          ],
          userVotes: [],
        });
      } else {
        setPollData({
          ...pollResponseData.data,
        });
      }
      setIsInitialized(true);
    }
  }, [isLoading, data]);

  useEffect(() => {
    if (pollData && Object.keys(pollData).length && isInitialized) {
      handleSaveDebounce();
    }
  }, [pollData]);

  if (isLoading) {
    return (
      <SpinnerContainer>
        <Spinner size="large" />
      </SpinnerContainer>
    );
  }

  return (
    <div>
      <InfoPanel style={{ display: "flex", alignItems: "center" }}>
        {theme.global.name === "light" ? <InfoIcon size="large" primaryColor="#0052cc" /> : <InfoIconDark />}
        The poll is anonymous.
      </InfoPanel>

      <TextInputFilter label="Title" value={panel.panelTitle} onUpdate={(value) => updatePanelTitle(panel.id, value)} />

      <TextAreaFilter
        label="Description"
        value={pollData?.description}
        onUpdate={(value) => setPollData({ ...pollData, description: value })}
      />

      <PollOptions
        panelId={panel.id}
        options={pollData?.options || []}
        updatePollData={updatePollData}
        resetUsers={resetUsers}
      />
    </div>
  );
}

export default PollEditor;
