import styled from "styled-components";
import { SidePanel, SidePanelHeader } from "../General";
import Button from "@atlaskit/button";
import { useTheme } from "styled-components";
import { useState, useEffect } from "react";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  padding-top: 40px;
`;

const Title = styled.span`
  font-size: 20px;
  font-weight: 400;
  color: ${({ theme }) => theme.shared.settings.general.brandAndColors.underConstructionTitle};
  margin-bottom: 8px;
`;

const Description = styled.span`
  width: 420px;
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme.shared.settings.general.brandAndColors.underConstructionDescription};
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 24px;
`;

function Preview({ isEnabled, title, description, buttonText, link }) {
  const [previewLink, setPreviewLink] = useState();
  const theme = useTheme();

  useEffect(() => {
    if (link && link.source === "email") {
      setPreviewLink(`mailto:${link.link}`);
    } else if (link) {
      setPreviewLink(link.link);
    }
  }, [link]);

  if (!isEnabled) {
    return null;
  }

  return (
    <SidePanel style={{ marginTop: "0px" }}>
      <SidePanelHeader>Preview</SidePanelHeader>

      <Wrapper>
        <img src={`/images/hub/under-construction-empty-state-${theme.global.name}.svg`} alt="" />

        <Title>{title}</Title>
        <Description>{description}</Description>

        {link && (
          <Button appearance="primary">
            <a target="_blank" rel="noreferrer" href={previewLink} style={{ all: "unset" }}>
              {buttonText}
            </a>
          </Button>
        )}
      </Wrapper>
    </SidePanel>
  );
}

export default Preview;
