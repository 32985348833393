import { Box, Flex } from "@atlaskit/primitives";
import React from "react";
import { useNavigationStore } from "../../../../../store";
import { PARENT_ENTRY_IDENTIFIER } from "../../../../../defaults";
import IconTitle from "../shared/IconTitle";
import { Link } from "../shared/LinkConfig";

function Hum() {
  const { editingEntry, updateEntry } = useNavigationStore((state) => ({
    editingEntry: state.editingEntry,
    updateEntry: state.updateEntry,
  }));

  const isParent = editingEntry.parent === PARENT_ENTRY_IDENTIFIER;

  return (
    <Box>
      <Box paddingBlockStart={isParent ? "space.300" : "space.0"}>
        <Flex direction="column">
          <IconTitle />
        </Flex>

        {editingEntry.id !== "f66589ac-1783-4ae2-8aed-7fe006fa3a53" && (
          <div style={{ marginTop: "20px" }}>
            <Link
              link={editingEntry?.data?.link}
              onSelected={(selected) => {
                if (selected?.link?.link) {
                  updateEntry(editingEntry.id, "openNewTab", selected.source !== "page", true);
                }
                updateEntry(editingEntry.id, "link", selected, true);
              }}
            />
          </div>
        )}
      </Box>
    </Box>
  );
}

export default Hum;
