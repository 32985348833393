/* eslint-disable react/forbid-prop-types */
import React, { useRef } from "react";
import PropTypes from "prop-types";
import EditorCloseIcon from "@atlaskit/icon/glyph/editor/close";

import styled from "styled-components";
import SimpleBar from "./Components/Simplebar";
import { useClickOutside } from "./useClickOutside";
import { useTheme } from "styled-components";

const SidebarContainer = styled.div`
  display: flex;
  position: absolute;
  overflow: hidden;
  top: 0;
  bottom: 0;
  justify-content: ${({ position }) => (position === "left" ? "end" : "start")};
  width: ${({ width, isOpen }) => (isOpen ? width : 0)}px;
  ${({ position }) => position}: 0;
  background: ${({ theme }) => theme.sidebar.background};
  z-index: 10005;
  box-shadow: -1px 0px 4px rgba(0, 0, 0, 0.25);
  transition:
    visibility 0.1s,
    width 0.2s;
`;

const Sidebar = styled.div`
  box-sizing: border-box;
  height: 100%;
  padding: 20px;
  min-width: ${({ width }) => width}px;
`;

const ScrollbarWrapper = styled.div`
  height: 100%;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid ${({ theme }) => theme.sidebar.seperator};
  margin-bottom: 15px;
  color: #0065ff;
  font-size: 14px;
  font-weight: 500;
`;

const TabsContainer = styled.div`
  display: flex;
  column-gap: 15px;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: -2px;
`;

const Tab = styled.span`
  color: ${({ isSelected, theme }) =>
    isSelected ? theme.shared.sidebar.tabs.text.active : theme.shared.sidebar.tabs.text.inactive};
  border-bottom: ${({ isSelected }) => (isSelected ? "2px solid #0065FF" : "none")};
  cursor: pointer;
  user-select: none;
`;

const SidebarTransition = React.memo(
  ({
    isOpen,
    position,
    close,
    width,
    additionalStyle,
    containerStyles,
    className,
    showTabs,
    tabs,
    selectedTab,
    setSelectedTab,
    shouldHideScrollbar,
    children,
    title,
  }) => {
    const dialogRef = useRef();

    useClickOutside(dialogRef, close);

    const theme = useTheme();

    return (
      <SidebarContainer
        ref={dialogRef}
        isOpen={isOpen}
        width={width}
        position={position}
        className={className}
        style={{ ...containerStyles }}
      >
        <Sidebar width={width}>
          <Header>
            {showTabs ? (
              <>
                {!!tabs.length && (
                  <TabsContainer>
                    {tabs.map((tab) => (
                      <Tab key={tab} isSelected={selectedTab === tab} onClick={() => setSelectedTab(tab)}>
                        {tab}
                      </Tab>
                    ))}
                  </TabsContainer>
                )}
              </>
            ) : (
              <span>{title}</span>
            )}
            <span role="none" style={{ cursor: "pointer" }} onClick={() => close()}>
              <EditorCloseIcon primaryColor={theme.poll.icons} />
            </span>
          </Header>
          <ScrollbarWrapper>
            {shouldHideScrollbar ? (
              <>{children}</>
            ) : (
              <SimpleBar
                style={{
                  ...additionalStyle,
                  overflowX: "hidden",
                  height: "calc(100% - 40px)",
                }}
              >
                {children}
              </SimpleBar>
            )}
          </ScrollbarWrapper>
        </Sidebar>
      </SidebarContainer>
    );
  },
);

SidebarTransition.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  position: PropTypes.oneOf(["left", "right"]),
  close: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  width: PropTypes.number,
  additionalStyle: PropTypes.objectOf(PropTypes.string),
  containerStyles: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
  showTabs: PropTypes.bool,
  title: PropTypes.string,
  tabs: PropTypes.arrayOf(PropTypes.string),
  selectedTab: PropTypes.string,
  setSelectedTab: PropTypes.func,
  shouldHideScrollbar: PropTypes.bool,
};

SidebarTransition.defaultProps = {
  children: undefined,
  className: "",
  position: "right",
  title: "",
  width: 400,
  additionalStyle: {},
  containerStyles: {},
  showTabs: false,
  tabs: [],
  selectedTab: "",
  setSelectedTab: () => {},
  shouldHideScrollbar: false,
};

SidebarTransition.displayName = "SidebarTransition";

export default SidebarTransition;
