import { CardDefaultData } from "../../Shared/Panels/data/cards";
import { recentlyActiveUsersEvents } from "../../Shared/Panels/edit/active-users/configurator/user-events";
import { PanelKeys } from "./panelTypes";

export const DEFAULT_DATA_BY_PANEL_KEY = {
  [PanelKeys.WORK_ANNIVERSARY_MAIN]: {
    isDummy: true,
    maxNumberOfItems: 4,
    useYears: true,
    backgroundType: "image",
    rounding: 3,
    imageSize: 80,
    collection: "",
    userGroup: "",
    pagination: "show-more",
    view: "card",
  },
  [PanelKeys.SIDE_NEWS]: {
    isSideNews: true,
    view: "side_news",
    loadingType: "dynamic",
    labels: [],
    spaces: [],
    contributors: [],
    maxNumberOfItems: 4,
    pagination: "infinite",
    sortBy: "created",
    ordering: "descending",
    activeElements: ["author", "space", "date"],
    labelsBackground: "#DFD8FD",
    isDummy: false,
    manuallyPicked: [],
    content: [],
  },
  // [PanelKeys.TARGETED_NEWS]: {
  //   view: "magazine",
  //   labels: [],
  //   spaces: [],
  //   contributors: [],
  //   maxNumberOfItems: 4,
  //   activeElements: ["author", "label", "space", "description", "date", "categories"],
  //   labelsBackground: "#ffffff",
  //   teamLabelsBackground: "#42526E",
  //   coverPictureSize: 180,
  //   coverPictureBorderRadius: 3,
  //   padding: 24,
  //   pagination: "show-more",
  //   sortBy: "created",
  //   ordering: "descending",
  //   displayTitle: true,
  //   displayBox: true,
  // },
  [PanelKeys.COMPANY_NEWS]: {
    view: "magazine",
    loadingType: "dynamic",
    labels: [],
    spaces: [],
    contributors: [],
    maxNumberOfItems: 4,
    pagination: "infinite",
    sortBy: "created",
    ordering: "descending",
    activeElements: ["image", "author", "label", "space", "description", "date"],
    labelsBackground: "#ffffff",
    teamLabelsBackground: "#42526E",
    coverPictureSize: 180,
    coverPictureBorderRadius: 3,
    padding: 24,
    isDummy: true,
    manuallyPicked: [],
    displayTitle: true,
    displayBox: true,
    content: [
      {
        id: "blogpost-1",
        name: "How team agreements help navigate the new world of hybrid work",
        space: { name: "Marketing" },
        excerpt: "",
        coverPictureCallback: async () => {
          return "https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=640&h=480&q=80";
        },
        labels: [],
        url: "",
        createdBy: "Mary Lee",
        avatarUrl:
          "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&w=640&h=480&q=80",
        lastModified: "Feb 1, 2023",
        created: "Feb 1, 2023",
        createdFormatted: "Feb 1, 2023",
        numberOfComments: 6,
        numberOfLikes: 8,
        spaceNameBackground: "#FFEBE6",
      },
      {
        id: "blogpost-2",
        name: "How and when to use corporate assets",
        space: { name: "UI Design" },
        excerpt: "",
        coverPictureCallback: async () => {
          return "https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=640&h=480&q=80";
        },
        labels: [],
        url: "",
        createdBy: "Mary Lee",
        avatarUrl:
          "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&w=640&h=480&q=80",
        lastModified: "Feb 1, 2023",
        created: "Feb 1, 2023",
        createdFormatted: "Feb 1, 2023",
        numberOfComments: 11,
        numberOfLikes: 8,
        spaceNameBackground: "#D4E8FF",
      },
      {
        id: "blogpost-3",
        name: "Your 3-step approach to delivering effective marketing campaign",
        space: { name: "Marketing" },
        excerpt: "",
        coverPictureCallback: async () => {
          return "https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=640&h=480&q=80";
        },
        labels: [],
        url: "",
        createdBy: "Mary Lee",
        avatarUrl:
          "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&w=640&h=480&q=80",
        lastModified: "Feb 1, 2023",
        created: "Feb 1, 2023",
        createdFormatted: "Feb 1, 2023",
        numberOfComments: 5,
        numberOfLikes: 8,
        spaceNameBackground: "#FFEBE6",
      },
    ],
  },
  // [PanelKeys.PERSONALIZED_NEWS]: {
  //   view: "magazine",
  //   pagination: "show-more",
  //   maxNumberOfItems: 4,
  //   activeElements: ["author", "label", "space", "description", "date", "categories"],
  //   watchingSpaces: true,
  //   subscribedCategories: true,
  //   sortBy: "created",
  //   ordering: "descending",
  //   coverPictureSize: 180,
  //   displayTitle: true,
  //   displayBox: true,
  //   labelsBackground: "#ffffff",
  //   teamLabelsBackground: "#42526E",
  //   padding: 24,
  // },
  [PanelKeys.COUNTDOWN]: {
    isDummy: true,
    dateandtime: 1735689600,
    title: "New Year '25 🚀",
    description: "The ultimate event everyone returns.",
    color: "#0065ff",
    openInNewTab: true,
    link: { source: "direct", link: "https://www.atlassian.com/company/events" },
    removeOnFinishedCount: false,
  },
  [PanelKeys.VIDEOS_YT]: {
    displayTitle: true,
    displayBox: true,
    isDummy: true,
    view: "grid",
    autoplay: false,
    pagination: "show-more-less",
    links: [
      { url: "https://www.youtube.com/watch?v=tRs4qQMMKpk", title: "Welcome to Atlassian" },
      { url: "https://www.youtube.com/watch?v=onaofZTHojA", title: "Atlassian presents: Unleash" },
      { url: "https://www.youtube.com/watch?v=kaNPNbAT-as", title: "Getting started in Confluence" },
    ],
  },
  [PanelKeys.NEW_STARTERS]: {
    isDummy: true,
    titleFieldId: "FF2Vrog",
    footerFieldId: "dc49cu8",
    titleFieldOn: true,
    displayBox: true,
    displayTitle: true,
    footerFieldOn: true,
    view: "card",
    backgroundType: "image",
    rounding: 6,
    imageSize: 80,
    collection: "",
    userGroup: "",
    maxNumberOfItems: 4,
    pagination: "show-more",
  },
  [PanelKeys.TEAM_NEWS]: {
    view: "list",
    loadingType: "dynamic",
    labels: [],
    spaces: [],
    contributors: [],
    maxNumberOfItems: 4,
    pagination: "infinite",
    sortBy: "created",
    ordering: "descending",
    activeElements: ["image", "author", "label", "space", "description", "date"],
    labelsBackground: "#ffffff",
    coverPictureSize: 180,
    coverPictureBorderRadius: 8,
    padding: 24,
    isDummy: true,
    manuallyPicked: [],
    displayTitle: true,
    content: [
      {
        id: "blogpost-1",
        name: "How feedback helps you in design and development",
        space: { name: "UI Design" },
        excerpt:
          "Feedback plays a pivotal role in design and development by providing valuable insights, identifying areas for improvement, and validating design choices. It offers perspectives from various stakeholders, ensuring that the final product meets user needs effectively…",
        coverPictureCallback: async () => {
          return "https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=640&h=480&q=80";
        },
        labels: [],
        url: "",
        createdBy: "Atlassian",
        avatarUrl:
          "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&w=640&h=480&q=80",
        lastModified: "Feb 7, 2023",
        created: "Feb 7, 2023",
        createdFormatted: "Feb 7, 2023",
        numberOfComments: 4,
        numberOfLikes: 9,
      },
      {
        id: "blogpost-2",
        name: "Where to start with creating a dashboard for company intranet",
        space: { name: "Marketing" },
        excerpt:
          "To start creating a dashboard for a company intranet, begin by defining the objectives and understanding user needs. Collaborate with stakeholders to gather requirements and select a platform or framework for building the dashboard…",
        coverPictureCallback: async () => {
          return "https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=640&h=480&q=80";
        },
        labels: [],
        url: "",
        createdBy: "Atlassian",
        avatarUrl:
          "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&w=640&h=480&q=80",
        lastModified: "Feb 3, 2023",
        created: "Feb 3, 2023",
        createdFormatted: "Feb 3, 2023",
        numberOfComments: 8,
        numberOfLikes: 16,
      },
      {
        id: "blogpost-4",
        name: "Monitoring and keeping track of every marketing campaign",
        space: { name: "Marketing" },
        excerpt:
          "To effectively monitor and keep track of every marketing campaign, start by establishing clear goals and key performance indicators (KPIs) for each campaign. Utilize marketing analytics tools to track relevant metrics such as website traffic, conversion rates, engagement, and ROI…",
        coverPictureCallback: async () => {
          return "https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=640&h=480&q=80";
        },
        labels: [],
        url: "",
        createdBy: "Atlassian",
        avatarUrl:
          "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&w=640&h=480&q=80",
        lastModified: "Feb 1, 2023",
        created: "Feb 1, 2023",
        createdFormatted: "Feb 1 2023",
        numberOfComments: 7,
        numberOfLikes: 19,
      },
    ],
  },
  [PanelKeys.PAGES]: {
    view: "card",
    loadingType: "dynamic",
    labels: [],
    spaces: [],
    contributors: [],
    maxNumberOfItems: 3,
    displayTitle: true,
    pagesLeftOff: false,
    pagination: "show-more",
    sortBy: "created",
    ordering: "descending",
    activeElements: ["author", "label", "space", "description", "date"],
    labelsBackground: "#ffffff",
    manuallyPicked: [],
    content: [],
    parentPageId: undefined,
    excludePersonalSpaces: true,
    displayBox: true,
  },
  [PanelKeys.PAGES_SIDE]: {
    isSideNews: true,
    view: "mini-card",
    loadingType: "dynamic",
    labels: [],
    spaces: [],
    contributors: [],
    maxNumberOfItems: 4,
    displayTitle: true,
    pagesLeftOff: false,
    pagination: "infinite",
    sortBy: "created",
    ordering: "descending",
    activeElements: ["author", "label", "space", "description", "date"],
    labelsBackground: "#ffffff",
    manuallyPicked: [],
    content: [],
    parentPageId: undefined,
    excludePersonalSpaces: false,
  },
  [PanelKeys.INFO_PANEL]: {
    isDummy: true,
    type: "info",
    title: "Important",
    description:
      "The San Francisco office will be closed on May 16th. Our coffee machine decided to take the day off too.",

    hasIcon: true,
    hasTitle: true,
    icon: undefined,
    iconSize: 48,
    titleSize: 24,
    textSize: 16,
    alignment: "left",
  },
  [PanelKeys.RECENTLY_ACTIVE_USERS_MAIN]: {
    isDummy: false,
    title: "Recently active users",
    pagination: "show-more",
    cardStyle: "card",
    backgroundType: "image",
    maxNumberOfItems: 4,
    displayTitle: true,
    displayBox: true,
    events: [...recentlyActiveUsersEvents],
    cardRounding: 0,
    profileImageSize: 80,
  },
  [PanelKeys.RECENTLY_ACTIVE_USERS_SIDE]: {
    isDummy: false,
    title: "Recently active users",
    pagination: "show-more",
    cardStyle: "card",
    backgroundType: "image",
    maxNumberOfItems: 4,
    displayTitle: true,
    events: [...recentlyActiveUsersEvents],
    cardRounding: 0,
    profileImageSize: 80,
  },
  [PanelKeys.IMPORTANT_DATES]: {
    isDummy: true,
    maxNumberOfItems: 4,
    pagination: "show-more",
  },
  [PanelKeys.IMPORTANT_DATES_MAIN]: {
    isDummy: true,
    maxNumberOfItems: 4,
    backgroundType: "image",
    rounding: 3,
    imageSize: 80,
    displayTitle: true,
    displayBox: true,
    pagination: "show-more",
  },
  [PanelKeys.PEOPLE_MAIN]: {
    isDummy: false,
    maxNumberOfItems: 4,
    view: "card",
    backgroundType: "image",
    rounding: 3,
    imageSize: 80,
    pagination: "show-more",
    loadingType: "dynamic",
    displayTitle: true,
    displayBox: true,
    titleFieldId: "FF2Vrog",
    footerFieldId: "qADc-c1x",
    titleFieldOn: true,
    footerFieldOn: true,
    collection: "",
    userGroup: "",
    manuallyPicked: [],
  },
  [PanelKeys.PEOPLE_SIDE]: {
    isDummy: false,
    maxNumberOfItems: 4,
    view: "card",
    backgroundType: "image",
    rounding: 3,
    imageSize: 80,
    pagination: "show-more",
    loadingType: "dynamic",
    displayTitle: true,
    titleFieldId: "FF2Vrog",
    titleFieldOn: true,
    collection: "",
    userGroup: "",
    manuallyPicked: [],
  },
  [PanelKeys.POLL]: {
    isDummy: true,
    pollId: undefined,
  },
  [PanelKeys.APP_LINKS]: {
    isDummy: true,
    displayTitle: false,
    links: [
      {
        id: "link-1",
        icon: "https://caelor-apps.web.app/images/icons/jira.svg",
        title: "Jira",
        link: "",
        order: 1,
      },
      {
        id: "link-2",
        icon: "https://caelor-apps.web.app/images/icons/trello.svg",
        title: "Trello",
        link: "",
        order: 2,
      },
      {
        id: "link-4",
        icon: "https://caelor-apps.web.app/images/icons/slack.svg",
        title: "Slack",
        link: "",
        order: 3,
      },
      {
        id: "link-3",
        icon: "https://caelor-apps.web.app/images/icons/hubspot.svg",
        title: "Hubspot",
        link: "",
        order: 4,
      },
    ],
  },
  [PanelKeys.ASSIGNED_TO_ME]: {
    isDummy: true,
    maxNumberOfItems: 5,
    pagination: "infinite",
  },
  [PanelKeys.IMPORTANT_SPACES]: {
    isDummy: true,
    isSideNews: true,
    displayTitle: true,
    pagination: "infinite",
    labels: [],
    maxNumberOfItems: 4,
    loadingType: "dynamic",
    manuallyPicked: [],
    spaceType: "global",
    spaceStatus: "current",
    activeElements: ["author"],
    displayBox: true,
  },
  [PanelKeys.IMPORTANT_SPACES_MAIN]: {
    isDummy: true,
    pagination: "infinite",
    view: "card",
    labels: [],
    maxNumberOfItems: 3,
    loadingType: "dynamic",
    isSideNews: false,
    displayTitle: true,
    manuallyPicked: [],
    spaceType: "global",
    spaceStatus: "current",
    activeElements: ["author", "description", "admins", "categories"],
    displayBox: true,
  },
  [PanelKeys.ICON_CARDS]: CardDefaultData[PanelKeys.ICON_CARDS],
  [PanelKeys.TEXT_CARDS]: CardDefaultData[PanelKeys.TEXT_CARDS],
  [PanelKeys.IMG_CARDS]: CardDefaultData[PanelKeys.IMG_CARDS],
  [PanelKeys.RELEASED_AI]: {
    channelId: "",
    elementsToDisplay: ["header", "coverimage"],
    title: "What's new",
    subtitle: "Discover latest features and improvements.",
    color: "#6515DD",
  },
  [PanelKeys.GLIFFY]: {
    spaceIds: [],
    loadingType: "dynamic",
    manualImageSrc: "",
    view: "card",
    pagination: "show-more",
    elementsToDisplay: ["attachedto", "contributors", "date"],
    orderBy: "desc",
    sortOrder: "modified",
    displayTitle: true,
    displayBox: true,
  },
};
