import React from "react";
import { FieldContainer, Label } from "./styled";
import Select from "@atlaskit/select";
import { selectStylesWithOverrides } from "./CustomSelectStyles";
import { useSidebarStore } from "../panels/edit/sidebar/sidebar-store";

function SelectFilter({
  label,
  value,
  setValue,
  options,
  hideValueFromSelect = false,
  isMulti = false,
  isDisabled = false,
  noDetails = false,
  isLoading = false,
  isClearable = false,
  placeholder = "Filter by collections..",
  closeMenuOnSelect = true,
  menuPlacement = "auto",
}) {
  const setDisableClickOutside = useSidebarStore((state) => state.setDisableClickOutside);

  const colorSchemeSelect = selectStylesWithOverrides({
    control: (provided) => ({
      ...provided,
      fontSize: "14px",
    }),
    option: (provided) => ({
      ...provided,
      fontSize: "14px",
    }),
  });
  if (noDetails) {
    return (
      <Select
        controlShouldRenderValue={!hideValueFromSelect}
        menuPlacement={menuPlacement}
        placeholder={placeholder}
        styles={colorSchemeSelect}
        isClearable={isClearable}
        options={options}
        value={isMulti ? value : options.find((op) => op.value === value)}
        onChange={(newOption) => {
          if (!isMulti) {
            setValue(newOption?.value);
          } else {
            setValue(newOption);
          }
        }}
        isDisabled={isDisabled}
        isLoading={isLoading}
        isMulti={isMulti}
      />
    );
  }

  return (
    <FieldContainer
      onMouseEnter={() => setDisableClickOutside(true)}
      onMouseLeave={() => setDisableClickOutside(false)}
    >
      <Label>{label}</Label>
      <Select
        controlShouldRenderValue={!hideValueFromSelect}
        menuPlacement="auto"
        placeholder={placeholder}
        styles={colorSchemeSelect}
        isClearable={isClearable}
        options={options}
        value={isMulti ? value : options.find((op) => op.value === value)}
        onChange={(newOption) => {
          if (!isMulti) {
            setValue(newOption?.value);
          } else {
            setValue(newOption);
          }
        }}
        isDisabled={isDisabled}
        isLoading={isLoading}
        isMulti={isMulti}
        closeMenuOnSelect={closeMenuOnSelect}
      />
    </FieldContainer>
  );
}

export default SelectFilter;
