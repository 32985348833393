import React, { useEffect, useRef } from "react";
import debounce from "lodash.debounce";
import PropTypes from "prop-types";
import styled from "styled-components";

import { ColorPicker, useColor } from "react-color-palette";
import "react-color-palette/lib/css/styles.css";

const ColorPickerWrapper = styled.div`
  ${({ isDisabled }) => {
    if (isDisabled) return "cursor: not-allowed;";
  }}

  .rcp-light {
    --rcp-background: transparent !important;
  }

  .rcp-saturation {
    border-radius: 4px;
  }

  .rcp-body {
    margin-top: 20px;
    padding: 1px;

    .rcp-hue,
    .rcp-alpha {
      width: 100%;
    }

    .rcp-fields {
      margin-top: 5px;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
        "Droid Sans", "Helvetica Neue", sans-serif;
      display: flex;
      align-items: center;

      .rcp-fields-element {
        align-items: flex-start;
        gap: 8px;
      }

      & > div:first-child {
        order: 1;
      }

      .rcp-fields-element-label {
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        color: ${({ theme }) => `${theme.shared.sidebar.label} !important`};
        order: 0;
        text-transform: none;
      }

      .rcp-fields-element-input {
        background-color: ${({ theme }) => `${theme.shared.sidebar.customColorInput} !important`};
        border: none;
        border-radius: 3px;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: ${({ theme }) => `${theme.shared.sidebar.label} !important`};
        order: 1;
      }
    }
  }
`;

function EmbededColorPicker({
  value,
  setValue,
  width,
  height,
  isDisabled = false,
  setIsGradientInitalized = () => {},
}) {
  const [color, setColor] = useColor("hex", value || "#df1111");

  const containerRef = useRef();

  useEffect(() => {
    const elements = document.getElementsByClassName("rcp-fields-element");
    if (elements) {
      Array.from(elements).forEach((el, index) => {
        const labelEl = el.getElementsByClassName("rcp-fields-element-label")[0];
        if (index === 0) {
          labelEl.innerText = "RGB";
        } else {
          labelEl.innerText = "Hex";
        }
      });
    }
  }, [containerRef?.current]);

  const handleColorChangeDebounce = useRef(
    debounce((colorHex) => {
      setValue(colorHex);
    }, 200),
  ).current;

  useEffect(() => {
    return () => {
      handleColorChangeDebounce.cancel();
    };
  }, [handleColorChangeDebounce]);

  return (
    <ColorPickerWrapper ref={containerRef} isDisabled={isDisabled}>
      <ColorPicker
        backgroundColor={"black"}
        width={width}
        height={height}
        color={color}
        hideHEX={isDisabled}
        hideRGB={isDisabled}
        onChange={(v) => {
          if (!isDisabled) {
            setColor(v);
            handleColorChangeDebounce(v.hex);
          }
          setIsGradientInitalized(false);
        }}
        hideHSV
        alpha
      />
    </ColorPickerWrapper>
  );
}

EmbededColorPicker.propTypes = {
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
};

export default EmbededColorPicker;
