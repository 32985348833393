import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { AsyncSelect } from "@atlaskit/select";
import { searchUsers } from "../apiPeople";

import { SIMPLE_SELECT_STYLES } from "../../Dashboard/filter/CustomSelectStyles";
import { atlassianRestService } from "../../../../../Service/AtlassianRestService";
import { CustomPlaceholder } from "../../Shared/CheckboxSelect/CustomCheckboxSelectComponents";

const UserLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 5px;
`;

const UserImage = styled.img`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  box-sizing: border-box;
`;

const UserName = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.shared.settings.database.filtersUserTextColor};
  white-space: nowrap;
  user-select: none;
`;

export const UserLabel = ({ name, icon }) => (
  <UserLabelWrapper>
    <UserImage src={icon} />
    <UserName>{name}</UserName>
  </UserLabelWrapper>
);

function User({ initialValue, onChange, fieldTitle, isMulti = true }) {
  const [selectKey, setSelectKey] = useState(Math.random().toString(36).substring(7));
  const [selectedValue, setSelectedValue] = useState([]);

  const selectedItem = isMulti ? selectedValue?.[0]?.name : selectedValue?.name;

  useEffect(() => {
    if (!initialValue) {
      setSelectedValue([]);
      return;
    }

    (async () => {
      try {
        await atlassianRestService
          .fetchUsers(initialValue)
          .then((results) => {
            setSelectedValue(
              results.map((r) => ({
                label: <UserLabel name={r.displayName} icon={`${window.AP._hostOrigin}${r.profilePicture.path}`} />,
                value: r.accountId,
                name: r.displayName,
              })),
            );
          })
          .catch((e) => {
            console.error(e);
          });
      } catch (error) {
        console.error(error);
        setSelectedValue([]);
      }
    })();
  }, [initialValue]);

  const loadOptions = async (query) => {
    try {
      const response = await searchUsers(query);
      const { results } = JSON.parse(response.body);

      const users = results
        .filter(({ user }) => user.accountType === "atlassian")
        .map(({ user }) => ({
          label: <UserLabel name={user.displayName} icon={`${window.AP._hostOrigin}${user.profilePicture.path}`} />,
          value: user.accountId,
          name: user.displayName,
        }));

      return users;
    } catch (error) {
      return [];
    }
  };

  useEffect(() => {
    window.AP.events.on("restore-filters-people-base", () => {
      setSelectKey(Math.random().toString(36).substring(7));
    });
  }, []);

  const handlePlaceholder = (props) => {
    const selectedValueLength = selectedValue?.length;

    return (
      <CustomPlaceholder
        item={selectedItem}
        count={selectedValueLength}
        isMulti={isMulti}
        fieldName={fieldTitle}
        {...props}
      />
    );
  };

  return (
    <AsyncSelect
      key={selectKey}
      defaultOptions
      className="select-search"
      isSearchable
      isMulti
      loadOptions={loadOptions}
      hideSelectedOptions={false}
      backspaceRemovesValue
      openMenuOnFocus
      menuPlacement="bottom"
      maxMenuHeight={200}
      styles={SIMPLE_SELECT_STYLES}
      controlShouldRenderValue={false}
      closeMenuOnSelect={false}
      isClearable
      value={selectedValue}
      onChange={(options) => {
        if (isMulti) {
          onChange((options || []).map(({ value }) => value));
        } else {
          onChange(options?.value || "");
        }
      }}
      components={{
        Placeholder: handlePlaceholder,
      }}
    />
  );
}

export default User;
