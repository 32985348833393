import { contentPropertyService } from "../../../Service/ContentPropertyService";
import { randomImageProvider } from "../../../Service/RandomImageProvider";

const coverPictureCache = {};

function isUUID(uuid) {
  return uuid.match("^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$") !== null;
}

function isJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export async function getCoverPictureOfContent(contentId) {
  if (contentId in coverPictureCache) {
    if (coverPictureCache[contentId] === "error") {
      return randomImageProvider.getRandomImage(contentId);
    }
    return coverPictureCache[contentId];
  }

  try {
    // const blogImage = await contentPropertyService.getBlogsContentProperty({
    //   blogPostId: contentId,
    //   key: "cosmos-news-image",
    // });
    // if (blogImage.data.length) {
    //   coverPictureCache[contentId] = blogImage?.data[0]?.value?.background?.image.link;
    //   return blogImage?.data[0]?.value?.background?.image.link;
    // }
    const url = `/rest/api/content/${contentId}/property/cover-picture-id-published`;
    const response = await window.AP.request(url);

    try {
      const body = JSON.parse(response.body);
      const isBodyValueJSON = isJsonString(body?.value);
      const fileIdOrDirectLink = isBodyValueJSON ? JSON.parse(body?.value)?.id : body?.value || "";
      if (isUUID(fileIdOrDirectLink)) {
        const attachmentLink = await findFileByIdInAttachments(contentId, fileIdOrDirectLink);
        coverPictureCache[contentId] = attachmentLink;
        return attachmentLink;
      }
      if (fileIdOrDirectLink) {
        coverPictureCache[contentId] = fileIdOrDirectLink;
        return fileIdOrDirectLink;
      }

      return randomImageProvider.getRandomImage(contentId);
    } catch (e) {
      coverPictureCache[contentId] = "error";
      return randomImageProvider.getRandomImage(contentId);
    }
  } catch (e) {
    coverPictureCache[contentId] = "error";
    return randomImageProvider.getRandomImage(contentId);
  }
}

export async function getIconOfContent(contentId) {
  const url = `/rest/api/content/${contentId}/property/emoji-title-published`;
  try {
    const response = await window.AP.request(url);
    const body = JSON.parse(response.body);
    return body.value;
  } catch (e) {
    console.error("Error fetching atlassian response", e);
    return;
  }
}

async function findFileByIdInAttachments(contentId, fileId) {
  const url = `/rest/api/content/${contentId}/child/attachment`;
  let response = await window.AP.request(url);
  let results = JSON.parse(response.body).results;
  let links = JSON.parse(response.body)._links;
  try {
    do {
      let found = results.find((r) => r.extensions.fileId === fileId);
      if (found) {
        return `${window.AP._hostOrigin}/wiki${found._links.download}`;
      }
      if ("next" in links) {
        response = JSON.parse(await window.AP.request(links.next));
        results = JSON.parse(response.body).results;
        links = JSON.parse(response.body)._links;
      }
    } while ("next" in links);
  } catch (e) {
    return null;
  }
}
