import React, { useContext, useState } from "react";
import styled from "styled-components";
import { BackgroundDialogContext } from "../Context";
import { IMAGE_TYPES } from "../ImageSourceTypes";
import GradientColorPicker from "../../GradientColorPicker";
import EmbededColorPicker from "../../EmbededColorPicker";

const ColorsWrapper = styled.div`
  display: flex;
  column-gap: 10px;
  row-gap: 10px;
  flex-wrap: wrap;
`;

const ColorTile = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 3px;
  background: ${({ theme }) => theme.shared.sidebar.colorBackground};
  cursor: pointer;

  position: relative;

  &::after {
    content: "";
    position: absolute;
    top: 4px;
    right: 4px;
    bottom: 4px;
    left: 4px;
    background: ${({ color }) => color};
  }
`;

const ColorLabel = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #42526e;
  margin-bottom: 8px;
  margin-top: 24px;
`;

const Divider = styled.div`
  width: 100%;
  margin-top: 24px;
  margin-bottom: 20px;
  border-bottom: 1px solid ${({ theme }) => theme.sidebar.seperator};
  opacity: 0.5;
`;

const TabsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 12px;
`;

const Tab = styled.div`
  display: grid;
  place-content: center;
  padding: 9px 0px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  cursor: pointer;
  background: ${({ isSelected, primaryColor, theme }) =>
    isSelected ? `${primaryColor.slice(0, 7)}1a` : theme.sidebar.backgroundTab};
  color: ${({ isSelected, primaryColor, theme }) => (isSelected ? primaryColor : theme.sidebar.colorTab)};
  border-radius: 3px;
`;

const DEFAULT_COLORS = ["#0052CC", "#091E42", "#00A2E0", "#36B37E", "#FF8B00", "#6554C0"];

const DEFAULT_GRADIENTS = [
  { rotation: "45deg", firstColor: "#FF8B00", firstColorPosition: 0, secondColor: "#FF5630", secondColorPosition: 100 },
  { rotation: "45deg", firstColor: "#FFC400", firstColorPosition: 0, secondColor: "#FF8B00", secondColorPosition: 100 },
  { rotation: "45deg", firstColor: "#2684FF", firstColorPosition: 0, secondColor: "#00B8D9", secondColorPosition: 100 },
  { rotation: "45deg", firstColor: "#6554C0", firstColorPosition: 0, secondColor: "#2684FF", secondColorPosition: 100 },
  { rotation: "45deg", firstColor: "#36B37E", firstColorPosition: 0, secondColor: "#6554C0", secondColorPosition: 100 },
  { rotation: "45deg", firstColor: "#FFC400", firstColorPosition: 0, secondColor: "#00B8D9", secondColorPosition: 100 },
];

function Color({ primaryColor }) {
  const [gradientInitialized, setIsGradientInitalized] = useState(false);
  const { image, setImage } = useContext(BackgroundDialogContext);

  const [tab, setTab] = useState("Solid");

  const [pickerKey, setPickerKey] = useState(Math.random().toString(32).slice(2));

  return (
    <>
      <TabsWrapper>
        <Tab isSelected={tab === "Solid"} primaryColor={primaryColor} onClick={() => setTab("Solid")}>
          Solid
        </Tab>
        <Tab isSelected={tab === "Gradient"} primaryColor={primaryColor} onClick={() => setTab("Gradient")}>
          Gradient
        </Tab>
      </TabsWrapper>

      <div style={{ marginTop: "24px" }}>
        {tab === "Solid" && (
          <>
            <Divider />

            <ColorLabel>Colors</ColorLabel>
            <ColorsWrapper style={{ marginBottom: "20px" }}>
              {DEFAULT_COLORS.map((clr) => (
                <ColorTile
                  key={clr}
                  color={clr}
                  onClick={() => {
                    setImage({ source: IMAGE_TYPES.COLOR, value: clr });
                    setPickerKey(Math.random().toString(32).slice(2));
                  }}
                />
              ))}
            </ColorsWrapper>

            <Divider />

            <EmbededColorPicker
              key={pickerKey}
              value={image?.source === IMAGE_TYPES.COLOR ? image?.value : ""}
              setValue={(v) => setImage({ source: IMAGE_TYPES.COLOR, value: v })}
              width={377}
              height={200}
            />

            <Divider />
          </>
        )}

        {tab === "Gradient" && (
          <>
            <Divider />

            <ColorLabel>Gradients</ColorLabel>
            <ColorsWrapper>
              {DEFAULT_GRADIENTS.map(
                ({ rotation, firstColor, firstColorPosition, secondColor, secondColorPosition }) => (
                  <ColorTile
                    key={`${firstColor}-${secondColor}`}
                    color={`linear-gradient(${rotation}, ${firstColor} ${firstColorPosition}%, ${secondColor} ${secondColorPosition}%)`}
                    onClick={() => {
                      setImage({
                        source: IMAGE_TYPES.GRADIENT,
                        value: { rotation, firstColor, firstColorPosition, secondColor, secondColorPosition },
                      });
                      setIsGradientInitalized(false);
                    }}
                  />
                ),
              )}
            </ColorsWrapper>

            <Divider />

            <GradientColorPicker
              value={image?.source === IMAGE_TYPES.GRADIENT ? image.value : undefined}
              setValue={(value) => setImage({ source: IMAGE_TYPES.GRADIENT, value })}
              presetColors={DEFAULT_COLORS}
              isInitalized={gradientInitialized}
              setIsInitialized={setIsGradientInitalized}
            />
          </>
        )}
      </div>
    </>
  );
}

export default Color;
