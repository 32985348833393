import { token } from "@atlaskit/tokens";
import styled from "styled-components";

export const MenuContentWrapper = styled.div`
  padding: 20px 25px 40px 25px;
  box-sizing: border-box;
  background-color: ${({ backgroundColor }) => backgroundColor};
  box-shadow: 0 4px 6px -6px rgba(0 0 0 / 25%);
  ${({ openOverContent }) =>
    openOverContent &&
    `position: fixed;
    z-index: 300;
    width: 100%;
    `}
`;

export const MenuContentPositioner = styled.div`
  display: grid;
  place-content: center;

  ${({ isNewsEnabled, customTemplate }) => {
    if (isNewsEnabled) {
      return `grid-template-columns: ${customTemplate ? customTemplate : "auto 260px"};`;
    }
  }}
`;

export const RootShortDescription = styled.div`
  @media (max-width: 1440px) {
    font-size: 14px;
  }

  min-width: 50px;
  max-width: 500px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: ${({ descriptionTextColor }) => descriptionTextColor};
  margin-bottom: 15px;
  padding: 0 10px;

  overflow: hidden;
`;

export const ChildMenuWrapper = styled.div`
  @media (max-width: 1440px) {
    max-height: 320px;
  }

  display: grid;
  overflow-y: auto;
  max-height: 600px;
  padding: 0 10px 5px 10px;
  column-gap: 24px;
  row-gap: 24px;
  background: transparent;
  grid-auto-rows: 1fr;
  grid-template-columns: ${({ numberOfColumns, maxNumberOfColumns = 4 }) =>
    `repeat(${
      numberOfColumns > 0 && numberOfColumns < maxNumberOfColumns ? numberOfColumns : maxNumberOfColumns
    }, minmax(190px, 370px))`};

  padding: 25px;
  margin: -25px;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.scrollbar};
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #999;
  }

  @media (max-width: ${({ isPreview }) => (isPreview ? 1600 : 1200)}px) {
    grid-template-columns: ${({ numberOfColumns }) =>
      `repeat(${numberOfColumns > 0 && numberOfColumns < 3 ? numberOfColumns : 3}, minmax(190px, 370px))`};
  }
`;

export const ChildEntry = styled.div`
  position: relative;
  z-index: 4;
  cursor: pointer;
  display: grid;
  grid-template-columns: ${({ displayIcons }) => (displayIcons ? "30px" : "")} auto;
  column-gap: 10px;
  background-color: ${({ backgroundColor: { inactive } }) => inactive};
  box-sizing: border-box;
  border: 1px solid ${token("color.border")};
  border-radius: 8px;

  padding: 20px;

  transition:
    background-color 75ms ease-in-out,
    box-shadow 75ms ease-in-out;

  &:hover {
    box-shadow:
      0px 0px 1px 0px rgba(9, 30, 66, 0.31),
      0px 8px 12px 0px rgba(9, 30, 66, 0.15);
  }

  .child-icon-bg-container {
    position: relative;
    z-index: 5;
    background-color: ${({ theme }) => theme.navigation.sydney.iconBackground};
    width: 30px;
    height: 30px;
    border-radius: 3px;

    .child-icon-container {
      position: relative;
      z-index: 10;
      width: 30px;
      height: 30px;
      display: grid;
      place-content: center;
      background-color: ${({ subentryIconColor }) => {
        if (subentryIconColor.length > 7) {
          return `${subentryIconColor.slice(0, -2)}1a`;
        }
        return `${subentryIconColor}1a`;
      }};
      border-radius: 3px;

      .icon-container {
        display: flex;

        svg {
          color: ${({ subentryIconColor }) => subentryIconColor};
        }
      }
    }
  }

  .child-name {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    margin-top: 4px;
    color: ${({ subentryTextColor }) => subentryTextColor};
  }

  .child-description {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin-top: 4px;
    color: ${({ subentryTextColor }) => {
      if (subentryTextColor.length > 7) {
        return `${subentryTextColor.slice(0, -2)}ba`;
      }
      return `${subentryTextColor}ba`;
    }};
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  &:hover {
    background-color: ${({ subentryBackgroundHoverColor, backgroundColor: { hover } }) => {
      if (subentryBackgroundHoverColor === "#00000000") return hover;
      return subentryBackgroundHoverColor;
    }};

    ${({ subentryTextIconHover }) => {
      if (subentryTextIconHover) {
        return `
          .child-name {
            color: ${subentryTextIconHover};
          }

          .child-icon-container {
            background-color: ${subentryTextIconHover}1a;

            .icon-container {
              svg {
                color: ${subentryTextIconHover};
              }
            }
          }
        `;
      }
    }}
  }
`;

export const NewsContainer = styled.div`
  .empty-state {
    width: 180px !important;
    height: 110px !important;
    margin-left: -15px;
  }
`;

export const NewsTitle = styled.div`
  line-height: 21px;
  color: ${({ descriptionTextColor }) => descriptionTextColor};
  margin-bottom: 15px;
  overflow: hidden;
`;

export const NewsDivider = styled.div`
  padding-left: 24px;
  margin-left: 14px;
  border-left: 1px solid ${({ theme }) => theme.navigation.paloAlto.childEntryBorder};

  .empty-state {
    width: 180px !important;
    height: 110px !important;
    margin-left: -15px !important;
  }
`;
