import React from "react";
import styled from "styled-components";
import DragHandlerIcon from "@atlaskit/icon/glyph/drag-handler";
import EditorRemoveIcon from "@atlaskit/icon/glyph/editor/remove";
import { useDrag, useDrop } from "react-dnd";
import { DeleteIcon, EditIcon, TrashIcon } from "../../../../../Dashboard/panels/edit/sidebar/components/Icons";

const IconContainer = styled.div`
  display: grid;
  place-content: center;
  width: 24px;
  height: 24px;
  min-width: 24px;
`;

const HiddenIconContainer = styled(IconContainer)`
  opacity: 0;
  transition: opacity 150ms linear;
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const ItemWrapper = styled.div`
  padding: 14px 10px 14px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 12px;

  border-radius: 3px;
  background-color: ${({ theme }) => theme.shared.sidebar.item.background};
  transition: background-color 150ms linear;

  cursor: pointer;

  ${({ isHoveringOver, orderDirection }) => {
    if (!isHoveringOver) {
      return null;
    }

    return orderDirection === "top-bottom" ? `border-bottom: 1px solid #aaa;` : `border-top: 1px solid #aaa;`;
  }};

  &:hover {
    background-color: ${({ theme }) => theme.shared.sidebar.item.hover};

    ${HiddenIconContainer} {
      opacity: 1;
    }
  }
`;

const ItemLeftSideWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 12px;
`;

const CardName = styled.span`
  color: ${({ theme }) => theme.shared.sidebar.item.title};
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

function Reorderable({ card, reorder, remove, edit }) {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: ["card-item"],
    drop: () => ({ id: card.id, ...card }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  }));

  const [{ draggedItem }, drag] = useDrag({
    type: "card-item",
    item: () => {
      return { id: card.id, type: "card-item", ...card };
    },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (item && dropResult) {
        reorder(item.id, dropResult.id);
      }
    },
    collect: (monitor) => ({
      draggedItem: monitor.getItem(),
    }),
  });

  const draggedItemPosition = draggedItem?.order ?? 0;
  const potentialTargetPosition = isOver ? card?.order : 0;

  return (
    <ItemWrapper
      ref={drop}
      isHoveringOver={isOver}
      orderDirection={draggedItemPosition < potentialTargetPosition ? "top-bottom" : "bottom-top"}
    >
      <ItemLeftSideWrapper>
        <IconContainer style={{ cursor: "grab" }} ref={drag}>
          <DragHandlerIcon primaryColor="#97A0AF" />
        </IconContainer>
        <CardName>{card.title}</CardName>
      </ItemLeftSideWrapper>

      <FlexContainer>
        <HiddenIconContainer style={{ cursor: "pointer" }} onClick={() => edit(card)}>
          <EditIcon />
        </HiddenIconContainer>
        <HiddenIconContainer
          style={{ cursor: "pointer" }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            remove(card.id);
          }}
        >
          <TrashIcon />
        </HiddenIconContainer>
      </FlexContainer>
    </ItemWrapper>
  );
}

export default Reorderable;
