import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Textfield from "@atlaskit/textfield";

const InputWrapper = styled.div`
  max-height: ${({ isSearching }) => (isSearching ? "0px" : "100px")};
  opacity: ${({ isSearching }) => (isSearching ? "0" : "1")};
  margin-top: ${({ isSearching }) => (isSearching ? "0px" : "12px")};
  overflow: hidden;
  transition:
    max-height 300ms linear,
    margin 300ms linear;

  input {
    color: white !important;
    &::placeholder {
      color: white !important;
    }
  }

  div:first-child {
    background: transparent !important;
    border-bottom: 1px solid #ffffff !important;
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0px;
    display: grid;
    max-width: 410px;
    display: grid;
    height: 46px;
    grid-template-columns: 39px calc(100% - 39px);
    justify-content: flex-start;
    align-items: center;
    font-size: 16px;

    .custom-apps-search {
      color: #ffffff;
      padding: 0px;
      height: 100%;
      font-size: 16px;
    }
  }

  div:first-child:hover {
    border-bottom: 1px solid #ffffff !important;
  }
`;

const SearchIcon = () => (
  <svg width="30" height="31" viewBox="0 0 20 21" fill="none">
    <rect width="20" height="20" transform="translate(0 0.40625)" fill="white" fillOpacity="0.01" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.6967 12.9766L16.98 16.3183C17.1271 16.4772 17.2065 16.6872 17.2015 16.9038C17.1964 17.1203 17.1072 17.3263 16.9528 17.4782C16.7984 17.6301 16.5909 17.7159 16.3744 17.7175C16.1578 17.7191 15.9491 17.6363 15.7925 17.4866L12.5109 14.1483C11.2447 15.1021 9.66328 15.5392 8.08694 15.371C6.51059 15.2027 5.05708 14.4417 4.02072 13.2421C2.98436 12.0425 2.44259 10.4938 2.50511 8.90976C2.56763 7.32569 3.22978 5.82458 4.35747 4.71036C5.48516 3.59614 6.99412 2.95208 8.57883 2.9086C10.1635 2.86512 11.7055 3.42546 12.8926 4.47617C14.0797 5.52688 14.8232 6.98942 14.9725 8.56768C15.1218 10.1459 14.6657 11.7219 13.6967 12.9766ZM8.75004 13.7391C9.96562 13.7391 11.1314 13.2562 11.9909 12.3967C12.8505 11.5371 13.3334 10.3713 13.3334 9.15577C13.3334 7.94019 12.8505 6.7744 11.9909 5.91486C11.1314 5.05532 9.96562 4.57243 8.75004 4.57243C7.53446 4.57243 6.36868 5.05532 5.50913 5.91486C4.64959 6.7744 4.16671 7.94019 4.16671 9.15577C4.16671 10.3713 4.64959 11.5371 5.50913 12.3967C6.36868 13.2562 7.53446 13.7391 8.75004 13.7391Z"
      fill="white"
    />
  </svg>
);

const HeaderSearch = React.forwardRef(
  ({ value, setValue, placeholder = "Search", isSearching, setIsSearching, disableSearchBar }, ref) => {
    const [inputValue, setInputValue] = useState("");

    useEffect(() => {
      if (value !== inputValue) {
        setInputValue(value);
      }
    }, [value]);

    return (
      <InputWrapper isSearching={isSearching} ref={ref}>
        <Textfield
          value={inputValue}
          placeholder={placeholder}
          onChange={(e) => {
            // const noSpecialChars = e.target.value.replace(/[^a-zA-Z0-9 ]/g, "");
            setInputValue(e.target.value);
          }}
          onKeyUp={(e) => {
            if (inputValue && e.key === "Enter") {
              setIsSearching(true);
              setValue(inputValue);
            } else if (!inputValue) {
              setValue("");
              setIsSearching(false);
            }
          }}
          isDisabled={disableSearchBar}
          elemBeforeInput={<SearchIcon />}
          className="custom-apps-search"
        />
      </InputWrapper>
    );
  },
);

export default HeaderSearch;
