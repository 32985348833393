import { Box, Flex } from "@atlaskit/primitives";
import React from "react";
import Toggle from "@atlaskit/toggle";
import Checkbox from "@atlaskit/checkbox";
import NativeTabs from "../../../../../../../Shared/Components/NativeTabs";
import { SidebarTabs, TabTypes } from "../tabs";
import IconTitle from "../shared/IconTitle";
import { useNavigationStore } from "../../../../../store";
import { PARENT_ENTRY_IDENTIFIER } from "../../../../../defaults";
import CustomInput from "../../../../../../ContentBuilder/Shared/Filter/CustomInput";
import { FieldColumn, FieldContainer, FieldInline, FieldLabel } from "../shared/Components";
import LinkConfig, { Link } from "../shared/LinkConfig";
import ContentVizNews from "../shared/ContentVizNews";
import Design from "./tabs/DesignTab";

function London() {
  const { editingEntry, updateEntry } = useNavigationStore((state) => ({
    editingEntry: state.editingEntry,
    updateEntry: state.updateEntry,
  }));

  const isParent = editingEntry.parent === PARENT_ENTRY_IDENTIFIER;

  const [selectedTab, setSelectedTab] = React.useState(TabTypes.LAYOUT);

  return (
    <Box>
      {isParent && <NativeTabs tabs={SidebarTabs} useNative={false} returnSelection={setSelectedTab} />}

      <Box paddingBlockStart={isParent ? "space.300" : "space.0"}>
        {selectedTab === TabTypes.LAYOUT && (
          <>
            <Flex direction="column">
              <IconTitle />
            </Flex>

            <FieldContainer>
              <FieldLabel>{isParent ? "Entry description" : "Subentry description"}</FieldLabel>
              <CustomInput
                isTextarea
                initialValue={editingEntry?.data?.description ?? ""}
                placeholder={isParent ? "Type entry description here..." : "Type subentry description here..."}
                onChange={(newDescription) => updateEntry(editingEntry.id, "description", newDescription, true)}
              />
            </FieldContainer>

            <FieldContainer>
              {isParent && (
                <>
                  <FieldInline>
                    <FieldLabel>Display icons</FieldLabel>
                    <Toggle
                      id="toggle-controlled-icons"
                      onChange={(e) => updateEntry(editingEntry.id, "displayIcons", e.target.checked, true)}
                      isChecked={editingEntry?.data?.displayIcons}
                    />
                  </FieldInline>

                  <FieldInline>
                    <FieldLabel>Display description and link</FieldLabel>
                    <Toggle
                      id="toggle-controlled-link"
                      onChange={(e) => updateEntry(editingEntry.id, "isLinkOn", e.target.checked, true)}
                      isChecked={editingEntry?.data?.isLinkOn}
                    />
                  </FieldInline>

                  <FieldInline>
                    <FieldLabel>Display news</FieldLabel>
                    <Toggle
                      id="toggle-controlled-news"
                      onChange={(e) => updateEntry(editingEntry.id, "isNewsEnabled", e.target.checked, true)}
                      isChecked={editingEntry?.data?.isNewsEnabled}
                    />
                  </FieldInline>
                </>
              )}
            </FieldContainer>

            {isParent && editingEntry?.data?.isLinkOn && <LinkConfig entry={editingEntry} updateEntry={updateEntry} />}

            {!isParent && (
              <>
                <Link
                  link={editingEntry?.data?.link}
                  onSelected={(selected) => {
                    updateEntry(editingEntry.id, "link", selected, true);
                  }}
                />
                <div>
                  <FieldColumn style={{ marginTop: "5px", columnGap: "3px" }}>
                    <Checkbox
                      id="checkbox-controlled"
                      isChecked={editingEntry?.data?.openNewTab}
                      onChange={(e) => {
                        updateEntry(editingEntry.id, "openNewTab", e.target.checked, true);
                      }}
                    />
                    <FieldLabel style={{ marginBottom: "2px" }}>Open in new tab</FieldLabel>
                  </FieldColumn>
                </div>
              </>
            )}

            {editingEntry?.data?.isNewsEnabled && <ContentVizNews />}
          </>
        )}

        {selectedTab === TabTypes.DESIGN && <Design />}
      </Box>
    </Box>
  );
}

export default London;
