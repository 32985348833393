import React, { useState, useEffect } from "react";
import UserEditor from "../editor/User";
import { FieldValue, UserImage, UserName, UserWrapper, EditorContainer } from "../styled";

function User({ value, field, onEdit, isEditable, getPrefixBasedOnRole }) {
  const [user, setUser] = useState();
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (value) {
      (async () => {
        try {
          const res = await window.AP.request(`/rest/api/user?accountId=${value}`);
          const data = JSON.parse(res.body);

          const userInfo = {
            name: data.displayName,
            icon: `${window.AP._hostOrigin}${data.profilePicture.path}`,
          };

          setUser(userInfo);
        } catch (error) {
          setUser(undefined);
        }
      })();
    } else {
      setUser(undefined);
    }
  }, [value]);

  useEffect(() => {
    if (!value) {
      setUser(undefined);
    }
  }, [user]);

  return (
    <>
      {isEditing && (
        <EditorContainer>
          <UserEditor
            initialValue={value}
            onAbort={() => setIsEditing(false)}
            onEdit={(val) => {
              onEdit(field.id, val);
              setIsEditing(false);
            }}
          />
        </EditorContainer>
      )}

      {user ? (
        <UserWrapper
          isEditing={isEditing}
          isEditable={isEditable}
          onClick={() => {
            if (isEditable) {
              setIsEditing(true);
            }
          }}
        >
          <UserImage src={user?.icon} />
          <UserName>{user.name}</UserName>
        </UserWrapper>
      ) : (
        <FieldValue
          isEditing={isEditing}
          isEditable={isEditable}
          onClick={() => {
            if (isEditable) {
              setIsEditing(true);
            }
          }}
        >
          {getPrefixBasedOnRole()} {field.title}
        </FieldValue>
      )}
    </>
  );
}

export default User;
