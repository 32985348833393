import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";

import CheckIcon from "@atlaskit/icon/glyph/check";
import Textfield from "@atlaskit/textfield";
import Select from "@atlaskit/select";
import Spinner from "@atlaskit/spinner";

import SimpleBar from "./Simplebar";

import { ImageAttribution, ImageThumbnail, ShowMoreButton, MasonryContainer } from "../styled/StyledComponents";
import UnsplashLogo from "../Icons/Unsplash";

import { UnsplashService } from "../RestService";
import { BackgroundDialogContext } from "../Context";
import { colorOptions } from "../UnsplashOptions";
import { IMAGE_TYPES } from "../ImageSourceTypes";
import { nanoid } from "nanoid";
import { Landscape, Portrait, Square } from "../Icons/OrientationIcons";

const ScrollContainer = styled.div`
  .simplebar-content {
    height: initial;
  }
`;

const TextFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  margin-top: -10px;

  & > div:first-child {
    background: ${({ theme }) => theme.sidebar.inputBackground};
    border-radius: 3px;
    border-width: 0px;

    .search-unsplash {
      font-weight: 400 !important;
      font-size: 14px !important;
      line-height: 17px !important;
      color: #172b4d !important;
      height: 40px !important;
    }

    &:focus-within {
      background: ${({ theme }) => theme.sidebar.inputBackground} !important;
    }
  }
`;

const UnsplashFiltersWrapper = styled.div`
  margin: 16px 0px 24px 0px;
  display: flex;
  align-items: center;
  column-gap: 16px;

  & > div:first-child {
    flex: 1;
    font-size: 14px;

    .search-unsplash-color__control {
      background: ${({ theme }) => theme.sidebar.inputBackground};
      border-radius: 3px;
      border-color: ${({ theme }) => theme.sidebar.seperator};
    }

    .search-unsplash-color__menu {
      margin-left: 2px;
    }
  }
`;

const OrientationWrapper = styled.div`
  padding: 12px 13px;
  display: flex;
  align-items: center;
  column-gap: 12px;
  background: ${({ theme }) => theme.sidebar.inputBackground};
  border-radius: 3px;
  height: 40px;
  box-sizing: border-box;
`;

const LineDivider = styled.div`
  height: 100%;
  border-right: 2px solid ${({ theme }) => theme.sidebar.seperator};
`;

const IconContainer = styled.div`
  height: 100%;
  width: auto;
  display: grid;
  place-content: center;
  cursor: pointer;
`;

function Unsplash() {
  const [page, setPage] = useState(1);
  const [columns, setColumns] = useState([
    { images: [], size: 0 },
    { images: [], size: 0 },
  ]);

  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const [search, setSearch] = useState("");
  const [color, setColor] = useState("");
  const [orientation, setOrientation] = useState("landscape");

  const { image, setImage, isMulti } = useContext(BackgroundDialogContext);

  let [col1, col2] = [...columns];

  const addImagesToColumns = (images) => {
    images.forEach((img) => {
      if (col1.size < col2.size) {
        col1 = {
          images: [...col1.images, img],
          size: col1.size + img.height / img.width,
        };
      } else {
        col2 = {
          images: [...col2.images, img],
          size: col2.size + img.height / img.width,
        };
      }
    });

    setColumns([{ ...col1 }, { ...col2 }]);
  };

  useEffect(() => {
    UnsplashService.searchImages("city", page).then(({ data }) => addImagesToColumns(data.results));
  }, []);

  useEffect(() => {
    setPage(1);
    setIsLoading(true);

    UnsplashService.searchImages(search, 1, color, orientation).then(({ data }) => {
      col1 = { images: [], size: 0 };
      col2 = { images: [], size: 0 };

      addImagesToColumns(data.results);
      setHasMore(data.total_pages > 1);
      setIsLoading(false);
    });
  }, [search, color, orientation]);

  const showImage = (img) => (
    <ImageThumbnail
      key={nanoid(16)}
      isSelected={isMulti ? image.find(({ id }) => id === img.id) : image.id === img.id}
      onClick={() => {
        UnsplashService.trackDownload(img.links.download_location);

        if (isMulti) {
          if (image.find(({ id }) => id === img.id)) {
            return setImage([...image.filter(({ id }) => id !== img.id)]);
          }

          return setImage([
            ...image,
            {
              source: IMAGE_TYPES.UNSPLASH,
              link: img.urls.regular,
              id: img.id,
            },
          ]);
        }
        return setImage({
          source: IMAGE_TYPES.UNSPLASH,
          link: img.urls.regular,
          id: img.id,
        });
      }}
    >
      <img src={img.urls.thumb} alt={img.description} />

      <CheckIcon size="small" />

      <ImageAttribution
        href={`${img.user.links.html}?utm_source=Caelor%20-%20Page%20Builder&utm_medium=referral`}
        target="_blank"
      >
        <img src={img.user.profile_image.small} alt={img.user.username} />
        <span>{img.user.username}</span>
      </ImageAttribution>
    </ImageThumbnail>
  );

  return (
    <>
      <TextFieldContainer>
        <Textfield
          className="search-unsplash"
          placeholder="Search images"
          onChange={(e) => {
            const { value } = e.target;
            window.unsplashSearch = value;

            setTimeout(() => {
              if (value === window.unsplashSearch) {
                setSearch(value);
              }
            }, 800);
          }}
        />
      </TextFieldContainer>

      <UnsplashFiltersWrapper>
        <Select
          value={color}
          options={colorOptions}
          onChange={setColor}
          placeholder="Search by color"
          isClearable
          classNamePrefix="search-unsplash-color"
        />

        <OrientationWrapper>
          <IconContainer
            onClick={() => {
              if (orientation === "landscape") {
                setOrientation(undefined);
              } else {
                setOrientation("landscape");
              }
            }}
          >
            <Landscape isActive={orientation === "landscape"} />
          </IconContainer>

          <LineDivider />

          <IconContainer
            onClick={() => {
              if (orientation === "portrait") {
                setOrientation(undefined);
              } else {
                setOrientation("portrait");
              }
            }}
          >
            <Portrait isActive={orientation === "portrait"} />
          </IconContainer>

          <LineDivider />

          <IconContainer
            onClick={() => {
              if (orientation === "squarish") {
                setOrientation(undefined);
              } else {
                setOrientation("squarish");
              }
            }}
          >
            <Square isActive={orientation === "squarish"} />
          </IconContainer>
        </OrientationWrapper>
      </UnsplashFiltersWrapper>

      <ScrollContainer>
        <SimpleBar
          style={{
            height: "calc(100% - 270px)",
            width: "378px",
            paddingRight: "15px",
            position: "absolute",
          }}
        >
          <MasonryContainer>
            <div>{col1.images.map(showImage)}</div>
            <div>{col2.images.map(showImage)}</div>
          </MasonryContainer>

          {!col1.size && !col2.size && !isLoading && (
            <p style={{ textAlign: "center" }}>
              <small>{`No images found for "${search}".`}</small>
            </p>
          )}

          {isLoading && (
            <ShowMoreButton>
              <Spinner size="small" />
            </ShowMoreButton>
          )}

          {hasMore && !isLoading && (
            <ShowMoreButton
              onClick={() => {
                setIsLoading(true);

                UnsplashService.searchImages(search, page + 1, color, orientation).then(({ data }) => {
                  setPage(page + 1);
                  addImagesToColumns(data.results);
                  setHasMore(data.total_pages > page);
                  setIsLoading(false);
                });
              }}
            >
              Show more
            </ShowMoreButton>
          )}
        </SimpleBar>
      </ScrollContainer>

      <a href="https://unsplash.com/" target="_blank" rel="noreferrer">
        <UnsplashLogo />
      </a>
    </>
  );
}

export default Unsplash;
