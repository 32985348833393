import React from "react";
import Spinner from "@atlaskit/spinner";
import { BasicPage, MiniPage, SidePage } from "@caelor/cards-and-panels-components";
import { Grid, MiniGrid, PagesTypes } from "./Pages";
import { SideNewsListContainer } from "./styled";
import { useTheme } from "styled-components";
import { Actions } from "./PagesActions";

export const PagesManual = ({ data, pages, primaryColor, showMoreClicked }) => {
  const theme = useTheme();
  const CompactCardVariant = ({ page }) => (
    <BasicPage
      key={page.data.contentId}
      name={page.data.name}
      spaceName={data.activeElements?.includes("space") ? page.data.space.name : null}
      url={page.data.url}
      authorUrl={page.data.authorUrl}
      spaceUrl={page.data.spaceUrl}
      excerpt={page.data.excerpt}
      linkColor={primaryColor}
      createdBy={data.activeElements?.includes("author") ? page.data.createdBy : null}
      lastModified={data.activeElements?.includes("date") ? page.data.lastModified : null}
      avatarUrl={page.data.avatarUrl}
      emojiCallback={page.data.emojiCallback}
      darkTheme={{
        backgroundColor: theme.shared.card.background,
        titleColor: theme.shared.card.title,
        descriptionColor: theme.shared.card.text,
        borderColor: theme.shared.card.border.box,
        authorColor: primaryColor,
        modifiedColor: theme.shared.card.text,
        boxShadow: theme.manualOrgChart.boxShadow,
      }}
    >
      <Actions page={page.data} />
    </BasicPage>
  );

  const MiniCardVariant = ({ page }) => (
    <MiniPage
      key={page.data.contentId}
      name={page.data.name}
      spaceName={page.data.space.name}
      url={page.data.url}
      authorUrl={page.data.authorUrl}
      spaceUrl={page.data.spaceUrl}
      excerpt={page.data.excerpt}
      createdBy={page.data.createdBy}
      linkColor={primaryColor}
      lastModified={page.data.lastModified}
      avatarUrl={page.data.avatarUrl}
      emojiCallback={page.data.emojiCallback}
      darkTheme={{
        backgroundColor: theme.shared.card.background,
        titleColor: theme.shared.card.title,
        textColor: theme.shared.card.text,
        borderColor: theme.shared.card.border.box,
        authorColor: primaryColor,
        boxShadow: theme.manualOrgChart.boxShadow,
      }}
    >
      <Actions page={page.data} />
    </MiniPage>
  );

  const SideNewsVariant = ({ page }) => {
    return (
      <SidePage
        key={page.data.contentId}
        name={page.data.name}
        spaceName={data.activeElements?.includes("space") ? page.data.space.name : null}
        url={page.data.url}
        authorUrl={page.data.authorUrl}
        spaceUrl={page.data.spaceUrl}
        excerpt={page.data.excerpt}
        linkColor={primaryColor}
        createdBy={data.activeElements?.includes("author") ? page.data.createdBy : null}
        lastModified={data.activeElements?.includes("date") ? page.data.lastModified : null}
        avatarUrl={page.data.avatarUrl}
        emojiCallback={page.data.emojiCallback}
        darkTheme={{
          titleColor: theme.shared.card.title,
        }}
      />
    );
  };

  const LayoutBasedOnVariant = ({ page }) => {
    if (!!data?.isSideNews) {
      return <SideNewsVariant page={page} />;
    }
    return (
      <>
        {data.view === PagesTypes.CARD && <CompactCardVariant page={page} />}
        {data.view === PagesTypes.MINI_CARD && <MiniCardVariant page={page} />}
      </>
    );
  };

  const MappedPages = () => {
    return pages.map((page, index) => {
      if (page.data.loading) {
        return (
          <div key={index} style={{ textAlign: "center" }}>
            <Spinner size={"large"} />
          </div>
        );
      }

      return <LayoutBasedOnVariant key={index} page={page} />;
    });
  };

  if (!!data?.isSideNews) {
    return (
      <SideNewsListContainer>
        <MappedPages />
      </SideNewsListContainer>
    );
  }

  if (data.view === PagesTypes.CARD) {
    return (
      <Grid>
        <MappedPages />
      </Grid>
    );
  }

  if (data.view === PagesTypes.MINI_CARD) {
    return (
      <MiniGrid>
        <MappedPages />
      </MiniGrid>
    );
  }
};
