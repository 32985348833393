export const SIDEBAR_TYPES = {
  CONTENT_SELECTOR: "content_selector",
  PANEL_LIST: "panel_list",
  PANEL_EDIT: "panel_edit",
  ADD_NEW_PANEL: "add_new_panel",
  HEADER_DESIGN: "header_design",
  PERMISSIONS: "permissions",
};

export const SIDEBAR_CONTENTS = {
  HEADER: "header",
  LAYOUT: "layout",
};
