import React, { useState } from "react";
import EditIcon from "@atlaskit/icon/glyph/edit-filled";
import UrlEditor from "../editor/Url";
import { FieldLink, FieldValue, EditorContainer, EditButton } from "../styled";
import { getExternalLink } from "../../../profile.service";

function Url({ value, field, onEdit, isEditable, getPrefixBasedOnRole }) {
  const [isEditing, setIsEditing] = useState(false);

  return (
    <>
      {isEditing && (
        <EditorContainer>
          <UrlEditor
            initialValue={value}
            onEdit={(val) => {
              onEdit(field.id, val);
              setIsEditing(false);
            }}
          />
        </EditorContainer>
      )}

      {value ? (
        <FieldValue isEditing={isEditing} isEditable={isEditable}>
          <FieldLink href={getExternalLink(value)} rel="noreferrer" target="_parent">
            {value}
          </FieldLink>

          <EditButton
            onClick={() => {
              if (isEditable) {
                setIsEditing(true);
              }
            }}
          >
            <EditIcon primaryColor="#344563" size="small" label="Edit" />
          </EditButton>
        </FieldValue>
      ) : (
        <FieldValue
          isEditing={isEditing}
          isEditable={isEditable}
          onClick={() => {
            if (isEditable) {
              setIsEditing(true);
            }
          }}
        >
          {getPrefixBasedOnRole()} {field.title}
        </FieldValue>
      )}
    </>
  );
}

export default Url;
