export const copyToClipboard = (text) => {
  const dummy = document.createElement("input");
  document.body.appendChild(dummy);

  dummy.value = text;
  dummy.select();

  document.execCommand("copy");
  document.body.removeChild(dummy);
};
