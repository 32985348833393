import React from "react";
import { Box, Flex, Text, xcss } from "@atlaskit/primitives";
import EditorRemoveIcon from "@atlaskit/icon/glyph/editor/remove";
import { token } from "@atlaskit/tokens";
import { useHelpersStore } from "../../../store";
import { ModalTypes } from "../../modals/types";

const boxStyle = xcss({
  cursor: "pointer",
  borderRadius: "3px",
  background: token("elevation.surface"),
  transition: "background 0.2s ease-in",

  ":hover": {
    background: token("color.background.accent.red.subtlest.hovered"),
  },

  ":active": {
    background: token("color.background.accent.red.subtler.hovered"),
  },
});

function DeleteNavigation() {
  const { setModalVisible, setModalType } = useHelpersStore((state) => ({
    setModalVisible: state.setModalVisible,
    setModalType: state.setModalType,
  }));

  return (
    <Box
      padding="space.100"
      xcss={boxStyle}
      onClick={() => {
        setModalVisible(true);
        setModalType(ModalTypes.DELETE_NAVIGATION);
      }}
    >
      <Flex alignItems="center" columnGap="space.100">
        <EditorRemoveIcon primaryColor={token("color.text.danger")} />
        <Text color="color.text.danger" weight="medium">
          Delete Navigation
        </Text>
      </Flex>
    </Box>
  );
}

export default DeleteNavigation;
