import React from "react";
import SizeButtons from "../../../../../../../Shared/Components/SizeButtons";
import {
  DesignSidebarWrapper,
  LabeledContainer,
  LabeledTitle,
  LabeledToggle,
  TabContent,
  TextInputWrapper,
  ToggleTitle,
} from "../../../styled/sidebar";
import TextField from "@atlaskit/textfield";
import TextArea from "@atlaskit/textarea";
import Toggle from "@atlaskit/toggle";
import SelectFilter from "../../../../Dashboard/filter/SelectFilter";

import { useQuery } from "react-query";
import { fetchUserDatabaseFields } from "../../../../../../UserDatabase/datagrid/api";
import { PanelTypes } from "../../panelTypes";
import { sizeType } from "../../../../../../../Shared/Components/size-options";
import { UsersPropertyKeys } from "../../../../Dashboard/panels/edit/types/NewUsersEditor";
import { useSidebarStore } from "../../../store/sidebar-store";

const LayoutSidebar = ({ panel, updatePanelDataProperty, fieldsData }) => {
  const setDisableClickOutside = useSidebarStore((state) => state.setDisableClickOutside);

  const formattedTitleOptions =
    fieldsData?.singleValueFields?.map((field) => ({
      label: field.title,
      value: field.id,
    })) || [];

  return (
    <div>
      <TabContent>
        <LabeledContainer>
          <LabeledTitle>Title</LabeledTitle>

          <TextInputWrapper>
            <TextField
              value={panel.data.title}
              placeholder="Enter title.."
              type="text"
              onChange={({ target: { value } }) => updatePanelDataProperty({ id: panel.id, property: "title", value })}
            />
          </TextInputWrapper>
        </LabeledContainer>

        <LabeledContainer>
          <LabeledTitle>Description</LabeledTitle>
          <TextArea
            resize="none"
            minimumRows={5}
            placeholder="Enter description.."
            value={panel.data.description}
            onChange={({ target: { value } }) =>
              updatePanelDataProperty({ id: panel.id, property: "description", value })
            }
          />
        </LabeledContainer>

        <LabeledContainer
          onMouseEnter={() => setDisableClickOutside(true)}
          onMouseLeave={() => setDisableClickOutside(false)}
        >
          <LabeledTitle>Fields to display</LabeledTitle>

          <SelectFilter
            options={formattedTitleOptions}
            value={formattedTitleOptions.find((field) => field.value === panel.data?.titleFieldId)}
            setValue={(newValue) =>
              updatePanelDataProperty({ id: panel.id, property: UsersPropertyKeys.TITLE_FIELD_ID, value: newValue })
            }
            placeholder="Job Title (Position)"
            menuPlacement="bottom"
            isClearable
          />
        </LabeledContainer>

        <LabeledContainer>
          <LabeledTitle>Display events</LabeledTitle>
          {panel.data.events.map((event, idx) => (
            <LabeledToggle key={idx}>
              <ToggleTitle>{event.label}</ToggleTitle>
              <Toggle
                isChecked={event.value}
                value={event.value}
                onChange={() =>
                  updatePanelDataProperty({
                    id: panel.id,
                    property: "events",
                    nestedProperty: "value",
                    label: event.label,
                    value: !event.value,
                  })
                }
              />
            </LabeledToggle>
          ))}
        </LabeledContainer>
      </TabContent>
    </div>
  );
};

const DesignSidebar = ({ panel, updatePanelDataProperty }) => {
  return (
    <DesignSidebarWrapper>
      <TabContent>
        <SizeButtons
          customStyles={{ marginTop: 0 }}
          label="Profile image size"
          types={{ panel: PanelTypes.TEAM, size: sizeType.PROFILE_IMAGE_SIZE }}
          input={{ min: 0, max: 100, step: 2, value: panel.data.profileImageSize }}
          setValue={(value) => updatePanelDataProperty({ id: panel.id, property: sizeType.PROFILE_IMAGE_SIZE, value })}
        />
      </TabContent>
    </DesignSidebarWrapper>
  );
};

const sidebars = {
  Layout: LayoutSidebar,
  Design: DesignSidebar,
};

const KnowYourTeamEdit = ({ panel, selectedTab, updatePanelDataProperty, setProperty }) => {
  const Component = sidebars[selectedTab];

  const { isLoading: fieldsLoading, data: fieldsData } = useQuery(
    ["userdatabase-fields", { includeEmail: false }],
    fetchUserDatabaseFields,
    {
      retry: 0,
      select: (response) => {
        const { data } = response;

        const dateFields = data?.filter((field) => field.type === "DATE") || [];
        const singleValueFields = data?.filter((field) => field.type === "SELECT" || field.type === "TEXT");

        return { dateFields, singleValueFields };
      },
    },
  );

  return (
    <Component
      panel={panel}
      updatePanelDataProperty={updatePanelDataProperty}
      setProperty={setProperty}
      fieldsData={fieldsData}
    />
  );
};

export default KnowYourTeamEdit;
