import React, { useMemo } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useChangesStore } from "../../../../../../track-changes-store";
import { usePanelsStore } from "../../../../store/panels-store";
import { useSidebarStore } from "../sidebar-store";
import { AddNewContainer, AddNewSectionWrapper, BlueText, DividerBorder, IconContainer, Wrapper } from "../styled";
import { ReorderablePanel, RegularPanel } from "./Panels";
import { PanelPositioning, PanelTypes } from "../../../panelTypes";
import { SIDEBAR_TYPES } from "../types";
import { AddIcon, SmallRightIcon } from "./Icons";

function PanelsList() {
  const { selectedContentType, setSidebarType } = useSidebarStore((state) => ({
    selectedContentType: state.selectedContentType,
    setSidebarType: state.setSidebarType,
  }));
  const { panels, setPanels, setEditingPanel } = usePanelsStore((state) => ({
    panels: state.panels,
    setPanels: state.setPanels,
    setEditingPanel: state.setEditingPanel,
  }));

  const setHasChanges = useChangesStore((state) => state.setHasChanges);

  const availablePanels = useMemo(
    () => panels.filter((panel) => panel.position === selectedContentType),
    [selectedContentType, panels]
  );

  const updateContentState = () => {
    setHasChanges(true);
  };

  const resetOrder = (list) => {
    const resetList = list.map((c, index) => {
      const slide = c;
      slide.order = index + 1;
      return slide;
    });
    return resetList;
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return resetOrder(result);
  };

  const reorderPanels = (sourceId, targetId) => {
    if (sourceId === targetId) {
      return;
    }

    const sourceIndex = availablePanels.findIndex((p) => p.id === sourceId);
    const targetIndex = availablePanels.findIndex((p) => p.id === targetId);

    const items = reorder(availablePanels, sourceIndex, targetIndex);

    const otherPanels = panels.filter((panel) => panel.position !== selectedContentType);
    setPanels([...otherPanels, ...items]);

    updateContentState();
  };

  const handleDeletePanel = (panelId) => {
    const newPanels = panels.filter((p) => p.id !== panelId);
    setPanels([...newPanels]);
    updateContentState();
  };

  return (
    <Wrapper style={{ paddingTop: "16px" }}>
      <div>
        {selectedContentType === PanelPositioning.MAIN && (
          <RegularPanel
            panel={panels.find((panel) => panel.type === PanelTypes.USER_START)}
            handleClickOnEdit={(panel) => {
              setEditingPanel(panel);
              setSidebarType(SIDEBAR_TYPES.PANEL_EDIT);
            }}
          />
        )}

        <DndProvider backend={HTML5Backend}>
          {availablePanels
            .filter((panel) => panel.type !== PanelTypes.USER_START)
            .map((panel) => (
              <ReorderablePanel
                key={panel.id}
                panel={panel}
                reorderPanels={reorderPanels}
                handleClickOnEdit={(panel) => {
                  setEditingPanel(panel);
                  setSidebarType(SIDEBAR_TYPES.PANEL_EDIT);
                }}
                handleClickOnDelete={(panelId) => handleDeletePanel(panelId)}
              />
            ))}
        </DndProvider>
      </div>

      <AddNewSectionWrapper>
        <DividerBorder />

        <AddNewContainer onClick={() => setSidebarType(SIDEBAR_TYPES.ADD_NEW_PANEL)}>
          <IconContainer>
            <AddIcon />
          </IconContainer>
          <BlueText>Add new</BlueText>
          <IconContainer>
            <SmallRightIcon />
          </IconContainer>
        </AddNewContainer>
      </AddNewSectionWrapper>
    </Wrapper>
  );
}

export default PanelsList;
