import styled, { useTheme } from "styled-components";
import Button from "@atlaskit/button";
import AddIcon from "@atlaskit/icon/glyph/editor/add";
import * as ReactDOM from "react-dom";
import { useEffect, useState } from "react";
import Popup from "@atlaskit/popup";
import CreateField from "../datagrid/header/fields/CreateField";
import shallow from "zustand/shallow";
import { useRecordsStore } from "../records-store";
import { useDatabaseUIStore } from "../database-ui-store";

const Wrapper = styled.div`
  display: flex;
  padding-right: 16px;
`;

export const AddColumn = () => {
  const [isOpen, setIsOpen] = useState(false);
  const theme = useTheme();

  const { insertField, fields } = useRecordsStore((state) => {
    return { insertField: state.insertField, fields: state.fields };
  }, shallow);

  const { addColumn } = useDatabaseUIStore((state) => {
    return { addColumn: state.addColumn };
  }, shallow);

  const firstFilteredField = fields[fields.length - 1];

  const fieldId = firstFilteredField?.id;
  const position = firstFilteredField?.ui.position + 1;

  const handleShowPopup = () => {
    setIsOpen((isOpen) => !isOpen);

    const lastElement = document.getElementById("last-header");
    if (lastElement) {
      lastElement.scrollIntoView();
    }
  };

  useEffect(() => {
    const popupElement = document.querySelector(`#portal-column-${fieldId}`);

    if (popupElement) {
      popupElement.style.border = `1px solid ${theme.shared.settings.database.menuBorder}`;
    }
  }, [fieldId]);

  return (
    <Popup
      autoFocus={false}
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      placement="bottom"
      content={() => {
        return ReactDOM.createPortal(
          <div
            style={{ background: theme.shared.settings.database.background, padding: "1px 22px", width: "inherit" }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <CreateField
              focus={true}
              position={position}
              onCancel={() => {
                setIsOpen(false);
              }}
              onSave={(field) => {
                insertField(field);
                addColumn({ ...field.ui, id: field.id, type: field.type });
                setIsOpen(false);
              }}
              labelName="Column"
            />
          </div>,
          document.querySelector(`#portal-column-${fieldId}`),
        );
      }}
      trigger={(triggerProps) => (
        <Wrapper {...triggerProps}>
          <Button
            appearance="primary"
            iconBefore={<AddIcon />}
            style={{ fontSize: "13px", textAlign: "left", lineHeight: "29px" }}
            onClick={handleShowPopup}
          >
            Add column
          </Button>
        </Wrapper>
      )}
    />
  );
};
