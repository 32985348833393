import React from "react";
import ImageIcon from "@atlaskit/icon/glyph/image";
import { useNavigationStore, useSidebarStore } from "../../../../../store";
import { Box, Flex, Text } from "@atlaskit/primitives";
import { EmptyIcon, IconBox, ImageButton } from "./styled";
import { PARENT_ENTRY_IDENTIFIER } from "../../../../../defaults";
import { MenuStyleTypes } from "../../../../../menu/types";
import EntryIcon from "./EntryIcon";
import CustomInput from "../../../../../../ContentBuilder/Shared/Filter/CustomInput";
import { HelperDrawerTypes } from "../../../helper_drawers/types";

function IconTitle() {
  const { editingEntry, findParentEntry, updateEntry } = useNavigationStore((state) => ({
    editingEntry: state.editingEntry,
    findParentEntry: state.findParentEntry,
    updateEntry: state.updateEntry,
  }));

  const { setIsHelperDrawerOpen, setSelectedHelperDrawerType } = useSidebarStore((state) => ({
    setIsHelperDrawerOpen: state.setIsHelperDrawerOpen,
    setSelectedHelperDrawerType: state.setSelectedHelperDrawerType,
  }));

  const { parent, data } = editingEntry;
  const { icon } = data || {};

  const entryParent = parent !== PARENT_ENTRY_IDENTIFIER ? findParentEntry(parent) : undefined;

  const getProp = (prop) => {
    if (editingEntry[prop]) return editingEntry[prop];
    return entryParent?.[prop];
  };

  const openHelperDrawer = (type) => {
    setSelectedHelperDrawerType(type);
    setIsHelperDrawerOpen(true);
  };

  return (
    <Flex alignItems="center" columnGap="space.150">
      {editingEntry?.data?.displayIcons !== false && entryParent?.data?.type !== "text" && (
        <Box>
          <Text color="color.text" weight="medium" size="small">
            {entryParent?.data?.type === "image" ? "Image" : "Icon"}
          </Text>

          {icon?.provider !== "none" ? (
            <IconBox>
              {parent !== PARENT_ENTRY_IDENTIFIER && entryParent?.menuStyle === MenuStyleTypes.PALO_ALTO && (
                <img
                  width={24}
                  height={24}
                  alt=""
                  src={
                    typeof icon === "string" ? icon : "https://caelor-apps.web.app/images/icons/Caelor_Black_Symbol.png"
                  }
                  style={{ objectFit: "contain" }}
                  onClick={() => openHelperDrawer(HelperDrawerTypes.APPS)}
                />
              )}

              {entryParent?.data?.type === "image" && (
                <ImageButton onClick={() => openHelperDrawer(HelperDrawerTypes.BACKGROUND)}>
                  <ImageIcon />
                </ImageButton>
              )}

              {icon.type === "app" && (
                <div
                  onClick={() => openHelperDrawer(HelperDrawerTypes.ICONS)}
                  style={{ width: "24px", height: "24px" }}
                >
                  <img src={icon?.icon} alt="" width={24} height={24} style={{ objectFit: "contain" }} />
                </div>
              )}

              {icon?.type === "emoji" && entryParent?.data?.type !== "image" && (
                <div onClick={() => openHelperDrawer(HelperDrawerTypes.ICONS)}>
                  <em-emoji shortcodes={icon?.shortcodes} size="1.2em"></em-emoji>
                </div>
              )}

              {(editingEntry.data.icon.type === "icon" || !editingEntry.data.icon.type) &&
                ((getProp("menuStyle") === MenuStyleTypes.DENVER &&
                  (entryParent?.data?.type === "icon" || editingEntry.parent === "0") &&
                  editingEntry.data.icon.type !== "emoji") ||
                  ((getProp("menuStyle") !== MenuStyleTypes.PALO_ALTO ||
                    (editingEntry.parent === "0" && getProp("menuStyle") === MenuStyleTypes.PALO_ALTO)) &&
                    getProp("menuStyle") !== MenuStyleTypes.DENVER)) && (
                  <EntryIcon
                    style={{
                      width: "24px",
                      height: "24px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "#42526E",
                    }}
                    icon={editingEntry?.data?.icon}
                    onClick={() => openHelperDrawer(HelperDrawerTypes.ICONS)}
                    onRandomIconSelected={(selected) => {
                      updateEntry(editingEntry.id, "icon", selected, true);
                    }}
                  />
                )}
            </IconBox>
          ) : (
            <EmptyIcon onClick={() => openHelperDrawer(HelperDrawerTypes.ICONS)}>
              <div />
            </EmptyIcon>
          )}
        </Box>
      )}

      <Box style={{ flex: 1 }}>
        <Text color="color.text" weight="medium" size="small">
          {!!entryParent ? "Subentry title" : "Entry title"}
        </Text>

        <CustomInput
          initialValue={editingEntry.data?.name ?? ""}
          placeholder={!!entryParent ? "Type subentry title here..." : "Type entry title here..."}
          onChange={(newName) => {
            updateEntry(editingEntry.id, "name", newName, true);
          }}
        />
      </Box>
    </Flex>
  );
}

export default IconTitle;
