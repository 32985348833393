import { useMemo } from "react";
import { handleClickOnChildEntry } from "../cardLayoutService";
import { ChildEntry, ChildMenuWrapper, MenuContentPositioner, MenuContentWrapper } from "./styled";

import { useTheme } from "styled-components";
import { dark } from "../../../../../dark-colors";
import { light } from "../../../../../light-colors";
import {
  DEFAULT_PALO_ALTO_COLORS,
  DEFAULT_PALO_ALTO_COLORS_DARK,
  THEME_NAME_DARK,
  THEME_NAME_LIGHT,
} from "../../../functions/generateNewEntry";

const themes = {
  dark,
  light,
};

function PaloAlto({ structure, selectedRootEntry, isPreview, openOverContent, themeColorsSet, temporaryColorScheme }) {
  const rootEntry = structure.find(({ id }) => id === selectedRootEntry);

  const theme = useTheme();

  const colors = useMemo(() => {
    if (!rootEntry) return {};
    if (themeColorsSet && theme.global.name === THEME_NAME_LIGHT) {
      return DEFAULT_PALO_ALTO_COLORS;
    } else if (themeColorsSet && theme.global.name === THEME_NAME_DARK) {
      return DEFAULT_PALO_ALTO_COLORS_DARK;
    } else {
      return rootEntry?.data?.colors;
    }
  }, [theme, rootEntry, structure, themeColorsSet]);

  return (
    <MenuContentWrapper
      backgroundColor={themes[temporaryColorScheme]?.navigation.paloAlto.menuContentBackground}
      openOverContent={openOverContent}
    >
      <MenuContentPositioner>
        <ChildMenuWrapper
          isPreview={isPreview}
          numberOfColumns={structure.filter((entry) => entry.parent === selectedRootEntry)?.length}
        >
          {structure
            .filter((entry) => entry.parent === selectedRootEntry)
            .map((entry) => (
              <ChildEntry
                key={entry.id}
                subentryIconColor={colors?.[temporaryColorScheme]?.subentryIconColor || "#42526e"}
                subentryTextColor={
                  colors?.[temporaryColorScheme]?.subentryTextColor === undefined ||
                  colors?.[temporaryColorScheme]?.subentryTextColor === "#42526e"
                    ? themes[temporaryColorScheme]?.navigation.london.menuContentTitle
                    : colors?.[temporaryColorScheme]?.subentryTextColor
                }
                subentryBackgroundHoverColor={
                  colors?.[temporaryColorScheme]?.subentryBackgroundHoverColor === undefined ||
                  colors?.[temporaryColorScheme]?.subentryBackgroundHoverColor === "0000000"
                    ? themes[temporaryColorScheme]?.navigation.paloAlto.childEntryBackgroundHover
                    : colors?.[temporaryColorScheme]?.subentryBackgroundHoverColor
                }
                subentryTextIconHover={colors?.[temporaryColorScheme]?.subentryTextIconHover}
                backgroundColor={{
                  inactive: themes[temporaryColorScheme]?.navigation.paloAlto.childEntryBackground,
                  hover: themes[temporaryColorScheme]?.navigation.paloAlto.childEntryBackgroundHover,
                }}
                borderColor={{
                  inactive: themes[temporaryColorScheme]?.navigation.paloAlto.childEntryBorder,
                  hover: themes[temporaryColorScheme]?.navigation.paloAlto.childEntryBackgroundHoverBorder,
                }}
                onClick={() => handleClickOnChildEntry(entry, isPreview)}
              >
                <div className="child-icon-bg-container">
                  <img
                    width="40"
                    height="40"
                    alt=""
                    src={
                      typeof entry.data.icon === "string"
                        ? entry.data.icon
                        : "https://caelor-apps.web.app/images/icons/Caelor_Black_Symbol.png"
                    }
                    style={{ objectFit: "contain" }}
                  />
                </div>

                <div>
                  <div className="child-name">{entry.data.name}</div>
                </div>
              </ChildEntry>
            ))}
        </ChildMenuWrapper>
      </MenuContentPositioner>
    </MenuContentWrapper>
  );
}

export default PaloAlto;
