import React from "react";
import ArrowRightIcon from "@atlaskit/icon/glyph/arrow-right";
import styled from "styled-components";
import { InlineContainer, MediumLine } from "./styled";

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  column-gap: 14px;
`;

const CardWrapper = styled.div`
  border-radius: 4px;
  border: 0.5px solid #d8dce3;
  background: #fff;
  box-shadow: 0px 0.5473684072494507px 3.284210443496704px 0px rgba(68, 68, 68, 0.12);
`;

const CardWrapperTop = styled.div`
  background: ${({ bgClr }) => bgClr};
  border-radius: 3px 3px 0px 0px;
  height: 38px;
  width: 28%;
  object-fit: cover;
  position: absolute;
`;

const CardContent = styled.div`
  padding: 20px 16px;
  display: flex;
  flex-direction: column;
`;

const IconContainer = styled.div`
  display: grid;
  place-content: center;
  width: 36px;
  height: 36px;
  border-radius: 3px;
`;

function ImportantSpacesMain() {
  return (
    <Grid>
      <CardWrapper>
        <CardWrapperTop bgClr={"linear-gradient(270deg, #de350b 0%, #ffbdad 98.75%)"} />
        <CardContent>
          <IconContainer>
            <img style={{ zIndex: 10 }} src="/images/hub/dashboard/spaces/notes.png" alt="" />
          </IconContainer>

          <MediumLine width={72} bg="#E4E4E4" style={{ marginTop: "15px" }} />
          <MediumLine width={64} bg="#E4E4E4" style={{ marginTop: "8px" }} />

          <InlineContainer style={{ marginTop: "21px" }}>
            <MediumLine width={48} bg="#B3BAC5" />
            <MediumLine width={24} bg="#F4F5F7" />
          </InlineContainer>
        </CardContent>
      </CardWrapper>

      <CardWrapper>
        <CardWrapperTop bgClr={"linear-gradient(270deg, #00A3BF 0%, #B3F5FF 98.75%)"} />
        <CardContent>
          <IconContainer>
            <img style={{ zIndex: 10 }} src="/images/hub/dashboard/spaces/flag.png" alt="" />
          </IconContainer>

          <MediumLine width={72} bg="#E4E4E4" style={{ marginTop: "15px" }} />
          <MediumLine width={64} bg="#E4E4E4" style={{ marginTop: "8px" }} />

          <InlineContainer style={{ marginTop: "21px" }}>
            <MediumLine width={48} bg="#B3BAC5" />
            <MediumLine width={24} bg="#F4F5F7" />
          </InlineContainer>
        </CardContent>
      </CardWrapper>

      <CardWrapper>
        <CardWrapperTop bgClr={"linear-gradient(270deg, #2571CB 0%, #84B9F8 98.75%)"} />
        <CardContent>
          <IconContainer>
            <img style={{ zIndex: 10 }} src="/images/hub/dashboard/spaces/alien.png" alt="" />
          </IconContainer>

          <MediumLine width={72} bg="#E4E4E4" style={{ marginTop: "15px" }} />
          <MediumLine width={64} bg="#E4E4E4" style={{ marginTop: "8px" }} />

          <InlineContainer style={{ marginTop: "21px" }}>
            <MediumLine width={48} bg="#B3BAC5" />
            <MediumLine width={24} bg="#F4F5F7" />
          </InlineContainer>
        </CardContent>
      </CardWrapper>
    </Grid>
  );
}

export default ImportantSpacesMain;
