import React from "react";
import Toggle from "@atlaskit/toggle";
import { Center, Column, ContentColumns, Panel, PanelDescription, PanelTitle } from "./styled";
import { useTheme } from "styled-components";

function WidgetActivator({ panelTitle, isActive, setIsActive, isDisabled }) {
  const isActived = isDisabled ? false : isActive;
  const theme = useTheme();

  return (
    <Panel>
      <ContentColumns style={{ alignItems: "center" }}>
        <Column style={{ rowGap: "0px" }}>
          <PanelTitle style={{ paddingBottom: "5px", border: "none" }}>{panelTitle}</PanelTitle>
          <PanelDescription style={{ fontSize: "12px" }}>{isActived ? "" : "Not "}Activated</PanelDescription>
        </Column>

        <Column style={{ display: "flex", alignItems: "flex-end" }}>
          <Toggle
            isDisabled={isDisabled}
            isChecked={isActived}
            onChange={({ target: { checked } }) => setIsActive(checked)}
          />
        </Column>
      </ContentColumns>

      {isDisabled && (
        <Center style={{ padding: "50px 100px", marginTop: "12px", borderTop: "1px solid rgba(9, 30, 66, 0.14)" }}>
          <img src={`/images/hub/pulse-no-license-${theme.global.name}.svg`} alt="" style={{ margin: "0 auto" }} />

          <PanelDescription>
            A valid Pulse license is required to enable Org Charts. Please ensure that Pulse is appropriately licensed
            for activation.
          </PanelDescription>
        </Center>
      )}
    </Panel>
  );
}

export default WidgetActivator;
