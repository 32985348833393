import { token } from "@atlaskit/tokens";
import styled from "styled-components";

export const MenuContentWrapper = styled.div`
  padding: 30px 32px;
  box-sizing: border-box;
  background-color: ${({ backgroundColor }) => backgroundColor};
  display: flex;
  align-items: flex-start;
  justify-content: center;
  column-gap: 24px;
  box-shadow: 0 4px 6px -6px rgba(0 0 0 / 25%);
  ${({ openOverContent }) =>
    openOverContent &&
    `position: fixed;
    z-index: 300;
    width: 100%;
    `}
`;

export const ChildMenuWrapper = styled.div`
  display: grid;
  max-height: 450px;
  overflow-y: auto;
  grid-gap: 24px;
  grid-template-columns: ${({ numberOfColumns }) =>
    `repeat(${numberOfColumns > 0 && numberOfColumns < 3 ? numberOfColumns : 3}, minmax(150px, 350px))`};
  grid-auto-rows: 1fr;
  background: transparent;

  padding: 25px;
  margin: -25px;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.scrollbar};
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #999;
  }

  @media (max-width: ${({ isPreview }) => (isPreview ? 1600 : 1200)}px) {
    grid-template-columns: ${({ numberOfColumns }) =>
      `repeat(${numberOfColumns > 0 && numberOfColumns < 2 ? numberOfColumns : 2}, minmax(150px, 350px))`};
  }
`;

export const MenuDescriptionWrapper = styled.div`
  min-width: 200px;
  max-width: 270px;
  padding-top: 20px;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  row-gap: ${token("space.100")};

  .menu-description-title {
    color: ${({ descriptionTextColor }) => descriptionTextColor};
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
  }

  .description {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    white-space: pre-line;
    color: ${({ descriptionTextColor }) => {
      if (descriptionTextColor.length > 7) {
        return `${descriptionTextColor.slice(0, -2)}ba`;
      }
      return `${descriptionTextColor}ba`;
    }};
    margin-bottom: 0;
  }

  .menu-description-border {
    width: calc(100% - 80px);
    border-bottom: 1px solid ${({ theme }) => theme.navigation.london.menuContentSeperator};
  }

  .menu-description-link {
    cursor: pointer;
    color: ${({ theme }) => theme.global.primaryColor};
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    column-gap: 12px;
    margin-top: 8px;
  }
`;

export const ChildEntry = styled.div`
  position: relative;
  z-index: 10;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background-color: transparent;
  border-radius: 8px;
  border: 1px solid ${token("color.border")};

  padding: 20px;

  transition:
    background-color 75ms ease-in-out,
    box-shadow 75ms ease-in-out;

  &:hover {
    box-shadow:
      0px 0px 1px 0px rgba(9, 30, 66, 0.31),
      0px 8px 12px 0px rgba(9, 30, 66, 0.15);
  }

  .icon-container > svg {
    color: ${({ subentryIconColor }) => subentryIconColor};
    transition: color 75ms ease-in-out;
  }

  .child-name {
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    color: ${({ subentryTextColor }) => subentryTextColor} !important;
    transition: color 75ms ease-in-out;
    margin-top: ${token("space.100")};
  }

  .child-description {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: ${({ subentryTextColor }) => {
      if (subentryTextColor.length > 7) {
        return `${subentryTextColor.slice(0, -2)}ba`;
      }
      return `${subentryTextColor}ba`;
    }};
    margin-top: ${token("space.050")};
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &:hover {
    background-color: ${({ subentryBackgroundHoverColor, backgroundColor: { hover } }) => {
      if (subentryBackgroundHoverColor === "#00000000") return hover;
      return subentryBackgroundHoverColor;
    }};

    ${({ subentryTextIconHover }) => {
      if (subentryTextIconHover) {
        return `
          .child-name {
            color: ${subentryTextIconHover};
          }

          .icon-container > svg {
            color: ${subentryTextIconHover};
          }
        `;
      }
    }}
  }
`;
