import React from "react";
import SectionMessage from "@atlaskit/section-message";
import styled from "styled-components";
import { useNewsMetadataStore } from "../news-metadata-store";
import { FieldContainer } from "./shared/styled";
import Tag from "@atlaskit/tag";
import { useCurrentUserStore } from "../../Components/Hub/current-user-store";
import CollectionsSelect from "../../Components/Hub/Components/Shared/CollectionsSelect";

const Wrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  height: 485px;
  overflow-y: auto;
  padding: 20px 25px 0px 25px;
  gap: 10px;
`;

const CategoryDescription = styled.div`
  color: ${({ theme }) => `${theme.newsMetadataDialog.categoryText}`};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 150% */
`;

const AddedCategories = styled.div`
  color: ${({ theme }) => `${theme.newsMetadataDialog.categoryText}`};
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
`;

const CategoryTagWrapper = styled.div`
  width: 443px;
  display: flex;
  flex-flow: row;
  align-items: center;
  align-content: center;
  gap: 20px 15px;
  flex-wrap: wrap;
`;

function Personalization() {
  const { selectedCollections, setSelectedCollections, savedCollections, setCollectionChanges, userIsEditor } =
    useNewsMetadataStore((state) => ({
      selectedCollections: state.selectedCollections,
      setSelectedCollections: state.setSelectedCollections,
      savedCollections: state.savedCollections,
      setCollectionChanges: state.setCollectionChanges,
      userIsEditor: state.userIsEditor,
    }));

  const { isCurrentUserAdmin } = useCurrentUserStore((state) => ({
    isCurrentUserAdmin: state.isCurrentUserAdmin,
  }));

  const handleUpdateInCollections = (selection) => {
    const collectionsToRemove = [];

    for (const collectionId of savedCollections) {
      const isSavedCollectionInSelection = selection.find((clcId) => clcId === collectionId);
      if (!isSavedCollectionInSelection) {
        collectionsToRemove.push(collectionId);
      }
    }

    setCollectionChanges({ add: selection, remove: collectionsToRemove });
    setSelectedCollections([...selection]);
  };

  return (
    <Wrapper>
      {userIsEditor || isCurrentUserAdmin ? (
        <FieldContainer style={{ marginTop: 0 }}>
          <CategoryDescription>
            By selecting relevant Cosmos collections, you ensure a personalized news experience across all news widgets,
            enhancing content relevance and engagement.
          </CategoryDescription>

          <CollectionsSelect
            label="Select collections"
            initialCollections={selectedCollections}
            onChange={(value) => handleUpdateInCollections(value)}
            isMulti
            displayUsers
          />
        </FieldContainer>
      ) : (
        <>
          <SectionMessage title="Editing permission required">
            Editing is limited to users who have page editing permissions and Cosmos administrators.
          </SectionMessage>
          <CategoryDescription>
            By selecting relevant Cosmos collections, you ensure a personalized news experience across all news widgets,
            enhancing content relevance and engagement.
          </CategoryDescription>
          {!selectedCollections && <AddedCategories>Targeted Collections</AddedCategories>}
          <CategoryTagWrapper>
            {selectedCollections.map((option) => (
              <Tag text={option.label} isRemovable={false} />
            ))}
          </CategoryTagWrapper>
        </>
      )}
    </Wrapper>
  );
}

export default Personalization;
