import React, { useEffect, useState } from "react";
import { AsyncSelect } from "@atlaskit/select";
import Form from "@atlaskit/form";

import { atlassianRestService } from "../../../../Service/AtlassianRestService";

import { useRecordsStore } from "../../records-store";
import shallow from "zustand/shallow";
import { SelectionType } from "../../menubar/Users";
import { USER_SELECT } from "./selectStyles";

function mapUser(user) {
  return {
    avatarUrl: atlassianRestService.getProfilePictureUrl(user.profilePicture.path),
    id: user.accountId,
    name: user.publicName,
    type: "user",
    label: user.publicName,
    value: user.accountId,
  };
}

const UserEditor = ({ initialValue, onEdit, onAbort }) => {
  const [selectedUser, setSelectedUser] = useState([]);
  const [options, setOptions] = useState([]);
  const [menuOpen, setMenuOpen] = useState(true);

  const { hiddenUsersSelection, records } = useRecordsStore((state) => {
    return {
      hiddenUsersSelection: state.hiddenUsersSelection,
      records: state.records,
    };
  }, shallow);

  useEffect(() => {
    const usersFromRecords = records.filter((record) =>
      hiddenUsersSelection.selectionType === SelectionType.SHOW_ALL_USERS
        ? true
        : hiddenUsersSelection.selectionType === SelectionType.SHOW_HIDDEN_USERS
        ? record.values.hidden
        : !record.values.hidden,
    );

    Promise.all(
      usersFromRecords.map(async (user) => {
        const userAtlassian = await atlassianRestService.getUserByAccountId(user.accountId);
        return userAtlassian;
      }),
    ).then((usersFromAtlassian) => {
      const options = usersFromAtlassian.map(mapUser);
      setOptions(options);
    });
  }, [initialValue]);

  useEffect(() => {
    (async () => {
      const result = await atlassianRestService.getUserByAccountId(initialValue);
      if (result) {
        setSelectedUser(mapUser(result));
      }
    })();
  }, [initialValue]);

  const loadOptions = async (query) => {
    try {
      const users = await atlassianRestService.findUsersByName(query);
      const { results } = users;
      const atlassianUsers = results
        .filter(({ user }) => user.accountType === "atlassian")
        .map(({ user }) => mapUser(user));
      return atlassianUsers;
    } catch (error) {
      return [];
    }
  };

  const handleOnChange = (user) => {
    setSelectedUser(user);
    if (user) {
      setMenuOpen(false);
      onEdit(user.id);
      onAbort();
      return;
    }
    onEdit(undefined);
    onAbort();
  };

  return (
    <div style={{ display: "flex", alignItems: "center", textOverflow: "ellipsis", width: "100%" }}>
      <Form name="userpicker" onClick={() => setMenuOpen((prev) => !prev)}>
        {() => (
          <AsyncSelect
            menuShouldScrollIntoView={false}
            placeholder="Search user..."
            autoFocus
            isClearable
            menuIsOpen={menuOpen}
            backspaceRemovesValue
            onBlur={onAbort}
            onChange={handleOnChange}
            value={selectedUser}
            defaultOptions
            loadOptions={loadOptions}
            styles={USER_SELECT}
          />
        )}
      </Form>
    </div>
  );
};

export default UserEditor;
