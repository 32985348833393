import React, { useEffect, useState } from "react";
import styled, { useTheme } from "styled-components";
import { usePanelsStore } from "../../../../../Dashboard/store/panels-store";
import PanelWrapper from "../../../shared/components/PanelWrapper";
import NoDatabaseSync from "../../../shared/components/NoDatabaseSync";
import EmptyState from "../../../../../../../../Shared/Components/EmptyState";
import NoPeopleSVG from "../../../../../Dashboard/panels/images/NoPeopleSVG";
import Spinner from "@atlaskit/spinner";
import {
  CardBackground,
  CardInfoContainer,
  CardLinkWrapper,
  LinkWrapper,
  Name,
} from "../../../../../Dashboard/panels/styles/peopleStyles";
import { createProfileLink } from "../../../../../PeopleBase/Views/createProfileLink";
import Pagination from "../../../../PanelsPagination/Pagination";
import { SpinnerContainer } from "../../../../../../styled/pages";
import { PanelsPaginationTypes } from "../../../../PanelsPagination/panels-pagination-types";
import { usePageStore } from "../../../../../../page-store";
import { useSidebarStore } from "../../../../../Dashboard/panels/edit/sidebar/sidebar-store";
import { Pages } from "../../../../../../pages";
import { useChangesStore } from "../../../../../../track-changes-store";

const UserGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-gap: 24px;
`;

const UserCardWrapper = styled.div`
  background: ${({ theme }) => theme.shared.card.background};
  box-shadow: 0px 0px 4px rgba(85, 85, 85, 0.06);
  border-radius: ${({ borderRadius }) => `${borderRadius}`};
  overflow: hidden;
  border: 1px solid ${({ theme }) => theme.shared.card.border.box};
  cursor: pointer;
`;

const UserImage = styled.img`
  width: ${({ imageSize }) => `${imageSize || 80}px`};
  height: ${({ imageSize }) => `${imageSize || 80}px`};
  box-sizing: border-box;
  border: 4px solid ${({ theme }) => theme.shared.card.border.avatar};
  border-radius: 50%;
  object-fit: cover;
  justify-self: center;
`;

const Status = styled.span`
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  color: ${({ theme }) => theme.shared.card.footer};

  .link {
    cursor: pointer;
    color: #0065ff;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const Updated = styled.span`
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  color: ${({ theme }) => theme.shared.card.footer};
  margin-top: 16px;
`;

const MainGridView = ({
  panelData,
  isLoading,
  borderRadius,
  activeUsersFilter,
  peoplePaginated,
  pagination,
  primaryColor,
  activeUsers,
  getBackground,
  panelTitle,
}) => {
  const { page, setPage, maxPage } = pagination;
  const [showMoreClicked, setShowMoreClicked] = useState(false);
  const hasInitDatabase = usePanelsStore((state) => state.hasInitEmployeeDatabase);
  const setSelectedPage = usePageStore((state) => state.setSelectedPage);
  const setDisableClickOutside = useSidebarStore((state) => state.setDisableClickOutside);
  const hasChanges = useChangesStore((state) => state.hasChanges);
  const theme = useTheme();

  const filteredUsers = peoplePaginated.filter(activeUsersFilter);

  const renderUserCard = (page) => {
    const { status, type, userData, id, linkToPage, friendlyModified } = page;
    const event =
      type === "comment"
        ? panelData.events.find((event) => event.type === "comment" || event.label === "Comment posted")
        : panelData.events.find((rule) => status === rule.status && type === rule.type);
    const showCard = event && event.value;

    if (showCard) {
      return (
        <CardLinkWrapper key={id} target="_top" href={createProfileLink(userData.accountId)}>
          <UserCardWrapper borderRadius={borderRadius}>
            <CardBackground background={getBackground(userData)} />
            <CardInfoContainer imageHeight={panelData.profileImageSize || 80}>
              <UserImage imageSize={panelData.profileImageSize} src={userData.profilePicture} />
              <Name>{userData.name}</Name>
              <Status>
                {status}&nbsp;
                <LinkWrapper target="_blank" href={linkToPage}>
                  <span className="link" style={{ color: primaryColor }}>
                    a {type}
                  </span>
                </LinkWrapper>
                .
              </Status>
              <Updated>{friendlyModified}</Updated>
            </CardInfoContainer>
          </UserCardWrapper>
        </CardLinkWrapper>
      );
    }

    return null;
  };

  const hasResults = filteredUsers.map(renderUserCard).some(Boolean);
  const showPagination =
    (filteredUsers.map(renderUserCard).filter((user) => !!user).length === panelData.maxNumberOfItems &&
      activeUsers.length > panelData.maxNumberOfItems) ||
    panelData.pagination === PanelsPaginationTypes.ARROWS;
  const filteredUsersMaxPage = Math.ceil(
    activeUsers
      .filter(activeUsersFilter)
      .map(renderUserCard)
      .filter((user) => !!user).length / panelData.maxNumberOfItems,
  );

  useEffect(() => {
    setShowMoreClicked(false);
  }, [panelData.pagination]);

  return (
    <PanelWrapper
      panelTitle={panelTitle}
      displayOptions={{
        displayTitle: panelData.displayTitle,
        displayBox: panelData?.displayBox ?? true,
      }}
    >
      {isLoading && (
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
      )}

      {!hasInitDatabase && !isLoading && <NoDatabaseSync />}

      {!activeUsers?.length && !isLoading && (
        <EmptyState
          title="No one to show"
          description="Add, remove or adjust filters in the panel configuration."
          Image={() => {
            return <img src={`/images/hub/no-active-users-empty-state-${theme.global.name}.svg`} alt="" />;
          }}
        />
      )}

      {!!activeUsers?.length && !isLoading && hasInitDatabase && (
        <>
          {hasResults ? (
            <>
              <UserGrid>{filteredUsers.map(renderUserCard)}</UserGrid>
              {(showPagination || showMoreClicked) && (
                <Pagination
                  type={
                    panelData.pagination === PanelsPaginationTypes.SHOW_MORE
                      ? PanelsPaginationTypes.INFINITE
                      : panelData.pagination
                  }
                  showMoreClicked={showMoreClicked}
                  onClick={() => {
                    if (showPagination && !showMoreClicked) {
                      setPage(2);
                      setShowMoreClicked(true);
                      return;
                    }

                    setSelectedPage(Pages.PEOPLE);
                  }}
                  page={page}
                  hasNextPage={showPagination}
                  updatePage={setPage}
                  maxPage={filteredUsersMaxPage}
                  onWrapperHoverCallback={setDisableClickOutside}
                />
              )}
            </>
          ) : (
            <EmptyState
              title="No one to show"
              description="Add, remove or adjust filters in the panel configuration."
              Image={() => {
                return <img src={`/images/hub/no-active-users-empty-state-${theme.global.name}.svg`} alt="" />;
              }}
            />
          )}
        </>
      )}
    </PanelWrapper>
  );
};

export default MainGridView;
