import React from "react";
import styled, { useTheme } from "styled-components";
import CheckIcon from "@atlaskit/icon/glyph/check";
import SettingsIcon from "@atlaskit/icon/glyph/settings";
import { useQuery } from "react-query";
import { getCategoriesSettings } from "../../Settings/News/api";
import { useNewsroomStore } from "../newsroom-store";
import { SUBS_FILTER } from "../defaults";
import ListingFilters, { Link, LinkItem } from "../../Shared/ListingFilters";
import { FilterDivider } from "../../Shared/Filters/styled-filters";
import { ModalKeys } from "../../../../Modal/modalKeys";
import { useCurrentUserStore } from "../../../current-user-store";
import { useCorporateIdentityStore } from "../../Settings/General/BrandAndColors/corporate-identity-store";
import { COLOR_TYPES } from "../../Settings/General/BrandAndColors/color-types";

const IconContainer = styled.div`
  display: grid;
  place-content: center;
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

function Categories() {
  const theme = useTheme();
  const colorScheme = theme.global.name;

  const primaryColor = useCorporateIdentityStore((state) => state.colors[COLOR_TYPES.PRIMARY]);

  const isCurrentUserAdmin = useCurrentUserStore((state) => state.isCurrentUserAdmin);

  const { categoryFilter, setCategoryFilter } = useNewsroomStore((state) => ({
    categoryFilter: state.categoryFilter,
    setCategoryFilter: state.setCategoryFilter,
  }));

  const { isLoading, data: categories } = useQuery("categories-settings", getCategoriesSettings, {
    retry: 0,
    select: (response) => {
      const { data } = response;
      return data?.categories?.data || [];
    },
  });

  if (isLoading) return <></>;

  return (
    <div>
      <LinkItem style={{ justifyContent: "space-between" }}>
        <LinkItem style={{ padding: "0px" }}>
          {categoryFilter === SUBS_FILTER && <CheckIcon primaryColor={primaryColor || "#0c66e4"} />}
          <Link
            primaryColor={primaryColor}
            onClick={() => setCategoryFilter(categoryFilter === SUBS_FILTER ? undefined : SUBS_FILTER)}
          >
            My Subscriptions
          </Link>
        </LinkItem>

        <IconContainer
          onClick={() => {
            window.AP.dialog.create({
              key: "caelor-custom-dialog",
              chrome: false,
              customData: {
                modalKey: ModalKeys.SUBSCRIPTIONS,
                isCurrentUserAdmin,
                primaryColor,
              },
            });
          }}
        >
          <SettingsIcon primaryColor={colorScheme === "light" ? "#44546F" : "#9FADBC"} />
        </IconContainer>
      </LinkItem>

      <FilterDivider style={{ paddingTop: "15px", marginBottom: "15px" }} borderColor={theme.global.divider} />

      <ListingFilters
        filters={categories}
        isFilterSelected={(filterId) => categoryFilter === filterId}
        title="Categories"
        handleClickOnItem={(id) => setCategoryFilter(categoryFilter === id ? undefined : id)}
      />
    </div>
  );
}

export default Categories;
