import React from "react";
import styled from "styled-components";
import Layout from "./Layout";
import Design from "./Design";

export const ToggleTitle = styled.h1`
  margin: 0;
  font-size: 14px;
  color: ${({ theme }) => theme.shared.sidebar.title};
  font-weight: 400;
  padding: 12px 8px;
`;

export const LabeledTitle = styled.h1`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #6b778c;
  margin: 0;
`;

export const TabContent = styled.div`
  display: flex;
  flex-direction: column;
  padding-block: 0px;
`;

export const DesignSidebarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 30px);
`;

export const LabeledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const LabeledToggle = styled.div`
  display: flex;
  place-items: center;
  justify-content: space-between;
  gap: 1rem;
`;

const sidebars = {
  Layout: Layout,
  Design: Design,
};

const ActiveUsersEditor = ({
  panel,
  selectedTab,
  updatePanelDataProperty,
  isPositionedInMainContent,
  updatePanelTitle,
}) => {
  const Component = sidebars[selectedTab];

  return (
    <Component
      panel={panel}
      panelTitle={panel.panelTitle}
      updatePanelTitle={updatePanelTitle}
      updatePanelDataProperty={updatePanelDataProperty}
      isPositionedInMainContent={isPositionedInMainContent}
    />
  );
};

export default ActiveUsersEditor;
