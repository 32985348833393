import { nanoid } from "nanoid";

export const extractAllParentEntries = (structure) => structure.filter((entry) => entry.parent === "0");

export const extractAllSpacePermissions = (entriesWithPermissions) => {
  const foundSpaces = [];

  for (const entry of entriesWithPermissions) {
    if (entry?.spaces?.length) {
      foundSpaces.push(...entry.spaces);
    }
  }

  return foundSpaces;
};

export const getAllCollectionsFromEntries = (entries) => {
  const foundCollections = [];
  for (const entry of entries) {
    if (entry?.collections?.length) {
      foundCollections.push(...entry.collections);
    }
  }
  return foundCollections || [];
};

export const getSimulatedCollectionsFromEntries = (entries) => {
  const simulatedCollections = [];
  for (const entry of entries) {
    if (entry?.filters?.length) {
      simulatedCollections.push({
        id: nanoid(6),
        entryId: entry.id,
        filters: entry.filters,
      });
    }
  }

  return simulatedCollections || [];
};

export const separateNonExistingCollections = (collectionsFromEntries, allCollections) => {
  const withoutDeletedCollections = [];
  const deletedCollections = [];

  for (const collectionFromEntry of collectionsFromEntries) {
    if (allCollections.find((collection) => collection.id === collectionFromEntry)) {
      withoutDeletedCollections.push(collectionFromEntry);
    } else {
      deletedCollections.push(collectionFromEntry);
    }
  }

  return [withoutDeletedCollections, deletedCollections];
};

export const buildCollectionFilters = (collectionsFromEntries, allCollections) =>
  collectionsFromEntries.map((collectionId) => ({
    id: collectionId,
    filters: allCollections.find((collection) => collection.id === collectionId)?.filters || [],
    confluenceGroup: allCollections.find((collection) => collection.id === collectionId)?.userGroup || "",
  }));

export const hideEntriesFromAnonymousUser = (entries = [], isUserAnonymous) => {
  if (!isUserAnonymous || !entries?.length) return entries;

  const removedParentEntries = [];

  const filteredForAnonymous = entries.filter((entry) => {
    if (entry.parent === "0") {
      if (entry?.isHiddenFromAnonymous) {
        removedParentEntries.push(entry);
        return false;
      }
      return true;
    }
    return !removedParentEntries.find((parentEntry) => parentEntry.id === entry.parent);
  });

  return [...filteredForAnonymous];
};
