import React, { useRef, useState } from "react";
import styled, { useTheme } from "styled-components";
import Modal, { ModalFooter, ModalTransition } from "@atlaskit/modal-dialog";
import Toggle from "@atlaskit/toggle";
import Spinner from "@atlaskit/spinner";
import { createRecordsWithAccountIds, fetchUsersInSystem, saveSyncGuestUsersState } from "../datagrid/api";
import { useRecordsStore } from "../records-store";

const Button = styled.button`
  all: unset;
  border-radius: 4px;
  padding: 6px 12px;
  font-size: 14px;
  cursor: pointer;
  text-align: center;
  background: ${({ bg }) => bg};
  color: ${({ color }) => color};

  &:hover {
    background: ${({ bg }) => bg}cc;
  }
  &:active {
    background: ${({ bg }) => bg}ee;
  }
`;

const ModalTitle = styled.h1`
  color: ${({ theme }) => theme.confirmDeleteModal.modalHeaderText};
  font-weight: 500;
  font-size: 20px;
  font-style: inherit;
  line-height: 1;

  min-width: 0;
  flex: 1 1 auto;
  word-wrap: break-word;
  padding-bottom: 0px;
`;

const ModalBody = styled.h1`
  color: ${({ theme }) => theme.confirmDeleteModal.modalBodyText};
  font-weight: 400;
  font-size: 14px;
  font-style: inherit;
  line-height: 1;

  min-width: 0;
  flex: 1 1 auto;
  padding: 16px 24px;
  padding-top: 0px;
`;

const ModalHeader = styled.div`
  display: flex;
  padding: 24px 24px 16px 24px;
  padding-bottom: 0px;
  position: relative;
  align-items: center;
  justify-content: space-between;
`;

export const SyncUserModal = ({ visible, onClose, onSyncFinished }) => {
  const [syncing, setSyncing] = useState(false);
  const syncModalWrapperRef = useRef();
  const theme = useTheme();

  const { syncGuestUsers, setSyncGuestUsers } = useRecordsStore((state) => ({
    syncGuestUsers: state.syncGuestUsers,
    setSyncGuestUsers: state.setSyncGuestUsers,
  }));

  const handleStart = async () => {
    setSyncing(true);
    fetchUsersInSystem(syncGuestUsers)
      .then((results) => createRecordsWithAccountIds(results, true))
      .then(onSyncFinished)
      .then(() => saveSyncGuestUsersState(syncGuestUsers));
  };

  return (
    <ModalTransition>
      {visible && (
        <Modal
          width="600px"
          onClose={() => onClose()}
          onOpenComplete={() => {
            if (syncModalWrapperRef.current) {
              syncModalWrapperRef.current.parentNode.parentNode.style.overflow = "visible";
            }
          }}
        >
          {syncing && (
            <div
              style={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spinner size={"large"} />
            </div>
          )}
          {!syncing && (
            <div ref={syncModalWrapperRef}>
              <ModalHeader>
                <ModalTitle>Start</ModalTitle>
              </ModalHeader>
              <ModalBody>
                <p style={{ lineHeight: "20px" }}>
                  It's all about people! Sync users, launch your database, and start your intranet.
                </p>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <span style={{ lineHeight: "20px" }}>Sync guest users</span>
                  <Toggle
                    isChecked={syncGuestUsers}
                    isDisabled={syncing}
                    onChange={({ target: { checked: isChecked } }) => setSyncGuestUsers(isChecked)}
                  />
                </div>

                <div style={{ fontSize: "13px", fontWeight: 400, lineHeight: "0.5" }}>
                  <p>Decide whether you wish to synchronize guest users or not. </p>
                  <p>You can always re-sync and change this later.</p>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button
                  bg={theme.confirmDeleteModal.cancelBackground}
                  color={theme.confirmDeleteModal.cancelTextSecundary}
                  onClick={onClose}
                >
                  Cancel
                </Button>

                <Button
                  onClick={handleStart}
                  style={{ marginLeft: "10px" }}
                  bg={theme.confirmDeleteModal.primaryBackground}
                  color={theme.confirmDeleteModal.primaryColor}
                  autoFocus
                >
                  Confirm
                </Button>
              </ModalFooter>
            </div>
          )}
        </Modal>
      )}
    </ModalTransition>
  );
};

export default SyncUserModal;
