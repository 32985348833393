import React from "react";
import SortSelect from "../../Shared/SortSelect";
import { useSpaceDirectoryStore } from "../space-directory-store";
import { FilterTypes, SORT_OPTIONS } from "../types";
import { FieldContainer, FieldLabel } from "../../Shared/Filters/styled-filters";
import { useCorporateIdentityStore } from "../../Settings/General/BrandAndColors/corporate-identity-store";
import { COLOR_TYPES } from "../../Settings/General/BrandAndColors/color-types";
import { useTheme } from "styled-components";
import { convertColorOpacity } from "../../../../../Shared/Functions/convertColorOpacity";

function Sort() {
  const { selectedSort, setSelectedSort, selectedFilter, setSelectedFilter } = useSpaceDirectoryStore((state) => ({
    selectedSort: state.selectedSort,
    setSelectedSort: state.setSelectedSort,
    selectedFilter: state.selectedFilter,
    setSelectedFilter: state.setSelectedFilter,
  }));

  const theme = useTheme();
  const colors = useCorporateIdentityStore((state) => state.colors);
  const primaryColor = colors[COLOR_TYPES.PRIMARY] || "#0065ff";

  const convertedColor = convertColorOpacity(primaryColor, theme.global.name);

  return (
    <FieldContainer>
      <FieldLabel>SORT BY</FieldLabel>
      <SortSelect
        selectedOption={selectedSort}
        onChange={(newSort) => {
          setSelectedSort(newSort);
          if (!selectedFilter) {
            setSelectedFilter(FilterTypes.ALL);
          }
        }}
        options={SORT_OPTIONS}
        useStandardSelect
        color={primaryColor}
        convertedColor={convertedColor}
        theme={theme}
      />
    </FieldContainer>
  );
}

export default Sort;
