import React from "react";

import { usePageStore } from "../../../../page-store";
import { Pages } from "../../../../pages";
import { Content, Divider, Header, Wrapper } from "../styles";
import TitleWithBack from "../../../../../../Shared/Components/TitleWithBack";
import TabsContent from "./tabs/TabsContent";

const Notifications = () => {
  const setSelectedPage = usePageStore((state) => state.setSelectedPage);

  return (
    <Wrapper>
      <Header>
        <TitleWithBack
          handleOnClick={() => setSelectedPage(Pages.SETTINGS)}
          title="Push Notifications"
          description="In-app notifications can be manual or dynamic for new blogs and key updates"
        />
      </Header>

      <Divider />

      <Content>
        <TabsContent />
      </Content>
    </Wrapper>
  );
};

export default Notifications;
