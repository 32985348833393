import React, { useContext, useEffect, useState } from "react";
import Spinner from "@atlaskit/spinner";
import { mapDiagramResponse, searchDiagramsForSpaceId } from "./api";
import { useInfiniteQuery, useQueryClient } from "react-query";
import PanelWrapper from "../../../../Shared/Panels/shared/components/PanelWrapper";
import Pagination from "../../../../Shared/PanelsPagination/Pagination";
import { useSidebarStore } from "../../edit/sidebar/sidebar-store";
import { ViewTypes } from "../../../../Shared/Panels/edit/people/configurator/options";
import CardView from "./components/views/CardView";
import ListView from "./components/views/ListView";
import { SpinnerContainer } from "../../../../../styled/pages";
import { PanelsPaginationTypes } from "../../../../Shared/PanelsPagination/panels-pagination-types";
import { PanelPositioning } from "../../panelTypes";
import { useAtlassianLocale } from "../../../../../../../Shared/Hooks/useAtlassianLocale";
import EmptyState from "../../shared/EmptyState";
import { useCurrentUserStore } from "../../../../../current-user-store";
import { DashboardAPIContext } from "../../../context";
import { TabTypes } from "../../tabTypes";
import { LoadingTypes } from "../../../filter/DynamicFilterToggle";

const NoSelectionEmptyState = ({ isAdmin, onClick }) => (
  <EmptyState
    title="Space is required"
    description="Select space(s) to display Gliffy Diagrams."
    button={{
      isAvailable: isAdmin,
      appearance: "primary",
      buttonText: "Select Space(s)",
      onClick,
    }}
  />
);

const Panel = ({ panelTitle, displayTitle, displayBox, children }) => {
  return (
    <PanelWrapper
      panelTitle={panelTitle}
      displayOptions={{
        displayTitle: displayTitle ?? true,
        displayBox: displayBox ?? true,
      }}
    >
      {children}
    </PanelWrapper>
  );
};

function Gliffy({ id, data, panelTitle, position }) {
  const {
    spaceIds,
    view,
    pagination,
    elementsToDisplay,
    orderBy,
    sortOrder,
    displayTitle,
    displayBox,
    loadingType,
    manualImageSrc,
  } = data;

  const [locale] = useAtlassianLocale();
  const queryClient = useQueryClient();

  const isPositionedInSide = position === PanelPositioning.SIDE;

  const [page, setPage] = useState(1);

  const setDisableClickOutside = useSidebarStore((state) => state.setDisableClickOutside);

  const isAdmin = useCurrentUserStore((state) => state.isCurrentUserAdmin);

  const { editPanelExternally } = useContext(DashboardAPIContext);

  const {
    isError,
    isLoading,
    fetchNextPage,
    hasNextPage,
    data: diagramResponseData,
  } = useInfiniteQuery(
    [
      `gliffy-diagrams-${id}`,
      {
        spaceIds: spaceIds,
        orderBy,
        sortOrder,
        pageSize: isPositionedInSide ? 1 : 3,
      },
    ],
    searchDiagramsForSpaceId,
    {
      enabled: !!spaceIds?.length && loadingType === LoadingTypes.DYNAMIC,
      retry: 0,
      select: (response) => {
        const pages = [];
        response.pages.forEach((page) => {
          const body = JSON.parse(page.body);
          const resultsInPage = body.results.map((result) => mapDiagramResponse(result));
          pages.push({ ...body, results: resultsInPage });
        });
        return { pages, pageParams: response.pageParams };
      },
      getNextPageParam: (lastPage) => {
        const body = JSON.parse(lastPage.body);
        if (body._links.next) {
          const urlParams = new URLSearchParams(encodeURI(body._links.next));
          const cursor = urlParams.get("cursor");
          return cursor;
        }
        return false;
      },
    },
  );

  useEffect(() => {
    setPage(1);
    queryClient.resetQueries([`gliffy-diagrams-${id}`]);
  }, [pagination, spaceIds]);

  const totalSize = diagramResponseData?.pages[0]?.totalSize;
  const numberOfItemsPerPage = isPositionedInSide ? 1 : 3;

  const paginatedData =
    pagination === PanelsPaginationTypes.ARROWS ? [diagramResponseData?.pages[page - 1]] : diagramResponseData?.pages;

  if (loadingType === LoadingTypes.MANUAL) {
    return (
      <Panel panelTitle={panelTitle} displayTitle={displayTitle} displayBox={displayBox}>
        {manualImageSrc ? (
          <img src={manualImageSrc} alt="gliffy-diagram" style={{ width: "100%", objectFit: "contain" }} />
        ) : (
          <NoSelectionEmptyState
            isAdmin={isAdmin}
            onClick={() => editPanelExternally(id, position, TabTypes.CONTENT)}
          />
        )}
      </Panel>
    );
  }

  if (isLoading) {
    return (
      <Panel panelTitle={panelTitle} displayTitle={displayTitle} displayBox={displayBox}>
        <SpinnerContainer>
          <Spinner size="xlarge" />
        </SpinnerContainer>
      </Panel>
    );
  }

  if (!spaceIds?.length) {
    return (
      <Panel panelTitle={panelTitle} displayTitle={displayTitle} displayBox={displayBox}>
        <NoSelectionEmptyState isAdmin={isAdmin} onClick={() => editPanelExternally(id, position, TabTypes.CONTENT)} />
      </Panel>
    );
  }

  if (totalSize === 0 || isError) {
    return (
      <Panel panelTitle={panelTitle} displayTitle={displayTitle} displayBox={displayBox}>
        <EmptyState
          title="No content found"
          description={
            isError
              ? "Ensure you have Gliffy Diagrams installed to utilize this feature."
              : "No Gliffy Diagrams were found. Please select another space."
          }
        />
      </Panel>
    );
  }

  return (
    <Panel panelTitle={panelTitle} displayTitle={displayTitle} displayBox={displayBox}>
      {view === ViewTypes.CARD && (
        <CardView data={paginatedData} elementsToDisplay={elementsToDisplay} position={position} locale={locale} />
      )}
      {view === ViewTypes.LIST && (
        <ListView data={paginatedData} elementsToDisplay={elementsToDisplay} position={position} locale={locale} />
      )}

      <Pagination
        type={pagination}
        page={page}
        updatePage={(newPage) => {
          setPage(newPage);
          if (pagination === PanelsPaginationTypes.SHOW_MORE && hasNextPage) {
            fetchNextPage();
          }
        }}
        maxPage={Math.ceil(totalSize / numberOfItemsPerPage)}
        onWrapperHoverCallback={setDisableClickOutside}
        infiniteQuery={{ shouldFetchNext: hasNextPage, fetchNextPage }}
      />
    </Panel>
  );
}

export default Gliffy;
