import React from "react";

import { PhoneContainer } from "../styles";
import { SCREEN_TYPE } from "./PhoneScreens/defaults";
import Newsroom from "./PhoneScreens/Newsroom";
import People from "./PhoneScreens/People";
import UnderConstruction from "./PhoneScreens/UnderConstruction";
import Maintenance from "./PhoneScreens/Maintenance";
import Loading from "./PhoneScreens/Loading";
import { useCosmosAppSettings } from "../use-cosmos-app-settings";
import { useDatabaseInitialization } from "../../../../../../Shared/Hooks/useDatabaseInitialization";

const screenComponents = {
  [SCREEN_TYPE.NEWSROOM]: Newsroom,
  [SCREEN_TYPE.PEOPLE]: People,
  [SCREEN_TYPE.UNDER_CONSTRUCTION]: UnderConstruction,
  [SCREEN_TYPE.MAINTENANCE]: Maintenance,
};

const Phone = ({ screenType = "newsroom", userDatabaseFieldsData }) => {
  const { isDatabaseInitialized } = useDatabaseInitialization();
  const { isLoadingCosmosAppSettings } = useCosmosAppSettings({ isDatabaseInitialized });

  const Screen = isLoadingCosmosAppSettings ? Loading : screenComponents[screenType];

  const screenProps = {
    [SCREEN_TYPE.PEOPLE]: { userDatabaseFieldsData },
  };

  return (
    <div style={{ width: "300px", height: "610px", background: "url('/images/cosmos-app/iphone-mockup-light.svg')" }}>
      <PhoneContainer>
        <Screen screenType={screenType} {...screenProps[screenType]} />
      </PhoneContainer>
    </div>
  );
};

export default Phone;
