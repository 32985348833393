import styled, { useTheme } from "styled-components";
import { useRef, useEffect, useMemo } from "react";
import { SidePanel, SidePanelHeader } from "../General";
import { Radio } from "@atlaskit/radio";
import Textfield from "@atlaskit/textfield";
import { saveCorporateIdentitySettings } from "../../../../api";

import debounce from "lodash.debounce";
import { RADIUS_TYPES, RADIUS_VALUES } from "../BrandAndColors/types";
import { useState } from "react";

const Message = styled.p`
  color: ${({ theme }) => theme.shared.settings.general.brandAndColors.logoNameColor};
  font-size: 14px;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
`;

const Wrapper = styled.div`
  display: flex;
  column-gap: 16px;

  margin-bottom: 12px;
`;

const RadiusOptionWrapper = styled.div`
  width: 160px;
  height: 40px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  padding-left: 8px;
  cursor: pointer;

  :nth-child(1) {
    padding-left: 15px;
  }

  label > span > span {
    cursor: pointer;
  }

  ${({ isChecked, theme }) =>
    isChecked
      ? `
      background-color: ${theme.shared.settings.general.brandAndColors.editLogoBackground};
      label > input:checked {
        --radio-background-color: ${theme.global.primaryColor} !important;
        --radio-border-color: ${theme.global.primaryColor} !important;
      }
    `
      : `
      background-color: transparent;
    `}
`;

const RADIUS_OPTIONS = [
  {
    name: "Small - 3px",
    type: RADIUS_TYPES.SMALL,
    value: RADIUS_VALUES[RADIUS_TYPES.SMALL],
  },
  {
    name: "Medium - 8px",
    type: RADIUS_TYPES.MEDIUM,
    value: RADIUS_VALUES[RADIUS_TYPES.MEDIUM],
  },
  {
    name: "Large - 16px",
    type: RADIUS_TYPES.LARGE,
    value: RADIUS_VALUES[RADIUS_TYPES.LARGE],
  },
];

function Corner({ borderRadius, setBorderRadius }) {
  const theme = useTheme();

  const textfieldRef = useRef(null);

  const [inputValue, setInputValue] = useState();

  const isUsingCustomValue = useMemo(() => !Object.values(RADIUS_VALUES).includes(borderRadius), [borderRadius]);

  const handleUpdateRadius = (radius) => {
    setBorderRadius(radius);
    handleSaveDebounce(radius);
  };

  const RadioLabel = ({ name, color }) => {
    return <span style={{ color: color, fontSize: "14px" }}>{name}</span>;
  };

  const handleSaveDebounce = useRef(
    debounce(async (_radius) => {
      try {
        await saveCorporateIdentitySettings({ borderRadius: _radius });
      } catch {
        console.log("failed to save borderRadius");
      }
    }, 500),
  ).current;

  useEffect(() => setInputValue(parseInt(borderRadius, 10)), []);

  useEffect(() => {
    return () => {
      handleSaveDebounce.cancel();
    };
  }, [handleSaveDebounce]);

  return (
    <SidePanel style={{ marginTop: "0px", marginLeft: "0px" }}>
      <SidePanelHeader>
        <div>Corner radius</div>
      </SidePanelHeader>

      <div style={{ paddingLeft: "25px", paddingRight: "25px" }}>
        <Message>Applied to news, images, videos, people cards and all other Cosmos widgets</Message>

        <Wrapper>
          {RADIUS_OPTIONS.map((option) => (
            <RadiusOptionWrapper
              isChecked={borderRadius === RADIUS_VALUES[option.type]}
              onClick={() => {
                handleUpdateRadius(RADIUS_VALUES[option.type]);
              }}
              key={option.type}
            >
              <Radio
                label={
                  <RadioLabel name={option.name} color={theme.shared.settings.general.brandAndColors.logoNameColor} />
                }
                isChecked={borderRadius === RADIUS_VALUES[option.type]}
              />
            </RadiusOptionWrapper>
          ))}
        </Wrapper>

        <RadiusOptionWrapper
          isChecked={isUsingCustomValue}
          onClick={() => {
            handleUpdateRadius(RADIUS_VALUES[RADIUS_TYPES.CUSTOM]);
            textfieldRef?.current?.focus();
          }}
          style={{ marginLeft: "8px" }}
        >
          <Radio isChecked={isUsingCustomValue} />

          <Textfield
            ref={textfieldRef}
            value={isUsingCustomValue ? inputValue : ""}
            onChange={(e) => {
              const valueAsNumber = Number(e.target.value);

              if (valueAsNumber >= 20) {
                setInputValue("20");
                handleUpdateRadius("20px");
              } else if (valueAsNumber >= 0) {
                setInputValue(valueAsNumber);
                handleUpdateRadius(`${valueAsNumber}px`);
              }
            }}
            appearance="subtle"
            type="string"
            placeholder="Custom radius"
            style={{
              border: `2px solid ${theme.shared.settings.general.brandAndColors.editLogoBackground}`,
              borderRadius: "3px",
            }}
          />
        </RadiusOptionWrapper>

        <Message style={{ fontSize: "12px" }}>Custom radius value from 0 to 20</Message>
      </div>
    </SidePanel>
  );
}

export default Corner;
