import React, { useContext, useEffect, useRef } from "react";
import EditPanelsButton from "../Shared/EditPanelsButton";
import { useSidebarStore } from "./newsroom-store";
import Sidebar from "./Sidebar/Sidebar";
import { useChangesStore } from "../../track-changes-store";

function AdminControls() {
  const primaryColor = "#0065ff";
  const primaryContrastColor = "#ffffff";

  const { setIsSidebarOpen } = useSidebarStore((state) => ({ setIsSidebarOpen: state.setIsSidebarOpen }));

  const { hasChanges, setHasChanges } = useChangesStore((state) => ({
    hasChanges: state.hasChanges,
    setHasChanges: state.setHasChanges,
  }));

  const hasChangesRef = useRef();
  hasChangesRef.current = hasChanges;

  return (
    <>
      <EditPanelsButton
        openSidebar={() => setIsSidebarOpen(true)}
        primaryColor={primaryColor}
        primaryContrastColor={primaryContrastColor}
      />

      <Sidebar />
    </>
  );
}

export default AdminControls;
