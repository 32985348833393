import React from "react";
import styled from "styled-components";
import Textfield from "@atlaskit/textfield";
import SearchIcon from "@atlaskit/icon/glyph/search";
import ArrowLeftIcon from "@atlaskit/icon/glyph/arrow-left";
import { PROVIDERS } from "./Icon";
import { ProviderOptionsContainer, ProviderSelector } from "./styled/Components";
import { useIconStore } from "./icon-store";
import shallow from "zustand/shallow";

const SearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;

  div:nth-child(3) {
    background: #ffffff;
    border: 2px solid #dfe1e6;
    border-radius: 5px;

    input {
      color: #6b778c;
      font-size: 14px;
      line-height: 24px;
      padding-bottom: 10px;
    }
  }

  svg {
    padding-left: 8px;
    width: 20px;
    height: 20px;
    fill: #6b778c;
  }
`;

const DividerLine = styled.div`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.icons.seperator};
`;

const Button = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;

  font-weight: 500;
  font-size: 14px;
  color: #0065ff;

  cursor: pointer;

  svg {
    padding-left: 0px;
  }

  span {
    line-height: 1.2;
  }
`;

function SelectProviders({ onClose, isInTabs }) {
  const { searchTerm, setSearchTerm, provider, setProvider } = useIconStore((state) => {
    return {
      searchTerm: state.searchTerm,
      setSearchTerm: state.setSearchTerm,
      provider: state.provider,
      setProvider: state.setProvider,
    };
  }, shallow);

  return (
    <>
      <SearchContainer>
        {!isInTabs && (
          <div
            style={{
              paddingBottom: "10px",
              borderBottom: "1px solid rgba(0,0,0,0.1)",
            }}
          >
            <Button onClick={onClose}>
              <ArrowLeftIcon size="medium" primaryColor="#0065ff" />
              <span>Go back</span>
            </Button>
          </div>
        )}
        <Textfield
          placeholder="Search"
          value={searchTerm}
          elemBeforeInput={<SearchIcon primaryColor="#42526E" />}
          onChange={(e) => {
            const { value } = e.target;
            setSearchTerm(value);
          }}
        />
      </SearchContainer>

      <ProviderOptionsContainer>
        {PROVIDERS.map(({ key, name }) => (
          <ProviderSelector isChecked={provider === key} key={key} onClick={() => setProvider(key)}>
            {name}
          </ProviderSelector>
        ))}
      </ProviderOptionsContainer>

      <DividerLine />
    </>
  );
}

export default SelectProviders;
