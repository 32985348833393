import React from "react";
import IconEmojiSidebar, { IconTypes } from "../../../../../../Shared/Components/SidebarIconPicker/IconEmojiSidebar";
import { useNavigationStore, useSidebarStore } from "../../../../store";

function Icons() {
  const { editingEntry, updateEntry } = useNavigationStore((state) => ({
    editingEntry: state.editingEntry,
    updateEntry: state.updateEntry,
  }));

  const { closeHelperDrawer } = useSidebarStore((state) => ({
    closeHelperDrawer: state.closeHelperDrawer,
  }));

  const handleUpdate = (selected) => updateEntry(editingEntry.id, "icon", selected, true);

  return (
    <IconEmojiSidebar
      hasEmojis
      appIconsDrawer={{ available: true, modifiers: false }}
      showNoIconBox
      onClose={() => closeHelperDrawer()}
      onSelected={(selected, type) => {
        closeHelperDrawer();
        if (IconTypes.ICON === type) {
          return handleUpdate({ ...selected, type });
        }

        if (IconTypes.APP === type) {
          return handleUpdate({ icon: selected, type });
        }

        if (IconTypes.EMOJI) {
          return handleUpdate({ ...selected, type });
        }
      }}
    />
  );
}

export default Icons;
