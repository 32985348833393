import React, { useEffect } from "react";
import { useQuery } from "react-query";
import Modal, { ModalTransition } from "@atlaskit/modal-dialog";
import styled, { useTheme } from "styled-components";
import Spinner from "@atlaskit/spinner";
import { fetchCollections } from "./api";
import { useCollectionsStore, useCreateCollectionStore } from "./collection-store";
import EmptyState from "./EmptyState";
import Header from "./Header";
import Overview from "./overview/Overview";
import CollectionInfoModal from "./modal/CollectionInfoModal";
import { fetchIsInitialized, fetchUserDatabaseFields } from "../UserDatabase/datagrid/api";
import SelectedCollection from "./selected/SelectedCollection";

import data from "@emoji-mart/data";
import { init } from "emoji-mart";
import { SectionKeys, SectionPagesIDs } from "../Hub/Components/Settings/sections";
import { usePageStore } from "../Hub/page-store";
import { Pages } from "../Hub/pages";
// init emoji-mart data
init({ data });

const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const SpinnerContainer = styled.div`
  display: grid;
  place-content: center;
  height: 100%;
  width: 100%;
`;

const Container = styled.div`
  display: grid;
  place-content: center;
  justify-items: center;
  text-align: center;
  height: 100%;
  width: 100%;
`;

const Title = styled.p`
  font-weight: 590;
  font-size: 20px;
  line-height: 23.87px;
  color: ${({ theme }) => theme.shared.emptyState.text};
  margin-top: 30px;
  margin-bottom: 12px;
`;

const Description = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.shared.emptyState.text};
`;

const Button = styled.button`
  height: 32px;
  border-radius: 3px;
  padding: 0px 12px;
  background-color: ${({ theme }) => theme.shared.emptyState.buttonBackground};
  color: ${({ theme }) => theme.shared.emptyState.buttonText};
  border: none;
  cursor: pointer;
`;

function Collections() {
  const {
    collections,
    setCollections,
    selectedCollection,
    isCreatingCollection,
    setIsCreatingCollection,
    setDatabaseFields,
    resetStore,
  } = useCollectionsStore((state) => ({
    collections: state.collections,
    setCollections: state.setCollections,
    selectedCollection: state.selectedCollection,
    isCreatingCollection: state.isCreatingCollection,
    setIsCreatingCollection: state.setIsCreatingCollection,
    setDatabaseFields: state.setDatabaseFields,
    resetStore: state.reset,
  }));

  const { isEditingIcon } = useCreateCollectionStore((state) => ({
    isEditingIcon: state.isEditingIcon,
  }));

  const setSelectedPage = usePageStore((state) => state.setSelectedPage);

  const theme = useTheme();

  const { isLoading: isInitializedLoading, data: isInitialized } = useQuery(
    "database-is-initialized",
    fetchIsInitialized,
    {
      retry: 0,
      select: (response) => {
        const { data } = response;
        return data;
      },
    },
  );

  const { data: fieldsData } = useQuery(["userdatabase-fields", { includeEmail: false }], fetchUserDatabaseFields, {
    retry: 0,
    select: (response) => {
      const { data } = response;
      return data;
    },
  });

  const { isLoading, isFetching, data, refetch } = useQuery("saved-collections", fetchCollections, {
    cacheTime: 0,
    retry: 0,
    select: (response) => {
      const { data } = response;
      return { collections: data || [] };
    },
  });

  const formatCollections = (savedCollections, availableFields) => {
    const formattedCollections = savedCollections?.map((collection) => {
      const availableFilters = collection.filters.filter((collectionFilter) => {
        const existsInDB = availableFields.find((availableField) => availableField.id === collectionFilter.column);
        return !!existsInDB;
      });

      return { ...collection, filters: availableFilters };
    });

    return formattedCollections;
  };

  useEffect(() => {
    resetStore();
  }, []);

  useEffect(() => {
    if (fieldsData) {
      setDatabaseFields(fieldsData.filter((field) => field.id !== "user"));
      if (!!data?.collections?.length) {
        setCollections(formatCollections(data.collections, fieldsData) || []);
      }
    }
  }, [fieldsData, data?.collections]);

  useEffect(() => {
    window.AP.events.on("single-collection-updated", () => {
      refetch();
    });
  }, []);

  if (!isInitialized && !isInitializedLoading) {
    return (
      <Container>
        <img width="525" height="184.5" src={`/images/hub/collections-empty-state-${theme.global.name}.svg`} alt="" />

        <div>
          <Title>Initalize your Employee Database sync</Title>
          <Description style={{ width: "464px" }}>
            <span>
              Start by syncing your Confluence user database to set up your intranet. This essential step establishes
              the foundation of the Cosmos Intranet. Once synced, you can connect Azure Active Directory.
            </span>
          </Description>

          <Button
            onClick={() => setSelectedPage(`${Pages.SETTINGS}-${SectionKeys.PEOPLE_BASE}-${SectionPagesIDs.DATABASE}`)}
          >
            Sync Employees
          </Button>
        </div>
      </Container>
    );
  }

  return (
    <Main>
      {!selectedCollection && (
        <>
          <Header />

          {isLoading && isInitializedLoading && (
            <SpinnerContainer>
              <Spinner size="xlarge" />
            </SpinnerContainer>
          )}

          {!isLoading && !isFetching && !!collections.length && <Overview />}

          {!isLoading && !isFetching && !collections.length && <EmptyState />}
        </>
      )}

      {selectedCollection && <SelectedCollection />}

      <ModalTransition>
        {isCreatingCollection && (
          <Modal
            height="auto"
            width="auto"
            isChromeless
            shouldCloseOnOverlayClick={!isEditingIcon}
            onClose={() => setIsCreatingCollection(false)}
          >
            <CollectionInfoModal />
          </Modal>
        )}
      </ModalTransition>
    </Main>
  );
}

export default Collections;
