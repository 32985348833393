import React from "react";
import styled, { useTheme } from "styled-components";
import { useAtlassianLocale } from "../../../../../Shared/Hooks/useAtlassianLocale";
import { useBlogs } from "./useBlogs";

import { DeleteIcon } from "../panels/edit/sidebar/components/Icons";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import DragHandlerIcon from "@atlaskit/icon/glyph/drag-handler";
import { HTML5Backend } from "react-dnd-html5-backend";

const RemoveContainer = styled.div`
  cursor: pointer;
  width: 24px;
  height: 24px;
  display: grid;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
  justify-self: flex-end;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  padding-top: 20px;
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.navigation.settings.iconBoxBorder};

  ${({ isHoveringOver, orderDirection }) => {
    if (!isHoveringOver) {
      return null;
    }

    return orderDirection === "top-bottom" ? `border-bottom: 1px solid #aaa;` : `border-top: 1px solid #aaa;`;
  }};

  padding: 7px 0px;

  font-size: 14px;
  font-weight: 500;
  color: #172b4d;
  .actions {
    visibility: hidden;
  }
  &:hover {
    .actions {
      visibility: visible;
    }
  }
`;

const IconContainer = styled.div`
  display: grid;
  place-content: center;
  cursor: grab;

  svg {
    color: #8993a4 !important;
    fill: #8993a4 !important;
  }

  &:hover {
    opacity: 0.9;
  }
`;

const ItemTitle = styled.span`
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 70%;
`;

function ReorderableItem({ blog, reorderBlogs, handleDelete }) {
  const theme = useTheme();
  const [{ isOver }, drop] = useDrop(() => ({
    accept: ["blog"],
    drop: () => ({ id: blog.id, ...blog }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  }));

  const [{ draggedItem }, drag] = useDrag({
    type: "blog",
    item: () => {
      return { id: blog.id, type: "blog", ...blog };
    },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (item && dropResult) {
        reorderBlogs(item.id, dropResult.id);
      }
    },
    collect: (monitor) => ({
      draggedItem: monitor.getItem(),
    }),
  });

  const draggedItemPosition = draggedItem?.position ?? 0;
  const potentialTargetPosition = isOver ? blog?.position : 0;
  return (
    <Item
      key={blog.id}
      ref={drop}
      isHoveringOver={isOver}
      orderDirection={draggedItemPosition < potentialTargetPosition ? "top-bottom" : "bottom-top"}
    >
      <IconContainer ref={drag}>
        <DragHandlerIcon />
      </IconContainer>

      <ItemTitle style={{ color: theme.shared.card.title }}>{blog.name}</ItemTitle>

      <RemoveContainer className="actions" onClick={() => handleDelete(blog.id)}>
        <DeleteIcon />
      </RemoveContainer>
    </Item>
  );
}

function ManualBlogsList({ manuallyPicked, onRemove, updateOrder }) {
  const [locale] = useAtlassianLocale();
  const theme = useTheme();
  const blogQueriesUnfiltered = useBlogs(manuallyPicked || [], locale);
  const blogQueries = blogQueriesUnfiltered.filter((blog) => blog.data && !!Object.keys(blog.data || {}).length);

  const blogData = blogQueries.map((blog) => blog.data);

  const resetOrder = (list) => {
    const resetList = list.map((c, index) => {
      const slide = c;
      slide.position = index + 1;
      return slide;
    });
    return resetList;
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return resetOrder(result);
  };

  const reorderBlogs = (sourceId, targetId) => {
    if (sourceId === targetId) {
      return;
    }
    const content = [...manuallyPicked];

    const sourceIndex = content.findIndex((b) => b.id === sourceId);
    const targetIndex = content.findIndex((b) => b.id === targetId);
    const items = reorder(content, sourceIndex, targetIndex);

    updateOrder(items);
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <Wrapper>
        {!!blogData?.length &&
          blogData
            .sort((a, b) => a.position - b.position)
            .map((data) => (
              <ReorderableItem key={data.id} blog={data} reorderBlogs={reorderBlogs} handleDelete={onRemove} />
            ))}
      </Wrapper>
    </DndProvider>
  );
}

export default ManualBlogsList;
