import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Button from "@atlaskit/button";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: ${({ direction }) => (direction === "column" ? "center" : "left")};
  flex-direction: ${({ direction }) => direction};
  row-gap: ${({ gap }) => `${gap}px`};
  column-gap: ${({ gap }) => `${gap}px`};
  padding: 25px 0px;
`;

const TextContainer = styled.div`
  display: grid;
  row-gap: 16px;
`;

const Title = styled.h6`
  all: unset;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: ${({ theme }) => theme.emptyState.title};
`;

const Description = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: ${({ theme }) => theme.emptyState.text};
`;

function EmptyState({ title, description, Image, direction, gap = 24, customStyles = {}, button = { undefined } }) {
  const {
    customWrapperStyles = {},
    customTitleStyles = {},
    customDescriptionStyles = {},
    customTextContainerStyles = {},
  } = customStyles || {};

  const { isAvailable = false, buttonText = "", appearance = "primary", onClick = () => {} } = button;

  return (
    <Wrapper direction={direction} gap={gap} style={{ ...customWrapperStyles }}>
      {Image && <Image />}
      <TextContainer style={{ ...customTextContainerStyles }}>
        <Title style={{ ...customTitleStyles }}>{title}</Title>
        <Description style={{ ...customDescriptionStyles }} dangerouslySetInnerHTML={{ __html: description }} />
      </TextContainer>
      {isAvailable && (
        <Button appearance={appearance} onClick={onClick}>
          {buttonText}
        </Button>
      )}
    </Wrapper>
  );
}

EmptyState.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  direction: PropTypes.oneOf(["column", "row"]),
  gap: PropTypes.number,
};

EmptyState.defaultProps = {
  direction: "column",
  gap: 24,
};

export default EmptyState;
