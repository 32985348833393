import React, { useEffect, useMemo } from "react";
import styled from "styled-components";
import { useWidgetsStore } from "../widgets-store";
import Tabs from "./Tabs";
import { AvailableContent } from "../consts/content";
import { useQuery } from "react-query";
import { fetchUserDatabaseFields } from "../../../../../UserDatabase/datagrid/api";

const Wrapper = styled.div`
  display: grid;
  row-gap: 24px;
`;

function Content() {
  const { selectedTab, setDatabaseFields } = useWidgetsStore();

  const SelectedContent = useMemo(() => AvailableContent[selectedTab?.id], [selectedTab]);

  const { isLoading: fieldsLoading, data: fieldsData } = useQuery(
    ["userdatabase-fields", { includeEmail: false }],
    fetchUserDatabaseFields,
    {
      retry: 0,
      select: (response) => {
        const { data } = response;
        return data
          .filter((field) => field.id !== "user")
          .map((field) => ({ label: field.title, value: field.id, fieldProps: field }));
      },
    },
  );

  useEffect(() => {
    if (!fieldsLoading && fieldsData) {
      setDatabaseFields([...fieldsData]);
    }
  }, [fieldsLoading, fieldsData, setDatabaseFields]);

  return (
    <Wrapper>
      <Tabs />
      <SelectedContent />
    </Wrapper>
  );
}

export default Content;
