import React from "react";
import { useDrop } from "react-dnd";
import shallow from "zustand/shallow";
import { useChangesStore } from "../../../../track-changes-store";
import { useRecordsStore } from "../../../../../UserDatabase/records-store";
import { useTheme } from "styled-components";

function ReorderablePlaceholder({ alignment }) {
  const { fields, setFields, updateField } = useRecordsStore(
    (state) => ({
      fields: state.fields,
      setFields: state.setFields,
      updateField: state.updateField,
    }),
    shallow,
  );

  const setHasChanges = useChangesStore((state) => state.setHasChanges);

  const theme = useTheme();

  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: ["profile-field"],
      drop: (item) => {
        if (item) {
          const items = fields.map((field) => {
            if (field.id === item.id) {
              const profile = { section: "main", alignment, order: 0 };
              updateField(field.id, { profile });
              setHasChanges(true);
              return { ...field, profile };
            }

            return field;
          });

          console.log(fields, items);
          setFields([...items]);
        }

        return { id: "placeholder" };
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
      }),
    }),
    [fields],
  );

  return (
    <div
      ref={drop}
      style={{
        textAlign: "center",
        fontSize: "12px",
        color: "#7a869a",
        padding: "10px",
        borderRadius: "4px",
        background: isOver
          ? theme.shared.profile.reorderablePlaceholderOverBackground
          : theme.shared.profile.reorderablePlaceholderBackground,
      }}
    >
      Nothing to show, select fields to display in profile section.
    </div>
  );
}

export default ReorderablePlaceholder;
