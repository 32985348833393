import { useEffect, useState } from "react";
import shallow from "zustand/shallow";
import { useDatabaseUIStore } from "./database-ui-store";
import { ChangeOperations } from "./change-handler";
import { useRecordsStore } from "./records-store";
import { FieldType } from "./field-type";

const clipboardFlag = () => {
  const flag = window.AP.flag.create({
    title: "Cell content copied to clipboard",
    type: "info",
  });

  setTimeout(() => {
    flag.close();
  }, 5000);
};

const useKeyboardShortcuts = () => {
  const [clipboard, setClipboard] = useState();

  const { records, fields, addChange, applyChangeInUI, updateField } = useRecordsStore(
    (state) => ({
      fields: state.fields,
      records: state.records,
      addChange: state.addChange,
      updateField: state.updateField,
      applyChangeInUI: state.applyRecordChange,
    }),
    shallow
  );

  const { activeCell, editingCell, setActiveCell } = useDatabaseUIStore(
    (state) => ({
      activeCell: state.activeCell,
      editingCell: state.editingCell,
      setActiveCell: state.setActiveCell,
    }),
    shallow
  );

  const refreshSelectField = (field) => {
    if (["MULTISELECT", "SELECT"].includes(field.type) && field.options) {
      updateField(field.id, {
        options: JSON.parse(JSON.stringify(field.options)),
      });
    }
  };

  const handleKeyboardShortcut = (e) => {
    if (activeCell && !editingCell) {
      const [columnId, rowId] = activeCell;

      const field = fields.find(({ id }) => id === columnId);
      const record = records.find((record) => record.accountId === rowId);
      const value = record.values?.[columnId];

      const change = {
        accountId: rowId,
        operation: ChangeOperations.UPDATE_RECORDS,
        values: { [columnId]: undefined },
      };

      if (e.ctrlKey || e.metaKey) {
        // C
        if (e.keyCode === 67) {
          setClipboard({ field, value, columnId });
          clipboardFlag();
        }

        // X
        if (e.keyCode === 88) {
          change.values[columnId] = field.type.includes("SELECT") ? [] : null;

          addChange(change);
          applyChangeInUI(rowId, change);
          setClipboard({ field, value, columnId });
          refreshSelectField(field);
          clipboardFlag();
        }

        // V
        if (e.keyCode === 86) {
          if (field.type === clipboard?.field?.type) {
            change.values[columnId] = clipboard.value;

            if (!["MULTISELECT", "SELECT"].includes(field.type) || columnId === clipboard.columnId) {
              e.preventDefault();
              e.stopPropagation();
              addChange(change);
              applyChangeInUI(rowId, change);
              refreshSelectField(field);
            }
          }
        }
      } else {
        // Backspace / Delete
        if (e.keyCode === 8 || e.keyCode === 46) {
          change.values[columnId] = field.type.includes("SELECT") ? [] : null;

          if (field.type === FieldType.DATE) {
            change.values[`recurring_${columnId}`] = null;
          }

          addChange(change);
          applyChangeInUI(rowId, change);
          refreshSelectField(field);
        }

        const rowIndex = records.findIndex((record) => record.accountId === rowId);
        const cellIndex = fields.findIndex((field) => field.id === columnId);

        // Arrow up
        if (e.keyCode === 38 && records[rowIndex - 1]) {
          setActiveCell([columnId, records[rowIndex - 1].accountId]);
        }
        // Arrow down
        if (e.keyCode === 40 && records[rowIndex + 1]) {
          setActiveCell([columnId, records[rowIndex + 1].accountId]);
        }
        // Arrow left
        if (e.keyCode === 37 && fields[cellIndex - 1]) {
          if (fields[cellIndex - 1]?.ui?.readonly) {
            return;
          }
          setActiveCell([fields[cellIndex - 1].id, rowId]);
        }
        // Arrow right
        if (e.keyCode === 39 && fields[cellIndex + 1]) {
          if (fields[cellIndex + 1]?.ui?.readonly) {
            return;
          }
          setActiveCell([fields[cellIndex + 1].id, rowId]);
        }
      }
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyboardShortcut);

    return () => {
      document.removeEventListener("keydown", handleKeyboardShortcut);
    };
  });
};

export default useKeyboardShortcuts;
