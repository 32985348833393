import React, { Fragment, useEffect, useRef, useState } from "react";
import { Route, Router, Switch } from "react-router";
import { createBrowserHistory } from "history";
import { initializeAppInConfluenceContext } from "./Confluence/confluence-app-initializer";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import Broadcaster from "./Broadcast/Broadcaster";
import NavigationMenu from "./Components/Navigation/menu/NavigationMenu";
import { QueryClient, QueryClientProvider } from "react-query";
import NoPermissions from "./Views/NoPermissions";
import Hub from "./Components/Hub/Hub";
import EmbedView from "./EmbedDashboardMacro/EmbedView";
import EmbedEditor from "./EmbedDashboardMacro/EmbedEditor";
import InsertDialog from "./EmbedDashboardMacro/InsertDialog";
import { ThemeProvider } from "styled-components";
import { dark } from "./dark-colors";
import { light } from "./light-colors";
import InsertNewsMetaDataDialog from "./NewsMetaDataDialog/InsertNewsMetadataDialog";
import ModalEntry from "./Components/Modal";

library.add(fas, fab);

function getParameterByName(name, url = window.location.href) {
  name = name.replace(/[[\]]/g, "\\$&");
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const routes = {
  embed: {
    View: EmbedView,
    Editor: EmbedEditor,
  },
};

const DEFAULT_COLOR_MODE = "light";

const themes = {
  dark,
  light,
};

function App() {
  const [atlassianConnectLoaded, setAtlassianConnectLoaded] = useState(false);
  const [colorScheme, setColorScheme] = useState(DEFAULT_COLOR_MODE);
  const selectedMacro = useRef("");
  const rootElementRef = useRef(document.documentElement);

  const theme = themes[colorScheme];

  useEffect(() => {
    (async () => {
      try {
        if (window.location.pathname === "/broadcast") {
          setAtlassianConnectLoaded(true);
          const element = document.getElementsByClassName("ac-content");
          element[0].classList.remove("hide");
          return;
        }

        const isAtlassianScriptInitialized = await initializeAppInConfluenceContext();
        const element = document.getElementsByClassName("ac-content");
        element[0].classList.remove("hide");
        setAtlassianConnectLoaded(isAtlassianScriptInitialized);

        selectedMacro.current = getParameterByName("macro");
        const mode = getParameterByName("mode");

        if (mode === "editor") {
          window.AP.dialog
            .create({
              key: "custom-macro-editor",
              width: "100%",
              height: "100%",
              customData: {
                macro: selectedMacro.current,
              },
            })
            .on("close", () => {
              window.AP.confluence.closeMacroEditor();
            });
        }
      } catch (error) {
        console.log("failed to load app.");
      }
    })();
  }, []);

  useEffect(() => {
    window.AP?.theming?.initializeTheming();
  }, [window?.AP]);

  useEffect(() => {
    const rootElement = rootElementRef.current;

    const observer = new MutationObserver((mutationsList) => {
      mutationsList.forEach((mutation) => {
        if (mutation.attributeName === "data-color-mode") {
          const colorMode = rootElement.getAttribute("data-color-mode");
          setColorScheme(colorMode || DEFAULT_COLOR_MODE);
        }
      });
    });

    observer.observe(rootElement, { attributes: true });

    if (!rootElement.hasAttribute("data-color-mode")) {
      setColorScheme(DEFAULT_COLOR_MODE);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  const AppWithRouter = () => {
    return (
      <div style={{ height: "100%" }}>
        <Router history={createBrowserHistory()}>
          <Switch>
            <Route path="/broadcast">
              <Broadcaster />
            </Route>
            <Route path="/fourohfour">
              <NoPermissions />
            </Route>
            <ThemeProvider theme={theme}>
              <QueryClientProvider client={queryClient}>
                <Route path="/cosmos">
                  <Hub />
                </Route>
                <Route path="/cosmos-navigation-panel">
                  <NavigationMenu />
                </Route>
                <Route path="/embed-cosmos">
                  <InsertDialog />
                </Route>
                <Route path="/cosmos-news-metadata">
                  <InsertNewsMetaDataDialog />
                </Route>
                <Route path="/cosmos-news-metadata-editor">
                  <InsertNewsMetaDataDialog />
                </Route>

                <Route path="/caelor-dialog-entry">
                  <ModalEntry />
                </Route>

                {Object.keys(routes).map((macroName) => (
                  <Fragment key={macroName}>
                    <Route path={`/macro/${macroName}`} component={routes[macroName].View} />
                    <Route path={`/macro/editor/${macroName}`} component={routes[macroName].Editor} />
                  </Fragment>
                ))}
              </QueryClientProvider>
            </ThemeProvider>
          </Switch>
        </Router>
      </div>
    );
  };

  if (atlassianConnectLoaded === false) {
    return <div>Loading Content Viz</div>;
  }

  return <AppWithRouter />;
}

export default App;
