export const dark = {
  scrollbar: "#5c5c5c",
  background: "#22272B",
  globalBackground: "#1D2125",
  manualOrgChart: {
    buttonBorderColor: "#579DFF",
    buttonColor: "#22272B",
    buttonTextColor: "#C7D1DB",
    buttonColorHover: "#2c333a",
    buttonColorActive: "#394148",
    iconsColor: "#579DFF",
    minusPlusColor: "#161A1D",
    name: "#C7D1DB",
    info: "#9FADBC",
    boxShadow: "0px 0px 1px 0px rgba(166, 197, 226, 0.16), 0px 1px 3px 0px rgba(166, 197, 226, 0.16)",
  },
  global: {
    name: "dark",
    primaryColor: "#69A6FC",
    background: {
      surface: "#161A1D",
      surfaceOverlay: "#22272B",
    },
    text: { title: "#B6C2CF", blue: "#579DFF", red: "#FD9891" },
    divider: "rgba(166, 197, 226, 0.16)",
    secondary: "#9FADBC",
  },
  shared: {
    subtitle: "#8696A7",
    label: {
      background: "#A1BDD914",
      text: "#C7D1DB",
      filter: "#8696A7",
    },
    form: {
      textarea: {
        background: "#343A43",
        divider: "#646464",
        actions: "#7a869a",
      },
      input: {
        background: "#1D2125",
        border: "#343A43",
        text: "#9FADBC",
        hover: "#343A43",
      },
      colorpicker: {
        background: "#505050",
        border: "#1D2125",
        colorTile: "#1D2125",
        colorBox: "#1D2125",
      },
      imageEditor: {
        cropperBackground: "#161A1D",
        cropperBorder: "#343A43",
      },
      select: {
        background: "#1D2125",
        borderColor: "#738496",
        border: "#343A43",
        text: "#9FADBC",
        hover: "#343A43",
        selected: {
          background: "#22272B",
          secondaryBackground: "#22272B",
          text: "#9FADBC",
          secondaryText: "#9FADBC",
        },
        options: "#1D2125",
        value: "#9FADBC",
        option: "#9FADBC",
        userName: "#9FADBC",
      },
      button: {
        background: { active: "#082145", inactive: "#A1BDD914", hover: "#343A43" },
        text: { active: "#579DFF", inactive: "#C7D1DB" },
      },
      calendar: {
        background: "#22272B",
        border: "#343A43",
        disabled: "#181c1f",
        hover: "#1d2226",
        inputBackground: "#161a1d",
        inputDivider: "#343a43",
        inputWrapperBackground: "#161a1d",
        inputWrapperColor: "#8696A7",
        closeIconColor: "#8696A7",
      },
      datePicker: {
        hover: "#1D2125",
      },
    },
    profile: {
      title: "#C7D1DB",
      subtitle: "#8696A7",
      footer: "#9FADBC",
      text: "#8696A7",
      divider: "#343A43",
      panel: { background: "#22272B", border: "#343A43" },
      field: {
        title: "#C7D1DB",
        value: "#9FADBC",
      },
      socialLinkText: "#9FADBC",
      sectionText: "#C7D1DB",
      selectBackground: "#161A1D",
      reorderablePlaceholderOverBackground: "#22272B",
      reorderablePlaceholderBackground: "#22272B",
      optionMouseOverColor: "#22272B",
      optionMouseOutColor: "inherit",
      editText: "#C7D1DB",
    },
    settings: {
      title: "#C7D1DB",
      description: "#9FADBC",
      divider: "#343A43",
      titleDivider: "#343A43",
      item: { background: "#22272B", border: "#343A43" },
      section: { title: "#C7D1DB", description: "#9FADBC" },
      admins: {
        icon: { background: "#161A1D", hover: "#22272B" },
        user: { title: "#C7D1DB", footer: "#9FADBC" },
        table: { header: "#9FADBC" },
        addBorder: "#343a43",
      },
      database: {
        background: "#161A1D",
        hover: "#22272B",
        border: "#343A43",
        header: "#22272B",
        text: "#8993a4",
        addOptionText: "#8993a4",
        addOptionHover: "#161A1D",
        basicFade: "linear-gradient(to left, #161A1D, transparent)",
        hiddenFade: "linear-gradient(to left, #161A1D, transparent)",
        customFade: "none",
        entraModal: {
          textColor: "#C7D1DB",
          buttonColor: "white",
          gradient: "linear-gradient(to top, #23292df0, #00000000)",
        },
        records: {
          headerName: "#7a869a",
          hiddenRowBackground: "#13171A",
          headerDropdown: { active: "rgb(87, 157, 255)", inactive: "#7a869a" },
          watchIconColor: "#7a869a",
          moreIconColor: "#44546F",
          mainLabelColor: "#7a869a",
          sideLabelColor: "#626f86",
        },
        menuBorder: "#333",
        menuBarIcons: "#8696A7",
        menuBarColor: "#8993a4",
        filtersBackgroundColor: "#2E363C",
        filtersTextColor: "#B6C2CF",
        filtersUserTextColor: "rgb(137, 147, 164)",
      },
      configuration: {
        entries: { text: "#9FADBC", background: { default: "#161A1D", hover: "#161A1D" } },
      },
      general: {
        panelBackground: "#22272B",
        panelHeaderColor: "#C7D1DB",
        panelBorderBottom: "rgba(166, 197, 226, 0.16)",
        admin: {
          labelColor: "#8696A7",
          emptyMessageColor: "#626F86",
          emptyStateTitleColor: "#C7D1DB",
        },
        features: {
          iconBackground: "#5E669515",
          featureNameColor: "#9fadbc",
          featureDescriptionColor: "#626f86",
          featureMenuBackground: "#161a1d",
        },
        brandAndColors: {
          sectionTitleColor: "#9fadbc",
          editLogoBackground: "rgba(161, 189, 217, 0.08)",
          editLogoColor: "#C7D1DB",
          logoNameColor: "#8696a7",
          logoDescriptionColor: "#626f86",
          pageHeaderTextColor: "#8696a7",
          underConstructionTitle: "#C7D1DB",
          underConstructionDescription: "#8696a7",
          underConstructionText: "#626f86",
        },
      },
    },
    sidebar: {
      icons: {
        bg: "#353B41",
        color: "#A0ACBD",
        divider: "rgba(166, 197, 226, 0.16)",
      },
      background: "#22272B",
      title: "#9FADBC",
      divider: "#343A43",
      label: "#9fadbc",
      colorBackground: "#1D2125",
      customColorInput: "#A1BDD914",
      iconColor: "#B3BAC5",
      cancelBackground: "#A1BDD914",
      iconCloseColor: "#97A0AF",
      positioning: {
        title: "#C7D1DB",
        description: "#9FADBC",
        icon: "#97A0AF",
      },
      item: { background: "#22272B", title: "#C7D1DB", hover: "transparent" },
      list: {
        background: "22272B",
        title: "#C7D1DB",
        description: "#9FADBC",
        hover: "transparent",
      },
      tabs: {
        background: { active: "#082145", inactive: "rgba(161, 189, 217, 0.08)" },
        text: { active: "#579DFF", inactive: "#9FADBC", hover: "#c9d9ea" },
        border: { active: "#579DFF", inactive: "#343A43" },
      },
      profileTabs: {
        background: { active: "#082145", inactive: "rgba(161, 189, 217, 0.08)" },
        text: { active: "#579DFF", inactive: "#C7D1DB" },
        border: { active: "transparent", inactive: "transparent" },
        hover: {
          background: "#082145",
          color: "#579DFF",
        },
      },
      button: {
        background: "#2D3339",
        text: "#C7D1DB",
        hover: "#2D3339",
        confirmBackground: "#579DFF",
        confirmText: "#161A1D",
        headerButtonHover: "#161A1D",
        headerButtonActive: "#161A1D",
        headerText: "#9FADBC",
      },
      preview: {
        background: "#22272B",
        title: "#C7D1DB",
        subtitle: "",
        description: "#9FADBC",
        divider: "#343A43",
        border: "#343A43",
        tabs: {
          active: { background: "#09326C", text: "#85B8FF" },
          inactive: { background: "#A1BDD914", text: "#C7D1DB" },
        },
      },
      hub: {
        background: "#22272B",
        icon: { inactive: "#9FADBC", active: "#2F7AFF" },
      },
      colors: {
        label: "#C7D1DB",
        input: "#C7D1DB",
        inputLabel: "#626F86",
      },
    },
    panel: {
      background: "#22272B",
      title: "#C7D1DB",
      hover: "#343A43",
      active: "#253858",
      space: {
        headerTitle: "#9FADBC",
      },
      divider: "#343A43",
    },
    card: {
      title: "#9FADBC",
      titleSecondary: "#9FADBC",
      subtitle: "#8696A7",
      divider: "#343A43",
      footer: "#9FADBC",
      text: "#8696A7",
      creatorLink: "#579DFF",
      background: "#22272B",
      border: {
        avatar: "#22272B",
        box: "#343A43",
      },
    },
    news: {
      primaryButtonColor: "#161A1D",
      title: "#C7D1DB",
      text: "#8696A7",
      background: "#22272B",
      border: "rgba(166, 197, 226, 0.16)",
      shadows: {
        default: "rgb(46 48 51) 0px 0px 1px 0px, rgb(28 29 31) 0px 1px 1px 0px",
        hover: "rgb(58 61 65) 0px 0px 1px 0px, rgb(21 21 22) 0px 8px 16px -6px",
      },
      labelsDefault: "#161A1D",
    },
    emptyState: {
      text: "#c7d1db",
      buttonBackground: "#579dff",
      buttonText: "#161a1d",
    },
  },
  poll: {
    description: "#C7D1DB",
    option: "#C7D1DB",
    icons: "#9FADBC",
    iconsSelected: "#42526E",
    removeVote: {
      background: "#161A1D",
      color: "#ffffff",
    },
  },
  links: {
    background: "#22272B",
    title: "#C7D1DB",
  },
  assignedToMe: {
    task: "#9FADBC",
  },
  notification: {
    title: "#C7D1DB",
    description: "#C7D1DB",
    secondaryTitle: "rgb(29, 33, 37)",
    secondaryTitleInfo: "#e7e7e7",
  },
  newUsers: {
    positionText: "#8696A7",
    dateTodayText: "#579DFF",
    dateText: "#9FADBC",
  },
  emptyState: {
    title: "#C7D1DB",
    text: "#C7D1DB",
    secondaryText: "#8696A7",
    description: "#9FADBC",
    database: {
      buttonBackground: "#597dff",
      buttonColor: "#161a1d",
      title: "#fff",
      description: "#c7d1db",
    },
  },
  videos: {
    title: "#C7D1DB",
  },
  confirmDeleteModal: {
    cancelBackground: "transparent",
    cancelText: "#F87462",
    cancelTextSecundary: "#C7D1DB",
    primaryBackground: "#579DFF",
    primaryColor: "#161A1D",
    modalHeaderText: "#C7D1DB",
    modalBodyText: "#9FADBC",
  },
  apps: {
    seperator: "#343A43",
  },
  sidebar: {
    background: "#22272B",
    seperator: "#343A43",
    searchBackground: "#1D2125",
    inputBackground: "#161a1d",
    mainTab: "#C7D1DB",
    inactiveTab: "#626f86",
    atttachmentsText: "#B6C2CF",
    backgroundTab: "#A1BDD914",
    colorTab: "#9fadbc",
    rangeBorder: "#343A43",
    filterBackground: "#22272B",
    filterSelectBackground: "#161A1D",
    filterLabelColor: "#8993a4",
  },
  collections: {
    searchBackground: "#22272B",
    searchBorder: "#343A43",
    collectionCardTitle: "#8993a4",
    collectionCardBackground: "#22272B",
    collectionCardBorder: "#343A43",
    collectionCardBorderHover: "#343A43",
    createCollectionBackground: "#22272B",
    createCollectionTitle: "#C7D1DB",
    createCollectionSelectBackground: "#161A1D",
    createCollectionSelectColor: "#B6C2CF",
    iconColor: "#6B778C",
    createCollectionSelectBorder: "#343A43",
    createCollectionDescription: "#161A1D",
    createCollectionToggleLabelColor: "#6b778c",
    createCollectionCancel: "#6b778c",
    createCollectionCancelHover: "rgb(22, 26, 29)",
    createCollectionCancelDisabledText: "#6b778c",
    contentWrapperBorderTop: "#343A43",
  },
  space: {
    selectableLabel: "#579DFF",
    fieldLabelColor: "#8696A7",
    listView: {
      headerTitleColor: "#8696A7",
      cardWrapperBorderBottomColor: "#A6C5E229",
      cardWrapperTitle: "#9FADBC",
      cardAvatarShadow: "#22272B",
      resultsColor: "#9FADBC",
    },
    updateSpaceModal: {
      modalBackground: "rgb(40, 46, 51)",
      modalHeaderColor: "#C7D1DB",
      modalLabelColor: "#9FADBC",
    },
  },
  icons: {
    tabs: "#626f86",
    providerPickerBackground: "#22272B",
    providerPickerColor: "#777f8c",
    providerBorder: "#3D454D",
    seperator: "#343A43",
  },
  navigation: {
    previewBackgrond: "#1D2125",
    emojiBackground: "40, 46, 51",
    london: {
      publishedMenuBackground: "#22272B",
      publishedMenuBorder: "rgba(166, 197, 226, 0.16)",
      menuContentBackground: "#1D2125",
      menuContentTitle: "#C7D1DB",
      menuContentSeperator: "rgba(166, 197, 226, 0.16)",
      menuContentEntryHoverBackground: "#161A1D",
    },
    sydney: {
      menuContentBackground: "#1D2125",
      rootShortDescription: "#8696A7",
      childEntryBackground: "#22272B",
      iconBackground: "rgba(38,132,255,0.1)",
      childEntryBackgroundHover: "#161A1D",
      childEntryBackgroundHoverBorder: "rgba(166, 197, 226, 0.16)",
    },
    denver: {
      menuContentBackground: "#1D2125",
      childEntryBorder: "transparent",
      childEntryBackgroundHover: "#161A1D",
      childEntryBackgroundHoverBorder: "rgba(166, 197, 226, 0.16)",
    },
    paloAlto: {
      menuContentBackground: "#1D2125",
      childEntryBorder: "rgba(166, 197, 226, 0.16)",
      childEntryBackgroundHover: "#161A1D",
      childEntryBackgroundHoverBorder: "rgba(166, 197, 226, 0.16)",
    },
    houston: {
      menuContentBackground: "#1D2125",
      childEntryBorder: "rgba(166, 197, 226, 0.16)",
    },
    settings: {
      menuEntryBackgroundColor: "#22272B",
      subMenuEntryBackgroundColor: "#1D2125",
      menuEntryColor: "#6b778c",
      menuEntryHoverBackgroundColor: "rgb(52, 58, 67)",
      menuEntryDivider: "rgb(52, 58, 67)",
      entryIcon: "#97a0af",
      addMainEntryButtonBackground: "#0747A6",
      addMainEntryButtonBackgroundHover: "#0065ff",
      addMainEntryButtonColor: "#FFFFFF !important",
      entryIconColor: "#97a0af",
      entryIconOpacity: "0.9",
      dropTargetBackground: "#343A43",
      entryAndSubEntryInputBackground: "#161A1D",
      entryAndSubEntryInputBorder: "#343A43",
      entryAndSubEntryDrawerTitle: "#9FADBC",
      iconBoxBackground: "#161A1D",
      iconBoxBorder: "#343A43",
      addLinkBackground: "#161A1D",
      customInputBackground: "#161A1D",
      linkEntryWrapperBorderBottom: "#343A43",
      linkEntryWrapperBorderTop: "#343A43",
      linkValue: "#6b778c",
      typeBackground: "#A1BDD914",
      typeColor: "#C7D1DB",
      listItemBackgroundHover: "#161A1D",
      premissionAsyncSelectBackground: "#161A1D",
      premissionViewPremissionselectBackground: "#161A1D",
      sidebarSectionDescriptionColor: "#343A43",
      sidebarMenuSelectButton: "#9FADBC",
      sidebarMenuSelectButtonHover: "#161A1D",
      savedBarUnsavedChangesText: "rgb(137, 147, 164)",
      navigationEmptyStateTitleColor: "#C7D1DB",
      select: {
        background: "#1D2125",
        border: "#343A43",
        borderFocused: "#A1BDD914",
        color: "#6b778c",
        text: "#9FADBC",
        hover: "#343A43",
        selected: { background: "#22272B", text: "#9FADBC" },
        options: "#1D2125",
        value: "#9FADBC",
        option: "#9FADBC",
      },
      linkSidebar: {
        tabColor: "#579DFF",
        searchPagesBackground: "#161A1D",
        searchPagesBorder: "#343A43",
        listItemBorder: "#343A43",
        headerLabel: "#6b778c",
        listTitle: "rgb(87, 157, 255)",
        listSpace: "#3071cf",
        seperator: "#343A43",
        linkButton: "#69A6FC",
        linkWrapperHover: "RGBA(22, 26, 29, 0.5)",
        emailTitle: "#C7D1DB",
        emailDescription: "#6b778c",
      },
      navigationPreview: {
        pseudoTopBarBackground: "#1D2125",
        appSwitcherIcon: "#9FADBC",
        pseudoTopBarEntry: "#6b778c",
      },
    },
  },
  spaceport: {
    title: "#8696A7",
    linkLabel: "#8696A7",
    divider: "#343A43",
    appContainerBorder: "#343A43",
    appTitle: "#8696A7",
    newsContainerBorder: "#343A43",
    newsTitle: "#8696A7",
    reviewBorder: "#343A43",
    reviewTitle: "#8696A7",
  },
  orgCharts: {
    tempTitle: "#C7D1DB",
  },
  newsMetadataDialog: {
    background: "rgba(40, 46, 51, 1)",
    title: "rgba(182, 194, 207, 1)",
    tab: "rgba(159, 173, 188, 1)",
    tabActive: "rgba(87, 157, 255, 1)",
    coverImage: "rgba(182, 194, 207, 1)",
    imageDescription: "rgba(140, 155, 171, 1)",
    coverButtonText: "rgba(29, 33, 37, 1)",
    covertButton: "rgba(87, 157, 255, 1)",
    categoryText: "rgba(140, 155, 171, 1)",
    categoryField: "rgba(115, 132, 150, 1)",
  },
  newsMetadataSettings: {
    title: "rgba(182, 194, 207, 1)",
    description: "rgba(140, 155, 171, 1)",
    categoryCard: "rgba(40, 46, 51, 1)",
    categoryCardBorder: "rgba(166, 197, 226, 0.16)",
    categoryCardHover: "rgba(44, 51, 58, 1)",
    categoryIcon: "rgba(28, 43, 65, 1)",
    blogIcon: "rgba(87, 157, 255, 1)",
  },
  installationBanners: {
    optics: {
      background: "#22272B",
      text: "#B6C2CF",
      highlightedText: "#579DFF",
      buttonBackground: "#579DFF",
      buttonText: "#161A1D",
    },
  },
};
