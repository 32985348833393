import styled, { useTheme } from "styled-components";
import Button from "@atlaskit/button";
import EmptyState from "../../../../../../../Shared/Components/EmptyState";
import { Panel, PanelContent, PanelTitle } from "../../../dashboard-styled";
import { usePageStore } from "../../../../../page-store";
import { Pages } from "../../../../../pages";
import NoMatchingNewsSVG from "../../images/NoMatchingNewsSVG";
import { THEME_NAME_DARK, THEME_NAME_LIGHT } from "../../../../../../Navigation/functions/generateNewEntry";

const Container = styled.div`
  display: grid;
  place-content: center;
  text-align: center;
  row-gap: 10px;
  margin-top: 16px;
  margin-bottom: 16px;

  #get-started-button {
    width: fit-content !important;
    margin: 0 auto;
  }
`;

const CenteredImage = styled.img`
  margin: 0 auto;
`;

const Title = styled.div`
  font-weight: 590;
  font-size: 18px;
  line-height: 21px;
  color: ${({ theme }) => theme.emptyState.title};
  margin-top: 10px;
`;

const Description = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: ${({ theme }) => theme.emptyState.text};
  margin-bottom: 20px;
`;

const setCustomStyles = (previewTheme, customStyles) => {
  if (previewTheme === THEME_NAME_DARK) {
    customStyles.customTitleStyles = { color: "#C7D1DB" };
    customStyles.customDescriptionStyles = { color: "#8696A7" };
  } else if (previewTheme === THEME_NAME_LIGHT) {
    customStyles.customTitleStyles = { color: "#07145a" };
    customStyles.customDescriptionStyles = { color: "#8696A7" };
  }
  return customStyles;
};

export const EmptyManual = ({
  panelTitle,
  returnWithPanel = true,
  customStyles = {},
  isInNavigation = false,
  previewTheme,
  isInPreview,
}) => {
  if (isInPreview) {
    customStyles = setCustomStyles(previewTheme, customStyles);
  }

  if (!returnWithPanel) {
    return (
      <EmptyState
        title="Add your news"
        description="Try selecting some news in the editor."
        Image={() => {
          return (
            <NoMatchingNewsSVG isInNavigation={isInNavigation} previewTheme={previewTheme} isInPreview={isInPreview} />
          );
        }}
        customStyles={customStyles}
      />
    );
  }

  return (
    <Panel>
      <PanelTitle>{panelTitle}</PanelTitle>
      <PanelContent>
        <EmptyState
          title="Add your news"
          description="Try selecting some news in the editor."
          Image={() => {
            return (
              <NoMatchingNewsSVG
                isInNavigation={isInNavigation}
                previewTheme={previewTheme}
                isInPreview={isInPreview}
              />
            );
          }}
        />
      </PanelContent>
    </Panel>
  );
};

export const EmptyForFilters = ({
  panelTitle,
  returnWithPanel = true,
  customStyles = {},
  isInNavigation = false,
  previewTheme,
  isInPreview,
}) => {
  if (isInPreview) {
    customStyles = setCustomStyles(previewTheme, customStyles);
  }

  if (!returnWithPanel) {
    return (
      <EmptyState
        title="We couldn't find anything matching filters"
        description="Try clearing some filters."
        Image={() => {
          return (
            <NoMatchingNewsSVG isInNavigation={isInNavigation} previewTheme={previewTheme} isInPreview={isInPreview} />
          );
        }}
        customStyles={customStyles}
      />
    );
  }

  return (
    <Panel>
      <PanelTitle>{panelTitle}</PanelTitle>
      <PanelContent>
        <EmptyState
          title="We couldn't find anything matching filters"
          description="Try clearing some filters."
          Image={() => {
            return (
              <NoMatchingNewsSVG
                isInNavigation={isInNavigation}
                previewTheme={previewTheme}
                isInPreview={isInPreview}
              />
            );
          }}
        />
      </PanelContent>
    </Panel>
  );
};

export const EmptyNoNews = ({
  panelTitle,
  returnWithPanel = true,
  customStyles = {},
  isInNavigation = false,
  previewTheme,
  isInPreview,
}) => {
  if (isInPreview) {
    customStyles = setCustomStyles(previewTheme, customStyles);
  }

  if (!returnWithPanel) {
    return (
      <EmptyState
        title="Set up your news"
        description="There are currently no blogs on your instance. Create a blog to launch your Newsroom."
        Image={() => {
          return (
            <NoMatchingNewsSVG isInNavigation={isInNavigation} previewTheme={previewTheme} isInPreview={isInPreview} />
          );
        }}
        customStyles={customStyles}
      />
    );
  }

  return (
    <Panel>
      <PanelTitle>{panelTitle}</PanelTitle>
      <PanelContent>
        <EmptyState
          title="Set up your news"
          description="There are currently no blogs on your instance. Create a blog to launch your Newsroom."
          Image={() => {
            return (
              <NoMatchingNewsSVG
                isInNavigation={isInNavigation}
                previewTheme={previewTheme}
                isInPreview={isInPreview}
              />
            );
          }}
        />
      </PanelContent>
    </Panel>
  );
};

export const PersonalizedNewsEmptyState = ({ customOnClick }) => {
  const setSelectedPage = usePageStore((state) => state.setSelectedPage);
  const theme = useTheme();

  return (
    <Container>
      <CenteredImage src={`/images/news/empty-blog-${theme.global.name}.svg`} />
      <Title>Customize Your News Feed</Title>
      <Description>Subscribe to categories and spaces to receive tailored updates.</Description>

      <Button
        id="get-started-button"
        appearance="primary"
        onClick={() => (customOnClick ? customOnClick() : setSelectedPage(Pages.NEWS))}
      >
        Get Started
      </Button>
    </Container>
  );
};
