import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ReadOnlyButton from "./actions/ReadOnlyButton";

const LevelsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 16px;

  position: absolute;
  z-index: 5;
  top: ${({ isShowingControls }) => (isShowingControls ? "80px" : "24px")};
  right: 24px;
`;

const buildLevelsFromStructure = (nodes, levels = []) => {
  for (let index = 0; index < nodes.length; index++) {
    if (!levels.includes(nodes[index].depth)) {
      levels.push(nodes[index].depth);
    }

    if (nodes[index]?.children?.length) {
      buildLevelsFromStructure(nodes[index].children, levels);
    }
  }

  return levels;
};

function LevelsToolbar({ structure, selectedLevel, setSelectedLevel, isShowingControls }) {
  const [levels, setLevels] = useState([]);

  useEffect(() => {
    if (structure?.children?.length) {
      const foundLevels = buildLevelsFromStructure(structure.children, []);
      setLevels([...foundLevels]);
    }
  }, [structure]);

  return (
    <LevelsWrapper isShowingControls={isShowingControls}>
      {levels.map((level) => (
        <ReadOnlyButton
          key={level}
          text={`L${level}`}
          isGrayed={selectedLevel === level}
          dimensions={{ width: "40px", height: "32px" }}
          onClick={() => {
            setSelectedLevel(selectedLevel === level ? undefined : level);
          }}
        />
      ))}
    </LevelsWrapper>
  );
}

export default LevelsToolbar;
