import { useQuery } from "react-query";
import { fetchIsInitialized } from "../../Components/UserDatabase/datagrid/api";

export const useDatabaseInitialization = () => {
  const { isLoading: isDatabaseLoading, data: isDatabaseInitialized } = useQuery(
    "database-is-initialized",
    fetchIsInitialized,
    {
      retry: 0,
      select: (response) => response.data,
    },
  );

  return { isDatabaseLoading, isDatabaseInitialized };
};
