import React from "react";
import styled, { useTheme } from "styled-components";
import AppSwitcherIcon from "@atlaskit/icon/glyph/app-switcher";

import { dark } from "../../../../dark-colors";
import { light } from "../../../../light-colors";
import { DEFAULT_NAVIGATION_PREVIEW_ITEMS } from "../../functions/generateNewEntry";
import ColorSchemeToggler from "./ColorSchemeToggler";

const themes = {
  dark,
  light,
};

const ConfluenceIcon = () => (
  <img width="24" height="24" src="/images/confluence_icon.png" alt="Atlassian Confluence" />
);

const PseudoTopBar = styled.div`
  display: flex;
  box-sizing: border-box;
  height: 56px;
  padding-right: 12px;
  padding-left: 20px;
  position: relative;
  flex-shrink: 0;
  background-color: ${({ backgroundColor }) => backgroundColor};

  &:after {
    height: 4px;
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    background: linear-gradient(
      rgba(9, 30, 66, 0.13) 0px,
      rgba(9, 30, 66, 0.13) 1px,
      rgba(9, 30, 66, 0.08) 1px,
      rgba(9, 30, 66, 0) 4px
    );
    content: "";
  }
`;

const PseudoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.shared.sidebar.divider};
  box-sizing: border-box;
  background: rgba(162, 162, 162, 0.1);
`;

const PseudoTopBarEntry = styled.div`
  color: ${({ color }) => color};
  font-weight: 500;
  font-size: 16px;
  background: ${({ color }) => color};
  line-height: 14px;
  margin-left: 20px;
  border-radius: 12px;
  overflow: hidden;
`;

function ConfluenceBar({ temporaryColorScheme, setTemporaryColorScheme }) {
  const theme = useTheme();

  return (
    <PseudoWrapper>
      <PseudoTopBar
        backgroundColor={themes[temporaryColorScheme].navigation.settings.navigationPreview.pseudoTopBarBackground}
        style={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                display: "flex",
                marginRight: "5px",
                alignItems: "center",
                color: theme.navigation.settings.navigationPreview.appSwitcherIcon,
                minWidth: "24px",
              }}
            >
              <AppSwitcherIcon />
            </div>
            <ConfluenceIcon
              style={{
                minWidth: "24px",
                minHeight: "24px",
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: "20px",
            }}
          >
            {DEFAULT_NAVIGATION_PREVIEW_ITEMS.map((item, index) => (
              <PseudoTopBarEntry
                key={index}
                color={themes[temporaryColorScheme].navigation.settings.navigationPreview.pseudoTopBarEntry}
                style={index === 0 ? { margin: "0 15px 0 0", lineHeight: "18px" } : {}}
              >
                {item}
              </PseudoTopBarEntry>
            ))}
          </div>
        </div>

        <ColorSchemeToggler
          setTemporaryColorScheme={setTemporaryColorScheme}
          temporaryColorScheme={temporaryColorScheme}
        />
      </PseudoTopBar>
      {/* <div style={{ display: "flex", flex: 1 }}>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            className={shouldPulse ? "pulse" : ""}
            style={{
              borderBottom: `1px solid ${theme.navigation.london.publishedMenuBorder}`,
              width: "100%",
              height: "fit-content",
            }}
          >
            <CardMenu
              config={{}}
              menuStyle={menuStyle}
              colors={isMacroSuiteInstalled ? colors : NAVIGATION_DEFAULT_COLOR_SCHEME}
              structure={possibleNewStructure ?? []}
              temporaryColorScheme={temporaryColorScheme}
              isPreview
              themeColorsSet={themeColorsSet}
            />
          </div>
        </div>
      </div> */}
    </PseudoWrapper>
  );
}

export default ConfluenceBar;
