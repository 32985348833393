import React, { useCallback } from "react";
import ConfigProvider from "./ConfigProvider";
import { usePanelsStore } from "../../../../store/panels-store";
import { useChangesStore } from "../../../../../../track-changes-store";

import { PANEL_VERSION } from "../../../panelTypes";

function EditPanel() {
  const { panels, setPanels, editingPanel, setEditingPanel } = usePanelsStore((state) => ({
    panels: state.panels,
    setPanels: state.setPanels,
    editingPanel: state.editingPanel,
    setEditingPanel: state.setEditingPanel,
  }));

  const setHasChanges = useChangesStore((state) => state.setHasChanges);

  const updateContentState = useCallback(() => {
    setHasChanges(true);
  }, [setHasChanges]);

  const updatePanelDataProperty = useCallback(
    (panelId, panelProperty, newValue, ignoreDummy) => {
      const editedPanel = {
        ...editingPanel,
        data: {
          ...editingPanel.data,
          isDummy: ignoreDummy ? editingPanel.data.isDummy : false,
          [panelProperty]: newValue,
        },
        version: PANEL_VERSION,
      };

      const newPanels = panels.map((panel) => {
        return panel.id === panelId ? { ...editedPanel } : { ...panel };
      });

      setEditingPanel({ ...editedPanel });
      setPanels([...newPanels]);
      updateContentState();
    },
    [editingPanel, panels, setEditingPanel, setPanels, updateContentState],
  );

  const updatePanelTitle = (panelId, value) => {
    const newPanels = panels.map((panel) => {
      return panel.id === panelId ? { ...panel, panelTitle: value } : { ...panel };
    });
    const selectedPanel = newPanels.find((p) => p.id === panelId);

    setEditingPanel({ ...selectedPanel });
    setPanels([...newPanels]);
    updateContentState();
  };

  return (
    <div style={{ marginTop: "20px", height: "calc(100% - 20px)" }}>
      <ConfigProvider
        selectedPanel={editingPanel}
        updatePanelDataProperty={updatePanelDataProperty}
        updatePanelTitle={updatePanelTitle}
      />
    </div>
  );
}

export default EditPanel;
