import React from "react";
import styled from "styled-components";

const Container = styled.div`
  position: absolute;
  pointer-events: none;
  height: calc(100vh - 234px);
  margin-top: 39px;
  color: #fff;
`;

const Skeleton = ({ children }) => {
  return <Container>{[...Array(30).keys()].map(() => children)}</Container>;
};

export default Skeleton;
