export const COSMOS_APP_KEY = {
  NEWS: "news",
  BRAND: "brand",
  PEOPLE: "people",
  NOTIFICATION: "notification",
  COLOR: "color",
  DATABASE_FIELD: "databaseField",
  PRIVATE_SPACES: "privateSpaces",
  SOURCE: "source",
  SPACES: "spaces",
  CATEGORIES: "categories",
  UNDER_CONSTRUCTION: "underConstruction",
  TITLE: "title",
  TEXT: "text",
  LINK: "link",
  LABEL: "label",
  PATH: "path",
  ENABLED: "enabled",
  MAINTENANCE: "maintenance",
  BUTTON: "button",
  PUBLISH_UNPUBLISH: "publishUnpublish",
  PUBLISHED: "published",
  NEWS_NAVIGATION: "newsNavigation",
  DATABASE_SYNC: "databaseSync",
  KEY_PROFILE_DETAILS: "keyProfileDetails",
  PEOPLE_WIDGETS: "peopleWidgets",
  BRAND_COLOR: "brandColor",
};

export const CONFIGURATION_DATA = [
  {
    id: 1,
    label: "Database Sync",
    value: COSMOS_APP_KEY.DATABASE_SYNC,
    enabled: false,
    sectionKey: "people_base",
    sectionId: "database",
  },
  {
    id: 2,
    label: "Key Profile Details",
    value: COSMOS_APP_KEY.KEY_PROFILE_DETAILS,
    enabled: false,
    sectionKey: "cosmos_app",
    sectionId: "people",
  },
  {
    id: 3,
    label: "People Widgets",
    value: COSMOS_APP_KEY.PEOPLE_WIDGETS,
    enabled: false,
    sectionKey: "people_base",
    sectionId: "widgets",
  },
  {
    id: 4,
    label: "News Navigation",
    value: COSMOS_APP_KEY.NEWS_NAVIGATION,
    enabled: false,
    sectionKey: "cosmos_app",
    sectionId: "news",
  },
  {
    id: 5,
    label: "Brand Color",
    value: COSMOS_APP_KEY.BRAND_COLOR,
    enabled: false,
    sectionKey: "cosmos_app",
    sectionId: "brand",
  },
];

export const COSMOS_APP_DEFAULT_VALUE = {
  [COSMOS_APP_KEY.PRIVATE_SPACES]: false,
  [COSMOS_APP_KEY.SOURCE]: COSMOS_APP_KEY.CATEGORIES,
  [COSMOS_APP_KEY.DATABASE_FIELD]: "ymbx9Wh",
  [COSMOS_APP_KEY.COLOR]: "#007AFF",
};

export const COSMOS_APP_DEFAULT_DATA = {
  [COSMOS_APP_KEY.NEWS]: {
    [COSMOS_APP_KEY.PRIVATE_SPACES]: COSMOS_APP_DEFAULT_VALUE[COSMOS_APP_KEY.PRIVATE_SPACES],
    [COSMOS_APP_KEY.SOURCE]: COSMOS_APP_DEFAULT_VALUE[COSMOS_APP_KEY.SOURCE],
  },
  [COSMOS_APP_KEY.PEOPLE]: {
    [COSMOS_APP_KEY.DATABASE_FIELD]: COSMOS_APP_DEFAULT_VALUE[COSMOS_APP_KEY.DATABASE_FIELD],
  },
  [COSMOS_APP_KEY.BRAND]: { [COSMOS_APP_KEY.COLOR]: COSMOS_APP_DEFAULT_VALUE[COSMOS_APP_KEY.COLOR] },
  [COSMOS_APP_KEY.UNDER_CONSTRUCTION]: {
    [COSMOS_APP_KEY.ENABLED]: false,
    [COSMOS_APP_KEY.TITLE]: "Under Construction",
    [COSMOS_APP_KEY.TEXT]: "The Cosmos mobile experience is coming soon. Stay tuned for updates.",
    [COSMOS_APP_KEY.BUTTON]: {
      [COSMOS_APP_KEY.LABEL]: "Read more",
      [COSMOS_APP_KEY.PATH]: "",
    },
  },
  [COSMOS_APP_KEY.MAINTENANCE]: {
    [COSMOS_APP_KEY.ENABLED]: false,
    [COSMOS_APP_KEY.TITLE]: "Maintenance Mode",
    [COSMOS_APP_KEY.TEXT]: "The app is temporarily unavailable due to maintenance. ",
    [COSMOS_APP_KEY.BUTTON]: {
      [COSMOS_APP_KEY.LABEL]: "Read more",
      [COSMOS_APP_KEY.PATH]: "",
    },
  },
  [COSMOS_APP_KEY.PUBLISH_UNPUBLISH]: {
    [COSMOS_APP_KEY.PUBLISHED]: false,
  },
};
