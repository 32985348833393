import dayjs from "dayjs";

export const handleDataForActiveUsers = (results = []) => {
  if (!results?.length) return [];

  const formattedPageData = results.map((page) => {
    const plainLastModified = page?.lastModified;
    const plainCreatedAt = page?.content?.history?.createdDate;

    const lastModified = dayjs(plainLastModified).set("second", 0);
    const createdAt = dayjs(plainCreatedAt).set("second", 0);
    const status = createdAt.diff(lastModified, "minute") >= 0 ? "Created" : "Updated";

    const plainUserData = status === "Created" ? page.content.history.createdBy : page.content.history.lastUpdated.by;

    const userData = {
      accountId: plainUserData.accountId,
      name: plainUserData.displayName,
      profilePicture: `${window.AP._hostOrigin}${plainUserData.profilePicture.path}`,
    };

    return {
      id: page.content.id,
      friendlyModified:
        page.friendlyLastModified.includes("ago") || page.friendlyLastModified.includes("yesterday")
          ? page.friendlyLastModified
          : dayjs(page.friendlyLastModified).format("MMM D, YYYY"),
      status,
      type: page.content.type === "page" ? "page" : "blog",
      userData,
      linkToPage: `${window.AP._hostOrigin}/wiki${page.content._links.tinyui}`,
    };
  });

  if (formattedPageData?.length) {
    return formattedPageData.filter(
      (pageData, index) =>
        formattedPageData.findIndex((item) => item.userData.accountId === pageData.userData.accountId) === index
    );
  }

  return [];
};
