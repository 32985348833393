import React, { useState, useEffect, useRef } from "react";
import styled, { useTheme } from "styled-components";
import PropTypes from "prop-types";
import { SketchPicker } from "react-color";
import { colorAccordingToRGBA } from "../Functions/color-according-to-hex";

const DEFAULT_COLORS = [
  "#0052CC",
  "#172b4d",
  "#00a2e0",
  "#00badd",
  "#00b779",
  "#ffc300",
  "#FF8B00",
  "#ff4310",
  "#6a4ec7",
  "#DFE1E6",
  "#ffffff",
];

export const useClickOutside = (ref, handler) => {
  useEffect(() => {
    const eventListener = (event) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };

    document.addEventListener("mousedown", eventListener);
    return () => {
      document.removeEventListener("mousedown", eventListener);
    };
  }, [ref, handler]);
};

const PickerContainer = styled.div`
  cursor: default;
  position: absolute;
  z-index: 9999;
  box-shadow: 0px 0px 6px -3px;

  [title="transparent"] {
    visibility: ${({ disableTransparent }) => (disableTransparent ? "hidden" : "visible")};

    div {
      &::before {
        content: "/";
        display: grid;
        place-content: center;
        color: red;
        height: 12px;
        font-size: 17px;
        padding-left: 1px;
        transform: rotateZ(15deg);
      }
    }
  }
`;

function CustomSketchPicker({
  selectedColor,
  setSelectedColor,
  callback,
  additionalStyle,
  presetColors,
  disableAlpha,
  disableTransparent = false,
  setInputValues = () => {},
}) {
  const [pickerColor, setPickerColor] = useState(selectedColor);

  const pickerContainerRef = useRef(null);

  useClickOutside(pickerContainerRef, () => callback());

  const theme = useTheme();

  return (
    <PickerContainer
      id="sketch-picker-custom-color"
      ref={pickerContainerRef}
      disableTransparent={disableTransparent}
      style={{
        background: "yellow !important",
        boxShadow: "none",
        ...additionalStyle,
      }}
    >
      <style>
        {`
          #sketch-picker-custom-color label {
            color:${theme.manualOrgChart.name} !important; 
          }
        `}
      </style>
      <SketchPicker
        styles={{
          default: {
            picker: {
              backgroundColor: theme.shared.sidebar.background,
              border: `1px solid ${theme.shared.sidebar.divider}`,
              boxShadow: theme.manualOrgChart.boxShadow,
            },
          },
        }}
        color={pickerColor}
        onChange={(clr) => setPickerColor(clr.rgb)}
        onChangeComplete={(clr) => {
          const {
            rgb: { r, g, b, a },
          } = clr;
          const hexValue = colorAccordingToRGBA(r, g, b, a);
          setSelectedColor(hexValue);
          setInputValues(hexValue);
        }}
        presetColors={presetColors}
        disableAlpha={disableAlpha}
      />
    </PickerContainer>
  );
}

CustomSketchPicker.propTypes = {
  selectedColor: PropTypes.string.isRequired,
  setSelectedColor: PropTypes.func.isRequired,
  callback: PropTypes.func,
  additionalStyle: PropTypes.object,
  presetColors: PropTypes.arrayOf(PropTypes.string),
  disableAlpha: PropTypes.bool,
  disableTransparent: PropTypes.bool,
};

CustomSketchPicker.defaultProps = {
  callback: () => {},
  additionalStyle: {},
  presetColors: DEFAULT_COLORS,
  disableAlpha: false,
  disableTransparent: false,
};

export default CustomSketchPicker;
