import React from "react";
import styled from "styled-components";

const SaveButton = styled.div`
  width: 48px;
  height: 48px;
  background: ${({ primaryColor }) => primaryColor};
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
  border-radius: 3px;

  position: fixed;
  bottom: 118px;
  right: 48px;
  z-index: 89;

  display: grid;
  place-content: center;
  cursor: pointer;
  ${({ isDisabled }) => {
    if (isDisabled) {
      return `
        pointer-events: none;
      `;
    }
  }}
  @media print {
    display: none !important;
  }

  &:hover {
    opacity: 0.9;
  }
`;

export const SaveIcon = ({ color }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.8667 3.60889C15.7778 3.39556 15.6533 3.20889 15.4933 3.04L12.96 0.506667C12.8 0.346667 12.6044 0.213333 12.3911 0.133333C12.1778 0.0444444 11.9467 0 11.7156 0H1.77778C1.28889 0 0.871111 0.177778 0.524444 0.524444C0.177778 0.871111 0 1.28889 0 1.77778V14.2222C0 14.7111 0.177778 15.1289 0.524444 15.4756C0.871111 15.8222 1.28889 16 1.77778 16H14.2222C14.7111 16 15.1289 15.8222 15.4756 15.4756C15.8222 15.1289 16 14.7111 16 14.2222V4.29333C16 4.05333 15.9556 3.83111 15.8667 3.61778V3.60889ZM9.88444 12.5511C9.36889 13.0667 8.73778 13.3333 8 13.3333C7.26222 13.3333 6.63111 13.0756 6.11556 12.5511C5.6 12.0267 5.33333 11.4044 5.33333 10.6667C5.33333 9.92889 5.59111 9.29778 6.11556 8.78222C6.64 8.26667 7.26222 8 8 8C8.73778 8 9.36889 8.25778 9.88444 8.78222C10.4 9.29778 10.6667 9.92889 10.6667 10.6667C10.6667 11.4044 10.4089 12.0356 9.88444 12.5511ZM11.9467 3.49333C11.9467 3.74222 11.8578 3.95556 11.6889 4.12444C11.52 4.29333 11.3067 4.38222 11.0578 4.38222H4.83556C4.58667 4.38222 4.37333 4.29333 4.20444 4.12444C4.03556 3.95556 3.94667 3.74222 3.94667 3.49333V1.71556C3.94667 1.46667 4.03556 1.25333 4.20444 1.08444C4.37333 0.915556 4.58667 0.826667 4.83556 0.826667H11.0578C11.3067 0.826667 11.52 0.915556 11.6889 1.08444C11.8578 1.25333 11.9467 1.46667 11.9467 1.71556V3.49333Z"
      fill={color}
    />
  </svg>
);

const SaveChangesButton = ({ onClick, primaryColor = "#0065ff", primaryContrastColor = "#fff", isDisabled }) => {
  return (
    <SaveButton primaryColor={primaryColor} onClick={onClick} isDisabled={isDisabled}>
      <SaveIcon color={primaryContrastColor} />
    </SaveButton>
  );
};

export default SaveChangesButton;
