import React from "react";
import styled from "styled-components";
import { MediumLine, Panel, Wrapper } from "./styled";

const Content = styled.div`
  padding: 4px 0px;
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 38px auto;
  align-items: center;
  column-gap: 15px;
`;

const Text = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #42526e;
`;

function ImportantSpaces() {
  return (
    <Wrapper>
      <Panel>
        <Content>
          <Container>
            <img src="/images/hub/dashboard/spaces/notes.png" alt="" />
            <Text>Marketing</Text>
          </Container>

          <Container style={{ marginTop: "24px" }}>
            <img src="/images/hub/dashboard/spaces/flag.png" alt="" />
            <MediumLine bg="#DFE1E6" width={76} />
          </Container>

          <Container style={{ marginTop: "24px" }}>
            <img src="/images/hub/dashboard/spaces/alien.png" alt="" />
            <MediumLine bg="#DFE1E6" width={61} />
          </Container>

          <Container style={{ marginTop: "24px" }}>
            <img src="/images/hub/dashboard/spaces/mountains.png" alt="" />
            <MediumLine bg="#DFE1E6" width={74} />
          </Container>
        </Content>
      </Panel>
    </Wrapper>
  );
}

export default ImportantSpaces;
