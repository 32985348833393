import React from "react";
import Drawer from "@atlaskit/drawer";
import styled from "styled-components";
import LinkSidebar from "./LinkSidebar/LinkSidebar";
import LinkTypes from "./LinkSidebar/LinkTypes";

const NoSidebar = styled.div`
  width: 0;
`;

const DrawerWrapper = styled.div`
  width: 357px;
  padding: 25px 18px 25px 32px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

const LinkDrawer = ({ value, visible, onSelected, onClose, close }) => (
  <Drawer
    onClose={onClose}
    isOpen={visible}
    width="medium"
    overrides={{
      Sidebar: {
        component: () => <NoSidebar />,
      },
      Content: {
        component: () => (
          <DrawerWrapper>
            <LinkSidebar
              value={value}
              onClose={onClose}
              selectedTab={LinkTypes.PAGES}
              onSelected={(selected) => {
                close();
                onSelected(selected);
              }}
              showAttachments={false}
            />
          </DrawerWrapper>
        ),
      },
    }}
  />
);

export default LinkDrawer;
