import { applyChanges } from "../../../UserDatabase/datagrid/api";
import {
  IMAGE_TYPES,
  getCssForBackground,
  getRandomUserBackground,
} from "../../../../Shared/Functions/user-bg-provider";
import { emailService } from "../../../../Service/EmailService";

const handleConfluenceResponse = (records, emails) => {
  if (!records.length) return [];

  try {
    const builtResponse = records.map((record) => ({
      name: record.displayName,
      icon: record.profilePicture,
      accountId: record.accountId,
    }));

    if (emails?.length) {
      return builtResponse.map((user) => {
        const foundEmail = emails.find((userEmailInfo) => userEmailInfo.accountId === user.accountId);
        return foundEmail ? { ...user, email: foundEmail.email } : user;
      });
    }

    return builtResponse;
  } catch (error) {
    return [];
  }
};

export const buildAndGetUserBackground = (userRecordData) => {
  const backgroundImage = userRecordData?.values?.user?.backgroundImage;
  const hasBgData = !!backgroundImage && !!Object.keys(backgroundImage || {}).length;
  const correctImage = hasBgData ? backgroundImage : getRandomUserBackground(IMAGE_TYPES.GRADIENT);

  let change = undefined;

  if (!hasBgData) {
    const newUserValues = {
      ...userRecordData.values.user,
      backgroundImage: correctImage,
    };

    change = {
      [userRecordData.accountId]: {
        accountId: userRecordData.accountId,
        values: {
          ...userRecordData.values,
          user: {
            ...newUserValues,
          },
        },
      },
    };
  }

  const image = getCssForBackground(correctImage);
  return {
    image,
    change,
  };
};

export const handleRetrievedData = async (records) => {
  if (!records?.length) return [];

  const usersToFetch = records.map((userRecord) => userRecord.accountId);

  let emailResponse = [];
  try {
    const response = await emailService.getUsersEmailBulk(usersToFetch);
    emailResponse = !!response?.data?.length ? response.data : [];
  } catch (error) {
    emailResponse = [];
  }

  const builtResponse = handleConfluenceResponse(records, emailResponse);

  if (!builtResponse?.length) return [];

  let changes = {};

  const mergedData = records.map((userRecord) => {
    const confUserData = builtResponse.find((user) => user.accountId === userRecord.accountId);

    const { image, change } = buildAndGetUserBackground(userRecord);

    if (change) {
      changes["UPDATE_RECORDS"] = { ...changes["UPDATE_RECORDS"], ...change };
    }

    return {
      ...confUserData,
      image,
      userRecordValues: userRecord.values,
    };
  });

  if (changes && Object.keys(changes || {}).length) {
    applyChanges(changes);
  }

  return mergedData;
};
