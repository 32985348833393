import React from "react";
import styled from "styled-components";
import { useWidgetsStore } from "../widgets-store";
import { AvailableTabs } from "../consts/tabs";

const Container = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
  border-bottom: ${({ theme }) => `2px solid ${theme.shared.sidebar.tabs.background.inactive}`};
`;

const Tab = styled.button`
  all: unset;
  color: ${({ theme }) => theme.shared.sidebar.tabs.text.inactive};
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
  padding: 4px 8px;
  position: relative;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.shared.sidebar.tabs.text.hover};
  }

  ${({ isSelected }) => {
    if (isSelected) {
      return `
        &::after {
            display: flex;
            align-items: center;
            justify-content: center;
            content: attr(title);
            font-weight: bold;
            font-size: 14px;
            color: #0c66e4;
            overflow: hidden;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
        .tab-label {
            visibility: hidden;
        }
    `;
    }
  }}
`;

const TabLine = styled.div`
  width: 100%;
  height: 2px;
  box-sizing: border-box;
  border-bottom: 2px solid #0c66e4;
  position: absolute;
  bottom: -2px;
  left: 0px;
`;

function Tabs() {
  const { selectedTab, setSelectedTab } = useWidgetsStore();

  return (
    <Container>
      {AvailableTabs.map(({ id, label }) => (
        <Tab key={id} isSelected={selectedTab?.id === id} title={label} onClick={() => setSelectedTab({ id, label })}>
          <span className="tab-label">{label}</span>
          {selectedTab?.id === id && <TabLine />}
        </Tab>
      ))}
    </Container>
  );
}

export default Tabs;
