import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import Tooltip from "@atlaskit/tooltip";
import { useQuery } from "react-query";
import { getUserReview, setUserReview } from "./review-api";
import { CloseIcon, FilledStarIcon, StarIcon } from "./Icons";
import { useTheme } from "styled-components";

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  padding: 20px 0px 19px 20px;
  border-bottom: 0.75px solid ${({ theme }) => theme.spaceport.reviewBorder};
`;

const Title = styled.span`
  font-weight: 500;
  font-size: 17px;
  line-height: 14px;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.spaceport.reviewTitle};
`;

const Close = styled.span`
  padding: 5px 10px 0px;

  &:hover {
    cursor: pointer;
  }
`;

export const StarsContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 15px;
  flex-direction: row;
  margin: 20px 0px;
`;

const IconContainer = styled.div`
  display: grid;
  place-content: center;
  cursor: pointer;
`;

const Message = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #6b778c;
  display: flex;
  flex-direction: column;
  margin: 20px 0px;
  grid-column: 1 / -1;
`;

const Link = styled.a`
  color: #4c9aff;
  text-decoration: none;

  &:active {
    color: #0065ff;
  }

  &:visited {
    color: #4c9aff;
  }
`;

function Review({ setFeedbackIsClosed }) {
  const [vote, setVote] = useState({ voted: false, score: undefined });
  const [hoveringStar, setHoveringStar] = useState(-1);
  const { isLoading, data } = useQuery(["user-review"], getUserReview, {
    retry: 0,
    select: (response) => {
      const { data } = response;
      return { ...data };
    },
  });

  const theme = useTheme();

  useEffect(() => {
    if (data?.exists) {
      setVote({ voted: true, score: data.score });
    }
  }, [data]);

  const stars = useMemo(() => {
    let defaultStars = [];
    const { voted, score } = vote;

    for (let idx = 0; idx < 4; idx += 1) {
      defaultStars[idx] = { isFilled: voted ? idx <= score - 1 : idx <= hoveringStar };
    }

    return defaultStars;
  }, [hoveringStar, vote]);

  const contentBasedOnIndex = useMemo(
    () => ({
      0: "Bad",
      1: "Not good",
      2: "Good",
      3: "Awesome",
    }),
    [],
  );

  const setUserScore = (score) => {
    setVote({ voted: true, score });
    setUserReview(score).catch((err) => console.log(err));
  };

  if (isLoading) {
    return null;
  }

  return (
    <Wrapper>
      <Title>We would love to hear your feedback!</Title>
      <Close
        onClick={() => {
          setFeedbackIsClosed(true);
        }}
      >
        <CloseIcon primaryColor={theme.poll.icons} />
      </Close>

      <StarsContainer
        onMouseLeave={() => {
          setHoveringStar(-1);
        }}
      >
        {stars.map(({ isFilled }, idx) => (
          <Tooltip key={idx} content={contentBasedOnIndex[idx]} position="top">
            <IconContainer
              key={idx}
              onClick={() => {
                setUserScore(idx + 1);
              }}
              onMouseOver={() => setHoveringStar(idx)}
            >
              {isFilled ? <FilledStarIcon /> : <StarIcon />}
            </IconContainer>
          </Tooltip>
        ))}
      </StarsContainer>

      {vote.voted && vote.score !== 4 && (
        <Message>
          Thank you. Got it. We're working on improvements. Feel free to <br />
          <span>
            <Link href="https://caelor.atlassian.net/servicedesk/customer/portal/1/group/1/create/8" target="_blank">
              comment or leave a suggestion
            </Link>
            , we would appreciate it.
          </span>
        </Message>
      )}
      {vote.voted && vote.score === 4 && (
        <Message>
          Thank you. Your positive feedback really means a lot to our team. We would greatly appreciate if
          <span>
            <Link
              href="https://marketplace.atlassian.com/apps/1230540/cosmos-intranet-for-confluence?hosting=cloud&tab=reviews"
              target="_blank"
            >
              you can leave a review on the Atlassian Marketplace
            </Link>
          </span>
        </Message>
      )}
    </Wrapper>
  );
}

export default Review;
