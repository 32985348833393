import { Panel, PanelContent, PanelTitle } from "../../../../Dashboard/dashboard-styled";

const PanelWrapper = ({ panelTitle, displayOptions: { displayTitle, displayBox }, children }) => {
  if (displayBox) {
    return (
      <Panel>
        {displayTitle && <PanelTitle>{panelTitle}</PanelTitle>}
        <PanelContent noPadding={!displayTitle}>{children}</PanelContent>
      </Panel>
    );
  }
  return (
    <div>
      {displayTitle && <PanelTitle>{panelTitle}</PanelTitle>}
      <PanelContent noPadding={!displayTitle}>{children}</PanelContent>
    </div>
  );
};

export default PanelWrapper;
