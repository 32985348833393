/* eslint-disable import/no-extraneous-dependencies */
import React from "react";
import styled from "styled-components";

import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";

export const SimpleBarStyled = styled(SimpleBar)`
  ${({ breakpointStyle }) => {
    if (breakpointStyle) {
      const [breakpoint, styles] = breakpointStyle;

      const css = Object.keys(styles).map(
        (attr) => `${attr}: ${styles[attr]} !important;`
      );

      return `@media (max-width: ${breakpoint}) {${css.join("")}}`;
    }

    return "";
  }}
`;

const SimpleBarWithBreakpoint = (props) => <SimpleBarStyled {...props} />;

export default SimpleBarWithBreakpoint;
