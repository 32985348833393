export const SCREEN_TYPE = {
  NEWSROOM: "newsroom",
  PEOPLE: "people",
  UNDER_CONSTRUCTION: "underConstruction",
  MAINTENANCE: "maintenance",
};

export const NEWSROOM_DATA = [
  {
    id: 1,
    label: "Caelor apps",
    imagePath: "/images/cosmos-app/logos/shoppie-news-logo.svg",
    title: "Transform Your ITSM with Shoppie: The Future of Seamless Procurement",
    info: "Jane Smith • October 21, 2024",
  },
  {
    id: 2,
    label: "Caelor apps",
    imagePath: "/images/cosmos-app/logos/cosmos-news-logo.svg",
    title: "Cosmos App Receives Exciting New Update",
    info: "Alex Turner •  October 16, 2024",
  },
  {
    id: 3,
    label: "Caelor apps",
    imagePath: "/images/cosmos-app/logos/cosmos-news-logo.svg",
    title: "Enhancing Collaboration: Cosmos App’s Latest Intranet Features",
    info: "Alex Turner •  October 11, 2024",
  },
  {
    id: 4,
    label: "Caelor apps",
    imagePath: "/images/cosmos-app/logos/cosmos-news-logo.svg",
    title: "Revolutionizing Intranets: Discover the New Cosmos App Update",
    info: "Alex Turner • October 09, 2024",
  },
  {
    id: 5,
    label: "Caelor apps",
    imagePath: "/images/cosmos-app/logos/shoppie-news-logo.svg",
    title: "Introducing Shoppie: Revolutionizing Your Shopping Experience in JSM",
    info: "Jane Smith • October 01, 2024",
  },
];

export const PEOPLE_DATA = [
  {
    id: 1,
    imagePath: "/images/cosmos-app/people/emoji-avatar.svg",
    name: "Sophie Mitchell",
    job: "User Experience Designer",
  },
  {
    id: 2,
    imagePath: "/images/cosmos-app/people/emoji-avatar-2.svg",
    name: "James Wilson",
    job: "Chief Information Officer (CIO)",
  },
  {
    id: 3,
    imagePath: "/images/cosmos-app/people/emoji-avatar-3.svg",
    name: "Max Robinson",
    job: "Data Scientist",
  },
  {
    id: 4,
    imagePath: "/images/cosmos-app/people/emoji-avatar-4.svg",
    name: "Priya Patel",
    job: "Data Scientist",
  },
  {
    id: 5,
    imagePath: "/images/cosmos-app/people/emoji-avatar-5.svg",
    name: "Mike Thompson",
    job: "IT Security Analyst",
  },
  {
    id: 6,
    imagePath: "/images/cosmos-app/people/emoji-avatar-6.svg",
    name: "Jessica Bennett",
    job: "Database Administrator",
  },
  {
    id: 7,
    imagePath: "/images/cosmos-app/people/emoji-avatar-7.svg",
    name: "Olivia Foster",
    job: "Project Manager",
  },
  {
    id: 8,
    imagePath: "/images/cosmos-app/people/emoji-avatar-8.svg",
    name: "Tyler Simmons",
    job: "Network Engineer",
  },
  {
    id: 9,
    imagePath: "/images/cosmos-app/people/emoji-avatar-9.svg",
    name: "Ryan Mitchell",
    job: "Software Engineer",
  },
];
