import create from "zustand";

export const useNewsMetadataStore = create((set, get) => ({
  blogId: undefined,
  setBlogId: (blogId) => set((state) => ({ blogId })),

  blogCategories: [],
  setBlogCategories: (blogCategories) => set((state) => ({ blogCategories })),

  allCategories: [],
  setAllCategories: (allCategories) => set((state) => ({ allCategories })),

  selectedTab: undefined,
  setSelectedTab: (selectedTab) => set((state) => ({ selectedTab })),

  propertyExists: false,
  setPropertyExists: (v) => set((state) => ({ propertyExists: v })),

  userIsEditor: false,
  setUserIsEditor: (v) => set((state) => ({ userIsEditor: v })),

  propertyId: "",
  setPropertyId: (propertyId) => set((state) => ({ propertyId })),

  version: "",
  setVersion: (version) => set((state) => ({ version })),

  savedCollections: [],
  setSavedCollections: (savedCollections) => set((state) => ({ savedCollections })),

  selectedCollections: [],
  setSelectedCollections: (selectedCollections) => set((state) => ({ selectedCollections })),

  collectionChanges: {},
  setCollectionChanges: (collectionChanges) => set((state) => ({ collectionChanges })),

  isDbInit: false,
  setIsDbInit: (isInit) => set((state) => ({ isDbInit: isInit })),

  isLoadingMetadata: false,
  setIsLoadingMetadata: (isLoadingMetadata) => set((state) => ({ isLoadingMetadata })),
}));

export const DEFAULT_IMAGE = {
  imageUrl: "",
  background: {
    image: {
      source: "",
      link: "",
    },
    overlay: {},
  },
};

export const useImageStore = create((set, get) => ({
  imageData: DEFAULT_IMAGE,
  setImageData: (imageData) => set((state) => ({ imageData })),
  updateImageData: (value) => {
    const currentData = { ...get().imageData };
    const newData = { ...currentData, ...value };
    return set((state) => ({ imageData: newData }));
  },
  isEditingImage: false,
  setIsEditingImage: (v) => set((state) => ({ isEditingImage: v })),

  propertyId: "",
  setPropertyId: (propertyId) => set((state) => ({ propertyId })),

  version: "",
  setVersion: (version) => set((state) => ({ version })),

  propertyExists: false,
  setPropertyExists: (v) => set((state) => ({ propertyExists: v })),

  isLoadingImage: false,
  setIsLoadingImage: (isLoadingImage) => set((state) => ({ isLoadingImage })),
}));
