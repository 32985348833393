import React, { useCallback } from "react";
import CommentIcon from "@atlaskit/icon/glyph/comment";
import CheckIcon from "@atlaskit/icon/glyph/check";
import EditorCloseIcon from "@atlaskit/icon/glyph/editor/close";
import Button from "@atlaskit/button";
import Avatar from "@atlaskit/avatar";
import SectionHeader from "./SectionHeader";
import { useCategoriesStore, useProposalsStore } from "../../categories-store";
import { ProposalCard, ProposalCardWrapper, ProposalName, ProposalText, ProposalWrapper } from "../styled";
import { saveCategoriesSettings, saveProposalsSettings } from "../../api";
import {
  createProperty,
  getProperty,
  updateProperty,
} from "../../../../../../../Shared/Functions/newsPropertiesHandlers";

function Proposals() {
  const { allCategories, setAllCategories } = useCategoriesStore((state) => ({
    allCategories: state.allCategories,
    setAllCategories: state.setAllCategories,
  }));

  const { allProposals, setAllProposals, allUsersInProposals } = useProposalsStore((state) => ({
    allProposals: state.allProposals,
    setAllProposals: state.setAllProposals,
    allUsersInProposals: state.allUsersInProposals,
  }));

  const approveProposal = async (proposalId, proposalName, blogId) => {
    const newProposals = allProposals.filter((proposal) => proposal.id !== proposalId);
    setAllProposals([...newProposals]);

    const newCategories = [...allCategories, { id: proposalId, value: proposalName }];
    setAllCategories([...newCategories]);

    try {
      saveCategoriesSettings([...newCategories]);
      saveProposalsSettings([...newProposals]);

      const blogsProperty = await getProperty(blogId, "cosmosnewsmetadata");
      if (blogsProperty) {
        const existingCategories = blogsProperty?.value?.categories;
        const newCategoriesInProperty = existingCategories?.length
          ? `${proposalId}, ${blogsProperty?.value?.categories}`
          : String(proposalId);

        await updateProperty(blogId, blogsProperty.id, "cosmosnewsmetadata", blogsProperty.version.number, {
          categories: newCategoriesInProperty,
        });
      } else {
        await createProperty(blogId, { categories: `${proposalId}` }, "cosmosnewsmetadata");
      }
    } catch (error) {
      const flag = window.AP.flag.create({
        title: "Failed to approve proposal.",
        body: "Something went wrong while creating proposal. Refresh, and try again.",
        type: "error",
      });
      setTimeout(() => {
        flag.close();
      }, 5000);
    }
  };

  const declineProposal = (proposalId) => {
    const newProposals = allProposals.filter((proposal) => proposal.id !== proposalId);
    setAllProposals([...newProposals]);
    saveProposalsSettings([...newProposals]);
  };

  const getUserDataForProposal = useCallback(
    (accountId) => {
      const foundUser = allUsersInProposals.find((user) => user.accountId === accountId);
      return foundUser;
    },
    [allUsersInProposals],
  );

  if (!allProposals?.length) return <></>;

  return (
    <>
      <SectionHeader
        Icon={CommentIcon}
        title="Category Proposals"
        description="List of all category proposals from employees."
      />
      <ProposalWrapper>
        {allProposals.map((proposal) => {
          const user = getUserDataForProposal(proposal.accountId);

          return (
            <ProposalCard key={proposal.id}>
              <ProposalCardWrapper>
                <Avatar
                  appearance="circle"
                  size="small"
                  src={`${window.AP._hostOrigin}${user?.profilePicture?.path}`}
                />
                <ProposalName>{user?.displayName}</ProposalName>
                <ProposalText> requested new category</ProposalText>
                <ProposalName> {proposal.name}</ProposalName>
              </ProposalCardWrapper>
              <ProposalCardWrapper>
                <Button
                  appearance="primary"
                  style={{ height: 30, alignItems: "center", fontSize: 14 }}
                  iconBefore={<CheckIcon label="" size="small" />}
                  onClick={() => approveProposal(proposal.id, proposal.name, proposal.blogId)}
                >
                  Approve
                </Button>
                <Button
                  appearance="default"
                  style={{ height: 30, alignItems: "center", fontSize: 14 }}
                  iconBefore={<EditorCloseIcon label="" size="small" />}
                  onClick={() => declineProposal(proposal.id)}
                >
                  Decline
                </Button>
              </ProposalCardWrapper>
            </ProposalCard>
          );
        })}
      </ProposalWrapper>
    </>
  );
}

export default Proposals;
