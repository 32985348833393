import { useQuery } from "react-query";
import { fetchUserDatabaseFields } from "../../Components/UserDatabase/datagrid/api";

export const useUserDatabaseFields = ({ isDatabaseInitialized }) => {
  const { isLoading: isDatabaseFieldsLoading, data: userDatabaseFieldsData } = useQuery(
    ["userdatabase-fields", { includeEmail: false }],
    fetchUserDatabaseFields,
    {
      enabled: isDatabaseInitialized,
      retry: 0,
      select: (response) => {
        const { data } = response;

        const dateFields = data?.filter((field) => field.type === "DATE") || [];
        const singleValueFields = data?.filter((field) => field.type === "SELECT" || field.type === "TEXT");

        return { dateFields, singleValueFields };
      },
    },
  );

  return { isDatabaseFieldsLoading, userDatabaseFieldsData };
};
