import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: ${({ numberOfColumns }) => `repeat(${numberOfColumns}, minmax(0, 1fr))`};
  column-gap: 25px;
  row-gap: 25px;
`;

function Grid({ numberOfColumns, children }) {
  return <Wrapper numberOfColumns={numberOfColumns}>{children}</Wrapper>;
}

export default Grid;
