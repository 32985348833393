import styled from "styled-components";

export const SpaceDirectoryWrapper = styled.div``;

export const SpaceDirectoryInfoWrapper = styled.div`
  padding: 26px 30px;
`;

export const SpacesGridContainer = styled.div`
  margin-top: 25px;
  display: grid;
  grid-template-columns: repeat(auto-fill, 225px);
  grid-gap: 18px;
`;

export const ThreeColumnGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 18px;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }
  /* @media (min-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (min-width: 1440px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media (min-width: 2560px) {
    grid-template-columns: repeat(6, 1fr);
  } */
`;

export const OneColumnGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
`;

export const ViewAllButtonWrapper = styled.div`
  display: grid;
  place-content: center;
  margin-top: 36px;
  font-weight: 400;
  font-size: 20px;
  line-height: 18px;
  color: ${({ primaryColor }) => primaryColor};
  cursor: pointer;
`;

export const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FiltersWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SectionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 40px;
`;

const Title = styled.div`
  color: ${({ theme }) => theme.shared.panel.title};
  font-weight: 500;
  margin-bottom: 18px;
`;

export const LargeTitle = styled(Title)`
  font-size: 36px;
  line-height: 43px;
`;

export const SmallTitle = styled(Title)`
  font-size: 24px;
  line-height: 29px;
`;

export const Subtitle = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${({theme}) => theme.space.listView.resultsColor};
  margin-top: 8px;
  margin-bottom: 20px;
`;
