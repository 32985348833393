import styled from "styled-components";

export const CardWrapper = styled.div`
  border-radius: 3px;
  height: 100%;
  box-sizing: border-box;
  background: ${({ bgClr }) => bgClr};
  border: 1px solid ${({ theme }) => theme.shared.card.border.box};
  box-shadow:
    0px 0px 1px 0px rgba(9, 30, 66, 0.31),
    0px 1px 3px 0px rgba(9, 30, 66, 0.25);
  padding: 32px 24px 24px 24px;
  overflow: hidden;
`;

export const CardContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: ${({ alignment }) => {
    return {
      left: "flex-start",
      center: "center",
      right: "flex-end",
    }[alignment];
  }};
`;

export const ImageCardContent = styled(CardContent)`
  height: ${({ imageHeight }) => `calc(100% - ${imageHeight}px)`};
  box-sizing: border-box;
  padding: 32px 24px 24px 24px;
`;

export const CardImage = styled.div`
  width: 100%;
  height: ${({ imageHeight }) => `${imageHeight}px`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  ${({ builtBg }) => builtBg};
`;

export const BigIconContainer = styled.div`
  display: grid;
  place-content: center;
  width: 48px;
  height: 48px;
  border-radius: 3px;
  background-color: ${({ bgClr }) => bgClr};

  svg,
  path,
  rect {
    fill: #fff !important;
  }
`;

export const IconContainer = styled.div`
  display: grid;
  place-content: center;
  width: 24px;
  height: 24px;
  min-width: 24px;
`;

export const ColoredDivider = styled.div`
  height: 2px;
  width: ${({ width }) => width};
  border-radius: 2px;
  background-color: ${({ bgClr }) => bgClr};
`;

export const CardTitle = styled.h1`
  all: unset;
  color: ${({ theme }) => theme.shared.card.title};
  font-size: 20px;
  font-weight: 500;
  text-align: ${({ alignment }) => alignment || "left"};
`;

export const CardDescription = styled.p`
  all: unset;
  color: ${({ theme }) => theme.shared.card.text};
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: ${({ alignment }) => alignment || "left"};
  flex: 1;
`;
