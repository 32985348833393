import { Spotlight, SpotlightTransition } from "@atlaskit/onboarding";
import { useCurrentUserStore } from "../../../current-user-store";
import { PEOPLE_WIZARD_PROPERTY_KEY, updateUserWizardConfigProperty } from "./wizard-service";

const PeopleNetworkWizard = ({ currentWizardStep, setCurrentWizardStep, updateWizardProperties }) => {
  const next = () => setCurrentWizardStep((currentWizardStep || 0) + 1);
  const end = () => setCurrentWizardStep(null);

  const currentUser = useCurrentUserStore((state) => state.currentUser);

  const renderActiveSpotlight = () => {
    const spotlights = [
      <Spotlight
        actions={[
          {
            onClick: () => {
              next();
            },
            text: "Next",
          },
          {
            onClick: () => {
              updateWizardProperties("people", true);
              updateUserWizardConfigProperty(
                currentUser?.accountId,
                { userSeenWizard: true },
                true,
                PEOPLE_WIZARD_PROPERTY_KEY
              );
              end();
            },
            text: "Skip",
            appearance: "subtle",
          },
        ]}
        heading="Filters and Collections"
        target="people-network-content"
        key="filtering-and-navigation-people-network"
        targetRadius={0}
        targetBgColor={"white"}
      >
        Welcome to People Network! Here you can filter based on any criteria and find all your colleagues.
      </Spotlight>,
      <Spotlight
        actions={[
          {
            onClick: () => {
              updateWizardProperties("people", true);
              updateUserWizardConfigProperty(
                currentUser?.accountId,
                { userSeenWizard: true },
                true,
                PEOPLE_WIZARD_PROPERTY_KEY
              );
              end();
            },
            text: "Done",
            appearance: "subtle",
          },
        ]}
        heading="Edit People Cards"
        target="edit-people-network"
        key="edit-people-network-content"
        targetRadius={8}
        targetBgColor={"white"}
      >
        You can change the people card layout by selecting different columns from the Database. You can also find card
        design options here.
      </Spotlight>,
    ];

    if (currentWizardStep < 0) {
      return null;
    }

    return spotlights[currentWizardStep];
  };

  return <SpotlightTransition>{renderActiveSpotlight()}</SpotlightTransition>;
};

export default PeopleNetworkWizard;
