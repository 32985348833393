import React from "react";
import styled from "styled-components";
import Button from "@atlaskit/button";
import { ModalBody, ModalFooter, ModalWrapper } from "../styled";

const ImagePreview = styled.div`
  width: 100%;
  height: 100%;
  background-image: ${({ picture }) => `url("${picture}")`};
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
`;

function Preview({ picture }) {
  return (
    <ModalWrapper>
      <ModalBody>
        <ImagePreview picture={picture} />
      </ModalBody>
      <ModalFooter>
        <Button appearance="subtle" onClick={() => window.AP.dialog.close()}>
          Close
        </Button>
      </ModalFooter>
    </ModalWrapper>
  );
}

export default Preview;
