import React, { useState, useEffect } from "react";
import styled from "styled-components";
import shallow from "zustand/shallow";
import Settings from "./settings/Settings";
import SocialLinks from "./settings/SocialLinks";
import SidebarPanel from "./types/SidebarPanel";
import EditPanelsButton from "../../Shared/EditPanelsButton";
import SidebarTransition from "./settings/SidebarTransition";
import { getUserProperty, updateUserProperty, getAccountId } from "../profile.service";
import { useRecordsStore } from "../../../../UserDatabase/records-store";
import { useCurrentUserStore } from "../../../current-user-store";
import { SpotlightManager, SpotlightTarget } from "@atlaskit/onboarding";
import ProfileWizard from "../../Shared/ConfigurationWizard/ProfileWizard";
import { TabKeys } from "./tabs-config";
import PanelContent from "./PanelContent";
import { WidgetIds } from "../../Settings/Widgets/consts/tabs";
import { useQuery } from "react-query";
import { getWidgetSettingById } from "../../Settings/Widgets/api";
import { OrgChartPropertyKeys } from "../../Settings/Widgets/components/types/orgchart/available-properties";

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  width: calc(100% - 60px);
  max-width: 1270px;
  padding: 35px 30px 50px;
  margin: 0 auto;
  gap: 30px;
`;

const Panels = ({ currentWizardStep, setCurrentWizardStep, updateWizardProperties, primaryColor }) => {
  const [isSettingsPanelOpen, setIsSettingsPanelOpen] = useState();
  const [isSocialPanelOpen, setIsSocialPanelOpen] = useState();
  const [isFirstSave, setIsFirstSave] = useState(true);
  const [userData, setUserData] = useState();
  const [selectedTab, setSelectedTab] = useState(TabKeys.PROFILE);

  const [isOrgChartSetup, setIsOrgChartSetup] = useState(false);

  const primaryContrastColor = "#ffffff";

  const { isLoading: loadingOrgChartSettings, data: widgetSettingData } = useQuery(
    ["org-chart-settings", { widgetId: WidgetIds.ORG_CHART }],
    getWidgetSettingById,
    {
      retry: 0,
      select: (response) => {
        const { data } = response;
        return data;
      },
    },
  );

  const { currentUser, isCurrentUserAdmin } = useCurrentUserStore(
    (state) => ({
      currentUser: state.currentUser,
      isCurrentUserAdmin: state.isCurrentUserAdmin,
    }),
    shallow,
  );

  const { fields, setFields, updateField, records } = useRecordsStore(
    (state) => ({
      fields: state.fields,
      records: state.records,
      setFields: state.setFields,
      updateField: state.updateField,
    }),
    shallow,
  );

  useEffect(() => {
    if (!loadingOrgChartSettings && widgetSettingData?.exists) {
      setIsOrgChartSetup(widgetSettingData.widget?.[OrgChartPropertyKeys.IS_ACTIVE]);
    } else {
      setIsOrgChartSetup(false);
    }
  }, [loadingOrgChartSettings, widgetSettingData]);

  useEffect(() => {
    let accountId = getAccountId();

    window.AP.user.getCurrentUser((user) => {
      accountId = accountId ? accountId : user.atlassianAccountId;

      getUserProperty(accountId)
        .then(({ data }) => {
          if (!data || !Object.keys(data || {}).length) {
            setIsFirstSave(true);
            return;
          }
          setUserData(data);
          setIsFirstSave(false);
        })
        .catch(() => setIsFirstSave(true));
    });
  }, []);

  return (
    <SpotlightManager>
      <ProfileWizard
        currentWizardStep={currentWizardStep}
        setCurrentWizardStep={setCurrentWizardStep}
        updateWizardProperties={updateWizardProperties}
      />
      <Wrapper>
        <SidebarPanel
          socialLinks={userData?.socialLinks}
          values={records[0].values}
          fields={fields}
          setIsSocialPanelOpen={setIsSocialPanelOpen}
          primaryColor={primaryColor}
        />

        {isCurrentUserAdmin && (
          <SpotlightTarget name="edit-profile">
            <EditPanelsButton
              openSidebar={() => setIsSettingsPanelOpen(true)}
              primaryColor={primaryColor}
              primaryContrastColor={primaryContrastColor}
              isDisabled={currentWizardStep !== null}
            />
          </SpotlightTarget>
        )}

        <PanelContent
          tab={{ selectedTab, setSelectedTab }}
          values={records[0].values}
          fields={fields}
          showOrgChartTab={isOrgChartSetup}
        />

        <SidebarTransition
          width={385}
          isOpen={isSettingsPanelOpen}
          close={() => setIsSettingsPanelOpen(false)}
          note="Changes you make here will be applied to all user profiles."
          primaryColor={primaryColor}
          primaryContrastColor={primaryContrastColor}
          scrollbarWrapperStyles={{ height: "100%" }}
        >
          <Settings
            fields={fields}
            setFields={setFields}
            updateField={updateField}
            close={() => setIsSettingsPanelOpen(false)}
          />
        </SidebarTransition>

        <SidebarTransition
          width={385}
          isOpen={isSocialPanelOpen}
          close={() => setIsSocialPanelOpen(false)}
          onSubmit={() => updateUserProperty(currentUser.accountId, userData, isFirstSave)}
          primaryColor={primaryColor}
          primaryContrastColor={primaryContrastColor}
          scrollbarWrapperStyles={{ height: "calc(100% - 82px)" }}
        >
          <SocialLinks
            values={userData}
            setUserData={setUserData}
            fields={fields}
            close={() => setIsSocialPanelOpen(false)}
          />
        </SidebarTransition>
      </Wrapper>
    </SpotlightManager>
  );
};

export default Panels;
