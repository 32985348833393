import React from "react";
import { useTheme } from "styled-components";
import { FieldContainer, FieldLabel, ViewButtonsWrapper, ViewSelectButton } from "../../Shared/Filters/styled-filters";
import { GridIcon, DepartmentIcon } from "../../Shared/Filters/Icons";
import { convertColorOpacity } from "../../../../../Shared/Functions/convertColorOpacity";

export const Views = {
  GRID: "grid",
  ORGCHART: "orgchart",
};

function ViewSelect({ selectedView, setSelectedView, primaryColor = "#0065ff" }) {
  const theme = useTheme();
  const convertedColor = convertColorOpacity(primaryColor, theme.global.name);

  return (
    <FieldContainer style={{ marginTop: "0px", marginBottom: "20px" }}>
      <FieldLabel style={{ textTransform: "uppercase" }}>View</FieldLabel>
      <ViewButtonsWrapper numberOfColumns={2}>
        <ViewSelectButton
          isActive={selectedView === Views.GRID}
          primaryColor={primaryColor}
          onClick={() => setSelectedView(Views.GRID)}
          backgroundColor={{
            inactive: theme.shared.form.button.background.inactive,
            active: convertedColor,
          }}
        >
          <GridIcon color={selectedView === Views.GRID ? primaryColor : theme.shared.form.button.text.inactive} />
        </ViewSelectButton>

        <ViewSelectButton
          isActive={selectedView === Views.ORGCHART}
          primaryColor={primaryColor}
          onClick={() => setSelectedView(Views.ORGCHART)}
          backgroundColor={{
            inactive: theme.shared.form.button.background.inactive,
            active: convertedColor,
          }}
        >
          <DepartmentIcon
            color={selectedView === Views.ORGCHART ? primaryColor : theme.shared.form.button.text.inactive}
          />
        </ViewSelectButton>
      </ViewButtonsWrapper>
    </FieldContainer>
  );
}

export default ViewSelect;
