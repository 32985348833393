import React, { useMemo } from "react";
import AvatarGroup from "@atlaskit/avatar-group";
import PageIcon from "@atlaskit/icon-object/glyph/page/16";
import BlogIcon from "@atlaskit/icon-object/glyph/blog/16";
import { DateInfo, Inline, InlineLink, ListImage, ListImagePlaceholder, Name, Row } from "../styled";
import { createProfileLink } from "../../../../../../PeopleBase/Views/createProfileLink";
import { useEffect } from "react";
import { useState } from "react";
import Actions from "../content/Actions";
import { useTheme } from "styled-components";

function List({ diagram, content, elementsToDisplay, locale, numberOfItemsInTable }) {
  const theme = useTheme();

  const [picture, setPicture] = useState("");
  const [loadingPicture, setLoadingPicture] = useState(false);

  const { title: contentTitle, type, link } = content;
  const { title, lastModified, contributers, editorUrl, getPictureFromAttachments } = diagram;

  const formattedDate = useMemo(() => {
    const rawDate = new Date(lastModified);
    const date = rawDate.toLocaleDateString(locale || "en-US", {
      month: "short",
      day: "2-digit",
      year: "numeric",
    });

    const time = rawDate.toLocaleTimeString(locale || "en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hourCycle: "h24",
    });

    return [date, time];
  }, [lastModified, locale]);

  useEffect(() => {
    if (!picture && getPictureFromAttachments) {
      setLoadingPicture(true);
      getPictureFromAttachments()
        .then((rawImageUrl) => {
          if (rawImageUrl) {
            setPicture(`${window.AP._hostOrigin}/wiki${rawImageUrl}`);
          }
        })
        .finally(() => setLoadingPicture(false));
    }
  }, [picture, getPictureFromAttachments]);

  return (
    <Row
      numberOfItems={numberOfItemsInTable}
      hasHover
      color={theme.global.name === "light" ? "#F1F2F4" : theme.shared.panel.hover}
    >
      <Inline
        onClick={(e) => {
          e.preventDefault();
          window.open(editorUrl, "_blank");
        }}
        style={{ cursor: "pointer" }}
      >
        {loadingPicture ? <ListImagePlaceholder /> : <ListImage src={picture} />}
        <Name style={{ fontSize: "16px", fontWeight: "400" }}>{title}</Name>
      </Inline>

      {elementsToDisplay.includes("attachedto") && (
        <InlineLink
          style={{ fontSize: "14px", cursor: "pointer" }}
          onClick={(e) => {
            e.preventDefault();
            window.open(link, "_blank");
          }}
          target="_blank"
          rel="noreferrer"
        >
          {type === "blogpost" ? <BlogIcon /> : <PageIcon />}
          &nbsp;<span className="title">{contentTitle}</span>
        </InlineLink>
      )}

      {elementsToDisplay.includes("contributors") && (
        <AvatarGroup
          appearance="stack"
          data={contributers}
          maxCount={3}
          onAvatarClick={(e) => {
            e.preventDefault();
            const url = e.target.src;
            const accountId = url.slice(url.lastIndexOf("/") + 1);
            window.open(createProfileLink(accountId), "_blank");
          }}
          isTooltipDisabled={false}
        />
      )}

      {elementsToDisplay.includes("date") && (
        <DateInfo style={{ fontSize: "13px", lineHeight: "20px" }}>
          {formattedDate[0]} at {formattedDate[1]}
        </DateInfo>
      )}

      <Actions visible editorUrl={editorUrl} pictureUrl={picture} />
    </Row>
  );
}

export default List;
