import React from "react";
import Spinner from "@atlaskit/spinner";
import { CoverStory, InlineNews } from "@caelor/cards-and-panels-components";
import { GridContainer, ListContainer, MagazineGridContainer, NewsTypes } from "./News";
import { OverlineWrapper, PersonDetails, PersonIcon, SideNewsListContainer, SpaceLabel, Title } from "./styled";
import { useCorporateIdentityStore } from "../../../../Settings/General/BrandAndColors/corporate-identity-store";
import { useTheme } from "styled-components";
import { htmlDecode } from "../../../../../../ContentBuilder/utils/decode-html-string";

function chunk(arr, size) {
  var chunks = [];
  for (var i = 0; i < arr.length; i += size) {
    chunks.push(arr.slice(i, i + size));
  }
  return chunks;
}

export const NewsManual = ({ data, blogs, version }) => {
  const borderRadius = useCorporateIdentityStore((state) => state.borderRadius);
  const theme = useTheme();

  const activeElements = version ? data.activeElements : ["image", ...data.activeElements];

  const GridVariant = ({ blogpost }) => {
    return (
      <CoverStory
        key={blogpost.data.id}
        coverPictureCallback={blogpost.data.coverPictureCallback}
        getBlogsCategories={activeElements?.includes("categories") ? blogpost.data.getBlogsCategories : undefined}
        name={blogpost.data.name}
        space={activeElements?.includes("space") ? blogpost.data.space : {}}
        url={blogpost.data.url}
        excerpt={activeElements?.includes("description") ? htmlDecode(blogpost.data.excerpt) : null}
        labels={activeElements?.includes("label") ? blogpost.data.labels : null}
        createdBy={activeElements?.includes("author") ? blogpost.data.createdBy : null}
        lastModified={activeElements?.includes("date") ? blogpost.data.createdFormatted : null}
        avatarUrl={blogpost.data.avatarUrl}
        labelsBackground={data.labelsBackground}
        coverPictureSize={data.coverPictureSize}
        coverPictureBorderRadius={data.coverPictureBorderRadius}
        labelsBorderRadius={borderRadius}
      />
    );
  };

  const ListVariant = ({ blogpost }) => {
    return (
      <InlineNews
        target={"_blank"}
        key={blogpost.data.id}
        name={blogpost.data.name}
        getBlogsCategories={activeElements?.includes("categories") ? blogpost.data.getBlogsCategories : undefined}
        space={activeElements?.includes("space") ? blogpost.data.space : {}}
        url={blogpost.data.url}
        excerpt={activeElements?.includes("description") ? htmlDecode(blogpost.data.excerpt) : null}
        labels={activeElements?.includes("label") ? blogpost.data.labels : null}
        createdBy={activeElements?.includes("author") ? blogpost.data.createdBy : null}
        lastModified={activeElements?.includes("date") ? blogpost.data.createdFormatted : null}
        avatarUrl={blogpost.data.avatarUrl}
        coverPictureCallback={blogpost.data.coverPictureCallback}
        labelsBackground={data?.teamLabelsBackground}
        coverPictureBorderRadius={data.coverPictureBorderRadius}
        imagePosition={activeElements?.includes("image") ? "left" : "none"}
        maxWidthOfImage={30}
        labelsBorderRadius={borderRadius}
        darkTheme={{
          backgroundColor: theme.shared.news.background,
          titleColor: theme.shared.news.title,
          descriptionColor: theme.shared.news.text,
          authorColor: theme.shared.news.text,
          borderColor: theme.shared.news.border,
          textColor: theme.shared.news.text,
        }}
      />
    );
  };

  const MagazineVariant = ({ blog, withLargeStory, idx }) => {
    return (
      <div className={`${withLargeStory ? `grid-large-story-child-${idx + 1}` : `grid-child-${idx + 1}`} child`}>
        <CoverStory
          key={blog.id}
          coverPictureCallback={blog.coverPictureCallback}
          getBlogsCategories={activeElements?.includes("categories") ? blog.getBlogsCategories : undefined}
          name={blog.name}
          space={activeElements?.includes("space") ? blog.space : {}}
          url={blog.url}
          avatarUrl={blog.avatarUrl}
          excerpt={activeElements?.includes("description") ? htmlDecode(blog.excerpt) : null}
          labels={activeElements?.includes("label") ? blog.labels : null}
          createdBy={activeElements?.includes("author") ? blog.createdBy : null}
          lastModified={activeElements?.includes("date") ? blog.createdFormatted : null}
          labelsBackground={data.labelsBackground}
          coverPictureBorderRadius={data.coverPictureBorderRadius}
          labelsBorderRadius={borderRadius}
        />
      </div>
    );
  };

  const SideNewsVariant = ({ blog }) => {
    return (
      <div
        key={blog.data.id}
        style={{ cursor: "pointer" }}
        onClick={(e) => {
          e.stopPropagation();
          window.open(blog.data.url, "_blank");
        }}
      >
        <OverlineWrapper isTwoColumns={activeElements?.includes("author")}>
          {activeElements?.includes("author") && <PersonIcon src={blog.data.avatarUrl} />}
          <PersonDetails>
            {activeElements?.includes("author") && `${blog.data.createdBy}`}
            {activeElements?.includes("author") && activeElements?.includes("date") && " · "}
            {activeElements?.includes("date") && `${blog.data.createdFormatted}`}
          </PersonDetails>
        </OverlineWrapper>
        <Title>{blog.data.name}</Title>
        {activeElements?.includes("space") && <SpaceLabel>{blog.data.space.name}</SpaceLabel>}
      </div>
    );
  };

  const LayoutBasedOnVariant = ({ blogpost }) => {
    if (!!data?.isSideNews) {
      return <SideNewsVariant blog={blogpost} />;
    }

    return (
      <>
        {data.view === NewsTypes.LIST && <ListVariant blogpost={blogpost} />}
        {data.view === NewsTypes.GRID && <GridVariant blogpost={blogpost} />}
      </>
    );
  };

  const MappedBlogs = () => {
    return blogs.map((blogpost, index) => {
      if (blogpost.data.loading) {
        return (
          <div key={index} style={{ textAlign: "center" }}>
            <Spinner size={"large"} />
          </div>
        );
      }

      return <LayoutBasedOnVariant key={index} blogpost={blogpost} />;
    });
  };

  if (!!data?.isSideNews) {
    return (
      <SideNewsListContainer>
        <MappedBlogs />
      </SideNewsListContainer>
    );
  }

  if (data.view === NewsTypes.MAGAZINE) {
    return chunk(blogs, 3).map((blogChunk, chunkIndex) => {
      return (
        <div key={chunkIndex} style={{ marginTop: "5px" }}>
          <MagazineGridContainer isLargeStory={chunkIndex === 0} padding={data.padding}>
            {blogChunk.map((blogpost, index) => {
              if (blogpost.data.loading) {
                return (
                  <div key={index} style={{ textAlign: "center" }}>
                    <Spinner size={"large"} />
                  </div>
                );
              }

              return (
                <MagazineVariant
                  key={blogpost.data.id}
                  blog={blogpost.data}
                  withLargeStory={chunkIndex === 0}
                  idx={index}
                />
              );
            })}
          </MagazineGridContainer>
        </div>
      );
    });
  }

  if (data.view === NewsTypes.GRID) {
    return (
      <GridContainer padding={data.padding}>
        <MappedBlogs />
      </GridContainer>
    );
  }

  if (data.view === NewsTypes.LIST) {
    return (
      <ListContainer padding={data.padding}>
        <MappedBlogs />
      </ListContainer>
    );
  }
};
