import { COLOR_TYPES, DEFAULT_COLORS, ICON_BG_TYPES, ICON_BG_SHAPE_TYPES } from "./color-types";

export const DEFAULT_VALUES = {
  logo: "",
  colors: DEFAULT_COLORS,
};

export const migrateOldColorTypes = (oldColors) => {
  return {
    ...oldColors,
    [COLOR_TYPES.ICON_TYPE]:
      oldColors[COLOR_TYPES.ICON_TYPE] === "light" ? ICON_BG_TYPES.COLOR : ICON_BG_TYPES.TRANSPARENT,
  };
};

export const addIconTypeAsProperty = (oldColors, theme) => {
  if (!Object.keys(oldColors || {}).length) return undefined;

  const hasIconType = Object.keys(oldColors).includes(COLOR_TYPES.ICON_TYPE);
  const hasIconShapeType = Object.keys(oldColors).includes(COLOR_TYPES.ICON_BG_SHAPE_TYPES);

  if (hasIconType) {
    if (["light", "dark"].includes(oldColors[COLOR_TYPES.ICON_TYPE])) {
      return migrateOldColorTypes(oldColors);
    }

    if(!hasIconShapeType) {
      oldColors = {...oldColors, [COLOR_TYPES.ICON_BG]: theme.shared.sidebar.icons.bg, [COLOR_TYPES.ICON_COLOR]: theme.shared.sidebar.icons.color, [COLOR_TYPES.ICON_BG_SHAPE_TYPES]: ICON_BG_SHAPE_TYPES.CIRCLE};
    }
    return oldColors;
  }

  let newColors = {...oldColors, [COLOR_TYPES.ICON_TYPE]: ICON_BG_TYPES.COLOR, [COLOR_TYPES.ICON_BG]: theme.shared.sidebar.icons.bg, [COLOR_TYPES.ICON_COLOR]: theme.shared.sidebar.icons.color}

  if(!hasIconShapeType) {
    newColors = {...oldColors, [COLOR_TYPES.ICON_TYPE]: ICON_BG_TYPES.COLOR, [COLOR_TYPES.ICON_BG]: theme.shared.sidebar.icons.bg, [COLOR_TYPES.ICON_COLOR]: theme.shared.sidebar.icons.color, [COLOR_TYPES.ICON_BG_SHAPE_TYPES]: ICON_BG_SHAPE_TYPES.CIRCLE};
  }
  
  return newColors;
};

export const checkIfColorsHaveDefaultValues = (colors, theme) => {
  const primaryColor = theme.global.primaryColor;


  if(colors[COLOR_TYPES.PRIMARY] === primaryColor) {
    return true;
  } else {return false;}
};
