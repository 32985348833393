import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import shallow from "zustand/shallow";
import Tooltip from "@atlaskit/tooltip";
import styled from "styled-components";
import Spinner from "@atlaskit/spinner";
import UnlockFilledIcon from "@atlaskit/icon/glyph/unlock-filled";
import LockFilledIcon from "@atlaskit/icon/glyph/lock-filled";
import { SpotlightManager, SpotlightTarget } from "@atlaskit/onboarding";
import PageHeaderCentered from "../Shared/Header/PageHeaderCentered";
import { ContentWrapper, SpinnerContainer } from "../../styled/pages";
import {
  ContentContainer,
  DividerLine,
  FilteredRecordsFiltersContainer,
  FiltersCollectionsContainer,
  FiltersContainer,
  LabelContainer,
  ShowHideMoreButton,
  SmallTitle,
} from "./styled/views";
import { useQuery } from "react-query";
import { fetchIsInitialized, fetchRecords, fetchUserDatabaseFields } from "../../../UserDatabase/datagrid/api";
import { getFilterOptionsFromAzure } from "../../../UserDatabase/menubar/azure/api";
import { useRecordsStore } from "../../../UserDatabase/records-store";
import FilterService from "../../../UserDatabase/filter-service";
import { useDatabaseFilters, useDatabaseStore, useSidebarPanelStore } from "./database-store";
import EmptyState from "./EmptyState";
import NoResultsState from "../Shared/NoResultsState";
import { useCurrentUserStore } from "../../current-user-store";
import { getPeopleNetworkSettings, savePeopleNetworkSettings } from "./apiPeople";
import EditPanelsButton from "../Shared/EditPanelsButton";
import SidebarTransition from "../../../../Shared/Components/SidebarTransition";
import { defaultSettings, migrateCardBackground } from "./defaultSettings";
import { handleRetrievedData } from "./handleRecordsData";
import { useChangesStore } from "../../track-changes-store";
import { useHeaderStore } from "../../store/header-store";
import { Pages } from "../../pages";
import { getUserCollections, saveHeaderSettings } from "../../api";
import { fetchCollections } from "../../../Collections/api";
import { useCorporateIdentityStore } from "../Settings/General/BrandAndColors/corporate-identity-store";
import { COLOR_TYPES } from "../Settings/General/BrandAndColors/color-types";
import { useHeaderChangesStore } from "../Shared/Header/customization/header-changes-store";
import PeopleNetworkWizard from "../Shared/ConfigurationWizard/PeopleNetworkWizard";
import { searchUsers } from "./apiPeople";
import StartScreen from "./Views/StartScreen";
import { Filter } from "../../../Collections/selected/Filters";
import FilterProvider from "../../../Collections/selected/FilterProvider";
import CollectionsFilter from "./Sidebar/CollectionsFilter";
import { FieldType } from "../../../UserDatabase/field-type";
import { usePanelsStore } from "./store/panels-store";
import { getDefaultPeopleNetwork } from "./Panel/defaultPanelData";
import { SIDEBAR_TYPES } from "./Sidebar/types";
import { useSidebarStore } from "./store/sidebar-store";
import ContentSelector from "./Sidebar/ContentSelector";
import PanelsList from "./Sidebar/PanelsList";
import EditPanel from "./Sidebar/EditPanel";
import AddPanels from "./Sidebar/AddPanels";
import {
  buildPanelCollectionsFilters,
  getAllCollectionsFromPanels,
  separateNonExistingCollections,
  simulateCollectionsBasedOnFiltersInPanels,
} from "../../../../Shared/Functions/handleCollectionFilters";
import { PeopleNetworkAPIContext } from "./Context";
import ViewSelect, { Views } from "./Filter/ViewSelect";
import OrgChart from "./Views/OrgChart";
import { getWidgetSettingById } from "../Settings/Widgets/api";
import { WidgetIds } from "../Settings/Widgets/consts/tabs";
import { OrgChartPropertyKeys } from "../Settings/Widgets/components/types/orgchart/available-properties";
import ActionsToolbar from "../OrgChart/components/ActionsToolbar";
import Permissions from "../Shared/Panels/shared/components/Permissions";
import Sort, { sortRecords } from "./Filter/Sort";

import EmployeesEmptyState from "../../../../Shared/Components/EmptyState";
import NoNewStartersSVG from "../Dashboard/panels/images/NoNewStartersSVG";

export const Subtitle = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.shared.subtitle};
  margin-top: 8px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 10px;
  flex-wrap: wrap;
  align-items: center;
`;

const CustomIconContainer = styled.div`
  width: 24px;
  height: 24px;
  cursor: pointer;
  display: grid;
  place-content: center;
  border-radius: 3px;

  transition: background 150ms linear;

  &:hover {
    background-color: ${({ bgColor }) => `${bgColor}1a`};
  }
`;

const PermissionsIcon = ({ handleClick }) => {
  const editingPanel = usePanelsStore((state) => state.editingPanel);

  const { permissions: collections, filters } = editingPanel || {};

  const hasPermissionsSet = !!collections?.length || !!filters?.length;

  return (
    <Tooltip position="auto" content={hasPermissionsSet ? "View restrictions applied" : "No view restrictions"}>
      <CustomIconContainer bgColor={hasPermissionsSet ? "#ff0000" : "#0065ff"} onClick={() => handleClick()}>
        {hasPermissionsSet ? <LockFilledIcon primaryColor="red" /> : <UnlockFilledIcon primaryColor="#0065ff" />}
      </CustomIconContainer>
    </Tooltip>
  );
};

function PeopleBase({ currentWizardStep, setCurrentWizardStep, updateWizardProperties }) {
  const [isSearching, setIsSearching] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);

  const [rawPeopleResponse, setRawPeopleResponse] = useState(null);

  const [selectedView, setSelectedView] = useState(Views.GRID);

  const [isOrgChartActive, setIsOrgChartActive] = useState(false);
  const [isOrgChartSetup, setIsOrgChartSetup] = useState(false);
  const [orgChartZoom, setOrgChartZoom] = useState(100);

  const [selectedSidebarTab, setSelectedSidebarTab] = useState();

  const [totalSize, setTotalSize] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);

  const [customSearchFilter, setCustomSearchFilter] = useState([]);
  const [hasSearchResults, setHasSearchResults] = useState(true);

  const [collections, setCollections] = useState();
  const [selectedCollection, setSelectedCollection] = useState();

  const [loadingFilteredCollections, setLoadingFilteredCollections] = useState(false);
  const [azureManagerOptions, setAzureManagerOptions] = useState([]);

  const [showAllFilters, setShowAllFilters] = useState(false);
  const [sorting, setSorting] = useState();

  const { hasChanges, setHasChanges } = useChangesStore((state) => ({
    hasChanges: state.hasChanges,
    setHasChanges: state.setHasChanges,
  }));

  const { hasHeaderChanges, setHasHeaderChanges } = useHeaderChangesStore((state) => ({
    hasHeaderChanges: state.hasHeaderChanges,
    setHasHeaderChanges: state.setHasHeaderChanges,
  }));

  const hasChangesRef = useRef();
  hasChangesRef.current = hasChanges;

  const { fields, setFields } = useRecordsStore((state) => ({ fields: state.fields, setFields: state.setFields }));

  const { colors, headerType, backgroundColor } = useCorporateIdentityStore((state) => ({
    colors: state.colors,
    headerType: state.headerType,
    backgroundColor: state.backgroundColor,
  }));

  const primaryColor = colors ? colors[COLOR_TYPES.PRIMARY] : "#0065ff";

  const { panels, setPanels, editingPanel, isDbInitalized } = usePanelsStore((state) => ({
    panels: state.panels,
    setPanels: state.setPanels,

    editingPanel: state.editingPanel,

    isDbInitalized: state.hasInitEmployeeDatabase,
  }));

  const { selectedContentType, sidebarType, setSidebarType, disableClickOutside, setDisableClickOutside } =
    useSidebarStore((state) => ({
      selectedContentType: state.selectedContentType,
      setSelectedContentType: state.setSelectedContentType,
      sidebarType: state.sidebarType,
      setSidebarType: state.setSidebarType,
      disableClickOutside: state.disableClickOutside,
      setDisableClickOutside: state.setDisableClickOutside,
    }));

  const { builtRecords, builtRecordsLoading, setBuiltRecords, setBuiltRecordsLoading } = useDatabaseStore(
    (state) => ({
      builtRecords: state.builtRecords,
      builtRecordsLoading: state.builtRecordsLoading,
      setBuiltRecords: state.setBuiltRecords,
      setBuiltRecordsLoading: state.setBuiltRecordsLoading,
    }),
    shallow,
  );

  const { isCurrentUserAdmin } = useCurrentUserStore((state) => ({
    isCurrentUserAdmin: state.isCurrentUserAdmin,
  }));

  const { filters, selectedFilters, setFilters, updateFilter, setSelectedFilters, reset } = useDatabaseFilters(
    (state) => ({
      filters: state.filters,
      setFilters: state.setFilters,
      updateFilter: state.updateFilter,
      selectedFilters: state.selectedFilters,
      setSelectedFilters: state.setSelectedFilters,
      reset: state.reset,
    }),
  );

  const { settings, setSettings, isEditing, setIsEditing } = useSidebarPanelStore((state) => ({
    settings: state.settings,
    setSettings: state.setSettings,
    isEditing: state.isEditing,
    setIsEditing: state.setIsEditing,
  }));

  const { headerData, isEditingHeader } = useHeaderStore((state) => ({
    headerData: state.headerData,
    isEditingHeader: state.isEditingHeader,
  }));

  const { isLoading: loadingOrgChartSettings, data: widgetSettingData } = useQuery(
    ["org-chart-settings", { widgetId: WidgetIds.ORG_CHART }],
    getWidgetSettingById,
    {
      retry: 0,
      select: (response) => {
        const { data } = response;
        return data;
      },
    },
  );

  const { isLoading: isInitializedLoading, data: isInitialized } = useQuery(
    "database-is-initialized",
    fetchIsInitialized,
    {
      retry: 0,
      select: (response) => {
        const { data } = response;
        return data;
      },
    },
  );

  const { isLoading: recordsLoading, data: recordsData } = useQuery(
    [
      "userdatabase-records",
      {
        filters: [
          ...selectedFilters,
          ...customSearchFilter,
          { isMultiUser: true, type: "BOOLEAN", value: true, column: "hidden", condition: "NOT" },
        ],
        confluenceGroup: selectedCollection?.userGroup || "",
        page: page,
      },
    ],
    fetchRecords,
    {
      enabled: !!isInitialized,
      retry: 0,
      select: (response) => {
        const { data } = response;
        return data;
      },
    },
  );

  const { isLoading: profileNetworkLoading, data: peopleNetworkData } = useQuery(
    ["people-network-settings"],
    getPeopleNetworkSettings,
    {
      retry: 0,
      select: (response) => {
        const { data } = response;
        return data;
      },
    },
  );

  const { isLoading: fieldsLoading, data: fieldsData } = useQuery(
    ["userdatabase-fields", { includeEmail: true }],
    fetchUserDatabaseFields,
    {
      retry: 0,
      select: (response) => {
        const { data } = response;
        return data;
      },
    },
  );

  const { isLoading: collectionsLoading, data: collectionsData } = useQuery("saved-collections", fetchCollections, {
    enabled: isDbInitalized,
    cacheTime: 0,
    retry: 0,
    select: (response) => {
      const { data } = response;
      return { collections: data || [] };
    },
  });

  const fetchUsersSearch = async () => {
    try {
      setSearchLoading(true);
      const request = await searchUsers(searchValue);
      if (!request.body) return;

      const response = JSON.parse(request.body);
      if (!response?.results?.length) {
        setHasSearchResults(false);
        return;
      }

      const filteredUsers = response.results
        .filter(({ user }) => user.accountType === "atlassian")
        .map(({ user }) => user.accountId);

      const customFilters = [
        {
          type: "USER",
          isMultiUser: true,
          value: filteredUsers,
        },
      ];

      if (customFilters?.length) {
        setCustomSearchFilter([...customFilters]);
      }
    } catch {
      // ignore
    } finally {
      setPage(1);
      setSearchLoading(false);
    }
  };

  useEffect(() => {
    if (!loadingOrgChartSettings && widgetSettingData?.exists) {
      setIsOrgChartActive(widgetSettingData.widget?.[OrgChartPropertyKeys.IS_ACTIVE]);
      setIsOrgChartSetup(
        widgetSettingData.widget?.[OrgChartPropertyKeys.IS_ACTIVE] &&
          !!widgetSettingData.widget?.[OrgChartPropertyKeys.PARENT_USERS]?.length &&
          !!widgetSettingData.widget?.[OrgChartPropertyKeys.SELECTED_FIELD],
      );
    } else {
      setIsOrgChartActive(false);
      setIsOrgChartSetup(false);
    }
  }, [loadingOrgChartSettings, widgetSettingData]);

  useEffect(() => {
    if (isSearching && searchValue) {
      fetchUsersSearch();
    }

    if (!searchValue && !hasActiveFilters()) {
      setCustomSearchFilter([]);
      setHasSearchResults(true);
      resetFilters();
    } else if (!searchValue) {
      setCustomSearchFilter([]);
    }
  }, [isSearching, searchValue]);

  const applyFilters = (newState) => {
    setPage(1);

    setSelectedFilters([]);
    setSelectedFilters([...FilterService.mapFilters(newState)]);
  };

  const resetFilters = () => {
    setPage(1);
    const restoredFilters = filters.map((f) => ({ ...f, value: undefined }));
    setFilters([...restoredFilters]);
    setSelectedFilters([]);

    window.AP.events.emit("restore-filters-people-base");
  };

  const defaultPanels = getDefaultPeopleNetwork();
  useEffect(() => {
    reset();
  }, []);

  useEffect(() => {
    if (fieldsData) {
      setFields(fieldsData);
      setFilters(FilterService.buildFilters(fieldsData));
    }
  }, [fieldsData]);

  useEffect(() => {
    if (collectionsData) {
      setCollections(collectionsData.collections);
    }
  }, [collectionsData]);

  useEffect(() => {
    if (peopleNetworkData?.exists) {
      setRawPeopleResponse(peopleNetworkData);
      setSettings(migrateCardBackground({ ...peopleNetworkData.peoplenetwork.settings }));
    } else {
      setSettings({ ...defaultSettings });
    }
  }, [peopleNetworkData]);

  useEffect(() => {
    if (recordsData) {
      (async () => {
        try {
          setBuiltRecordsLoading(true);
          const newRecordsData = await handleRetrievedData(recordsData?.hits);
          setBuiltRecords([...newRecordsData], page);
        } catch (err) {
          console.error("Error building records", err);
        } finally {
          setBuiltRecordsLoading(false);
        }
      })();

      setTotalSize(recordsData?.totalSize || 0);
      setTotalPages(recordsData?.totalPages || 1);
    }
  }, [recordsData]);

  useEffect(() => {
    if (recordsLoading || !recordsData?.hits?.length) return;

    // in case the loaded data is not overflowing fetch next page
    if (recordsData?.hits && totalPages > 1 && totalPages > page) {
      const overflowContainer = document.getElementById("hub-overflow-container");
      if (overflowContainer && overflowContainer.scrollHeight <= overflowContainer.clientHeight) {
        setPage(page + 1);
      }
    }
  }, [recordsData, recordsLoading, totalPages, page]);

  useEffect(() => {
    getPeopleNetworkSettings({ queryKey: "people-network-settings" })
      .then((res) => {
        if (res?.data?.exists && res?.data?.peoplenetwork?.panels) {
          setPanels(res.data.peoplenetwork.panels);
        } else {
          setPanels(defaultPanels);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    getFilterOptionsFromAzure("azureManager", "").then((res) =>
      setAzureManagerOptions(res.data.options?.map((opt) => ({ ...opt, id: opt.value }))),
    );
  }, []);

  useEffect(() => {
    (async () => {
      setLoadingFilteredCollections(true);
      if (!collectionsData) return;

      const savedPanels =
        !!rawPeopleResponse?.exists && "panels" in rawPeopleResponse.peoplenetwork
          ? rawPeopleResponse.peoplenetwork?.panels
          : getDefaultPeopleNetwork();

      const showAllToAdmin = isEditing;

      if (!showAllToAdmin) {
        const foundCollections = getAllCollectionsFromPanels(savedPanels);
        const simulatedCollections = simulateCollectionsBasedOnFiltersInPanels(savedPanels);

        const [removedNonExistingCollections, deletedCollections] = separateNonExistingCollections(
          foundCollections,
          collectionsData.collections,
        );

        const foundCollectionsWithFilters = buildPanelCollectionsFilters(
          removedNonExistingCollections,
          collectionsData.collections,
        );

        let collectionsUserBelongsTo = [];
        try {
          const response = await getUserCollections([...foundCollectionsWithFilters, ...simulatedCollections]);
          collectionsUserBelongsTo = response?.data?.length ? response.data : [];
        } catch {
          collectionsUserBelongsTo = [];
        }

        const filteredPanels = savedPanels.filter((panel) => {
          if (!panel?.permissions?.length && !panel?.filters?.length) return true;

          const availableCollectionsInPanel =
            panel?.permissions?.filter((collectionId) => !deletedCollections.find((id) => id === collectionId)) || [];

          if (!availableCollectionsInPanel?.length && !simulatedCollections?.length) return true;

          const collectionThatMatchesPanel = simulatedCollections?.find((clc) => clc.panelId === panel.id)?.id;

          const matchAgainst = collectionThatMatchesPanel
            ? [...(availableCollectionsInPanel || []), collectionThatMatchesPanel]
            : availableCollectionsInPanel;

          return matchAgainst.some((collectionId) => collectionsUserBelongsTo.includes(collectionId));
        });

        setPanels([...filteredPanels]);
      } else {
        setPanels([...savedPanels]);
      }

      setLoadingFilteredCollections(false);
    })();
  }, [rawPeopleResponse, collectionsData, isCurrentUserAdmin, isEditing, setPanels]);

  const handleSavePeopleNetworkSettings = async (callback = () => {}) => {
    try {
      await savePeopleNetworkSettings({ settings, panels });
    } catch (err) {
      console.log(err);
    } finally {
      callback();
      setRawPeopleResponse({ exists: true, peoplenetwork: { settings, panels } });
      setHasChanges(false);
    }
  };

  const handleSaveHeaderSettings = async (callback = () => {}) => {
    try {
      await saveHeaderSettings(headerData);
    } catch (err) {
      console.log(err);
    } finally {
      callback();
      setHasHeaderChanges(false);
    }
  };

  const handleUpdateFilter = (filter) => {
    const newState = updateFilter(filter);
    if (selectedCollection) {
      setSelectedCollection(undefined);
    }
    setFilters(newState);
    applyFilters(newState);
  };

  useEffect(() => {
    if (recordsLoading) {
      setBuiltRecordsLoading(true);
    }
  }, [filters]);

  const sum = filters.reduce((acc, filter) => {
    if (filter.value?.length) {
      return acc + 1;
    }
    return acc + 0;
  }, 0);

  const isContentLoading = fieldsLoading || isInitializedLoading;

  const removeInactiveFilters = (_filters) =>
    _filters.filter((filter) => {
      if (!filter?.value) return false;

      if (filter.type === FieldType.DATE) {
        if (filter.recurring) {
          return !!filter?.value?.period;
        } else {
          return !!filter?.value?.range?.filter((r) => !!r).length;
        }
      }

      return !!filter?.value?.length;
    });

  const hasActiveFilters = () => {
    return selectedCollection !== undefined || (filters && !!removeInactiveFilters(filters).length);
  };

  const SidebarContent = useMemo(
    () =>
      ({
        [SIDEBAR_TYPES.CONTENT_SELECTOR]: ContentSelector,
        [SIDEBAR_TYPES.PANEL_LIST]: PanelsList,
        [SIDEBAR_TYPES.PANEL_EDIT]: EditPanel,
        [SIDEBAR_TYPES.ADD_NEW_PANEL]: AddPanels,
        [SIDEBAR_TYPES.PERMISSIONS]: () => (
          <Permissions
            usePanelsStore={usePanelsStore}
            hasToggleForAnonymous={false}
            setDisableClickOutside={setDisableClickOutside}
          />
        ),
      })[sidebarType],
    [sidebarType],
  );

  const handleArrowBack = useCallback(() => {
    const previousSidebar = {
      [SIDEBAR_TYPES.PANEL_LIST]: SIDEBAR_TYPES.CONTENT_SELECTOR,
      [SIDEBAR_TYPES.PANEL_EDIT]: SIDEBAR_TYPES.PANEL_LIST,
      [SIDEBAR_TYPES.ADD_NEW_PANEL]: SIDEBAR_TYPES.PANEL_LIST,
      [SIDEBAR_TYPES.PERMISSIONS]: SIDEBAR_TYPES.PANEL_EDIT,
    }[sidebarType];
    setSidebarType(previousSidebar);
  }, [sidebarType]);

  const getSidebarTitle = useCallback(() => {
    if (sidebarType === SIDEBAR_TYPES.CONTENT_SELECTOR) return "Customize People Network";
    if (sidebarType === SIDEBAR_TYPES.PANEL_LIST)
      return `${selectedContentType.charAt(0).toUpperCase()}${selectedContentType.slice(1)} Design`;
    if (sidebarType === SIDEBAR_TYPES.PANEL_EDIT) return `${editingPanel?.data.title ?? "Panel"}`;
    if (sidebarType === SIDEBAR_TYPES.PERMISSIONS) return "Permissions";
    if (sidebarType === SIDEBAR_TYPES.ADD_NEW_PANEL) return "Add New Widget";
  }, [sidebarType, selectedContentType, editingPanel]);

  useEffect(() => {
    sortRecords(sorting, builtRecords).then((result) => {
      setBuiltRecords(result, page);
    });
  }, [sorting, recordsData]);

  useEffect(() => {
    setIsSearching(hasActiveFilters() || !!searchValue);
  }, [selectedCollection, filters, searchValue]);

  const handleSaveSidebarChanges = () => {
    if (hasChanges) return handleSavePeopleNetworkSettings();
    if (hasHeaderChanges) return handleSaveHeaderSettings();
  };

  return (
    <PeopleNetworkAPIContext.Provider value={{ handleSaveSidebarChanges }}>
      <SpotlightManager>
        {!recordsLoading && !builtRecordsLoading && !fieldsLoading && (
          <PeopleNetworkWizard
            currentWizardStep={currentWizardStep}
            setCurrentWizardStep={setCurrentWizardStep}
            updateWizardProperties={updateWizardProperties}
          />
        )}
        <div>
          <PageHeaderCentered
            {...headerData[Pages.PEOPLE]}
            searchPlaceholder="Search people"
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            isSearching={isSearching}
            setIsSearching={setIsSearching}
            headerType={headerType}
          />

          {!!isInitialized ? (
            <>
              {isCurrentUserAdmin && (
                <>
                  {!fieldsLoading && !recordsLoading && !isInitializedLoading && !profileNetworkLoading && (
                    <SpotlightTarget name="edit-people-network">
                      <EditPanelsButton
                        openSidebar={() => setIsEditing(true)}
                        primaryColor="#0065ff"
                        primaryContrastColor="#ffffff"
                        isDisabled={currentWizardStep !== null}
                      />
                    </SpotlightTarget>
                  )}

                  <SidebarTransition
                    key="people-network-settings"
                    isOpen={isEditing}
                    close={() => {
                      setIsEditing(false);
                      setSelectedSidebarTab(undefined);
                      setSidebarType(SIDEBAR_TYPES.CONTENT_SELECTOR);
                    }}
                    width={360}
                    title={getSidebarTitle()}
                    subtitle={sidebarType === SIDEBAR_TYPES.PERMISSIONS && editingPanel?.panelTitle}
                    customScrollbarHeight="calc(100% - 45px)"
                    isPanelList={sidebarType === SIDEBAR_TYPES.PANEL_LIST && selectedContentType !== "header"}
                    isAddNew
                    useArrowBack={sidebarType !== SIDEBAR_TYPES.CONTENT_SELECTOR}
                    onArrowBackClick={handleArrowBack}
                    disabledClickOutside={isEditingHeader || disableClickOutside}
                    hasChanges={hasChanges || hasHeaderChanges}
                    onSave={handleSaveSidebarChanges}
                    showExtraIcon={sidebarType === SIDEBAR_TYPES.PANEL_EDIT}
                    ExtraIcon={PermissionsIcon}
                    handleClickOnExtraIcon={() => setSidebarType(SIDEBAR_TYPES.PERMISSIONS)}
                  >
                    <SidebarContent />
                  </SidebarTransition>
                </>
              )}

              {isContentLoading && (
                <SpinnerContainer>
                  <Spinner size="xlarge" />
                </SpinnerContainer>
              )}

              {!isContentLoading && (
                <ContentWrapper>
                  <FiltersCollectionsContainer>
                    {isOrgChartActive && (
                      <ViewSelect
                        selectedView={selectedView}
                        setSelectedView={setSelectedView}
                        primaryColor={primaryColor}
                      />
                    )}
                    {selectedView === Views.GRID && (
                      <>
                        <SpotlightTarget key="people-network-content" name="people-network-content">
                          <FiltersContainer>
                            <LabelContainer>
                              FILTER BY
                              <ShowHideMoreButton
                                onClick={() => {
                                  setShowAllFilters(!showAllFilters);
                                }}
                              >
                                <span style={{ color: primaryColor, fontWeight: 500 }}>
                                  {!showAllFilters ? "Show more" : "Show less"}
                                </span>
                              </ShowHideMoreButton>
                            </LabelContainer>
                            <Filter
                              fields={fields}
                              filters={filters}
                              resetFilters={resetFilters}
                              updateFilter={handleUpdateFilter}
                              showAllFilters={showAllFilters}
                              backgroundColor={backgroundColor}
                            />

                            <LabelContainer style={{ marginTop: "28px" }}>SORT BY</LabelContainer>
                            <Sort
                              sortOrder={sorting?.order}
                              sortField={sorting?.field}
                              onSortChange={(field) => setSorting({ ...sorting, field })}
                              onOrderChange={(order) => setSorting({ ...sorting, order })}
                            />
                          </FiltersContainer>
                        </SpotlightTarget>

                        <DividerLine />

                        {collections?.length || isCurrentUserAdmin ? (
                          <CollectionsFilter
                            collections={collections}
                            selectedCollection={selectedCollection}
                            setSelectedCollection={setSelectedCollection}
                            filters={filters}
                            setFilters={setFilters}
                            applyFilters={applyFilters}
                            resetFilters={resetFilters}
                          />
                        ) : (
                          <></>
                        )}
                      </>
                    )}
                  </FiltersCollectionsContainer>

                  {selectedView === Views.ORGCHART && isOrgChartSetup && (
                    <ActionsToolbar enableActionsAlways disableAbsolute zoom={orgChartZoom} setZoom={setOrgChartZoom} />
                  )}

                  <ContentContainer shouldBeFullWidth={selectedView === Views.ORGCHART}>
                    {selectedView === Views.GRID && (
                      <>
                        {hasActiveFilters() && (
                          <FilteredRecordsFiltersContainer>
                            <SmallTitle>
                              {selectedCollection ? selectedCollection.name : "Showing results for:"}
                            </SmallTitle>
                            <Subtitle>
                              <>
                                {totalSize === 1
                                  ? `${totalSize} result filtered by`
                                  : `${totalSize} results filtered by`}
                              </>
                              {removeInactiveFilters(filters).map((filter) => {
                                return (
                                  <FilterProvider
                                    key={filter.column}
                                    filter={filter}
                                    azureManagerOptions={azureManagerOptions}
                                    field={fields.find((field) => field.id === filter.column)}
                                    removeCallback={async (value) => {
                                      let newFilters = [...filters];
                                      const currentFilter = newFilters.find((x) => x.id === filter.id);
                                      if (!value) {
                                        currentFilter.value = undefined;
                                      } else {
                                        const currentFilter = newFilters.find((x) => x.id === filter.id);
                                        currentFilter.value = currentFilter.value.filter((x) => x !== value);
                                        if (!currentFilter.value.length) {
                                          currentFilter.value = undefined;
                                        }
                                      }
                                      if (selectedCollection) {
                                        setSelectedCollection(undefined);
                                      }
                                      setFilters(newFilters);
                                      applyFilters(newFilters);
                                      return true;
                                    }}
                                  />
                                );
                              })}
                            </Subtitle>
                          </FilteredRecordsFiltersContainer>
                        )}
                        {searchLoading ? (
                          <SpinnerContainer>
                            <Spinner size="xlarge" />
                          </SpinnerContainer>
                        ) : (
                          <>
                            {(!!builtRecords?.length || builtRecordsLoading) && hasSearchResults ? (
                              <StartScreen
                                totalSize={totalSize}
                                page={page}
                                hasNextPage={totalPages > page}
                                fetchNextPage={() => setPage(page + 1)}
                                hasActiveFilters={hasActiveFilters()}
                                isSearching={isSearching}
                                isLoading={builtRecordsLoading}
                              />
                            ) : hasSearchResults ? (
                              <EmployeesEmptyState
                                Image={NoNewStartersSVG}
                                title="No employees to display"
                                description="Add, remove or adjust filters to show employees."
                              />
                            ) : (
                              <NoResultsState isPeopleSearch />
                            )}
                          </>
                        )}
                      </>
                    )}
                    {selectedView === Views.ORGCHART && <OrgChart />}
                  </ContentContainer>
                </ContentWrapper>
              )}
            </>
          ) : (
            <>{!isInitializedLoading && <EmptyState isAdminView={isCurrentUserAdmin} />}</>
          )}
        </div>
      </SpotlightManager>
    </PeopleNetworkAPIContext.Provider>
  );
}

export default PeopleBase;
