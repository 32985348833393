import React from "react";
import styled from "styled-components";
import Button, { LoadingButton } from "@atlaskit/button";

const CustomModalWrapper = styled.div`
  position: fixed;
  z-index: 99999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.4);
`;

const CustomModalBackground = styled.div`
  background-color: ${({ theme }) => theme.newsMetadataDialog.background};
  margin: 10% auto;
  padding: 24px;
  border-radius: 3px;
  width: 500px;
  height: ${({ customHeight }) => customHeight ?? "450px"};
`;

const CustomModalContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const CustomModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  height: 30px;
`;

const CustomModalBody = styled.div`
  height: 100%;
  width: 100%;
  box-sizing: border-box;
`;

const CustomModalHeading = styled.h1`
  color: ${({ theme }) => theme.newsMetadataSettings.title};

  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin: 0;
`;

const CustomModalSubheading = styled.h3`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #777f8c;
`;

const CustomModalFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
`;

const CustomModalButtons = styled.div`
  display: flex;
  gap: 12px;
`;

function CustomModal({
  heading,
  subheading,
  primaryButtonText = "Add",
  children,
  onCancel,
  onNext,
  disabled,
  isUpdateDone,
  customHeight = "450px",
}) {
  return (
    <CustomModalWrapper>
      <CustomModalBackground customHeight={customHeight}>
        <CustomModalContent>
          <CustomModalHeader>
            <CustomModalHeading>{heading}</CustomModalHeading>{" "}
            <CustomModalSubheading>{subheading}</CustomModalSubheading>
          </CustomModalHeader>

          <CustomModalBody>{children}</CustomModalBody>

          <CustomModalFooter>
            <CustomModalButtons>
              <Button appearance="subtle" onClick={() => onCancel()}>
                Cancel
              </Button>
              <LoadingButton
                appearance="primary"
                onClick={() => onNext()}
                isDisabled={disabled}
                isLoading={isUpdateDone}
              >
                {primaryButtonText}
              </LoadingButton>
            </CustomModalButtons>
          </CustomModalFooter>
        </CustomModalContent>
      </CustomModalBackground>
    </CustomModalWrapper>
  );
}

export default CustomModal;
