import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 35px 30px;
  height: calc(100% - 64px);
  width: calc(100% - 60px);
  overflow-y: auto;
`;

export const Content = styled.div``;

export const ContentGridContainer = styled.div`
  display: grid;
  grid-template-columns: 0px auto;
`;

export const GridPlaceholder = styled.div`
  opacity: 0;
`;

export const ContentWrapper = styled.div`
  grid-area: 2 / 2;
`;

export const Title = styled.span`
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: #07145a;
`;

export const ModalTitle = styled.div`
  color: ${({ theme }) => theme.shared.settings.section.title};
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
`;

export const Description = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #8993a4;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  column-gap: 16px;
  row-gap: 12px;

  padding-bottom: 31px;

  ${Description} {
    grid-area: 2 / 2;
  }
`;

export const Divider = styled.div`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.shared.sidebar.divider};
`;

export const Section = styled.div`
  margin: 32px 0px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`;

export const SectionTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 15px;
`;

export const SectionTitle = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: ${({ theme }) => theme.global.secondary};
  margin-bottom: -2px;
`;

export const ResetButton = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: ${({ color }) => color};
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }
`;

export const InlineRadioWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 37px;

  input[type="radio"]:checked {
    background-color: ${({ primaryColor }) => primaryColor};
    border-color: ${({ primaryColor }) => primaryColor};
  }
`;

export const RadioWrapperColumns = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 300px));
  align-items: flex-start;
  column-gap: 36px;
  row-gap: 36px;

  margin-top: 35px;

  input[type="radio"]:checked {
    background-color: ${({ primaryColor }) => primaryColor};
    border-color: ${({ primaryColor }) => primaryColor};
  }
`;

export const RadioButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;

  label {
    display: block;
    width: fit-content;
  }
`;

export const RadioButtonColumnsWrapper = styled.div`
  display: grid;
  grid-template-columns: 24px auto;
  column-gap: 8px;
  row-gap: 12px;
  align-items: center;
  width: fit-content;

  label {
    color: #6b778c;
    font-size: 14px;
    font-weight: 500;
  }

  .value {
    color: #8993a4;
    font-size: 14px;
    font-weight: 400;
    grid-area: 2 / 2;

    & > div:first-child {
      border-color: ${({ theme }) => theme.sidebar.seperator};
      min-width: 210px;
    }
  }
`;

export const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 18px;
  margin: 7px 0px;
  width: fit-content;
  cursor: pointer;

  & > span {
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const EditLogoButton = styled.div`
  display: grid;
  place-content: center;
  width: fit-content;
  padding: 6px 12px;
  background-color: rgba(9 30 66 / 6%);
  color: #172b4d;
  border-radius: 3px;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  cursor: pointer;
`;

export const LogoPreview = styled.img`
  width: 48px;
  height: 48px;
`;

export const ModalWrapper = styled.div`
  /* width: 35vw; */
  /* height: 30vh; */
  background: ${({ theme }) => theme.background};
  border-radius: 12px;
  padding: 24px;
  position: relative;
`;

export const ModalHeader = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: ${({ theme }) => theme.global.text.title};
`;

export const CropperContainer = styled.div`
  margin: 20px 0px;
  background: ${({ theme }) => theme.shared.form.imageEditor.cropperBackground};
  border: 1px dashed ${({ theme }) => theme.shared.form.imageEditor.cropperBorder};
  border-radius: 4px;
  position: relative;
  width: 100%;
  height: 300px;
`;

export const EmptyStateContainer = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  place-content: center;
  row-gap: 10px;

  svg {
    margin: 0 auto;
  }

  & > div {
    font-weight: 500;
    font-size: 31px;
    line-height: 24px;
    color: #6b778c;
    text-align: center;
  }
`;

export const ImageSizePreview = styled.div`
  margin-top: 5px;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme }) => theme.global.text.title};
`;

export const SizeErrorText = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: red;
`;

export const Upload = styled.label`
  margin-top: 20px;
  display: block;
  user-select: none;
  border-radius: 8px;
  padding: 6px 12px;
  font-size: 14px;
  cursor: pointer;
  text-align: center;
  background: #0065ff;
  color: #fff;
  width: fit-content;

  &:hover {
    background: #0065ffcc;
  }
  &:active {
    background: #0065ffee;
  }
`;

export const Button = styled.div`
  border-radius: 8px;
  padding: 12px;
  font-size: 14px;
  cursor: pointer;
  text-align: center;
  flex: 1;
  background: ${({ bg }) => bg};
  color: ${({ color }) => color};

  &:hover {
    background: ${({ bg }) => bg}cc;
  }
  &:active {
    background: ${({ bg }) => bg}ee;
  }
`;

export const ModalFooter = styled.div`
  position: relative;
  left: 0;
  right: 0;
  bottom: 0;

  margin-top: 25px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 10px;
`;

export const ColorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
`;

export const ColorInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 12px;
  position: relative;

  & > div:nth-of-type(2) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    row-gap: 4px;
  }
`;

export const ColorBoxWrapper = styled.div`
  cursor: pointer;
  box-sizing: border-box;
  width: 40px;
  height: 40px;
  background: ${({ theme }) => theme.shared.sidebar.colorBackground};
  border-radius: 3px;

  display: grid;
  place-content: center;

  & > div:first-child {
    width: 32px;
    height: 32px;
    border-radius: 1px;
    background-color: ${({ bgColor }) => bgColor};
  }
`;

export const ColorTitle = styled.span`
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #6b778c;
`;
