import { useQueries } from "react-query";
import { getSpaceByKey, mapSpace } from "../api";

export const useSpaces = (data) =>
  useQueries(
    data.map((space) => {
      return {
        queryKey: ["space", space.spaceKey, space.id],
        queryFn: () => {
          if (!space.spaceKey) {
            return { isNew: true, loading: false, id: space.id };
          }
          return getSpaceByKey(space.spaceKey);
        },
        retry: 0,
        placeholderData: {
          spaceKey: space.spaceKey,
          loading: true,
          isPlaceholder: true,
        },
        select: (response) => {
          if (response.isPlaceholder || response.isNew) {
            return response;
          }
          const result = JSON.parse(response.body);
          return {
            ...mapSpace(result),
            id: data.find((x) => x.id.toString() === space.id).id,
          };
        },
      };
    })
  );
