import React, { useMemo } from "react";
import Select from "@atlaskit/select";
import WarningIcon from "@atlaskit/icon/glyph/warning";
import SizeButtons from "../../../../../../../Shared/Components/SizeButtons";
import {
  DesignSidebarWrapper,
  LabeledContainer,
  LabeledTitle,
  TabContent,
  TextInputWrapper,
} from "../../../styled/sidebar";
import TextField from "@atlaskit/textfield";
import TextArea from "@atlaskit/textarea";

import SELECT_STYLES from "../../../../../../ContentBuilder/Shared/Filter/CustomSelectStyle";
import { fetchUserDatabaseFields } from "../../../../../../UserDatabase/datagrid/api";
import { useQuery } from "react-query";
import { PanelTypes } from "../../panelTypes";
import { sizeType } from "../../../../../../../Shared/Components/size-options";
import useSavedCollections from "../../../../../../../Shared/Hooks/useSavedCollections";
import SelectWithCustomOptions from "../../../../../../ContentBuilder/Shared/Filter/SelectWithCustomOptions";
import { PaginationOptions } from "../../../../Shared/PanelsPagination/pagination-options";
import { getWidgetSettingById } from "../../../../Settings/Widgets/api";
import { WidgetIds } from "../../../../Settings/Widgets/consts/tabs";
import { WarningPanel } from "../../../../Dashboard/panels/edit/types/styled";
import { Pages } from "../../../../../pages";
import { SectionKeys, SectionPagesIDs } from "../../../../Settings/sections";
import SelectFilter from "../../../../Dashboard/filter/SelectFilter";
import { UsersPropertyKeys } from "../../../../Dashboard/panels/edit/types/NewUsersEditor";
import { useSidebarStore } from "../../../store/sidebar-store";

const LayoutSidebar = ({ panel, updatePanelDataProperty, fieldsData, collectionsData }) => {
  const setDisableClickOutside = useSidebarStore((state) => state.setDisableClickOutside);

  const formattedTitleOptions = fieldsData?.singleValueFields?.map((field) => ({
    label: field.title,
    value: field.id,
  }));
  const formattedFooterOptions = [...(fieldsData?.singleValueFields || []), ...(fieldsData?.dateFields || [])]?.map(
    (field) => ({
      label: field.title,
      value: field.id,
    }),
  );

  const { data: widgetSettingData } = useQuery(
    ["new-starters-global-setting", { widgetId: WidgetIds.NEW_STARTERS }],
    getWidgetSettingById,
    {
      retry: 0,
      select: (response) => {
        const { data } = response;
        return data;
      },
    },
  );

  const hasSelectedField = useMemo(
    () => !!widgetSettingData?.exists && widgetSettingData?.widget?.selectedField,
    [widgetSettingData],
  );

  const { collections } = collectionsData;

  const formattedCollections = collections?.map((collection) => ({
    label: collection.name,
    value: collection.id,
  }));

  return (
    <div>
      <TabContent>
        {!hasSelectedField && (
          <WarningPanel style={{ marginTop: "20px" }}>
            <WarningIcon size="large" primaryColor="#ff991f" />
            <span>
              To activate the widget, please ensure you select the date field from the employee database in{" "}
              <a
                href={`${window.AP._hostOrigin}/wiki/plugins/servlet/ac/com.caelor.confluence.cloud.cosmos/caelor-cosmos-portal?ac.cosmosPage=${Pages.SETTINGS}-${SectionKeys.PEOPLE_BASE}-${SectionPagesIDs.WIDGETS}&ac.widgetTab=${WidgetIds.NEW_STARTERS}`}
                target="_parent"
                alt=""
              >
                Cosmos settings.{" "}
              </a>
              Please note that currently, the widget is displaying dummy data.
            </span>
          </WarningPanel>
        )}

        <LabeledContainer>
          <LabeledTitle>Title</LabeledTitle>
          <TextInputWrapper>
            <TextField
              value={panel.data.title}
              placeholder="Enter title.."
              type="text"
              onChange={({ target: { value } }) => updatePanelDataProperty({ id: panel.id, property: "title", value })}
            />
          </TextInputWrapper>
        </LabeledContainer>

        <LabeledContainer>
          <LabeledTitle>Description</LabeledTitle>
          <TextArea
            resize="none"
            minimumRows={5}
            placeholder="Enter description.."
            value={panel.data.description}
            onChange={({ target: { value } }) =>
              updatePanelDataProperty({ id: panel.id, property: "description", value })
            }
          />
        </LabeledContainer>

        <LabeledContainer
          onMouseEnter={() => setDisableClickOutside(true)}
          onMouseLeave={() => setDisableClickOutside(false)}
        >
          <LabeledTitle>Collections</LabeledTitle>
          <Select
            menuPlacement="auto"
            styles={SELECT_STYLES}
            value={formattedCollections?.find((collection) => collection.value === panel.data?.collection)}
            onChange={(option) =>
              updatePanelDataProperty({ id: panel.id, property: UsersPropertyKeys.COLLECTION, value: option?.value })
            }
            options={formattedCollections}
            placeholder="Select.."
            isClearable
          />
        </LabeledContainer>

        <SelectWithCustomOptions
          initialValue={panel.data.pagination}
          options={PaginationOptions}
          label="Pagination"
          onUpdate={(value) => updatePanelDataProperty({ id: panel.id, property: UsersPropertyKeys.PAGINATION, value })}
          customContainerStyle={{ marginTop: "0px" }}
        />

        <LabeledContainer>
          <LabeledTitle>Fields to display</LabeledTitle>

          <SelectFilter
            value={panel.data?.titleFieldId}
            setValue={(newValue) => {
              updatePanelDataProperty({ id: panel.id, property: UsersPropertyKeys.TITLE_FIELD_ID, value: newValue });
            }}
            options={formattedTitleOptions}
            isDisabled={!hasSelectedField}
            noDetails
            menuPlacement="bottom"
          />

          <SelectFilter
            value={panel.data?.footerFieldId}
            setValue={(newValue) => {
              updatePanelDataProperty({ id: panel.id, property: UsersPropertyKeys.FOOTER_FIELD_ID, value: newValue });
            }}
            options={formattedFooterOptions}
            isDisabled={!hasSelectedField}
            noDetails
            menuPlacement="bottom"
          />
        </LabeledContainer>
      </TabContent>
    </div>
  );
};

const DesignSidebar = ({ panel, updatePanelDataProperty }) => {
  const stylingOptions = [
    { label: "None", value: "none" },
    { label: "Profile header image", value: "image" },
  ];

  return (
    <DesignSidebarWrapper>
      <TabContent>
        <LabeledContainer>
          <LabeledTitle>Styling</LabeledTitle>
          <Select
            menuPlacement="auto"
            styles={SELECT_STYLES}
            onChange={({ label, value }) =>
              updatePanelDataProperty({
                id: panel.id,
                property: "backgroundType",
                value: value,
              })
            }
            value={stylingOptions?.find((option) => option.value === panel.data.backgroundType)}
            options={stylingOptions}
            placeholder="Choose a card background"
          />
        </LabeledContainer>

        <SizeButtons
          label="Profile image size"
          types={{ panel: PanelTypes.NEW_STARTERS, size: sizeType.PROFILE_IMAGE_SIZE }}
          input={{ min: 0, max: 150, step: 2, value: panel.data.profileImageSize }}
          setValue={(value) => updatePanelDataProperty({ id: panel.id, property: sizeType.PROFILE_IMAGE_SIZE, value })}
        />
      </TabContent>
    </DesignSidebarWrapper>
  );
};

const sidebars = {
  Layout: LayoutSidebar,
  Design: DesignSidebar,
};

const NewStartersEdit = ({ panel, selectedTab, updatePanelDataProperty }) => {
  const Component = sidebars[selectedTab];

  const { data: fieldsData } = useQuery(["userdatabase-fields", { includeEmail: false }], fetchUserDatabaseFields, {
    retry: 0,
    select: (response) => {
      const { data } = response;

      const dateFields = data?.filter((field) => field.type === "DATE") || [];
      const singleValueFields = data?.filter((field) => field.type === "SELECT" || field.type === "TEXT");

      return { dateFields, singleValueFields };
    },
  });

  const { collectionsData } = useSavedCollections({ areOptions: true });

  return (
    <Component
      panel={panel}
      fieldsData={fieldsData}
      updatePanelDataProperty={updatePanelDataProperty}
      collectionsData={collectionsData}
    />
  );
};

export default NewStartersEdit;
