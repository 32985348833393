/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

import Textfield from "@atlaskit/textfield";
import Toggle from "@atlaskit/toggle";

import { useClickOutside } from "./useClickOutside";
import SidebarTransition from "./SidebarTransition";
import SimpleBar from "./Components/Simplebar";
import SingleColorPicker from "./SingleColorPicker";

import Portal from "./Components/Portal";
import { BackgroundDialogContext } from "./Context";

import {
  Reset,
  Content,
  SidebarContainer,
  ContentContainer,
  VerticalRule,
  ColorOverlayActions,
  ColorOverlayInputsContainer,
  ImagePreviewContainer,
  ColorInputsContainer,
  ImagePreviewOverlay,
  ImagePreviewDescription,
  Divider,
  TextFieldContainer,
} from "./styled/StyledComponents";

import Unsplash from "./Components/Unsplash";
import LinkTab from "./Components/Link";
import Attachments from "./Components/Attachments";

import { convertHexToRGB } from "./convertHexToRgb";
import { DefaultTabSources, ColorTabSources } from "./TabSources";
import SidebarCustomRange from "../../../Components/ContentBuilder/Shared/SidebarCustomRange";
import Color from "./Components/Color";
import ImagePreview from "./Components/ImagePreview";
import debounce from "lodash.debounce";
import { IMAGE_TYPES } from "./ImageSourceTypes";
import { ConfirmDeleteModal } from "../../../Components/Hub/Components/Shared/ConfirmDeleteModal";
import { useTheme } from "styled-components";
function BackgroundPopup({
  image,
  setImage,
  overlay,
  setOverlay,
  isOpen,
  onClose,
  dialogPositionCss,
  position = "fixed",
  resetBackground,
  showOverlay = true,
  showZoom = false,
  linkToDocs = "",
  hasUpload = false,
  showCurrentPageInAttachments = true,
  hasColorTabs = false,
  primaryColor = "#0065ff",
  primaryColorContrast = "#fff",
  moveableImage = false,
  showVideoInAttachments = true,
  defaultImage = "",
  zIndex = 100,
  boxWidth,
  boxHeight,
}) {
  const [selectedTab, setSelectedTab] = useState(DefaultTabSources.Unsplash);
  const [colorOverlayActive, setColorOverlayActive] = useState(false);
  const [colorOverlayColor, setColorOverlayColor] = useState("#000000");
  const [colorOverlayOpacity, setColorOverlayOpacity] = useState(0.3);

  const [resetHeaderModalVisible, setResetHeaderModalVisible] = useState(false);

  const [zoom, setZoom] = useState(1);

  const [showReset, setShowReset] = useState(true);

  const theme = useTheme();

  const imageRef = useRef();
  imageRef.current = image;

  const popupRef = useRef(null);
  useClickOutside(popupRef, () => {
    if (resetHeaderModalVisible) return;

    onClose();
  });

  const getProps = {
    image,
    linkToDocs,
  };

  const setProps = {
    setImage,
  };

  useEffect(() => {
    if (Object.keys(overlay).length) {
      setColorOverlayActive(overlay?.isActive || false);
      setColorOverlayColor(overlay?.color || "#000000");
      setColorOverlayOpacity(overlay?.opacity ?? 0.3);
    }
  }, [overlay]);

  useEffect(() => {
    if (image?.link === defaultImage) {
      setShowReset(false);
    } else {
      setShowReset(true);
    }

    if (image?.zoom) {
      setZoom(image.zoom);
    }
  }, [image]);

  const handleZoomChangesDebounce = useRef(
    debounce((newZoom) => {
      const curZoom = imageRef?.current?.zoom;

      if (curZoom !== newZoom) {
        setImage({ ...imageRef.current, zoom: newZoom });
      }
    }, 300),
  ).current;

  useEffect(() => {
    return () => {
      handleZoomChangesDebounce.cancel();
    };
  }, [handleZoomChangesDebounce]);

  useEffect(() => {
    handleZoomChangesDebounce(zoom);
  }, [zoom]);

  return (
    <Portal contentVisible={isOpen}>
      <BackgroundDialogContext.Provider value={{ ...getProps, ...setProps }}>
        <SidebarContainer dialogPositionCss={dialogPositionCss} position={position} ref={popupRef}>
          <SidebarTransition
            key="background-popup"
            isOpen={isOpen}
            position="right"
            close={() => {
              if (resetHeaderModalVisible) return;
              onClose(image.source);
            }}
            width={835}
            showTabs
            shouldHideScrollbar
            className="selector-sidebar"
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            tabs={Object.values(hasColorTabs ? ColorTabSources : DefaultTabSources)}
            containerStyles={{ zIndex }}
          >
            <ContentContainer>
              <Content>
                {selectedTab === ColorTabSources.Unsplash && isOpen && <Unsplash />}
                {selectedTab === ColorTabSources.Link && <LinkTab />}
                {selectedTab === ColorTabSources.Color && <Color primaryColor={primaryColor} />}
                {selectedTab === ColorTabSources.Attachments && (
                  <Attachments
                    showCurrentPageInAttachments={showCurrentPageInAttachments}
                    hasUpload={hasUpload}
                    showVideoInAttachments={showVideoInAttachments}
                  />
                )}
              </Content>

              <Content>
                <SimpleBar
                  style={{
                    height: "calc(100% - 90px)",
                    width: "378px",
                    paddingRight: "15px",
                    position: "absolute",
                  }}
                >
                  {showReset && <Reset onClick={() => setResetHeaderModalVisible(true)}>Reset to default</Reset>}

                  <ImagePreviewContainer>
                    {overlay?.isActive && <ImagePreviewOverlay color={convertHexToRGB(overlay)} />}
                    <ImagePreview moveableImage={moveableImage} />
                  </ImagePreviewContainer>
                  {(image.source === IMAGE_TYPES.LINK || image.source === IMAGE_TYPES.UNSPLASH) && moveableImage && (
                    <ImagePreviewDescription>
                      Drag the background image to the desired position.
                    </ImagePreviewDescription>
                  )}

                  {(image.source === IMAGE_TYPES.LINK || image.source === IMAGE_TYPES.UNSPLASH) && showZoom && (
                    <div style={{ marginTop: "32px" }}>
                      <SidebarCustomRange
                        label="Zoom"
                        value={zoom}
                        setValue={(v) => setZoom(v)}
                        min={1}
                        max={5}
                        step={0.1}
                        isDisabled={!image?.link}
                      />
                    </div>
                  )}

                  {showOverlay && (
                    <ColorOverlayActions style={{ marginTop: "20px" }}>
                      <span>Color overlay</span>
                      <Toggle
                        onChange={() => {
                          setColorOverlayActive(!colorOverlayActive);
                          setOverlay({
                            isActive: !colorOverlayActive,
                            color: colorOverlayColor,
                            opacity: colorOverlayOpacity,
                          });
                        }}
                        isChecked={colorOverlayActive}
                      />
                    </ColorOverlayActions>
                  )}

                  {colorOverlayActive && (
                    <ColorOverlayInputsContainer>
                      <SingleColorPicker
                        label=""
                        value={colorOverlayColor.slice(0, 7)}
                        setValue={(v) => {
                          setColorOverlayColor(v.slice(0, 7));
                          setOverlay({
                            isActive: colorOverlayActive,
                            color: v.slice(0, 7),
                            opacity: colorOverlayOpacity,
                          });
                        }}
                        disableAlpha
                        boxWidth={boxWidth}
                        boxHeight={boxHeight}
                      />
                      <ColorInputsContainer firstColumnWidth="40px">
                        <span
                          style={{
                            color: theme.global.secondary,
                            opacity: "0.5",
                            fontSize: "10px",
                          }}
                        >
                          Opacity
                        </span>
                        <div style={{ width: "280px" }}>
                          <SidebarCustomRange
                            step={5}
                            min={0}
                            max={100}
                            value={Math.floor(colorOverlayOpacity * 100)}
                            setValue={(value) => {
                              setColorOverlayOpacity(value / 100);
                              setOverlay({
                                isActive: colorOverlayActive,
                                color: colorOverlayColor,
                                opacity: value / 100,
                              });
                            }}
                            shouldShowInput
                          />
                        </div>
                      </ColorInputsContainer>
                    </ColorOverlayInputsContainer>
                  )}

                  <Divider />

                  {(image.source === IMAGE_TYPES.ATTACHMENTS ||
                    image.source === IMAGE_TYPES.LINK ||
                    image.source === IMAGE_TYPES.UNSPLASH) && (
                    <TextFieldContainer>
                      <label>Link to image</label>
                      <Textfield isReadOnly className="link-display" value={image?.link || ""} />
                    </TextFieldContainer>
                  )}
                </SimpleBar>
              </Content>
            </ContentContainer>
            <VerticalRule />
          </SidebarTransition>
        </SidebarContainer>

        <ConfirmDeleteModal
          onClickOutside={() => setResetHeaderModalVisible(false)}
          onDiscard={() => setResetHeaderModalVisible(false)}
          onConfirm={() => {
            resetBackground();
            setResetHeaderModalVisible(false);
          }}
          visible={resetHeaderModalVisible}
          deleteInfo={{
            title: "Reset to default?",
            description: "All changes will be reset to default.",
          }}
          buttonInfo={{
            mainText: "Reset to default",
            secondaryText: "Cancel",
            appearance: "primary",
          }}
        />
      </BackgroundDialogContext.Provider>
    </Portal>
  );
}

BackgroundPopup.propTypes = {
  image: PropTypes.object.isRequired,
  setImage: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  resetBackground: PropTypes.func.isRequired,
  dialogPositionCss: PropTypes.shape({
    top: PropTypes.string,
    bottom: PropTypes.string,
    right: PropTypes.string,
    left: PropTypes.string,
  }),
  position: PropTypes.string,
  overlay: PropTypes.object,
  setOverlay: PropTypes.func,
  showOverlay: PropTypes.bool,
  linkToDocs: PropTypes.string,
};

BackgroundPopup.defaultProps = {
  showOverlay: true,
  linkToDocs: "",
  position: "fixed",
  overlay: {},
  setOverlay() {},
  dialogPositionCss: {
    bottom: "0",
  },
};

export default BackgroundPopup;
