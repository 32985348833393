import { contentPropertyService } from "../../Service/ContentPropertyService";

export const getProperty = async (blogId, propertyKey) => {
  try {
    const result = await contentPropertyService.getBlogsContentProperty({
      blogPostId: blogId,
      key: propertyKey,
    });
    return result?.data?.[0];
  } catch (error) {
    return undefined;
  }
};

export const updateProperty = async (blogId, propertyId, propertyKey, version, value) => {
  try {
    await contentPropertyService.updateBlogsContentProperty({
      blogPostId: blogId,
      key: propertyKey,
      propertyId: propertyId,
      newValue: value,
      version: version,
    });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const deleteProperty = async (blogId, propertyId) => {
  try {
    await contentPropertyService.deleteBlogsContentProperty({
      blogPostId: blogId,
      key: propertyId,
    });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const createProperty = async (blogId, value, propertyKey) => {
  try {
    await contentPropertyService.createBlogsContentProperty({
      blogPostId: blogId,
      key: propertyKey,
      value: value,
    });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};
