import React, { useEffect, useState } from "react";
import Spinner from "@atlaskit/spinner";
import { SpinnerContainer } from "../../../../styled/pages";
import { getWidgetSettingById } from "../../../Settings/Widgets/api";
import { WidgetIds } from "../../../Settings/Widgets/consts/tabs";
import { useContentVizConnectionStore } from "../../../../../../Shared/store/contentviz-connection-store";
import { useQuery } from "react-query";
import { Panel, PanelContent } from "../../../Dashboard/dashboard-styled";
import OrgChartBuilder from "../../../OrgChart/OrgChartBuilder";
import { getAccountId } from "../../profile.service";
import { useCurrentUserStore } from "../../../../current-user-store";
import { OrgChartPropertyKeys } from "../../../Settings/Widgets/components/types/orgchart/available-properties";
import EmptyState, { EmptyStateTypes } from "../../../OrgChart/components/EmptyState";
import { createProfileLink } from "../../../PeopleBase/Views/createProfileLink";

const OrgChart = () => {
  const isContentVizInstalled = useContentVizConnectionStore((state) => state.isContentVizInstalled);
  const { isCurrentUserAdmin, currentUser } = useCurrentUserStore((state) => ({
    isCurrentUserAdmin: state.isCurrentUserAdmin,
    currentUser: state.currentUser,
  }));

  const [isOrgChartSetup, setIsOrgChartSetup] = useState(false);
  const [orgChartSettings, setOrgChartSettings] = useState();
  const [user, setUser] = useState();

  const { isLoading: loadingOrgChartSettings, data: widgetSettingData } = useQuery(
    ["org-chart-settings", { widgetId: WidgetIds.ORG_CHART }],
    getWidgetSettingById,
    {
      retry: 0,
      select: (response) => {
        const { data } = response;
        return data;
      },
    },
  );

  useEffect(() => {
    const accountId = getAccountId();

    if (accountId) {
      setUser(accountId);
    } else {
      setUser(currentUser.accountId);
    }
  }, [currentUser]);

  useEffect(() => {
    if (!loadingOrgChartSettings && widgetSettingData?.exists) {
      setOrgChartSettings(widgetSettingData.widget);
      setIsOrgChartSetup(
        widgetSettingData.widget?.[OrgChartPropertyKeys.IS_ACTIVE] &&
          !!widgetSettingData.widget?.[OrgChartPropertyKeys.PARENT_USERS]?.length &&
          !!widgetSettingData.widget?.[OrgChartPropertyKeys.SELECTED_FIELD],
      );
    } else {
      setOrgChartSettings(undefined);
      setIsOrgChartSetup(false);
    }
  }, [loadingOrgChartSettings, widgetSettingData]);

  const handleClickOnUser = (accountId) => {
    const url = createProfileLink(accountId);
    window.open(url, "_parent");
  };

  if (loadingOrgChartSettings) {
    return (
      <SpinnerContainer>
        <Spinner size="xlarge" />
      </SpinnerContainer>
    );
  }

  return (
    <Panel style={{ padding: "0px" }}>
      <PanelContent noPadding>
        {isContentVizInstalled && isOrgChartSetup && (
          <OrgChartBuilder
            orgChartSettings={orgChartSettings}
            userToBuildFor={user}
            buildForUserProfile
            showControls
            onItemClick={handleClickOnUser}
            minZoomOnCenterView={0.75}
            initialY={30}
          />
        )}

        {!isContentVizInstalled && isCurrentUserAdmin && <EmptyState type={EmptyStateTypes.NO_LICENSE} />}

        {isContentVizInstalled && !isOrgChartSetup && isCurrentUserAdmin && (
          <EmptyState type={EmptyStateTypes.NOT_SETUP} />
        )}

        {!isCurrentUserAdmin && (!isContentVizInstalled || !isOrgChartSetup) && (
          <EmptyState type={EmptyStateTypes.REGULAR_USER} />
        )}
      </PanelContent>
    </Panel>
  );
};

export default OrgChart;
