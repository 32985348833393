import React from "react";
import EmptyState from "../../../Shared/Components/EmptyState";
import { useTheme } from "styled-components";

function NoFoundCollections() {
  const theme = useTheme();

  return (
    <EmptyState
      Image={() => {
        return <img src={`/images/hub/no-search-empty-state-${theme.global.name}.svg`} alt="" />;
      }}
      title="Nothing matches your search"
      description="Remove filters, or search for something less specific."
    />
  );
}

export default NoFoundCollections;
