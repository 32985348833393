import React, { useMemo } from "react";
import { AvailableCards } from "../card-styles/cards";
import OptionalLink from "./OptionalLink";

function CardStyleChooser({ cardData, config, cardsType }) {
  const StyledCard = useMemo(() => {
    return AvailableCards[cardsType][config.style];
  }, [config.style, cardsType]);

  return (
    <OptionalLink link={cardData.link} openInNewTab={cardData.openInNewTab}>
      <StyledCard cardData={cardData} config={config} />
    </OptionalLink>
  );
}

export default CardStyleChooser;
