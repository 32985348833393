import React, { useEffect, useRef, useState } from "react";
import { CheckboxSelect, components } from "@atlaskit/select";

import { OptionLabel, OptionIcon, CustomPlaceholder } from "./CustomCheckboxSelectComponents";

import { useClickOutside } from "../../../../../Shared/Hooks/useClickOutside";
import { atlassianRestService } from "../../../../../Service/AtlassianRestService";
import { useTheme } from "styled-components";
import EditorErrorIcon from "@atlaskit/icon/glyph/editor/error";

import { SIMPLE_SELECT_STYLES } from "../../Dashboard/filter/CustomSelectStyles";

function SpacesCheckboxSelect({
  initialSpaces,
  isMulti = true,
  label = "Spaces",
  placeholder = "Search...",
  onChange,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedSpaces, setSelectedSpaces] = useState();
  const [selectKey, setSelectKey] = useState(Math.random().toString(36).substring(7));

  const spacesCount = selectedSpaces?.length;
  const spaceItem = selectedSpaces?.map((s) => s.name)[0];

  const selectWrapperRef = useRef();
  const theme = useTheme();

  useClickOutside(selectWrapperRef, () => setIsOpen(false));

  const ClearIcon = ({ innerProps }) => {
    return (
      <div {...innerProps} style={{ marginRight: "10px", marginTop: "2px" }}>
        <EditorErrorIcon primaryColor={theme.global.secondary} width="18px" height="18px" />
      </div>
    );
  };

  useEffect(() => {
    if ((initialSpaces ?? []).length > 0) {
      const promises = initialSpaces.map(async (s) => atlassianRestService.getSpaceByKey(s));

      Promise.all(promises)
        .then((results) => {
          setSelectedSpaces(results.map((r) => _toSpaceField(r)));
        })
        .catch((e) => {
          // Handle errors here
        });
    } else {
      setSelectedSpaces([]);
    }
  }, [initialSpaces]);

  const [optionsList, setOptionsList] = useState([]);
  useEffect(() => {
    async function loadOptions(query) {
      try {
        const res = (await atlassianRestService.searchForSpace(query)).results.map(({ space }) => _toSpaceField(space));
        setOptionsList(res);
      } catch (err) {
        console.error(err.message);
      }
    }

    loadOptions();
  }, []);

  useEffect(() => {
    window.AP.events.on("restore-filters-newsroom", () => {
      setSelectKey(Math.random().toString(36).substring(7));
    });
  }, []);

  const getIconUrl = (icon) => {
    return `${window.AP._hostOrigin}/wiki/${icon.path}`;
  };

  const handlePlaceholder = (props) => {
    return <CustomPlaceholder item={spaceItem} count={spacesCount} isMulti={isMulti} fieldName="Spaces" {...props} />;
  };

  return (
    <CheckboxSelect
      key={selectKey}
      defaultOptions
      isMulti={isMulti}
      isSearchable
      backspaceRemovesValue
      closeMenuOnSelect={false}
      styles={SIMPLE_SELECT_STYLES}
      value={selectedSpaces}
      onChange={(e) => {
        if (!e) {
          onChange(isMulti ? [] : undefined);
          return;
        }
        onChange(isMulti ? e : e?.value);
        return;
      }}
      controlShouldRenderValue={false}
      options={optionsList?.map((op) => ({ ...op })) || []}
      components={{
        Placeholder: handlePlaceholder,
      }}
      formatOptionLabel={({ label, icon }) => (
        <OptionLabel>
          <OptionIcon src={getIconUrl(icon)} alt="space-icon" />
          <span>{label}</span>
        </OptionLabel>
      )}
    />
  );
}

function _toSpaceField(space) {
  return {
    icon: space.icon,
    name: space.name,
    spaceKey: space.key,
    type: "space",
    label: space.name,
    value: space.key,
    homepageId: space.homepage.id,
    coverPictureCallback: async () => {},
  };
}

export default SpacesCheckboxSelect;
