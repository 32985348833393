import React, { useState } from "react";
import styled from "styled-components";
import Tabs, { Tab, TabList, useTabPanel } from "@atlaskit/tabs";
import { useNavigationStore } from "../../Components/Navigation/store";

const TabListWrapper = styled.div`
  div[role="tablist"] {
    display: grid;
    grid-template-columns: ${({ numberOfItems }) => `repeat(${numberOfItems}, 1fr)`};
    text-align: center;
    font-size: 14px;
  }
`;

const CustomTabList = ({ useNative, children }) => {
  if (useNative) {
    return <TabList>{children}</TabList>;
  }

  return (
    <TabListWrapper numberOfItems={children.length}>
      <TabList>{children}</TabList>
    </TabListWrapper>
  );
};

const CustomTabPanel = ({ children }) => {
  const tabPanelAttributes = useTabPanel();

  return (
    <div {...tabPanelAttributes} style={{ marginTop: "20px" }}>
      {children}
    </div>
  );
};

function NativeTabs({ tabs, useNative = true, returnSelection = undefined }) {
  const [selected, setSelected] = useState(0);

  const { structure } = useNavigationStore((state) => ({
    structure: state.structure,
  }));

  return (
    <Tabs
      selected={selected}
      onChange={(idx) => {
        setSelected(idx);
        if (returnSelection) returnSelection(tabs[idx]?.id);
      }}
    >
      {structure.length ? (
        <CustomTabList useNative={useNative}>
          {tabs.map((tab) => (
            <Tab key={tab.id}>{tab.label}</Tab>
          ))}
        </CustomTabList>
      ) : (
        <></>
      )}
      {!returnSelection &&
        tabs.map(({ id, Panel }) => (
          <CustomTabPanel key={id}>
            <Panel />
          </CustomTabPanel>
        ))}
    </Tabs>
  );
}

export default NativeTabs;
