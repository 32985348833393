import React from "react";
import DatePicker from "react-date-picker/dist/entry.nostyle";
import EditorCloseIcon from "@atlaskit/icon/glyph/editor/close";
import dayjs from "dayjs";
import styled from "styled-components";

import "./DatePicker.css";
import "./Calendar.css";

const DateWrapper = styled.div`
  .react-calendar {
    background: ${({ theme }) => theme.shared.form.calendar.background};
    border-color: ${({ theme }) => theme.shared.form.calendar.border};
  }
  .react-calendar button:enabled {
    background: transparent;
  }

  .react-calendar button:enabled:hover {
    background: ${({ theme }) => theme.shared.form.calendar.hover};
  }

  .react-calendar__tile {
    &:disabled {
      background: ${({ theme }) => theme.shared.form.calendar.border};
    }
  }

  .react-calendar__navigation button:disabled {
    background: ${({ theme }) => theme.shared.form.calendar.border};
  }
  .react-date-picker__button {
    background: transparent !important;
    color: ${({ theme }) => theme.shared.form.calendar.closeIconColor} !important;
    border: none;
  }

  .react-date-picker__inputGroup {
    background: ${({ theme }) => theme.shared.form.calendar.inputBackground};
  }

  .react-date-picker__inputGroup__divider {
    color: ${({ theme }) => theme.shared.form.calendar.inputDivider};
  }

  .react-date-picker__wrapper {
    background: ${({ theme }) => theme.shared.form.calendar.inputWrapperBackground};
    color: ${({ theme }) => theme.shared.form.calendar.inputWrapperColor};
  }
`;

const CosmosDatePicker = ({ initialValue, onEdit, onAbort, minDate, maxDate, useDayjs = true }) => {
  const minimumDate = minDate ? (useDayjs ? dayjs.unix(minDate).toDate() : minDate) : undefined;
  const maximumDate = maxDate ? (useDayjs ? dayjs.unix(maxDate).toDate() : maxDate) : undefined;

  return (
    <DateWrapper>
      <DatePicker
        autoFocus
        dateFormat="DD/MM/YYYY"
        monthPlaceholder="mm"
        dayPlaceholder="dd"
        yearPlaceholder="yyyy"
        clearIcon={<EditorCloseIcon size="small" />}
        locale="en-US"
        value={initialValue ? dayjs.unix(initialValue).toDate() : null}
        onCalendarClose={() => {
          onAbort();
        }}
        onChange={(selected) => {
          if (!selected || selected.length === 0) {
            onEdit(undefined);
            return;
          }
          onEdit(dayjs(selected).unix());
        }}
        minDate={minimumDate}
        maxDate={maximumDate}
      />
    </DateWrapper>
  );
};

export default CosmosDatePicker;
