import { DrawerTypes } from "../types";

export const getTitle = (type, entry) => {
  if (type === DrawerTypes.ADD_ENTRY) return "Add new entry";
  if (type === DrawerTypes.EDIT_ENTRY) return entry?.data?.name || "Edit entry";
  return "test";
};

export const getSubtitle = (type) =>
  ({ [DrawerTypes.ADD_ENTRY]: "Choose a design style for your new entry.", [DrawerTypes.EDIT_ENTRY]: undefined })[type];
