import React, { useCallback, useState } from "react";
import styled, { useTheme } from "styled-components";
import Button from "@atlaskit/button";
import QueuesIcon from "@atlaskit/icon/glyph/queues";
import TrashIcon from "@atlaskit/icon/glyph/trash";
import EditorAddIcon from "@atlaskit/icon/glyph/editor/add";
import SectionHeader from "./SectionHeader";
import { useCategoriesStore, useModalStore } from "../../categories-store";
import { BlogsNumber, CategoryCard, CategoryCardWrapper, CategoryTitle, TrashIconWrapper } from "../styled";
import EmptyState from "../EmptyState";
import { saveCategoriesSettings } from "../../api";
import { ModalTypes } from "../modal/types";
import { ConfirmDeleteModal } from "../../../../Shared/ConfirmDeleteModal";

const SplitWrapper = styled.div`
  display: flex;
  flex-flow: row;
  gap: 10px;
  justify-content: space-between;
`;

function Categories() {
  const theme = useTheme();
  const [temporaryDeleteCategory, setTemporaryDeleteCategory] = useState({});
  const { allCategories, setAllCategories, categoriesBlogCountData, setSelectedCategory } = useCategoriesStore(
    (state) => ({
      allCategories: state.allCategories,
      setAllCategories: state.setAllCategories,
      categoriesBlogCountData: state.categoriesBlogCountData,
      setSelectedCategory: state.setSelectedCategory,
    }),
  );
  const { setIsModalOpen, setModalKey, isDeleteModalOpen, setIsDeleteModalOpen } = useModalStore((state) => ({
    setIsModalOpen: state.setIsModalOpen,
    setModalKey: state.setModalKey,
    setIsDeleteModalOpen: state.setIsDeleteModalOpen,
    isDeleteModalOpen: state.isDeleteModalOpen,
  }));

  const deleteCategory = (categoryId) => {
    const updatedCategories = allCategories.filter((category) => category.id !== categoryId);
    setAllCategories([...updatedCategories]);
    saveCategoriesSettings([...updatedCategories]);
  };

  const getCategoryNumberOfBlogs = useCallback(
    (categoryId) => {
      const found = categoriesBlogCountData?.find(({ id }) => id === categoryId)?.numberOfBlogs;
      if (typeof found === "number") {
        return `${found} ${found === 1 ? "Blog" : "Blogs"}`;
      } else {
        return "0 Blogs";
      }
    },
    [categoriesBlogCountData],
  );

  const openCreateModal = () => {
    setModalKey(ModalTypes.CREATE_CATEGORY);
    setIsModalOpen(true);
  };

  return (
    <>
      <SplitWrapper>
        <SectionHeader
          Icon={QueuesIcon}
          title="Categories"
          description="Organize your news with custom categories, independent of Confluence spaces or labels."
        />
        {!!allCategories?.length && (
          <Button appearance="primary" iconAfter={<EditorAddIcon />} onClick={() => openCreateModal()}>
            Create Category
          </Button>
        )}
      </SplitWrapper>

      {allCategories?.length ? (
        <CategoryCardWrapper>
          {allCategories.map((category) => (
            <CategoryCard
              key={category.id}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setSelectedCategory(category);
              }}
            >
              <div style={{ display: "flex", flexFlow: "column" }}>
                <CategoryTitle style={{ width: 145 }}>{category.value}</CategoryTitle>
                <BlogsNumber>{getCategoryNumberOfBlogs(category.id)}</BlogsNumber>
              </div>
              <TrashIconWrapper
                className="trash-icon-wrapper"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setTemporaryDeleteCategory({ id: category.id, title: category.value });
                  setIsDeleteModalOpen(true);
                }}
              >
                <TrashIcon primaryColor="rgba(137, 147, 164, 1)" size="small" />
              </TrashIconWrapper>
            </CategoryCard>
          ))}
        </CategoryCardWrapper>
      ) : (
        <EmptyState
          title="No categories created"
          description={`No news categories have been created yet. Click the "Create Category" button to get started.`}
          imageSrc={`/images/emptyCategories-${theme.global.name}.svg`}
          buttonText="Create Category"
          onBtnClick={() => openCreateModal()}
        />
      )}
      <ConfirmDeleteModal
        onClickOutside={() => setIsDeleteModalOpen(false)}
        onDiscard={() => {
          setIsDeleteModalOpen(false);
        }}
        onConfirm={() => {
          deleteCategory(temporaryDeleteCategory.id);
          setIsDeleteModalOpen(false);
        }}
        visible={isDeleteModalOpen}
        deleteInfo={{
          title: "Delete category",
          description: `Are you sure you want to delete category ${temporaryDeleteCategory.title}?`,
        }}
        buttonInfo={{
          mainText: "Delete",
          secondaryText: "Cancel",
          appearance: "danger",
        }}
        height="200px"
      />
    </>
  );
}

export default Categories;
