import React, { useEffect, useState } from "react";
import styled from "styled-components";
import disableScroll from "disable-scroll";
import Spinner from "@atlaskit/spinner";
import { useInfiniteQuery, useQuery } from "react-query";
import { fetchRecords, fetchUserDatabaseFields } from "../../../UserDatabase/datagrid/api";
import { SpinnerContainer } from "../../styled/pages";
import { buildDynamicStructure, buildStructureForUserProfile, filterTreeBasedOnLevel } from "./structure-builder";
import Chart from "./components/Chart";
import { ViewTypes } from "./consts";
import ActionsToolbar from "./components/ActionsToolbar";
import LevelsToolbar from "./components/LevelsToolbar";
import eventBus from "../../../../Shared/Functions/eventBus";
import { Center, PanelDescription } from "../Settings/Widgets/components/shared/styled";
import { useCurrentUserStore } from "../../current-user-store";

const ContentWrapper = styled.div`
  box-sizing: border-box;
  position: relative;
  height: 100%;
`;

function OrgChartBuilder({
  orgChartSettings,
  buildForUserProfile = false,
  userToBuildFor = undefined,
  showControls = false,
  showLevels = false,
  onItemClick,
  minZoomOnCenterView = undefined,
  initialY = undefined,
}) {
  const [foundField, setFoundField] = useState();
  const [databaseFields, setDatabaseFields] = useState([]);
  const [zoom, setZoom] = useState(100);

  const [userData, setUserData] = useState();

  const { currentUser, isCurrentUserAdmin } = useCurrentUserStore((state) => ({
    currentUser: state.currentUser,
    isCurrentUserAdmin: state.isCurrentUserAdmin,
  }));

  const [structure, setStructure] = useState();
  const [filteredStructure, setFilteredStructure] = useState();
  const [allRecords, setAllRecords] = useState([]);

  const [selectedLevel, setSelectedLevel] = useState(undefined);

  const [error, setError] = useState();

  const { selectedField, parentUsers, view, elementsToDisplay, borderRadius, lineColor } = orgChartSettings || {};

  const { isLoading: fieldsLoading, data: fieldsData } = useQuery(
    ["userdatabase-fields", { includeEmail: false }],
    fetchUserDatabaseFields,
    {
      retry: 0,
      select: (response) => {
        const { data } = response;
        return data;
      },
    },
  );

  const {
    isFetching,
    isLoading,
    data: recordsData,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery(
    [
      "userdatabase-records",
      {
        filters: [{ column: foundField?.id, type: foundField?.type, condition: "NOT", value: null }],
        options: {
          includesManagerId: true,
        },
      },
    ],
    fetchRecords,
    {
      retry: 0,
      enabled: !!foundField && !!parentUsers?.length,
      select: (response) => {
        const pages = [];
        response.pages.forEach((page) => {
          pages.push({ ...page.data });
        });
        return { pages, pageParams: response.pageParams };
      },
      getNextPageParam: (lastPage) => {
        if (lastPage.data.page >= lastPage.data.totalPages) {
          return false;
        }
        return Number(lastPage.data.page + 1);
      },
    },
  );

  useEffect(() => {
    if (userToBuildFor) {
      window.AP.request(`/rest/api/user?accountId=${userToBuildFor}`).then((response) => {
        const body = JSON.parse(response.body);
        setUserData(body);
      });
    }
  }, [userToBuildFor]);

  useEffect(() => {
    if (!fieldsLoading && fieldsData) {
      setDatabaseFields([...fieldsData]);
    }
  }, [fieldsLoading, fieldsData, setDatabaseFields]);

  useEffect(() => {
    if (selectedField && databaseFields?.length) {
      setFoundField(databaseFields.find(({ id }) => id === selectedField));
    } else {
      setFoundField(undefined);
    }
  }, [selectedField, databaseFields]);

  useEffect(() => {
    if (recordsData && !hasNextPage && !isLoading && !isFetching) {
      let records = recordsData.pages.map((p) => p.hits).flat();

      records = records.map((record) => {
        if (selectedField === "azureManager") {
          const azureManager = records.find(({ values }) => values.azureId === record.values.azureManager)?.accountId;

          return {
            ...record,
            values: {
              ...record.values,
              azureManager,
            },
          };
        }

        return record;
      });

      setAllRecords([...records]);
    }

    if (hasNextPage) {
      fetchNextPage();
    }
  }, [recordsData, hasNextPage, isLoading, isFetching, fetchNextPage]);

  useEffect(() => {
    if (allRecords?.length && parentUsers?.length && foundField?.id) {
      const builtStructure = buildDynamicStructure(allRecords, parentUsers, foundField.id, showLevels);

      if (buildForUserProfile && userToBuildFor) {
        const structureForCurrentUser = buildStructureForUserProfile(userToBuildFor, builtStructure);
        if (structureForCurrentUser?.error) {
          setError(structureForCurrentUser.error);
        } else {
          setStructure({ ...structureForCurrentUser });
        }
      } else {
        setStructure({ ...builtStructure });
      }
    } else {
      setStructure();
    }
  }, [allRecords, parentUsers, foundField?.id, buildForUserProfile, userToBuildFor, showLevels]);

  useEffect(() => {
    if (showLevels && selectedLevel) {
      const filteredTree = filterTreeBasedOnLevel(structure.children, selectedLevel);
      setFilteredStructure({ ...filteredTree });
    } else {
      setFilteredStructure({ ...structure });
    }
    setTimeout(() => {
      eventBus.dispatch("caelor-handle-center-view", { minZoomOnCenterView: 0.7 });
    }, 25);
  }, [showLevels, selectedLevel, structure]);

  const usedStructure = buildForUserProfile ? structure : filteredStructure;

  const isLoadingRecords = hasNextPage || isLoading || isFetching;

  if (!usedStructure && buildForUserProfile && !isLoadingRecords) {
    return (
      <Center style={{ padding: "50px 0px" }}>
        <img src="/images/hub/db-not-init.svg" style={{ margin: "0 auto" }} alt="" />
        <PanelDescription>
          {currentUser?.accountId === userToBuildFor ? (
            "You don't have a supervisor or you are not marked as a top level. Update your profile to complete the Org Chart."
          ) : (
            <>
              {userData?.displayName} doesn't have a supervisor or isn't marked as a top level.{" "}
              {isCurrentUserAdmin && `Update ${userData?.displayName}'s profile to complete the Org Chart.`}
            </>
          )}
        </PanelDescription>
      </Center>
    );
  }

  if (error)
    return (
      <ContentWrapper>
        <div>
          {error.type === "user-not-found" && (
            <span>Current user is not in structure. Please assign him a supervisor.</span>
          )}
        </div>
      </ContentWrapper>
    );

  return (
    <>
      {isLoadingRecords && (
        <SpinnerContainer>
          <Spinner size="large" />
        </SpinnerContainer>
      )}
      {!isLoadingRecords && usedStructure && allRecords && (
        <ContentWrapper
          onMouseEnter={(e) => {
            disableScroll.on();
          }}
          onMouseLeave={(e) => {
            disableScroll.off();
          }}
        >
          {showControls && <ActionsToolbar structure={structure} zoom={zoom} setZoom={setZoom} />}
          {showLevels && (
            <LevelsToolbar
              isShowingControls={showControls}
              selectedLevel={selectedLevel}
              setSelectedLevel={(level) => {
                setSelectedLevel(level);
              }}
              structure={structure}
            />
          )}
          <Chart
            structure={usedStructure}
            userRecords={allRecords}
            fields={databaseFields}
            viewOptions={{
              view: view || ViewTypes.CARD,
              elementsToDisplay,
              borderRadius: borderRadius >= 0 ? borderRadius : 3,
              lineColor: lineColor || "#4C9AFF",
            }}
            onItemClick={onItemClick}
            minZoomOnCenterView={minZoomOnCenterView}
            initialY={initialY}
          />
        </ContentWrapper>
      )}
    </>
  );
}

export default OrgChartBuilder;
