import React, { useEffect, useState } from "react";
import styled, { useTheme } from "styled-components";
import Spinner from "@atlaskit/spinner";
import { Panel, PanelContent, PanelTitle } from "../../dashboard-styled";
import { useSidebarStore } from "../edit/sidebar/sidebar-store";
import { PanelPositioning } from "../panelTypes";
import { useCorporateIdentityStore } from "../../../Settings/General/BrandAndColors/corporate-identity-store";
import PanelWrapper from "../../../Shared/Panels/shared/components/PanelWrapper";
import Pagination from "../../../Shared/PanelsPagination/Pagination";
import { PanelsPaginationTypes } from "../../../Shared/PanelsPagination/panels-pagination-types";
import EmptyState from "../../../../../../Shared/Components/EmptyState";

const LinksWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-template-rows: 1fr;
  grid-gap: 32px;
`;

const LinksWrapperMagazine = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-template-rows: 1fr;
  grid-gap: 32px;
  overflow: hidden;

  .item-1 {
    grid-area: ${({ isOneVideo }) => (isOneVideo ? " 1 / 1 / 4 / 4 " : " 1 / 1 / 3 / 3 ")};
  }
  .item-2 {
    grid-area: span 1 / span 1;
  }
`;

const LinksWrapperOneItem = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-template-rows: 1fr;
  grid-gap: 32px;
  overflow: hidden;

  .item-1 {
    grid-area: 1 / 1 / 4 / 4;
  }
  .item-2 {
    grid-area: span 1 / span 1;
  }
`;

export const HiddenPanel = styled.div`
  background: #ffffff;
  padding: 17px 0px;
  position: relative;
  overflow: hidden;
`;

const VideoLinkWrapper = styled.a`
  all: unset;
`;
const LinkWrapper = styled.div`
  all: unset;
`;

const VideoContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  user-select: none;
  cursor: pointer;
`;
const VideoContainerMagazine = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  user-select: none;
  height: 100%;
`;
const VideoContainerOneItem = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  user-select: none;
  cursor: pointer;
  height: 100%;
`;

const ThumbnailImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: ${({ borderRadius }) => `${borderRadius}`};
  user-select: none;
`;

const GridThumbnailImg = styled.img`
  width: 100%;
  height: 154px;
  object-fit: cover;
  border-radius: ${({ borderRadius }) => `${borderRadius}`};
  user-select: none;
`;
const Thumbnail = styled.div`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: ${({ borderRadius }) => `${borderRadius}`};
  user-select: none;
  overflow: hidden;
`;

const VideoTitle = styled.span`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: ${({ theme }) => theme.videos.title};
  padding-bottom: 5px;
`;

const SpinnerContainer = styled.div`
  width: 100%;
  height: 185px;
  display: grid;
  place-content: center;
`;

// LIST
const VideosListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`;

const VideoListContainer = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 96px auto;
  column-gap: 16px;
  cursor: pointer;
`;

const ListThumbnail = styled.img`
  width: 100%;
  height: 54px;
  object-fit: cover;
  border-radius: 4px;
  user-select: none;
`;

const ListVideoTitle = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: ${({ theme }) => theme.videos.title};

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const VideosTypes = {
  MAGAZINE: "magazine",
  ONE_ITEM: "one item",
  GRID: "grid",
};

function Videos({ panelTitle, data, position }) {
  const YOUTUBE_API_KEY = "AIzaSyC0FESLs1yFgbfSFWGsg5GKgpoUvvi9WwQ";

  const [loading, setLoading] = useState(false);
  const [builtLinks, setBuiltLinks] = useState([]);

  const [currentItemsMain, setCurrentItemsMain] = useState([]);
  const [currentItemsSide, setCurrentItemsSide] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);

  const postsPerPageSide = data.maxNumberOfItems;
  const postsPerPageMain = 3;

  const indexOfLastSide = currentPage * postsPerPageSide;
  const indexOfFirstSide = indexOfLastSide - postsPerPageSide;

  const indexOfLastMain = currentPage * postsPerPageMain;
  const indexOfFirstMain = indexOfLastMain - postsPerPageMain;

  const indexOfLastMainShowMore = 1 * postsPerPageMain;
  const indexOfFirstMainShowMore = indexOfLastMainShowMore - postsPerPageMain;

  const indexOfLastSideShowMore = 1 * postsPerPageSide;
  const indexOfFirstSideShowMore = indexOfLastSideShowMore - postsPerPageSide;

  const [clickedShowAll, setClickedShowAll] = useState(false);

  const setDisableClickOutside = useSidebarStore((state) => state.setDisableClickOutside);
  const borderRadius = useCorporateIdentityStore((state) => state.borderRadius);

  const isFullWidth = position === PanelPositioning.MAIN;

  const maxPage = Math.ceil(builtLinks?.length / postsPerPageMain);

  useEffect(() => {
    if (!!data?.links?.length) {
      setLoading(true);
      const links = data.links
        .filter((link) => !!link?.url && link.url.match(/youtube\.com.*(\?v=|\/embed\/)(.{11})/))
        .map((link) => {
          const videoId = link.url.match(/youtube\.com.*(\?v=|\/embed\/)(.{11})/)?.pop();
          return {
            ...link,
            thumbnail: `http://img.youtube.com/vi/${videoId}/0.jpg`,
            videoId,
          };
        });

      const videoIds = links.map((link) => link.videoId).join(",");

      (async () => {
        try {
          const response = await fetch(
            `https://www.googleapis.com/youtube/v3/videos?part=snippet&id=${videoIds}&fields=items(id%2Csnippet)&key=${YOUTUBE_API_KEY}.`,
          );
          const resData = await response.json();

          const linksWithTitles = links.map((link) => ({
            ...link,
            videoTitle: resData?.items?.find((item) => item.id === link.videoId)?.snippet?.title || "",
          }));
          setBuiltLinks([...linksWithTitles]);
        } catch (error) {
          setBuiltLinks([...links]);
        } finally {
          setLoading(false);
        }
      })();
    } else {
      setBuiltLinks([]);
    }
  }, [data]);

  useEffect(() => {
    setCurrentPage(1);
  }, [builtLinks]);

  useEffect(() => {
    if (!!builtLinks.length && builtLinks.length > postsPerPageSide && !clickedShowAll) {
      const items = builtLinks.slice(indexOfFirstSide, indexOfLastSide);
      setCurrentItemsSide([...items]);
    } else {
      setCurrentItemsSide([...builtLinks]);
    }
  }, [builtLinks]);

  useEffect(() => {
    if (!data.view) {
      if (!!builtLinks.length && builtLinks.length > postsPerPageMain) {
        const items = builtLinks.slice(indexOfFirstMain, indexOfLastMain);
        setCurrentItemsMain([...items]);
      } else {
        setCurrentItemsMain([...builtLinks]);
      }
      setClickedShowAll(false);
    } else {
      if (data.pagination === PanelsPaginationTypes.NONE) {
        if (data.view === VideosTypes.MAGAZINE || data.view === VideosTypes.GRID) {
          const items = builtLinks.slice(indexOfFirstMainShowMore, indexOfLastMainShowMore);
          setCurrentItemsMain([...items]);
        } else {
          const items = builtLinks.slice(0, 1);
          setCurrentItemsMain([...items]);
        }
        setClickedShowAll(false);
        setCurrentPage(1);
      }
      if (data.pagination === PanelsPaginationTypes.ARROWS) {
        if (!!builtLinks.length && builtLinks.length > postsPerPageMain) {
          const items = builtLinks.slice(indexOfFirstMain, indexOfLastMain);
          setCurrentItemsMain([...items]);
        } else {
          setCurrentItemsMain([...builtLinks]);
        }
        setClickedShowAll(false);
      }
      if (data.pagination === PanelsPaginationTypes.SHOW_MORE_LESS || !data.pagination) {
        if (data.view === VideosTypes.MAGAZINE || data.view === VideosTypes.GRID) {
          if (!!builtLinks.length && builtLinks.length > postsPerPageMain && !clickedShowAll) {
            const items = builtLinks.slice(indexOfFirstMainShowMore, indexOfLastMainShowMore);
            setCurrentItemsMain([...items]);
          } else {
            setCurrentItemsMain([...builtLinks]);
          }
        } else {
          if (!!builtLinks.length && builtLinks.length > 1 && !clickedShowAll) {
            const items = builtLinks.slice(0, 1);
            setCurrentItemsMain([...items]);
          } else {
            setCurrentItemsMain([...builtLinks]);
          }
        }
        setCurrentPage(1);
      }
    }
  }, [builtLinks, currentPage]);

  const changeShowAll = (value) => {
    setClickedShowAll(value);
  };

  const handleShowAllMain = (e) => {
    e.stopPropagation();
    setCurrentItemsMain([...builtLinks]);
    changeShowAll(true);
  };

  const handleShowLessMain = (e) => {
    e.stopPropagation();
    if (data.view === VideosTypes.MAGAZINE || data.view === VideosTypes.GRID) {
      const items = builtLinks.slice(indexOfFirstMainShowMore, indexOfLastMainShowMore);
      setCurrentItemsMain([...items]);
    } else {
      const items = builtLinks.slice(0, 1);
      setCurrentItemsMain([...items]);
    }
    changeShowAll(false);
  };

  const handleShowAllSide = (e) => {
    e.stopPropagation();
    setCurrentItemsSide([...builtLinks]);
    changeShowAll(true);
  };

  const handleShowLessSide = (e) => {
    e.stopPropagation();
    if (data.view === VideosTypes.MAGAZINE || data.view === VideosTypes.GRID) {
      const items = builtLinks.slice(indexOfFirstSideShowMore, indexOfLastSideShowMore);
      setCurrentItemsSide([...items]);
    } else {
      const items = builtLinks.slice(0, 1);
      setCurrentItemsSide([...items]);
    }
    changeShowAll(false);
  };

  if (isFullWidth) {
    return (
      <PanelWrapper
        panelTitle={panelTitle}
        displayOptions={{
          displayTitle: typeof data.displayTitle === "undefined" ? true : data.displayTitle,
          displayBox: typeof data.displayBox === "undefined" ? true : data.displayBox,
        }}
      >
        {(data.view === VideosTypes.GRID || !data.view) && (
          <GridView
            loading={loading}
            currentItemsMain={currentItemsMain}
            borderRadius={borderRadius}
            data={data}
            postsPerPageMain={postsPerPageMain}
            setDisableClickOutside={setDisableClickOutside}
            pagination={{ currentPage, maxPage }}
            updatePage={setCurrentPage}
          />
        )}
        {data.view === VideosTypes.MAGAZINE && (
          <MagazineView
            builtLinks={builtLinks}
            loading={loading}
            currentItemsMain={currentItemsMain}
            borderRadius={borderRadius}
            data={data}
          />
        )}
        {data.view === VideosTypes.ONE_ITEM && (
          <OneItemView
            clickedShowAll={clickedShowAll}
            loading={loading}
            currentItemsMain={currentItemsMain}
            borderRadius={borderRadius}
            data={data}
            setDisableClickOutside={setDisableClickOutside}
            builtLinks={builtLinks}
            handleShowAllMain={handleShowAllMain}
            handleShowLessMain={handleShowLessMain}
            isFullWidth={isFullWidth}
          />
        )}
        {data.view !== VideosTypes.ONE_ITEM &&
          !!builtLinks.length &&
          (data.pagination === PanelsPaginationTypes.SHOW_MORE_LESS || !data.pagination) &&
          builtLinks.length > postsPerPageMain && (
            <Pagination
              onClick={clickedShowAll ? handleShowLessMain : handleShowAllMain}
              onWrapperHoverCallback={setDisableClickOutside}
              type={PanelsPaginationTypes.SHOW_MORE_LESS}
              showMoreClicked={clickedShowAll}
            />
          )}

        {!builtLinks.length && data.view !== VideosTypes.MAGAZINE && data.view !== VideosTypes.ONE_ITEM && (
          <EmptyVideoState />
        )}
      </PanelWrapper>
    );
  }

  return (
    <Panel>
      <PanelTitle panelPosition={position}>{panelTitle}</PanelTitle>
      <PanelContent>
        <VideosListWrapper>
          {!loading ? (
            currentItemsSide.map((link, index) => (
              <VideoLinkWrapper key={index} target="_top" href={link.url}>
                <VideoListContainer>
                  <ListThumbnail src={link?.thumbnail} alt="" />
                  <ListVideoTitle>{link?.videoTitle}</ListVideoTitle>
                </VideoListContainer>
              </VideoLinkWrapper>
            ))
          ) : (
            <SpinnerContainer>
              <Spinner size="large" />
            </SpinnerContainer>
          )}

          {!builtLinks.length && <EmptyVideoState />}
        </VideosListWrapper>
      </PanelContent>

      {data.view !== VideosTypes.ONE_ITEM &&
        !!builtLinks.length &&
        (data.pagination === PanelsPaginationTypes.SHOW_MORE_LESS || !data.pagination) &&
        builtLinks.length > postsPerPageSide && (
          <Pagination
            onClick={clickedShowAll ? handleShowLessSide : handleShowAllSide}
            onWrapperHoverCallback={setDisableClickOutside}
            type={PanelsPaginationTypes.SHOW_MORE_LESS}
            showMoreClicked={clickedShowAll}
          />
        )}
    </Panel>
  );
}

export default Videos;

const EmptyVideoState = () => {
  const theme = useTheme();

  return (
    <EmptyState
      Image={() => {
        return <img src={`/images/hub/video-empty-state-${theme.global.name}.svg`} alt="No video results" />;
      }}
      title="Your video playlist is empty."
      description="Add some videos to get started!"
    />
  );
};

const GridView = ({
  loading,
  currentItemsMain,
  borderRadius,
  data,
  setDisableClickOutside,
  pagination,
  updatePage,
}) => {
  const { currentPage, maxPage } = pagination;
  return (
    <>
      <LinksWrapper>
        {!loading ? (
          currentItemsMain.map((link, index) => (
            <VideoLinkWrapper key={index} target="_blank" href={link.url}>
              <VideoContainer>
                <GridThumbnailImg src={link?.thumbnail} alt={link.videoTitle} borderRadius={borderRadius} />
                <VideoTitle>{link?.videoTitle}</VideoTitle>
              </VideoContainer>
            </VideoLinkWrapper>
          ))
        ) : (
          <SpinnerContainer>
            <Spinner size="large" />
          </SpinnerContainer>
        )}
      </LinksWrapper>

      {data.pagination === PanelsPaginationTypes.ARROWS && !!currentItemsMain.length && (
        <Pagination
          onWrapperHoverCallback={setDisableClickOutside}
          type={PanelsPaginationTypes.ARROWS}
          page={currentPage}
          maxPage={maxPage}
          updatePage={updatePage}
        />
      )}
    </>
  );
};

const MagazineView = ({ builtLinks, loading, currentItemsMain, borderRadius, data }) => {
  return (
    <>
      {builtLinks.length ? (
        <LinksWrapperMagazine isOneVideo={builtLinks.length === 1}>
          {!loading ? (
            currentItemsMain.map((link, index) => (
              <div key={index} className={index === 0 ? `item-1 ` : `item-2 `}>
                <LinkWrapper key={index}>
                  <VideoContainerMagazine>
                    {index === 0 ? (
                      <Thumbnail borderRadius={borderRadius} style={{ cursor: "pointer" }}>
                        <iframe
                          title={`youtube-video-${index}`}
                          width="100%"
                          height={builtLinks.length < 3 ? "450px" : "100%"}
                          frameBorder="0"
                          src={`https://www.youtube.com/embed/${link?.videoId}?autoplay=${
                            data.autoplay || 0
                          }&controls=${!data.playerControls ? 0 : 1}&loop=${!data.loop ? 0 : 1}&mute=${
                            !data.mute ? 0 : 1
                          }`}
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; allow-presentation"
                        />
                      </Thumbnail>
                    ) : (
                      <a target="_blank" href={link.url} rel="noreferrer">
                        <ThumbnailImg src={link?.thumbnail} alt={link.videoTitle} borderRadius={borderRadius} />
                      </a>
                    )}
                  </VideoContainerMagazine>
                </LinkWrapper>
              </div>
            ))
          ) : (
            <SpinnerContainer>
              <Spinner size="large" />
            </SpinnerContainer>
          )}
        </LinksWrapperMagazine>
      ) : (
        <EmptyVideoState />
      )}
    </>
  );
};

const OneItemView = ({
  clickedShowAll,
  loading,
  currentItemsMain,
  borderRadius,
  data,
  setDisableClickOutside,
  builtLinks,
  handleShowAllMain,
  handleShowLessMain,
}) => {
  return (
    <>
      {builtLinks.length ? (
        <>
          <LinksWrapperOneItem>
            {!loading ? (
              currentItemsMain.map((link, index) => (
                <div key={index} className={index === 0 ? `item-1` : `item-2`}>
                  <LinkWrapper key={index}>
                    <VideoContainerOneItem>
                      {index === 0 ? (
                        <Thumbnail borderRadius={borderRadius} style={{ cursor: "pointer" }}>
                          <iframe
                            title={`youtube-video-${index}`}
                            width="100%"
                            height="450px"
                            frameBorder="0"
                            src={`https://www.youtube.com/embed/${link?.videoId}?autoplay=${
                              !data.autoplay ? 0 : 1
                            }&controls=${!data.playerControls ? 0 : 1}&loop=${!data.loop ? 0 : 1}&mute=${
                              !data.mute ? 0 : 1
                            }`}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; allow-presentation"
                          />
                        </Thumbnail>
                      ) : (
                        <a target="_blank" href={link.url} rel="noreferrer">
                          <ThumbnailImg src={link?.thumbnail} alt={link.videoTitle} borderRadius={borderRadius} />
                        </a>
                      )}
                    </VideoContainerOneItem>
                  </LinkWrapper>
                </div>
              ))
            ) : (
              <SpinnerContainer>
                <Spinner size="large" />
              </SpinnerContainer>
            )}
          </LinksWrapperOneItem>

          {(!data.pagination || data.pagination === PanelsPaginationTypes.SHOW_MORE_LESS) && builtLinks.length > 1 && (
            <Pagination
              onClick={clickedShowAll ? handleShowLessMain : handleShowAllMain}
              onWrapperHoverCallback={setDisableClickOutside}
              type={PanelsPaginationTypes.SHOW_MORE_LESS}
              showMoreClicked={clickedShowAll}
            />
          )}
        </>
      ) : (
        <EmptyVideoState />
      )}
    </>
  );
};
