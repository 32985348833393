import React, { useMemo } from "react";
import styled from "styled-components";
import { CardPropertyTypes } from "../../propertyTypes";
import { useConfiguratorStore } from "../configurator-store";
import { FieldContainer, Label } from "../../../../../../Dashboard/filter/styled";
import IconRenderer, { IconSizes } from "../../../../../../../../../Shared/Components/Icons/IconRenderer";
import InputFilter from "../../../../../../Dashboard/filter/InputFilter";
import BackgroundPopup from "../../../../../../../../../Shared/Components/Background/BackgroundPopup";
import { IconRendererWrapper } from "../components/styled-types";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 16px;
`;

function ImageCardFields({ image, title, updateCard }) {
  const { isEditingImage, setIsEditingImage } = useConfiguratorStore((state) => ({
    isEditingImage: state.isEditingImage,
    setIsEditingImage: state.setIsEditingImage,
  }));

  const DEFAULT_IMAGE = useMemo(
    () => "https://img.freepik.com/free-photo/low-angle-shot-modern-building-facade_181624-49557.jpg?w=750",
    [],
  );

  return (
    <Wrapper>
      <FieldContainer>
        <Label>Image</Label>

        <IconRendererWrapper>
          <IconRenderer
            icon={{ icon: "atlaskit/ImageIcon", provider: "atlaskit" }}
            onClick={() => setIsEditingImage(true)}
            iconSize={IconSizes.SMALL}
          />
        </IconRendererWrapper>
      </FieldContainer>

      <InputFilter
        initialValue={title || ""}
        onUpdate={(newTitle) => updateCard(CardPropertyTypes.TITLE, newTitle)}
        label="Card title"
        placeholder="Edit card title"
        containerStyle={{ flex: "1" }}
      />

      <BackgroundPopup
        linkToDocs="https://caelor.atlassian.net/wiki/x/jYCV"
        position="absolute"
        dialogPositionCss={{ top: 0, bottom: 0, right: 0 }}
        isOpen={isEditingImage}
        image={image || {}}
        showOverlay={false}
        defaultImage={DEFAULT_IMAGE}
        onClose={() => setIsEditingImage(false)}
        resetBackground={() => {
          updateCard(CardPropertyTypes.IMAGE, { source: "link", link: DEFAULT_IMAGE });
        }}
        zIndex={501}
        setImage={(image) => {
          updateCard(CardPropertyTypes.IMAGE, image);
        }}
        showCurrentPageInAttachments={false}
        showVideoInAttachments={false}
        hasUpload
        hasColorTabs
      />
    </Wrapper>
  );
}

export default ImageCardFields;
