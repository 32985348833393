const axios = require("axios");

export default class BaseRestService {
  constructor() {
    this.client = axios.create();
  }

  // eslint-disable-next-line class-methods-use-this
  async buildJWTAuthAxiosConfig() {
    let jwt = await window.AP.context.getToken();
    if (jwt === "") {
      jwt = document.querySelector('meta[name="ac-token"]').content || "";
    }
    if (jwt === "") {
      console.warn(
        `Could not get JWT from URL ${window.location}! Backend will not accept occurring requests.`
      );
    }
    return { headers: { Authorization: `JWT ${jwt}` } };
  }
}
