import { Box, xcss } from "@atlaskit/primitives";
import { token } from "@atlaskit/tokens";
import EditorSuccessIcon from "@atlaskit/icon/glyph/editor/success";
import ErrorIcon from "@atlaskit/icon/glyph/error";
import { useFlags } from "@atlaskit/flag";
import { LoadingButton } from "@atlaskit/button";
import React, { useEffect, useState } from "react";
import isEqual from "lodash.isequal";
import { useNavigationChangesStore, useNavigationColorsStore, useNavigationStore } from "../../../store";
import { saveNavigationMenu } from "../../../functions/api";

const footerStyle = xcss({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  columnGap: `${token("space.200")}`,
  borderTop: `1px solid ${token("color.border.disabled")}`,
  backgroundColor: token("elevation.surface"),
});

function Footer() {
  const [loading, setLoading] = useState(false);

  const { showFlag } = useFlags();

  const { structure, savedStructure, active, savedActive, version, setSavedStructure, setSavedActive } =
    useNavigationStore((state) => ({
      structure: state.structure,
      savedStructure: state.savedStructure,
      active: state.active,
      savedActive: state.savedActive,
      version: state.version,
      setSavedStructure: state.setSavedStructure,
      setSavedActive: state.setSavedActive,
    }));

  const { dark, light, savedColors, setSavedColors } = useNavigationColorsStore((state) => ({
    dark: state.dark,
    light: state.light,
    savedColors: {
      dark: state.savedColors.dark,
      light: state.savedColors.light,
    },
    setSavedColors: state.setSavedColors,
  }));

  const setHasChanges = useNavigationChangesStore((state) => state.setHasChanges);

  const isDisabled =
    isEqual(structure, savedStructure) && isEqual({ dark, light }, savedColors) && active === savedActive;

  useEffect(() => {
    setHasChanges(!isDisabled);
  }, [isDisabled, setHasChanges]);

  const saveNavigation = async () => {
    try {
      setLoading(true);
      await saveNavigationMenu({ structure, active, version, dark, light });
      setSavedStructure(JSON.parse(JSON.stringify(structure)));
      setSavedColors({ dark, light });
      setSavedActive(active);

      showFlag({
        icon: <EditorSuccessIcon primaryColor={token("color.icon.success")} />,
        title: "Your changes were saved",
        isAutoDismiss: true,
      });
    } catch {
      showFlag({
        icon: <ErrorIcon primaryColor={token("color.icon.error")} />,
        title: "Something went wrong",
        description: "Your changes weren't saved. Please try again.",
        isAutoDismiss: true,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box paddingInline="space.400" paddingBlock="space.150" xcss={footerStyle}>
      <LoadingButton appearance="primary" isDisabled={isDisabled} isLoading={loading} onClick={() => saveNavigation()}>
        Save Changes
      </LoadingButton>
    </Box>
  );
}

export default Footer;
