import React, { useState } from "react";
import TextField from "@atlaskit/textfield";

const NumberEditor = ({ initialValue, onEdit }) => {
  const [value, setValue] = useState(initialValue);

  return (
    <TextField
      style={{ height: "100%", zIndex: 2 }}
      type="number"
      autoFocus
      value={value}
      onBlur={(e) => onEdit(e.target.value)}
      onChange={(e) => setValue(e.target.value)}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          onEdit(value);
        }
      }}
    />
  );
};

export default NumberEditor;
