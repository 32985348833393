import React, { useEffect } from "react";
import { AsyncSelect } from "@atlaskit/select";
import { atlassianRestService } from "../../../../../Service/AtlassianRestService";
import { OptionIcon, OptionLabel } from "../CheckboxSelect/CustomCheckboxSelectComponents";

import { simpleSelectStyles } from "./simple-select-styles";

function SpacesSimpleSelect({
  initialSpaces,
  isMulti = false,
  placeholder = "Find a space",
  selectedSpaces,
  setSelectedSpaces,
}) {
  useEffect(() => {
    if ((initialSpaces ?? []).length > 0) {
      const promises = [];
      for (const spaceKey of initialSpaces) {
        promises.push(atlassianRestService.getSpaceByKey(spaceKey));
      }
      Promise.all(promises)
        .then((results) => {
          setSelectedSpaces(results.map((r) => _toSpaceField(r)));
        })
        .catch((e) => {
          // Handle errors here
        });
    }
  }, [initialSpaces]);

  const loadOptions = async (query) => {
    return (await atlassianRestService.searchForSpace(query)).results.map(({ space }) => _toSpaceField(space));
  };

  const getIconUrl = (icon) => {
    return `${window.AP._hostOrigin}/wiki/${icon.path}`;
  };

  return (
    <AsyncSelect
      id="spaces-select"
      className="select-search"
      classNamePrefix="select-search"
      defaultOptions
      cacheOptions
      isMulti={isMulti}
      autoFocus
      placeholder={placeholder}
      isSearchable
      hideSelectedOptions={false}
      backspaceRemovesValue
      maxMenuHeight={240}
      styles={simpleSelectStyles}
      value={selectedSpaces}
      loadOptions={loadOptions}
      onChange={(e) => {
        if (e === undefined) {
          setSelectedSpaces([]);
          return;
        }
        if (isMulti) {
          setSelectedSpaces(e);
          return;
        }
        setSelectedSpaces([e]);
      }}
      formatOptionLabel={(option) => (
        <OptionLabel>
          <OptionIcon src={getIconUrl(option.icon)} alt="space-icon" />
          <span>{option.name}</span>
        </OptionLabel>
      )}
    />
  );
}

function _toSpaceField(space) {
  return {
    icon: space.icon,
    name: space.name,
    spaceKey: space.key,
    type: "space",
    label: space.name,
    value: space.key,
    homepageId: space.homepage.id,
    coverPictureCallback: async () => {},
  };
}

export default SpacesSimpleSelect;
