import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import Spinner from "@atlaskit/spinner";
import { useQuery } from "react-query";
import styled, { useTheme } from "styled-components";
import { fetchRecords, fetchUserDatabaseFields } from "../../../../../UserDatabase/datagrid/api";
import { useSidebarStore } from "../../store/sidebar-store";
import { fetchDetailsForUsersIndividually } from "../../../Dashboard/api";
import {
  IMAGE_TYPES,
  getCssForBackground,
  getRandomUserBackground,
} from "../../../../../../Shared/Functions/user-bg-provider";
import { Section, SmallTitle, SpinnerContainer, Subtitle } from "../../styled/views";
import NoNewStartersSVG from "../../../Dashboard/panels/images/NoNewStartersSVG";
import { useCorporateIdentityStore } from "../../../Settings/General/BrandAndColors/corporate-identity-store";
import useSavedCollections from "../../../../../../Shared/Hooks/useSavedCollections";
import { createProfileLink } from "../createProfileLink";
import { getUserValuesFromTwoFields } from "../../../Shared/Panels/shared/utils/user-values-extractor";
import Pagination from "../../../Shared/PanelsPagination/Pagination";
import { PanelsPaginationTypes } from "../../../Shared/PanelsPagination/panels-pagination-types";
import { WidgetIds } from "../../../Settings/Widgets/consts/tabs";
import { getWidgetSettingById } from "../../../Settings/Widgets/api";
import EmptyState from "../../../../../../Shared/Components/EmptyState";

const LinkWrapper = styled.a`
  all: unset;
`;

export const PanelPaginationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 5px;
  min-width: 53px;
`;

export const PanelContent = styled.div`
  padding-top: 20px;
`;

const UsersContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-template-rows: 1fr;
  grid-gap: 24px;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
`;

const Card = styled.div`
  background: ${({ theme }) => theme.shared.card.background};
  box-shadow:
    rgba(9, 30, 66, 0.25) 0px 1px 3px,
    rgba(9, 30, 66, 0.31) 0px 0px 1px;
  border-radius: ${({ borderRadius }) => `${borderRadius}`};
  overflow: hidden;
  border: 1px solid ${({ theme }) => theme.shared.card.border.box};
  cursor: pointer;
  transition: box-shadow linear 75ms;

  &:hover {
    box-shadow:
      rgba(9, 30, 66, 0.31) 0px 0px 1px 0px,
      rgba(9, 30, 66, 0.25) 0px 8px 16px -6px;
  }
`;

const CardBackground = styled.div`
  width: 100%;
  background: ${({ background }) => background};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 80px;
`;

const CardInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  align-items: center;
  justify-content: center;
  padding: 0px 16px 16px 16px;
  margin-top: ${({ imageHeight }) => `-${imageHeight / 2}px`};
  text-align: center;
`;

const Image = styled.img`
  width: ${({ size }) => (size ? `${size}px` : "80px")};
  height: ${({ size }) => (size ? `${size}px` : "80px")};
  box-sizing: border-box;
  border: 4px solid ${({ theme }) => theme.shared.card.border.avatar};
  border-radius: 50%;
  object-fit: cover;
  justify-self: center;
`;

const Name = styled.span`
  font-weight: 500;
  font-size: 15px;
  line-height: 19px;
  color: ${({ theme }) => theme.shared.card.title};
  margin-top: 8px;
`;

const Position = styled.span`
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme }) => theme.shared.card.subtitle};
  height: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
`;

const GridCenter = styled.div`
  height: 32px;
  display: grid;
  place-content: center;
  margin-top: 8px;
`;

const Date = styled.span`
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: ${({ isBlue, theme }) => (isBlue ? theme.global.text.blue : theme.shared.card.footer)};
  margin-top: 13px;
  flex: 1;
  display: flex;
  align-items: flex-end;
  min-height: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const DummyContent = ({ savedData, borderRadius }) => {
  const theme = useTheme();
  const dummyData = [
    {
      displayName: "Mary Lee",
      icon: "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
      accountId: "1234",
      position: "Project Manager",
      entryDate: 1685616039,
      background: "linear-gradient(270deg, #998DD9 0%, #5243AA 98.75%)",
    },
    {
      displayName: "John Smith",
      accountId: "123456",
      icon: "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80",
      position: "Android Developer",
      entryDate: 1689417639,
      background: "linear-gradient(270deg, #78E2F2 0%, #00A3BF 98.75%)",
    },
    {
      displayName: "Sarah Connor",
      accountId: "123",
      icon: "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80",
      position: "Product Manager",
      entryDate: 1692096039,
      background: "linear-gradient(270deg, #fc5c7d 0%, #6a82fb 98.75%)",
    },
    {
      displayName: "Jane Clark",
      accountId: "12345",
      icon: "https://images.unsplash.com/photo-1488426862026-3ee34a7d66df?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80",
      position: "Product Manager",
      entryDate: 1693305639,
      background: "linear-gradient(270deg, #870000 0%, #190a05 98.75%)",
    },
  ];

  const getBackground = (userData) => {
    if (savedData.backgroundType === "image") return userData.background;
    if (savedData.backgroundType === "none") return theme.background;
  };

  return (
    <>
      {dummyData.slice(0, 4).map((user) => (
        <Card key={user.accountId} borderRadius={borderRadius}>
          <CardBackground background={getBackground(user)} />
          <CardInfoContainer imageHeight={savedData.profileImageSize}>
            <Image size={savedData.profileImageSize} src={user.icon} />
            <Name>{user.displayName}</Name>
            <Position>{savedData.titleFieldOn ? user.position.toUpperCase() : ""}</Position>
            <Date>{savedData.footerFieldOn ? dayjs.unix(user.entryDate).format("MMM D, YYYY") : ""}</Date>
          </CardInfoContainer>
        </Card>
      ))}
    </>
  );
};

const DEFAULT_DATA = {
  titleFieldId: "FF2Vrog",
  footerFieldId: "dc49cu8",
  titleFieldOn: true,
  footerFieldOn: true,
  backgroundType: "color",
  rounding: 6,
  imageSize: 80,
};

function NewStarters({ panelData }) {
  const [start, setStart] = useState(undefined);
  const [people, setPeople] = useState([]);
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(false);
  const [savedData, setSavedData] = useState(DEFAULT_DATA);
  const [page, setPage] = useState(1);

  const [databaseField, setDatabaseField] = useState("");

  const postsPerPage = panelData.maxNumberOfItems || 4;
  const indexOfLast = page * postsPerPage;
  const indexOfFirst = indexOfLast - postsPerPage;
  const maxPage = Math.ceil(people?.length / postsPerPage);
  const hasNextPage = people?.length > postsPerPage;

  const setDisableClickOutside = useSidebarStore((state) => state.setDisableClickOutside);
  const borderRadius = useCorporateIdentityStore((state) => state.borderRadius);

  const { collectionsData } = useSavedCollections({
    collectionId: panelData?.collection,
    areOptions: false,
  });

  const { isLoading: loadingGlobalSetting, data: widgetSettingData } = useQuery(
    ["new-starters-global-setting", { widgetId: WidgetIds.NEW_STARTERS }],
    getWidgetSettingById,
    {
      retry: 0,
      select: (response) => {
        const { data } = response;
        return data;
      },
    },
  );

  useEffect(() => {
    if (loadingGlobalSetting) return;

    if (widgetSettingData?.widget?.selectedField) {
      setDatabaseField(widgetSettingData.widget.selectedField);
    }
  }, [loadingGlobalSetting, widgetSettingData]);

  const { isLoading: recordsLoading, data: recordsData } = useQuery(
    [
      "userdatabase-records",
      {
        filters: [
          ...(collectionsData?.filters || []),
          {
            column: databaseField,
            value: { range: [start] },
            type: "DATE",
            condition: "IS",
          },
          { isMultiUser: true, type: "BOOLEAN", value: true, column: "hidden", condition: "NOT" },
        ],
        confluenceGroup: collectionsData?.userGroup || "",
        sort: { fieldId: databaseField, order: "desc", fieldPrefix: "date_field" },
        limit: 12,
      },
    ],
    fetchRecords,
    {
      enabled: !!databaseField && !!start,
      retry: 0,
      select: (response) => {
        const { data } = response;
        return data.hits;
      },
    },
  );

  const { data: fieldsData } = useQuery(["userdatabase-fields", { includeEmail: false }], fetchUserDatabaseFields, {
    retry: 0,
    select: (response) => {
      const { data } = response;
      const matchingFieldsByType = data?.filter(
        (field) => field.type === "SELECT" || field.type === "TEXT" || field.type === "DATE",
      );
      return matchingFieldsByType;
    },
  });

  const getDetailsForUsers = (users) => {
    if (users?.length) {
      setLoading(true);
      fetchDetailsForUsersIndividually(users.map((u) => u.accountId))
        .then((resUsers) => {
          if (resUsers?.length) {
            const formattedUsers = resUsers.map((user) => {
              return {
                accountId: user.accountId,
                name: user.displayName,
                icon: `${window.AP._hostOrigin}${user.profilePicture.path}`,
              };
            });
            setRecords([...formattedUsers]);
          }
        })
        .catch((e) => setPeople([]))
        .finally(() => setLoading(false));
    } else {
      setRecords([]);
    }
  };

  useEffect(() => {
    if (!!recordsData?.length && !!fieldsData?.length) {
      getDetailsForUsers(recordsData);
    } else {
      setRecords([]);
    }
  }, [recordsData, fieldsData]);

  useEffect(() => {
    if (!!records?.length && !!fieldsData) {
      const formattedPeople = records.map((user) => {
        const userValues = recordsData.find((r) => r.accountId === user.accountId)?.values;

        const {
          firstField: titleValue,
          secondField: footerValue,
          footerType,
        } = getUserValuesFromTwoFields(userValues, fieldsData, savedData.titleFieldId, databaseField);

        const backgroundImage = userValues?.user?.backgroundImage;
        const hasBgData = !!backgroundImage && !!Object.keys(backgroundImage || {}).length;
        const correctImage = hasBgData ? backgroundImage : getRandomUserBackground(IMAGE_TYPES.GRADIENT);

        const image = getCssForBackground(correctImage);
        return {
          ...user,
          titleValue,
          footerValue,
          footerType,
          backgroundImage: image,
        };
      });

      setPeople([...formattedPeople]);
    } else {
      setPeople([]);
    }
  }, [records, fieldsData, savedData.titleFieldId, savedData.footerFieldId]);

  useEffect(() => {
    const FOUR_WEEKS_AGO = 2678400;
    const today = dayjs().unix();
    const todayFixed = dayjs.unix(today).hour(0).minute(0).second(0).unix();
    const startDate = todayFixed - FOUR_WEEKS_AGO;
    setStart(startDate);
  }, []);

  useEffect(() => {
    setSavedData(panelData);
  }, [panelData]);

  useEffect(() => {
    setPage(1);
  }, [postsPerPage, panelData.pagination]);

  const dynamicIndexOfFirst = {
    [PanelsPaginationTypes.SHOW_MORE]: 0,
    [PanelsPaginationTypes.ARROWS]: indexOfFirst,
  };

  const peoplePaginated = people.slice(dynamicIndexOfFirst[panelData.pagination], indexOfLast);

  const theme = useTheme();

  const getBackground = (userData) => {
    if (savedData.backgroundType === "image") return userData.backgroundImage;
    if (savedData.backgroundType === "none") return theme.background;
  };

  return (
    <Section>
      <SmallTitle noBorder={true}>{panelData.title}</SmallTitle>
      <Subtitle>{panelData.description}</Subtitle>

      <UsersContainer>
        {recordsLoading || loading || loadingGlobalSetting ? (
          <SpinnerContainer>
            <Spinner />
          </SpinnerContainer>
        ) : (
          <>
            {!databaseField ? (
              <DummyContent savedData={savedData} borderRadius={borderRadius} />
            ) : (
              <>
                {!!people?.length &&
                  peoplePaginated.map((user) => (
                    <LinkWrapper key={user.accountId} target="_top" href={createProfileLink(user.accountId)}>
                      <Card key={user.accountId} borderRadius={borderRadius}>
                        <CardBackground background={getBackground(user)} />
                        <CardInfoContainer imageHeight={panelData.profileImageSize || 80}>
                          <Image size={panelData.profileImageSize} src={user.icon} />
                          <Name>{user.name}</Name>
                          <Position>{panelData.titleFieldOn ? user.titleValue : ""}</Position>
                          <GridCenter>
                            <Date isBlue={user.footerType === "URL"}>
                              {panelData.footerFieldOn ? user.footerValue : ""}
                            </Date>
                          </GridCenter>
                        </CardInfoContainer>
                      </Card>
                    </LinkWrapper>
                  ))}
              </>
            )}
          </>
        )}
      </UsersContainer>

      {databaseField && !!people?.length && (
        <Pagination
          type={panelData.pagination}
          page={page}
          hasNextPage={hasNextPage}
          updatePage={setPage}
          maxPage={maxPage}
          onWrapperHoverCallback={setDisableClickOutside}
        />
      )}

      {!!databaseField && !people.length && !recordsLoading && !loading && (
        <EmptyState
          title="No New Starters"
          description="There are currently no new starters listed. New employee details will appear here as they join the company."
          Image={NoNewStartersSVG}
        />
      )}
    </Section>
  );
}

export default NewStarters;
