import create from "zustand";
import { SIDEBAR_TYPES } from "../Sidebar/types";

export const useSidebarStore = create((set, get) => ({
  selectedContentType: undefined,
  setSelectedContentType: (v) => set((state) => ({ selectedContentType: v })),

  sidebarType: SIDEBAR_TYPES.CONTENT_SELECTOR,
  setSidebarType: (v) => set((state) => ({ sidebarType: v })),

  hoveringPanel: undefined,
  setHoveringPanel: (v) => set((state) => ({ hoveringPanel: v })),

  disableClickOutside: false,
  setDisableClickOutside: (v) => set((state) => ({ disableClickOutside: v })),
}));
