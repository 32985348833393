import React, { useState } from "react";
import NumberEditor from "../editor/Number";
import { FieldValue, EditorContainer } from "../styled";

function Number({ value, field, onEdit, isEditable, getPrefixBasedOnRole }) {
  const [isEditing, setIsEditing] = useState(false);

  return (
    <>
      {isEditing && (
        <EditorContainer>
          <NumberEditor
            initialValue={value}
            onEdit={(val) => {
              onEdit(field.id, val);
              setIsEditing(false);
            }}
          />
        </EditorContainer>
      )}

      <FieldValue isEditing={isEditing} isEditable={isEditable} onClick={() => setIsEditing(true)}>
        {value || `${getPrefixBasedOnRole()} ${field.title}`}
      </FieldValue>
    </>
  );
}

export default Number;
