import styled from "styled-components";

export const ModalWrapper = styled.div`
  margin: 15vh auto 0 auto; // (100vh - 70vh) / 2 --- positioned in middle of screen
  padding: 24px;
  box-sizing: border-box;
  height: 70vh;
  width: 70vw;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.globalBackground};
  display: flex;
  flex-direction: column;
`;

export const ModalBody = styled.div`
  flex: 1;
  overflow-y: auto;
`;

export const ModalFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: right;
  column-gap: 6px;
  padding-top: 12px;
`;
