/* eslint-disable react/forbid-prop-types */
import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled, { useTheme } from "styled-components";
import { useClickOutside } from "../../../../../Shared/Hooks/useClickOutside";

const Input = styled.input`
  font-size: 14px;
  line-height: 1.44;
  outline: none;
  background-color: transparent;
  color: #091e42;
  border: none;
  padding: 0px;
  white-space: pre;
  text-align: left;
  overflow: hidden;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
`;

function InlineTextEditor({ value, setValue, isEditing, isFocused }) {
  const [editing, setEditing] = useState(isEditing);
  const theme = useTheme();

  const inlineEditorContainerRef = useRef(null);
  const inlineEditorInputRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      if (inlineEditorInputRef?.current?.focus) {
        inlineEditorInputRef?.current.focus();
      }
    }, 250);
  }, [inlineEditorInputRef?.current?.focus]);

  useClickOutside(inlineEditorContainerRef, () => setEditing(false));

  return (
    <div
      ref={inlineEditorContainerRef}
      role="none"
      style={{
        minHeight: "14px",
        flex: "1",
        position: "relative",
        display: "flex",
        alignItems: "center",
      }}
      onClick={() => setEditing(true)}
    >
      {editing ? (
        <>
          <span
            style={{
              fontSize: "14px",
              lineHeight: "1.44",
              visibility: "hidden",
              whiteSpace: "pre-wrap",
            }}
          >
            {value || "Type here..."}
          </span>

          <Input
            ref={inlineEditorInputRef}
            maxLength={80}
            autoFocus={isFocused}
            type="text"
            className="test-input"
            value={value}
            placeholder="Type here..."
            onChange={(e) => setValue(e.target.value)}
            style={{
              position: "absolute",
              width: "100%",
              left: "0",
              color: theme?.shared?.form?.input?.text || "#9FADBC",
            }}
          />
        </>
      ) : (
        <span style={{ fontSize: "14px", lineHeight: "1.44" }}>{value || "Type here..."}</span>
      )}
    </div>
  );
}

InlineTextEditor.propTypes = {
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  isEditing: PropTypes.bool,
  isFocused: PropTypes.bool,
};

InlineTextEditor.defaultProps = {
  isEditing: false,
  isFocused: false,
};

export default InlineTextEditor;
