import { useRef } from "react";
import { FieldContainer, Label } from "../../Dashboard/filter/styled";
import { useNewsroomStore } from "../newsroom-store";
import SelectFilter from "../../Dashboard/filter/SelectFilter";
import { ActiveNewsElements, NewsElements, NewsViews } from "../defaults";
import SingleTileColorPicker from "../../../../../Shared/Components/SingleTileColorPicker";
import ElementsToggle from "../../Dashboard/filter/ElementsToggle";
import { useChangesStore } from "../../../track-changes-store";
import SizeButtons from "../../../../../Shared/Components/SizeButtons";
import { sizeType } from "../../../../../Shared/Components/size-options";

export const SettingPropertyKeys = {
  VIEW: "selectedView",
  CARD_BORDER_RADIUS: "cardBorderRadius",
  GRID_GAP: "gridGap",
  ACTIVE_ELEMENTS: "activeElements",
  LABELS_BACKGROUND: "labelsBackground",
};

function NewsDesign() {
  const { settings, updateSettings, updateSettingsView } = useNewsroomStore((state) => ({
    settings: state.settings,
    updateSettings: state.updateSettings,
    updateSettingsView: state.updateSettingsView,
  }));

  const { hasChanges, setHasChanges } = useChangesStore((state) => ({
    hasChanges: state.hasChanges,
    setHasChanges: state.setHasChanges,
  }));

  const hasChangesRef = useRef();
  hasChangesRef.current = hasChanges;

  const getSettingsTypeData = (view, prop) => settings[view][prop];

  const updateElements = (value) => {
    const newElements = !!settings[settings.selectedView]?.activeElements.find((el) => el === value)
      ? settings[settings.selectedView].activeElements.filter((el) => el !== value)
      : [...settings[settings.selectedView].activeElements, value];
    updateSettingsView(settings.selectedView, SettingPropertyKeys.ACTIVE_ELEMENTS, newElements);

    setHasChanges(true);
  };

  const handleUpdateSettings = (propertyKey, value) => {
    updateSettings(propertyKey, value);
    setHasChanges(true);
  };

  const handleUpdateSettingsView = (view, propertyKey, value) => {
    updateSettingsView(view, propertyKey, value);
    setHasChanges(true);
  };

  return (
    <div style={{ marginTop: "20px" }}>
      <div>
        <Label>News view</Label>
        <SelectFilter
          value={settings.selectedView}
          setValue={(v) => handleUpdateSettings(SettingPropertyKeys.VIEW, v)}
          options={[
            { label: "Magazine", value: NewsViews.MAGAZINE },
            { label: "Grid", value: NewsViews.GRID },
            { label: "List", value: NewsViews.LIST },
          ]}
          noDetails
        />
      </div>

      <div>
        <ElementsToggle
          elements={
            settings.selectedView === NewsViews.LIST
              ? getSettingsTypeData(settings.selectedView, SettingPropertyKeys.ACTIVE_ELEMENTS)?.filter(
                  (element) => element === NewsElements.AUTHOR,
                )
              : getSettingsTypeData(settings.selectedView, SettingPropertyKeys.ACTIVE_ELEMENTS)
          }
          options={
            settings.selectedView === NewsViews.LIST
              ? [{ label: "Author", value: NewsElements.AUTHOR }]
              : ActiveNewsElements
          }
          label="Elements to display"
          updateElements={updateElements}
        />

        {settings.selectedView !== NewsViews.LIST && (
          <FieldContainer>
            <SizeButtons
              label="Image size"
              types={{ panel: "news", size: sizeType.NEWSROOM_PADDING }}
              input={{
                min: 10,
                max: 30,
                step: 10,
                value: getSettingsTypeData(settings.selectedView, SettingPropertyKeys.GRID_GAP),
              }}
              setValue={(value) => handleUpdateSettingsView(settings.selectedView, SettingPropertyKeys.GRID_GAP, value)}
            />
          </FieldContainer>
        )}

        <FieldContainer>
          <Label>Colors</Label>
          <SingleTileColorPicker
            label="Space and Label"
            value={getSettingsTypeData(settings.selectedView, SettingPropertyKeys.LABELS_BACKGROUND)}
            setValue={(value) =>
              handleUpdateSettingsView(settings.selectedView, SettingPropertyKeys.LABELS_BACKGROUND, value)
            }
          />
        </FieldContainer>
      </div>
    </div>
  );
}

export default NewsDesign;
