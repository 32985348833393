import React from "react";
import ArrowRightIcon from "@atlaskit/icon/glyph/arrow-right";
import styled from "styled-components";
import { MediumLine } from "./styled";

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  column-gap: 14px;
`;

const CardWrapper = styled.div`
  border-radius: 4px;
  border: 0.5px solid #d8dce3;
  background: #fff;
  box-shadow: 0px 0.5473684072494507px 3.284210443496704px 0px rgba(68, 68, 68, 0.12);
  padding: 20px 16px;

  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  color: #97a0af;
  font-size: 24px;
  font-weight: 700;
  padding-bottom: 8px;
  width: fit-content;
  border-bottom: ${({ borderColor }) => `2px solid ${borderColor}`};
`;

const SmallIcon = styled.div`
  display: grid;
  place-content: center;
  width: 24px;
  height: 24px;
  align-self: flex-end;
  margin-top: 8px;
`;

function TextCards() {
  return (
    <Grid>
      <CardWrapper>
        <Title borderColor="#00A59B">Abc</Title>

        <MediumLine width={72} bg="#E4E4E4" style={{ marginTop: "15px" }} />
        <MediumLine width={64} bg="#E4E4E4" style={{ marginTop: "8px" }} />

        <SmallIcon>
          <ArrowRightIcon primaryColor="#00A59B" />
        </SmallIcon>
      </CardWrapper>

      <CardWrapper>
        <Title borderColor="#FF9458">Abc</Title>

        <MediumLine width={72} bg="#E4E4E4" style={{ marginTop: "15px" }} />
        <MediumLine width={64} bg="#E4E4E4" style={{ marginTop: "8px" }} />

        <SmallIcon>
          <ArrowRightIcon primaryColor="#FF9458" />
        </SmallIcon>
      </CardWrapper>

      <CardWrapper>
        <Title borderColor="#FF991F">Abc</Title>

        <MediumLine width={72} bg="#E4E4E4" style={{ marginTop: "15px" }} />
        <MediumLine width={64} bg="#E4E4E4" style={{ marginTop: "8px" }} />

        <SmallIcon>
          <ArrowRightIcon primaryColor="#FF991F" />
        </SmallIcon>
      </CardWrapper>
    </Grid>
  );
}

export default TextCards;
