import dayjs from "dayjs";

const contentTypeMapper = {
  page: "page",
  blogpost: "blog",
  comment: "comment",
};

export const handleDataForActiveUsers = (results = []) => {
  if (!results?.length) return [];

  const formattedPageData = results.map((result) => {
    const plainLastModified = result?.lastModified;
    const plainCreatedAt = result?.content?.history?.createdDate;

    const lastModified = dayjs(plainLastModified).set("second", 0);
    const createdAt = dayjs(plainCreatedAt).set("second", 0);
    const status = createdAt.diff(lastModified, "minute") >= 0 ? "Created" : "Updated";

    const plainUserData =
      status === "Created" ? result.content.history.createdBy : result.content.history.lastUpdated.by;

    const userData = {
      accountId: plainUserData.accountId,
      name: plainUserData.displayName,
      profilePicture: `${window.AP._hostOrigin}${plainUserData.profilePicture.path}`,
    };

    return {
      id: result.content.id,
      friendlyModified:
        result.friendlyLastModified.includes("ago") || result.friendlyLastModified.includes("yesterday")
          ? result.friendlyLastModified
          : dayjs(result.friendlyLastModified).format("MMM D, YYYY"),
      status,
      type: contentTypeMapper[result.content.type],
      userData,
      linkToPage: `${window.AP._hostOrigin}/wiki${result.content._links.tinyui || result.content._links.webui}`,
    };
  });

  if (formattedPageData?.length) {
    return formattedPageData.filter(
      (pageData, index) =>
        formattedPageData.findIndex((item) => item.userData.accountId === pageData.userData.accountId) === index,
    );
  }

  return [];
};
