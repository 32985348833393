import React from "react";
import styled from "styled-components";
import {
  Card,
  CardBackground,
  CardInfoContainer,
  GridWrapper,
  MediumLine,
  Panel,
  SmallLine,
  UserImage,
  Wrapper,
} from "./styled";

const Date = styled.span`
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  color: #0065ff;
  margin-top: 4px;
`;

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 14px;
`;

const PersonWrapper = styled.div`
  display: grid;
  grid-template-columns: 40px auto 32px;
  column-gap: 12px;
  align-items: center;
`;

const PersonImage = styled.img`
  box-sizing: border-box;
  width: 40px;
  height: 40px;
  border: 2px solid #e6ebf0;
  border-radius: 50%;
  object-fit: cover;
  user-select: none;
`;

const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ListName = styled.span`
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #172b4d;
  white-space: nowrap;
`;

const ListField = styled.span`
  font-weight: 500;
  font-size: 9px;
  line-height: 11px;
  color: #c1c7d0;
  margin-top: 1.5px;
`;

const ListDate = styled.span`
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: #2eb67d;
`;

export const NewStartersList = () => {
  return (
    <Wrapper>
      <Panel>
        <ListWrapper>
          <PersonWrapper>
            <PersonImage src="https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=128&h=128&q=80" />

            <DetailsWrapper>
              <ListName>Sarah Connor</ListName>
              <ListField>Product Manager</ListField>
            </DetailsWrapper>

            <ListDate>TODAY</ListDate>
          </PersonWrapper>

          <PersonWrapper>
            <PersonImage src="https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=128&h=128&q=80" />

            <DetailsWrapper>
              <SmallLine width={47} bg="#C1C7D0" />
              <SmallLine style={{ marginTop: "7px" }} width={64} bg="#E4E4E4" />
            </DetailsWrapper>

            <SmallLine width={32} bg="#E4E4E4" />
          </PersonWrapper>

          <PersonWrapper>
            <PersonImage src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=128&h=128&q=80" />

            <DetailsWrapper>
              <SmallLine width={47} bg="#C1C7D0" />
              <SmallLine style={{ marginTop: "7px" }} width={64} bg="#E4E4E4" />
            </DetailsWrapper>

            <SmallLine width={32} bg="#E4E4E4" />
          </PersonWrapper>

          <PersonWrapper>
            <PersonImage src="https://images.unsplash.com/photo-1488426862026-3ee34a7d66df?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=128&h=128&q=80" />

            <DetailsWrapper>
              <SmallLine width={47} bg="#C1C7D0" />
              <SmallLine style={{ marginTop: "7px" }} width={64} bg="#E4E4E4" />
            </DetailsWrapper>

            <SmallLine width={32} bg="#E4E4E4" />
          </PersonWrapper>
        </ListWrapper>
      </Panel>
    </Wrapper>
  );
};

function NewStarters() {
  const dummyData = [
    {
      icon: "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=128&h=128&q=80",
      background: `url("https://images.unsplash.com/photo-1496715976403-7e36dc43f17b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=640&h=480&q=80")`,
      date: "05 May 2023",
    },
    {
      icon: "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=128&h=128&q=80",
      background: `url("https://images.unsplash.com/photo-1419242902214-272b3f66ee7a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=640&h=480&q=80")`,
      date: "15 April 2023",
    },
    {
      icon: "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=128&h=128&q=80",
      background: "linear-gradient(270deg, #84B9F8 0%, #2571CB 98.75%)",
      date: "27 April 2023",
    },
    {
      icon: "https://images.unsplash.com/photo-1488426862026-3ee34a7d66df?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=128&h=128&q=80",
      background: `url("https://images.unsplash.com/photo-1497032628192-86f99bcd76bc?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=640&h=480&q=80")`,
      date: "14 June 2023",
    },
  ];

  return (
    <GridWrapper>
      {dummyData.map(({ icon, background, date }, idx) => (
        <Card key={idx}>
          <CardBackground background={background} />
          <CardInfoContainer>
            <UserImage src={icon} />
            <MediumLine width={65} style={{ backgroundColor: "#DFE1E6" }} />
            <MediumLine width={55} style={{ backgroundColor: "#EBECF0" }} />
            <Date>{date}</Date>
          </CardInfoContainer>
        </Card>
      ))}
    </GridWrapper>
  );
}

export default NewStarters;
