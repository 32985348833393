import httpClient from "../../Service/http-client";

export async function getHeaderSettings({ queryKey }) {
  const url = `ac/confluence/customization/header`;
  return httpClient.get(url);
}

export async function saveHeaderSettings(data) {
  const url = `ac/confluence/customization/header/save`;
  return httpClient.post(url, { data });
}

export async function getCorporateIdentitySettings({ queryKey }) {
  const url = `ac/confluence/customization/corporate`;
  return httpClient.post(url);
}

export async function saveCorporateIdentitySettings(data) {
  const url = `ac/confluence/customization/corporate/save`;
  return httpClient.put(url, { data });
}

export async function checkIfUserIsCosmosAdmin() {
  const url = `ac/confluence/api/admin/check-user-permission`;
  return httpClient.get(url);
}

export async function getUserCollections(collections) {
  const url = `ac/confluence/userdatabase/get-collections-for-user`;
  return httpClient.post(url, { collections });
}

export async function getUnderConstructionData({ queryKey }) {
  const url = `ac/confluence/customization/construction`;
  return httpClient.get(url);
}

export async function saveUnderConstructionData(data) {
  const url = `ac/confluence/customization/construction/save`;
  return httpClient.put(url, { data });
}
