import React, { useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import { useSidebarStore } from "../sidebar-store";
import { AvailablePanelsBasedOnPosition } from "../../../availablePanelsByPosition";
import { Wrapper } from "../styled";
import { MediumBlueRightIcon } from "./Icons";
import { usePanelsStore } from "../../../../store/panels-store";
import { nanoid } from "nanoid";
import { DEFAULT_DATA_BY_PANEL_KEY } from "../../../panelDataByKey";
import { SIDEBAR_TYPES } from "../types";
import HoverDialog from "./preview/HoverDialog";
import { useChangesStore } from "../../../../../../track-changes-store";
import { PanelCategories, PanelFilters } from "../../../panelTypes";
import { analytics } from "../../../../../../../../analytics";
import { PanelKeys } from "../../../panelTypes";
import { COLOR_TYPES } from "../../../../../Settings/General/BrandAndColors/color-types";
import { useCorporateIdentityStore } from "../../../../../Settings/General/BrandAndColors/corporate-identity-store";

const FilterCategories = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 20px;
`;

const CategoryLabel = styled.span`
  color: ${({ isActive, theme }) =>
    isActive ? theme.shared.sidebar.preview.tabs.active.text : theme.shared.sidebar.preview.tabs.inactive.text};
  background: ${({ isActive, theme }) =>
    isActive
      ? theme.shared.sidebar.preview.tabs.active.background
      : theme.shared.sidebar.preview.tabs.inactive.background};
  font-size: 14px;
  padding: 4px 8px;
  border-radius: 20px;
  cursor: pointer;
  text-transform: capitalize;

  .count {
    font-weight: 500;
    margin-left: 6px;
  }
`;

const ActionIconContainer = styled.div`
  width: 24px;
  height: 24px;
  display: grid;
  place-content: center;

  visibility: hidden;
`;

const PanelWrapper = styled.div`
  padding: 12px;
  display: grid;
  grid-template-columns: 24px auto 24px;
  column-gap: 12px;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.shared.sidebar.list.background};
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.shared.sidebar.list.hover};
    transition: background-color 50ms linear;

    ${ActionIconContainer} {
      visibility: visible;
    }
  }
`;

const Title = styled.span`
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: ${({ theme }) => theme.shared.sidebar.list.title};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Description = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  color: ${({ theme }) => theme.shared.sidebar.list.description};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

function AddPanels() {
  const [category, setCategory] = useState(PanelCategories.ALL);

  const { colors } = useCorporateIdentityStore((state) => ({
    colors: state.colors,
  }));

  const { panels, setPanels, setEditingPanel } = usePanelsStore((state) => ({
    panels: state.panels,
    setPanels: state.setPanels,
    setEditingPanel: state.setEditingPanel,
  }));

  const { selectedContentType, setSidebarType, setHoveringPanel } = useSidebarStore((state) => ({
    selectedContentType: state.selectedContentType,
    setSidebarType: state.setSidebarType,
    setHoveringPanel: state.setHoveringPanel,
  }));

  const primaryColor = "#0065ff";

  const { setHasChanges } = useChangesStore((state) => ({
    setHasChanges: state.setHasChanges,
  }));

  const availablePanels = useMemo(() => AvailablePanelsBasedOnPosition[selectedContentType], [selectedContentType]);

  const filteredPanels = useMemo(
    () => availablePanels.filter((panel) => Object.values(PanelFilters[category]).includes(panel.key)),
    [availablePanels, category],
  );

  const categoryItemsCount = useMemo(() => {
    const filtersCount = {};
    Object.entries(PanelFilters).forEach(([filterKey, panels]) => {
      Object.assign(filtersCount, {
        [filterKey]: panels.filter((panel) => availablePanels.find((availablePanel) => availablePanel.key === panel))
          .length,
      });
    });
    return filtersCount;
  }, [availablePanels]);

  const generateData = (data) => {
    const newData = { ...data };

    if (colors[COLOR_TYPES.WIDGET]) {
      newData.labelsBackground = colors[COLOR_TYPES.WIDGET];
      newData.teamLabelsBackground = colors[COLOR_TYPES.WIDGET];
      return newData;
    } else {
      return data;
    }
  };

  const generateNewPanel = (key, type, title) => {
    const currentPanels = panels.filter((p) => p.position === selectedContentType);
    const biggestOrder = Math.max(...currentPanels.map((p) => p.order));

    const newPanelId = nanoid(11);

    const newPanel = {
      id: newPanelId,
      key,
      order: biggestOrder + 1,
      position: selectedContentType,
      type,
      panelTitle: title,
      isHiddenFromAnonymous: true,
      data:
        key === PanelKeys.COMPANY_NEWS || key === PanelKeys.TEAM_NEWS
          ? generateData(DEFAULT_DATA_BY_PANEL_KEY[key])
          : DEFAULT_DATA_BY_PANEL_KEY[key],
    };

    window.AP.events.emit("new-panel-added", { newPanelId });
    analytics.logEvent({ name: "Dashboard module", template: "create", params: { module: newPanel.panelTitle } });

    setPanels([...panels, newPanel]);
    setEditingPanel(newPanel);
    setSidebarType(SIDEBAR_TYPES.PANEL_EDIT);
    setHasChanges(true);
  };

  const onMouseEnter = useCallback((event, key, Icon) => {
    event.preventDefault();
    setHoveringPanel({ key, Icon });
  }, []);

  const onMouseLeave = useCallback((event) => {
    event.preventDefault();
    setHoveringPanel(undefined);
  }, []);

  return (
    <Wrapper style={{ marginTop: "20px" }}>
      <FilterCategories>
        {Object.keys(PanelFilters).map((panelFilter) => (
          <CategoryLabel key={panelFilter} isActive={panelFilter === category} onClick={() => setCategory(panelFilter)}>
            {panelFilter}
            <span className="count">{categoryItemsCount[panelFilter]}</span>
          </CategoryLabel>
        ))}
      </FilterCategories>

      {filteredPanels.map(({ key, dataKey, type, title, description, Icon }) => (
        <PanelWrapper
          key={key}
          onClick={() => generateNewPanel(dataKey || key, type, title)}
          onMouseMove={(e) => onMouseEnter(e, key, Icon)}
          onMouseEnter={(e) => onMouseEnter(e, key, Icon)}
          onMouseLeave={(e) => onMouseLeave(e)}
        >
          <Icon />
          <Wrapper>
            <Title>{title}</Title>
            <Description>{description}</Description>
          </Wrapper>
          <ActionIconContainer>
            <MediumBlueRightIcon primaryColor={primaryColor} />
          </ActionIconContainer>
        </PanelWrapper>
      ))}

      <HoverDialog />
    </Wrapper>
  );
}

export default AddPanels;
