import httpClient from "../../../../../../Service/http-client";

export async function getCosmosLayout({ queryKey }) {
  const url = `ac/confluence/api/admin/cosmos-layout`;
  return httpClient.get(url);
}

export async function saveCosmosLayout(layout) {
  const url = `ac/confluence/api/admin/cosmos-layout`;
  return httpClient.post(url, { data: layout });
}
