import React from "react";
import styled from "styled-components";
import TextField from "@atlaskit/textfield";
import { FieldContainer, Label } from "./styled";

const TextInputWrapper = styled.div`
  div:first-child {
    border-color: ${({ theme }) => theme.shared.form.input.border};
    color: ${({ theme }) => theme.shared.form.input.text};
    background-color: ${({ theme }) => theme.shared.form.input.background};

    &:hover,
    &:focus,
    &:active {
      background-color: ${({ theme }) => theme.shared.form.input.hover};
    }
  }
`;

const TextInputFilter = ({
  value,
  onUpdate,
  label,
  placeholder = "",
  customMargin,
  containerStyles = {},
  isDisabled = false,
}) => {
  const handleChange = (e) => {
    onUpdate(e.target.value);
  };

  return (
    <FieldContainer
      style={customMargin ? { marginTop: `${customMargin}`, ...containerStyles } : { ...containerStyles }}
    >
      {label && <Label>{label}</Label>}

      <TextInputWrapper>
        <TextField
          className="text-field-input-filter"
          appearance="subtle"
          value={value}
          placeholder={placeholder}
          type={"text"}
          onChange={handleChange}
          isDisabled={isDisabled}
        />
      </TextInputWrapper>
    </FieldContainer>
  );
};

export default TextInputFilter;
