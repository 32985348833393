import React, { useEffect, useState } from "react";
import styled from "styled-components";
import EditorRemoveIcon from "@atlaskit/icon/glyph/editor/remove";
import AvatarGroup from "@atlaskit/avatar-group";
import IconRenderer from "../../../Shared/Components/Icons/IconRenderer";
import { useQuery } from "react-query";
import { fetchRecords } from "../../UserDatabase/datagrid/api";
import { fetchSingleUser } from "../api";
import { nanoid } from "nanoid";
import { IconTypes } from "../../../Shared/Components/SidebarIconPicker/IconEmojiSidebar";
import { useTheme } from "styled-components";

const DeleteIconContainer = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;

  display: grid;
  place-content: center;

  padding: 4px;
  border-radius: 3px;
  background-color: transparent;

  visibility: hidden;

  &:hover {
    background-color: transparent !important;
  }
`;

const CardWrapper = styled.div`
  box-sizing: border-box;
  background: ${({ backgroundColor, theme }) => backgroundColor || theme.collections.collectionCardBackground};
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.collections.collectionCardBorder};
  padding: 15px 20px;

  position: relative;

  display: flex;
  flex-direction: column;
  row-gap: 14px;

  cursor: pointer;

  &:hover {
    border: 1px solid ${({ theme }) => theme.collections.collectionCardBorderHover};

    ${DeleteIconContainer} {
      visibility: visible;

      &:hover {
        opacity: 0.9;
        background-color: #f3f3f3;
      }
    }
  }
`;

const IconNameWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 15px;
`;

const IconContainer = styled.div`
  width: 38px;
  height: 38px;
  background: #f4f4f4;
  border-radius: 50%;
  display: grid;
  place-content: center;
`;

const Name = styled.span`
  font-weight: 700;
  font-size: 13.91px;
  line-height: 20px;
  color: ${({ theme }) => theme.collections.collectionCardTitle};
`;

const Description = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #8993a4;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 40px;
`;

const AvatarLink = styled.a`
  height: 32px;
  width: 32px;
  -webkit-box-align: stretch;
  align-items: stretch;
  background-color: #ffffff;
  border-radius: 50%;
  box-sizing: content-box;
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  outline: none;
  overflow: hidden;
  transform: translateZ(0px);
  transition:
    transform 200ms ease 0s,
    opacity 200ms ease 0s;
  box-shadow: 0 0 0 2px #ffffff;
  border: none;
  margin: 2px;
  padding: 0px;
  font-size: inherit;
  font-family: inherit;
  position: relative;
  cursor: pointer;
`;

const AvatarImage = styled.img`
  display: flex;
  width: 100%;
  height: 100%;
  flex: 1 1 100%;
  border-radius: 50%;
  max-width: 100%;
`;

const NUMBER_OF_USERS_IN_PREVIEW = 6;

const CustomAvatar = (props) => {
  return (
    <AvatarLink>
      <AvatarImage src={props.icon} />
    </AvatarLink>
  );
};

function CollectionCard({ id, name, backgroundColor, description, icon, filters, userGroup, onClick, onDeleteClick }) {
  const { data: recordsData } = useQuery(
    [
      "userdatabase-records",
      {
        filters: [...filters, { isMultiUser: true, type: "BOOLEAN", value: true, column: "hidden", condition: "NOT" }],
        confluenceGroup: userGroup || "",
      },
    ],
    fetchRecords,
    {
      retry: 0,
      select: (response) => {
        const { data } = response;
        return { users: data?.hits || [], totalSize: data?.totalSize || 0 };
      },
    },
  );

  const [avatarGroup, setAvatarGroup] = useState([]);

  const theme = useTheme();

  useEffect(() => {
    if (!!recordsData?.users?.length) {
      (async () => {
        const usersToGet = recordsData.users.slice(0, NUMBER_OF_USERS_IN_PREVIEW);
        const promises = [];
        for (const user of usersToGet) {
          promises.push(fetchSingleUser(user.accountId));
        }
        Promise.all(promises)
          .then((results) => {
            const users = results.map((v) => {
              const parsedUser = JSON.parse(v.body);
              return {
                id: parsedUser.accountId,
                key: parsedUser.accountId,
                name: parsedUser.displayName,
                icon: parsedUser.profilePicture?.path
                  ? `${window.AP._hostOrigin}${parsedUser.profilePicture.path}`
                  : "",
              };
            });

            const remainingUsers = recordsData.totalSize > users.length ? recordsData.totalSize - users.length : 0;
            const fullUsers = [...users];

            if (remainingUsers > 0) {
              for (let i = 0; i < remainingUsers; i++) {
                const id = nanoid(24);
                fullUsers.push({
                  id,
                  key: id,
                  name: "",
                  icon: "",
                });
              }
            }

            setAvatarGroup([...fullUsers]);
          })
          .catch((e) => {
            setAvatarGroup([]);
          });
      })();
    }
  }, [recordsData]);

  return (
    <CardWrapper onClick={() => onClick()} backgroundColor={backgroundColor}>
      <DeleteIconContainer onClick={(event) => onDeleteClick(event, id)}>
        <EditorRemoveIcon primaryColor={theme.poll.icons} />
      </DeleteIconContainer>

      <IconNameWrapper>
        <IconContainer>
          {(!icon || icon?.type === IconTypes.ICON) && <IconRenderer icon={icon?.data} />}
          {icon?.type === IconTypes.EMOJI && icon?.data && (
            <em-emoji shortcodes={icon.data.shortcodes} style={{ height: "29px" }} size="1.2em"></em-emoji>
          )}
        </IconContainer>
        <Name>{name}</Name>
      </IconNameWrapper>

      <Description>{description}</Description>

      <AvatarGroup
        appearance="stack"
        data={avatarGroup}
        maxCount={NUMBER_OF_USERS_IN_PREVIEW}
        isTooltipDisabled
        onAvatarClick={() => {}}
        overrides={{
          Avatar: {
            render: (Component, props, index) => {
              return <CustomAvatar {...props} />;
            },
          },
        }}
      />
    </CardWrapper>
  );
}

export default CollectionCard;
