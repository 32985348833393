import { isEqual } from "lodash";
import { sortStringArray } from "./sortArray";
import { updateBlogToCollections } from "../../Components/Collections/api";
import { createProperty, deleteProperty, updateProperty } from "../../Shared/Functions/newsPropertiesHandlers";

export const saveImage = async (blogId, propertyData, imageData) => {
  const { propertyExists, version, propertyId } = propertyData;

  const hasImage = !!imageData?.background?.image?.link;

  if (propertyExists) {
    const fn = {
      [true]: updateProperty,
      [false]: deleteProperty,
    };

    const status = await fn[hasImage](blogId, propertyId, "cosmos-news-image", version, imageData);
    return status;
  } else {
    const status = await createProperty(blogId, imageData, "cosmos-news-image");
    return status;
  }
};

export const saveCollections = async (blogId, selectedCollections, savedCollections, collectionChanges) => {
  try {
    if (!isEqual(sortStringArray(savedCollections), sortStringArray(selectedCollections))) {
      await updateBlogToCollections(blogId, collectionChanges);
    }
    return true;
  } catch (error) {
    return false;
  }
};

export const saveCategories = async (blogId, propertyData, selectedCategories) => {
  const { propertyExists, version, propertyId } = propertyData;

  if (propertyExists) {
    const status = await updateProperty(blogId, propertyId, "cosmosnewsmetadata", version, {
      categories: selectedCategories.map((c) => c.value).join(","),
    });
    return status;
  } else {
    const status = await createProperty(
      blogId,
      {
        categories: selectedCategories.map((c) => c.value).join(","),
      },
      "cosmosnewsmetadata",
    );
    return status;
  }
};
