import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import shallow from "zustand/shallow";
import { nanoid } from "nanoid";

import TextField from "@atlaskit/textfield";
import Field from "@atlaskit/form/Field";
import Select from "@atlaskit/select";
import Button from "@atlaskit/button";

import { FieldType } from "../../../field-type";
import { useFieldStore } from "./field-store";
import SelectSettings from "./SelectSettings";
import NumberFormat from "./NumberFormat";
import DateFormat from "./DateFormat";

const CreateFieldWrapper = styled.div`
  width: 320px;
  font-size: 14px;
`;

const FormWrapper = styled.div`
  padding: 10px;
  label {
    color: #626f86;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
  }
`;

const FieldActionWrapper = styled.div`
  text-align: right;
  padding: 10px;
`;

const TYPE_OPTIONS = [
  { id: FieldType.TEXT, label: "Text", value: FieldType.TEXT },
  { id: FieldType.URL, label: "URL", value: FieldType.URL },
  { id: FieldType.NUMBER, label: "Number", value: FieldType.NUMBER },
  { id: FieldType.SELECT, label: "Select", value: FieldType.SELECT },
  { id: FieldType.MULTISELECT, label: "Multiselect", value: FieldType.MULTISELECT },
  { id: FieldType.USER, label: "User", value: FieldType.USER },
  { id: FieldType.DATE, label: "Date", value: FieldType.DATE },
];

const CreateField = ({ onCancel, onSave, position, existingField = undefined, labelName = "Field", focus = false }) => {
  const [title, setTitle] = useState(existingField?.title ?? "");
  const [fieldType, setFieldType] = useState(existingField?.type ?? "");

  const [selectedFieldType, setSelectedFieldType] = useState("");

  const createFieldWrappeRef = useRef();

  const { args, setArgs } = useFieldStore((state) => {
    return {
      args: state.args,
      setArgs: state.setArgs,
    };
  }, shallow);

  useEffect(() => {
    if (createFieldWrappeRef.current) {
      createFieldWrappeRef.current.parentNode.style.overflow = "visible";
    }
  }, [createFieldWrappeRef]);

  return (
    <CreateFieldWrapper ref={createFieldWrappeRef}>
      <FormWrapper>
        <Field name="field-name" label={`${labelName} Name`}>
          {({ fieldProps }) => {
            return (
              <TextField
                autoFocus={focus}
                value={title}
                placeholder={`Type ${labelName.toLowerCase()} name here...`}
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
              />
            );
          }}
        </Field>
        <Field name="field-type" label={`${labelName} Type`}>
          {({ fieldProps }) => {
            return (
              <Select
                value={selectedFieldType}
                options={TYPE_OPTIONS}
                placeholder={`Select ${labelName.toLowerCase()} type`}
                maxMenuHeight={125}
                onChange={(result) => {
                  setSelectedFieldType(result);
                  setFieldType(result.id);

                  if (result.id === FieldType.NUMBER) {
                    setArgs({ format: "integer" });
                  } else if (result.id === FieldType.DATE) {
                    setArgs({ recurring: false });
                  } else if (result.id.includes("SELECT")) {
                    setArgs({ isCreatable: true });
                  } else {
                    setArgs({});
                  }
                }}
              />
            );
          }}
        </Field>
      </FormWrapper>

      <div style={{ padding: "0 10px" }} onClick={(e) => e.stopPropagation()}>
        {selectedFieldType.id === FieldType.DATE && (
          <DateFormat recurring={args.recurring} onUpdate={(recurring) => setArgs({ recurring })} />
        )}

        {selectedFieldType.id === FieldType.NUMBER && (
          <NumberFormat format={args.format} onUpdate={(format) => setArgs({ format })} />
        )}

        {selectedFieldType.id?.includes("SELECT") && (
          <SelectSettings isCreatable={args.isCreatable} onUpdate={(isCreatable) => setArgs({ isCreatable })} />
        )}
      </div>

      <FieldActionWrapper>
        <Button appearance="link" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          appearance="primary"
          onClick={() => {
            if (title && fieldType) {
              const defaultUI = {
                readonly: false,
                position,
                fixed: false,
                hasMenu: false,
                width: 200,
              };
              onSave({
                id: nanoid(10),
                title,
                type: fieldType,
                ui: defaultUI,
                ...args,
              });
            }
          }}
        >
          Create
        </Button>
      </FieldActionWrapper>
    </CreateFieldWrapper>
  );
};

export default CreateField;
