import React from "react";
import styled from "styled-components";
import { FieldContainer, Label } from "./styled";

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  column-gap: 15px;
`;

const Button = styled.button`
  all: unset;
  display: grid;
  place-content: center;
  padding: 12px 0px;
  border-radius: 3px;
  background-color: ${({ isActive, primaryColor, theme }) =>
    isActive ? `${primaryColor}1a` : `${theme.shared.form.button.background.inactive}`};
  transition: background-color 150ms linear;

  svg,
  rect {
    fill: ${({ isActive, primaryColor }) => (isActive ? primaryColor : "#97A0AF")} !important;
  }

  cursor: pointer;
`;

const IconContainer = styled.div`
  display: grid;
  place-content: center;
  width: 16px;
  height: 16px;
`;

const OneColumnIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
    <rect x="2" y="2" width="12" height="12" rx="0.5" fill="#97A0AF" />
  </svg>
);

const TwoColumnIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
    <rect x="9" y="2" width="5" height="12" rx="0.5" fill="#97A0AF" />
    <rect x="2" y="2" width="5" height="12" rx="0.5" fill="#97A0AF" />
  </svg>
);

const ThreeColumnIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
    <rect x="6" y="2" width="4" height="12" rx="0.5" fill="#97A0AF" />
    <rect x="11" y="2" width="4" height="12" rx="0.5" fill="#97A0AF" />
    <rect x="1" y="2" width="4" height="12" rx="0.5" fill="#97A0AF" />
  </svg>
);

const FourColumnIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1.5" y="2" width="2.5" height="12" rx="0.5" fill="#97A0AF" />
    <rect x="5" y="2" width="2.5" height="12" rx="0.5" fill="#97A0AF" />
    <rect x="8.5" y="2" width="2.5" height="12" rx="0.5" fill="#97A0AF" />
    <rect x="12" y="2" width="2.5" height="12" rx="0.5" fill="#97A0AF" />
  </svg>
);

function ColumnNumberPicker({ label, value, setValue, primaryColor = "#0065ff" }) {
  return (
    <FieldContainer>
      <Label>{label}</Label>

      <Wrapper>
        <Button isActive={value === 1} primaryColor={primaryColor} onClick={() => setValue(1)}>
          <IconContainer>
            <OneColumnIcon />
          </IconContainer>
        </Button>

        <Button isActive={value === 2} primaryColor={primaryColor} onClick={() => setValue(2)}>
          <IconContainer>
            <TwoColumnIcon />
          </IconContainer>
        </Button>

        <Button isActive={value === 3} primaryColor={primaryColor} onClick={() => setValue(3)}>
          <IconContainer>
            <ThreeColumnIcon />
          </IconContainer>
        </Button>

        <Button isActive={value === 4} primaryColor={primaryColor} onClick={() => setValue(4)}>
          <IconContainer>
            <FourColumnIcon />
          </IconContainer>
        </Button>
      </Wrapper>
    </FieldContainer>
  );
}

export default ColumnNumberPicker;
