import styled, { useTheme } from "styled-components";
import { useRef, useEffect, useState, useMemo } from "react";
import { SidePanel, SidePanelHeader } from "../General";
import SingleTileColorPicker from "../../../../../../Shared/Components/SingleTileColorPicker";

import { COLOR_TYPES, getDefaultColorsBasedOnTheme } from "../BrandAndColors/color-types";
import { saveCorporateIdentitySettings } from "../../../../api";
import debounce from "lodash.debounce";
import { useCorporateIdentityStore } from "./corporate-identity-store";

const ResetToDefault = styled.p`
  color: ${({ theme }) => theme.global.primaryColor};
  font-size: 14px;
  font-weight: 400;
  margin-top: 0;
  margin-left: auto;
  position: relative;
  bottom: 18px;
  cursor: pointer;
`;

const ColorTitle = styled.p`
  color: ${({ theme }) => theme.shared.settings.general.brandAndColors.sectionTitleColor};
  font-size: 14px;
  font-weight: 500;
`;

const THEME_NAMES = {
  light: "colors",
  dark: "colors_dark",
};

function Colors() {
  const theme = useTheme();

  const { colors, setColors } = useCorporateIdentityStore((state) => ({
    colors: state.colors,
    setColors: state.setColors,
  }));

  const DEFAULT_COLORS = useMemo(() => getDefaultColorsBasedOnTheme(theme.global.name), [theme]);

  const [isUsingDefaultColors, setIsUsingDefaultColors] = useState(true);

  const handleSaveDebounce = useRef(
    debounce(async (_colors) => {
      try {
        await saveCorporateIdentitySettings({ [THEME_NAMES[theme.global.name]]: _colors });
      } catch {
        console.log("failed to save colors");
      }
    }, 500),
  ).current;

  const handleUpdateColor = (value, type) => {
    if (value) {
      const newColors = { ...colors, [type]: value };

      handleSaveDebounce(newColors);
      setColors(newColors);
    }
  };

  const resetColors = () => {
    const newColors = {
      ...colors,
      [COLOR_TYPES.PRIMARY]: DEFAULT_COLORS[COLOR_TYPES.PRIMARY],
    };

    handleSaveDebounce(newColors);
    setColors(newColors);
  };

  useEffect(() => {
    setIsUsingDefaultColors(colors[COLOR_TYPES.PRIMARY] === DEFAULT_COLORS[COLOR_TYPES.PRIMARY]);
  }, [colors, DEFAULT_COLORS]);

  useEffect(() => {
    return () => {
      handleSaveDebounce.cancel();
    };
  }, [handleSaveDebounce]);

  return (
    <SidePanel style={{ marginTop: "0px", marginLeft: "0px" }}>
      <SidePanelHeader>
        <div>Colors</div>
        {!isUsingDefaultColors && (
          <ResetToDefault
            onClick={() => {
              resetColors();
            }}
          >
            Reset to default
          </ResetToDefault>
        )}
      </SidePanelHeader>

      <div style={{ paddingLeft: "25px", paddingRight: "25px" }}>
        <ColorTitle>Primary Color</ColorTitle>

        <SingleTileColorPicker
          label="Links, action buttons and active sidebar icons."
          value={colors[COLOR_TYPES.PRIMARY]}
          setValue={(value) => {
            handleUpdateColor(value, COLOR_TYPES.PRIMARY);
          }}
          isDisabled={false}
          disableAlpha
          disableTransparent
        />
      </div>
    </SidePanel>
  );
}

export default Colors;
