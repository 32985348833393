import React from "react";
import styled from "styled-components";
import InfoIcon from "@atlaskit/icon/glyph/info";
import { InfoPanel } from "../../../../Dashboard/panels/edit/types/styled";

const PanelContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;

const Title = styled.span`
  color: ${({ theme }) => theme.shared.settings.title};
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
`;

const Content = styled.p`
  all: unset;
  color: ${({ theme }) => theme.shared.settings.title};
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`;

export const StyledContent = ({ title, description }) => (
  <PanelContentWrapper>
    <Title>{title}</Title>
    <Content>{description}</Content>
  </PanelContentWrapper>
);

function InformationPanel({ content }) {
  return (
    <InfoPanel style={{ padding: "16px", columnGap: "16px" }}>
      <InfoIcon primaryColor="#0052cc" />
      {content}
    </InfoPanel>
  );
}

export default InformationPanel;
