import create from "zustand";

export const useUnderConstructionStore = create((set, get) => ({
  underConstructionActivated: undefined,

  setUnderConstructionActivated: (_underConstructionActivated) => set((state) => ({ underConstructionActivated: !!_underConstructionActivated })),

  messageTitle: undefined,

  setMessageTitle: (_messageTitle) => set((state) => ({ messageTitle: _messageTitle })),

  messageDescription: undefined,

  setMessageDescription: (_messageDescription) => set((state) => ({ messageDescription: _messageDescription })),

  messageButton: undefined,

  setMessageButon: (_messageButon) => set((state) => ({ messageButton: _messageButon })),

  messageLink: undefined,

  setMessageLink: (_messageLink) => set((state) => ({ messageLink: _messageLink })),
}));
