import styled from "styled-components";
import { token } from "@atlaskit/tokens";

export const ContentPanel = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;

  width: 628px;
  height: fit-content;

  border-radius: 3px;
  background-color: ${({ theme }) => theme.shared.settings.general.panelBackground};
  box-shadow:
    0px 0px 1px 0px rgba(9, 30, 66, 0.31),
    0px 1px 3px 0px rgba(9, 30, 66, 0.25);

  padding-top: 20px;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 35px;
`;

export const ContentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height: 32px;
  border-bottom: 1px solid ${({ theme }) => theme.shared.settings.general.panelBorderBottom};
  padding-bottom: 4px;
`;

export const ContentTitle = styled.h1`
  margin: 0;
  font-size: 16px;
  font-weight: 400;
  color: ${({ theme }) => theme.shared.settings.general.panelHeaderColor};
`;

export const ContentDescription = styled.p`
  color: ${token("color.text.subtlest")};
  font-size: 12px;
  margin: 0;
`;

export const ContentButtonLink = styled.button`
  all: unset;
  cursor: pointer;
  font-size: 14px;
  color: ${({ theme }) => theme.global.text.blue};
`;

export const ContentSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 23px;
  gap: 32px;
`;

export const ContentSection = styled.section`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ContentSectionHeading = styled.div`
  display: flex;
  justify-content: space-between;
  place-items: center;
`;

export const ContentSectionForm = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const ContentSectionTitle = styled.h1`
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.shared.settings.general.panelHeaderColor};
`;

export const ContentSectionDescription = styled.p`
  margin: 0;
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme.shared.settings.general.brandAndColors.pageHeaderTextColor};
  max-width: 70ch;
`;

export const Wrapper = styled.div`
  padding: 35px 30px;
  height: calc(100% - 30px);
  width: calc(100% - 5px);
  margin: auto;
  overflow-y: auto;
  padding-left: 0px;
  padding-right: 0px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  padding-left: 30px;
  padding-right: 30px;
`;

export const Divider = styled.div`
  width: 100%;
  border-bottom: 2px solid ${({ theme }) => theme.shared.settings.titleDivider};
`;

export const Content = styled.div`
  margin-top: 30px;
  padding-left: 30px;
  padding-right: 30px;
`;

export const ContentContainer = styled.div`
  display: flex;
  gap: 32px;
`;

export const ContentRadioContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 18px;
  font-size: 14px;
`;

export const PreviewContainer = styled.div`
  display: grid;
  place-content: center;
  padding-block: 42px;
  width: 100%;
  border-radius: 3px;
  box-shadow:
    0px 1px 3px 0px rgba(9, 30, 66, 0.25),
    0px 0px 1px 0px rgba(9, 30, 66, 0.31);
  background-color: rgba(161, 189, 217, 0.08);
`;

export const TabContainer = styled.div`
  padding: 4px 8px;
`;

export const ManualContainer = styled.div`
  display: flex;
  flex-direction: column;
  place-items: center;
  gap: 16px;
  width: 450px;
`;

export const ManualContainerHeading = styled.h1`
  margin: 0;
  font-size: 20px;
  font-weight: 500;
`;

export const ManualContainerDescription = styled.p`
  margin: 0;
  font-size: 14px;
  text-align: center;
`;

export const FormField = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const FormFieldLabel = styled.h1`
  margin: 0;
  font-size: 14px;
  font-weight: 400;
`;

export const FormFieldLinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  border-top: 1px solid rgba(9, 30, 66, 0.14);
  padding-top: 12px;
`;

export const ContentSectionToggleContainer = styled.div`
  padding: 20px 24px;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.shared.settings.general.panelBackground};
  box-shadow:
    0px 1px 3px 0px rgba(9, 30, 66, 0.25),
    0px 0px 1px 0px rgba(9, 30, 66, 0.31);
`;

export const ToggleContainerHeading = styled.div`
  display: flex;
  place-items: center;
  justify-content: space-between;
`;

export const ToggleContainerTitle = styled.h1`
  margin: 0;
  font-size: 16px;
  color: ${token("color.text.subtle")};
  font-weight: 400;
`;

export const ToggleContainerDescription = styled.p`
  margin: 0;
  font-size: 12px;
  color: ${token("color.text.subtlest")};
  padding-top: 8px;
`;

export const PhoneContainer = styled.div`
  width: 269.25px;
  height: 533px;
  margin-left: 15.25px;
  margin-top: 65px;
  background-color: #fff;
  border-bottom-left-radius: 38px;
  border-bottom-right-radius: 38px;
`;

export const ScreenContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 12px;
  font-family: "SF Pro";
  padding-inline: 11px;
  height: 100%;
`;

export const ScreenTitle = styled.h1`
  margin: 0;
  font-family: "SF Pro Display";
  font-weight: 700;
  font-size: 24px;
  letter-spacing: 0.259px;
`;

export const FilterContainer = styled.div`
  display: flex;
  gap: 12px;
`;

export const SearchContainer = styled.div`
  width: 100%;
  background-color: #efeff0;
  border-radius: 27px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const SearchContent = styled.div`
  display: flex;
  gap: 8px;
  padding-inline: 6px;
`;

export const SearchLabel = styled.p`
  margin: 0;
  font-size: 12px;
  font-weight: 400;
  color: rgba(60, 60, 67, 0.6);
`;

export const FilterButton = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: ${({ backgroundColor }) => backgroundColor};
  display: grid;
  place-content: center;
`;

export const LabelsContainer = styled.div`
  display: flex;
`;

export const CategoryLabel = styled.div`
  border-radius: 30px;
  padding: 7px;
  font-size: 10px;
  font-weight: 500;
  color: #aeaeb2;
`;

export const ScreenNavigation = styled.div`
  position: absolute;
  padding-inline: 11px;
  padding-top: 6px;
  background: #fefefe;
  bottom: 0;
  left: 0;
  right: 0;
  height: 60px;
  width: 247px;
  border-top: 1px solid #d9d9d9;
  border-bottom-left-radius: 38px;
  border-bottom-right-radius: 38px;
`;

export const ScreenIconsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
`;

export const NavigationIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  place-items: center;
`;

export const NavigationIcon = styled.div`
  width: 24px;
  height: 24px;
`;

export const NavigationIconLabel = styled.h1`
  font-size: 8px;
  margin: 0;
  color: #757f8a;
  font-weight: 400;
`;

export const NewsroomHeading = styled.div`
  display: flex;
  flex-direction: column;
`;

export const NewsroomCategory = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const NewsroomTitle = styled.h1`
  margin: 0;
  font-size: 12px;
`;

export const NewsroomLink = styled.p`
  margin: 0;
  color: #007aff;
  font-size: 10px;
`;

export const NewsroomDescription = styled.p`
  margin: 0;
  font-size: 10px;
  color: #8e8e93;
`;

export const NewsroomList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const NewsroomItem = styled.div`
  display: flex;
  gap: 6px;
`;

export const NewsroomItemImage = styled.div`
  width: 61px;
  height: 61px;
  border-radius: 5px;
`;

export const NewsroomItemData = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
`;

export const NewsroomItemLabel = styled.p`
  margin: 0;
  color: rgba(0, 0, 0, 0.5);
  font-size: 10px;
  text-transform: uppercase;
`;

export const NewsroomItemTitle = styled.h1`
  margin: 0;
  font-size: 10px;
  font-weight: 600;
`;

export const NewsroomItemInfo = styled.p`
  margin: 0;
  color: rgba(0, 0, 0, 0.5);
  font-size: 10px;
  font-weight: 400;
`;

export const PeopleList = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PersonContainer = styled.div`
  display: flex;
  place-items: center;
  gap: 12px;
  padding: 7px;
  justify-content: space-between;
`;

export const PersonData = styled.div`
  display: flex;
  place-items: center;
  gap: 12px;
`;

export const PersonName = styled.h1`
  margin: 0;
  font-size: 11px;
  font-weight: 500;
`;

export const PersonJob = styled.p`
  margin: 0;
  font-size: 11px;
  color: #8e8e93;
  font-weight: 400;
`;

export const PersonInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PeopleHeading = styled.h1`
  margin: 0;
  font-size: 12px;
  font-weight: 600;
  padding-top: 5px;
`;

export const ControlButton = styled.button`
  position: absolute;
  bottom: 30px;
  background: ${({ backgroundColor }) => backgroundColor};
  color: white;
  border-radius: 100px;
  width: 245px;
  padding: 10px;
  left: 10px;
  border: none;
`;

export const ControlContainer = styled.div`
  display: flex;
  flex-direction: column;
  place-items: center;
  gap: 16px;
  max-width: 265px;
`;

export const ControlTitle = styled.h1`
  margin: 0;
  font-size: 16px;
  text-align: center;
  word-break: break-word;
`;

export const ControlDescription = styled.p`
  margin: 0;
  font-size: 12px;
  text-align: center;
  color: #aeaeb2;
  word-break: break-word;
`;

export const ConfigurationItem = styled.div`
  display: flex;
  justify-content: space-between;
  place-items: center;
`;

export const ConfigurationItemData = styled.div`
  display: flex;
  gap: 16px;
  place-items: center;
`;

export const ConfigurationItemLabel = styled.h1`
  margin: 0;
  font-size: 16px;
  font-weight: 500;
`;

export const ConfigurationItemLink = styled.span`
  cursor: pointer;
  font-size: 14px;
  color: ${token("color.link")};
`;
