import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Select from "@atlaskit/select";
import AvatarGroup from "@atlaskit/avatar-group";
import { useQuery } from "react-query";
import { fetchCollections } from "../../../Collections/api";
import { FieldContainer, Label } from "../Dashboard/filter/styled";
import IconRenderer, { IconSizes } from "../../../../Shared/Components/Icons/IconRenderer";
import { IconTypes } from "../../../../Shared/Components/SidebarIconPicker/IconEmojiSidebar";
import { fetchRecords } from "../../../UserDatabase/datagrid/api";
import { atlassianRestService } from "../../../../Service/AtlassianRestService";
import { nanoid } from "nanoid";

const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 16px;
`;

const IconName = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
`;

const IconContainer = styled.div`
  display: grid;
  place-content: center;
  width: 24px;
  height: 24px;
  box-sizing: border-box;
  border-radius: 50%;
  background-color: ${({ clr }) => clr};
`;

const Name = styled.div`
  color: ${({ theme }) => theme.shared.form.select.option};
  font-size: 14px;
  line-height: 16px;
`;

const mapDummyData = () => {
  const id = nanoid(4);

  return {
    id: id,
    key: id,
    name: "",
    src: "",
  };
};

const CollectionLabel = ({ icon, name, color, filters = [], userGroup, displayUsers = true }) => {
  const [userData, setUserData] = useState([]);

  const { data: recordsData } = useQuery(
    [
      "userdatabase-records",
      {
        filters: [...filters, { isMultiUser: true, type: "BOOLEAN", value: true, column: "hidden", condition: "NOT" }],
        confluenceGroup: userGroup || "",
      },
    ],
    fetchRecords,
    {
      enabled: displayUsers,
      retry: 0,
      select: (response) => {
        const { data } = response;
        return { users: data?.hits || [], totalSize: data?.totalSize || 0 };
      },
    },
  );

  useEffect(() => {
    if (!!recordsData?.users?.length && displayUsers) {
      (async () => {
        const usersToGet = recordsData.users.slice(0, 5).map((u) => u.accountId);
        const remainderCount = recordsData.users.length - 5;

        const remainder = [];
        for (let index = 0; index < remainderCount; index++) {
          remainder.push(mapDummyData());
        }

        try {
          const data = await atlassianRestService.fetchUsers(usersToGet);
          if (!data?.length) return [];

          const users = [
            ...data.map((user) => ({
              id: user.accountId,
              key: user.accountId,
              name: user.displayName,
              src: user.profilePicture?.path ? `${window.AP._hostOrigin}${user.profilePicture.path}` : "",
            })),
            ...remainder,
          ];

          setUserData([...users]);
        } catch (error) {
          setUserData([]);
        }
      })();
    }
  }, [recordsData, displayUsers]);

  return (
    <LabelWrapper>
      <IconName>
        <IconContainer clr={color}>
          {(!icon || icon?.type === IconTypes.ICON) && <IconRenderer icon={icon?.data} iconSize={IconSizes.SMALL} />}
          {icon?.type === IconTypes.EMOJI && icon?.data && (
            <em-emoji shortcodes={icon.data.shortcodes} style={{ marginBottom: "1px" }} size="0.8em"></em-emoji>
          )}
        </IconContainer>
        <Name>{name}</Name>
      </IconName>

      {displayUsers && (
        <AvatarGroup
          appearance="stack"
          size="small"
          data={userData}
          isTooltipDisabled
          onAvatarClick={() => {}}
          maxCount={5}
        />
      )}
    </LabelWrapper>
  );
};

function CollectionsSelect({
  label = "Select collections",
  initialCollections = [],
  isMulti = true,
  onChange = () => {},
  displayUsers = true,
}) {
  const [selectedCollections, setSelectedCollections] = useState([]);
  const [collections, setCollections] = useState([]);

  const { isLoading, data } = useQuery("saved-collections", fetchCollections, {
    cacheTime: 0,
    retry: 0,
    select: (response) => {
      const { data } = response;
      return data || [];
    },
  });

  useEffect(() => {
    if (!isLoading && data?.length) {
      setCollections(
        [...data].map(({ icon, backgroundColor, name, filters, userGroup, id }) => ({
          label: (
            <CollectionLabel
              icon={icon}
              name={name}
              color={backgroundColor}
              filters={filters}
              userGroup={userGroup}
              displayUsers={displayUsers}
            />
          ),
          data: { icon, backgroundColor, name, filters, userGroup, id },
          value: id,
          id,
        })),
      );
    }
  }, [isLoading, data, displayUsers]);

  useEffect(() => {
    const selectedOptions = initialCollections
      .map((collectionId) => {
        const found = collections.find((clc) => clc.id === collectionId);
        if (!found) return undefined;

        const { id, icon, name, backgroundColor, filters, userGroup } = found.data;

        return {
          label: (
            <CollectionLabel
              icon={icon}
              name={name}
              color={backgroundColor}
              filters={filters}
              userGroup={userGroup}
              displayUsers={false}
            />
          ),
          value: id,
          id,
        };
      })
      .filter((clc) => !!clc);

    setSelectedCollections([...selectedOptions]);
  }, [initialCollections, collections]);

  return (
    <FieldContainer>
      <Label>{label}</Label>
      <Select
        isMulti={isMulti}
        value={selectedCollections}
        options={collections}
        isLoading={isLoading}
        onChange={(selection) => {
          if (!selection) onChange(isMulti ? [] : undefined);

          if (isMulti) {
            onChange(selection.map((clc) => clc.id));
          } else {
            onChange(selection.id);
          }
        }}
      />
    </FieldContainer>
  );
}

export default CollectionsSelect;
