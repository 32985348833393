import React from "react";
import styled from "styled-components";
import { CardPropertyTypes } from "../../propertyTypes";
import { useConfiguratorStore } from "../configurator-store";
import { FieldContainer, Label } from "../../../../../../Dashboard/filter/styled";
import IconRenderer, { IconSizes } from "../../../../../../../../../Shared/Components/Icons/IconRenderer";
import InputFilter from "../../../../../../Dashboard/filter/InputFilter";
import SidebarTransition from "../../../../../../../../../Shared/Components/SidebarTransition";
import SidebarIconPicker from "../../../../../../../../../Shared/Components/SidebarIconPicker/SidebarIconPicker";
import { IconRendererWrapper } from "../components/styled-types";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 16px;
`;

function IconCardFields({ icon, title, updateCard }) {
  const { isEditingIcon, setIsEditingIcon } = useConfiguratorStore((state) => ({
    isEditingIcon: state.isEditingIcon,
    setIsEditingIcon: state.setIsEditingIcon,
  }));

  const closeSidebar = () => {
    setIsEditingIcon(false);
  };

  return (
    <Wrapper>
      <FieldContainer>
        <Label>Icon</Label>

        <IconRendererWrapper>
          <IconRenderer
            icon={icon}
            onClick={() => {
              setIsEditingIcon(true);
            }}
            iconSize={IconSizes.SMALL}
          />
        </IconRendererWrapper>
      </FieldContainer>

      <InputFilter
        initialValue={title || ""}
        onUpdate={(newTitle) => updateCard(CardPropertyTypes.TITLE, newTitle)}
        label="Card title"
        placeholder="Edit card title"
        containerStyle={{ flex: "1" }}
      />

      <SidebarTransition
        isOpen={isEditingIcon}
        close={() => closeSidebar()}
        width={360}
        title="Edit Icon"
        customScrollbarHeight="calc(100% - 45px)"
        useArrowBack
        onArrowBackClick={() => closeSidebar()}
      >
        <SidebarIconPicker
          isInTabs
          showNoIconBox={false}
          onIconSelect={(newIcon) => {
            updateCard(CardPropertyTypes.ICON, newIcon);
            closeSidebar();
          }}
          onClose={() => closeSidebar()}
        />
      </SidebarTransition>
    </Wrapper>
  );
}

export default IconCardFields;
