import React from "react";
import styled from "styled-components";
import { ColumnContainer, Content, MediumLine, Panel, Title, Wrapper } from "./styled";
import { Radio } from "@atlaskit/radio";
import { useCorporateIdentityStore } from "../../../../../../../Settings/General/BrandAndColors/corporate-identity-store";
import { COLOR_TYPES } from "../../../../../../../Settings/General/BrandAndColors/color-types";

const Options = styled.div`
  margin-top: 22px;
`;

const OptionWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 13px;
`;

const OptionText = styled.span`
  font-weight: 400;
  font-size: 10px;
  line-height: 17px;
  color: #42526e;
`;

const Button = styled.div`
  background: ${({ primaryColor }) => `${primaryColor}`};
  border-radius: 8px;
  color: #fff;
  font-weight: 500;
  font-size: 10px;
  line-height: 17px;
  letter-spacing: 1px;
  display: grid;
  place-content: center;
  text-align: center;
  cursor: pointer;
  max-width: 210px;
  padding: 6px 0px;
  margin: 15px auto 0 auto;
  max-width: 145px;
`;

function Poll() {
  const colors = useCorporateIdentityStore((state) => state.colors);
  const primaryColor = colors ? colors[COLOR_TYPES.PRIMARY] : "#0065ff";

  return (
    <Wrapper>
      <Panel>
        <Title>It's poll time!</Title>
        <Content>
          <ColumnContainer style={{ rowGap: "9px" }}>
            <MediumLine bg="rgba(9, 30, 66, 0.13)" width={173} />
            <MediumLine bg="rgba(9, 30, 66, 0.13)" width={173} />
            <MediumLine bg="rgba(9, 30, 66, 0.13)" width={173} />
          </ColumnContainer>

          <Options>
            <OptionWrapper>
              <Radio label="" />
              <OptionText>Cosmos</OptionText>
            </OptionWrapper>

            <OptionWrapper>
              <Radio label="" />
              <OptionText>Pulse</OptionText>
            </OptionWrapper>

            <OptionWrapper>
              <Radio label="" />
              <OptionText>Optic</OptionText>
            </OptionWrapper>
          </Options>

          <Button primaryColor={primaryColor}>VOTE</Button>
        </Content>
      </Panel>
    </Wrapper>
  );
}

export default Poll;
