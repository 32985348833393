import { nanoid } from "nanoid";

export const getAllCollectionsFromPanels = (panels) => {
  const foundCollections = [];
  for (const panel of panels) {
    if (panel?.permissions?.length) {
      foundCollections.push(...panel.permissions);
    }
  }
  return foundCollections || [];
};

export const simulateCollectionsBasedOnFiltersInPanels = (panels) => {
  const simulatedCollections = [];
  for (const panel of panels) {
    if (panel?.filters?.length) {
      simulatedCollections.push({
        id: nanoid(6),
        panelId: panel.id,
        filters: panel.filters,
      });
    }
  }

  return simulatedCollections || [];
};

export const separateNonExistingCollections = (collectionsFromPanels, allCollections = []) => {
  const withoutDeletedCollections = [];
  const deletedCollections = [];

  for (const collectionFromPanel of collectionsFromPanels) {
    if (allCollections.find((collection) => collection.id === collectionFromPanel)) {
      withoutDeletedCollections.push(collectionFromPanel);
    } else {
      deletedCollections.push(collectionFromPanel);
    }
  }

  return [withoutDeletedCollections, deletedCollections];
};

export const buildPanelCollectionsFilters = (collectionsFromPanels, allCollections = []) =>
  collectionsFromPanels.map((collectionId) => ({
    id: collectionId,
    filters: allCollections.find((collection) => collection.id === collectionId)?.filters || [],
    confluenceGroup: allCollections.find((collection) => collection.id === collectionId)?.userGroup || "",
  }));
