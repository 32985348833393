import styled from "styled-components";
import NoMatchingNewsSVG from "../Dashboard/panels/images/NoMatchingNewsSVG";

const Container = styled.div`
  display: grid;
  place-content: center;
  justify-items: center;
  text-align: center;
  height: 100%;
  width: 100%;
`;

const Title = styled.p`
  font-weight: 590;
  font-size: 20px;
  line-height: 23.87px;
  color: ${({ theme }) => theme.emptyState.title};
  margin-top: 30px;
  margin-bottom: 12px;
`;

const Description = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #44526d;

  .create-link {
    cursor: pointer;
    color: #2684ff;
  }
`;

function EmptyState({ onClick }) {
  return (
    <Container>
      <NoMatchingNewsSVG />
      <div>
        <Title>Launch your Newsroom</Title>
        <Description>
          <span>There are currently no blogs on your instance. </span>
          <span className="create-link" onClick={onClick}>
            Create a blog
          </span>
          <span> to launch your Newsroom.</span>
        </Description>
      </div>
    </Container>
  );
}

export default EmptyState;
