import React from "react";
import {
  DesignSidebarWrapper,
  LabeledContainer,
  LabeledTitle,
  TabContent,
  TextInputWrapper,
} from "../../../styled/sidebar";
import TextField from "@atlaskit/textfield";

import TextArea from "@atlaskit/textarea";
import { AvailableStyles } from "../../../defaultSettings";
import SelectFilter from "../../../../Dashboard/filter/SelectFilter";
import { useSidebarPanelStore } from "../../../database-store";
import { useChangesStore } from "../../../../../track-changes-store";
import { AustinFilterOptions, AustinPropertyKeys, CalgaryFilterOptions } from "../CardLayout";
import { useRecordsStore } from "../../../../../../UserDatabase/records-store";
import SizeButtons from "../../../../../../../Shared/Components/SizeButtons";

const LayoutSidebar = ({ panel, updatePanelDataProperty }) => {
  const setHasChanges = useChangesStore((state) => state.setHasChanges);

  const { settings, updateSettingsStyle } = useSidebarPanelStore((state) => ({
    settings: state.settings,
    updateSettingsStyle: state.updateSettingsStyle,
  }));

  const fields = useRecordsStore((state) => state.fields);

  const handleSettingsStyleChanges = (styleKey, propertyKey, value) => {
    updateSettingsStyle(styleKey, propertyKey, value);
    setHasChanges(true);
  };

  return (
    <div>
      <TabContent>
        <LabeledContainer>
          <LabeledTitle>Title</LabeledTitle>
          <TextInputWrapper>
            <TextField
              value={panel.data.title}
              placeholder="Enter title.."
              type="text"
              onChange={({ target: { value } }) => updatePanelDataProperty({ id: panel.id, property: "title", value })}
            />
          </TextInputWrapper>
        </LabeledContainer>

        <LabeledContainer>
          <LabeledTitle>Description</LabeledTitle>
          <TextArea
            resize="none"
            minimumRows={5}
            placeholder="Enter description.."
            value={panel.data.description}
            onChange={({ target: { value } }) =>
              updatePanelDataProperty({ id: panel.id, property: "description", value })
            }
          />
        </LabeledContainer>

        {settings.selectedStyle === AvailableStyles.AUSTIN && (
          <AustinFilterOptions
            fields={fields}
            data={settings[AvailableStyles.AUSTIN]}
            updateSettings={handleSettingsStyleChanges}
          />
        )}

        {settings.selectedStyle === AvailableStyles.CALGARY && (
          <CalgaryFilterOptions
            fields={fields}
            data={settings[AvailableStyles.CALGARY]}
            updateSettings={handleSettingsStyleChanges}
          />
        )}
      </TabContent>
    </div>
  );
};

const DesignSidebar = () => {
  const setHasChanges = useChangesStore((state) => state.setHasChanges);

  const { settings, updateSettings, updateSettingsStyle } = useSidebarPanelStore((state) => ({
    settings: state.settings,
    updateSettings: state.updateSettings,
    updateSettingsStyle: state.updateSettingsStyle,
  }));

  const handleSettingsChanges = (propertyKey, value) => {
    updateSettings(propertyKey, value);
    setHasChanges(true);
  };

  const handleSettingsStyleChanges = (styleKey, propertyKey, value) => {
    updateSettingsStyle(styleKey, propertyKey, value);
    setHasChanges(true);
  };

  return (
    <DesignSidebarWrapper>
      <TabContent>
        <LabeledContainer>
          <LabeledTitle>Design style</LabeledTitle>
          <SelectFilter
            value={settings.selectedStyle}
            setValue={(v) => handleSettingsChanges("selectedStyle", v)}
            options={[
              { label: "Austin", value: AvailableStyles.AUSTIN },
              { label: "Calgary", value: AvailableStyles.CALGARY },
            ]}
            noDetails
          />
        </LabeledContainer>

        {settings.selectedStyle === AvailableStyles.AUSTIN && (
          <>
            <SizeButtons
              label="Image size"
              customOptions={[
                { label: "S", value: 60 },
                { label: "M", value: 80 },
                { label: "L", value: 96 },
              ]}
              input={{
                value: settings[AvailableStyles.AUSTIN].imageSize,
                min: 24,
                max: 120,
              }}
              setValue={(value) =>
                handleSettingsStyleChanges(AvailableStyles.AUSTIN, AustinPropertyKeys.IMAGE_SIZE, value)
              }
            />
            <SelectFilter
              label="Card background"
              value={settings[AvailableStyles.AUSTIN].cardBackground}
              setValue={(value) =>
                handleSettingsStyleChanges(AvailableStyles.AUSTIN, AustinPropertyKeys.CARD_BG, value)
              }
              options={[
                { label: "None", value: "none" },
                { label: "Profile header image", value: "image" },
              ]}
            />
          </>
        )}
      </TabContent>
    </DesignSidebarWrapper>
  );
};

const sidebars = {
  Layout: LayoutSidebar,
  Design: DesignSidebar,
};

const AllEmployeesEdit = ({ panel, selectedTab, updatePanelDataProperty }) => {
  const Component = sidebars[selectedTab];

  return <Component panel={panel} updatePanelDataProperty={updatePanelDataProperty} />;
};

export default AllEmployeesEdit;
