import httpClient from "../../../../Service/http-client";
import { FilterTypes } from "./types";

export const mapSpace = (space, filterPermission) => {
  const spaceInfo = {
    key: space.key,
    name: space.name,
    type: space.type,
    status: space.status,
    icon: `${window.AP._hostOrigin}/wiki${space.icon.path}`,
    description: space.description.plain.value,
    url: `${window.AP._hostOrigin}/wiki/spaces/${space.key}/overview`,
    createdBy: space.history.createdBy.displayName,
    creatorId: space.history.createdBy.accountId,
    labels: space?.metadata?.labels?.results
      ? space.metadata.labels.results.filter((l) => l.prefix !== "my").map((l) => l.label)
      : [],
  };

  if (filterPermission) {
    return {
      ...spaceInfo,
      permissions: space.permissions.filter(
        (permission) => permission.operation.targetType === "space" && permission.operation.operation === "administer",
      ),
    };
  }
  return spaceInfo;
};

export async function searchSpaces({ queryKey, pageParam = 0 }) {
  const [_key, { searchValue = "", selectedFilter = undefined, selectedSort = undefined, limit = 20 }] = queryKey;
  let cql = `type=space`;
  if (searchValue) {
    cql += ` AND (text ~ "${searchValue}*" OR title ~ "${searchValue}*")`;
  }

  if (selectedFilter === FilterTypes.STARRED) {
    cql += ` AND space IN favouriteSpaces()`;
  }
  if (selectedFilter === FilterTypes.WATCHING) {
    cql += ` AND watcher = currentUser()`;
  }
  if (selectedFilter === FilterTypes.COMMUNAL) {
    cql += ` AND space.type=global`;
  }
  if (selectedFilter === FilterTypes.PERSONAL) {
    cql += ` AND space.type=personal`;
  }

  if (selectedSort) {
    cql += ` ORDER BY created`;
    if (selectedSort === "newest") {
      cql += ` desc`;
    }
    if (selectedSort === "oldest") {
      cql += ` asc`;
    }
  }

  let url =
    selectedFilter === FilterTypes.ARCHIVED
      ? `/rest/api/space?status=archived&limit=${limit}&expand=history,icon,description.plain,permissions,metadata.labels`
      : `/rest/api/search?cql=${cql}&limit=${limit}&expand=space.history,space.icon,space.description.plain,space.permissions,space.metadata.labels`;

  if (selectedFilter === FilterTypes.LATEST_ACTIVITY) {
    url = `/rest/api/search?cql=type=space AND lastmodified < endOfDay() ORDER BY lastModified desc&limit=${limit}&expand=space.history,space.icon,space.description.plain,space.permissions,space.metadata.labels`;
  }
  if (selectedFilter === FilterTypes.STAR_AND_WATCH) {
    url = `/rest/api/search?cql=type=space AND (watcher = currentUser() OR space IN favouriteSpaces())&limit=${limit}&expand=space.history,space.icon,space.description.plain,space.permissions,space.metadata.labels`;
  }

  if (pageParam) {
    url = `${url}&cursor=${decodeURI(pageParam)}`;
  }
  try {
    return window.AP.request(url);
  } catch (e) {
    console.log("cant fetch spaces");
    console.log(e);
  }
}

export async function getLatestSpaces({ queryKey, pageParam = 0 }) {
  const [_key, { limit = 6 }] = queryKey;
  const url = `/rest/api/search?cql=type=space AND space.type=global ORDER BY created desc&limit=${limit}&expand=space.description.plain,space.icon,space.history,space.permissions,space.metadata.labels`;
  try {
    return window.AP.request(url);
  } catch (e) {
    console.log("cant fetch spaces");
    console.log(e);
  }
}

export async function getLastViewedContentByUser({ queryKey, pageParam = 0 }) {
  const url = `/rest/api/search?cql=type=space AND space IN recentlyViewedSpaces(15) AND lastmodified < endOfDay() ORDER BY lastModified desc&expand=space.icon,space.history,space.description.plain,space.permissions,space.metadata.labels`;
  try {
    return window.AP.request(url);
  } catch (e) {
    console.log("cant fetch spaces");
    console.log(e);
  }
}

export async function getFavoriteAndWatchedSpaces({ queryKey, pageParam = 0 }) {
  const [_key, { limit = 12 }] = queryKey;
  let url = `/rest/api/search?cql=type=space AND (watcher = currentUser() OR space IN favouriteSpaces())&limit=${limit}&expand=space.description.plain,space.icon,space.history,space.metadata.labels`;
  if (pageParam) {
    url = `${url}&cursor=${decodeURI(pageParam)}`;
  }
  try {
    return window.AP.request(url);
  } catch (e) {
    console.log("cant fetch spaces");
    console.log(e);
  }
}

export async function getLatestActivityContent({ queryKey, pageParam = 0 }) {
  const [_key, { limit = 100 }] = queryKey;
  let url = `/rest/api/search?cql=type=space AND lastmodified < endOfDay() ORDER BY lastModified desc&limit=${limit}&expand=space,space.icon,space.history,space.description.plain,space.permissions,space.metadata.labels`;
  if (pageParam) {
    url = `${url}&cursor=${decodeURI(pageParam)}`;
  }
  try {
    return window.AP.request(url);
  } catch (e) {
    console.log("cant fetch spaces");
    console.log(e);
  }
}

export async function getCurrentUserSpaceWatchStatus(spaceKey) {
  const url = `/rest/api/user/watch/space/${spaceKey}`;
  try {
    return window.AP.request(url);
  } catch (e) {
    return false;
  }
}

export async function getCurrentUserSpaceFavoriteStatus(spaceKey) {
  const url = `/rest/api/search?cql=type=space AND space IN favouriteSpaces() AND space = "${spaceKey}"`;
  try {
    return window.AP.request(url);
  } catch (e) {
    console.log("cant fetch watch status");
    console.log(e);
  }
}

export async function addCurrentUserAsSpaceWatcher(spaceKey) {
  const url = `ac/confluence/api/user/watch/space`;
  return httpClient.post(url, { spaceKey });
}

export async function removeCurrentUserAsSpaceWatcher(spaceKey) {
  const url = `ac/confluence/api/user/watch/space/remove`;
  return httpClient.post(url, { spaceKey });
}

async function getUsersFromGroup(groupId) {
  const url = `/rest/api/group/${groupId}/membersByGroupId`;
  try {
    return window.AP.request(url);
  } catch (error) {
    console.log("can't fetch group members");
    console.log(error);
  }
}

export async function getUsersForArrayOfGroups(groups) {
  try {
    return Promise.all(
      groups.map((g) => {
        return new Promise(async (resolve) => {
          try {
            const response = await getUsersFromGroup(g.groupId);
            if (response.body) {
              const data = JSON.parse(response.body);
              const users = data?.results?.map((u) => ({
                id: u.accountId,
                key: u.accountId,
                name: u.displayName,
                icon: u.profilePicture?.path ? `${window.AP._hostOrigin}${u.profilePicture.path}` : "",
              }));

              if (users) {
                resolve(users);
              } else {
                resolve([]);
              }
            }

            resolve([]);
          } catch (error) {
            console.error(error?.message);
            resolve([]);
          }
        });
      }),
    );
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function updateSpaceByKey(spaceInfo) {
  const url = `/rest/api/space/${spaceInfo.key}`;
  try {
    const updateSpace = () =>
      new Promise((resolve, reject) => {
        window.AP.request({
          url: url,
          type: "PUT",
          contentType: "application/json",
          data: JSON.stringify({
            name: spaceInfo.name,
            description: { plain: { value: spaceInfo.description } },
            status: spaceInfo.status,
          }),
          success: (responseText) => {
            resolve(true);
          },
          error: (error) => {
            resolve(false);
          },
        });
      });

    const response = await updateSpace();

    return response;
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function createNewSpace(spaceInfo) {
  const url = `/rest/api/space`;
  try {
    const createSpace = () =>
      new Promise((resolve, reject) => {
        window.AP.request({
          url: url,
          type: "POST",
          contentType: "application/json",
          data: JSON.stringify({
            key: spaceInfo.key,
            name: spaceInfo.name,
            description: { plain: { value: spaceInfo.description } },
          }),
          success: () => {
            resolve({ error: false, status: 200 });
          },
          error: (error) => {
            resolve({ error: true, status: error.status });
          },
        });
      });

    const response = await createSpace();

    return response;
  } catch (error) {
    console.log(error);
    return false;
  }
}
