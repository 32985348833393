import React from "react";
import styled from "styled-components";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useDrag, useDrop } from "react-dnd";

import Select from "@atlaskit/select";
import Spinner from "@atlaskit/spinner";

import DragIcon from "@atlaskit/icon/glyph/drag-handler";
import RemoveIcon from "@atlaskit/icon/glyph/editor/remove";
import { IconContainer } from "../../../../../../../Hub/Components/Dashboard/panels/edit/types/styled";
import { DEFAULT_SPACE_ICON } from "../../../../../../functions/generateNewEntry";
import { useHelpersStore, useNavigationStore } from "../../../../../../store";
import { FieldColumn } from "../../shared/Components";

const SpacesList = styled.div`
  margin-top: 20px;
`;

const DrawerContent = styled.div`
  padding-right: 8px;
`;

const ListItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 3px;
  color: ${({ theme }) => theme.poll.icons};
  height: 32px;
  font-weight: 500;
  font-size: 14px;
  padding: ${({ isCompact }) => (isCompact ? "0 0 0 23px" : "6px 0")};

  ${({ isHoveringOver, orderDirection }) => {
    if (!isHoveringOver) {
      return null;
    }

    return `border-${orderDirection === "top-bottom" ? "bottom" : "top"}: 1px solid #000 !important;`;
  }}

  &:not(:last-child) {
    border-bottom: 1px solid
      ${({ isCompact, theme }) =>
        isCompact
          ? theme.navigation.settings.linkEntryWrapperBorderTop
          : theme.navigation.settings.linkEntryWrapperBorderBottom};
  }

  &:hover {
    background-color: ${({ theme }) => theme.navigation.settings.listItemBackgroundHover};

    .delete-icon {
      display: flex;
    }
  }
`;

const DeleteIcon = styled(IconContainer)`
  display: none;
  cursor: pointer;
  margin-right: 8px;

  svg {
    color: ${({ theme }) => theme.poll.icons} !important;
  }

  &:hover svg {
    color: ${({ theme }) => theme.navigation.settings.entryIconColor} !important;
    opacity: ${({ theme }) => theme.navigation.settings.entryIconOpacity} !important;
  }
`;

export const EntrySpaces = ({ spaces, spaceOptions, updateEntry, isCompact = false }) => (
  <SpacesList>
    <DndProvider backend={HTML5Backend}>
      {spaces
        .map((space) => {
          const icon = spaceOptions.find(({ id }) => id === space.value)?.icon?.path;

          return {
            ...space,
            icon: icon ? `${window.AP._hostOrigin}${icon}` : DEFAULT_SPACE_ICON,
          };
        })
        .map((space) => (
          <ListItem key={space.value} updateEntry={updateEntry} spaces={spaces} space={space} isCompact={isCompact} />
        ))}
    </DndProvider>
  </SpacesList>
);

const ListItem = ({ updateEntry, spaces, space, isCompact }) => {
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const reorderSpaces = (sourceId, targetId) => {
    if (sourceId === targetId) return;

    const list = [...spaces];

    const sourceIndex = list.findIndex((s) => s.value === sourceId);
    const targetIndex = list.findIndex((s) => s.value === targetId);

    const items = reorder(list, sourceIndex, targetIndex);

    updateEntry(items);
  };

  const [{ isOver }, drop] = useDrop(() => ({
    accept: ["space"],
    drop: () => ({ id: space.value, ...space }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  }));

  const [{ draggedItem }, drag] = useDrag({
    type: "space",
    item: () => {
      return { id: space.value, type: "space", ...space };
    },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (item && dropResult) {
        reorderSpaces(item.id, dropResult.id);
      }
    },
    collect: (monitor) => ({
      draggedItem: monitor.getItem(),
    }),
  });

  const draggedItemPosition = spaces.findIndex(({ value }) => draggedItem?.id === value) ?? 0;
  const potentialTargetPosition = isOver ? spaces.findIndex(({ value }) => space.value === value) : 0;

  return (
    <ListItemContainer
      ref={drop}
      isHoveringOver={isOver}
      isCompact={isCompact}
      orderDirection={draggedItemPosition < potentialTargetPosition ? "top-bottom" : "bottom-top"}
    >
      <div ref={drag} style={{ display: "flex", alignItems: "center", gap: "10px", cursor: "grab" }}>
        <DragIcon />
        <img
          src={space.icon}
          alt={space.label}
          width={isCompact ? 16 : 24}
          height={isCompact ? 16 : 24}
          style={{
            borderRadius: "2px",
          }}
        />
        <div
          style={{
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden",
            width: isCompact ? "135px" : "170px",
          }}
        >
          {space.label}
        </div>
      </div>

      <DeleteIcon
        className="delete-icon"
        onClick={() => {
          const value = spaces.filter(({ value }) => value !== space.value);
          updateEntry(value);
        }}
      >
        <RemoveIcon />
      </DeleteIcon>
    </ListItemContainer>
  );
};

const Spaces = () => {
  const { editingEntry, updateEntryInState } = useNavigationStore((state) => ({
    editingEntry: state.editingEntry,
    updateEntryInState: state.updateEntry,
  }));

  const spaceOptions = useHelpersStore((state) => state.spaceOptions);

  const spaces = editingEntry.data.spaces || [];

  const updateEntry = (value) => {
    updateEntryInState(editingEntry.id, "spaces", value, true);
  };

  if (!spaceOptions.length) {
    return (
      <div style={{ margin: "30px auto 0" }}>
        <Spinner />
      </div>
    );
  }

  const handleSelectChange = (selectedOption) => {
    const filteredOptions = selectedOption.filter((option) => !spaces.some((space) => space.value === option.value));
    const newValue = [...spaces, ...filteredOptions];
    updateEntry(newValue);
  };

  return (
    <DrawerContent>
      <FieldColumn style={{ rowGap: "5px" }}>
        <Select
          isMulti
          placeholder="Select space(s)"
          onChange={handleSelectChange}
          options={spaceOptions
            .map((opt) => ({ label: opt.name, value: opt.id }))
            .filter((opt) => !spaces.map(({ value }) => value).includes(opt.value))}
          styles={{
            container: (provided) => ({
              ...provided,
              flex: 1,
              maxWidth: "100%",
            }),
          }}
        />
      </FieldColumn>

      <EntrySpaces spaces={spaces} spaceOptions={spaceOptions} updateEntry={updateEntry} />
    </DrawerContent>
  );
};

export default Spaces;
