import React, { useEffect, useState } from "react";

import Spinner from "@atlaskit/spinner";
import { Panel, PanelContent, PanelTitle } from "../../dashboard-styled";
import styled, { useTheme } from "styled-components";
import dayjs from "dayjs";
import { fetchRecords } from "../../../../../UserDatabase/datagrid/api";
import { useQuery } from "react-query";
import { SpinnerContainer } from "../../../../styled/pages";
import { usePageStore } from "../../../../page-store";
import { Pages } from "../../../../pages";
import EmptyState from "../../../../../../Shared/Components/EmptyState";
import NoBirthdays from "../images/NoBirthdaysSVG";
import { useSidebarStore } from "../edit/sidebar/sidebar-store";
import { PanelPositioning } from "../panelTypes";
import {
  IMAGE_TYPES,
  getCssForBackground,
  getRandomUserBackground,
} from "../../../../../../Shared/Functions/user-bg-provider";
import { useCorporateIdentityStore } from "../../../Settings/General/BrandAndColors/corporate-identity-store";
import { COLOR_TYPES } from "../../../Settings/General/BrandAndColors/color-types";
import useSavedCollections from "../../../../../../Shared/Hooks/useSavedCollections";
import { PanelsPaginationTypes } from "../../../Shared/PanelsPagination/panels-pagination-types";
import Pagination from "../../../Shared/PanelsPagination/Pagination";
import { getWidgetSettingById } from "../../../Settings/Widgets/api";
import { WidgetIds } from "../../../Settings/Widgets/consts/tabs";
import PanelWrapper from "../../../Shared/Panels/shared/components/PanelWrapper";
import { ListItem, ListUser } from "../styles/peopleStyles";
import { usePanelsStore } from "../../store/panels-store";
import { PanelKeys } from "../panelTypes";

const LinkWrapper = styled.a`
  all: unset;

  & > span {
    cursor: pointer;
  }
`;

const PeopleContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`;

const PersonWrapper = styled.div`
  display: grid;
  grid-template-columns: 56px auto;
  column-gap: 16px;
  align-items: center;
`;

const PersonImage = styled.img`
  box-sizing: border-box;
  width: 52px;
  height: 52px;
  border-radius: 50%;
  object-fit: cover;
  user-select: none;
`;

const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
`;

const Name = styled.span`
  font-weight: 500;
  font-size: 15px;
  line-height: 19px;
  color: ${({ theme }) => theme.shared.card.title};
`;

const ListName = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: ${({ theme }) => theme.shared.card.title};

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const DateWrapper = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme }) => theme.shared.card.subtitle};

  display: grid;
  place-items: center;
`;
const DateWrapperSide = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme }) => theme.shared.card.subtitle};
  margin-top: 4px;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const LeftoverWrapper = styled.div`
  ${({ isFullWidth }) => {
    if (isFullWidth) {
      return `
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        margin-top: 13px;
        flex: 1;
        display: flex;
        align-items: flex-end;
        min-height: 15px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      `;
    }
    return `
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        text-align: right;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    `;
  }}
  color: ${({ primaryColor }) => primaryColor};
`;

const GridPeopleContainer = styled.div`
  display: grid;
  grid-template-columns: ${({ numberOfItems }) => `repeat(${numberOfItems}, minmax(0, 1fr))`};
  grid-template-rows: 1fr;
  grid-gap: 24px;
`;

const Card = styled.div`
  background: ${({ theme }) => theme.shared.card.background};
  box-shadow: 0px 0px 4px rgba(85, 85, 85, 0.06);
  border-radius: ${({ borderRadius }) => `${borderRadius}`};
  overflow: hidden;
  border: 1px solid ${({ theme }) => theme.shared.card.border.box};

  cursor: pointer;
`;

const CardBackground = styled.div`
  width: 100%;
  background: ${({ background }) => background};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 80px;
`;

const CardInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  align-items: center;
  justify-content: center;
  padding: 0px 16px 16px 16px;
  margin-top: ${({ imageHeight }) => `-${imageHeight / 2}px`};
  text-align: center;
`;

const Image = styled.img`
  width: ${({ imageSize }) => `${imageSize || 80}px`};
  height: ${({ imageSize }) => `${imageSize || 80}px`};
  box-sizing: border-box;
  border: 4px solid ${({ theme }) => theme.shared.card.border.avatar};
  border-radius: 50%;
  object-fit: cover;
  justify-self: center;
`;

const GridCenter = styled.div`
  height: 32px;
  display: grid;
  place-content: center;
  margin-top: 8px;
`;

const PersonWrapperMain = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ backgroundColor }) => backgroundColor};
  border-radius: 3px;
  border: 1px solid ${({ borderColor }) => borderColor};
  box-shadow:
    rgba(9, 30, 66, 0.2) 0px 1px 3px,
    rgba(9, 30, 66, 0.1) 0px 0px 1px;

  & > div {
    padding: 10px 18px;

    &:not(:last-child) {
      border-bottom: 1px solid ${({ borderColor }) => borderColor};
    }
  }
`;

export const ListLabelWrapper = styled.div`
  display: grid;
  place-items: center;
`;

const customStyles = {
  customDescriptionStyles: {
    width: "464px",
  },
};

const dummyData = [
  {
    displayName: "Sarah Connor",
    accountId: "123",
    icon: "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=128&h=128&q=80",
    formattedBirthday: "Jan 6, 2023",
    daysLeft: 0,
    yearsLeft: 15,
    background: "linear-gradient(45deg, #FF8B00 0%, #FF5630 100%)",
  },
  {
    displayName: "Mary Lee",
    icon: "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=128&h=128&q=80",
    accountId: "1234",
    formattedBirthday: "Jan 7, 2023",
    daysLeft: 1,
    yearsLeft: 10,
    background: "linear-gradient(45deg, #2684FF 0%, #00B8D9 100%)",
  },
  {
    displayName: "Jane Clark",
    accountId: "12345",
    icon: "https://images.unsplash.com/photo-1488426862026-3ee34a7d66df?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=128&h=128&q=80",
    formattedBirthday: "Jan 14, 2023",
    daysLeft: 7,
    yearsLeft: 3,
    background: "linear-gradient(45deg, #6554C0 0%, #2684FF 100%)",
  },
  {
    displayName: "John Smith",
    accountId: "123456",
    icon: "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=128&h=128&q=80",
    formattedBirthday: "Feb 5, 2023",
    daysLeft: 30,
    yearsLeft: 1,
    background: "linear-gradient(45deg, #FF5630 0%, #FFC400 100%)",
  },
];

const EMPTY_STATE_TEXT = {
  [PanelPositioning.MAIN]: {
    [PanelKeys.IMPORTANT_DATES_MAIN]: {
      emptyStateTitle: "No Upcoming Birthdays",
      emptyStateDescription:
        "There are currently no upcoming birthdays. Birthdays will appear here as they are added to employee profiles.",
    },
    [PanelKeys.WORK_ANNIVERSARY_MAIN]: {
      emptyStateTitle: "No Work Anniversaries",
      emptyStateDescription:
        "There are currently no work anniversaries to display. Anniversaries will show up here as they are recorded in the system.",
    },
    [PanelKeys.NEW_STARTERS]: {
      emptyStateTitle: "No New Starters",
      emptyStateDescription:
        "There are currently no new starters listed. New employee details will appear here as they join the company.",
    },
  },
  [PanelPositioning.SIDE]: {
    [PanelKeys.IMPORTANT_DATES]: {
      emptyStateTitle: "No Upcoming Birthdays",
      emptyStateDescription:
        "There are currently no upcoming birthdays. Birthdays will appear here as they are added to employee profiles.",
    },
    [PanelKeys.WORK_ANNIVERSARY_MAIN]: {
      emptyStateTitle: "No Work Anniversaries",
      emptyStateDescription:
        "There are currently no work anniversaries to display. Anniversaries will show up here as they are recorded in the system.",
    },
    [PanelKeys.NEW_STARTERS_SIDE]: {
      emptyStateTitle: "No New Starters",
      emptyStateDescription:
        "There are currently no new starters listed. New employee details will appear here as they join the company.",
    },
  },
};

const LeftoverDaysOrYears = ({ isFullWidth, useYears, daysLeft, yearsLeft, primaryColor }) => {
  if (daysLeft === 0 && !useYears) return <></>;

  return (
    <LeftoverWrapper isFullWidth={isFullWidth} primaryColor={primaryColor}>
      {useYears ? (
        <>
          {yearsLeft}
          {` Year${yearsLeft === 1 ? "" : "s"}`}
        </>
      ) : (
        <>
          {daysLeft}
          {` Day${daysLeft === 1 ? "" : "s"}`}
        </>
      )}
    </LeftoverWrapper>
  );
};

const DummyContent = ({ isFullWidth, isCard = false, data, isWorkAnniversary, borderRadius, primaryColor }) => {
  const theme = useTheme();
  const getBackground = (bgImage) => {
    if (data.backgroundType === "image") return bgImage;
    if (data.backgroundType === "none") return theme.global.background.surfaceOverlay;
  };

  if (isFullWidth) {
    return (
      <>
        {isCard ? (
          <>
            {dummyData.map((person) => (
              <Card key={person.accountId} borderRadius={borderRadius}>
                <CardBackground background={getBackground(person.background)} />
                <CardInfoContainer imageHeight={data?.imageSize || 76}>
                  <Image imageSize={data?.imageSize || 76} src={person.icon} />
                  <Name style={{ marginTop: "8px" }}>{person.displayName}</Name>
                  <DateWrapper style={{ fontSize: "11px", marginTop: "-4px" }}>
                    {person.daysLeft === 0 && <span style={{ color: "#37B37F" }}>TODAY</span>}
                    {person.daysLeft === 1 && <span style={{ color: primaryColor }}>TOMORROW</span>}
                    {person.daysLeft > 1 && <span>{person.formattedBirthday.split(",")[0].trim()}</span>}
                  </DateWrapper>
                  <GridCenter>
                    {!isWorkAnniversary && person.daysLeft === 0 && (
                      <img src="/images/hub/dashboard/birthday-emoji.png" alt="" />
                    )}
                    <LeftoverDaysOrYears
                      isFullWidth
                      useYears={isWorkAnniversary}
                      daysLeft={person.daysLeft}
                      yearsLeft={person.yearsLeft}
                      primaryColor={primaryColor}
                    />
                  </GridCenter>
                </CardInfoContainer>
              </Card>
            ))}
          </>
        ) : (
          <PersonWrapperMain backgroundColor={theme.shared.card.background} borderColor={theme.shared.card.border.box}>
            {dummyData.map((person) => (
              <ListItem key={person.accountId}>
                <ListUser>
                  <Image imageSize={40} src={person.icon} style={{ cursor: "pointer" }} />
                  <Name>{person.name}</Name>
                </ListUser>

                <DateWrapper>
                  {person.daysLeft === 0 && <span style={{ color: "#37B37F" }}>TODAY</span>}
                  {person.daysLeft === 1 && <span style={{ color: primaryColor }}>TOMORROW</span>}
                  {(person.daysLeft > 1 || person.daysLeft < 0) && (
                    <span>{person.formattedDateField.split(",")[0].trim()}</span>
                  )}
                </DateWrapper>

                <ListLabelWrapper>
                  {!isWorkAnniversary && person.daysLeft === 0 && (
                    <img src="/images/hub/dashboard/birthday-emoji.png" alt="" />
                  )}
                  <LeftoverDaysOrYears
                    isFullWidth={false}
                    useYears={isWorkAnniversary}
                    daysLeft={person.daysLeft}
                    yearsLeft={person.yearsLeft}
                    primaryColor={primaryColor}
                  />
                </ListLabelWrapper>
              </ListItem>
            ))}
          </PersonWrapperMain>
        )}
      </>
    );
  }

  return (
    <>
      {dummyData.map((person) => (
        <PersonWrapper key={person.accountId}>
          <PersonImage src={person.icon} />
          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <DetailsWrapper>
              <ListName>{person.displayName}</ListName>
              <DateWrapper>
                {person.daysLeft === 0 && <span style={{ color: "#37B37F" }}>TODAY</span>}
                {person.daysLeft === 1 && <span style={{ color: primaryColor }}>TOMORROW</span>}
                {person.daysLeft > 1 && <span>{person.formattedBirthday.split(",")[0].trim()}</span>}
              </DateWrapper>
            </DetailsWrapper>
            {!isWorkAnniversary && person.daysLeft === 0 && (
              <img src="/images/hub/dashboard/birthday-emoji.png" alt="" />
            )}
            <LeftoverDaysOrYears
              isFullWidth={false}
              useYears={isWorkAnniversary}
              daysLeft={person.daysLeft}
              yearsLeft={person.yearsLeft}
              primaryColor={primaryColor}
            />
          </div>
        </PersonWrapper>
      ))}
    </>
  );
};

const ListBirthday = ({
  panelTitle,
  data,
  isLoading,
  records,
  people,
  isWorkAnniversary,
  createProfileLink,
  primaryColor,
  pagination,
  databaseField,
  currentPanelKey,
}) => {
  const { page, setPage, maxPage, hasNextPage } = pagination;
  const [showMoreClicked, setShowMoreClicked] = useState(false);

  const setDisableClickOutside = useSidebarStore((state) => state.setDisableClickOutside);
  const setSelectedPage = usePageStore((state) => state.setSelectedPage);

  useEffect(() => {
    setShowMoreClicked(false);
  }, [data.pagination, data.maxNumberOfItems]);

  const theme = useTheme();

  return (
    <PanelWrapper
      panelTitle={panelTitle}
      displayOptions={{
        displayTitle: data?.displayTitle ?? true,
        displayBox: data?.displayBox ?? true,
      }}
    >
      <PeopleContainer>
        {isLoading ? (
          <SpinnerContainer>
            <Spinner />
          </SpinnerContainer>
        ) : (
          <>
            {!databaseField && <DummyContent isWorkAnniversary={isWorkAnniversary} primaryColor={primaryColor} />}
            {databaseField && (
              <>
                {!records?.length && (
                  <EmptyState
                    title={EMPTY_STATE_TEXT[PanelPositioning.MAIN][currentPanelKey].emptyStateTitle}
                    description={EMPTY_STATE_TEXT[PanelPositioning.MAIN][currentPanelKey].emptyStateDescription}
                    Image={NoBirthdays}
                    customStyles={customStyles}
                  />
                )}
                <PersonWrapperMain
                  backgroundColor={theme.shared.card.background}
                  borderColor={theme.shared.card.border.box}
                >
                  {!!records?.length &&
                    people.map((person) => (
                      <ListItem>
                        <LinkWrapper key={person.accountId} target="_top" href={createProfileLink(person.accountId)}>
                          <ListUser>
                            <Image imageSize={40} src={person.icon} style={{ cursor: "pointer" }} />
                            <Name>{person.name}</Name>
                          </ListUser>
                        </LinkWrapper>

                        <DateWrapper>
                          {person.daysLeft === 0 && <span style={{ color: "#37B37F" }}>TODAY</span>}
                          {person.daysLeft === 1 && <span style={{ color: primaryColor }}>TOMORROW</span>}
                          {(person.daysLeft > 1 || person.daysLeft < 0) && (
                            <span>{person.formattedDateField.split(",")[0].trim()}</span>
                          )}
                        </DateWrapper>
                        <ListLabelWrapper>
                          {!isWorkAnniversary && person.daysLeft === 0 && (
                            <img src="/images/hub/dashboard/birthday-emoji.png" alt="" />
                          )}
                          <LeftoverDaysOrYears
                            isFullWidth={false}
                            useYears={isWorkAnniversary}
                            daysLeft={person.daysLeft}
                            yearsLeft={person.yearsLeft}
                            primaryColor={primaryColor}
                          />
                        </ListLabelWrapper>
                      </ListItem>
                    ))}
                </PersonWrapperMain>
              </>
            )}
          </>
        )}
      </PeopleContainer>

      {databaseField && !!records?.length && (
        <Pagination
          type={data.pagination === PanelsPaginationTypes.SHOW_MORE ? PanelsPaginationTypes.INFINITE : data.pagination}
          showMoreClicked={showMoreClicked}
          onClick={() => {
            if (hasNextPage && !showMoreClicked) {
              setPage(2);
              setShowMoreClicked(true);
              return;
            }

            setSelectedPage(Pages.PEOPLE);
          }}
          page={page}
          hasNextPage={hasNextPage}
          updatePage={setPage}
          maxPage={maxPage}
          onWrapperHoverCallback={setDisableClickOutside}
        />
      )}
    </PanelWrapper>
  );
};
const ListBirthdaySide = ({
  panelTitle,
  data,
  isLoading,
  records,
  people,
  isWorkAnniversary,
  createProfileLink,
  primaryColor,
  pagination,
  databaseField,
  currentPanelKey,
}) => {
  const { page, setPage, maxPage, hasNextPage } = pagination;
  const [showMoreClicked, setShowMoreClicked] = useState(false);

  const setDisableClickOutside = useSidebarStore((state) => state.setDisableClickOutside);
  const setSelectedPage = usePageStore((state) => state.setSelectedPage);

  useEffect(() => {
    setShowMoreClicked(false);
  }, [data.pagination, data.maxNumberOfItems]);

  return (
    <Panel>
      <PanelTitle panelPosition={PanelPositioning.SIDE}>{panelTitle}</PanelTitle>
      <PanelContent>
        <PeopleContainer>
          {isLoading ? (
            <SpinnerContainer>
              <Spinner />
            </SpinnerContainer>
          ) : (
            <>
              {!databaseField && <DummyContent isWorkAnniversary={isWorkAnniversary} primaryColor={primaryColor} />}
              {databaseField && (
                <>
                  {!records?.length && (
                    <EmptyState
                      title={
                        EMPTY_STATE_TEXT[PanelPositioning.SIDE][currentPanelKey || PanelKeys.IMPORTANT_DATES]
                          .emptyStateTitle
                      }
                      description={
                        EMPTY_STATE_TEXT[PanelPositioning.SIDE][currentPanelKey || PanelKeys.IMPORTANT_DATES]
                          .emptyStateDescription
                      }
                      Image={NoBirthdays}
                    />
                  )}
                  {!!records?.length &&
                    people.map((person) => (
                      <LinkWrapper key={person.accountId} target="_top" href={createProfileLink(person.accountId)}>
                        <PersonWrapper>
                          <PersonImage src={person.icon} style={{ cursor: "pointer" }} />
                          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <DetailsWrapper>
                              <ListName>{person.name}</ListName>
                              <DateWrapperSide>
                                {person.daysLeft === 0 && <span style={{ color: "#37B37F" }}>TODAY</span>}
                                {person.daysLeft === 1 && <span style={{ color: primaryColor }}>TOMORROW</span>}
                                {(person.daysLeft > 1 || person.daysLeft < 0) && (
                                  <span>{person.formattedDateField.split(",")[0].trim()}</span>
                                )}
                              </DateWrapperSide>
                            </DetailsWrapper>
                            {!isWorkAnniversary && person.daysLeft === 0 && (
                              <img src="/images/hub/dashboard/birthday-emoji.png" alt="" />
                            )}
                            <LeftoverDaysOrYears
                              isFullWidth={false}
                              useYears={isWorkAnniversary}
                              daysLeft={person.daysLeft}
                              yearsLeft={person.yearsLeft}
                              primaryColor={primaryColor}
                            />
                          </div>
                        </PersonWrapper>
                      </LinkWrapper>
                    ))}
                </>
              )}
            </>
          )}
        </PeopleContainer>

        {databaseField && !!records?.length && (
          <Pagination
            type={
              data.pagination === PanelsPaginationTypes.SHOW_MORE ? PanelsPaginationTypes.INFINITE : data.pagination
            }
            showMoreClicked={showMoreClicked}
            onClick={() => {
              if (hasNextPage && !showMoreClicked) {
                setPage(2);
                setShowMoreClicked(true);
                return;
              }

              setSelectedPage(Pages.PEOPLE);
            }}
            page={page}
            hasNextPage={hasNextPage}
            updatePage={setPage}
            maxPage={maxPage}
            onWrapperHoverCallback={setDisableClickOutside}
          />
        )}
      </PanelContent>
    </Panel>
  );
};

const GridBirthday = ({
  panelTitle,
  data,
  isLoading,
  records,
  people,
  isWorkAnniversary,
  createProfileLink,
  borderRadius,
  primaryColor,
  pagination,
  databaseField,
  currentPanelKey,
}) => {
  const { page, setPage, maxPage, hasNextPage, maxNumberOfItems } = pagination;
  const [showMoreClicked, setShowMoreClicked] = useState(false);

  const setDisableClickOutside = useSidebarStore((state) => state.setDisableClickOutside);
  const setSelectedPage = usePageStore((state) => state.setSelectedPage);

  const theme = useTheme();

  const getBackground = (bgImage) => {
    if (data.backgroundType === "image") return bgImage;
    if (data.backgroundType === "none") return theme.global.background.surfaceOverlay;
  };

  useEffect(() => {
    setShowMoreClicked(false);
  }, [data.pagination, data.maxNumberOfItems]);

  return (
    <PanelWrapper
      panelTitle={panelTitle}
      displayOptions={{
        displayTitle: data?.displayTitle ?? true,
        displayBox: data?.displayBox ?? true,
      }}
    >
      {isLoading ? (
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
      ) : (
        <>
          {!databaseField && (
            <GridPeopleContainer numberOfItems={maxNumberOfItems}>
              <DummyContent
                isCard={true}
                isFullWidth
                data={data}
                isWorkAnniversary={isWorkAnniversary}
                borderRadius={borderRadius}
                primaryColor={primaryColor}
              />
            </GridPeopleContainer>
          )}
          {databaseField && (
            <>
              {!records?.length && (
                <EmptyState
                  title={EMPTY_STATE_TEXT[PanelPositioning.MAIN][currentPanelKey].emptyStateTitle}
                  description={EMPTY_STATE_TEXT[PanelPositioning.MAIN][currentPanelKey].emptyStateDescription}
                  Image={NoBirthdays}
                  gap={20}
                  customStyles={customStyles}
                />
              )}

              {!!records?.length && (
                <>
                  <GridPeopleContainer numberOfItems={maxNumberOfItems}>
                    {people.map((person) => (
                      <LinkWrapper key={person.accountId} target="_top" href={createProfileLink(person.accountId)}>
                        <Card borderRadius={borderRadius}>
                          <CardBackground background={getBackground(person.background)} />
                          <CardInfoContainer imageHeight={data?.imageSize || 80}>
                            <Image imageSize={data?.imageSize || 80} src={person.icon} />
                            <Name style={{ marginTop: "8px" }}>{person.name}</Name>
                            <DateWrapper>
                              {person.daysLeft === 0 && <span style={{ color: "#37B37F" }}>TODAY</span>}
                              {person.daysLeft === 1 && <span style={{ color: primaryColor }}>TOMORROW</span>}
                              {(person.daysLeft > 1 || person.daysLeft < 0) && (
                                <span>{person.formattedDateField.split(",")[0].trim()}</span>
                              )}
                            </DateWrapper>
                            <GridCenter>
                              {!isWorkAnniversary && person.daysLeft === 0 && (
                                <img src="/images/hub/dashboard/birthday-emoji.png" alt="" />
                              )}
                              <LeftoverDaysOrYears
                                isFullWidth
                                useYears={isWorkAnniversary}
                                daysLeft={person.daysLeft}
                                yearsLeft={person.yearsLeft}
                                primaryColor={primaryColor}
                              />
                            </GridCenter>
                          </CardInfoContainer>
                        </Card>
                      </LinkWrapper>
                    ))}
                  </GridPeopleContainer>

                  <Pagination
                    type={
                      data.pagination === PanelsPaginationTypes.SHOW_MORE
                        ? PanelsPaginationTypes.INFINITE
                        : data.pagination
                    }
                    showMoreClicked={showMoreClicked}
                    onClick={() => {
                      if (hasNextPage && !showMoreClicked) {
                        setPage(2);
                        setShowMoreClicked(true);
                        return;
                      }

                      setSelectedPage(Pages.PEOPLE);
                    }}
                    page={page}
                    hasNextPage={hasNextPage}
                    updatePage={setPage}
                    maxPage={maxPage}
                    onWrapperHoverCallback={setDisableClickOutside}
                  />
                </>
              )}
            </>
          )}
        </>
      )}
    </PanelWrapper>
  );
};

function Birthday({ panelTitle, data, position, id }) {
  const [people, setPeople] = useState([]);
  const [birthdayRange, setBirthdayRange] = useState({ start: undefined, end: undefined });
  const [page, setPage] = useState(1);
  const [workAnniversaryRange, setWorkAnniversaryRange] = useState({ start: undefined, end: undefined });
  const [startOrEndOfCurrentYear, setStartOrEndOfCurrentYear] = useState(undefined);

  const [databaseField, setDatabaseField] = useState("");

  const borderRadius = useCorporateIdentityStore((state) => state.borderRadius);
  const colors = useCorporateIdentityStore((state) => state.colors);
  const primaryColor = colors ? colors[COLOR_TYPES.PRIMARY] : "#0065ff";

  const isWorkAnniversary = !!data?.useYears;

  const isFullWidth = position === PanelPositioning.MAIN;

  const { collectionsData } = useSavedCollections({
    collectionId: data?.collection,
    areOptions: false,
  });

  const { panels } = usePanelsStore((state) => {
    return {
      panels: state.panels,
    };
  });

  const currentPanelKey = panels.find((panel) => panel.id === id).key;

  const { isLoading: loadingGlobalSetting, data: widgetSettingData } = useQuery(
    [
      isWorkAnniversary ? "work-anniversary-widget-setting" : "birthday-widget-setting",
      { widgetId: isWorkAnniversary ? WidgetIds.WORK_ANNIVERSARY : WidgetIds.BIRTHDAY },
    ],
    getWidgetSettingById,
    {
      retry: 0,
      select: (response) => {
        const { data } = response;
        return data;
      },
    },
  );

  useEffect(() => {
    if (loadingGlobalSetting) return;

    if (widgetSettingData?.widget?.selectedField) {
      setDatabaseField(widgetSettingData.widget.selectedField);
    }
  }, [loadingGlobalSetting, widgetSettingData]);

  const { isLoading: recordsLoading, data: recordsData } = useQuery(
    [
      "userdatabase-records",
      {
        filters: [
          ...(collectionsData?.filters || []),
          {
            column: databaseField,
            value: {
              range: isWorkAnniversary
                ? [workAnniversaryRange.start, workAnniversaryRange.end]
                : [birthdayRange.start, birthdayRange.end],
            },
            type: "DATE",
            condition: "IS",
            isInDashboard: true,
            recurring: true,
          },
          isWorkAnniversary
            ? {
                column: databaseField,
                value: { range: [undefined, startOrEndOfCurrentYear] },
                type: "DATE",
                condition: "IS",
                isInDashboard: true,
                recurring: false,
              }
            : undefined,
          { isMultiUser: true, type: "BOOLEAN", value: true, column: "hidden", condition: "NOT" },
        ],
        confluenceGroup: data?.userGroup || collectionsData?.userGroup,
        sort: {
          fieldId: databaseField,
          order: "asc",
          fieldPrefix: "date_field_recurring",
        },
      },
    ],
    fetchRecords,
    {
      enabled:
        !!databaseField &&
        (isWorkAnniversary ? !!workAnniversaryRange?.start : !!birthdayRange?.start && !!startOrEndOfCurrentYear),
      retry: 0,
      select: (response) => {
        const { data } = response;
        return data?.hits;
      },
    },
  );

  const createProfileLink = (accountId) => {
    return `${window.AP._hostOrigin}/wiki/plugins/servlet/ac/com.caelor.confluence.cloud.cosmos/caelor-cosmos-portal?ac.cosmosPage=profile&ac.accountId=${accountId}`;
  };

  const createUnixTimeFromNumberOfDays = (numberOfDays) => {
    const HOURS = 24;
    const MINUTES = 60;
    const SECONDS = 60;
    return numberOfDays * HOURS * MINUTES * SECONDS;
  };

  useEffect(() => {
    const today = dayjs().unix();
    const todayUnix = dayjs.unix(today).hour(0).minute(0).second(0).unix();
    const todayFixedYear = dayjs.unix(todayUnix).year(1970).unix();

    if (!isWorkAnniversary) {
      const SIX_MONTHS = createUnixTimeFromNumberOfDays(180);
      const start = todayFixedYear - 1;
      const end = todayFixedYear + SIX_MONTHS;
      setBirthdayRange({ start, end });
      const currentYearEnd = new Date(new Date().getFullYear(), 11, 31);
      const convertedToUnix = Math.floor(currentYearEnd.getTime() / 1000);
      setStartOrEndOfCurrentYear(convertedToUnix);
    } else {
      const SEVEN_DAYS = createUnixTimeFromNumberOfDays(7);
      const NINETY_DAYS = createUnixTimeFromNumberOfDays(90);
      const start = todayFixedYear - SEVEN_DAYS;
      const end = todayFixedYear + NINETY_DAYS;
      setWorkAnniversaryRange({ start, end });

      const currentYearStart = new Date(new Date().getFullYear(), 0, 1);
      const convertedToUnix = Math.floor(currentYearStart.getTime() / 1000);
      setStartOrEndOfCurrentYear(convertedToUnix);
    }
  }, [isWorkAnniversary]);

  const getDetailsForUsers = (users) => {
    if (users?.length) {
      const formattedUsers = users
        .map((user) => {
          const fieldPrefix = isWorkAnniversary || databaseField.includes("azure") ? "" : "recurring_";
          const dateValue = user.values[`${fieldPrefix}${databaseField}`];

          const formattedDateField = dayjs.unix(dateValue).format("MMM D");

          const dateFieldUnix = dayjs.unix(dateValue);
          const dateFieldUnixCurrentYear = dayjs.unix(dateValue).set("year", dayjs().get("year"));

          const daysLeftFromCurrentYear = dateFieldUnixCurrentYear
            .hour(0)
            .minute(0)
            .second(0)
            .millisecond(0)
            .diff(dayjs().hour(0).minute(0).second(0).millisecond(0), "day");

          const yearsLeftFromCurrentYear = Math.abs(dateFieldUnix.diff(dateFieldUnixCurrentYear, "year"));

          const backgroundImage = user.values?.user?.backgroundImage;
          const hasBgData = !!backgroundImage && !!Object.keys(backgroundImage || {}).length;
          const correctImage = hasBgData ? backgroundImage : getRandomUserBackground(IMAGE_TYPES.GRADIENT);

          const image = getCssForBackground(correctImage);

          return {
            accountId: user.accountId,
            name: user.displayName,
            icon: user.profilePicture,
            formattedDateField,
            daysLeft: daysLeftFromCurrentYear,
            yearsLeft: yearsLeftFromCurrentYear,
            hasBirthday: !!dateValue,
            background: image,
          };
        })
        .filter((user) => user.hasBirthday);

      setPeople([...formattedUsers]);
    }
  };

  useEffect(() => {
    if (!!recordsData?.length) {
      getDetailsForUsers(recordsData);
    }
  }, [recordsData]);

  const maxNumberOfItems = data.maxNumberOfItems || 4;
  const indexOfLast = page * maxNumberOfItems;
  const indexOfFirst = indexOfLast - maxNumberOfItems;
  const maxPage = Math.ceil(people?.length / maxNumberOfItems);
  const hasNextPage = recordsData?.length > maxNumberOfItems;

  useEffect(() => {
    setPage(1);
  }, [maxNumberOfItems, data.pagination, databaseField, data.collection]);

  const dynamicIndexOfFirst = {
    [PanelsPaginationTypes.SHOW_MORE]: 0,
    [PanelsPaginationTypes.ARROWS]: indexOfFirst,
  };

  const peoplePaginated = people.slice(dynamicIndexOfFirst[data.pagination], indexOfLast);

  if (isFullWidth) {
    return (
      <>
        {!data?.view || data.view === "card" ? (
          <>
            <GridBirthday
              panelTitle={panelTitle}
              data={data}
              databaseField={databaseField}
              isWorkAnniversary={isWorkAnniversary}
              people={peoplePaginated}
              records={recordsData}
              isLoading={recordsLoading || loadingGlobalSetting}
              borderRadius={borderRadius}
              primaryColor={primaryColor}
              createProfileLink={createProfileLink}
              pagination={{ maxNumberOfItems, page, setPage, maxPage, hasNextPage }}
              currentPanelKey={currentPanelKey}
            />
          </>
        ) : (
          <>
            <ListBirthday
              panelTitle={panelTitle}
              data={data}
              databaseField={databaseField}
              isWorkAnniversary={isWorkAnniversary}
              people={peoplePaginated}
              records={recordsData}
              isLoading={recordsLoading || loadingGlobalSetting}
              borderRadius={borderRadius}
              primaryColor={primaryColor}
              createProfileLink={createProfileLink}
              pagination={{ maxNumberOfItems, page, setPage, maxPage, hasNextPage }}
              currentPanelKey={currentPanelKey}
            />
          </>
        )}
      </>
    );
  }

  return (
    <ListBirthdaySide
      panelTitle={panelTitle}
      data={data}
      databaseField={databaseField}
      isWorkAnniversary={isWorkAnniversary}
      people={peoplePaginated}
      records={recordsData}
      isLoading={recordsLoading || loadingGlobalSetting}
      borderRadius={borderRadius}
      primaryColor={primaryColor}
      createProfileLink={createProfileLink}
      pagination={{ maxNumberOfItems, page, setPage, maxPage, hasNextPage }}
      currentPanelKey={currentPanelKey}
    />
  );
}

export default Birthday;
