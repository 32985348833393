import React from "react";
import EditPanelsButton from "../Shared/EditPanelsButton";
import Sidebar from "./Sidebar";
import { useSidebarStore } from "./space-directory-store";

function AdminControls() {
  const setIsSidebarOpen = useSidebarStore((state) => state.setIsSidebarOpen);

  const primaryColor = "#0065ff";
  const primaryContrastColor = "#ffffff";

  return (
    <>
      <EditPanelsButton
        openSidebar={() => setIsSidebarOpen(true)}
        primaryColor={primaryColor}
        primaryContrastColor={primaryContrastColor}
      />

      <Sidebar />
    </>
  );
}

export default AdminControls;
