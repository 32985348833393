import { useEffect, useState } from "react";
import Spinner from "@atlaskit/spinner";
import { ChildMenuWrapper, MenuContentPositioner, MenuContentWrapper } from "./styled";
import { dark } from "../../../../../dark-colors";
import { light } from "../../../../../light-colors";
import { DEFAULT_SPACE_ICON, DUMMY_SPACES } from "../../../functions/generateNewEntry";
import { useFetchSpaces } from "../../../hooks/fetchSpaces";
import SpaceSimpleCard from "../../../../Hub/Components/SpaceDirectory/Cards/SpaceSimpleCard";

function Houston({ structure, selectedRootEntry, isPreview, openOverContent, temporaryColorScheme }) {
  const [spaces, setSpaces] = useState([]);

  const themes = {
    dark,
    light,
  };

  const rootEntry = structure.find(({ id }) => id === selectedRootEntry);

  const formattedSpaces = rootEntry.data.spaces.map((space) => {
    const data = spaces.find(({ id }) => id === space.value);
    const icon = data?.icon?.path;

    return {
      ...data,
      icon: icon ? `${window.AP._hostOrigin}${icon}` : DEFAULT_SPACE_ICON,
    };
  });

  const [isLoading] = useFetchSpaces({ spaces: rootEntry.data.spaces, setSpaces, returnRawData: true });

  if (formattedSpaces.length) {
    if (!spaces.length || !spaces.every(({ id }) => formattedSpaces.find((opt) => id === opt.id))) {
      return (
        <MenuContentWrapper
          backgroundColor={themes[temporaryColorScheme]?.navigation.houston.menuContentBackground}
          openOverContent={openOverContent}
        >
          <MenuContentPositioner>
            <div style={{ margin: "30px auto 0" }}>
              <Spinner size="large" />
            </div>
          </MenuContentPositioner>
        </MenuContentWrapper>
      );
    }
  }

  return (
    <MenuContentWrapper
      backgroundColor={themes[temporaryColorScheme]?.navigation.houston.menuContentBackground}
      openOverContent={openOverContent}
    >
      <MenuContentPositioner>
        <ChildMenuWrapper
          isPreview={isPreview}
          numberOfColumns={structure.filter((entry) => entry.parent === selectedRootEntry)?.length}
        >
          {formattedSpaces.length ? (
            <>
              {formattedSpaces.map((space, i) => (
                <SpaceSimpleCard
                  key={i}
                  isInNavigation
                  hasActions
                  temporaryColorScheme={temporaryColorScheme}
                  displayAuthors={rootEntry.data.displayAuthors ?? true}
                  spaceInfo={{
                    url: `${window.AP._hostOrigin}/wiki${space._links?.webui}`,
                    key: space.key,
                    type: space.type,
                    icon: space.icon,
                    name: space.name,
                    creatorId: space.authorId,
                    createdBy: space.user?.displayName || "Unknown user",
                  }}
                />
              ))}
            </>
          ) : (
            <>
              {DUMMY_SPACES.map((space, i) => (
                <SpaceSimpleCard
                  key={i}
                  isInNavigation
                  hasActions
                  temporaryColorScheme={temporaryColorScheme}
                  spaceInfo={{
                    url: "#",
                    key: "KEY",
                    type: "global",
                    name: space.title,
                    createdBy: space.creator,
                    icon: `/images/dummy-spaces/${i + 1}.png`,
                  }}
                />
              ))}
            </>
          )}
        </ChildMenuWrapper>
      </MenuContentPositioner>
    </MenuContentWrapper>
  );
}

export default Houston;
