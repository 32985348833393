import React, { useEffect, useState } from "react";
import SidebarAppPicker from "../../../../../Hub/Components/Dashboard/filter/SidebarAppPicker";
import { getMacroSuiteStyleguide } from "../../../../../Hub/Components/Dashboard/api";
import { AppLinks } from "../../../../../Hub/Components/Dashboard/filter/appLinks";
import { useNavigationStore, useSidebarStore } from "../../../../store";

function Apps() {
  const { editingEntry, updateEntry } = useNavigationStore((state) => ({
    editingEntry: state.editingEntry,
    updateEntry: state.updateEntry,
  }));

  const { closeHelperDrawer } = useSidebarStore((state) => ({
    closeHelperDrawer: state.closeHelperDrawer,
  }));

  const [appIcons, setAppIcons] = useState([]);

  useEffect(() => {
    getMacroSuiteStyleguide()
      .then((res) => {
        if (res?.data?.exists && res?.data?.body?.appicons) {
          setAppIcons(res.data.body.appicons);
        } else {
          setAppIcons(AppLinks);
        }
      })
      .catch((err) => setAppIcons(AppLinks));
  }, []);

  return (
    <SidebarAppPicker
      customIcons={appIcons}
      onIconSelect={({ path }) => {
        updateEntry(editingEntry.id, "icon", path, true);
        closeHelperDrawer();
      }}
    />
  );
}

export default Apps;
