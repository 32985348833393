import React, { useEffect, useState } from "react";
import { CreatableSelect } from "@atlaskit/select";
import { FieldContainer, Label } from "./styled";
import { SELECT_STYLES, SIMPLE_SELECT_STYLES, selectStylesWithOverrides } from "./CustomSelectStyles";
import { useTheme } from "styled-components";

const components = {
  DropdownIndicator: null,
};

const createOption = (label) => ({
  label,
  value: label,
});

const LabelMultiSelect = ({
  initialLabels,
  onUpdate,
  label = "Categories",
  placeholder = "Filter by categories",
  containerStyle = {},
}) => {
  const [labels, setLabels] = useState([]);
  const [inputValue, setInputValue] = useState(undefined);
  const theme = useTheme();

  useEffect(() => {
    if (initialLabels) {
      setLabels(initialLabels.map((l) => createOption(l)));
    }
  }, [initialLabels]);

  const handleKeyDown = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
        setInputValue("");
        setLabels([...labels, createOption(inputValue)]);
        onUpdate([...labels, createOption(inputValue)].map((l) => l.label));
        event.preventDefault();
        break;
      default:
        return;
    }
  };

  return (
    <FieldContainer style={containerStyle}>
      <Label>{label}</Label>
      <CreatableSelect
        styles={SIMPLE_SELECT_STYLES}
        components={components}
        inputValue={inputValue}
        isClearable
        isMulti
        onChange={(newLabels) => {
          setLabels(newLabels);
          onUpdate(newLabels.map((l) => l.label));
        }}
        menuIsOpen={false}
        onInputChange={(newValue) => setInputValue(newValue)}
        onKeyDown={handleKeyDown}
        placeholder={placeholder}
        value={labels}
      />
    </FieldContainer>
  );
};

export default LabelMultiSelect;
