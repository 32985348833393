import BaseRestService from "./BaseRestService";

const UNSPLASH_KEY = "lTcsEt6MpSPe5fIA6ghtQHkTCLRcyGWoMNFEZt99-aQ";

class Unsplash extends BaseRestService {
  async searchImages(search, page, color, orientation) {
    let url = `https://api.unsplash.com/search/photos?per_page=28&page=${page}&client_id=${UNSPLASH_KEY}`;

    if (color) {
      url += `&color=${color.value}`;
    }
    if (orientation) {
      url += `&orientation=${orientation}`;
    }
    if (search) {
      url += `&query=${search}`;
    } else {
      url += `&query=city`;
    }

    return this.client.get(url, await this.buildJWTAuthAxiosConfig());
  }

  async trackDownload(url) {
    const downloadLink = `${url}&client_id=${UNSPLASH_KEY}`;

    return this.client.get(downloadLink, await this.buildJWTAuthAxiosConfig());
  }
}

export const UnsplashService = new Unsplash();

export const getPageById = async (pageId) =>
  new Promise((resolve) => {
    window.AP.request({
      method: "GET",
      url: `/rest/api/content/search?cql=(type=page OR type=blogpost) and id=${pageId}`,
      success: (res) => {
        const { results } = JSON.parse(res);
        if (results.length) {
          // Find by id
          const currentPage = results.find((result) => result.id === pageId);
          if (!currentPage) resolve({});

          resolve({
            label: currentPage.title,
            value: currentPage.id,
          });
        } else {
          resolve({});
        }
      },
      error: () => {
        resolve({});
      },
    });
  });

export const getPages = async () =>
  new Promise((resolve) => {
    window.AP.request({
      method: "GET",
      url: "/rest/api/search?cql=type=page OR type=blogpost",
      success: (res) => {
        const { results } = JSON.parse(res);
        const pages = results.map((page) => ({
          label: page.content.title,
          value: page.content.id,
          type: page.content.type,
        }));
        resolve(pages);
      },
      error: () => {
        resolve([]);
      },
    });
  });

export const searchPages = async (input) =>
  new Promise((resolve) => {
    const url = !input
      ? `/rest/api/content/search?cql=(type=page OR type=blogpost) and title ~ "*"`
      : `/rest/api/content/search?cql=(type=page OR type=blogpost) and title ~ "${input}*"`;
    window.AP.request({
      method: "GET",
      url,
      success: (res) => {
        const { results } = JSON.parse(res);
        const pages = results.map((page) => ({
          label: page.title,
          value: page.id,
          type: page.type,
        }));
        resolve(pages);
      },
      error: () => {
        resolve([]);
      },
    });
  });

export const getPageAttachments = async (pageId) =>
  new Promise((resolve) => {
    window.AP.request({
      method: "GET",
      url: `/rest/api/content/${pageId}/child/attachment`,
      success: (res) => {
        try {
          const data = JSON.parse(res);
          const attData = data.results;
          resolve(
            attData.filter((att) =>
              [
                "video/mp4",
                "image/jpeg",
                "image/jpg",
                "image/png",
                "image/gif",
                "image/webp",
                "application/octet-stream",
              ].includes(att.metadata.mediaType),
            ),
          );
        } catch (e) {
          resolve([]);
        }
      },
      error: () => {
        resolve([]);
      },
    });
  });
