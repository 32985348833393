import React from "react";
import { useTheme } from "styled-components";
import { THEME_NAME_LIGHT } from "../../../../../Navigation/functions/generateNewEntry";

function NoBirthdays() {
  const theme = useTheme();

  if (theme.global.name === THEME_NAME_LIGHT) {
    return (
      <svg width="160" height="156" viewBox="0 0 160 156" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_3193_3606)">
          <mask
            id="mask0_3193_3606"
            style={{ maskType: "alpha" }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="160"
            height="156"
          >
            <rect
              width="160"
              height="156"
              transform="matrix(1 1.74846e-07 1.74846e-07 -1 0 156)"
              fill="url(#paint0_linear_3193_3606)"
            />
          </mask>
          <g mask="url(#mask0_3193_3606)">
            <ellipse cx="80.4" cy="101.182" rx="78.4" ry="21.8909" fill="#E9F2FF" />
            <g filter="url(#filter0_d_3193_3606)">
              <path
                d="M37.6367 76.7457L75.3425 45.1075C79.3473 41.7471 84.9549 41.0588 89.6537 43.3508L123.673 59.9457V72.6729V84.382V85.6985C123.673 92.0307 119.188 97.4759 112.973 98.6895L53.4098 110.32C46.5197 111.666 40.0378 107.43 38.1718 101.078C37.8242 99.8941 37.6367 98.6372 37.6367 97.3293V89.4729V76.7457Z"
                fill="#E9F2FF"
              />
              <path
                d="M38.7637 89.4729V77.2712L76.0669 45.9709C79.7308 42.8966 84.8609 42.2668 89.1596 44.3637L122.546 60.6499V72.6729V84.382V85.6985C122.546 91.4915 118.443 96.4732 112.757 97.5834L53.1938 109.214C46.8933 110.444 40.9605 106.572 39.2531 100.76C38.9356 99.6789 38.7637 98.5289 38.7637 97.3293V89.4729Z"
                stroke="#0C66E4"
                strokeWidth="2.254"
              />
            </g>
            <rect x="67.1641" y="38.5635" width="9.16364" height="24.4364" fill="#579DFF" />
            <path
              d="M77.3465 31.9457C77.3465 35.0384 74.8393 37.5457 71.7465 37.5457C68.6537 37.5457 66.1465 35.0384 66.1465 31.9457C66.1465 28.8529 68.6537 26.3456 71.7465 26.3456C74.8393 26.3456 77.3465 28.8529 77.3465 31.9457Z"
              fill="#579DFF"
            />
            <path
              d="M66.1465 31.9457C66.1465 28.8529 68.6537 26.3456 71.7465 26.3456C74.8393 26.3456 77.3465 28.8529 77.3465 31.9457C77.3465 29.2305 76.2265 22.6802 71.7465 18.2002C67.2665 22.6802 66.1465 29.2305 66.1465 31.9457Z"
              fill="#579DFF"
            />
            <g filter="url(#filter1_d_3193_3606)">
              <path d="M78.3633 61.6641L65.127 64.7186" stroke="#0C66E4" strokeWidth="2.254" />
            </g>
            <g filter="url(#filter2_d_3193_3606)">
              <circle cx="89.5634" cy="39.582" r="8.14545" fill="#E9F2FF" />
              <circle cx="89.5634" cy="39.582" r="7.01845" stroke="#0C66E4" strokeWidth="2.254" />
            </g>
            <g filter="url(#filter3_d_3193_3606)">
              <circle cx="98.7275" cy="44.6728" r="8.14545" fill="#E9F2FF" />
              <circle cx="98.7275" cy="44.6728" r="7.01845" stroke="#0C66E4" strokeWidth="2.254" />
            </g>
            <g filter="url(#filter4_d_3193_3606)">
              <circle cx="107.892" cy="49.7646" r="8.14545" fill="#E9F2FF" />
              <circle cx="107.892" cy="49.7646" r="7.01845" stroke="#0C66E4" strokeWidth="2.254" />
            </g>
            <g filter="url(#filter5_d_3193_3606)">
              <circle cx="115.019" cy="53.8369" r="8.14545" fill="#E9F2FF" />
              <circle cx="115.019" cy="53.8369" r="7.01845" stroke="#0C66E4" strokeWidth="2.254" />
            </g>
            <path d="M39.1641 77.764L121.637 61.4727V72.164L39.1641 88.4549V77.764Z" fill="#579DFF" />
            <path
              d="M40.1826 101.691L121.637 85.4004C121.637 91.0127 117.648 95.8329 112.135 96.883L51.9844 108.34C48.7756 108.952 45.4842 107.808 43.3449 105.34L40.1826 101.691Z"
              fill="#579DFF"
            />
          </g>
        </g>
        <defs>
          <filter
            id="filter0_d_3193_3606"
            x="15.4284"
            y="32.1404"
            width="130.453"
            height="112.976"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="12.338" />
            <feGaussianBlur stdDeviation="11.1042" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.403111 0 0 0 0 0.524415 0 0 0 0 0.709375 0 0 0 0.15 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3193_3606" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3193_3606" result="shape" />
          </filter>
          <filter
            id="filter1_d_3193_3606"
            x="42.6657"
            y="50.696"
            width="58.1598"
            height="49.6667"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="12.338" />
            <feGaussianBlur stdDeviation="11.1042" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.403111 0 0 0 0 0.524415 0 0 0 0 0.709375 0 0 0 0.15 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3193_3606" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3193_3606" result="shape" />
          </filter>
          <filter
            id="filter2_d_3193_3606"
            x="59.2096"
            y="21.5662"
            width="60.7077"
            height="60.7077"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="12.338" />
            <feGaussianBlur stdDeviation="11.1042" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.403111 0 0 0 0 0.524415 0 0 0 0 0.709375 0 0 0 0.15 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3193_3606" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3193_3606" result="shape" />
          </filter>
          <filter
            id="filter3_d_3193_3606"
            x="68.3737"
            y="26.657"
            width="60.7077"
            height="60.7077"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="12.338" />
            <feGaussianBlur stdDeviation="11.1042" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.403111 0 0 0 0 0.524415 0 0 0 0 0.709375 0 0 0 0.15 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3193_3606" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3193_3606" result="shape" />
          </filter>
          <filter
            id="filter4_d_3193_3606"
            x="77.5378"
            y="31.7488"
            width="60.7077"
            height="60.7077"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="12.338" />
            <feGaussianBlur stdDeviation="11.1042" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.403111 0 0 0 0 0.524415 0 0 0 0 0.709375 0 0 0 0.15 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3193_3606" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3193_3606" result="shape" />
          </filter>
          <filter
            id="filter5_d_3193_3606"
            x="84.6647"
            y="35.821"
            width="60.7077"
            height="60.7077"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="12.338" />
            <feGaussianBlur stdDeviation="11.1042" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.403111 0 0 0 0 0.524415 0 0 0 0 0.709375 0 0 0 0.15 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3193_3606" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3193_3606" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear_3193_3606"
            x1="59.5973"
            y1="156"
            x2="133.691"
            y2="156"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </linearGradient>
          <clipPath id="clip0_3193_3606">
            <rect width="160" height="155.98" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  } else {
    return (
      <svg width="160" height="156" viewBox="0 0 160 156" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_3193_3611)">
          <mask
            id="mask0_3193_3611"
            style={{ maskType: "alpha" }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="160"
            height="156"
          >
            <rect
              width="160"
              height="156"
              transform="matrix(1 1.74846e-07 1.74846e-07 -1 0 156)"
              fill="url(#paint0_linear_3193_3611)"
            />
          </mask>
          <g mask="url(#mask0_3193_3611)">
            <ellipse cx="80.4" cy="101.182" rx="78.4" ry="21.8909" fill="#1C2B41" />
            <g filter="url(#filter0_d_3193_3611)">
              <path
                d="M37.6367 76.7457L75.3425 45.1075C79.3473 41.7471 84.9549 41.0588 89.6537 43.3508L123.673 59.9457V72.6729V84.382V85.6985C123.673 92.0307 119.188 97.4759 112.973 98.6895L53.4098 110.32C46.5197 111.666 40.0378 107.43 38.1718 101.078C37.8242 99.8941 37.6367 98.6372 37.6367 97.3293V89.4729V76.7457Z"
                fill="#1C2B41"
              />
              <path
                d="M38.7637 89.4729V77.2712L76.0669 45.9709C79.7308 42.8966 84.8609 42.2668 89.1596 44.3637L122.546 60.6499V72.6729V84.382V85.6985C122.546 91.4915 118.443 96.4732 112.757 97.5834L53.1938 109.214C46.8933 110.444 40.9605 106.572 39.2531 100.76C38.9356 99.6789 38.7637 98.5289 38.7637 97.3293V89.4729Z"
                stroke="#579DFF"
                strokeWidth="2.254"
              />
            </g>
            <rect x="67.1641" y="38.5635" width="9.16364" height="24.4364" fill="#0055CC" />
            <path
              d="M77.3465 31.9457C77.3465 35.0384 74.8393 37.5457 71.7465 37.5457C68.6537 37.5457 66.1465 35.0384 66.1465 31.9457C66.1465 28.8529 68.6537 26.3456 71.7465 26.3456C74.8393 26.3456 77.3465 28.8529 77.3465 31.9457Z"
              fill="#0055CC"
            />
            <path
              d="M66.1465 31.9457C66.1465 28.8529 68.6537 26.3456 71.7465 26.3456C74.8393 26.3456 77.3465 28.8529 77.3465 31.9457C77.3465 29.2305 76.2265 22.6802 71.7465 18.2002C67.2665 22.6802 66.1465 29.2305 66.1465 31.9457Z"
              fill="#0055CC"
            />
            <g filter="url(#filter1_d_3193_3611)">
              <path d="M78.3633 61.6641L65.127 64.7186" stroke="#579DFF" strokeWidth="2.254" />
            </g>
            <g filter="url(#filter2_d_3193_3611)">
              <circle cx="89.5634" cy="39.582" r="8.14545" fill="#1C2B41" />
              <circle cx="89.5634" cy="39.582" r="7.01845" stroke="#579DFF" strokeWidth="2.254" />
            </g>
            <g filter="url(#filter3_d_3193_3611)">
              <circle cx="98.7275" cy="44.6728" r="8.14545" fill="#1C2B41" />
              <circle cx="98.7275" cy="44.6728" r="7.01845" stroke="#579DFF" strokeWidth="2.254" />
            </g>
            <g filter="url(#filter4_d_3193_3611)">
              <circle cx="107.892" cy="49.7646" r="8.14545" fill="#1C2B41" />
              <circle cx="107.892" cy="49.7646" r="7.01845" stroke="#579DFF" strokeWidth="2.254" />
            </g>
            <g filter="url(#filter5_d_3193_3611)">
              <circle cx="115.019" cy="53.8369" r="8.14545" fill="#1C2B41" />
              <circle cx="115.019" cy="53.8369" r="7.01845" stroke="#579DFF" strokeWidth="2.254" />
            </g>
            <path d="M39.1641 77.764L121.637 61.4727V72.164L39.1641 88.4549V77.764Z" fill="#0055CC" />
            <path
              d="M40.1826 101.691L121.637 85.4004C121.637 91.0127 117.648 95.8329 112.135 96.883L51.9844 108.34C48.7756 108.952 45.4842 107.808 43.3449 105.34L40.1826 101.691Z"
              fill="#0055CC"
            />
          </g>
        </g>
        <defs>
          <filter
            id="filter0_d_3193_3611"
            x="15.4284"
            y="32.1404"
            width="130.453"
            height="112.976"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="12.338" />
            <feGaussianBlur stdDeviation="11.1042" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.403111 0 0 0 0 0.524415 0 0 0 0 0.709375 0 0 0 0.15 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3193_3611" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3193_3611" result="shape" />
          </filter>
          <filter
            id="filter1_d_3193_3611"
            x="42.6657"
            y="50.696"
            width="58.1598"
            height="49.6667"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="12.338" />
            <feGaussianBlur stdDeviation="11.1042" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.403111 0 0 0 0 0.524415 0 0 0 0 0.709375 0 0 0 0.15 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3193_3611" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3193_3611" result="shape" />
          </filter>
          <filter
            id="filter2_d_3193_3611"
            x="59.2096"
            y="21.5662"
            width="60.7077"
            height="60.7077"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="12.338" />
            <feGaussianBlur stdDeviation="11.1042" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.403111 0 0 0 0 0.524415 0 0 0 0 0.709375 0 0 0 0.15 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3193_3611" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3193_3611" result="shape" />
          </filter>
          <filter
            id="filter3_d_3193_3611"
            x="68.3737"
            y="26.657"
            width="60.7077"
            height="60.7077"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="12.338" />
            <feGaussianBlur stdDeviation="11.1042" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.403111 0 0 0 0 0.524415 0 0 0 0 0.709375 0 0 0 0.15 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3193_3611" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3193_3611" result="shape" />
          </filter>
          <filter
            id="filter4_d_3193_3611"
            x="77.5378"
            y="31.7488"
            width="60.7077"
            height="60.7077"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="12.338" />
            <feGaussianBlur stdDeviation="11.1042" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.403111 0 0 0 0 0.524415 0 0 0 0 0.709375 0 0 0 0.15 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3193_3611" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3193_3611" result="shape" />
          </filter>
          <filter
            id="filter5_d_3193_3611"
            x="84.6647"
            y="35.821"
            width="60.7077"
            height="60.7077"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="12.338" />
            <feGaussianBlur stdDeviation="11.1042" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.403111 0 0 0 0 0.524415 0 0 0 0 0.709375 0 0 0 0.15 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3193_3611" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3193_3611" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear_3193_3611"
            x1="59.5973"
            y1="156"
            x2="133.691"
            y2="156"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </linearGradient>
          <clipPath id="clip0_3193_3611">
            <rect width="160" height="155.98" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  }
}

export default React.memo(NoBirthdays);
