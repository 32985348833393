export async function searchForSpaceWithTerm(query) {
  const url = !query
    ? `/rest/api/search?cql=type=space and space.title ~ "*"`
    : `/rest/api/search?cql=type=space and space.title ~ "${query}*"`;

  try {
    const response = await fetch(url);
    return response.results.map((s) => s.space);
  } catch (err) {
    console.log(err);
    return [];
  }
}

export async function searchPagesWithCQL(input, expandParams = [], expandCQL = [], orderBy = "") {
  const expandQuery = !expandParams.length ? "" : `,${expandParams.join(",")}`;
  const expandQueryCQL = !expandCQL.length ? "" : ` and ${expandCQL.join(" and ")}`;
  const orderQuery = !orderBy ? "" : ` order by ${orderBy}`;

  const url = !input
    ? `/rest/api/content/search?expand=space${expandQuery}&cql=type=page and title ~ "*"${expandQueryCQL}${orderQuery}`
    : `/rest/api/content/search?expand=space${expandQuery}&cql=type=page and title ~ "${input} *"${expandQueryCQL}${orderQuery}`;

  try {
    return await fetch(url);
  } catch (err) {
    console.log(err);
    return [];
  }
}

async function fetch(path) {
  const AP = window.AP;
  if (!AP) {
    console.error("No AP object on window. Cant fetch " + path);
    return null;
  }
  const response = await AP.request(path);
  try {
    return JSON.parse(response.body);
  } catch (e) {
    console.error("Error fetching atlassian response", e);
    return null;
  }
}

export const searchPages = async (input, expandParams = [], expandCQL = [], orderBy = "") => {
  const expandQuery = !expandParams.length ? "" : `,${expandParams.join(",")}`;
  const expandQueryCQL = !expandCQL.length ? "" : ` and ${expandCQL.join(" and ")}`;
  const orderQuery = !orderBy ? "" : ` order by ${orderBy}`;

  const url = !input
    ? `/rest/api/content/search?expand=space${expandQuery}&cql=type=page and title ~ "*"${expandQueryCQL}${orderQuery}`
    : `/rest/api/content/search?expand=space${expandQuery}&cql=type=page and title ~ "${input}*"${expandQueryCQL}${orderQuery}`;

  try {
    const response = await window.AP.request(url);
    const body = JSON.parse(response.body);
    return body;
  } catch (err) {
    console.log(err);
    return [];
  }
};
