class AtlassianUserCache {
  constructor() {
    if (!AtlassianUserCache.instance) {
      AtlassianUserCache.instance = this;
      this.cache = {};
    }
    // Initialize object
    return AtlassianUserCache.instance;
  }

  get(accountId) {
    return this.cache[accountId];
  }

  putIfAbsent(accountId, user) {
    if (accountId in this.cache) {
      return;
    }
    this.cache[accountId] = user;
  }
}

const atlassianUserCache = new AtlassianUserCache();

export default atlassianUserCache;
