import React from "react";
import { useWidgetsStore } from "../../widgets-store";
import { Column, ContentColumns } from "../shared/styled";
import FieldSelector from "../shared/FieldSelector";
import { WidgetIds } from "../../consts/tabs";
import { FieldType } from "../../../../../../UserDatabase/field-type";
import InformationPanel, { StyledContent } from "../shared/InformationPanel";
import { EnableStates } from "./defaults";

function WorkAnniversary() {
  const { widgetSettings, updateWidgetSettings } = useWidgetsStore((state) => ({
    widgetSettings: state.widgetSettings,
    updateWidgetSettings: state.updateWidgetSettings,
  }));

  return (
    <ContentColumns>
      <Column>
        <FieldSelector
          title="Work Anniversary"
          withRecurring={EnableStates.DISABLED}
          selectedField={widgetSettings[WidgetIds.WORK_ANNIVERSARY]?.selectedField}
          filterFieldsByTypes={[FieldType.DATE]}
          onChange={(selectedField) => {
            updateWidgetSettings(WidgetIds.WORK_ANNIVERSARY, {
              selectedField,
            });
          }}
        />
      </Column>

      <Column>
        <InformationPanel
          content={
            <StyledContent
              title="Work Anniversary"
              description="The Work Anniversary widget displays users who have work anniversaries within 7 days in the past and up to 90 days in the future. For a user's anniversary to be featured, at least one year must have passed since their start date."
            />
          }
        />
      </Column>
    </ContentColumns>
  );
}

export default WorkAnniversary;
