import React, { useState, useEffect } from "react";
import Spinner from "@atlaskit/spinner";
import { FieldType } from "../../UserDatabase/field-type";
import Date from "./fields/Date";
import Labels from "./fields/Labels";
import Text from "./fields/Text";
import Url from "./fields/Url";

import { atlassianRestService } from "../../../Service/AtlassianRestService";

const FilterProvider = ({ filter, field, removeCallback, azureManagerOptions }) => {
  const [options, setOptions] = useState([]);

  const returnUniqueOptions = () => {
    const newOptions = filter.value.map((id) => {
      return { id, label: <Spinner size="small" /> };
    });

    setOptions((oldOptions) => {
      const filteredNewOptions = newOptions.filter(
        (newOption) => !oldOptions.some((oldOption) => oldOption.id === newOption.id),
      );
      return [...oldOptions, ...filteredNewOptions];
    });
  };

  const getOptions = async () => {
    if (filter.column === "azureManager") {
      if (azureManagerOptions.length) {
        setOptions(azureManagerOptions);
      } else {
        setOptions(filter.value.map((id) => ({ id, label: <Spinner size="small" /> })));
      }
    } else if (filter.type === FieldType.USER && filter.value) {
      returnUniqueOptions();

      atlassianRestService.fetchUsers(filter.value).then((users) => {
        setOptions(users.map((user) => ({ id: user.accountId, label: user.displayName })));
      });
    } else {
      setOptions(field.options || []);
    }
  };

  useEffect(() => {
    getOptions();
  }, [filter]);

  return (
    <div>
      {(filter.type === FieldType.MULTISELECT ||
        filter.type === FieldType.SELECT ||
        filter.type === FieldType.USER) && (
        <Labels
          column={filter.column}
          isMulti={filter.type === FieldType.MULTISELECT || filter.type === FieldType.USER || !!filter?.value?.length}
          value={filter.value}
          options={options}
          removeCallback={removeCallback}
        />
      )}

      {filter.type === FieldType.DATE && <Date filter={filter} removeCallback={removeCallback} />}

      {(filter.type === FieldType.NUMBER || filter.type === FieldType.TEXT) && (
        <Text value={filter.value} removeCallback={removeCallback} />
      )}

      {filter.type === FieldType.URL && <Url value={filter.value} removeCallback={removeCallback} />}
    </div>
  );
};
export default FilterProvider;
