import httpClient from "../../../../Service/http-client";
import { LoadingTypes } from "./filter/DynamicFilterToggle";
import { Ordering, Sorting } from "./filter/sorting-types";

export const mapSpace = (space) => {
  return {
    key: space.key,
    name: space.name,
    type: space.type,
    status: space.status,
    icon: `${window.AP._hostOrigin}/wiki${space.icon.path}`,
    description: space.description.plain.value,
    url: `${window.AP._hostOrigin}/wiki/spaces/${space.key}/overview`,
    createdBy: space.history.createdBy.displayName,
    creatorId: space.history.createdBy.accountId,
    labels: space?.metadata?.labels?.results
      ? space.metadata.labels.results.filter((l) => l.prefix !== "my").map((l) => l.label)
      : [],
    permissions: space.permissions.filter(
      (permission) => permission.operation.targetType === "space" && permission.operation.operation === "administer",
    ),
    isPersonal: space.type === "personal",
  };
};

export async function getCurrentUsersTasks(accountId, status) {
  try {
    const url = `/api/v2/tasks?assigned-to=${accountId}&body-format=storage${
      status ? `&status=${status}` : ""
    }&limit=250`;
    const userTaskReq = await window.AP.request(url);
    if (userTaskReq?.body) {
      const data = JSON.parse(userTaskReq.body);
      return data;
    }
    return undefined;
  } catch (error) {
    return undefined;
  }
}

export async function updateTask(taskId, status) {
  const url = "ac/confluence/api/tasks/update";
  return httpClient.post(url, { taskId, status });
}

export async function getBlogById(contentId) {
  const url = `/rest/api/search?cql=type=blogpost and id=${contentId}&limit=1&expand=content.history,content.space,content.metadata.labels,content.metadata.likes,content.children.comment`;
  try {
    return window.AP.request(url);
  } catch (e) {
    console.log("cant fetch blogpost", contentId);
    console.log(e);
    return undefined;
  }
}

export async function searchBlogposts({ queryKey, pageParam }) {
  const [
    _key,
    {
      labels = [],
      contributors = [],
      spaces = [],
      limit = 2,
      sorting = Sorting.CREATED,
      ordering = Ordering.DESCENDING,
      loadingType = LoadingTypes.DYNAMIC,
      manullyPicked = [],
    },
  ] = queryKey;
  let cql = "type = blogpost";

  if (loadingType === LoadingTypes.MANUAL) {
    const manual = manullyPicked.map(({ contentId }) => contentId);
    if (manual?.length) {
      cql = cql + ` AND content in (${manual.join(", ")})`;
    } else {
      cql = cql + ` AND content in (-1)`;
    }
  } else {
    if (labels.length > 0) {
      cql = cql + ` AND label in (${labels.map((l) => `"${l}"`).join(",")})`;
    }
    if (contributors.length > 0) {
      cql = cql + ` AND contributor.accountid in (${contributors.map((c) => `"${c}"`).join(",")})`;
    }
    if (spaces.length > 0) {
      cql = cql + ` AND space in (${spaces.map((s) => `"${s}"`).join(",")})`;
    }
    if (sorting === Sorting.CREATED) {
      cql = cql + ` ORDER BY created`;
    }
    if (sorting === Sorting.MODIFIED) {
      cql = cql + ` ORDER BY lastmodified`;
    }
    if (sorting === Sorting.TITLE) {
      cql = cql + ` ORDER BY title`;
    }
    if (ordering === Ordering.ASCENDING) {
      cql = cql + ` asc`;
    }
    if (ordering === Ordering.DESCENDING) {
      cql = cql + ` desc`;
    }
  }

  let url = `/rest/api/search?cql=${cql}&limit=${limit}&expand=content.history,content.space,content.metadata.labels,content.metadata.likes,content.children.comment`;

  if (pageParam) {
    url = `${url}&cursor=${decodeURI(pageParam)}`;
  }
  try {
    return window.AP.request(url);
  } catch (e) {
    console.log("cant fetch blogposts");
    console.log(e);
  }
}

export async function fetchBlogsByIds(blogIds = []) {
  let cql = `type = blogpost AND content in (${blogIds.join(", ")})`;

  let url = `/rest/api/search?cql=${cql}&expand=content.history,content.space,content.metadata.labels,content.metadata.likes,content.children.comment`;

  try {
    return window.AP.request(url);
  } catch (e) {
    console.log("cant fetch blogposts");
    console.log(e);
  }
}

export async function searchPages({ queryKey, pageParam }) {
  const [
    _key,
    {
      labels = [],
      contributors = [],
      spaces = [],
      parentPageId = undefined,
      limit = 2,
      sorting = Sorting.CREATED,
      ordering = Ordering.DESCENDING,
      excludePersonalSpaces = false,
    },
  ] = queryKey;
  let cql = "type in (page, blogpost)";
  if (labels.length > 0) {
    cql = cql + ` AND label in (${labels.map((l) => `"${l}"`).join(",")})`;
  }
  if (contributors.length > 0) {
    cql = cql + ` AND contributor.accountid in (${contributors.map((c) => `"${c}"`).join(",")})`;
  }
  if (excludePersonalSpaces) {
    cql = cql + ` AND space.type=global`;
  }
  if (spaces.length > 0) {
    cql = cql + ` AND space in (${spaces.map((s) => `"${s}"`).join(",")})`;
  }
  if (parentPageId) {
    cql = cql + ` AND parent=${parentPageId}`;
  }
  if (sorting === Sorting.CREATED) {
    cql = cql + ` ORDER BY created`;
  }
  if (sorting === Sorting.MODIFIED) {
    cql = cql + ` ORDER BY lastmodified`;
  }
  if (sorting === Sorting.TITLE) {
    cql = cql + ` ORDER BY title`;
  }
  if (ordering === Ordering.ASCENDING) {
    cql = cql + ` asc`;
  }
  if (ordering === Ordering.DESCENDING) {
    cql = cql + ` desc`;
  }

  let url = `/rest/api/search?cql=${cql}&limit=${limit}&expand=content.history,content.space,content.metadata.labels,restrictions.update.restrictions.user,content.metadata.likes,content.children.comment`;
  if (pageParam) {
    url = `${url}&cursor=${decodeURI(pageParam)}`;
  }
  try {
    return window.AP.request(url);
  } catch (e) {
    console.log("cant fetch pages");
    console.log(e);
  }
}

export async function getCurrentUserPageWatchStatus(contentId) {
  const url = `/rest/api/user/watch/content/${contentId}`;
  try {
    return window.AP.request(url);
  } catch (e) {
    console.log("cant fetch watch status");
    console.log(e);
  }
}

export async function addCurrentUserAsContentWatcher(contentId) {
  const url = `ac/confluence/api/user/watch/content`;
  return httpClient.post(url, { contentId });
}

export async function removeCurrentUserAsContentWatcher(contentId) {
  const url = `ac/confluence/api/user/watch/content/remove`;
  return httpClient.post(url, { contentId });
}

export async function getPageById(contentId) {
  const url = `/rest/api/search?cql=type in (page, blogpost) and id=${contentId}&limit=1&expand=content.history,content.space,content.metadata.labels,content.metadata.likes,content.children.comment`;

  try {
    return window.AP.request(url);
  } catch (e) {
    console.log("cant fetch page", contentId);
    console.log(e);
    return undefined;
  }
}

export async function searchSpaces({ queryKey, pageParam = 0 }) {
  const [_key, { categories = [], spaceType = "global", spaceStatus = "current", limit = 2 }] = queryKey;
  const labelsSplitted = categories.join(",");
  let url = `/rest/api/space?type=${spaceType}&status=${spaceStatus}&start=${pageParam}&limit=${limit}&expand=icon,description.plain,history,homepage,permissions,metadata.labels`;
  if (labelsSplitted.length > 0) {
    url = `${url}&label=${labelsSplitted}`;
  }
  try {
    return window.AP.request(url);
  } catch (e) {
    console.log("cant fetch spaces");
    console.log(e);
  }
}

export async function fetchDetailsForUsers(accountIds) {
  try {
    const url = `/rest/api/user/bulk?accountId=${accountIds.join(",")}`;
    return window.AP.request(url);
  } catch (error) {
    return undefined;
  }
}

export async function fetchDetailsForUsersIndividually(accountIds) {
  try {
    const promises = accountIds.map(async (accountId) => {
      const res = await window.AP.request(`/rest/api/user?accountId=${accountId}`);
      const resData = JSON.parse(res.body);
      return resData;
    });

    const response = await Promise.all(promises);
    return response;
  } catch (error) {
    return [];
  }
}

export async function getSpaceByKey(spaceKey) {
  return window.AP.request(
    `/rest/api/space/${spaceKey}?expand=history,icon,description.plain,permissions,metadata.labels`,
  );
}

export async function fetchUserConnectedToJira({ queryKey }) {
  const url = `ac/confluence/jira/user-already-connected`;
  return httpClient.get(url);
}

export async function fetchUserIssuesTotal(accountId) {
  const queryString = encodeURIComponent(`jql=assignee="${accountId}" AND statusCategory not in (Done)&maxResults=0`);
  const url = `ac/confluence/jira/api?path=/rest/api/3/search?${queryString}`;

  try {
    const response = await httpClient.get(url);
    return response?.data?.total || 0;
  } catch {
    return 0;
  }
}

export async function getDashboard({ queryKey }) {
  const url = "ac/confluence/dashboard";
  return httpClient.get(url);
}

export async function saveDashboard(dashboard) {
  const url = `ac/confluence/dashboard/save`;
  return httpClient.post(url, { data: dashboard });
}

export async function getDraftDashboard({ queryKey }) {
  const url = "ac/confluence/dashboard/draft";
  return httpClient.get(url);
}

export async function saveDraftDashboard(draft) {
  const url = `ac/confluence/dashboard/save/draft`;
  return httpClient.post(url, { data: draft });
}

export async function getPollById({ queryKey }) {
  const [_key, { pollId }] = queryKey;
  const url = `ac/confluence/poll`;
  return httpClient.post(url, { pollId });
}

export async function savePoll(pollId, pollData) {
  const url = `ac/confluence/poll/save`;
  return httpClient.post(url, { pollId, pollData });
}

export async function updateUserVotesInPoll(pollId, newUser) {
  const url = `ac/confluence/poll/vote`;
  return httpClient.post(url, { pollId, newUser });
}

export async function removeUserVoteInPoll(pollId) {
  const url = `ac/confluence/poll/vote/remove`;
  return httpClient.post(url, { pollId });
}

export async function getMacroSuiteStyleguide() {
  const url = `ac/confluence/macrosuite/styleguide`;
  return httpClient.get(url);
}

export async function setMacroSuiteStyleguide(propertyKey, data) {
  const url = `ac/confluence/macrosuite/styleguide`;
  return httpClient.post(url, { propertyKey, data });
}
