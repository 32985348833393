import React, { Fragment, useMemo } from "react";
import { InlineNews } from "@caelor/cards-and-panels-components";
import InfiniteScroller from "../../../../../Shared/Components/InfiniteScroller";
import { ListDateGroupWrapper, ListDateSeparator, ListDateTitle, ListGridContainer } from "../styled/newsroom";
import { NewsElements, NewsViews } from "../defaults";
import { useNewsroomStore } from "../newsroom-store";
import { SettingPropertyKeys } from "../Sidebar/NewsDesign";
import { useTheme } from "styled-components";

function ListView({ data, fetchNextPage, hasNextPage, borderRadius }) {
  const settings = useNewsroomStore((state) => state.settings);

  const getListSettingsData = (prop) => settings[NewsViews.LIST][prop];

  const theme = useTheme();

  const createGroupsForBlogs = (_data) => {
    if (_data?.pages?.length) {
      const allBlogPosts = _data.pages.map((page) => page.results).flat();
      const groups = allBlogPosts.reduce((groups, blog) => {
        const date = blog.created.split("T")[0].slice(0, 7);
        if (!groups[date]) {
          groups[date] = [];
        }
        groups[date].push(blog);
        return groups;
      }, {});

      const groupArrays = Object.keys(groups).map((date) => {
        return {
          date,
          blogs: groups[date],
        };
      });

      return [...groupArrays];
    }
    return [];
  };

  const groupedByDateBlogs = useMemo(() => createGroupsForBlogs(data), [data?.pages]);

  return (
    <InfiniteScroller
      dataLength={data?.pages?.length || 0}
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage}
      endMessage=""
    >
      {!!groupedByDateBlogs?.length &&
        groupedByDateBlogs.map(({ date, blogs }, index) => (
          <Fragment key={index}>
            <ListDateGroupWrapper isFirstPage={index === 0}>
              <ListDateTitle>
                {new Date(date).toLocaleString("en-GB", { year: "numeric", month: "long" })}
              </ListDateTitle>
              <ListDateSeparator borderColor={theme.shared.sidebar.divider} />
            </ListDateGroupWrapper>

            <ListGridContainer style={{ rowGap: "1px" }}>
              {blogs.map((blog) => (
                <InlineNews
                  key={blog.id}
                  coverPictureCallback={blog.coverPictureCallback}
                  getBlogsCategories={
                    getListSettingsData(SettingPropertyKeys.ACTIVE_ELEMENTS).includes(NewsElements.CATEGORIES)
                      ? blog.getBlogsCategories
                      : undefined
                  }
                  name={blog.name}
                  space={blog.space}
                  url={blog.url}
                  excerpt={blog.excerpt}
                  labels={blog.labels}
                  createdBy={
                    getListSettingsData(SettingPropertyKeys.ACTIVE_ELEMENTS).includes(NewsElements.AUTHOR)
                      ? blog.createdBy
                      : null
                  }
                  lastModified={blog.createdFormatted}
                  avatarUrl={blog.avatarUrl}
                  labelsBackground={getListSettingsData(SettingPropertyKeys.LABELS_BACKGROUND)}
                  coverPictureBorderRadius={borderRadius.replace("px", "")}
                  imagePosition="left"
                  labelsBorderRadius={borderRadius}
                  darkTheme={{
                    titleColor: theme.shared.news.title,
                    descriptionColor: theme.shared.news.text,
                    authorColor: theme.shared.news.text,
                    borderColor: theme.shared.sidebar.divider,
                    textColor: theme.shared.news.text,
                    borderBottom: theme.shared.sidebar.divider,
                  }}
                />
              ))}
            </ListGridContainer>
          </Fragment>
        ))}
    </InfiniteScroller>
  );
}

export default ListView;
