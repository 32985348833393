import React, { useEffect, useMemo, useRef } from "react";
import Spinner from "@atlaskit/spinner";
import { SpinnerContainer } from "../../../styled/pages";
import { useSpaceDirectoryStore } from "../space-directory-store";
import { SectionsWrapper, SmallTitle, Subtitle } from "../styled/space";
import InfiniteScroller from "../../../../../Shared/Components/InfiniteScroller";
import Discover from "../Sections/Discover";
import LastModifiedByUser from "../Sections/LastModifiedByUser";
import StarredAndWatching from "../Sections/StarredAndWatching";
import LatestActivity from "../Sections/LatestActivity";
import Results from "./Results";
import EmptyState from "./EmptyState";
import NoResultsState from "../../Shared/NoResultsState";
import { useCurrentUserStore } from "../../../current-user-store";

function SpaceEntry({ data, fetchNextPage, hasNextPage, isSearching, searchValue, isLoading }) {
  const currentUserRef = useRef();

  const isAnonymous = useCurrentUserStore((state) => state.isAnonymous);

  const { selectedView, selectedFilter, setUpdatingSpace, setEditSpaceModalOpen } = useSpaceDirectoryStore((state) => ({
    selectedView: state.selectedView,
    selectedFilter: state.selectedFilter,
    setUpdatingSpace: state.setUpdatingSpace,
    setEditSpaceModalOpen: state.setEditSpaceModalOpen,
  }));

  const primaryColor = "#0065ff";
  const primaryContrastColor = "#ffffff";

  const shouldShowSections = useMemo(() => {
    return !isSearching && !selectedFilter;
  }, [isSearching, selectedFilter]);

  useEffect(() => {
    window.AP.user.getCurrentUser((user) => {
      currentUserRef.current = user.atlassianAccountId;
    });
  }, []);

  if (!data?.pages && !isLoading && shouldShowSections) {
    return <EmptyState />;
  }

  const totalSize = data?.pages?.[0]?.totalSize || data?.pages?.[0]?.size;
  const results = totalSize !== 0 && (totalSize > 1 ? `${totalSize} results` : `${totalSize} result`);

  return (
    <>
      {shouldShowSections && (
        <SectionsWrapper>
          <Discover
            currentUserId={currentUserRef?.current}
            primaryColor={primaryColor}
            primaryContrastColor={primaryContrastColor}
          />
          {!isAnonymous && (
            <>
              <LastModifiedByUser
                currentUserId={currentUserRef?.current}
                primaryColor={primaryColor}
                primaryContrastColor={primaryContrastColor}
              />
              <StarredAndWatching />
            </>
          )}
          <LatestActivity />
        </SectionsWrapper>
      )}

      {!shouldShowSections && (
        <div>
          <SmallTitle>{searchValue ? `Results for: "${searchValue}"` : `${selectedFilter}`}</SmallTitle>
          <Subtitle>{!isLoading && results}</Subtitle>
          {isLoading ? (
            <SpinnerContainer>
              <Spinner size="xlarge" />
            </SpinnerContainer>
          ) : (
            <>
              {!data?.pages?.[0]?.results?.length ? (
                <NoResultsState />
              ) : (
                <InfiniteScroller
                  dataLength={data?.pages?.length || 0}
                  fetchNextPage={fetchNextPage}
                  hasNextPage={hasNextPage}
                  endMessage=""
                >
                  <Results
                    data={data}
                    selectedFilter={selectedFilter}
                    selectedView={selectedView}
                    currentUserId={currentUserRef?.current}
                    setIsModalOpen={setEditSpaceModalOpen}
                    setUpdatingSpace={setUpdatingSpace}
                  />
                </InfiniteScroller>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
}

export default SpaceEntry;
