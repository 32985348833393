import React from "react";
import Toggle from "@atlaskit/toggle";
import { useNavigationStore } from "../../../../../store";
import { PARENT_ENTRY_IDENTIFIER } from "../../../../../defaults";
import { HoustonTabs, TabTypes } from "../tabs";
import { Box, Flex } from "@atlaskit/primitives";
import NativeTabs from "../../../../../../../Shared/Components/NativeTabs";
import IconTitle from "../shared/IconTitle";
import { FieldInline, FieldLabel } from "../shared/Components";
import Spaces from "./tabs/SpacesTab";

function Houston() {
  const { editingEntry, updateEntry } = useNavigationStore((state) => ({
    editingEntry: state.editingEntry,
    updateEntry: state.updateEntry,
  }));

  const isParent = editingEntry.parent === PARENT_ENTRY_IDENTIFIER;

  const [selectedTab, setSelectedTab] = React.useState(TabTypes.LAYOUT);

  return (
    <Box>
      {isParent && <NativeTabs tabs={HoustonTabs} useNative={false} returnSelection={setSelectedTab} />}

      <Box paddingBlockStart={isParent ? "space.300" : "space.0"}>
        {selectedTab === TabTypes.LAYOUT && (
          <>
            <Flex direction="column">
              <IconTitle />
            </Flex>

            <FieldInline style={{ marginTop: "20px" }}>
              <FieldLabel>Display authors</FieldLabel>
              <Toggle
                id="toggle-controlled-authors"
                onChange={(e) => {
                  updateEntry(editingEntry.id, "displayAuthors", e.target.checked, true);
                }}
                isChecked={editingEntry?.data?.displayAuthors ?? true}
              />
            </FieldInline>
          </>
        )}

        {selectedTab === TabTypes.SPACES && <Spaces />}
      </Box>
    </Box>
  );
}

export default Houston;
