import React, { useCallback, useContext, useEffect, useState } from "react";
import { atlassianRestService } from "../../../../../Service/AtlassianRestService";
import { ChartContext } from "../context";
import ViewSelector from "../views/ViewSelector";

function UserTemplate(node) {
  const [isLoading, setIsLoading] = useState(false);
  const [userInfo, setUserInfo] = useState();

  const { viewOptions, isPreview, onItemClick } = useContext(ChartContext);

  const fetchUserInfo = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await atlassianRestService.getUserByAccountId(node.id);
      if (response) {
        setUserInfo({
          key: response.accountId,
          name: response.displayName,
          profilePictureSrc: `${window.AP._hostOrigin}${response?.profilePicture?.path}`,
        });
      }
    } catch (error) {
      setUserInfo({ error: true });
    } finally {
      setIsLoading(false);
    }
  }, [node?.id]);

  useEffect(() => {
    if (node?.id && node.id !== "root-level") {
      fetchUserInfo();
    }

    return () => {
      setUserInfo();
    };
  }, [node.id, fetchUserInfo]);

  if (node.id === "root-level") return <></>;

  return (
    <ViewSelector
      selectedView={viewOptions?.view}
      isPreview={isPreview}
      componentProps={{
        node,
        userInfo,
        isLoading,
        hasChildren: !!node?.children?.length,
        onItemClick,
      }}
    />
  );
}

export default UserTemplate;
