export const LATEST_NEWS = [
  {
    label: "",
    title: "Active Directory",
    text: "Efficient and reliable synchronization of user data with Active Directory.",
    image: "/images/news/active_directory.jpg",
    color: "#1b65d2",
    date: "March, 2024",
  },
  {
    label: "",
    title: "Org. Charts",
    text: "Dynamic Org Charts in People Network and Profile screens with real-time updates and interactive elements.",
    image: "/images/news/org_charts.jpg",
    color: "#1b65d2",
    link: "https://caelor-apps.atlassian.net/l/cp/ajD0sTuw",
    date: "",
  },
  {
    label: "",
    title: "Dark Mode",
    text: "Experience Cosmos in a new light with a full Dark Mode compatibility.",
    image: "/images/news/dark_mode.jpg",
    color: "#1b65d2",
    link: "https://caelor-apps.atlassian.net/l/cp/USWVXty1",
    date: "",
  },
  {
    label: "",
    title: "Navigation Menu Revamp",
    text: "Discover new styles, entry personalization, space-based navigation menus and more!",
    image: "/images/news/navigation_menu_revamp.jpg",
    color: "#1b65d2",
    link: "https://caelor-apps.atlassian.net/l/cp/kU0MzBDm",
    date: "",
  },
  {
    label: "",
    title: "Customize People Network",
    text: "Add new, delete, and reorder panels, completely customizing your People Network.",
    image: "/images/news/customize_people_network.jpg",
    color: "#1b65d2",
    link: "https://caelor-apps.atlassian.net/l/cp/L1GDXxLH",
    date: "",
  },
  {
    label: "",
    title: "Cosmos Admins",
    text: "Provide users with admin rights on your intranet, regardless of their Confluence admin status.",
    image: "/images/news/cosmos_admins.jpg",
    color: "#1b65d2",
    link: "https://caelor-apps.atlassian.net/l/cp/j5QDf1Ry",
    date: "",
  },
  {
    label: "",
    title: "Gradient and Color Pickers",
    text: "Create gradients for the Welcome Banner and Countdown panels on the Dashboard.",
    image: "/images/news/gradient_and_color_pickers.jpg",
    color: "#1b65d2",
    link: undefined,
    date: "",
  },
  {
    label: "",
    title: "Collections",
    text: "Create custom user groups based on specific criteria and personalize your intranet.",
    image: "/images/news/collections.jpg",
    color: "#1b65d2",
    link: "https://caelor-apps.atlassian.net/l/cp/1FGgCE9Z ",
    date: "",
  },
  {
    label: "",
    title: "People Network Redesign",
    text: "Enhanced panels for team interaction, recent activity, user content, and improved user listing.",
    image: "/images/news/people_network_design.jpg",
    color: "#1b65d2",
    link: "https://caelor-apps.atlassian.net/l/cp/L1GDXxLH ",
    date: "",
  },
  {
    label: "",
    title: "Dashboard Builder",
    text: "Add new, delete, and reorder panels, completely customizing your Cosmos Dashboard.",
    image: "/images/news/dashboard_builder.jpg",
    color: "#1b65d2",
    link: "https://caelor-apps.atlassian.net/l/cp/09a11cAb",
    date: "",
  },
  {
    label: "",
    title: "Brand and Colors",
    text: "Upload your logo and headers, change titles, descriptions, and colors.",
    image: "/images/news/brand_and_colors.jpg",
    color: "#1b65d2",
    link: "https://caelor-apps.atlassian.net/l/cp/5TPJiHe0 ",
    date: "",
  },
];

export const CAELOR_NEWS = [];
