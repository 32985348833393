import styled from "styled-components";

export const ViewButtonsWrapper = styled.div`
  display: grid;
  grid-template-columns: ${({ numberOfColumns }) => `repeat(${numberOfColumns || 3}, minmax(0, 1fr))`};
  column-gap: 17px;
`;

export const ViewSelectButton = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: grid;
  place-content: center;

  border-radius: 3px;
  padding: 8px 0px;
  cursor: pointer;
  background-color: ${({ isActive, backgroundColor }) => {
    if (isActive) {
      return backgroundColor.active;
    }
    return backgroundColor.inactive;
  }};
  &:hover {
    background-color: ${({ isActive, theme }) => (isActive ? "" : theme.shared.form.select.borderColor)};
  }
`;

export const FieldContainer = styled.div`
  margin-top: 20px;
  background: "yellow";
`;

export const DoubleLabelContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 10px;
`;

export const FieldLabel = styled.div`
  font-weight: 700;
  font-size: 11px;
  line-height: normal;
  color: ${({ theme }) => theme.space.fieldLabelColor};
  margin-bottom: 8px;
`;

export const ClickableLabel = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: ${({ color }) => color};
  margin-bottom: 12px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export const FilterDivider = styled.div`
  width: 100%;
  padding-top: 20px;
  border-bottom: 1px solid ${({ borderColor }) => borderColor};
`;
