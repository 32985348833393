import React from "react";
import AddIcon from "@atlaskit/icon/glyph/editor/add";
import Button from "@atlaskit/button/new";
import { Pages } from "../../../pages";
import { usePageStore } from "../../../page-store";
import { SectionKeys, SectionPagesIDs } from "../../Settings/sections";
import ListingFilters from "../../Shared/ListingFilters";
import styled from "styled-components";
import { useCorporateIdentityStore } from "../../Settings/General/BrandAndColors/corporate-identity-store";
import { COLOR_TYPES } from "../../Settings/General/BrandAndColors/color-types";

export const CollectionsButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;

  span {
    flex-grow: 0;
  }
`;

function CollectionsFilter({
  collections,
  selectedCollection,
  setSelectedCollection,
  filters,
  setFilters,
  applyFilters,
  resetFilters,
}) {
  const { setSelectedPage } = usePageStore((state) => ({
    setSelectedPage: state.setSelectedPage,
  }));
  const colors = useCorporateIdentityStore((state) => state.colors);

  const primaryColor = colors ? colors[COLOR_TYPES.PRIMARY] : "#0065ff";

  const handleClickOnCollection = (clickedCollection) => {
    if (!selectedCollection || selectedCollection.id !== clickedCollection.id) {
      setSelectedCollection(clickedCollection);
      if (clickedCollection.filters?.length) {
        const newFilters = filters.map((filter) => ({
          ...filter,
          value: undefined,
        }));
        clickedCollection.filters.forEach((collectionFilter) => {
          const updatedFilter = newFilters.find((f) => f.column === collectionFilter.column);
          if (!updatedFilter) {
            return;
          }
          updatedFilter.value = collectionFilter.value;
        });
        setFilters(newFilters);
        applyFilters(newFilters);
      } else {
        resetFilters();
        applyFilters([]);
      }
    } else {
      setSelectedCollection(undefined);
      resetFilters();
      applyFilters([]);
    }
  };

  const handleAddCollection = () => {
    setSelectedPage(`${Pages.SETTINGS}-${SectionKeys.PEOPLE_BASE}-${SectionPagesIDs.COLLECTIONS}`);
  };

  return (
    <div>
      {collections?.length ? (
        <ListingFilters
          filters={collections
            .filter((x) => !x.hiddenPeopleNetwork)
            .sort((a, z) => (a.name.toLowerCase() > z.name.toLowerCase() ? 1 : -1))}
          isFilterSelected={(id) => selectedCollection?.id === id}
          title="COLLECTIONS"
          handleClickOnItem={(id) => handleClickOnCollection(collections.find((clc) => clc.id === id))}
        />
      ) : (
        <CollectionsButtonWrapper>
          <Button
            appearance="primary"
            iconBefore={AddIcon}
            onClick={handleAddCollection}
            style={{ backgroundColor: primaryColor }}
          >
            Create Collections
          </Button>
        </CollectionsButtonWrapper>
      )}
    </div>
  );
}

export default CollectionsFilter;
