import styled from "styled-components";

export const hoverStyle = `
  border-radius: 9px;
  cursor: pointer;

  &:hover { 
    background: ${({ theme }) => theme.shared.profile.selectBackground};
    margin: -4px -12px;
    padding: 4px 12px;
  }
`;

export const FieldContainer = styled.div`
  position: relative;
  margin-bottom: 20px;
`;

export const FieldTitle = styled.div`
  width: fit-content;
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 5px;
  line-height: 26px;
  color: ${({ theme }) => theme.shared.profile.field.title};
`;

export const EditButton = styled.span`
  display: none;
  position: absolute;
  height: 32px;
  width: 28px;
  align-items: center;
  justify-content: center;
  top: 0;
  right: -21px;
  background: ${({ theme }) => theme.shared.profile.selectBackground};;
  border-top-right-radius: 9px;
  border-bottom-right-radius: 9px;

  &:hover {
    background: #ddd;
  }
`;

export const FieldValue = styled.span`
  position: relative;
  display: block;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: ${({ theme }) => theme.shared.profile.field.value};
  width: fit-content;
  word-break: break-word;
  min-height: 24px;
  min-width: 40px;
  opacity: ${({ isEditing }) => (isEditing ? 0 : 1)};

  &:hover ${EditButton} {
    display: inline-flex;
  }

  ${({ isEditable }) => (isEditable ? hoverStyle : "")}
`;

export const FieldLink = styled.a`
  text-decoration: none;
  width: fit-content;
  color: #0c5eff;

  &:hover {
    color: #0c5eff;
    opacity: 0.95;
  }

  &:visited {
    color: #0c5eff;
  }
`;

export const UserWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  column-gap: 6px;
  min-height: 24px;
  opacity: ${({ isEditing }) => (isEditing ? 0 : 1)};

  ${({ isEditable }) => (isEditable ? hoverStyle : "")}
`;

export const UserImage = styled.img`
  box-sizing: border-box;
  height: 20px;
  width: 20px;
  border-radius: 50%;
`;

export const UserName = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.shared.sidebar.label};
`;

export const EditorContainer = styled.div`
  position: absolute;
  max-width: 200px;
  margin: -4px -12px;
`;

export const EditorBackground = styled.div`
  display: flex;
  align-items: center;
  border-radius: 9px;
  background: ${({ theme }) => theme.shared.profile.selectBackground};;
  padding: 0 6px;
  height: 32px;

  * {
    font-size: 14px;
  }
`;
