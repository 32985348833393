import React, { useEffect, useState } from "react";
import styled, { useTheme } from "styled-components";
import StarFilledIcon from "@atlaskit/icon/glyph/star-filled";
import WatchIcon from "@atlaskit/icon/glyph/watch";
import WatchFilledIcon from "@atlaskit/icon/glyph/watch-filled";
import Tooltip from "@atlaskit/tooltip";
import { token } from "@atlaskit/tokens";
import {
  addCurrentUserAsSpaceWatcher,
  getCurrentUserSpaceFavoriteStatus,
  getCurrentUserSpaceWatchStatus,
  removeCurrentUserAsSpaceWatcher,
} from "../apiSpaces";
import { useCorporateIdentityStore } from "../../Settings/General/BrandAndColors/corporate-identity-store";
import { COLOR_TYPES } from "../../Settings/General/BrandAndColors/color-types";
import { useCurrentUserStore } from "../../../current-user-store";
import { createProfileLink } from "../../PeopleBase/Views/createProfileLink";
import { dark } from "../../../../../dark-colors";
import { light } from "../../../../../light-colors";

const themes = {
  dark,
  light,
};

const SimpleCardWrapper = styled.div`
  position: relative;
  background-color: ${({ theme, temporaryColorScheme = undefined }) =>
    temporaryColorScheme ? themes[temporaryColorScheme].shared.card.background : theme.shared.card.background};
  border: 1px solid
    ${({ theme, temporaryColorScheme = undefined }) =>
      temporaryColorScheme ? themes[temporaryColorScheme].shared.card.border.box : theme.shared.card.border.box};
  padding: ${token("space.250")};
  height: calc(100% - 40px);
  cursor: pointer;

  box-shadow:
    rgba(9, 30, 66, 0.25) 0px 1px 3px,
    rgba(9, 30, 66, 0.31) 0px 0px 1px;
  border-radius: ${({ borderRadius }) => borderRadius};

  transition: box-shadow linear 75ms;
  &:hover {
    box-shadow:
      rgba(9, 30, 66, 0.31) 0px 0px 1px 0px,
      rgba(9, 30, 66, 0.25) 0px 8px 12px -3px;
  }
`;

const SpaceIcon = styled.img`
  width: 40px;
  height: 40px;
  box-sizing: border-box;
  border-radius: ${({ isPersonal, borderRadius }) => (isPersonal ? "50%" : borderRadius)};
`;

const ContentWrapper = styled.div`
  margin-top: 20px;
`;

const SpaceName = styled.span`
  font-weight: 600;
  font-size: 13.1299px;
  line-height: 17px;
  color: ${({ theme, temporaryColorScheme = undefined }) =>
    temporaryColorScheme ? themes[temporaryColorScheme].shared.card.title : theme.shared.card.title};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const SpaceCreator = styled.div`
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 11px;
  line-height: 17px;
  color: ${({ theme }) => theme.shared.card.footer};
`;

const CreatorLink = styled.span`
  color: ${({ primaryColor }) => primaryColor};
  cursor: pointer;

  display: flex;
  align-items: center;
`;

const IconContainer = styled.div`
  display: grid;
  place-content: center;

  ${({ isClickable }) => isClickable && `width: 32px; height: 32px; border-radius: 3px;`};

  &:hover {
    ${({ isClickable }) =>
      isClickable && `background-color: ${token("color.background.neutral.subtle.hovered")}; cursor: pointer;`}
  }
`;

const IconAndActionsWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 5px;
`;

const Actions = ({ isFavorite, isWatching, updateWatching }) => {
  const theme = useTheme();

  return (
    <ActionsWrapper>
      <IconContainer style={{ cursor: "default" }} small>
        {isFavorite ? <StarFilledIcon primaryColor={token("color.background.accent.yellow.subtle")} /> : <div />}
      </IconContainer>

      <Tooltip position="bottom" content={isWatching ? "Unwatch" : "Watch"}>
        <IconContainer
          isClickable
          small
          onClick={(e) => {
            e.preventDefault();
            updateWatching(isWatching);
          }}
        >
          {isWatching ? (
            <WatchFilledIcon primaryColor={theme.poll.iconsSelected} />
          ) : (
            <WatchIcon primaryColor={theme.poll.icons} />
          )}
        </IconContainer>
      </Tooltip>
    </ActionsWrapper>
  );
};

function SpaceSimpleCard({
  hasActions,
  displayAuthors = true,
  spaceInfo,
  temporaryColorScheme = undefined,
  isInNavigation = false,
}) {
  const [isError, setIsError] = useState(false);
  const [isLoadingWatchStatus, setIsLoadingWatchStatus] = useState(true);
  const [isLoadingFavoriteStatus, setIsLoadingFavoriteStatus] = useState(true);
  const [isWatching, setIsWatching] = useState(false);
  const [isFavorite, setIsFavorite] = useState(false);

  const { colors, borderRadius } = useCorporateIdentityStore((state) => ({
    colors: state.colors,
    borderRadius: state.borderRadius,
  }));
  const primaryColor = colors ? colors[COLOR_TYPES.PRIMARY] : "#0065ff";

  const isAnonymous = useCurrentUserStore((state) => state.isAnonymous);

  useEffect(() => {
    if (hasActions) {
      setIsLoadingWatchStatus(true);
      setIsLoadingFavoriteStatus(true);
      getCurrentUserSpaceWatchStatus(spaceInfo.key)
        .then((res) => {
          if (res.body) {
            const body = JSON.parse(res.body);
            setIsWatching(body.watching);
          }
        })
        .catch((err) => setIsError(true))
        .finally(() => setIsLoadingWatchStatus(false));

      getCurrentUserSpaceFavoriteStatus(spaceInfo.key)
        .then((res) => {
          if (res.body) {
            const parsed = JSON.parse(res.body);
            if (parsed?.results?.length) {
              setIsFavorite(true);
            } else {
              setIsFavorite(false);
            }
          }
        })
        .catch((err) => setIsError(true))
        .finally(() => setIsLoadingFavoriteStatus(false));
    }
  }, [spaceInfo.key]);

  const removeWatcher = () => {
    setIsWatching(false);
    removeCurrentUserAsSpaceWatcher(spaceInfo.key)
      .then((res) => {
        if (!res?.data?.success) {
          setIsWatching(true);
        }
      })
      .catch((e) => setIsError(true));
  };

  const addWatcher = () => {
    setIsWatching(true);
    addCurrentUserAsSpaceWatcher(spaceInfo.key)
      .then((res) => {
        if (!res?.data?.success) {
          setIsWatching(false);
        }
      })
      .catch((e) => setIsError(true));
  };

  return (
    <a href={spaceInfo.url} style={{ textDecoration: "none" }} rel="noreferrer" target="_blank">
      <SimpleCardWrapper
        temporaryColorScheme={temporaryColorScheme}
        borderRadius={isInNavigation ? "8px" : borderRadius}
        className="simple-space-card"
      >
        <IconAndActionsWrapper>
          <SpaceIcon src={spaceInfo.icon} isPersonal={spaceInfo.type === "personal"} borderRadius={borderRadius} />
          {hasActions && !isLoadingWatchStatus && !isLoadingFavoriteStatus && !isError && (
            <Actions
              isFavorite={isFavorite}
              isWatching={isWatching}
              updateWatching={(watching) => {
                watching ? removeWatcher() : addWatcher();
              }}
            />
          )}
        </IconAndActionsWrapper>

        <ContentWrapper>
          <SpaceName
            temporaryColorScheme={temporaryColorScheme}
            className="simple-space-card__title"
            isInNavigation={isInNavigation}
          >
            {spaceInfo.name}
          </SpaceName>

          {!isAnonymous && displayAuthors && (
            <SpaceCreator color={primaryColor} className="simple-space-card__creator">
              by&nbsp;
              <CreatorLink
                primaryColor={primaryColor}
                onClick={(e) => {
                  e.preventDefault();
                  window.open(createProfileLink(spaceInfo.creatorId));
                }}
              >
                {spaceInfo.createdBy}
              </CreatorLink>
            </SpaceCreator>
          )}
        </ContentWrapper>
      </SimpleCardWrapper>
    </a>
  );
}

export default SpaceSimpleCard;
