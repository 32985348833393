import React from "react";

export const HeaderIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
    <rect x="2" y="2" width="16" height="4.8" rx="1" fill="#4C9AFF" />
    <rect x="2" y="7.6001" width="4.8" height="4.8" rx="1" fill="#B3BAC5" />
    <rect x="2" y="13.2" width="4.8" height="4.8" rx="1" fill="#B3BAC5" />
    <rect x="7.59961" y="7.6001" width="4.8" height="4.8" rx="1" fill="#B3BAC5" />
    <rect x="7.59961" y="13.2" width="4.8" height="4.8" rx="1" fill="#B3BAC5" />
    <rect x="13.2002" y="7.6001" width="4.8" height="4.8" rx="1" fill="#B3BAC5" />
    <rect x="13.2002" y="13.2" width="4.8" height="4.8" rx="1" fill="#B3BAC5" />
  </svg>
);
