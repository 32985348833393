import styled from "styled-components";
import merge from "lodash.merge";

export const checkboxSelectStyles = {
  control: (provided) => ({
    ...provided,
    fontSize: "14px",
    width: "100%",
    height: "30px",
    marginTop: "10px",
    boxShadow: "0 0 0 1px hsla(218, 50%, 10%, 0.1), 0 4px 11px hsla(218, 50%, 10%, 0.1)",
    borderRadius: 4,
  }),
  container: (provided) => ({
    ...provided,
    position: "absolute",
    zIndex: 10,
  }),
  selectContainer: (provided) => ({ ...provided, fontSize: "14px" }),
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 900,
    position: "absolute",
    left: "0px",
    top: "20px",
  }),
  menu: (provided) => ({ ...provided, zIndex: 900 }),
  option: (provided) => ({ ...provided, fontSize: "14px" }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: "transparent",
  }),
  placeholder: (provided) => ({ ...provided, fontWeight: 500 }),
  multiValueRemove: (provided) => ({ ...provided, display: "none" }),
  input: (provided) => ({ ...provided, fontSize: "14px" }),
  valueContainer: (provided) => ({
    ...provided,
    flexWrap: "nowrap",
    overflow: "hidden",
    fontWeight: 600,
  }),
};

export const selectStylesWithOverrides = (overrides) => {
  return merge(checkboxSelectStyles, overrides);
};

export const labelsCheckboxSelectStyles = {
  control: (provided) => ({
    ...provided,
    fontSize: "14px",
    width: "100%",
    height: "30px",
    marginTop: "10px",
    boxShadow: "0 0 0 1px hsla(218, 50%, 10%, 0.1), 0 4px 11px hsla(218, 50%, 10%, 0.1)",
    borderRadius: 4,
  }),
  container: (provided) => ({
    ...provided,
    position: "absolute",
    zIndex: 10,
  }),
  selectContainer: (provided) => ({ ...provided, fontSize: "14px" }),
  menuPortal: (provided) => ({ ...provided, zIndex: 900, position: "absolute", left: "0px", top: "20px" }),
  menu: (provided) => ({ ...provided, zIndex: 900 }),
  option: (provided) => ({ ...provided, fontSize: "14px" }),
  placeholder: (provided) => ({ ...provided, fontWeight: 500 }),
  input: (provided) => ({ ...provided, fontSize: "14px" }),
  valueContainer: (provided) => ({
    ...provided,
    flexWrap: "nowrap",
    overflow: "hidden",
    fontWeight: 600,
  }),
};

export const SelectWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 250px;
  align-items: center;
  justify-content: space-between;
  column-gap: 50px;

  .select-search__value-container {
    div:first-child {
      width: auto !important;
    }
  }
`;

export const SelectLabel = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #6b778c;
  margin-bottom: 5px;
  white-space: nowrap;
`;
