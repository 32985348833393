import React from "react";
import { useSidebarStore } from "../../../../Dashboard/panels/edit/sidebar/sidebar-store";
import { PanelPositioning } from "../../../../Dashboard/panels/panelTypes";
import { FieldContainer } from "../../../../Dashboard/filter/styled";
import { LabeledTitle } from "../../../../PeopleBase/styled/sidebar";
import SimpleToggle from "../../../../Dashboard/filter/SimpleToggle";
import { PeoplePropertyKeys } from "./configurator/propertyTypes";
import SelectFilter from "../../../../Dashboard/filter/SelectFilter";
import SizeButtons from "../../../../../../../Shared/Components/SizeButtons";
import { StylingOptions } from "./configurator/options";
import { sizeType } from "../../../../Dashboard/panels/edit/sidebar/sizes";
import { Divider } from "../../../../Dashboard/filter/styled";
const Design = ({ panelId, panelType, data, updatePanelDataProperty }) => {
  const isInMainContent = useSidebarStore((state) => state.selectedContentType === PanelPositioning.MAIN);

  return (
    <div>
      <FieldContainer>
        <LabeledTitle>Widget options</LabeledTitle>
        <SimpleToggle
          label="Display title"
          value={data.displayTitle}
          setValue={(value) => updatePanelDataProperty(panelId, PeoplePropertyKeys.DISPLAY_TITLE, value)}
          customStyles={{ padding: "10px 0px 8px 10px" }}
        />
        <SimpleToggle
          label="Display box"
          value={data.displayBox}
          setValue={(value) => updatePanelDataProperty(panelId, PeoplePropertyKeys.DISPLAY_BOX, value)}
          customStyles={{ padding: "10px 0px 8px 10px" }}
        />
      </FieldContainer>

      {isInMainContent && <Divider />}

      {data.view === PeoplePropertyKeys.CARD && isInMainContent && (
        <>
          <SelectFilter
            label="Styling"
            value={data.backgroundType}
            setValue={(v) => updatePanelDataProperty(panelId, PeoplePropertyKeys.BACKGROUND_TYPE, v, true)}
            options={StylingOptions}
          />
          <Divider />
          <SizeButtons
            label="Profile image size"
            types={{ panel: panelType, size: sizeType.IMAGE_SIZE }}
            input={{ min: 60, max: 100, step: 1, value: data.imageSize }}
            setValue={(value) => updatePanelDataProperty(panelId, PeoplePropertyKeys.IMAGE_SIZE, value, true)}
          />
        </>
      )}
    </div>
  );
};

export default Design;
