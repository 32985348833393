import React, { useMemo } from "react";
import styled from "styled-components";
import Spinner from "@atlaskit/spinner";
import WarningIcon from "@atlaskit/icon/glyph/warning";
import { useQuery } from "react-query";
import { fetchUserDatabaseFields } from "../../../../../../UserDatabase/datagrid/api";
import { SpinnerContainer } from "../../../../../styled/pages";
import TextInputFilter from "../../../filter/TextInputFilter";
import SelectFilter from "../../../filter/SelectFilter";
import { TabTypes } from "../../tabTypes";
import { FieldContainer, Label } from "../../../filter/styled";
import { DesignSidebarWrapper, PanelEditorWrapper, WarningPanel } from "./styled";
import { useSidebarStore } from "../sidebar/sidebar-store";
import SizeButtons from "../../../../../../../Shared/Components/SizeButtons";
import { sizeType } from "../sidebar/sizes";
import { PanelPositioning } from "../../panelTypes";
import useSavedCollections from "../../../../../../../Shared/Hooks/useSavedCollections";
import InputFilter from "../../../filter/InputFilter";
import SelectWithCustomOptions from "../../../filter/SelectWithCustomOptions";
import { PaginationOptions } from "../../../../Shared/PanelsPagination/pagination-options";
import { WidgetIds } from "../../../../Settings/Widgets/consts/tabs";
import { getWidgetSettingById } from "../../../../Settings/Widgets/api";
import { Pages } from "../../../../../pages";
import { SectionKeys, SectionPagesIDs } from "../../../../Settings/sections";
import useUserGroups from "../../../../../../../Shared/Hooks/useUserGroups";
import { Divider } from "../../../filter/styled";
import SimpleToggle from "../../../filter/SimpleToggle";
import { ViewOptions } from "../../../../Shared/Panels/edit/people/configurator/options";

export const UsersPropertyKeys = {
  FIELD_ID: "fieldId",
  FOOTER_FIELD_ID: "footerFieldId",
  TITLE_FIELD_ID: "titleFieldId",
  BG_TYPE: "backgroundType",
  ROUNDING: "rounding",
  IMAGE_SIZE: "imageSize",
  TITLE_ENALBED: "titleFieldOn",
  FOOTER_ENABLED: "footerFieldOn",
  COLLECTION: "collection",
  NUMBER_ITEMS: "maxNumberOfItems",
  PAGINATION: "pagination",
  DISPLAY_TITLE: "displayTitle",
  DISPLAY_BOX: "displayBox",
  VIEW: "view",
};

const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 25px;

  & > div:first-child {
    flex: 1;
  }
`;

export const InfoIconDark = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none">
    <circle cx="24" cy="24" r="19" fill="#082145" />
    <path
      d="M24 32C24.4533 32 24.8336 31.8464 25.1408 31.5392C25.4469 31.2331 25.6 30.8533 25.6 30.4V23.96C25.6 23.5067 25.4469 23.1333 25.1408 22.84C24.8336 22.5467 24.4533 22.4 24 22.4C23.5467 22.4 23.1669 22.5531 22.8608 22.8592C22.5536 23.1664 22.4 23.5467 22.4 24V30.44C22.4 30.8933 22.5536 31.2667 22.8608 31.56C23.1669 31.8533 23.5467 32 24 32ZM24 19.2C24.4533 19.2 24.8336 19.0464 25.1408 18.7392C25.4469 18.4331 25.6 18.0533 25.6 17.6C25.6 17.1467 25.4469 16.7664 25.1408 16.4592C24.8336 16.1531 24.4533 16 24 16C23.5467 16 23.1669 16.1531 22.8608 16.4592C22.5536 16.7664 22.4 17.1467 22.4 17.6C22.4 18.0533 22.5536 18.4331 22.8608 18.7392C23.1669 19.0464 23.5467 19.2 24 19.2ZM24 40C21.7867 40 19.7067 39.5797 17.76 38.7392C15.8133 37.8997 14.12 36.76 12.68 35.32C11.24 33.88 10.1003 32.1867 9.2608 30.24C8.42027 28.2933 8 26.2133 8 24C8 21.7867 8.42027 19.7067 9.2608 17.76C10.1003 15.8133 11.24 14.12 12.68 12.68C14.12 11.24 15.8133 10.0997 17.76 9.2592C19.7067 8.41973 21.7867 8 24 8C26.2133 8 28.2933 8.41973 30.24 9.2592C32.1867 10.0997 33.88 11.24 35.32 12.68C36.76 14.12 37.8997 15.8133 38.7392 17.76C39.5797 19.7067 40 21.7867 40 24C40 26.2133 39.5797 28.2933 38.7392 30.24C37.8997 32.1867 36.76 33.88 35.32 35.32C33.88 36.76 32.1867 37.8997 30.24 38.7392C28.2933 39.5797 26.2133 40 24 40Z"
      fill="#85B8FF"
    />
  </svg>
);

const LayoutSidebar = ({
  panelTitle,
  panelId,
  data,
  fieldsData,
  updatePanelTitle,
  updatePanelDataProperty,
  isPositionedInMainContent,
}) => {
  const { data: widgetSettingData } = useQuery(
    ["new-starters-global-setting", { widgetId: WidgetIds.NEW_STARTERS }],
    getWidgetSettingById,
    {
      retry: 0,
      select: (response) => {
        const { data } = response;
        return data;
      },
    },
  );

  const hasSelectedField = useMemo(
    () => !!widgetSettingData?.exists && widgetSettingData?.widget?.selectedField,
    [widgetSettingData],
  );

  return (
    <>
      {!hasSelectedField && (
        <WarningPanel style={{ marginTop: "20px" }}>
          <WarningIcon size="large" primaryColor="#ff991f" />
          <span>
            To activate the widget, please ensure you select the date field from the employee database in{" "}
            <a
              href={`${window.AP._hostOrigin}/wiki/plugins/servlet/ac/com.caelor.confluence.cloud.cosmos/caelor-cosmos-portal?ac.cosmosPage=${Pages.SETTINGS}-${SectionKeys.PEOPLE_BASE}-${SectionPagesIDs.WIDGETS}&ac.widgetTab=${WidgetIds.NEW_STARTERS}`}
              target="_parent"
              alt=""
            >
              Cosmos settings.{" "}
            </a>
            Please note that currently, the widget is displaying dummy data.
          </span>
        </WarningPanel>
      )}
      <TextInputFilter label="Title" value={panelTitle} onUpdate={(value) => updatePanelTitle(panelId, value)} />
      <Divider />
      {isPositionedInMainContent && (
        <>
          <FieldContainer>
            <SelectWithCustomOptions
              initialValue={data?.view ?? "card"}
              options={ViewOptions}
              label="View"
              onUpdate={(newValue) => updatePanelDataProperty(panelId, UsersPropertyKeys.VIEW, newValue)}
            />
          </FieldContainer>

          <Divider />
        </>
      )}

      <SelectWithCustomOptions
        initialValue={data.pagination}
        options={PaginationOptions}
        label="Pagination"
        onUpdate={(newValue) => updatePanelDataProperty(panelId, UsersPropertyKeys.PAGINATION, newValue)}
      />

      <Divider />

      <SelectWithCustomOptions
        initialValue={data?.titleFieldId}
        options={[...fieldsData.singleValueFields].map((field) => ({
          label: field.title,
          value: field.id,
        }))}
        isDisabled={!hasSelectedField}
        label="Fields to display"
        onUpdate={(newValue) => updatePanelDataProperty(panelId, UsersPropertyKeys.TITLE_FIELD_ID, newValue, true)}
      />

      <SelectWithCustomOptions
        initialValue={data?.footerFieldId}
        options={[...fieldsData.dateFields].map((field) => ({
          label: field.title,
          value: field.id,
        }))}
        isDisabled={!hasSelectedField}
        onUpdate={(newValue) => updatePanelDataProperty(panelId, UsersPropertyKeys.FOOTER_FIELD_ID, newValue, true)}
      />

      {!isPositionedInMainContent && (
        <InputFilter
          numberField
          minLength={1}
          maxLength={5}
          initialValue={data.maxNumberOfItems}
          label="Items per page"
          placeholder="Limit the number of pages"
          onUpdate={(value) => updatePanelDataProperty(panelId, UsersPropertyKeys.NUMBER_ITEMS, value)}
        />
      )}
    </>
  );
};

const DesignSidebar = ({ panelId, panelType, data, updatePanelDataProperty }) => {
  const isInMainContent = useSidebarStore((state) => state.selectedContentType === PanelPositioning.MAIN);

  return (
    <DesignSidebarWrapper>
      <div>
        {isInMainContent && (
          <>
            <FieldContainer>
              <Label>Widget options</Label>
              <SimpleToggle
                label="Display title"
                value={data?.displayTitle ?? true}
                setValue={(value) => updatePanelDataProperty(panelId, UsersPropertyKeys.DISPLAY_TITLE, value)}
                customStyles={{ padding: "10px 0px 8px 10px" }}
              />
              <SimpleToggle
                label="Display box"
                value={data?.displayBox ?? true}
                setValue={(value) => updatePanelDataProperty(panelId, UsersPropertyKeys.DISPLAY_BOX, value)}
                customStyles={{ padding: "10px 0px 8px 10px" }}
              />
              <Divider />
            </FieldContainer>
            <SelectFilter
              label="Styling"
              value={data.backgroundType}
              setValue={(v) => updatePanelDataProperty(panelId, UsersPropertyKeys.BG_TYPE, v, true)}
              options={[
                { label: "None", value: "none" },
                { label: "Profile header image", value: "image" },
              ]}
            />
            <Divider />
            <SizeButtons
              label="Profile image size"
              types={{ panel: panelType, size: sizeType.IMAGE_SIZE }}
              input={{ min: 60, max: 100, step: 1, value: data.imageSize }}
              setValue={(value) => updatePanelDataProperty(panelId, UsersPropertyKeys.IMAGE_SIZE, value, true)}
            />
          </>
        )}
      </div>
    </DesignSidebarWrapper>
  );
};

const ContentSidebar = ({ panelId, data, updatePanelDataProperty, collectionsData, collectionsLoading }) => {
  const { loadingUserGroups, userGroups } = useUserGroups();
  const { collections } = collectionsData || { collections: [] };

  return (
    <div>
      <SelectFilter
        label="Group"
        placeholder="Filter by group.."
        value={data?.userGroup}
        isClearable
        setValue={(newValue) => updatePanelDataProperty(panelId, "userGroup", newValue)}
        options={userGroups}
        isLoading={loadingUserGroups}
      />

      <SelectFilter
        label="Collections"
        value={data?.collection}
        setValue={(newValue) => updatePanelDataProperty(panelId, UsersPropertyKeys.COLLECTION, newValue, true)}
        options={collections.map((collection) => ({ label: collection.name, value: collection.id }))}
        isClearable
        isLoading={collectionsLoading}
      />
    </div>
  );
};

function NewUsersEditor({ panel, selectedTab, isPositionedInMainContent, updatePanelDataProperty, updatePanelTitle }) {
  const { data, type } = panel;

  const { isLoading: fieldsLoading, data: fieldsData } = useQuery(
    ["userdatabase-fields", { includeEmail: false }],
    fetchUserDatabaseFields,
    {
      retry: 0,
      select: (response) => {
        const { data } = response;

        const dateFields = data?.filter((field) => field.type === "DATE") || [];
        const singleValueFields = data?.filter((field) => field.type === "SELECT" || field.type === "TEXT");

        return { dateFields, singleValueFields };
      },
    },
  );

  const { collectionsLoading, collectionsData } = useSavedCollections({ areOptions: true });

  return (
    <PanelEditorWrapper>
      {fieldsLoading && (
        <SpinnerContainer>
          <Spinner size="medium" />
        </SpinnerContainer>
      )}
      {!fieldsLoading && (
        <>
          {(selectedTab === TabTypes.LAYOUT || !isPositionedInMainContent) && (
            <LayoutSidebar
              panelTitle={panel.panelTitle}
              panelId={panel.id}
              data={data}
              fieldsData={fieldsData}
              updatePanelTitle={updatePanelTitle}
              updatePanelDataProperty={updatePanelDataProperty}
              isPositionedInMainContent={isPositionedInMainContent}
            />
          )}

          {selectedTab === TabTypes.CONTENT && (
            <ContentSidebar
              panelId={panel.id}
              data={data}
              updatePanelDataProperty={updatePanelDataProperty}
              panelType={type}
              collectionsData={collectionsData}
              collectionsLoading={collectionsLoading}
            />
          )}

          {selectedTab === TabTypes.DESIGN && (
            <DesignSidebar
              panelId={panel.id}
              data={data}
              updatePanelDataProperty={updatePanelDataProperty}
              panelType={type}
            />
          )}
        </>
      )}
    </PanelEditorWrapper>
  );
}

export default NewUsersEditor;
