import React from "react";
import Spinner from "@atlaskit/spinner";
import styled from "styled-components";

const Content = styled.div`
  display: grid;
  place-content: center;
  margin-top: 75px;
  height: calc(100% - 75px);
`;

function LoadingScreen() {
  return (
    <Content>
      <Spinner size="xlarge" />
    </Content>
  );
}

export default LoadingScreen;
