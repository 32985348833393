import React, { Fragment, useMemo, useState } from "react";
import { handleClickOnEntryLink, handleClickOnChildEntry } from "../cardLayoutService";
import { ChildEntry, ChildMenuWrapper, MenuContentWrapper, MenuDescriptionWrapper } from "./styled";
import { useTheme } from "styled-components";
import { useQuery } from "react-query";
import NewsNavigationCard from "../NewsNavigationCard";
import { NewsContainer } from "../Sydney/styled";
import NewsEmptyState from "../NewsEmptyState";
import { dark } from "../../../../../dark-colors";
import { light } from "../../../../../light-colors";
import { LoadingTypes } from "../../../../Hub/Components/Dashboard/filter/DynamicFilterToggle";
import { searchBlogposts } from "../../../../Hub/Components/Dashboard/api";
import { mapBlogpost } from "../../../../Hub/Components/Dashboard/panels/types/news/handleBlogData";
import {
  DEFAULT_LONDON_COLORS,
  DEFAULT_LONDON_COLORS_DARK,
  THEME_NAME_DARK,
  THEME_NAME_LIGHT,
} from "../../../functions/generateNewEntry";
import Pagination from "../../../../Hub/Components/Shared/PanelsPagination/Pagination";
import { PanelsPaginationTypes } from "../../../../Hub/Components/Shared/PanelsPagination/panels-pagination-types";
import EntryIcon from "../../../configuration/drawer/components/edit/shared/EntryIcon";

const themes = {
  dark,
  light,
};

function London({ structure, selectedRootEntry, isPreview, openOverContent, themeColorsSet, temporaryColorScheme }) {
  const rootEntry = structure?.find(({ id }) => id === selectedRootEntry);

  const [currentPage, setCurrentPage] = useState(1);

  const [isNewsEnabled, newsConfig] = useMemo(
    () => [!!rootEntry?.data?.isNewsEnabled, rootEntry?.data?.news || {}],
    [rootEntry],
  );

  const theme = useTheme();

  const { isLoading, data: blogsData } = useQuery(
    [
      `blogposts-navigation`,
      {
        loadingType: newsConfig?.loadingType,
        manullyPicked: newsConfig?.manuallySelected,
        labels: newsConfig?.labels || [],
        contributors: newsConfig?.contributors || [],
        spaces: newsConfig?.spaces || [],
        limit: newsConfig?.loadingType === LoadingTypes.MANUAL ? newsConfig?.manuallySelected?.length || 0 : 20,
      },
    ],
    searchBlogposts,
    {
      enabled: isNewsEnabled,
      retry: 0,
      select: (response) => {
        const body = JSON.parse(response.body);
        const results = body.results.map((blog) => mapBlogpost(blog));
        return results;
      },
    },
  );

  const hasNewsFilters = !!newsConfig?.labels?.length || newsConfig?.contributors || newsConfig?.spaces;

  const colors = useMemo(() => {
    if (!rootEntry) return {};
    if (themeColorsSet && theme.global.name === THEME_NAME_LIGHT) {
      return DEFAULT_LONDON_COLORS;
    } else if (themeColorsSet && theme.global.name === THEME_NAME_DARK) {
      return DEFAULT_LONDON_COLORS_DARK;
    } else {
      return rootEntry?.data?.colors;
    }
  }, [theme, rootEntry, structure, themeColorsSet]);

  if (!rootEntry) return <></>;

  return (
    <MenuContentWrapper
      backgroundColor={themes[temporaryColorScheme]?.navigation.london.menuContentBackground}
      openOverContent={openOverContent}
    >
      {(rootEntry?.data?.isLinkOn || (rootEntry?.data?.isLinkOn === undefined && rootEntry?.data?.link?.link)) && (
        <MenuDescriptionWrapper
          descriptionTextColor={
            colors?.[temporaryColorScheme]?.descriptionTextColor ||
            themes[temporaryColorScheme]?.navigation.london.menuContentTitle
          }
        >
          <div className="menu-description-title">{rootEntry?.data.name}</div>
          <span className="description">{rootEntry?.data.description}</span>
          <div className="menu-description-border" />
          {rootEntry?.data?.isLinkOn &&
            rootEntry?.data?.link &&
            !!rootEntry?.data?.link?.length &&
            rootEntry?.data?.link?.map((link) => (
              <div
                key={link.id}
                className="menu-description-link"
                onClick={() => {
                  if (!isPreview) {
                    handleClickOnEntryLink(link);
                  }
                }}
              >
                {link.linkName && <span className="link-name">{link.linkName}</span>}
              </div>
            ))}
        </MenuDescriptionWrapper>
      )}

      <ChildMenuWrapper
        isPreview={isPreview}
        numberOfColumns={structure.filter((entry) => entry.parent === selectedRootEntry).length}
      >
        {structure
          .filter((entry) => entry.parent === selectedRootEntry)
          .map((entry) => (
            <ChildEntry
              key={entry.id}
              subentryIconColor={colors?.[temporaryColorScheme]?.subentryIconColor || "#42526e"}
              subentryTextColor={
                colors?.[temporaryColorScheme]?.subentryTextColor === undefined ||
                colors?.[temporaryColorScheme]?.subentryTextColor === "#42526e"
                  ? themes[temporaryColorScheme]?.navigation.london.menuContentTitle
                  : colors?.[temporaryColorScheme]?.subentryTextColor
              }
              subentryBackgroundHoverColor={
                colors?.[temporaryColorScheme]?.subentryBackgroundHoverColor === undefined ||
                colors?.[temporaryColorScheme]?.subentryBackgroundHoverColor === "0000000"
                  ? themes[temporaryColorScheme]?.navigation.london.menuContentEntryHoverBackground
                  : colors?.[temporaryColorScheme]?.subentryBackgroundHoverColor
              }
              subentryTextIconHover={colors?.subentryTextIconHover}
              backgroundColor={{
                hover: themes[temporaryColorScheme]?.navigation.london.menuContentEntryHoverBackground,
              }}
              onClick={() => handleClickOnChildEntry(entry, isPreview)}
            >
              {rootEntry.data.displayIcons && (
                <div style={{ width: "24px", height: "24px" }}>
                  {(entry.data.icon?.type === "icon" || !entry.data.icon?.type) && <EntryIcon icon={entry.data.icon} />}
                  {entry.data.icon?.type === "app" && <img src={entry.data.icon.icon} alt="" width={24} height={24} />}
                  {entry.data.icon?.type === "emoji" && (
                    <div style={{ width: "20px", height: "20px" }}>
                      <em-emoji shortcodes={entry?.data?.icon?.shortcodes} size="1.2em"></em-emoji>
                    </div>
                  )}
                </div>
              )}

              <div className="child-name">{entry.data.name}</div>
              <div className="child-description">{entry.data.description}</div>
            </ChildEntry>
          ))}
      </ChildMenuWrapper>

      <NewsContainer>
        {isNewsEnabled && (
          <>
            {!isLoading && !!blogsData?.length ? (
              blogsData.slice(currentPage - 1, currentPage).map((blog) => (
                <Fragment key={blog.id}>
                  <NewsNavigationCard temporaryColorScheme={temporaryColorScheme} key={blog.id} data={blog} />
                  {!!(blogsData?.length > 1) && (
                    <Pagination
                      type={PanelsPaginationTypes.ARROWS}
                      page={currentPage}
                      maxPage={blogsData.length}
                      updatePage={(newPage) => setCurrentPage(newPage)}
                      customWrapperStyles={{ placeContent: "flex-start", marginTop: "12px", marginLeft: "-5px" }}
                      customPrimaryColor={"#0065ff"}
                    />
                  )}
                </Fragment>
              ))
            ) : (
              <NewsEmptyState
                hasNewsFilters={hasNewsFilters}
                newsConfig={newsConfig}
                temporaryColorScheme={temporaryColorScheme}
                isInPreview={isPreview}
              />
            )}
          </>
        )}
      </NewsContainer>
    </MenuContentWrapper>
  );
}

export default London;
