export const MagazineIcon = ({ color }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M4 18H20.008C20 18 20 6 20 6H3.992C4 6 4 18 4 18ZM2 5.994C2 4.893 2.898 4 3.99 4H20.01C21.108 4 22 4.895 22 5.994V18.006C21.9992 18.5339 21.7894 19.04 21.4165 19.4137C21.0436 19.7873 20.5379 19.9981 20.01 20H3.99C3.46185 19.9989 2.9557 19.7884 2.58261 19.4146C2.20953 19.0407 2 18.5342 2 18.006V5.994Z"
      fill={color}
    />
    <path d="M7 5V19H9V5H7Z" fill={color} />
    <path d="M7 11H21V9H7V11Z" fill={color} />
  </svg>
);

export const GridIcon = ({ color }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M10 3H4C3.44772 3 3 3.44772 3 4V10C3 10.5523 3.44772 11 4 11H10C10.5523 11 11 10.5523 11 10V4C11 3.44772 10.5523 3 10 3Z"
      fill={color}
    />
    <path
      d="M10 13H4C3.44772 13 3 13.4477 3 14V20C3 20.5523 3.44772 21 4 21H10C10.5523 21 11 20.5523 11 20V14C11 13.4477 10.5523 13 10 13Z"
      fill={color}
    />
    <path
      d="M20 3H14C13.4477 3 13 3.44772 13 4V10C13 10.5523 13.4477 11 14 11H20C20.5523 11 21 10.5523 21 10V4C21 3.44772 20.5523 3 20 3Z"
      fill={color}
    />
    <path
      d="M20 13H14C13.4477 13 13 13.4477 13 14V20C13 20.5523 13.4477 21 14 21H20C20.5523 21 21 20.5523 21 20V14C21 13.4477 20.5523 13 20 13Z"
      fill={color}
    />
  </svg>
);

export const DepartmentIcon = ({ color }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <g id="department-icon">
      <path
        id="Vector"
        d="M11 5V7H13V5H11ZM11 11V9H10C9.73478 9 9.48043 8.89464 9.29289 8.70711C9.10536 8.51957 9 8.26522 9 8V4C9 3.73478 9.10536 3.48043 9.29289 3.29289C9.48043 3.10536 9.73478 3 10 3H14C14.2652 3 14.5196 3.10536 14.7071 3.29289C14.8946 3.48043 15 3.73478 15 4V8C15 8.26522 14.8946 8.51957 14.7071 8.70711C14.5196 8.89464 14.2652 9 14 9H13V11H18C18.2652 11 18.5196 11.1054 18.7071 11.2929C18.8946 11.4804 19 11.7348 19 12V15H20C20.2652 15 20.5196 15.1054 20.7071 15.2929C20.8946 15.4804 21 15.7348 21 16V20C21 20.2652 20.8946 20.5196 20.7071 20.7071C20.5196 20.8946 20.2652 21 20 21H16C15.7348 21 15.4804 20.8946 15.2929 20.7071C15.1054 20.5196 15 20.2652 15 20V16C15 15.7348 15.1054 15.4804 15.2929 15.2929C15.4804 15.1054 15.7348 15 16 15H17V13H7V15H8C8.26522 15 8.51957 15.1054 8.70711 15.2929C8.89464 15.4804 9 15.7348 9 16V20C9 20.2652 8.89464 20.5196 8.70711 20.7071C8.51957 20.8946 8.26522 21 8 21H4C3.73478 21 3.48043 20.8946 3.29289 20.7071C3.10536 20.5196 3 20.2652 3 20V16C3 15.7348 3.10536 15.4804 3.29289 15.2929C3.48043 15.1054 3.73478 15 4 15H5V12C5 11.7348 5.10536 11.4804 5.29289 11.2929C5.48043 11.1054 5.73478 11 6 11H11ZM5 17V19H7V17H5ZM17 17V19H19V17H17Z"
        fill={color}
      />
    </g>
  </svg>
);

export const ListIcon = ({ color }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M3.00002 18C3.00002 18.552 3.44502 19 3.99302 19H20.007C20.138 18.9999 20.2676 18.9739 20.3885 18.9235C20.5094 18.8731 20.6191 18.7993 20.7114 18.7064C20.8036 18.6135 20.8766 18.5032 20.9262 18.382C20.9757 18.2608 21.0008 18.131 21 18V17H3.00002V18ZM3.00002 11H21V9H3.00002V11ZM3.00002 7H21V6C21 5.448 20.555 5 20.007 5H3.99302C3.86206 5.00013 3.73242 5.02613 3.61155 5.07652C3.49067 5.12691 3.38095 5.20068 3.28867 5.29361C3.1964 5.38653 3.12339 5.49678 3.07386 5.618C3.02432 5.73923 2.99923 5.86905 3.00002 6V7ZM3.00002 15H21V13H3.00002V15Z"
      fill={color}
    />
  </svg>
);
