import { useEffect, useState } from "react";
import { PanelPositioning } from "../../../../Dashboard/panels/panelTypes";
import { useCorporateIdentityStore } from "../../../../Settings/General/BrandAndColors/corporate-identity-store";
import { COLOR_TYPES } from "../../../../Settings/General/BrandAndColors/color-types";
import { LoadingTypes } from "../../../../Dashboard/filter/DynamicFilterToggle";
import useSavedCollections from "../../../../../../../Shared/Hooks/useSavedCollections";
import { fetchRecords, fetchUserDatabaseFields } from "../../../../../../UserDatabase/datagrid/api";
import { useQuery } from "react-query";
import { fetchDetailsForUsersIndividually } from "../../../../Dashboard/api";
import {
  IMAGE_TYPES,
  getCssForBackground,
  getRandomUserBackground,
} from "../../../../../../../Shared/Functions/user-bg-provider";
import { PanelsPaginationTypes } from "../../../PanelsPagination/panels-pagination-types";
import MainView from "./components/MainView";
import SideView from "./components/SideView";
import { getUserValuesFromTwoFields } from "../../shared/utils/user-values-extractor";
import { usePanelsStore } from "../../../../Dashboard/store/panels-store";

const panelComponents = {
  [PanelPositioning.MAIN]: MainView,
  [PanelPositioning.SIDE]: SideView,
};

export const messageByLoadingType = {
  dynamic: "Add, remove or adjust filters in the panel configuration.",
  manual: "Select people in the panel configuration.",
};

function People({ panelTitle, data, position }) {
  const [people, setPeople] = useState([]);
  const [loading, setLoading] = useState(false);
  const [savedData, setSavedData] = useState(data);
  const [page, setPage] = useState(1);

  const borderRadius = useCorporateIdentityStore((state) => state.borderRadius);
  const colors = useCorporateIdentityStore((state) => state.colors);
  const hasInitDatabase = usePanelsStore((state) => state.hasInitEmployeeDatabase);
  const primaryColor = colors ? colors[COLOR_TYPES.PRIMARY] : "#0065ff";

  const isDynamicallyPicked = data.loadingType === LoadingTypes.DYNAMIC;

  const maxNumberOfItems = data.maxNumberOfItems || 4;
  const indexOfLast = page * maxNumberOfItems;
  const indexOfFirst = indexOfLast - maxNumberOfItems;

  const { collectionsData } = useSavedCollections({
    collectionId: data?.collection,
    areOptions: false,
  });

  const appendCollectionsDataFilters = isDynamicallyPicked ? collectionsData?.filters || [] : [];
  const appendConfluenceGroup = isDynamicallyPicked ? data?.userGroup || collectionsData?.userGroup : "";

  const { isLoading: recordsLoading, data: recordsData } = useQuery(
    [
      "userdatabase-records",
      {
        filters: [
          ...appendCollectionsDataFilters,
          { isMultiUser: true, type: "BOOLEAN", value: true, column: "hidden", condition: "NOT" },
        ],
        confluenceGroup: appendConfluenceGroup,
      },
    ],
    fetchRecords,
    {
      retry: 0,
      select: (response) => {
        const { data: responseData } = response;

        const manuallyPickedUsers = responseData?.hits.filter((user) =>
          data.manuallyPicked.some((manuallyPickedUser) => user.accountId === manuallyPickedUser.accountId),
        );

        return isDynamicallyPicked ? responseData?.hits : manuallyPickedUsers;
      },
    },
  );

  const { isLoading: fieldsLoading, data: fieldsData } = useQuery(
    ["userdatabase-fields", { includeEmail: false }],
    fetchUserDatabaseFields,
    {
      retry: 0,
      select: (response) => {
        const { data } = response;
        const matchingFieldsByType = data?.filter(
          (field) => field?.type === "SELECT" || field?.type === "TEXT" || field?.type === "DATE",
        );

        return matchingFieldsByType || [];
      },
    },
  );

  const createProfileLink = (accountId) =>
    `${window.AP._hostOrigin}/wiki/plugins/servlet/ac/com.caelor.confluence.cloud.cosmos/caelor-cosmos-portal?ac.cosmosPage=profile&ac.accountId=${accountId}`;

  const getDetailsForUsers = (users) => {
    if (users?.length && !!fieldsData) {
      setLoading(true);
      fetchDetailsForUsersIndividually(users.map((u) => u.accountId))
        .then((resUsers) => {
          if (resUsers?.length) {
            const formattedUsers = resUsers.map((user) => {
              const userValues = users.find((r) => r.accountId === user.accountId)?.values;

              const backgroundImage = userValues?.user?.backgroundImage;
              const hasBgData = !!backgroundImage && !!Object.keys(backgroundImage || {}).length;
              const correctImage = hasBgData ? backgroundImage : getRandomUserBackground(IMAGE_TYPES.GRADIENT);

              const {
                firstField: titleValue,
                secondField: footerValue,
                footerType,
              } = getUserValuesFromTwoFields(userValues, fieldsData, savedData.titleFieldId, savedData.footerFieldId);

              const image = getCssForBackground(correctImage);

              return {
                accountId: user.accountId,
                name: user.displayName,
                icon: `${window.AP._hostOrigin}${user.profilePicture.path}`,
                background: image,
                titleValue,
                footerValue,
                footerType,
              };
            });

            const sortedUsers = formattedUsers.sort((a, b) => {
              const aIndex = data.manuallyPicked.findIndex((item) => item.accountId === a.accountId);
              const bIndex = data.manuallyPicked.findIndex((item) => item.accountId === b.accountId);

              return aIndex - bIndex;
            });

            setPeople([...sortedUsers]);
          }
        })
        .catch((e) => {
          setPeople([]);
        })
        .finally(() => setLoading(false));
    }
  };

  const maxPage = Math.ceil(people?.length / maxNumberOfItems);

  useEffect(() => {
    if (!!recordsData?.length) {
      getDetailsForUsers(recordsData);
    }
  }, [recordsData, fieldsData, savedData.titleFieldId, savedData.footerFieldId]);

  useEffect(() => {
    setSavedData(data);
  }, [data]);

  useEffect(() => {
    setPage(1);
  }, [maxNumberOfItems, data.pagination, data.titleFieldId, data.collection, data.userGroup, data.view]);

  const dynamicIndexOfFirst = {
    [PanelsPaginationTypes.SHOW_MORE]: 0,
    [PanelsPaginationTypes.ARROWS]: indexOfFirst,
  };

  const peoplePaginated = people.slice(dynamicIndexOfFirst[data.pagination], indexOfLast);

  const panelProps = {
    panelTitle,
    data,
    people: peoplePaginated,
    records: recordsData,
    isLoading: recordsLoading || loading,
    borderRadius,
    primaryColor,
    pagination: { maxNumberOfItems, page, setPage, maxPage, hasNextPage: recordsData?.length > maxNumberOfItems },
    createProfileLink,
    hasInitDatabase,
  };

  const PanelComponent = panelComponents[position];

  return <PanelComponent {...panelProps} />;
}

export default People;
