import React, { useMemo } from "react";
import { useHelpersStore } from "../../store";
import Modal, { ModalTransition } from "@atlaskit/modal-dialog";
import { DeleteNavigation } from "./DeleteNavigation";
import { ModalTypes } from "./types";
import { DeleteEntry } from "./DeleteEntry";

function ModalEntry() {
  const { modalType, modalVisible, setModalType, setModalVisible } = useHelpersStore((state) => ({
    modalType: state.modalType,
    modalVisible: state.modalVisible,
    setModalType: state.setModalType,
    setModalVisible: state.setModalVisible,
  }));

  const ModalContent = useMemo(() => {
    const modals = {
      [ModalTypes.DELETE_NAVIGATION]: DeleteNavigation,
      [ModalTypes.DELETE_ENTRY]: DeleteEntry,
      [undefined]: () => <></>,
    };

    return modals[modalType];
  }, [modalType]);

  return (
    <ModalTransition>
      {modalVisible && (
        <Modal
          height="250px"
          width="400px"
          onClose={() => {
            setModalVisible(false);
            setModalType(undefined);
          }}
        >
          <ModalContent />
        </Modal>
      )}
    </ModalTransition>
  );
}

export default ModalEntry;
