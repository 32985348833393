const DEFAULT_GRADIENTS = [
  { rotation: "45deg", firstColor: "#FF8B00", firstColorPosition: 0, secondColor: "#FF5630", secondColorPosition: 100 },
  { rotation: "45deg", firstColor: "#FFC400", firstColorPosition: 0, secondColor: "#FF8B00", secondColorPosition: 100 },
  { rotation: "45deg", firstColor: "#2684FF", firstColorPosition: 0, secondColor: "#00B8D9", secondColorPosition: 100 },
  { rotation: "45deg", firstColor: "#6554C0", firstColorPosition: 0, secondColor: "#2684FF", secondColorPosition: 100 },
  { rotation: "45deg", firstColor: "#36B37E", firstColorPosition: 0, secondColor: "#6554C0", secondColorPosition: 100 },
  { rotation: "45deg", firstColor: "#FFC400", firstColorPosition: 0, secondColor: "#00B8D9", secondColorPosition: 100 },
  { rotation: "45deg", firstColor: "#FF5630", firstColorPosition: 0, secondColor: "#FFC400", secondColorPosition: 100 },
];

const DEFAULT_COLORS = [
  "#0052CC",
  "#091E42",
  "#00A2E0",
  "#00B8D9",
  "#36B37E",
  "#FFC400",
  "#FF8B00",
  "#FF5630",
  "#6554C0",
  "#DFE1E6",
  "#FFFFFF",
];

const DEFAULT_IMAGES = [
  "https://images.unsplash.com/photo-1612914380234-b35087733c4e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=640&h=480&q=80",
  "https://images.unsplash.com/photo-1494500764479-0c8f2919a3d8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=640&h=480&q=80",
  "https://images.unsplash.com/photo-1527236278376-a1ed0f95da30?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=640&h=480&q=80",
  "https://images.unsplash.com/photo-1559666126-84f389727b9a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=640&h=480&q=80",
  "https://images.unsplash.com/photo-1501854140801-50d01698950b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=640&h=480&q=80",
  "https://images.unsplash.com/photo-1464013778555-8e723c2f01f8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=640&h=480&q=80",
  "https://images.unsplash.com/photo-1462331321792-cc44368b8894?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=640&h=480&q=80",
  "https://images.unsplash.com/photo-1523549115643-7fc0b74a2250?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=640&h=480&q=80",
  "https://images.unsplash.com/photo-1500964757637-c85e8a162699?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=640&h=480&q=80",
  "https://images.unsplash.com/photo-1490358930084-2d26f21dc211?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=640&h=480&q=80",
];

export const IMAGE_TYPES = {
  VIDEO: "video",
  COLOR: "color",
  GRADIENT: "gradient",
  UNSPLASH: "unsplash",
  LINK: "link",
  ATTACHMENTS: "attachments",
  CUSTOM: "custom",
};

const defaultValuesBasedOnType = {
  [IMAGE_TYPES.GRADIENT]: DEFAULT_GRADIENTS,
  [IMAGE_TYPES.COLOR]: DEFAULT_COLORS,
  [IMAGE_TYPES.UNSPLASH]: DEFAULT_IMAGES,
  [IMAGE_TYPES.LINK]: DEFAULT_IMAGES,
};

const getImageValueType = (type) => {
  if (type === IMAGE_TYPES.UNSPLASH || type === IMAGE_TYPES.ATTACHMENTS || type === IMAGE_TYPES.LINK) {
    return "link";
  }
  return "value";
};

export const getRandomUserBackground = (type) => {
  const defaults = defaultValuesBasedOnType[type];

  const min = 0;
  const max = defaults.length - 1;
  const randomNumber = Math.floor(Math.random() * (max - min + 1) + min);

  return { source: type, [getImageValueType(type)]: defaults[randomNumber] };
};

export const getCssForBackground = (background, fallbackImageUrl = "") => {
  if (
    background?.source === IMAGE_TYPES.UNSPLASH ||
    background?.source === IMAGE_TYPES.ATTACHMENTS ||
    background?.source === IMAGE_TYPES.LINK
  ) {
    return `url("${background?.link || ""}")`;
  }

  if (background?.source === IMAGE_TYPES.COLOR) {
    return `${background.value}`;
  }

  if (background?.source === IMAGE_TYPES.GRADIENT) {
    return `linear-gradient(${background.value?.rotation || "90deg"}, ${background.value.firstColor} ${
      background.value.firstColorPosition
    }%, ${background.value.secondColor} ${background.value.secondColorPosition}%)`;
  }

  return `url("${fallbackImageUrl}")`;
};
