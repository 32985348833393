export const TabTypes = {
  LAYOUT: "layout",
  DESIGN: "design",
  SPACES: "spaces",
};

export const SidebarTabs = [
  {
    id: TabTypes.LAYOUT,
    label: "Layout",
  },
  {
    id: TabTypes.DESIGN,
    label: "Design",
  },
];

export const HoustonTabs = [
  {
    id: TabTypes.LAYOUT,
    label: "Layout",
  },
  {
    id: TabTypes.SPACES,
    label: "Spaces",
  },
];
