import React, { useEffect, useState, useRef } from "react";
import { FilterFieldWrapper } from "./styled";
import { useTheme } from "styled-components";
import { CheckboxSelect } from "@atlaskit/select";
import {
  MODIFIED_SELECT_STYLES,
  SIMPLE_SELECT_STYLES,
} from "../../../Hub/Components/Dashboard/filter/CustomSelectStyles";
import { CustomPlaceholder } from "../../../Hub/Components/Shared/CheckboxSelect/CustomCheckboxSelectComponents";
import Select from "@atlaskit/select";

function SelectFilter({
  value,
  onChange,
  options,
  fieldTitle,

  placeholder,
  isMulti = true,

  closeOnChange = false,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectKey, setSelectKey] = useState(Math.random().toString(36).substring(7));
  const [selectedValue, setSelectedValue] = useState();

  const selectedItem = isMulti ? selectedValue?.map((item) => item.label)[0] : selectedValue?.label;

  const labelRef = useRef();
  const theme = useTheme();
  useEffect(() => {
    if (isMulti) {
      if (!value || !value?.length) {
        setSelectedValue(undefined);
      } else {
        const mappedValue = value.map((v) => {
          const option = options.find((o) => o.id === v);
          return { label: option?.label, value: option?.id };
        });

        setSelectedValue([...mappedValue]);
      }
    } else {
      const option = options.find((option) => option.value === value);
      setSelectedValue(option);
    }
  }, [value]);

  useEffect(() => {
    window.AP.events.on("restore-filters-people-base", () => {
      setSelectKey(Math.random().toString(36).substring(7));
    });
  }, []);

  const handlePlaceholder = (props) => {
    const selectedValueLength = selectedValue?.length;

    return (
      <CustomPlaceholder
        item={selectedItem}
        count={selectedValueLength}
        isMulti={isMulti}
        fieldName={placeholder}
        {...props}
      />
    );
  };

  return (
    <FilterFieldWrapper ref={labelRef} hasTitle={!!fieldTitle}>
      {!isMulti ? (
        <Select
          isSearchable
          isClearable
          placeholder={placeholder}
          value={selectedValue}
          controlShouldRenderValue={true}
          options={options}
          styles={MODIFIED_SELECT_STYLES}
          isMulti={isMulti}
          onChange={(options) => onChange(options?.value || "")}
        />
      ) : (
        <CheckboxSelect
          placeholder={placeholder}
          key={selectKey}
          isClearable
          value={selectedValue}
          isSearchable
          backspaceRemovesValue
          isMulti={isMulti}
          styles={SIMPLE_SELECT_STYLES}
          controlShouldRenderValue={false}
          components={{
            Placeholder: handlePlaceholder,
          }}
          onChange={(options) => {
            if (isMulti) {
              onChange((options || []).map(({ value }) => value));
            } else {
              onChange(options?.value || "");
            }
            if (closeOnChange) {
              setIsOpen(false);
            }
          }}
          options={options?.map((op) => ({ label: op?.label, value: op?.id })) || []}
        />
      )}
    </FilterFieldWrapper>
  );
}

export default SelectFilter;
