import React from "react";
import { Column, ContentColumns } from "../shared/styled";
import FieldSelector from "../shared/FieldSelector";
import { useWidgetsStore } from "../../widgets-store";
import { WidgetIds } from "../../consts/tabs";
import { FieldType } from "../../../../../../UserDatabase/field-type";
import InformationPanel, { StyledContent } from "../shared/InformationPanel";
import { EnableStates } from "./defaults";

function Birthday() {
  const { widgetSettings, updateWidgetSettings } = useWidgetsStore((state) => ({
    widgetSettings: state.widgetSettings,
    updateWidgetSettings: state.updateWidgetSettings,
  }));

  return (
    <ContentColumns>
      <Column>
        <FieldSelector
          title="Upcoming Birthdays"
          withRecurring={EnableStates.ENABLED}
          selectedField={widgetSettings[WidgetIds.BIRTHDAY]?.selectedField}
          filterFieldsByTypes={[FieldType.DATE]}
          onChange={(selectedField) => {
            updateWidgetSettings(WidgetIds.BIRTHDAY, {
              selectedField,
            });
          }}
        />
      </Column>

      <Column>
        <InformationPanel
          content={
            <StyledContent
              title="Upcoming Birthdays"
              description="As a Cosmos admin, you can configure the system to display upcoming employee birthdays by selecting the “Birthday”, or any other relevant date column in your database. This feature displays birthdays for the next six months."
            />
          }
        />
      </Column>
    </ContentColumns>
  );
}

export default Birthday;
