import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { light } from "../../../../light-colors";
import { dark } from "../../../../dark-colors";

const themes = {
  dark,
  light,
};

const Wrapper = styled.div`
  width: 260px;
  max-width: 100%;
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  max-height: 115px;
  object-fit: cover;
  border-radius: 4px;
`;

const Title = styled.div`
  margin-top: 16px;
  color: ${({ color }) => color};
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-height: 40px;
`;

const Author = styled.span`
  color: ${({ color }) => color};
  font-size: 12px;
  font-weight: 400;
  display: flex;
  align-items: center;
  margin-top: 4px;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const Description = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-top: 16px;
  color: ${({ color }) => color};

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-height: 40px;
`;

const Link = styled.a`
  all: unset;
  cursor: pointer;
`;

const OptionalLink = ({ url, children }) => {
  if (url) {
    return (
      <Link href={url} target="_blank" rel="noreferrer">
        {children}
      </Link>
    );
  }

  return children;
};

function NewsNavigationCard({ data, temporaryColorScheme }) {
  const { name, lastModified, createdBy, excerpt, url, coverPictureCallback = async () => {} } = data;

  const [backgroundURL, setBackgroundURL] = useState("");

  const theme = themes;

  useEffect(() => {
    (async () => {
      if (coverPictureCallback) {
        setBackgroundURL(await coverPictureCallback());
      }
    })();
  }, [coverPictureCallback]);

  return (
    <OptionalLink url={url}>
      <Wrapper>
        <Image src={backgroundURL} />
        <Title color={theme[temporaryColorScheme].shared.news.title}>{name}</Title>
        <Author color={theme[temporaryColorScheme].shared.news.title}>
          <span>{createdBy}</span>&nbsp;&#183;&nbsp;<span>{lastModified}</span>
        </Author>
        <Description color={theme[temporaryColorScheme].shared.news.text}>{excerpt}</Description>
      </Wrapper>
    </OptionalLink>
  );
}

export default NewsNavigationCard;
