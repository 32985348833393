import React from "react";
import styled from "styled-components";
import Toggle from "@atlaskit/toggle";

const ElementWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0px 8px 10px;

  ${({ isDisabled }) => {
    if (isDisabled) {
      return `cursor: not-allowed; opacity: 0.8;`;
    }
  }}
`;

const ElementLabel = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.shared.sidebar.title};
`;

function SimpleToggle({ value, label, setValue, isDisabled = false, hasToggle = true, customStyles = {} }) {
  return (
    <ElementWrapper isDisabled={isDisabled} style={{ ...customStyles }}>
      <ElementLabel>{label}</ElementLabel>
      {hasToggle && <Toggle isChecked={value} onChange={() => setValue(!value)} isDisabled={isDisabled} />}
    </ElementWrapper>
  );
}

export default SimpleToggle;
