import React, { useEffect, useRef, useState } from "react";
import Button from "@atlaskit/button";
import { getCurrentUserSpaceWatchStatus } from "../../Hub/Components/SpaceDirectory/apiSpaces";
import { useSubscriptionsStore } from "./subscriptions-store";
import { getSubscribedToCategories } from "../../Hub/Components/Settings/News/api";
import { findSpacesUserIsWatching } from "../../Hub/Components/Dashboard/panels/types/news/api";
import { Content, Description, Header, ModalTitle } from "../../Hub/Components/Settings/styled/sections";
import Subscriptions from "./sections/Subscriptions";
import { useClickOutside } from "../../../Shared/Hooks/useClickOutside";
import { subscriptionsContext as SubscriptionsContext } from "./context";
import { ModalBody, ModalFooter, ModalWrapper } from "../styled";

const mapSpace = (space) => ({
  id: space.id,
  key: space.key,
  name: space.name,
  icon: space?.icon?.path,
  fetchWatchingStatus: async () => getCurrentUserSpaceWatchStatus(space.key),
});

function ManageSubscriptions({ isCurrentUserAdmin, primaryColor }) {
  const [loadingSpaces, setLoadingSpaces] = useState(false);
  const [loadingCategories, setLoadingCategories] = useState(false);

  const wrapperRef = useRef();

  useClickOutside(wrapperRef, () => window.AP.dialog.close());

  const { categories, setUsersCategories, setCategories, setUsersSpaces, setSpaces, setCategoryBlogCountData } =
    useSubscriptionsStore((state) => ({
      categories: state.categories,
      setUsersCategories: state.setUsersCategories,
      setCategories: state.setCategories,
      setUsersSpaces: state.setUsersSpaces,
      setSpaces: state.setSpaces,
      setCategoryBlogCountData: state.setCategoryBlogCountData,
    }));

  useEffect(() => {
    if (categories?.length) {
      (async () => {
        const promises = categories.map(async ({ id }) => {
          try {
            const request = await window.AP.request(
              encodeURI(
                `/rest/api/search?cql=type=blogpost AND content.property[cosmosnewsmetadata].categories~"${id}"`,
              ),
            );
            if (request?.body) {
              const data = JSON.parse(request.body);
              return { id, numberOfBlogs: data.totalSize };
            }
            return { id, numberOfBlogs: 0 };
          } catch (error) {
            return { id, numberOfBlogs: undefined };
          }
        });

        try {
          const resolved = await Promise.all(promises);
          setCategoryBlogCountData([...resolved]);
        } catch (err) {
          setCategoryBlogCountData([]);
        }
      })();
    } else {
      setCategoryBlogCountData([]);
    }
  }, [categories]);

  useEffect(() => {
    setLoadingCategories(true);
    (async () => {
      const usersCategories = await getSubscribedToCategories({ includeAllCategories: true });
      setUsersCategories(usersCategories?.data?.subscribedTo || []);
      setCategories(usersCategories?.data?.categories || []);

      setLoadingCategories(false);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const foundSpaces = await findSpacesUserIsWatching();
      const spaces = foundSpaces.filter(({ space }) => space.type !== "personal" && space.status !== "archived");

      setUsersSpaces(spaces?.length ? [...spaces.map(({ space }) => mapSpace(space))] : []);
    })();
  }, []);

  useEffect(() => {
    setLoadingSpaces(true);
    (async () => {
      const request = await window.AP.request("/api/v2/spaces?include-icon=true");
      if (request?.body) {
        const data = JSON.parse(request.body)?.results || [];
        const spaces = data.filter(({ type, status }) => type !== "personal" && status !== "archived");

        setSpaces([...spaces.map((space) => mapSpace(space))]);
      }
      setLoadingSpaces(false);
    })();
  }, []);

  return (
    <SubscriptionsContext.Provider value={{ isCurrentUserAdmin, primaryColor }}>
      <ModalWrapper ref={wrapperRef}>
        <ModalBody>
          <Header>
            <ModalTitle>My Subscriptions</ModalTitle>
            <Description>
              Customize your feed by subscribing news categories and watching your favourite spaces, ensuring you only
              get content that matters to you.
            </Description>
          </Header>

          <Content>
            <Subscriptions isLoading={loadingSpaces || loadingCategories} />
          </Content>
        </ModalBody>
        <ModalFooter>
          <Button appearance="subtle" onClick={() => window.AP.dialog.close()}>
            Close
          </Button>
        </ModalFooter>
      </ModalWrapper>
    </SubscriptionsContext.Provider>
  );
}

export default ManageSubscriptions;
