import React from "react";
import { Box, Flex } from "@atlaskit/primitives";
import MenuItem from "./MenuItem";

function Structure({ nestedStructure = [] }) {
  return (
    <Box style={{ maxHeight: "calc(100vh - 500px)", overflowY: "auto" }}>
      <Flex direction="column">
        {nestedStructure.map((entry, idx) => (
          <MenuItem key={entry.id} entry={entry} index={idx} />
        ))}
      </Flex>
    </Box>
  );
}

export default Structure;
