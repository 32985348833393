import httpClient from "../../../Service/http-client";
import { atlassianRestService } from "../../../Service/AtlassianRestService";

export async function getNavigationMenu({ queryKey }) {
  const url = `ac/confluence/api/navigation?menuId=dd486d3d-5af1-4257-80b1-cf2c26351228`;

  try {
    return httpClient.get(url);
  } catch (error) {
    return undefined;
  }
}

export async function saveNavigationMenu({ structure, active, version, dark, light }) {
  const url = `ac/confluence/api/navigation/menu`;
  return httpClient.post(url, {
    publishedStructure: JSON.stringify(structure),
    active,
    dark,
    light,
    version,
  });
}

export async function fetchSpaces({ queryKey }) {
  const [_queryKey, { spaces = null }] = queryKey;

  let url;
  if (spaces) {
    url = `/api/v2/spaces?include-icon=true&limit=250&ids=${spaces.map((s) => s.value).join(",")}`;
  } else {
    url = `/api/v2/spaces?include-icon=true&limit=250`;
  }

  const res = await window.AP.request(url);

  const data = JSON.parse(res.body);
  const bulk = data.results;

  const users = await atlassianRestService.fetchUsers(bulk.map((data) => data.authorId));

  return bulk.map((data) => ({ ...data, user: users.find((user) => user.accountId === data.authorId) }));
}

export async function fetchAllSpaces({ queryKey }) {
  const [_queryKey, { url = null }] = queryKey;
  const res = await window.AP.request(url || "/api/v2/spaces?include-icon=true&limit=250");

  const data = JSON.parse(res.body);
  const bulk = data.results;

  if (data._links.next) {
    const nextBulk = await fetchAllSpaces(data._links.next.replace("/wiki", ""));

    return [...bulk, ...nextBulk];
  }

  return bulk;
}
