import React from "react";
import { FilterSidebarWrapper } from "../styled/newsroom";
import { FilterDivider } from "../../Shared/Filters/styled-filters";
import { useCorporateIdentityStore } from "../../Settings/General/BrandAndColors/corporate-identity-store";
import { useCurrentUserStore } from "../../../current-user-store";

import { COLOR_TYPES } from "../../Settings/General/BrandAndColors/color-types";
import { useNewsroomStore } from "../newsroom-store";
import ViewSelect from "./ViewSelect";
import Filters from "./Filters";
import Sort from "./Sort";
import { useTheme } from "styled-components";

import Button from "@atlaskit/button/new";
import AddIcon from "@atlaskit/icon/glyph/editor/add";
import Categories from "./Categories";

function FilterSidebar() {
  const colors = useCorporateIdentityStore((state) => state.colors);
  const primaryColor = colors ? colors[COLOR_TYPES.PRIMARY] : "#0065ff";
  const theme = useTheme();

  const setCreateBlogModalOpen = useNewsroomStore((state) => state.setCreateBlogModalOpen);
  const isCurrentUserAdmin = useCurrentUserStore((state) => state.isCurrentUserAdmin);

  return (
    <FilterSidebarWrapper>
      {isCurrentUserAdmin && (
        <>
          <Button
            appearance="primary"
            iconBefore={AddIcon}
            onClick={() => setCreateBlogModalOpen(true)}
            style={{ fontSize: "14px", backgroundColor: primaryColor }}
          >
            Create Blog
          </Button>
        </>
      )}
      <FilterDivider borderColor={theme.global.divider} />
      <ViewSelect mgTop={isCurrentUserAdmin ? "28px" : "0px"} primaryColor={primaryColor} />
      <Filters />
      <Sort />
      <FilterDivider style={{ marginBottom: "10px" }} borderColor={theme.global.divider} />
      <Categories />
    </FilterSidebarWrapper>
  );
}

export default FilterSidebar;
