export const AppLinks = [
  {
    id: Math.random().toString(32).slice(2),
    title: "Caelor",
    path: "https://caelor-apps.web.app/images/icons/Caelor_Black_Symbol.png",
  },
  {
    id: Math.random().toString(32).slice(2),
    title: "Xcode",
    path: "https://caelor-apps.web.app/images/icons/xcode.svg",
  },
  {
    id: Math.random().toString(32).slice(2),
    title: "Wordpress",
    path: "https://caelor-apps.web.app/images/icons/wordpress.svg",
  },
  {
    id: Math.random().toString(32).slice(2),
    title: "Visual studio",
    path: "https://caelor-apps.web.app/images/icons/visualstudio.svg",
  },
  {
    id: Math.random().toString(32).slice(2),
    title: "Trello",
    path: "https://caelor-apps.web.app/images/icons/trello.svg",
  },
  {
    id: Math.random().toString(32).slice(2),
    title: "Sales Force",
    path: "https://caelor-apps.web.app/images/icons/salesforce.svg",
  },
  {
    id: Math.random().toString(32).slice(2),
    title: "Power Bi",
    path: "https://caelor-apps.web.app/images/icons/powerbi.svg",
  },
  {
    id: Math.random().toString(32).slice(2),
    title: "Personio",
    path: "https://caelor-apps.web.app/images/icons/personio.svg",
  },
  {
    id: Math.random().toString(32).slice(2),
    title: "Microsoft",
    path: "https://caelor-apps.web.app/images/icons/microsoft.svg",
  },
  {
    id: Math.random().toString(32).slice(2),
    title: "Google",
    path: "https://caelor-apps.web.app/images/icons/google.svg",
  },
  {
    id: Math.random().toString(32).slice(2),
    title: "Maps",
    path: "https://caelor-apps.web.app/images/icons/maps.svg",
  },
  {
    id: Math.random().toString(32).slice(2),
    title: "Play",
    path: "https://caelor-apps.web.app/images/icons/play.svg",
  },
  {
    id: Math.random().toString(32).slice(2),
    title: "Firebase",
    path: "https://caelor-apps.web.app/images/icons/firebase.svg",
  },
  {
    id: Math.random().toString(32).slice(2),
    title: "Drive",
    path: "https://caelor-apps.web.app/images/icons/drive.svg",
  },
  {
    id: Math.random().toString(32).slice(2),
    title: "Meet",
    path: "https://caelor-apps.web.app/images/icons/meet.svg",
  },
  {
    id: Math.random().toString(32).slice(2),
    title: "Cloud",
    path: "https://caelor-apps.web.app/images/icons/cloud.svg",
  },
  {
    id: Math.random().toString(32).slice(2),
    title: "Adsense",
    path: "https://caelor-apps.web.app/images/icons/adsense.svg",
  },
  {
    id: Math.random().toString(32).slice(2),
    title: "Ads",
    path: "https://caelor-apps.web.app/images/icons/ads.svg",
  },
  {
    id: Math.random().toString(32).slice(2),
    title: "Analytics",
    path: "https://caelor-apps.web.app/images/icons/analytics.svg",
  },
  {
    id: Math.random().toString(32).slice(2),
    title: "Chrome",
    path: "https://caelor-apps.web.app/images/icons/chrome.svg",
  },
  {
    id: Math.random().toString(32).slice(2),
    title: "Confluence",
    path: "https://caelor-apps.web.app/images/icons/confluence.svg",
  },
  {
    id: Math.random().toString(32).slice(2),
    title: "Jira",
    path: "https://caelor-apps.web.app/images/icons/jira.svg",
  },
  {
    id: Math.random().toString(32).slice(2),
    title: "GitLab",
    path: "https://caelor-apps.web.app/images/icons/gitlab.svg",
  },
  {
    id: Math.random().toString(32).slice(2),
    title: "Edge",
    path: "https://caelor-apps.web.app/images/icons/edge.svg",
  },
  {
    id: Math.random().toString(32).slice(2),
    title: "Slack",
    path: "https://caelor-apps.web.app/images/icons/slack.svg",
  },
  {
    id: Math.random().toString(32).slice(2),
    title: "Excel",
    path: "https://caelor-apps.web.app/images/icons/excel.svg",
  },
  {
    id: Math.random().toString(32).slice(2),
    title: "Booking",
    path: "https://caelor-apps.web.app/images/icons/booking.svg",
  },
  {
    id: Math.random().toString(32).slice(2),
    title: "Bamboo",
    path: "https://caelor-apps.web.app/images/icons/bamboo.svg",
  },
  {
    id: Math.random().toString(32).slice(2),
    title: "Azure",
    path: "https://caelor-apps.web.app/images/icons/azure.svg",
  },
  {
    id: Math.random().toString(32).slice(2),
    title: "Apple",
    path: "https://caelor-apps.web.app/images/icons/apple.svg",
  },
  {
    id: Math.random().toString(32).slice(2),
    title: "Android",
    path: "https://caelor-apps.web.app/images/icons/android.svg",
  },
  {
    id: Math.random().toString(32).slice(2),
    title: "Airtable",
    path: "https://caelor-apps.web.app/images/icons/airtable.svg",
  },
  {
    id: Math.random().toString(32).slice(2),
    title: "Airbnb",
    path: "https://caelor-apps.web.app/images/icons/airbnb.svg",
  },
  {
    id: Math.random().toString(32).slice(2),
    title: "Word",
    path: "https://caelor-apps.web.app/images/icons/word.svg",
  },
  {
    id: Math.random().toString(32).slice(2),
    title: "Sketch",
    path: "https://caelor-apps.web.app/images/icons/sketch.svg",
  },
  {
    id: Math.random().toString(32).slice(2),
    title: "Teams",
    path: "https://caelor-apps.web.app/images/icons/teams.svg",
  },
  {
    id: Math.random().toString(32).slice(2),
    title: "GitHub",
    path: "https://caelor-apps.web.app/images/icons/github.svg",
  },
  {
    id: Math.random().toString(32).slice(2),
    title: "Hubspot",
    path: "https://caelor-apps.web.app/images/icons/hubspot.svg",
  },
  {
    id: Math.random().toString(32).slice(2),
    title: "Atlassian",
    path: "https://caelor-apps.web.app/images/icons/atlassian.svg",
  },
];
