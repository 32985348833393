import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useDrag, useDrop } from "react-dnd";
import shallow from "zustand/shallow";
import { useRecordsStore } from "../../records-store";
import styled from "styled-components";
import { useDatabaseUIStore } from "../../database-ui-store";
import { Resizable } from "react-resizable";
import HeaderDropDown, { SelectDropDown, SelectOptionsEditor, NumberDropDown, DateDropDown } from "./HeaderDropDown";
import { FieldType } from "../../field-type";
import HeaderIcon, { EntraIcon } from "./HeaderIcon";
import Checkbox from "@atlaskit/checkbox";
import Tooltip from "@atlaskit/tooltip";
import { useTheme } from "styled-components";
import MoreIcon from "@atlaskit/icon/glyph/more";
import { ControlledMenu, MenuItem, MenuDivider } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/theme-dark.css";
import "@szhsin/react-menu/dist/transitions/slide.css";

import Popup from "@atlaskit/popup";
import { RenameField } from "./HeaderDropDown";
import DeleteField from "./fields/DeleteField";
import { SortField } from "./HeaderDropDown";
import { InsertField } from "./HeaderDropDown";

import * as ReactDOM from "react-dom";

import { SelectionType } from "../../menubar/Users";
import { THEME_NAME_DARK, THEME_NAME_LIGHT } from "../../../Navigation/functions/generateNewEntry";

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0px;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 400;
  border-right: 1px solid ${({ theme }) => theme.shared.settings.database.border};
  border-bottom: 1px solid ${({ theme }) => theme.shared.settings.database.border};
  font-size: 14px;
  flex-shrink: 0;
  padding: 5px 8px;
  height: 28px;
  position: relative;
  background-color: ${({ theme }) => theme.shared.settings.database.header};
  color: ${({ theme }) => theme.shared.settings.database.text};

  &.sortable {
    cursor: grab;
  }
`;

const HeaderCell = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  font-weight: 500;
  gap: 5px;
  flex: 1;
  color: ${({ theme }) => theme.shared.settings.database.records.headerName};
`;

const AddFieldWrapper = styled.div`
  width: 207px;
  background: ${({ theme }) => theme.shared.settings.database.header};
  font-weight: 400;
  border-right: 1px solid ${({ theme }) => theme.shared.settings.database.border};
  border-bottom: 1px solid ${({ theme }) => theme.shared.settings.database.border};
  font-size: 14px;
  padding: 10.5px 30px;
  height: 39px;
  box-sizing: border-box;
`;

const Resizer = styled.div`
  //
  cursor: col-resize;
  position: absolute;
  right: -3px;
  z-index: 1;
  top: 0px;
  width: 5px;
  border-radius: 3px;
  margin: 3px 0px;
  height: 80%;
  &:hover {
    background-color: #2d7ff9;
  }
`;

const HeaderRowWrapper = styled.div`
  display: flex;
  color: ${({ theme }) => theme.shared.settings.database.textx};
  position: absolute;
  z-index: 3;

  & > div {
    &:nth-child(1) {
      position: sticky;
      z-index: 4;
      left: 0;
    }
    &:nth-child(2) {
      position: sticky !important;
      z-index: 4;
      left: 41px;
    }
    &:nth-child(3) {
      position: sticky !important;
      z-index: 4;
      left: 108px;
    }
    &:nth-child(4) {
      position: sticky !important;
      z-index: 4;
      overflow: unset;
      left: ${({ left }) => left}px;
    }
  }
`;

const HideUserHeaderWrapper = styled.div`
  background-color: ${({ theme }) => theme.shared.settings.database.header};
  position: relative;
  border-bottom: 1px solid ${({ theme }) => theme.shared.settings.database.border};
  border-right: 1px solid ${({ theme }) => theme.shared.settings.database.border};
`;

const MenuWrapper = styled.div`
  ${({ theme }) => `
    .szh-menu {
      width: 320px;
      top: 20px !important;
      left: -208px !important;

      background-color: ${theme.shared.settings.database.header};
      border: 1px solid ${theme.shared.settings.database.border};

      .szh-menu__divider {
        background-color: ${theme.shared.settings.database.border}
      }
    }
  `}

  .szh-menu__item {
    border-left: 2px solid transparent;

    &:hover {
      border-color: #0c66e4;
    }
  }
`;

const RemoveHover = styled.div`
  .szh-menu__item {
    &:hover {
      border-left: none !important;
    }
  }
`;

const EntraIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  margin: 0 7px 0 auto;
  border-radius: 50%;
  background: #5195fe1f;
`;

const ResizeHandler = React.forwardRef((props, ref) => <Resizer className="resizer" ref={ref} {...props} />);

const SortableHeader = ({ field, columnInUi, onSorted, setLeftOffset }) => {
  const { isResizing, setIsResizing } = useDatabaseUIStore((state) => {
    return {
      isResizing: state.isResizing,
      setIsResizing: state.setIsResizing,
    };
  }, shallow);

  const [{ isOver, isOverCurrent, canDrop }, drop] = useDrop(() => ({
    accept: ["column"],
    drop: () => ({ id: field.id, title: field.title }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));

  const [{ isDragging }, drag] = useDrag({
    type: "column",
    canDrag: () => !isResizing,
    item: () => {
      return { id: field.id, type: "field", title: field.title };
    },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (item && dropResult) {
        onSorted(item.id, dropResult.id);
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  function attachRef(el) {
    drag(el);
    drop(el);
  }

  const theme = useTheme();

  const dropOverStyles = {
    header: {
      [true]: {
        borderLeft: `3px solid ${theme.global.text.blue}`,
      },
    },
  };

  return (
    <div ref={attachRef}>
      <Header
        field={field}
        columnInUi={columnInUi}
        isHoveringOver={isOver}
        className="sortable"
        setLeftOffset={setLeftOffset}
        onResizeStart={() => {
          setIsResizing(true);
        }}
        onResizeStop={() => {
          setIsResizing(false);
        }}
        additionalStyles={{ header: { ...dropOverStyles.header[isOver || isOverCurrent] } }}
      />
    </div>
  );
};

const HideUserHeader = ({}) => {
  const { hiddenUsersSelection, setHiddenUsersSelection, records } = useRecordsStore((state) => {
    return {
      hiddenUsersSelection: state.hiddenUsersSelection,
      setHiddenUsersSelection: state.setHiddenUsersSelection,
      records: state.records,
    };
  }, shallow);

  const numberOfAllUsers = records.filter((record) =>
    hiddenUsersSelection.selectionType === SelectionType.SHOW_ALL_USERS
      ? true
      : hiddenUsersSelection.selectionType === SelectionType.SHOW_HIDDEN_USERS
      ? record.values.hidden
      : !record.values.hidden,
  ).length;

  const isIndeterminate = () => {
    if (!hiddenUsersSelection.selectedUsers.length) {
      return false;
    }

    if (hiddenUsersSelection.selectedUsers.length !== numberOfAllUsers) {
      return true;
    } else {
      return false;
    }
  };

  const isChecked = () => {
    if (!hiddenUsersSelection.selectedUsers.length) {
      return false;
    }

    return true;
  };

  const onSelectChanged = () => {
    const allRecords = records
      .filter((record) =>
        hiddenUsersSelection.selectionType === SelectionType.SHOW_ALL_USERS
          ? true
          : hiddenUsersSelection.selectionType === SelectionType.SHOW_HIDDEN_USERS
          ? record.values.hidden
          : !record.values.hidden,
      )
      .map((record) => record.accountId);

    let newState = [];
    if (isChecked()) {
      if (isIndeterminate()) {
        newState = [...allRecords];
      } else {
        newState = [];
      }
    } else {
      newState = [...allRecords];
    }

    setHiddenUsersSelection({ selectionType: hiddenUsersSelection.selectionType, selectedUsers: newState });
  };

  return (
    <HideUserHeaderWrapper
      style={{
        width: `40px`,
        maxWidth: `40px`,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Checkbox
        isDisabled={!records}
        isIndeterminate={isIndeterminate()}
        isChecked={isChecked()}
        onChange={onSelectChanged}
      />
    </HideUserHeaderWrapper>
  );
};

const CounterHeader = ({ numberOfActiveUsers }) => (
  <HeaderWrapper
    style={{
      width: `50px`,
      maxWidth: `50px`,
    }}
  >
    <div
      style={{
        overflow: "hidden",
        textOverflow: "ellipsis",
        flex: "1",
        textAlign: "center",
        color: "#7A869A",
        fontSize: "12px",
        fontWeight: "400",
        lineHeight: "20px",
      }}
    >
      {numberOfActiveUsers}
    </div>
  </HeaderWrapper>
);

const HeaderUserDropdown = ({ field }) => {
  const [open, setOpen] = useState(false);
  const [renameIsOpen, setRenameIsOpen] = useState(false);
  const [confirmDeleteIsOpen, setConfirmDeleteIsOpen] = useState(false);
  const ref = useRef(null);
  const skipOpen = useRef(false);

  const { sortRecords, deleteField } = useRecordsStore((state) => {
    return {
      sortRecords: state.sortRecords,
      deleteField: state.deleteField,
    };
  }, shallow);

  const theme = useTheme();
  const [isDark, setIsDark] = useState(THEME_NAME_LIGHT);

  const toggleMenu = () => {
    if (!skipOpen.current) {
      setOpen(true);
    }
  };

  const closeMenu = (e) => {
    setOpen(false);
  };

  useEffect(() => {
    setIsDark(theme?.global.name === THEME_NAME_DARK ? THEME_NAME_DARK : THEME_NAME_LIGHT);
  }, [theme]);

  return (
    <>
      <div
        ref={ref}
        onClick={toggleMenu}
        className="button"
        style={{ cursor: "pointer", height: "20px", width: "20px" }}
      >
        <MoreIcon
          primaryColor={
            open
              ? theme.shared.settings.database.records.headerDropdown.active
              : theme.shared.settings.database.records.headerDropdown.inactive
          }
        />
      </div>
      <MenuWrapper>
        <ControlledMenu
          skipOpen={skipOpen}
          state={open ? "open" : "closed"}
          anchorRef={ref}
          onClose={closeMenu}
          theming={isDark}
          align="center"
        >
          <Popup
            isOpen={renameIsOpen}
            onClose={() => setRenameIsOpen(false)}
            placement="bottom"
            content={() => {
              return ReactDOM.createPortal(
                <RenameField
                  fieldId={field.id}
                  title={field.title}
                  onSave={() => {
                    setRenameIsOpen(false);
                  }}
                  fieldLabel={"Column name"}
                />,
                document.querySelector(`#portal-column-${field.id}`),
              );
            }}
            trigger={(triggerProps) => (
              <>
                <div {...triggerProps} style={{ cursor: "pointer" }}>
                  <MenuItem onClick={() => setRenameIsOpen(!renameIsOpen)}>Rename column</MenuItem>
                </div>
              </>
            )}
          />

          <MenuDivider />

          <SortField label="Sort ascending" sortOrder="asc" field={field} sortFunc={sortRecords} />
          <SortField label="Sort descending" sortOrder="desc" field={field} sortFunc={sortRecords} />

          <MenuDivider />

          <Popup
            isOpen={confirmDeleteIsOpen}
            onClose={() => setConfirmDeleteIsOpen(false)}
            placement="bottom"
            content={() =>
              ReactDOM.createPortal(
                <DeleteField
                  onCancel={() => {
                    setConfirmDeleteIsOpen(false);
                  }}
                  onConfirm={() => {
                    deleteField(field.id);
                    setConfirmDeleteIsOpen(false);
                  }}
                />,
                document.querySelector(`#portal-column-${field.id}`),
              )
            }
            trigger={(triggerProps) => (
              <RemoveHover>
                <div {...triggerProps} style={{ cursor: "pointer", color: theme.global.text.red }}>
                  <MenuItem
                    disabled={field.isEssential}
                    onClick={() => {
                      setConfirmDeleteIsOpen(true);
                    }}
                  >
                    Delete column
                  </MenuItem>
                </div>
              </RemoveHover>
            )}
          />
        </ControlledMenu>
      </MenuWrapper>
    </>
  );
};

const HeaderEmailDropdown = ({ field }) => {
  const [open, setOpen] = useState(false);
  const [confirmDeleteIsOpen, setConfirmDeleteIsOpen] = useState(false);
  const ref = useRef(null);
  const skipOpen = useRef(false);
  const { sortRecords, deleteField } = useRecordsStore((state) => {
    return {
      sortRecords: state.sortRecords,
      deleteField: state.deleteField,
    };
  }, shallow);

  const theme = useTheme();
  const [isDark, setIsDark] = useState(THEME_NAME_LIGHT);

  const toggleMenu = () => {
    if (!skipOpen.current) {
      setOpen(true);
    }
  };

  const closeMenu = (e) => {
    setOpen(false);
  };

  useEffect(() => {
    setIsDark(theme?.global.name === THEME_NAME_DARK ? THEME_NAME_DARK : THEME_NAME_LIGHT);
  }, [theme]);

  return (
    <>
      <div
        ref={ref}
        onClick={toggleMenu}
        className="button"
        style={{ cursor: "pointer", height: "20px", width: "20px" }}
      >
        <MoreIcon
          primaryColor={
            open
              ? theme.shared.settings.database.records.headerDropdown.active
              : theme.shared.settings.database.records.headerDropdown.inactive
          }
        />
      </div>
      <MenuWrapper>
        <ControlledMenu
          skipOpen={skipOpen}
          state={open ? "open" : "closed"}
          anchorRef={ref}
          onClose={closeMenu}
          theming={isDark}
          align="center"
        >
          <InsertField label="Insert column right" fieldId={field.id} position={field.ui.position + 1} />

          <SortField label="Sort ascending" sortOrder="asc" field={field} sortFunc={sortRecords} />
          <SortField label="Sort descending" sortOrder="desc" field={field} sortFunc={sortRecords} />

          <MenuDivider />

          <Popup
            isOpen={confirmDeleteIsOpen}
            onClose={() => setConfirmDeleteIsOpen(false)}
            placement="bottom"
            content={() =>
              ReactDOM.createPortal(
                <DeleteField
                  onCancel={() => {
                    setConfirmDeleteIsOpen(false);
                  }}
                  onConfirm={() => {
                    deleteField(field.id);
                    setConfirmDeleteIsOpen(false);
                  }}
                />,
                document.querySelector(`#portal-column-${field.id}`),
              )
            }
            trigger={(triggerProps) => (
              <RemoveHover>
                <div {...triggerProps} style={{ cursor: "pointer", color: theme.global.text.red }}>
                  <MenuItem
                    disabled={field.isEssential}
                    onClick={() => {
                      setConfirmDeleteIsOpen(true);
                    }}
                  >
                    Delete column
                  </MenuItem>
                </div>
              </RemoveHover>
            )}
          />
        </ControlledMenu>
      </MenuWrapper>
    </>
  );
};

const Header = ({
  field,
  columnInUi,
  setLeftOffset,
  isHoveringOver = false,
  onResizeStart = () => {},
  onResizeStop = () => {},
  className,
  additionalStyles = { header: {} },
}) => {
  const { updateWidth } = useDatabaseUIStore((state) => {
    return {
      updateWidth: state.updateWidth,
    };
  }, shallow);

  const { updateFieldUi, updateField } = useRecordsStore((state) => {
    return {
      updateFieldUi: state.updateFieldUi,
      updateField: state.updateField,
    };
  }, shallow);

  const onResize = useCallback(
    (event, { element, size, handle }) => {
      event.stopPropagation();
      event.preventDefault();

      updateWidth(columnInUi.id, size.width);
      updateFieldUi(columnInUi.id, { width: size.width });

      if (columnInUi.id === "user") {
        setLeftOffset(size.width);
      }
    },
    [field],
  );

  const theme = useTheme();

  return (
    <div style={{ position: "relative" }}>
      <Resizable
        height={34}
        width={columnInUi?.width}
        onResizeStart={onResizeStart}
        onResizeStop={(e, data) => {
          //onResize(e, data);
          onResizeStop();
        }}
        onResize={onResize}
        handle={<ResizeHandler />}
        axis="x"
      >
        <HeaderWrapper
          style={{
            width: `${columnInUi?.width}px`,
            maxWidth: `${columnInUi?.width}px`,
            ...additionalStyles.header,
          }}
          className={className}
        >
          <HeaderCell>
            {field.id.includes("azure") ? (
              <>
                <HeaderIcon field={field} />
                <Tooltip content="Entra ID Field - Read Only" position="top">
                  <span>{field.title.trim()}</span>
                </Tooltip>

                <EntraIconContainer>
                  <Tooltip content="Entra ID Field - Read Only" position="top">
                    <EntraIcon width="16" />
                  </Tooltip>
                </EntraIconContainer>
              </>
            ) : (
              <>
                <HeaderIcon field={field} />
                {field.title.trim()}
              </>
            )}
          </HeaderCell>
          {field.id !== "user" && field.id !== "email" && !field.id.includes("azure") && (
            <div style={{ cursor: "pointer" }}>
              <HeaderDropDown
                field={field}
                firstChild={
                  <>
                    {(field.type === FieldType.SELECT || field.type === FieldType.MULTISELECT) && (
                      <>
                        <SelectOptionsEditor
                          fieldId={field.id}
                          options={field.options}
                          onUpdate={(value) => {
                            if (Array.isArray(value)) {
                              updateField(field.id, { options: value.filter((o) => o.label.trim().length > 0) });
                            } else {
                              updateField(field.id, { isCreatable: value });
                            }
                          }}
                        />
                        <MenuDivider />
                      </>
                    )}
                  </>
                }
                importantChild={
                  <>
                    {field.type === FieldType.NUMBER && (
                      <NumberDropDown
                        fieldId={field.id}
                        format={field.format}
                        onUpdate={(format) => {
                          updateField(field.id, { format });
                        }}
                      />
                    )}
                    {field.type === FieldType.DATE && (
                      <DateDropDown
                        fieldId={field.id}
                        recurring={field.recurring}
                        onUpdate={(recurring) => {
                          updateField(field.id, { recurring });
                        }}
                      />
                    )}
                    {(field.type === FieldType.SELECT || field.type === FieldType.MULTISELECT) && (
                      <SelectDropDown
                        fieldId={field.id}
                        options={field.options}
                        isCreatable={field.isCreatable}
                        onUpdate={(value) => {
                          if (Array.isArray(value)) {
                            updateField(field.id, { options: value.filter((o) => o.label.trim().length > 0) });
                          } else {
                            updateField(field.id, { isCreatable: value });
                          }
                        }}
                      />
                    )}
                  </>
                }
              ></HeaderDropDown>
            </div>
          )}

          {field.id === "user" && <HeaderUserDropdown field={field} />}

          {field.id === "email" && <HeaderEmailDropdown field={field} />}
        </HeaderWrapper>
      </Resizable>
      <div
        style={{
          position: "absolute",
          top: "38px",
          left: "-1px",
          zIndex: "1",
          border: `1px solid ${theme.shared.settings.database.border}`,
        }}
        id={`portal-column-${field.id}`}
      />
    </div>
  );
};

const AddHeaderTrigger = () => {
  return (
    <div id="last-header">
      <AddFieldWrapper></AddFieldWrapper>
    </div>
  );
};

const HeaderRow = ({ containerRef, setLeftOffset, leftOffset }) => {
  const { fields, reorderFields, numberOfActiveUsers } = useRecordsStore((state) => {
    return {
      fields: state.fields,
      reorderFields: state.reorderFields,
      numberOfActiveUsers: state.numberOfActiveUsers,
    };
  }, shallow);

  const [headerRowTopOffset, setHeaderRowTopOffset] = useState(0);

  const headerRowWrapperRef = useRef(null);
  const columns = useDatabaseUIStore((state) => state.columns);

  const offsetDifference = 200 - leftOffset;
  const calculatedLeftOffset = leftOffset === 0 ? 325 : 325 - offsetDifference;

  const scrollEventListener = useCallback(() => {
    setHeaderRowTopOffset(containerRef.current.scrollTop);
  }, [containerRef, setHeaderRowTopOffset]);

  const scrollTopOffset = useMemo(() => headerRowTopOffset, [headerRowTopOffset]);

  useEffect(() => {
    containerRef.current.addEventListener("scroll", scrollEventListener, true);
    return () => containerRef.current && containerRef.current.removeEventListener("scroll", scrollEventListener, true);
  }, []);

  return (
    <>
      <HeaderRowWrapper ref={headerRowWrapperRef} style={{ top: `${scrollTopOffset}px` }} left={calculatedLeftOffset}>
        <DndProvider backend={HTML5Backend}>
          <HideUserHeader />
          <CounterHeader numberOfActiveUsers={numberOfActiveUsers} />
          {fields
            .filter((x) => x.id !== "hidden")
            .map((field) => {
              if (field.id === "user" || field.id === "email" || field.id.includes("azure")) {
                return (
                  <Header field={field} columnInUi={columns[field.id]} key={field.id} setLeftOffset={setLeftOffset} />
                );
              }
              return (
                <SortableHeader
                  field={field}
                  columnInUi={columns[field.id]}
                  key={field.id}
                  setLeftOffset={setLeftOffset}
                  onSorted={(sourceId, targetId) => {
                    reorderFields(sourceId, targetId);
                  }}
                />
              );
            })}
        </DndProvider>
        <AddHeaderTrigger />
      </HeaderRowWrapper>
    </>
  );
};

export default HeaderRow;
