import React from "react";
import { ColumnContainer, MediumLine, Wrapper } from "./styled";
import styled from "styled-components";
import InfoIcon from "@atlaskit/icon/glyph/info";

const PanelWrapper = styled.div`
  padding: 24px 25px;
  border-radius: 12px;
  background-color: #deebff;
  color: #42526e;
  filter: drop-shadow(0px 1.7px 3.5px rgba(0, 0, 0, 0.08));
`;

const PanelTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 17px;
`;

const PanelTitle = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  word-wrap: break-word;
`;

export const InfoMain = () => {
  return (
    <Wrapper>
      <PanelWrapper>
        <PanelTitleWrapper>
          <InfoIcon size="large" primaryColor="#0052cc" />
          <PanelTitle>Info</PanelTitle>
        </PanelTitleWrapper>

        <ColumnContainer style={{ marginTop: "25px", rowGap: "11px", opacity: "0.6" }}>
          <MediumLine width={550} bg="rgba(9, 30, 66, 0.25)" />
          <MediumLine width={550} bg="rgba(9, 30, 66, 0.25)" />
          <MediumLine width={550} bg="rgba(9, 30, 66, 0.25)" />
          <MediumLine width={350} bg="rgba(9, 30, 66, 0.25)" />
        </ColumnContainer>
      </PanelWrapper>
    </Wrapper>
  );
};

function Info() {
  return (
    <Wrapper>
      <PanelWrapper>
        <PanelTitleWrapper>
          <InfoIcon size="large" primaryColor="#0052cc" />
          <PanelTitle>Info</PanelTitle>
        </PanelTitleWrapper>

        <ColumnContainer style={{ marginTop: "25px", rowGap: "11px", opacity: "0.6" }}>
          <MediumLine width={145} bg="rgba(9, 30, 66, 0.25)" />
          <MediumLine width={145} bg="rgba(9, 30, 66, 0.25)" />
          <MediumLine width={145} bg="rgba(9, 30, 66, 0.25)" />
          <MediumLine width={115} bg="rgba(9, 30, 66, 0.25)" />
        </ColumnContainer>
      </PanelWrapper>
    </Wrapper>
  );
}

export default Info;
