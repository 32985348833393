import React, { useEffect } from "react";
import DateEditor from "./editor/DateEditor";
import SelectEditor from "./editor/SelectEditor";
import NumberEditor from "./editor/NumberEditor";
import TextEditor from "./editor/TextEditor";
import UserEditor from "./editor/UserEditor";
import UrlEditor from "./editor/UrlEditor";

const EditorProvider = ({ initialValue, field, onEdited, abortEdit, keepEdit }) => {
  const handleKeyDown = (e) => {
    if (e.key === "Escape") {
      abortEdit();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  if (field.type === "URL") {
    return <UrlEditor initialValue={initialValue} onEdit={onEdited} />;
  }

  if (field.type === "TEXT") {
    return <TextEditor initialValue={initialValue} onEdit={onEdited} />;
  }

  if (field.type === "SELECT") {
    return (
      <SelectEditor
        field={field}
        initialValue={initialValue}
        onAbort={abortEdit}
        onEdit={onEdited}
        onSelect={onEdited}
      />
    );
  }

  if (field.type === "MULTISELECT") {
    return (
      <SelectEditor
        isMulti
        field={field}
        initialValue={initialValue}
        onAbort={abortEdit}
        onEdit={onEdited}
        onSelect={onEdited}
        onKeepEdit={keepEdit}
      />
    );
  }

  if (field.type === "USER") {
    return <UserEditor initialValue={initialValue} onAbort={abortEdit} onEdit={onEdited} />;
  }

  if (field.type === "DATE") {
    return <DateEditor initialValue={initialValue} onAbort={abortEdit} onEdit={onEdited} />;
  }

  if (field.type === "NUMBER") {
    return <NumberEditor initialValue={initialValue} onEdit={onEdited} />;
  }

  return <div></div>;
};

export default EditorProvider;
