import styled from "styled-components";

export const SidebarContainer = styled.div`
  & > * {
    position: ${({ position }) => position};
    overflow: hidden;
    ${({ dialogPositionCss }) => {
      if (dialogPositionCss && Object.keys(dialogPositionCss).length) {
        return Object.entries(dialogPositionCss)
          .map(([key, value]) => `${key}: ${value}`)
          .join("; ");
      }
      return `
        top: 56px;
        bottom: 0;
      `;
    }}
  }
`;

export const Reset = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: ${({ theme }) => theme.global.primaryColor};
  cursor: pointer;

  float: right;
  margin: 0 0 10px;

  &:hover {
    opacity: 0.9;
  }
`;

export const ContentContainer = styled.div`
  display: grid;
  grid-template-columns: calc(50% - 20px) calc(50% - 20px);
  column-gap: 40px;
  height: calc(100% - 45px);
  overflow: hidden;
`;

export const Content = styled.div`
  width: 100%;
  padding-top: 10px;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const TabContainer = styled.div`
  display: flex;
  column-gap: 20px;
  margin-top: 12px;
`;

export const Tab = styled.span`
  font-size: 12px;
  font-weight: 500;
  color: #07145a;
  opacity: ${({ isSelected }) => (isSelected ? "1" : "0.5")};
  padding-bottom: 2px;
  border-bottom: ${({ isSelected }) => (isSelected ? "1px solid #07145a" : "1px solid transparent")};
  cursor: pointer;
  user-select: none;
`;

export const VerticalRule = styled.div`
  position: absolute;
  left: 50%;
  top: 70px;
  height: calc(100% - 90px);
  border-right: 1px solid ${({ theme }) => theme.sidebar.seperator};
`;

export const ColorOverlayActions = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  justify-content: space-between;

  & > span:first-child {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #6b778c;
    margin-bottom: 5px;
  }
`;

export const ColorOverlayInputsContainer = styled.div`
  display: grid;
  grid-template-columns: 30px auto;
  column-gap: 10px;
  align-items: center;
  margin-top: 10px;
`;

export const IconContainer = styled.div`
  display: grid;
  place-content: center;
  cursor: pointer;
  height: 40px;
  width: 40px;
`;

export const TextFieldContainer = styled.div`
  label {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #6b778c;
    margin-bottom: 5px;
    display: block;
  }

  & > div:nth-child(2) {
    border-color: ${({ theme }) => theme.shared.form.input.border};
    color: ${({ theme }) => theme.shared.form.input.text};
    background-color: ${({ theme }) => theme.shared.form.input.hover};
    border-radius: 3px;
    border-width: 0px;
    height: 40px;

    .link-display {
      font-weight: 400 !important;
      font-size: 14px !important;
      line-height: 17px !important;
      color: ${({ theme }) => theme.shared.form.input.text} !important;
      height: 40px !important;
    }
  }
`;

export const ImagePreviewContainer = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  text-align: center;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.sidebar.seperator};
  user-select: none;
  box-sizing: border-box;
  overflow: hidden;
`;

export const Divider = styled.div`
  width: 100%;
  opacity: 0.5;
  border-bottom: 2px solid ${({ theme }) => theme.sidebar.seperator};
  margin-top: 25px;
  margin-bottom: 25px;
`;

export const ImagePreviewDescription = styled.span`
  margin-top: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #7a869a;
  display: block;
`;

export const VideoPreview = styled.video`
  height: 100%;
  position: absolute;
  left: 0;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
`;

export const ImagePreviewOverlay = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: 4px;
  background-color: ${({ color }) => color};
`;

export const ColorInputsContainer = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: ${({ firstColumnWidth }) => firstColumnWidth || "32px"} auto;
  column-gap: 5px;
`;

export const ImagesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  align-items: center;
  user-select: none;
  width: 100%;
`;

export const MasonryContainer = styled.div`
  display: flex;
  gap: 12px;

  & > div {
    display: flex;
    flex-direction: column;
    height: min-content;
    gap: 12px;
  }
`;

export const ShowMoreButton = styled.div`
  margin: 20px 0 20px;
  color: ${({ theme }) => theme.global.primaryColor};
  cursor: pointer;
  text-align: center;
  font-weight: 500;
  font-size: 14px;

  &:hover {
    opacity: 0.9;
  }
`;

export const AttachmentsTable = styled.table`
  width: 100%;
  text-align: left;
  margin-top: 15px;

  thead {
    color: #838aad;
    border-bottom: 1px solid ${({ theme }) => theme.sidebar.seperator};
    font-size: 12px;

    td {
      border-bottom: 1px solid ${({ theme }) => theme.sidebar.seperator};
      padding-bottom: 5px;
    }
  }

  tbody {
    font-size: 14px;
    line-height: 1;

    tr {
      cursor: pointer;

      &:hover {
        opacity: 0.85;
      }
    }

    td {
      padding: 4px 0;
    }
  }

  td:last-child {
    text-align: right;
  }

  svg {
    color: #ffab00 !important;
  }
`;

export const ImageThumbnail = styled.div`
  position: relative;
  cursor: grab;
  border-radius: 4px;
  display: grid;
  place-content: center;
  user-select: none;

  & > img {
    display: block;
    width: 183px;
    height: ${({ height }) => height || "auto"};
    border-radius: 2px;
  }

  & > span {
    display: ${({ isSelected }) => (isSelected ? "flex" : "none")};
    position: absolute;
    height: 14px;
    width: 14px;
    left: 2px;
    top: 2px;
    z-index: 1;
    background: #23a5f7;
    color: #fff;
    border-radius: 50%;
  }

  &:hover {
    & > img {
      filter: brightness(0.8);
    }

    & > div,
    & > a {
      display: flex;
    }
  }
`;

export const ImageAttribution = styled.a`
  display: none;
  flex-direction: row;
  align-items: center;
  position: absolute;
  bottom: 6px;
  left: 6px;
  right: 6px;
  color: #fff;

  img {
    border-radius: 50%;
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }

  span {
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const SelectedImg = styled.img`
  display: block;
  max-width: 183px;
  max-height: 118px;
  width: 183px;
  height: 118px;
  object-fit: cover;
  border-radius: 2px;
`;

export const SelectedImgDeleteBtn = styled.div`
  display: none;
  position: absolute;
  z-index: 1;
  right: 0;
  color: #fff;
  background: #07145a;
  border-bottom-left-radius: 4px;

  &:hover {
    opacity: 0.8;
  }
`;

export const ImageLinkPreview = styled.div`
  background: ${({ link }) => `url("${link}")`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 0% 50%;
  width: 100%;
  height: 100%;
  min-height: 250px;
`;
