export const ChangeOperations = {
  CREATE_FIELDS: "CREATE_FIELDS",
  UPDATE_FIELDS: "UPDATE_FIELDS",
  DELETE_FIELDS: "DELETE_FIELDS",
  UPDATE_RECORDS: "UPDATE_RECORDS",
  DELETE_RECORDS: "DELETE_RECORDS",
};

export function handleRecordChange(records, key, change) {
  const newRecords = JSON.parse(JSON.stringify(records));
  const indexOfChangedRecord = newRecords.findIndex((record) => record.accountId === key);
  if (indexOfChangedRecord < 0) {
    return null;
  }
  newRecords[indexOfChangedRecord].values = { ...newRecords[indexOfChangedRecord].values, ...change.values };
  return newRecords;
}

export function handleFieldChange(fields, key, change) {
  const newFields = JSON.parse(JSON.stringify(fields));
  const indexOfChangedField = fields.findIndex((field) => field.id === key);
  if (indexOfChangedField < 0) {
    return null;
  }
  newFields[indexOfChangedField] = { ...newFields[indexOfChangedField], ...change };
  return newFields;
}
