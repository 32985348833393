import React from "react";
import styled from "styled-components";
import { ColumnContainer, Ellipse, InlineContainer, LargeLine, MediumLine, SmallLine, Wrapper } from "./styled";

const CompanyNewsWrapper = styled.div`
  background: ${({ imageUrl }) =>
    `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%), url("${imageUrl}") center center no-repeat;`};
  background-size: cover;
  display: flex;
  flex-direction: column;
  row-gap: 6px;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 20px 20px 15px 20px;
  border-radius: 8px;
`;

const MagazineGridContainer = styled.div`
  display: grid;
  grid-template-columns: ${({ isLargeStory }) => (isLargeStory ? "repeat(4, 1fr)" : "repeat(2, 1fr)")};
  grid-template-rows: ${({ isLargeStory }) => (isLargeStory ? "repeat(4, 1fr)" : "1fr")};
  grid-column-gap: ${({ padding }) => `${padding || 24}px`};
  grid-row-gap: ${({ padding }) => `${padding || 24}px`};
  height: ${({ isLargeStory }) => (isLargeStory ? "350px" : "240px")};
  .child {
    > div,
    > a > div {
      height: calc(100% - 35px) !important;
    }
  }
  .grid-large-story-child-1 {
    grid-area: 1 / 1 / 5 / 3;
  }
  .grid-large-story-child-2 {
    grid-area: 1 / 3 / 3 / 5;
  }
  .grid-large-story-child-3 {
    grid-area: 3 / 3 / 5 / 5;
  }

  .grid-child-3 {
    display: none;
  }
`;

function CompanyNews() {
  const CompanyNews = [
    {
      className: "grid-large-story-child-1 child",
      imageUrl:
        "https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=640&h=480&q=80",
    },
    {
      className: "grid-large-story-child-2 child",
      imageUrl:
        "https://images.unsplash.com/photo-1559361815-8b418ee8721b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=640&h=480&q=80",
    },
    {
      className: "grid-large-story-child-3 child",
      imageUrl:
        "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=640&h=480&q=80",
    },
  ];

  return (
    <Wrapper>
      <MagazineGridContainer isLargeStory padding={24}>
        {CompanyNews.map(({ className, imageUrl }, idx) => (
          <div key={idx} className={className}>
            <CompanyNewsWrapper imageUrl={imageUrl}>
              <InlineContainer>
                <Ellipse />
                <SmallLine width={32} style={{ marginTop: "2px" }} />
                <SmallLine width={40} style={{ marginTop: "2px" }} />
              </InlineContainer>

              <ColumnContainer>
                <MediumLine width={185} />
                {idx !== 1 && <MediumLine width={146} />}
              </ColumnContainer>

              <InlineContainer>
                {idx !== 2 && <LargeLine width={44} style={{ backgroundColor: "rgba(255 255 255 / 20%)" }} />}
                <LargeLine width={38} style={{ backgroundColor: "rgba(255 255 255 / 20%)" }} />
              </InlineContainer>
            </CompanyNewsWrapper>
          </div>
        ))}
      </MagazineGridContainer>
    </Wrapper>
  );
}

export default CompanyNews;
