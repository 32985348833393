import React from "react";
import styled from "styled-components";
import Toggle from "@atlaskit/toggle";
import { FieldContainer, Label } from "./styled";

const ElementWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0px 8px 12px;
`;

const ElementLabel = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.shared.sidebar.title};
`;

function ElementsToggle({ elements = [], options, updateElements, label, isDisabled = false }) {
  return (
    <FieldContainer>
      <Label>{label}</Label>

      {options.map((option) => (
        <ElementWrapper key={option.value}>
          <ElementLabel>{option.label}</ElementLabel>
          <Toggle
            isChecked={!!elements.find((el) => el === option.value)}
            onChange={() => updateElements(option.value)}
            isDisabled={isDisabled}
          />
        </ElementWrapper>
      ))}
    </FieldContainer>
  );
}

export default ElementsToggle;
