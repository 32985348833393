import Apps from "./components/Apps";
import Background from "./components/Background";
import Icons from "./components/Icons";
import Permissions from "./components/Permissions";

export const HelperDrawerTypes = {
  APPS: "apps",
  ICONS: "icons",
  PERMISSIONS: "permissions",
  BACKGROUND: "background",
};

export const HelperDrawerContent = {
  [HelperDrawerTypes.APPS]: Apps,
  [HelperDrawerTypes.BACKGROUND]: Background,
  [HelperDrawerTypes.ICONS]: Icons,
  [HelperDrawerTypes.PERMISSIONS]: Permissions,
};

export const Titles = {
  [HelperDrawerTypes.APPS]: "Apps",
  [HelperDrawerTypes.BACKGROUND]: "Background",
  [HelperDrawerTypes.ICONS]: "Icons",
  [HelperDrawerTypes.PERMISSIONS]: "Permissions",
};
