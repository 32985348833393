import React from "react";
import { Box, Flex, Text, xcss } from "@atlaskit/primitives";

export const FieldContainer = ({ children }) => <Box style={{ marginTop: "20px" }}>{children}</Box>;

export const FieldLabel = ({ children }) => (
  <Text color="color.text" weight="medium" size="small">
    {children}
  </Text>
);

export const FieldColumn = ({ children, style = {} }) => (
  <Flex alignItems="center" xcss={xcss({ ...style })}>
    {children}
  </Flex>
);

export const FieldInline = ({ children, style = {} }) => (
  <Flex alignItems="center" justifyContent="space-between" xcss={xcss({ ...style })}>
    {children}
  </Flex>
);
