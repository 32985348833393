import React, { useEffect, useMemo } from "react";
import styled from "styled-components";
import eventBus from "../../../../../Shared/Functions/eventBus";
import Button from "./actions/Button";
import Range from "./actions/Range";
import MinusPlus from "./actions/MinusPlus";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 36px;

  background: transparent;

  ${({ disableAbsolute }) => {
    if (disableAbsolute) return ``;

    return `
      position: absolute;
      z-index: 5;
      top: 16px;
      left: 24px;
      right: 24px;
      width: calc(100% - 48px);
    `;
  }}
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 12px;
`;

const ZOOM_MAX = 200;
const ZOOM_MIN = 30;
const ZOOM_STEP = 5;

function ActionsToolbar({
  structure,
  enableActionsAlways = false,
  disableAbsolute = false,
  zoom = 100,
  setZoom = () => {},
}) {
  const areActionsDisabled = useMemo(
    () => !structure?.children?.length && !enableActionsAlways,
    [structure, enableActionsAlways],
  );

  useEffect(() => {
    eventBus.on("caelor-zoom-update-in-reducer", ({ zoomLevel }) => setZoom(zoomLevel));

    return () => {
      eventBus.remove("caelor-zoom-update-in-reducer");
    };
  }, [setZoom]);

  const dispatchZoomUpdateEvent = (newZoomLevel) => {
    eventBus.dispatch("caelor-update-zoom", { zoomLevel: newZoomLevel / 100 });
  };

  const handleZoomDecrement = () => {
    let newZoomLevel = zoom - ZOOM_STEP;
    if (newZoomLevel <= ZOOM_MIN) {
      newZoomLevel = ZOOM_MIN;
    }

    setZoom(newZoomLevel);
    dispatchZoomUpdateEvent(newZoomLevel);
  };

  const handleZoomIncrement = () => {
    let newZoomLevel = zoom + ZOOM_STEP;
    if (newZoomLevel >= ZOOM_MAX) {
      newZoomLevel = ZOOM_MAX;
    }
    setZoom(newZoomLevel);
    dispatchZoomUpdateEvent(newZoomLevel);
  };

  return (
    <Wrapper disableAbsolute={disableAbsolute}>
      <ButtonsContainer></ButtonsContainer>
      <ButtonsContainer>
        <Button
          isDisabled={areActionsDisabled}
          onClick={() => {
            eventBus.dispatch("caelor-handle-center-view");
          }}
          text="Center view"
        />

        <Range
          value={zoom}
          onChange={(newZoom) => {
            setZoom(newZoom);
            dispatchZoomUpdateEvent(newZoom);
          }}
          min={ZOOM_MIN}
          max={ZOOM_MAX}
          step={ZOOM_STEP}
          isDisabled={areActionsDisabled}
        />

        <MinusPlus
          value={zoom}
          onDecrementClick={handleZoomDecrement}
          onIncrementClick={handleZoomIncrement}
          isDisabled={areActionsDisabled}
        />
      </ButtonsContainer>
    </Wrapper>
  );
}

export default ActionsToolbar;
