import React, { useMemo } from "react";
import Transition from "../Transition";
import { useSidebarStore } from "../../../store";
import { HelperDrawerContent, HelperDrawerTypes, Titles } from "./types";

function HelperDrawer() {
  const { isHelperDrawerOpen, selectedHelperDrawerType, closeHelperDrawer } = useSidebarStore((state) => ({
    isHelperDrawerOpen: state.isHelperDrawerOpen,
    selectedHelperDrawerType: state.selectedHelperDrawerType,
    closeHelperDrawer: state.closeHelperDrawer,
  }));

  const Content = useMemo(() => HelperDrawerContent[selectedHelperDrawerType], [selectedHelperDrawerType]);

  if (!Content) return <></>;

  if (selectedHelperDrawerType === HelperDrawerTypes.BACKGROUND) return <Content />;

  return (
    <Transition
      isOpen={isHelperDrawerOpen}
      close={() => closeHelperDrawer()}
      width={415}
      title={Titles[selectedHelperDrawerType]}
    >
      <Content />
    </Transition>
  );
}

export default HelperDrawer;
