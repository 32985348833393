import { PanelTypes as DashboardPanelTypes, PanelKeys as DashboardPanelKeys } from "../../Dashboard/panels/panelTypes";

export const PanelTypes = {
  TEAM: "know_your_team",
  BIRTHDAY: "upcoming_birthdays",
  ACTIVE_USERS: "recently_active_users",
  NEW_STARTERS: "new_starters",
  BLOG: "user_blog_posts",
  EMPLOYEES: "all_employees",
  CARDS: DashboardPanelTypes.CARDS,
};

export const PanelKeys = {
  KNOW_YOUR_TEAM: "know_your_team",
  UPCOMING_BIRTHDAY: "upcoming_birthdays",
  RECENTLY_ACTIVE_USERS: "recently_active_users",
  NEW_STARTERS: "new_starters",
  USER_BLOG_POSTS: "user_blog_posts",
  ALL_EMPLOYEES: "all_employees",
  WORK_ANNIVERSARY: "work-aniversary-main",
  ICON_CARDS: DashboardPanelKeys.ICON_CARDS,
  TEXT_CARDS: DashboardPanelKeys.TEXT_CARDS,
  IMG_CARDS: DashboardPanelKeys.IMG_CARDS,
};
