import React, { useState, useEffect } from "react";
import DisableDynamicFilterToggle, { LoadingTypes } from "../../../filter/DynamicFilterToggle";
import { AddNewContentContainer, Divider, FieldContainer, Label } from "../../../filter/styled";
import TextInputFilter from "../../../filter/TextInputFilter";
import { TabTypes } from "../../tabTypes";
import SpaceMultiSelect from "../../../filter/SpaceMultiSelect";
import ManualSpacesList from "../../../filter/ManualSpacesList";
import InputFilter from "../../../filter/InputFilter";
import LabelMultiSelect from "../../../filter/LabelMultiSelect";
import SelectFilter from "../../../filter/SelectFilter";
import { useCorporateIdentityStore } from "../../../../Settings/General/BrandAndColors/corporate-identity-store";
import { COLOR_TYPES } from "../../../../Settings/General/BrandAndColors/color-types";
import SelectWithCustomOptions from "../../../filter/SelectWithCustomOptions";
import { SpacesTypes } from "../../types/spaces/Spaces";
import { DesignSidebarWrapper } from "./styled";
import SimpleToggle from "../../../filter/SimpleToggle";
import { PanelsPaginationTypes } from "../../../../Shared/PanelsPagination/panels-pagination-types";
import { PaginationOptions } from "../../../../Shared/PanelsPagination/pagination-options";
import ElementsToggle from "../../../filter/ElementsToggle";

const SpacesPropertyKeys = {
  VIEW: "view",
  PAGINATION: "pagination",
  MAX_ITEMS: "maxNumberOfItems",
  LOADING_TYPE: "loadingType",
  LABELS: "labels",
  MANUALLY_PICKED: "manuallyPicked",
  TYPE: "spaceType",
  STATUS: "spaceStatus",
  DISPLAY_TITLE: "displayTitle",
  ACTIVE_ELEMENTS: "activeElements",
  DISPLAY_BOX: "displayBox",
};

const elementsToDisplay = {
  [SpacesTypes.CARD]: [
    { label: "Author", value: "author" },
    { label: "Description", value: "description" },
  ],
  [SpacesTypes.MINI_CARD]: [{ label: "Author", value: "author" }],
  [SpacesTypes.LIST]: [
    { label: "Author", value: "author" },
    { label: "Admins", value: "admins" },
    { label: "Categories", value: "categories" },
  ],
};

const sideElementsToDisplay = [{ label: "Author", value: "author" }];

const ManualSidebar = ({ panelId, data, updatePanelDataProperty }) => {
  const [pickerKey, setPickerKey] = useState(Math.random().toString(36).substring(7));
  const colors = useCorporateIdentityStore((state) => state.colors);
  const primaryColor = colors ? colors[COLOR_TYPES.PRIMARY] : "#0065ff";

  const addNewContentManually = (newItem) => {
    const newManuallyPicked = [...data.manuallyPicked, newItem];
    updatePanelDataProperty(panelId, SpacesPropertyKeys.MANUALLY_PICKED, newManuallyPicked);
  };

  const removeContentManually = (itemId) => {
    const newManuallyPicked = data.manuallyPicked.filter((item) => item.id !== itemId);
    updatePanelDataProperty(panelId, SpacesPropertyKeys.MANUALLY_PICKED, newManuallyPicked);
  };

  function generateId() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
      const r = (Math.random() * 16) | 0,
        v = c === "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  useEffect(() => {
    setPickerKey(Math.random().toString(36).substring(7));
  }, [data.manuallyPicked]);

  return (
    <>
      <AddNewContentContainer>
        <div style={{ flex: 1 }}>
          <SpaceMultiSelect
            manuallyPicked={data.manuallyPicked}
            key={pickerKey}
            containerStyle={{
              flex: 1,
              marginTop: "0px",
            }}
            placeholder="Select space(s)"
            label=""
            isMulti={false}
            onUpdate={(space) => {
              if (data.manuallyPicked.some((item) => item.spaceKey === space.spaceKey)) return;
              if (data.manuallyPicked.length > 0) {
                const currentLastPicked = data.manuallyPicked[data.manuallyPicked.length - 1];
                const position = currentLastPicked.position + 1;
                addNewContentManually({
                  id: generateId(),
                  position,
                  spaceKey: space.spaceKey,
                });
                setPickerKey(Math.random().toString(36).substring(7));
                return;
              }
              setPickerKey(Math.random().toString(36).substring(7));
              addNewContentManually({
                id: generateId(),
                position: 0,
                spaceKey: space.spaceKey,
              });
            }}
          />
        </div>
      </AddNewContentContainer>

      <ManualSpacesList
        manuallyPicked={data.manuallyPicked}
        onRemove={(id) => removeContentManually(id)}
        updateOrder={(items) => {
          const newManuallyPicked = items.map(({ id, position, key }) => ({
            id,
            position,
            spaceKey: key,
          }));
          updatePanelDataProperty(panelId, SpacesPropertyKeys.MANUALLY_PICKED, newManuallyPicked);
        }}
      />
    </>
  );
};

const LayoutSidebar = ({ panelId, data, updatePanelDataProperty, panelTitle, updatePanelTitle }) => {
  const isSideNews = !!data?.isSideNews;

  const activeElements = data.activeElements || [];

  const updateElements = (value) => {
    const newElements = !!activeElements.find((el) => el === value)
      ? activeElements.filter((el) => el !== value)
      : [...activeElements, value];
    updatePanelDataProperty(panelId, SpacesPropertyKeys.ACTIVE_ELEMENTS, newElements, true);
  };

  return (
    <>
      <TextInputFilter label="Title" value={panelTitle} onUpdate={(value) => updatePanelTitle(panelId, value)} />
      <Divider />
      {!isSideNews && (
        <SelectWithCustomOptions
          initialValue={data.view}
          options={[
            {
              label: "Compact Card",
              value: SpacesTypes.CARD,
            },
            {
              label: "Mini Card",
              value: SpacesTypes.MINI_CARD,
            },
            { label: "List", value: SpacesTypes.LIST },
          ]}
          label="View"
          onUpdate={(newView) => updatePanelDataProperty(panelId, SpacesPropertyKeys.VIEW, newView)}
        />
      )}

      {data.loadingType === LoadingTypes.DYNAMIC && (
        <SelectWithCustomOptions
          initialValue={
            data.pagination === PanelsPaginationTypes.INFINITE ? PanelsPaginationTypes.SHOW_MORE : data.pagination
          }
          options={PaginationOptions}
          label="Pagination"
          onUpdate={(newValue) => updatePanelDataProperty(panelId, SpacesPropertyKeys.PAGINATION, newValue)}
        />
      )}

      {isSideNews && data.loadingType === LoadingTypes.DYNAMIC && (
        <InputFilter
          numberField
          minLength={1}
          initialValue={data.maxNumberOfItems}
          label="Items per page"
          placeholder="Limit the number of pages"
          onUpdate={(value) => updatePanelDataProperty(panelId, SpacesPropertyKeys.MAX_ITEMS, value)}
        />
      )}

      <ElementsToggle
        label="Elements to display"
        elements={data.activeElements}
        options={elementsToDisplay[data.view] || sideElementsToDisplay}
        updateElements={updateElements}
      />
    </>
  );
};

const FilterSidebar = ({ panelId, data, updatePanelDataProperty }) => {
  return (
    <>
      <DisableDynamicFilterToggle
        initialValue={data.loadingType}
        onUpdate={(newLoadingType) => updatePanelDataProperty(panelId, SpacesPropertyKeys.LOADING_TYPE, newLoadingType)}
      />

      {data.loadingType === LoadingTypes.DYNAMIC && (
        <>
          <LabelMultiSelect
            label="Categories"
            placeholder="Filter by categories"
            initialLabels={data.labels}
            onUpdate={(labels) => {
              updatePanelDataProperty(panelId, SpacesPropertyKeys.LABELS, labels);
            }}
          />

          <SelectFilter
            label="Type"
            value={data.spaceType || "global"}
            setValue={(v) => updatePanelDataProperty(panelId, SpacesPropertyKeys.TYPE, v)}
            options={[
              { label: "Communal", value: "global" },
              { label: "Personal", value: "personal" },
            ]}
          />

          <SelectFilter
            label="Status"
            value={data.spaceStatus || "current"}
            setValue={(v) => updatePanelDataProperty(panelId, SpacesPropertyKeys.STATUS, v)}
            options={[
              { label: "Active", value: "current" },
              { label: "Archived", value: "archived" },
            ]}
          />
        </>
      )}

      {data.loadingType === LoadingTypes.MANUAL && (
        <ManualSidebar panelId={panelId} data={data} updatePanelDataProperty={updatePanelDataProperty} />
      )}
    </>
  );
};

const DesignSidebar = ({ panelId, data, updatePanelDataProperty }) => {
  const isSideNews = !!data?.isSideNews;
  const displayBox = data.displayBox ?? true;
  return (
    <DesignSidebarWrapper>
      <div>
        <FieldContainer>
          <Label>Widget options</Label>
          <SimpleToggle
            label="Display title"
            value={data.displayTitle}
            setValue={(value) => updatePanelDataProperty(panelId, SpacesPropertyKeys.DISPLAY_TITLE, value)}
            customStyles={{ padding: "10px 0px 8px 10px" }}
          />

          {!isSideNews && (
            <SimpleToggle
              label="Display box"
              value={displayBox}
              setValue={(value) => updatePanelDataProperty(panelId, SpacesPropertyKeys.DISPLAY_BOX, value)}
              customStyles={{ padding: "10px 0px 8px 10px" }}
            />
          )}
        </FieldContainer>
      </div>
    </DesignSidebarWrapper>
  );
};

function SpacesEditor({ panel, selectedTab, updatePanelDataProperty, updatePanelTitle }) {
  const { data } = panel;

  return (
    <div>
      {selectedTab === TabTypes.LAYOUT && (
        <LayoutSidebar
          panelId={panel.id}
          data={data}
          updatePanelDataProperty={updatePanelDataProperty}
          panelTitle={panel.panelTitle}
          updatePanelTitle={updatePanelTitle}
        />
      )}

      {selectedTab === TabTypes.CONTENT && (
        <FilterSidebar panelId={panel.id} data={data} updatePanelDataProperty={updatePanelDataProperty} />
      )}

      {selectedTab === TabTypes.DESIGN && (
        <DesignSidebar panelId={panel.id} data={data} updatePanelDataProperty={updatePanelDataProperty} />
      )}
    </div>
  );
}

export default SpacesEditor;
