export const fileToDataUri = (file) =>
  new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      resolve(event.target.result);
    };
    reader.readAsDataURL(file);
  });

export const validateAttachments = async (dataFiles) => {
  if (dataFiles.length) {
    const files = [];
    for (let i = 0; i < dataFiles.length; i += 1) {
      files.push(dataFiles[i]);
    }

    const result = await Promise.all(
      files.map(async (file) => {
        const url = await fileToDataUri(file);
        return { file, url };
      })
    );
    return result;
  }
  return undefined;
};
