import React from "react";
import styled from "styled-components";

const EditButton = styled.div`
  width: 48px;
  height: 48px;
  background: ${({ primaryColor }) => primaryColor};
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
  border-radius: 3px;

  position: fixed;
  bottom: 48px;
  right: 48px;
  z-index: 89;

  display: grid;
  place-content: center;
  cursor: pointer;
  ${({ isDisabled }) => {
    if (isDisabled) {
      return `
        pointer-events: none;
      `;
    }
  }}
  @media print {
    display: none !important;
  }

  &:hover {
    opacity: 0.9;
  }
`;

const EditPenIcon = ({ color }) => (
  <svg width="13" height="13" viewBox="0 0 13 13" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.14806 0.852087C9.24265 0.759064 9.37022 0.707266 9.50288 0.708016C9.63555 0.708765 9.76253 0.762001 9.85606 0.856087L12.1441 3.14409C12.1909 3.19027 12.2281 3.24524 12.2537 3.30584C12.2792 3.36644 12.2926 3.43149 12.293 3.49726C12.2933 3.56302 12.2807 3.62822 12.2559 3.68911C12.231 3.74999 12.1944 3.80538 12.1481 3.85209L5.00006 11.0001L2.00006 8.00009L9.14806 0.852087ZM1.00006 9.00009L4.00006 12.0001L0.490058 12.8771C0.220058 12.9451 0.0540588 12.7851 0.123059 12.5101L1.00006 9.00009Z"
      fill={color}
    />
  </svg>
);

function EditPanelsButton({ openSidebar, primaryColor = "#0065ff", primaryContrastColor = "#fff", isDisabled }) {
  return (
    <EditButton primaryColor={primaryColor} onClick={() => openSidebar()} isDisabled={isDisabled}>
      <EditPenIcon color={primaryContrastColor} />
    </EditButton>
  );
}

export default EditPanelsButton;
