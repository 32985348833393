import styled from "styled-components";
import { convertHexToRGB } from "../../../../Shared/Components/Background/convertHexToRgb";

export const LinkSidebarContainer = styled.div`
  padding: 10px 0px;
`;

export const SpinnerContainer = styled.div`
  display: grid;
  place-content: center;
  margin-top: 25px;
`;

export const ListItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0px 0px 0px;
  max-height: calc(100vh - 260px);
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.scrollbar};
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #999;
  }
`;

export const SearchSelectContainer = styled.div`
  display: grid;
  grid-template-columns: 2.7fr 1fr;
  column-gap: 20px;
`;

export const ListItem = styled.div`
  display: grid;
  grid-template-columns: 30px 3fr 1.5fr;
  column-gap: 10px;
  align-items: center;
  border-radius: 3px;
  padding: 5px 0px;

  background-color: ${({ isSelected }) => (isSelected ? "rgba(7, 20, 90, 0.1)" : "transparent")};

  cursor: ${({ isClickable }) => (isClickable ? "pointer" : "default")};

  &:hover {
    background-color: ${({ isClickable }) => (isClickable ? "rgba(7, 20, 90, 0.05)" : "transparent")};
  }

  &:active {
    background-color: ${({ isClickable }) => (isClickable ? "rgba(7, 20, 90, 0.1)" : "transparent")};
  }
`;

export const HeaderLabels = styled.div`
  color: ${({ theme }) => theme.shared.settings.section.title};
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  opacity: 0.5;
`;

export const ListType = styled.div`
  width: 24px;
  height: 22px;
  display: grid;
  place-content: center;
`;

const Span = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
`;

export const ListTitle = styled(Span)`
  color: ${({ theme }) => theme.navigation.settings.linkSidebar.listTitle};
  font-weight: 400;
  line-height: 16px;
  user-select: none;
`;

export const ListSpace = styled(Span)`
  color: ${({ theme }) => theme.navigation.settings.linkSidebar.listSpace};
  user-select: none;
`;

export const AttachmentsUploadBox = styled.div`
  margin-top: 20px;
  box-sizing: border-box;
  border: 1px dashed ${({ theme }) => theme.shared.form.select.borderColor};
  color: ${({ theme }) => theme.shared.settings.section.title};
  border-radius: 4px;
  padding: 25px 0px;

  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 20px;
`;

export const AttachmentsTitle = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: inherit;
`;

export const AttachmentsDesc = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: inherit;
`;

export const AttachmentsLink = styled.label`
  color: #0052cc;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export const AlertContainer = styled.div`
  padding-top: 15px;
`;

export const AlertMsg = styled.div`
  font-size: 14px;
  line-height: 14px;
  color: #07145a;

  display: flex;
  align-items: center;
  column-gap: 15px;
`;

export const LinkImageTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
`;

export const LinkText = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.navigation.settings.linkSidebar.emailDescription};
  text-align: center;
`;

export const LinkTitle = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${({ theme }) => theme.navigation.settings.linkSidebar.emailTitle};
  padding: 40px 0px 20px 0px;
`;

export const PrimaryButton = styled.div`
  padding: 0px 12px;
  height: 32px;
  background: #0065ff;
  border-radius: 3px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  white-space: nowrap;
  display: grid;
  place-content: center;
  cursor: pointer;

  &:hover {
    background-color: #579dff;
  }
`;

export const DirectLinkText = styled.p`
  color: #626f86;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
`;

export const TextfieldWrapper = styled.div`
  width: 100%;
  margin-top: 15px;
  margin-right: 15px;
  margin-bottom: 15px;

  & > div:first-child {
    background: ${({ theme }) => theme.shared.form.input.background};
    border: 2px solid ${({ theme }) => theme.shared.form.select.borderColor};
    color: ${({ theme }) => theme.shared.form.select.value};
  }
`;

export const LinkWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Divider = styled.div`
  border-top: 1px solid ${({ theme }) => theme.navigation.settings.linkSidebar.seperator};
  margin-top: 24px;
  margin-bottom: 5px;
`;

export const LinkPagesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 20px;
`;

export const PageLinkWrapper = styled.div`
  width: 420px;
  height: 44px;
  border-radius: 3px;
  padding-left: 2px;
  margin-bottom: 10px;

  &:hover {
    cursor: pointer;
    background: ${({ theme }) => theme.navigation.settings.linkSidebar.linkWrapperHover};
  }
`;

export const LinkButton = styled.p`
  color: ${({ theme }) => theme.navigation.settings.linkSidebar.linkButton};
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 20px;
  text-decoration: none;
`;
