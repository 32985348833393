import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Toggle from "@atlaskit/toggle";
import { FieldContainer, Label } from "./styled";

const ElementWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0px 8px 12px;
`;

const ElementLabel = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.shared.sidebar.title};
`;

function SimpleElementsToggle({ label, elements, onChange }) {
  const updateElements = (id, newCheckedValue) => {
    onChange(elements.map((el) => (el.id === id ? { ...el, isChecked: newCheckedValue } : el)));
  };

  return (
    <FieldContainer>
      <Label>{label}</Label>

      {elements.map(({ id, label, isChecked }) => (
        <ElementWrapper key={id}>
          <ElementLabel>{label}</ElementLabel>
          <Toggle isChecked={isChecked} onChange={() => updateElements(id, !isChecked)} />
        </ElementWrapper>
      ))}
    </FieldContainer>
  );
}

SimpleElementsToggle.propTypes = {
  label: PropTypes.string,
  elements: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      isChecked: PropTypes.bool.isRequired,
    }),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default SimpleElementsToggle;
