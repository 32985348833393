import React, { Fragment, useEffect, useState } from "react";
import styled, { useTheme } from "styled-components";
import { useInfiniteQuery, useQueryClient } from "react-query";
import { mapSpace, searchSpaces } from "../../../api";
import { LoadingTypes } from "../../../filter/DynamicFilterToggle";
import { useSpaces } from "../../../filter/useSpaces";
import { Pages } from "../../../../../pages";
import { usePageStore } from "../../../../../page-store";
import { useSidebarStore } from "../../edit/sidebar/sidebar-store";
import { useCorporateIdentityStore } from "../../../../Settings/General/BrandAndColors/corporate-identity-store";
import { COLOR_TYPES } from "../../../../Settings/General/BrandAndColors/color-types";
import { CardSpace, ListSpace, MiniCardSpace, SideSpace } from "@caelor/cards-and-panels-components";
import { getAdminGroups, getAdminUsers } from "../../../../SpaceDirectory/Cards/dataHelpers";
import { getUsersForArrayOfGroups } from "../../../../SpaceDirectory/apiSpaces";
import { EmptyForFilters, EmptyManual, EmptyNoSpaces } from "./EmptyStates";
import { Actions } from "./SpacesAction";
import Pagination from "../../../../Shared/PanelsPagination/Pagination";
import PanelWrapper from "../../../../Shared/Panels/shared/components/PanelWrapper";
import { PanelsPaginationTypes } from "../../../../Shared/PanelsPagination/panels-pagination-types";

const SpacesContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 20px;
  max-width: 260px;
`;

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;
const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  column-gap: 14px;
  row-gap: 20px;
  margin-top: 20px;
`;
const OneColumnGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
`;
const CardWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 4fr 3fr 2.5fr 5fr 90px;
  align-items: center;
  column-gap: 10px;
  padding: 8px 0px;
`;
const HeaderTitle = styled.span`
  font-weight: 600;
  font-size: 11px;
  line-height: 13px;
  color: ${({ theme }) => theme.space.listView.headerTitleColor};
`;

export const SpacesTypes = {
  CARD: "card",
  LIST: "list",
  MINI_CARD: "mini-card",
};

const ListHeader = ({ elementsToDisplay }) => {
  const theme = useTheme();

  const { createdBy, admins, categories } = elementsToDisplay;

  return (
    <CardWrapper
      style={{
        padding: "0px 0px 5px 0px",
        marginBottom: "7px",
        borderBottom: `1px solid ${theme.space.listView.cardWrapperBorderBottomColor}`,
      }}
    >
      <HeaderTitle>Space name</HeaderTitle>
      <HeaderTitle>{createdBy && "Created by"}</HeaderTitle>
      <HeaderTitle>{admins && "Admins"}</HeaderTitle>
      <HeaderTitle>{categories && "Categories"}</HeaderTitle>
      <HeaderTitle>Actions</HeaderTitle>
    </CardWrapper>
  );
};

function Spaces({ id, panelTitle, data, position }) {
  const [showMoreClicked, setShowMoreClicked] = useState(false);
  const [admins, setAdmins] = useState([]);
  const [page, setPage] = useState(1);
  const [maxPages, setMaxPages] = useState(1);

  const queryClient = useQueryClient();

  const theme = useTheme();

  const setSelectedPage = usePageStore((state) => state.setSelectedPage);

  const setDisableClickOutside = useSidebarStore((state) => state.setDisableClickOutside);

  const { colors, borderRadius } = useCorporateIdentityStore((state) => ({
    colors: state.colors,
    borderRadius: state.borderRadius,
  }));

  const { displayTitle, displayBox = true } = data;

  const primaryColor = colors ? colors[COLOR_TYPES.PRIMARY] : "#0065ff";

  const maxNumberOfItems = data.maxNumberOfItems || 4;

  const {
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    data: spacesData,
  } = useInfiniteQuery(
    [
      `spaces-${id}`,
      {
        limit: maxNumberOfItems,
        spaceType: data.spaceType,
        spaceStatus: data.spaceStatus,
        categories: data.labels,
      },
    ],
    searchSpaces,
    {
      retry: 0,
      enabled: data.loadingType === LoadingTypes.DYNAMIC,
      select: (response) => {
        const pages = [];
        response.pages.forEach((page) => {
          const body = JSON.parse(page.body);
          const resultsInPage = body.results.map((space) => mapSpace(space));
          pages.push({ ...body, results: resultsInPage });
        });
        return { pages, pageParams: response.pageParams };
      },
      getNextPageParam: (lastPage, allPages) => {
        const body = JSON.parse(lastPage.body);
        if (body._links.next) {
          return allPages.length * maxNumberOfItems;
        }
        return false;
      },
    },
  );

  useEffect(() => {
    setPage(1);
    setShowMoreClicked(false);
    queryClient.resetQueries([`spaces-${id}`]);
  }, [data.pagination, data.maxNumberOfItems, data.view, data.spaceType, data.spaceStatus, data.labels]);

  useEffect(() => {
    if (hasNextPage && !isFetchingNextPage) {
      setMaxPages(page + 1);
    }

    if (!hasNextPage && !isFetchingNextPage) {
      setMaxPages(page);
    }
  }, [page, hasNextPage, isFetchingNextPage]);

  useEffect(() => {
    if (!spacesData?.pages?.length) return;

    const loadAdminData = async () => {
      try {
        const adminData = await (async () => {
          const results = await Promise.all(
            spacesData.pages.map(async (page) => {
              const data = await Promise.all(
                page.results.map(async (space) => {
                  const adminUsers = getAdminUsers(space.permissions);
                  const adminGroups = getAdminGroups(space.permissions);
                  const allUsersFromGroups = await getUsersForArrayOfGroups(adminGroups);
                  const users = allUsersFromGroups.flat();

                  const allUsers = [...new Set([...adminUsers, ...users])].filter(
                    (value, index, self) => index === self.findIndex((t) => t.id === value.id),
                  );
                  return { key: space.key, admins: allUsers };
                }),
              );
              return data;
            }),
          );
          return results.flat();
        })();
        setAdmins((prevAdmins) => [...prevAdmins, ...adminData]);
      } catch (error) {
        setAdmins([]);
      }
    };

    loadAdminData();
  }, [spacesData?.pages, data.loadingType]);

  const spaceQueriesUnfiltered = useSpaces(data.manuallyPicked || []);
  const spaceQueries = spaceQueriesUnfiltered.filter((space) => space.data && !!Object.keys(space.data || {}).length);
  const paginatedSpacesData =
    data.pagination === PanelsPaginationTypes.ARROWS ? [spacesData?.pages[page - 1]] : spacesData?.pages;

  if (data.loadingType === LoadingTypes.MANUAL) {
    if (!spaceQueries?.length) {
      return <EmptyManual panelTitle={panelTitle} displayTitle={data.displayTitle} />;
    }
    return (
      <PanelWrapper panelTitle={panelTitle} displayOptions={{ displayTitle, displayBox }}>
        {!!data?.isSideNews && (
          <SpacesContainer>
            {spaceQueries?.map((space) => (
              <SideSpace
                key={space?.data?.id}
                name={space?.data?.name}
                url={space?.data?.url}
                authorUrl={`${window.AP._hostOrigin}/wiki/people/${space?.data?.creatorId}`}
                description={space?.data?.description}
                spaceIconUrl={space?.data?.icon}
                primaryColor={primaryColor}
                createdBy={data?.activeElements?.includes("author") ? space.data.createdBy : null}
                lastModified={space?.data?.createdFormatted}
                avatarUrl={space?.data?.avatarUrl}
                spaceKey={space?.data?.key}
                coverPictureBorderRadius={borderRadius.replace("px", "")}
                darkTheme={{
                  titleColor: theme.shared.card.title,
                }}
                coverPictureCallback={() => {}}
                backgroundMode="none"
              />
            ))}
          </SpacesContainer>
        )}
        {data.view === SpacesTypes.CARD && (
          <Grid style={{ marginTop: "0px" }}>
            {spaceQueries?.map((space) => (
              <CardSpace
                key={space?.data?.id}
                name={space?.data?.name}
                url={space?.data?.url}
                authorUrl={`${window.AP._hostOrigin}/wiki/people/${space?.data?.creatorId}`}
                description={space?.data?.description}
                spaceIconUrl={space?.data?.icon}
                primaryColor={primaryColor}
                createdBy={data?.activeElements?.includes("author") ? space.data.createdBy : null}
                lastModified={space?.data?.createdFormatted}
                avatarUrl={space?.data?.avatarUrl}
                overlay={{
                  color: "#000000",
                  opacity: 0.2,
                  isActive: false,
                }}
                spaceKey={space?.data?.key}
                coverPictureBorderRadius={borderRadius.replace("px", "")}
                darkTheme={{
                  backgroundColor: theme.shared.card.background,
                  titleColor: theme.shared.card.title,
                  borderColor: theme.shared.card.border.box,
                  boxShadow: theme.manualOrgChart.boxShadow,
                  createdBy: primaryColor,
                  authorColor: primaryColor,
                  descriptionColor: theme.shared.form.input.text,
                }}
                coverPictureCallback={() => {}}
                backgroundMode="none"
              >
                <Actions data={space.data} view={data.view} />
              </CardSpace>
            ))}
          </Grid>
        )}
        {data.view === SpacesTypes.MINI_CARD && (
          <Grid style={{ marginTop: "0px" }}>
            {spaceQueries?.map((space) => (
              <MiniCardSpace
                key={space?.data?.id}
                name={space?.data?.name}
                url={space?.data?.url}
                authorUrl={`${window.AP._hostOrigin}/wiki/people/${space?.data?.creatorId}`}
                description={space?.data?.description}
                spaceIconUrl={space?.data?.icon}
                primaryColor={primaryColor}
                createdBy={data?.activeElements?.includes("author") ? space.data.createdBy : null}
                avatarUrl={space?.data?.avatarUrl}
                spaceKey={space?.data?.key}
                coverPictureBorderRadius={borderRadius.replace("px", "")}
                darkTheme={{
                  backgroundColor: theme.shared.card.background,
                  titleColor: theme.shared.card.title,
                  textColor: primaryColor,
                  boxShadow: theme.manualOrgChart.boxShadow,
                }}
                coverPictureCallback={() => {}}
                backgroundMode="none"
              >
                <Actions data={space.data} view={data.view} />
              </MiniCardSpace>
            ))}
          </Grid>
        )}
        {data.view === SpacesTypes.LIST && (
          <ListWrapper style={{ marginTop: "0px" }}>
            <ListHeader
              elementsToDisplay={{
                createdBy: data?.activeElements?.includes("author"),
                admins: data?.activeElements?.includes("admins"),
              }}
            />
            <OneColumnGrid>
              {spaceQueries?.map((space) => (
                <ListSpace
                  key={space?.data?.id}
                  name={space?.data?.name}
                  url={space?.data?.url}
                  authorUrl={`${window.AP._hostOrigin}/wiki/people/${space?.data?.creatorId}`}
                  description={space?.data?.description}
                  spaceIconUrl={space?.data?.icon}
                  primaryColor={primaryColor}
                  labels={space?.data?.labels}
                  createdBy={data?.activeElements?.includes("author") ? space.data.createdBy : null}
                  lastModified={space?.data?.createdFormatted}
                  admins={admins?.find((adminGroup) => adminGroup.key === space.data.key)?.admins}
                  avatarUrl={space?.data?.avatarUrl}
                  spaceKey={space?.data?.key}
                  coverPictureBorderRadius={borderRadius.replace("px", "")}
                  darkTheme={{
                    backgroundColor: theme.shared.card.background,
                    titleColor: theme.shared.card.title,
                    textColor: theme.shared.card.text,
                    borderColor: theme.shared.card.border.box,
                    authorColor: primaryColor,
                    categories: theme.shared.label.background,
                    categoriesText: theme.shared.label.text,
                  }}
                  coverPictureCallback={() => {}}
                  backgroundMode="none"
                >
                  <Actions data={space.data} view={data.view} />
                </ListSpace>
              ))}
            </OneColumnGrid>
          </ListWrapper>
        )}
      </PanelWrapper>
    );
  }

  if (!!spacesData?.pages?.length && !spacesData.pages[0].results.length) {
    if (!!data.labels) {
      return <EmptyForFilters panelTitle={panelTitle} displayTitle={data.displayTitle} />;
    }

    return <EmptyNoSpaces panelTitle={panelTitle} displayTitle={data.displayTitle} />;
  }

  return (
    <PanelWrapper panelTitle={panelTitle} displayOptions={{ displayTitle, displayBox }}>
      {!!data?.isSideNews && (
        <SpacesContainer>
          {!isLoading &&
            !!spacesData?.pages?.length &&
            paginatedSpacesData?.map((page, index) => (
              <Fragment key={`space-${index}`}>
                {page?.results?.map((space) => (
                  <SideSpace
                    key={space.id}
                    name={space.name}
                    url={space.url}
                    authorUrl={`${window.AP._hostOrigin}/wiki/people/${space.creatorId}`}
                    description={space.description}
                    spaceIconUrl={space.icon}
                    primaryColor={primaryColor}
                    createdBy={data?.activeElements?.includes("author") ? space.createdBy : null}
                    lastModified={space.createdFormatted}
                    avatarUrl={space.avatarUrl}
                    spaceKey={space.key}
                    coverPictureBorderRadius={borderRadius.replace("px", "")}
                    darkTheme={{
                      titleColor: theme.shared.card.title,
                    }}
                    coverPictureCallback={() => {}}
                    backgroundMode="none"
                  />
                ))}
              </Fragment>
            ))}
        </SpacesContainer>
      )}
      {data.view === SpacesTypes.CARD &&
        !isLoading &&
        !!spacesData?.pages?.length &&
        paginatedSpacesData?.map((pages, index) => (
          <Grid style={{ marginTop: index === 0 ? "0px" : "20px" }}>
            {pages?.results?.map((space, idx) => (
              <CardSpace
                key={space.id}
                name={space.name}
                url={space.url}
                authorUrl={`${window.AP._hostOrigin}/wiki/people/${space.creatorId}`}
                description={data?.activeElements?.includes("description") ? space.description : null}
                spaceIconUrl={space.icon}
                primaryColor={primaryColor}
                createdBy={data?.activeElements?.includes("author") ? space.createdBy : null}
                lastModified={space.createdFormatted}
                avatarUrl={space.avatarUrl}
                spaceKey={space.key}
                overlay={{
                  color: "#000000",
                  opacity: 0.2,
                  isActive: false,
                }}
                coverPictureBorderRadius={borderRadius.replace("px", "")}
                darkTheme={{
                  backgroundColor: theme.shared.card.background,
                  titleColor: theme.shared.card.title,
                  borderColor: theme.shared.card.border.box,
                  boxShadow: theme.manualOrgChart.boxShadow,
                  createdBy: primaryColor,
                  authorColor: primaryColor,
                  descriptionColor: theme.shared.form.input.text,
                }}
                coverPictureCallback={() => {}}
                backgroundMode="none"
              >
                <Actions data={space} view={data.view} />
              </CardSpace>
            ))}
          </Grid>
        ))}
      {data.view === SpacesTypes.MINI_CARD &&
        !data?.isSideNews &&
        !isLoading &&
        !!spacesData?.pages?.length &&
        paginatedSpacesData?.map((pages, index) => (
          <Grid style={{ marginTop: index === 0 ? "0px" : "20px" }}>
            {pages?.results?.map((space, idx) => (
              <MiniCardSpace
                key={space.id}
                name={space.name}
                url={space.url}
                spaceIconUrl={space.icon}
                authorUrl={space.authorUrl}
                description={space.description}
                primaryColor={primaryColor}
                createdBy={data?.activeElements?.includes("author") ? space.createdBy : null}
                avatarUrl={space.avatarUrl}
                spaceKey={space.key}
                coverPictureBorderRadius={borderRadius.replace("px", "")}
                darkTheme={{
                  backgroundColor: theme.shared.card.background,
                  titleColor: theme.shared.card.title,
                  authorColor: primaryColor,
                  boxShadow: theme.manualOrgChart.boxShadow,
                }}
                coverPictureCallback={() => {}}
                backgroundMode="none"
              >
                <Actions data={space} view={data.view} />
              </MiniCardSpace>
            ))}
          </Grid>
        ))}
      {data.view === SpacesTypes.LIST && !data?.isSideNews && !isLoading && !!spacesData?.pages?.length && (
        <ListWrapper style={{ marginTop: "16px" }}>
          <ListHeader
            elementsToDisplay={{
              createdBy: data?.activeElements?.includes("author"),
              admins: data?.activeElements?.includes("admins"),
              categories: data?.activeElements?.includes("categories"),
            }}
          />
          {spacesData?.pages?.map((pages, index) => (
            <OneColumnGrid>
              {pages?.results?.map((space, idx) => (
                <ListSpace
                  key={space.id}
                  name={space.name}
                  url={space.url}
                  spaceIconUrl={space.icon}
                  authorUrl={space.authorUrl}
                  description={space.description}
                  primaryColor={primaryColor}
                  labels={data?.activeElements?.includes("categories") ? space.labels : null}
                  createdBy={data?.activeElements?.includes("author") ? space.createdBy : null}
                  avatarUrl={space.avatarUrl}
                  admins={
                    data?.activeElements?.includes("admins")
                      ? admins?.find((adminGroup) => adminGroup.key === space.key)?.admins
                      : null
                  }
                  spaceKey={space.key}
                  coverPictureBorderRadius={borderRadius.replace("px", "")}
                  darkTheme={{
                    backgroundColor: theme.shared.card.background,
                    titleColor: theme.shared.card.title,
                    textColor: theme.shared.card.text,
                    borderColor: theme.shared.card.border.box,
                    authorColor: primaryColor,
                    categories: theme.shared.label.background,
                    categoriesText: theme.shared.label.text,
                  }}
                  coverPictureCallback={() => {}}
                  backgroundMode="none"
                >
                  <Actions data={space} view={data.view} />
                </ListSpace>
              ))}
            </OneColumnGrid>
          ))}
        </ListWrapper>
      )}

      <Pagination
        showMoreClicked={showMoreClicked}
        hasNextPage={page < maxPages}
        type={data.pagination === PanelsPaginationTypes.SHOW_MORE ? PanelsPaginationTypes.INFINITE : data.pagination}
        page={page}
        updatePage={setPage}
        maxPage={maxPages}
        onWrapperHoverCallback={setDisableClickOutside}
        infiniteQuery={{ shouldFetchNext: hasNextPage, fetchNextPage }}
        onClick={() => {
          if (hasNextPage && !showMoreClicked) {
            fetchNextPage();
            setShowMoreClicked(true);
            return;
          }
          setSelectedPage(Pages.SPACE);
        }}
      />
    </PanelWrapper>
  );
}

export default Spaces;
