import React, { useRef, useState, useLayoutEffect, useEffect } from "react";
import TrashIcon from "@atlaskit/icon/glyph/editor/remove";
import DragIcon from "@atlaskit/icon/glyph/drag-handler";
import AddIcon from "@atlaskit/icon/glyph/add";
import styled from "styled-components";
import TextField from "@atlaskit/textfield";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useDrag, useDrop } from "react-dnd";
import SingleTileColorPicker from "../../../../../Shared/Components/SingleTileColorPicker";
import { nanoid } from "nanoid";

import { OptionColors } from "../../editor/SelectEditor";
import { useTheme } from "styled-components";

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

const Content = styled.div`
  max-height: 300px;
  width: 320px;
  font-size: 14px;
  overflow-y: auto;
  z-index: 1;

  p {
    padding: 0 16px;
    color: #626f86;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
  }
`;

const Footer = styled.div`
  padding: 10px;
  height: 30px;
  cursor: pointer;
  display: flex;
`;

const ColorPicker = styled.div`
  .sketch-picker {
    width: 121px;
  }

  #sketch-picker-custom-color {
    top: unset !important;
    bottom: 5px;

    .sketch-picker {
      height: 62px;
      width: 121px !important;
      padding-top: 0 !important;
      overflow: hidden;

      & > div {
        border-top: none !important;
      }
      & > div:nth-child(-n + 3) {
        display: none !important;
      }
    }
  }

  & > div > div:first-child {
    height: 18px;
    width: 18px;
    max-width: 18px;
  }
`;

const AddButton = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.shared.settings.database.addOptionText};
  gap: 6px;
  width: 100%;
  padding: 0 5px;
  font-size: 13px;
  font-weight: 500;
  border-radius: 4px;

  &:hover {
    background: ${({ theme }) => theme.shared.settings.database.addOptionHover};
  }
`;

const OptionWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-right: 8px;
  border-radius: 4px;
  margin: 0 9px;

  ${({ isHoveringOver, orderDirection }) => {
    if (!isHoveringOver) {
      return null;
    }

    return orderDirection === OrderDirection.TOP_TO_BOTTOM
      ? `border-bottom: 1px solid #aaa;`
      : `border-top: 1px solid #aaa;`;
  }};

  &:hover {
    background: ${({ theme }) => theme.shared.settings.database.addOptionHover};
  }
`;

export const RemoveBtn = styled.div`
  cursor: pointer;

  &:hover > span > svg {
    opacity: 0.75;
  }
`;

const OrderDirection = {
  TOP_TO_BOTTOM: "top_to_bottom",
  BOTTOM_TO_TOP: "bottom_to_top",
};

const SingleOption = ({ option, handleRemove, handleUpdate, reorderOptions }) => {
  const [color, setColor] = useState(option.color ?? "#ccc");
  const [label, setLabel] = useState("");

  useEffect(() => {
    setLabel(option.label);
  }, []);

  const theme = useTheme();

  const [{ isOver }, drop] = useDrop(() => ({
    accept: ["option"],
    drop: () => ({ id: option.id, label: option.label, position: option.position }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  }));

  const [{ draggedItem }, drag] = useDrag({
    type: "option",
    item: () => {
      return { id: option.id, type: "option", label: option.label, position: option.position };
    },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (item && dropResult) {
        reorderOptions(item.id, dropResult.id);
      }
    },
    collect: (monitor) => ({
      draggedItem: monitor.getItem(),
    }),
  });

  const draggedItemPosition = draggedItem?.position ?? 0;
  const potentialTargetPosition = isOver ? option?.position : 0;

  return (
    <OptionWrapper
      ref={drop}
      isHoveringOver={isOver}
      orderDirection={
        draggedItemPosition < potentialTargetPosition ? OrderDirection.TOP_TO_BOTTOM : OrderDirection.BOTTOM_TO_TOP
      }
    >
      <div ref={drag} style={{ cursor: "grab" }}>
        <DragIcon size="medium" primaryColor="#42526e" />
      </div>

      <ColorPicker>
        <SingleTileColorPicker
          label=""
          value={color}
          setValue={(value) => {
            setColor(value);
            handleUpdate({ id: option.id, color: value });
          }}
          closeOnSelect
          disableAlpha
          disableTransparent
          presetColors={OptionColors}
        />
      </ColorPicker>

      <div style={{ margin: "0 3px", width: "100%", color: theme.shared.settings.database.addOptionText }}>
        <TextField
          autoFocus
          placeholder="Option label..."
          appearance="none"
          value={label}
          onChange={(e) => {
            setLabel(e.target.value);
          }}
          onBlur={() => {
            if (label) {
              handleUpdate({ id: option.id, label });
            }
          }}
        />
      </div>

      <RemoveBtn onClick={() => handleRemove(option.id)}>
        <TrashIcon size="medium" primaryColor="#42526e" />
      </RemoveBtn>
    </OptionWrapper>
  );
};

const SelectOptions = ({ existingOptions, onUpdate, notifyOnReorder = false }) => {
  const [options, setOptions] = useState(existingOptions ?? []);
  const prevOptions = usePrevious(options);
  const existingOptionsRef = useRef();

  const handleRemove = (optionId) => {
    const newOptions = options.filter((o) => o.id !== optionId);
    setOptions(newOptions);
    onUpdate(newOptions);
  };

  const reorderOptions = (sourceId, targetId) => {
    if (sourceId === targetId) {
      return;
    }
    const newOptions = JSON.parse(JSON.stringify(options));
    const sourceIndex = newOptions.findIndex((o) => o.id === sourceId);
    const source = newOptions.find((o) => o.id === sourceId);
    const targetIndex = newOptions.findIndex((o) => o.id === targetId);
    const target = newOptions.find((o) => o.id === targetId);
    const newPositionOfSource = target.position;

    source.position = newPositionOfSource;

    if (sourceIndex < targetIndex) {
      //moved from top to bottom
      newOptions.slice(sourceIndex + 1, targetIndex + 1).forEach((o) => {
        const newPosition = o.position - 1;
        o.position = newPosition;
      });
    }
    if (sourceIndex > targetIndex) {
      //moved from bottom to top
      newOptions.slice(targetIndex, sourceIndex).forEach((o) => {
        const newPosition = o.position + 1;
        o.position = newPosition;
      });
    }

    setOptions(newOptions.sort((a, b) => a.position - b.position));
    if (notifyOnReorder) {
      onUpdate(newOptions);
    }
  };

  const handleUpdate = (option) => {
    const newOptions = [...options];
    const indexToUpdate = newOptions.findIndex((o) => o.id === option.id);
    if (indexToUpdate < 0) {
      return;
    }
    newOptions[indexToUpdate] = { ...newOptions[indexToUpdate], ...option };
    setOptions(newOptions);
    onUpdate(newOptions);
  };

  useLayoutEffect(() => {
    if (prevOptions) {
      existingOptionsRef.current.scrollTop = existingOptionsRef.current.scrollHeight;
    }
  }, [options]);

  return (
    <>
      <Content ref={existingOptionsRef}>
        <p>Select options</p>

        <DndProvider backend={HTML5Backend}>
          {options.map((o) => {
            return (
              <SingleOption
                key={o.id}
                option={o}
                reorderOptions={reorderOptions}
                handleRemove={handleRemove}
                handleUpdate={handleUpdate}
              />
            );
          })}
        </DndProvider>

        <Footer
          onClick={() => {
            const position = options.sort((a, b) => b.position - a.position)[0]?.position;

            setOptions([
              ...options,
              {
                id: nanoid(7),
                label: "",
                color: OptionColors[Number(`${options.length}`.slice(-1))],
                position: position || 0,
              },
            ]);
          }}
        >
          <AddButton>
            <AddIcon size="small" />
            Add an option
          </AddButton>
        </Footer>
      </Content>
    </>
  );
};

export default SelectOptions;
