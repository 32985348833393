import React, { useState, useEffect } from "react";
import { useTheme } from "styled-components";

import Toggle from "@atlaskit/toggle";
import TextField from "@atlaskit/textfield";
import CrossIcon from "@atlaskit/icon/glyph/cross";
import ErrorIcon from "@atlaskit/icon/glyph/error";
import RefreshIcon from "@atlaskit/icon/glyph/refresh";
import Button, { LoadingButton } from "@atlaskit/button";
import Modal, { ModalTransition } from "@atlaskit/modal-dialog";

import { EntraIcon } from "../../datagrid/header/HeaderIcon";
import { assignAzureDataToAllUsers, resyncAzureUsers } from "../../resync-service";
import { getShouldSyncGuestUsersStatus } from "../../datagrid/api";

import { AzureIntegrationStatus, AZURE_PORTAL_URL } from "./types";

import { getAzureIntegrationStatus, getAuthUrlForAzureIntegration, updateAzureIntegration } from "./api";

import {
  Header,
  Content,
  Footer,
  Overlay,
  CloseBtn,
  Step,
  Title,
  Description,
  InputLabel,
  InputWarning,
  LabeledToggle,
  ToggleTitle,
  Link,
} from "./styled";

export const AzureIntegration = () => {
  const [isOpen, setIsOpen] = useState(false);

  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [shouldSyncGuests, setShouldSyncGuests] = useState(false);
  const [integrationStatus, setIntegrationStatus] = useState(undefined);
  const [hasCaching, setHasCaching] = useState(false);
  const [resyncingInProgress, setResyncingInProgress] = useState(false);

  const theme = useTheme();

  const { textColor, buttonColor, gradient } = theme.shared.settings.database.entraModal;

  const updateIntegrationStatus = async () => {
    const integrationStatusResponse = await getAzureIntegrationStatus();
    setIntegrationStatus(integrationStatusResponse.data.integrationStatus);
    setHasCaching(integrationStatusResponse.data.hasCaching);
    return integrationStatusResponse.data.integrationStatus;
  };

  const updateConnection = async (isAzureEnabled, isCacheEnabled, closeOnComplete = true) => {
    await updateAzureIntegration(isAzureEnabled, isCacheEnabled);
    window.AP.events.emit("azure-sync-complete");

    if (closeOnComplete) setIsOpen(false);

    await updateIntegrationStatus();
  };

  const syncAzureData = async () => {
    setResyncingInProgress(true);

    const flag = window.AP.flag.create({
      title: "Sync in progress",
      body: "Usually, Entra ID sync can take a few minutes to reflect. Please wait...",
      type: "info",
    });

    setTimeout(() => {
      flag.close();
    }, 5000);

    resyncAzureUsers(shouldSyncGuests)
      .then(() => {
        window.AP.events.emit("azure-sync-complete");
      })
      .catch(() => {
        window.AP.flag.create({
          title: "Something went wrong",
          body: "Please refresh and try again.",
          type: "error",
        });
      })
      .finally(() => {
        setResyncingInProgress(false);
      });
  };

  useEffect(() => {
    (async () => {
      await updateIntegrationStatus();
      try {
        const shouldSyncGuestsResponse = await getShouldSyncGuestUsersStatus();
        setShouldSyncGuests(!!shouldSyncGuestsResponse?.data?.shouldSyncGuestUsers);
      } catch {
        setShouldSyncGuests(false);
      }
    })();
  }, []);

  useEffect(() => {
    if (isOpen) {
      setIsConfirmationOpen(false);
    }
  }, [isOpen]);

  const AzureIntegrationNotExisting = () => {
    const [tenantId, setTenantId] = useState("");
    const [hasWarning, setHasWarning] = useState();
    const [userConsentInProgress, setUserConsentInProgress] = useState(false);

    const connectAzure = async () => {
      if (!tenantId) {
        return setHasWarning(true);
      }

      const azureAuthUrl = await getAuthUrlForAzureIntegration(tenantId);
      window.open(azureAuthUrl);
      setUserConsentInProgress(true);
    };

    const finishConnection = async () => {
      const newIntegrationStatus = await updateIntegrationStatus();
      if (newIntegrationStatus === AzureIntegrationStatus.NOT_EXISTING) {
        const flag = window.AP.flag.create({
          title: "Something went wrong",
          body: `Could not connect Azure with Cosmos. Try again or contact support if you still experiece issues.`,
          type: "error",
          actions: { databaseSaveError: "Caelor App Support" },
        });

        setTimeout(() => {
          flag.close();
        }, 5000);
      } else {
        setResyncingInProgress(true);

        await assignAzureDataToAllUsers(shouldSyncGuests, hasCaching);
        setIsOpen(false);
        setResyncingInProgress(false);
        setIntegrationStatus(AzureIntegrationStatus.ENABLED);

        window.AP.events.emit("azure-sync-complete");
      }
    };

    return (
      <Content>
        <Title color={textColor}>Connect Microsoft Entra ID</Title>
        <Description color={textColor}>
          Microsoft Entra ID is the new name for Microsoft Azure Active Directory.
        </Description>

        <Step color={textColor}>
          <span>1</span>
          <div>
            Open
            <a href={AZURE_PORTAL_URL} rel="noreferrer" target="_blank">
              {" Microsoft Azure portal. "}
            </a>
            and copy your Tenant ID.
          </div>
        </Step>
        <Step color={textColor}>
          <span>2</span> Enter your Tenant ID and click "Connect". A consent screen should open in new tab.
        </Step>
        <Step color={textColor}>
          <span>3</span> Close the consent screen and click "Complete".
        </Step>

        {!userConsentInProgress && !resyncingInProgress && (
          <>
            <InputLabel border={theme.global.divider}>Tenant ID</InputLabel>
            <TextField
              value={tenantId}
              onChange={(e) => {
                setTenantId(e.target.value);
                setHasWarning(!e.target.value);
              }}
            />
            {hasWarning && (
              <InputWarning>
                <ErrorIcon /> This field is required.
              </InputWarning>
            )}
          </>
        )}

        <Footer>
          <Button appearance="subtle" onClick={() => setIsOpen(false)}>
            Cancel
          </Button>

          {userConsentInProgress || resyncingInProgress ? (
            <LoadingButton
              appearance="primary"
              onClick={finishConnection}
              isLoading={resyncingInProgress}
              isDisabled={resyncingInProgress}
            >
              Complete
            </LoadingButton>
          ) : (
            <Button appearance="primary" onClick={connectAzure}>
              Connect
            </Button>
          )}
        </Footer>
      </Content>
    );
  };

  const AzureIntegrationEnabled = () => (
    <Content>
      <Title>Microsoft Entra ID</Title>
      <Description>
        {"Daily caching of Microsoft Entra ID enables better performance. By enabling caching, you agree to our "}
        <Link color={theme.global.text.blue} href="https://caelor-apps.atlassian.net/wiki/x/UwCZ">
          data privacy policy.
        </Link>
      </Description>

      <LabeledToggle>
        <ToggleTitle color={theme.shared.label}>Cache {hasCaching ? "Enabled" : "Disabled"}</ToggleTitle>
        <Toggle
          isChecked={hasCaching}
          onChange={() => {
            setHasCaching(!hasCaching);
            updateConnection(true, !hasCaching, false);
          }}
        />
      </LabeledToggle>

      <Footer>
        <Button appearance="subtle" onClick={() => setIsOpen(false)}>
          <span style={{ color: buttonColor }}>Close</span>
        </Button>

        <Button appearance="danger" onClick={() => setIsConfirmationOpen(true)}>
          Disconnect
        </Button>
      </Footer>
    </Content>
  );

  const DisconnectConfirmation = () => (
    <Content>
      <Title>Disconnect Microsoft Entra ID</Title>
      <Description>Are you sure you want to disconnect Microsoft Entra ID from database?</Description>

      <Footer>
        <Button appearance="subtle" onClick={() => setIsOpen(false)}>
          <span style={{ color: buttonColor }}>Cancel</span>
        </Button>

        <Button appearance="danger" onClick={() => updateConnection(false, false)}>
          Disconnect
        </Button>
      </Footer>
    </Content>
  );

  return (
    <>
      {hasCaching && (
        <LoadingButton
          appearance="subtle"
          isLoading={resyncingInProgress}
          iconBefore={<RefreshIcon size="small" />}
          onClick={syncAzureData}
          style={{ fontSize: "13px" }}
        >
          Sync Entra ID
        </LoadingButton>
      )}

      <Button
        appearance={"subtle"}
        iconBefore={<EntraIcon />}
        isDisabled={resyncingInProgress}
        onClick={() => setIsOpen(true)}
        style={{ fontSize: "13px" }}
      >
        <span style={{ color: buttonColor }}>
          {integrationStatus === AzureIntegrationStatus.ENABLED ? "" : "Connect"} MS Entra ID
        </span>
      </Button>

      <ModalTransition>
        {isOpen && (
          <Modal width={409} onClose={() => setIsOpen(false)} isChromeless>
            <Header>
              <img src="./images/database/entra-id-connect.png" alt="Entra ID" width="409" height="219" />
              <Overlay gradient={gradient} />
              <CloseBtn onClick={() => setIsOpen(false)}>
                <CrossIcon primaryColor="#fff" />
              </CloseBtn>
            </Header>

            {integrationStatus === AzureIntegrationStatus.ENABLED && !resyncingInProgress ? (
              <>{isConfirmationOpen ? <DisconnectConfirmation /> : <AzureIntegrationEnabled />}</>
            ) : (
              <AzureIntegrationNotExisting />
            )}
          </Modal>
        )}
      </ModalTransition>
    </>
  );
};
