import React, { useCallback } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Cropper from "react-easy-crop";

const CropperContainer = styled.div`
  background: #efeff1;
  border: 1px dashed #c2c7d0;
  border-radius: 4px;
  position: relative;
  width: 100%;
  height: 300px;
`;

function ImageEditor({ image, aspectRatio, zoom, setZoom, crop, setCrop, setCroppedAreaPixels }) {
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  return (
    <CropperContainer>
      <Cropper
        image={image.link}
        crop={crop}
        zoom={zoom}
        aspect={aspectRatio.width / aspectRatio.height}
        onCropChange={setCrop}
        onCropComplete={onCropComplete}
        onZoomChange={setZoom}
        initialCroppedAreaPixels={image.croppedAreaPixels}
      />
    </CropperContainer>
  );
}

ImageEditor.propTypes = {
  image: PropTypes.shape({
    link: PropTypes.string.isRequired,
  }).isRequired,
  aspectRatio: PropTypes.shape({
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
  }).isRequired,
  zoom: PropTypes.number.isRequired,
  setZoom: PropTypes.func.isRequired,
  crop: PropTypes.shape({
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
  }).isRequired,
  setCrop: PropTypes.func.isRequired,
  setCroppedAreaPixels: PropTypes.func.isRequired,
};

export default ImageEditor;
