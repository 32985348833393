import create from "zustand";

export const useDatabaseUIStore = create((set, get) => ({
  loaded: false,
  columns: {},
  activeCell: undefined,
  editingCell: undefined,
  isResizing: false,
  filters: [],
  selectedFilters: [],
  sort: { order: "asc" },
  setLoaded: () => set((state) => ({ loaded: true })),
  setColumns: (columns) => set((state) => ({ columns })),
  setIsResizing: (resizing) => set((state) => ({ isResizing: resizing })),
  addColumn: (column) => {
    const newColumns = { ...get().columns };
    newColumns[column.id] = { ...column };
    return set((state) => ({ columns: newColumns }));
  },
  setActiveCell: (cellId) => set((state) => ({ activeCell: cellId })),
  setEditingCell: (cellId) => set((state) => ({ editingCell: cellId })),
  updateWidth: (columnId, newWidth) => {
    const newColumns = { ...get().columns };
    newColumns[columnId].width = newWidth;
    return set((state) => ({ columns: newColumns }));
  },
  setFilters: (filters) => set((state) => ({ filters })),
  setSelectedFilters: (selectedFilters) => set((state) => ({ selectedFilters })),
  setSort: (sort) => set((state) => ({ sort: { ...get().sort, ...sort } })),
  addFilter: (filter) => {
    const newFilters = [...get().filters];
    newFilters.push(filter);
    return set((state) => ({ filters: newFilters }));
  },
  updateFilter: (filter) => {
    const newFilters = [...get().filters];
    const indexOfUpdatedFilter = newFilters.findIndex((f) => f.id === filter.id);
    newFilters[indexOfUpdatedFilter] = filter;
    return set((state) => ({ filters: newFilters }));
  },
  removeFilter: (filterId) => {
    return set((state) => ({ filters: [...get().filters.filter((f) => f.id !== filterId)] }));
  },
  resetFilters: (filters) => {
    const restoredFilters = filters.map((f) => ({ ...f, value: undefined }));
    get().setFilters([...restoredFilters]);
    get().setSelectedFilters([]);
  }
}));
