import React, { useState } from "react";
import RecentlyActiveUsersEdit from "./panels/edit/RecentlyActiveUsersEdit";
import KnowYourTeamEdit from "./panels/edit/KnowYourTeamEdit";
import UserBlogPostsEdit from "./panels/edit/UserBlogPostsEdit";
import { PanelTypes } from "./panelTypes";
import Tabs from "../../../../../Shared/Components/Tabs";
import { TabTypes } from "./tabTypes";
import { TabTypes as DashboardTabTypes } from "../../Dashboard/panels/tabTypes";
import AllEmployeesEdit from "./panels/edit/AllEmployeesEdit";
import NewStartersEdit from "./panels/edit/NewStartersEdit";
import UpcomingBirthdaysEdit from "./panels/edit/UpcomingBirthdaysEdit";
import CardsEditor from "../../Shared/Panels/edit/cards/CardsEditor";

const NoFilterOptions = [TabTypes.LAYOUT, TabTypes.DESIGN];
const CardsOptions = [TabTypes.LAYOUT, DashboardTabTypes.CONTENT, TabTypes.DESIGN];

function ConfigProvider({ selectedPanel, updatePanelDataProperty, simpleUpdateProperty, updatePanelTitle }) {
  const [selectedTab, setSelectedTab] = useState("Layout");

  const getTabOptionsBasedOnPanelType = () => {
    const options = {
      [PanelTypes.TEAM]: NoFilterOptions,
      [PanelTypes.ACTIVE_USERS]: NoFilterOptions,
      [PanelTypes.BLOG]: NoFilterOptions,
      [PanelTypes.EMPLOYEES]: NoFilterOptions,
      [PanelTypes.BIRTHDAY]: NoFilterOptions,
      [PanelTypes.NEW_STARTERS]: NoFilterOptions,
      [PanelTypes.CARDS]: CardsOptions,
    };

    return options[selectedPanel?.type] || [];
  };

  if (!selectedPanel) return <></>;

  return (
    <>
      <Tabs tabs={getTabOptionsBasedOnPanelType()} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />

      {selectedPanel.type === PanelTypes.TEAM && (
        <KnowYourTeamEdit
          panel={selectedPanel}
          selectedTab={selectedTab}
          updatePanelDataProperty={updatePanelDataProperty}
        />
      )}

      {selectedPanel.type === PanelTypes.ACTIVE_USERS && (
        <RecentlyActiveUsersEdit
          panel={selectedPanel}
          selectedTab={selectedTab}
          updatePanelDataProperty={updatePanelDataProperty}
        />
      )}

      {selectedPanel.type === PanelTypes.BLOG && (
        <UserBlogPostsEdit
          panel={selectedPanel}
          selectedTab={selectedTab}
          updatePanelDataProperty={updatePanelDataProperty}
        />
      )}

      {selectedPanel.type === PanelTypes.EMPLOYEES && (
        <AllEmployeesEdit
          panel={selectedPanel}
          selectedTab={selectedTab}
          updatePanelDataProperty={updatePanelDataProperty}
        />
      )}

      {selectedPanel.type === PanelTypes.BIRTHDAY && (
        <UpcomingBirthdaysEdit
          panel={selectedPanel}
          selectedTab={selectedTab}
          updatePanelDataProperty={updatePanelDataProperty}
        />
      )}

      {selectedPanel.type === PanelTypes.NEW_STARTERS && (
        <NewStartersEdit
          panel={selectedPanel}
          selectedTab={selectedTab}
          updatePanelDataProperty={updatePanelDataProperty}
        />
      )}

      {selectedPanel.type === PanelTypes.CARDS && (
        <CardsEditor
          panel={selectedPanel}
          selectedTab={selectedTab}
          updatePanelDataProperty={simpleUpdateProperty}
          updatePanelTitle={updatePanelTitle}
          isInPeopleNetwork
        />
      )}
    </>
  );
}

export default ConfigProvider;
