export const ActiveUsersPropertyKeys = {
  TITLE: "title",
  DESCRIPTION: "description",
  PROFILE_IMAGE_SIZE: "profileImageSize",
  PAGINATION: "pagination",
  EVENTS: "events",
  BACKGROUND_TYPE: "backgroundType",
  DISPLAY_TITLE: "displayTitle",
  DISPLAY_BOX: "displayBox",
  MAX_NUMBER_OF_ITEMS: "maxNumberOfItems",
};
