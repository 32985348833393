export const simpleSelectStyles = {
  control: (provided) => ({
    ...provided,
    fontSize: "14px",
    width: "412px",
    height: "40px",
    borderRadius: 4,
  }),
  container: (provided) => ({
    ...provided,
    position: "absolute",
    zIndex: 10,
  }),
  selectContainer: (provided) => ({ ...provided, fontSize: "14px" }),
  menuPortal: (provided) => ({ ...provided, zIndex: 900, position: "absolute", left: "0px", top: "20px" }),
  menu: (provided) => ({ ...provided, zIndex: 900 }),
  option: (provided) => ({ ...provided, fontSize: "14px" }),
  placeholder: (provided) => ({ ...provided, fontWeight: 500 }),
  input: (provided) => ({ ...provided, fontSize: "14px" }),
  valueContainer: (provided) => ({
    ...provided,
    flexWrap: "nowrap",
    overflow: "hidden",
    fontWeight: 600,
  }),
};
