import React, { useEffect, useRef, useState } from "react";
import styled, { useTheme } from "styled-components";
import Button from "@atlaskit/button";
import FilterIcon from "@atlaskit/icon/glyph/filter";
import { FieldType } from "../../../../UserDatabase/field-type";
import { FilterLabel } from "../../../styled/pages";
import Select from "./Select";
import AsyncSelect from "./AsyncSelect";
import User from "./User";
import Date from "./Date";
import { useClickOutside } from "../../../../../Shared/Hooks/useClickOutside";
import { MenuGroup, Section } from "@atlaskit/menu";

const ButtonWrapper = styled.div`
  position: relative;
  width: fit-content;
`;

const CustomFilterContentWrapper = styled.div`
  position: absolute;
  left: 0px;
  top: 40px;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.sidebar.filterBackground};
  z-index: 300;
  padding: 24px 0px;
  border-radius: 4px;
  box-shadow:
    0 4px 8px -2px rgba(9, 30, 66, 0.25),
    0 0 1px rgba(9, 30, 66, 0.31);

  display: flex;
  flex-direction: column;
`;

const StyledButtonFilter = styled(Button)`
  &&& {
    font-size: 13px;
    font-weight: 550;
    text-align: left;
    padding-right: 16px;
    color: ${({ theme }) => theme.shared.settings.database.menuBarColor} !important;
    background-color: ${({ isSelected, theme }) =>
      isSelected ? theme.manualOrgChart.buttonColorActive : theme.manualOrgChart.buttonColor};

    &:hover {
      background-color: ${({ theme }) => theme.manualOrgChart.buttonColorActive};
    }
  }
`;

const SelectWrapper = styled.div`
  margin-bottom: 10px;

  div:first-child {
    margin-bottom: none;
  }
`;

function Filter({ fields, applyFilters, label, filters, updateFilter }) {
  const theme = useTheme();
  const [pickerKey, setPickerKey] = useState(Math.random().toString(32).slice(2));
  const [dialogOpen, setDialogOpen] = useState(false);

  const dialogRef = useRef();

  useClickOutside(dialogRef, () => {
    setDialogOpen(false);
  });

  useEffect(() => {
    applyFilters();
  }, [filters]);

  return (
    <ButtonWrapper ref={dialogRef}>
      <StyledButtonFilter
        appearance="default"
        isSelected={dialogOpen}
        iconBefore={
          <FilterIcon size="medium" label="filter" primaryColor={theme.shared.settings.database.menuBarIcons} />
        }
        onClick={() => setDialogOpen(!dialogOpen)}
      >
        <span style={{ color: theme.shared.settings.database.menuBarColor, fontWeight: 500 }}>{label}</span>
      </StyledButtonFilter>

      {dialogOpen && (
        <CustomFilterContentWrapper>
          <FilterLabel style={{ marginLeft: "20px", textTransform: "uppercase" }}>Filter by</FilterLabel>

          <MenuGroup
            style={{
              width: "306px",
              maxHeight: "300px",
              padding: "0 15px 5px 20px",
              marginRight: "5px",
            }}
          >
            <Section>
              {filters.map((filter) => {
                const field = fields.find((field) => field.id === filter.column);
                return (
                  <SelectWrapper key={filter.id} style={{ marginBottom: "10px" }}>
                    {filter.column.includes("azure") ? (
                      <>
                        {filter.type === FieldType.SELECT && (
                          <AsyncSelect
                            key={pickerKey}
                            filter={filter}
                            onChange={(v) => updateFilter({ ...filter, value: v })}
                          />
                        )}
                      </>
                    ) : (
                      <>
                        {(filter.type === FieldType.SELECT || filter.type === FieldType.MULTISELECT) && (
                          <Select
                            key={pickerKey}
                            value={filter.value}
                            onChange={(v) => {
                              updateFilter({ ...filter, value: v });
                            }}
                            options={filter.options || []}
                            fieldTitle={filter.fieldTitle}
                          />
                        )}
                      </>
                    )}
                    {filter.type === FieldType.USER && (
                      <User
                        key={pickerKey}
                        initialValue={filter.value}
                        onChange={(v) => {
                          updateFilter({ ...filter, value: v });
                        }}
                        fieldTitle={filter.fieldTitle}
                      />
                    )}
                    {filter.type === FieldType.DATE && (
                      <Date
                        value={filter.value}
                        onChange={(v) => {
                          updateFilter({ ...filter, value: v, recurring: field.recurring || false });
                        }}
                        field={field}
                      />
                    )}
                  </SelectWrapper>
                );
              })}
            </Section>
          </MenuGroup>
        </CustomFilterContentWrapper>
      )}
    </ButtonWrapper>
  );
}

export default Filter;
