import React, { useEffect, useState } from "react";
import { useSidebarPanelStore } from "../database-store";
import { useRecordsStore } from "../../../../UserDatabase/records-store";
import { AvailableStyles } from "../defaultSettings";
import CalgaryCard from "./CalgaryCard";
import AustinCard from "./AustinCard";
import { fieldValueHandlers, handlerOptions } from "./field-value-handler";
import { useCorporateIdentityStore } from "../../Settings/General/BrandAndColors/corporate-identity-store";

function Card({ name, icon, email, accountId, image, userRecordValues }) {
  const settings = useSidebarPanelStore((state) => state.settings);
  const fields = useRecordsStore((state) => state.fields);
  const borderRadius = useCorporateIdentityStore((state) => state.borderRadius);

  const [personFieldData, setPersonFieldData] = useState([]);

  const getUserValueFromField = (field) => {
    if (!field) return "";

    const value = userRecordValues[field.id] || "";

    if (field.id.includes("azure")) return value;

    const getFieldValue = fieldValueHandlers[field.type];
    const fieldOptions = handlerOptions[field.type](field);

    const formattedValue = getFieldValue(value, fieldOptions);
    return formattedValue;
  };

  const getUserValuesForFields = (fieldsToUse) => {
    const values = [];

    if (!fieldsToUse?.length) return [];

    fieldsToUse.forEach(({ key, value }) => {
      const dbField = fields.find((field) => field.id === value.id);

      if (dbField?.type === "EMAIL") {
        values.push({ key, value: email, type: dbField.type });
        return;
      }

      const formattedValue = getUserValueFromField(dbField);
      values.push({ key, value: formattedValue, type: dbField?.type });
    });

    return values;
  };

  useEffect(() => {
    if (settings.selectedStyle) {
      const fields = settings[settings.selectedStyle];

      const fieldsToUse = Object.entries(fields)
        .filter(([key, value]) => key.includes("field") && value.enabled)
        .map(([key, value]) => ({ key, value }));

      const formattedFields = getUserValuesForFields(fieldsToUse);
      setPersonFieldData([...formattedFields]);
    }
  }, [settings]);

  const createProfileLink = () => {
    return `${window.AP._hostOrigin}/wiki/plugins/servlet/ac/com.caelor.confluence.cloud.cosmos/caelor-cosmos-portal?ac.cosmosPage=profile&ac.accountId=${accountId}`;
  };

  return (
    <div>
      {settings.selectedStyle === AvailableStyles.AUSTIN && (
        <AustinCard
          accountId={accountId}
          createProfileLink={createProfileLink}
          settingsData={settings[AvailableStyles.AUSTIN]}
          borderRadius={borderRadius}
          personFieldData={personFieldData}
          personData={{
            name,
            icon,
            image,
          }}
        />
      )}

      {settings.selectedStyle === AvailableStyles.CALGARY && (
        <CalgaryCard
          accountId={accountId}
          createProfileLink={createProfileLink}
          settingsData={settings[AvailableStyles.CALGARY]}
          borderRadius={borderRadius}
          personFieldData={personFieldData}
          personData={{
            name,
            icon,
            image,
          }}
        />
      )}
    </div>
  );
}

export default Card;
