import React, { useState } from "react";
import TextEditor from "../editor/Text";
import { FieldValue, EditorContainer } from "../styled";

function Text({ value, field, onEdit, isEditable, isSidebar, getPrefixBasedOnRole }) {
  const [isEditing, setIsEditing] = useState(false);

  return (
    <>
      {isEditing && (
        <>
          {isSidebar ? (
            <EditorContainer style={{ position: "static", maxWidth: "310px", width: "310px" }}>
              <TextEditor
                isSidebar
                initialValue={value}
                onEdit={(val) => {
                  onEdit(field.id, val);
                  setIsEditing(false);
                }}
              />
            </EditorContainer>
          ) : (
            <EditorContainer>
              <TextEditor
                initialValue={value}
                onEdit={(val) => {
                  onEdit(field.id, val);
                  setIsEditing(false);
                }}
              />
            </EditorContainer>
          )}
        </>
      )}

      {(!isSidebar || !isEditing) && (
        <FieldValue
          isEditing={isEditing}
          isEditable={isEditable}
          onClick={() => {
            if (isEditable) {
              setIsEditing(true);
            }
          }}
        >
          {value || `${getPrefixBasedOnRole()} ${field.title}`}
        </FieldValue>
      )}
    </>
  );
}

export default Text;
