import React, { useMemo } from "react";
import { useModalStore } from "../../categories-store";
import { ModalTypes } from "./types";
import CreateCategory from "./components/CreateCategory";
import AddBlogs from "./components/AddBlogs";
import EditCategory from "./components/EditCategory";
import TargetCollections from "./components/TargetCollections";

const Modals = {
  [ModalTypes.CREATE_CATEGORY]: CreateCategory,
  [ModalTypes.EDIT_CATEGORY]: EditCategory,
  [ModalTypes.ASSIGN_BLOGS]: AddBlogs,
  [ModalTypes.TARGET_AUDIENCE]: TargetCollections,
  [undefined]: () => <></>,
};

function EntryModal() {
  const { isModalOpen, setIsModalOpen, modalKey, setModalKey } = useModalStore((state) => ({
    isModalOpen: state.isModalOpen,
    setIsModalOpen: state.setIsModalOpen,
    modalKey: state.modalKey,
    setModalKey: state.setModalKey,
  }));

  const closeModal = ({ shouldRefetchBlogs = false } = {}) => {
    setIsModalOpen(false);
    setModalKey(undefined);

    if (shouldRefetchBlogs) {
      window.AP.events.emit("refetch-blogs");
    }
  };

  const SelectedModal = useMemo(() => Modals[modalKey], [modalKey]);

  if (!isModalOpen) return <></>;

  return <SelectedModal closeModal={closeModal} />;
}

export default EntryModal;
