import React from "react";
import styled, { useTheme } from "styled-components";
import { Pages } from "../../../pages";
import { SectionKeys, SectionPagesIDs } from "../../Settings/sections";
import { WidgetIds } from "../../Settings/Widgets/consts/tabs";

const PanelDescription = styled.p`
  all: unset;
  color: #626f86;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;

  a {
    color: #1d7afc;
    text-decoration: none;

    &:visited {
      color: #1d7afc;
    }
  }
`;

export const EmptyStateTypes = {
  NO_LICENSE: "not-selected",
  NOT_SETUP: "not-initialized",
  REGULAR_USER: "regular-user",
};

const DescriptionBasedOnType = {
  [EmptyStateTypes.NO_LICENSE]: () => (
    <span>
      Org Charts are powered by{" "}
      <a
        href="https://marketplace.atlassian.com/apps/1224103/content-viz-overviews-org-charts-news?hosting=cloud&tab=overview"
        target="_blank"
        rel="noreferrer"
      >
        Content Viz.
      </a>{" "}
      To display organizational charts in People Network screen, a valid{" "}
      <a
        href="https://marketplace.atlassian.com/apps/1224103/content-viz-overviews-org-charts-news?hosting=cloud&tab=overview"
        target="_blank"
        rel="noreferrer"
      >
        Content Viz
      </a>{" "}
      license is required.
    </span>
  ),
  [EmptyStateTypes.NOT_SETUP]: () => (
    <span>
      Org Charts are currently inactive or not fully set up. Please{" "}
      <a
        rel="noreferrer"
        target="_parent"
        href={`${window.AP._hostOrigin}/wiki/plugins/servlet/ac/com.caelor.confluence.cloud.cosmos/caelor-cosmos-portal?ac.cosmosPage=${Pages.SETTINGS}-${SectionKeys.PEOPLE_BASE}-${SectionPagesIDs.WIDGETS}&ac.widgetTab=${WidgetIds.ORG_CHART}`}
      >
        configure
      </a>{" "}
      the Org Charts settings to enable this feature.
    </span>
  ),
  [EmptyStateTypes.REGULAR_USER]: () => (
    <span>Currently under construction by your intranet admins. The Org Chart display will be available soon.</span>
  ),
};

function EmptyState({ type }) {
  const theme = useTheme();

  const ImageBasedOnType = {
    [EmptyStateTypes.NO_LICENSE]: "/images/hub/contentviz-no-license.svg",
    [EmptyStateTypes.NOT_SETUP]: `/images/hub/people-empty-state-${theme.global.name}.svg`,
    [EmptyStateTypes.REGULAR_USER]: "/images/hub/column-not-selected.svg",
  };

  return (
    <div style={{ display: "grid", placeContent: "center", padding: "50px 0px 50px 0px" }}>
      <img style={{ margin: "0 auto" }} src={ImageBasedOnType[type]} alt="" />
      <PanelDescription>{DescriptionBasedOnType[type]()}</PanelDescription>
    </div>
  );
}

export default EmptyState;
