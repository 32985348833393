import { Spotlight, SpotlightTransition } from "@atlaskit/onboarding";
import { useCurrentUserStore } from "../../../current-user-store";
import { PROFILE_WIZARD_PROPERTY_KEY, updateUserWizardConfigProperty } from "./wizard-service";

const ProfileWizard = ({ currentWizardStep, setCurrentWizardStep, updateWizardProperties }) => {
  const next = () => setCurrentWizardStep((currentWizardStep || 0) + 1);
  const end = () => setCurrentWizardStep(null);

  const currentUser = useCurrentUserStore((state) => state.currentUser);

  const renderActiveSpotlight = () => {
    const spotlights = [
      <Spotlight
        actions={[
          {
            onClick: () => {
              next();
            },
            text: "Next",
          },
          {
            onClick: () => {
              updateWizardProperties("profile", true);
              updateUserWizardConfigProperty(
                currentUser?.accountId,
                { userSeenWizard: true },
                true,
                PROFILE_WIZARD_PROPERTY_KEY
              );
              end();
            },
            text: "Skip",
            appearance: "subtle",
          },
        ]}
        heading="Default Columns"
        target="profile-content"
        key="profile-default-columns"
        targetRadius={16}
        targetBgColor={"white"}
      >
        To improve your Cosmos experience, we've created default columns covering everything you need. You can create
        your own in the Database.
      </Spotlight>,
      <Spotlight
        actions={[
          {
            onClick: () => {
              updateWizardProperties("profile", true);
              updateUserWizardConfigProperty(
                currentUser?.accountId,
                { userSeenWizard: true },
                true,
                PROFILE_WIZARD_PROPERTY_KEY
              );
              end();
            },
            text: "Done",
            appearance: "subtle",
          },
        ]}
        heading="Edit Layout"
        target="edit-profile"
        key="edit-profile-layout"
        targetRadius={8}
        targetBgColor={"white"}
      >
        You can change the user profile layout and replace the default fields. Changes you make here will apply to all
        Cosmos users.
      </Spotlight>,
    ];

    if (currentWizardStep < 0) {
      return null;
    }

    return spotlights[currentWizardStep];
  };

  return <SpotlightTransition>{renderActiveSpotlight()}</SpotlightTransition>;
};

export default ProfileWizard;
