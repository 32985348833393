/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import CustomSketchPicker from "../CustomSketchPicker";

export const MultipleColorsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 20px;

  div:nth-child(3) {
    #sketch-picker-custom-color {
      right: 5px !important;
      left: auto !important;
    }
  }
`;

const ColorContainer = styled.div`
  display: ${({ isHidden }) => (isHidden ? "none" : "flex")};
  align-items: center;
  column-gap: 10px;
  overflow: visible;
  position: relative;
`;

const ColorBox = styled.div`
  box-sizing: border-box;
  width: ${({ boxWidth }) => (boxWidth ? boxWidth : "48px")};
  height: ${({ boxHeight }) => (boxHeight ? boxHeight : "48px")};
  background: ${({ color, showColorIsTransparent, isDisabled }) => {
    if (isDisabled) {
      return "#EBECF0";
    }
    return showColorIsTransparent ? "#ffffff" : color;
  }};
  border: ${({ theme }) => `4px solid ${theme.shared.form.colorpicker.background}`};
  border-radius: 3px;
  cursor: ${({ isDisabled }) => (isDisabled ? "default" : "pointer")};

  ${({ showColorIsTransparent }) => {
    if (showColorIsTransparent) {
      return `
        &::before {
          content: "/";
          display: grid;
          place-content: center;
          color: red;
          height: 21px;
          font-size: 30px;
          padding-left: 3px;
          transform: rotateZ(15deg);
        }
      `;
    }
    return "";
  }}
`;

const ColorLabel = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: ${({ isDisabled }) => (isDisabled ? "#EBECF0" : "#6B778C")};
  font-weight: 500;
`;

export const DEFAULT_COLORS = [
  "#0052CC",
  "#172b4d",
  "#00a2e0",
  "#00badd",
  "#00b779",
  "#ffc300",
  "#FF8B00",
  "#ff4310",
  "#6a4ec7",
  "#DFE1E6",
  "#ffffff",
];

function SingleColorPicker({
  label,
  value,
  setValue,
  additionalStyle,
  presetColors,
  isDisabled,
  disableAlpha,
  isHidden,
  boxWidth,
  boxHeight,
}) {
  const [isColorPickerOpen, setIsColorPickerOpen] = useState(false);
  const [showColorIsTransparent, setShowColorIsTransparent] = useState(false);

  useEffect(() => {
    const hasOpacity = value.length >= 8;
    if (value === "transparent" || (hasOpacity && value.slice(-2) === "00")) {
      setShowColorIsTransparent(true);
    } else {
      setShowColorIsTransparent(false);
    }
  }, [value]);

  return (
    <ColorContainer isHidden={isHidden}>
      <ColorBox
        boxWidth={boxWidth}
        boxHeight={boxHeight}
        color={value}
        isDisabled={isDisabled}
        showColorIsTransparent={showColorIsTransparent}
        onClick={() => {
          if (!isDisabled) {
            setIsColorPickerOpen(true);
          }
        }}
      />
      {label && <ColorLabel isDisabled={isDisabled}>{label}</ColorLabel>}

      {isColorPickerOpen && (
        <CustomSketchPicker
          selectedColor={value}
          setSelectedColor={setValue}
          additionalStyle={additionalStyle}
          presetColors={[...presetColors, "transparent"]}
          callback={() => setIsColorPickerOpen(false)}
          disableAlpha={disableAlpha}
        />
      )}
    </ColorContainer>
  );
}

SingleColorPicker.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  presetColors: PropTypes.arrayOf(PropTypes.string),
  additionalStyle: PropTypes.object,
  isDisabled: PropTypes.bool,
  isHidden: PropTypes.bool,
  disableAlpha: PropTypes.bool,
};

SingleColorPicker.defaultProps = {
  presetColors: DEFAULT_COLORS,
  additionalStyle: {
    top: "5px",
    left: "5px",
  },
  isDisabled: false,
  isHidden: false,
  disableAlpha: false,
};

export default SingleColorPicker;
