import {
  IconGENERIC,
  IconPDF,
  IconIMG,
  IconARCHIVE,
  IconAUDIO,
  IconEXE,
  IconGIF,
  IconVIDEO,
} from "./AttachmentIcons";

const ICON_BY_TYPE = {
  "image/gif": IconGIF,
  "application/pdf": IconPDF,
  "application/zip": IconARCHIVE,
  "application/octet-stream": IconARCHIVE,
  "application/x-msdownload": IconEXE,
};

const getIconWithGenericType = (type) => {
  if (type.includes("image")) {
    return IconIMG;
  }
  if (type.includes("video")) {
    return IconVIDEO;
  }
  if (type.includes("audio")) {
    return IconAUDIO;
  }
  return IconGENERIC;
};

export const getIconBasedOnType = (type) => {
  const Icon = ICON_BY_TYPE[type]
    ? ICON_BY_TYPE[type]
    : getIconWithGenericType(type);

  return Icon();
};
