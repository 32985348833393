import React from "react";
import styled from "styled-components";
import { PanelPositioning } from "../../../panelTypes";
import { MainContentIcon, SideContentIcon } from "./Icons";
import { ArrowRightIcon } from "../../../../../../icons/SidebarIcons";
import { Description, IconContainer, Title, Wrapper } from "../styled";
import { useSidebarStore } from "../sidebar-store";
import { SIDEBAR_TYPES } from "../types";

const ContentColumnsContainer = styled.div`
  display: grid;
  grid-template-columns: 20px auto 20px;
  column-gap: 16px;
  row-gap: 12px;
  align-items: flex-start;
  cursor: pointer;
`;

function ContentSelector() {
  const { setSelectedContentType, setSidebarType } = useSidebarStore((state) => ({
    setSelectedContentType: state.setSelectedContentType,
    setSidebarType: state.setSidebarType,
  }));

  const handleClickOnContent = (contentType) => {
    setSelectedContentType(contentType);
    setSidebarType(SIDEBAR_TYPES.PANEL_LIST);
  };

  return (
    <Wrapper style={{ paddingTop: "32px" }}>
      <ContentColumnsContainer onClick={() => handleClickOnContent(PanelPositioning.MAIN)}>
        <MainContentIcon />
        <div>
          <Title>Main Content</Title>
          <Description>Change the content, layout, and design of the widgets on the left.</Description>
        </div>
        <IconContainer style={{ justifySelf: "flex-end" }}>
          <ArrowRightIcon />
        </IconContainer>
      </ContentColumnsContainer>

      <ContentColumnsContainer
        style={{ marginTop: "32px" }}
        onClick={() => handleClickOnContent(PanelPositioning.SIDE)}
      >
        <SideContentIcon />
        <div>
          <Title>Side Content</Title>
          <Description>Change the content, layout, and design of the widgets on the right.</Description>
        </div>
        <IconContainer style={{ justifySelf: "flex-end" }}>
          <ArrowRightIcon />
        </IconContainer>
      </ContentColumnsContainer>
    </Wrapper>
  );
}

export default ContentSelector;
