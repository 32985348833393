import { PanelTypes } from "./panels/panelTypes";
import { updateNewStartersBackgroundProperty } from "./migration/panel-content";
import { PanelsHiddenFromAnonymousAndGuestsUsers } from "./panels/hiddenPanelsForAnonymousAndGuests";

const PanelsToUpdate = {
  [PanelTypes.NEW_USERS_MAIN]: updateNewStartersBackgroundProperty,
};

const updatePanelProperties = (panels) => {
  let newPanels = [...panels];

  Object.keys(PanelsToUpdate).forEach((panelKey) => {
    newPanels = PanelsToUpdate[panelKey](newPanels);
  });

  return newPanels;
};

const removeDisabledPanels = (panels) => panels.filter((panel) => ("isEnabled" in panel ? panel.isEnabled : true));

const filterForAnonymousAccess = (panels) => panels.filter((panel) => !panel?.isHiddenFromAnonymous);

const filterHiddenPanelsFromAnonOrGuests = (panels) =>
  panels.filter((panel) => !PanelsHiddenFromAnonymousAndGuestsUsers.includes(panel.type));

export const formatPanelData = (panels, isAnonymous, isGuest) => {
  const newPanels = [...panels];

  const removedDisabledPanels = removeDisabledPanels(newPanels);
  const updatedPanels = updatePanelProperties(removedDisabledPanels);
  const removePanelsHiddenFromAnonOrGuests =
    isAnonymous || isGuest ? filterHiddenPanelsFromAnonOrGuests(updatedPanels) : updatedPanels;
  const filteredForAnonymousUsers = isAnonymous
    ? filterForAnonymousAccess(removePanelsHiddenFromAnonOrGuests)
    : removePanelsHiddenFromAnonOrGuests;

  return [...filteredForAnonymousUsers];
};

export const checkIfPanelsContainDummyData = (panels) => panels.some((panel) => !!panel?.data?.isDummy);
