import React from "react";
import { PanelPositioning } from "../../../../panelTypes";
import { HeaderItem, HeaderRow, ListViewWrapper } from "../styled";
import List from "../type/List";
import { useCorporateIdentityStore } from "../../../../../../Settings/General/BrandAndColors/corporate-identity-store";
import SideListContent from "../content/SideListContent";

function ListView({ data, elementsToDisplay, position, locale }) {
  const numberOfItemsInTable = 1 + elementsToDisplay.length;

  const borderRadius = useCorporateIdentityStore((state) => state.borderRadius);

  if (position === PanelPositioning.SIDE) {
    return data?.map((page) =>
      page?.results?.map(({ diagram, content }) => (
        <SideListContent
          key={diagram.id}
          content={content}
          diagram={diagram}
          elementsToDisplay={elementsToDisplay}
          locale={locale}
          openOnName
        />
      )),
    );
  }

  return (
    <ListViewWrapper borderRadius={borderRadius}>
      <HeaderRow numberOfItems={numberOfItemsInTable + 1}>
        <HeaderItem>Diagram</HeaderItem>
        {elementsToDisplay.includes("attachedto") && <HeaderItem>Attached to</HeaderItem>}
        {elementsToDisplay.includes("contributors") && <HeaderItem>Contributors</HeaderItem>}
        {elementsToDisplay.includes("date") && <HeaderItem>Last Modified</HeaderItem>}
      </HeaderRow>

      {data?.map((page) =>
        page?.results?.map(({ diagram, content }) => (
          <List
            key={diagram.id}
            diagram={diagram}
            content={content}
            elementsToDisplay={elementsToDisplay}
            locale={locale}
            numberOfItemsInTable={numberOfItemsInTable + 1}
          />
        )),
      )}
    </ListViewWrapper>
  );
}

export default ListView;
