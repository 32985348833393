import React, { useState } from "react";
import { useHeaderStore } from "../../../../store/header-store";
import InputFilter from "../../../Dashboard/filter/InputFilter";
import SidebarFooter from "../../SidebarFooter";
import HeaderBackground from "./HeaderBackground";
import { useHeaderChangesStore } from "./header-changes-store";

function HeaderSidebarDesign({
  header,
  saveHeaderSettings,
  closePanel,
  pageToUpdate,
  primaryColor = "#0065ff",
  primaryContrastColor = "#ffffff",
}) {
  const [isSaving, setIsSaving] = useState(false);

  const updateHeaderData = useHeaderStore((state) => state.updateHeaderData);
  const setHasHeaderChanges = useHeaderChangesStore((state) => state.setHasHeaderChanges);

  const saveCallback = () => setIsSaving(false);

  const handleUpdates = (key, value) => {
    const newHeader = { ...header, [key]: value };
    updateHeaderData(pageToUpdate, newHeader);
    setHasHeaderChanges(true);
  };

  return (
    <div>
      <InputFilter
        initialValue={header.title}
        label="Title"
        onUpdate={(value) => handleUpdates("title", value)}
        maxLength={50}
      />

      <InputFilter
        multiLine
        initialValue={header.description}
        label="Subtitle"
        onUpdate={(value) => handleUpdates("description", value)}
        maxLength={360}
      />

      <HeaderBackground
        defaultImage={header.imageUrl}
        background={header.background}
        handleUpdates={handleUpdates}
        primaryColor={primaryColor}
        primaryContrastColor={primaryContrastColor}
      />
    </div>
  );
}

export default HeaderSidebarDesign;
