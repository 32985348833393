import { useCallback, useMemo } from "react";
import styled from "styled-components";
import ImageIcon from "@atlaskit/icon/glyph/image";
import { getImage, handleClickOnEntryLink, handleClickOnChildEntry } from "../cardLayoutService";
import { ChildEntry, ChildMenuWrapper, MenuContentPositioner, MenuContentWrapper, ImageContainer } from "./styled";
import { useTheme } from "styled-components";
import { dark } from "../../../../../dark-colors";
import { light } from "../../../../../light-colors";
import {
  DEFAULT_DENVER_COLORS,
  DEFAULT_DENVER_COLORS_DARK,
  THEME_NAME_DARK,
  THEME_NAME_LIGHT,
} from "../../../functions/generateNewEntry";
import EntryIcon from "../../../configuration/drawer/components/edit/shared/EntryIcon";
import { useNavigationStore, useSidebarStore } from "../../../store";
import { DrawerTypes } from "../../../configuration/drawer/types";
import { HelperDrawerTypes } from "../../../configuration/drawer/helper_drawers/types";

const themes = {
  dark,
  light,
};

const Link = styled.div`
  color: ${({ textColor }) => textColor};
  cursor: pointer;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;

  &:hover {
    color: ${({ textHoverColor }) => textHoverColor};
  }
`;

const EditImageContainer = styled.div`
  position: absolute;
  z-index: 5;
  top: 6px;
  right: 6px;

  width: 32px;
  height: 32px;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;

  .background {
    opacity: 0.9;
    background: #f5f6f8;

    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 6;
  }

  .icon-wrapper {
    display: grid;
    place-content: center;
    height: 100%;
    width: 100%;

    position: relative;
    z-index: 7;
  }
`;

function Denver({ structure, selectedRootEntry, isPreview, openOverContent, themeColorsSet, temporaryColorScheme }) {
  const rootEntry = structure.find(({ id }) => id === selectedRootEntry);

  const { setEditingEntry } = useNavigationStore((state) => ({
    setEditingEntry: state.setEditingEntry,
  }));

  const { setIsOpen, setSelectedSidebarType, setIsHelperDrawerOpen, setSelectedHelperDrawerType } = useSidebarStore(
    (state) => ({
      setIsOpen: state.setIsOpen,
      setSelectedSidebarType: state.setSelectedSidebarType,
      setIsHelperDrawerOpen: state.setIsHelperDrawerOpen,
      setSelectedHelperDrawerType: state.setSelectedHelperDrawerType,
    }),
  );

  const checkIfEntryImageEditable = useCallback(
    (entryId) => {
      if (!isPreview) return false;
      if (structure.find(({ id }) => id === entryId)) return true;
      return false;
    },
    [isPreview, structure],
  );

  const theme = useTheme();

  const colors = useMemo(() => {
    if (!rootEntry) return {};
    if (themeColorsSet && theme.global.name === THEME_NAME_LIGHT) {
      return DEFAULT_DENVER_COLORS;
    } else if (themeColorsSet && theme.global.name === THEME_NAME_DARK) {
      return DEFAULT_DENVER_COLORS_DARK;
    } else {
      return rootEntry?.data?.colors;
    }
  }, [theme, rootEntry, structure, themeColorsSet]);

  return (
    <MenuContentWrapper
      backgroundColor={themes[temporaryColorScheme]?.navigation.denver.menuContentBackground}
      openOverContent={openOverContent}
    >
      <MenuContentPositioner>
        <ChildMenuWrapper
          isPreview={isPreview}
          numberOfColumns={structure.filter((entry) => entry.parent === selectedRootEntry)?.length}
        >
          {structure
            .filter((entry) => entry.parent === selectedRootEntry)
            .map((entry) => (
              <ChildEntry
                key={entry.id}
                subentryIconColor={colors?.[temporaryColorScheme]?.subentryIconColor || "#42526e"}
                subentryTextColor={
                  colors?.[temporaryColorScheme]?.subentryTextColor === undefined ||
                  colors?.[temporaryColorScheme]?.subentryTextColor === "#42526e"
                    ? themes[temporaryColorScheme]?.navigation.london.menuContentTitle
                    : colors?.[temporaryColorScheme]?.subentryTextColor
                }
                subentryBackgroundHoverColor={
                  colors?.[temporaryColorScheme]?.subentryBackgroundHoverColor === undefined ||
                  colors?.[temporaryColorScheme]?.subentryBackgroundHoverColor === "0000000"
                    ? themes[temporaryColorScheme]?.navigation.denver.childEntryBackgroundHover
                    : colors?.[temporaryColorScheme]?.subentryBackgroundHoverColor
                }
                subentryTextIconHover={colors?.[temporaryColorScheme]?.subentryTextIconHover}
                backgroundColor={{
                  inactive: themes[temporaryColorScheme]?.navigation.denver.childEntryBackground,
                  hover: themes[temporaryColorScheme]?.navigation.denver.childEntryBackgroundHover,
                }}
                borderColor={{
                  inactive: themes[temporaryColorScheme]?.navigation.denver.childEntryBorder,
                  hover: themes[temporaryColorScheme]?.navigation.denver.childEntryBackgroundHoverBorder,
                }}
                onClick={() => handleClickOnChildEntry(entry, isPreview)}
              >
                {rootEntry.data.type === "icon" && (entry.data?.icon?.type === "icon" || !entry.data?.icon?.type) && (
                  <div className="child-icon-bg-container">
                    <EntryIcon icon={entry.data.icon} />
                  </div>
                )}

                {rootEntry.data.type === "icon" && entry.data?.icon?.type === "emoji" && (
                  <div className="child-icon-bg-container">
                    <em-emoji shortcodes={entry.data?.icon?.shortcodes} size="1.2em" />
                  </div>
                )}

                {rootEntry.data.type === "icon" && entry.data?.icon?.type === "app" && (
                  <div className="child-icon-bg-container">
                    <img src={entry.data.icon?.icon} alt="" width={20} height={20} style={{ objectFit: "contain" }} />
                  </div>
                )}

                {rootEntry.data.type === "image" && (
                  <>
                    <ImageContainer imageSize={rootEntry.data?.imageSize || 120} image={getImage(entry.data.image)} />
                    {checkIfEntryImageEditable(entry.id) && (
                      <EditImageContainer
                        className="edit-image-container"
                        onClick={() => {
                          if (checkIfEntryImageEditable(entry.id)) {
                            setEditingEntry(entry);
                            setSelectedSidebarType(DrawerTypes.EDIT_ENTRY);
                            setSelectedHelperDrawerType(HelperDrawerTypes.BACKGROUND);
                            setIsOpen(true);
                            setIsHelperDrawerOpen(true);
                          }
                          return;
                        }}
                      >
                        <div className="background" />
                        <div className="icon-wrapper">
                          <ImageIcon primaryColor="#44546F" />
                        </div>
                      </EditImageContainer>
                    )}
                  </>
                )}

                <div>
                  <div className="child-name denver">{entry?.data?.name}</div>
                </div>

                {(entry?.data?.link || []).map((link) => (
                  <Link
                    key={link.id}
                    textColor={
                      colors?.[temporaryColorScheme]?.subentryLinkColor === undefined ||
                      colors?.[temporaryColorScheme]?.subentryLinkColor === "#42526e"
                        ? themes[temporaryColorScheme]?.navigation.london.menuContentTitle
                        : colors?.[temporaryColorScheme]?.subentryLinkColor
                    }
                    textHoverColor={
                      colors?.[temporaryColorScheme]?.subentryLinkHoverColor === undefined ||
                      colors?.[temporaryColorScheme]?.subentryLinkHoverColor === "0000000"
                        ? themes[temporaryColorScheme]?.navigation.denver.childEntryBackgroundHover
                        : colors?.[temporaryColorScheme]?.subentryLinkHoverColor
                    }
                    onClick={() => {
                      if (!isPreview) {
                        handleClickOnEntryLink(link);
                      }
                    }}
                  >
                    {link.linkName && <span className="link-name">{link.linkName}</span>}
                  </Link>
                ))}
              </ChildEntry>
            ))}
        </ChildMenuWrapper>
      </MenuContentPositioner>
    </MenuContentWrapper>
  );
}

export default Denver;
