import React, { useEffect, useRef } from "react";
import { useQuery } from "react-query";
import Dashboard from "../Components/Hub/Components/Dashboard/Dashboard";
import { usePanelsStore } from "../Components/Hub/Components/Dashboard/store/panels-store";
import { useCurrentUserStore } from "../Components/Hub/current-user-store";
import { fetchIsInitialized } from "../Components/UserDatabase/datagrid/api";
import { checkContentVizLicense } from "../Shared/Functions/checkContentvizLicense";
import { checkMacroSuiteLicense } from "../Shared/Functions/checkMacrosuiteLicense";
import { useContentVizConnectionStore } from "../Shared/store/contentviz-connection-store";
import { useMacroSuiteConnectionStore } from "../Shared/store/macrosuite-connection-store";

function EmbedView() {
  const embedRef = useRef();

  const { data: isInitialized } = useQuery("database-is-initialized", fetchIsInitialized, {
    retry: 0,
    select: (response) => {
      const { data } = response;
      return data;
    },
  });

  const { setCurrentUser, setIsCurrentUserAdmin } = useCurrentUserStore((state) => ({
    setCurrentUser: state.setCurrentUser,
    setIsCurrentUserAdmin: state.setIsCurrentUserAdmin,
  }));

  const setHasInitEmployeeDatabase = usePanelsStore((state) => state.setHasInitEmployeeDatabase);

  const setIsMacroSuiteInstalled = useMacroSuiteConnectionStore((state) => state.setIsMacroSuiteInstalled);
  const setIsContentVizInstalled = useContentVizConnectionStore((state) => state.setIsContentVizInstalled);

  const resizeFix = () => {
    let dashboardHeight;

    setInterval(() => {
      if (embedRef.current) {
        const currentHeight = `${embedRef.current.offsetHeight + 35}px`;

        if (dashboardHeight !== currentHeight) {
          dashboardHeight = currentHeight;
          window.AP?.resize("100%", currentHeight);
        }
      }
    }, 50);
  };

  useEffect(() => {
    resizeFix();
    window.AP.request("/rest/api/user/current?expand=operations")
      .then((payload) => JSON.parse(payload.body))
      .then((user) => {
        setCurrentUser(user);
        setIsCurrentUserAdmin(false);
      })
      .catch(() => {
        setCurrentUser(undefined);
        setIsCurrentUserAdmin(false);
      });
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const isMacroSuiteInstalled = await checkMacroSuiteLicense();
        setIsMacroSuiteInstalled(!!isMacroSuiteInstalled);

        const isContentVizInstalled = await checkContentVizLicense();
        setIsContentVizInstalled(!!isContentVizInstalled);
      } catch (error) {
        setIsMacroSuiteInstalled(false);
        setIsContentVizInstalled(false);
      }
    })();
  }, []);

  useEffect(() => {
    setHasInitEmployeeDatabase(!!isInitialized);
  }, [isInitialized]);

  return (
    <div ref={embedRef}>
      <Dashboard isInMacro />
    </div>
  );
}

export default EmbedView;
