import React from "react";
import { Section, SmallTitle, Subtitle } from "../../styled/views";
import GridView from "../GridView";

function Employees({ panelData, totalSize, fetchNextPage, hasNextPage, hasActiveFilters }) {
  if (!totalSize) return null;

  return hasActiveFilters ? (
    <Section>
      <GridView totalSize={totalSize} fetchNextPage={fetchNextPage} hasNextPage={hasNextPage} />
    </Section>
  ) : (
    <Section>
      <SmallTitle>{panelData.title}</SmallTitle>
      <Subtitle>{panelData.description}</Subtitle>
      <GridView totalSize={totalSize} fetchNextPage={fetchNextPage} hasNextPage={hasNextPage} />
    </Section>
  );
}

export default Employees;
