import styled from "styled-components";

export const AttachmentsUploadBox = styled.div`
  margin-top: 20px;
  box-sizing: border-box;
  background: ${({ theme }) => theme.sidebar.inputBackground};
  border: 1px dashed ${({ theme }) => theme.sidebar.seperator};
  border-radius: 4px;
  padding: 25px 0px;

  cursor: ${({ isDisabled }) => (isDisabled ? "no-drop" : "default")};
  opacity: ${({ isDisabled }) => (isDisabled ? "0.5" : "1")};

  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 20px;
`;

export const AttachmentsTitle = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.sidebar.atttachmentsText};
`;

export const AttachmentsDesc = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.sidebar.atttachmentsText};
`;

export const AttachmentsLink = styled.label`
  color: ${({ theme }) => theme.global.primaryColor};
  cursor: ${({ isDisabled }) => (isDisabled ? "not-allowed" : "pointer")};

  &:hover {
    text-decoration: underline;
  }
`;

export const AlertContainer = styled.div`
  padding-top: 15px;
`;

export const AlertMsg = styled.div`
  font-size: 14px;
  line-height: 14px;
  color: ${({ theme }) => theme.global.primaryColor};

  display: flex;
  align-items: center;
  column-gap: 15px;
`;
