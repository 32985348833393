import React from "react";
import styled from "styled-components";
import Toggle from "@atlaskit/toggle";
import { Label } from "../../../../ContentBuilder/Shared/Filter/styled";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
`;

const DateFormat = ({ recurring, onUpdate }) => (
  <>
    <Container>
      <Label style={{ margin: 0 }}>Recurring</Label>
      <Toggle onChange={() => onUpdate(!recurring)} isChecked={recurring} />
    </Container>
  </>
);

export default DateFormat;
