export const MenuStyleTypes = {
  LONDON: "london",
  SYDNEY: "sydney",
  PALO_ALTO: "palo_alto",
  HOUSTON: "houston",
  DENVER: "denver",
  HUM: "hum",
};

export const MenuTypeTitles = {
  [MenuStyleTypes.LONDON]: "London",
  [MenuStyleTypes.SYDNEY]: "Sydney",
  [MenuStyleTypes.PALO_ALTO]: "Palo Alto",
  [MenuStyleTypes.HOUSTON]: "Houston",
  [MenuStyleTypes.DENVER]: "Denver",
  [MenuStyleTypes.HUM]: "Hum",
};
