import { useState, useEffect, useRef } from "react";
import styled, { useTheme } from "styled-components";
import debounce from "lodash.debounce";

import TextField from "@atlaskit/textfield";
import SearchIcon from "@atlaskit/icon/glyph/search";

import { useDatabaseUIStore } from "../database-ui-store";
import { searchUsers } from "../../Hub/Components/PeopleBase/apiPeople";
import FilterService from "../filter-service";

const Container = styled.div`
  & > div {
    margin-right: 16px;
    border-color: ${({ border }) => border};

    input {
      font-size: 13px;
    }
  }
`;

const Search = () => {
  const [searchValue, setSearchValue] = useState("");
  const [isSearching, setIsSearching] = useState(false);

  const theme = useTheme();

  const { updateFilter, setSelectedFilters, resetFilters, selectedFilters } = useDatabaseUIStore((state) => {
    return {
      resetFilters: state.resetFilters,
      updateFilter: state.updateFilter,
      setSelectedFilters: state.setSelectedFilters,
      selectedFilters: state.selectedFilters,
    };
  });

  const handleSearch = async (query) => {
    let searchFilter = {
      type: "USER",
      isMultiUser: true,
      value: [null],
      isSearch: true,
    };

    try {
      const request = await searchUsers(query);
      const response = JSON.parse(request.body || "{}");

      if (response?.results?.length) {
        const accountIds = response.results
          .filter(({ user }) => user.accountType === "atlassian")
          .map(({ user }) => user.accountId);

        searchFilter.value = accountIds;
      }
    } catch (e) {
      console.error(`Failed to search users: ${query}`, e.message);
    }

    setIsSearching(true);
    updateFilter(searchFilter);
    setSelectedFilters(FilterService.mapFilters([searchFilter]));
  };

  const handleSearchDebounce = useRef(
    debounce((query) => {
      handleSearch(query);
    }, 800),
  ).current;

  useEffect(() => {
    if (isSearching) {
      setIsSearching(false);
    } else if (!selectedFilters.find((filter) => filter.isSearch)) {
      setSearchValue("");
    }
  }, [selectedFilters]);

  return (
    <Container border={theme.shared.form.input.border}>
      <TextField
        placeholder="Search..."
        value={searchValue}
        isCompact
        onChange={(e) => {
          const { value } = e.target;
          setSearchValue(value);
          if (value) {
            handleSearchDebounce(value);
          }
        }}
        onKeyUp={() => {
          if (!searchValue) {
            resetFilters([]);
          }
        }}
        elemAfterInput={
          <span style={{ marginRight: "4px" }}>
            <SearchIcon size="small" primaryColor={theme.shared.form.input.border} />
          </span>
        }
      />
    </Container>
  );
};

export default Search;
