import Spinner from "@atlaskit/spinner";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import styled, { useTheme } from "styled-components";
import EmptyState from "../../../../../../Shared/Components/EmptyState";
import { fetchRecords } from "../../../../../UserDatabase/datagrid/api";
import { COLOR_TYPES } from "../../../Settings/General/BrandAndColors/color-types";
import { useCorporateIdentityStore } from "../../../Settings/General/BrandAndColors/corporate-identity-store";
import { handleDataForActiveUsers } from "../../../Shared/Panels/view/active-users/utils/active-users-data-handler";
import Pagination from "../../../Shared/PanelsPagination/Pagination";
import { PanelsPaginationTypes } from "../../../Shared/PanelsPagination/panels-pagination-types";
import { useSidebarStore } from "../../store/sidebar-store";
import { Section, SmallTitle, SpinnerContainer, Subtitle } from "../../styled/views";
import { fetchRecentContent } from "../api";
import { createProfileLink } from "../createProfileLink";

const LinkWrapper = styled.a`
  all: unset;
`;

const UserGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-gap: 24px;
`;

const UserCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  box-shadow:
    rgba(9, 30, 66, 0.25) 0px 1px 3px,
    rgba(9, 30, 66, 0.31) 0px 0px 1px;
  border-radius: ${({ borderRadius }) => `${borderRadius}`};
  background: ${({ theme }) => theme.shared.card.background};
  border: 1px solid ${({ theme }) => theme.shared.card.border.box};
  cursor: pointer;
  text-align: center;
  transition: box-shadow linear 75ms;

  &:hover {
    box-shadow:
      rgba(9, 30, 66, 0.31) 0px 0px 1px 0px,
      rgba(9, 30, 66, 0.25) 0px 8px 16px -6px;
  }
`;

const UserImage = styled.img`
  box-sizing: border-box;
  height: ${({ size }) => (size ? `${size}px` : "72px")};
  width: ${({ size }) => (size ? `${size}px` : "72px")};
  border-radius: 50%;
  object-fit: cover;
`;

const Name = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: ${({ theme }) => theme.shared.card.title};
  margin-top: 16px;
  margin-bottom: 4px;
`;

const Status = styled.span`
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  color: ${({ theme }) => theme.shared.card.footer};

  .link {
    cursor: pointer;
    color: ${({ theme }) => theme.global.text.blue};
    &:hover {
      text-decoration: underline;
    }
  }
`;

const Updated = styled.span`
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  color: ${({ theme }) => theme.shared.card.footer};
  margin-top: 16px;
`;

function ActiveUsers({ panelData }) {
  const [activeUsers, setActiveUsers] = useState([]);
  const [page, setPage] = useState(1);

  const borderRadius = useCorporateIdentityStore((state) => state.borderRadius);
  const colors = useCorporateIdentityStore((state) => state.colors);
  const setDisableClickOutside = useSidebarStore((state) => state.setDisableClickOutside);

  const primaryColor = colors ? colors[COLOR_TYPES.PRIMARY] : "#0065ff";
  const maxNumberOfItems = panelData.maxNumberOfItems || 4;
  const indexOfLast = page * maxNumberOfItems;
  const indexOfFirst = indexOfLast - maxNumberOfItems;

  const theme = useTheme();

  const { isFetching, data } = useQuery(["active-users"], fetchRecentContent, {
    retry: 0,
    select: (response) => {
      const { body } = response;
      const { results } = JSON.parse(body);
      return handleDataForActiveUsers(results).slice(0, 8);
    },
  });

  const activeUsersIds = activeUsers?.map((activeUser) => activeUser.userData.accountId);

  const { isFetching: isUsersRecordsFetching, data: usersRecords } = useQuery(
    [
      "active-users-records",
      {
        filters: [
          { isMultiUser: true, type: "BOOLEAN", value: true, column: "hidden", condition: "NOT" },
          { isMultiUser: true, type: "USER", value: activeUsersIds },
        ],
      },
    ],
    fetchRecords,
    {
      enabled: !!activeUsersIds?.length,
      retry: 0,
      select: (response) => {
        const { data } = response;
        return data;
      },
    },
  );

  useEffect(() => {
    if (!!data?.length) {
      setActiveUsers([...data]);
    }
  }, [data]);

  useEffect(() => {
    setPage(1);
  }, [maxNumberOfItems, panelData.pagination]);

  const dynamicIndexOfFirst = {
    [PanelsPaginationTypes.SHOW_MORE]: 0,
    [PanelsPaginationTypes.ARROWS]: indexOfFirst,
  };

  const filteredUsersIds = usersRecords?.hits.map((userRecord) => userRecord.accountId);
  const activeUsersFilter = (activeUser) => filteredUsersIds?.includes(activeUser.userData.accountId);
  const peoplePaginated = activeUsers
    .filter(activeUsersFilter)
    .slice(dynamicIndexOfFirst[panelData.pagination], indexOfLast);
  const maxPage = Math.ceil(activeUsers?.filter(activeUsersFilter).length / maxNumberOfItems);

  const filterByPanelEvents = (page) => {
    if (page.type === "comment") {
      const event = panelData.events.find((rule) => rule.type === "comment" || rule.label === "Comment posted");
      return event?.value;
    }
    return panelData.events.find((rule) => page.status === rule.status && page.type === rule.type)?.value;
  };

  const showEmptyState = peoplePaginated.every((page) => !filterByPanelEvents(page));

  return (
    <Section>
      <SmallTitle>{panelData.title}</SmallTitle>
      <Subtitle>{panelData.description}</Subtitle>
      {isFetching && (
        <SpinnerContainer>
          <Spinner size="xlarge" />
        </SpinnerContainer>
      )}

      {showEmptyState && (
        <EmptyState
          title="No one to show"
          description="Add, remove or adjust filters in the panel configuration."
          Image={() => {
            return <img src={`/images/hub/no-recently-active-users-empty-state-${theme.global.name}.svg`} alt="" />;
          }}
        />
      )}

      {!isFetching && !isUsersRecordsFetching && !!activeUsers?.length && (
        <>
          <UserGrid>
            {peoplePaginated.map(
              (page) =>
                filterByPanelEvents(page) && (
                  <LinkWrapper key={page.id} target="_top" href={createProfileLink(page.userData.accountId)}>
                    <UserCardWrapper borderRadius={borderRadius}>
                      <UserImage size={panelData.profileImageSize} src={page.userData.profilePicture} />
                      <Name>{page.userData.name}</Name>
                      <Status>
                        {page.status}&nbsp;
                        <LinkWrapper target="_top" href={page.linkToPage}>
                          <span className="link" style={{ color: primaryColor }}>
                            a {page.type}
                          </span>
                        </LinkWrapper>
                        .
                      </Status>
                      <Updated>{page.friendlyModified}</Updated>
                    </UserCardWrapper>
                  </LinkWrapper>
                ),
            )}
          </UserGrid>

          {maxPage > 1 && (
            <Pagination
              type={panelData.pagination}
              page={page}
              updatePage={setPage}
              maxPage={maxPage}
              onWrapperHoverCallback={setDisableClickOutside}
            />
          )}
        </>
      )}
    </Section>
  );
}

export default ActiveUsers;
