import AddAdmins from "./AddAdmin";
import SectionMessageCustom from "../Message";

import { saveCosmosAdmins, getCosmosAdmins, fetchDetailsForUsers } from "./api";
import { formatUserData } from "./AddAdmin";
import { useAdminStore } from "./admin-store";
import { useQuery } from "react-query";
import { useEffect, useState } from "react";

function Admins() {
  const [loading, setLoading] = useState(false);

  const { isLoading: loadingSavedAdmins, data: cosmosAdminsResponse } = useQuery("cosmos-admins", getCosmosAdmins, {
    retry: 0,
    select: (response) => {
      const { data } = response;
      return data;
    },
  });

  const setCosmosAdmins = useAdminStore((state) => state.setCosmosAdmins);

  const saveUsersToDB = (users) => {
    const accIds = users.map((user) => user.value);
    saveCosmosAdmins(accIds)
      .then(() => {})
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    setLoading(true);
    (async () => {
      try {
        if (cosmosAdminsResponse?.length) {
          const response = await fetchDetailsForUsers(cosmosAdminsResponse);
          const { results } = JSON.parse(response?.body || `{"results":[]}`);
          setCosmosAdmins(results?.length ? results.map(({ user }) => formatUserData(user)) : []);
        } else {
          setCosmosAdmins([]);
        }
      } catch (error) {
        setCosmosAdmins([]);
      } finally {
        setLoading(false);
      }
    })();
  }, [cosmosAdminsResponse]);

  const isLoading = loadingSavedAdmins || loading;

  return (
    <>
      <AddAdmins loading={isLoading} saveUsersToDB={saveUsersToDB} />

      <SectionMessageCustom
        title={"Cosmos Admins"}
        description={
          "By default, only Confluence administrators can configure Cosmos. You can give users Cosmos admin premissions without making them Confluence admins"
        }
      />
    </>
  );
}

export default Admins;
