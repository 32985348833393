import styled from "styled-components";
const TabSwitcherWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  place-items: center;
  gap: 10px;
`;

const TabButton = styled.div`
  display: grid;
  place-items: center;
  height: 32px;
  width: 100%;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  background: ${({ isActive, theme }) => (isActive ? "#0065ff1a" : theme.navigation.settings.typeBackground)};
  color: ${({ isActive, theme }) => (isActive ? "#0065ff" : theme.navigation.settings.typeColor)};
`;

const TypeSwitcher = ({ options = [], onClick = () => {}, activeType = "" }) => (
  <TabSwitcherWrapper>
    {options.map(({ value, SelectedIcon }, idx) => (
      <TabButton onClick={() => onClick(value)} key={idx} isActive={value === activeType}>
        <SelectedIcon />
      </TabButton>
    ))}
  </TabSwitcherWrapper>
);

export default TypeSwitcher;
