import EmptyState from "../../../../../../../Shared/Components/EmptyState";
import { Panel, PanelContent, PanelTitle } from "../../../dashboard-styled";
import NoPagesSVG from "../../images/NoPagesSVG";

export const EmptyManual = ({ panelTitle, displayTitle }) => (
  <Panel>
    {displayTitle && <PanelTitle>{panelTitle}</PanelTitle>}
    <PanelContent>
      <EmptyState title="Add your spaces" description="Try selecting some spaces in the editor." Image={NoPagesSVG} />
    </PanelContent>
  </Panel>
);

export const EmptyForFilters = ({ panelTitle, displayTitle }) => (
  <Panel>
    {displayTitle && <PanelTitle>{panelTitle}</PanelTitle>}
    <PanelContent>
      <EmptyState
        title="We couldn't find anything matching filters"
        description="Try clearing some filters."
        Image={NoPagesSVG}
      />
    </PanelContent>
  </Panel>
);

export const EmptyNoSpaces = ({ panelTitle, displayTitle }) => (
  <Panel>
    {displayTitle && <PanelTitle>{panelTitle}</PanelTitle>}
    <PanelContent>
      <EmptyState
        title="Set up your spaces"
        description="There are currently no spaces on your instance."
        Image={NoPagesSVG}
      />
    </PanelContent>
  </Panel>
);
