import atlassianUserCache from "./AtlassianUserCache";
import { emailService } from "./EmailService";

class AtlassianRestService {
  async searchForPage(query, limit = 25, showOnlyGlobalPages = false) {
    const url = `/rest/api/search?cql=type in (page, blogpost) and (title~"${query}*")${
      showOnlyGlobalPages ? ` and space.type=global` : ""
    }&limit=${limit}&expand=content.history,content.space&excerpt=none`;
    try {
      return await this.fetch(url);
    } catch (e) {
      console.log("cant fetch pages");
      console.log(e);
      return {
        results: [],
      };
    }
  }

  async searchForBlog(query, limit = 25) {
    const url = `/rest/api/search?cql=type=blogpost and (title~"${query}*")&limit=${limit}&expand=content.history,content.space,content.metadata.labels,content.metadata.likes,content.children.comment&excerpt=none`;
    try {
      return await this.fetch(url);
    } catch (e) {
      console.log("cant fetch blogs");
      console.log(e);
      return {
        results: [],
      };
    }
  }

  async getContentpropertyBlogs(selectedCategory) {
    const url = encodeURI(
      `/rest/api/search?cql=content.property[cosmosnewsmetadata].categories~"${selectedCategory}"&expand=content.history,content.space,content.metadata.labels,content.metadata.likes,content.children.comment,content.space.icon`,
    );
    try {
      return await this.fetch(url);
    } catch (e) {
      console.log("cant fetch blogs");
      console.log(e);
      return {
        results: [],
      };
    }
  }

  async fetchRecentlyViewedPages(numberOfPages = 10) {
    const url = `/rest/api/search?cql=type in (page, blogpost) and id in recentlyViewedContent(${numberOfPages})&limit=25&expand=content.history,content.space&excerpt=none`;
    try {
      return await this.fetch(url);
    } catch (e) {
      console.log("cant fetch recently viewed pages");
      console.log(e);
    }
  }

  async searchForSpace(query) {
    const url = query
      ? `/rest/api/search?cql=type=space and (space.title~"${query}*" or space.key IN ("${query}"))&limit=25&expand=space.icon,space.homepage`
      : "/rest/api/search?cql=type=space&expand=space.icon,space.homepage";
    try {
      return await this.fetch(url);
    } catch (e) {
      console.log("cant fetch spaces");
      console.log(e);
    }
  }

  async getPageById(contentId) {
    const response = await this.fetch(
      `/rest/api/search?cql=type in (page, blogpost) and id=${contentId}&limit=25&expand=content.history,content.space,content.metadata.labels`,
    );
    if (response.results.length > 0) {
      return response.results[0];
    }
    return undefined;
  }

  async getBlogById(contentId) {
    const response = await this.fetch(
      `/rest/api/search?cql=type=blogpost and id=${contentId}&limit=25&expand=content.history,content.space,content.metadata.labels`,
    );
    if (response.results.length > 0) {
      return response.results[0];
    }
    return undefined;
  }

  async getSpaceByKey(spaceKey) {
    return await this.fetch(`/rest/api/space/${spaceKey}?expand=icon,homepage`);
  }

  async getCurrentUser() {
    return await this.fetch("/rest/api/user/current");
  }

  async getUserByAccountId(accountId) {
    try {
      const userFromCache = atlassianUserCache.get(accountId);
      if (userFromCache) {
        return userFromCache;
      }
      const freshUser = await this.fetch(`/rest/api/user?accountId=${accountId}&expand=isExternalCollaborator`);
      atlassianUserCache.putIfAbsent(accountId, freshUser);
      return freshUser;
    } catch (e) {
      console.warn("Could not fetch user with accountId " + accountId + ". Returning null.");
      console.warn(e);
      return null;
    }
  }

  async findUsersByName(query) {
    const cql = query
      ? `/rest/api/search/user?cql=user.fullname~"${query}"*&sitePermissionTypeFilter=all`
      : `/rest/api/search/user?cql=type=user&sitePermissionTypeFilter=all`;
    try {
      return await this.fetch(cql);
    } catch (e) {
      console.log("cant fetch users");
      console.log(e);
    }
  }

  async getGroupsByName(query) {
    const groupPickerUrl = ` /wiki/rest/api/group/picker?query=${query}`;
    try {
      return await this.fetch(groupPickerUrl);
    } catch (e) {
      console.log("cant fetch groups");
      console.log(e);
    }
  }

  async getGroups() {
    try {
      return await this.fetch(`/rest/api/group`);
    } catch (error) {
      return undefined;
    }
  }

  async getGroupById(groupId) {
    const url = ` /wiki/rest/api/group/by-id?id=${groupId}`;
    try {
      return await this.fetch(url);
    } catch (e) {
      console.log("cant fetch group");
      console.log(e);
    }
  }

  async getSpaces(labels, type, status, start, limit) {
    const labelsSplitted = labels.join(",");
    let url = `/rest/api/space?type=${type}&status=${status}&start=${start}&limit=${limit}`;
    if (labelsSplitted.length > 0) {
      url = `${url}&label=${labelsSplitted}`;
    }
    try {
      return await this.fetch(url);
    } catch (e) {
      console.log("cant fetch spaces");
      console.log(e);
    }
  }

  async getBlogLabelsByName(name) {
    const url = `/rest/api/label?type=blogpost&name=${name}`;
    try {
      return await this.fetch(url);
    } catch (e) {
      console.log("cant fetch labels");
      console.log(e);
    }
  }

  async searchPages(labels, contributors, spaces, parentPageId, limit, cursor = undefined) {
    let cql = "type in (page, blogpost)";
    if (labels.length > 0) {
      cql = cql + ` AND label in (${labels.join(",")})`;
    }
    if (contributors.length > 0) {
      cql = cql + ` AND contributor.accountid in (${contributors.map((c) => `"${c}"`).join(",")})`;
    }
    if (spaces.length > 0) {
      cql = cql + ` AND space in (${spaces.join(",")})`;
    }
    if (parentPageId) {
      cql = cql + ` AND parent=${parentPageId}`;
    }
    let url = `/rest/api/search?cql=${cql}&limit=${limit}&expand=content.space`;
    if (cursor) {
      url = `${url}&cursor=${cursor}`;
    }
    try {
      return await this.fetch(url);
    } catch (e) {
      console.log("cant fetch pages");
      console.log(e);
    }
  }

  async searchBlogposts(labels, contributors, spaces, limit, cursor = undefined) {
    let cql = "type = blogpost";
    if (labels.length > 0) {
      cql = cql + ` AND label in (${labels.join(",")})`;
    }
    if (contributors.length > 0) {
      cql = cql + ` AND contributor.accountid in (${contributors.map((c) => `"${c}"`).join(",")})`;
    }
    if (spaces.length > 0) {
      cql = cql + ` AND space in (${spaces.join(",")})`;
    }

    cql = cql + ` ORDER BY created desc`;

    let url = `/rest/api/search?cql=${cql}&limit=${limit}&expand=content.space`;
    if (cursor) {
      url = `${url}&cursor=${cursor}`;
    }
    try {
      return await this.fetch(url);
    } catch (e) {
      console.log("cant fetch blogposts");
      console.log(e);
    }
  }

  async getCoverPictureOfContent(contentId) {
    try {
      const url = `/rest/api/content/${contentId}/property/cosmos-random-image`;
      const coverPictureResponse = await this.fetch(url);
      return JSON.parse(coverPictureResponse.value).id;
    } catch (e) {
      console.log("error", e);
      return null;
    }
  }

  getProfilePictureUrl(path) {
    const hostUrl = window.AP._hostOrigin;
    if (hostUrl === null) {
      console.error("No AP object on window");
    }
    return hostUrl + path;
  }

  async fetchUsers(accounts) {
    if (!accounts.length) {
      return [];
    }

    const remainder = accounts.splice(25);

    const cql = `user.accountid = ${accounts.map((id) => `"${id}"`).join(" OR user.accountid = ")}`;
    const data = await this.fetch(`/rest/api/search/user?cql=${cql}`);

    const bulk = data?.results?.map((data) => data.user);

    if (remainder.length) {
      const nextBulk = await this.fetchUsers(remainder);

      return [...bulk, ...nextBulk];
    }

    return bulk;
  }

  async fetchEmails(accounts) {
    if (!accounts.length) {
      return [];
    }

    const remainder = accounts.splice(25);

    const response = await emailService.getUsersEmailBulk(accounts);
    const bulk = response?.data || [];

    if (remainder.length) {
      const nextBulk = await this.fetchEmails(remainder);

      return [...bulk, ...nextBulk];
    }

    return bulk;
  }

  async fetch(path) {
    const AP = window.AP;
    if (!AP) {
      console.error("No AP object on window. Cant fetch " + path);
      return null;
    }
    const response = await AP.request(path);
    try {
      return JSON.parse(response.body);
    } catch (e) {
      console.error("Error fetching atlassian response", e);
      return null;
    }
  }
}

export const atlassianRestService = new AtlassianRestService();
