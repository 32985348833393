import React from "react";
import styled, { useTheme } from "styled-components";
import DragHandlerIcon from "@atlaskit/icon/glyph/drag-handler";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useDrag, useDrop } from "react-dnd";
import { usePages } from "../panels/types/pages/handlePageData";
import { DeleteIcon } from "../panels/edit/sidebar/components/Icons";

const RemoveContainer = styled.div`
  cursor: pointer;
  width: 24px;
  height: 24px;
  display: grid;
  align-items: center;
  justify-content: flex-end;

  flex: 1;
  justify-self: flex-end;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;

  border-bottom: 1px solid rgba(223 225 230 / 50%);

  ${({ isHoveringOver, orderDirection }) => {
    if (!isHoveringOver) {
      return null;
    }

    return orderDirection === "top-bottom" ? `border-bottom: 1px solid #aaa;` : `border-top: 1px solid #aaa;`;
  }};

  padding: 7px 0px;

  font-size: 14px;
  font-weight: 500;
  color: #172b4d;
  .actions {
    visibility: hidden;
  }
  &:hover {
    .actions {
      visibility: visible;
    }
  }
`;

const IconContainer = styled.div`
  display: grid;
  place-content: center;
  cursor: grab;

  svg {
    color: #8993a4 !important;
    fill: #8993a4 !important;
  }

  &:hover {
    opacity: 0.9;
  }
`;

const ItemTitle = styled.span`
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 70%;
`;

function ReorderableItem({ page, reorderPages, handleDelete }) {
  const theme = useTheme();
  const [{ isOver }, drop] = useDrop(() => ({
    accept: ["page"],
    drop: () => ({ id: page.id, ...page }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  }));

  const [{ draggedItem }, drag] = useDrag({
    type: "page",
    item: () => {
      return { id: page.id, type: "page", ...page };
    },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (item && dropResult) {
        reorderPages(item.id, dropResult.id);
      }
    },
    collect: (monitor) => ({
      draggedItem: monitor.getItem(),
    }),
  });

  const draggedItemPosition = draggedItem?.position ?? 0;
  const potentialTargetPosition = isOver ? page?.position : 0;
  return (
    <Item
      key={page.id}
      ref={drop}
      isHoveringOver={isOver}
      orderDirection={draggedItemPosition < potentialTargetPosition ? "top-bottom" : "bottom-top"}
    >
      <IconContainer ref={drag}>
        <DragHandlerIcon />
      </IconContainer>

      <ItemTitle style={{ color: theme.shared.card.title }}>{page.name}</ItemTitle>

      <RemoveContainer className="actions" onClick={() => handleDelete(page.id)}>
        <DeleteIcon />
      </RemoveContainer>
    </Item>
  );
}

function ManualPagesList({ manuallyPicked, onRemove, updateOrder }) {
  const pageQueries = usePages(manuallyPicked);

  const pageData = pageQueries.map((page) => page.data);

  const resetOrder = (list) => {
    const resetList = list.map((c, index) => {
      const slide = c;
      slide.position = index + 1;
      return slide;
    });
    return resetList;
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return resetOrder(result);
  };

  const reorderPages = (sourceId, targetId) => {
    if (sourceId === targetId) {
      return;
    }
    const content = [...pageData];

    const sourceIndex = content.findIndex((p) => p.id === sourceId);
    const targetIndex = content.findIndex((p) => p.id === targetId);
    const items = reorder(content, sourceIndex, targetIndex);
    updateOrder(items);
  };
  return (
    <DndProvider backend={HTML5Backend}>
      <Wrapper>
        {!!pageData?.length &&
          pageData
            .sort((a, b) => a.position - b.position)
            .map((data) => (
              <ReorderableItem key={data?.id} page={data} reorderPages={reorderPages} handleDelete={onRemove} />
            ))}
      </Wrapper>
    </DndProvider>
  );
}

export default ManualPagesList;
