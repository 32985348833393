import React from "react";

import Button from "@atlaskit/button/new";

import {
  ContentButtonLink,
  ManualContainer,
  ManualContainerDescription,
  ManualContainerHeading,
  TabContainer,
} from "../../styles";

const ManualNotifications = () => {
  return (
    <TabContainer style={{ display: "grid", placeContent: "center" }}>
      <ManualContainer style={{ paddingTop: "200px" }}>
        <ManualContainerHeading>Create your first notification</ManualContainerHeading>
        <ManualContainerDescription>
          Manually send notifications by customizing the content and adding deep links to specific blogs or screens.
          This helps target key updates to specific groups or the entire organization effectively.
        </ManualContainerDescription>

        <Button appearance="primary">Create</Button>
        <ContentButtonLink>Learn how</ContentButtonLink>
      </ManualContainer>
    </TabContainer>
  );
};

export default ManualNotifications;
