import React from "react";
import Tag from "@atlaskit/tag";

function Text({ value, removeCallback }) {
  {
    <Tag text={value} onAfterRemoveAction={() => removeCallback(value)} />;
  }
}

export default Text;
