import React, { useRef, useState, useEffect } from "react";
import styled from "styled-components";
import * as ReactDOM from "react-dom";
import MoreIcon from "@atlaskit/icon/glyph/more";
import Popup from "@atlaskit/popup";
import Field from "@atlaskit/form/Field";
import TextField from "@atlaskit/textfield";
import Toggle from "@atlaskit/toggle";
import Button from "@atlaskit/button";

import { ControlledMenu, MenuItem, MenuDivider } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/theme-dark.css";
import "@szhsin/react-menu/dist/transitions/slide.css";

import SelectOptions from "./fields/SelectOptions";
import NumberFormat from "./fields/NumberFormat";
import DeleteField from "./fields/DeleteField";
import CreateField from "./fields/CreateField";

import { useDatabaseUIStore } from "../../database-ui-store";
import shallow from "zustand/shallow";
import { useRecordsStore } from "../../records-store";

import { useTheme } from "styled-components";
import { COLOR_TYPES } from "../../../Hub/Components/Settings/General/BrandAndColors/color-types";
import { useCorporateIdentityStore } from "../../../Hub/Components/Settings/General/BrandAndColors/corporate-identity-store";

import { FieldType } from "../../field-type";
import { THEME_NAME_DARK, THEME_NAME_LIGHT } from "../../../Navigation/functions/generateNewEntry";

const FieldFormatButton = styled.div`
  cursor: pointer;
`;

const ToggleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.375rem 1.6rem;
`;

const MenuWrapper = styled.div`
  ${({ theme }) => `
      .szh-menu {
        z-index: 9999;
        width: 320px;
        top: 10px !important;
        left: -187px !important;
        background-color: ${theme.shared.settings.database.header};
        border: 1px solid ${theme.shared.settings.database.border};

        .szh-menu__divider {
          background-color: ${theme.shared.settings.database.border}
        }
      }
    `}

  .szh-menu__item {
    border-left: 2px solid transparent;

    &:hover {
      border-color: #0c66e4;
    }
  }
`;

const FieldWrapper = styled.div`
  label {
    color: #626f86;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
  }
`;

export const RenameField = ({ fieldId, title, onSave, fieldLabel = "Name" }) => {
  const { updateField } = useRecordsStore((state) => {
    return {
      updateField: state.updateField,
    };
  }, shallow);

  const [value, setValue] = useState(title ?? "");
  const theme = useTheme();

  const colors = useCorporateIdentityStore((state) => state.colors);
  const primaryColor = colors[COLOR_TYPES.PRIMARY] || "#0065ff";

  return (
    <div
      style={{ background: theme.shared.settings.database.background, padding: "1px 22px", width: "320px" }}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <FieldWrapper>
        <Field name="field-name" label={fieldLabel}>
          {({ fieldProps }) => {
            return (
              <TextField
                {...fieldProps}
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    updateField(fieldId, { title: value });
                    onSave();
                  }
                }}
                value={value}
                placeholder="Field name"
                onChange={(e) => {
                  setValue(e.target.value);
                }}
              />
            );
          }}
        </Field>
      </FieldWrapper>

      <div style={{ marginTop: "10px", textAlign: "right", fontSize: "14px", padding: "0px 12px" }}>
        <Button
          color={primaryColor}
          style={{ paddingRight: 0, color: `${primaryColor} !important` }}
          appearance="link"
          onClick={() => {
            updateField(fieldId, { title: value });
            onSave();
          }}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export const InsertField = ({ label, fieldId, position }) => {
  const [isOpen, setIsOpen] = useState(false);
  const theme = useTheme();

  const { insertField } = useRecordsStore((state) => {
    return { insertField: state.insertField };
  }, shallow);

  const { addColumn } = useDatabaseUIStore((state) => {
    return { addColumn: state.addColumn };
  }, shallow);

  return (
    <Popup
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      placement="bottom"
      content={() => {
        return ReactDOM.createPortal(
          <div
            style={{ background: theme.shared.settings.database.background, padding: "1px 22px", width: "inherit" }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <CreateField
              position={position}
              onCancel={() => {
                setIsOpen(false);
              }}
              onSave={(field) => {
                insertField(field);
                addColumn({ ...field.ui, id: field.id, type: field.type });
                setIsOpen(false);
              }}
              labelName="Column"
            />
          </div>,
          document.querySelector(`#portal-column-${fieldId}`),
        );
      }}
      trigger={(triggerProps) => (
        <div {...triggerProps} style={{ cursor: "pointer" }}>
          <MenuItem onClick={() => setIsOpen(!isOpen)}>{label}</MenuItem>
        </div>
      )}
    />
  );
};

export const SortField = ({ label, sortOrder, field, sortFunc }) => {
  const sortHandler = () => {
    sortFunc(sortOrder, field.id);
  };

  return <MenuItem onClick={sortHandler}>{label}</MenuItem>;
};

const HeaderDropDown = ({ field, importantChild, firstChild }) => {
  const [open, setOpen] = useState(false);
  const [renameIsOpen, setRenameIsOpen] = useState(false);
  const [confirmDeleteIsOpen, setConfirmDeleteIsOpen] = useState(false);
  const ref = useRef(null);
  const skipOpen = useRef(false);
  const deleteField = useRecordsStore((state) => state.deleteField);

  const theme = useTheme();
  const [isDark, setIsDark] = useState(THEME_NAME_LIGHT);

  const { fields, updateField, updateFieldUi, sortRecords } = useRecordsStore((state) => {
    return {
      fields: state.fields,
      updateField: state.updateField,
      updateFieldUi: state.updateFieldUi,
      sortRecords: state.sortRecords,
    };
  }, shallow);

  const toggleMenu = () => {
    if (!skipOpen.current) {
      setOpen(true);
    }
  };

  const closeMenu = (e) => {
    setOpen(false);
  };

  useEffect(() => {
    setIsDark(theme?.global.name === THEME_NAME_DARK ? THEME_NAME_DARK : THEME_NAME_LIGHT);
  }, [theme]);

  return (
    <>
      <div ref={ref} onClick={toggleMenu} className="button" style={{ height: "20px", width: "20px" }}>
        <MoreIcon
          primaryColor={
            open
              ? theme.shared.settings.database.records.headerDropdown.active
              : theme.shared.settings.database.records.headerDropdown.inactive
          }
        />
      </div>
      <MenuWrapper>
        <ControlledMenu
          skipOpen={skipOpen}
          state={open ? "open" : "closed"}
          anchorRef={ref}
          onClose={closeMenu}
          theming={isDark}
          align="center"
        >
          {firstChild}

          {importantChild}

          <Popup
            isOpen={renameIsOpen}
            onClose={() => setRenameIsOpen(false)}
            placement="bottom"
            content={() => {
              return ReactDOM.createPortal(
                <RenameField
                  fieldId={field.id}
                  title={field.title}
                  onSave={() => {
                    setRenameIsOpen(false);
                  }}
                  fieldLabel={"Column name"}
                />,
                document.querySelector(`#portal-column-${field.id}`),
              );
            }}
            trigger={(triggerProps) => (
              <>
                <div {...triggerProps} style={{ cursor: "pointer" }}>
                  <MenuItem onClick={() => setRenameIsOpen(!renameIsOpen)}>Rename column</MenuItem>
                </div>
              </>
            )}
          />

          <MenuDivider />

          <InsertField label="Insert column left" fieldId={field.id} position={field.ui.position} />
          <InsertField label="Insert column right" fieldId={field.id} position={field.ui.position + 1} />

          {field.type === FieldType.DATE && (
            <>
              <SortField label="Sort ascending" sortOrder="asc" field={field} sortFunc={sortRecords} />
              <SortField label="Sort descending" sortOrder="desc" field={field} sortFunc={sortRecords} />
            </>
          )}

          <MenuDivider />

          <Popup
            isOpen={confirmDeleteIsOpen}
            onClose={() => setConfirmDeleteIsOpen(false)}
            placement="bottom"
            content={() =>
              ReactDOM.createPortal(
                <DeleteField
                  onCancel={() => {
                    setConfirmDeleteIsOpen(false);
                  }}
                  onConfirm={() => {
                    deleteField(field.id);
                    setConfirmDeleteIsOpen(false);
                  }}
                />,
                document.querySelector(`#portal-column-${field.id}`),
              )
            }
            trigger={(triggerProps) => (
              <div {...triggerProps} style={{ cursor: "pointer", color: theme.global.text.red }}>
                <MenuItem
                  disabled={field.isEssential}
                  onClick={() => {
                    setConfirmDeleteIsOpen(true);
                  }}
                >
                  Delete column
                </MenuItem>
              </div>
            )}
          />
        </ControlledMenu>
      </MenuWrapper>
    </>
  );
};

export const SelectDropDown = ({ isCreatable, onUpdate }) => {
  return (
    <ToggleContainer>
      <span>User can create options</span>
      <Toggle isChecked={isCreatable} onChange={({ target: { checked } }) => onUpdate(checked)} />
    </ToggleContainer>
  );
};

export const SelectOptionsEditor = ({ options, onUpdate, fieldId }) => {
  const [editSelectOptionsOpen, setEditSelectOptionsOpen] = useState(false);
  const theme = useTheme();

  return (
    <Popup
      isOpen={editSelectOptionsOpen}
      onClose={() => setEditSelectOptionsOpen(false)}
      placement="bottom"
      content={() => {
        return ReactDOM.createPortal(
          <div
            style={{
              flexDirection: "column",
              background: theme.shared.settings.database.background,
              width: "100%",
              display: "flex",
            }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <SelectOptions existingOptions={options} onUpdate={onUpdate} notifyOnReorder />
          </div>,
          document.querySelector(`#portal-column-${fieldId}`),
        );
      }}
      trigger={(triggerProps) => (
        <div {...triggerProps}>
          <FieldFormatButton>
            <MenuItem
              onClick={() => setEditSelectOptionsOpen(!editSelectOptionsOpen)}
              style={{ color: theme.global.text.blue }}
            >
              Add/Edit options
            </MenuItem>
          </FieldFormatButton>
        </div>
      )}
    />
  );
};

export const DateDropDown = ({ recurring, onUpdate }) => {
  return (
    <ToggleContainer>
      <span>Recurring</span>
      <Toggle isChecked={recurring} onChange={({ target: { checked } }) => onUpdate(checked)} />
    </ToggleContainer>
  );
};

export const NumberDropDown = ({ fieldId, format, onUpdate }) => {
  const [editSelectOptionsOpen, setEditSelectOptionsOpen] = useState(false);
  const theme = useTheme();

  return (
    <>
      <Popup
        isOpen={editSelectOptionsOpen}
        onClose={() => setEditSelectOptionsOpen(false)}
        placement="bottom"
        content={() => {
          return ReactDOM.createPortal(
            <div
              style={{ background: theme.shared.settings.database.background, width: "320px", padding: "10px" }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <NumberFormat format={format} onUpdate={onUpdate} />
            </div>,
            document.querySelector(`#portal-column-${fieldId}`),
          );
        }}
        trigger={(triggerProps) => (
          <div {...triggerProps}>
            <FieldFormatButton>
              <MenuItem onClick={() => setEditSelectOptionsOpen(!editSelectOptionsOpen)}>Format settings</MenuItem>
            </FieldFormatButton>
          </div>
        )}
      />
    </>
  );
};

export default HeaderDropDown;
