import httpClient from "../../../../../Service/http-client";

export async function getWidgetsSettings({ queryKey }) {
  const url = "ac/confluence/widgets/settings";
  return httpClient.get(url);
}

export async function saveWidgetSettings(data) {
  const url = "ac/confluence/widgets/settings/save";
  return httpClient.post(url, { data });
}

export async function getWidgetSettingById({ queryKey }) {
  const [_key, { widgetId }] = queryKey;
  const url = `ac/confluence/widgets/setting?widgetId=${widgetId}`;
  return httpClient.get(url);
}
