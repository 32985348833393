import { useQueries } from "react-query";
import { getCoverPictureOfContent } from "../../../../ContentBuilder/Shared/cover-picture-provider";
import { htmlDecode } from "../../../../ContentBuilder/utils/decode-html-string";
import { getBlogById } from "../api";
import { getBlogsCategories } from "../panels/types/news/handleBlogData";

const FALLBACK_LOCALE = "en-US";

function mapBlogpost(blogpost, locale, categories) {
  return {
    id: blogpost.content.id,
    type: blogpost.content.type,
    name: blogpost.content.title,
    space: { name: blogpost.content.space.name },
    excerpt: htmlDecode(blogpost.excerpt),
    coverPictureCallback: async () => getCoverPictureOfContent(blogpost.content.id),
    getBlogsCategories: async () => getBlogsCategories(categories, blogpost.content.id),
    labels: blogpost.content.metadata?.labels?.results.map((l) => l.label) ?? [],
    url: `${window.AP._hostOrigin}/wiki${blogpost.url}`,
    createdBy: blogpost.content.history.createdBy.displayName,
    avatarUrl: `${window.AP._hostOrigin}${blogpost.content.history.createdBy.profilePicture.path}`,
    lastModified: blogpost.friendlyLastModified,
    createdFormatted: new Date(blogpost.content.history.createdDate).toLocaleString(`${locale ?? FALLBACK_LOCALE}`, {
      year: "numeric",
      month: "short",
      day: "numeric",
    }),
  };
}

export const useBlogs = (data, locale, categories) =>
  useQueries(
    data.map((blogpost) => {
      return {
        queryKey: ["blog", blogpost.contentId, blogpost.id],
        queryFn: () => {
          if (blogpost.contentId === 0) {
            return { isNew: true, loading: false, id: blogpost.id };
          }
          return getBlogById(blogpost.contentId);
        },
        retry: 0,
        placeholderData: {
          contentId: blogpost.contentId,
          loading: true,
          isPlaceholder: true,
        },
        select: (response) => {
          if (response.isPlaceholder || response.isNew) {
            return response;
          }
          const body = JSON.parse(response.body);

          if (!body?.results?.length) return {};

          const result = body.results.map((p) => {
            return {
              ...mapBlogpost(p, locale, categories),
              id: data.find((x) => x.id.toString() === blogpost.id).id,
            };
          });
          return result[0];
        },
      };
    }),
  );
