import styled from "styled-components";

export const SideNewsListContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 29px;
`;

export const OverlineWrapper = styled.div`
  display: grid;
  grid-template-columns: ${({ isTwoColumns }) => (isTwoColumns ? "18px auto" : "auto")};
  align-items: center;
  column-gap: 5px;
`;

export const PersonIcon = styled.img`
  width: 18px;
  height: 18px;
  border-radius: 50%;
  box-sizing: border-box;
  object-fit: cover;
`;

export const PersonDetails = styled.span`
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #5e6695;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Title = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.shared.news.title};
  margin-top: 9px;
  max-width: 260px;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

export const SpaceLabel = styled.div`
  width: fit-content;
  display: grid;
  place-content: center;

  padding: 3px 4px;
  background: ${({ backgroundColor }) => backgroundColor};
  border-radius: 3px;
  color: #000;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  margin-top: 10px;
`;

export const ShowMore = styled.div`
  margin-top: 16px;
  display: grid;
  place-content: center;
  text-align: center;
  cursor: pointer;

  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: ${({ primaryColor }) => primaryColor};

  &:hover {
    opacity: 0.9;
  }
`;
