import React, { useMemo } from "react";
import { PanelKeys } from "./panelTypes";
import KnowYourTeamPreview from "./panels/preview/KnowYourTeamPreview";
import UpcomingBirthdaysPreview from "./panels/preview/UpcomingBirthdaysPreview";
import WorkAnniversaryPreview from "./panels/preview/WorkAnniversaryPreview";
import RecentlyActiveUsersPreview from "./panels/preview/RecentlyActiveUsersPreview";
import NewStartersPreview from "./panels/preview/NewStartersPreview";
import UserBlogPostsPreview from "./panels/preview/UserBlogPostsPreview";
import IconCards from "../../Dashboard/panels/edit/sidebar/components/preview/types/IconCards";
import ImageCards from "../../Dashboard/panels/edit/sidebar/components/preview/types/ImageCards";
import TextCards from "../../Dashboard/panels/edit/sidebar/components/preview/types/TextCards";

const NoPreview = () => {
  return <div style={{ fontSize: "14px", color: "#172b4d" }}>No preview available.</div>;
};

function PreviewProvider({ panelKey }) {
  const AvailablePreviews = useMemo(
    () => ({
      [PanelKeys.KNOW_YOUR_TEAM]: KnowYourTeamPreview,
      [PanelKeys.UPCOMING_BIRTHDAY]: UpcomingBirthdaysPreview,
      [PanelKeys.RECENTLY_ACTIVE_USERS]: RecentlyActiveUsersPreview,
      [PanelKeys.WORK_ANNIVERSARY]: WorkAnniversaryPreview,
      [PanelKeys.NEW_STARTERS]: NewStartersPreview,
      [PanelKeys.USER_BLOG_POSTS]: UserBlogPostsPreview,
      [PanelKeys.ICON_CARDS]: IconCards,
      [PanelKeys.IMG_CARDS]: ImageCards,
      [PanelKeys.TEXT_CARDS]: TextCards,
    }),
    [],
  );

  const SelectedPreview = useMemo(() => {
    return AvailablePreviews[panelKey] ?? NoPreview;
  }, [panelKey, AvailablePreviews]);

  return <SelectedPreview />;
}

export default PreviewProvider;
