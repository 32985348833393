import { convertNavigationStructureToLatestVersion } from "./conversion";
import { createDefaultNavigation } from "./create-navigation";

export const loadNavigation = (rawData) => {
  try {
    if (!rawData) {
      return { active: false, inactive: true, publishedStructure: createDefaultNavigation() };
    }

    const converted = convertNavigationStructureToLatestVersion(rawData);
    const colorSchemeRoot = !("dark" in converted && "light" in converted)
      ? { dark: converted.colors, light: converted.colors }
      : { dark: converted.dark, light: converted.light };

    if (!converted?.publishedStructure?.length) {
      return { ...converted, publishedStructure: createDefaultNavigation() };
    }
    const parsedStructure = JSON.parse(converted.publishedStructure);

    const colorSchemeStructure = parsedStructure.map((item) => {
      if (item.parent === "0" && "colors" in item.data) {
        if ("dark" in item.data.colors && "light" in item.data.colors) {
          return item;
        }
        return { ...item, data: { ...item.data, colors: { light: item.data.colors, dark: item.data.colors } } };
      }

      return item;
    });

    return { ...converted, ...colorSchemeRoot, publishedStructure: colorSchemeStructure };
  } catch {
    return { ...rawData, publishedStructure: [] };
  }
};
