import React, { useMemo } from "react";
import Spinner from "@atlaskit/spinner";
import { useQuery } from "react-query";
import { fetchUserDatabaseFields } from "../../../../../../UserDatabase/datagrid/api";
import { SpinnerContainer } from "../../../../../styled/pages";
import useSavedCollections from "../../../../../../../Shared/Hooks/useSavedCollections";
import Layout from "./Layout";
import Design from "./Design";
import Content from "./Content";
import { TabTypes } from "../../../../Dashboard/panels/tabTypes";
import { PanelEditorWrapper } from "../../../../Dashboard/panels/edit/types/styled";

const tabComponents = {
  [TabTypes.LAYOUT]: Layout,
  [TabTypes.PEOPLE]: Content,
  [TabTypes.DESIGN]: Design,
};

function PeopleEditor({ panel, selectedTab, updatePanelDataProperty, updatePanelTitle }) {
  const { data, type } = panel;

  const { isLoading: fieldsLoading, data: fieldsData } = useQuery(
    ["userdatabase-fields", { includeEmail: false }],
    fetchUserDatabaseFields,
    {
      retry: 0,
      select: (response) => {
        const { data } = response;

        const dateFields = data?.filter((field) => field.type === "DATE") || [];
        const singleValueFields = data?.filter((field) => field.type === "SELECT" || field.type === "TEXT") || [];

        return { dateFields, singleValueFields };
      },
    },
  );

  const { collectionsData } = useSavedCollections({ areOptions: true });

  const tabComponentProps = useMemo(() => {
    return {
      [TabTypes.LAYOUT]: {
        panelTitle: panel.panelTitle,
        panelId: panel.id,
        data,
        fieldsData,
        updatePanelTitle,
        updatePanelDataProperty,
        collectionsData,
      },
      [TabTypes.PEOPLE]: {
        panelId: panel.id,
        data,
        updatePanelDataProperty,
        panelType: type,
        collectionsData,
      },
      [TabTypes.DESIGN]: {
        panelId: panel.id,
        data,
        updatePanelDataProperty,
        panelType: type,
      },
    };
  }, [panel, data, fieldsData, updatePanelTitle, updatePanelDataProperty, collectionsData, type]);

  const TabComponent = tabComponents[selectedTab];

  return (
    <PanelEditorWrapper>
      {fieldsLoading && (
        <SpinnerContainer>
          <Spinner size="medium" />
        </SpinnerContainer>
      )}
      {!fieldsLoading && <TabComponent {...tabComponentProps[selectedTab]} />}
    </PanelEditorWrapper>
  );
}

export default PeopleEditor;
