import styled from "styled-components";

export const GridViewWrapper = styled.div`
  display: grid;
  grid-template-columns: ${({ numberOfColumns }) => `repeat(${numberOfColumns}, minmax(0, 1fr))`};
  grid-gap: 24px;

  @media (max-width: 1200px) {
    grid-template-columns: ${({ numberOfColumns }) => `repeat(${numberOfColumns - 1}, minmax(0, 1fr))`};
  }
  @media (max-width: 1024px) {
    grid-template-columns: ${({ numberOfColumns }) => `repeat(${numberOfColumns - 2}, minmax(0, 1fr))`};
  }
`;

export const StartScreenWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 40px;
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  color: ${({ theme }) => theme.shared.panel.title};
  font-weight: 500;
`;

export const LargeTitle = styled(Title)`
  font-size: 36px;
  line-height: 43px;
`;

export const SmallTitle = styled(Title)`
  font-size: 24px;
  line-height: 29px;
`;

export const Subtitle = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #344563;
  margin-top: 8px;
  margin-bottom: 20px;
`;

export const SpinnerContainer = styled.div`
  display: grid;
  place-content: center;
  margin-top: 50px;
`;

export const FiltersCollectionsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FiltersContainer = styled.div`
  background-color: ${({ theme }) => theme.globalBackground};
`;

export const CollectionsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CollectionsLabel = styled.div`
  font-weight: 700;
  font-size: 11px;
  line-height: normal;
  text-transform: uppercase;
  color: #5e6695;
  margin-bottom: 23px;
`;

export const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.shared.label.filter};

  font-weight: 700;
  font-size: 11px;
  line-height: normal;
  margin-bottom: 8px;
`;

export const ShowHideMoreButton = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme }) => theme.global.primaryColor};

  cursor: pointer;
`;

export const ArrowIconContainer = styled.div`
  width: 8px;
  height: 14px;
  display: grid;
  place-content: center;
  margin-top: 2px;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${({ shouldBeFullWidth }) => {
    if (shouldBeFullWidth) {
      return `grid-area: 2 / 1 / 2 / 3; max-width: inherit;`;
    }
  }}
`;

export const FilteredRecordsFiltersContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const DividerLine = styled.div`
  border-bottom: ${({ theme }) => `1px solid ${theme.global.divider}`};
  margin-top: 28px;
  margin-bottom: 24px;
  width: 100%;
`;
