import { PanelTypes } from "../panels/panelTypes";

export const updateNewStartersBackgroundProperty = (panels) => {
  const newPanels = [
    ...panels.map((panel) => {
      if (panel.type === PanelTypes.NEW_USERS_MAIN && !panel?.migratedBgType) {
        return {
          ...panel,
          data: {
            ...panel.data,
            backgroundType: panel.data.backgroundType === "color" ? "image" : panel.data.backgroundType,
          },
          migratedBgType: true,
        };
      }
      return panel;
    }),
  ];

  return newPanels;
};
