import create from "zustand";
import _ from "lodash";

const deepFind =
  (callbackFn) =>
  ([firstInArray, ...restOfArray] = []) => {
    return (
      firstInArray &&
      (callbackFn(firstInArray)
        ? firstInArray
        : deepFind(callbackFn)(firstInArray.children) || deepFind(callbackFn)(restOfArray))
    );
  };

const findUserById = (id) => (structure) => deepFind((user) => user.id === id)([structure]);

export const useStructureStore = create((set, get) => ({
  structure: {
    id: "root-level",
    expanded: true,
    children: [],
  },
  setStructure: (data) => set((state) => ({ structure: data })),

  checkIsUserInStructure: (userAccountId) => {
    const structureCopy = { ...get().structure };
    const found = findUserById(userAccountId)(structureCopy);
    return !!found;
  },

  updateNodeExpandedState: (node) => {
    const structureCopy = { ...get().structure };

    let foundUser = findUserById(node.id)(structureCopy);

    if (!foundUser) return;

    foundUser.expanded = !node.expanded;

    return set((state) => ({ structure: structureCopy }));
  },
}));
