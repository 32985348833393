import React from "react";
import styled from "styled-components";
import { FieldContainer } from "./styled";

const Button = styled.div`
  background: ${({ isSelected, theme }) =>
    isSelected ? theme.shared.form.button.background.active : theme.shared.form.button.background.inactive};
  color: ${({ isSelected, theme }) =>
    isSelected ? theme.shared.form.button.text.active : theme.shared.form.button.text.inactive};
  border-radius: 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  display: grid;
  place-content: center;
  padding: 7.5px 0px;
  cursor: pointer;
  user-select: none;
`;

export const LoadingTypes = {
  DYNAMIC: "dynamic",
  MANUAL: "manual",
};

const DisableDynamicFilterToggle = ({ initialValue, onUpdate, customStyles = {} }) => {
  return (
    <FieldContainer
      style={{
        textAlign: "center",
        display: "grid",
        alignItems: "center",
        gridTemplateColumns: "1fr 1fr",
        columnGap: "12px",
        ...customStyles,
      }}
    >
      <Button
        isSelected={initialValue === LoadingTypes.DYNAMIC}
        onClick={() => {
          onUpdate(LoadingTypes.DYNAMIC);
        }}
      >
        Dynamic
      </Button>
      <Button
        isSelected={initialValue === LoadingTypes.MANUAL}
        onClick={() => {
          onUpdate(LoadingTypes.MANUAL);
        }}
      >
        Manual
      </Button>
    </FieldContainer>
  );
};

export default DisableDynamicFilterToggle;
