import React, { useEffect, useState } from "react";
import { PanelTypes } from "./panelTypes";

import Notification from "./types/Notification";
import Birthday from "./types/Birthday";
import Countdown from "./types/Countdown";
import Spaces from "./types/spaces/Spaces";
import Tasks from "./types/Tasks";
import Poll from "./types/Poll";
import Links from "./types/Links";
import Videos from "./types/Videos";
import NewUsers from "./types/NewUsers";
import UserStart from "./types/UserStart";
import News from "./types/news/News";
import Cards from "../../Shared/Panels/view/cards/Cards";
import Pages from "./types/pages/Pages";
import People from "../../Shared/Panels/view/people/People";
import ActiveUsers from "../../Shared/Panels/view/active-users/ActiveUsers";
import PersonalizedNews from "./types/news/PersonalizedNews";
import TargetedNews from "./types/news/TargetedNews";
import Gliffy from "./types/gliffy/Gliffy";
import ReleasedAI from "./types/ReleasedAI";

function PanelProvider({ panel, position }) {
  const [removeRowGap, setRemoveRowGap] = useState(false);

  useEffect(() => {
    window.AP.events.on("new-panel-added", ({ newPanelId }) => {
      const element = document.getElementById(newPanelId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    });
  }, []);

  const setShouldRemoveRowGap = (shouldRemove) => setRemoveRowGap(shouldRemove);

  return (
    <div id={panel.id} style={{ marginTop: removeRowGap ? "-24px" : "0px" }}>
      {panel.type === PanelTypes.USER_START && <UserStart {...panel} position={position} />}
      {panel.type === PanelTypes.NOTIFICATION && <Notification {...panel} position={position} />}
      {panel.type === PanelTypes.BIRTHDAY && <Birthday {...panel} position={position} />}
      {panel.type === PanelTypes.PEOPLE && <People {...panel} position={position} />}
      {panel.type === PanelTypes.NEWS && <News {...panel} position={position} />}
      {panel.type === PanelTypes.PERSONALIZED_NEWS && <PersonalizedNews {...panel} position={position} />}
      {panel.type === PanelTypes.TARGETED_NEWS && <TargetedNews {...panel} position={position} />}
      {panel.type === PanelTypes.PAGES && <Pages {...panel} position={position} />}
      {panel.type === PanelTypes.COUNTDOWN && <Countdown {...panel} setShouldRemoveRowGap={setShouldRemoveRowGap} />}
      {panel.type === PanelTypes.SPACES && <Spaces {...panel} position={position} />}
      {panel.type === PanelTypes.ASSIGNED_TASKS && <Tasks {...panel} position={position} />}
      {panel.type === PanelTypes.POLL && <Poll {...panel} position={position} />}
      {panel.type === PanelTypes.USEFUL_LINKS && <Links {...panel} />}
      {panel.type === PanelTypes.VIDEOS && <Videos {...panel} position={position} />}
      {panel.type === PanelTypes.NEW_USERS_MAIN && <NewUsers {...panel} position={position} />}
      {panel.type === PanelTypes.CARDS && (
        <Cards {...panel} cardsType={panel.key} position={position} isInPeopleNetwork={false} />
      )}
      {panel.type === PanelTypes.ACTIVE_USERS && <ActiveUsers {...panel} cardsType={panel.key} position={position} />}
      {panel.type === PanelTypes.GLIFFY && <Gliffy {...panel} position={position} />}
      {panel.type === PanelTypes.RELEASED_AI && <ReleasedAI {...panel} position={position} />}
    </div>
  );
}

export default PanelProvider;
