import React from "react";
import { dummyData } from "./dummy-data";
import {
  CardsGridWrapper,
  CardHeader,
  SmallCardWrapper,
  SmallCardBodyWrapper,
  SmallCardSkeletonWrapper,
  Skeleton,
  Birthday,
} from "./styles";

const WorkAnniversaryPreview = () => (
  <CardsGridWrapper numberOfColumns={4}>
    {dummyData.map((data, idx) => (
      <SmallCardWrapper key={idx}>
        <CardHeader backgroundUrl={data.backgroundUrl} />
        <SmallCardBodyWrapper>
          <img src={data.avatarUrl} alt="Profile" />
          <SmallCardSkeletonWrapper>
            <Skeleton width="68px" height="6px" backgroundColor="#DFE1E6" marginTop="0" />
            <Skeleton width="57px" height="6px" backgroundColor="#EBECF0" marginTop="0" />
          </SmallCardSkeletonWrapper>
        </SmallCardBodyWrapper>
        <Birthday>{Math.abs(idx - 4)} Years</Birthday>
      </SmallCardWrapper>
    ))}
  </CardsGridWrapper>
);

export default WorkAnniversaryPreview;
