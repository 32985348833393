import React from "react";
import DragHandlerIcon from "@atlaskit/icon/glyph/drag-handler";
import EditorRemoveIcon from "@atlaskit/icon/glyph/editor/remove";
import { useDrag, useDrop } from "react-dnd";
import { EntryName, Placeholder, SpaceEntryBox, childItemStyle, iconStyle } from "./styled";
import { Box, Grid } from "@atlaskit/primitives";
import { token } from "@atlaskit/tokens";
import { useHelpersStore, useNavigationStore } from "../../../../store";
import { DEFAULT_SPACE_ICON } from "../../../../functions/generateNewEntry";

function SpaceItem({ space, parentId, itemLevel, index, reorderEntry }) {
  const entryType = `child-${parentId}`;
  const width = `calc(100% - ${itemLevel * 24}px)`;
  const marginLeft = `${itemLevel * 24}px`;

  const { deleteSpaceEntry } = useNavigationStore((state) => ({
    deleteSpaceEntry: state.deleteSpaceEntry,
  }));

  const { spaceOptions } = useHelpersStore((state) => ({
    spaceOptions: state.spaceOptions,
  }));

  const potentialIcon = spaceOptions.find(({ id }) => id === space.value)?.icon?.path;
  const icon = potentialIcon ? `${window.AP._hostOrigin}${potentialIcon}` : DEFAULT_SPACE_ICON;

  const [{ isOver, canDrop }, drop] = useDrop(() => ({
    accept: [entryType],
    drop: () => ({ type: entryType, entryId: space.value, parentId }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));

  const [{ draggedItem }, drag] = useDrag({
    type: entryType,
    item: () => {
      return { type: entryType, entryId: space.value, parentId, index };
    },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (item && dropResult) {
        reorderEntry(item, dropResult, { isReorderingSpaces: true, parentId });
      }
    },
    collect: (monitor) => ({
      draggedItem: monitor.getItem(),
    }),
  });

  const draggedItemPosition = draggedItem?.index ?? 0;
  const potentialTargetPosition = isOver ? index : 0;

  const orderDirection = draggedItemPosition > potentialTargetPosition ? "top-bottom" : "bottom-top";

  return (
    <>
      {orderDirection === "top-bottom" && <Placeholder style={{ width, marginLeft }} isOver={isOver && canDrop} />}

      <SpaceEntryBox style={{ width, marginLeft }} hasChildren={false} ref={drag} key={space.value}>
        <Box padding="space.100" xcss={childItemStyle} ref={drop}>
          <Grid id="content-grid" templateColumns="24px 16px auto 24px" alignItems="center">
            <Box xcss={iconStyle}>
              <DragHandlerIcon primaryColor={token("color.icon")} />
            </Box>

            <img
              src={icon}
              alt={space.label}
              width={16}
              height={16}
              style={{
                borderRadius: "2px",
              }}
            />

            <EntryName>{space.label}</EntryName>

            <Box id="entry-actions" style={{ marginLeft: token("space.050") }}>
              <Box xcss={iconStyle} onClick={() => deleteSpaceEntry(parentId, space.value)}>
                <EditorRemoveIcon primaryColor={token("color.icon.accent.gray")} />
              </Box>
            </Box>
          </Grid>
        </Box>
      </SpaceEntryBox>

      {orderDirection === "bottom-top" && <Placeholder style={{ width, marginLeft }} isOver={isOver && canDrop} />}
    </>
  );
}

export default SpaceItem;
