import React, { useEffect, useState } from "react";
import EditorCloseIcon from "@atlaskit/icon/glyph/editor/close";
import SettingsIcon from "@atlaskit/icon/glyph/settings";
import Toggle from "@atlaskit/toggle";
import Textfield from "@atlaskit/textfield";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { MainContentIcon, SideContentIcon } from "./Icons";
import ReorderableField from "./ReorderableField";
import ReorderablePlaceholder from "./ReorderablePlaceholder";
import FieldSelect from "./FieldSelect";
import SocialLink from "./SocialLink";
import { SectionTypes, Alignment, SocialTypes } from "./types";
import {
  Wrapper,
  ContentSelector,
  Section,
  Header,
  Title,
  IconContainer,
  Divider,
  ItemToggleWrapper,
  BorderDivider,
  Description,
  NoTextDivider,
} from "./styled";
import { useChangesStore } from "../../../../track-changes-store";
import { ArrowLeftIcon, ArrowRightIcon } from "../../../../icons/SidebarIcons";
import { useTheme } from "styled-components";

function ConfigureFields({ fields, setFields, updateField, close }) {
  const setHasChanges = useChangesStore((state) => state.setHasChanges);

  const [openSection, setOpenSection] = useState();
  const [selectedField, setSelectedField] = useState();
  const sortedFields = fields.sort((a, b) => a?.profile?.order - b?.profile?.order);

  const [labelInput, setLabelInput] = useState("");

  const { socialLinks, isEmailEnabled, areSocialLinksEnabled } = sortedFields?.find((field) => field.id === "user")
    ?.profile || {
    socialLinks: [],
    areSocialLinksEnabled: false,
    isEmailEnabled: false,
  };
  const sidebarFields = sortedFields.filter((field) => field?.profile?.section === SectionTypes.SIDEBAR);
  const mainPanelFields = sortedFields.filter((field) => field?.profile?.section === SectionTypes.MAIN);
  const rightSideFields = mainPanelFields.filter((field) => field?.profile?.alignment === Alignment.RIGHT);
  const leftSideFields = mainPanelFields.filter((field) => field?.profile?.alignment === Alignment.LEFT);

  const sortedMainPanelFields = [...leftSideFields, ...rightSideFields];

  const theme = useTheme();

  const disableSocialLinks = () => {
    const newFields = fields.map((field) => {
      if (field.id === "user") {
        const profile = {
          ...field.profile,
          socialLinks: socialLinks.length ? [] : ["linkedin", "twitter", "facebook"],
        };
        updateField(field.id, { profile });
        setHasChanges(true);
        return { ...field, profile };
      }
      return field;
    });

    setFields([...newFields]);
  };

  const updateUserFieldProfile = (newValue) => {
    const newFields = fields.map((field) => {
      if (field.id === "user") {
        const profile = {
          ...field.profile,
          ...newValue,
        };
        updateField(field.id, { profile });
        setHasChanges(true);
        return { ...field, profile };
      }
      return field;
    });

    setFields([...newFields]);
  };

  const updateFieldLabel = (value) => {
    const newFields = fields.map((field) => {
      if (field.id === selectedField.id) {
        const profile = { ...field.profile, label: value };
        updateField(field.id, { profile });
        setHasChanges(true);
        return { ...field, profile };
      }
      return field;
    });

    setFields([...newFields]);
  };

  useEffect(() => {
    if (!!selectedField?.profile) {
      const timer = setTimeout(() => {
        updateFieldLabel(labelInput || "");
      }, 500);

      return () => clearTimeout(timer);
    }
  }, [labelInput]);

  useEffect(() => {
    setLabelInput(selectedField?.profile?.label || "");
  }, [selectedField]);

  return (
    <>
      <Header>
        <Title>
          {openSection ? (
            <IconContainer
              onClick={() =>
                setOpenSection(
                  [SectionTypes.SOCIAL, SectionTypes.DATE].includes(openSection) ? SectionTypes.SIDEBAR : null,
                )
              }
            >
              <ArrowLeftIcon />
            </IconContainer>
          ) : (
            <>Customize Profile Layout</>
          )}

          {openSection === SectionTypes.DATE && "Edit Date"}
          {openSection === SectionTypes.MAIN && "Edit Profile"}
          {openSection === SectionTypes.SIDEBAR && "Edit Contact"}
          {openSection === SectionTypes.SOCIAL && "Edit Social links"}
        </Title>

        <span role="none" style={{ cursor: "pointer", minWidth: "26px" }} onClick={close}>
          <EditorCloseIcon primaryColor={theme.shared.sidebar.iconCloseColor} />
        </span>
      </Header>

      <BorderDivider />

      <Wrapper>
        <ContentSelector isVisible={openSection} onClick={() => setOpenSection(SectionTypes.SIDEBAR)}>
          <SideContentIcon />
          <div>
            <Section>Contact Section</Section>
            <Description>Optimize the contact section by rearranging, adding, or modifying fields.</Description>
          </div>
          <IconContainer>
            <ArrowRightIcon />
          </IconContainer>
        </ContentSelector>

        {openSection === SectionTypes.SIDEBAR && (
          <>
            <FieldSelect fields={fields} setFields={setFields} section="sidebar" />

            <ItemToggleWrapper
              style={{ borderBottom: "1px solid rgba(9, 30, 66, 0.14)", paddingBottom: "14px", marginLeft: "0" }}
            >
              <span style={{ paddingLeft: "32px" }}>Email</span>
              <div style={{ marginRight: "-6px" }}>
                <Toggle
                  isChecked={!!isEmailEnabled}
                  onChange={() =>
                    updateUserFieldProfile({
                      isEmailEnabled: !isEmailEnabled,
                    })
                  }
                />
              </div>
            </ItemToggleWrapper>

            <NoTextDivider />

            <DndProvider backend={HTML5Backend}>
              {sidebarFields.length ? (
                sidebarFields.map((field) => (
                  <ReorderableField
                    key={field.id}
                    sourceField={field}
                    fields={fields}
                    setFields={setFields}
                    onConfigClick={() => {
                      setSelectedField(field);
                      setOpenSection(SectionTypes.DATE);
                    }}
                  />
                ))
              ) : (
                <p
                  style={{
                    textAlign: "center",
                    fontSize: "12px",
                    color: "#7a869a",
                    marginBlock: "20px",
                  }}
                >
                  Nothing to show, select fields to display in contact section.
                </p>
              )}
            </DndProvider>

            <NoTextDivider />

            <ItemToggleWrapper
              style={{ borderTop: "1px solid rgba(9, 30, 66, 0.14)", paddingBottom: "14px", marginLeft: "0" }}
            >
              <span style={{ paddingLeft: "32px" }}>Social links</span>
              <div style={{ display: "flex", marginRight: "-6px" }}>
                <IconContainer onClick={() => setOpenSection(SectionTypes.SOCIAL)}>
                  <SettingsIcon />
                </IconContainer>

                <Toggle
                  isChecked={areSocialLinksEnabled}
                  onChange={() => {
                    updateUserFieldProfile({
                      socialLinks: socialLinks.length ? [] : ["linkedin", "twitter", "facebook"],
                    });
                    updateUserFieldProfile({ areSocialLinksEnabled: !areSocialLinksEnabled });
                  }}
                />
              </div>
            </ItemToggleWrapper>
          </>
        )}

        <ContentSelector isVisible={openSection} onClick={() => setOpenSection(SectionTypes.MAIN)}>
          <MainContentIcon />
          <div>
            <Section>Profile Details Section</Section>
            <Description>Enhance the profile details section by rearranging, adding, or modifying fields.</Description>
          </div>
          <IconContainer>
            <ArrowRightIcon />
          </IconContainer>
        </ContentSelector>

        {openSection === SectionTypes.MAIN && (
          <>
            <FieldSelect fields={fields} setFields={setFields} section="main" alignment="left" />
            <Divider label="LEFT SIDE" style={{ margin: "20px 0 20px" }} />

            <DndProvider backend={HTML5Backend}>
              {leftSideFields.length ? (
                leftSideFields.map((field) => (
                  <ReorderableField
                    sortedMainPanelFields={sortedMainPanelFields}
                    key={field.id}
                    sourceField={field}
                    fields={fields}
                    setFields={setFields}
                  />
                ))
              ) : (
                <ReorderablePlaceholder fields={fields} setFields={setFields} alignment="left" />
              )}

              <Divider label="RIGHT SIDE" style={{ margin: "20px 0 20px" }} />

              {rightSideFields.length ? (
                rightSideFields.map((field) => (
                  <ReorderableField
                    sortedMainPanelFields={sortedMainPanelFields}
                    key={field.id}
                    sourceField={field}
                    fields={fields}
                    setFields={setFields}
                  />
                ))
              ) : (
                <ReorderablePlaceholder fields={fields} setFields={setFields} alignment="right" />
              )}
            </DndProvider>
          </>
        )}

        {openSection === SectionTypes.DATE && (
          <>
            <small
              style={{
                color: theme.shared.profile.editText,
                fontWeight: 500,
                margin: "25px 0 10px",
              }}
            >
              Edit text
            </small>
            <Textfield
              value={labelInput}
              placeholder="Your label e.g. Member Since"
              onChange={(e) => setLabelInput(e.target.value)}
            />
          </>
        )}

        {openSection === SectionTypes.SOCIAL &&
          Object.keys(SocialTypes).map((key) => (
            <SocialLink
              key={key}
              id={key}
              title={SocialTypes[key]}
              socialLinks={socialLinks}
              fields={fields}
              setFields={setFields}
            />
          ))}
      </Wrapper>
    </>
  );
}

export default ConfigureFields;
