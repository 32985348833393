import React, { useState } from "react";
import dayjs from "dayjs";
import { FieldValue, EditorContainer } from "../styled";
import DateEditor from "../editor/Date";

function DateType({ value, field, onEdit, isEditable, isSidebar = false, getPrefixBasedOnRole }) {
  const [isEditing, setIsEditing] = useState(false);

  const format = field?.recurring ? "MMM D" : "MMM D, YYYY";
  const minDate = new Date("1900-01-01");
  const maxDate = new Date();

  return (
    <>
      {isEditing && (
        <EditorContainer>
          <DateEditor
            initialValue={value}
            onAbort={() => setIsEditing(false)}
            onEdit={(val) => {
              onEdit(field.id, val);
              setIsEditing(false);
            }}
            useDayjs={false}
            minDate={minDate}
            maxDate={maxDate}
          />
        </EditorContainer>
      )}

      <FieldValue
        isEditing={isEditing}
        isEditable={isEditable}
        onClick={() => {
          if (isEditable) {
            setIsEditing(true);
          }
        }}
      >
        {isSidebar && field.profile.label}{" "}
        {value ? dayjs.unix(value).format(format) : `${getPrefixBasedOnRole()} ${field.title}`}
      </FieldValue>
    </>
  );
}

export default DateType;
