import React from "react";
import styled from "styled-components";
import { Content, Panel, SmallLine, Title, Wrapper } from "./styled";

const TaskWrapper = styled.div`
  display: grid;
  grid-template-columns: 10px auto;
  column-gap: 10px;
`;

const Task = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 5px;
  row-gap: 6px;
  align-items: center;
`;

const Tag = styled.div`
  background: #deebff;
  border-radius: 8px;
  padding: 2px 5px 4px 5px;
  display: grid;
`;

const TagText = styled.span`
  font-weight: 500;
  font-size: 10px;
  line-height: 10px;
  color: rgba(9, 30, 66, 0.54);
`;

const Box = () => (
  <svg width="10" height="10" viewBox="0 0 10 10" fill="none">
    <rect
      x="0.71118"
      y="0.71118"
      width="8.57764"
      height="8.57764"
      rx="0.71118"
      fill="white"
      stroke="#C1C7D0"
      strokeWidth="1.42236"
    />
  </svg>
);

function AssignedToMe() {
  return (
    <Wrapper>
      <Panel>
        <Title>Assigned to me</Title>
        <Content>
          <TaskWrapper>
            <Box />
            <Task>
              <SmallLine bg="rgba(9, 30, 66, 0.13)" width={20} />
              <Tag>
                <TagText>@ Sarah Connor</TagText>
              </Tag>
              <SmallLine bg="rgba(9, 30, 66, 0.13)" width={25} />
              <SmallLine bg="rgba(9, 30, 66, 0.13)" width={106} />
            </Task>
          </TaskWrapper>

          <TaskWrapper style={{ marginTop: "23px" }}>
            <Box />
            <Task>
              <SmallLine bg="rgba(9, 30, 66, 0.13)" width={139} />
              <Tag style={{ borderRadius: "1px" }}>
                <TagText>10 Feb 2023</TagText>
              </Tag>
            </Task>
          </TaskWrapper>

          <TaskWrapper style={{ marginTop: "23px" }}>
            <Box />
            <Task>
              <SmallLine bg="rgba(9, 30, 66, 0.13)" width={50} />
              <Tag>
                <TagText>@ Sarah Connor</TagText>
              </Tag>
              <SmallLine bg="rgba(9, 30, 66, 0.13)" width={139} />
              <SmallLine bg="rgba(9, 30, 66, 0.13)" width={139} />
              <SmallLine bg="rgba(9, 30, 66, 0.13)" width={29} />
              <Tag style={{ borderRadius: "1px" }}>
                <TagText>10 Feb 2023</TagText>
              </Tag>
            </Task>
          </TaskWrapper>
        </Content>
      </Panel>
    </Wrapper>
  );
}

export default AssignedToMe;
