import create from "zustand";
import { generateDefaultEntriesForParentEntry, createParentEntry } from "./functions/generateNewEntry";
import { PARENT_ENTRY_IDENTIFIER } from "./defaults";

export const useNavigationStore = create((set, get) => ({
  active: false,
  setActive: (active) => set((state) => ({ active })),

  savedActive: false,
  setSavedActive: (active) => set((state) => ({ savedActive: active })),

  version: 2,
  setVersion: (version) => set((state) => ({ version })),

  savedStructure: [],
  setSavedStructure: (newStructure) => set((state) => ({ savedStructure: newStructure })),

  structure: [],
  setStructure: (newStructure) => set((state) => ({ structure: newStructure })),

  editingEntry: undefined,
  setEditingEntry: (entry) => set((state) => ({ editingEntry: entry })),

  deleteNavigation: () => set((state) => ({ structure: [] })),

  findParentEntry: (id) => get().structure.find((entry) => entry.id === id),

  deleteEntry: (entryId) => {
    const currentStructure = [...get().structure];
    const entry = currentStructure.find((entry) => entry.id === entryId);

    const isParent = entry.parent === PARENT_ENTRY_IDENTIFIER;

    if (isParent) {
      const newStructure = currentStructure.filter((entry) => entry.id !== entryId && entry.parent !== entryId);
      return set((state) => ({ structure: newStructure }));
    }

    return set((state) => ({ structure: currentStructure.filter((entry) => entry.id !== entryId) }));
  },

  deleteSpaceEntry: (parentId, spaceId) => {
    const currentStructure = [...get().structure];
    const entry = currentStructure.find((entry) => entry.id === parentId);

    entry.data.spaces = entry.data.spaces.filter((space) => space.value !== spaceId);
    set((state) => ({ structure: currentStructure }));
  },

  updateEntry: (id, prop, value, isUpdatingData) => {
    const currentStructure = [...get().structure];
    const entry = currentStructure.find((entry) => entry.id === id);

    let newEntry;
    if (entry) {
      if (isUpdatingData) {
        newEntry = { ...entry, data: { ...entry.data, [prop]: value } };
      } else {
        newEntry = { ...entry, [prop]: value };
      }
      set((state) => ({
        structure: currentStructure.map((storedEntry) => (storedEntry.id === newEntry.id ? newEntry : storedEntry)),
        editingEntry: newEntry,
      }));
    }
  },

  updateEntryLinks: (entryId, link) => {
    const currentStructure = [...get().structure];
    const entry = currentStructure.find(({ id }) => id === entryId);

    if (entry) {
      const existingLinks = entry.data.link;
      const sameLink = existingLinks.find((savedLink) => savedLink.id === link.id);
      if (sameLink) {
        const newLinks = existingLinks.map((sLink) => (sLink.id === link.id ? link : sLink));

        entry.data.link = newLinks;
        return set((state) => ({ structure: currentStructure, editingEntry: entry }));
      }

      entry.data.link = [...existingLinks, link];
      return set((state) => ({ structure: currentStructure, editingEntry: entry }));
    }
  },

  creatingEntry: undefined,
  setCreatingEntry: (entry) => set((state) => ({ creatingEntry: entry })),

  createNewEntry: (entryType, spaces) => {
    const id = crypto.randomUUID();
    const currentStructure = [...get().structure];
    const newEntry = createParentEntry(id, entryType, spaces);
    const children = generateDefaultEntriesForParentEntry(entryType, id);

    set((state) => ({ structure: [...currentStructure, newEntry, ...children] }));
  },

  createNewSubentry: (entry, parentId) => {
    const currentStructure = [...get().structure];

    const children = currentStructure.filter((item) => item.parent === parentId);
    const lastItem = children[children.length - 1];

    const indexOfLastItem = currentStructure.map((item) => item.id).indexOf(lastItem.id);

    currentStructure.splice(indexOfLastItem + 1, 0, entry);

    set((state) => ({ structure: [...currentStructure] }));
  },

  reorderEntry: (source, target, houstonDetails = {}) => {
    if (source.type !== target.type) return;

    const currentStructure = [...get().structure];
    const { isReorderingSpaces, parentId } = houstonDetails;

    if (isReorderingSpaces) {
      const parentEntry = currentStructure.find((entry) => entry.id === parentId);
      if (!parentEntry) return;

      const sourceEntry = parentEntry.data.spaces.find((space) => space.value === source.entryId);
      const targetEntry = parentEntry.data.spaces.find((space) => space.value === target.entryId);

      const sourceIndex = parentEntry.data.spaces.indexOf(sourceEntry);
      const targetIndex = parentEntry.data.spaces.indexOf(targetEntry);

      if (sourceIndex === targetIndex) return;

      parentEntry.data.spaces.splice(sourceIndex, 1);
      parentEntry.data.spaces.splice(targetIndex, 0, sourceEntry);

      return set((state) => ({ structure: currentStructure }));
    }

    const sourceEntry = currentStructure.find((entry) => entry.id === source.entryId);
    const targetEntry = currentStructure.find((entry) => entry.id === target.entryId);

    const sourceIndex = currentStructure.indexOf(sourceEntry);
    const targetIndex = currentStructure.indexOf(targetEntry);

    if (sourceIndex === targetIndex) return;

    currentStructure.splice(sourceIndex, 1);
    currentStructure.splice(targetIndex, 0, sourceEntry);

    return set((state) => ({ structure: currentStructure }));
  },
}));

const initialColorsState = {
  savedColors: {},
  dark: { menuBackgroundColor: "#282E33", menuTextColor: "#9FADBC", menuTextHover: "#C7D1DB" },
  light: { menuBackgroundColor: "#ffffff", menuTextColor: "#42526e", menuTextHover: "#42526e" },
  subentryIconColor: "#42526e",
  subentryTextColor: "#42526e",
  subentryBackgroundHoverColor: "#42526e1a",
  descriptionTextColor: "#42526e",
};

export const useNavigationColorsStore = create((set, get) => ({
  ...initialColorsState,
  setColor: (variant, key, value) => set((state) => ({ ...state, [variant]: { ...state[variant], [key]: value } })),
  setSavedColors: (color) => set((state) => ({ savedColors: color })),
  setMenuBackgroundColor: (color) => set((state) => ({ menuBackgroundColor: color })),
  setMenuTextColor: (color) => set((state) => ({ menuTextColor: color })),
  setMenuTextHover: (color) => set((state) => ({ menuTextHover: color })),
  setSubentryIconColor: (color) => set((state) => ({ subentryIconColor: color })),
  setSubentryTextColor: (color) => set((state) => ({ subentryTextColor: color })),
  setSubentryBackgroundHoverColor: (color) => set((state) => ({ subentryBackgroundHoverColor: color })),
  setDescriptionTextColor: (color) => set((state) => ({ descriptionTextColor: color })),
  resetColors: () => set(initialColorsState),
}));

export const useHelpersStore = create((set, get) => ({
  spaces: [],
  setSpaces: (spaces) => set((state) => ({ spaces })),

  spaceOptions: [],
  setSpaceOptions: (spaceOptions) => set((state) => ({ spaceOptions })),

  modalVisible: false,
  setModalVisible: (visible) => set((state) => ({ modalVisible: visible })),

  modalType: undefined,
  setModalType: (type) => set((state) => ({ modalType: type })),

  closeModal: () => set((state) => ({ modalVisible: false, modalType: undefined })),

  deletingEntry: undefined,
  setDeletingEntry: (entry) => set((state) => ({ deletingEntry: entry })),
}));

export const useSidebarStore = create((set, get) => ({
  isOpen: false,
  setIsOpen: (isOpen) => set((state) => ({ isOpen })),

  selectedSidebarType: undefined,
  setSelectedSidebarType: (selectedSidebarType) => set((state) => ({ selectedSidebarType })),

  closeSidebar: () => set((state) => ({ isOpen: false, selectedSidebarType: undefined })),

  isHelperDrawerOpen: false,
  setIsHelperDrawerOpen: (isHelperDrawerOpen) => set((state) => ({ isHelperDrawerOpen })),

  selectedHelperDrawerType: undefined,
  setSelectedHelperDrawerType: (selectedHelperDrawerType) => set((state) => ({ selectedHelperDrawerType })),

  selectedLink: undefined,
  setSelectedLink: (selectedLink) => set((state) => ({ selectedLink })),

  closeHelperDrawer: () =>
    set((state) => ({ isHelperDrawerOpen: false, selectedHelperDrawerType: undefined, selectedLink: undefined })),
}));

export const useNavigationThemeColorSetStore = create((set, get) => ({
  themeColorsSet: false,
  returnThemeColorsSet: () => get().themeColorsSet,
  setThemeColorsSet: (newThemeColorsSet) => set({ themeColorsSet: newThemeColorsSet }),
}));

export const useNavigationChangesStore = create((set, get) => ({
  hasChanges: false,
  setHasChanges: (changes) => set((state) => ({ hasChanges: changes })),
}));
