export const getAdminUsers = (permissions) =>
  permissions
    .filter((p) => p?.subjects?.user)
    .map((p) => {
      const users = p.subjects.user?.results
        ?.filter((u) => u.accountType === "atlassian")
        .map((u) => ({
          id: u.accountId,
          key: u.accountId,
          name: u.displayName,
          icon: u.profilePicture?.path ? `${window.AP._hostOrigin}${u.profilePicture.path}` : "",
        }));

      return users;
    })
    .flat();

export const getAdminGroups = (permissions) =>
  permissions
    .filter((p) => p?.subjects?.group)
    .map((p) => {
      const groups = p.subjects.group?.results?.map((g) => ({
        groupId: g.id,
        name: g.name,
      }));

      return groups;
    })
    .flat()
    .filter((value, index, self) => index === self.findIndex((t) => t.id === value.id));
