import { CoverStory } from "@caelor/cards-and-panels-components";
import React from "react";
import InfiniteScroller from "../../../../../Shared/Components/InfiniteScroller";
import { useNewsroomStore } from "../newsroom-store";
import { NewsElements, NewsViews } from "../defaults";
import { SettingPropertyKeys } from "../Sidebar/NewsDesign";
import { GridContainer } from "../styled/newsroom";

function GridView({ data, fetchNextPage, hasNextPage, borderRadius }) {
  const settings = useNewsroomStore((state) => state.settings);

  const getGridSettingsData = (prop) => settings[NewsViews.GRID][prop];

  return (
    <InfiniteScroller
      dataLength={data?.pages?.length || 0}
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage}
      endMessage=""
    >
      {data?.pages?.map((page, index) => (
        <GridContainer key={index} gridGap={getGridSettingsData(SettingPropertyKeys.GRID_GAP)}>
          {page.results.map((blog) => (
            <CoverStory
              key={blog.id}
              coverPictureCallback={blog.coverPictureCallback}
              getBlogsCategories={
                getGridSettingsData(SettingPropertyKeys.ACTIVE_ELEMENTS).includes(NewsElements.CATEGORIES)
                  ? blog.getBlogsCategories
                  : undefined
              }
              name={blog.name}
              space={
                getGridSettingsData(SettingPropertyKeys.ACTIVE_ELEMENTS).includes(NewsElements.SPACE) ? blog.space : []
              }
              url={blog.url}
              excerpt={
                getGridSettingsData(SettingPropertyKeys.ACTIVE_ELEMENTS).includes(NewsElements.DESCRIPTION)
                  ? blog.excerpt
                  : null
              }
              labels={
                getGridSettingsData(SettingPropertyKeys.ACTIVE_ELEMENTS).includes(NewsElements.LABEL) ? blog.labels : []
              }
              createdBy={
                getGridSettingsData(SettingPropertyKeys.ACTIVE_ELEMENTS).includes(NewsElements.AUTHOR)
                  ? blog.createdBy
                  : null
              }
              lastModified={
                getGridSettingsData(SettingPropertyKeys.ACTIVE_ELEMENTS).includes(NewsElements.DATE)
                  ? blog.createdFormatted
                  : null
              }
              avatarUrl={blog.avatarUrl}
              labelsBackground={getGridSettingsData(SettingPropertyKeys.LABELS_BACKGROUND)}
              coverPictureSize={320}
              coverPictureBorderRadius={borderRadius.replace("px", "")}
              labelsBorderRadius={borderRadius}
            />
          ))}
        </GridContainer>
      ))}
    </InfiniteScroller>
  );
}

export default GridView;
