import React from "react";
import { Box, Flex, Text } from "@atlaskit/primitives";
import styled from "styled-components";
import ChevronRightLargeIcon from "@atlaskit/icon/glyph/chevron-right-large";
import { token } from "@atlaskit/tokens";
import { MenuStyleTypes, MenuTypeTitles } from "../../../menu/types";
import { useHelpersStore, useNavigationStore, useSidebarStore } from "../../../store";
import { createParentEntry, generateDefaultEntriesForParentEntry } from "../../../functions/generateNewEntry";

const ItemBox = styled.div`
  border-radius: 3px;
  padding: ${token("space.100")};
  transition: all 50ms ease-in-out;
  cursor: pointer;

  #icon {
    opacity: 0;
    transition: all 50ms ease-in-out;
  }

  &:hover {
    background: ${token("elevation.surface.hovered")};

    #icon {
      opacity: 1;
    }
  }

  &:active {
    background: ${token("elevation.surface.pressed")};
  }
`;

function AddEntry() {
  const { createNewEntry, setCreatingEntry } = useNavigationStore((state) => ({
    createNewEntry: state.createNewEntry,
    setCreatingEntry: state.setCreatingEntry,
  }));
  const spaces = useHelpersStore((state) => state.spaces);
  const closeSidebar = useSidebarStore((state) => state.closeSidebar);

  return (
    <Flex direction="column" gap="space.150">
      {Object.values(MenuStyleTypes).map((style) => (
        <ItemBox
          key={style}
          onClick={() => {
            createNewEntry(style, spaces);
            setCreatingEntry(undefined);
            closeSidebar();
          }}
          onMouseEnter={() => {
            const id = crypto.randomUUID();
            const newEntry = createParentEntry(id, style, spaces);
            const children = generateDefaultEntriesForParentEntry(style, id);
            setCreatingEntry({ parentEntry: newEntry, children });
          }}
          onMouseLeave={() => setCreatingEntry(undefined)}
        >
          <Flex alignItems="center" justifyContent="space-between">
            <Text color="color.text">{MenuTypeTitles[style]}</Text>
            <Box id="icon">
              <ChevronRightLargeIcon primaryColor={token("color.icon.brand")} />
            </Box>
          </Flex>
        </ItemBox>
      ))}
    </Flex>
  );
}

export default AddEntry;
