import React from "react";
import { dummyData } from "./dummy-data";
import {
  BigCardAvatarWrapper,
  BigCardBodyWrapper,
  BigCardSkeletonWrapper,
  BigCardWrapper,
  CardHeader,
  CardsGridWrapper,
  Skeleton,
} from "./styles";

const KnowYourTeamPreview = () => {
  return (
    <CardsGridWrapper numberOfColumns={2}>
      {dummyData.slice(0, 2).map((data, idx) => (
        <BigCardWrapper key={idx}>
          <CardHeader backgroundUrl={data.backgroundUrl} />
          <BigCardBodyWrapper>
            <BigCardAvatarWrapper>
              <img src={data.avatarUrl} alt="Profile" />
            </BigCardAvatarWrapper>
            <BigCardSkeletonWrapper>
              <Skeleton width="68px" height="6px" backgroundColor="#C1C7D0" marginTop="0" />
              <Skeleton width="48px" height="6px" backgroundColor="#EBECF0" marginTop="4px" />
              <Skeleton width="56px" height="6px" backgroundColor="#EBECF0" marginTop="12px" />
            </BigCardSkeletonWrapper>
          </BigCardBodyWrapper>
        </BigCardWrapper>
      ))}
    </CardsGridWrapper>
  );
};

export default KnowYourTeamPreview;
