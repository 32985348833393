import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { fetchRecords } from "../../../../../UserDatabase/datagrid/api";
import dayjs from "dayjs";
import { fetchDetailsForUsersIndividually } from "../../../Dashboard/api";
import {
  IMAGE_TYPES,
  getCssForBackground,
  getRandomUserBackground,
} from "../../../../../../Shared/Functions/user-bg-provider";
import { Section, SmallTitle, SpinnerContainer, Subtitle } from "../../styled/views";

import styled from "styled-components";
import { useSidebarStore } from "../../store/sidebar-store";

import Spinner from "@atlaskit/spinner";
import NoBirthdays from "../../../Dashboard/panels/images/NoBirthdaysSVG";
import { useCorporateIdentityStore } from "../../../Settings/General/BrandAndColors/corporate-identity-store";
import { COLOR_TYPES } from "../../../Settings/General/BrandAndColors/color-types";
import useSavedCollections from "../../../../../../Shared/Hooks/useSavedCollections";
import { createProfileLink } from "../createProfileLink";
import Pagination from "../../../Shared/PanelsPagination/Pagination";
import { PanelsPaginationTypes } from "../../../Shared/PanelsPagination/panels-pagination-types";
import { useTheme } from "styled-components";
import { getWidgetSettingById } from "../../../Settings/Widgets/api";
import { WidgetIds } from "../../../Settings/Widgets/consts/tabs";
import EmptyState from "../../../../../../Shared/Components/EmptyState";

const LinkWrapper = styled.a`
  all: unset;
`;

const Name = styled.span`
  font-weight: 500;
  font-size: 15px;
  line-height: 19px;
  color: ${({ theme }) => theme.shared.card.title};
  margin-top: 8px;
`;

const DateWrapper = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme }) => theme.shared.card.subtitle};
  height: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const LeftoverWrapper = styled.div`
  ${({ isFullWidth }) => {
    if (isFullWidth) {
      return `
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        margin-top: 13px;
        flex: 1;
        display: flex;
        align-items: flex-end;
        min-height: 15px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      `;
    }
    return `
      font-weight: 600;
      font-size: 16px;
      line-height: 21px;
    `;
  }}
  color: ${({ primaryColor }) => primaryColor};
`;

const GridPeopleContainer = styled.div`
  display: grid;
  grid-template-columns: ${({ numberOfItems }) => `repeat(${numberOfItems}, minmax(0, 1fr))`};
  grid-template-rows: 1fr;
  grid-gap: 24px;
`;

const Card = styled.div`
  background: ${({ theme }) => theme.shared.card.background};
  box-shadow:
    rgba(9, 30, 66, 0.25) 0px 1px 3px,
    rgba(9, 30, 66, 0.31) 0px 0px 1px;
  border-radius: ${({ borderRadius }) => `${borderRadius}`};
  overflow: hidden;
  cursor: pointer;
  transition: box-shadow linear 75ms;
  border: 1px solid ${({ theme }) => theme.shared.card.border.box};

  &:hover {
    box-shadow:
      rgba(9, 30, 66, 0.31) 0px 0px 1px 0px,
      rgba(9, 30, 66, 0.25) 0px 8px 16px -6px;
  }
`;

const CardBackground = styled.div`
  width: 100%;
  background: ${({ background }) => background};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 80px;
`;

const CardInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  align-items: center;
  justify-content: center;
  padding: 0px 16px 16px 16px;
  margin-top: ${({ imageHeight }) => `-${imageHeight / 2}px`};
  text-align: center;
`;

const Image = styled.img`
  width: ${({ imageSize }) => `${imageSize || 80}px`};
  height: ${({ imageSize }) => `${imageSize || 80}px`};
  box-sizing: border-box;
  border: 4px solid ${({ theme }) => theme.shared.card.border.avatar};
  border-radius: 50%;
  object-fit: cover;
  justify-self: center;
`;

const GridCenter = styled.div`
  height: 32px;
  display: grid;
  place-content: center;
  margin-top: 8px;
`;

export const PanelPaginationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 5px;
  min-width: 53px;
`;

export const PanelContent = styled.div`
  padding-top: 20px;
`;

const EMPTY_STATE_TEXT = {
  false: {
    emptyStateTitle: "No Upcoming Birthdays",
    emptyStateDescription:
      "There are currently no upcoming birthdays. Birthdays will appear here as they are added to employee profiles.",
  },
  true: {
    emptyStateTitle: "No Work Anniversaries",
    emptyStateDescription:
      "There are currently no work anniversaries to display. Anniversaries will show up here as they are recorded in the system.",
  },
};

const dummyData = [
  {
    displayName: "Sarah Connor",
    accountId: "123",
    icon: "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80",
    formattedBirthday: "Jan 6, 2023",
    daysLeft: 0,
    yearsLeft: 15,
    background: "linear-gradient(45deg, #FF8B00 0%, #FF5630 100%)",
  },
  {
    displayName: "Mary Lee",
    icon: "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
    accountId: "1234",
    formattedBirthday: "Jan 7, 2023",
    daysLeft: 1,
    yearsLeft: 10,
    background: "linear-gradient(45deg, #2684FF 0%, #00B8D9 100%)",
  },
  {
    displayName: "Jane Clark",
    accountId: "12345",
    icon: "https://images.unsplash.com/photo-1488426862026-3ee34a7d66df?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80",
    formattedBirthday: "Jan 14, 2023",
    daysLeft: 7,
    yearsLeft: 3,
    background: "linear-gradient(45deg, #6554C0 0%, #2684FF 100%)",
  },
  {
    displayName: "John Smith",
    accountId: "123456",
    icon: "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80",
    formattedBirthday: "Feb 5, 2023",
    daysLeft: 30,
    yearsLeft: 1,
    background: "linear-gradient(45deg, #FF5630 0%, #FFC400 100%)",
  },
];

const LeftoverDaysOrYears = ({ isFullWidth, useYears, daysLeft, yearsLeft, primaryColor }) => {
  if (daysLeft === 0 && !useYears) return <></>;

  return (
    <LeftoverWrapper isFullWidth={isFullWidth} primaryColor={primaryColor}>
      {useYears ? (
        <>
          {yearsLeft}
          {` Year${yearsLeft === 1 ? "" : "s"}`}
        </>
      ) : (
        <>
          {daysLeft}
          {` Day${daysLeft === 1 ? "" : "s"}`}
        </>
      )}
    </LeftoverWrapper>
  );
};

const DummyContent = ({ data, isWorkAnniversary, borderRadius, primaryColor }) => {
  const theme = useTheme();
  const getBackground = (bgImage) => {
    if (data.backgroundType === "image") return bgImage;
    if (data.backgroundType === "none") return theme.global.background.surfaceOverlay;
  };

  const maxNumberOfItems = data.maxNumberOfItems || 4;

  return (
    <>
      {dummyData.slice(0, maxNumberOfItems).map((person) => (
        <Card key={person.accountId} borderRadius={borderRadius}>
          <CardBackground background={getBackground(person.background)} />
          <CardInfoContainer imageHeight={data?.profileImageSize || 76}>
            <Image imageSize={data?.profileImageSize || 76} src={person.icon} />
            <Name>{person.displayName}</Name>
            <DateWrapper>
              {person.daysLeft === 0 && <span style={{ color: "#37B37F" }}>TODAY</span>}
              {person.daysLeft === 1 && <span style={{ color: primaryColor }}>TOMORROW</span>}
              {person.daysLeft > 1 && <span>{person.formattedBirthday}</span>}
            </DateWrapper>
            <GridCenter>
              {!isWorkAnniversary && person.daysLeft === 0 && (
                <img src="/images/hub/dashboard/birthday-emoji.png" alt="" />
              )}
              <LeftoverDaysOrYears
                isFullWidth={true}
                useYears={isWorkAnniversary}
                daysLeft={person.daysLeft}
                yearsLeft={person.yearsLeft}
                primaryColor={primaryColor}
              />
            </GridCenter>
          </CardInfoContainer>
        </Card>
      ))}
    </>
  );
};

const GridBirthday = ({
  data,
  isLoading,
  records,
  people,
  isWorkAnniversary,
  borderRadius,
  primaryColor,
  databaseField,
}) => {
  const [page, setPage] = useState(1);

  const theme = useTheme();
  const setDisableClickOutside = useSidebarStore((state) => state.setDisableClickOutside);

  const postsPerPage = data.maxNumberOfItems || 4;
  const indexOfLast = page * postsPerPage;
  const indexOfFirst = indexOfLast - postsPerPage;
  const maxPage = Math.ceil(people?.length / postsPerPage);
  const hasNextPage = people?.length > postsPerPage;

  const getBackground = (bgImage) => {
    if (data.backgroundType === "image") return bgImage;
    if (data.backgroundType === "none") return theme.global.background.surfaceOverlay;
  };

  useEffect(() => {
    setPage(1);
  }, [postsPerPage, data.pagination]);

  const dynamicIndexOfFirst = {
    [PanelsPaginationTypes.SHOW_MORE]: 0,
    [PanelsPaginationTypes.ARROWS]: indexOfFirst,
  };

  const peoplePaginated = people.slice(dynamicIndexOfFirst[data.pagination], indexOfLast);

  return (
    <Section>
      <SmallTitle>{data.title}</SmallTitle>
      <Subtitle>{data.description}</Subtitle>

      {isLoading ? (
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
      ) : (
        <>
          {!databaseField && (
            <GridPeopleContainer numberOfItems={postsPerPage}>
              <DummyContent
                isFullWidth={true}
                data={data}
                isWorkAnniversary={isWorkAnniversary}
                borderRadius={borderRadius}
                primaryColor={primaryColor}
              />
            </GridPeopleContainer>
          )}

          {databaseField && (
            <>
              {!records?.length && (
                <EmptyState
                  title={EMPTY_STATE_TEXT[isWorkAnniversary].emptyStateTitle}
                  description={EMPTY_STATE_TEXT[isWorkAnniversary].emptyStateDescription}
                  Image={NoBirthdays}
                />
              )}

              {!!records?.length && (
                <>
                  <GridPeopleContainer numberOfItems={postsPerPage}>
                    {peoplePaginated.map((person) => (
                      <LinkWrapper key={person.accountId} target="_top" href={createProfileLink(person.accountId)}>
                        <Card borderRadius={borderRadius}>
                          <CardBackground background={getBackground(person.background)} />
                          <CardInfoContainer imageHeight={data?.profileImageSize || 80}>
                            <Image imageSize={data?.profileImageSize || 80} src={person.icon} />
                            <Name>{person.name}</Name>
                            <DateWrapper>
                              {person.daysLeft === 0 && <span style={{ color: "#37B37F" }}>TODAY</span>}
                              {person.daysLeft === 1 && <span style={{ color: primaryColor }}>TOMORROW</span>}
                              {person.daysLeft > 1 && <span>{person.formattedDateField}</span>}
                            </DateWrapper>
                            <GridCenter>
                              {!isWorkAnniversary && person.daysLeft === 0 && (
                                <img src="/images/hub/dashboard/birthday-emoji.png" alt="" />
                              )}
                              <LeftoverDaysOrYears
                                isFullWidth={true}
                                useYears={isWorkAnniversary}
                                daysLeft={person.daysLeft}
                                yearsLeft={person.yearsLeft}
                                primaryColor={primaryColor}
                              />
                            </GridCenter>
                          </CardInfoContainer>
                        </Card>
                      </LinkWrapper>
                    ))}
                  </GridPeopleContainer>

                  <Pagination
                    type={data.pagination}
                    page={page}
                    hasNextPage={hasNextPage}
                    updatePage={setPage}
                    maxPage={maxPage}
                    onWrapperHoverCallback={setDisableClickOutside}
                  />
                </>
              )}
            </>
          )}
        </>
      )}
    </Section>
  );
};

function UpcomingBirthdays({ panelData, id }) {
  const [people, setPeople] = useState([]);
  const [loading, setLoading] = useState(false);

  const [birthdayRange, setBirthdayRange] = useState({ start: undefined, end: undefined });
  const [workAnniversaryRange, setWorkAnniversaryRange] = useState({ start: undefined, end: undefined });
  const [startOrEndOfCurrentYear, setStartOrEndOfCurrentYear] = useState(undefined);

  const [databaseField, setDatabaseField] = useState("");

  const borderRadius = useCorporateIdentityStore((state) => state.borderRadius);

  const theme = useTheme();

  const isWorkAnniversary = !!panelData?.useYears;

  const colors = useCorporateIdentityStore((state) => state.colors);
  const primaryColor = colors ? colors[COLOR_TYPES.PRIMARY] : theme.text.blue;

  const { collectionsData } = useSavedCollections({
    collectionId: panelData?.collection,
    areOptions: false,
  });

  const { isLoading: loadingGlobalSetting, data: widgetSettingData } = useQuery(
    [
      isWorkAnniversary ? "work-anniversary-widget-setting" : "birthday-widget-setting",
      { widgetId: isWorkAnniversary ? WidgetIds.WORK_ANNIVERSARY : WidgetIds.BIRTHDAY },
    ],
    getWidgetSettingById,
    {
      retry: 0,
      select: (response) => {
        const { data } = response;
        return data;
      },
    },
  );

  useEffect(() => {
    if (loadingGlobalSetting) return;

    if (widgetSettingData?.widget?.selectedField) {
      setDatabaseField(widgetSettingData.widget.selectedField);
    }
  }, [loadingGlobalSetting, widgetSettingData]);

  const { isLoading: recordsLoading, data: recordsData } = useQuery(
    [
      "userdatabase-records",
      {
        filters: [
          ...(collectionsData?.filters || []),
          {
            column: databaseField,
            value: {
              range: isWorkAnniversary
                ? [workAnniversaryRange.start, workAnniversaryRange.end]
                : [birthdayRange.start, birthdayRange.end],
            },
            type: "DATE",
            condition: "IS",
            isInDashboard: true,
            recurring: true,
          },
          isWorkAnniversary
            ? {
                column: databaseField,
                value: { range: [undefined, startOrEndOfCurrentYear] },
                type: "DATE",
                condition: "IS",
                isInDashboard: true,
                recurring: false,
              }
            : undefined,
          { isMultiUser: true, type: "BOOLEAN", value: true, column: "hidden", condition: "NOT" },
        ],
        confluenceGroup: collectionsData?.userGroup || "",
        sort: {
          fieldId: databaseField,
          order: "asc",
          fieldPrefix: `date_field${isWorkAnniversary ? "" : "_recurring"}`,
        },
      },
    ],
    fetchRecords,
    {
      enabled:
        !!databaseField &&
        (isWorkAnniversary ? !!workAnniversaryRange?.start : !!birthdayRange?.start && !!startOrEndOfCurrentYear),
      retry: 0,
      select: (response) => {
        const { data } = response;
        return data?.hits;
      },
    },
  );

  const createUnixTimeFromNumberOfDays = (numberOfDays) => {
    const HOURS = 24;
    const MINUTES = 60;
    const SECONDS = 60;
    return numberOfDays * HOURS * MINUTES * SECONDS;
  };

  useEffect(() => {
    const today = dayjs().unix();
    const todayUnix = dayjs.unix(today).hour(0).minute(0).second(0).unix();
    const todayFixedYear = dayjs.unix(todayUnix).year(1970).unix();

    if (!isWorkAnniversary) {
      const SIX_MONTHS = createUnixTimeFromNumberOfDays(180);
      const start = todayFixedYear - 1;
      const end = todayFixedYear + SIX_MONTHS;
      setBirthdayRange({ start, end });
      const currentYearEnd = new Date(new Date().getFullYear(), 11, 31);
      const convertedToUnix = Math.floor(currentYearEnd.getTime() / 1000);
      setStartOrEndOfCurrentYear(convertedToUnix);
    } else {
      const FIFTEEN_DAYS = createUnixTimeFromNumberOfDays(15);
      const NINETY_DAYS = createUnixTimeFromNumberOfDays(90);
      const start = todayFixedYear - FIFTEEN_DAYS;
      const end = todayFixedYear + NINETY_DAYS;
      setWorkAnniversaryRange({ start, end });

      const currentYearStart = new Date(new Date().getFullYear(), 0, 1);
      const convertedToUnix = Math.floor(currentYearStart.getTime() / 1000);
      setStartOrEndOfCurrentYear(convertedToUnix);
    }
  }, [isWorkAnniversary]);

  const getDetailsForUsers = (users) => {
    if (users?.length) {
      setLoading(true);
      fetchDetailsForUsersIndividually(users.map((u) => u.accountId))
        .then((resUsers) => {
          if (resUsers?.length) {
            const formattedUsers = resUsers
              .map((user) => {
                const userValues = users.find((r) => r.accountId === user.accountId)?.values;

                const dateField = userValues[`${isWorkAnniversary ? "" : "recurring_"}${databaseField}`];
                const formattedDateField = dayjs.unix(dateField).format("MMM D");

                const dateFieldUnix = dayjs.unix(dateField);
                const dateFieldUnixCurrentYear = dayjs.unix(dateField).set("year", dayjs().get("year"));

                const daysLeftFromCurrentYear = Math.abs(
                  dateFieldUnixCurrentYear.diff(dayjs().hour(0).minute(0).second(0).millisecond(0), "day"),
                );
                const yearsLeftFromCurrentYear = Math.abs(dateFieldUnix.diff(dateFieldUnixCurrentYear, "year"));

                const backgroundImage = userValues?.user?.backgroundImage;
                const hasBgData = !!backgroundImage && !!Object.keys(backgroundImage || {}).length;
                const correctImage = hasBgData ? backgroundImage : getRandomUserBackground(IMAGE_TYPES.GRADIENT);

                const image = getCssForBackground(correctImage);

                return {
                  accountId: user.accountId,
                  name: user.displayName,
                  icon: `${window.AP._hostOrigin}${user.profilePicture.path}`,
                  formattedDateField,
                  daysLeft: daysLeftFromCurrentYear,
                  yearsLeft: yearsLeftFromCurrentYear,
                  hasBirthday: !!dateField,
                  background: image,
                };
              })
              .filter((user) => user.hasBirthday);
            setPeople([...formattedUsers]);
          }
        })
        .catch((e) => setPeople([]))
        .finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    if (!!recordsData?.length) {
      getDetailsForUsers(recordsData);
    }
  }, [recordsData]);

  return (
    <GridBirthday
      data={panelData}
      databaseField={databaseField}
      isWorkAnniversary={isWorkAnniversary}
      people={people}
      records={recordsData}
      isLoading={recordsLoading || loading || loadingGlobalSetting}
      borderRadius={borderRadius}
      primaryColor={primaryColor}
    />
  );
}

export default UpcomingBirthdays;
