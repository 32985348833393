import styled from "styled-components";

import Drawer from '@atlaskit/drawer';
import SpaceportDrawer from "./SpaceportDrawer";

const NoSidebar = styled.div`
  width: 0px;
`;

const SpaceportWrapper = styled.div`
  overflow-x: hidden;
  overflow: auto;
  width: inherit;
`;

function Spaceport({ spaceportOpen, setSpaceportOpen }) {

  return (
    <>
      <Drawer
        overrides={{
          Sidebar: {
            component: () => <NoSidebar />,
          },
          Content: {
            component: () => (
              <SpaceportWrapper>
                <SpaceportDrawer /> 
              </SpaceportWrapper>
            ),
          },
        }}
        onClose={() => {setSpaceportOpen(false)}} 
        isOpen={spaceportOpen}
        width="medium">
      </Drawer>
    </>
  );
}

export default Spaceport;
