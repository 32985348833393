import { useEffect, useState } from "react";

const DEFAULT_BREAKPOINTS = {
  XSMALL: "768px",
  SMALL: "1024px",
  MEDIUM: "1440px",
  LARGE: "2560px",
};

export const useBreakpointObserver = (breakpoints = DEFAULT_BREAKPOINTS) => {
  const [breakpoint, setBreakpoint] = useState();

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      if (document.body.offsetWidth < 1024) setBreakpoint(breakpoints.XSMALL);
      if (document.body.offsetWidth >= 1024 && document.body.offsetWidth < 1440) setBreakpoint(breakpoints.SMALL);
      if (document.body.offsetWidth >= 1440 && document.body.offsetWidth < 2560) setBreakpoint(breakpoints.MEDIUM);
      if (document.body.offsetWidth >= 2560) setBreakpoint(breakpoints.LARGE);
    });
    resizeObserver.observe(document.body);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return [breakpoint];
};
