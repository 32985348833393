import dayjs from "dayjs";

export const getUserValuesFromTwoFields = (userValues, fields, firstFieldId, secondFieldId) => {
  let firstValue = "";
  let secondValue = "";

  const storedFirstValue = userValues[`${firstFieldId}`];
  const storedSecondValue = userValues[`${secondFieldId}`];

  const firstField = fields?.find((field) => field.id === firstFieldId);
  if (firstField?.type === "DATE" || firstField?.id?.includes("azureHireDate")) {
    if (storedFirstValue) {
      firstValue = dayjs.unix(storedFirstValue).format(`MMM D, YYYY`);
    } else {
      firstValue = "";
    }
  } else if (firstField?.id?.includes("azure")) {
    firstValue = storedFirstValue;
  } else if (firstField?.type === "SELECT") {
    const found = firstField?.options?.find((op) => op.id === storedFirstValue);
    firstValue = found?.label || "";
  } else if (firstField) {
    firstValue = storedFirstValue;
  }

  const secondField = fields?.find((field) => field.id === secondFieldId);

  if (secondField?.type === "DATE" || secondField?.id?.includes("azureHireDate")) {
    if (storedSecondValue) {
      secondValue = dayjs.unix(storedSecondValue).format(`MMM D, YYYY`);
    } else {
      secondValue = "";
    }
  } else if (secondField?.id?.includes("azure")) {
    secondValue = storedSecondValue;
  } else if (secondField?.type === "SELECT") {
    const found = secondField?.options?.find((op) => op.id === storedSecondValue);
    secondValue = found?.label || "";
  } else if (secondField) {
    secondValue = storedSecondValue;
  }

  return { firstField: firstValue, secondField: secondValue, footerType: secondField?.type };
};
