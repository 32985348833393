import React, { useMemo } from "react";
import { PanelKeys, PanelPositioning } from "../../../../panelTypes";
import { useSidebarStore } from "../../sidebar-store";

import CompanyNews from "./types/CompanyNews";
import Countdown from "./types/Countdown";
import VideoYT, { ListVideoYT } from "./types/VideoYT";
import NewStarters, { NewStartersList } from "./types/NewStarters";
import TeamNews from "./types/TeamNews";
import Info, { InfoMain } from "./types/Info";
import ImportantDates, { ImportantDatesMain } from "./types/ImportantDates";
import Poll from "./types/Poll";
import AppLinks from "./types/AppLinks";
import AssignedToMe from "./types/AssignedToMe";
import ImportantSpaces from "./types/ImportantSpaces";
import SideNews from "./types/SideNews";
import { WorkAnniversaryMain, WorkAnniversarySide } from "./types/WorkAnniversary";
import IconCards from "./types/IconCards";
import ImageCards from "./types/ImageCards";
import TextCards from "./types/TextCards";
import { PeopleMain, PeopleSide } from "./types/People";
import { ActiveUsersMain, ActiveUsersSide } from "./types/ActiveUsers";
import Pages from "./types/Pages";
import ImportantSpacesMain from "./types/ImportantSpacesMain";
import Gliffy from "./types/Gliffy";
import Released from "./types/Released";

const NoPreview = () => {
  return <div style={{ fontSize: "14px", color: "#172b4d" }}>No preview available.</div>;
};

function PreviewProvider({ panelKey }) {
  const selectedContentType = useSidebarStore((state) => state.selectedContentType);

  const AvailablePreviews = useMemo(
    () => ({
      [PanelKeys.COMPANY_NEWS]: CompanyNews,
      [PanelKeys.PERSONALIZED_NEWS]: selectedContentType === PanelPositioning.MAIN ? TeamNews : SideNews,
      [PanelKeys.TARGETED_NEWS]: selectedContentType === PanelPositioning.MAIN ? TeamNews : SideNews,
      [PanelKeys.PAGES]: Pages,
      [PanelKeys.PAGES_SIDE]: Pages,
      [PanelKeys.GLIFFY]: Gliffy,
      [PanelKeys.RELEASED_AI]: Released,
      [PanelKeys.COUNTDOWN]: Countdown,
      [PanelKeys.VIDEOS_YT]: VideoYT,
      [PanelKeys.VIDEOS_YT_SIDE]: ListVideoYT,
      [PanelKeys.NEW_STARTERS]: NewStarters,
      [PanelKeys.NEW_STARTERS_SIDE]: NewStartersList,
      [PanelKeys.TEAM_NEWS]: TeamNews,
      [PanelKeys.INFO_PANEL]: Info,
      [PanelKeys.INFO_PANEL_MAIN]: InfoMain,
      [PanelKeys.IMPORTANT_DATES]: ImportantDates,
      [PanelKeys.IMPORTANT_DATES_MAIN]: ImportantDatesMain,
      [PanelKeys.POLL]: Poll,
      [PanelKeys.APP_LINKS]: AppLinks,
      [PanelKeys.ASSIGNED_TO_ME]: AssignedToMe,
      [PanelKeys.IMPORTANT_SPACES]: ImportantSpaces,
      [PanelKeys.IMPORTANT_SPACES_MAIN]: ImportantSpacesMain,
      [PanelKeys.SIDE_NEWS]: SideNews,
      [PanelKeys.WORK_ANNIVERSARY_MAIN]: WorkAnniversaryMain,
      [PanelKeys.WORK_ANNIVERSARY_SIDE]: WorkAnniversarySide,
      [PanelKeys.PEOPLE_MAIN]: PeopleMain,
      [PanelKeys.PEOPLE_SIDE]: PeopleSide,
      [PanelKeys.ICON_CARDS]: IconCards,
      [PanelKeys.TEXT_CARDS]: TextCards,
      [PanelKeys.IMG_CARDS]: ImageCards,
      [PanelKeys.RECENTLY_ACTIVE_USERS_MAIN]: ActiveUsersMain,
      [PanelKeys.RECENTLY_ACTIVE_USERS_SIDE]: ActiveUsersSide,
    }),
    [],
  );

  const SelectedPreview = useMemo(() => {
    return AvailablePreviews[panelKey] ?? NoPreview;
  }, [panelKey, AvailablePreviews]);

  return <SelectedPreview />;
}

export default PreviewProvider;
