import React from "react";
import Select from "@atlaskit/select";
import styled from "styled-components";
import { useChangesStore } from "../../../../track-changes-store";
import { useRecordsStore } from "../../../../../UserDatabase/records-store";

const Container = styled.div`
  margin-bottom: 20px;
  padding-top: 32px;
`;

function FieldSelect({ fields, setFields, section, alignment = null }) {
  const availableFields = fields.filter((field) => {
    if (!field?.profile && field.key !== "user") {
      return true;
    }

    return false;
  });

  const options = availableFields.map(({ id, title }) => ({ value: id, label: title }));

  const updateField = useRecordsStore((state) => state.updateField);
  const setHasChanges = useChangesStore((state) => state.setHasChanges);

  const enableField = ({ value }) => {
    const order = fields.reduce((value, field) => {
      if (field?.profile?.section === section && field?.profile?.alignment === alignment) {
        if (value <= field?.profile?.order) {
          return field?.profile?.order + 1;
        }
      }

      return value;
    }, 0);

    updateField(value, { profile: { section, alignment, order } });
    setHasChanges(true);

    setFields(
      fields.map((field) => {
        if (field.id === value) {
          return { ...field, profile: { section, alignment, order } };
        }

        return field;
      })
    );
  };

  return (
    <Container>
      <Select options={options} value={null} placeholder="Select field to display" onChange={enableField} />
    </Container>
  );
}

export default FieldSelect;
