import styled from "styled-components";

const Container = styled.div`
  display: grid;
  place-content: center;
  justify-items: center;
  text-align: center;
  height: 100%;
  width: 100%;
`;

const Title = styled.p`
  font-weight: 590;
  font-size: 20px;
  line-height: 23.87px;
  color: #07145a;
  margin-top: 30px;
  margin-bottom: 12px;
`;

const Description = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #44526d;

  .create-link {
    cursor: pointer;
    color: #2684ff;
  }
`;

function EmptyState() {
  return (
    <Container>
      <img src="/images/hub/space-directory-empty-state.svg" alt="Empty space directory" style={{ margin: "0 auto" }} />
      <div>
        <Title>Launch your Space Station</Title>
        <Description>
          <span>There are currently no spaces on your instance. Create a space to launch your Space Station.</span>
        </Description>
      </div>
    </Container>
  );
}

export default EmptyState;
