import styled from "styled-components";

export const FilterFieldWrapper = styled.div`
  display: ${({ hasTitle }) => (hasTitle ? "grid" : "block")};
  grid-template-columns: auto auto;
  align-items: center;
  justify-content: space-between;
  column-gap: 20px;
`;

export const FilterLabel = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #626f86;
  white-space: nowrap;
  width: 70px;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const DateRangeFilterFieldWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 5px;
`;

export const customDatePickerStyles = (primaryColor) => ({
  control: (provided, state) => ({
    ...provided,
    fontSize: "14px",
  }),

  singleValue: (provided) => ({
    ...provided,
    fontWeight: 600,
  }),
});
