import React, { useEffect, useMemo, useState } from "react";
import { Box, Text, xcss } from "@atlaskit/primitives";
import Footer from "./components/Footer";
import { token } from "@atlaskit/tokens";
import CardMenu from "../../menu/layout/CardMenu";
import { useMacroSuiteConnectionStore } from "../../../../Shared/store/macrosuite-connection-store";
import { useNavigationColorsStore, useNavigationStore, useSidebarStore } from "../../store";
import { useTheme } from "styled-components";
import { NAVIGATION_DEFAULT_COLOR_SCHEME } from "../../functions/generateNewEntry";
import ConfluenceBar from "./ConfluenceBar";
import EmptyNavigationState from "../shared/EmptyNavigationState";
import { DrawerTypes } from "../drawer/types";

const previewCss = xcss({
  flex: "1",
  display: "grid",
  gridTemplateRows: "auto 62px",
  backgroundColor: token("elevation.surface.hovered"),
});

function Preview({ themeColorsSet, setThemeColorsSet }) {
  const theme = useTheme();

  const [colors, setColors] = useState({});
  const [temporaryColorScheme, setTemporaryColorScheme] = useState(theme.global.name);

  const isMacroSuiteInstalled = useMacroSuiteConnectionStore((state) => state.isMacroSuiteInstalled);

  const { dark, light } = useNavigationColorsStore((state) => ({
    dark: state.dark,
    light: state.light,
  }));

  const { isOpen, selectedSidebarType } = useSidebarStore((state) => ({
    isOpen: state.isOpen,
    selectedSidebarType: state.selectedSidebarType,
  }));

  const savedColors = useMemo(() => ({ dark, light }), [dark, light]);

  const structure = useNavigationStore((state) => state.structure);

  const isAddingEntry = isOpen && selectedSidebarType === DrawerTypes.ADD_ENTRY;

  useEffect(() => {
    const navigationNotCustomized =
      savedColors?.dark?.menuBackgroundColor === "#ffffffff" &&
      savedColors?.dark?.menuTextColor === "#42526e" &&
      savedColors?.dark?.menuTextHover === "#42526e" &&
      savedColors?.light?.menuBackgroundColor === "#ffffffff" &&
      savedColors?.light?.menuTextColor === "#42526e" &&
      savedColors?.light?.menuTextHover === "#42526e";

    if (navigationNotCustomized) {
      setColors(NAVIGATION_DEFAULT_COLOR_SCHEME);
    } else {
      setColors(savedColors);
    }
  }, [theme, colors, savedColors, setThemeColorsSet]);

  return (
    <Box xcss={previewCss}>
      {structure?.length || isAddingEntry ? (
        <Box
          paddingInline="space.400"
          paddingBlock="space.800"
          style={{ display: "flex", flexDirection: "column", rowGap: "20px", overflowY: "auto", minHeight: 0 }}
        >
          <Text>Preview</Text>

          <Box>
            <ConfluenceBar
              temporaryColorScheme={temporaryColorScheme}
              setTemporaryColorScheme={setTemporaryColorScheme}
            />
            <Box
              style={{
                borderBottom: `1px solid ${theme.navigation.london.publishedMenuBorder}`,
                width: "100%",
                height: "fit-content",
              }}
            >
              <CardMenu
                colors={isMacroSuiteInstalled ? colors : NAVIGATION_DEFAULT_COLOR_SCHEME}
                structure={structure ?? []}
                temporaryColorScheme={temporaryColorScheme}
                isPreview
                themeColorsSet={themeColorsSet}
              />
            </Box>
          </Box>
        </Box>
      ) : (
        <EmptyNavigationState />
      )}

      <Footer />
    </Box>
  );
}

export default Preview;
