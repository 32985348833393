import React from "react";
import { EditorBackground } from "../styled";
import CosmosDatePicker from "../../../../../../../Shared/Components/DatePicker/CosmosDatePicker";

const Date = ({ initialValue, onEdit, onAbort }) => {
  return (
    <EditorBackground style={{ padding: "0 2px" }}>
      <CosmosDatePicker initialValue={initialValue} onEdit={onEdit} onAbort={onAbort} />
    </EditorBackground>
  );
};

export default Date;
