import React from "react";
import styled from "styled-components";
import { Wrapper } from "./styled";

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  column-gap: 24px;
  row-gap: 24px;
`;

const Box = styled.div`
  width: 102px;
  height: 102px;
  display: grid;
  place-content: center;
  border: 0.5px solid #dfe1e6;
  border-radius: 4px;
`;

const Icon = styled.img`
  width: 50px;
  height: 100%;
`;

function AppLinks() {
  return (
    <Wrapper>
      <Container>
        <Box>
          <Icon src="https://caelor-apps.web.app/images/icons/drive.svg" alt="" />
        </Box>

        <Box>
          <Icon src="https://caelor-apps.web.app/images/icons/jira.svg" alt="" />
        </Box>

        <Box>
          <Icon src="https://caelor-apps.web.app/images/icons/trello.svg" alt="" />
        </Box>

        <Box>
          <Icon src="https://caelor-apps.web.app/images/icons/slack.svg" alt="" />
        </Box>
      </Container>
    </Wrapper>
  );
}

export default AppLinks;
