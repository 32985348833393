export const Pages = {
  HOME: "home",
  DASHBOARD: "dashboard",
  NEWS: "news",
  PAGES: "pages",
  PROFILE: "profile",
  PEOPLE: "people",
  SPACE: "space",
  SETTINGS: "settings",
  CATEGORIES: "settings-newsroom-categories",
  DOWNLOAD: "download-center",
  STYLEGUIDE: "styleguide",
  HELP_CENTER: "help-center",
};

export const DefaultPages = {
  HOME: "home",
  DASHBOARD: "dashboard",
  NEWS: "news",
  PAGES: "pages",
  PROFILE: "profile",
  PEOPLE: "people",
  SPACE: "space",
  SETTINGS: "settings",
};
