import React, { Fragment } from "react";
import { ThreeColumnGrid, ListWrapper, OneColumnGrid, ViewAllButtonWrapper } from "../styled/space";
import { ListHeader } from "../Cards/SpaceListCard";
import { SpaceCardWithBackground, SpaceListCard, SpaceSimpleCard, SpaceCard } from "../Cards/";
import { TemplateType } from "./template-type";
import { useCorporateIdentityStore } from "../../Settings/General/BrandAndColors/corporate-identity-store";
import { COLOR_TYPES } from "../../Settings/General/BrandAndColors/color-types";

const cardTemplates = {
  [TemplateType.SPACE_CARD]: SpaceCard,
  [TemplateType.SPACE_CARD_WITH_BACKGROUND]: SpaceCardWithBackground,
  [TemplateType.SPACE_SIMPLE_CARD]: SpaceSimpleCard,
  [TemplateType.SPACE_LIST_CARD]: SpaceListCard,
};

export const SpaceCardsList = ({
  data,
  itemLimit,
  paginated = false,
  hasNextPage = false,
  fetchNextPage,
  ...props
}) => {
  const colors = useCorporateIdentityStore((state) => state.colors);
  const primaryColor = colors ? colors[COLOR_TYPES.PRIMARY] : "#0065ff";
  return paginated ? (
    <ListWrapper>
      <ListHeader />
      <OneColumnGrid>
        {data.map((page) => (
          <Fragment key={page}>
            {page?.results.map((space) => (
              <SpaceListCard hasActions key={space.key} spaceInfo={space} />
            ))}
          </Fragment>
        ))}
      </OneColumnGrid>

      {hasNextPage && (
        <ViewAllButtonWrapper primaryColor={primaryColor} onClick={fetchNextPage}>
          Show more
        </ViewAllButtonWrapper>
      )}
    </ListWrapper>
  ) : (
    <ListWrapper>
      <ListHeader />
      <OneColumnGrid>
        {data.slice(0, itemLimit).map((space) => (
          <SpaceListCard key={space.key} spaceInfo={space} {...props} />
        ))}
      </OneColumnGrid>
    </ListWrapper>
  );
};

export const SpaceCardsGrid = ({
  data,
  itemLimit,
  template,
  paginated = false,
  hasNextPage = false,
  fetchNextPage,
  ...props
}) => {
  const colors = useCorporateIdentityStore((state) => state.colors);
  const primaryColor = colors ? colors[COLOR_TYPES.PRIMARY] : "#0065ff";
  const Template = cardTemplates[template];

  return paginated ? (
    <ListWrapper>
      <ThreeColumnGrid>
        {data.map((page) => (
          <Fragment key={page}>
            {page?.results.map((space) => (
              <SpaceSimpleCard hasActions key={space.key} spaceInfo={space} />
            ))}
          </Fragment>
        ))}
      </ThreeColumnGrid>

      {hasNextPage && (
        <ViewAllButtonWrapper primaryColor={primaryColor} onClick={() => fetchNextPage()}>
          Show more
        </ViewAllButtonWrapper>
      )}
    </ListWrapper>
  ) : (
    <ThreeColumnGrid>
      {data.slice(0, itemLimit).map((space) => (
        <Template key={space.key} spaceInfo={space} {...props} />
      ))}
    </ThreeColumnGrid>
  );
};
