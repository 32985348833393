import React, { useContext } from "react";
import CheckIcon from "@atlaskit/icon/glyph/check";
import AddIcon from "@atlaskit/icon/glyph/add";
import styled, { useTheme } from "styled-components";
import { subscriptionsContext } from "../context";

const Wrapper = styled.div`
  padding: 15px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${({ borderClr }) => borderClr};
  border-radius: 3px;
`;

const TitleIconContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  color: ${({ theme }) => theme.notification.title};
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const AdditionalInfo = styled.div`
  color: ${({ clr }) => clr};
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`;

const IconContainer = styled.div`
  min-width: 32px;
  width: 32px;
  height: 32px;
  display: grid;
  place-content: center;
  cursor: pointer;
  border-radius: 3px;

  background-color: ${({ theme, isSelected }) => {
    if (isSelected) {
      return theme.shared.sidebar.preview.tabs.active.background;
    }
    return theme.shared.sidebar.button.background;
  }};
`;

function Item({ hasIcon, additionalInfo, Icon, title, isSelected, onSelection = () => {} }) {
  const theme = useTheme();
  const colorScheme = theme.global.name;

  const { primaryColor } = useContext(subscriptionsContext);

  return (
    <Wrapper borderClr={colorScheme === "light" ? "#EBECF0" : "rgba(166, 197, 226, 0.16)"}>
      <TitleIconContainer>
        {hasIcon && <Icon />}
        <Column>
          <Title>{title}</Title>
          {!!additionalInfo && (
            <AdditionalInfo clr={colorScheme === "light" ? "#626F86" : "#8696A7"}>{additionalInfo}</AdditionalInfo>
          )}
        </Column>
      </TitleIconContainer>

      <IconContainer isSelected={isSelected} onClick={() => onSelection()}>
        {isSelected ? (
          <CheckIcon primaryColor={primaryColor || "#0c66e4"} />
        ) : (
          <AddIcon primaryColor={colorScheme === "light" ? "#44546F" : "#9FADBC"} />
        )}
      </IconContainer>
    </Wrapper>
  );
}

export default Item;
