const ZOOM_FACTOR = 0.01;
const ZOOM_FACTOR_IN = 1 + ZOOM_FACTOR;
const ZOOM_FACTOR_OUT = 1 - ZOOM_FACTOR;

export const types = {
  PAN: "PAN",
  PAN_START: "PAN_START",
  ZOOM: "ZOOM",
  CUSTOM_ZOOM: "CUSTOM_ZOOM",
  RESET: "RESET",
  CENTER: "CENTER",
};

export const startPan = (event) => ({
  type: types.PAN_START,
  clientX: event.clientX,
  clientY: event.clientY,
});

export const pan = (event) => ({
  type: types.PAN,
  clientX: event.clientX,
  clientY: event.clientY,
});

export const zoom = (event, containerRect, zoomLimits) => ({
  type: types.ZOOM,
  zoomFactor: event.deltaY < 0 ? ZOOM_FACTOR_IN : ZOOM_FACTOR_OUT,
  clientX: event.clientX,
  clientY: event.clientY,
  containerRect: containerRect,
  zoomLimits: zoomLimits,
});

export const customZoomUpdate = (zoomLevel) => ({
  type: types.CUSTOM_ZOOM,
  zoomLevel,
});

export const reset = (initialY) => ({
  type: types.RESET,
  initialY,
});

export const centerView = (initialY, newZoomLevel, newHorizontalPosition) => ({
  type: types.CENTER,
  initialY,
  newZoomLevel,
  newHorizontalPosition,
});
