import React from "react";
import Select, { components } from "@atlaskit/select";
import { useEffect } from "react";
import { useState } from "react";
import { selectStylesWithOverrides } from "./CustomSelectStyles";
import { FieldContainer, Label } from "./styled";
import { useTheme } from "styled-components";

const SelectWithCustomOptions = ({
  initialValue,
  onUpdate,
  options,
  label,
  placeholder,
  iconBefore = false,
  isMulti = false,
  isDisabled = false,
  isClearable = false,
  customContainerStyle = {},
}) => {
  const [selected, setSelected] = useState();
  const theme = useTheme();

  useEffect(() => {
    if (initialValue) {
      let selected;

      if (isMulti) {
        selected = options.filter((p) => initialValue.includes(p.value));
      } else {
        selected = options.find((p) => p.value === initialValue);
      }

      if (selected) {
        setSelected(selected);
      }
    } else {
      setSelected();
    }
  }, [initialValue]);

  const { Option, SingleValue } = components;

  const IconOption = (props) => (
    <Option {...props}>
      <div
        style={{
          display: "flex",
        }}
      >
        {iconBefore && props.data.iconBefore}
        <div style={{ marginLeft: iconBefore ? "5px" : "initial", lineHeight: "1" }}>{props.data.label}</div>
      </div>
    </Option>
  );

  const IconSingleValue = (props) => (
    <SingleValue {...props}>
      <div
        style={{
          display: "flex",
        }}
      >
        {iconBefore && props.data.iconBefore}
        <div style={{ marginLeft: iconBefore ? "5px" : "initial" }}>{props.data.label}</div>
      </div>
    </SingleValue>
  );

  return (
    <FieldContainer style={customContainerStyle}>
      <Label>{label}</Label>
      <Select
        value={selected}
        options={options}
        isDisabled={isDisabled}
        isMulti={isMulti}
        styles={selectStylesWithOverrides({
          control: (provided, { isFocused }) => ({
            ...provided,
            backgroundColor: theme.shared.form.select.background,
            borderColor: theme.shared.form.select.border,
            fontSize: "14px",
            "&:hover": {
              backgroundColor: `${theme.shared.form.select.hover} !important`,
              borderColor: `${theme.shared.form.select.hover} !important`,
            },
          }),
          menu: (provided) => ({
            ...provided,
            backgroundColor: theme.shared.form.select.options,
            border: `1px solid ${theme.shared.form.select.border}`,
          }),
          option: (provided, { isSelected, isFocused }) => ({
            ...provided,
            fontSize: "14px",
          }),
          singleValue: (provided) => ({
            ...provided,
            color: theme.shared.form.select.value,
          }),
        })}
        placeholder={placeholder}
        isClearable={isClearable}
        components={{ Option: IconOption, SingleValue: IconSingleValue }}
        onChange={(result) => {
          if (isMulti) {
            onUpdate(result.map((opt) => opt.value));
          } else {
            onUpdate(result?.value);
          }
        }}
      />
    </FieldContainer>
  );
};

export default SelectWithCustomOptions;
