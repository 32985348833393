const deepFind =
  (callbackFn) =>
  ([firstInArray, ...restOfArray] = []) => {
    return (
      firstInArray &&
      (callbackFn(firstInArray)
        ? firstInArray
        : deepFind(callbackFn)(firstInArray.children) || deepFind(callbackFn)(restOfArray))
    );
  };

const findUserById = (id) => (structure) => deepFind((user) => user.id === id)([structure]);

const createTreeFromList = (list) => {
  let map = {},
    node,
    roots = [],
    i;

  for (i = 0; i < list.length; i += 1) {
    map[list[i].id] = i; // initialize the map
    list[i].children = []; // initialize the children
  }

  for (i = 0; i < list.length; i += 1) {
    node = list[i];
    if (!!node.parentId) {
      list[map[node.parentId]]?.children?.push(node);
    } else {
      roots.push(node);
    }
  }
  return roots;
};

const flattenTree = (tree, flattedArray = []) => {
  for (let index = 0; index < tree.length; index++) {
    flattedArray.push({ ...tree[index], children: [] });
    if (tree[index]?.children?.length) {
      flattenTree(tree[index].children, flattedArray);
    }
  }

  return flattedArray;
};

const buildNode = (accountId, supervisor) => ({ id: accountId, parentId: supervisor, expanded: true, children: [] });

const assignDepth = (depth) => (node) => {
  node.depth = depth;
  (node.children || []).forEach(assignDepth(depth + 1));
};

export const buildDynamicStructure = (users, topLevelUsers, supervisorFieldId, buildDepthLevels) => {
  const allUsers = users
    .filter(({ accountId }) => !topLevelUsers.includes(accountId))
    .map((user) => ({
      id: user.accountId,
      supervisor: user?.values?.[supervisorFieldId],
    }));

  const usersWithoutSupervisor = [];
  const usersWithSupervisor = allUsers.filter((user) => {
    if (!user.supervisor) {
      usersWithoutSupervisor.push(user.id);
    }
    return !!user.supervisor;
  });

  const unsortedNodes = [];
  const unsortedParentNodes = [];

  for (let index = 0; index < usersWithSupervisor.length; index++) {
    const user = usersWithSupervisor[index];
    unsortedNodes.push(buildNode(user.id, user.supervisor));
  }

  for (let index = 0; index < topLevelUsers.length; index++) {
    const userId = topLevelUsers[index];
    unsortedParentNodes.push(buildNode(userId, undefined));
  }

  const tree = createTreeFromList([...unsortedParentNodes, ...unsortedNodes]);

  const builtStructure = {
    id: "root-level",
    expanded: true,
    level: -1,
    children: tree || [],
  };

  if (buildDepthLevels && builtStructure) {
    builtStructure.children.forEach(assignDepth(1));
  }

  return builtStructure;
};

export const buildStructureForUserProfile = (userId, structure) => {
  const structureCopy = { ...structure };
  const foundUser = findUserById(userId)(structureCopy);

  if (!foundUser) return { error: true, type: "user-not-found" };

  const currentUserParentIsRoot = !foundUser?.parentId;
  let foundUserAsParent = undefined;

  if (!currentUserParentIsRoot) {
    foundUserAsParent = findUserById(foundUser.parentId)(structureCopy);
  }

  const parentOfUser = currentUserParentIsRoot
    ? { id: "root-level", expanded: true }
    : { id: foundUserAsParent.id, expanded: true };

  const newStructure = {
    id: parentOfUser.id,
    expanded: true,
    children: [
      {
        id: foundUser.id,
        expanded: true,
        children: foundUser.children.map((user) => ({ id: user.id, expanded: true, children: [] })),
      },
    ],
  };

  if (!currentUserParentIsRoot) {
    return {
      id: "root-level",
      expanded: true,
      children: [newStructure],
    };
  }

  return newStructure;
};

export const filterTreeBasedOnLevel = (tree, level) => {
  const flattenedTree = flattenTree(tree);
  const filteredByLevel = flattenedTree.filter((node) => node.depth <= level);
  const newTree = createTreeFromList([...filteredByLevel]);

  const builtStructure = {
    id: "root-level",
    expanded: true,
    level: -1,
    children: newTree || [],
  };
  return builtStructure;
};
