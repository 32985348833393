import React from "react";
import styled from "styled-components";

import { useSingleCollectionStore } from "../collection-store";

import InfiniteScroller from "../../../Shared/Components/InfiniteScroller";
import PersonCard from "./PersonCard";

const Wrapper = styled.div`
  width: calc(100% - 56px);
  padding: 0px 28px;
  position: relative;

  .infinite-scroll-spinner > span {
    position: absolute;
    left: calc(50% - 24px);
    bottom: 50px;
  }
`;

const RecordsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 16px;
  background: ${({ theme }) => theme.global.background};
  width: 100%;
  min-height: 100vh;
  padding: 0 13px;
  border-radius: 4px;
  box-sizing: border-box;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  @media (min-width: 1024px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  @media (max-width: 1439px) and (min-width: 1025px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  @media (min-width: 1440px) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  @media (min-width: 1700px) {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
  @media (min-width: 1900px) {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
  @media (min-width: 2560px) {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }
`;

function Records({ fetchNextPage, hasNextPage, totalSize }) {
  const records = useSingleCollectionStore((state) => state.records);

  const createProfileLink = (accountId) => {
    return `${window.AP._hostOrigin}/wiki/plugins/servlet/ac/com.caelor.confluence.cloud.cosmos/caelor-cosmos-portal?ac.cosmosPage=profile&ac.accountId=${accountId}`;
  };

  return (
    <Wrapper>
      <InfiniteScroller
        dataLength={totalSize}
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        endMessage=""
        scrollThreshold="200px"
      >
        {!!records?.length && (
          <RecordsWrapper>
            {records.map((record) => (
              <PersonCard key={record.accountId} user={record} createProfileLink={createProfileLink} />
            ))}
          </RecordsWrapper>
        )}
      </InfiniteScroller>
    </Wrapper>
  );
}

export default Records;
