import create from "zustand";
import { SortTypes } from "./Filters/Sort";
import { NewsViews } from "./defaults";

export const useNewsroomStore = create((set, get) => ({
  settings: {
    selectedView: NewsViews.MAGAZINE,
  },
  setSettings: (v) => set((state) => ({ settings: { ...v } })),
  updateSettings: (key, value) =>
    set((state) => {
      const copyOfSettings = { ...get().settings, [key]: value };
      return set((state) => ({ settings: copyOfSettings }));
    }),
  updateSettingsView: (viewKey, propertyKey, value) =>
    set((state) => {
      const existingSettings = { ...get().settings };
      return set((state) => ({
        settings: {
          ...existingSettings,
          [viewKey]: {
            ...existingSettings[viewKey],
            [propertyKey]: value,
          },
        },
      }));
    }),

  sorting: SortTypes.NEWEST,
  setSorting: (v) => set((state) => ({ sorting: v })),

  categoryFilter: "",
  setCategoryFilter: (v) => set((state) => ({ categoryFilter: v })),

  spaceFilter: [],
  setSpaceFilter: (v) => set((state) => ({ spaceFilter: [...v] })),

  labelsFilter: [],
  setLabelsFilter: (v) => set((state) => ({ labelsFilter: [...v] })),

  authorsFilter: [],
  setAuthorsFilter: (v) => set((state) => ({ authorsFilter: [...v] })),

  dateFilter: { start: "", end: "" },
  setDateFilter: (type, value) =>
    set((state) => {
      const currentDateFilter = { ...get().dateFilter };
      return set((state) => ({ dateFilter: { ...currentDateFilter, [type]: value } }));
    }),

  categories: [],
  setCategories: (categories) => set((state) => ({ categories })),

  resetFilters: () =>
    set((state) => ({
      spaceFilter: [],
      labelsFilter: [],
      authorsFilter: [],
      dateFilter: { start: "", end: "" },
    })),

  changesWarningModal: false,
  setChangesWarningModal: (v) => set((state) => ({ changesWarningModal: v })),

  createBlogModalOpen: false,
  setCreateBlogModalOpen: (v) => set((state) => ({ createBlogModalOpen: v })),
}));

export const useSidebarStore = create((set, get) => ({
  isSidebarOpen: false,
  setIsSidebarOpen: (v) => set((state) => ({ isSidebarOpen: v })),
  selectedSidebar: undefined,
  setSelectedSidebar: (v) => set((state) => ({ selectedSidebar: v })),
}));
