import React, { useEffect, useState } from "react";
import { FieldContainer, Label } from "./styled";
import styled, { useTheme } from "styled-components";
import { AsyncSelect } from "@atlaskit/select";
import { atlassianRestService } from "../../../../../Service/AtlassianRestService";
import { SELECT_STYLES, SIMPLE_SELECT_STYLES, selectStylesWithOverrides } from "./CustomSelectStyles";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
`;

const Image = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  object-fit: cover;
`;

const Name = styled.span`
  font-weight: 400;
  font-size: 14px;
  color: ${({ theme }) => theme.shared.form.select.value};
`;

const StyledLabel = ({ name, pictureUrl }) => {
  return (
    <Wrapper>
      <Image src={pictureUrl} />
      <Name>{name}</Name>
    </Wrapper>
  );
};

const PeoplePicker = ({
  initialAccountIDs,
  onUpdate,
  isMulti,
  label = "People",
  placeholder = "Filter by people",
  customStyles = {},
}) => {
  const [selectedUsers, setSelectedUsers] = useState([]);
  const theme = useTheme();

  useEffect(() => {
    if ((initialAccountIDs ?? []).length > 0) {
      const promises = [];
      for (const accountId of initialAccountIDs) {
        promises.push(atlassianRestService.getUserByAccountId(accountId));
      }
      Promise.all(promises)
        .then((results) => {
          setSelectedUsers(results.map((r) => _toUserField(r)));
        })
        .catch((e) => {
          // Handle errors here
        });
    }
  }, [initialAccountIDs]);

  const loadOptions = async (query) => {
    try {
      const users = await atlassianRestService.findUsersByName(query);
      const { results } = users;
      const atlassianUsers = results
        .filter(({ user }) => user.accountType === "atlassian")
        .map(({ user }) => _toUserField(user));
      return atlassianUsers;
    } catch (error) {
      return [];
    }
  };

  return (
    <FieldContainer style={customStyles}>
      <Label color={theme.sidebar.label}>{label}</Label>
      <AsyncSelect
        width={"100%"}
        isMulti={isMulti}
        placeholder={placeholder}
        addMoreMessage=""
        onChange={(e) => {
          if (e === undefined) {
            setSelectedUsers([]);
            onUpdate(isMulti ? [] : undefined);
            return;
          }
          if (isMulti) {
            setSelectedUsers(e);
            onUpdate(e.map((u) => u.id));
            return;
          }
          setSelectedUsers([e]);
          onUpdate(e);
        }}
        value={selectedUsers}
        defaultOptions
        loadOptions={loadOptions}
        styles={SIMPLE_SELECT_STYLES}
      />
    </FieldContainer>
  );
};
function _toUserField(user) {
  return {
    avatarUrl: atlassianRestService.getProfilePictureUrl(user.profilePicture.path),
    id: user.accountId,
    name: user.publicName,
    type: "user",
    label: (
      <StyledLabel
        name={user.publicName}
        pictureUrl={atlassianRestService.getProfilePictureUrl(user.profilePicture.path)}
      />
    ),
    value: user.accountId,
  };
}

export default PeoplePicker;
