import React from "react";
import { useDrag, useDrop } from "react-dnd";
import RemoveIcon from "@atlaskit/icon/glyph/editor/remove";
import SettingsIcon from "@atlaskit/icon/glyph/settings";
import styled from "styled-components";
import DragHandlerIcon from "@atlaskit/icon/glyph/drag-handler";
import { IconContainer } from "./styled";
import { useChangesStore } from "../../../../track-changes-store";
import { useRecordsStore } from "../../../../../UserDatabase/records-store";

const Wrapper = styled.div`
  ${({ isHoveringOver, orderDirection }) => {
    if (!isHoveringOver) {
      return null;
    }

    return orderDirection === "top-bottom" ? `border-bottom: 1px solid #aaa;` : `border-top: 1px solid #aaa;`;
  }};
`;

const Field = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 0px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.shared.sidebar.item.title};
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
`;

const Title = styled.span`
  max-width: 162px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

function ReorderableField({ sortedMainPanelFields, sourceField, fields, setFields, onConfigClick }) {
  const alignment = sourceField.profile?.alignment;

  const updateField = useRecordsStore((state) => state.updateField);
  const setHasChanges = useChangesStore((state) => state.setHasChanges);

  const [{ isOver }, drop] = useDrop(() => ({
    accept: ["profile-field"],
    drop: () => ({ id: sourceField.id, ...sourceField }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  }));

  const reorderFields = (targetId) => {
    const targetField = fields.find(({ id }) => id === targetId);

    if (targetId !== sourceField.id && targetId !== "placeholder") {
      const items = fields.map((field) => {
        let profile;

        if (alignment === targetField.profile.alignment) {
          if (field.id === sourceField.id) {
            profile = { ...targetField.profile };
          }
          if (field.id === targetField.id) {
            profile = { ...sourceField.profile };
          }
        } else if (field.profile) {
          if (field.id === sourceField.id) {
            profile = { ...targetField.profile };
          }

          if (alignment !== field.profile.alignment) {
            profile = {
              ...field.profile,
              order: field.profile.order + (field.profile.order >= targetField.profile.order ? 1 : -1),
            };
          }
        }

        if (profile) {
          updateField(field.id, { profile });
          setHasChanges(true);
          return { ...field, profile };
        }

        return field;
      });

      setFields([...items]);
    }
  };

  const handleRemove = (fieldId) => {
    const newFields = fields.map((field) => {
      if (field.id === fieldId) {
        return { ...field, profile: null };
      }
      return field;
    });

    setFields([...newFields]);
    setHasChanges(true);
    updateField(fieldId, { profile: null });
  };

  const [{ draggedItem }, drag] = useDrag({
    type: "profile-field",
    item: () => {
      return { id: sourceField.id, type: "profile-field", ...sourceField };
    },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (item && dropResult) {
        reorderFields(dropResult.id);
      }
    },
    collect: (monitor) => ({
      draggedItem: monitor.getItem(),
    }),
  });

  const draggedItemPosition = sortedMainPanelFields?.findIndex(({ id }) => id === draggedItem?.id);
  const potentialTargetPosition = isOver ? sortedMainPanelFields?.findIndex(({ id }) => id === sourceField?.id) : 0;

  return (
    <Wrapper
      ref={drop}
      isHoveringOver={isOver}
      orderDirection={draggedItemPosition < potentialTargetPosition ? "bottom-top" : "top-bottom"}
    >
      <Field key={sourceField.id}>
        <FlexContainer>
          <IconContainer style={{ cursor: "grab" }} ref={drag}>
            <DragHandlerIcon />
          </IconContainer>
          <Title>{sourceField.title}</Title>
        </FlexContainer>

        <div style={{ display: "flex", gap: "9px" }}>
          {sourceField.type === "DATE" && sourceField.profile.section === "sidebar" && (
            <IconContainer onClick={() => onConfigClick()}>
              <SettingsIcon />
            </IconContainer>
          )}

          <IconContainer onClick={() => handleRemove(sourceField.id)} style={{ marginRight: "5px" }}>
            <RemoveIcon />
          </IconContainer>
        </div>
      </Field>
    </Wrapper>
  );
}

export default ReorderableField;
