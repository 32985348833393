import React from "react";
import Modal, { ModalFooter, ModalTransition } from "@atlaskit/modal-dialog";
import Button from "@atlaskit/button";
import styled from "styled-components";
import { useTheme } from "styled-components";

const ModalTitle = styled.h1`
  color: ${({ theme }) => theme.confirmDeleteModal.modalHeaderText};
  font-weight: 500;
  font-size: 20px;
  font-style: inherit;
  line-height: 1;

  min-width: 0;
  flex: 1 1 auto;
  word-wrap: break-word;
  padding-bottom: 0px;
`;

const ModalBody = styled.div`
  color: ${({ theme }) => theme.confirmDeleteModal.modalBodyText};
  font-weight: 400;
  font-size: 14px;
  font-style: inherit;
  line-height: 1;

  min-width: 0;
  flex: 1 1 auto;
  padding: 2px 24px;
`;

const ModalHeader = styled.div`
  display: flex;
  padding: 24px;
  padding-bottom: 0px;
  position: relative;
  align-items: center;
  justify-content: space-between;
`;

export const ConfirmDeleteModal = ({
  onClickOutside = () => {},
  onDiscard,
  onConfirm,
  visible,
  height = "215px",
  deleteInfo: { title, description, optionId },
  buttonInfo = { appearance: "danger" },
}) => {
  const handleConfirm = async () => {
    onConfirm(optionId);
  };

  return (
    <ModalTransition>
      {visible && (
        <Modal height={height} width="400px" title={title} onClose={() => onClickOutside()}>
          <ModalHeader>
            <ModalTitle>{title}</ModalTitle>
          </ModalHeader>
          <ModalBody>{description}</ModalBody>
          <ModalFooter>
            <Button onClick={() => onDiscard()}>
              {buttonInfo?.secondaryText ? buttonInfo.secondaryText : "Cancel"}
            </Button>
            <Button onClick={handleConfirm} autoFocus appearance={buttonInfo.appearance}>
              {buttonInfo?.mainText ? buttonInfo.mainText : "Delete"}
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </ModalTransition>
  );
};
