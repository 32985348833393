import React from "react";
import { Panel, PanelContent, PanelTitle } from "../../shared/styled";
import OrgChartBuilder from "../../../../../OrgChart/OrgChartBuilder";

function Preview({ orgChartSettings }) {
  return (
    <Panel>
      <PanelTitle>Preview</PanelTitle>
      <PanelContent>
        <OrgChartBuilder orgChartSettings={orgChartSettings} />
      </PanelContent>
    </Panel>
  );
}

export default Preview;
