import React from "react";
import { FiltersWrapper } from "../styled/space";
import { FilterDivider } from "../../Shared/Filters/styled-filters";
import ViewSelect from "./ViewSelect";
import Sort from "./Sort";
import FilterTabs from "./FilterTabs";
import { useTheme } from "styled-components";
import { useCorporateIdentityStore } from "../../Settings/General/BrandAndColors/corporate-identity-store";
import { COLOR_TYPES } from "../../Settings/General/BrandAndColors/color-types";

function FilterSidebar() {
  const theme = useTheme();
  const colors = useCorporateIdentityStore((state) => state.colors);
  const primaryColor = colors[COLOR_TYPES.PRIMARY] || "#0065ff";

  return (
    <FiltersWrapper>
      <ViewSelect primaryColor={primaryColor} />

      <Sort />

      <FilterDivider borderColor={theme.global.divider} />

      <FilterTabs />
    </FiltersWrapper>
  );
}

export default FilterSidebar;
