import React from "react";
import ArrowLeftIcon from "@atlaskit/icon/glyph/arrow-left";
import styled from "styled-components";

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`;

export const Breadcrumb = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #8993a4;
  white-space: nowrap;
  display: flex;
  align-items: center;
  column-gap: 10px;
`;

export const SettingsLink = styled.span`
  cursor: pointer;

  &:hover {
    color: #42526e;
  }
`;

export const Title = styled.h1`
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  color: ${({ theme }) => theme.shared.settings.section.title};
  margin: 0;
`;

const IconContainer = styled.div`
  width: 24px;
  height: 24px;
  display: grid;
  place-content: center;

  cursor: pointer;
`;

export const Symbol = styled.span`
  color: #626f86;
`;

export const BreadcrumbTitle = styled.h1`
  margin: 0;
  font-size: 14px;
  color: #42526e;
  font-weight: 400;
`;

export const Description = styled.a`
  all: unset;
  color: ${({ link }) => (link ? "#0C66E4" : "#626f86")};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-bottom: 22px;
  cursor: ${({ link }) => (link ? "pointer" : "default")};
  margin-right: 8px;
`;

const TitleWithBack = ({
  handleOnClick,
  pageTitle = "Settings",
  title,
  description = "",
  linkDescription,
  detailOpen = false,
}) => {
  return (
    <TitleWrapper>
      <Breadcrumb>
        <SettingsLink onClick={handleOnClick}>{pageTitle}</SettingsLink> <Symbol>&#47;</Symbol>
        {detailOpen && (
          <>
            <SettingsLink onClick={handleOnClick}>Categories</SettingsLink>
            <Symbol>&#47;</Symbol>
          </>
        )}
        <BreadcrumbTitle>{title}</BreadcrumbTitle>
      </Breadcrumb>
      <div style={{ display: "flex", gap: "0.5rem" }}>
        <IconContainer onClick={handleOnClick}>
          <ArrowLeftIcon size="medium" primaryColor="#42526E" />
        </IconContainer>
        <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
          <Title>{title}</Title>
          <Description target="_blank" link={linkDescription} href={linkDescription}>
            {description}
          </Description>
        </div>
      </div>
    </TitleWrapper>
  );
};

export default TitleWithBack;
