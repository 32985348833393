import create from "zustand";

export const useConfiguratorStore = create((set, get) => ({
  isEditingIcon: false,
  setIsEditingIcon: (isEditing) => set((state) => ({ isEditingIcon: isEditing })),

  isEditingLink: false,
  setIsEditingLink: (isEditing) => set((state) => ({ isEditingLink: isEditing })),

  isEditingImage: false,
  setIsEditingImage: (isEditing) => set((state) => ({ isEditingImage: isEditing })),
}));
