import httpClient from "../../../Service/http-client";

export async function fetchIsInitialized({ queryKey }) {
  const url = `ac/confluence/userdatabase/initialized`;
  return httpClient.get(url);
}

export async function fetchUserDatabaseFields({ queryKey }) {
  const [_key, { includeEmail = true, includeHiddenField = false }] = queryKey;
  const url = `ac/confluence/userdatabase/fields?includeEmail=${includeEmail}&includeHiddenField=${includeHiddenField}`;
  return httpClient.get(url);
}

export async function fetchUserRecord({ queryKey }) {
  const [_key, { accountId = undefined }] = queryKey;
  const url = `ac/confluence/userdatabase/user-record`;
  return httpClient.post(url, { accountId });
}

export async function fetchRecords({ queryKey, pageParam }) {
  const [_key, { filters, options = {}, sort, page = undefined, confluenceGroup = "", limit = undefined }] = queryKey;

  const url = `ac/confluence/userdatabase/records`;
  return httpClient.post(url, { filters, options, page: page || pageParam, sort, limit, confluenceGroup });
}

export async function applyChanges(changes) {
  const url = `ac/confluence/userdatabase/apply-changes`;
  return httpClient.post(url, changes);
}

export async function applyChangesForUser(changes) {
  const url = `ac/confluence/userdatabase/apply-changes-for-user`;
  return httpClient.post(url, changes);
}

export async function createRecordsWithAccountIds(accountIds, initialLoading = false) {
  const url = `ac/confluence/userdatabase/create-records-by-accountids`;
  return httpClient.post(url, { accountIds, initialLoading });
}

export async function deleteRecordsWithAccountIds(accountIds) {
  const url = `ac/confluence/userdatabase/delete-records-by-accountids`;
  return httpClient.post(url, { accountIds });
}

export async function getShouldSyncGuestUsersStatus() {
  const url = `ac/confluence/userdatabase/sync-guests`;
  return httpClient.get(url);
}

export async function saveSyncGuestUsersState(shouldSyncGuestUsers) {
  const url = `ac/confluence/userdatabase/sync-guests`;
  return httpClient.post(url, { shouldSyncGuestUsers });
}

async function getMembers(url) {
  return new Promise((resolve, reject) => {
    window.AP.request(url).then((payload) => resolve(JSON.parse(payload.body)));
  });
}

export async function getAllExistingUserAccountIds() {
  const url = `ac/confluence/userdatabase/collection/existing-users`;
  return httpClient.get(url);
}

const USER_SEARCH_LIMIT = 100;
const getUrl = (start, shouldIncludeGuestUsers) =>
  `/rest/api/search/user?cql=type=user&limit=${USER_SEARCH_LIMIT}&start=${start}&sitePermissionTypeFilter=${
    shouldIncludeGuestUsers ? "all" : "none"
  }`;

export async function fetchUsersInSystem(shouldIncludeGuestUsers) {
  return await getUsersRecursively(1, 0, [], shouldIncludeGuestUsers);
}

async function getUsersRecursively(i = 1, start = 0, users = [], shouldIncludeGuestUsers) {
  const data = await getMembers(getUrl(start, shouldIncludeGuestUsers));

  users.push(
    ...data.results
      .filter((r) => r.user.type === "known" && r.user.accountType === "atlassian")
      .map((r) => r.user.accountId),
  );

  if (data.size === 0 || i >= 140) {
    return users;
  }

  return await getUsersRecursively(i + 1, start + USER_SEARCH_LIMIT, users, shouldIncludeGuestUsers);
}
