import React, { useMemo } from "react";
import styled from "styled-components";
import CheckIcon from "@atlaskit/icon/glyph/check";
import { FilterTypes } from "../types";
import { useSpaceDirectoryStore } from "../space-directory-store";
import { useCurrentUserStore } from "../../../current-user-store";
import ListingFilters, { Link, LinkItem } from "../../Shared/ListingFilters";
import { useCorporateIdentityStore } from "../../Settings/General/BrandAndColors/corporate-identity-store";
import { COLOR_TYPES } from "../../Settings/General/BrandAndColors/color-types";

const TabsWrapper = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  row-gap: 4px;
`;

function FilterTabs() {
  const { selectedFilter, setSelectedFilter } = useSpaceDirectoryStore((state) => ({
    selectedFilter: state.selectedFilter,
    setSelectedFilter: state.setSelectedFilter,
  }));

  const primaryColor = useCorporateIdentityStore((state) => state.colors[COLOR_TYPES.PRIMARY]);

  const isAnonymous = useCurrentUserStore((state) => state.isAnonymous);

  const Filters = useMemo(
    () => [
      { label: "All", value: FilterTypes.ALL, isDisabled: false },
      { label: "Starred", value: FilterTypes.STARRED, isDisabled: isAnonymous },
      // { label: "Watching", value: FilterTypes.WATCHING, isDisabled: isAnonymous }, Removed because confluence returns old non re-indexed data.
      { label: "Communal", value: FilterTypes.COMMUNAL, isDisabled: false },
      { label: "Personal", value: FilterTypes.PERSONAL, isDisabled: isAnonymous },
      { label: "Archived", value: FilterTypes.ARCHIVED, isDisabled: isAnonymous },
    ],
    [isAnonymous],
  );

  return (
    <TabsWrapper>
      <ListingFilters
        filters={Filters.filter(({ isDisabled }) => !isDisabled)}
        isFilterSelected={(id) => selectedFilter === id}
        handleClickOnItem={(id) => setSelectedFilter(selectedFilter === id ? undefined : id)}
      />

      {selectedFilter && !Filters.map((f) => f.value).includes(selectedFilter) && (
        <>
          {selectedFilter === FilterTypes.LATEST_ACTIVITY && (
            <LinkItem onClick={() => setSelectedFilter(undefined)}>
              <CheckIcon primaryColor={primaryColor || "#0c66e4"} />
              <Link primaryColor={primaryColor}>Latest Activity</Link>
            </LinkItem>
          )}
          {selectedFilter === FilterTypes.STAR_AND_WATCH && (
            <LinkItem onClick={() => setSelectedFilter(undefined)}>
              <CheckIcon primaryColor={primaryColor || "#0c66e4"} />
              <Link primaryColor={primaryColor}>Starred & Watching</Link>
            </LinkItem>
          )}
        </>
      )}
    </TabsWrapper>
  );
}

export default FilterTabs;
