export const AvailableStyles = {
  AUSTIN: "austin",
  CALGARY: "calgary",
};

const austin = {
  isNameOn: true,
  fieldOne: {
    id: "FF2Vrog",
    enabled: true,
  },
  fieldTwo: {
    id: "qADc-c1x",
    enabled: true,
  },
  cardBackground: "image",
  imageSize: 76,
};

const calgary = {
  isNameOn: true,
  fieldOne: {
    id: "FF2Vrog",
    enabled: true,
  },
  fieldTwo: {
    id: "qADc-c1x",
    enabled: true,
  },
  fieldThree: {
    id: "qBCd-c2y",
    enabled: false,
  },
  fieldFour: {
    id: "xndy7Wk",
    enabled: false,
  },
};

export const defaultSettings = {
  selectedStyle: AvailableStyles.AUSTIN,

  austin,
  calgary,

  borderRadius: 3,
};

export const migrateCardBackground = (savedSettings) => {
  if (savedSettings.austin.cardBackground === "color") {
    return {
      ...savedSettings,
      austin: {
        ...savedSettings.austin,
        cardBackground: "image",
      },
    };
  }
  return savedSettings;
};
