import create from "zustand";

export const usePanelsStore = create((set, get) => ({
  panels: [],
  setPanels: (v) => set((state) => ({ panels: v })),

  hasInitEmployeeDatabase: false,
  setHasInitEmployeeDatabase: (v) => set((state) => ({ hasInitEmployeeDatabase: v })),

  isSidebarOpen: false,
  setIsSidebarOpen: (v) => set((state) => ({ isSidebarOpen: v })),

  editingPanel: undefined,
  setEditingPanel: (v) => set((state) => ({ editingPanel: v })),

  isEditingAppIcon: false,
  setIsEditingAppIcon: (v) => set((state) => ({ isEditingAppIcon: v })),

  isEditingAppAddIcon: false,
  setIsEditingAppAddIcon: (v) => set((state) => ({ isEditingAppAddIcon: v })),

  exitEditModalVisible: false,
  setExitEditModalVisible: (v) => set((state) => ({ exitEditModalVisible: v })),

  isInMacro: false,
  setIsInMacro: (v) => set((state) => ({ isInMacro: v })),
}));
