import React, { useContext, useMemo } from "react";
import SidebarTransition from "../../../../Shared/Components/SidebarTransition";
import { useSidebarStore } from "./space-directory-store";
import SidebarTabSelector, { SidebarTabs } from "../Shared/SidebarTabSelector";
import HeaderSidebarDesign from "../Shared/Header/customization/HeaderSidebarDesign";
import { Pages } from "../../pages";
import { SpaceDirectoryAPIContext } from "./context";
import { useHeaderStore } from "../../store/header-store";
import { useHeaderChangesStore } from "../Shared/Header/customization/header-changes-store";

function Sidebar() {
  const { isSidebarOpen, setIsSidebarOpen, selectedSidebar, setSelectedSidebar } = useSidebarStore((state) => ({
    isSidebarOpen: state.isSidebarOpen,
    setIsSidebarOpen: state.setIsSidebarOpen,
    selectedSidebar: state.selectedSidebar,
    setSelectedSidebar: state.setSelectedSidebar,
  }));

  const { headerData, isEditingHeader } = useHeaderStore((state) => ({
    headerData: state.headerData,
    isEditingHeader: state.isEditingHeader,
  }));

  const { handleSaveHeaderSettings } = useContext(SpaceDirectoryAPIContext);

  const { hasHeaderChanges } = useHeaderChangesStore((state) => ({
    hasHeaderChanges: state.hasHeaderChanges,
  }));

  const primaryColor = "#0065ff";
  const primaryContrastColor = "#ffffff";

  const sidebarTitle = useMemo(
    () =>
      ({
        [SidebarTabs.NOT_SELECTED]: "Customize Space Station",
        [SidebarTabs.HEADER_DESIGN]: "Edit header design",
      })[selectedSidebar],
    [selectedSidebar],
  );

  return (
    <SidebarTransition
      isOpen={isSidebarOpen}
      close={() => {
        setIsSidebarOpen(false);
        setSelectedSidebar(undefined);
      }}
      width={360}
      title={sidebarTitle}
      customScrollbarHeight="calc(100% - 120px)"
      useArrowBack={!!selectedSidebar}
      onArrowBackClick={() => setSelectedSidebar(undefined)}
      disabledClickOutside={isEditingHeader}
      hasChanges={hasHeaderChanges}
      onSave={handleSaveHeaderSettings}
    >
      {!selectedSidebar && <SidebarTabSelector setSelectedSidebarTab={setSelectedSidebar} />}

      {selectedSidebar === SidebarTabs.HEADER_DESIGN && (
        <HeaderSidebarDesign
          header={headerData[Pages.SPACE]}
          saveHeaderSettings={handleSaveHeaderSettings}
          pageToUpdate={Pages.SPACE}
          closePanel={() => setIsSidebarOpen(false)}
          primaryColor={primaryColor}
          primaryContrastColor={primaryContrastColor}
        />
      )}
    </SidebarTransition>
  );
}

export default Sidebar;
