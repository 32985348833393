import merge from "lodash.merge";

// eslint-disable-next-line import/no-anonymous-default-export
const SELECT_STYLES = {
  control: (provided) => ({
    ...provided,
    backgroundColor: "#F4F5F7",
    borderColor: "transparent",
    minWidth: "120px",
    fontSize: "14px",
    borderRadius: "3px",
    boxShadow: null,
  }),
  menu: (provided) => ({
    ...provided,
    outline: "none",
    zIndex: "5",
  }),
  option: (provided) => ({
    ...provided,
    height: "28px",
    fontSize: "14px",
    boxSizing: "border-box",
    boxShadow: null,
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: "0 6px",
  }),
  input: (provided) => ({
    ...provided,
    margin: "0px",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
};

export const selectStylesWithOverrides = (overrides) => {
  return merge(SELECT_STYLES, overrides);
};
export default SELECT_STYLES;
