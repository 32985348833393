import React from "react";
import ArrowRightIcon from "@atlaskit/icon/glyph/arrow-right";
import styled from "styled-components";
import { MediumLine } from "./styled";

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  column-gap: 14px;
`;

const CardWrapper = styled.div`
  border-radius: 4px;
  border: 0.5px solid #d8dce3;
  background: #fff;
  box-shadow: 0px 0.5473684072494507px 3.284210443496704px 0px rgba(68, 68, 68, 0.12);
  padding: 20px 16px;

  display: flex;
  flex-direction: column;
`;

const IconContainer = styled.div`
  display: grid;
  place-content: center;
  width: 36px;
  height: 36px;
  border-radius: 3px;
  background-color: ${({ bgClr }) => bgClr};
`;

const SmallIcon = styled.div`
  display: grid;
  place-content: center;
  width: 24px;
  height: 24px;
  align-self: flex-end;
  margin-top: 8px;
`;

const Icon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
    <g id="folder_open">
      <mask
        id="mask0_3749_103115"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="20"
        height="20"
      >
        <rect id="Bounding box" width="20" height="20" fill="white" />
      </mask>
      <g mask="url(#mask0_3749_103115)">
        <path
          id="folder_open_2"
          d="M3.33219 18.3703C2.87386 18.3703 2.4815 18.2044 2.15511 17.8725C1.82872 17.5406 1.66553 17.1416 1.66553 16.6755V6.50659C1.66553 6.04051 1.82872 5.64152 2.15511 5.30962C2.4815 4.97772 2.87386 4.81177 3.33219 4.81177H8.33219L9.99886 6.50659H16.6655C17.1239 6.50659 17.5162 6.67254 17.8426 7.00444C18.169 7.33634 18.3322 7.73533 18.3322 8.20141H3.33219V16.6755L5.33219 9.89623H19.5822L17.4364 17.1628C17.3253 17.53 17.1204 17.823 16.8218 18.042C16.5232 18.2609 16.1933 18.3703 15.8322 18.3703H3.33219Z"
          fill="white"
        />
      </g>
    </g>
  </svg>
);

function IconCards() {
  return (
    <Grid>
      <CardWrapper>
        <IconContainer bgClr="#00A59B">
          <Icon />
        </IconContainer>

        <MediumLine width={72} bg="#E4E4E4" style={{ marginTop: "15px" }} />
        <MediumLine width={64} bg="#E4E4E4" style={{ marginTop: "8px" }} />

        <SmallIcon>
          <ArrowRightIcon primaryColor="#00A59B" />
        </SmallIcon>
      </CardWrapper>

      <CardWrapper>
        <IconContainer bgClr="#FF9458">
          <Icon />
        </IconContainer>

        <MediumLine width={72} bg="#E4E4E4" style={{ marginTop: "15px" }} />
        <MediumLine width={64} bg="#E4E4E4" style={{ marginTop: "8px" }} />

        <SmallIcon>
          <ArrowRightIcon primaryColor="#FF9458" />
        </SmallIcon>
      </CardWrapper>

      <CardWrapper>
        <IconContainer bgClr="#FF991F">
          <Icon />
        </IconContainer>

        <MediumLine width={72} bg="#E4E4E4" style={{ marginTop: "15px" }} />
        <MediumLine width={64} bg="#E4E4E4" style={{ marginTop: "8px" }} />

        <SmallIcon>
          <ArrowRightIcon primaryColor="#FF991F" />
        </SmallIcon>
      </CardWrapper>
    </Grid>
  );
}

export default IconCards;
