import React from "react";
import CheckIcon from "@atlaskit/icon/glyph/check";
import styled from "styled-components";
import { useCorporateIdentityStore } from "../Settings/General/BrandAndColors/corporate-identity-store";
import { COLOR_TYPES } from "../Settings/General/BrandAndColors/color-types";

export const LinkItem = styled.div`
  padding: 8px 20px 8px 8px;

  display: flex;
  align-items: center;
  column-gap: 4px;
`;

export const Link = styled.span`
  cursor: pointer;

  color: ${({ primaryColor }) => primaryColor || "#0c66e4"};
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
`;

export const TitleDivider = styled.div`
  color: ${({ theme }) => theme.shared.label.filter};
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  margin-top: 3px;
  line-height: normal;
  margin-bottom: 20px;
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
`;

function ListingFilters({ filters, isFilterSelected, handleClickOnItem = () => {}, title }) {
  const colors = useCorporateIdentityStore((state) => state.colors);
  const primaryColor = colors[COLOR_TYPES.PRIMARY];

  return (
    <>
      {!!filters?.length && (
        <>
          {!!title && <TitleDivider>{title}</TitleDivider>}

          <List>
            {filters.map((filter, idx) => (
              <LinkItem key={idx}>
                {isFilterSelected(filter.id || filter.value) && <CheckIcon primaryColor={primaryColor || "#0c66e4"} />}
                <Link primaryColor={primaryColor} onClick={() => handleClickOnItem(filter.id || filter.value)}>
                  {filter.name || filter.value}
                </Link>
              </LinkItem>
            ))}
          </List>
        </>
      )}
    </>
  );
}

export default ListingFilters;
