import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import styled, { useTheme } from "styled-components";
import Select from "@atlaskit/select";
import { DatePicker } from "@atlaskit/datetime-picker";
import ArrowIcon from "@atlaskit/icon/glyph/arrow-right";
import { customDatePickerStyles } from "../../../../Collections/selected/Filter/styled";
import { useCorporateIdentityStore } from "../../Settings/General/BrandAndColors/corporate-identity-store";
import { COLOR_TYPES } from "../../Settings/General/BrandAndColors/color-types";
import { MODIFIED_SELECT_STYLES } from "../../Dashboard/filter/CustomSelectStyles";
import { FilterLabel } from "../../../styled/pages";

const Range = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;

  & > div {
    flex: 1;
  }
`;

const DeprecatedPeriods = [{ label: "This week", value: "THIS_WEEK" }];

const PeriodOptions = [
  { label: "Next 7 days", value: "NEXT_WEEK" },
  { label: "Next 14 days", value: "NEXT_TWO_WEEKS" },
  { label: "Next 30 days", value: "NEXT_30_DAYS" },
  { label: "Next 90 days", value: "NEXT_THREE_MONTH" },
  { label: "This month", value: "THIS_MONTH" },
  { label: "Next month", value: "NEXT_MONTH" },
];

function Date({ value, onChange, field }) {
  const filter = value || { range: [] };
  const [start, end] = filter.range;

  const [selectKey, setSelectKey] = useState(Math.random().toString(36).substring(7));

  const theme = useTheme();
  const colors = useCorporateIdentityStore((state) => state.colors);

  const primaryColor = colors[COLOR_TYPES.PRIMARY] || "#0065ff";

  useEffect(() => {
    window.AP.events.on("restore-filters-people-base", () => {
      setSelectKey(Math.random().toString(36).substring(7));
    });
  }, []);

  return (
    <div>
      {!field.recurring ? (
        <div style={{ margin: "28px 0px" }}>
          <FilterLabel style={{ textTransform: "uppercase" }}>{field.title}</FilterLabel>
          <Range>
            <DatePicker
              id="filter-date-from"
              placeholder="From date"
              dateFormat="DD/MM/YYYY"
              maxDate={end ? dayjs.unix(end).format("YYYY-MM-DD") : ""}
              value={start ? dayjs.unix(start).format("YYYY-MM-DD") : ""}
              onChange={(date) => {
                const timestamp = date && date.length ? dayjs(date).unix() : undefined;
                onChange({ ...filter, range: [timestamp, end] });
              }}
              selectProps={{ styles: customDatePickerStyles(primaryColor, theme) }}
            />
            <ArrowIcon size="small" />
            <DatePicker
              id="filter-date-to"
              placeholder="To date"
              dateFormat="DD/MM/YYYY"
              minDate={start ? dayjs.unix(start).format("YYYY-MM-DD") : ""}
              value={end ? dayjs.unix(end).format("YYYY-MM-DD") : ""}
              onChange={(date) => {
                const timestamp = date && date.length ? dayjs(date).unix() : undefined;
                onChange({ ...filter, range: [start, timestamp] });
              }}
              selectProps={{ styles: customDatePickerStyles(primaryColor, theme) }}
            />
          </Range>
        </div>
      ) : (
        <Select
          isClearable
          placeholder={field.title}
          key={selectKey}
          value={[...PeriodOptions, ...DeprecatedPeriods].find((opt) => opt.value === filter.period)}
          openMenuOnFocus
          menuPlacement="bottom"
          styles={MODIFIED_SELECT_STYLES}
          maxMenuHeight={200}
          controlShouldRenderValue={true}
          onChange={(e) => {
            onChange({ ...filter, period: e?.value });
          }}
          options={PeriodOptions}
        />
      )}
    </div>
  );
}

export default Date;
