import React from "react";
import styled from "styled-components";
import Spinner from "@atlaskit/spinner";
import InfiniteScroll from "react-infinite-scroll-component";
import { SpinnerContainer } from "../../Components/Hub/styled/pages";

export const SpinnerContainerFixed = styled.div`
  position: fixed;
  bottom: 90px;
  left: 0;
  right: 0;
  display: grid;
  place-content: center;
`;

const InfiniteScroller = ({
  dataLength,
  fetchNextPage,
  hasNextPage,
  hasFixedSpinner = false,
  scrollThreshold = undefined,
  scrollableTargetId = "hub-overflow-container",
  endMessage = "All caught up!",
  children,
}) => (
  <InfiniteScroll
    dataLength={dataLength}
    next={fetchNextPage}
    hasMore={hasNextPage}
    scrollableTarget={scrollableTargetId}
    scrollThreshold={scrollThreshold}
    loader={
      hasFixedSpinner ? (
        <SpinnerContainerFixed>
          <Spinner size="large" />
        </SpinnerContainerFixed>
      ) : (
        <SpinnerContainer className="infinite-scroll-spinner">
          <Spinner size="large" />
        </SpinnerContainer>
      )
    }
    endMessage={
      <p style={{ color: "#172b4d", textAlign: "center" }}>
        <b>{endMessage}</b>
      </p>
    }
  >
    {children}
  </InfiniteScroll>
);

export default InfiniteScroller;
