import TitleWithBack from "../../../../../Shared/Components/TitleWithBack";
import { Pages } from "../../../pages";
import { usePageStore } from "../../../page-store";
import styled from "styled-components";
import Tabs, { TabList, Tab, TabPanel } from "@atlaskit/tabs";

import Admins from "./Admins/Admins";
import BrandAndColors from "./BrandAndColors/BrandAndColors";
import UnderConstruction from "./UnderConstruction/UnderConstruction";
import FeaturesPage from "./Features/FeaturesPage";
import { useState, useEffect } from "react";

import { SectionKeys, SectionPagesIDs } from "../sections";

const Wrapper = styled.div`
  padding: 35px 30px;
  height: calc(100% - 30px);
  width: calc(100% - 5px);
  margin: auto;
  overflow-y: auto;
  padding-left: 0px;
  padding-right: 0px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  padding-left: 30px;
  padding-right: 30px;
`;

const Divider = styled.div`
  width: 100%;
  border-bottom: 2px solid ${({ theme }) => theme.shared.settings.titleDivider};
`;

const Content = styled.div`
  margin-top: 30px;
  padding-left: 30px;
  padding-right: 30px;
`;

const TabListWrapper = styled.div`
  & > div > div {
    font-size: 14px;
  }
`;

const TabPanelWrapper = styled.div`
  padding-top: 20px;
  & > div {
    box-shadow: none !important;
  }
`;

export const Panel = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;

  width: 628px;
  height: fit-content;

  border-radius: 3px;
  background-color: ${({ theme }) => theme.shared.settings.general.panelBackground};
  box-shadow:
    0px 0px 1px 0px rgba(9, 30, 66, 0.31),
    0px 1px 3px 0px rgba(9, 30, 66, 0.25);

  padding-top: 20px;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 35px;
`;

export const SidePanel = styled.div`
  display: block;
  margin-left: 32px;

  width: unset;
  height: fit-content;

  background-color: ${({ theme }) => theme.shared.settings.general.panelBackground};
  border-radius: 3px;
  box-shadow:
    0px 0px 1px 0px rgba(9, 30, 66, 0.31),
    0px 1px 3px 0px rgba(9, 30, 66, 0.25);

  margin-top: 24px;
  padding-bottom: 35px;
  flex-shrink: 1;
`;

export const SidePanelHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: auto;
  height: 32px;
  color: ${({ theme }) => theme.shared.settings.general.panelHeaderColor};
  border-bottom: 1px solid ${({ theme }) => theme.shared.settings.general.panelBorderBottom};
  padding-top: 20px;
  padding-bottom: 4px;
  margin-right: 25px;
  margin-left: 25px;
`;
export const PanelHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 32px;
  color: ${({ theme }) => theme.shared.settings.general.panelHeaderColor};
  border-bottom: 1px solid ${({ theme }) => theme.shared.settings.general.panelBorderBottom};
  padding-bottom: 4px;
`;

const TabNames = {
  COSMOS_ADMINS: "Cosmos Admins",
  FEATURES: "Features",
  BRAND_AND_COLORS: "Brand and Colors",
  UNDER_CONSTRUCTION: "Under Construction",
};

const TabComponents = {
  COSMOS_ADMINS: Admins,
  FEATURES: FeaturesPage,
  BRAND_AND_COLORS: BrandAndColors,
  UNDER_CONSTRUCTION: UnderConstruction,
};

function General() {
  const [selectedTab, setSelectedTab] = useState(0);
  const setSelectedPage = usePageStore((state) => state.setSelectedPage);

  const TAB_VALUES = {
    [`${SectionKeys.CONFIGURATION}-${SectionPagesIDs.ADMINS}`]: 0,
    [`${SectionKeys.CONFIGURATION}-${SectionPagesIDs.LAYOUT}`]: 1,
    [`${SectionKeys.INTRANET}-${SectionPagesIDs.CORPORATE_IDENTITY}`]: 2,
    [`${SectionKeys.CONFIGURATION}-${SectionPagesIDs.UNDER_CONSTRUCTION}`]: 3,

    [`${SectionKeys.CONFIGURATION}-${SectionPagesIDs.GENERAL_SETTINGS}`]: 0,
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const page = params.get("cosmosPage").replace("settings-", "");

    if (page) {
      setSelectedTab(TAB_VALUES[page] || 0);
    }
  }, [window]);

  return (
    <Wrapper>
      <Header>
        <TitleWithBack
          handleOnClick={() => setSelectedPage(Pages.SETTINGS)}
          title="General Settings"
          description={"Customize and personalize your intranet experience."}
        />
      </Header>

      <Divider />

      <Content>
        <Tabs id="default" onChange={(index) => setSelectedTab(index)} selected={selectedTab}>
          <TabListWrapper>
            <TabList>
              {Object.values(TabNames).map((value) => {
                return <Tab key={value}>{value}</Tab>;
              })}
            </TabList>
          </TabListWrapper>

          <TabPanelWrapper>
            {Object.values(TabComponents).map((Component, index) => (
              <TabPanel key={index}>{selectedTab === index ? <Component /> : null}</TabPanel>
            ))}
          </TabPanelWrapper>
        </Tabs>
      </Content>
    </Wrapper>
  );
}

export default General;
