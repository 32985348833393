import React, { useEffect, useState } from "react";

import Toggle from "@atlaskit/toggle";
import SectionMessage from "@atlaskit/section-message";
import CheckCircleIcon from "@atlaskit/icon/glyph/check-circle";
import CrossCircleIcon from "@atlaskit/icon/glyph/cross-circle";

import {
  ConfigurationItem,
  ConfigurationItemData,
  ConfigurationItemLabel,
  ConfigurationItemLink,
  ContentDescription,
  ContentHeader,
  ContentPanel,
  ContentSectionToggleContainer,
  ContentTitle,
  TabContainer,
  ToggleContainerDescription,
  ToggleContainerHeading,
  ToggleContainerTitle,
} from "../../styles";
import { useCosmosAppStore } from "../../cosmos-app-store";
import { CONFIGURATION_DATA, COSMOS_APP_KEY } from "../../defaults";
import { token } from "@atlaskit/tokens";
import { useDatabaseInitialization } from "../../../../../../../Shared/Hooks/useDatabaseInitialization";
import { useWidgetSettings } from "../../../../../../../Shared/Hooks/useWidgetSettings";
import { Pages } from "../../../../../pages";

const PublishUnpublish = ({ isLoadingCosmosAppSettings }) => {
  const [configuration, setConfiguration] = useState(CONFIGURATION_DATA);

  const { cosmosAppSettings, updateCosmosAppSettings } = useCosmosAppStore((state) => ({
    cosmosAppSettings: state.cosmosAppSettings,
    updateCosmosAppSettings: state.updateCosmosAppSettings,
  }));

  const { isDatabaseLoading, isDatabaseInitialized } = useDatabaseInitialization();
  const { isWidgetSettingsLoading, widgetSettingsData } = useWidgetSettings();

  useEffect(() => {
    if (!isDatabaseLoading) {
      setConfiguration((previousState) =>
        previousState.map((item) => {
          if (item.value === COSMOS_APP_KEY.DATABASE_SYNC) {
            return { ...item, enabled: isDatabaseInitialized };
          }

          return item;
        }),
      );
    }
  }, [isDatabaseLoading]);

  useEffect(() => {
    if (!isWidgetSettingsLoading && widgetSettingsData.exists) {
      const {
        widgetSettings: { id, ...widgets },
      } = widgetSettingsData;

      const hasSelectedField = Object.values(widgets).every((widget) => "selectedField" in widget);

      setConfiguration((previousState) =>
        previousState.map((item) => {
          if (item.value === COSMOS_APP_KEY.PEOPLE_WIDGETS) {
            return { ...item, enabled: hasSelectedField };
          }

          return item;
        }),
      );
    }
  }, [isWidgetSettingsLoading]);

  useEffect(() => {
    if (!isLoadingCosmosAppSettings) {
      setConfiguration((previousState) =>
        previousState.map((item) => {
          if (item.value === COSMOS_APP_KEY.KEY_PROFILE_DETAILS) {
            return { ...item, enabled: !!cosmosAppSettings?.people?.databaseField };
          }

          if (item.value === COSMOS_APP_KEY.NEWS_NAVIGATION) {
            return { ...item, enabled: !!cosmosAppSettings?.news?.source };
          }

          if (item.value === COSMOS_APP_KEY.BRAND_COLOR) {
            return { ...item, enabled: !!cosmosAppSettings?.brand?.color };
          }

          return item;
        }),
      );
    }
  }, [isLoadingCosmosAppSettings]);

  const canPublish = Object.values(configuration).every(({ enabled }) => enabled);

  return (
    <TabContainer style={{ marginTop: "24px", display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gap: "32px" }}>
      <div style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
        <ContentSectionToggleContainer>
          <ToggleContainerHeading>
            <ToggleContainerTitle>Published</ToggleContainerTitle>
            <Toggle
              isDisabled={!canPublish || isLoadingCosmosAppSettings}
              isChecked={cosmosAppSettings?.publishUnpublish?.published}
              onChange={() =>
                updateCosmosAppSettings(COSMOS_APP_KEY.PUBLISH_UNPUBLISH, {
                  ...cosmosAppSettings?.publishUnpublish,
                  published: !cosmosAppSettings?.publishUnpublish?.published,
                })
              }
            />
          </ToggleContainerHeading>
          <ToggleContainerDescription>
            {canPublish &&
              cosmosAppSettings.publishUnpublish.published &&
              !isLoadingCosmosAppSettings &&
              "App is published and now accessible to all employees."}

            {canPublish &&
              !cosmosAppSettings.publishUnpublish.published &&
              !isLoadingCosmosAppSettings &&
              "Accessible and fully functional for users once they login with Atlassian account."}

            {!canPublish &&
              !isLoadingCosmosAppSettings &&
              "Some requirements are missing. Please complete all necessary configurations to publish the app."}
          </ToggleContainerDescription>
        </ContentSectionToggleContainer>

        <ContentPanel style={{ width: "auto", gap: "13px" }}>
          <ContentHeader>
            <ContentTitle>Get ready to publish</ContentTitle>
          </ContentHeader>
          <ContentDescription>
            Follow these steps to ensure everything’s set for launch. Complete each task to make sure your content meets
            all the requirements for a seamless publishing experience.
          </ContentDescription>
          {configuration.map(({ id, label, enabled, sectionKey, sectionId }) => (
            <ConfigurationItem key={id}>
              <ConfigurationItemData>
                {enabled ? (
                  <CheckCircleIcon primaryColor={token("color.icon.success")} />
                ) : (
                  <CrossCircleIcon primaryColor={token("color.icon.danger")} />
                )}

                <ConfigurationItemLabel>{label}</ConfigurationItemLabel>
              </ConfigurationItemData>
              {!enabled && (
                <ConfigurationItemLink
                  onClick={() =>
                    window.open(
                      `${window.AP._hostOrigin}/wiki/plugins/servlet/ac/com.caelor.confluence.cloud.cosmos/caelor-cosmos-portal?ac.cosmosPage=${Pages.SETTINGS}-${sectionKey}-${sectionId}`,
                      "_top",
                    )
                  }
                >
                  Configure
                </ConfigurationItemLink>
              )}
            </ConfigurationItem>
          ))}
        </ContentPanel>
      </div>

      <div style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
        <SectionMessage title="Publish/Unpublish">
          <p style={{ margin: 0, fontSize: "14px" }}>
            When published, the app is accessible to users. When unpublished, the app remains configurable by admins,
            but employees attempting to log in will be blocked and receive a message indicating the app isn’t available
            for this instance.
          </p>
        </SectionMessage>
        <SectionMessage title="Required Checks" appearance="warning">
          <p style={{ margin: 0, fontSize: "14px" }}>
            Before publishing, ensure all required checks are completed. This includes setting up key configurations
            news settings, branding and main profile field to guarantee the best experience for all users. The app
            should only be made available once these essentials are properly set up.
          </p>
        </SectionMessage>
      </div>
    </TabContainer>
  );
};

export default PublishUnpublish;
