import React from "react";

import Spinner from "@atlaskit/spinner";

const Loading = () => {
  return (
    <div style={{ display: "grid", placeContent: "center", height: "100%" }}>
      <Spinner size="xlarge" />
    </div>
  );
};

export default Loading;
