import create from "zustand";

export const useCollectionsStore = create((set, get) => ({
  collections: [],
  searchValue: "",
  isCreatingCollection: false,
  selectedCollection: undefined,
  databaseFields: [],
  setDatabaseFields: (databaseFields) => set((state) => ({ databaseFields })),
  setCollections: (collections) => set((state) => ({ collections })),
  setSearchValue: (searchValue) => set((state) => ({ searchValue })),
  setIsCreatingCollection: (v) => set((state) => ({ isCreatingCollection: v })),
  setSelectedCollection: (v) => set((state) => ({ selectedCollection: v })),
  addCollection: (newCollection) => {
    const newCollections = [newCollection, ...get().collections];
    return set((state) => ({ collections: newCollections }));
  },
  reset: () => set((state) => ({ selectedCollection: undefined })),
}));

export const useCreateCollectionStore = create((set, get) => ({
  isEditingIcon: false,
  setIsEditingIcon: (v) => set((state) => ({ isEditingIcon: v })),
  filters: [],
  setFilters: (v) => set((state) => ({ filters: v })),
  resetFilters: () => set((state) => ({ filters: [] })),
  addFilter: (filter) => {
    const newFilters = [...get().filters];
    newFilters.push(filter);
    return set((state) => ({ filters: newFilters }));
  },
  updateFilter: (filter) => {
    const newFilters = [...get().filters];
    const indexOfUpdatedFilter = newFilters.findIndex((f) => f.id === filter.id);
    newFilters[indexOfUpdatedFilter] = filter;
    return set((state) => ({ filters: newFilters }));
  },
  removeFilter: (filterId) => {
    return set((state) => ({ filters: [...get().filters.filter((f) => f.id !== filterId)] }));
  },
}));

export const useSingleCollectionStore = create((set, get) => ({
  isInit: false,
  setIsInit: (v) => set((state) => ({ isInit: v })),
  records: [],
  setRecords: (v) => set((state) => ({ records: v })),
  resetRecords: () => set((state) => ({ records: [] })),
  addRecords: (records) => {
    const allRecords = [...get().records, ...records];
    return set((state) => ({ records: allRecords }));
  },
  filters: [],
  setFilters: (v) => set((state) => ({ filters: v })),
  resetFilters: () => set((state) => ({ filters: [] })),
  addFilter: (filter) => {
    const newFilters = [...get().filters];
    newFilters.push(filter);
    return set((state) => ({ filters: newFilters }));
  },
  updateFilter: (filter, returnNewState = false) => {
    const newFilters = [...get().filters];
    const filterIndex = newFilters.findIndex(({ id }) => id === filter.id);
    const targetIndex = filterIndex >= 0 ? filterIndex : newFilters.length;

    newFilters[targetIndex] = filter;
    const result = set((state) => ({ filters: newFilters }));
    if(returnNewState) {
      return newFilters;
    } else {
      return result;
    }
  },
  removeFilter: (filterId, returnNewState = false) => {
    const newFilters = [...get().filters.filter((f) => f.id !== filterId)];
    const result = set((state) => ({ filters: [...get().filters.filter((f) => f.id !== filterId)] }));
    if(returnNewState) {
      return newFilters;
    } else {
      return result;
    }
  },
  reset: () => set({ isInit: false, records: [], filters: [] }),
}));
