import React, { useCallback } from "react";
import SimpleToggle from "../../../../Dashboard/filter/SimpleToggle";
import AlignmentButtons from "../../../../Dashboard/filter/AlignmentButtons";
import { PropertyTypes } from "./propertyTypes";
import { Divider, FieldContainer, Label } from "../../../../Dashboard/filter/styled";
import { PanelKeys } from "../../../../Dashboard/panels/panelTypes";
import SizeButtons from "../../../../../../../Shared/Components/SizeButtons";

function Design({ panel, updatePanelDataProperty }) {
  const {
    id,
    key: cardType,
    data: { displayTitle, displayBox, imageHeight, alignment },
  } = panel;

  const updatePanel = useCallback(
    (propertyKey, value) => {
      updatePanelDataProperty(id, propertyKey, value);
    },
    [id, updatePanelDataProperty],
  );

  const primaryColor = "#0065ff";
  const slicedPrimaryColor = primaryColor.length > 7 ? primaryColor.slice(0, -2) : primaryColor;

  return (
    <>
      <FieldContainer>
        <Label>Widget options</Label>
        <SimpleToggle
          label="Display title"
          value={displayTitle}
          setValue={(value) => updatePanel(PropertyTypes.DISPLAY_TITLE, value)}
          customStyles={{ padding: "10px 0px 8px 10px" }}
        />

        <SimpleToggle
          label="Display box"
          value={displayBox}
          setValue={(value) => updatePanel(PropertyTypes.DISPLAY_BOX, value)}
          customStyles={{ padding: "15px 0px 8px 10px" }}
        />
      </FieldContainer>

      <Divider style={{ margin: "10px 0px 5px 0px" }} />

      {cardType === PanelKeys.IMG_CARDS && (
        <SizeButtons
          label="Image height"
          customOptions={[
            { label: "S", value: 70 },
            { label: "M", value: 100 },
            { label: "L", value: 150 },
          ]}
          input={{
            value: imageHeight || 100,
            min: 40,
            max: 200,
          }}
          setValue={(value) => updatePanel(PropertyTypes.IMAGE_HEIGHT, value)}
        />
      )}

      <AlignmentButtons
        label="Alignment"
        value={alignment}
        setValue={(newAlignment) => updatePanel(PropertyTypes.ALIGNMENT, newAlignment)}
        primaryColor={slicedPrimaryColor}
      />
    </>
  );
}

export default Design;
