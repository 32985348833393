import React from "react";
import {
  Card,
  CardBackground,
  CardInfoContainer,
  CardLinkWrapper,
  FooterLabel,
  GridCenter,
  GridPeopleContainer,
  Image,
  Name,
  TitleLabel,
} from "../styles/people";
import { useTheme } from "styled-components";

const MainGridView = ({ data, people, createProfileLink, borderRadius, pagination }) => {
  const theme = useTheme();
  const { maxNumberOfItems } = pagination;

  const getBackground = (background) => {
    if (data.backgroundType === "image") return background;
    if (data.backgroundType === "none") return theme.global.background.surfaceOverlay;
  };

  return (
    <GridPeopleContainer numberOfItems={maxNumberOfItems}>
      {people.map((person) => (
        <CardLinkWrapper key={person.accountId} target="_top" href={createProfileLink(person.accountId)}>
          <Card borderRadius={borderRadius}>
            <CardBackground background={getBackground(person.background)} />
            <CardInfoContainer imageHeight={data.imageSize || 80}>
              <Image imageSize={data.imageSize || 80} src={person.icon} />
              <Name>{person.name}</Name>
              <TitleLabel>{person.titleValue}</TitleLabel>
              <GridCenter>
                <FooterLabel>{person.footerValue}</FooterLabel>
              </GridCenter>
            </CardInfoContainer>
          </Card>
        </CardLinkWrapper>
      ))}
    </GridPeopleContainer>
  );
};

export default MainGridView;
