import React, { Fragment } from "react";
import SpaceListCard, { ListHeader } from "../Cards/SpaceListCard";
import { SpaceCard, SpaceSimpleCard } from "../Cards";
import { ThreeColumnGrid, OneColumnGrid } from "../styled/space";
import { FilterTypes, ViewTypes } from "../types";

function Results({ data, selectedFilter, selectedView, currentUserId, setIsModalOpen, setUpdatingSpace }) {
  if (selectedView === ViewTypes.LIST) {
    return (
      <>
        <ListHeader />
        <OneColumnGrid>
          {data?.pages?.map((page, idx) => (
            <Fragment key={idx}>
              {page.results.map((space) => (
                <SpaceListCard
                  key={space.key}
                  spaceInfo={space}
                  setIsModalOpen={setIsModalOpen}
                  setUpdatingSpace={setUpdatingSpace}
                  currentUserId={currentUserId}
                />
              ))}
            </Fragment>
          ))}
        </OneColumnGrid>
      </>
    );
  }

  return (
    <>
      {selectedFilter !== FilterTypes.LATEST_ACTIVITY && selectedFilter !== FilterTypes.STAR_AND_WATCH ? (
        <ThreeColumnGrid>
          {data?.pages?.map((page, idx) => (
            <Fragment key={idx}>
              {page.results.map((space) => (
                <SpaceCard
                  key={space.key}
                  spaceInfo={space}
                  setIsModalOpen={setIsModalOpen}
                  setUpdatingSpace={setUpdatingSpace}
                  currentUserId={currentUserId}
                />
              ))}
            </Fragment>
          ))}
        </ThreeColumnGrid>
      ) : (
        <ThreeColumnGrid>
          {data.pages.map((page) => (
            <Fragment key={page}>
              {page.results.map((space) => (
                <SpaceSimpleCard hasActions key={space.key} spaceInfo={space} />
              ))}
            </Fragment>
          ))}
        </ThreeColumnGrid>
      )}
    </>
  );
}

export default Results;
