import React from "react";
import styled from "styled-components";

import EditorAlignLeftIcon from "@atlaskit/icon/glyph/editor/align-left";
import EditorAlignCenterIcon from "@atlaskit/icon/glyph/editor/align-center";
import EditorAlignRightIcon from "@atlaskit/icon/glyph/editor/align-right";

import { FieldContainer, Label } from "./styled";

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  column-gap: 15px;
`;

const Button = styled.button`
  all: unset;
  display: grid;
  place-content: center;
  padding: 8px 0px;
  border-radius: 3px;
  background-color: ${({ isActive, primaryColor, theme }) =>
    isActive ? `${primaryColor}1a` : theme.shared.form.button.background.inactive};
  transition: background-color 150ms linear;

  svg {
    color: ${({ isActive, primaryColor }) => (isActive ? primaryColor : "#42526E")} !important;
  }

  cursor: pointer;
`;

const IconContainer = styled.div`
  display: grid;
  place-content: center;
  width: 24px;
  height: 24px;
`;

function AlignmentButtons({ label = "", value, setValue, primaryColor = "#0065ff" }) {
  return (
    <FieldContainer>
      <Label>{label}</Label>

      <Wrapper>
        <Button primaryColor={primaryColor} onClick={() => setValue("left")} isActive={value === "left"}>
          <IconContainer>
            <EditorAlignLeftIcon />
          </IconContainer>
        </Button>

        <Button primaryColor={primaryColor} onClick={() => setValue("center")} isActive={value === "center"}>
          <IconContainer>
            <EditorAlignCenterIcon />
          </IconContainer>
        </Button>

        <Button primaryColor={primaryColor} onClick={() => setValue("right")} isActive={value === "right"}>
          <IconContainer>
            <EditorAlignRightIcon />
          </IconContainer>
        </Button>
      </Wrapper>
    </FieldContainer>
  );
}

export default AlignmentButtons;
