import React from "react";

import SelectWithCustomOptions from "../../../../ContentBuilder/Shared/Filter/SelectWithCustomOptions";

const FORMAT_OPTIONS = [
  {
    value: "integer",
    label: "Integer (1000)"
  },
  {
    value: "decimal",
    label: "Decimal (1,000.00)"
  },
  {
    value: "percent",
    label: "Percent (100.00%)"
  }
];

const NumberFormat = ({ format, onUpdate }) => (
  <>
    <SelectWithCustomOptions
      customContainerStyle={{ margin: 0 }}
      initialValue={format}
      options={FORMAT_OPTIONS}
      onUpdate={(format) => onUpdate(format)}
      label="Format"
    />
  </>
);

export default NumberFormat;
