import React from "react";
import styled from "styled-components";
import Textfield from "@atlaskit/textfield";
import EditorCloseIcon from "@atlaskit/icon/glyph/editor/close";
import { SocialTypes } from "./types";
import { Header, IconContainer } from "./styled";
import { useTheme } from "styled-components";
import { useChangesStore } from "../../../../track-changes-store";

const AvailableLink = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  color: ${({ theme }) => theme.shared.profile.socialLinkText};

  & > :first-child {
    max-width: 280px;
  }
`;

const Title = styled.span`
  max-width: 162px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Section = styled.div`
  margin: 10px 0 20px;
  font-weight: 500;
  font-size: 12px;
  color: ${({ theme }) => theme.shared.profile.sectionText};
`;

const NoFieldsMessage = ({ children, theme }) => (
  <div
    style={{
      textAlign: "center",
      color: theme.global.secondary,
      marginBottom: "30px",
    }}
  >
    {children}
  </div>
);

function SocialLinks({ fields, values, setUserData, close }) {
  const { socialLinks } = fields?.find(({ id }) => id === "user")?.profile || { socialLinks: [] };
  const setHasChanges = useChangesStore((state) => state.setHasChanges);

  const theme = useTheme();

  const changeValue = (key, link, isSocial) => {
    setUserData({
      ...(values || {}),
      socialLinks: {
        ...(values?.socialLinks || {}),
        [key]: link.trim().length ? { link, isSocial } : null,
      },
    });
    setHasChanges(true);
  };

  return (
    <>
      <Header>
        <Title>Edit social links</Title>

        <span role="none" style={{ cursor: "pointer", minWidth: "26px" }} onClick={close}>
          <EditorCloseIcon primaryColor={theme.shared.sidebar.iconColor} />
        </span>
      </Header>

      <Section>Available Social Media Links</Section>

      {socialLinks.length ? (
        socialLinks.map((key) => (
          <AvailableLink style={{ width: "100%" }} key={key}>
            <Textfield
              value={values?.socialLinks?.[key]?.link}
              placeholder={`Your ${SocialTypes[key]} link`}
              onChange={(e) => changeValue(key, e.target.value, true)}
              elemBeforeInput={
                <IconContainer style={{ margin: "0 5px 0 10px" }}>
                  <img src={`/images/social-links/${key}.svg`} alt={key} width="20" height="20" />
                </IconContainer>
              }
            />
          </AvailableLink>
        ))
      ) : (
        <NoFieldsMessage theme={theme}>
          You have no activated social links. Select available links or contact your Confluence admin if you miss any
          options.
        </NoFieldsMessage>
      )}
    </>
  );
}

export default SocialLinks;
