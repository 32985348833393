export const SELECT_STYLE = {
  control: (provided) => ({
    ...provided,
    fontSize: "14px",
    fontWeight: "400",
    color: "#42526E",
    backgroundColor: "rgba(9, 30, 66, 0.04)",
    borderColor: "rgba(9, 30, 66, 0.04)",
    borderRadius: "3px",
  }),
  singleValue: (provided) => ({ ...provided, fontWeight: "400" }),
  selectContainer: (provided) => ({ ...provided, fontSize: "14px" }),
  menu: (provided) => ({ ...provided, marginTop: "0px" }),
  option: (provided) => ({ ...provided, fontSize: "14px" }),
  placeholder: (provided) => ({ ...provided, fontWeight: 500 }),
  input: (provided) => ({ ...provided, fontSize: "14px" }),
  valueContainer: (provided) => ({
    ...provided,
    fontWeight: 600,
  }),
};

export const USER_SELECT = {
  container: (base) => ({
    ...base,
    width: "100%",
    marginBottom: "6px",
  }),
  control: (provided) => ({
    ...provided,
    backgroundColor: `inherit !important`,
    border: "none",
    width: "inherit",
    fontSize: "14px",
    borderRadius: "3px",
    boxShadow: null,
    height: "33px",
    minHeight: "33px",
  }),
  menu: (provided) => ({
    ...provided,
    outline: "none",
    zIndex: "5",
    width: "100%",
  }),
  option: (provided) => ({
    ...provided,
    height: "inherit",
    fontSize: "14px",
    boxSizing: "border-box",
    whiteSpace: "initial",
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: "0 6px",
  }),
  input: (provided) => ({
    ...provided,
    margin: "0px",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
};
