import { Box, Text } from "@atlaskit/primitives";
import React from "react";
import ReorderWrapper from "./reorder/ReorderWrapper";
import { useNavigationStore } from "../../../store";
import { PARENT_ENTRY_IDENTIFIER } from "../../../defaults";
import Structure from "./reorder/Structure";

function MenuEntries() {
  const structure = useNavigationStore((state) => state.structure);

  const parentEntries = structure?.filter((entry) => entry.parent === PARENT_ENTRY_IDENTIFIER);

  const nestedStructure = parentEntries?.reduce((acc, curr) => {
    const children = structure?.filter((entry) => entry.parent === curr.id);
    acc.push({ ...curr, children });
    return acc;
  }, []);

  const hasEntries = structure?.length > 0;

  return (
    <Box>
      <Box paddingBlockEnd="space.050">
        <Text color="color.text.subtlest" weight="medium" size="small">
          {hasEntries ? "Menu entries" : "Start navigating"}
        </Text>
      </Box>

      {hasEntries ? (
        <ReorderWrapper>
          <Structure nestedStructure={nestedStructure} />
        </ReorderWrapper>
      ) : (
        <Text color="color.text.subtle">Add your first entry and create a new navigation menu.</Text>
      )}
    </Box>
  );
}

export default MenuEntries;
