import React from "react";
import styled from "styled-components";
import Textfield from "@atlaskit/textfield";

const InputWrapper = styled.div`
  div:first-child {
    background: ${({ theme }) => theme.collections.searchBackground};
    border: 1px solid ${({ theme }) => theme.collections.searchBackground};
    border-radius: 5px;
    display: grid;
    max-width: 270px;
    display: grid;
    height: 34px;
    grid-template-columns: 36px calc(100% - 36px);
    justify-content: flex-start;
    align-items: center;

    svg {
      padding: 0px 8px;
    }

    .custom-apps-search {
      padding: 0px;
      height: 100%;

      &::placeholder {
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #6b778c;
      }
    }
  }
`;

const SearchIcon = () => (
  <svg width="20" height="21" viewBox="0 0 20 21" fill="none">
    <rect width="20" height="20" transform="translate(0 0.335938)" fill="white" fillOpacity="0.01" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.4765 14.2267C11.4957 14.9251 10.2958 15.3359 9 15.3359C5.68629 15.3359 3 12.6496 3 9.33594C3 6.02223 5.68629 3.33594 9 3.33594C12.3137 3.33594 15 6.02223 15 9.33594C15 10.6318 14.5892 11.8317 13.8907 12.8125L16.7071 15.6288C17.0976 16.0194 17.0976 16.6525 16.7071 17.043C16.3166 17.4336 15.6834 17.4336 15.2929 17.043L12.4765 14.2267ZM13 9.33594C13 11.5451 11.2091 13.3359 9 13.3359C6.79086 13.3359 5 11.5451 5 9.33594C5 7.1268 6.79086 5.33594 9 5.33594C11.2091 5.33594 13 7.1268 13 9.33594Z"
      fill="#6B778C"
    />
  </svg>
);

function SearchInput({ value, setValue, placeholder = "Search" }) {
  return (
    <InputWrapper>
      <Textfield
        placeholder={placeholder}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        elemBeforeInput={<SearchIcon />}
        className="custom-apps-search"
      />
    </InputWrapper>
  );
}

export default SearchInput;
