import React from "react";
import styled from "styled-components";
import { tabContent, TabKeys, tabOptions } from "./tabs-config";
import { SpotlightTarget } from "@atlaskit/onboarding";

const TabsContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 0;
  gap: 20px;
`;

const Tabs = styled.div`
  display: flex;
  gap: 8px;
`;

const Tab = styled.button`
  border: none;
  outline: none;
  background-color: ${({ isSelected, theme }) =>
    isSelected
      ? theme.shared.sidebar.profileTabs.background.active
      : theme.shared.sidebar.profileTabs.background.inactive};
  border-radius: 12px;
  color: ${({ isSelected, theme }) =>
    isSelected ? theme.shared.sidebar.profileTabs.text.active : theme.shared.sidebar.profileTabs.text.inactive};
  font-size: 12px;
  padding: 6px 15px;
  font-weight: 500;
  cursor: pointer;
  transition:
    background-color 0.2s ease,
    color 0.2s ease;

  &:hover {
    background-color: ${({ theme }) => theme.shared.sidebar.profileTabs.hover.background};
    color: ${({ theme }) => theme.shared.sidebar.profileTabs.hover.color};
  }
`;

const PanelContent = ({ tab, values = {}, fields = [], showOrgChartTab = false }) => {
  const { selectedTab, setSelectedTab } = tab;

  const filteredSelectedTab = showOrgChartTab ? selectedTab : TabKeys.PROFILE;

  const tabProps = {
    [TabKeys.PROFILE]: {
      values,
      fields,
    },
    [TabKeys.ORG_CHART]: {},
  };

  const TabComponent = tabContent[filteredSelectedTab].component;
  const isCurrentTabSpotlight = tabContent[filteredSelectedTab].spotlight.isEnabled;
  const currentTabSpotlightName = tabContent[filteredSelectedTab].spotlight.name;

  return (
    <TabsContainer>
      {showOrgChartTab && (
        <Tabs>
          {tabOptions.map(({ label, value }) => (
            <Tab onClick={() => setSelectedTab(value)} isSelected={value === selectedTab}>
              {label}
            </Tab>
          ))}
        </Tabs>
      )}
      {isCurrentTabSpotlight ? (
        <div>
          <SpotlightTarget name={currentTabSpotlightName}>
            <TabComponent {...tabProps[selectedTab]} />
          </SpotlightTarget>
        </div>
      ) : (
        <div>
          <TabComponent {...tabProps[selectedTab]} />
        </div>
      )}
    </TabsContainer>
  );
};

export default PanelContent;
