import React, { useEffect, useState } from "react";
import PageIcon from "@atlaskit/icon-object/glyph/page/16";
import BlogIcon from "@atlaskit/icon-object/glyph/blog/16";
import { ContentWrapper, Image, ImageContainer, ImagePlaceholder, InlineLink, Name, NameWrapper } from "../styled";
import { Label } from "../../../../../filter/styled";
import Actions from "./Actions";

function Content({ diagram, elementsToDisplay, content, isPositionedInSide }) {
  const [picture, setPicture] = useState("");
  const [loadingPicture, setLoadingPicture] = useState(false);

  const { title: contentTitle, type, link } = content;
  const { title, editorUrl, getPictureFromAttachments } = diagram;

  useEffect(() => {
    if (!picture && getPictureFromAttachments) {
      setLoadingPicture(true);
      getPictureFromAttachments()
        .then((rawImageUrl) => {
          if (rawImageUrl) {
            setPicture(`${window.AP._hostOrigin}/wiki${rawImageUrl}`);
          }
        })
        .finally(() => setLoadingPicture(false));
    }
  }, [picture, getPictureFromAttachments]);

  return (
    <ContentWrapper>
      <NameWrapper>
        <Name style={{ fontSize: isPositionedInSide ? "18px" : "20px" }}>{title}</Name>
        <Actions pictureUrl={picture} editorUrl={editorUrl} />
      </NameWrapper>

      {loadingPicture ? (
        <ImagePlaceholder />
      ) : (
        <ImageContainer>
          <Image
            src={picture}
            onClick={(e) => {
              e.preventDefault();
              window.open(editorUrl, "_blank");
            }}
          />
        </ImageContainer>
      )}

      {elementsToDisplay.includes("attachedto") && (
        <div>
          <Label>Attached to</Label>
          <InlineLink href={link} target="_blank" rel="noreferrer">
            {!isPositionedInSide && <>{type === "blogpost" ? <BlogIcon /> : <PageIcon />}&nbsp;</>}
            {contentTitle}
          </InlineLink>
        </div>
      )}
    </ContentWrapper>
  );
}

export default Content;
