import React, { useEffect, useRef, useState } from "react";
import { AsyncSelect } from "@atlaskit/select";

import { CustomDropdownIndicator, Dropdown } from "./CustomCheckboxSelectComponents";
import { atlassianRestService } from "../../../../../Service/AtlassianRestService";
import { useClickOutside } from "../../../../../Shared/Hooks/useClickOutside";
import Button from "@atlaskit/button/standard-button";

import ChevronDownIcon from "@atlaskit/icon/glyph/chevron-down";
import { useTheme } from "styled-components";
import { MODIFIED_SELECT_STYLES } from "../../Dashboard/filter/CustomSelectStyles";

const createOption = (label) => ({
  label,
  value: label,
  name: label,
});

function LabelsCheckboxSelect({ initialLabels, isMulti = true, placeholder = "Search...", options = [], onChange }) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedLabels, setSelectedLabels] = useState();
  const [selectKey, setSelectKey] = useState(Math.random().toString(36).substring(7));

  const labelsCount = selectedLabels?.length;
  const labelItem = selectedLabels?.map((item) => item.value)[0];

  const selectWrapperRef = useRef();
  const theme = useTheme();

  useClickOutside(selectWrapperRef, () => setIsOpen(false));

  useEffect(() => {
    if (initialLabels) {
      setSelectedLabels(initialLabels.map((l) => createOption(l)));
    } else {
      setSelectedLabels([]);
    }
  }, [initialLabels]);

  useEffect(() => {
    window.AP.events.on("restore-filters-newsroom", () => {
      setSelectKey(Math.random().toString(36).substring(7));
    });
  }, []);

  const loadOptions = async (query) => {
    const label = (await atlassianRestService.getBlogLabelsByName(query))?.label;

    if (label) {
      const option = createOption(label.name);
      if (!options.some((l) => l.name === option.name)) {
        options.push(option);
      }
    }

    return options;
  };

  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  return (
    <Dropdown
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      target={
        <Button
          iconAfter={
            <ChevronDownIcon primaryColor={!isOpen ? theme.shared.sidebar.iconColor : theme.global.primaryColor} />
          }
          onClick={() => setIsOpen((prev) => !prev)}
          isSelected={isOpen}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            height: "40px",
            textAlign: "left",
            alignItems: "center",

            fontWeight: labelsCount === 0 ? 400 : 600,

            padding: "0 8px 0 6px",
            backgroundColor: !isHovered ? theme.background : theme.global.name === "light" ? "#f7f8f9" : "#282E33",
            border: `2px solid ${
              !isHovered ? theme.shared.form.select.borderColor : theme.global.name === "light" ? "#8590A2" : "#738496"
            }`,
          }}
        >
          <span
            style={{
              color:
                labelsCount === 0
                  ? theme.shared.label.filter
                  : !isOpen
                  ? theme.shared.form.select.option
                  : theme.global.primaryColor,
            }}
          >
            {labelsCount > 1 ? `${labelItem} + ${labelsCount - 1} more` : labelsCount === 1 ? labelItem : "Labels"}
          </span>
        </Button>
      }
    >
      <AsyncSelect
        key={selectKey}
        id="labels-select"
        className="select-search"
        classNamePrefix="select-search"
        defaultOptions
        cacheOptions
        isMulti={isMulti}
        autoFocus
        placeholder="Search label..."
        isSearchable
        hideSelectedOptions={true}
        backspaceRemovesValue
        maxMenuHeight={200}
        styles={MODIFIED_SELECT_STYLES}
        value={selectedLabels}
        loadOptions={loadOptions}
        onChange={(e) => {
          if (!e) {
            onChange(isMulti ? [] : undefined);
            return;
          }
          onChange(isMulti ? e.map(({ value }) => value) : e?.value);
          return;
        }}
        controlShouldRenderValue
        components={{
          DropdownIndicator: CustomDropdownIndicator,
        }}
      />
    </Dropdown>
  );
}

export default LabelsCheckboxSelect;
