export const convertColorOpacity = (hexColor, theme) => {
  hexColor = hexColor.replace("#", "");

  let r = parseInt(hexColor.substring(0, 2), 16);
  let g = parseInt(hexColor.substring(2, 4), 16);
  let b = parseInt(hexColor.substring(4, 6), 16);

  let opacity = theme === "light" ? 0.2 : 0.15;

  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};
