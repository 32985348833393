/* eslint-disable no-param-reassign */
import React, { useEffect, useRef, useState } from "react";
import { Picker } from "emoji-mart";
import { useTheme } from "styled-components";

import "./emojiPicker.scss";

function SidebarEmojiPicker({ onSelect }) {
  const [isInit, setIsInit] = useState(false);
  const containerRef = useRef();

  const theme = useTheme();

  useEffect(() => {
    if (!isInit && containerRef?.current) {
      (async () => {
        try {
          const emojiDataRes = await fetch("https://cdn.jsdelivr.net/npm/@emoji-mart/data");
          const emojiData = await emojiDataRes.json();

          const pickerComponent = new Picker({
            theme: theme.global.name,
            data: emojiData || [],
            onEmojiSelect: (v) => onSelect(v),
          });

          containerRef.current.appendChild(pickerComponent);
          setIsInit(true);
        } catch (error) {}
      })();
    }
  }, [containerRef]);

  return (
    <div
      style={{ height: "calc(100% - 40px)", "--background": theme.navigation.emojiBackground }}
      id="emoji-picker-container"
      data-no-focus-lock
      ref={containerRef}
    ></div>
  );
}

export default SidebarEmojiPicker;
