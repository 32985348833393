import styled from "styled-components";

export const CardHeader = styled.div`
  height: 55px;
  border-radius: 8px 8px 0 0;
  ${({ backgroundUrl }) =>
    backgroundUrl &&
    `
    background: url("${backgroundUrl}")
  `}
`;

export const BigCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 1px 0px rgba(9, 30, 66, 0.31), 0px 1px 1px 0px rgba(9, 30, 66, 0.25);
  border-radius: 8px;
`;

export const BigCardBodyWrapper = styled.div`
  height: 60px;
  border-radius: 0 0 8px 8px;
  padding: 20px;
  position: relative;
`;

export const BigCardAvatarWrapper = styled.div`
  position: absolute;
  left: 20px;
  top: -24px;

  img {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid white;
  }
`;

export const BigCardSkeletonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
`;

export const SmallCardWrapper = styled.div`
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 2.4px 0px rgba(85, 85, 85, 0.25);
`;

export const SmallCardBodyWrapper = styled.div`
  height: 84px;
  border-radius: 0 0 8px 8px;
  position: relative;
  display: grid;
  place-items: center;
  margin-top: -30px;

  img {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    border: 2px solid white;
    object-fit: cover;
  }
`;

export const CardsGridWrapper = styled.div`
  display: grid;
  grid-template-columns: ${({ numberOfColumns }) => `repeat(${numberOfColumns}, 1fr)`};
  gap: 1rem;
`;

export const SmallCardSkeletonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 6px;
  margin-top: 8px;
`;

export const SmallCardFooter = styled.div`
  color: #0065ff;
  font-size: 10px;
  font-weight: 400;
  padding: 8px 0 8px 0;
  display: flex;
  justify-content: center;
`;

export const Skeleton = styled.div`
  width: ${({ width }) => `${width}`};
  height: ${({ height }) => `${height}`};
  border-radius: 8px;
  margin-top: ${({ marginTop }) => `${marginTop}`};
  background: ${({ backgroundColor }) => `${backgroundColor}`};
`;

export const Birthday = styled.div`
  color: #0065ff;
  font-size: 10px;
  font-weight: 400;
  padding: 8px 0 8px 0;
  text-align: center;
`;

export const ListWrapper = styled.div`
  padding: 22px 26px;
  display: flex;
  place-items: center;
`;

export const List = styled.div`
  display: flex;
  gap: 18px;
  place-items: center;
  flex: 1;

  img {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    object-fit: cover;
  }
`;

export const ContainerWrapper = styled.div`
  box-shadow: 0px 0px 1px 0px rgba(9, 30, 66, 0.31), 0px 1px 1px 0px rgba(9, 30, 66, 0.25);
  border-radius: 8px;
  display: flex;
  flex-direction: column;

  & > div:not(:last-child) {
    border-bottom: 1px solid #f3f3f5;
  }
`;

export const ListSkeletonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
