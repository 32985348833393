export const BG_COLOR_TYPES = {
  WHITE: "#ffffff",
  LIGHT_GREY: "#fafbfc",
};

export const HEADER_TYPES = {
  FULL_WIDTH: "full_width",
  GRID: "grid",
};

export const RADIUS_TYPES = {
  SMALL: "small",
  MEDIUM: "medium",
  LARGE: "large",
  CUSTOM: "custom",
};

export const RADIUS_VALUES = {
  [RADIUS_TYPES.SMALL]: "3px",
  [RADIUS_TYPES.MEDIUM]: "8px",
  [RADIUS_TYPES.LARGE]: "16px",
};
