import React from "react";
import styled from "styled-components";
import ChevronLeftIcon from "@atlaskit/icon/glyph/chevron-left";
import ChevronRightIcon from "@atlaskit/icon/glyph/chevron-right";
import { PanelsPaginationTypes } from "./panels-pagination-types";
import { useCorporateIdentityStore } from "../../Settings/General/BrandAndColors/corporate-identity-store";
import { COLOR_TYPES } from "../../Settings/General/BrandAndColors/color-types";

const Wrapper = styled.div`
  width: 100%;
  display: grid;
  place-content: center;
  margin-top: 20px;
`;

const Button = styled.button`
  all: unset;
  color: ${({ primaryColor }) => primaryColor};
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }
`;

export const ShowMore = styled.div`
  display: grid;
  place-content: center;
  text-align: center;
  cursor: pointer;

  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: ${({ primaryColor }) => primaryColor};

  &:hover {
    opacity: 0.9;
  }
`;

const ArrowsWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 12px;
`;

const Arrow = styled(ArrowsWrapper)`
  column-gap: 4px;

  opacity: ${({ isDisabled }) => (isDisabled ? "0.5" : "1")};

  cursor: pointer;
`;

const IconContainer = styled.div`
  display: grid;
  place-content: center;
  width: 16px;
  height: 16px;
`;

function Pagination({
  type,
  page,
  updatePage,
  maxPage,
  showMoreClicked,
  hasNextPage,
  onClick,
  infiniteQuery = false,
  onWrapperHoverCallback = () => {},
  customWrapperStyles = {},
  customPrimaryColor,
  minPage = 1,
}) {
  const { shouldFetchNext, fetchNextPage } = infiniteQuery;
  const colors = useCorporateIdentityStore((state) => state.colors);
  const primaryColor = colors ? colors[COLOR_TYPES.PRIMARY] : "#0065ff";

  if (
    type === PanelsPaginationTypes.NONE ||
    ((type === PanelsPaginationTypes.SHOW_MORE || type === PanelsPaginationTypes.INFINITE) && page === maxPage)
  )
    return null;

  return (
    <Wrapper
      style={{ ...customWrapperStyles }}
      onMouseEnter={(e) => {
        e.preventDefault();
        onWrapperHoverCallback(true);
      }}
      onMouseLeave={(e) => {
        e.preventDefault();
        onWrapperHoverCallback(false);
      }}
    >
      {type === PanelsPaginationTypes.SHOW_MORE && page < maxPage && (
        <Button primaryColor={primaryColor} onClick={() => updatePage(page + 1)}>
          Show more
        </Button>
      )}

      {type === PanelsPaginationTypes.INFINITE && (
        <ShowMore primaryColor={primaryColor} onClick={onClick}>
          {showMoreClicked && "View all"}
          {!showMoreClicked && hasNextPage && "Show more"}
        </ShowMore>
      )}

      {type === PanelsPaginationTypes.SHOW_MORE_LESS && (
        <ShowMore primaryColor={primaryColor} onClick={onClick}>
          {showMoreClicked && "Show Less"}
          {!showMoreClicked && "Show more"}
        </ShowMore>
      )}

      {type === PanelsPaginationTypes.ARROWS && (
        <ArrowsWrapper>
          <Arrow
            isDisabled={page === minPage}
            onClick={() => {
              if (page > minPage) {
                updatePage(page - 1);
              }
            }}
          >
            <IconContainer>
              <ChevronLeftIcon primaryColor={customPrimaryColor || primaryColor} />
            </IconContainer>
            <Button primaryColor={customPrimaryColor || primaryColor}>Prev</Button>
          </Arrow>

          <Arrow
            isDisabled={page === maxPage}
            onClick={() => {
              if (page < maxPage) {
                updatePage(page + 1);

                if (infiniteQuery && shouldFetchNext) {
                  fetchNextPage();
                }
              }
            }}
          >
            <Button primaryColor={customPrimaryColor || primaryColor}>Next</Button>
            <IconContainer>
              <ChevronRightIcon primaryColor={customPrimaryColor || primaryColor} />
            </IconContainer>
          </Arrow>
        </ArrowsWrapper>
      )}
    </Wrapper>
  );
}

export default Pagination;
