import React, { useEffect, useRef } from "react";
import Select from "@atlaskit/select";
import styled from "styled-components";

import { SELECT_STYLE } from "../datagrid/editor/selectStyles";
import { useDatabaseUIStore } from "../database-ui-store";
import { useRecordsStore } from "../records-store";

const Container = styled.div`
  display: flex;
  width: 300px;
  padding: 20px;
  gap: 20px;
  overflow: visible;

  & > div {
    flex: 1;
  }
`;

const Label = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 4px;
  color: #6b778c;
`;

const ORDER_OPTIONS = [
  { value: "asc", label: "Ascending" },
  { value: "desc", label: "Descending" },
];

function SortWrapper() {
  const fields = useRecordsStore((state) => state.fields);
  const sortWrapperRef = useRef();

  const { sort, setSort } = useDatabaseUIStore((state) => {
    return {
      sort: state.sort,
      setSort: state.setSort,
    };
  });

  useEffect(() => {
    if (sortWrapperRef.current) {
      sortWrapperRef.current.parentNode.style.overflow = "visible";
    }
  }, [sortWrapperRef]);

  const columns = fields.map((field) => ({ label: field.title, value: field.id }));

  const selectedColumn = columns.find((opt) => opt.value === sort.fieldId);
  const selectedOrder = ORDER_OPTIONS.find((opt) => opt.value === sort.order);

  return (
    <Container ref={sortWrapperRef}>
      <div>
        <Label>Column</Label>
        <Select
          isClearable
          value={selectedColumn}
          options={columns}
          styles={SELECT_STYLE}
          onChange={(opt) => setSort({ fieldId: opt?.value })}
        />
      </div>

      <div>
        <Label>Sort by</Label>
        <Select
          isClearable={false}
          value={selectedOrder}
          styles={SELECT_STYLE}
          options={ORDER_OPTIONS}
          onChange={(opt) => setSort({ order: opt.value })}
        />
      </div>
    </Container>
  );
}

export default SortWrapper;
