import React, { useEffect, useRef } from "react";

import debounce from "lodash.debounce";

import { saveCosmosAppSettings } from "../api";
import { useCosmosAppStore } from "../cosmos-app-store";
import { useCosmosAppSettings } from "../use-cosmos-app-settings";
import { COSMOS_APP_DEFAULT_DATA, COSMOS_APP_KEY } from "../defaults";
import { usePageStore } from "../../../../page-store";
import { Pages } from "../../../../pages";
import {
  Content,
  ContentContainer,
  Divider,
  Header,
  ContentPanel,
  ContentHeader,
  PreviewContainer,
  Wrapper,
  ContentTitle,
  ContentSectionContainer,
  ContentSection,
  ContentSectionTitle,
  ContentSectionHeading,
  ContentSectionDescription,
  ContentButtonLink,
} from "../styles";
import SingleTileColorPicker from "../../../../../../Shared/Components/SingleTileColorPicker";
import TitleWithBack from "../../../../../../Shared/Components/TitleWithBack";
import { useDatabaseInitialization } from "../../../../../../Shared/Hooks/useDatabaseInitialization";
import Phone from "../Shared/Phone";
import { SCREEN_TYPE } from "../Shared/PhoneScreens/defaults";

const Brand = () => {
  const setSelectedPage = usePageStore((state) => state.setSelectedPage);
  const { cosmosAppSettings, setCosmosAppSettings, updateCosmosAppSettings } = useCosmosAppStore((state) => ({
    cosmosAppSettings: state.cosmosAppSettings,
    setCosmosAppSettings: state.setCosmosAppSettings,
    updateCosmosAppSettings: state.updateCosmosAppSettings,
  }));

  const { isDatabaseLoading, isDatabaseInitialized } = useDatabaseInitialization();
  const { isLoadingCosmosAppSettings, cosmosAppSettingsData } = useCosmosAppSettings({ isDatabaseInitialized });

  useEffect(() => {
    if (!isLoadingCosmosAppSettings && cosmosAppSettingsData.exists) {
      setCosmosAppSettings(cosmosAppSettingsData.cosmosAppSettings);
    }
  }, [isDatabaseLoading, isLoadingCosmosAppSettings]);

  const handleSaveCosmosAppSettings = useRef(
    debounce((settings) => {
      saveCosmosAppSettings(settings);
    }, 250),
  ).current;

  useEffect(() => {
    return () => {
      handleSaveCosmosAppSettings.cancel();
    };
  }, [handleSaveCosmosAppSettings]);

  useEffect(() => {
    if (!isLoadingCosmosAppSettings) {
      if (!(COSMOS_APP_KEY.BRAND in cosmosAppSettings)) {
        setCosmosAppSettings({
          ...cosmosAppSettings,
          [COSMOS_APP_KEY.BRAND]: [COSMOS_APP_KEY.BRAND],
        });
      }
    }
  }, [isLoadingCosmosAppSettings, cosmosAppSettings]);

  useEffect(() => {
    if (Object.keys(cosmosAppSettings || {}).length && !isLoadingCosmosAppSettings) {
      handleSaveCosmosAppSettings(cosmosAppSettings);
    }
  }, [cosmosAppSettings, isLoadingCosmosAppSettings, handleSaveCosmosAppSettings]);

  return (
    <Wrapper>
      <Header>
        <TitleWithBack
          handleOnClick={() => setSelectedPage(Pages.SETTINGS)}
          title="Brand"
          description="Apply company colors for a consistent app appearance"
        />
      </Header>

      <Divider />

      <Content>
        <ContentContainer>
          <ContentPanel>
            <ContentHeader>
              <ContentTitle>Color</ContentTitle>
              <ContentButtonLink
                onClick={() =>
                  updateCosmosAppSettings(COSMOS_APP_KEY.BRAND, { ...COSMOS_APP_DEFAULT_DATA[COSMOS_APP_KEY.BRAND] })
                }
              >
                Reset to default
              </ContentButtonLink>
            </ContentHeader>
            <ContentSectionContainer>
              <ContentSection>
                <ContentSectionHeading>
                  <ContentSectionTitle>Brand Color</ContentSectionTitle>
                </ContentSectionHeading>
                <SingleTileColorPicker
                  isDisabled={isLoadingCosmosAppSettings}
                  value={cosmosAppSettings?.brand?.color}
                  setValue={(color) => updateCosmosAppSettings(COSMOS_APP_KEY.BRAND, { color })}
                />
                <ContentSectionDescription>
                  The selected brand color will be applied to all buttons, links, and labels within the app for a
                  cohesive look and feel.
                </ContentSectionDescription>
              </ContentSection>
            </ContentSectionContainer>
          </ContentPanel>
          <PreviewContainer>
            <Phone screenType={SCREEN_TYPE.PEOPLE} />
          </PreviewContainer>
        </ContentContainer>
      </Content>
    </Wrapper>
  );
};

export default Brand;
