import React from "react";
import {
  Image,
  ListItem,
  ListLabel,
  ListLabelWrapper,
  ListUser,
  ListWrapper,
  Name,
} from "../../../../../Dashboard/panels/styles/peopleStyles";
import { useTheme } from "styled-components";

const MainListView = ({ data, people }) => {
  const theme = useTheme();
  return (
    <ListWrapper backgroundColor={theme.shared.card.background} borderColor={theme.shared.card.border.box}>
      {people.map((person) => (
        <ListItem key={person.accountId}>
          <ListUser>
            <Image imageSize={40} src={person.icon} />
            <Name>{person.name}</Name>
          </ListUser>
          <ListLabelWrapper>
            <ListLabel color={theme.shared.card.title}>{person.titleValue}</ListLabel>
          </ListLabelWrapper>
          <ListLabelWrapper>
            <ListLabel color={theme.shared.card.subtitle}>{person.footerValue}</ListLabel>
          </ListLabelWrapper>
        </ListItem>
      ))}
    </ListWrapper>
  );
};

export default MainListView;
