import React, { useEffect } from "react";
import { BroadcastChannel } from "broadcast-channel";
import Spinner from "@atlaskit/spinner";

const MESSAGE_MAP = {
  jira_not_on_same_site: { type: "error", msg: "Jira instance you've picked is not on the same site." },
  error_connecting_to_jira: { type: "error", msg: "Could not establish connection to Jira" },
  connecting_to_jira_successful: { type: "success", msg: "Successfully connected to Jira" },
  trello_connection_success: { type: "success", msg: "Successfully connected to Trello" },
  trello_connection_error: { type: "error", msg: "Could not establish connection to Trello" },
  azure_connection_successful: { type: "success", msg: "Successfully connected to Azure" },
  azure_connection_error: { type: "error", msg: "Could not establish connection to Azure" },
};

const Broadcaster = (props) => {
  useEffect(() => {
    (async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const broadcastChannel = urlParams.get("broadcastChannel");
      const code = urlParams.get("code");

      if (broadcastChannel && code) {
        const channel = new BroadcastChannel(broadcastChannel);
        await channel.postMessage(MESSAGE_MAP[code] || code);
        setTimeout(() => window.close(), 1000);
      }
    })();
  }, []);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        inset: 0,
      }}
    >
      <Spinner size="xlarge" />
    </div>
  );
};

export default Broadcaster;
