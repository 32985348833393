import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import xss from "xss";

import { CreatableSelect } from "@atlaskit/select";
import Textfield from "@atlaskit/textfield";

import { BackgroundDialogContext } from "../Context";

import ImageIcon from "../Icons/Image";
import { IMAGE_TYPES } from "../ImageSourceTypes";

const TextfieldContainer = styled.div`
  & > div:first-child {
    background: ${({ theme }) => theme.sidebar.inputBackground};
    border-radius: 3px;
    border-width: 0px;

    .input-link {
      font-weight: 400 !important;
      font-size: 14px !important;
      line-height: 17px !important;
      color: #172b4d !important;
      height: 40px !important;
    }

    &:focus-within {
      background: ${({ theme }) => theme.sidebar.inputBackground} !important;
    }
  }
`;

function Link() {
  const { image, setImage, isMulti } = useContext(BackgroundDialogContext);
  const [inputValue, setInputValue] = useState(image?.source === IMAGE_TYPES.LINK ? image?.link : "");
  const isValidLink = (link) => {
    return link.startsWith("https://");
  };

  useEffect(() => {
    setInputValue(image?.source === IMAGE_TYPES.LINK ? image?.link : "");
  }, [image]);

  const handleBlur = () => {
    if (isValidLink(inputValue)) {
      setImage({ source: IMAGE_TYPES.LINK, link: xss(inputValue.replace('"', "")), id: "" });
    }
  };

  return (
    <>
      {isMulti ? (
        <CreatableSelect
          isMulti
          onChange={(links) => {
            setImage([
              ...image.filter((img) => img.source !== IMAGE_TYPES.LINK),
              ...links.map(({ value }) => ({
                source: IMAGE_TYPES.LINK,
                link: value,
                id: "",
              })),
            ]);
          }}
          onCreateOption={(link) => {
            setImage([
              ...image,
              {
                source: IMAGE_TYPES.LINK,
                id: "",
                link,
              },
            ]);
          }}
          value={image
            .filter((img) => img.source === IMAGE_TYPES.LINK)
            .map(({ link }) => ({ value: link, label: link }))}
        />
      ) : (
        <TextfieldContainer>
          <Textfield
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onBlur={handleBlur}
            placeholder="https://www.caelor.com"
            className="input-link"
          />
        </TextfieldContainer>
      )}

      <div style={{ textAlign: "center", marginTop: "40px" }}>
        <ImageIcon />

        <h2 style={{ fontWeight: 500 }}>Enter a link</h2>
        <p>Paste an Image Address to preview.</p>
      </div>
    </>
  );
}

export default Link;
