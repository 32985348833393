import React, { useEffect, useState } from "react";
import Spinner from "@atlaskit/spinner";
import { useTheme } from "styled-components";
import { useInfiniteQuery, useQuery, useQueryClient } from "react-query";
import { fetchCollections } from "../../../../../../Collections/api";
import { getUserCollections } from "../../../../../api";
import { mapBlogpost } from "./handleBlogData";
import PanelWrapper from "../../../../Shared/Panels/shared/components/PanelWrapper";
import { GridContainer, ListContainer, MagazineGridContainer, NewsTypes } from "./News";
import { CoverStory, InlineNews } from "@caelor/cards-and-panels-components";
import { htmlDecode } from "../../../../../../ContentBuilder/utils/decode-html-string";
import Pagination from "../../../../Shared/PanelsPagination/Pagination";
import { useSidebarStore } from "../../edit/sidebar/sidebar-store";
import { PanelsPaginationTypes } from "../../../../Shared/PanelsPagination/panels-pagination-types";
import { getAllBlogIdsFromCategories, searchTargetedNews } from "./api";
import { PanelPositioning } from "../../panelTypes";
import { getCategoriesSettings } from "../../../../Settings/News/api";
import { EmptyManual } from "./EmptyStates";
import { OverlineWrapper, PersonDetails, PersonIcon, SideNewsListContainer, SpaceLabel, Title } from "./styled";
import { Pages } from "../../../../../pages";
import { usePageStore } from "../../../../../page-store";
import { useCorporateIdentityStore } from "../../../../Settings/General/BrandAndColors/corporate-identity-store";

function TargetedNews({ id, panelTitle, data, position }) {
  const [page, setPage] = useState(1);
  const [showMoreClicked, setShowMoreClicked] = useState(false);

  const [loadingUserBlogs, setLoadingUserBlogs] = useState(false);
  const [loadingNotUserBlogs, setLoadingNotUserBlogs] = useState(false);

  const [foundBlogIds, setFoundBlogIds] = useState([]);
  const [blogIdsUserDoesntBelongTo, setBlogIdsUserDoesntBelongTo] = useState([]);

  const [allCategories, setAllCategories] = useState([]);

  const setSelectedPage = usePageStore((state) => state.setSelectedPage);
  const borderRadius = useCorporateIdentityStore((state) => state.borderRadius);
  const setDisableClickOutside = useSidebarStore((state) => state.setDisableClickOutside);

  const queryClient = useQueryClient();

  const theme = useTheme();

  const {
    view,
    maxNumberOfItems,
    activeElements,
    labelsBackground,
    teamLabelsBackground,
    coverPictureSize,
    padding,
    displayTitle,
    displayBox,
    pagination,
    sortBy,
    ordering,
    labels,
    spaces,
    contributors,
  } = data || {};

  const setLoading = (state) => {
    setLoadingUserBlogs(state);
    setLoadingNotUserBlogs(state);
  };

  const { isLoading: isLoadingCategories, data: categoriesSettingsData } = useQuery(
    "categories-settings",
    getCategoriesSettings,
    {
      retry: 0,
      select: (response) => {
        const { data } = response;
        return data;
      },
    },
  );

  const { isLoading: isLoadingCollections, data: collectionsData } = useQuery("saved-collections", fetchCollections, {
    cacheTime: 0,
    retry: 0,
    select: (response) => {
      return { collections: response?.data || [] };
    },
  });

  const isSideNews = position === PanelPositioning.SIDE;

  const {
    isLoading: isFetchingBlogsData,
    fetchNextPage,
    hasNextPage,
    data: blogsData,
  } = useInfiniteQuery(
    [
      `blogposts-targeted-${id}`,
      {
        blogIds: foundBlogIds,
        blogIdsToRemove: blogIdsUserDoesntBelongTo.filter((blogId) => !foundBlogIds.find((id) => id === blogId)),
        labels: labels,
        contributors: contributors,
        spaces: spaces,
        limit: isSideNews ? maxNumberOfItems : view === NewsTypes.MAGAZINE ? 3 : maxNumberOfItems || 4,
        sorting: sortBy,
        ordering: ordering,
      },
    ],
    searchTargetedNews,
    {
      enabled: !loadingUserBlogs && !loadingNotUserBlogs && !isLoadingCategories,
      retry: 0,
      select: (response) => {
        const pages = [];
        response.pages.forEach((page) => {
          const body = JSON.parse(page.body);
          const resultsInPage = body.results.map((blog) => mapBlogpost(blog, undefined, allCategories));
          pages.push({ ...body, results: resultsInPage });
        });
        return { pages, pageParams: response.pageParams };
      },
      getNextPageParam: (lastPage) => {
        const body = JSON.parse(lastPage.body);
        if (body._links.next) {
          const urlParams = new URLSearchParams(encodeURI(body._links.next));
          const cursor = urlParams.get("cursor");
          return cursor;
        }
        return false;
      },
    },
  );

  useEffect(() => {
    setPage(1);
    setShowMoreClicked(false);
    queryClient.resetQueries([`blogposts-targeted-${id}`]);
  }, [view, pagination, maxNumberOfItems, sortBy, ordering]);

  useEffect(() => {
    if (!isLoadingCategories && categoriesSettingsData?.exists) {
      setAllCategories(categoriesSettingsData?.categories?.data || []);
    }
  }, [isLoadingCategories, categoriesSettingsData]);

  const findAllBlogIdsFromCollections = async (collections) => {
    const allFoundBlogs = [];
    for (const collection of collections) {
      if (collection?.targetedBlogs?.length) {
        allFoundBlogs.push(...collection.targetedBlogs);
      }
      if (collection?.targetedCategories?.length) {
        const foundBlogIds = await getAllBlogIdsFromCategories(collection?.targetedCategories);
        allFoundBlogs.push(...foundBlogIds);
      }
    }
    return [...new Set(allFoundBlogs)];
  };

  const extractBlogsFromCollectionsUserDoesntBelongTo = async (usersCollections, allCollections) => {
    const collectionsUserDoesntBelongTo = allCollections.filter(
      ({ id }) => !usersCollections.find((clc) => clc.id === id),
    );
    try {
      const allFoundBlogs = await findAllBlogIdsFromCollections(collectionsUserDoesntBelongTo);
      const uniqueIds = [...new Set(allFoundBlogs)];
      setBlogIdsUserDoesntBelongTo([...uniqueIds]);
    } catch (error) {
      setBlogIdsUserDoesntBelongTo([]);
    } finally {
      setLoadingNotUserBlogs(false);
    }
  };

  const extractBlogsFromCollectionsUserBelongsTo = async (collections) => {
    try {
      const allFoundBlogs = await findAllBlogIdsFromCollections(collections);
      const uniqueIds = [...new Set(allFoundBlogs)];
      setFoundBlogIds([...uniqueIds]);
    } catch (error) {
      setFoundBlogIds([]);
    } finally {
      setLoadingUserBlogs(false);
    }
  };

  const fetchCollectionsUserBelongsTo = () => {
    getUserCollections(collectionsData.collections)
      .then((res) => {
        if (res.data?.length) {
          const collections = collectionsData.collections.filter(
            (clc) => !!res.data.find((userClcId) => userClcId === clc.id),
          );
          if (collections?.length) {
            extractBlogsFromCollectionsUserBelongsTo(collections);
            extractBlogsFromCollectionsUserDoesntBelongTo([...collections], collectionsData?.collections || []);
          } else {
            setLoading(false);
          }
        } else {
          setLoading(false);
        }
      })
      .catch((err) => setLoading(false));
  };

  useEffect(() => {
    if (collectionsData?.collections?.length) {
      setLoading(true);
      fetchCollectionsUserBelongsTo();
    }
  }, [collectionsData]);

  const numberOfItems = {
    [NewsTypes.GRID]: maxNumberOfItems,
    [NewsTypes.LIST]: maxNumberOfItems,
    [NewsTypes.MAGAZINE]: 3,
    [NewsTypes.SIDE_NEWS]: maxNumberOfItems,
  };
  const paginatedBlogsData =
    pagination === PanelsPaginationTypes.ARROWS ? [blogsData?.pages[page - 1]] : blogsData?.pages;
  const numOfTotalPages = Math.ceil(blogsData?.pages[0]?.totalSize / numberOfItems[view]);

  const isLoading =
    isLoadingCollections || isLoadingCategories || loadingUserBlogs || loadingNotUserBlogs || isFetchingBlogsData;

  const isEmpty = !blogsData?.pages?.length || !blogsData?.pages[0]?.results?.length;

  if (isEmpty && !isLoading) {
    return <EmptyManual panelTitle={panelTitle} />;
  }

  return (
    <PanelWrapper
      panelTitle={panelTitle}
      displayOptions={{
        displayTitle,
        displayBox,
      }}
    >
      {isLoading && (
        <div style={{ display: "grid", placeContent: "center" }}>
          <Spinner size="xlarge" />
        </div>
      )}

      <>
        {isSideNews &&
          !isLoading &&
          !isEmpty &&
          paginatedBlogsData?.map((page, index) => (
            <SideNewsListContainer key={`page-${index}`} style={{ marginTop: index === 0 ? "0px" : "20px" }}>
              {page?.results?.map((blog) => (
                <div
                  key={blog.id}
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    window.open(blog.url, "_blank");
                  }}
                >
                  <OverlineWrapper isTwoColumns={activeElements?.includes("author")}>
                    {activeElements?.includes("author") && <PersonIcon src={blog.avatarUrl} />}
                    <PersonDetails>
                      {activeElements?.includes("author") && `${blog.createdBy}`}
                      {activeElements?.includes("author") && activeElements?.includes("date") && " · "}
                      {activeElements?.includes("date") && `${blog.createdFormatted}`}
                    </PersonDetails>
                  </OverlineWrapper>
                  <Title color={theme.shared.card.title}>{blog.name}</Title>
                  {activeElements?.includes("space") && (
                    <SpaceLabel backgroundColor={labelsBackground}>{blog.space.name}</SpaceLabel>
                  )}
                </div>
              ))}
            </SideNewsListContainer>
          ))}

        {view === NewsTypes.MAGAZINE &&
          !isLoading &&
          !isEmpty &&
          !isSideNews &&
          paginatedBlogsData?.map((page, index) => (
            <div key={`page-${index}`} style={{ marginTop: index !== 0 ? "24px" : "0px" }}>
              <MagazineGridContainer isLargeStory={index === 0} padding={padding}>
                {page?.results?.map((blog, idx) => (
                  <div
                    key={blog.id}
                    className={`${index === 0 ? `grid-large-story-child-${idx + 1}` : `grid-child-${idx + 1}`} child`}
                  >
                    <CoverStory
                      key={blog.id}
                      coverPictureCallback={blog.coverPictureCallback}
                      getBlogsCategories={activeElements?.includes("categories") ? blog.getBlogsCategories : undefined}
                      name={blog.name}
                      space={activeElements?.includes("space") ? blog.space : {}}
                      url={blog.url}
                      excerpt={activeElements?.includes("description") ? htmlDecode(blog.excerpt) : null}
                      labels={activeElements?.includes("label") ? blog.labels : null}
                      createdBy={activeElements?.includes("author") ? blog.createdBy : null}
                      lastModified={activeElements?.includes("date") ? blog.createdFormatted : null}
                      numberOfComments={activeElements?.includes("comments") ? blog.numberOfComments : null}
                      numberOfLikes={activeElements?.includes("likes") ? blog.numberOfLikes : null}
                      avatarUrl={blog.avatarUrl}
                      labelsBackground={labelsBackground}
                      coverPictureBorderRadius={borderRadius.replace("px", "")}
                      labelsBorderRadius={borderRadius}
                      darkTheme={{
                        shadowColors: {
                          default: theme.shared.news.shadows.default,
                          hover: theme.shared.news.shadows.hover,
                        },
                      }}
                    />
                  </div>
                ))}
              </MagazineGridContainer>
            </div>
          ))}

        {view === NewsTypes.GRID && !isLoading && !isEmpty && !isSideNews && (
          <GridContainer padding={padding}>
            {paginatedBlogsData?.map((page, index) => (
              <>
                {page?.results?.map((blog, idx) => (
                  <CoverStory
                    key={blog.id}
                    coverPictureCallback={blog.coverPictureCallback}
                    getBlogsCategories={activeElements?.includes("categories") ? blog.getBlogsCategories : undefined}
                    name={blog.name}
                    space={activeElements?.includes("space") ? blog.space : {}}
                    url={blog.url}
                    excerpt={activeElements?.includes("description") ? htmlDecode(blog.excerpt) : null}
                    labels={activeElements?.includes("label") ? blog.labels : null}
                    createdBy={activeElements?.includes("author") ? blog.createdBy : null}
                    lastModified={activeElements?.includes("date") ? blog.createdFormatted : null}
                    numberOfComments={activeElements?.includes("comments") ? blog.numberOfComments : null}
                    numberOfLikes={activeElements?.includes("likes") ? blog.numberOfLikes : null}
                    avatarUrl={blog.avatarUrl}
                    labelsBackground={labelsBackground}
                    coverPictureSize={coverPictureSize}
                    coverPictureBorderRadius={borderRadius.replace("px", "")}
                    labelsBorderRadius={borderRadius}
                    darkTheme={{
                      shadowColors: {
                        default: theme.shared.news.shadows.default,
                        hover: theme.shared.news.shadows.hover,
                      },
                    }}
                  />
                ))}
              </>
            ))}
          </GridContainer>
        )}

        {view === NewsTypes.LIST &&
          !isLoading &&
          !isEmpty &&
          !isSideNews &&
          paginatedBlogsData?.map((page, index) => (
            <ListContainer key={`page-${index}`} style={{ marginTop: index !== 0 ? "24px" : "0px" }}>
              {page?.results?.map((blog, idx) => (
                <>
                  <InlineNews
                    key={blog.id}
                    coverPictureCallback={blog.coverPictureCallback}
                    getBlogsCategories={activeElements?.includes("categories") ? blog.getBlogsCategories : undefined}
                    name={blog.name}
                    space={activeElements?.includes("space") ? blog.space : {}}
                    url={blog.url}
                    excerpt={activeElements?.includes("description") ? htmlDecode(blog.excerpt) : null}
                    labels={activeElements?.includes("label") ? blog.labels : null}
                    createdBy={activeElements?.includes("author") ? blog.createdBy : null}
                    numberOfComments={activeElements?.includes("comments") ? blog.numberOfComments : null}
                    numberOfLikes={activeElements?.includes("likes") ? blog.numberOfLikes : null}
                    lastModified={activeElements?.includes("date") ? blog.createdFormatted : null}
                    avatarUrl={blog.avatarUrl}
                    labelsBackground={teamLabelsBackground}
                    coverPictureBorderRadius={borderRadius.replace("px", "")}
                    imagePosition="left"
                    maxWidthOfImage={30}
                    labelsBorderRadius={borderRadius}
                    darkTheme={{
                      backgroundColor: theme.shared.news.background,
                      titleColor: theme.shared.news.title,
                      descriptionColor: theme.shared.news.text,
                      authorColor: theme.shared.news.text,
                      borderColor: theme.shared.sidebar.divider,
                      textColor: theme.shared.news.text,
                      borderBottom: theme.shared.sidebar.divider,
                    }}
                  />
                </>
              ))}
            </ListContainer>
          ))}
      </>

      {!isEmpty && (
        <Pagination
          showMoreClicked={showMoreClicked}
          hasNextPage={page < numOfTotalPages}
          // INFINITE pagination type has Show more and view more, which directs the user to Newsroom
          // this is now disabled so there is infinite loading with Show more (Continuous show more button)
          // type={pagination === PanelsPaginationTypes.SHOW_MORE ? PanelsPaginationTypes.INFINITE : pagination}
          type={pagination}
          page={page}
          updatePage={(v) => {
            setPage(v);
            if (hasNextPage) fetchNextPage();
          }}
          maxPage={numOfTotalPages}
          onWrapperHoverCallback={setDisableClickOutside}
          infiniteQuery={{ shouldFetchNext: hasNextPage, fetchNextPage }}
          // onClick={() => {
          //   if (hasNextPage && !showMoreClicked) {
          //     fetchNextPage();
          //     setPage(2);
          //     setShowMoreClicked(true);
          //     return;
          //   }

          //   setSelectedPage(Pages.NEWS);
          // }}
        />
      )}
    </PanelWrapper>
  );
}

export default TargetedNews;
