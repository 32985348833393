import React, { useEffect, useState } from "react";
import { getIconByName } from "../SidebarIconPicker/icons-service";
import { generateRandomIcon } from "../SidebarIconPicker/random-icon-generator";
import styled from "styled-components";

export const IconSizes = {
  SMALL: "small",
  MEDIUM: "medium",
  LARGE: "large",
};

const IconSizesBasedOnProvider = {
  "font-awesome": {
    [IconSizes.SMALL]: "17px",
    [IconSizes.MEDIUM]: "20px",
    [IconSizes.LARGE]: "28px",
  },
  other: {
    [IconSizes.SMALL]: "20px",
    [IconSizes.MEDIUM]: "24px",
    [IconSizes.LARGE]: "32px",
  },
};

const IconContainer = styled.div`
  color: ${({ theme }) => theme.collections.iconColor};
  display: grid;
  place-content: center;

  ${({ provider, iconSize }) => {
    const correctProvider = provider === "font-awesome" ? provider : "other";
    const correctSize = IconSizesBasedOnProvider[correctProvider][iconSize];

    return `
      width: ${correctSize};
      height: ${correctSize};
    `;
  }}

  & > svg {
    width: inherit !important;
    height: inherit !important;
  }
`;

function IconRenderer({
  icon = undefined,
  style = {},
  onClick = () => {},
  onRandomIconSelected = () => {},
  iconSize = IconSizes.MEDIUM,
  usePlainIcon = false,
}) {
  const [loadedIcon, setLoadedIcon] = useState(undefined);

  useEffect(() => {
    (async () => {
      if (!icon) {
        const randomIcon = await generateRandomIcon();
        setLoadedIcon(randomIcon.svg);
        onRandomIconSelected({ provider: randomIcon.provider, icon: randomIcon.icon });
        return;
      }
      const module = await import(`./providers/${icon.provider}`);
      const foundIcon = getIconByName(icon.provider, icon.icon.split("/")[1], module.default);
      setLoadedIcon(foundIcon);
    })();
  }, [icon]);

  if (!loadedIcon) {
    return <div style={{ color: "#42526E", ...style }} onClick={onClick} />;
  }

  if (usePlainIcon) {
    return <div style={{ height: "inherit" }} dangerouslySetInnerHTML={{ __html: loadedIcon }} />;
  }

  return (
    <IconContainer
      className="icon-container"
      provider={icon?.provider || "none"}
      iconSize={iconSize}
      style={{ ...style }}
      onClick={onClick}
      dangerouslySetInnerHTML={{
        __html: loadedIcon,
      }}
    ></IconContainer>
  );
}

export default IconRenderer;
