import styled from "styled-components";
import { token } from "@atlaskit/tokens";

export const MenuContentWrapper = styled.div`
  padding: 20px 25px 40px 25px;
  box-sizing: border-box;
  background-color: ${({ backgroundColor }) => backgroundColor};
  box-shadow: 0 4px 6px -6px rgba(0 0 0 / 25%);
  ${({ openOverContent }) =>
    openOverContent &&
    `position: fixed;
    z-index: 300;
    width: 100%;
   `}
`;

export const MenuContentPositioner = styled.div`
  display: grid;
  place-content: center;
`;

export const ChildMenuWrapper = styled.div`
  @media (max-width: 1440px) {
    max-height: 320px;
  }

  display: grid;
  overflow-y: auto;
  max-height: 600px;
  grid-template-columns: ${({ numberOfColumns }) =>
    `repeat(${numberOfColumns > 0 && numberOfColumns < 4 ? numberOfColumns : 4}, minmax(190px, 370px))`};
  grid-auto-rows: 1fr;
  padding: 0 10px 30px 10px;
  column-gap: 12px;
  row-gap: 12px;
  background: transparent;

  margin-bottom: -25px;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.scrollbar};
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #999;
  }

  @media (max-width: ${({ isPreview }) => (isPreview ? 1600 : 1200)}px) {
    grid-template-columns: ${({ numberOfColumns }) =>
      `repeat(${numberOfColumns > 0 && numberOfColumns < 3 ? numberOfColumns : 3}, minmax(190px, 370px))`};
  }
`;

export const ChildEntry = styled.div`
  position: relative;
  z-index: 4;
  cursor: pointer;
  display: grid;
  grid-template-columns: 30px auto;
  column-gap: 10px;
  background-color: transparent;
  box-sizing: border-box;
  border: 1px solid ${token("color.border")};
  border-radius: 8px;

  padding: 20px;

  transition:
    background-color 75ms ease-in-out,
    box-shadow 75ms ease-in-out;

  &:hover {
    box-shadow:
      0px 0px 1px 0px rgba(9, 30, 66, 0.31),
      0px 8px 12px 0px rgba(9, 30, 66, 0.15);
  }

  .child-icon-bg-container {
    z-index: 5;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      margin-right: 10px;
    }

    .icon-container {
      display: flex;

      svg {
        color: ${({ subentryIconColor }) => subentryIconColor};
      }
    }
  }

  .child-name {
    font-weight: 500;
    font-size: 15px;
    line-height: 21px;
    margin-top: 5px;
    color: ${({ subentryTextColor }) => subentryTextColor};
  }

  &:hover {
    background-color: ${({ subentryBackgroundHoverColor, backgroundColor: { hover } }) => {
      if (subentryBackgroundHoverColor === "#00000000") return hover;
      return subentryBackgroundHoverColor;
    }};

    ${({ subentryTextIconHover }) => {
      if (subentryTextIconHover) {
        return `
          .child-name {
            color: ${subentryTextIconHover};
          }

          .icon-container {
            svg {
              color: ${subentryTextIconHover};
            }
          }
        `;
      }
    }}
  }
`;
