import React from "react";
import styled, { useTheme } from "styled-components";
import { AustinPropertyKeys } from "../Panel/panels/CardLayout";
import { FieldType } from "../../../../UserDatabase/field-type";
import { useCorporateIdentityStore } from "../../Settings/General/BrandAndColors/corporate-identity-store";
import { COLOR_TYPES } from "../../Settings/General/BrandAndColors/color-types";

const CardLinkWrapper = styled.a`
  all: unset;
`;

const CardWrapper = styled.div`
  background: ${({ theme }) => theme.shared.card.background};
  box-shadow:
    rgba(9, 30, 66, 0.25) 0px 1px 3px,
    rgba(9, 30, 66, 0.31) 0px 0px 1px;
  border-radius: ${({ borderRadius }) => `${borderRadius}`};
  border: 1px solid ${({ theme }) => theme.shared.card.border.box};
  overflow: hidden;
  cursor: pointer;
  transition: box-shadow linear 75ms;

  &:hover {
    box-shadow:
      rgba(9, 30, 66, 0.31) 0px 0px 1px 0px,
      rgba(9, 30, 66, 0.25) 0px 8px 16px -6px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  align-items: center;
  justify-content: center;
  padding: 0px 16px 16px 16px;
  margin-top: ${({ imageSize }) => `-${imageSize / 2}px`};
  text-align: center;
`;

const CardBackground = styled.div`
  width: 100%;
  background: ${({ background }) => background};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 80px;
`;

const CardImage = styled.img`
  box-sizing: border-box;
  border: 4px solid ${({ theme }) => theme.shared.card.border.avatar};
  background-color: #fff;
  width: ${({ imageSize }) => `${imageSize || 80}px`};
  height: ${({ imageSize }) => `${imageSize || 80}px`};
  border-radius: 50%;
  object-fit: cover;
  margin: 0 auto;
`;

const Name = styled.div`
  font-weight: 500;
  font-size: 15px;
  line-height: 19px;
  color: ${({ theme }) => theme.shared.card.title};
  text-align: center;
  margin-top: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Position = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme }) => theme.shared.card.subtitle};
  height: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
`;

const Location = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: ${({ theme }) => theme.shared.card.footer};
  margin-top: 13px;

  height: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const GridCenter = styled.div`
  height: 32px;
  display: grid;
  place-content: center;
  margin-top: 8px;
`;

const Link = styled.a`
  color: ${({ theme }) => theme.global.text.blue};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const LinkWrapper = ({ href, isLink = true, children, primaryColor }) => {
  if (!isLink) {
    return <>{children}</>;
  }

  return (
    <Link
      style={{ color: primaryColor }}
      href={href}
      target="_blank"
      rel="noreferrer"
      onClick={(e) => e.stopPropagation()}
    >
      {children}
    </Link>
  );
};

const Preview = ({ borderRadius, settingsData }) => {
  return (
    <CardWrapper borderRadius={borderRadius} style={{ maxWidth: "200px", margin: "20px auto 0px auto" }}>
      <CardBackground background={{}} />

      <ContentWrapper imageSize={settingsData.imageSize || 76}>
        <CardImage
          src="https://images.unsplash.com/flagged/photo-1570612861542-284f4c12e75f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80"
          imageSize={settingsData.imageSize || 76}
        />
        <Name>{settingsData.isNameOn ? "Michael Scott" : ""}</Name>
        <Position>Product Manager</Position>
        <Location>London, UK</Location>
      </ContentWrapper>
    </CardWrapper>
  );
};

function AustinCard({ isPreview, settingsData, personFieldData, personData, borderRadius, createProfileLink }) {
  const colors = useCorporateIdentityStore((state) => state.colors);
  const primaryColor = colors ? colors[COLOR_TYPES.PRIMARY] : "#0065ff";
  const theme = useTheme();

  if (isPreview) return <Preview borderRadius={borderRadius} settingsData={settingsData} />;

  const fieldOne = personFieldData.find((field) => field.key === AustinPropertyKeys.FIELD_ONE);
  const fieldTwo = personFieldData.find((field) => field.key === AustinPropertyKeys.FIELD_TWO);

  const getBackground = () => {
    if (settingsData.cardBackground === "image") return personData.image;
    if (settingsData.cardBackground === "none") return theme.background;
  };

  return (
    <CardLinkWrapper target="_top" href={createProfileLink()} onClick={(e) => e.stopPropagation()}>
      <CardWrapper borderRadius={borderRadius}>
        <CardBackground background={getBackground()} />

        <ContentWrapper imageSize={settingsData.imageSize || 80}>
          <CardImage src={personData?.icon} imageSize={settingsData.imageSize || 80} />
          <Name>{settingsData.isNameOn ? personData?.name : ""}</Name>
          <Position>
            <LinkWrapper
              primaryColor={primaryColor}
              href={`mailto:${fieldOne?.value}`}
              isLink={fieldOne?.type === FieldType.EMAIL}
            >
              {fieldOne?.value}
            </LinkWrapper>
          </Position>
          <GridCenter>
            <Location>
              <LinkWrapper
                primaryColor={primaryColor}
                href={`mailto:${fieldTwo?.value}`}
                isLink={fieldTwo?.type === FieldType.EMAIL}
              >
                {fieldTwo?.value}
              </LinkWrapper>
            </Location>
          </GridCenter>
        </ContentWrapper>
      </CardWrapper>
    </CardLinkWrapper>
  );
}

export default AustinCard;
