import React, { Fragment, useState, useEffect } from "react";
import styled, { useTheme } from "styled-components";
import { deleteCollectionInDatabase } from "../api";
import { useCollectionsStore } from "../collection-store";
import { ConfirmDeleteCollection } from "../modal/ConfirmDeleteCollection";
import CollectionCard from "./CollectionCard";
import Toolbar from "./Toolbar";
import { ConfirmDeleteModal } from "../../Hub/Components/Shared/ConfirmDeleteModal";
import EmptyState from "../../../Shared/Components/EmptyState";

const Wrapper = styled.div`
  padding: 30px;
  width: calc(100% - 60px);
  height: 100%;

  display: flex;
  flex-direction: column;
  row-gap: 20px;
`;

const CollectionWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 21px;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (min-width: 1440px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media (min-width: 2560px) {
    grid-template-columns: repeat(6, 1fr);
  }
  ${(props) =>
    props.isEmpty &&
    `
    display: flex;
    justify-content: center;
    align-items: center;
  `}
`;

function Overview() {
  const [deleteInfo, setDeleteInfo] = useState({});
  const [filteredCollections, setFilteredCollections] = useState([]);
  const theme = useTheme();

  const { collections, setCollections, setSelectedCollection, searchValue } = useCollectionsStore((state) => ({
    collections: state.collections,
    setSelectedCollection: state.setSelectedCollection,
    setCollections: state.setCollections,
    searchValue: state.searchValue,
  }));

  useEffect(() => {
    const trimmed = searchValue ? searchValue.trim() : "";
    if (!trimmed.length) {
      setFilteredCollections([...collections]);
      return;
    }

    const regex = new RegExp(trimmed, "i");
    const filtered = collections.filter((collection) => collection.name.search(regex) >= 0);
    setFilteredCollections(filtered);
  }, [searchValue, collections]);

  const handleDeleteCollection = (event, collectionId) => {
    event.preventDefault();
    event.stopPropagation();
    setDeleteInfo({ visible: true, collectionId });
  };

  const deleteCollection = (collectionId) => {
    setCollections([...collections.filter((collection) => collection.id !== collectionId)]);
    deleteCollectionInDatabase(collectionId)
      .then((res) => console.log(res))
      .catch((e) => console.log(e));
  };

  return (
    <>
      <Wrapper>
        <Toolbar />
        <CollectionWrapper isEmpty={filteredCollections.length === 0}>
          {filteredCollections.length ? (
            filteredCollections
              .sort((a, b) => {
                const firstName = a.name.toUpperCase();
                const secondName = b.name.toUpperCase();
                if (firstName < secondName) return -1;
                if (firstName > secondName) return 1;
                return 0;
              })
              .map((collection) => (
                <Fragment key={collection.id}>
                  <CollectionCard
                    {...collection}
                    onClick={() => setSelectedCollection(collection)}
                    onDeleteClick={handleDeleteCollection}
                  />
                </Fragment>
              ))
          ) : (
            <EmptyState
              Image={() => {
                return <img src={`/images/hub/no-search-empty-state-${theme.global.name}.svg`} alt="" />;
              }}
              title="Nothing matches your search"
              description="Remove filters, or search for something less specific."
            />
          )}
        </CollectionWrapper>
      </Wrapper>

      <ConfirmDeleteCollection
        onClose={() => {
          setDeleteInfo({});
        }}
        onConfirm={(collectionId) => {
          deleteCollection(collectionId);
        }}
        deleteInfo={deleteInfo}
      />
    </>
  );
}

export default Overview;
