import React from "react";
import styled, { useTheme } from "styled-components";
import { useCollectionsStore } from "./collection-store";

const Container = styled.div`
  display: grid;
  place-content: center;
  justify-items: center;
  text-align: center;
  height: 100%;
  width: 100%;
`;

const Title = styled.p`
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: ${({ theme }) => theme.shared.emptyState.text};
  margin-top: 30px;
  margin-bottom: 12px;
`;

const Description = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.shared.emptyState.text};
`;

const Button = styled.button`
  height: 32px;
  border-radius: 3px;
  padding: 0px 12px;
  background-color: ${({ theme }) => theme.shared.emptyState.buttonBackground};
  color: ${({ theme }) => theme.shared.emptyState.buttonText};
  border: none;
  cursor: pointer;
`;

function EmptyState({ startCollection }) {
  const { setIsCreatingCollection } = useCollectionsStore((state) => ({
    setIsCreatingCollection: state.setIsCreatingCollection,
  }));

  const theme = useTheme();

  return (
    <Container>
      <img width="525" height="184.5" src={`/images/hub/collections-empty-state-${theme.global.name}.svg`} alt="" />

      <div>
        <Title>No People Collections created</Title>
        <Description style={{ width: "464px" }}>
          <span>
            Begin personalizing your intranet by creating people collections. These collections help organize your
            network and tailor the intranet to specific group needs.
          </span>
        </Description>

        <Button
          onClick={() => {
            setIsCreatingCollection(true);
          }}
        >
          Create People Collections
        </Button>
      </div>
    </Container>
  );
}

export default EmptyState;
