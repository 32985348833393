export const SideContentIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
    <rect x="7.6001" y="2" width="10.4" height="16" rx="1" fill="#B3BAC5" />
    <rect x="1.99976" y="2" width="4.8" height="16" rx="1" fill="#4C9AFF" />
  </svg>
);

export const MainContentIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
    <rect x="7.6001" y="2" width="10.4" height="16" rx="1" fill="#4C9AFF" />
    <rect x="1.99976" y="2" width="4.8" height="16" rx="1" fill="#B3BAC5" />
  </svg>
);
