import { nanoid } from "nanoid";
import { FilterConditions } from "./filter-conditions";
import { FieldType } from "./field-type";

export const FilterActionTypes = {
  CREATE: "create",
  REMOVE: "remove",
  UPDATE: "update",
};

const buildFilters = (data) => {
  const filters = data
    ?.filter((filter) => filter.id !== "user" && !!filter?.type)
    ?.map((filter) => {
      const baseFilter = {
        id: nanoid(7),
        value: undefined,
        column: filter.id,
        type: filter.type,
        fieldTitle: filter.title,
        condition:
          filter.type.includes("SELECT") || filter.type.includes("USER")
            ? FilterConditions.IS_ANY_OF
            : FilterConditions.IS,
      };

      if (filter.type === FieldType.SELECT || filter.type === FieldType.MULTISELECT) {
        return {
          ...baseFilter,
          options: filter.options,
        };
      }

      if (filter.type === FieldType.DATE) {
        return {
          ...baseFilter,
          recurring: !!filter?.recurring,
        };
      }

      return baseFilter;
    });

  return [...filters];
};

const mapFilters = (filters) => {
  let value = filters.filter(({ value }) => {
    if (value?.range?.some((val) => val) || value?.period) {
      return true;
    }

    if (typeof value === "object") {
      return value?.length;
    }

    return value;
  });

  value = value.map((filter) => {
    return {
      ...filter,
      id: filter.id,
      value: filter.value,
      column: filter.column,
      condition: filter.type === FieldType.SELECT ? FilterConditions.IS_ANY_OF : filter.condition,
      type: filter.type,
    };
  });

  return [...value];
};

const getActionTypeBasedOnFilterValue = (filter, existingFilters = []) => {
  let checkIfFilterHasValue;
  if (filter.type === "SELECT" || filter.type === "MULTISELECT") {
    checkIfFilterHasValue = () => !!filter.value?.length;
  } else if (filter.type === "DATE") {
    checkIfFilterHasValue = () => !!filter.value?.range?.length || !!filter.value?.period;
  } else {
    checkIfFilterHasValue = () => !!filter.value;
  }

  if (!checkIfFilterHasValue()) {
    return FilterActionTypes.REMOVE;
  }
  if (existingFilters.find((f) => f.column === filter.column)) {
    return FilterActionTypes.UPDATE;
  }
  return FilterActionTypes.CREATE;
};

export default { buildFilters, mapFilters, getActionTypeBasedOnFilterValue };
