import React, { useEffect, useRef } from "react";
import styled from "styled-components";

import Filter from "../../Hub/Components/PeopleBase/Filter/Filter";
import { useRecordsStore } from "../records-store";
import { useDatabaseUIStore } from "../database-ui-store";
import FilterService from "../filter-service";

import { useClickOutside } from "../../../Shared/Hooks/useClickOutside";

export const Container = styled.div`
  padding-left: 16px;
  [aria-label="filter"],
  [aria-label="filter"] * {
    height: 27px;
    width: 16px;
    margin-right: 10px;
  }
`;

const Filters = () => {
  const filterContainerRef = useRef(null);
  const { filters, setFilters, updateFilter, setSelectedFilters, selectedFilters } = useDatabaseUIStore((state) => {
    return {
      filters: state.filters,
      setFilters: state.setFilters,
      updateFilter: state.updateFilter,
      setSelectedFilters: state.setSelectedFilters,
      selectedFilters: state.selectedFilters,
      resetFilters: state.resetFilters,
    };
  });

  const fields = useRecordsStore(
    (state) => state.fields,
    (old, newFields) => JSON.stringify(old.map((f) => f.id)) === JSON.stringify(newFields.map((f) => f.id)),
  );

  useEffect(() => {
    if (!filters.length && fields.length) {
      setFilters(FilterService.buildFilters(fields));
    }
  }, [filters, fields, setFilters]);

  const clearFilters = () => {
    if (!selectedFilters.length) {
      const restoredFilters = filters.map((f) => ({ ...f, value: undefined }));
      setFilters([...restoredFilters]);
    }
  };

  useClickOutside(filterContainerRef, clearFilters);

  const nonSearchFilters = selectedFilters.filter((filter) => !filter.isSearch);

  return (
    <Container ref={filterContainerRef} style={{ paddingLeft: "16px" }}>
      <Filter
        label={
          nonSearchFilters.length > 0
            ? `${nonSearchFilters.length} Filter${nonSearchFilters.length === 1 ? "" : "s"} Applied`
            : "Filter"
        }
        fields={fields}
        filters={filters}
        updateFilter={updateFilter}
        applyFilters={() => {
          setSelectedFilters(FilterService.mapFilters(filters));
        }}
      />
    </Container>
  );
};

export default Filters;
