import React, { useMemo } from "react";
import { useNavigationStore } from "../../../../store";
import { PARENT_ENTRY_IDENTIFIER } from "../../../../defaults";
import { EditEntriesComponents } from "./selectors";

function EditEntry() {
  const { findParentEntry, editingEntry } = useNavigationStore((state) => ({
    findParentEntry: state.findParentEntry,
    editingEntry: state.editingEntry,
  }));

  const isParent = editingEntry?.parent === PARENT_ENTRY_IDENTIFIER;
  const menuStyle = isParent ? editingEntry?.menuStyle : findParentEntry(editingEntry?.parent)?.menuStyle;

  const Content = useMemo(() => EditEntriesComponents[menuStyle], [menuStyle]);

  if (!Content) return <></>;

  return <Content />;
}

export default EditEntry;
