import React from "react";

export const SupportIcon = ({ primaryColor }) => (
  <svg width="19" height="19" viewBox="0 0 19 19" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.55455 7.45358V7.79198L6.07911 7.51918L7.35617 14.8809L3.41391 15.5864C2.32501 15.7816 1.28715 15.0435 1.095 13.9374L0.512515 10.5804C0.319356 9.47525 1.04696 8.41995 2.13586 8.22475L2.55289 8.15013V7.45358C2.55289 3.52315 5.68949 0.336914 9.55868 0.336914C13.4279 0.336914 16.5645 3.52315 16.5645 7.45358V8.1585L16.9238 8.2228C18.0127 8.418 18.7393 9.4733 18.5481 10.5784L17.9646 13.9355C17.7735 15.0416 16.7346 15.7797 15.6457 15.5845L15.183 15.5017C14.4305 17.3424 12.6438 18.6369 10.5595 18.6369C9.45402 18.6369 8.55785 17.7266 8.55785 16.6036V15.5869C8.55785 15.0254 9.00594 14.5702 9.55868 14.5702C10.1114 14.5702 10.5595 15.0254 10.5595 15.5869V16.6036C11.6482 16.6036 12.6016 16.0149 13.128 15.1339L11.7034 14.8789L12.9815 7.51724L14.5628 7.80025V7.45358C14.5628 4.64613 12.3224 2.37025 9.55868 2.37025C6.79497 2.37025 4.55455 4.64613 4.55455 7.45358ZM4.45377 9.8748L2.48314 10.2276L3.06562 13.5846L5.03725 13.2308L4.45377 9.8748ZM14.0224 13.2289L14.6048 9.87285L16.5765 10.2256L15.993 13.5827L14.0224 13.2289Z"
      fill={primaryColor}
    />
  </svg>
);

export const DemoIcon = ({ primaryColor }) => (
  <svg width="19" height="19" viewBox="0 0 19 19" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.19829 2.60645H16.7787C17.9255 2.60645 18.8549 3.50145 18.8549 4.60045V16.6124C18.8549 16.8744 18.8012 17.1338 18.6968 17.3757C18.5925 17.6177 18.4395 17.8376 18.2467 18.0228C18.0539 18.2079 17.825 18.3548 17.5731 18.4549C17.3212 18.5551 17.0513 18.6066 16.7787 18.6064H2.19829C1.64782 18.6064 1.11989 18.3964 0.730549 18.0225C0.341212 17.6486 0.122346 17.1414 0.12207 16.6124V4.60045C0.12207 3.49945 1.05039 2.60645 2.19829 2.60645ZM2.20349 6.60645V15.6064C2.20349 15.8717 2.31314 16.126 2.50831 16.3136C2.70348 16.5011 2.96819 16.6064 3.24421 16.6064H15.7327C16.0088 16.6064 16.2735 16.5011 16.4686 16.3136C16.6638 16.126 16.7735 15.8717 16.7735 15.6064V6.60645H2.20349ZM3.24421 1.60645C3.24421 1.34123 3.35385 1.08687 3.54902 0.899338C3.74419 0.711802 4.0089 0.606445 4.28492 0.606445C4.56093 0.606445 4.82564 0.711802 5.02081 0.899338C5.21598 1.08687 5.32563 1.34123 5.32563 1.60645V2.60645H3.24421V1.60645ZM13.6513 1.60645C13.6513 1.34123 13.761 1.08687 13.9561 0.899338C14.1513 0.711802 14.416 0.606445 14.692 0.606445C14.9681 0.606445 15.2328 0.711802 15.4279 0.899338C15.6231 1.08687 15.7327 1.34123 15.7327 1.60645V2.60645H13.6513V1.60645ZM4.28492 10.6064V8.60544H6.36634V10.6064H4.28492ZM12.6106 10.6064V8.60544H14.692V10.6064H12.6106ZM8.44777 10.6064V8.60544H10.5302V10.6064H8.44777ZM4.28492 14.6064V12.6064H6.36634V14.6064H4.28492ZM8.44777 14.6064V12.6064H10.5302V14.6064H8.44777ZM12.6106 14.6064V12.6064H14.692V14.6064H12.6106Z"
      fill={primaryColor}
    />
  </svg>
);

export const DocumentationIcon = ({ primaryColor }) => (
  <svg width="18" height="19" viewBox="0 0 18 19" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.98259 14.831H2.00581V4.66432H2.98259V2.63099H2.00581C1.48769 2.63099 0.990796 2.84522 0.624432 3.22654C0.258067 3.60786 0.0522461 4.12505 0.0522461 4.66432V14.831C0.0522461 15.3703 0.258067 15.8875 0.624432 16.2688C0.990796 16.6501 1.48769 16.8643 2.00581 16.8643H2.98259V14.831ZM15.6808 7.71941V16.8643H5.91294V2.63099H11.7639V5.16249C11.7639 5.36276 11.8018 5.56106 11.8754 5.74608C11.949 5.9311 12.057 6.09922 12.193 6.24083C12.3291 6.38244 12.4906 6.49477 12.6683 6.57141C12.8461 6.64804 13.0366 6.68749 13.229 6.68749H17.6246V6.15679C17.6246 5.85484 17.4956 5.56916 17.2739 5.37599L12.3177 1.06939C11.9661 0.76439 11.5236 0.597656 11.0655 0.597656H5.91294C5.39482 0.597656 4.89793 0.811882 4.53156 1.19321C4.1652 1.57453 3.95938 2.09172 3.95938 2.63099V16.8643C3.95938 17.4036 4.1652 17.9208 4.53156 18.3021C4.89793 18.6834 5.39482 18.8977 5.91294 18.8977H15.6808C16.1989 18.8977 16.6958 18.6834 17.0621 18.3021C17.4285 17.9208 17.6343 17.4036 17.6343 16.8643V7.71941H15.6808Z"
      fill={primaryColor}
    />
  </svg>
);

export const FeedbackIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      d="M13.6728 12.0422L17.3458 8.46217L12.2698 7.72417L9.99985 3.12517L7.72985 7.72517L2.65385 8.46217L6.32685 12.0422L5.45985 17.0972L9.99985 14.7112L14.5398 17.0972L13.6728 12.0422ZM9.99985 17.0402L5.49485 19.4102C5.23947 19.5447 4.95153 19.605 4.66365 19.5843C4.37576 19.5636 4.09941 19.4627 3.86589 19.2931C3.63236 19.1235 3.45098 18.8919 3.34229 18.6245C3.23359 18.3571 3.20192 18.0646 3.25085 17.7802L4.11085 12.7632L0.466848 9.21017C0.260273 9.0088 0.114161 8.75364 0.045037 8.47356C-0.0240874 8.19348 -0.0134668 7.89965 0.0756974 7.62529C0.164862 7.35093 0.329012 7.10699 0.549586 6.92106C0.77016 6.73513 1.03836 6.61462 1.32385 6.57317L6.36085 5.84117L8.61285 1.27717C8.74038 1.01814 8.9379 0.799999 9.18304 0.647456C9.42817 0.494913 9.71113 0.414062 9.99985 0.414062C10.2886 0.414062 10.5715 0.494913 10.8167 0.647456C11.0618 0.799999 11.2593 1.01814 11.3868 1.27717L13.6388 5.84117L18.6758 6.57317C18.9613 6.61462 19.2295 6.73513 19.4501 6.92106C19.6707 7.10699 19.8348 7.35093 19.924 7.62529C20.0132 7.89965 20.0238 8.19348 19.9547 8.47356C19.8855 8.75364 19.7394 9.0088 19.5328 9.21017L15.8878 12.7632L16.7478 17.7792C16.7966 18.0635 16.7649 18.3558 16.6563 18.623C16.5476 18.8902 16.3663 19.1217 16.133 19.2913C15.8996 19.4609 15.6235 19.5618 15.3357 19.5826C15.048 19.6035 14.7602 19.5434 14.5048 19.4092L9.99985 17.0402Z"
      fill="#0065ff"
    />
  </svg>
);

export const LockIcon = ({ primaryColor }) => (
  <svg width="20" height="26" viewBox="0 0 20 20">
    <path
      fill={primaryColor}
      d="M4.16602 9.17425C4.16602 8.24925 4.91352 7.50008 5.83768 7.50008H14.161C14.6045 7.50096 15.0296 7.67771 15.343 7.99156C15.6563 8.30541 15.8325 8.73073 15.8327 9.17425V15.8259C15.8327 16.7509 15.0852 17.5001 14.161 17.5001H5.83768C5.3941 17.4994 4.96891 17.3227 4.65548 17.0088C4.34206 16.695 4.16602 16.2695 4.16602 15.8259V9.17425ZM5.83268 9.16675V15.8334H14.166V9.16675H5.83268Z"
    />
    <path
      fill={primaryColor}
      d="M9.99935 14.1667C10.9198 14.1667 11.666 13.4206 11.666 12.5001C11.666 11.5796 10.9198 10.8334 9.99935 10.8334C9.07887 10.8334 8.33268 11.5796 8.33268 12.5001C8.33268 13.4206 9.07887 14.1667 9.99935 14.1667Z"
    />
    <path
      fill={primaryColor}
      d="M6.66602 5.00175C6.66646 4.11767 7.01773 3.26991 7.64271 2.64462C8.26769 2.01933 9.11528 1.66763 9.99935 1.66675C10.4372 1.66675 10.8708 1.75302 11.2753 1.92064C11.6799 2.08826 12.0474 2.33394 12.357 2.64365C12.6665 2.95335 12.912 3.32101 13.0794 3.72562C13.2468 4.13024 13.3329 4.56387 13.3327 5.00175V5.41675H11.666V5.00175C11.6661 4.78277 11.6231 4.56591 11.5394 4.36356C11.4557 4.16121 11.333 3.97732 11.1782 3.8224C11.0234 3.66748 10.8397 3.54457 10.6374 3.46066C10.4351 3.37676 10.2183 3.33352 9.99935 3.33341C9.08102 3.33341 8.33268 4.08175 8.33268 5.00175V9.16675H6.66602V5.00175ZM11.666 5.41675H13.3327C13.3327 5.63776 13.2449 5.84972 13.0886 6.006C12.9323 6.16228 12.7204 6.25008 12.4993 6.25008C12.2783 6.25008 12.0664 6.16228 11.9101 6.006C11.7538 5.84972 11.666 5.63776 11.666 5.41675Z"
    />
  </svg>
);

export const CloseIcon = ({ primaryColor }) => (
  <svg width="22" height="22" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      fill={primaryColor}
      clipRule="evenodd"
      d="M15.1846 7.4L12.0006 10.585L8.81464 7.399C8.71938 7.30584 8.59127 7.25393 8.45803 7.25449C8.32478 7.25505 8.19712 7.30804 8.10264 7.402L7.40264 8.103C7.35472 8.14897 7.31652 8.20409 7.2903 8.2651C7.26408 8.3261 7.25038 8.39175 7.25001 8.45815C7.24963 8.52455 7.2626 8.59035 7.28813 8.65165C7.31366 8.71295 7.35124 8.7685 7.39864 8.815L10.5836 11.999L7.39964 15.185C7.30661 15.2804 7.25488 15.4086 7.25563 15.5418C7.25638 15.6751 7.30955 15.8027 7.40364 15.897L8.10364 16.597C8.30964 16.804 8.61964 16.797 8.81564 16.601L12.0016 13.416L15.1856 16.601C15.281 16.694 15.4092 16.7458 15.5425 16.745C15.6757 16.7443 15.8033 16.6911 15.8976 16.597L16.5986 15.897C16.6465 15.851 16.6846 15.7958 16.7108 15.7347C16.7369 15.6737 16.7505 15.608 16.7508 15.5416C16.7511 15.4752 16.738 15.4095 16.7124 15.3482C16.6868 15.2869 16.6491 15.2314 16.6016 15.185L13.4156 11.999L16.6016 8.815C16.6948 8.71974 16.7467 8.59163 16.7462 8.45838C16.7456 8.32514 16.6926 8.19748 16.5986 8.103L15.8986 7.403C15.8517 7.35497 15.7958 7.31672 15.734 7.29046C15.6722 7.2642 15.6058 7.25045 15.5386 7.25C15.4728 7.25054 15.4078 7.26407 15.3472 7.28981C15.2866 7.31555 15.2317 7.35299 15.1856 7.4H15.1846Z"
    />
  </svg>
);

export const LinkIcon = ({ primaryColor }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      fill={primaryColor}
      d="M12.9979 2C12.5948 2 12.2293 2.24375 12.073 2.61875C11.9168 2.99375 12.0043 3.42188 12.2886 3.70938L13.5821 5L8.29252 10.2937C7.90197 10.6844 7.90197 11.3188 8.29252 11.7094C8.68307 12.1 9.31732 12.1 9.70787 11.7094L14.9975 6.41563L16.291 7.70937C16.5784 7.99687 17.0064 8.08125 17.3814 7.925C17.7563 7.76875 18 7.40625 18 7V3C18 2.44687 17.5532 2 17.0002 2H12.9979ZM4.49951 3C3.11853 3 2 4.11875 2 5.5V15.5C2 16.8813 3.11853 18 4.49951 18H14.4976C15.8785 18 16.9971 16.8813 16.9971 15.5V12C16.9971 11.4469 16.5503 11 15.9973 11C15.4443 11 14.9975 11.4469 14.9975 12V15.5C14.9975 15.775 14.7725 16 14.4976 16H4.49951C4.22457 16 3.99961 15.775 3.99961 15.5V5.5C3.99961 5.225 4.22457 5 4.49951 5H7.99883C8.55185 5 8.99863 4.55312 8.99863 4C8.99863 3.44687 8.55185 3 7.99883 3H4.49951Z"
    />
  </svg>
);

export const FilledStarIcon = () => (
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.0714 13.284L4.1664 15.337C4.00125 15.4238 3.81511 15.4627 3.62901 15.4493C3.44291 15.4358 3.26429 15.3706 3.11333 15.261C2.96237 15.1513 2.8451 15.0016 2.77478 14.8288C2.70446 14.656 2.68389 14.4669 2.7154 14.283L3.4604 9.934L0.301398 6.854C0.167988 6.72374 0.0736517 6.55878 0.0290423 6.37774C-0.0155671 6.1967 -0.00867329 6.0068 0.0489455 5.82947C0.106564 5.65214 0.212613 5.49445 0.355116 5.37421C0.49762 5.25397 0.670903 5.17596 0.855398 5.149L5.2214 4.514L7.1744 0.558C7.25692 0.390535 7.38468 0.249515 7.54321 0.150904C7.70174 0.0522937 7.8847 3.05176e-05 8.0714 3.05176e-05C8.25809 3.05176e-05 8.44106 0.0522937 8.59959 0.150904C8.75812 0.249515 8.88587 0.390535 8.9684 0.558L10.9204 4.514L15.2864 5.149C15.471 5.17581 15.6444 5.2537 15.787 5.37388C15.9296 5.49406 16.0358 5.65173 16.0936 5.82909C16.1513 6.00644 16.1583 6.19641 16.1137 6.37752C16.0691 6.55863 15.9748 6.72368 15.8414 6.854L12.6814 9.934L13.4274 14.283C13.4588 14.4668 13.4382 14.6556 13.3679 14.8283C13.2976 15.001 13.1804 15.1506 13.0296 15.2602C12.8788 15.3698 12.7004 15.4351 12.5145 15.4487C12.3285 15.4622 12.1425 15.4236 11.9774 15.337L8.0714 13.284Z"
      fill="#FFAB00"
    />
  </svg>
);

export const StarIcon = () => (
  <svg width="17" height="16" viewBox="0 0 18 18" fill="none">
    <path
      d="M8.99926 13.373L12.9793 15.566L12.2193 10.911L15.4953 7.564L10.9713 6.874L8.99926 2.687L7.02726 6.876L2.50326 7.565L5.77926 10.91L5.01726 15.565L8.99926 13.373ZM8.99926 15.656L5.98326 17.318C5.6546 17.4991 5.28161 17.5841 4.90693 17.5633C4.53224 17.5425 4.17098 17.4167 3.86442 17.2002C3.55786 16.9838 3.31838 16.6855 3.17335 16.3394C3.02832 15.9933 2.98358 15.6133 3.04426 15.243L3.64326 11.587L1.07326 8.963C0.815575 8.69996 0.635513 8.37088 0.552917 8.01204C0.47032 7.65319 0.488395 7.27851 0.605149 6.92928C0.721903 6.58005 0.932804 6.26984 1.21461 6.03282C1.49642 5.7958 1.83819 5.64118 2.20226 5.586L5.67226 5.058L7.19026 1.834C7.352 1.49096 7.60795 1.20098 7.92825 0.997884C8.24855 0.794788 8.62 0.686951 8.99926 0.686951C9.37852 0.686951 9.74996 0.794788 10.0703 0.997884C10.3906 1.20098 10.6465 1.49096 10.8083 1.834L12.3273 5.058L15.7973 5.586C16.1612 5.64148 16.5027 5.79632 16.7842 6.03346C17.0658 6.2706 17.2764 6.58085 17.393 6.93003C17.5095 7.27921 17.5274 7.65377 17.4447 8.01247C17.362 8.37117 17.1819 8.70009 16.9243 8.963L14.3553 11.587L14.9543 15.243C15.0151 15.6135 14.9704 15.9936 14.8253 16.3398C14.6803 16.6861 14.4407 16.9845 14.1339 17.201C13.8272 17.4175 13.4657 17.5433 13.0909 17.564C12.716 17.5846 12.3429 17.4994 12.0143 17.318L8.99926 15.656Z"
      fill="#FFAB00"
    />
  </svg>
);
