import React from "react";
import styled from "styled-components";

const BannerWrapper = styled.div`
  background: ${({ theme }) => theme.installationBanners.optics.background};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 20px 18px;
  gap: 22px;
`;

const BannerHeader = styled.div`
  display: flex;
  place-items: center;
  gap: 12px;

  img {
    width: 48px;
    height: 48px;
  }
`;

const HeaderParagraph = styled.p`
  margin: 0;
  font-size: 16px;
  color: ${({ theme }) => theme.installationBanners.optics.text};

  span {
    color: ${({ theme }) => theme.installationBanners.optics.highlightedText};
    font-weight: 700;
  }
`;

const Button = styled.button`
  background: ${({ theme }) => theme.installationBanners.optics.buttonBackground};
  border: none;
  border-radius: 8px;
  font-size: 14px;
  color: ${({ theme }) => theme.installationBanners.optics.buttonText};
  height: 32px;
  cursor: pointer;
`;

const MacroSuiteInstallationBanner = ({
  HeaderParagraphText = () => (
    <>
      For more formatting options install <span>Optics</span>
    </>
  ),
  buttonText = "Install Now",
}) => {
  return (
    <BannerWrapper>
      <BannerHeader>
        <img src="/images/macrosuite-banner/optic-icon.png" alt="Optics" />
        <HeaderParagraph>
          <HeaderParagraphText />
        </HeaderParagraph>
      </BannerHeader>
      <Button
        onClick={() =>
          window.open(
            "https://marketplace.atlassian.com/apps/1222649/macrosuite-content-formatting-macros?hosting=cloud&tab=overview",
            "_blank",
          )
        }
      >
        {buttonText}
      </Button>
    </BannerWrapper>
  );
};

export default MacroSuiteInstallationBanner;
