import React, { useMemo } from "react";
import styled from "styled-components";
import { CalgaryPropertyKeys } from "../Panel/panels/CardLayout";
import { FieldType } from "../../../../UserDatabase/field-type";
import { useCorporateIdentityStore } from "../../Settings/General/BrandAndColors/corporate-identity-store";
import { COLOR_TYPES } from "../../Settings/General/BrandAndColors/color-types";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

const CardLinkWrapper = styled.a`
  all: unset;
`;

const CardWrapper = styled.div`
  background: ${({ theme }) => theme.shared.card.background};
  border-radius: ${({ borderRadius }) => `${borderRadius}`};
  box-shadow:
    rgba(9, 30, 66, 0.25) 0px 1px 3px,
    rgba(9, 30, 66, 0.31) 0px 0px 1px;
  overflow: hidden;
  border: 1px solid ${({ theme }) => theme.shared.card.border.box};
  padding: 24px;
  height: calc(100% - 48px);
  cursor: pointer;

  display: flex;
  flex-direction: column;
  row-gap: 16px;
  transition: box-shadow linear 75ms;

  &:hover {
    box-shadow:
      rgba(9, 30, 66, 0.31) 0px 0px 1px 0px,
      rgba(9, 30, 66, 0.25) 0px 8px 16px -6px;
  }
`;

const CardInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 18px;
`;

const CardImage = styled.img`
  width: 80px;
  height: 80px;
  border: 4px solid ${({ theme }) => theme.shared.card.border.avatar};
  background-color: #fff;
  border-radius: 50%;
  box-sizing: border-box;
  object-fit: cover;
`;

const CardPersonInfo = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 7px;

  overflow: hidden;

  font-weight: 400;
  font-size: 14px;
  line-height: 14px;

  .name {
    height: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: ${({ theme }) => theme.shared.card.title};
    cursor: pointer;
  }

  .info {
    height: 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${({ theme }) => theme.shared.card.subtitle};
  }

  .location {
    height: 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${({ theme }) => theme.shared.card.subtitle};
    margin-top: 3px;
  }
`;

const CardDescription = styled.div`
  margin: 5px 0px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: ${({ theme }) => theme.shared.card.footer};
  overflow: hidden;
  text-overflow: ellipsis;

  max-height: 52px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

const LabelContainer = styled.div`
  display: flex;
  overflow: hidden;
  column-gap: 8px;
  row-gap: 8px;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    background: linear-gradient(to left, #fff, transparent);
    height: 100%;
    width: 20px;
    right: 0;
  }
`;

const Label = styled.div`
  display: inline-block;
  margin-bottom: 0;
  border-radius: 3px;
  padding: 3px 5px;
  max-width: 100%;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: #505f79;
  text-align: center;
  white-space: nowrap;

  color: #505f79;
  background-color: ${({ bgColor }) => bgColor};
`;

const Link = styled.a`
  color: #0065ff;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const LinkWrapper = ({ href, isLink = true, children, primaryColor }) => {
  if (!isLink) {
    return <>{children}</>;
  }

  return (
    <Link
      style={{ color: primaryColor }}
      href={href}
      target="_blank"
      rel="noreferrer"
      onClick={(e) => e.stopPropagation()}
    >
      {children}
    </Link>
  );
};

const Preview = ({ borderRadius, settingsData }) => {
  const dummyLabels = useMemo(
    () => [
      { label: "Marketing", color: "#D1FBE0", id: "marketing" },
      { label: "UX/UI", color: "#FFE6CE", id: "ux-ui" },
      { label: "Product managment", color: "#C3FAFF", id: "product" },
    ],
    [],
  );

  return (
    <CardWrapper borderRadius={borderRadius} style={{ marginTop: "20px" }}>
      <CardInfoWrapper>
        <CardImage src="https://images.unsplash.com/flagged/photo-1570612861542-284f4c12e75f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80" />
        <CardPersonInfo>
          <span className="info">Product Manager</span>
          {settingsData.isNameOn && <span className="name">Michael Scott</span>}
          <span className="location">London, UK</span>
        </CardPersonInfo>
      </CardInfoWrapper>

      <CardDescription>
        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Hic a recusandae numquam tempora perferendis magnam
        illum vero.
      </CardDescription>

      <LabelContainer>
        {dummyLabels.map((op) => (
          <Label key={op.id} bgColor={op.color}>
            {op.label}
          </Label>
        ))}
      </LabelContainer>
    </CardWrapper>
  );
};

function CalgaryCard({ isPreview, createProfileLink, settingsData, personFieldData, personData, borderRadius }) {
  const colors = useCorporateIdentityStore((state) => state.colors);
  const primaryColor = colors ? colors[COLOR_TYPES.PRIMARY] : "#0065ff";

  if (isPreview) return <Preview borderRadius={borderRadius} settingsData={settingsData} />;

  const fieldOne = personFieldData.find((field) => field.key === CalgaryPropertyKeys.FIELD_ONE);
  const fieldTwo = personFieldData.find((field) => field.key === CalgaryPropertyKeys.FIELD_TWO);
  const fieldThree = personFieldData.find((field) => field.key === CalgaryPropertyKeys.FIELD_THREE);
  const fieldFour = personFieldData.find((field) => field.key === CalgaryPropertyKeys.FIELD_FOUR);

  dayjs.extend(customParseFormat);

  return (
    <CardLinkWrapper target="_top" href={createProfileLink()} onClick={(e) => e.stopPropagation()}>
      <CardWrapper borderRadius={borderRadius}>
        <CardInfoWrapper>
          <CardImage src={personData?.icon} />
          <CardPersonInfo>
            <span className="info">
              <LinkWrapper
                primaryColor={primaryColor}
                href={`mailto:${fieldOne?.value}`}
                isLink={fieldOne?.type === FieldType.EMAIL}
              >
                {fieldOne?.value}
              </LinkWrapper>
            </span>

            {settingsData.isNameOn && <span className="name">{personData?.name || ""}</span>}

            <span className="location">
              <LinkWrapper
                primaryColor={primaryColor}
                href={`mailto:${fieldTwo?.value}`}
                isLink={fieldTwo?.type === FieldType.EMAIL}
              >
                {fieldTwo?.value}
              </LinkWrapper>
            </span>
          </CardPersonInfo>
        </CardInfoWrapper>

        {fieldThree?.value && (
          <CardDescription>
            <LinkWrapper
              primaryColor={primaryColor}
              href={`mailto:${fieldThree?.value}`}
              isLink={fieldThree?.type === FieldType.EMAIL}
            >
              {fieldThree.type === "DATE"
                ? dayjs(fieldThree.value, "DD-MM-YYYY").format("MMM D, YYYY")
                : fieldThree.value}
            </LinkWrapper>
          </CardDescription>
        )}

        {fieldFour?.type === "MULTISELECT" && !!fieldFour?.value?.length ? (
          <LabelContainer>
            {fieldFour.value.map((op) => (
              <Label key={op.id} bgColor={op.color}>
                {op.label}
              </Label>
            ))}
          </LabelContainer>
        ) : (
          <>
            {fieldFour?.value && (
              <div>
                <LinkWrapper
                  primaryColor={primaryColor}
                  href={`mailto:${fieldFour?.value}`}
                  isLink={fieldFour?.type === FieldType.EMAIL}
                >
                  {fieldFour?.value}
                </LinkWrapper>
              </div>
            )}
          </>
        )}
      </CardWrapper>
    </CardLinkWrapper>
  );
}

export default CalgaryCard;
