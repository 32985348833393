import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import Spinner from "@atlaskit/spinner";
import { useQuery } from "react-query";
import styled, { useTheme } from "styled-components";
import { fetchRecords, fetchUserDatabaseFields } from "../../../../../UserDatabase/datagrid/api";
import { SpinnerContainer } from "../../../../styled/pages";
import { Panel, PanelContent, PanelTitle } from "../../dashboard-styled";
import { fetchDetailsForUsersIndividually } from "../../api";
import EmptyState from "../../../../../../Shared/Components/EmptyState";
import NoNewStartersSVG from "../images/NoNewStartersSVG";
import {
  IMAGE_TYPES,
  getCssForBackground,
  getRandomUserBackground,
} from "../../../../../../Shared/Functions/user-bg-provider";
import { useSidebarStore } from "../edit/sidebar/sidebar-store";
import { PanelPositioning } from "../panelTypes";
import { useCorporateIdentityStore } from "../../../Settings/General/BrandAndColors/corporate-identity-store";
import useSavedCollections from "../../../../../../Shared/Hooks/useSavedCollections";
import Pagination from "../../../Shared/PanelsPagination/Pagination";
import { PanelsPaginationTypes } from "../../../Shared/PanelsPagination/panels-pagination-types";
import { usePageStore } from "../../../../page-store";
import { Pages } from "../../../../pages";
import { getUserValuesFromTwoFields } from "../../../Shared/Panels/shared/utils/user-values-extractor";
import { WidgetIds } from "../../../Settings/Widgets/consts/tabs";
import { getWidgetSettingById } from "../../../Settings/Widgets/api";
import { createProfileLink } from "../../../PeopleBase/Views/createProfileLink";
import PanelWrapper from "../../../Shared/Panels/shared/components/PanelWrapper";
import { ListItem, ListLabel, ListLabelWrapper, ListUser } from "../styles/peopleStyles";

// GRID
const UsersContainer = styled.div`
  display: grid;
  grid-template-columns: ${({ numberOfItems }) => `repeat(${numberOfItems || 4}, minmax(0, 1fr))`};
  grid-template-rows: 1fr;
  grid-gap: 24px;
`;

const CardLinkWrapper = styled.a`
  all: unset;
`;

const Card = styled.div`
  background: ${({ theme }) => theme.shared.card.background};
  box-shadow: 0px 0px 4px rgba(85, 85, 85, 0.06);
  border-radius: ${({ borderRadius }) => `${borderRadius}`};
  overflow: hidden;
  border: 1px solid ${({ theme }) => theme.shared.card.border.box};

  cursor: pointer;
`;

const CardBackground = styled.div`
  width: 100%;
  background: ${({ background }) => background};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 80px;
`;

const CardInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  align-items: center;
  justify-content: center;
  padding: 0px 16px 16px 16px;
  margin-top: ${({ imageHeight }) => `-${imageHeight / 2}px`};
  text-align: center;
`;

const Image = styled.img`
  width: ${({ imageSize }) => `${imageSize || 80}px`};
  height: ${({ imageSize }) => `${imageSize || 80}px`};
  box-sizing: border-box;
  border: 4px solid ${({ theme }) => theme.shared.card.border.avatar};
  border-radius: 50%;
  object-fit: cover;
  justify-self: center;
`;

const Name = styled.span`
  font-weight: 500;
  font-size: 15px;
  line-height: 19px;
  color: ${({ theme }) => theme.shared.card.title};
`;

const Position = styled.span`
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme }) => theme.newUsers.positionText};
  height: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
`;

const Date = styled.span`
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: ${({ isBlue, theme }) => (isBlue ? theme.newUsers.dateTodayText : theme.newUsers.dateText)};
  margin-top: 13px;
  flex: 1;
  display: flex;
  align-items: flex-end;
  min-height: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 32px;
`;

const GridCenter = styled.div`
  height: 32px;
  display: grid;
  place-content: center;
  margin-top: 8px;
`;

// LIST
const PeopleContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`;

const PersonWrapper = styled.div`
  display: grid;
  grid-template-columns: 52px auto 85px;
  column-gap: 16px;
  align-items: center;
`;
const PersonWrapperMain = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ backgroundColor }) => backgroundColor};
  border-radius: 3px;
  border: 1px solid ${({ borderColor }) => borderColor};
  box-shadow:
    rgba(9, 30, 66, 0.2) 0px 1px 3px,
    rgba(9, 30, 66, 0.1) 0px 0px 1px;

  & > div {
    padding: 10px 18px;

    &:not(:last-child) {
      border-bottom: 1px solid ${({ borderColor }) => borderColor};
    }
  }
`;

const PersonImage = styled.img`
  box-sizing: border-box;
  width: 52px;
  height: 52px;
  border-radius: 50%;
  object-fit: cover;
  user-select: none;
`;

const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
`;

const ListName = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: ${({ theme }) => theme.shared.card.title};

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ListField = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #6b778c;
  margin-top: 4px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 90px;
`;

const ListDate = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #6b778c;
  text-align: right;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const ListPerson = styled.div`
  display: flex;
  place-items: center;
  gap: 18px;
`;

const customStyles = {
  customDescriptionStyles: {
    width: "464px",
  },
};

const DummyContent = ({ savedData, isFullWidth, borderRadius, isCard = false }) => {
  const theme = useTheme();
  const dummyData = [
    {
      displayName: "Mary Lee",
      icon: "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
      accountId: "1234",
      position: "Project Manager",
      entryDate: 1685616039,
      background: "linear-gradient(270deg, #998DD9 0%, #5243AA 98.75%)",
    },
    {
      displayName: "John Smith",
      accountId: "123456",
      icon: "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80",
      position: "Android Developer",
      entryDate: 1689417639,
      background: "linear-gradient(270deg, #78E2F2 0%, #00A3BF 98.75%)",
    },
    {
      displayName: "Sarah Connor",
      accountId: "123",
      icon: "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80",
      position: "Product Manager",
      entryDate: 1692096039,
      background: "linear-gradient(270deg, #fc5c7d 0%, #6a82fb 98.75%)",
    },
    {
      displayName: "Jane Clark",
      accountId: "12345",
      icon: "https://images.unsplash.com/photo-1488426862026-3ee34a7d66df?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80",
      position: "Product Manager",
      entryDate: 1693305639,
      background: "linear-gradient(270deg, #870000 0%, #190a05 98.75%)",
    },
  ];

  const getBackground = (userData) => {
    if (savedData.backgroundType === "image") return userData.background;
    if (savedData.backgroundType === "none") return theme.global.background.surfaceOverlay;
  };

  if (isFullWidth) {
    return (
      <>
        {isCard ? (
          <>
            {dummyData.slice(0, 4).map((user) => (
              <Card key={user.accountId} borderRadius={borderRadius}>
                <CardBackground background={getBackground(user)} />
                <CardInfoContainer imageHeight={savedData.imageSize || 80}>
                  <Image imageSize={savedData.imageSize || 80} src={user.icon} />
                  <Name>{user.displayName}</Name>
                  <Position>{savedData.titleFieldOn ? user.position.toUpperCase() : ""}</Position>
                  <Date>{savedData.footerFieldOn ? dayjs.unix(user.entryDate).format("MMM D, YYYY") : ""}</Date>
                </CardInfoContainer>
              </Card>
            ))}
          </>
        ) : (
          <PersonWrapperMain backgroundColor={theme.shared.card.background} borderColor={theme.shared.card.border.box}>
            {dummyData.slice(0, 4).map((user) => (
              <ListItem key={user.accountId} target="_top" href={createProfileLink(user.accountId)}>
                <ListUser>
                  <Image imageSize={40} src={user.icon} />
                  <Name>{user.displayName}</Name>
                </ListUser>
                <ListLabelWrapper>
                  <ListLabel color={theme.shared.card.title}>{savedData.titleFieldOn ? user.position : ""}</ListLabel>
                </ListLabelWrapper>
                <ListLabelWrapper>
                  <ListLabel color={theme.shared.card.title}>
                    {savedData.footerFieldOn ? dayjs.unix(user.entryDate).format("MMM D, YYYY") : ""}
                  </ListLabel>
                </ListLabelWrapper>
              </ListItem>
            ))}
          </PersonWrapperMain>
        )}
      </>
    );
  }
  return (
    <>
      {dummyData.slice(0, 4).map((user) => (
        <PersonWrapper key={user.accountId}>
          <PersonImage src={user.icon} />
          <DetailsWrapper>
            <ListName>{user.displayName}</ListName>
            {savedData.titleFieldOn && <ListField>{user.position}</ListField>}
          </DetailsWrapper>
          {savedData.footerFieldOn && <ListDate>{dayjs.unix(user.entryDate).format("MMM D, YYYY")}</ListDate>}
        </PersonWrapper>
      ))}
    </>
  );
};

const GridView = ({
  panelTitle,
  data,
  people,
  savedData,
  isLoading,
  getBackground,
  createProfileLink,
  pagination,
  databaseField,
}) => {
  const { page, setPage, maxPage, hasNextPage } = pagination;

  const [showMoreClicked, setShowMoreClicked] = useState(false);

  const setDisableClickOutside = useSidebarStore((state) => state.setDisableClickOutside);
  const setSelectedPage = usePageStore((state) => state.setSelectedPage);
  const borderRadius = useCorporateIdentityStore((state) => state.borderRadius);

  useEffect(() => {
    setShowMoreClicked(false);
  }, [data.pagination, data.maxNumberOfItems, data.collection, databaseField]);

  return (
    <PanelWrapper
      panelTitle={panelTitle}
      displayOptions={{
        displayTitle: data?.displayTitle ?? true,
        displayBox: data?.displayBox ?? true,
      }}
    >
      <UsersContainer numberOfItems={data.maxNumberOfItems || 4}>
        {isLoading ? (
          <SpinnerContainer>
            <Spinner />
          </SpinnerContainer>
        ) : (
          <>
            {!databaseField ? (
              <DummyContent savedData={savedData} isFullWidth borderRadius={borderRadius} isCard={true} />
            ) : (
              <>
                {!!people?.length &&
                  people.map((user) => (
                    <CardLinkWrapper key={user.accountId} target="_top" href={createProfileLink(user.accountId)}>
                      <Card borderRadius={borderRadius}>
                        <CardBackground background={getBackground(user)} />
                        <CardInfoContainer imageHeight={savedData.imageSize || 80}>
                          <Image imageSize={savedData.imageSize || 80} src={user.icon} />
                          <Name>{user.name}</Name>
                          <Position>{savedData.titleFieldOn ? user.titleValue : ""}</Position>
                          <GridCenter />
                        </CardInfoContainer>
                      </Card>
                    </CardLinkWrapper>
                  ))}
              </>
            )}
          </>
        )}
      </UsersContainer>

      {databaseField && !!people.length && (
        <Pagination
          type={data.pagination === PanelsPaginationTypes.SHOW_MORE ? PanelsPaginationTypes.INFINITE : data.pagination}
          showMoreClicked={showMoreClicked}
          onClick={() => {
            if (hasNextPage && !showMoreClicked) {
              setPage(2);
              setShowMoreClicked(true);
              return;
            }

            setSelectedPage(Pages.PEOPLE);
          }}
          page={page}
          hasNextPage={hasNextPage}
          updatePage={setPage}
          maxPage={maxPage}
          onWrapperHoverCallback={setDisableClickOutside}
        />
      )}

      {!!databaseField && !people.length && !isLoading && (
        <EmptyState
          title="No New Starters"
          description="There are currently no new starters listed. New employee details will appear here as they join the company."
          Image={NoNewStartersSVG}
          customStyles={customStyles}
        />
      )}
    </PanelWrapper>
  );
};

const ListViewSide = ({
  panelTitle,
  savedData,
  isLoading,
  data,
  people,
  createProfileLink,
  pagination,
  databaseField,
}) => {
  const { page, setPage, maxPage, hasNextPage } = pagination;

  const [showMoreClicked, setShowMoreClicked] = useState(false);

  const setSelectedPage = usePageStore((state) => state.setSelectedPage);
  const setDisableClickOutside = useSidebarStore((state) => state.setDisableClickOutside);

  useEffect(() => {
    setShowMoreClicked(false);
  }, [data.pagination, data.maxNumberOfItems, data.collection]);

  return (
    <Panel>
      <PanelTitle panelPosition={PanelPositioning.SIDE} noBorder={true}>
        {panelTitle}
      </PanelTitle>
      <PanelContent>
        <PeopleContainer>
          {isLoading ? (
            <SpinnerContainer>
              <Spinner />
            </SpinnerContainer>
          ) : (
            <>
              {!databaseField ? (
                <DummyContent savedData={savedData} />
              ) : (
                <>
                  {!!people?.length &&
                    people.map((user) => (
                      <CardLinkWrapper key={user.accountId} target="_top" href={createProfileLink(user.accountId)}>
                        <PersonWrapper>
                          <PersonImage src={user.icon} />
                          <DetailsWrapper>
                            <ListName>{user.name}</ListName>
                            {savedData.titleFieldOn && <ListField>{user.titleValue}</ListField>}
                          </DetailsWrapper>
                          {savedData.footerFieldOn && <ListDate>{user.footerValue}</ListDate>}
                        </PersonWrapper>
                      </CardLinkWrapper>
                    ))}
                </>
              )}
            </>
          )}
        </PeopleContainer>

        {databaseField && !!people.length && (
          <Pagination
            type={
              data.pagination === PanelsPaginationTypes.SHOW_MORE ? PanelsPaginationTypes.INFINITE : data.pagination
            }
            showMoreClicked={showMoreClicked}
            onClick={() => {
              if (hasNextPage && !showMoreClicked) {
                setPage(2);
                setShowMoreClicked(true);
                return;
              }

              setSelectedPage(Pages.PEOPLE);
            }}
            page={page}
            hasNextPage={hasNextPage}
            updatePage={setPage}
            maxPage={maxPage}
            onWrapperHoverCallback={setDisableClickOutside}
          />
        )}

        {!!databaseField && !people.length && !isLoading && (
          <EmptyState
            title="No New Starters"
            description="There are currently no new starters listed. New employee details will appear here as they join the company."
            Image={NoNewStartersSVG}
          />
        )}
      </PanelContent>
    </Panel>
  );
};

const ListView = ({ panelTitle, savedData, isLoading, data, people, createProfileLink, pagination, databaseField }) => {
  const { page, setPage, maxPage, hasNextPage } = pagination;

  const [showMoreClicked, setShowMoreClicked] = useState(false);

  const theme = useTheme();

  const setSelectedPage = usePageStore((state) => state.setSelectedPage);
  const setDisableClickOutside = useSidebarStore((state) => state.setDisableClickOutside);

  useEffect(() => {
    setShowMoreClicked(false);
  }, [data.pagination, data.maxNumberOfItems, data.collection]);

  return (
    <PanelWrapper
      panelTitle={panelTitle}
      displayOptions={{
        displayTitle: data?.displayTitle ?? true,
        displayBox: data?.displayBox ?? true,
      }}
    >
      <PeopleContainer>
        {isLoading ? (
          <SpinnerContainer>
            <Spinner />
          </SpinnerContainer>
        ) : (
          <>
            {!databaseField ? (
              <DummyContent savedData={savedData} isFullWidth />
            ) : (
              <PersonWrapperMain
                backgroundColor={theme.shared.card.background}
                borderColor={theme.shared.card.border.box}
              >
                {!!people?.length &&
                  people.map((user) => (
                    <ListItem key={user.accountId} target="_top" href={createProfileLink(user.accountId)}>
                      <ListUser>
                        <Image imageSize={40} src={user.icon} />
                        <Name>{user.name}</Name>
                      </ListUser>
                      <ListLabelWrapper>
                        <ListLabel color={theme.shared.card.subtitle}>
                          {savedData.titleFieldOn ? user.titleValue : ""}
                        </ListLabel>
                      </ListLabelWrapper>
                      <ListLabelWrapper>
                        <ListLabel color={theme.shared.card.title}>
                          {savedData.footerFieldOn ? user.footerValue : ""}
                        </ListLabel>
                      </ListLabelWrapper>
                    </ListItem>
                  ))}
              </PersonWrapperMain>
            )}
          </>
        )}
      </PeopleContainer>

      {databaseField && !!people.length && (
        <Pagination
          type={data.pagination === PanelsPaginationTypes.SHOW_MORE ? PanelsPaginationTypes.INFINITE : data.pagination}
          showMoreClicked={showMoreClicked}
          onClick={() => {
            if (hasNextPage && !showMoreClicked) {
              setPage(2);
              setShowMoreClicked(true);
              return;
            }

            setSelectedPage(Pages.PEOPLE);
          }}
          page={page}
          hasNextPage={hasNextPage}
          updatePage={setPage}
          maxPage={maxPage}
          onWrapperHoverCallback={setDisableClickOutside}
        />
      )}

      {!!databaseField && !people.length && !isLoading && (
        <EmptyState
          title="No New Starters"
          description="There are currently no new starters listed. New employee details will appear here as they join the company."
          Image={NoNewStartersSVG}
          customStyles={customStyles}
        />
      )}
    </PanelWrapper>
  );
};

const DEFAULT_DATA = {
  titleFieldId: "FF2Vrog",
  footerFieldId: "dc49cu8",
  titleFieldOn: true,
  footerFieldOn: true,
  backgroundType: "color",
  rounding: 6,
  imageSize: 80,
};

function NewUsers({ panelTitle, data, position }) {
  const [start, setStart] = useState(undefined);
  const [people, setPeople] = useState([]);
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [savedData, setSavedData] = useState(DEFAULT_DATA);

  const [databaseField, setDatabaseField] = useState("");

  const isFullWidth = position === PanelPositioning.MAIN;

  const theme = useTheme();

  const { collectionsData } = useSavedCollections({
    collectionId: data?.collection,
    areOptions: false,
  });

  const { isLoading: loadingGlobalSetting, data: widgetSettingData } = useQuery(
    ["new-starters-global-setting", { widgetId: WidgetIds.NEW_STARTERS }],
    getWidgetSettingById,
    {
      retry: 0,
      select: (response) => {
        const { data } = response;
        return data;
      },
    },
  );

  useEffect(() => {
    if (loadingGlobalSetting) return;

    if (widgetSettingData?.widget?.selectedField) {
      setDatabaseField(widgetSettingData.widget.selectedField);
    }
  }, [loadingGlobalSetting, widgetSettingData]);

  const { isLoading: recordsLoading, data: recordsData } = useQuery(
    [
      "userdatabase-records",
      {
        filters: [
          ...(collectionsData?.filters || []),
          {
            column: databaseField,
            value: { range: [start] },
            type: "DATE",
            condition: "IS",
          },
          { isMultiUser: true, type: "BOOLEAN", value: true, column: "hidden", condition: "NOT" },
        ],
        confluenceGroup: data?.userGroup || collectionsData?.userGroup,
        sort: { fieldId: databaseField, order: "desc", fieldPrefix: "date_field" },
        limit: 12,
      },
    ],
    fetchRecords,
    {
      enabled: !!databaseField && !!start,
      retry: 0,
      select: (response) => {
        const { data } = response;
        return data.hits;
      },
    },
  );

  const { data: fieldsData } = useQuery(["userdatabase-fields", { includeEmail: false }], fetchUserDatabaseFields, {
    retry: 0,
    select: (response) => {
      const { data } = response;
      const matchingFieldsByType = data?.filter(
        (field) => field?.type === "SELECT" || field?.type === "TEXT" || field?.type === "DATE",
      );
      return matchingFieldsByType;
    },
  });

  const getDetailsForUsers = (users) => {
    if (users?.length) {
      setLoading(true);
      fetchDetailsForUsersIndividually(users.map((u) => u.accountId))
        .then((resUsers) => {
          if (resUsers?.length) {
            const formattedUsers = resUsers.map((user) => {
              return {
                accountId: user.accountId,
                name: user.displayName,
                icon: `${window.AP._hostOrigin}${user.profilePicture.path}`,
              };
            });
            setRecords([...formattedUsers]);
          }
        })
        .catch((e) => setPeople([]))
        .finally(() => setLoading(false));
    } else {
      setRecords([]);
    }
  };

  useEffect(() => {
    if (!!recordsData?.length && !!fieldsData?.length) {
      getDetailsForUsers(recordsData);
    } else {
      setRecords([]);
    }
  }, [recordsData, fieldsData]);

  useEffect(() => {
    if (!!records?.length && !!fieldsData) {
      const formattedPeople = records.map((user) => {
        const userValues = recordsData.find((r) => r.accountId === user.accountId)?.values;

        const {
          firstField: titleValue,
          secondField: footerValue,
          footerType,
        } = getUserValuesFromTwoFields(userValues, fieldsData, savedData.titleFieldId, databaseField);

        const backgroundImage = userValues?.user?.backgroundImage;
        const hasBgData = !!backgroundImage && !!Object.keys(backgroundImage || {}).length;
        const correctImage = hasBgData ? backgroundImage : getRandomUserBackground(IMAGE_TYPES.GRADIENT);

        const image = getCssForBackground(correctImage);
        return {
          ...user,
          titleValue,
          footerValue,
          footerType,
          backgroundImage: image,
        };
      });

      setPeople([...formattedPeople]);
    } else {
      setPeople([]);
    }
  }, [records, fieldsData, savedData.titleFieldId, savedData.footerFieldId]);

  useEffect(() => {
    const SEVEN_WEEKS = 4233600; 
    const today = dayjs().unix();
    const todayFixed = dayjs.unix(today).hour(0).minute(0).second(0).unix();
    const startDate = todayFixed - SEVEN_WEEKS;
    setStart(startDate);
  }, []);

  const maxNumberOfItems = data.maxNumberOfItems || 4;
  const indexOfLast = page * maxNumberOfItems;
  const indexOfFirst = indexOfLast - maxNumberOfItems;
  const maxPage = Math.ceil(people?.length / maxNumberOfItems);
  const hasNextPage = recordsData?.length > maxNumberOfItems;

  useEffect(() => {
    setSavedData(data);
  }, [data]);

  useEffect(() => {
    setPage(1);
  }, [maxNumberOfItems, data.pagination, databaseField, data.collection]);

  const dynamicIndexOfFirst = {
    [PanelsPaginationTypes.SHOW_MORE]: 0,
    [PanelsPaginationTypes.ARROWS]: indexOfFirst,
  };

  const peoplePaginated = people.slice(dynamicIndexOfFirst[data.pagination], indexOfLast);

  const getBackground = (userData) => {
    if (savedData.backgroundType === "image") return userData.backgroundImage;
    if (savedData.backgroundType === "none") return theme.global.background.surfaceOverlay;
  };

  if (isFullWidth) {
    return (
      <>
        {!data?.view || data.view === "card" ? (
          <>
            <GridView
              data={data}
              databaseField={databaseField}
              panelTitle={panelTitle}
              people={peoplePaginated}
              savedData={savedData}
              createProfileLink={createProfileLink}
              getBackground={getBackground}
              isLoading={loading || recordsLoading || loadingGlobalSetting}
              pagination={{ page, setPage, maxPage, hasNextPage }}
            />
          </>
        ) : (
          <>
            <ListView
              panelTitle={panelTitle}
              databaseField={databaseField}
              savedData={savedData}
              isLoading={loading || recordsLoading || loadingGlobalSetting}
              data={data}
              people={peoplePaginated}
              createProfileLink={createProfileLink}
              pagination={{ maxNumberOfItems, page, setPage, maxPage, hasNextPage }}
            />
          </>
        )}
      </>
    );
  }

  return (
    <ListViewSide
      panelTitle={panelTitle}
      databaseField={databaseField}
      savedData={savedData}
      isLoading={loading || recordsLoading || loadingGlobalSetting}
      data={data}
      people={peoplePaginated}
      createProfileLink={createProfileLink}
      pagination={{ maxNumberOfItems, page, setPage, maxPage, hasNextPage }}
    />
  );
}

export default NewUsers;
