import { xcss } from "@atlaskit/primitives";
import { token } from "@atlaskit/tokens";
import styled from "styled-components";

export const Placeholder = styled.div`
  height: 2px;
  margin: 1px 0px;
  background-color: ${token("color.background.accent.blue.subtlest.pressed")};
  visibility: ${({ isOver }) => (isOver ? "visible" : "hidden")};
`;

export const EntryBox = styled.div`
  #entry-actions {
    display: none;
  }

  &:hover {
    #entry-actions {
      display: block;
    }

    #content-grid {
      grid-template-columns: ${({ gridColumnsOnHover }) => gridColumnsOnHover};
    }
  }
`;

export const SpaceEntryBox = styled.div`
  #entry-actions {
    display: none;
  }

  &:hover {
    #entry-actions {
      display: block;
    }

    #content-grid {
      grid-template-columns: 24px 16px auto 24px;
    }
  }
`;

export const EntryName = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex-grow: 0;
  min-width: 0px;
  font-size: 14px;
  font-weight: 500;
  margin-left: 8px;
  color: ${token("color.text")};
`;

export const parentItemStyle = xcss({
  background: token("elevation.surface.sunken"),
  borderRadius: "3px",
  width: "100%",

  ":hover": {
    background: token("elevation.surface.hovered"),
  },
});

export const childItemStyle = xcss({
  background: token("elevation.surface"),
  borderRadius: "3px",
  width: "100%",

  ":hover": {
    background: token("elevation.surface.sunken"),
  },
});

export const iconStyle = xcss({
  cursor: "pointer",
  minWidth: "24px",
  width: "24px",
  height: "24px",
  display: "grid",
  placeContent: "center",
});

export const rotatableIconStyle = xcss({
  transition: "transform 0.2s ease",
  cursor: "pointer",
});

export const expandableChildrenStyle = xcss({
  transition: "max-height 0.2s ease",
  overflow: "hidden",
});
