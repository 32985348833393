import React from "react";
import { DesignSidebarWrapper, TabContent } from "./ActiveUsersEditor";
import { FieldContainer, Label } from "../../../../Dashboard/filter/styled";
import SimpleToggle from "../../../../Dashboard/filter/SimpleToggle";
import { ActiveUsersPropertyKeys } from "./configurator/propertyTypes";
import SelectFilter from "../../../../Dashboard/filter/SelectFilter";
import { StylingOptions } from "./configurator/options";
import SizeButtons from "../../../../../../../Shared/Components/SizeButtons";
import { PanelTypes } from "../../../../Dashboard/panels/panelTypes";
import { sizeType } from "../../../../../../../Shared/Components/size-options";
import { Divider } from "../../../../Dashboard/filter/styled";

const Design = ({ panel, updatePanelDataProperty, isPositionedInMainContent }) => {
  return (
    <DesignSidebarWrapper>
      <TabContent>
        <FieldContainer>
          <Label>Widget options</Label>
          <SimpleToggle
            label="Display title"
            value={panel.data.displayTitle}
            setValue={(value) => updatePanelDataProperty(panel.id, ActiveUsersPropertyKeys.DISPLAY_TITLE, value)}
            customStyles={{ padding: "10px 0px 8px 10px" }}
          />
          {isPositionedInMainContent && (
            <SimpleToggle
              label="Display box"
              value={panel?.data?.displayBox ?? true}
              setValue={(value) => updatePanelDataProperty(panel.id, ActiveUsersPropertyKeys.DISPLAY_BOX, value)}
              customStyles={{ padding: "10px 0px 8px 10px" }}
            />
          )}
        </FieldContainer>

        {isPositionedInMainContent && <Divider />}
        {isPositionedInMainContent && (
          <>
            <SelectFilter
              label="Styling"
              value={panel.data.backgroundType}
              setValue={(newValue) =>
                updatePanelDataProperty(panel.id, ActiveUsersPropertyKeys.BACKGROUND_TYPE, newValue)
              }
              options={StylingOptions}
            />
            <Divider />
            <SizeButtons
              label="Image size"
              types={{ panel: PanelTypes.ACTIVE_USERS, size: sizeType.PROFILE_IMAGE_SIZE }}
              input={{ min: 0, max: 150, step: 2, value: panel.data.profileImageSize }}
              setValue={(value) => updatePanelDataProperty(panel.id, ActiveUsersPropertyKeys.PROFILE_IMAGE_SIZE, value)}
            />
          </>
        )}
      </TabContent>
    </DesignSidebarWrapper>
  );
};

export default Design;
