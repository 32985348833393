import React, { useEffect, useState } from "react";
import { PanelKeys, PanelPositioning, PanelTypes } from "../../../panelTypes";
import { useSidebarStore } from "../sidebar-store";
import NewsEditor from "../../types/NewsEditor";
import Tabs from "../../../../../../../../Shared/Components/Tabs";
import { useMacroSuiteConnectionStore } from "../../../../../../../../Shared/store/macrosuite-connection-store";
import { TabTypes } from "../../../tabTypes";
import CountdownEditor from "../../types/CountdownEditor";
import VideosEditor from "../../types/VideosEditor";
import SpacesEditor from "../../types/SpacesEditor";
import BirthdayEditor from "../../types/BirthdayEditor";
import NotificationEditor from "../../types/NotificationEditor";
import PollEditor from "../../types/PollEditor";
import AppLinksEditor from "../../types/AppLinksEditor";
import TasksEditor from "../../types/TasksEditor";
import NewUsersEditor from "../../types/NewUsersEditor";
import UserStartEditor from "../../types/UserStartEditor";
import PagesEditor from "../../types/PagesEditor";
import CardsEditor from "../../../../../Shared/Panels/edit/cards/CardsEditor";
import PeopleEditor from "../../../../../Shared/Panels/edit/people/PeopleEditor";
import ActiveUsersEditor from "../../../../../Shared/Panels/edit/active-users/ActiveUsersEditor";
import PersonalizedEditor from "../../types/PersonalizedEditor";
import TargetedNewsEditor from "../../types/TargetedNewsEditor";
import GliffyEditor from "../../types/GliffyEditor";
import ReleasedAIEditor from "../../types/ReleasedAIEditor";

const NoContentOptions = [TabTypes.LAYOUT, TabTypes.FILTER, TabTypes.DESIGN];
const NoFilterOptions = [TabTypes.LAYOUT, TabTypes.DESIGN];
const NoDesignOptions = [TabTypes.LAYOUT, TabTypes.FILTER];
const ContentOptions = [TabTypes.LAYOUT, TabTypes.CONTENT, TabTypes.DESIGN];
const PeopleOptions = [TabTypes.LAYOUT, TabTypes.PEOPLE, TabTypes.DESIGN];
const NoLayoutOptions = [TabTypes.CONTENT, TabTypes.DESIGN];

const DEFAULT_SELECTED_TAB = "Layout";

function ConfigProvider({ selectedPanel, updatePanelDataProperty, updatePanelTitle }) {
  const [selectedTab, setSelectedTab] = useState(DEFAULT_SELECTED_TAB);

  const isPositionedInMainContent = useSidebarStore((state) => state.selectedContentType === PanelPositioning.MAIN);

  const isMacroSuiteInstalled = useMacroSuiteConnectionStore((state) => state.isMacroSuiteInstalled);

  const getTabOptionsBasedOnPanelType = () => {
    const options = {
      [PanelTypes.NEWS]: ContentOptions,
      [PanelTypes.PERSONALIZED_NEWS]: ContentOptions,
      [PanelTypes.TARGETED_NEWS]: ContentOptions,
      [PanelTypes.PAGES]: ContentOptions,
      [PanelTypes.COUNTDOWN]: NoFilterOptions,
      [PanelTypes.NOTIFICATION]: ContentOptions,
      [PanelTypes.VIDEOS]: isPositionedInMainContent ? ContentOptions : [],
      [PanelTypes.SPACES]: ContentOptions,
      [PanelTypes.BIRTHDAY]: isPositionedInMainContent ? ContentOptions : [],
      [PanelTypes.NEW_USERS_MAIN]: isPositionedInMainContent ? ContentOptions : [],
      [PanelTypes.PEOPLE]: PeopleOptions,
      [PanelTypes.POLL]: [],
      [PanelTypes.USEFUL_LINKS]: [],
      [PanelTypes.ASSIGNED_TASKS]: [],
      [PanelTypes.USER_START]: NoLayoutOptions,
      [PanelTypes.CARDS]: ContentOptions,
      [PanelTypes.ACTIVE_USERS]: NoFilterOptions,
      [PanelTypes.GLIFFY]: ContentOptions,
      [PanelTypes.RELEASED_AI]: ContentOptions,
    };

    return options[selectedPanel?.type] || [];
  };

  useEffect(() => {
    window.AP.events.on("change-tab-externally", (tab) => setSelectedTab(tab));
  }, []);

  if (!selectedPanel) return <></>;

  return (
    <>
      <Tabs tabs={getTabOptionsBasedOnPanelType()} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />

      {selectedPanel.type === PanelTypes.USER_START && (
        <UserStartEditor
          panel={selectedPanel}
          selectedTab={selectedTab}
          updatePanelDataProperty={updatePanelDataProperty}
        />
      )}

      {selectedPanel.type === PanelTypes.NEWS && (
        <NewsEditor
          panel={selectedPanel}
          selectedTab={selectedTab}
          updatePanelDataProperty={updatePanelDataProperty}
          updatePanelTitle={updatePanelTitle}
        />
      )}

      {selectedPanel.type === PanelTypes.PERSONALIZED_NEWS && (
        <PersonalizedEditor
          panel={selectedPanel}
          selectedTab={selectedTab}
          updatePanelDataProperty={updatePanelDataProperty}
          updatePanelTitle={updatePanelTitle}
        />
      )}

      {selectedPanel.type === PanelTypes.TARGETED_NEWS && (
        <TargetedNewsEditor
          panel={selectedPanel}
          selectedTab={selectedTab}
          updatePanelDataProperty={updatePanelDataProperty}
          updatePanelTitle={updatePanelTitle}
        />
      )}

      {selectedPanel.type === PanelTypes.PAGES && (
        <PagesEditor
          panel={selectedPanel}
          selectedTab={selectedTab}
          updatePanelDataProperty={updatePanelDataProperty}
          updatePanelTitle={updatePanelTitle}
        />
      )}

      {selectedPanel.type === PanelTypes.COUNTDOWN && (
        <CountdownEditor
          panel={selectedPanel}
          selectedTab={selectedTab}
          updatePanelDataProperty={updatePanelDataProperty}
          updatePanelTitle={updatePanelTitle}
        />
      )}

      {selectedPanel.type === PanelTypes.VIDEOS && (
        <VideosEditor
          panel={selectedPanel}
          selectedTab={selectedTab}
          updatePanelDataProperty={updatePanelDataProperty}
          updatePanelTitle={updatePanelTitle}
          isPositionedInMainContent={isPositionedInMainContent}
        />
      )}

      {selectedPanel.type === PanelTypes.SPACES && (
        <SpacesEditor
          panel={selectedPanel}
          selectedTab={selectedTab}
          updatePanelDataProperty={updatePanelDataProperty}
          updatePanelTitle={updatePanelTitle}
        />
      )}

      {selectedPanel.type === PanelTypes.ACTIVE_USERS && (
        <ActiveUsersEditor
          panel={selectedPanel}
          selectedTab={selectedTab}
          updatePanelDataProperty={updatePanelDataProperty}
          updatePanelTitle={updatePanelTitle}
          isPositionedInMainContent={isPositionedInMainContent}
        />
      )}

      {selectedPanel.type === PanelTypes.BIRTHDAY && (
        <BirthdayEditor
          panel={selectedPanel}
          selectedTab={selectedTab}
          isPositionedInMainContent={isPositionedInMainContent}
          updatePanelDataProperty={updatePanelDataProperty}
          updatePanelTitle={updatePanelTitle}
        />
      )}

      {selectedPanel.type === PanelTypes.NOTIFICATION && (
        <NotificationEditor
          panel={selectedPanel}
          selectedTab={selectedTab}
          updatePanelDataProperty={updatePanelDataProperty}
          updatePanelTitle={updatePanelTitle}
        />
      )}

      {selectedPanel.type === PanelTypes.POLL && (
        <PollEditor
          panel={selectedPanel}
          updatePanelDataProperty={updatePanelDataProperty}
          updatePanelTitle={updatePanelTitle}
        />
      )}

      {selectedPanel.type === PanelTypes.USEFUL_LINKS && (
        <AppLinksEditor
          panel={selectedPanel}
          updatePanelDataProperty={updatePanelDataProperty}
          updatePanelTitle={updatePanelTitle}
          isMacroSuiteInstalled={isMacroSuiteInstalled}
        />
      )}

      {selectedPanel.type === PanelTypes.ASSIGNED_TASKS && (
        <TasksEditor
          panel={selectedPanel}
          updatePanelDataProperty={updatePanelDataProperty}
          updatePanelTitle={updatePanelTitle}
        />
      )}

      {selectedPanel.type === PanelTypes.NEW_USERS_MAIN && (
        <NewUsersEditor
          panel={selectedPanel}
          selectedTab={selectedTab}
          isPositionedInMainContent={isPositionedInMainContent}
          updatePanelDataProperty={updatePanelDataProperty}
          updatePanelTitle={updatePanelTitle}
        />
      )}

      {selectedPanel.type === PanelTypes.PEOPLE && (
        <PeopleEditor
          panel={selectedPanel}
          selectedTab={selectedTab}
          isPositionedInMainContent={isPositionedInMainContent}
          updatePanelDataProperty={updatePanelDataProperty}
          updatePanelTitle={updatePanelTitle}
        />
      )}

      {selectedPanel.type === PanelTypes.CARDS && (
        <CardsEditor
          panel={selectedPanel}
          selectedTab={selectedTab}
          updatePanelDataProperty={updatePanelDataProperty}
          updatePanelTitle={updatePanelTitle}
          isInPeopleNetwork={false}
        />
      )}

      {selectedPanel.type === PanelTypes.GLIFFY && (
        <GliffyEditor
          panel={selectedPanel}
          selectedTab={selectedTab}
          updatePanelDataProperty={updatePanelDataProperty}
          updatePanelTitle={updatePanelTitle}
        />
      )}

      {selectedPanel.type === PanelTypes.RELEASED_AI && (
        <ReleasedAIEditor
          panel={selectedPanel}
          selectedTab={selectedTab}
          updatePanelDataProperty={updatePanelDataProperty}
        />
      )}
    </>
  );
}

export default ConfigProvider;
