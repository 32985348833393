import React, { useEffect } from "react";
import styled from "styled-components";
import { useCorporateIdentityStore } from "../../Components/Hub/Components/Settings/General/BrandAndColors/corporate-identity-store";
import { COLOR_TYPES } from "../../Components/Hub/Components/Settings/General/BrandAndColors/color-types";

const TabsWrapperButtons = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

const TabButton = styled.div`
  height: 28px;
  width: fit-content;
  box-sizing: border-box;

  border-radius: 13px;
  background-color: ${({ isSelected, primaryColor }) =>
    isSelected ? `${primaryColor.slice(0, 7)}1a` : "rgba(9 30 66 / 4%)"};
  color: ${({ isSelected, primaryColor }) => (isSelected ? primaryColor : "#42526e")};

  padding: 4px 1pc;
  margin-right: 4px;

  line-height: 20px;
  font-size: 14px;

  user-select: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  cursor: pointer;

  &:hover {
    ${({ isSelected }) => {
      if (!isSelected) {
        return `background-color: #ebecf0;`;
      }
    }}
  }
`;

const TabsWrapper = styled.div`
  width: 100%;
  position: relative;
  border-bottom: 2px solid rgba(9 30 66 / 15%);
  border-radius: 1px;

  ${({ fitContent, numberOfTabs }) => {
    if (fitContent) {
      return `
        display: flex;
        align-items: center;
        column-gap: 16px;
      `;
    }
    return `
      display: grid;
      grid-template-columns: ${`repeat(${numberOfTabs ?? 0}, minmax(0, 1fr))`};
      align-items: center;
      justify-content: center;
      text-align: center;
    `;
  }}
`;

const Tab = styled.div`
  ${({ fitContent }) => {
    if (fitContent) {
      return `width: fit-content;`;
    }
  }}

  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  padding-bottom: 7px;
  position: relative;
  bottom: -2px;
  color: ${({ isSelected, theme }) =>
    isSelected ? theme.shared.sidebar.tabs.text.active : theme.shared.sidebar.tabs.text.inactive};
  border-bottom: 2px solid
    ${({ isSelected, theme }) =>
      isSelected ? theme.shared.sidebar.tabs.border.active : theme.shared.sidebar.tabs.border.inactive};
  cursor: pointer;
`;

function Tabs({ tabs, selectedTab, setSelectedTab, showAsButtons, fitContent = false }) {
  const colors = useCorporateIdentityStore((state) => state.colors);
  const primaryColor = colors ? colors[COLOR_TYPES.PRIMARY] : "#0065ff";

  useEffect(() => {
    if (!tabs.find((t) => t === selectedTab)) {
      setSelectedTab(tabs[0]);
    }
  }, [selectedTab, tabs]);

  if (!tabs.length) return <></>;

  if (showAsButtons) {
    return (
      <TabsWrapperButtons>
        {tabs.map((tab) => (
          <TabButton
            key={tab}
            isSelected={selectedTab === tab}
            onClick={() => setSelectedTab(tab)}
            primaryColor={primaryColor}
          >
            {tab}
          </TabButton>
        ))}
      </TabsWrapperButtons>
    );
  }

  return (
    <TabsWrapper fitContent={fitContent} numberOfTabs={tabs.length}>
      {tabs.map((tab) => (
        <Tab
          key={tab}
          fitContent={fitContent}
          primaryColor={primaryColor}
          isSelected={selectedTab === tab}
          onClick={() => setSelectedTab(tab)}
        >
          {tab}
        </Tab>
      ))}
    </TabsWrapper>
  );
}

export default Tabs;
