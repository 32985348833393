import React, { useContext } from "react";
import HeaderSidebarDesign from "../../Shared/Header/customization/HeaderSidebarDesign";
import { Pages } from "../../../pages";
import { NewsroomAPIContext } from "../context";
import { useSidebarStore } from "../newsroom-store";
import { useHeaderStore } from "../../../store/header-store";

function HeaderDesign() {
  const { handleSaveHeaderSettings } = useContext(NewsroomAPIContext);

  const headerData = useHeaderStore((state) => state.headerData);

  const setIsSidebarOpen = useSidebarStore((state) => state.setIsSidebarOpen);

  const primaryColor = "#0065ff";
  const primaryContrastColor = "#ffffff";

  return (
    <HeaderSidebarDesign
      header={headerData[Pages.NEWS]}
      saveHeaderSettings={handleSaveHeaderSettings}
      pageToUpdate={Pages.NEWS}
      closePanel={() => setIsSidebarOpen(false)}
      primaryColor={primaryColor}
      primaryContrastColor={primaryContrastColor}
    />
  );
}

export default HeaderDesign;
