import React, { useState } from "react";
import PropTypes from "prop-types";

import Spinner from "@atlaskit/spinner";
import EditorSuccessIcon from "@atlaskit/icon/glyph/editor/success";
import EditorErrorIcon from "@atlaskit/icon/glyph/editor/error";

import { attachmentsService } from "../../AttachmentsService";

import { validateAttachments } from "./uploadService";
import { AlertData, AlertTypes } from "./alertTypes";

import {
  AttachmentsDesc,
  AttachmentsLink,
  AttachmentsTitle,
  AttachmentsUploadBox,
  AlertContainer,
  AlertMsg,
} from "./styled";

const UploadSection = ({ selectedPage, loadAttachments, isDraft, isDisabled }) => {
  const [uploadAlert, setUploadAlert] = useState(AlertData[AlertTypes.OFF]);

  const uploadAttachmentsToPage = async (attForUpload) => {
    const localFiles = attForUpload.filter((item) => item.file.name).map((att) => att.file);

    if (localFiles.length > 15) {
      setUploadAlert(AlertData[AlertTypes.ITEM_LIMIT]);
      setTimeout(() => {
        setUploadAlert(AlertData[AlertTypes.OFF]);
      }, 3000);
      return;
    }

    if (localFiles.length) {
      setUploadAlert(AlertData[AlertTypes.UPLOADING]);

      const formData = new FormData();
      formData.append("pageStatus", isDraft ? "draft" : "current");
      formData.append("contentId", selectedPage);
      localFiles.forEach((file) => {
        formData.append("file", file);
      });

      try {
        const response = await attachmentsService.uploadAttachments(formData);
        if (response.status === 200) {
          setUploadAlert(AlertData[AlertTypes.SUCCESS]);
          setTimeout(() => {
            loadAttachments(selectedPage);
          }, 2500);
        }
      } catch (err) {
        if (err?.response?.status === 413) {
          setUploadAlert(AlertData[AlertTypes.SIZE_LIMIT]);
        } else {
          setUploadAlert(AlertData[AlertTypes.ERROR]);
        }
      } finally {
        setTimeout(() => {
          setUploadAlert(AlertData[AlertTypes.OFF]);
        }, 3000);
      }
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleInputChange = async (e) => {
    if (isDisabled) return;
    const files = await validateAttachments(e.target.files);
    if (files) {
      await uploadAttachmentsToPage([...files]);
    }
    e.target.value = "";
  };

  const handleDropAttachment = async (e) => {
    if (isDisabled) return;

    e.preventDefault();
    if (e.dataTransfer.getData("text")) {
      return;
    }
    const files = await validateAttachments(e.dataTransfer.files);
    if (files) {
      await uploadAttachmentsToPage([...files]);
    }
  };

  return (
    <>
      <AttachmentsUploadBox
        isDisabled={isDisabled}
        id="drop_zone"
        onDrop={(e) => handleDropAttachment(e)}
        onDragOver={(e) => handleDragOver(e)}
      >
        <img src="/images/AttachmentsUpload.svg" alt="" />
        <div>
          <AttachmentsTitle>Upload images</AttachmentsTitle>
          <AttachmentsDesc>
            Drop an image or click&nbsp;
            <AttachmentsLink isDisabled={isDisabled} htmlFor="upload">
              here
            </AttachmentsLink>
            <input
              disabled={isDisabled}
              type="file"
              id="upload"
              name="upload"
              multiple
              onChange={handleInputChange}
              style={{ display: "none" }}
            />
            &nbsp;to upload.
            <br />
            It is required to select the page first.
          </AttachmentsDesc>
        </div>
      </AttachmentsUploadBox>

      <AlertContainer>
        {uploadAlert.active && (
          <AlertMsg>
            <span>{uploadAlert.msg}</span>
            <span style={{ height: "24px", width: "24px" }}>
              {uploadAlert.status === "error" && <EditorErrorIcon primaryColor="#FF5630" />}
              {uploadAlert.status === "success" && <EditorSuccessIcon primaryColor="#36B37E" />}
              {uploadAlert.status === "loading" && <Spinner size="small" />}
            </span>
          </AlertMsg>
        )}
      </AlertContainer>
    </>
  );
};

UploadSection.defaultProps = {
  selectedPage: null,
};

UploadSection.propTypes = {
  selectedPage: PropTypes.string,
  loadAttachments: PropTypes.func.isRequired,
  isDraft: PropTypes.bool.isRequired,
};

UploadSection.displayName = "UploadSection";

export default UploadSection;
