import React, { useEffect } from "react";
import styled from "styled-components";
import Button from "@atlaskit/button";
import { Container } from "./Filters";
import { useDatabaseUIStore } from "../database-ui-store";

const StyledButtonFilter = styled(Button)`
  &&& {
    font-size: 13px;
    font-weight: 550;
    text-align: left;
    padding-right: 16px;
    color: ${({ theme }) => theme.shared.settings.database.menuBarColor} !important;
    background-color: ${({ isSelected, theme }) =>
      isSelected ? theme.manualOrgChart.buttonColorActive : theme.manualOrgChart.buttonColor};

    &:hover {
      background-color: ${({ theme }) => theme.manualOrgChart.buttonColorActive};
    }
  }
`;

const ClearFilters = () => {
  const { filters, selectedFilters, resetFilters } = useDatabaseUIStore((state) => {
    return {
      filters: state.filters,
      setFilters: state.setFilters,
      selectedFilters: state.selectedFilters,
      setSelectedFilters: state.setSelectedFilters,
      resetFilters: state.resetFilters,
    };
  });

  if (!selectedFilters.length) {
    return <></>;
  }

  return (
    <Container>
      <StyledButtonFilter
        onClick={() => {
          resetFilters(filters);
        }}
      >
        {selectedFilters[0].value.length === 1 ? "Clear filter" : "Clear filters"}
      </StyledButtonFilter>
    </Container>
  );
};

export default ClearFilters;
