import React from "react";

import { NavigationIconContainer, NavigationIconLabel, ScreenIconsContainer, ScreenNavigation } from "../../styles";
import HomeIcon from "../Icons/HomeIcon";
import NewsIcon from "../Icons/NewsIcon";
import PeopleIcon from "../Icons/PeopleIcon";
import { useCosmosAppStore } from "../../cosmos-app-store";

const navigation = [
  { id: 1, Icon: HomeIcon, label: "Home", value: "home" },
  { id: 2, Icon: NewsIcon, label: "News", value: "newsroom" },
  { id: 3, Icon: PeopleIcon, label: "People", value: "people" },
];

const Navigation = ({ screenType }) => {
  const cosmosAppSettings = useCosmosAppStore((state) => state.cosmosAppSettings);

  return (
    <ScreenNavigation>
      <ScreenIconsContainer>
        {navigation.map(({ id, Icon, label, value }) => (
          <NavigationIconContainer key={id}>
            <div style={{ width: "24px", height: "24px" }}>
              <Icon color={screenType === value ? cosmosAppSettings?.brand?.color : "#757F8A"} />
            </div>
            <NavigationIconLabel style={{ color: screenType === value ? cosmosAppSettings?.brand?.color : "#757F8A" }}>
              {label}
            </NavigationIconLabel>
          </NavigationIconContainer>
        ))}
      </ScreenIconsContainer>
    </ScreenNavigation>
  );
};

export default Navigation;
