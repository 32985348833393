import React, { useCallback, useState } from "react";

import Tabs, { Tab, TabList } from "@atlaskit/tabs";
import PublishUnpublish from "./PublishUnpublish";
import UnderConstruction from "./UnderConstruction";
import Maintenance from "./Maintenance";

const tabComponent = {
  0: PublishUnpublish,
  1: UnderConstruction,
  2: Maintenance,
};

const tabs = ["Publish/Unpublish", "Under Construction", "Maintenance"];

const TabsContent = ({ isLoadingCosmosAppSettings }) => {
  const [tabIndex, setTabIndex] = useState(0);

  const TabComponent = tabComponent[tabIndex];

  const handleOnChange = useCallback((index) => setTabIndex(index), [setTabIndex]);

  return (
    <Tabs onChange={handleOnChange} selected={tabIndex}>
      <TabList>
        {tabs.map((tab) => (
          <Tab>{tab}</Tab>
        ))}
      </TabList>
      <TabComponent isLoadingCosmosAppSettings={isLoadingCosmosAppSettings} />
    </Tabs>
  );
};

export default TabsContent;
