import React, { useEffect, useState } from "react";
import { usePanelsStore } from "../../../../../Dashboard/store/panels-store";
import PanelWrapper from "../../../shared/components/PanelWrapper";
import NoDatabaseSync from "../../../shared/components/NoDatabaseSync";
import EmptyState from "../../../../../../../../Shared/Components/EmptyState";
import {
  CardLinkWrapper,
  DetailsWrapper,
  LinkWrapper,
  ListName,
  PersonImage,
} from "../../../../../Dashboard/panels/styles/peopleStyles";
import styled, { useTheme } from "styled-components";
import { createProfileLink } from "../../../../../PeopleBase/Views/createProfileLink";
import Pagination from "../../../../PanelsPagination/Pagination";
import Spinner from "@atlaskit/spinner";
import { SpinnerContainer } from "../../../../../../styled/pages";
import { usePageStore } from "../../../../../../page-store";
import { useSidebarStore } from "../../../../../Dashboard/panels/edit/sidebar/sidebar-store";
import { PanelsPaginationTypes } from "../../../../PanelsPagination/panels-pagination-types";
import { Pages } from "../../../../../../pages";
import { useChangesStore } from "../../../../../../track-changes-store";

const PersonWrapper = styled.div`
  display: grid;
  grid-template-columns: 52px auto 85px;
  column-gap: 16px;
  align-items: center;
`;

const ListField = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #6b778c;
  margin-top: 4px;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ListDate = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #6b778c;
  text-align: right;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: default;
`;

const SideView = ({
  panelData,
  isLoading,
  activeUsers,
  activeUsersFilter,
  peoplePaginated,
  pagination,
  primaryColor,
  panelTitle,
}) => {
  const { page, setPage, maxPage } = pagination;
  const [showMoreClicked, setShowMoreClicked] = useState(false);
  const hasInitDatabase = usePanelsStore((state) => state.hasInitEmployeeDatabase);
  const setSelectedPage = usePageStore((state) => state.setSelectedPage);
  const setDisableClickOutside = useSidebarStore((state) => state.setDisableClickOutside);
  const hasChanges = useChangesStore((state) => state.hasChanges);
  const filteredUsers = peoplePaginated.filter(activeUsersFilter);
  const theme = useTheme();

  useEffect(() => {
    setShowMoreClicked(false);
  }, [panelData.pagination, panelData.maxNumberOfItems]);

  const renderUserCard = (user) => {
    const { status, type, userData, linkToPage, friendlyModified } = user;
    const event =
      type === "comment"
        ? panelData.events.find((event) => event.type === "comment" || event.label === "Comment posted")
        : panelData.events.find((rule) => status === rule.status && type === rule.type);
    const showCard = event && event.value;

    if (showCard) {
      return (
        <CardLinkWrapper key={userData.accountId}>
          <PersonWrapper>
            <LinkWrapper target="_top" href={createProfileLink(userData.accountId)}>
              <PersonImage src={userData.profilePicture} />
            </LinkWrapper>
            <DetailsWrapper>
              <LinkWrapper showUnderline={false} target="_top" href={createProfileLink(userData.accountId)}>
                <ListName>{userData.name}</ListName>
              </LinkWrapper>
              <ListField>
                {status}&nbsp;
                <LinkWrapper target="_blank" href={linkToPage}>
                  <span className="link" style={{ color: primaryColor }}>
                    a {type}
                  </span>
                </LinkWrapper>
                .
              </ListField>
            </DetailsWrapper>
            <ListDate>{friendlyModified}</ListDate>
          </PersonWrapper>
        </CardLinkWrapper>
      );
    }

    return null;
  };

  const hasResults = peoplePaginated.some(
    (user) => panelData.events.find((rule) => user.status === rule.status && user.type === rule.type)?.value,
  );
  const showPagination =
    (filteredUsers.map(renderUserCard).filter((user) => !!user).length === panelData.maxNumberOfItems &&
      activeUsers.length > panelData.maxNumberOfItems) ||
    panelData.pagination === PanelsPaginationTypes.ARROWS;
  const filteredUsersMaxPage = Math.ceil(
    activeUsers
      .filter(activeUsersFilter)
      .map(renderUserCard)
      .filter((user) => user !== null).length / panelData.maxNumberOfItems,
  );

  return (
    <PanelWrapper
      panelTitle={panelTitle}
      displayOptions={{
        displayTitle: panelData.displayTitle,
        displayBox: true,
      }}
    >
      {isLoading && (
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
      )}

      {!hasInitDatabase && !isLoading && <NoDatabaseSync />}

      {!activeUsers?.length && !isLoading && (
        <EmptyState
          title="No one to show"
          description="Add, remove or adjust filters in the panel configuration."
          Image={() => {
            return <img src={`/images/hub/no-active-users-empty-state-${theme.global.name}.svg`} alt="" />;
          }}
        />
      )}

      {!!activeUsers?.length && !isLoading && hasInitDatabase && (
        <>
          {hasResults ? (
            <>
              <div style={{ display: "flex", flexDirection: "column", rowGap: "16px" }}>
                {peoplePaginated.map(renderUserCard)}
              </div>
              {(showPagination || showMoreClicked) && (
                <Pagination
                  type={
                    panelData.pagination === PanelsPaginationTypes.SHOW_MORE
                      ? PanelsPaginationTypes.INFINITE
                      : panelData.pagination
                  }
                  showMoreClicked={showMoreClicked}
                  onClick={() => {
                    if (showPagination && !showMoreClicked) {
                      setPage(2);
                      setShowMoreClicked(true);
                      return;
                    }

                    setSelectedPage(Pages.PEOPLE);
                  }}
                  page={page}
                  hasNextPage={showPagination}
                  updatePage={setPage}
                  maxPage={filteredUsersMaxPage}
                  onWrapperHoverCallback={setDisableClickOutside}
                />
              )}
            </>
          ) : (
            <EmptyState
              title="No one to show"
              description="Add, remove or adjust filters in the panel configuration."
              Image={() => {
                return <img src={`/images/hub/no-active-users-empty-state-${theme.global.name}.svg`} alt="" />;
              }}
            />
          )}
        </>
      )}
    </PanelWrapper>
  );
};

export default SideView;
