import styled from "styled-components";
import { convertHexToRGB } from "../../Background/convertHexToRgb";

export const ProviderOptionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;
  row-gap: 10px;
  margin: 15px 0px;
`;

export const ProviderSelector = styled.div`
  display: inline-block;
  border-radius: 50px;
  background-color: ${({ isChecked, primaryColor, theme }) => {
    return isChecked
      ? convertHexToRGB({ color: primaryColor?.length > 7 ? primaryColor.slice(0, -2) : primaryColor, opacity: 0.25 })
      : theme.icons.providerPickerBackground;
  }};
  color: ${({ isChecked, theme }) => (isChecked ? "#0065ff" : theme.icons.providerPickerColor)};
  line-height: 13px;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  padding: 5px 15px;
  white-space: nowrap;
  cursor: pointer;
  border-bottom: 1.5px solid ${({ theme }) => theme.icons.providerBorder};
`;

export const OverflowContainer = styled.div`
  padding-top: 21px;
  padding-right: 15px;
  padding-left: 5px;

  height: calc(100% - 200px);
  overflow: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.scrollbar};
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #999;
  }
`;

export const IconsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(36px, 1fr));
  column-gap: 10px;
  row-gap: 9px;
`;

export const SingleIconContainer = styled.span`
  cursor: pointer;

  svg {
    width: 32px;
    height: 32px;
    color: ${({ theme }) => theme.manualOrgChart.buttonTextColor};
  }

  &:hover svg {
    margin: -4px;
    padding: 4px;
    border-radius: 3px;
    background: ${({ theme }) => theme.manualOrgChart.minusPlusColor};
  }
`;

export const MessageContainer = styled.div`
  color: #42526e;
  font-size: 14px;
  text-align: center;
  padding-top: 5px;
  border-top: 1.5px solid #f2f3f7;
`;
