import create from "zustand";
import { ChangeOperations, handleFieldChange, handleRecordChange } from "./change-handler";
import { FieldType } from "./field-type";
import { atlassianRestService } from "../../Service/AtlassianRestService";

const SortTypes = {
  ASCENDING: "asc",
  DESCENDING: "desc",
};

function addOneChange(change, updatedChanges, records) {
  const changeOperation = change.operation;
  const found = records.find((r) => r.accountId === change.accountId);

  if (changeOperation in updatedChanges) {
    if (change.accountId in updatedChanges[changeOperation]) {
      updatedChanges[changeOperation][change.accountId].values = {
        ...found.values,
        ...updatedChanges[changeOperation][change.accountId].values,
        ...change.values,
      };
    } else {
      const newChangeForAccount = {
        [change.accountId]: {
          accountId: change.accountId,
          values: { ...found.values, ...change.values },
        },
      };
      updatedChanges[changeOperation] = { ...updatedChanges[changeOperation], ...newChangeForAccount };
    }
  } else {
    updatedChanges[changeOperation] = {
      [change.accountId]: {
        accountId: change.accountId,
        values: { ...found.values, ...change.values },
      },
    };
  }
}

async function getUserByAccountId(accountId) {
  const result = await atlassianRestService.getUserByAccountId(accountId);
  if (result?.accountId) {
    let user = {
      avatarUrl: atlassianRestService.getProfilePictureUrl(result.profilePicture.path),
      id: result.accountId,
      name: `${result.publicName} ${result.isExternalCollaborator ? " (Guest)" : ""}`,
      isGuestUser: result.isExternalCollaborator,
      type: "user",
    };
    return user;
  }
}

function getUpdatedChanges(changeOperation, updatedChanges, fieldId, key, change) {
  if (changeOperation in updatedChanges) {
    if (fieldId in updatedChanges[changeOperation]) {
      if (key) {
        updatedChanges[changeOperation][fieldId][key] = {
          ...updatedChanges[changeOperation][fieldId][key],
          ...change,
        };
      } else {
        updatedChanges[changeOperation][fieldId] = {
          ...updatedChanges[changeOperation][fieldId],
          ...change,
        };
      }
    } else {
      if (key) {
        const newChangeForField = {
          [fieldId]: {
            [key]: { ...change },
          },
        };
        updatedChanges[changeOperation] = { ...updatedChanges[changeOperation], ...newChangeForField };
      } else {
        const newChangeForField = {
          [fieldId]: { ...change },
        };
        updatedChanges[changeOperation] = { ...updatedChanges[changeOperation], ...newChangeForField };
      }
    }
  } else {
    if (key) {
      updatedChanges[changeOperation] = {
        [fieldId]: {
          [key]: change,
        },
      };
    } else {
      updatedChanges[changeOperation] = {
        [fieldId]: {
          ...change,
        },
      };
    }
  }
  return updatedChanges;
}

function updateChangeWithNewField(field, updatedChanges) {
  const changeOperation = ChangeOperations.UPDATE_FIELDS;
  if (changeOperation in updatedChanges) {
    const newField = {
      [field.id]: {
        ...field,
      },
    };
    updatedChanges[changeOperation] = { ...updatedChanges[changeOperation], ...newField };
  } else {
    updatedChanges[changeOperation] = {
      [field.id]: {
        ...field,
      },
    };
  }
  return updatedChanges;
}

export const useRecordsStore = create((set, get) => ({
  fields: [],
  records: [],
  recordEmails: [],
  emailsOfAccountIdsAlreadyFetched: [],
  changes: {},
  syncedGroup: undefined,
  lastResync: undefined,
  syncGuestUsers: false,
  numberOfTotalUsers: 0,
  numberOfHiddenUsers: 0,
  numberOfActiveUsers: 0,
  hiddenUsersSelection: { selectionType: 0, selectedUsers: [] },
  setNumberOfTotalUsers: (totalUsers) => set((state) => ({ numberOfTotalUsers: totalUsers })),
  setNumberOfActiveUsers: (activeUsers) => set((state) => ({ numberOfActiveUsers: activeUsers })),
  setNumberOfHiddenUsers: (hiddenUsers) => set((state) => ({ numberOfHiddenUsers: hiddenUsers })),
  setLastResync: () => set((state) => ({ lastResync: new Date() })),
  setSyncedGroup: (syncedGroup) => set((state) => ({ syncedGroup })),
  setFields: (fields) => set((state) => ({ fields })),
  setRecords: (records) => set((state) => ({ records })),
  setRecordEmails: (recordEmails) => set((state) => ({ recordEmails })),
  setEmailsOfAccountIdsAlreadyFetched: (accountIds) => {
    const accountIdsAlreadyFetched = [...get().emailsOfAccountIdsAlreadyFetched];
    accountIdsAlreadyFetched.push(...accountIds);
    return set((state) => ({
      emailsOfAccountIdsAlreadyFetched: accountIdsAlreadyFetched,
    }));
  },
  appendRecordEmails: (newEmails) => {
    const mails = [...get().recordEmails];
    mails.push(...newEmails);
    return set((state) => ({ recordEmails: mails }));
  },
  resetChanges: () => set((state) => ({ changes: {} })),
  setHiddenUsersSelection: (hiddenUsersSelection) => set((state) => ({ hiddenUsersSelection })),
  setSyncGuestUsers: (syncGuestUsersState) => set((state) => ({ syncGuestUsers: syncGuestUsersState })),
  reorderFields: (sourceId, targetId) => {
    if (sourceId === targetId) {
      return;
    }
    const newFields = JSON.parse(JSON.stringify(get().fields));
    const sourceIndex = newFields.findIndex((f) => f.id === sourceId);
    const source = newFields.find((f) => f.id === sourceId);
    const targetIndex = newFields.findIndex((f) => f.id === targetId);
    const target = newFields.find((f) => f.id === targetId);
    const newPositionOfSource = target.ui.position;

    source.ui.position = newPositionOfSource;
    get().updateFieldUi(source.id, { position: newPositionOfSource });
    if (sourceIndex < targetIndex) {
      //moved from left to right
      newFields.slice(sourceIndex + 1, targetIndex + 1).forEach((f) => {
        const newPosition = f.ui.position - 1;
        f.ui.position = newPosition;
        get().updateFieldUi(f.id, { position: newPosition });
      });
    }
    if (sourceIndex > targetIndex) {
      //moved from right to left
      newFields.slice(targetIndex, sourceIndex).forEach((f) => {
        const newPosition = f.ui.position + 1;
        f.ui.position = newPosition;
        get().updateFieldUi(f.id, { position: newPosition });
      });
    }
    return set((state) => ({ fields: newFields.sort((a, b) => a.ui.position - b.ui.position) }));
  },
  insertField: (field) => {
    const oldFields = JSON.parse(JSON.stringify(get().fields));

    let fields = oldFields.map((data) => {
      data.ui.position = data.ui.position + (data.ui.position < field.ui.position ? 0 : 1);
      return data;
    });

    fields = [...fields, field].sort((a, b) => a.ui.position - b.ui.position);

    return set((state) => ({ fields, changes: updateChangeWithNewField(field, { ...get().changes }) }));
  },
  updateField: (fieldId, change, key) => {
    const changeOperation = ChangeOperations.UPDATE_FIELDS;
    const updatedChanges = getUpdatedChanges(changeOperation, { ...get().changes }, fieldId, key, change);
    const newFields = handleFieldChange(get().fields, fieldId, change);

    return set((state) => ({ changes: updatedChanges, fields: newFields }));
  },
  updateFieldUi: (fieldId, uiChange, key = "ui") => {
    const changeOperation = ChangeOperations.UPDATE_FIELDS;
    const updatedChanges = getUpdatedChanges(changeOperation, { ...get().changes }, fieldId, key, uiChange);

    return set((state) => ({ changes: updatedChanges }));
  },
  createField: (field) => {
    const newFields = JSON.parse(JSON.stringify(get().fields));
    newFields.push(field);
    return set((state) => ({ changes: updateChangeWithNewField(field, { ...get().changes }), fields: newFields }));
  },
  deleteField: (fieldId) => {
    const changeOperation = ChangeOperations.DELETE_FIELDS;
    const updatedChanges = { ...get().changes };
    if (changeOperation in updatedChanges) {
      updatedChanges[changeOperation] = [...updatedChanges[changeOperation], fieldId];
    } else {
      updatedChanges[changeOperation] = [fieldId];
    }
    const newFields = [...get().fields].filter((f) => f.id !== fieldId);

    return set((state) => ({ changes: updatedChanges, fields: newFields }));
  },
  addChange: (change) => {
    const updatedChanges = { ...get().changes };
    const records = get().records;
    addOneChange(change, updatedChanges, records);
    return set((state) => ({ changes: updatedChanges }));
  },

  addMultipleChange: (multipleChanges) => {
    const updatedChanges = { ...get().changes };
    multipleChanges.forEach((change) => {
      const records = get().records;
      addOneChange(change, updatedChanges, records);
    });
    return set((state) => ({ changes: updatedChanges }));
  },
  applyFieldChange: async (fieldId, change) => {
    const changedFields = handleFieldChange(get().fields, fieldId, change);
    if (!changedFields) {
      return;
    }
    return set((state) => {
      return { fields: changedFields };
    });
  },
  applyRecordChange: async (accountId, change) => {
    const changedRecords = handleRecordChange(get().records, accountId, change);
    if (!changedRecords) {
      return;
    }
    return set((state) => {
      return { records: changedRecords };
    });
  },
  sortRecords: async (sortOrder, fieldId) => {
    const records = get().records;
    const recordEmails = get().recordEmails;
    const fieldID = fieldId.toUpperCase();

    if (!records.length) {
      return;
    }

    if (fieldID === FieldType.USER) {
      const promises = records.map((record) => getUserByAccountId(record.accountId));
      let sortedUsers = await Promise.all(promises);

      sortedUsers = sortedUsers.sort((a, b) => {
        const userA = a.name.toLowerCase();
        const userB = b.name.toLowerCase();

        if (sortOrder === SortTypes.ASCENDING) {
          return userB > userA ? 1 : -1;
        }
        return userA > userB ? 1 : -1;
      });
      const accountIdToRecordMap = {};

      records.forEach((record) => {
        accountIdToRecordMap[record.accountId] = record;
      });
      const sortedRecordsBasedOnName = sortedUsers.map((sortedUser) => accountIdToRecordMap[sortedUser.id]);

      get().setRecords(sortedRecordsBasedOnName);
    } else if (fieldID === FieldType.EMAIL) {
      const sortedEmails = [...recordEmails].sort((a, b) => {
        const emailA = a.email.toLowerCase();
        const emailB = b.email.toLowerCase();

        if (sortOrder === SortTypes.ASCENDING) {
          return emailB > emailA ? 1 : -1;
        }
        return emailA > emailB ? 1 : -1;
      });
      const accountIdToRecordMap = {};

      records.forEach((record) => {
        accountIdToRecordMap[record.accountId] = record;
      });
      const sortedRecordsBasedOnEmails = sortedEmails.map((sortedEmail) => accountIdToRecordMap[sortedEmail.accountId]);

      get().setRecords(sortedRecordsBasedOnEmails);
    } else {
      if (sortOrder === SortTypes.ASCENDING) {
        const sortedRecordsAsc = records.slice().sort((a, b) => {
          const dateA = a.values[fieldId];
          const dateB = b.values[fieldId];
          return dateA - dateB;
        });

        get().setRecords(sortedRecordsAsc);
      } else if (sortOrder === SortTypes.DESCENDING) {
        const sortedRecordsDesc = records.slice().sort((a, b) => {
          const dateA = b.values[fieldId];
          const dateB = a.values[fieldId];
          return dateA - dateB;
        });

        get().setRecords(sortedRecordsDesc);
      }
    }
  },
}));
