import React from "react";
import styled from "styled-components";
import { useSidebarStore } from "../store/sidebar-store";
import { SIDEBAR_CONTENTS, SIDEBAR_TYPES } from "./types";
import { HeaderIcon } from "../../../icons/SidebarHeaderIcon";
import { ArrowRightIcon, CardLayoutIcon } from "../../../icons/SidebarIcons";

const TabSelectorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 32px;
  padding-top: 32px;
`;

const TabWrapper = styled.div`
  display: grid;
  grid-template-columns: 20px auto 20px;
  column-gap: 16px;
  row-gap: 12px;
  align-items: flex-start;
  cursor: pointer;
`;

const Title = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.shared.sidebar.positioning.title};
  user-select: none;
  display: block;
`;

const Description = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.shared.sidebar.positioning.description};
  margin-top: 12px;
`;

const IconContainer = styled.div`
  display: grid;
  place-content: center;
  width: 24px;
  height: 24px;
`;

function ContentSelector() {
  const { setSelectedContentType, setSidebarType } = useSidebarStore((state) => ({
    setSelectedContentType: state.setSelectedContentType,
    setSidebarType: state.setSidebarType,
  }));

  const handleClickOnContent = (contentType) => {
    setSelectedContentType(contentType);
    setSidebarType(SIDEBAR_TYPES.PANEL_LIST);
  };

  return (
    <TabSelectorWrapper>
      <TabWrapper onClick={() => handleClickOnContent(SIDEBAR_CONTENTS.HEADER)}>
        <HeaderIcon />
        <div>
          <Title>Header Design</Title>
          <Description>Change the cover image to keep your brand identity consistent.</Description>
        </div>
        <IconContainer>
          <ArrowRightIcon />
        </IconContainer>
      </TabWrapper>
      <TabWrapper onClick={() => handleClickOnContent(SIDEBAR_CONTENTS.LAYOUT)}>
        <CardLayoutIcon />
        <div>
          <Title>Layout Design</Title>
          <Description>Edit the layout and select which widgets to show.</Description>
        </div>
        <IconContainer>
          <ArrowRightIcon />
        </IconContainer>
      </TabWrapper>
    </TabSelectorWrapper>
  );
}

export default ContentSelector;
