import React from "react";

export const DeleteIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
    <g opacity="0.499086">
      <rect width="16" height="16" fill="white" fillOpacity="0.01" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.1233 4.93325L8.00065 7.05659L5.87665 4.93259C5.81314 4.87048 5.72774 4.83587 5.63891 4.83624C5.55008 4.83662 5.46497 4.87195 5.40199 4.93459L4.93532 5.40192C4.90337 5.43256 4.8779 5.46931 4.86043 5.50998C4.84295 5.55065 4.83381 5.59442 4.83356 5.63869C4.83331 5.68296 4.84196 5.72682 4.85898 5.76769C4.876 5.80855 4.90105 5.84558 4.93265 5.87658L7.05599 7.99925L4.93332 10.1233C4.8713 10.1868 4.83681 10.2723 4.83731 10.3611C4.83781 10.45 4.87326 10.535 4.93599 10.5979L5.40265 11.0646C5.53999 11.2026 5.74665 11.1979 5.87732 11.0673L8.00132 8.94392L10.124 11.0673C10.1876 11.1293 10.273 11.1638 10.3619 11.1633C10.4507 11.1628 10.5358 11.1273 10.5987 11.0646L11.066 10.5979C11.0979 10.5672 11.1233 10.5304 11.1407 10.4898C11.1581 10.4491 11.1672 10.4053 11.1674 10.361C11.1676 10.3167 11.1589 10.2729 11.1418 10.232C11.1247 10.1912 11.0996 10.1542 11.068 10.1233L8.94399 7.99925L11.068 5.87658C11.1301 5.81308 11.1647 5.72767 11.1643 5.63884C11.164 5.55001 11.1286 5.4649 11.066 5.40192L10.5993 4.93525C10.568 4.90323 10.5307 4.87773 10.4895 4.86023C10.4483 4.84272 10.4041 4.83355 10.3593 4.83325C10.3154 4.83361 10.2721 4.84263 10.2317 4.85979C10.1913 4.87695 10.1547 4.90191 10.124 4.93325H10.1233Z"
        fill="#42526E"
      />
    </g>
  </svg>
);

export const LinkIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M13.7515 0H2.24852C1.0067 0 0 1.0067 0 2.24852V13.7515C0 14.9933 1.0067 16 2.24852 16H13.7515C14.9933 16 16 14.9933 16 13.7515V2.24852C16 1.0067 14.9933 0 13.7515 0Z"
      fill="#00B8D9"
    />
    <path
      d="M8.27023 5.0031L9.29075 3.98258C10.0406 3.2327 11.2677 3.2327 12.0176 3.98258C12.7675 4.73246 12.7675 5.95954 12.0176 6.70942L9.94388 8.78313C9.194 9.53301 7.96692 9.53301 7.21704 8.78313"
      stroke="white"
      strokeWidth="1.3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M7.72994 10.997L6.70942 12.0175C5.95954 12.7674 4.73246 12.7674 3.98258 12.0175C3.2327 11.2676 3.2327 10.0405 3.98258 9.29066L6.05629 7.21695C6.80617 6.46707 8.03325 6.46707 8.78313 7.21695"
      stroke="white"
      strokeWidth="1.3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
);

export const EmailIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
    <rect width="16" height="16" rx="2" fill="#2684FF" />
    <rect x="3" y="4" width="10" height="8" rx="1" fill="white" />
    <mask
      id="mask0_154_5180"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="16"
    >
      <rect width="16" height="16" rx="2" fill="#2684FF" />
    </mask>
    <g mask="url(#mask0_154_5180)">
      <path
        d="M13.5 4.50017L9.38765 8.61237C8.6066 9.3934 7.34029 9.39339 6.55925 8.61235L2.49994 4.55304"
        stroke="#2684FF"
        strokeWidth="1.3"
        strokeLinecap="round"
      />
    </g>
  </svg>
);

export const CloudIcon = () => (
  <svg width="20" height="20" viewBox="0 0 16 16">
    <path fill="currentColor" d="M11.47 9a4.5 4.5 0 0 0-8.72-.99A3 3 0 0 0 3 14h8.5a2.5 2.5 0 1 0-.03-5Z" />
    <path
      fill="currentColor"
      d="M14.54 9.77a3.5 3.5 0 0 0-2.22-1.67 5.5 5.5 0 0 0-6.34-4 4 4 0 0 1 7.4.9 2.5 2.5 0 0 1 1.16 4.77Z"
    />
  </svg>
);
