import styled from "styled-components";
import { usePageStore } from "../../page-store";
import { Pages } from "../../pages";
import { SectionKeys, SectionPagesIDs } from "../Settings/sections";
import NoNewStartersSVG from "../Dashboard/panels/images/NoNewStartersSVG";

const Container = styled.div`
  display: grid;
  place-content: center;
  justify-items: center;
  text-align: center;
  height: 100%;
  width: 100%;
`;

const Title = styled.p`
  font-weight: 590;
  font-size: 20px;
  line-height: 23.87px;
  color: ${({ theme }) => theme.emptyState.title};
  margin-top: 30px;
  margin-bottom: 12px;
`;

const Description = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.emptyState.description};

  .create-link {
    cursor: pointer;
    color: #2684ff;
  }
`;

function EmptyState({ isAdminView }) {
  const { setSelectedPage } = usePageStore((state) => ({
    setSelectedPage: state.setSelectedPage,
  }));

  return (
    <Container>
      <NoNewStartersSVG />
      {isAdminView ? (
        <div>
          <Title>Launch your People Network</Title>
          <Description>
            <span>There are currently no users in your Database. </span>
            <span
              className="create-link"
              onClick={() =>
                setSelectedPage(`${Pages.SETTINGS}-${SectionKeys.PEOPLE_BASE}-${SectionPagesIDs.DATABASE}`)
              }
            >
              Sync users
            </span>
            <span> to launch your People Network.</span>
          </Description>
        </div>
      ) : (
        <div>
          <Title>Hang in there!</Title>
          <Description>
            <span>You won't be alone for too long. Standing by until your admin launches People Network.</span>
          </Description>
        </div>
      )}
    </Container>
  );
}

export default EmptyState;
