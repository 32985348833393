import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.shared.sidebar.positioning.title};
  user-select: none;
  display: block;
`;

export const Description = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.shared.sidebar.positioning.description};
  margin-top: 12px;
`;

export const IconContainer = styled.div`
  display: grid;
  place-content: center;
  cursor: pointer;

  svg {
    path {
      fill: ${({ theme }) => theme.shared.sidebar.positioning.icon};
    }
  }

  &:hover {
    opacity: 0.9;
  }
`;

export const DividerBorder = styled.div`
  margin-top: 24px;
  margin-bottom: 23px;
  border-bottom: 0.75px solid ${({ theme }) => theme.shared.sidebar.divider};
`;

export const BlueText = styled.span`
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: ${({ theme }) => theme.global.text.blue};
`;

export const AddNewSectionWrapper = styled.div`
  position: sticky;
  bottom: 0px;
  background-color: ${({ theme }) => theme.global.background.surfaceOverlay};
`;

export const AddNewContainer = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 24px auto 24px;
  column-gap: 12px;
  cursor: pointer;

  &:hover {
    ${BlueText} {
      opacity: 0.9;
    }
  }
`;
