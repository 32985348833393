import { PanelKeys } from "./panelTypes";

export const PreviewContentByPanelKey = {
  [PanelKeys.ALL_EMPLOYEES]: {
    title: "Company News",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    fullLayout: true,
  },
  [PanelKeys.KNOW_YOUR_TEAM]: {
    title: "Know your team",
    description:
      "Introduce your team members through this interactive feature. The Know your team widget features people cards and additional user profile details.",
    fullLayout: true,
  },
  [PanelKeys.NEW_STARTERS]: {
    title: "New Starters",
    description:
      "Introduce new hires based on their entry dates and simplify the onboarding process. The New Starters widget features dynamic people cards with different design styles.",
    fullLayout: true,
  },
  [PanelKeys.WORK_ANNIVERSARY]: {
    title: "Work Anniversary",
    description:
      "Keep your team informed with the Work Anniversary widget, and ensure they never miss important dates.",
    fullLayout: true,
  },
  [PanelKeys.RECENTLY_ACTIVE_USERS]: {
    title: "Recently active users",
    description:
      "Display recently active users on your Confluence site. The Recently active users widget features people cards and activity information.",
    fullLayout: true,
  },
  [PanelKeys.UPCOMING_BIRTHDAY]: {
    title: "Upcoming birthdays",
    description:
      "Keep your team informed with the Upcoming Birthdays widget, and ensure they never miss important dates.",
    fullLayout: true,
  },
  [PanelKeys.USER_BLOG_POSTS]: {
    title: "Content creators",
    description:
      "Display recent blog creators on your Confluence site. The Content creators widget features people cards and offers a range of filtering options.",
    fullLayout: true,
  },
  [PanelKeys.ICON_CARDS]: {
    title: "Icon Cards",
    description:
      "Group information with cards. The icon cards widget offers a variety of design and color options to  better highlight the content.",
    fullLayout: true,
  },
  [PanelKeys.TEXT_CARDS]: {
    title: "Text Cards",
    description:
      "Group information with cards. The text cards widget offers a variety of design and color options to  better highlight the content.",
    fullLayout: true,
  },
  [PanelKeys.IMG_CARDS]: {
    title: "Image Cards",
    description:
      "Group information with cards. The image cards widget offers a variety of design and color options to  better highlight the content.",
    fullLayout: true,
  },
};
