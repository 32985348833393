import React, { Fragment } from "react";
import { MediumLine, Wrapper } from "./styled";
import styled from "styled-components";

const CountdownWrapper = styled.div`
  padding: 12px;
  background-color: #4c9aff;
  color: #fff;
  filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.1));
  border-radius: 4px;

  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  column-gap: 50px;
`;

const TimesWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(7, min-content);
  column-gap: 11px;
  justify-self: center;
`;

const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 9px;
`;

const TimeBox = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 3px;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const Digits = styled.div`
  font-weight: 700;
  font-size: 26px;
  line-height: 28px;
  color: #ffffff;

  ${({ noNumber }) =>
    noNumber
      ? ""
      : `
        padding: 8px;
        background: rgba(255, 255, 255, 0.2);
        border-radius: 6px;
        `};
`;

const Stamp = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #ffffff;
`;

const StampPlaceholder = styled.div`
  height: 16px;
  width: auto;
`;

function Countdown() {
  return (
    <Wrapper>
      <CountdownWrapper>
        <DetailsWrapper>
          <MediumLine width={120} />
          <MediumLine width={175} />
        </DetailsWrapper>

        <TimesWrapper>
          {[
            { stamp: "days", time: "14" },
            { stamp: "hours", time: "20" },
            { stamp: "minutes", time: "15" },
            { stamp: "seconds", time: "36" },
          ].map(({ stamp, time }, index) => (
            <Fragment key={stamp}>
              {index !== 0 && (
                <TimeBox>
                  <Digits noNumber>:</Digits>
                  <StampPlaceholder />
                </TimeBox>
              )}
              <TimeBox>
                <Digits>{time}</Digits>
                <Stamp>{stamp}</Stamp>
              </TimeBox>
            </Fragment>
          ))}
        </TimesWrapper>
      </CountdownWrapper>
    </Wrapper>
  );
}

export default Countdown;
