import Categories from "../Components/Categories";
import Personalization from "../Components/Personalization";
import UnsplashImage from "../Components/UnsplashImage";
import { NewsMetadataTabs } from "./tabs";

export const AvailableContent = {
  [NewsMetadataTabs.PHOTO]: UnsplashImage,
  [NewsMetadataTabs.CATEGORIES]: Categories,
  [NewsMetadataTabs.TARGETING]: Personalization,
  [undefined]: () => <></>,
};
