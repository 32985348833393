import React, { useMemo } from "react";
import { FiltersWrapper } from "../styled/newsroom";
import SpacesCheckboxSelect from "../../Shared/CheckboxSelect/SpacesCheckboxSelect";
import { useNewsroomStore } from "../newsroom-store";
import LabelsCheckboxSelect from "../../Shared/CheckboxSelect/LabelsCheckboxSelect";
import UsersCheckboxSelect from "../../Shared/CheckboxSelect/UsersCheckboxSelect";
import { ClickableLabel, DoubleLabelContainer, FieldContainer, FieldLabel } from "../../Shared/Filters/styled-filters";
import DateRangeSelect from "./DateRangeSelect";
import { COLOR_TYPES } from "../../Settings/General/BrandAndColors/color-types";
import { useCorporateIdentityStore } from "../../Settings/General/BrandAndColors/corporate-identity-store";

function Filters() {
  const {
    spaceFilter,
    setSpaceFilter,
    labelsFilter,
    setLabelsFilter,
    authorsFilter,
    setAuthorsFilter,
    dateFilter,
    setDateFilter,

    resetFilters,
  } = useNewsroomStore((state) => ({
    spaceFilter: state.spaceFilter,
    setSpaceFilter: state.setSpaceFilter,

    labelsFilter: state.labelsFilter,
    setLabelsFilter: state.setLabelsFilter,

    authorsFilter: state.authorsFilter,
    setAuthorsFilter: state.setAuthorsFilter,

    dateFilter: state.dateFilter,
    setDateFilter: state.setDateFilter,

    resetFilters: state.resetFilters,
  }));

  const hasFilters = useMemo(() => {
    return (
      !!spaceFilter?.length ||
      !!labelsFilter?.length ||
      !!authorsFilter?.length ||
      !!dateFilter.start ||
      !!dateFilter.end
    );
  }, [spaceFilter, labelsFilter, authorsFilter, dateFilter]);

  const colors = useCorporateIdentityStore((state) => state.colors);
  const primaryColor = colors[COLOR_TYPES.PRIMARY] || "#0065ff";

  return (
    <FieldContainer>
      <DoubleLabelContainer>
        <FieldLabel>FILTER BY</FieldLabel>
        {hasFilters && (
          <ClickableLabel color={primaryColor} onClick={() => resetFilters()}>
            Clear filters
          </ClickableLabel>
        )}
      </DoubleLabelContainer>

      <FiltersWrapper>
        <SpacesCheckboxSelect initialSpaces={spaceFilter.map(({ value }) => value)} onChange={setSpaceFilter} />
        <LabelsCheckboxSelect initialLabels={labelsFilter} onChange={setLabelsFilter} />
        <UsersCheckboxSelect initialUsers={authorsFilter.map(({ value }) => value)} onChange={setAuthorsFilter} />
        <DateRangeSelect color={primaryColor} onChange={setDateFilter} />
      </FiltersWrapper>
    </FieldContainer>
  );
}

export default Filters;
