import React, { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useCorporateIdentityStore } from "../../../../Settings/General/BrandAndColors/corporate-identity-store";
import { COLOR_TYPES } from "../../../../Settings/General/BrandAndColors/color-types";
import { PanelPositioning } from "../../../../Dashboard/panels/panelTypes";
import { fetchRecentContent } from "../../../../PeopleBase/Views/api";
import { fetchRecords } from "../../../../../../UserDatabase/datagrid/api";
import {
  IMAGE_TYPES,
  getCssForBackground,
  getRandomUserBackground,
} from "../../../../../../../Shared/Functions/user-bg-provider";
import { PanelsPaginationTypes } from "../../../PanelsPagination/panels-pagination-types";
import MainGridView from "./components/MainGridView";
import SideView from "./components/SideView";
import { handleDataForActiveUsers } from "./utils/active-users-data-handler";
import { useTheme } from "styled-components";

const panelComponents = {
  [PanelPositioning.MAIN]: MainGridView,
  [PanelPositioning.SIDE]: SideView,
};

function ActiveUsers({ data: panelData, position, panelTitle }) {
  const [activeUsers, setActiveUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);

  const borderRadius = useCorporateIdentityStore((state) => state.borderRadius);
  const colors = useCorporateIdentityStore((state) => state.colors);
  const primaryColor = colors ? colors[COLOR_TYPES.PRIMARY] : "#0065ff";
  const maxNumberOfItems = panelData.maxNumberOfItems || 4;
  const indexOfLast = page * maxNumberOfItems;
  const indexOfFirst = indexOfLast - maxNumberOfItems;

  const theme = useTheme();

  const { isLoading: activeUsersLoading, data } = useQuery(["active-users"], fetchRecentContent, {
    retry: 0,
    select: (response) => {
      const { body } = response;
      const { results } = JSON.parse(body);
      return handleDataForActiveUsers(results).slice(0, 8);
    },
  });

  const activeUsersIds = activeUsers?.map((activeUser) => activeUser.userData.accountId);

  const {
    isFetching: isUsersRecordsFetching,
    isLoading: activeUsersRecordsLoading,
    data: usersRecords,
  } = useQuery(
    [
      "active-users-records",
      {
        filters: [
          { isMultiUser: true, type: "BOOLEAN", value: true, column: "hidden", condition: "NOT" },
          { isMultiUser: true, type: "USER", value: activeUsersIds },
        ],
      },
    ],
    fetchRecords,
    {
      enabled: !!activeUsersIds?.length,
      retry: 0,
      select: (response) => {
        const { data } = response;
        return data;
      },
    },
  );

  useEffect(() => {
    if (!!data?.length) {
      setLoading(true);
      const formattedUsers = data.map((user) => {
        const userData = data.find((r) => r.userData.accountId === user.userData.accountId)?.userData;
        const backgroundImage = userData?.user?.backgroundImage;
        const hasBgData = !!backgroundImage && !!Object.keys(backgroundImage || {}).length;
        const correctImage = hasBgData ? backgroundImage : getRandomUserBackground(IMAGE_TYPES.GRADIENT);
        const image = getCssForBackground(correctImage);

        const formattedUser = {
          ...user,
          userData: { ...user.userData, backgroundImage: image },
        };

        return formattedUser;
      });
      setActiveUsers([...formattedUsers]);
      setLoading(false);
    }
  }, [data]);

  useEffect(() => {
    setPage(1);
  }, [maxNumberOfItems, panelData.pagination, panelData.events]);

  const dynamicIndexOfFirst = {
    [PanelsPaginationTypes.SHOW_MORE]: 0,
    [PanelsPaginationTypes.ARROWS]: indexOfFirst,
  };

  const getBackground = (user) => {
    if (panelData.backgroundType === "image") return user.backgroundImage;
    if (panelData.backgroundType === "none") return theme.global.background.surfaceOverlay;
  };

  const filteredUsersIds = usersRecords?.hits.map((userRecord) => userRecord.accountId);
  const activeUsersFilter = (activeUser) => filteredUsersIds?.includes(activeUser.userData.accountId);
  const peoplePaginated = activeUsers
    .filter(activeUsersFilter)
    .slice(dynamicIndexOfFirst[panelData.pagination], indexOfLast);
  const maxPage = Math.ceil(activeUsers?.filter(activeUsersFilter).length / maxNumberOfItems);

  const panelProps = useMemo(() => {
    return {
      [PanelPositioning.MAIN]: {
        panelData,
        isLoading: activeUsersLoading || activeUsersRecordsLoading || loading,
        borderRadius,
        activeUsersFilter,
        peoplePaginated,
        pagination: { page, setPage, maxPage },
        primaryColor,
        isUsersRecordsFetching,
        activeUsers,
        getBackground,
        panelTitle,
      },
      [PanelPositioning.SIDE]: {
        panelData,
        isLoading: activeUsersLoading || activeUsersRecordsLoading || loading,
        pagination: { page, setPage, maxPage },
        isUsersRecordsFetching,
        activeUsersFilter,
        activeUsers,
        peoplePaginated,
        primaryColor,
        panelTitle,
      },
    };
  }, [
    panelData,
    activeUsersLoading,
    activeUsersRecordsLoading,
    borderRadius,
    activeUsersFilter,
    peoplePaginated,
    page,
    setPage,
    maxPage,
    primaryColor,
    isUsersRecordsFetching,
    activeUsers,
    getBackground,
  ]);

  const PanelComponent = panelComponents[position];

  return <PanelComponent {...panelProps[position]} />;
}

export default ActiveUsers;
