import React, { useEffect, useState } from "react";
import xss from "xss";
import PropTypes from "prop-types";
import Textfield from "@atlaskit/textfield";
import {
  LinkPagesWrapper,
  DirectLinkText,
  PrimaryButton,
  TextfieldWrapper,
  LinkWrapper,
  Divider,
  PageLinkWrapper,
  LinkButton,
} from "./styled/Components";
import { selectStylesWithOverrides } from "../../../Components/ContentBuilder/Shared/Filter/CustomSelectStyle";
import { useTheme } from "styled-components";

function DirectLink({ value, onSelected, cosmosLayout }) {
  const [cosmosPages, setCosmosPages] = useState([]);
  const [input, setInput] = useState("");

  const theme = useTheme();

  const createHrefLinkForPage = (page) =>
    `${window.AP._hostOrigin}/wiki/plugins/servlet/ac/com.caelor.confluence.cloud.cosmos/caelor-cosmos-portal?ac.cosmosPage=${page}`;

  useEffect(() => {
    if (value?.source && value?.source === "direct") {
      setInput(value.link);
    }
  }, [value]);

  useEffect(() => {
    const filteredPages = cosmosLayout.filter(
      (page) => page.isAvailable === true && page.isDisabled === false && page.name !== "User Profile",
    );
    filteredPages.unshift({ id: "dashboard", name: "Cosmos Dashboard" });

    setCosmosPages(filteredPages);
  }, [cosmosLayout]);

  const SELECT_STYLES = selectStylesWithOverrides({
    control: (provided, { isFocused }) => ({
      ...provided,
      backgroundColor: theme.shared.form.select.background,
      borderColor: theme.shared.form.select.border,
      fontSize: "14px",
      "&:hover": {
        backgroundColor: `${theme.shared.form.select.hover} !important`,
        borderColor: `${theme.shared.form.select.hover} !important`,
      },
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: theme.shared.form.select.options,
      border: `1px solid ${theme.shared.form.select.border}`,
    }),
    option: (provided, { isSelected, isFocused }) => ({
      ...provided,
      fontSize: "14px",
      color: isSelected ? theme.shared.form.select.selected.text : theme.shared.form.select.option,
      backgroundColor: isSelected
        ? theme.shared.form.select.selected.background
        : isFocused
        ? theme.shared.form.select.hover
        : theme.shared.form.select.options,
    }),
  });

  return (
    <>
      <DirectLinkText>Enter a link to an external website or resource</DirectLinkText>
      <LinkWrapper>
        <TextfieldWrapper>
          <Textfield
            placeholder="www.caelor.com"
            name="direct-link"
            className="direct-link-input"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            style={SELECT_STYLES}
          />
        </TextfieldWrapper>

        <PrimaryButton
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            let sanitizedUrl = xss(input, "");
            if (sanitizedUrl.length > 0) {
              sanitizedUrl =
                sanitizedUrl.startsWith("http://") || sanitizedUrl.startsWith("https://")
                  ? sanitizedUrl
                  : `https://${sanitizedUrl}`;
            }
            onSelected({ link: sanitizedUrl, source: "direct" });
          }}
        >
          Save
        </PrimaryButton>
      </LinkWrapper>

      <Divider />

      <LinkPagesWrapper>
        <DirectLinkText style={{ marginBottom: "15px" }}>Select a link to the existing Cosmos page</DirectLinkText>

        {cosmosPages.map(({ id, name }) => (
          <PageLinkWrapper
            key={name}
            onClick={() => {
              let url = createHrefLinkForPage(id);

              onSelected({ link: url, source: "direct" });
            }}
          >
            <LinkButton>{name}</LinkButton>
          </PageLinkWrapper>
        ))}
      </LinkPagesWrapper>
    </>
  );
}

DirectLink.propTypes = {
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onSelected: PropTypes.func.isRequired,
};

export default DirectLink;
