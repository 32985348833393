import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const InlineContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 4px;
`;

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 6px;
`;

export const Ellipse = styled.div`
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background-color: ${({ bg }) => bg ?? "rgba(255 255 255 / 50%)"};
`;

const Line = styled.div`
  width: ${({ width }) => (width ? `${width}px` : "35px")};
  border-radius: 8px;
  background-color: ${({ bg }) => bg ?? "rgba(255 255 255 / 50%)"};
`;

export const SmallLine = styled(Line)`
  height: 4px;
`;

export const MediumLine = styled(Line)`
  height: 8px;
`;

export const LargeLine = styled(Line)`
  height: 11px;
`;

export const Panel = styled.div`
  background: #ffffff;
  border: 1px solid #d8dce3;
  border-radius: 8.5px;
  padding: 16px 25px;
`;

export const Title = styled.div`
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  color: #42526e;
  padding-bottom: 10px;
  border-bottom: 0.5px solid rgba(122 134 154 / 50%);
`;

export const Content = styled.div`
  padding: 16px 0px;
`;

export const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  column-gap: 14px;
`;

export const Card = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 2.37156px rgba(85, 85, 85, 0.06);
  border: 0.6px solid #d8dce3;
  border-radius: 9.5px;
  overflow: hidden;
`;

export const CardBackground = styled.div`
  width: 100%;
  background: ${({ background }) => background};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 50px;
`;

export const CardInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  align-items: center;
  justify-content: center;
  padding: 0px 16px 16px 16px;
  margin-top: -24px;
  text-align: center;
`;

export const UserImage = styled.img`
  width: 48px;
  height: 48px;
  box-sizing: border-box;
  border: 2px solid #fff;
  border-radius: 50%;
  object-fit: cover;
  justify-self: center;
`;

export const NewsImage = styled.img`
  width: 145px;
  height: 95px;
  border-radius: 6px;
  object-fit: cover;
`;
