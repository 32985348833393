import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled, { useTheme } from "styled-components";
import Textfield from "@atlaskit/textfield";
import { AsyncSelect } from "@atlaskit/select";
import Spinner from "@atlaskit/spinner";
import EditorSuccessIcon from "@atlaskit/icon/glyph/editor/success";
import EditorErrorIcon from "@atlaskit/icon/glyph/editor/error";

import {
  AttachmentsDesc,
  AttachmentsLink,
  AttachmentsTitle,
  AttachmentsUploadBox,
  SearchSelectContainer,
  ListItem,
  HeaderLabels,
  ListItemsContainer,
  SpinnerContainer,
  ListTitle,
  ListSpace,
  ListType,
  AlertContainer,
  AlertMsg,
} from "./styled/Components";
import { buildAttachmentData, validateAttachments } from "./buildData";
import { getIconBasedOnType } from "./icons/getIconBasedOnType";
import { AlertData, AlertTypes } from "./alertTypes";
import { searchPages } from "./api";
import { attachmentsService } from "../Background/AttachmentsService";
import { useEventListener } from "../../Hooks/useEventListener";
import { selectStylesWithOverrides } from "../../../Components/ContentBuilder/Shared/Filter/CustomSelectStyle";

const TextFieldWrapper = styled.div`
  & > div:first-child {
    background: ${({ theme }) => theme.shared.form.input.background};
    border: 2px solid ${({ theme }) => theme.shared.form.select.borderColor};
    color: ${({ theme }) => theme.shared.form.select.value};
  }
  .search-pages-link-modal {
    border-color: transparent !important;
  }
`;

const Attachments = React.forwardRef((props, ref) => {
  const { value, setValue } = props;

  const [input, setInput] = useState("");
  const [selectedPage, setSelectedPage] = useState(undefined);
  const [attachments, setAttachments] = useState([]);
  const [loadingAttachments, setLoadingAttachments] = useState(false);

  const [macroLocation, setMacroLocation] = useState();
  const [uploadAlert, setUploadAlert] = useState(AlertData[AlertTypes.OFF]);

  const [selection, setSelection] = useState("");

  const theme = useTheme();

  const SELECT_STYLES = selectStylesWithOverrides({
    control: (provided, { isFocused }) => ({
      ...provided,
      backgroundColor: theme.shared.form.select.background,
      borderColor: theme.shared.form.select.border,
      fontSize: "14px",
      color: `${theme.shared.form.select.option} !important`,
      "&:hover": {
        backgroundColor: `${theme.shared.form.select.hover} !important`,
        borderColor: `${theme.shared.form.select.hover} !important`,
      },
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: theme.shared.form.select.options,
      border: `1px solid ${theme.shared.form.select.border}`,
    }),
    singleValue: (provided) => ({
      ...provided,
      color: `${theme.shared.form.select.option} !important`,
    }),
    option: (provided, { isSelected, isFocused }) => ({
      ...provided,
      fontSize: "14px",
      color: isSelected ? theme.shared.form.select.selected.text : theme.shared.form.select.option,
      backgroundColor: isSelected
        ? theme.shared.form.select.selected.background
        : isFocused
        ? theme.shared.form.select.hover
        : theme.shared.form.select.options,
    }),
  });

  const loadPages = async (searchTerm) => {
    try {
      const response = await searchPages(searchTerm);

      if (response?.results?.length) {
        const options = response.results.map((page) => ({
          label: page.title,
          value: page.id,
        }));
        return options;
      }
      return [];
    } catch (error) {
      return [];
    }
  };

  const loadAttachments = (id) => {
    if (id) {
      setLoadingAttachments(true);
      attachmentsService
        .fetchAttachments(id)
        .then((res) => {
          if (res?.data?.attachments?.length) {
            const builtAttachments = buildAttachmentData(res.data.attachments);
            setAttachments([...builtAttachments]);
          } else {
            setAttachments([]);
          }
        })
        .catch(() => setAttachments([]))
        .finally(() => setLoadingAttachments(false));
    } else {
      setLoadingAttachments(false);
    }
  };

  const uploadAttachmentsToPage = async (attForUpload) => {
    const localFiles = attForUpload.filter((item) => item.file.name).map((att) => att.file);

    if (localFiles.length > 15) {
      setUploadAlert(AlertData[AlertTypes.ITEM_LIMIT]);
      setTimeout(() => {
        setUploadAlert(AlertData[AlertTypes.OFF]);
      }, 3000);
      return;
    }

    if (localFiles.length) {
      setUploadAlert(AlertData[AlertTypes.UPLOADING]);

      const formData = new FormData();
      const isInitialDraft = macroLocation.target !== "contentedit";
      formData.append("pageStatus", isInitialDraft ? "draft" : "current");
      formData.append("contentId", selectedPage?.value);
      localFiles.forEach((file) => {
        formData.append("file", file);
      });

      try {
        const response = await attachmentsService.uploadAttachments(formData);
        if (response.status === 200) {
          setUploadAlert(AlertData[AlertTypes.SUCCESS]);
          setTimeout(() => {
            loadAttachments(selectedPage?.value);
          }, 2500);
        }
      } catch (err) {
        if (err?.response?.status === 413) {
          setUploadAlert(AlertData[AlertTypes.SIZE_LIMIT]);
        } else {
          setUploadAlert(AlertData[AlertTypes.ERROR]);
        }
      } finally {
        setTimeout(() => {
          setUploadAlert(AlertData[AlertTypes.OFF]);
        }, 3000);
      }
    }
  };

  const handleInputChange = async (e) => {
    const files = await validateAttachments(e.target.files);
    if (files) {
      await uploadAttachmentsToPage([...files]);
    }
    e.target.value = "";
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDropAttachment = async (e) => {
    e.preventDefault();
    if (e.dataTransfer.getData("text")) {
      return;
    }
    const files = await validateAttachments(e.dataTransfer.files);
    if (files) {
      await uploadAttachmentsToPage([...files]);
    }
  };

  useEffect(() => {
    const id = selectedPage?.value;
    loadAttachments(id);
  }, [selectedPage]);

  useEffect(() => {
    window.AP.navigator.getLocation(async (location) => {
      setMacroLocation(location);
    });
  }, []);

  useEffect(() => {
    if (value?.source && value?.source === "attachments") {
      setSelection(value.id || "");
    }
  }, [value]);

  useEventListener("dragover", handleDragOver, ref.current);
  useEventListener("drop", handleDropAttachment, ref.current);

  return (
    <div>
      <SearchSelectContainer>
        <TextFieldWrapper>
          <Textfield
            className="search-pages-link-modal"
            name="search-pages"
            placeholder="Type to search"
            value={input}
            onChange={(e) => setInput(e.target.value)}
          />
        </TextFieldWrapper>

        <AsyncSelect
          defaultOptions
          cacheOptions
          loadOptions={loadPages}
          value={selectedPage}
          onChange={(v) => setSelectedPage(v)}
          styles={SELECT_STYLES}
          isClearable
        />
      </SearchSelectContainer>

      <AttachmentsUploadBox>
        <div>
          <AttachmentsTitle>Upload files</AttachmentsTitle>
          <AttachmentsDesc>
            Drop a file or click&nbsp;
            <AttachmentsLink htmlFor="upload">here</AttachmentsLink>
            <input
              type="file"
              id="upload"
              name="upload"
              multiple
              onChange={handleInputChange}
              style={{ display: "none" }}
            />
            &nbsp;to upload
          </AttachmentsDesc>
        </div>
      </AttachmentsUploadBox>

      <AlertContainer>
        {uploadAlert.active && (
          <AlertMsg>
            <span>{uploadAlert.msg}</span>
            <span style={{ height: "24px", width: "24px" }}>
              {uploadAlert.status === "error" && <EditorErrorIcon primaryColor="#FF5630" />}
              {uploadAlert.status === "success" && <EditorSuccessIcon primaryColor="#36B37E" />}
              {uploadAlert.status === "loading" && <Spinner size="small" />}
            </span>
          </AlertMsg>
        )}
      </AlertContainer>

      <ListItem
        style={{
          padding: "25px 0px 5px 0px",
          borderBottom: `2px solid ${theme.shared.form.select.borderColor}`,
        }}
      >
        <HeaderLabels>Type</HeaderLabels>
        <HeaderLabels>Title</HeaderLabels>
        <HeaderLabels>Space</HeaderLabels>
      </ListItem>

      <ListItemsContainer>
        {loadingAttachments && (
          <SpinnerContainer>
            <Spinner size="large" />
          </SpinnerContainer>
        )}

        {!loadingAttachments &&
          attachments
            .filter(({ title }) => title.toLowerCase().includes(input.toLowerCase()))
            .map(({ id, type, title, space, link }) => (
              <ListItem
                key={id}
                isClickable
                isSelected={selection === id}
                onClick={() => {
                  setSelection(id);
                  setValue({ id, link, title, type, source: "attachments" });
                }}
              >
                <ListType>{getIconBasedOnType(type)}</ListType>
                <ListTitle>{title}</ListTitle>
                <ListSpace>{space}</ListSpace>
              </ListItem>
            ))}
      </ListItemsContainer>
    </div>
  );
});

Attachments.propTypes = {
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  setValue: PropTypes.func.isRequired,
};

Attachments.displayName = "Attachments";

export default Attachments;
