import React, { useEffect, useCallback, useRef } from "react";
import styled from "styled-components";
import shallow from "zustand/shallow";
import { useQuery } from "react-query";
import debounce from "lodash.debounce";
import Header from "./Header";
import EmptyState from "./EmptyState";
import Panels from "./Panels/Panels";
import { getAccountId } from "./profile.service";
import { ConfirmDeleteModal } from "../Shared/ConfirmDeleteModal";
import { usePageStore } from "../../page-store";
import { useRecordsStore } from "../../../UserDatabase/records-store";
import { useCurrentUserStore } from "../../current-user-store";
import { useChangesStore } from "../../track-changes-store";
import {
  fetchUserRecord,
  applyChangesForUser,
  fetchIsInitialized,
  fetchUserDatabaseFields,
} from "../../../UserDatabase/datagrid/api";
import { analytics } from "../../../../analytics";
import { useCorporateIdentityStore } from "../Settings/General/BrandAndColors/corporate-identity-store";
import { COLOR_TYPES } from "../Settings/General/BrandAndColors/color-types";

const Container = styled.div`
  min-height: calc(100% - 80px);
  background: ${({ theme }) => theme.global.background.surface};
  padding-bottom: 80px;
`;

function UserProfile({ currentWizardStep, setCurrentWizardStep, updateWizardProperties }) {
  const colors = useCorporateIdentityStore((state) => state.colors);
  const primaryColor = colors ? colors[COLOR_TYPES.PRIMARY] : "#0065ff";

  const { currentUser, isCurrentUserAdmin } = useCurrentUserStore((state) => ({
    currentUser: state.currentUser,
    isCurrentUserAdmin: state.isCurrentUserAdmin,
  }));

  const backgroundColor = useCorporateIdentityStore((state) => state.backgroundColor);

  const { fields, records, changes, setFields, setRecords, resetChanges } = useRecordsStore(
    (state) => ({
      fields: state.fields,
      records: state.records,
      changes: state.changes,
      setFields: state.setFields,
      setRecords: state.setRecords,
      resetChanges: state.resetChanges,
    }),
    shallow,
  );

  const { hasChanges, setHasChanges } = useChangesStore(
    (state) => ({
      hasChanges: state.hasChanges,
      setHasChanges: state.setHasChanges,
    }),
    shallow,
  );

  const redirectPage = useRef();
  const hasChangesRef = useRef();
  hasChangesRef.current = hasChanges;

  const updateRedirectPageRef = (page) => (redirectPage.current = page);

  const handleSave = useCallback(
    debounce(async () => {
      if (Object.keys(changes).length) {
        await applyChangesForUser(changes);
        resetChanges();
      }
    }, 1000),
    [changes],
  );

  const { isLoading: isRecordLoading, data: userRecord } = useQuery(
    [
      "userprofile-record",
      {
        accountId: getAccountId(),
      },
    ],
    fetchUserRecord,
    {
      retry: 0,
      select: (response) => {
        const { data } = response;
        return data;
      },
    },
  );

  const { isLoading: isFieldsLoading, data: fieldsData } = useQuery(
    ["userprofile-fields", { includeEmail: false }],
    fetchUserDatabaseFields,
    {
      retry: 0,
      select: (response) => {
        const { data } = response;
        return data;
      },
    },
  );

  const { isLoading: isInitializedLoading, data: isInitialized } = useQuery(
    "database-is-initialized",
    fetchIsInitialized,
    {
      retry: 0,
      select: (response) => {
        const { data } = response;
        return data;
      },
    },
  );

  useEffect(() => {
    if (fieldsData) {
      setFields(JSON.parse(JSON.stringify(fieldsData)));
    }
  }, [fieldsData]);

  useEffect(() => {
    if (userRecord) {
      setRecords([userRecord]);
    }
  }, [userRecord]);

  useEffect(() => {
    handleSave();
  }, [changes]);

  useEffect(() => {
    analytics.logEvent({ name: "Cosmos User Profile", template: "load" });
  }, []);

  if (isFieldsLoading || isInitializedLoading || isRecordLoading) {
    return null;
  }

  if (!fields.length || !records.length || !currentUser) {
    return <EmptyState isAdminView={isCurrentUserAdmin} />;
  }

  return (
    <Container backgroundColor={backgroundColor}>
      <Header />
      {!isInitialized && <EmptyState isAdminView={isCurrentUserAdmin} />}
      <Panels
        currentWizardStep={currentWizardStep}
        setCurrentWizardStep={setCurrentWizardStep}
        updateWizardProperties={updateWizardProperties}
        primaryColor={primaryColor}
      />
    </Container>
  );
}

export default UserProfile;
