import React, { useEffect, useState } from "react";
import styled from "styled-components";
import xss from "xss";
import Spinner from "@atlaskit/spinner";
import { Radio } from "@atlaskit/radio";
import { Panel, PanelContent, PanelTitle } from "../../dashboard-styled";
import { getPollById, removeUserVoteInPoll, updateUserVotesInPoll } from "../../api";
import { useQuery } from "react-query";
import { SpinnerContainer } from "../../../../styled/pages";
import { useCurrentUserStore } from "../../../../current-user-store";
import { useCorporateIdentityStore } from "../../../Settings/General/BrandAndColors/corporate-identity-store";
import { COLOR_TYPES } from "../../../Settings/General/BrandAndColors/color-types";

const Description = styled.p`
  margin: 0px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: ${({ theme }) => theme.poll.description};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  padding: 24px 0px;
`;

const OptionWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 16px;

  input[type="radio"]:checked {
    background-color: ${({ primaryColor }) => primaryColor};
    border-color: ${({ primaryColor }) => primaryColor};
  }
`;

const OptionTextAndBar = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const OptionPercentage = styled.span`
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: ${({ primaryColor }) => primaryColor};

  opacity: ${({ hasVoted }) => (hasVoted ? 1 : 0)};
  transition: opacity 250ms linear;
`;

const OptionText = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: ${({ theme }) => theme.poll.option};
  user-select: none;
`;

const OptionBar = styled.div`
  height: 4px;
  width: 100%;
  border-radius: 2px;
  position: relative;
  background-color: #ebecf0;
  opacity: ${({ hasVoted }) => (hasVoted ? 1 : 0)};
  transition: opacity 50ms linear;

  &::after {
    content: "";
    height: 4px;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;

    background-color: ${({ primaryColor }) => primaryColor};
    border-radius: 2px;

    width: ${({ hasVoted, percentage }) => {
      if (hasVoted) {
        return `${percentage}%`;
      }
      return `0px`;
    }};
    transition: width 500ms linear;
  }
`;

const Button = styled.div`
  background: ${({ isSecondary, primaryColor, theme }) =>
    isSecondary ? theme.poll.removeVote.background : primaryColor};
  border: ${({ isSecondary, primaryColor }) => (isSecondary ? `1px solid ${primaryColor}` : "1px solid transparent")};
  color: ${({ isSecondary, primaryColor, theme }) => (isSecondary ? primaryColor : theme.poll.removeVote.color)};
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  display: grid;
  place-content: center;
  text-align: center;
  cursor: pointer;
  max-width: 210px;
  padding: 6px 0px;
  margin-top: 8px;
  margin: 0 auto;

  &:hover {
    opacity: 0.9;
  }
`;

const VotedMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  row-gap: 13px;

  .main {
    padding-top: 6px;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    text-transform: uppercase;
    color: ${({ primaryColor }) => primaryColor};
  }

  .number {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #42526e;
  }
`;

const DummyContent = ({ panelTitle, position, primaryColor = "#0065ff" }) => {
  const [hasVoted, setHasVoted] = useState(false);
  const [dummyData, setDummyData] = useState([
    { id: "1", label: "Style Tokyo", percentage: 40, isChecked: false },
    { id: "2", label: "Style Amsterdam", percentage: 15, isChecked: false },
    { id: "3", label: "Style New York", percentage: 45, isChecked: false },
  ]);
  const description =
    "We're no Van Gogh, that's plain to see. So lend us your eye, and help us choose our Content Viz dashboard design style.";

  const updateOptions = (optionId) => {
    const newOptions = dummyData.map((op) => {
      return op.id === optionId ? { ...op, isChecked: true } : { ...op, isChecked: false };
    });
    setDummyData([...newOptions]);
  };

  return (
    <Panel>
      <PanelTitle panelPosition={position}>{panelTitle}</PanelTitle>
      <PanelContent>
        <Description>{description}</Description>
        <Wrapper>
          {dummyData.map((option) => (
            <OptionWrapper
              primaryColor={primaryColor}
              key={option.id}
              onClick={() => {
                if (!hasVoted) updateOptions(option.id);
              }}
            >
              <Radio value={option.id} label="" name={`poll-radio-button`} isChecked={option.isChecked} />
              <OptionTextAndBar>
                <div
                  style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: "2px" }}
                >
                  <OptionText>{option.label}</OptionText>
                  {hasVoted && (
                    <OptionPercentage primaryColor={primaryColor} hasVoted={hasVoted}>
                      {option.percentage}%
                    </OptionPercentage>
                  )}
                </div>
                <OptionBar primaryColor={primaryColor} hasVoted={hasVoted} percentage={option.percentage} />
              </OptionTextAndBar>
            </OptionWrapper>
          ))}
        </Wrapper>
        {!hasVoted && (
          <Button
            style={{ textTransform: "uppercase" }}
            primaryColor={primaryColor}
            onClick={() => {
              if (dummyData.find((op) => op.isChecked)) setHasVoted(true);
            }}
          >
            Vote
          </Button>
        )}

        {hasVoted && (
          <VotedMessageWrapper primaryColor={primaryColor}>
            <span className="main">You're a rockstar, thanks!</span>
            <span className="number">457 people voted</span>
          </VotedMessageWrapper>
        )}
      </PanelContent>
    </Panel>
  );
};

function Poll({ id, panelTitle, data, position }) {
  const [isConfigured, setIsConfigured] = useState(false);
  const [hasVoted, setHasVoted] = useState(false);
  const [options, setOptions] = useState([]);
  const [totalVotes, setTotalVotes] = useState(0);
  const [loading, setLoading] = useState(true);
  const [description, setDescription] = useState("");
  const [userVote, setUserVote] = useState();
  const currentUser = useCurrentUserStore((state) => state.currentUser);
  const colors = useCorporateIdentityStore((state) => state.colors);
  const primaryColor = colors ? colors[COLOR_TYPES.PRIMARY] : "#0065ff";

  const {
    isLoading,
    data: pollResponseData,
    refetch,
  } = useQuery(
    [
      `get-poll-${data.pollId}`,
      {
        pollId: data.pollId,
      },
    ],
    getPollById,
    {
      enabled: !!data?.pollId,
      retry: 0,
      select: (response) => {
        const { data } = response;
        return data;
      },
    },
  );

  const calculatePercentage = (optionVotes) => {
    return Math.round((Number(optionVotes) / Number(totalVotes)) * 100);
  };

  const updateVotes = (option) => {
    const newOptions = options.map((op) => {
      return op.id === option.id ? { ...op, votes: op.votes + 1 } : { ...op };
    });

    setOptions([...newOptions]);
    setTotalVotes(totalVotes + 1);

    updateUserVotesInPoll(data.pollId, { accountId: currentUser.accountId, optionId: option.id })
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
  };

  const updateOptions = (optionId) => {
    const newOptions = options.map((op) => {
      return op.id === optionId ? { ...op, isChecked: true } : { ...op, isChecked: false };
    });

    setOptions([...newOptions]);
  };

  const resetData = () => {
    getPollById({ queryKey: [`get-poll-${data.pollId}`, { pollId: data.pollId }] })
      .then((res) => {
        if (res) {
          const pollResponseData = res.data;
          if (!pollResponseData.exists) {
            setIsConfigured(false);
            setLoading(false);
          } else {
            setIsConfigured(true);

            const savedData = pollResponseData.data;
            const user = checkIfUserVoted(savedData.userVotes);
            setUserVote(user?.optionId);
            const builtOptions = savedData.options.map((option) => ({
              ...option,
              votes: savedData.userVotes.filter((vote) => vote.optionId === option.id)?.length || 0,
              isChecked: user && user.optionId === option.id,
            }));

            setTotalVotes(savedData?.userVotes?.length || 0);
            setDescription(xss(savedData.description));
            setOptions([...builtOptions]);
            setHasVoted(!!user);
            setLoading(false);
          }
        }
      })
      .catch(() => {});
  };

  const deleteVote = () => {
    removeUserVoteInPoll(data.pollId)
      .then((res) => {
        resetData();
      })
      .catch((err) => console.log(err));
  };

  const checkIfUserVoted = (userVotes) => {
    const user = userVotes?.find((vote) => vote.accountId === currentUser.accountId);
    return user;
  };

  useEffect(() => {
    if (pollResponseData && currentUser) {
      if (!pollResponseData.exists) {
        setIsConfigured(false);
        setLoading(false);
      } else {
        setIsConfigured(true);

        const savedData = pollResponseData.data;
        const user = checkIfUserVoted(savedData.userVotes);
        setUserVote(user?.optionId);
        const builtOptions = savedData.options.map((option) => ({
          ...option,
          votes: savedData?.userVotes?.filter((vote) => vote.optionId === option.id)?.length || 0,
          isChecked: user && user.optionId === option.id,
        }));

        setTotalVotes(savedData?.userVotes?.length || 0);
        setDescription(xss(savedData.description));
        setOptions([...builtOptions]);
        setHasVoted(!!user);
        setLoading(false);
      }
    }
  }, [pollResponseData, currentUser]);

  useEffect(() => {
    window.AP.events.on("updated-poll-settings", (pollId) => {
      if (pollId === data?.pollId) {
        refetch();
      }
    });
  }, []);

  const borderRadius = useCorporateIdentityStore((state) => state.borderRadius);

  if (isLoading || loading) {
    return (
      <SpinnerContainer>
        <Spinner />
      </SpinnerContainer>
    );
  }

  if (!isConfigured) {
    return <DummyContent panelTitle={panelTitle} position={position} primaryColor={primaryColor} />;
  }

  return (
    <Panel>
      <PanelTitle panelPosition={position}>{panelTitle}</PanelTitle>
      <PanelContent>
        <Description>{description}</Description>
        <Wrapper>
          {options.map((option) => (
            <OptionWrapper
              primaryColor={primaryColor}
              key={option.id}
              onClick={() => {
                if (!hasVoted) updateOptions(option.id);
              }}
            >
              <Radio
                value={option.id}
                label=""
                name={`poll-radio-button-${id}`}
                isChecked={option.isChecked}
                isDisabled={hasVoted && userVote !== option.id}
              />
              <OptionTextAndBar>
                <div
                  style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: "2px" }}
                >
                  <OptionText>{option.label}</OptionText>
                  {hasVoted && (
                    <OptionPercentage primaryColor={primaryColor} hasVoted={hasVoted}>
                      {calculatePercentage(option.votes)}%
                    </OptionPercentage>
                  )}
                </div>
                <OptionBar
                  primaryColor={primaryColor}
                  hasVoted={hasVoted}
                  percentage={calculatePercentage(option.votes)}
                />
              </OptionTextAndBar>
            </OptionWrapper>
          ))}
        </Wrapper>

        {!hasVoted && (
          <Button
            style={{ textTransform: "uppercase", borderRadius }}
            primaryColor={primaryColor}
            onClick={() => {
              const selectedOption = options.find((op) => op.isChecked);
              if (selectedOption) {
                updateVotes(selectedOption);
                setHasVoted(true);
                setUserVote(selectedOption.id);
              }
            }}
          >
            Vote
          </Button>
        )}

        {hasVoted && (
          <>
            <VotedMessageWrapper primaryColor={primaryColor}>
              <span className="main">You're a rockstar, thanks!</span>
              <span className="number">
                {totalVotes}&nbsp;
                {totalVotes > 1 ? "people voted" : "person voted"}
              </span>
            </VotedMessageWrapper>

            <Button
              style={{ marginTop: "15px", textTransform: "uppercase", borderRadius }}
              isSecondary
              onClick={() => deleteVote()}
            >
              Remove vote
            </Button>
          </>
        )}
      </PanelContent>
    </Panel>
  );
}

export default Poll;
