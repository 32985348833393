import React, { useState, useEffect } from "react";
import styled from "styled-components";
import BlogPicker from "../../../../../../Hub/Components/Dashboard/filter/BlogPicker";
import { generateId } from "../../../../../../ContentBuilder/utils/id-generator";
import ManualBlogsList from "../../../../../../Hub/Components/Dashboard/filter/ManualBlogsList";
import LabelMultiSelect from "../../../../../../Hub/Components/Dashboard/filter/LabelMultiSelect";
import SpaceMultiSelect from "../../../../../../Hub/Components/Dashboard/filter/SpaceMultiSelect";
import PeoplePicker from "../../../../../../Hub/Components/Dashboard/filter/PeoplePicker";
import { useNavigationStore } from "../../../../../store";
import DisableDynamicFilterToggle, {
  LoadingTypes,
} from "../../../../../../Hub/Components/Dashboard/filter/DynamicFilterToggle";

const Divider = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.navigation.settings.iconBoxBorder};
  margin-top: 20px;
  margin-bottom: 19px;
`;

const NewsPropertyTypes = {
  ENABLED: "enabled",
  LOADING_TYPE: "loadingType",
  LABELS: "labels",
  SPACES: "spaces",
  CONTRIBUTORS: "contributors",
  MANUALLY_SELECTED: "manuallySelected",
};

const ManualOptions = ({ newsConfig, updateNewsProps }) => {
  const [pickerKey, setPickerKey] = useState(Math.random().toString(36).substring(7));

  const addNewContentManually = (newItem) => {
    const newManuallyPicked = [...(newsConfig?.manuallySelected || []), newItem];
    updateNewsProps(NewsPropertyTypes.MANUALLY_SELECTED, newManuallyPicked);
  };

  const removeContentManually = (itemId) => {
    const newManuallyPicked = (newsConfig?.manuallySelected || []).filter((item) => item.id !== itemId);
    updateNewsProps(NewsPropertyTypes.MANUALLY_SELECTED, newManuallyPicked);
  };

  useEffect(() => {
    setPickerKey(Math.random().toString(36).substring(7));
  }, [newsConfig.manuallySelected]);

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <BlogPicker
        manuallyPicked={newsConfig.manuallySelected || []}
        key={pickerKey}
        onUpdate={(selectedBlog) => {
          if ((newsConfig?.manuallySelected || []).length > 0) {
            const currentLastPicked = newsConfig.manuallySelected[newsConfig.manuallySelected.length - 1];
            const position = currentLastPicked.position + 1;
            addNewContentManually({ id: generateId(), position, contentId: selectedBlog.contentId });
            setPickerKey(Math.random().toString(36).substring(7));
            return;
          }
          addNewContentManually({ id: generateId(), position: 0, contentId: selectedBlog.contentId });
          setPickerKey(Math.random().toString(36).substring(7));
        }}
        label=""
        placeholder="Select blog(s)"
      />

      <ManualBlogsList
        manuallyPicked={newsConfig?.manuallySelected || []}
        onRemove={(id) => removeContentManually(id)}
      />
    </div>
  );
};

const DynamicOptions = ({ newsConfig, updateNewsProps }) => {
  return (
    <>
      <LabelMultiSelect
        label="Labels"
        placeholder="Filter by labels"
        initialLabels={newsConfig?.labels || []}
        onUpdate={(labels) => {
          updateNewsProps(NewsPropertyTypes.LABELS, labels);
        }}
      />

      <SpaceMultiSelect
        label="Spaces"
        isDynamic={true}
        initialSpaces={newsConfig?.spaces || []}
        onUpdate={(spaces) => updateNewsProps(NewsPropertyTypes.SPACES, spaces)}
      />

      <PeoplePicker
        isMulti
        initialAccountIDs={newsConfig?.contributors}
        label="Contributors"
        placeholder="Filter by contributors"
        onUpdate={(users) => updateNewsProps(NewsPropertyTypes.CONTRIBUTORS, users)}
      />
    </>
  );
};

function ContentVizNews() {
  const { editingEntry, updateEntry } = useNavigationStore((state) => ({
    editingEntry: state.editingEntry,
    updateEntry: state.updateEntry,
  }));
  const newsConfig = editingEntry?.data?.news || {};

  const updateNewsProps = (key, value) => {
    const newNews = { ...(editingEntry?.data?.news || {}), [key]: value };
    updateEntry(editingEntry.id, "news", newNews, true);
  };

  return (
    <>
      <Divider />

      <DisableDynamicFilterToggle
        initialValue={newsConfig?.loadingType || LoadingTypes.DYNAMIC}
        onUpdate={(newLoadingType) => updateNewsProps(NewsPropertyTypes.LOADING_TYPE, newLoadingType)}
      />

      {(newsConfig?.loadingType === LoadingTypes.DYNAMIC || typeof newsConfig?.loadingType === "undefined") && (
        <DynamicOptions newsConfig={newsConfig} updateNewsProps={updateNewsProps} />
      )}
      {newsConfig?.loadingType === LoadingTypes.MANUAL && (
        <ManualOptions newsConfig={newsConfig} updateNewsProps={updateNewsProps} />
      )}
    </>
  );
}

export default ContentVizNews;
