import React, { useMemo } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import dayjs from "dayjs";
import { getExternalLink } from "../../UserProfile/profile.service";
import { useCorporateIdentityStore } from "../../Settings/General/BrandAndColors/corporate-identity-store";
import { COLOR_TYPES } from "../../Settings/General/BrandAndColors/color-types";
import { createProfileLink } from "../Views/createProfileLink";

const CardLinkWrapper = styled.a`
  all: unset;
`;

const CardWrapper = styled.div`
  height: 100%;
  box-shadow:
    rgba(9, 30, 66, 0.25) 0px 1px 3px,
    rgba(9, 30, 66, 0.31) 0px 0px 1px;
  border-radius: ${({ borderRadius }) => `${borderRadius}`};
  background-color: ${({ theme }) => theme.shared.card.background};
  cursor: pointer;
  transition: box-shadow linear 75ms;
  border: 1px solid ${({ theme }) => theme.shared.card.border.box};

  &:hover {
    box-shadow:
      rgba(9, 30, 66, 0.31) 0px 0px 1px 0px,
      rgba(9, 30, 66, 0.25) 0px 8px 16px -6px;
  }
`;

const Background = styled.div`
  width: 100%;
  background: ${({ background }) => background};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 112px;
  border-radius: ${({ borderRadius }) => `${borderRadius} ${borderRadius} 0 0`};
`;

const ContentWrapper = styled.div`
  padding: 0px 32px 10px 32px;
  margin-top: -48px;
`;

const ImageAndSocialsWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  place-items: center;
  height: 96px;
`;

const SplitColumnsWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

const UserImage = styled.img`
  box-sizing: border-box;
  width: ${({ profileImageSize }) => (profileImageSize ? `${profileImageSize}px` : "96px")};
  height: ${({ profileImageSize }) => (profileImageSize ? `${profileImageSize}px` : "96px")};
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid ${({ theme }) => theme.shared.card.border.avatar};
`;

const Name = styled.span`
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: ${({ theme }) => theme.shared.card.title};
  margin-top: 16px;
  display: block;
`;

const TitleField = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  min-height: 41px;
  color: ${({ theme }) => theme.shared.card.subtitle};
  margin-top: 4px;
  padding-bottom: 24px;
  display: block;
`;

const DividerLine = styled.div`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.shared.card.divider};
`;

const EventTitle = styled.span`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: ${({ theme }) => theme.shared.card.footer};
`;

const EventText = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
`;

const ColoredEventText = styled(EventText)`
  color: ${({ primaryColor }) => primaryColor};
`;

const GreenEventText = styled(EventText)`
  color: #00875a;
`;

const SocialLinksWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 12px;
  row-gap: 12px;
`;

const IconContainer = styled.a`
  display: grid;
  place-content: center;

  text-decoration: none;

  cursor: pointer;
`;

const SocialLinkImage = styled.img`
  width: 20px;
  height: 20px;
`;

const Birthday = ({ additionalData, primaryColor }) => {
  return (
    <SplitColumnsWrapper>
      <EventTitle>{additionalData.daysLeft === 0 ? "Happy Birthday" : "Birthday"}</EventTitle>
      {additionalData.daysLeft === 0 && <GreenEventText>TODAY</GreenEventText>}
      {additionalData.daysLeft === 1 && <ColoredEventText primaryColor={primaryColor}>TOMORROW</ColoredEventText>}
    </SplitColumnsWrapper>
  );
};

const Anniversary = ({ additionalData, primaryColor }) => {
  return (
    <SplitColumnsWrapper>
      <EventTitle>Work Anniversary</EventTitle>
      {additionalData.daysLeft === 0 && <GreenEventText>TODAY</GreenEventText>}
      {additionalData.daysLeft === 1 && <ColoredEventText primaryColor={primaryColor}>TOMORROW</ColoredEventText>}
    </SplitColumnsWrapper>
  );
};

const NewStarter = ({ additionalData, primaryColor }) => {
  return (
    <SplitColumnsWrapper>
      <EventTitle>New Starter</EventTitle>
      {additionalData.daysLeft === 0 && <GreenEventText>TODAY</GreenEventText>}
      {additionalData.daysLeft === 1 && <ColoredEventText primaryColor={primaryColor}>TOMORROW</ColoredEventText>}
      {additionalData.daysLeft !== 0 && additionalData.daysLeft !== 1 && (
        <ColoredEventText primaryColor={primaryColor}>
          {dayjs.unix(additionalData.dateValue).format(`MMM D`)}
        </ColoredEventText>
      )}
    </SplitColumnsWrapper>
  );
};

const SocialLinks = ({ socialLinks }) => {
  const builtLinks = useMemo(() => {
    const availableLinks = Object.entries(socialLinks)
      .filter(([key, value]) => !!value?.link && !!value?.isSocial)
      .map(([key, value]) => ({
        key,
        link: value.link,
      }));
    return availableLinks;
  }, [socialLinks]);

  return (
    <>
      {builtLinks.map(({ key, link }) => (
        <IconContainer key={key} href={getExternalLink(link)} target="_blank" onClick={(e) => e.stopPropagation()}>
          <SocialLinkImage src={`/images/social-links/${key}.svg`} />
        </IconContainer>
      ))}
    </>
  );
};

const CardFooter = ({ additionalData }) => {
  const colors = useCorporateIdentityStore((state) => state.colors);
  const primaryColor = colors ? colors[COLOR_TYPES.PRIMARY] : "#0065ff";

  const FooterComponent = useMemo(() => {
    if (!additionalData) return null;

    if (additionalData.hasBirthday) return Birthday;
    if (additionalData.hasAnniversary) return Anniversary;
    if (additionalData.isNewStarter) return NewStarter;
  }, [additionalData]);

  if (!additionalData) return <></>;

  return (
    <>
      <DividerLine />
      <div style={{ padding: "16px 0px 10px 0px" }}>
        <FooterComponent primaryColor={primaryColor} additionalData={additionalData} />
      </div>
    </>
  );
};

function TeamCard({ panelData, data, borderRadius }) {
  const eventsToDisplay = panelData.events.filter((event) => event.value);
  const formattedEvents = eventsToDisplay.reduce((acc, event) => {
    acc[event.key] = event.value;
    return acc;
  }, {});

  return (
    <CardLinkWrapper target="_top" href={createProfileLink(data.accountId)} onClick={(e) => e.stopPropagation()}>
      <CardWrapper borderRadius={borderRadius}>
        <Background borderRadius={borderRadius} background={data.image} />

        <ContentWrapper>
          <ImageAndSocialsWrapper>
            <UserImage profileImageSize={panelData.profileImageSize} src={data?.confluenceData?.icon} />
            {!!Object.keys(data.socialLinks || {}).length && panelData.socialLinks && (
              <SocialLinksWrapper style={{ marginBottom: "-40px" }}>
                <SocialLinks socialLinks={data.socialLinks} />
              </SocialLinksWrapper>
            )}
          </ImageAndSocialsWrapper>

          <Name>{data?.confluenceData?.name}</Name>

          <TitleField>{panelData.titleFieldOn && data.titleValue}</TitleField>

          {data?.additionalData &&
            !!Object.keys(formattedEvents).find((event) => formattedEvents[event] === data?.additionalData[event]) && (
              <CardFooter additionalData={data?.additionalData} />
            )}
        </ContentWrapper>
      </CardWrapper>
    </CardLinkWrapper>
  );
}

TeamCard.propTypes = {
  data: PropTypes.shape({
    accountId: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    values: PropTypes.object,
    additionalData: PropTypes.shape({
      hasBirthday: PropTypes.bool,
      hasAnniversary: PropTypes.bool,
      isNewStarter: PropTypes.bool,
      dateValue: PropTypes.number,
      daysLeft: PropTypes.number,
    }),
    confluenceData: PropTypes.shape({
      accountId: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default TeamCard;
