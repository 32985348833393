import { MenuStyleTypes } from "../menu/types";

export const generateLinkToDashboard = () =>
  `${window.AP._hostOrigin}/wiki/plugins/servlet/ac/com.caelor.confluence.cloud.cosmos/caelor-cosmos-portal`;

export const DEFAULT_BACKGROUND = {
  source: "unsplash",
  link: "https://images.unsplash.com/photo-1477959858617-67f85cf4f1df?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3wzMzQyNzV8MHwxfHNlYXJjaHwxfHxjaXR5fGVufDB8MHx8fDE2OTAzMjIzNzl8MA&ixlib=rb-4.0.3&q=80&w=1080",
};

export const DEFAULT_NAVIGATION_PREVIEW_ITEMS = [
  "Confluence",
  "Home",
  "Recent",
  "Spaces",
  "People",
  "Apps",
  "Templates",
];

export const DEFAULT_SPACE_ICON =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYBAMAAAASWSDLAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAB5QTFRFAIb/////eL3/vN3/kMn/KJ3/NKL/A5L/bLj/Gpr/1uRTnAAAAC1JREFUeJxjYKA2mJZWgOAoKbWFhobDOSAA5RiDAZQjCAY04bS4AIEHtTwHBwBjfwncB0dIPAAAAABJRU5ErkJggg==";

export const THEME_NAME_LIGHT = "light";
export const THEME_NAME_DARK = "dark";

export const NAVIGATION_DEFAULT_COLORS = {
  menuBackgroundColor: "#ffffff",
  menuTextColor: "#42526E",
  menuTextHover: "#0065FF",
  subentryIconColor: "#505F79",
  subentryTextColor: "#42526E",
  subentryBackgroundHoverColor: "#E5F0FF",
  descriptionTextColor: "#42526E",

  // additional colors not included when macrosuite is installed
  menuTextActive: "#0065FF",
  subentryTextIconHover: "#0065FF",
};

export const NAVIGATION_DEFAULT_COLORS_DARK = {
  menuBackgroundColor: "#282E33",
  menuTextColor: "#9FADBC",
  menuTextHover: "#C7D1DB",
  subentryIconColor: "#505F79", // #2684FF
  subentryTextColor: "#9FADBC",
  subentryBackgroundHoverColor: "#E5F0FF",
  descriptionTextColor: "#C7D1DB",

  // additional colors not included when macrosuite is installed
  menuTextActive: "#69A6FC",
  subentryTextIconHover: "#0065FF",
};

export const NAVIGATION_DEFAULT_COLOR_SCHEME = {
  dark: NAVIGATION_DEFAULT_COLORS_DARK,
  light: NAVIGATION_DEFAULT_COLORS,
};

export const DEFAULT_LONDON_COLORS = {
  subentryBackgroundHoverColor: "#00000000",
  subentryIconColor: "#0c60ff",
  subentryTextColor: "#42526e",
};

export const DEFAULT_LONDON_COLORS_DARK = {
  subentryBackgroundHoverColor: "#00000000",
  subentryIconColor: "#2684FF",
  subentryTextColor: "#9FADBC",
};

export const DEFAULT_SYDNEY_COLORS = {
  subentryBackgroundHoverColor: "#00000000",
  subentryIconColor: "#0065ff",
  subentryTextColor: "#42526e",
  descriptionTextColor: "#44546F",
};

export const DEFAULT_SYDNEY_COLORS_DARK = {
  subentryBackgroundHoverColor: "#00000000",
  subentryIconColor: "#2684FF",
  subentryTextColor: "#C7D1DB",
  descriptionTextColor: "#9FADBC",
};

export const DEFAULT_DENVER_COLORS = {
  subentryBackgroundHoverColor: "#00000000",
  subentryIconColor: "#0c60ff",
  subentryTextColor: "#42526e",
  subentryLinkColor: "#42526e",
  subentryLinkHoverColor: "#0065ff",
};

export const DEFAULT_DENVER_COLORS_DARK = {
  subentryBackgroundHoverColor: "#00000000",
  subentryIconColor: "#0c60ff",
  subentryTextColor: "#C7D1DB",
  subentryLinkColor: "#9FADBC",
  subentryLinkHoverColor: "#2684FF",
};

export const DEFAULT_PALO_ALTO_COLORS = {
  subentryBackgroundHoverColor: "#00000000",
  subentryIconColor: "#0c60ff",
  subentryTextColor: "#42526e",
};

export const DEFAULT_PALO_ALTO_COLORS_DARK = {
  subentryBackgroundHoverColor: "#00000000",
  subentryIconColor: "#0c60ff",
  subentryTextColor: "#C7D1DB",
};

const generateLondonChildren = () => [
  {
    data: {
      icon: {
        icon: "caelor/MacroSuite",
        provider: "caelor",
      },
      name: "Optics",
      description:
        "Perfectly formatted Confluence content: Button, Cards, Panel, Page Divider, Tabs & more macros | Styleguide | Templates | Library",
    },
  },
  {
    data: {
      icon: {
        icon: "caelor/ContentViz",
        provider: "caelor",
      },
      name: "Pulse",
      description:
        "Attractive Confluence blogs, pages, people & spaces overviews. Organize content with Filters, News Modules, OrgCharts and more.",
    },
  },
  {
    data: {
      icon: {
        icon: "caelor/Cosmos",
        provider: "caelor",
      },
      name: "Cosmos",
      description:
        "Turn your Confluence Cloud into a one-stop shop for all your intranet needs. Create your company's knowledge universe and encourage teamwork and collaboration in hybrid teams.",
    },
  },
];

const generateSydneyChildren = () => [
  {
    data: {
      name: "Webinars",
      description: "Interactive sessions with experts on various topics.",
      icon: {
        icon: "atlaskit/PeopleGroupIcon",
        provider: "atlaskit",
      },
    },
  },
  {
    data: {
      name: "Case Studies",
      description: "Real-world solutions showcased in different industries.",
      icon: {
        icon: "atlaskit/SearchIcon",
        provider: "atlaskit",
      },
    },
  },
  {
    data: {
      name: "Ebooks and Onepagers",
      description: "Comprehensive guides and resources for your challenges.",
      icon: {
        icon: "atlaskit/BookIcon",
        provider: "atlaskit",
      },
    },
  },
];

const generateHoustonChildren = () => [];

const generateDenverChildren = () => [
  {
    data: {
      icon: {
        icon: "atlaskit/EditorMediaWrapLeftIcon",
        provider: "atlaskit",
      },
      name: "Human resources",
      linksCount: 4,
      link: [
        {
          id: 1,
          position: 1,
          linkName: "HR Policies",
        },
        {
          id: 2,
          position: 2,
          linkName: "Employee Benefits",
        },
        {
          id: 3,
          position: 3,
          linkName: "Recruitment Process",
        },
        {
          id: 4,
          position: 4,
          linkName: "Open Positions",
        },
      ],
      image: {
        source: "link",
        link: "https://images.unsplash.com/photo-1647546733367-2e12704a3279?q=80&w=3030&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        id: "",
      },
    },
  },
  {
    data: {
      icon: {
        icon: "atlaskit/BookIcon",
        provider: "atlaskit",
      },
      name: "Marketing",
      linksCount: 3,
      link: [
        {
          id: 1,
          position: 1,
          linkName: "Brand Guidelines",
        },
        {
          id: 2,
          position: 2,
          linkName: "Marketing Strategies",
        },
        {
          id: 3,
          position: 3,
          linkName: "Campaign Results",
        },
      ],
      image: {
        source: "link",
        link: "https://images.unsplash.com/photo-1518005135182-62fd125ae47f?q=80&w=3174&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        id: "",
      },
    },
  },
  {
    data: {
      icon: {
        icon: "atlaskit/PeopleGroupIcon",
        provider: "atlaskit",
      },
      name: "Sales",
      linksCount: 4,
      link: [
        {
          id: 1,
          position: 1,
          linkName: "Sales Playbook",
        },
        {
          id: 2,
          position: 2,
          linkName: "Target Accounts",
        },
        {
          id: 3,
          position: 3,
          linkName: "Quota Achievements",
        },
        {
          id: 4,
          position: 4,
          linkName: "Trainings",
        },
      ],
      image: {
        source: "link",
        link: "https://images.unsplash.com/photo-1467503508472-2b93d23f9c59?q=80&w=2970&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        id: "",
      },
    },
  },
  {
    data: {
      icon: {
        icon: "atlaskit/VidShareScreenIcon",
        provider: "atlaskit",
      },
      name: "IT Support",
      linksCount: 2,
      link: [
        {
          id: 1,
          position: 1,
          linkName: "System Updates",
        },
        {
          id: 2,
          position: 2,
          linkName: "IT Policies",
        },
      ],
      image: {
        source: "link",
        link: "https://images.unsplash.com/photo-1582647509711-c8aa8a8bda71?q=80&w=3135&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        id: "",
      },
    },
  },
];

const generatePaloAltoChildren = () => [
  {
    data: {
      icon: "https://caelor-apps.web.app/images/icons/atlassian.svg",
      name: "Atlassian",
    },
  },
  {
    data: {
      icon: "https://caelor-apps.web.app/images/icons/jira.svg",
      name: "Jira Software",
    },
  },
  {
    data: {
      icon: "https://caelor-apps.web.app/images/icons/teams.svg",
      name: "MS Teams",
    },
  },
  {
    data: {
      icon: "https://caelor-apps.web.app/images/icons/trello.svg",
      name: "Trello",
    },
  },
  {
    data: {
      icon: "https://caelor-apps.web.app/images/icons/slack.svg",
      name: "Slack",
    },
  },
  {
    data: {
      icon: "https://caelor-apps.web.app/images/icons/drive.svg",
      name: "Google Drive",
    },
  },
  {
    data: {
      icon: "https://caelor-apps.web.app/images/icons/cloud.svg",
      name: "Google Cloud",
    },
  },
  {
    data: {
      icon: "https://caelor-apps.web.app/images/icons/maps.svg",
      name: "Google Maps",
    },
  },
];

const generateHumChildren = () => [];

export const DUMMY_SPACES = [
  {
    title: "Anna Clark",
    creator: "Anna Clark",
  },
  {
    title: "Sales",
    creator: "Jane Thompson",
  },
  {
    title: "UX/UI Design",
    creator: "Hannah Hughes",
  },
  {
    title: "Operations",
    creator: "William Mitchell",
  },
  {
    title: "Product Management",
    creator: "William Mitchell",
  },
  {
    title: "Marketing",
    creator: "Daniel Hughes",
  },
  {
    title: "Maria Sharalova",
    creator: "Maria Sharalova",
  },
];

const generateLondonEntry = (id) => ({
  data: {
    icon: {
      icon: "none",
      provider: "none",
    },
    colors: { dark: DEFAULT_DENVER_COLORS_DARK, light: DEFAULT_LONDON_COLORS },
    name: "Confluence Apps",
    displayIcons: true,
    displayDescription: true,
    description: "Powerful apps to improve your Confluence experience.",
    isLinkOn: true,
    linksCount: 1,
    link: [
      {
        id: 1,
        position: 1,
        linkName: "DOCUMENTATION",
      },
    ],
  },
  parent: "0",
  menuStyle: "london",
  isHiddenFromAnonymous: true,
  id,
});

const generateSydneyEntry = (id) => ({
  data: {
    icon: {
      icon: "none",
      provider: "none",
    },
    colors: { dark: DEFAULT_SYDNEY_COLORS_DARK, light: DEFAULT_SYDNEY_COLORS },
    name: "Resources",
    displayIcons: true,
    displayDescription: true,
    description: "Content that inspires and helps teams get work done.",
  },
  parent: "0",
  menuStyle: "sydney",
  isHiddenFromAnonymous: true,
  id,
});

const generatePaloAltoEntry = (id) => ({
  data: {
    icon: {
      icon: "none",
      provider: "none",
    },
    colors: { dark: DEFAULT_PALO_ALTO_COLORS_DARK, light: DEFAULT_PALO_ALTO_COLORS },
    name: "App Links",
  },
  parent: "0",
  menuStyle: "palo_alto",
  isHiddenFromAnonymous: true,
  id,
});

const generateHoustonEntry = (id, spaces) => ({
  data: {
    icon: {
      icon: "none",
      provider: "none",
    },
    spaces,
    name: "Important Spaces",
  },
  parent: "0",
  menuStyle: "houston",
  isHiddenFromAnonymous: true,
  id,
});

const generateDenverEntry = (id) => ({
  data: {
    icon: {
      icon: "none",
      provider: "none",
    },
    colors: { dark: DEFAULT_DENVER_COLORS_DARK, light: DEFAULT_DENVER_COLORS },
    name: "Departments",
    type: "image",
  },
  parent: "0",
  menuStyle: "denver",
  isHiddenFromAnonymous: true,
  id,
});

const generateHumEntry = (id) => ({
  data: {
    icon: {
      icon: "none",
      provider: "none",
    },
    link: {
      link: generateLinkToDashboard(),
      source: "direct",
    },
    name: "Newsroom",
  },
  parent: "0",
  menuStyle: "hum",
  isHiddenFromAnonymous: true,
  id,
});

const childrenGenerator = {
  [MenuStyleTypes.LONDON]: generateLondonChildren,
  [MenuStyleTypes.SYDNEY]: generateSydneyChildren,
  [MenuStyleTypes.PALO_ALTO]: generatePaloAltoChildren,
  [MenuStyleTypes.HOUSTON]: generateHoustonChildren,
  [MenuStyleTypes.DENVER]: generateDenverChildren,
  [MenuStyleTypes.HUM]: generateHumChildren,
  [undefined]: () => [],
};

export const generateDefaultEntriesForParentEntry = (menuStyle = "london", parentId) => {
  if (menuStyle === MenuStyleTypes.HUM) return [];

  const getter = childrenGenerator[menuStyle];
  const newEntries = [...getter()];
  const populatedEntries = [...newEntries.map((entry) => ({ ...entry, parent: parentId, id: crypto.randomUUID() }))];

  return [...populatedEntries];
};

export const createParentEntry = (id, menuStyle, spaces) => {
  if (!menuStyle) return undefined;
  const getter = {
    [MenuStyleTypes.LONDON]: generateLondonEntry,
    [MenuStyleTypes.SYDNEY]: generateSydneyEntry,
    [MenuStyleTypes.PALO_ALTO]: generatePaloAltoEntry,
    [MenuStyleTypes.HOUSTON]: generateHoustonEntry,
    [MenuStyleTypes.DENVER]: generateDenverEntry,
    [MenuStyleTypes.HUM]: generateHumEntry,
  }[menuStyle];

  const generatedEntry = getter(id, spaces);

  return { ...generatedEntry };
};
