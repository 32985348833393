import React, { useEffect, useState } from "react";
import PanelWrapper from "../../../shared/components/PanelWrapper";
import {
  DetailsWrapper,
  LinkWrapper,
  ListName,
  PersonImage,
  PersonWrapper,
  TitleLabel,
} from "../../../../../Dashboard/panels/styles/peopleStyles";
import Pagination from "../../../../PanelsPagination/Pagination";
import { usePageStore } from "../../../../../../page-store";
import { useSidebarStore } from "../../../../../Dashboard/panels/edit/sidebar/sidebar-store";
import { PanelsPaginationTypes } from "../../../../PanelsPagination/panels-pagination-types";
import { Pages } from "../../../../../../pages";
import { useChangesStore } from "../../../../../../track-changes-store";
import EmptyState from "../../../../../../../../Shared/Components/EmptyState";
import { useTheme } from "styled-components";
import { messageByLoadingType } from "../People";
import NoDatabaseSync from "../../../shared/components/NoDatabaseSync";

const SideView = ({ panelTitle, data, people, createProfileLink, pagination, hasInitDatabase }) => {
  const { page, setPage, maxPage, hasNextPage } = pagination;
  const [showMoreClicked, setShowMoreClicked] = useState(false);

  const setSelectedPage = usePageStore((state) => state.setSelectedPage);
  const setDisableClickOutside = useSidebarStore((state) => state.setDisableClickOutside);
  const hasChanges = useChangesStore((state) => state.hasChanges);
  const theme = useTheme();

  useEffect(() => {
    setShowMoreClicked(false);
  }, [data.pagination]);

  return (
    <PanelWrapper panelTitle={panelTitle} displayOptions={{ displayTitle: data.displayTitle, displayBox: true }}>
      <div style={{ display: "flex", flexDirection: "column", rowGap: "16px" }}>
        {people?.map((person) => (
          <PersonWrapper>
            <LinkWrapper target="_top" href={createProfileLink(person.accountId)}>
              <PersonImage src={person.icon} style={{ cursor: "pointer" }} />
            </LinkWrapper>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <DetailsWrapper>
                <LinkWrapper target="_top" href={createProfileLink(person.accountId)}>
                  <ListName>{person.name}</ListName>
                </LinkWrapper>
                {data?.titleFieldOn && <TitleLabel>{person.titleValue || ""}</TitleLabel>}
              </DetailsWrapper>
            </div>
          </PersonWrapper>
        ))}

        {!hasInitDatabase && <NoDatabaseSync />}

        {!people?.length && hasInitDatabase && (
          <EmptyState
            title="No one to show"
            description={messageByLoadingType[data.loadingType]}
            Image={() => {
              return <img src={`/images/hub/no-people-empty-state-${theme.global.name}.svg`} alt="" />;
            }}
          />
        )}
      </div>
      <Pagination
        type={data.pagination === PanelsPaginationTypes.SHOW_MORE ? PanelsPaginationTypes.INFINITE : data.pagination}
        showMoreClicked={showMoreClicked}
        onClick={() => {
          if (hasNextPage && !showMoreClicked) {
            setPage(2);
            setShowMoreClicked(true);
            return;
          }

          setSelectedPage(Pages.PEOPLE);
        }}
        page={page}
        hasNextPage={hasNextPage}
        updatePage={setPage}
        maxPage={maxPage}
        onWrapperHoverCallback={setDisableClickOutside}
      />
    </PanelWrapper>
  );
};

export default SideView;
