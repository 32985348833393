import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { PropertyTypes } from "../propertyTypes";
import Reorderable from "./Reorderable";
import { CardDataByKey, createCardContent, getRandomCardImage } from "./cardDataByKey";
import { nanoid } from "nanoid";
import CardConfiguration from "./CardConfiguration";
import { useConfiguratorStore } from "./configurator-store";
import SidebarTransition from "../../../../../../../../Shared/Components/SidebarTransition";
import { useChangesStore } from "../../../../../../track-changes-store";
import { useSidebarStore as useSidebarStoreInDashboard } from "../../../../../Dashboard/panels/edit/sidebar/sidebar-store";
import { useSidebarStore as useSidebarStoreInPeopleNetwork } from "../../../../../PeopleBase/store/sidebar-store";
import { DashboardAPIContext } from "../../../../../Dashboard/context";
import { PeopleNetworkAPIContext } from "../../../../../PeopleBase/Context";
import { PanelKeys } from "../../../../../Dashboard/panels/panelTypes";

const ReorderableWrapper = styled.div`
  margin-top: 20px;
`;

const AddButton = styled.button`
  all: unset;
  padding: 6px 12px;
  margin-top: 24px;
  width: calc(100% - 24px);
  color: ${({ theme }) => theme.shared.sidebar.button.text};
  text-align: center;
  font-size: 14px;
  line-height: 20px;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.shared.sidebar.button.background};
  transition: background-color 150ms linear;

  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.shared.sidebar.button.hover};
  }
`;

function CardListConfigurator({ panel, updatePanelDataProperty, isInPeopleNetwork }) {
  const {
    id,
    data: { cards },
  } = panel;

  const [cardBeingEdited, setCardBeingEdited] = useState();

  const { isEditingIcon, isEditingLink, isEditingImage } = useConfiguratorStore((state) => ({
    isEditingIcon: state.isEditingIcon,
    isEditingLink: state.isEditingLink,
    isEditingImage: state.isEditingImage,
  }));

  const { hasChanges, setHasChanges } = useChangesStore((state) => ({
    hasChanges: state.hasChanges,
    setHasChanges: state.setHasChanges,
  }));

  const setDisableClickOutsideInDashboard = useSidebarStoreInDashboard((state) => state.setIsEditingCardsConfig);
  const setDisableClickOutsideInPeopleNetwork = useSidebarStoreInPeopleNetwork((state) => state.setDisableClickOutside);

  const { publishDashboard } = useContext(DashboardAPIContext);
  const { handleSaveSidebarChanges } = useContext(PeopleNetworkAPIContext);

  const updatePanel = useCallback(
    (value) => {
      updatePanelDataProperty(id, PropertyTypes.CARDS, value);
    },
    [id, updatePanelDataProperty],
  );

  const add = () => {
    let newCard = {
      id: nanoid(6),
      order: (cards || []).length + 1,
      ...createCardContent(),
      ...CardDataByKey[panel.key],
    };

    if (panel.key === PanelKeys.IMG_CARDS) {
      newCard = {
        ...newCard,
        image: getRandomCardImage(),
      };
    }

    updatePanel([...cards, newCard]);
  };

  const remove = (cardId) => {
    updatePanel([...cards.filter(({ id }) => id !== cardId)]);
  };

  const updateCard = (cardId, cardProperty, value) => {
    const updatedCard = { ...cardBeingEdited, [cardProperty]: value };
    setCardBeingEdited({ ...updatedCard });
    updatePanel(cards.map((card) => (card.id === cardId ? { ...updatedCard } : card)));
  };

  const resetOrder = (list) => {
    const resetList = list.map((c, index) => {
      const slide = c;
      slide.order = index + 1;
      return slide;
    });
    return resetList;
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return resetOrder(result);
  };

  const reorderCards = (sourceId, targetId) => {
    if (sourceId === targetId) {
      return;
    }
    const copyOfContent = [...cards];
    const sourceIndex = copyOfContent.findIndex((p) => p.id === sourceId);
    const targetIndex = copyOfContent.findIndex((p) => p.id === targetId);
    const items = reorder(copyOfContent, sourceIndex, targetIndex);

    updatePanel(items);
  };

  useEffect(() => {
    isInPeopleNetwork
      ? setDisableClickOutsideInPeopleNetwork(!!cardBeingEdited)
      : setDisableClickOutsideInDashboard(!!cardBeingEdited);
  }, [cardBeingEdited, isInPeopleNetwork]);

  return (
    <>
      <ReorderableWrapper>
        <DndProvider backend={HTML5Backend}>
          {cards.map((card) => (
            <Reorderable
              key={card.id}
              card={card}
              reorder={reorderCards}
              remove={remove}
              edit={(_card) => setCardBeingEdited(_card)}
            />
          ))}
        </DndProvider>
      </ReorderableWrapper>

      <AddButton onClick={() => add()}>Add new card</AddButton>

      <SidebarTransition
        isOpen={!!cardBeingEdited && cardBeingEdited?.id}
        close={() => setCardBeingEdited(undefined)}
        width={360}
        title={`Edit ${cardBeingEdited?.title}`}
        customScrollbarHeight="calc(100% - 45px)"
        disabledClickOutside={isEditingIcon || isEditingLink || isEditingImage}
        useArrowBack
        onArrowBackClick={() => setCardBeingEdited(undefined)}
        hasChanges={hasChanges}
        onSave={() => {
          if (isInPeopleNetwork) {
            handleSaveSidebarChanges();
          } else {
            publishDashboard();
          }
          setHasChanges(false);
        }}
      >
        <CardConfiguration card={cardBeingEdited} updateCardById={updateCard} cardType={panel.key} />
      </SidebarTransition>
    </>
  );
}

export default CardListConfigurator;
