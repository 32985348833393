import { PanelKeys } from "../../../Dashboard/panels/panelTypes";

export const CardDefaultData = {
  [PanelKeys.ICON_CARDS]: {
    pagination: "none",
    displayTitle: true,
    displayBox: true,
    style: "las_vegas",
    alignment: "left",
    numberOfColumns: 3,
    elementsToDisplay: [
      { id: "text", label: "Text", isChecked: true },
      { id: "arrow", label: "Arrow", isChecked: false },
    ],
    cards: [
      {
        order: 1,
        id: "card-1",
        title: "Intranet Help & Tutorials",
        description:
          "Explore our tutorials to navigate and utilize new Intranet. From basic navigation to expert tips.",
        icon: { icon: "atlaskit/FolderFilledIcon", provider: "atlaskit" },
        link: "",
        openInNewTab: true,
        colors: {
          elements: "#00A59B",
          background: "#fff",
        },
      },
      {
        order: 2,
        id: "card-2",
        title: "Benefits & Compensation",
        description: "Explore details about your employment benefits, health plans, compensation structures, and more.",
        icon: { icon: "atlaskit/FolderFilledIcon", provider: "atlaskit" },
        link: "",
        openInNewTab: true,
        colors: {
          elements: "#FF9458",
          background: "#fff",
        },
      },
      {
        order: 3,
        id: "card-3",
        title: "Work-Life Balance Resources",
        description: "Ensuring you're at your best, both in and out of work.",
        icon: { icon: "atlaskit/FolderFilledIcon", provider: "atlaskit" },
        link: "",
        openInNewTab: true,
        colors: {
          elements: "#FF991F",
          background: "#fff",
        },
      },
    ],
  },
  [PanelKeys.TEXT_CARDS]: {
    pagination: "none",
    displayTitle: true,
    displayBox: true,
    style: "las_vegas",
    alignment: "left",
    numberOfColumns: 3,
    elementsToDisplay: [
      { id: "text", label: "Text", isChecked: true },
      { id: "arrow", label: "Arrow", isChecked: false },
    ],
    cards: [
      {
        order: 1,
        id: "card-1",
        title: "Benefits & Compensation",
        description: "Explore details about your employment benefits, health plans, compensation structures, and more.",
        link: "",
        openInNewTab: true,
        colors: {
          elements: "#00A59B",
          background: "#fff",
        },
      },
      {
        order: 2,
        id: "card-2",
        title: "Personal Development",
        description:
          "Access training modules, workshops, and resources tailored to boost your skills and career progression.",
        link: "",
        openInNewTab: true,
        colors: {
          elements: "#FF9458",
          background: "#fff",
        },
      },
      {
        order: 3,
        id: "card-3",
        title: "Work-Life Balance Resources",
        description: "Ensuring you're at your best, both in and out of work.",
        link: "",
        openInNewTab: true,
        colors: {
          elements: "#FF991F",
          background: "#fff",
        },
      },
    ],
  },
  [PanelKeys.IMG_CARDS]: {
    pagination: "none",
    displayTitle: true,
    displayBox: true,
    style: "las_vegas",
    alignment: "left",
    imageHeight: 100,
    numberOfColumns: 3,
    elementsToDisplay: [
      { id: "text", label: "Text", isChecked: true },
      { id: "arrow", label: "Arrow", isChecked: false },
    ],
    cards: [
      {
        order: 1,
        id: "card-1",
        title: "Employee Handbook",
        description: "Your go-to guide for company policies, cultural norms, and everything in between.",
        image: {
          source: "link",
          link: "https://img.freepik.com/free-photo/low-angle-shot-modern-building-facade_181624-49557.jpg?w=750",
        },
        link: "",
        openInNewTab: true,
        colors: {
          elements: "#00A59B",
          background: "#fff",
        },
      },
      {
        order: 2,
        id: "card-2",
        title: "Intranet Help & Tutorials",
        description:
          "Explore our tutorials to navigate and utilize new Intranet. From basic navigation to expert tips.",
        image: {
          source: "link",
          link: "https://img.freepik.com/free-photo/low-angle-shot-steel-cables-with-clear-blue-sky_181624-21922.jpg?w=750",
        },
        link: "",
        openInNewTab: true,
        colors: {
          elements: "#FF9458",
          background: "#fff",
        },
      },
      {
        order: 3,
        id: "card-3",
        title: "Benefits & Compensation",
        description: "Explore details about your employment benefits, health plans, compensation structures, and more.",
        image: {
          source: "link",
          link: "https://images.unsplash.com/photo-1560840067-ddcaeb7831d2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=750&q=80",
        },
        link: "",
        openInNewTab: true,
        colors: {
          elements: "#FF991F",
          background: "#fff",
        },
      },
    ],
  },
};
