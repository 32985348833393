export const COLOR_TYPES = {
  PRIMARY: "primary",
  PRIMARY_CONTRAST: "primary_contrast",
  SECONDARY: "secondary",
  DASHBOARD_BG: "dashboard_bg",
  SIDEBAR: "sidebar",
  ICON_TYPE: "icon_type",
  ICON_COLOR: "icon_color",
  ICON_BG: "icon_bg",
  ICON_BG_SHAPE_TYPES: "icon_bg_shape_type",
};

export const ColorKeys = {
  [COLOR_TYPES.PRIMARY]: "",
  [COLOR_TYPES.PRIMARY_CONTRAST]: "",
};

export const ICON_BG_TYPES = {
  COLOR: "color",
  TRANSPARENT: "transparent",
  NONE: "none",
};

export const ICON_BG_SHAPE_TYPES = {
  CIRCLE: "circle",
  SQUARE: "square",
  NONE: "none",
};

// add new icon bg shape types here and define them in defaults below

export const DEFAULT_COLORS = {
  [COLOR_TYPES.PRIMARY]: "#0065ff",
  [COLOR_TYPES.SECONDARY]: "#5E6695",
  [COLOR_TYPES.PRIMARY_CONTRAST]: "#fff",
  [COLOR_TYPES.ICON_TYPE]: "color",
  [COLOR_TYPES.ICON_BG]: "#E3E5E9",
  [COLOR_TYPES.ICON_COLOR]: "#44536F",
  [COLOR_TYPES.ICON_BG_SHAPE_TYPES]: "circle",
};

export const DEFAULT_COLORS_DARK = {
  [COLOR_TYPES.PRIMARY]: "#69A6FC",
  [COLOR_TYPES.SECONDARY]: "#9FADBC",
  [COLOR_TYPES.PRIMARY_CONTRAST]: "#fff",
  [COLOR_TYPES.ICON_TYPE]: "color",
  [COLOR_TYPES.ICON_BG]: "#353B41",
  [COLOR_TYPES.ICON_COLOR]: "#A0ACBD",
  [COLOR_TYPES.ICON_BG_SHAPE_TYPES]: "circle",
};

export const getDefaultColorsBasedOnTheme = (theme) => (theme === "light" ? DEFAULT_COLORS : DEFAULT_COLORS_DARK);
