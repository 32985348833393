import React, { useMemo, useRef } from "react";
import ArrowRightIcon from "@atlaskit/icon/glyph/arrow-right";
import {
  CardDescription,
  CardTitle,
  CardWrapper,
  ColoredDivider,
  IconContainer,
  CardImage,
  ImageCardContent,
} from "../shared/styled";
import xss from "xss";
import { useTheme } from "styled-components";
import useLinksObserver from "../shared/use-links-observer";

function ImageCardLasVegas({ cardData, config }) {
  const { alignment, imageHeight, elementsToDisplay } = config;
  const {
    title,
    description,
    image,
    colors: { elements: elementsColor, background: backgroundColor, defaultBackground },
  } = cardData;

  const [isTextActive, isArrowActive] = useMemo(
    () => [
      elementsToDisplay.find(({ id }) => id === "text").isChecked,
      elementsToDisplay.find(({ id }) => id === "arrow").isChecked,
    ],
    [elementsToDisplay],
  );

  const getBackground = () => {
    if (image?.source === "color") {
      return `background-color: ${image.value};`;
    }
    if (image?.source === "gradient") {
      return `background-image: linear-gradient(${image.value?.rotation || "90deg"}, ${image.value.firstColor} ${
        image.value.firstColorPosition
      }%, ${image.value.secondColor} ${image.value.secondColorPosition}%);`;
    }

    return `background-image: url("${image?.link}");`;
  };

  const cardRef = useRef(null);
  const theme = useTheme();

  useLinksObserver(cardRef);

  const defaultBackgroundColor = (defaultBackground ?? true) && backgroundColor === "#fff";

  return (
    <CardWrapper
      ref={cardRef}
      bgClr={defaultBackgroundColor ? theme.shared.card.background : backgroundColor}
      style={{ padding: "0px" }}
    >
      <CardImage builtBg={getBackground()} imageHeight={imageHeight || 100} />
      <ImageCardContent alignment={alignment} imageHeight={imageHeight || 100}>
        <CardTitle style={{ marginBottom: "8px" }} alignment={alignment}>
          {title}
        </CardTitle>

        <ColoredDivider width="48px" bgClr={elementsColor} style={{ marginBottom: "12px" }} />

        {isTextActive && (
          <CardDescription alignment={alignment} dangerouslySetInnerHTML={{ __html: xss(description) }} />
        )}

        {isArrowActive && (
          <IconContainer style={{ marginTop: "12px", alignSelf: "flex-end" }}>
            <ArrowRightIcon primaryColor={elementsColor} />
          </IconContainer>
        )}
      </ImageCardContent>
    </CardWrapper>
  );
}

export default ImageCardLasVegas;
