import MainPanel from "./types/MainPanel";
import OrgChart from "./types/OrgChart";

export const TabKeys = {
  PROFILE: "profile",
  ORG_CHART: "org-charts",
};

export const tabOptions = [
  { label: "Profile", value: TabKeys.PROFILE },
  { label: "Org Chart", value: TabKeys.ORG_CHART },
];

export const tabContent = {
  [TabKeys.PROFILE]: { component: MainPanel, spotlight: { isEnabled: true, name: "profile-content" } },
  [TabKeys.ORG_CHART]: { component: OrgChart, spotlight: { isEnabled: false, name: "org-charts-content" } },
};
