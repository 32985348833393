import React from "react";
import DisableDynamicFilterToggle from "../../../../Dashboard/filter/DynamicFilterToggle";
import { PeoplePropertyKeys } from "./configurator/propertyTypes";
import Dynamic from "./content-pickers/Dynamic";
import Manual from "./content-pickers/Manual";

const Content = ({ panelId, data, updatePanelDataProperty, collectionsData }) => {
  return (
    <>
      <DisableDynamicFilterToggle
        initialValue={data.loadingType}
        onUpdate={(newLoadingType) => updatePanelDataProperty(panelId, PeoplePropertyKeys.LOADING_TYPE, newLoadingType)}
      />

      {data.loadingType === PeoplePropertyKeys.DYNAMIC && (
        <Dynamic
          panelId={panelId}
          data={data}
          updatePanelDataProperty={updatePanelDataProperty}
          collectionsData={collectionsData}
        />
      )}

      {data.loadingType === PeoplePropertyKeys.MANUAL && (
        <Manual panelId={panelId} data={data} updatePanelDataProperty={updatePanelDataProperty} />
      )}
    </>
  );
};

export default Content;
