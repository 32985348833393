export const SectionTypes = {
  SOCIAL: "social",
  MAIN: "main",
  SIDEBAR: "sidebar",
  DATE: "date",
};

export const Alignment = {
  LEFT: "left",
  RIGHT: "right",
};

export const SocialTypes = {
  linkedin: "Linkedin",
  twitter: "Twitter",
  facebook: "Facebook",
  slack: "Slack",
  instagram: "Instagram",
  github: "Github",
  amazon: "Amazon",
  atlassian: "Atlassian",
  bitbucket: "Bitbucket",
  behance: "Behance",
  dribble: "Dribble",
  google_chat: "Google Chat",
  jira: "Jira",
  microsoft_teams: "Microsoft Teams",
  paypal: "PayPal",
  personio: "Personio",
  reddit: "Reddit",
  skype: "Skype",
  telegram: "Telegram",
  trello: "Trello",
  youtube: "Youtube",
  whatsapp: "WhatsApp",
  website: "Website",
};
