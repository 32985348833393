import React, { useEffect, useState } from "react";
import EditorRemoveIcon from "@atlaskit/icon/glyph/editor/remove";
import SpaceMultiSelect from "../../../../../Dashboard/filter/SpaceMultiSelect";
import PeoplePicker from "../../../../../Dashboard/filter/PeoplePicker";
import { searchBlogs } from "../../../api";
import styled from "styled-components";
import LabelMultiSelect from "../../../../../Dashboard/filter/LabelMultiSelect";
import BlogPicker from "../../../../../Dashboard/filter/BlogPicker";
import Tabs from "../../Tabs";
import { useCategoriesStore } from "../../../categories-store";
import CustomModal from "../CustomModal";
import { LoadingTypes } from "../../../../../Dashboard/filter/DynamicFilterToggle";
import {
  createProperty,
  getProperty,
  updateProperty,
} from "../../../../../../../../Shared/Functions/newsPropertiesHandlers";
import { SELECT_STYLES } from "../../../../../Dashboard/filter/CustomSelectStyles";

const FilterBlogsText = styled.div`
  color: ${({ theme }) => theme.newsMetadataSettings.description};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-top: 20px;
`;

const Description = styled.div`
  color: ${({ theme }) => theme.newsMetadataSettings.description};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 150% */
  padding-top: 20px;
`;

const ItemWrapper = styled.div`
  padding-top: 10px;
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.navigation.settings.iconBoxBorder};

  ${({ isHoveringOver, orderDirection }) => {
    if (!isHoveringOver) {
      return null;
    }

    return orderDirection === "top-bottom" ? `border-bottom: 1px solid #aaa;` : `border-top: 1px solid #aaa;`;
  }};

  padding: 7px 0px;

  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.newsMetadataSettings.description};
  .actions {
    visibility: hidden;
  }
  &:hover {
    .actions {
      visibility: visible;
    }
  }
`;
const RemoveContainer = styled.div`
  cursor: pointer;
  width: 24px;
  height: 24px;
  display: grid;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
  justify-self: flex-end;
`;

const ItemTitle = styled.span`
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 70%;
`;

function AddBlogs({ closeModal }) {
  const [loading, setLoading] = useState(false);
  const [selectedSpaces, setSelectedSpaces] = useState([]);
  const [selectedCreator, setSelectedCreator] = useState([]);
  const [selectedLabels, setSelectedLabels] = useState([]);
  const [selectedBlogs, setSelectedBlogs] = useState([]);
  const [selectedTemporaryBlogs, setSelectedTemporaryBlogs] = useState([]);
  const [numberOfBlogs, setNumberOfBlogs] = useState(0);
  const [blogIds, setBlogIds] = useState([]);

  const { selectedTab, selectedCategory } = useCategoriesStore((state) => ({
    selectedTab: state.selectedTab,
    selectedCategory: state.selectedCategory,
  }));

  const categoryId = selectedCategory?.id;

  useEffect(() => {
    async function fetchData() {
      const response = await searchBlogs({ selectedCreator, selectedSpaces, selectedLabels });

      setNumberOfBlogs(response?.blogs?.length || 0);
      const blogIdsArray = [];

      response.blogs.forEach((blog) => {
        blogIdsArray.push(blog.content.id);
      });
      setBlogIds(blogIdsArray);
    }
    fetchData();
  }, [selectedCreator, selectedSpaces, selectedLabels]);

  const addCategoryToBlogs = async (blogs) => {
    try {
      setLoading(true);
      const promises = blogs.map(async (blogId) => {
        const blogProperty = await getProperty(blogId, "cosmosnewsmetadata");

        const exists = blogProperty?.key === "cosmosnewsmetadata";
        const existingCategories = blogProperty?.value?.categories?.trim();

        const categoryValue = existingCategories?.length
          ? `${categoryId}, ${blogProperty?.value?.categories}`
          : String(categoryId);

        return exists
          ? updateProperty(blogId, blogProperty.id, "cosmosnewsmetadata", blogProperty.version.number, {
              categories: categoryValue,
            })
          : createProperty(blogId, { categories: categoryValue }, "cosmosnewsmetadata");
      });

      await Promise.all(promises);
      await new Promise((resolve) => setTimeout(resolve, 5000)); // content properties are not updated/indexed instantly

      closeModal({ shouldRefetchBlogs: true });
    } catch (error) {
      // Handle errors if necessary
      console.error("Error:", error);
    } finally {
      setLoading(false);

      const flag = window.AP.flag.create({
        title: "Success: Blogs Added to Category",
        body: "Usually, changes can take a few seconds to reflect. Please refresh the page to see the effect.",
        type: "success",
        actions: {
          refresh: "Refresh page",
        },
      });

      setTimeout(() => {
        flag.close();
      }, 5000);
    }
  };

  useEffect(() => {
    window.AP.events.on("flag.action", ({ actionIdentifier }) => {
      if (actionIdentifier === "refresh") {
        window.location.reload();
      }
    });
  }, []);

  const addBlogs = () => {
    addCategoryToBlogs(selectedTab.id === LoadingTypes.DYNAMIC ? blogIds : selectedBlogs.map((blog) => blog.id));
  };

  const removeContentManually = (itemId) => {
    const newManuallyPicked = selectedBlogs.filter((item) => item.id !== itemId);
    setSelectedBlogs(newManuallyPicked);
  };

  return (
    <CustomModal
      heading="Assign Blogs"
      isUpdateDone={loading}
      onCancel={closeModal}
      onNext={() => addBlogs()}
      children={
        <div>
          <Tabs />
          {selectedTab.id === "dynamic" ? (
            <>
              <Description>Use spaces, authors, or labels as filters to add multiple blogs to a category.</Description>
              <SpaceMultiSelect
                initialSpaces={selectedSpaces}
                isDynamic={true}
                onUpdate={(spaces) => setSelectedSpaces(spaces)}
                containerStyle={{ marginTop: 20 }}
              />
              <PeoplePicker
                isMulti
                initialAccountIDs={selectedCreator}
                label="Contributors"
                placeholder="Filter by contributors"
                onUpdate={(users) => setSelectedCreator(users)}
                customStyles={{ marginTop: 10 }}
              />
              <LabelMultiSelect
                label="Labels"
                placeholder="Filter by labels"
                initialLabels={selectedLabels}
                onUpdate={(labels) => {
                  setSelectedLabels(labels);
                }}
                containerStyle={{ marginTop: 10 }}
              />
              <FilterBlogsText>Filtered blogs: {numberOfBlogs}</FilterBlogsText>
            </>
          ) : (
            <>
              <Description>Select blogs manually to add them to a category.</Description>
              <BlogPicker
                manuallyPicked={selectedTemporaryBlogs}
                onUpdate={(selectedBlog) => {
                  if (selectedBlogs.some((item) => item.contentId === selectedBlog.contentId)) return;
                  setSelectedBlogs([...selectedBlogs, selectedBlog]);
                  setSelectedTemporaryBlogs(selectedTemporaryBlogs);
                }}
                customStyles={SELECT_STYLES}
                label="Blog"
                placeholder="Select blog(s)"
                closeMenuOnSelect={true}
              />
              <ItemWrapper>
                {selectedBlogs.map((blog) => (
                  <Item key={blog.id}>
                    <ItemTitle>{blog.name}</ItemTitle>

                    <RemoveContainer className="actions" onClick={() => removeContentManually(blog.id)}>
                      <EditorRemoveIcon />
                    </RemoveContainer>
                  </Item>
                ))}
              </ItemWrapper>
            </>
          )}
        </div>
      }
    />
  );
}

export default AddBlogs;
