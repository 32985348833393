import React from "react";
import styled, { useTheme } from "styled-components";
import EditorCloseIcon from "@atlaskit/icon/glyph/editor/close";

const CustomModalWrapper = styled.div`
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.4);
`;

const CustomModalBackground = styled.div`
  background-color: ${({ theme }) => theme.background};
  margin: 10% auto;
  padding: 10px;
  border-radius: 9px;
  width: 492px;
  height: 295px;
`;

const CustomModalContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const CustomModalHeader = styled.div`
  display: flex;
  flex-direction: column;
`;

const CloseModalWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const CloseModalButton = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  box-sizing: border-box;
  cursor: pointer;
  user-select: none;
  white-space: nowrap;
  z-index: 10;

  &:active {
    color: #91b4e8;
  }
`;

const CustomModalBody = styled.div`
  padding: 0px 20px;
`;

const CustomModalHeadingWrapper = styled.div`
  padding: 0px 20px;
`;

const CustomModalHeading = styled.h1`
  font-weight: 700;
  font-size: 18px;
  color: ${({ theme }) => theme.manualOrgChart.buttonTextColor};
`;

const CustomModalSubheading = styled.h3`
  font-weight: 400;
  font-size: 14px;
  color: ${({ theme }) => theme.manualOrgChart.buttonTextColor};
`;

const CustomModalFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 75px;
`;

const CustomModalButtons = styled.div`
  margin-top: 20px;
  display: flex;
  gap: 12px;
  margin-right: 10px;
`;

export const NextButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 12px;
  height: 34px;
  border: none;
  background: #0065ff;
  border-radius: 3px;
  box-sizing: border-box;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  width: fit-content;
  cursor: pointer;
  user-select: none;
  white-space: nowrap;

  opacity: 1;
  transition: opacity 150ms linear;

  &:hover {
    opacity: 0.9;
  }
`;

export const CancelButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 12px;
  height: 34px;
  border: none;
  background: ${({ theme }) => theme.shared.form.button.background.hover};
  border-radius: 3px;
  box-sizing: border-box;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.manualOrgChart.buttonTextColor};
  width: fit-content;
  cursor: pointer;
  user-select: none;
  white-space: nowrap;

  opacity: 1;
  transition: opacity 150ms linear;

  &:hover {
    opacity: 0.9;
  }
`;

function CustomModal({ heading, subheading, children, onCancel, onNext, disabled }) {
  const theme = useTheme();
  return (
    <CustomModalWrapper>
      <CustomModalBackground>
        <CustomModalContent>
          <CustomModalHeader>
            <CloseModalWrapper>
              <CloseModalButton onClick={onCancel}>
                <EditorCloseIcon primaryColor={theme.shared.form.calendar.closeIconColor} size="large" />
              </CloseModalButton>
            </CloseModalWrapper>
            <CustomModalHeadingWrapper>
              <CustomModalHeading>{heading}</CustomModalHeading>
              <CustomModalSubheading>{subheading}</CustomModalSubheading>
            </CustomModalHeadingWrapper>
          </CustomModalHeader>
          <CustomModalBody>{children}</CustomModalBody>
          <CustomModalFooter>
            <CustomModalButtons>
              <CancelButton onClick={onCancel}>Cancel</CancelButton>
              <NextButton onClick={onNext} disabled={disabled}>
                Create
              </NextButton>
            </CustomModalButtons>
          </CustomModalFooter>
        </CustomModalContent>
      </CustomModalBackground>
    </CustomModalWrapper>
  );
}

export default CustomModal;
