import React from "react";
import Select from "@atlaskit/select";
import { useEffect } from "react";
import { useState } from "react";
import { FieldContainer, Label } from "./styled";
import { SELECT_STYLES } from "./CustomSelectStyles";

export const PaginationTypes = {
  INFINITE: "infinite",
  CAROUSEL: "carousel",
  NONE: "none",
};

const PaginationSelect = ({ initialValue, onUpdate, newBlogVariant }) => {
  const [selected, setSelected] = useState();

  const paginationOptions = [
    { label: "Show more-button", value: PaginationTypes.INFINITE },
    // { label: "Slider", value: PaginationTypes.CAROUSEL },
    { label: "Inactive", value: PaginationTypes.NONE },
  ];

  useEffect(() => {
    if (initialValue) {
      const foundOption = paginationOptions.find((p) => p.value === initialValue);
      if (foundOption) {
        setSelected(foundOption);
      }
    }
  }, [initialValue]);

  return (
    <FieldContainer>
      <Label>Pagination</Label>
      <Select
        menuPlacement="auto"
        styles={SELECT_STYLES}
        options={paginationOptions}
        value={selected}
        onChange={(newOption) => {
          onUpdate(newOption.value);
        }}
      />
    </FieldContainer>
  );
};

export default PaginationSelect;
