import styled from "styled-components";

export const ContentWrapper = styled.div`
  padding: 60px 30px;
  display: grid;
  grid-template-columns: 258px auto;
  column-gap: 60px;
  max-width: 1270px;
  margin: 0 auto;

  .infinite-scroll-component {
    overflow: initial !important;
  }
`;

export const ToolbarDualColumns = styled.div`
  display: grid;
  align-items: center;
  justify-content: space-between;
  column-gap: 15px;
  grid-template-columns: 1fr 1fr;
`;

export const ToolbarTripleColumn = styled.div`
  display: grid;
  align-items: center;
  column-gap: 15px;
  grid-template-columns: 1fr auto 1fr;
`;

export const ActionsToolbarWrapper = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: ${({ oneColumn }) => (oneColumn ? "1fr" : "1fr 1fr")};
  padding: 15px 30px 15px 30px;

  position: sticky;
  top: 0px;
  z-index: 56;
  background-color: #f5f8fb;
`;

export const SortFilterWrapper = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 175px auto auto;
  column-gap: 15px;
`;

export const ViewCreateWrapper = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 175px auto;
  column-gap: 15px;
`;

export const FilterButton = styled.div`
  display: flex;
  align-items: center;
  column-gap: 5px;
`;

export const ActionText = styled.span`
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #42526e;
`;

export const CustomButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 12px;
  height: 34px;
  background: ${({ bg }) => bg};
  border-radius: 3px;
  box-sizing: border-box;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${({ color }) => color};
  width: fit-content;
  cursor: pointer;
  user-select: none;
  white-space: nowrap;

  opacity: 1;
  transition: opacity 150ms linear;

  &:hover {
    opacity: 0.9;
  }
`;

export const FilterLabel = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #6b778c;
  margin-bottom: 5px;
`;

export const SpinnerContainer = styled.div`
  display: grid;
  place-content: center;
  margin-top: 50px;
`;

export const CustomDropdownButton = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 12px;
  width: 250px;
  height: 40px;
  border-radius: 3px;
  box-sizing: border-box;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  user-select: none;
  white-space: nowrap;
`;
