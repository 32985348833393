import React from "react";
import { useTheme } from "styled-components";
import { THEME_NAME_LIGHT } from "../../../../../Navigation/functions/generateNewEntry";

function NoNewStartersSVG() {
  const theme = useTheme();

  if (theme.global.name === THEME_NAME_LIGHT) {
    return (
      <svg width="160" height="156" viewBox="0 0 160 156" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_3193_3636)">
          <mask
            id="mask0_3193_3636"
            style={{ maskType: "alpha" }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="160"
            height="156"
          >
            <rect
              width="160"
              height="156"
              transform="matrix(1 1.74846e-07 1.74846e-07 -1 0 156)"
              fill="url(#paint0_linear_3193_3636)"
            />
          </mask>
          <g mask="url(#mask0_3193_3636)">
            <g clipPath="url(#clip1_3193_3636)">
              <g filter="url(#filter0_d_3193_3636)">
                <path
                  d="M28 59.722C28 55.6784 31.2448 52.4004 35.2475 52.4004H65.5525C69.5552 52.4004 72.8 55.6784 72.8 59.722V97.5787C72.8 101.622 69.5552 104.9 65.5525 104.9H35.2475C31.2448 104.9 28 101.622 28 97.5787V59.722Z"
                  fill="#E9F2FF"
                />
                <path
                  d="M28.35 59.722C28.35 55.8683 31.4415 52.7504 35.2475 52.7504H65.5525C69.3586 52.7504 72.45 55.8683 72.45 59.722V97.5787C72.45 101.432 69.3585 104.55 65.5525 104.55H35.2475C31.4414 104.55 28.35 101.432 28.35 97.5787V59.722Z"
                  stroke="#0C66E4"
                  strokeWidth="0.7"
                />
              </g>
              <path
                d="M28 61.2602C28 56.3671 31.9667 52.4004 36.8598 52.4004H63.9402C68.8333 52.4004 72.8 56.3671 72.8 61.2602V67.562H28V61.2602Z"
                fill="#CCE0FF"
              />
              <path
                d="M58.9651 67.6759C58.9651 72.3982 55.1768 76.2195 50.5121 76.2195C45.8473 76.2195 42.059 72.3982 42.059 67.6759C42.059 62.9535 45.8473 59.1322 50.5121 59.1322C55.1768 59.1322 58.9651 62.9535 58.9651 67.6759Z"
                fill="#E4F0FF"
                stroke="white"
                strokeWidth="0.789832"
              />
              <rect x="42.1123" y="93.1328" width="8.19026" height="8.19026" rx="4.09513" fill="#F6F6F6" />
              <rect x="52.8623" y="93.1328" width="8.19026" height="8.19026" rx="4.09513" fill="#DEEBFF" />
              <rect x="34.7773" y="78.3145" width="30.451" height="4.03846" rx="2.01923" fill="#579DFF" />
              <rect x="37.0615" y="85.0449" width="26.2639" height="2.35577" rx="1.17788" fill="#579DFF" />
              <g filter="url(#filter1_d_3193_3636)">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M65.5525 53.0332H35.2475C31.5908 53.0332 28.6264 56.0279 28.6264 59.722V97.5787C28.6264 101.273 31.5907 104.268 35.2475 104.268H65.5525C69.2092 104.268 72.1736 101.273 72.1736 97.5787V59.722C72.1736 56.0279 69.2093 53.0332 65.5525 53.0332ZM35.2475 52.4004C31.2448 52.4004 28 55.6784 28 59.722V97.5787C28 101.622 31.2448 104.9 35.2475 104.9H65.5525C69.5552 104.9 72.8 101.622 72.8 97.5787V59.722C72.8 55.6784 69.5552 52.4004 65.5525 52.4004H35.2475Z"
                  fill="#0C66E4"
                />
              </g>
            </g>
            <g clipPath="url(#clip2_3193_3636)">
              <g filter="url(#filter2_d_3193_3636)">
                <path
                  d="M41.6504 55.9364C41.6504 51.084 45.5442 47.1504 50.3473 47.1504H86.7134C91.5166 47.1504 95.4104 51.084 95.4104 55.9364V101.364C95.4104 106.217 91.5166 110.15 86.7134 110.15H50.3473C45.5441 110.15 41.6504 106.217 41.6504 101.364V55.9364Z"
                  fill="#E9F2FF"
                />
                <path
                  d="M42.0704 55.9364C42.0704 51.3119 45.7801 47.5704 50.3473 47.5704H86.7134C91.2807 47.5704 94.9904 51.3119 94.9904 55.9364V101.364C94.9904 105.989 91.2806 109.73 86.7134 109.73H50.3473C45.7801 109.73 42.0704 105.989 42.0704 101.364V55.9364Z"
                  stroke="#0C66E4"
                  strokeWidth="0.84"
                />
              </g>
              <path
                d="M41.6504 57.7822C41.6504 51.9104 46.4104 47.1504 52.2822 47.1504H84.7786C90.6504 47.1504 95.4104 51.9104 95.4104 57.7822V65.3444H41.6504V57.7822Z"
                fill="#CCE0FF"
              />
              <path
                d="M78.8092 65.4812C78.8092 71.148 74.2631 75.7336 68.6655 75.7336C63.0678 75.7336 58.5218 71.148 58.5218 65.4812C58.5218 59.8144 63.0678 55.2288 68.6655 55.2288C74.2631 55.2288 78.8092 59.8144 78.8092 65.4812Z"
                fill="#E4F0FF"
                stroke="white"
                strokeWidth="0.947798"
              />
              <rect x="58.5859" y="96.0293" width="9.82831" height="9.82831" rx="4.91415" fill="#F6F6F6" />
              <rect x="71.4854" y="96.0293" width="9.82831" height="9.82831" rx="4.91415" fill="#DEEBFF" />
              <rect x="49.7822" y="78.2471" width="36.5411" height="4.84615" rx="2.42308" fill="#579DFF" />
              <rect x="52.5244" y="86.3242" width="31.5167" height="2.82692" rx="1.41346" fill="#579DFF" />
              <g filter="url(#filter3_d_3193_3636)">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M86.7134 47.9098H50.3473C45.9593 47.9098 42.4021 51.5034 42.4021 55.9364V101.364C42.4021 105.797 45.9593 109.391 50.3473 109.391H86.7134C91.1015 109.391 94.6587 105.797 94.6587 101.364V55.9364C94.6587 51.5034 91.1015 47.9098 86.7134 47.9098ZM50.3473 47.1504C45.5442 47.1504 41.6504 51.084 41.6504 55.9364V101.364C41.6504 106.217 45.5441 110.15 50.3473 110.15H86.7134C91.5166 110.15 95.4104 106.217 95.4104 101.364V55.9364C95.4104 51.084 91.5166 47.1504 86.7134 47.1504H50.3473Z"
                  fill="#0C66E4"
                />
              </g>
            </g>
            <g clipPath="url(#clip3_3193_3636)">
              <g filter="url(#filter4_d_3193_3636)">
                <path
                  d="M60.9004 48.5204C60.9004 42.1584 66.0718 37.001 72.451 37.001H120.75C127.129 37.001 132.3 42.1584 132.3 48.5204V108.082C132.3 114.444 127.129 119.601 120.75 119.601H72.451C66.0718 119.601 60.9004 114.444 60.9004 108.082V48.5204Z"
                  fill="#E9F2FF"
                />
                <path
                  d="M61.2504 48.5204C61.2504 42.3526 66.2642 37.351 72.451 37.351H120.75C126.937 37.351 131.95 42.3526 131.95 48.5204V108.082C131.95 114.249 126.937 119.251 120.75 119.251H72.451C66.2642 119.251 61.2504 114.249 61.2504 108.082V48.5204Z"
                  stroke="#0C66E4"
                  strokeWidth="0.7"
                />
              </g>
              <path
                d="M60.9004 51.0145C60.9004 43.275 67.1745 37.001 74.9139 37.001H118.287C126.026 37.001 132.3 43.275 132.3 51.0145V60.8553H60.9004V51.0145Z"
                fill="#CCE0FF"
              />
              <path
                d="M110.255 61.0331C110.255 68.4535 104.223 74.4718 96.7783 74.4718C89.3336 74.4718 83.3014 68.4535 83.3014 61.0331C83.3014 53.6127 89.3336 47.5944 96.7783 47.5944C104.223 47.5944 110.255 53.6127 110.255 61.0331Z"
                fill="#CCE0FF"
                stroke="#E9F2FF"
                strokeWidth="1.24927"
              />
              <rect x="83.3916" y="101.086" width="12.9545" height="12.9545" rx="6.47724" fill="#579DFF" />
              <rect x="100.395" y="101.086" width="12.9545" height="12.9545" rx="6.47724" fill="#579DFF" />
              <rect x="72.2559" y="77.7705" width="48.1641" height="6.35385" rx="3.17692" fill="#579DFF" />
              <rect x="75.8682" y="88.3604" width="41.5415" height="3.70641" rx="1.8532" fill="#579DFF" />
              <g filter="url(#filter5_d_3193_3636)">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M120.75 37.9966H72.451C66.6231 37.9966 61.8987 42.7083 61.8987 48.5204V108.082C61.8987 113.894 66.6231 118.605 72.451 118.605H120.75C126.578 118.605 131.302 113.894 131.302 108.082V48.5204C131.302 42.7083 126.578 37.9966 120.75 37.9966ZM72.451 37.001C66.0718 37.001 60.9004 42.1584 60.9004 48.5204V108.082C60.9004 114.444 66.0718 119.601 72.451 119.601H120.75C127.129 119.601 132.3 114.444 132.3 108.082V48.5204C132.3 42.1584 127.129 37.001 120.75 37.001H72.451Z"
                  fill="#0C66E4"
                />
              </g>
            </g>
          </g>
        </g>
        <defs>
          <filter
            id="filter0_d_3193_3636"
            x="12.7318"
            y="45.6145"
            width="75.3363"
            height="83.0365"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="8.48235" />
            <feGaussianBlur stdDeviation="7.63412" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.403111 0 0 0 0 0.524415 0 0 0 0 0.709375 0 0 0 0.15 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3193_3636" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3193_3636" result="shape" />
          </filter>
          <filter
            id="filter1_d_3193_3636"
            x="12.7318"
            y="45.6145"
            width="75.3363"
            height="83.0365"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="8.48235" />
            <feGaussianBlur stdDeviation="7.63412" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.403111 0 0 0 0 0.524415 0 0 0 0 0.709375 0 0 0 0.15 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3193_3636" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3193_3636" result="shape" />
          </filter>
          <filter
            id="filter2_d_3193_3636"
            x="23.3285"
            y="39.0073"
            width="90.4035"
            height="99.6438"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="10.1788" />
            <feGaussianBlur stdDeviation="9.16094" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.403111 0 0 0 0 0.524415 0 0 0 0 0.709375 0 0 0 0.15 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3193_3636" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3193_3636" result="shape" />
          </filter>
          <filter
            id="filter3_d_3193_3636"
            x="23.3285"
            y="39.0073"
            width="90.4035"
            height="99.6438"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="10.1788" />
            <feGaussianBlur stdDeviation="9.16094" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.403111 0 0 0 0 0.524415 0 0 0 0 0.709375 0 0 0 0.15 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3193_3636" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3193_3636" result="shape" />
          </filter>
          <filter
            id="filter4_d_3193_3636"
            x="45.6322"
            y="30.2151"
            width="101.937"
            height="113.136"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="8.48235" />
            <feGaussianBlur stdDeviation="7.63412" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.403111 0 0 0 0 0.524415 0 0 0 0 0.709375 0 0 0 0.15 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3193_3636" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3193_3636" result="shape" />
          </filter>
          <filter
            id="filter5_d_3193_3636"
            x="40.5231"
            y="27.9444"
            width="112.155"
            height="123.354"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="11.3207" />
            <feGaussianBlur stdDeviation="10.1887" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.403111 0 0 0 0 0.524415 0 0 0 0 0.709375 0 0 0 0.15 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3193_3636" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3193_3636" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear_3193_3636"
            x1="59.5973"
            y1="156"
            x2="133.691"
            y2="156"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </linearGradient>
          <clipPath id="clip0_3193_3636">
            <rect width="160" height="155.98" fill="white" />
          </clipPath>
          <clipPath id="clip1_3193_3636">
            <rect x="28" y="52.4004" width="44.8" height="52.5" rx="5.70955" fill="white" />
          </clipPath>
          <clipPath id="clip2_3193_3636">
            <rect x="41.6494" y="47.1504" width="53.76" height="63" rx="6.85146" fill="white" />
          </clipPath>
          <clipPath id="clip3_3193_3636">
            <rect x="60.9004" y="37" width="71.4" height="82.6" rx="9.03077" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  } else {
    return (
      <svg width="160" height="156" viewBox="0 0 160 156" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_3193_3637)">
          <mask
            id="mask0_3193_3637"
            style={{ maskType: "alpha" }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="160"
            height="156"
          >
            <rect
              width="160"
              height="156"
              transform="matrix(1 1.74846e-07 1.74846e-07 -1 0 156)"
              fill="url(#paint0_linear_3193_3637)"
            />
          </mask>
          <g mask="url(#mask0_3193_3637)">
            <g clipPath="url(#clip1_3193_3637)">
              <g filter="url(#filter0_d_3193_3637)">
                <path
                  d="M28 59.722C28 55.6784 31.2448 52.4004 35.2475 52.4004H65.5525C69.5552 52.4004 72.8 55.6784 72.8 59.722V97.5787C72.8 101.622 69.5552 104.9 65.5525 104.9H35.2475C31.2448 104.9 28 101.622 28 97.5787V59.722Z"
                  fill="#1C2B41"
                />
                <path
                  d="M28.35 59.722C28.35 55.8683 31.4415 52.7504 35.2475 52.7504H65.5525C69.3586 52.7504 72.45 55.8683 72.45 59.722V97.5787C72.45 101.432 69.3585 104.55 65.5525 104.55H35.2475C31.4414 104.55 28.35 101.432 28.35 97.5787V59.722Z"
                  stroke="#579DFF"
                  strokeWidth="0.7"
                />
              </g>
              <path
                d="M28 61.2602C28 56.3671 31.9667 52.4004 36.8598 52.4004H63.9402C68.8333 52.4004 72.8 56.3671 72.8 61.2602V67.562H28V61.2602Z"
                fill="#09326C"
              />
              <path
                d="M58.9651 67.6759C58.9651 72.3982 55.1768 76.2195 50.5121 76.2195C45.8473 76.2195 42.059 72.3982 42.059 67.6759C42.059 62.9535 45.8473 59.1322 50.5121 59.1322C55.1768 59.1322 58.9651 62.9535 58.9651 67.6759Z"
                fill="#E4F0FF"
                stroke="white"
                strokeWidth="0.789832"
              />
              <rect x="42.1123" y="93.1328" width="8.19026" height="8.19026" rx="4.09513" fill="#F6F6F6" />
              <rect x="52.8623" y="93.1328" width="8.19026" height="8.19026" rx="4.09513" fill="#DEEBFF" />
              <rect x="34.7773" y="78.3145" width="30.451" height="4.03846" rx="2.01923" fill="#0055CC" />
              <rect x="37.0615" y="85.0449" width="26.2639" height="2.35577" rx="1.17788" fill="#0055CC" />
              <g filter="url(#filter1_d_3193_3637)">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M65.5525 53.0332H35.2475C31.5908 53.0332 28.6264 56.0279 28.6264 59.722V97.5787C28.6264 101.273 31.5907 104.268 35.2475 104.268H65.5525C69.2092 104.268 72.1736 101.273 72.1736 97.5787V59.722C72.1736 56.0279 69.2093 53.0332 65.5525 53.0332ZM35.2475 52.4004C31.2448 52.4004 28 55.6784 28 59.722V97.5787C28 101.622 31.2448 104.9 35.2475 104.9H65.5525C69.5552 104.9 72.8 101.622 72.8 97.5787V59.722C72.8 55.6784 69.5552 52.4004 65.5525 52.4004H35.2475Z"
                  fill="#579DFF"
                />
              </g>
            </g>
            <g clipPath="url(#clip2_3193_3637)">
              <g filter="url(#filter2_d_3193_3637)">
                <path
                  d="M41.6504 55.9364C41.6504 51.084 45.5442 47.1504 50.3473 47.1504H86.7134C91.5166 47.1504 95.4104 51.084 95.4104 55.9364V101.364C95.4104 106.217 91.5166 110.15 86.7134 110.15H50.3473C45.5441 110.15 41.6504 106.217 41.6504 101.364V55.9364Z"
                  fill="#1C2B41"
                />
                <path
                  d="M42.0704 55.9364C42.0704 51.3119 45.7801 47.5704 50.3473 47.5704H86.7134C91.2807 47.5704 94.9904 51.3119 94.9904 55.9364V101.364C94.9904 105.989 91.2806 109.73 86.7134 109.73H50.3473C45.7801 109.73 42.0704 105.989 42.0704 101.364V55.9364Z"
                  stroke="#579DFF"
                  strokeWidth="0.84"
                />
              </g>
              <path
                d="M41.6504 57.7822C41.6504 51.9104 46.4104 47.1504 52.2822 47.1504H84.7786C90.6504 47.1504 95.4104 51.9104 95.4104 57.7822V65.3444H41.6504V57.7822Z"
                fill="#09326C"
              />
              <rect x="71.4854" y="96.0293" width="9.82831" height="9.82831" rx="4.91415" fill="#DEEBFF" />
              <rect x="49.7822" y="78.2471" width="36.5411" height="4.84615" rx="2.42308" fill="#0055CC" />
              <rect x="52.5244" y="86.3242" width="31.5167" height="2.82692" rx="1.41346" fill="#0055CC" />
              <g filter="url(#filter3_d_3193_3637)">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M86.7134 47.9098H50.3473C45.9593 47.9098 42.4021 51.5034 42.4021 55.9364V101.364C42.4021 105.797 45.9593 109.391 50.3473 109.391H86.7134C91.1015 109.391 94.6587 105.797 94.6587 101.364V55.9364C94.6587 51.5034 91.1015 47.9098 86.7134 47.9098ZM50.3473 47.1504C45.5442 47.1504 41.6504 51.084 41.6504 55.9364V101.364C41.6504 106.217 45.5441 110.15 50.3473 110.15H86.7134C91.5166 110.15 95.4104 106.217 95.4104 101.364V55.9364C95.4104 51.084 91.5166 47.1504 86.7134 47.1504H50.3473Z"
                  fill="#579DFF"
                />
              </g>
            </g>
            <g clipPath="url(#clip3_3193_3637)">
              <g filter="url(#filter4_d_3193_3637)">
                <path
                  d="M60.9004 48.5204C60.9004 42.1584 66.0718 37.001 72.451 37.001H120.75C127.129 37.001 132.3 42.1584 132.3 48.5204V108.082C132.3 114.444 127.129 119.601 120.75 119.601H72.451C66.0718 119.601 60.9004 114.444 60.9004 108.082V48.5204Z"
                  fill="#1C2B41"
                />
                <path
                  d="M61.2504 48.5204C61.2504 42.3526 66.2642 37.351 72.451 37.351H120.75C126.937 37.351 131.95 42.3526 131.95 48.5204V108.082C131.95 114.249 126.937 119.251 120.75 119.251H72.451C66.2642 119.251 61.2504 114.249 61.2504 108.082V48.5204Z"
                  stroke="#579DFF"
                  strokeWidth="0.7"
                />
              </g>
              <path
                d="M60.9004 51.0145C60.9004 43.275 67.1745 37.001 74.9139 37.001H118.287C126.026 37.001 132.3 43.275 132.3 51.0145V60.8553H60.9004V51.0145Z"
                fill="#09326C"
              />
              <path
                d="M110.255 61.0331C110.255 68.4535 104.223 74.4718 96.7783 74.4718C89.3336 74.4718 83.3014 68.4535 83.3014 61.0331C83.3014 53.6127 89.3336 47.5944 96.7783 47.5944C104.223 47.5944 110.255 53.6127 110.255 61.0331Z"
                fill="#09326C"
                stroke="#1C2B41"
                strokeWidth="1.24927"
              />
              <rect x="83.3916" y="101.086" width="12.9545" height="12.9545" rx="6.47724" fill="#0055CC" />
              <rect x="100.395" y="101.086" width="12.9545" height="12.9545" rx="6.47724" fill="#0055CC" />
              <rect x="72.2559" y="77.7705" width="48.1641" height="6.35385" rx="3.17692" fill="#0055CC" />
              <rect x="75.8682" y="88.3604" width="41.5415" height="3.70641" rx="1.8532" fill="#0055CC" />
              <g filter="url(#filter5_d_3193_3637)">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M120.75 37.9966H72.451C66.6231 37.9966 61.8987 42.7083 61.8987 48.5204V108.082C61.8987 113.894 66.6231 118.605 72.451 118.605H120.75C126.578 118.605 131.302 113.894 131.302 108.082V48.5204C131.302 42.7083 126.578 37.9966 120.75 37.9966ZM72.451 37.001C66.0718 37.001 60.9004 42.1584 60.9004 48.5204V108.082C60.9004 114.444 66.0718 119.601 72.451 119.601H120.75C127.129 119.601 132.3 114.444 132.3 108.082V48.5204C132.3 42.1584 127.129 37.001 120.75 37.001H72.451Z"
                  fill="#579DFF"
                />
              </g>
            </g>
          </g>
        </g>
        <defs>
          <filter
            id="filter0_d_3193_3637"
            x="12.7318"
            y="45.6145"
            width="75.3363"
            height="83.0365"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="8.48235" />
            <feGaussianBlur stdDeviation="7.63412" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.403111 0 0 0 0 0.524415 0 0 0 0 0.709375 0 0 0 0.15 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3193_3637" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3193_3637" result="shape" />
          </filter>
          <filter
            id="filter1_d_3193_3637"
            x="12.7318"
            y="45.6145"
            width="75.3363"
            height="83.0365"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="8.48235" />
            <feGaussianBlur stdDeviation="7.63412" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.403111 0 0 0 0 0.524415 0 0 0 0 0.709375 0 0 0 0.15 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3193_3637" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3193_3637" result="shape" />
          </filter>
          <filter
            id="filter2_d_3193_3637"
            x="23.3285"
            y="39.0073"
            width="90.4035"
            height="99.6438"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="10.1788" />
            <feGaussianBlur stdDeviation="9.16094" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.403111 0 0 0 0 0.524415 0 0 0 0 0.709375 0 0 0 0.15 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3193_3637" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3193_3637" result="shape" />
          </filter>
          <filter
            id="filter3_d_3193_3637"
            x="23.3285"
            y="39.0073"
            width="90.4035"
            height="99.6438"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="10.1788" />
            <feGaussianBlur stdDeviation="9.16094" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.403111 0 0 0 0 0.524415 0 0 0 0 0.709375 0 0 0 0.15 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3193_3637" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3193_3637" result="shape" />
          </filter>
          <filter
            id="filter4_d_3193_3637"
            x="45.6322"
            y="30.2151"
            width="101.937"
            height="113.136"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="8.48235" />
            <feGaussianBlur stdDeviation="7.63412" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.403111 0 0 0 0 0.524415 0 0 0 0 0.709375 0 0 0 0.15 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3193_3637" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3193_3637" result="shape" />
          </filter>
          <filter
            id="filter5_d_3193_3637"
            x="40.5231"
            y="27.9444"
            width="112.155"
            height="123.354"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="11.3207" />
            <feGaussianBlur stdDeviation="10.1887" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.403111 0 0 0 0 0.524415 0 0 0 0 0.709375 0 0 0 0.15 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3193_3637" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3193_3637" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear_3193_3637"
            x1="59.5973"
            y1="156"
            x2="133.691"
            y2="156"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </linearGradient>
          <clipPath id="clip0_3193_3637">
            <rect width="160" height="155.98" fill="white" />
          </clipPath>
          <clipPath id="clip1_3193_3637">
            <rect x="28" y="52.4004" width="44.8" height="52.5" rx="5.70955" fill="white" />
          </clipPath>
          <clipPath id="clip2_3193_3637">
            <rect x="41.6494" y="47.1504" width="53.76" height="63" rx="6.85146" fill="white" />
          </clipPath>
          <clipPath id="clip3_3193_3637">
            <rect x="60.9004" y="37" width="71.4" height="82.6" rx="9.03077" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  }
}

export default React.memo(NoNewStartersSVG);
