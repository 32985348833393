import React from "react";
import styled, { useTheme } from "styled-components";
import MoreVerticalIcon from "@atlaskit/icon/glyph/more-vertical";
import { IconButton } from "@atlaskit/button/new";
import DropdownMenu, { DropdownItem, DropdownItemGroup } from "@atlaskit/dropdown-menu";
import { ModalKeys } from "../../../../../../../../Modal/modalKeys";
import { copyToClipboard } from "../../../../../../../../../Shared/Functions/copyToClipboard";

export const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;

  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: opacity 150ms linear;
`;

const ImageResizeIcon = ({ primaryColor }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.06767 12.9057L3.32067 18.6507C3.11432 18.8602 2.99907 19.1427 3.00001 19.4367C3.00094 19.7308 3.11799 20.0125 3.32567 20.2207L3.36167 20.2577C3.56949 20.4664 3.85161 20.5842 4.14614 20.5851C4.44067 20.586 4.72354 20.4701 4.93267 20.2627L10.6797 14.5187C10.8857 14.309 11.0006 14.0264 10.9994 13.7325C10.9983 13.4385 10.8812 13.1568 10.6737 12.9487L10.6367 12.9117C10.4284 12.7044 10.1468 12.5875 9.85291 12.5863C9.55902 12.5852 9.27753 12.7 9.06767 12.9057Z"
      fill={primaryColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.00067 15.5867V19.4827C3.00067 20.0907 3.49367 20.5847 4.10067 20.5847H8.00067C8.26629 20.5847 8.52102 20.4792 8.70884 20.2914C8.89666 20.1035 9.00217 19.8488 9.00217 19.5832C9.00217 19.3176 8.89666 19.0628 8.70884 18.875C8.52102 18.6872 8.26629 18.5817 8.00067 18.5817H5.00067V15.5867C5.00067 15.3215 4.89532 15.0671 4.70778 14.8796C4.52024 14.692 4.26589 14.5867 4.00067 14.5867C3.73546 14.5867 3.4811 14.692 3.29357 14.8796C3.10603 15.0671 3.00067 15.3215 3.00067 15.5867Z"
      fill={primaryColor}
    />
    <path
      d="M18.5853 5.003V7.998C18.5853 8.26322 18.6907 8.51757 18.8782 8.70511C19.0657 8.89264 19.3201 8.998 19.5853 8.998C19.8505 8.998 20.1049 8.89264 20.2924 8.70511C20.48 8.51757 20.5853 8.26322 20.5853 7.998V4.102C20.5853 3.494 20.0923 3 19.4853 3H15.5853C15.3197 3 15.065 3.10551 14.8772 3.29333C14.6893 3.48115 14.5838 3.73589 14.5838 4.0015C14.5838 4.26711 14.6893 4.52185 14.8772 4.70967C15.065 4.89749 15.3197 5.003 15.5853 5.003H18.5853Z"
      fill={primaryColor}
    />
    <path
      d="M13.3253 10.294C13.5115 10.4807 13.7641 10.5861 14.0278 10.587C14.2915 10.588 14.5448 10.4844 14.7323 10.299L19.8843 5.147C20.069 4.95911 20.1721 4.70593 20.1711 4.44247C20.1702 4.17902 20.0653 3.92658 19.8793 3.74L19.8453 3.706C19.6591 3.51927 19.4065 3.4139 19.1428 3.41296C18.8792 3.41203 18.6258 3.5156 18.4383 3.701L15.8623 6.277L13.2863 8.853C13.1016 9.04089 12.9986 9.29407 12.9995 9.55753C13.0004 9.82098 13.1053 10.0734 13.2913 10.26L13.3253 10.294Z"
      fill={primaryColor}
    />
  </svg>
);

function Actions({ visible = false, pictureUrl, editorUrl }) {
  const theme = useTheme();

  const confirmCopyFlag = () => {
    const flag = window.AP.flag.create({ title: "Copied to Clipboard", type: "success" });

    setTimeout(() => {
      flag.close();
    }, 3000);
  };

  return (
    <ActionsWrapper visible={visible}>
      <IconButton
        spacing="compact"
        icon={(iconProps) => (
          <ImageResizeIcon {...iconProps} primaryColor={theme.global.name === "dark" ? "#b6c2cf" : "#182b4d"} />
        )}
        label="fullscreen"
        onClick={() =>
          window.AP.dialog.create({
            key: "caelor-custom-dialog",
            chrome: false,
            customData: {
              modalKey: ModalKeys.PICTURE_PREVIEW,
              picture: pictureUrl,
            },
          })
        }
      />
      <DropdownMenu
        trigger={({ triggerRef, ...props }) => (
          <IconButton {...props} spacing="compact" icon={MoreVerticalIcon} label="more" ref={triggerRef} />
        )}
        shouldRenderToParent
      >
        <DropdownItemGroup>
          <DropdownItem onClick={() => window.open(editorUrl, "_blank")}>Edit</DropdownItem>
          <DropdownItem
            onClick={() => {
              copyToClipboard(editorUrl);
              confirmCopyFlag();
            }}
          >
            Copy Link
          </DropdownItem>
        </DropdownItemGroup>
      </DropdownMenu>
    </ActionsWrapper>
  );
}

export default Actions;
