import React, { useState } from "react";
import Button, { LoadingButton } from "@atlaskit/button";
import { analytics } from "../analytics";
import { nanoid } from "nanoid";
import styled from "styled-components";

const Wrapper = styled.div`
  background-color: #fff;
  width: 300px;
  height: 120px;
  border-radius: 5px;
  margin-top: 25% !important;
  margin: 0 auto;
  position: relative;
  padding: 20px;
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #42526e;
`;

const Description = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #42526e;
  margin-top: 25px;
`;

const Footer = styled.div`
  position: absolute;
  left: 20px;
  bottom: 20px;
  right: 20px;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 5px;
`;

const getMacroData = ({ macroId, localId }) => ({
  type: "extension",
  attrs: {
    layout: "full-width",
    extensionType: "com.atlassian.confluence.macro.core",
    extensionKey: "cosmos-embed-dashboard-macro",
    parameters: {
      macroParams: {
        data: {
          value: "embed-dashboard",
        },
        __bodyContent: {
          value: "",
        },
      },
      macroMetadata: {
        macroId: {
          value: macroId,
        },
        schemaVersion: {
          value: "1",
        },
        placeholder: [
          {
            type: "icon",
            data: {
              url: "https://leon-cosmos-tunnel.loophole.site/images/hub/sidebar-logo.svg",
            },
          },
        ],
        title: "Cosmos - Embed Dashboard",
      },
    },
    localId: localId,
  },
});

function InsertDialog() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const generateIds = () => {
    const macroId = `${nanoid(8)}-${nanoid(4)}-${nanoid(4)}-${nanoid(4)}-${nanoid(12)}`;
    const localId = `${nanoid(8)}-${nanoid(4)}-${nanoid(4)}-${nanoid(4)}-${nanoid(12)}`;

    return { macroId, localId };
  };

  const insertDialog = () => {
    window.AP.context.getContext(async (ctx) => {
      const {
        confluence: {
          content: { id, type, version },
          space: { id: spaceId },
        },
      } = ctx;

      setLoading(true);

      try {
        const response = await window.AP.request(
          `/api/v2/${type === "page" ? "pages" : "blogposts"}/${id}?body-format=atlas_doc_format`
        );

        if (response?.body) {
          const data = JSON.parse(response.body);

          if (data?.body?.atlas_doc_format) {
            const contentValue = JSON.parse(data.body.atlas_doc_format.value);
            const pageTitle = data.title;
            const pageStatus = data.status;

            const newContentValue = {
              ...contentValue,
              content: [...contentValue?.content, getMacroData(generateIds())],
            };

            const putResponse = await window.AP.request({
              url: `/api/v2/${type === "page" ? "pages" : "blogposts"}/${id}`,
              type: "PUT",
              headers: {
                Accept: "application/json",
                "X-Atlassian-Token": "nocheck",
              },
              contentType: "application/json",
              data: JSON.stringify({
                id: id,
                status: pageStatus,
                title: pageTitle,
                spaceId: spaceId,
                body: {
                  representation: "atlas_doc_format",
                  value: JSON.stringify(newContentValue),
                },
                version: {
                  number: Number(version) + 1,
                },
              }),
            });

            if (putResponse?.xhr?.status >= 200 && putResponse?.xhr?.status < 300) {
              window.AP.navigator.reload();
            }
          }
        } else {
          setError(true);
        }
      } catch (error) {
        setError(true);
      } finally {
        setLoading(false);
      }
    });
  };

  return (
    <Wrapper>
      <Title>Insert Cosmos Dashboard</Title>

      {error && <Description>Something went wrong, please refresh the page and try again.</Description>}

      <Footer>
        <Button onClick={() => window.AP.dialog.close()}>Cancel</Button>
        <LoadingButton appearance="primary" isLoading={loading} isDisabled={loading} onClick={() => insertDialog()}>
          Insert
        </LoadingButton>
      </Footer>
    </Wrapper>
  );
}

export default InsertDialog;
