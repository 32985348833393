import React from "react";
import RangeInput from "@atlaskit/range";
import styled from "styled-components";

const RangeStyleOverride = styled.div`
  input {
    --track-fg: #4c9aff !important;
    --track-bg: ${({ theme }) => theme.shared.sidebar.tabs.background.inactive} !important;
    --thumb-border: transparent !important;
  }

  input::-webkit-slider-thumb {
    background-color: #0065ff;
  }
  input::-moz-range-thumb {
    background-color: #0065ff;
  }
`;

function Range({ value = 100, min = 0, max = 100, step = 1, onChange = () => {}, isDisabled = false }) {
  return (
    <RangeStyleOverride>
      <RangeInput
        value={value}
        min={min}
        max={max}
        step={step}
        onChange={(changedValue) => onChange(changedValue)}
        isDisabled={isDisabled}
      />
    </RangeStyleOverride>
  );
}

export default Range;
