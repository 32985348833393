import httpClient from "../../../../Service/http-client";

export async function getUser({ queryKey }) {
  const [_key, { accountId }] = queryKey;
  try {
    const url = `/rest/api/user?accountId=${accountId}`;
    return window.AP.request(url);
  } catch (error) {
    console.log("can't fetch user");
    return undefined;
  }
}

export async function searchUsers(query) {
  const url = `/rest/api/search/user?cql=user.fullname~"${query ?? ""}*"`;
  try {
    return window.AP.request(url);
  } catch (error) {
    console.log("can't fetch users");
    return undefined;
  }
}

export async function getPeopleNetworkSettings({ queryKey }) {
  const url = "ac/confluence/peoplenetwork";
  return httpClient.get(url);
}

export async function savePeopleNetworkSettings(data) {
  const url = "ac/confluence/peoplenetwork/save";
  return httpClient.post(url, { data });
}
