/* eslint-disable no-underscore-dangle */
export const buildPageData = (pages) => {
  if (pages?.length) {
    return pages.map((page) => ({
      id: page.id,
      type: page.type,
      title: page.title,
      space: page.space.name,
      link: `${window.AP._hostOrigin}/wiki${
        page._links.tinyui || page._links.webui
      }`,
    }));
  }
  return [];
};

export const buildAttachmentData = (attachments) => {
  if (attachments.length) {
    return attachments.map((att) => ({
      title: att.title,
      type: att.metadata.mediaType,
      id: att.id,
      link: `${window.AP._hostOrigin}/wiki${att._links.webui}`,
      spaceKey: att.space.key,
      space: att.space.name,
    }));
  }
  return [];
};

export const fileToDataUri = (file) =>
  new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      resolve(event.target.result);
    };
    reader.readAsDataURL(file);
  });

export const validateAttachments = async (dataFiles) => {
  if (dataFiles.length) {
    const files = [];
    for (let i = 0; i < dataFiles.length; i += 1) {
      files.push(dataFiles[i]);
    }
    const result = await Promise.all(
      files.map(async (f) => {
        const fileUri = await fileToDataUri(f);
        return { file: f, url: fileUri };
      })
    );
    return result;
  }
  return undefined;
};
