import React, { useMemo } from "react";
import styled from "styled-components";
import Layout from "./Layout";
import Design from "./Design";
import CardListConfigurator from "./configurator/CardListConfigurator";
import { TabTypes } from "../../../../Dashboard/panels/tabTypes";

const CardEditorWrapper = styled.div`
  height: calc(100% - 50px);
`;

function CardsEditor({ panel, selectedTab, updatePanelDataProperty, updatePanelTitle, isInPeopleNetwork = false }) {
  const panelProps = useMemo(
    () => ({
      panel,
      updatePanelDataProperty,
      updatePanelTitle,
      isInPeopleNetwork,
    }),
    [panel, updatePanelDataProperty, updatePanelTitle, isInPeopleNetwork],
  );

  return (
    <CardEditorWrapper>
      {selectedTab === TabTypes.LAYOUT && <Layout {...panelProps} />}
      {selectedTab === TabTypes.CONTENT && <CardListConfigurator {...panelProps} />}
      {selectedTab === TabTypes.DESIGN && <Design {...panelProps} />}
    </CardEditorWrapper>
  );
}

export default CardsEditor;
