import styled from "styled-components";

export const IconRendererWrapper = styled.div`
  border-radius: 3px;
  overflow: hidden;
  border: 2px solid ${({ theme }) => theme.shared.form.input.border};
  transition: border 150ms linear;
  box-sizing: border-box;

  display: grid;
  place-content: center;
  height: 40px;
  width: 40px;

  cursor: pointer;
`;
