import styled, { useTheme } from "styled-components";
import EmptyState from "../../../../Shared/Components/EmptyState";

const Container = styled.div`
  display: grid;
  place-content: center;
  justify-items: center;
  text-align: center;
  width: 100%;
  margin-top: 120px;

  ${({ removeMargin }) => {
    if (removeMargin) {
      return `
        margin: 0px !important;
      `;
    }
  }}
`;

const Title = styled.p`
  font-weight: 590;
  font-size: 20px;
  line-height: 23.87px;
  color: ${({ theme }) => theme.notification.title};
  margin-top: 30px;
  margin-bottom: 12px;
`;

const Description = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.emptyState.text};
`;

function NoResultsState({ isPeopleSearch, removeMargin = false }) {
  const theme = useTheme();

  return (
    <Container removeMargin={removeMargin}>
      <EmptyState
        Image={() => {
          return (
            <img
              src={`/images/hub/no-search-empty-state-${theme.global.name}.svg`}
              alt="No search results"
              style={{ margin: "0 auto" }}
            />
          );
        }}
        title={"We couldn't find" + (isPeopleSearch ? " anyone " : " anything ") + "matching your search"}
        description="Try clearing some filters or changing the keywords."
      />
    </Container>
  );
}

export default NoResultsState;
