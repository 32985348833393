import React, { useEffect, useMemo, useRef, useState } from "react";
import { Radio } from "@atlaskit/radio";
import styled from "styled-components";
import SimpleToggle from "../../../filter/SimpleToggle";
import { Border, Label } from "../../../filter/styled";
import TextAreaFilter from "../../../filter/TextAreaFilter";
import { TabTypes } from "../../tabTypes";
import { ColorTile, ColorsWrapper, DesignSidebarWrapper, PanelEditorWrapper, Tab, TabsWrapper } from "./styled";
import GradientColorPicker from "../../../../../../../Shared/Components/GradientColorPicker";
import EmbededColorPicker from "../../../../../../../Shared/Components/EmbededColorPicker";
import { DEFAULT_COLORS } from "./colors";
import { COLOR_TYPES } from "../../../../Settings/General/BrandAndColors/color-types";
import { useCorporateIdentityStore } from "../../../../Settings/General/BrandAndColors/corporate-identity-store";
import { useTheme } from "styled-components";
import { Divider } from "../../../filter/styled";
import { validateAttachments } from "../../../../../../../Shared/Components/LinkSidebar/buildData";

const StyleRadioContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${Label} {
    margin-top: 3px;
    margin-bottom: 12px;
  }
`;

const StyleRadioHeading = styled.div`
  display: flex;
`;

const StyleCustomPicker = styled.div`
  padding-left: 6px;
`;

const Subtitle = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #626f86;
  margin-top: 25px;
  margin-bottom: 5px;
`;

export const USER_START_GRADIENTS = [
  "linear-gradient(270deg, #3a52c5 0%, #704ebf 24.35%, #b34cb5 50.13%, #d16b9e 77.69%, #efa37b 100%)",
  "linear-gradient(267.99deg, #15D0C5 -3.1%, #01C3C9 15.11%, #1692F2 104.58%)",
  "linear-gradient(269.2deg, #1371FF -2.64%, #5FC5FF 119.28%)",
  "linear-gradient(268.32deg, #FF5A8C -1.07%, #FD9B96 69.17%, #FBDA61 132.91%)",
  "linear-gradient(265.5deg, #FBC04E -9.15%, #FBAA4E 11.95%, #FF9142 85.57%)",
  "linear-gradient(45deg, #FFC400 0%, #00BBD9 100%)",
];

const RadioButtonWrapper = ({ children }) => {
  return <StyleRadioContainer>{children}</StyleRadioContainer>;
};

const LayoutSidebar = ({ panelId, data, updatePanelDataProperty }) => {
  return (
    <DesignSidebarWrapper>
      <div>
        <TextAreaFilter
          label="Description"
          value={data.message}
          onUpdate={(v) => updatePanelDataProperty(panelId, "message", v)}
        />
        <Divider />

        <Subtitle>Elements to display</Subtitle>

        <SimpleToggle
          label="Confluence action items"
          value={data.actionItemsEnabled}
          setValue={(v) => updatePanelDataProperty(panelId, "actionItemsEnabled", v)}
        />

        <SimpleToggle
          label="Jira issues assigned to me"
          value={data.jiraTasksEnabled}
          setValue={(v) => updatePanelDataProperty(panelId, "jiraTasksEnabled", v)}
        />
      </div>
    </DesignSidebarWrapper>
  );
};

const DesignSidebar = ({ panelId, data, updatePanelDataProperty }) => {
  const [gradientInitialized, setIsGradientInitalized] = useState(true);
  const [tab, setTab] = useState("Solid");
  const [pickerKey, setPickerKey] = useState(Math.random().toString(32).slice(2));
  const [isTabInit, setIsTabInit] = useState(false);
  const [isPredefined, setIsPredefined] = useState(true);

  const initRef = useRef();
  initRef.current = gradientInitialized;

  const isPredefinedRef = useRef();
  isPredefinedRef.current = isPredefined;

  const TabTypes = useMemo(() => ({ SOLID: "Solid", GRADIENT: "Gradient" }), []);

  const theme = useTheme();

  const colors = useCorporateIdentityStore((state) => state.colors);
  const primaryColor = colors[COLOR_TYPES.PRIMARY] || "#0065ff";

  useEffect(() => {
    if (data?.backgroundColor && !isTabInit) {
      if (typeof data.backgroundColor === "string") {
        setTab(data.backgroundColor.includes("gradient") ? TabTypes.GRADIENT : TabTypes.SOLID);
        setIsTabInit(true);
      } else {
        setTab(TabTypes.GRADIENT);
        setIsTabInit(true);
      }
    }
  }, [data.backgroundColor, isTabInit]);

  const isRadioCheckedInSolid = ({ isCheckingPredefined }) => {
    if (typeof data.backgroundColor !== "string" || data.backgroundColor.includes("gradient")) return false;

    const isPredefinedSelected = DEFAULT_COLORS.includes(data?.backgroundColor || "");
    return isCheckingPredefined ? isPredefinedSelected : !isPredefinedSelected;
  };

  const isRadioCheckedInCustom = ({ isCheckingPredefined }) => {
    if (typeof data.backgroundColor === "string" && !data.backgroundColor.includes("gradient")) return false;

    if (typeof data.backgroundColor === "object") return !isCheckingPredefined;

    const isPredefinedSelected = USER_START_GRADIENTS.includes(data?.backgroundColor || "");
    return isCheckingPredefined ? isPredefinedSelected : !isPredefinedSelected;
  };

  return (
    <DesignSidebarWrapper>
      <div>
        <Label
          style={{
            marginTop: "20px",
            marginBottom: "10px",
            color: theme.shared.sidebar.colors.inputLabel,
          }}
        >
          Background color
        </Label>

        <TabsWrapper>
          <Tab isSelected={tab === TabTypes.SOLID} primaryColor={primaryColor} onClick={() => setTab(TabTypes.SOLID)}>
            Solid
          </Tab>
          <Tab
            isSelected={tab === TabTypes.GRADIENT}
            primaryColor={primaryColor}
            onClick={() => setTab(TabTypes.GRADIENT)}
          >
            Gradient
          </Tab>
        </TabsWrapper>
        <div>
          {tab === TabTypes.SOLID && (
            <>
              <Border style={{ margin: "20px 0px" }} />

              <RadioButtonWrapper>
                <StyleRadioHeading>
                  <Radio isChecked={isRadioCheckedInSolid({ isCheckingPredefined: true })} />
                  <Label>Predefined colors</Label>
                </StyleRadioHeading>

                <ColorsWrapper style={{ marginBottom: "20px" }}>
                  {DEFAULT_COLORS.map((clr) => (
                    <ColorTile
                      key={clr}
                      color={clr}
                      isSelected={clr === data?.backgroundColor}
                      onClick={() => {
                        updatePanelDataProperty(panelId, "backgroundColor", clr);
                        setIsGradientInitalized(false);
                        setIsPredefined(true);
                        setPickerKey(Math.random().toString(32).slice(2));
                      }}
                    />
                  ))}
                </ColorsWrapper>
              </RadioButtonWrapper>

              <Border style={{ margin: "20px 0px" }} />
              <RadioButtonWrapper>
                <StyleRadioHeading>
                  <Radio isChecked={isRadioCheckedInSolid({ isCheckingPredefined: false })} />
                  <Label>Custom picker</Label>
                </StyleRadioHeading>
                <StyleCustomPicker>
                  <EmbededColorPicker
                    key={pickerKey}
                    value={typeof data.backgroundColor === "string" ? data.backgroundColor : ""}
                    setValue={(v) => updatePanelDataProperty(panelId, "backgroundColor", v)}
                    width={294}
                    height={175}
                    setIsGradientInitalized={setIsGradientInitalized}
                    onClick={() => setIsPredefined(false)}
                  />
                </StyleCustomPicker>
              </RadioButtonWrapper>
            </>
          )}

          {tab === TabTypes.GRADIENT && (
            <>
              <Border style={{ marginTop: "20px", marginBottom: "19px" }} />

              <RadioButtonWrapper isChecked={isRadioCheckedInCustom({ isCheckingPredefined: true })}>
                <div>
                  <StyleRadioHeading>
                    <Radio isChecked={isRadioCheckedInCustom({ isCheckingPredefined: true })} />
                    <Label>Predefined colors</Label>
                  </StyleRadioHeading>

                  <ColorsWrapper>
                    {USER_START_GRADIENTS.map((clr) => (
                      <ColorTile
                        key={clr}
                        color={clr}
                        isSelected={clr === data?.backgroundColor}
                        onClick={() => {
                          updatePanelDataProperty(panelId, "backgroundColor", clr);
                          setPickerKey(Math.random().toString(32).slice(2));
                          setIsGradientInitalized(true);
                          setIsPredefined(true);
                        }}
                      />
                    ))}
                  </ColorsWrapper>
                </div>
              </RadioButtonWrapper>

              <Border style={{ marginTop: "20px", marginBottom: "19px" }} />

              <RadioButtonWrapper>
                <StyleRadioHeading>
                  <Radio isChecked={isRadioCheckedInCustom({ isCheckingPredefined: false })} />
                  <Label>Custom picker</Label>
                </StyleRadioHeading>
                <div
                  onClick={() => {
                    setIsGradientInitalized(true);
                    setIsPredefined(false);
                  }}
                >
                  <GradientColorPicker
                    value={typeof data.backgroundColor !== "string" ? data.backgroundColor : undefined}
                    setValue={(value) => {
                      if (isPredefinedRef.current === false && initRef.current) {
                        updatePanelDataProperty(panelId, "backgroundColor", value);
                        setIsPredefined(false);
                      }
                    }}
                    presetColors={DEFAULT_COLORS}
                    isInitalized={gradientInitialized}
                    setIsInitialized={setIsGradientInitalized}
                  />
                </div>
              </RadioButtonWrapper>
            </>
          )}
        </div>
      </div>
    </DesignSidebarWrapper>
  );
};

function UserStartEditor({ panel, selectedTab, updatePanelDataProperty }) {
  const { data } = panel;

  return (
    <PanelEditorWrapper>
      {selectedTab === TabTypes.CONTENT && (
        <LayoutSidebar panelId={panel.id} data={data} updatePanelDataProperty={updatePanelDataProperty} />
      )}

      {selectedTab === TabTypes.DESIGN && (
        <DesignSidebar panelId={panel.id} data={data} updatePanelDataProperty={updatePanelDataProperty} />
      )}
    </PanelEditorWrapper>
  );
}

export default UserStartEditor;
