import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Radio } from "@atlaskit/radio";
import styled from "styled-components";
import DateFilter from "../../../filter/DateFilter";
import { Border, Divider, FieldContainer, Label } from "../../../filter/styled";
import TextAreaFilter from "../../../filter/TextAreaFilter";
import TextInputFilter from "../../../filter/TextInputFilter";
import { TabTypes } from "../../tabTypes";
import { ColorTile, ColorsWrapper, DesignSidebarWrapper, PanelEditorWrapper, Tab, TabsWrapper } from "./styled";
import GradientColorPicker from "../../../../../../../Shared/Components/GradientColorPicker";
import EmbededColorPicker from "../../../../../../../Shared/Components/EmbededColorPicker";
import { DEFAULT_COLORS, DEFAULT_GRADIENTS } from "./colors";
import SimpleToggle from "../../../filter/SimpleToggle";
import LinkField from "../../../../../../../Shared/Components/LinkField";
import SidebarTransition from "../../../../../../../Shared/Components/SidebarTransition";
import LinkSidebar from "../../../../../../../Shared/Components/LinkSidebar/LinkSidebar";
import { useSidebarStore } from "../sidebar/sidebar-store";

const StyleRadioContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${Label} {
    margin-top: 3px;
    margin-bottom: 12px;
  }
`;

const StyleRadioHeading = styled.div`
  display: flex;
`;
const StyleCustomPicker = styled.div`
  padding-left: 6px;
`;

const CountdownPropertyKeys = {
  DATE: "dateandtime",
  TITLE: "title",
  DESCRIPTION: "description",
  COLOR: "color",
  OPEN_NEW_TAB: "openInNewTab",
  LINK: "link",
  REMOVE_ON_FINISHED_COUNT: "removeOnFinishedCount",
};

const RadioButtonWrapper = ({ children }) => {
  return <StyleRadioContainer>{children}</StyleRadioContainer>;
};

const LayoutSidebar = ({ panelId, data, updatePanelDataProperty }) => {
  const [linkSidebarOpen, setLinkSidebarOpen] = useState(false);

  const linkSidebarRef = useRef();

  const { setDisableClickOutside } = useSidebarStore((state) => ({
    setDisableClickOutside: state.setDisableClickOutside,
  }));

  const changeSidebarOpen = (isOpen) => {
    setLinkSidebarOpen(isOpen);
    setDisableClickOutside(isOpen);
  };

  return (
    <>
      <TextInputFilter
        label="Title"
        value={data.title}
        onUpdate={(value) => updatePanelDataProperty(panelId, CountdownPropertyKeys.TITLE, value)}
      />

      <TextAreaFilter
        label="Description"
        value={data.description}
        onUpdate={(value) => updatePanelDataProperty(panelId, CountdownPropertyKeys.DESCRIPTION, value)}
      />

      <DateFilter
        label="Date / Time"
        value={data.dateandtime}
        onChange={(value) => updatePanelDataProperty(panelId, CountdownPropertyKeys.DATE, value)}
      />

      <FieldContainer>
        <SimpleToggle
          label="Disappear after the countdown ends"
          value={!!data?.removeOnFinishedCount}
          setValue={(value) => updatePanelDataProperty(panelId, CountdownPropertyKeys.REMOVE_ON_FINISHED_COUNT, value)}
          customStyles={{ padding: "0px" }}
        />
      </FieldContainer>

      <Divider />

      <FieldContainer>
        <Label>Link</Label>
        <LinkField
          value={data?.link}
          setValue={() => updatePanelDataProperty(panelId, CountdownPropertyKeys.LINK, undefined)}
          openInNewTab={data?.openInNewTab}
          setOpenInNewTab={(shouldOpenInNewTab) =>
            updatePanelDataProperty(panelId, CountdownPropertyKeys.OPEN_NEW_TAB, shouldOpenInNewTab)
          }
          setIsEditingLink={() => changeSidebarOpen(true)}
        />
      </FieldContainer>

      <SidebarTransition
        isOpen={linkSidebarOpen}
        close={() => changeSidebarOpen(false)}
        width={500}
        customScrollbarHeight="calc(100% - 55px)"
        onlyChildren
      >
        <LinkSidebar
          selectedTab={0}
          value={data?.link}
          onSelected={(link) => {
            updatePanelDataProperty(panelId, CountdownPropertyKeys.LINK, link);
            changeSidebarOpen(false);
          }}
          onClose={() => changeSidebarOpen(false)}
          showAttachments
          ref={linkSidebarRef}
        />
      </SidebarTransition>
    </>
  );
};

const DesignSidebar = ({ panelId, data, updatePanelDataProperty }) => {
  const [gradientInitialized, setIsGradientInitalized] = useState(true);
  const [tab, setTab] = useState("Solid");
  const [pickerKey, setPickerKey] = useState(Math.random().toString(32).slice(2));
  const [isTabInit, setIsTabInit] = useState(false);
  const [isPredefined, setIsPredefined] = useState(true);

  const primaryColor = "#0065FF";

  const initRef = useRef();
  initRef.current = gradientInitialized;

  const isPredefinedRef = useRef();
  isPredefinedRef.current = isPredefined;

  const TabTypes = useMemo(() => ({ SOLID: "Solid", GRADIENT: "Gradient" }), []);

  useEffect(() => {
    if (data?.color && !isTabInit) {
      setTab(typeof data?.color === "string" ? TabTypes.SOLID : TabTypes.GRADIENT);
      setIsTabInit(true);
    }
  }, [data.color, isTabInit]);

  const createStringGradientFromObject = useCallback((colorObject = {}) => {
    const { rotation, firstColor, firstColorPosition, secondColor, secondColorPosition } = colorObject;
    return `linear-gradient(${rotation}, ${firstColor} ${firstColorPosition}%, ${secondColor} ${secondColorPosition}%)`;
  }, []);

  const DEFAULT_GRADIENTS_AS_STRING = useMemo(
    () => DEFAULT_GRADIENTS.map((colorObject) => createStringGradientFromObject(colorObject)),
    [createStringGradientFromObject],
  );

  const isRadioCheckedInSolid = ({ isCheckingPredefined }) => {
    if (typeof data?.color !== "string") return false;

    const isPredefinedSelected = DEFAULT_COLORS.includes(data?.color || "");
    return isCheckingPredefined ? isPredefinedSelected : !isPredefinedSelected;
  };

  const isRadioCheckedInCustom = ({ isCheckingPredefined }) => {
    if (typeof data?.color === "string") return false;

    const isPredefinedSelected = DEFAULT_GRADIENTS_AS_STRING.includes(createStringGradientFromObject(data.color));
    return isCheckingPredefined ? isPredefinedSelected : !isPredefinedSelected;
  };

  return (
    <DesignSidebarWrapper>
      <div>
        <Label style={{ marginTop: "20px", marginBottom: "10px" }}>Background color</Label>

        <TabsWrapper>
          <Tab isSelected={tab === "Solid"} primaryColor={primaryColor} onClick={() => setTab("Solid")}>
            Solid
          </Tab>
          <Tab isSelected={tab === "Gradient"} primaryColor={primaryColor} onClick={() => setTab("Gradient")}>
            Gradient
          </Tab>
        </TabsWrapper>

        <div>
          {tab === "Solid" && (
            <>
              <Border style={{ margin: "20px 0px" }} />
              <RadioButtonWrapper>
                <StyleRadioHeading>
                  <Radio isChecked={isRadioCheckedInSolid({ isCheckingPredefined: true })} />
                  <Label>Predefined colors</Label>
                </StyleRadioHeading>
                <ColorsWrapper style={{ marginBottom: "20px" }}>
                  {DEFAULT_COLORS.map((clr) => (
                    <ColorTile
                      key={clr}
                      color={clr}
                      isSelected={clr === data?.color}
                      onClick={() => {
                        updatePanelDataProperty(panelId, CountdownPropertyKeys.COLOR, clr);
                        setPickerKey(Math.random().toString(32).slice(2));
                        setIsGradientInitalized(false);
                        setIsPredefined(true);
                      }}
                    />
                  ))}
                </ColorsWrapper>
              </RadioButtonWrapper>

              <Border style={{ marginTop: "20px", marginBottom: "19px" }} />
              <RadioButtonWrapper>
                <StyleRadioHeading>
                  <Radio isChecked={isRadioCheckedInSolid({ isCheckingPredefined: false })} />
                  <Label>Custom picker</Label>
                </StyleRadioHeading>
                <StyleCustomPicker>
                  <EmbededColorPicker
                    key={pickerKey}
                    value={typeof data.color === "string" ? data.color : ""}
                    setValue={(clr) => updatePanelDataProperty(panelId, CountdownPropertyKeys.COLOR, clr)}
                    width={294}
                    height={175}
                    setIsGradientInitalized={setIsGradientInitalized}
                    onClick={() => setIsPredefined(false)}
                  />
                </StyleCustomPicker>
              </RadioButtonWrapper>
            </>
          )}

          {tab === "Gradient" && (
            <>
              <Border style={{ marginTop: "20px", marginBottom: "19px" }} />

              <RadioButtonWrapper>
                <div>
                  <StyleRadioHeading>
                    <Radio isChecked={isRadioCheckedInCustom({ isCheckingPredefined: true })} />
                    <Label>Predefined colors</Label>
                  </StyleRadioHeading>
                  <ColorsWrapper style={{ marginBottom: "20px" }}>
                    {DEFAULT_GRADIENTS.map((colorObject) => (
                      <ColorTile
                        key={`${colorObject.firstColor}-${colorObject.secondColor}`}
                        color={createStringGradientFromObject(colorObject)}
                        isSelected={
                          createStringGradientFromObject(colorObject) ===
                          createStringGradientFromObject(data?.color || {})
                        }
                        onClick={() => {
                          updatePanelDataProperty(panelId, CountdownPropertyKeys.COLOR, {
                            ...colorObject,
                          });

                          setIsGradientInitalized(true);
                          setIsPredefined(true);
                        }}
                      />
                    ))}
                  </ColorsWrapper>
                </div>
              </RadioButtonWrapper>

              <Border style={{ marginTop: "20px", marginBottom: "19px" }} />

              <RadioButtonWrapper>
                <StyleRadioHeading>
                  <Radio isChecked={isRadioCheckedInCustom({ isCheckingPredefined: false })} />
                  <Label>Custom picker</Label>
                </StyleRadioHeading>
                <div
                  onClick={() => {
                    setIsGradientInitalized(true);
                    setIsPredefined(false);
                  }}
                >
                  <GradientColorPicker
                    value={typeof data.color !== "string" ? data.color : undefined}
                    setValue={(value) => {
                      if (isPredefinedRef.current === false && initRef.current) {
                        updatePanelDataProperty(panelId, CountdownPropertyKeys.COLOR, {
                          ...value,
                        });
                        setIsPredefined(false);
                      }
                    }}
                    presetColors={DEFAULT_COLORS}
                    isInitalized={gradientInitialized}
                    setIsInitialized={setIsGradientInitalized}
                  />
                </div>
              </RadioButtonWrapper>
            </>
          )}
        </div>
      </div>
    </DesignSidebarWrapper>
  );
};

function CountdownEditor({ panel, selectedTab, updatePanelDataProperty }) {
  const { data } = panel;

  return (
    <PanelEditorWrapper>
      {selectedTab === TabTypes.LAYOUT && (
        <LayoutSidebar
          panelId={panel.id}
          data={data}
          updatePanelDataProperty={updatePanelDataProperty}
          panelTitle={panel.panelTitle}
        />
      )}

      {selectedTab === TabTypes.DESIGN && (
        <DesignSidebar panelId={panel.id} data={data} updatePanelDataProperty={updatePanelDataProperty} />
      )}
    </PanelEditorWrapper>
  );
}

export default CountdownEditor;
