import React from "react";
import Drawer from "@atlaskit/drawer";
import styled from "styled-components";
import IconEmojiSidebar from "../../Shared/Components/SidebarIconPicker/IconEmojiSidebar";

export const NoSidebar = styled.div`
  width: 0px;
`;

export const DrawerWrapper = styled.div`
  width: 430px;
  padding: 25px 18px 25px 32px;
  display: flex;
  flex-direction: column;
`;

const IconDrawer = ({ visible, onSelected, onClose, close, appIconsDrawer = { available: true, modifiers: true } }) => {
  return (
    <Drawer
      onClose={onClose}
      isOpen={visible}
      width="medium"
      overrides={{
        Sidebar: {
          component: () => <NoSidebar />,
        },
        Content: {
          component: () => (
            <DrawerWrapper>
              <IconEmojiSidebar
                hasEmojis
                showNoIconBox={false}
                appIconsDrawer={appIconsDrawer}
                onClose={onClose}
                onSelected={(selected, type) => {
                  close();
                  onSelected(selected, type);
                }}
              />
            </DrawerWrapper>
          ),
        },
      }}
    ></Drawer>
  );
};

export default IconDrawer;
