import styled from "styled-components";
import { saveUnderConstructionData } from "../../../../api";
import { Panel } from "../General";
import Toggle from "@atlaskit/toggle";

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  justify-items: center;
  align-items: center;
`;

const TitleWraper = styled.div`
  display: flex;
  flex-direction: column;
`;

const PanelTitle = styled.span`
  font-size: 16px;
  font-weight: 500;
  color: ${({ theme }) => theme.shared.settings.general.panelHeaderColor};
  margin-bottom: 8px;
`;

const PanelSubTitle = styled.span`
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme.shared.settings.general.brandAndColors.underConstructionText};
`;

function ToggleUnderConstruction({ underConstructionActivated, setUnderConstructionActivated }) {
  const toggleUC = async () => {
    setUnderConstructionActivated(!underConstructionActivated);

    try {
      await saveUnderConstructionData({ underConstructionActivated: !underConstructionActivated });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Panel>
      <Header>
        <TitleWraper>
          <PanelTitle>Under Construction</PanelTitle>
          <PanelSubTitle>{underConstructionActivated ? "Activated" : "Not Activated"}</PanelSubTitle>
        </TitleWraper>

        <Toggle
          isChecked={underConstructionActivated}
          value={underConstructionActivated}
          onChange={() => {
            toggleUC();
          }}
        />
      </Header>
    </Panel>
  );
}

export default ToggleUnderConstruction;
