import React, { useCallback, useEffect } from "react";
import { usePanAndZoom } from "./hook/usePanAndZoom";
import eventBus from "../../Functions/eventBus";

const PanAndZoomWrapper = ({
  isPreview,
  initialY = 60,
  minZoom = 0,
  maxZoom = 2,
  mainWrapperStyle = {},
  movableContainerStyle = {},
  children,
}) => {
  const { containerRef, onMouseDown, onWheel, translateX, translateY, scale } = usePanAndZoom({
    minZoom,
    maxZoom,
    initialY: isPreview ? initialY : 100,
  });

  const calculate = useCallback(
    (minZoomOnCenterView = 0.2) => {
      const chart = document.getElementById("translate-wrapper")?.getElementsByClassName("p-organizationchart")[0];
      if (!chart) return;
      const table = chart.getElementsByClassName("p-organizationchart-table")[0];

      if (!table || !containerRef?.current) return;

      const tableWidth = table?.offsetWidth;
      const availableSpace = containerRef?.current?.offsetWidth;

      const fitToFrame = (actualTableWidth) => {
        const newHorizontalPosition = ((actualTableWidth - availableSpace) / 2) * -1;
        if (actualTableWidth < availableSpace) {
          eventBus.dispatch("caelor-pan-zoom-center-view", { newZoomLevel: 1, newHorizontalPosition });
          return;
        }
        const neededSpace = (availableSpace * 100) / actualTableWidth;
        let fittingScale = (Math.floor(neededSpace / 5) * 5) / 100;

        if (fittingScale < minZoomOnCenterView) {
          fittingScale = minZoomOnCenterView;
        }
        eventBus.dispatch("caelor-pan-zoom-center-view", { newZoomLevel: fittingScale, newHorizontalPosition });
      };

      if (tableWidth > availableSpace) {
        fitToFrame(tableWidth);
      } else if (scale > 1.0) {
        fitToFrame(tableWidth);
      } else {
        eventBus.dispatch("caelor-pan-zoom-center-view", { newZoomLevel: 1 });
      }
    },
    [containerRef],
  );

  useEffect(() => {
    eventBus.on("caelor-handle-center-view", (data) => {
      calculate(data?.minZoomOnCenterView);
    });

    return () => {
      eventBus.remove("caelor-handle-center-view");
    };
  }, [calculate]);

  return (
    <div style={{ ...mainWrapperStyle }} ref={containerRef} onMouseDown={onMouseDown} onWheel={onWheel}>
      <div
        id="translate-wrapper"
        style={{
          minWidth: "fit-content",
          transform: `translate(${translateX}px, ${translateY}px) scale(${scale})`,
          ...movableContainerStyle,
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default PanAndZoomWrapper;
