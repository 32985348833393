import styled from "styled-components";
import Button from "@atlaskit/button";
import { useTheme } from "styled-components";
import { useCallback } from "react";

const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;
`;

const MainContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  padding-top: 40px;
  width: 420px;
`;

const Title = styled.span`
  font-size: 20px;
  font-weight: 400;
  color: ${({ theme }) => theme.shared.settings.general.brandAndColors.underConstructionTitle};
  margin-bottom: 8px;
`;

const Description = styled.span`
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme.shared.settings.general.brandAndColors.underConstructionDescription};
  text-align: center;
  margin-bottom: 24px;
`;

function UnderConstructionFull({ title, description, buttonText, link }) {
  const getBuiltLink = useCallback(() => (link?.source === "email" ? `mailto:${link.link}` : link?.link || ""), [link]);
  const theme = useTheme();

  return (
    <Wrapper>
      <MainContent>
        <img src={`/images/hub/under-construction-empty-state-${theme.global.name}.svg`} alt="" />

        <Title>{title}</Title>
        <Description>{description}</Description>
        {!!link?.link && (
          <Button appearance="primary" onClick={() => window.open(getBuiltLink(), "_blank")}>
            {buttonText}
          </Button>
        )}
      </MainContent>
    </Wrapper>
  );
}

export default UnderConstructionFull;
