import React from "react";
import { useTheme } from "styled-components";
import { THEME_NAME_LIGHT } from "../../../../../Navigation/functions/generateNewEntry";

function NoPagesSVG() {
  const theme = useTheme();

  if (theme.global.name === THEME_NAME_LIGHT) {
    return (
      <svg width="160" height="160" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_9801_51417)">
          <g filter="url(#filter0_d_9801_51417)">
            <rect x="43" y="36" width="82" height="96" rx="12" fill="#E9F2FF" />
            <rect x="44" y="37" width="80" height="94" rx="11" stroke="#0C66E4" strokeWidth="2" />
          </g>
          <g filter="url(#filter1_dd_9801_51417)">
            <rect x="35" y="28" width="82" height="95" rx="12" fill="#E9F2FF" />
            <rect x="36" y="29" width="80" height="93" rx="11" stroke="#0C66E4" strokeWidth="2" />
          </g>
          <rect x="101" y="54" width="55" height="6" rx="3" transform="rotate(-180 101 54)" fill="#579DFF" />
          <rect x="101" y="67" width="55" height="6" rx="3" transform="rotate(-180 101 67)" fill="#579DFF" />
          <rect x="86" y="80" width="40" height="6" rx="3" transform="rotate(-180 86 80)" fill="#579DFF" />
          <rect x="95" y="93" width="49" height="6" rx="3" transform="rotate(-180 95 93)" fill="#579DFF" />
          <rect x="81" y="105" width="35" height="6" rx="3" transform="rotate(-180 81 105)" fill="#579DFF" />
          <rect width="80" height="160" transform="translate(80)" fill="url(#paint0_linear_9801_51417)" />
        </g>
        <defs>
          <filter
            id="filter0_d_9801_51417"
            x="21.1882"
            y="26.3059"
            width="125.624"
            height="139.624"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="12.1176" />
            <feGaussianBlur stdDeviation="10.9059" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.403111 0 0 0 0 0.524415 0 0 0 0 0.709375 0 0 0 0.15 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_9801_51417" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_9801_51417" result="shape" />
          </filter>
          <filter
            id="filter1_dd_9801_51417"
            x="23"
            y="24"
            width="106"
            height="119"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="8" />
            <feGaussianBlur stdDeviation="6" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.0352941 0 0 0 0 0.117647 0 0 0 0 0.258824 0 0 0 0.15 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_9801_51417" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="0.5" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.0352941 0 0 0 0 0.117647 0 0 0 0 0.258824 0 0 0 0.31 0" />
            <feBlend mode="normal" in2="effect1_dropShadow_9801_51417" result="effect2_dropShadow_9801_51417" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_9801_51417" result="shape" />
          </filter>
          <linearGradient id="paint0_linear_9801_51417" x1="0" y1="160" x2="80" y2="160" gradientUnits="userSpaceOnUse">
            <stop stopColor="white" stopOpacity="0" />
            <stop offset="0.5" stopColor="white" />
          </linearGradient>
          <clipPath id="clip0_9801_51417">
            <rect width="160" height="160" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  } else {
    return (
      <svg width="160" height="160" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_9803_51635)">
          <g filter="url(#filter0_d_9803_51635)">
            <rect x="43" y="36" width="82" height="96" rx="12" fill="#1C2B41" />
            <rect x="44" y="37" width="80" height="94" rx="11" stroke="#579DFF" strokeWidth="2" />
          </g>
          <g filter="url(#filter1_dd_9803_51635)">
            <rect x="35" y="28" width="82" height="95" rx="12" fill="#1C2B41" />
            <rect x="36" y="29" width="80" height="93" rx="11" stroke="#579DFF" strokeWidth="2" />
          </g>
          <rect x="101" y="54" width="55" height="6" rx="3" transform="rotate(-180 101 54)" fill="#0055CC" />
          <rect x="101" y="67" width="55" height="6" rx="3" transform="rotate(-180 101 67)" fill="#0055CC" />
          <rect x="86" y="80" width="40" height="6" rx="3" transform="rotate(-180 86 80)" fill="#0055CC" />
          <rect x="95" y="93" width="49" height="6" rx="3" transform="rotate(-180 95 93)" fill="#0055CC" />
          <rect x="81" y="105" width="35" height="6" rx="3" transform="rotate(-180 81 105)" fill="#0055CC" />
          <rect width="80" height="160" transform="translate(80)" fill="url(#paint0_linear_9803_51635)" />
        </g>
        <defs>
          <filter
            id="filter0_d_9803_51635"
            x="21.1882"
            y="26.3059"
            width="125.624"
            height="139.624"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="12.1176" />
            <feGaussianBlur stdDeviation="10.9059" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.403111 0 0 0 0 0.524415 0 0 0 0 0.709375 0 0 0 0.15 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_9803_51635" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_9803_51635" result="shape" />
          </filter>
          <filter
            id="filter1_dd_9803_51635"
            x="23"
            y="24"
            width="106"
            height="119"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="8" />
            <feGaussianBlur stdDeviation="6" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.0352941 0 0 0 0 0.117647 0 0 0 0 0.258824 0 0 0 0.15 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_9803_51635" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="0.5" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.0352941 0 0 0 0 0.117647 0 0 0 0 0.258824 0 0 0 0.31 0" />
            <feBlend mode="normal" in2="effect1_dropShadow_9803_51635" result="effect2_dropShadow_9803_51635" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_9803_51635" result="shape" />
          </filter>
          <linearGradient id="paint0_linear_9803_51635" x1="0" y1="160" x2="80" y2="160" gradientUnits="userSpaceOnUse">
            <stop stopColor="#22272B" stopOpacity="0" />
            <stop offset="0.64" stopColor="#22272B" />
          </linearGradient>
          <clipPath id="clip0_9803_51635">
            <rect width="160" height="160" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  }
}

export default React.memo(NoPagesSVG);
