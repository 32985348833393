import React from "react";
import dayjs from "dayjs";
import Tag from "@atlaskit/tag";

const Labels = {
  THIS_WEEK: "This week",
  NEXT_WEEK: "Next 7 days",
  THIS_MONTH: "This month",
  NEXT_MONTH: "Next month",
  NEXT_TWO_WEEKS: "Next 14 days",
  NEXT_THREE_MONTH: "Next 90 days",
  NEXT_30_DAYS: "Next 30 days"
};

function Date({ filter, removeCallback }) {
  if (!filter.recurring && filter.value?.range?.length) {
    return (
      <Tag
        text={filter.value.range
          .filter((date) => date)
          .map((date) => dayjs.unix(date).format("DD/MM/YYYY"))
          .join(" - ")}
        onAfterRemoveAction={() => removeCallback(undefined)}
      />
    );
  }
  if (filter.value?.period) {
    return <Tag text={Labels[filter.value.period]} onAfterRemoveAction={() => removeCallback(undefined)} />;
  }

  return null;
}

export default Date;
