import React from "react";
import styled from "styled-components";
import { HeaderIcon } from "../../icons/SidebarHeaderIcon";
import { ArrowRightIcon } from "../../icons/SidebarIcons";

const TabSelectorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 32px;
  padding-top: 32px;
`;

const TabWrapper = styled.div`
  display: grid;
  grid-template-columns: 20px auto 20px;
  column-gap: 16px;
  row-gap: 12px;
  align-items: flex-start;
  cursor: pointer;
`;

const Title = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.shared.sidebar.positioning.title};
  user-select: none;
  display: block;
`;

const Description = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.shared.sidebar.positioning.description};
  margin-top: 12px;
`;

const IconContainer = styled.div`
  display: grid;
  place-content: center;
  width: 24px;
  height: 24px;
`;

export const SidebarTabs = {
  NOT_SELECTED: undefined,
  CARD_LAYOUT: "card-layout",
  HEADER_DESIGN: "header-design",
  NEWS_DESIGN: "news-design",
  LAYOUT_DESIGN: "layout-design",
};

function SidebarTabSelector({ tab = undefined, Icon, setSelectedSidebarTab }) {
  return (
    <TabSelectorWrapper>
      <TabWrapper onClick={() => setSelectedSidebarTab(SidebarTabs.HEADER_DESIGN)}>
        <HeaderIcon />
        <div>
          <Title>Header Design</Title>
          <Description>Change the cover image to keep your brand identity consistent.</Description>
        </div>
        <IconContainer>
          <ArrowRightIcon />
        </IconContainer>
      </TabWrapper>

      {!!tab && (
        <TabWrapper onClick={() => setSelectedSidebarTab(tab.value)}>
          <Icon />
          <div>
            <Title>{tab.title}</Title>
            <Description>{tab.description}</Description>
          </div>
          <IconContainer>
            <ArrowRightIcon />
          </IconContainer>
        </TabWrapper>
      )}
    </TabSelectorWrapper>
  );
}

export default SidebarTabSelector;
