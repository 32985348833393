import React, { useCallback, useState } from "react";

import Tabs, { Tab, TabList } from "@atlaskit/tabs";
import DynamicNotifications from "./DynamicNotifications";
import ManualNotifications from "./ManualNotifications";

const tabComponent = {
  0: DynamicNotifications,
  1: ManualNotifications,
};

const tabs = ["Dynamic Notifications", "Manual Notifications"];

const TabsContent = () => {
  const [tabIndex, setTabIndex] = useState(0);

  const TabComponent = tabComponent[tabIndex];

  const handleOnChange = useCallback((index) => setTabIndex(index), [setTabIndex]);

  return (
    <Tabs onChange={handleOnChange} selected={tabIndex}>
      <TabList>
        {tabs.map((tab) => (
          <Tab>{tab}</Tab>
        ))}
      </TabList>
      <TabComponent />
    </Tabs>
  );
};

export default TabsContent;
