import React, { useEffect, useState } from "react";
import styled, { useTheme } from "styled-components";
import EditIcon from "@atlaskit/icon/glyph/edit";
import StarFilledIcon from "@atlaskit/icon/glyph/star-filled";
import WatchIcon from "@atlaskit/icon/glyph/watch";
import WatchFilledIcon from "@atlaskit/icon/glyph/watch-filled";
import {
  addCurrentUserAsSpaceWatcher,
  getCurrentUserSpaceFavoriteStatus,
  getCurrentUserSpaceWatchStatus,
  removeCurrentUserAsSpaceWatcher,
} from "../apiSpaces";
import { useCorporateIdentityStore } from "../../Settings/General/BrandAndColors/corporate-identity-store";

const Card = styled.div`
  background-color: ${({ theme }) => theme.shared.card.background};
  box-shadow:
    rgba(9, 30, 66, 0.25) 0px 1px 3px,
    rgba(9, 30, 66, 0.31) 0px 0px 1px;
  border-radius: ${({ borderRadius }) => borderRadius};
  overflow: hidden;
  border: 1px solid ${({ theme }) => theme.shared.card.border.box};
  cursor: pointer;
  position: relative;
  height: 100%;

  transition: box-shadow linear 75ms;
  &:hover {
    box-shadow:
      rgba(9, 30, 66, 0.31) 0px 0px 1px 0px,
      rgba(9, 30, 66, 0.25) 0px 8px 12px -3px;
  }
`;

const CardBackground = styled.div`
  width: 100%;
  height: 75px;
  background: linear-gradient(270deg, #84b9f8 0%, #2571cb 98.75%);
  border-radius: 3px 3px 0px 0px;
  position: relative;
  z-index: 53;
  overflow: hidden;
`;

const CardInfo = styled.div`
  position: relative;
  z-index: 54;
  margin-top: -30px;
  padding: 0px 18px 20px 18px;

  display: flex;
  flex-direction: column;
  row-gap: 22px;
`;

const CardIcon = styled.img`
  border-radius: ${({ isPersonal, borderRadius }) => (isPersonal ? "50%" : borderRadius)};
  border: 2px solid ${({ theme }) => theme.space.listView.cardAvatarShadow};
  background-color: ${({ theme }) => theme.space.listView.cardAvatarShadow};
  box-sizing: border-box;
  width: 46px;
  height: 46px;
`;

const CardTitle = styled.div`
  font-weight: 600;
  font-size: 14.5142px;
  line-height: 18px;
  color: ${({ theme }) => theme.shared.card.title};
  padding-bottom: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const ActionsWrapper = styled.div`
  position: absolute;
  top: 40px;
  right: 10px;

  display: flex;
  align-items: center;
  column-gap: 5px;
`;

const IconContainer = styled.div`
  display: grid;
  place-content: center;
  cursor: pointer;

  ${({ small }) => small && `svg{ width: 21px; height: 21px;}`};
`;

const Actions = ({
  isPersonal,
  isWatching,
  isFavorite,
  updateWatching,
  setIsModalOpen,
  setUpdatingSpace,
  hasEditPermission,
}) => {
  const theme = useTheme();

  return (
    <ActionsWrapper>
      {!isPersonal && hasEditPermission && (
        <IconContainer
          small
          onClick={(e) => {
            e.preventDefault();
            setUpdatingSpace();
            setIsModalOpen(true);
          }}
        >
          <EditIcon primaryColor={theme.poll.icons} />
        </IconContainer>
      )}

      <IconContainer>{isFavorite ? <StarFilledIcon primaryColor="#FFC400" /> : <div />}</IconContainer>

      <IconContainer
        small
        onClick={(e) => {
          e.preventDefault();
          updateWatching(isWatching);
        }}
      >
        {isWatching ? (
          <WatchFilledIcon primaryColor={theme.poll.iconsSelected} />
        ) : (
          <WatchIcon primaryColor={theme.poll.icons} />
        )}
      </IconContainer>
    </ActionsWrapper>
  );
};

function SpaceCardWithBackground({ hasActions, spaceInfo, setIsModalOpen, setUpdatingSpace, currentUserId }) {
  const [isError, setIsError] = useState(false);
  const [isLoadingWatchStatus, setIsLoadingWatchStatus] = useState(true);
  const [isLoadingFavoriteStatus, setIsLoadingFavoriteStatus] = useState(true);
  const [isWatching, setIsWatching] = useState(false);
  const [isFavorite, setIsFavorite] = useState(false);

  const { borderRadius } = useCorporateIdentityStore((state) => ({ borderRadius: state.borderRadius }));

  useEffect(() => {
    if (hasActions) {
      setIsLoadingWatchStatus(true);
      setIsLoadingFavoriteStatus(true);
      getCurrentUserSpaceWatchStatus(spaceInfo.key)
        .then((res) => {
          if (res.body) {
            const body = JSON.parse(res.body);
            setIsWatching(body.watching);
          }
        })
        .catch((err) => setIsError(true))
        .finally(() => setIsLoadingWatchStatus(false));

      getCurrentUserSpaceFavoriteStatus(spaceInfo.key)
        .then((res) => {
          if (res.body) {
            const parsed = JSON.parse(res.body);
            if (parsed?.results?.length) {
              setIsFavorite(true);
            } else {
              setIsFavorite(false);
            }
          }
        })
        .catch((err) => setIsError(true))
        .finally(() => setIsLoadingFavoriteStatus(false));
    }
  }, []);

  const removeWatcher = () => {
    setIsWatching(false);
    removeCurrentUserAsSpaceWatcher(spaceInfo.key)
      .then((res) => {
        if (!res?.data?.success) {
          setIsWatching(true);
        }
      })
      .catch((e) => setIsError(true));
  };

  const addWatcher = () => {
    setIsWatching(true);
    addCurrentUserAsSpaceWatcher(spaceInfo.key)
      .then((res) => {
        if (!res?.data?.success) {
          setIsWatching(false);
        }
      })
      .catch((e) => setIsError(true));
  };

  const checkIfCurrentUserHasPermissionToEdit = () => {
    if (!spaceInfo.permissions.length || !currentUserId || spaceInfo?.type === "personal") return false;

    let hasPermission;
    spaceInfo.permissions?.forEach((permission) => {
      const permissionForUser = permission?.subjects?.user?.results?.find((user) => user.accountId === currentUserId);
      if (permissionForUser) hasPermission = true;
    });
    return !!hasPermission;
  };

  return (
    <a style={{ textDecoration: "none" }} href={spaceInfo.url} target="_blank" rel="noreferrer">
      <Card key={spaceInfo.key} borderRadius={borderRadius}>
        <CardBackground />
        <CardInfo>
          {hasActions && !isLoadingWatchStatus && !isLoadingFavoriteStatus && !isError && (
            <Actions
              isPersonal={spaceInfo.type === "personal"}
              isWatching={isWatching}
              isFavorite={isFavorite}
              updateWatching={(watching) => {
                watching ? removeWatcher() : addWatcher();
              }}
              setIsModalOpen={setIsModalOpen}
              setUpdatingSpace={() => setUpdatingSpace(spaceInfo)}
              hasEditPermission={checkIfCurrentUserHasPermissionToEdit()}
            />
          )}

          <CardIcon src={spaceInfo.icon} isPersonal={spaceInfo.type === "personal"} borderRadius={borderRadius} />
          <CardTitle>{spaceInfo.name}</CardTitle>
        </CardInfo>
      </Card>
    </a>
  );
}

export default SpaceCardWithBackground;
