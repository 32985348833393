import styled from "styled-components";

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.collections.createCollectionBackground};
  width: calc(100% - 60px);
  height: calc(100% - 60px);
  border-radius: 9px;
  padding: 30px;
`;

export const Title = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: ${({ theme }) => theme.collections.createCollectionTitle};
`;

export const Description = styled.p`
  margin-top: 12px;
  margin-bottom: 25px;

  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #777f8c;
`;

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;

  margin-top: 25px;
`;

export const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 6px;

  .label {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #6b778c;
  }
  .description {
    font-size: 12px;
    line-height: 16px;
    color: #6b778c;
  }
`;

export const TextfieldWrapper = styled.div`
  div:first-child {
    min-height: 40px;
    border-width: 1px;
    background: ${({ theme }) => theme.collections.createCollectionSelectBackground};
    border-radius: 3px;
    border-color: ${({ theme }) => theme.collections.createCollectionSelectBorder};

    .collection-text-input::placeholder {
      color: #172b4d;
    }
  }
`;

export const IconBox = styled.div`
  width: 40px;
  height: 40px;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.collections.createCollectionSelectBackground};
  border-radius: 3px;
  cursor: pointer;
  display: grid;
  place-content: center;
`;

export const Footer = styled.div`
  flex: 1;
  justify-self: flex-end;

  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 5px;
`;

export const Button = styled.div`
  user-select: none;
  padding: 6px 12px;
  border-radius: 3px;
  cursor: pointer;
  width: fit-content;
  display: grid;
  place-content: center;

  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  opacity: 1;
  transition: opacity 150ms linear;

  &:hover {
    opacity: 0.85;
  }
`;

export const Cancel = styled(Button)`
  color: ${({ theme }) => theme.collections.createCollectionCancel};
  background-color: transparent;
  margin-right: 10px;

  transition: background-color 150ms linear;

  &:hover {
    background-color: ${({ theme }) => theme.collections.createCollectionCancelHover};
    opacity: 1;
  }
`;

export const Add = styled(Button)`
  color: ${({ theme }) => theme.collections.createCollectionDisabledText};
  background-color: ${({ theme }) => theme.collections.createCollectionCancelHover};

  ${({ isDisabled }) => {
    if (isDisabled) {
      return `
        cursor: not-allowed;
        background-color: ${({ theme }) => theme.collections.createCollectionCancelHover};
        color: ${({ theme }) => theme.collections.createCollectionDisabledText};
      `;
    }
  }}

  ${({ isSaving }) => {
    if (isSaving) {
      return `
        background-color: ${({ theme }) => theme.collections.createCollectionCancelHover};
      `;
    }
  }}
`;

export const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  min-width: 106px;

  span:first-child {
    width: 20px !important;
    height: 20px !important;
  }
`;

export const ToggleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-top: 10px;
`;

export const ToggleLabel = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.collections.createCollectionToggleLabelColor};
`;

export const BackgroundColorsWrapper = styled.div`
  display: flex;
  gap: 6px;
  justify-content: space-between;
`;

export const BackgroundColorWrapper = styled.div`
  border-radius: 3px;
  width: 40px;
  height: 40px;
  display: grid;
  place-items: center;
  border: ${({ isSelected }) => (isSelected ? "1px solid #0065FF" : "1px solid transparent")};
`;

export const BackgroundColorItem = styled.div`
  width: 24px;
  height: 24px;
  border: 1px solid #dfe1e6;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 3px;
  cursor: pointer;
`;
