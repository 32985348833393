/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useRef } from "react";
import EditorCloseIcon from "@atlaskit/icon/glyph/editor/close";
import styled from "styled-components";
import Tooltip from "@atlaskit/tooltip";
import { SaveIcon } from "../../Components/Hub/Components/Shared/SaveChangesButton";

const ArrowLeftIcon = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.17266 0.827413C6.25012 0.904737 6.31157 0.996573 6.35349 1.09767C6.39542 1.19876 6.417 1.30714 6.417 1.41658C6.417 1.52602 6.39542 1.6344 6.35349 1.73549C6.31157 1.83659 6.25012 1.92842 6.17266 2.00575L3.01183 5.16658H11.0002C11.2212 5.16658 11.4331 5.25438 11.5894 5.41066C11.7457 5.56694 11.8335 5.7789 11.8335 5.99991C11.8335 6.22093 11.7457 6.43289 11.5894 6.58917C11.4331 6.74545 11.2212 6.83325 11.0002 6.83325H3.01183L6.17266 9.99408C6.25012 10.0714 6.31157 10.1632 6.35349 10.2643C6.39542 10.3654 6.417 10.4738 6.417 10.5832C6.417 10.6927 6.39542 10.8011 6.35349 10.9022C6.31157 11.0033 6.25012 11.0951 6.17266 11.1724C5.84766 11.4974 5.31933 11.4974 4.9935 11.1724L0.410995 6.58908C0.260079 6.43874 0.172672 6.23618 0.16683 6.02325V5.97575C0.172799 5.76308 0.260203 5.56083 0.410995 5.41075L4.9935 0.827413C5.07089 0.749933 5.1628 0.688467 5.26396 0.64653C5.36513 0.604593 5.47357 0.583008 5.58308 0.583008C5.69259 0.583008 5.80103 0.604593 5.9022 0.64653C6.00336 0.688467 6.09527 0.749933 6.17266 0.827413Z"
      fill="#97A0AF"
    />
  </svg>
);

const SidebarContainer = styled.div`
  display: flex;
  position: fixed;
  overflow: hidden;
  top: 0;
  bottom: 0;

  ${({ position }) => {
    if (position === "right") {
      return `
        right: 0;
        box-shadow: -2px 2px 4px rgba(0 0 0 / 8%);
      `;
    }
    return `
      left: 0;
      box-shadow: 2px 2px 4px rgba(0 0 0 / 8%);
    `;
  }}

  justify-content: start;
  width: ${({ width, isOpen }) => (isOpen ? width : 0)}px;
  background: ${({ theme }) => theme.shared.sidebar.background};
  z-index: 99;
  transition:
    visibility 0.1s,
    width 0.2s;
`;

const Sidebar = styled.div`
  box-sizing: border-box;
  height: 100%;
  padding: 20px;
  min-width: ${({ width }) => width}px;
  position: relative;
`;

const ScrollbarWrapper = styled.div`
  height: ${({ customScrollbarHeight }) => (customScrollbarHeight ? customScrollbarHeight : "calc(100% - 60px)")};
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  scrollbar-gutter: stable;
  padding: 0px 5px;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    margin-bottom: ${({ isPanelList }) => (isPanelList ? "50px" : "0px")};
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.scrollbar};
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #999;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.shared.sidebar.title};
`;

const BorderDivider = styled.div`
  width: 100%;
  margin-top: 19px;

  border-bottom: 1px solid ${({ theme }) => theme.shared.sidebar.divider};
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: ${({ hasSubtitle }) => (hasSubtitle ? "flex-start" : "center")};
  column-gap: 13px;
`;

const TitleWithSubtitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
`;

const Subtitle = styled.span`
  color: #97a0af;
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
`;

const IconContainer = styled.div`
  display: grid;
  place-content: center;
  cursor: pointer;
  width: 24px;
  height: 24px;

  &:hover {
    opacity: 0.9;
  }
`;

const SidebarTransition = ({
  isOpen,
  close,
  width,
  children,
  title,
  subtitle,
  disabledClickOutside,
  containerStyles,
  customScrollbarHeight,
  isPanelList,
  useArrowBack = false,
  onArrowBackClick = () => {},
  onlyChildren = false,
  position = "right",
  ignoreClass = undefined,
  showClose = true,
  hasChanges = false,
  onSave = () => {},
  showExtraIcon = false,
  ExtraIcon = () => {},
  handleClickOnExtraIcon = () => {},
}) => {
  const dialogRef = useRef();

  useEffect(() => {
    if (isOpen && !disabledClickOutside) {
      const eventListener = (event) => {
        if (!dialogRef.current || dialogRef.current.contains(event.target)) {
          return;
        }

        if (ignoreClass && event.target.closest(`.${ignoreClass}`)) {
          return;
        }

        if (useArrowBack) {
          onArrowBackClick(event);
        } else if (!disabledClickOutside) {
          close(event);
        }
      };

      document.addEventListener("mousedown", eventListener);
      return () => {
        document.removeEventListener("mousedown", eventListener);
      };
    }
  }, [dialogRef, close, isOpen, disabledClickOutside]);

  const primaryColor = "#0065ff";

  return (
    <SidebarContainer ref={dialogRef} position={position} isOpen={isOpen} width={width} style={{ ...containerStyles }}>
      <Sidebar width={width}>
        {!onlyChildren && (
          <>
            <Header>
              {showClose && useArrowBack ? (
                <TitleWrapper hasSubtitle={!!subtitle}>
                  <span role="none" style={{ height: "20px", cursor: "pointer" }} onClick={() => onArrowBackClick()}>
                    <ArrowLeftIcon />
                  </span>

                  {!!subtitle ? (
                    <TitleWithSubtitleWrapper>
                      <span>{title}</span>
                      <Subtitle>{subtitle}</Subtitle>
                    </TitleWithSubtitleWrapper>
                  ) : (
                    <span>{title}</span>
                  )}
                </TitleWrapper>
              ) : (
                <span>{title}</span>
              )}
              <div style={{ display: "flex", placeItems: "center", gap: "12px" }}>
                {hasChanges && (
                  <Tooltip content="Save all changes" position="bottom">
                    <IconContainer onClick={() => onSave()}>
                      <SaveIcon color={primaryColor} />
                    </IconContainer>
                  </Tooltip>
                )}
                {showExtraIcon && <ExtraIcon handleClick={handleClickOnExtraIcon} />}
                {isOpen && showClose && (
                  <IconContainer onClick={() => close()}>
                    <EditorCloseIcon primaryColor="#97A0AF" />
                  </IconContainer>
                )}
              </div>
            </Header>

            <BorderDivider />
          </>
        )}
        <ScrollbarWrapper isPanelList={isPanelList} customScrollbarHeight={customScrollbarHeight}>
          <>{children}</>
        </ScrollbarWrapper>
      </Sidebar>
    </SidebarContainer>
  );
};

export default SidebarTransition;
