import React from "react";
import { DatePicker } from "@atlaskit/datetime-picker";
import dayjs from "dayjs";
import styled from "styled-components";
import { useTheme } from "styled-components";
import { convertColorOpacity } from "../../../../../Shared/Functions/convertColorOpacity";
import { useCorporateIdentityStore } from "../../Settings/General/BrandAndColors/corporate-identity-store";
import { COLOR_TYPES } from "../../Settings/General/BrandAndColors/color-types";
import { customDatePickerStyles } from "../../../../Collections/selected/Filter/styled";

import { FieldLabel } from "../../Shared/Filters/styled-filters";

const DatePickerContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;
`;

const DateRangeSelect = ({ color, onChange, dateFilter }) => {
  const theme = useTheme();

  const colors = useCorporateIdentityStore((state) => state.colors);
  const primaryColor = colors[COLOR_TYPES.PRIMARY] || "#0065ff";

  const convertedColor = convertColorOpacity(color, theme.global.name);

  return (
    <div style={{ margin: "16px 0px" }}>
      <FieldLabel style={{ textTransform: "uppercase" }}>By date</FieldLabel>
      <DatePickerContainer color={color} backgroundColor={convertedColor}>
        <DatePicker
          dateFormat="DD/MM/YYYY"
          selectProps={{ styles: customDatePickerStyles(primaryColor) }}
          placeholder="From date"
          onChange={(date) => onChange("start", dayjs(date).unix())}
        />
        <DatePicker
          dateFormat="DD/MM/YYYY"
          selectProps={{ styles: customDatePickerStyles(primaryColor) }}
          placeholder="To date"
          onChange={(date) => onChange("end", dayjs(date).unix())}
        />
      </DatePickerContainer>
    </div>
  );
};

export default DateRangeSelect;
