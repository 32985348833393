import React, { useEffect, useState } from "react";
import styled, { useTheme } from "styled-components";
import Spinner from "@atlaskit/spinner";
import { Section, SmallTitle, SpinnerContainer, Subtitle } from "../../styled/views";
import { useQuery } from "react-query";
import { fetchLatestBlogs } from "../api";
import { createProfileLink } from "../createProfileLink";
import { useCorporateIdentityStore } from "../../../Settings/General/BrandAndColors/corporate-identity-store";
import { COLOR_TYPES } from "../../../Settings/General/BrandAndColors/color-types";
import dayjs from "dayjs";
import { fetchRecords } from "../../../../../UserDatabase/datagrid/api";
import EmptyState from "../../../../../../Shared/Components/EmptyState";

const LinkWrapper = styled.a`
  all: unset;
`;

const BlogsWrapper = styled.div`
  background: ${({ theme }) => theme.shared.card.background};
  box-shadow:
    rgba(9, 30, 66, 0.25) 0px 1px 3px,
    rgba(9, 30, 66, 0.31) 0px 0px 1px;
  border-radius: ${({ borderRadius }) => `${borderRadius}`};
  padding: 22px 24px;
  border: 1px solid ${({ theme }) => theme.shared.card.border.box};
  transition: box-shadow linear 75ms;

  &:hover {
    box-shadow:
      rgba(9, 30, 66, 0.31) 0px 0px 1px 0px,
      rgba(9, 30, 66, 0.25) 0px 8px 16px -6px;
  }
`;

const Blog = styled.div`
  display: grid;
  grid-template-columns: 72px auto;
  align-items: center;
  column-gap: 21px;

  padding-top: ${({ isFirstInList }) => (isFirstInList ? "0px" : "18px")};
  padding-bottom: ${({ isLastInList }) => (isLastInList ? "0px" : "18px")};
  border-top: ${({ isFirstInList, theme }) =>
    isFirstInList ? "1px solid transparent" : `1px solid ${theme.shared.card.divider}`};
`;

const UserImage = styled.img`
  box-sizing: border-box;
  height: ${({ size }) => `${size}px`};
  width: ${({ size }) => `${size}px`};
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 6px;
`;

const Title = styled.span`
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: ${({ color }) => color};

  cursor: pointer;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-all;
`;

const Author = styled.span`
  color: ${({ theme }) => theme.shared.card.title};
`;

const Modified = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #6b778c;
  text-align: right;
`;

const InfoFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-weight: 400;
  font-size: 14px;
  line-height: 17px;

  .by {
    color: #6b778c;
  }
`;

const handleDataForLatestBlogs = (results) => {
  if (!results?.length) return [];

  const formattedBlogData = results.map((blog) => {
    const plainUserData = blog.content.history.createdBy;

    return {
      id: blog.content.id,
      friendlyModified:
        blog.friendlyLastModified.includes("ago") || blog.friendlyLastModified.includes("yesterday")
          ? blog.friendlyLastModified
          : dayjs(blog.friendlyLastModified).format("MMM D, YYYY"),
      title: blog.content.title,
      linkToBlog: `${window.AP._hostOrigin}/wiki${blog.content._links.tinyui}`,
      userData: {
        accountId: plainUserData.accountId,
        name: plainUserData.displayName,
        profilePicture: `${window.AP._hostOrigin}${plainUserData.profilePicture.path}`,
      },
    };
  });

  return formattedBlogData;
};

function BlogPosts({ panelData }) {
  const [latestBlogs, setLatestBlogs] = useState([]);

  const { isFetching, data } = useQuery(
    ["latest-blogs", { labels: panelData.labels, contributors: panelData.contributors, spaces: panelData.spaces }],
    fetchLatestBlogs,
    {
      retry: 0,
      select: (response) => {
        const { body } = response;
        const { results } = JSON.parse(body);

        return handleDataForLatestBlogs(results);
      },
    },
  );

  const activeUsersIds = data?.map((activeUser) => activeUser.userData.accountId);

  const { isFetching: isUsersRecordsFetching, data: usersRecords } = useQuery(
    [
      "active-users-records",
      {
        filters: [
          { isMultiUser: true, type: "BOOLEAN", value: true, column: "hidden", condition: "NOT" },
          { isMultiUser: true, type: "USER", value: activeUsersIds },
        ],
      },
    ],
    fetchRecords,
    {
      enabled: !!activeUsersIds?.length,
      retry: 0,
      select: (response) => {
        const { data } = response;
        return data;
      },
    },
  );

  const borderRadius = useCorporateIdentityStore((state) => state.borderRadius);

  useEffect(() => {
    if (data) {
      setLatestBlogs([...data]);
    }
  }, [data]);

  const colors = useCorporateIdentityStore((state) => state.colors);
  const primaryColor = colors ? colors[COLOR_TYPES.PRIMARY] : "#0065ff";

  const filteredUsersIds = usersRecords?.hits.map((userRecord) => userRecord.accountId);
  const activeUsersFilter = (activeUser) => filteredUsersIds?.includes(activeUser.userData.accountId);

  const theme = useTheme();

  return (
    <Section>
      <SmallTitle>{panelData.title}</SmallTitle>
      <Subtitle>{panelData.description}</Subtitle>

      {isFetching && (
        <SpinnerContainer>
          <Spinner size="xlarge" />
        </SpinnerContainer>
      )}

      {!latestBlogs?.length && (
        <EmptyState
          Image={() => {
            return <img src={`/images/hub/no-blogs-empty-state-${theme.global.name}.svg`} alt="" />;
          }}
          title="No blogs to show"
          description="There are no blogs available."
        />
      )}

      {!isFetching && !!latestBlogs?.length && (
        <BlogsWrapper borderRadius={borderRadius}>
          {latestBlogs.filter(activeUsersFilter).map((blog, index) => (
            <Blog key={blog.id} isFirstInList={index === 0} isLastInList={latestBlogs.length - 1 === index}>
              <LinkWrapper
                target="_top"
                href={createProfileLink(blog.userData.accountId)}
                onClick={(e) => e.stopPropagation()}
              >
                <UserImage src={blog.userData.profilePicture} size={panelData.profileImageSize} />
              </LinkWrapper>
              <InfoWrapper>
                <LinkWrapper target="_top" href={blog.linkToBlog} onClick={(e) => e.stopPropagation()}>
                  <Title color={primaryColor}>{blog.title}</Title>
                </LinkWrapper>
                <InfoFooter>
                  <div>
                    <span className="by">by </span>
                    <Author>{blog.userData.name}</Author>
                  </div>
                  <Modified>{blog.friendlyModified}</Modified>
                </InfoFooter>
              </InfoWrapper>
            </Blog>
          ))}
        </BlogsWrapper>
      )}
    </Section>
  );
}

export default BlogPosts;
