import styled from "styled-components";
import { Radio } from "@atlaskit/radio";
import { useRecordsStore } from "../records-store";
import shallow from "zustand/shallow";
import { useEffect, useState } from "react";

import DropdownMenu, { DropdownItem, DropdownItemGroup } from "@atlaskit/dropdown-menu";

const Wrapper = styled.div`
  display: flex;
  padding-right: 16px;
  text-align: center;
  color: #344563;
  font-size: 13px;
  font-weight: 550;
  line-height: 20px;
`;

const RadioWrapper = styled.div`
  display: flex;
  height: 24px;
  align-self: center;
  gap: 4px;
  color: #44546f;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  padding-right: 16px;
  width: 120px;
`;

export const SelectionType = {
  SHOW_VISIBLE_USERS: 0,
  SHOW_HIDDEN_USERS: 1,
  SHOW_ALL_USERS: 2,
};

export const Users = () => {
  const { setHiddenUsersSelection, numberOfHiddenUsers, numberOfActiveUsers, numberOfTotalUsers } = useRecordsStore(
    (state) => {
      return {
        setHiddenUsersSelection: state.setHiddenUsersSelection,
        numberOfHiddenUsers: state.numberOfHiddenUsers,
        numberOfActiveUsers: state.numberOfActiveUsers,
        numberOfTotalUsers: state.numberOfTotalUsers,
      };
    },
    shallow,
  );

  const [allUsersChecked, setAllUsersChecked] = useState(false);
  const [allUsersVisibleChecked, setAllUsersVisibleChecked] = useState(true);
  const [hiddenUsersChecked, setHiddenUsersChecked] = useState(false);

  const resetChecks = ({ allUsersChecked, allUsersVisibleChecked, hiddenUsersChecked }) => {
    setAllUsersChecked(allUsersChecked);
    setAllUsersVisibleChecked(allUsersVisibleChecked);
    setHiddenUsersChecked(hiddenUsersChecked);
  };

  const showHiddenUsers = () => {
    const newState = {
      selectionType: SelectionType.SHOW_HIDDEN_USERS,
      selectedUsers: [],
    };
    setHiddenUsersSelection(newState);

    resetChecks({ allUsersChecked: false, allUsersVisibleChecked: false, hiddenUsersChecked: true });
  };

  const showVisibleUsers = () => {
    const newState = {
      selectionType: SelectionType.SHOW_VISIBLE_USERS,
      selectedUsers: [],
    };
    setHiddenUsersSelection(newState);

    resetChecks({ allUsersChecked: false, allUsersVisibleChecked: true, hiddenUsersChecked: false });
  };

  const showAllUsers = () => {
    const newState = {
      selectionType: SelectionType.SHOW_ALL_USERS,
      selectedUsers: [],
    };
    setHiddenUsersSelection(newState);

    resetChecks({ allUsersChecked: true, allUsersVisibleChecked: false, hiddenUsersChecked: false });
  };

  const GetTrigger = () => {
    if (allUsersChecked) {
      return `All (${numberOfTotalUsers})`;
    } else if (allUsersVisibleChecked) {
      return `Active (${numberOfActiveUsers})`;
    } else {
      return `Hidden (${numberOfHiddenUsers || 0})`;
    }
  };

  useEffect(() => {
    if (allUsersChecked) showAllUsers();
  }, []);

  return (
    <Wrapper>
      <DropdownMenu trigger={GetTrigger()} triggerType="button" placement="bottom-start">
        <DropdownItemGroup>
          <DropdownItem>
            <RadioWrapper onClick={showAllUsers}>
              <Radio isChecked={allUsersChecked} label={`All (${numberOfTotalUsers})`} />
            </RadioWrapper>
          </DropdownItem>
          <DropdownItem>
            <RadioWrapper onClick={showVisibleUsers}>
              <Radio isChecked={allUsersVisibleChecked} label={`Active (${numberOfActiveUsers})`} />
            </RadioWrapper>
          </DropdownItem>
          <DropdownItem>
            <RadioWrapper onClick={showHiddenUsers}>
              <Radio isChecked={hiddenUsersChecked} label={`Hidden (${numberOfHiddenUsers || 0})`} />
            </RadioWrapper>
          </DropdownItem>
        </DropdownItemGroup>
      </DropdownMenu>
    </Wrapper>
  );
};
