export const NewsElements = {
  AUTHOR: "author",
  LABEL: "label",
  SPACE: "space",
  DESCRIPTION: "description",
  DATE: "date",
  CATEGORIES: "categories",
};

export const SUBS_FILTER = "subscriptions";

export const ActiveNewsElements = [
  { label: "Author", value: NewsElements.AUTHOR },
  { label: "Label", value: NewsElements.LABEL },
  { label: "Space", value: NewsElements.SPACE },
  { label: "Date", value: NewsElements.DATE },
  { label: "Description", value: NewsElements.DESCRIPTION },
  { label: "Categories", value: NewsElements.CATEGORIES },
];

export const NewsViews = {
  GRID: "grid",
  MAGAZINE: "magazine",
  LIST: "list",
};

export const DefaultActiveElements = [
  NewsElements.AUTHOR,
  NewsElements.LABEL,
  NewsElements.SPACE,
  NewsElements.DESCRIPTION,
  NewsElements.DATE,
];

const grid = {
  activeElements: DefaultActiveElements,
  gridGap: 20,
  cardBorderRadius: 8,
  labelsBackground: "#c6c6c6",
};

const magazine = {
  activeElements: DefaultActiveElements,
  gridGap: 20,
  cardBorderRadius: 8,
  labelsBackground: "#c6c6c6",
};

const list = {
  activeElements: [NewsElements.AUTHOR],
  cardBorderRadius: 8,
  labelsBackground: "#c6c6c6",
};

export const defaultSettings = {
  selectedView: NewsViews.MAGAZINE,

  grid,
  magazine,
  list,
};
