import React from "react";
import styled from "styled-components";
import Button from "@atlaskit/button";
import { useTheme } from "styled-components";

const DeleteFieldWrapper = styled.div`
  width: 300px;
  background: ${({ theme }) => theme.shared.settings.database.background};
  border: ${({ theme }) => `1px solid ${theme.shared.settings.database.border}`};
  padding: 10px 20px;
  font-size: 14px;

  h3 {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;

const DeleteFieldFooter = styled.div`
  text-align: right;
  padding: 10px;
`;

const DeleteFieldDescription = styled.p`
  color: #44546f;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

const DeleteField = ({ onConfirm, onCancel }) => {
  const theme = useTheme();

  return (
    <DeleteFieldWrapper
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      <div>
        <h3 style={{ color: theme.shared.settings.section.title }}>Delete column?</h3>
        <DeleteFieldDescription>
          All data in this column will be lost. Are you sure you want to delete this column?
        </DeleteFieldDescription>
      </div>
      <DeleteFieldFooter>
        <Button appearance="subtle-link" onClick={onCancel}>
          Cancel
        </Button>

        <Button appearance="danger" onClick={onConfirm}>
          Delete
        </Button>
      </DeleteFieldFooter>
    </DeleteFieldWrapper>
  );
};

export default DeleteField;
