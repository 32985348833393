import { PanelKeys } from "../../../../panelTypes";

export const PreviewContentByPanelKey = {
  [PanelKeys.PERSONALIZED_NEWS]: {
    title: "Personalized News Feed",
    description:
      "The Personalized News widget delivers content tailored to employees, based on their followed spaces and subscribed news categories. It ensures users receive information they're interested in, making staying informed simple and relevant.",
    fullLayout: false,
  },
  [PanelKeys.TARGETED_NEWS]: {
    title: "News For You",
    description:
      "The News For You widget displays content tailored to specific people collections, using fields like location and department from the employee database. This ensures that users receive relevant news based on their role and team, streamlining how they stay updated with important information.",
    fullLayout: false,
  },
  [PanelKeys.COMPANY_NEWS]: {
    title: "Company News",
    description:
      "Display Confluence blogs as news and keep your team in the loop with real-time updates. The Company News widget supports different views, and has a wide range of filtering and design options.",
    fullLayout: true,
  },
  [PanelKeys.PAGES]: {
    title: "Important Pages",
    description:
      "Assemble helpful guidelines, the latest meeting notes, or collect related pages together. Boost productivity with all important pages in one place.",
    fullLayout: false,
  },
  [PanelKeys.PAGES_SIDE]: {
    title: "Important Pages",
    description:
      "Assemble helpful guidelines, the latest meeting notes, or collect related pages together. Boost productivity with all important pages in one place.",
    fullLayout: true,
  },
  [PanelKeys.COUNTDOWN]: {
    title: "Countdown",
    description:
      "Add custom countdowns for product launches, project milestones, and other significant events. The Countdown widget offers a wide range of color options, allowing you to match it with your branding style.",
    fullLayout: true,
  },
  [PanelKeys.VIDEOS_YT]: {
    title: "Video",
    description:
      "Display important video content and engage your team visually. The Video widget allows you to broadcast YouTube videos directly on your dashboard.",
    fullLayout: true,
  },
  [PanelKeys.VIDEOS_YT_SIDE]: {
    title: "Video",
    description:
      "Display important video content and engage your team visually. The Video widget allows you to broadcast YouTube videos directly on your dashboard.",
    fullLayout: false,
  },
  [PanelKeys.NEW_STARTERS]: {
    title: "New Starters",
    description:
      "Introduce new hires based on their entry dates and simplify the onboarding process. The New Starters widget features dynamic people cards with different design styles.",
    fullLayout: true,
  },
  [PanelKeys.NEW_STARTERS_SIDE]: {
    title: "New Starters",
    description:
      "Introduce new hires based on their entry dates and simplify the onboarding process. The New Starters widget features dynamic people cards.",
    fullLayout: false,
  },
  [PanelKeys.TEAM_NEWS]: {
    title: "Team News",
    description:
      "Display Confluence blogs as news and keep your team in the loop with real-time updates from your department or project. The Team News widget supports different views, and has a wide range of filtering and design options.",
    fullLayout: true,
  },
  [PanelKeys.INFO_PANEL]: {
    title: "Info Panel",
    description:
      "Add a custom info panel and display important information in a concise and easy-to-read format. Info Panel comes in a variety of styles to help better convey your message.",
    fullLayout: false,
  },
  [PanelKeys.INFO_PANEL_MAIN]: {
    title: "Info Panel",
    description:
      "Add a custom info panel and display important information in a concise and easy-to-read format. Info Panel comes in a variety of styles to help better convey your message.",
    fullLayout: true,
  },
  [PanelKeys.IMPORTANT_DATES]: {
    title: "Upcoming Birthdays",
    description:
      "Keep your team informed with the Upcoming Birthdays widget, and ensure they never miss important dates.",
    fullLayout: false,
  },
  [PanelKeys.IMPORTANT_DATES_MAIN]: {
    title: "Upcoming Birthdays",
    description:
      "Keep your team informed with the Upcoming Birthdays widget, and ensure they never miss important dates.",
    fullLayout: true,
  },
  [PanelKeys.POLL]: {
    title: "Poll",
    description:
      "Gather valuable feedback from your team and improve your decision making process. The Poll widget ensures anonymous voting.",
    fullLayout: false,
  },
  [PanelKeys.APP_LINKS]: {
    title: "App Links",
    description:
      "Create shortcuts to frequently used apps and tools. The App Links widget comes with a wide range of company and app logos for better visualization.",
    fullLayout: false,
  },
  [PanelKeys.ASSIGNED_TO_ME]: {
    title: "Assigned to me",
    description:
      "Add the Assigned to me widget and give your users access to a dashboard view of Confluence tasks. Ensure your team doesn’t miss a deadline or an important task assigned to them.",
    fullLayout: false,
  },
  [PanelKeys.IMPORTANT_SPACES]: {
    title: "Important Spaces",
    description:
      "Provide quick access to spaces on your site. The Important Spaces widget offers an overview of your most important or frequently used Confluence spaces.",
    fullLayout: false,
  },
  [PanelKeys.IMPORTANT_SPACES_MAIN]: {
    title: "Important Spaces",
    description:
      "Provide quick access to spaces on your site. The Important Spaces widget offers an overview of your most important or frequently used Confluence spaces.",
    fullLayout: true,
  },
  [PanelKeys.SIDE_NEWS]: {
    title: "Latest News",
    description:
      "Display Confluence blogs as news and keep your team in the loop with real-time updates. The Latest News widget supports the wide range of filtering and design options. ",
    fullLayout: false,
  },
  [PanelKeys.WORK_ANNIVERSARY_MAIN]: {
    title: "Work Anniversary",
    description:
      "Keep your team informed with the Work Anniversary widget, and ensure they never miss important dates.",
    fullLayout: true,
  },
  [PanelKeys.WORK_ANNIVERSARY_SIDE]: {
    title: "Work Anniversary",
    description:
      "Keep your team informed with the Work Anniversary widget, and ensure they never miss important dates.",
    fullLayout: false,
  },
  [PanelKeys.PEOPLE_MAIN]: {
    title: "People",
    description:
      "Put faces to names and visualize how teams are structured in your organization. Connect with colleagues and better understand the dynamics of your working environment.",
    fullLayout: true,
  },
  [PanelKeys.PEOPLE_SIDE]: {
    title: "People",
    description:
      "Put faces to names and visualize how teams are structured in your organization. Connect with colleagues and better understand the dynamics of your working environment.",
    fullLayout: false,
  },
  [PanelKeys.RECENTLY_ACTIVE_USERS_MAIN]: {
    title: "Recently active users",
    description:
      "Display recently active users on your Confluence site. The Recently active users widget features people cards and activity information.",
    fullLayout: true,
  },
  [PanelKeys.RECENTLY_ACTIVE_USERS_SIDE]: {
    title: "Recently active users",
    description:
      "Display recently active users on your Confluence site. The Recently active users widget features people cards and activity information.",
    fullLayout: false,
  },
  [PanelKeys.ICON_CARDS]: {
    title: "Icon Cards",
    description:
      "Group information with cards. The icon cards widget offers a variety of design and color options to  better highlight the content.",
    fullLayout: true,
  },
  [PanelKeys.TEXT_CARDS]: {
    title: "Text Cards",
    description:
      "Group information with cards. The text cards widget offers a variety of design and color options to  better highlight the content.",
    fullLayout: true,
  },
  [PanelKeys.IMG_CARDS]: {
    title: "Image Cards",
    description:
      "Group information with cards. The image cards widget offers a variety of design and color options to  better highlight the content.",
    fullLayout: true,
  },
  [PanelKeys.GLIFFY]: {
    title: "Gliffy Diagrams",
    description:
      "Display detailed diagrams of key processes and structures. Enhance communication and collaboration with interactive layers and automatic updates across linked duplicates.",
    fullLayout: true,
  },
  [PanelKeys.RELEASED_AI]: {
    title: "Released",
    description:
      "Engage and keep everyone in the loop with stunning release notes effortlessly generated from your Jira tickets.",
    fullLayout: true,
  },
};
