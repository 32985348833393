import styled from "styled-components";

export const Header = styled.div`
  position: relative;
`;

export const Content = styled.div`
  padding: 20px;
  overflow: auto;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: end;
  padding-top: 20px;
  gap: 10px;
`;

export const Overlay = styled.div`
  position: absolute;
  inset: 159px 0 auto 0;
  height: 60px;
  background: ${({ gradient }) => gradient};
`;

export const CloseBtn = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

export const Title = styled.div`
  font-weight: 600;
  font-size: 24px;
  color: ${({ color }) => color};
`;

export const Description = styled.div`
  font-size: 14px;
  line-height: 1.6;
  margin: 8px 0 20px;
  width: 330px;
  color: ${({ color }) => color};
`;

export const Step = styled.div`
  display: flex;
  align-items: center;
  color: ${({ color }) => color};
  margin: 25px 0 25px;
  font-size: 14px;
  line-height: 1.4;
  gap: 15px;

  span {
    border-radius: 50%;
    background: #0065ff22;
    color: #0065ff;
    min-width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
  }

  a {
    color: #0c66e4;
    outline: none;
    text-decoration: none;
  }
`;

export const InputLabel = styled.div`
  font-size: 12px;
  font-weight: 600;
  color: #6b778c;
  padding: 20px 0 5px;
  border-top: 1px solid ${({ border }) => border};
`;

export const InputWarning = styled.div`
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 600;
  margin: 2px 0 0 -3px;
  color: #de350b;
  gap: 3px;
`;

export const LabeledToggle = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 5px;
`;

export const ToggleTitle = styled.div`
  font-size: 14px;
  line-height: 1;
  color: ${({ color }) => color};
`;

export const Link = styled.a`
  text-decoration: none;
  color: ${({ color }) => color};
`;
