import styled from "styled-components";

export const FieldContainer = styled.div`
  margin-top: 20px;
`;

export const Label = styled.div`
  color: ${({ theme }) => theme.shared.settings.section.title};
  margin-bottom: 5px;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
`;

export const Divider = styled.div`
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin-top: 24px;
  margin-bottom: 5px;
`;

export const HelperMessage = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.86em;
  line-height: 1.33;
  margin-top: 4px;
`;
