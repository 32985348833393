import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { FieldContainer, Label } from "./styled";
import IconRenderer from "../../../../../Shared/Components/Icons/IconRenderer";
import IconEmojiSidebar, { IconTypes } from "../../../../../Shared/Components/SidebarIconPicker/IconEmojiSidebar";
import SidebarTransition from "../../../../../Shared/Components/SidebarTransition";
import { formatNewPath } from "./SidebarAppPicker";

export const IconBox = styled.div`
  width: 40px;
  height: 40px;
  box-sizing: border-box;
  border-radius: 3px;
  border: 2px solid rgba(9, 30, 66, 0.14);
  cursor: pointer;
  display: grid;
  place-content: center;
`;

export const AppIconContainer = styled.div`
  width: ${({ iconSize = 24 }) => iconSize};
  height: ${({ iconSize = 24 }) => iconSize};
  object-fit: contain;

  img {
    object-fit: contain;
  }
`;

function IconEmojiSelector({
  label,
  icon,
  setIcon = () => {},
  closeOnSelected = false,
  customMargin,
  disableClickOutsideCallback = () => {},
  customScrollbarHeight = false,
}) {
  const [isEditingIcon, setIsEditingIcon] = useState(false);

  const handleChangesInIsEditingIcon = (isEditing) => {
    setIsEditingIcon(isEditing);
    disableClickOutsideCallback(isEditing);
  };

  return (
    <>
      <FieldContainer style={customMargin ? { marginTop: `${customMargin}` } : {}}>
        {label && <Label>{label}</Label>}

        <IconBox onClick={() => handleChangesInIsEditingIcon(true)}>
          {icon?.type === IconTypes.APP && (
            <AppIconContainer>
              <img
                src={icon.data.includes("admin.caelor.com") ? formatNewPath(icon.data) : icon.data}
                width={24}
                height={24}
                alt=""
              />
            </AppIconContainer>
          )}
          {icon?.type === IconTypes.ICON && <IconRenderer icon={icon?.data} />}
          {icon?.type === IconTypes.EMOJI && <em-emoji shortcodes={icon?.data?.shortcodes} size="1.2em"></em-emoji>}
        </IconBox>
      </FieldContainer>

      <SidebarTransition
        isOpen={isEditingIcon}
        close={() => handleChangesInIsEditingIcon(false)}
        width={425}
        onlyChildren
        customScrollbarHeight={customScrollbarHeight}
      >
        <IconEmojiSidebar
          hasEmojis
          selectedTab={0}
          onSelected={(data, type) => {
            setIcon(data, type);
            if (closeOnSelected) {
              handleChangesInIsEditingIcon(false);
            }
          }}
          onClose={() => handleChangesInIsEditingIcon(false)}
        />
      </SidebarTransition>
    </>
  );
}

IconEmojiSelector.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.shape({
    type: PropTypes.string,
    data: PropTypes.object,
  }),
  setIcon: PropTypes.func,

  customMargin: PropTypes.string,
};

export default IconEmojiSelector;
