import React from "react";

const HomeIcon = ({ color = "#757F8A", width = 20, height = 17 }) => (
  <svg width={width} height={height} viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.22207 15.372H12.4946V10.7587C12.4946 10.5842 12.4419 10.4442 12.3364 10.3387C12.2349 10.2332 12.097 10.1805 11.9225 10.1805H8.80026C8.62579 10.1805 8.48581 10.2332 8.38031 10.3387C8.27482 10.4442 8.22207 10.5842 8.22207 10.7587V15.372ZM3.38961 14.9642V8.65891L9.86535 3.23608C10.0195 3.10218 10.1818 3.03523 10.3523 3.03523C10.5227 3.03118 10.687 3.09813 10.8452 3.23608L17.3271 8.67108V14.9642C17.3271 15.5039 17.1688 15.9259 16.8523 16.2302C16.5359 16.5345 16.1017 16.6866 15.5499 16.6866H5.1607C4.60888 16.6866 4.17473 16.5345 3.85825 16.2302C3.54582 15.9259 3.38961 15.5039 3.38961 14.9642ZM1.66721 8.47632C1.44811 8.47632 1.27566 8.4114 1.14988 8.28156C1.0241 8.14766 0.961207 7.99348 0.961207 7.81901C0.961207 7.72163 0.983523 7.62628 1.02816 7.53295C1.07279 7.43557 1.14177 7.34834 1.23509 7.27125L9.34194 0.466848C9.65842 0.199054 9.99722 0.0651572 10.3583 0.0651572C10.7195 0.0651572 11.0583 0.199054 11.3747 0.466848L19.4816 7.27125C19.5749 7.34834 19.6439 7.43557 19.6885 7.53295C19.7332 7.62628 19.7555 7.72163 19.7555 7.81901C19.7555 7.99348 19.6926 8.14766 19.5668 8.28156C19.4451 8.4114 19.2726 8.47632 19.0495 8.47632C18.944 8.47632 18.8446 8.45197 18.7512 8.40328C18.662 8.35459 18.5747 8.29576 18.4895 8.22678L10.6931 1.68409C10.5876 1.59483 10.476 1.5502 10.3583 1.5502C10.2407 1.5502 10.1291 1.59483 10.0236 1.68409L2.22714 8.22678C2.14193 8.29576 2.05267 8.35459 1.95935 8.40328C1.87008 8.45197 1.7727 8.47632 1.66721 8.47632ZM15.4282 4.27074V2.37792C15.4282 2.21968 15.4769 2.0939 15.5742 2.00058C15.6757 1.9032 15.8055 1.85451 15.9637 1.85451H16.9254C17.0877 1.85451 17.2175 1.9032 17.3149 2.00058C17.4123 2.0939 17.461 2.21968 17.461 2.37792V5.97488L15.4282 4.27074Z"
      fill={color}
    />
  </svg>
);

export default HomeIcon;
