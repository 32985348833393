import React, { useCallback, useEffect, useState } from "react";
import Cropper from "react-easy-crop";
import Modal, { ModalTransition } from "@atlaskit/modal-dialog";
import {
  Button,
  CropperContainer,
  Description,
  EmptyStateContainer,
  ImageSizePreview,
  ModalFooter,
  ModalHeader,
  ModalWrapper,
  SizeErrorText,
  Upload,
} from "../../../styled/sections";
import getCroppedImg from "./crop";
import SidebarCustomRange from "../../../../../../ContentBuilder/Shared/SidebarCustomRange";
import { EmptyUploadImage } from "./EmptyImage";

function ImageEditor({
  isOpen,
  saveLogoName,
  setLogoName,
  close,
  image,
  setImage,
  primaryColor = "#0065ff",
  primaryContrastColor = "#fff",
}) {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const [currentImage, setCurrentImage] = useState(null);
  const [uploadedImage, setUploadedImage] = useState(image);

  const [sizeError, setSizeError] = useState(false);

  const handleImageUpload = async (e) => {
    if (setLogoName) {
      saveLogoName(e.target.files[0].name);
      setLogoName(e.target.files[0].name);
    }
    try {
      setUploadedImage(URL.createObjectURL(e.target.files[0]));
    } catch (error) {
      setUploadedImage(null);
    }
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const checkSizeOfImage = useCallback(() => {
    if (currentImage?.size > 150) {
      setSizeError(true);
    } else {
      setSizeError(false);
    }
  }, [currentImage]);

  useEffect(() => {
    (async () => {
      try {
        const croppedImage = await getCroppedImg(uploadedImage, croppedAreaPixels);
        setCurrentImage(croppedImage);
      } catch {
        setCurrentImage(null);
      }
    })();
  }, [croppedAreaPixels, uploadedImage]);

  useEffect(() => {
    if (currentImage?.size) {
      checkSizeOfImage();
    }
  }, [currentImage]);

  return (
    <ModalTransition>
      {isOpen && (
        <Modal onClose={() => close()}>
          <ModalWrapper>
            <ModalHeader>Edit logo</ModalHeader>
            <Description style={{ display: "block", marginTop: "5px" }}>
              Upload your company logo and adjust it using zoom and move features.
            </Description>

            <span>
              <Upload htmlFor="upload">Choose logo</Upload>
              <input
                type="file"
                id="upload"
                name="upload"
                onChange={handleImageUpload}
                accept="image/png,image/jpg,image/jpeg"
                style={{ display: "none" }}
              />
            </span>

            <CropperContainer>
              {uploadedImage ? (
                <Cropper
                  image={uploadedImage}
                  crop={crop}
                  zoom={zoom}
                  aspect={1 / 1}
                  onCropChange={setCrop}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                />
              ) : (
                <EmptyStateContainer>
                  <EmptyUploadImage />
                  <div>Upload your logo</div>
                </EmptyStateContainer>
              )}
            </CropperContainer>

            <SidebarCustomRange
              label="Zoom"
              value={zoom}
              setValue={setZoom}
              min={1}
              max={5}
              step={0.1}
              isDisabled={!uploadedImage}
            />

            {currentImage && <ImageSizePreview>Approx size of image {currentImage.size}kb</ImageSizePreview>}

            {sizeError && <SizeErrorText>Image exceeds the maximum limit of 150kb</SizeErrorText>}

            <ModalFooter>
              <Button
                bg="#F1F2F4"
                color="#42526E"
                onClick={() => {
                  close();
                }}
              >
                Cancel
              </Button>
              <Button
                bg={primaryColor}
                color={primaryContrastColor}
                onClick={async () => {
                  if (currentImage.image && !sizeError) {
                    setImage(currentImage.image);
                    close();
                  }
                }}
              >
                Apply
              </Button>
            </ModalFooter>
          </ModalWrapper>
        </Modal>
      )}
    </ModalTransition>
  );
}

export default ImageEditor;
