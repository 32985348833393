import React, { useEffect, useState } from "react";
import { CheckboxSelect } from "@atlaskit/select";
import { CustomPlaceholder } from "../../Shared/CheckboxSelect/CustomCheckboxSelectComponents";

import { MODIFIED_SELECT_STYLES } from "../../Dashboard/filter/CustomSelectStyles";

function SelectFilter({ value, onChange, options, fieldTitle }) {
  const [selectKey, setSelectKey] = useState(Math.random().toString(36).substring(7));
  const [selectedValue, setSelectedValue] = useState();

  const selectedItem = selectedValue?.map((item) => item.label)[0];

  useEffect(() => {
    if (!value || !value?.length) {
      setSelectedValue(undefined);
    } else {
      const mappedValue = value.map((v) => {
        const option = options.find((o) => o.id === v);
        return { label: option.label, value: option.id };
      });

      setSelectedValue([...mappedValue]);
    }
  }, [value]);

  useEffect(() => {
    window.AP.events.on("restore-filters-people-base", () => {
      setSelectKey(Math.random().toString(36).substring(7));
    });
  }, []);

  const handlePlaceholder = (props) => {
    const selectedValueLength = selectedValue?.length;

    return (
      <CustomPlaceholder
        item={selectedItem}
        count={selectedValueLength}
        isMulti={true}
        fieldName={fieldTitle}
        {...props}
      />
    );
  };

  return (
    <CheckboxSelect
      menuPlacement="bottom"
      menuShouldScrollIntoView={false}
      key={selectKey}
      isClearable
      value={selectedValue}
      isSearchable
      backspaceRemovesValue
      isMulti
      maxMenuHeight={200}
      styles={MODIFIED_SELECT_STYLES}
      controlShouldRenderValue={false}
      components={{
        Placeholder: handlePlaceholder,
      }}
      onChange={(arr) => {
        onChange((arr || []).map(({ value }) => value));
      }}
      options={options?.map((op) => ({ label: op.label, value: op.id })) || []}
    />
  );
}

export default SelectFilter;
