import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Spinner from "@atlaskit/spinner";
import Checkbox from "@atlaskit/checkbox";
import { parse } from "himalaya";
import { useCurrentUserStore } from "../../../../current-user-store";
import { getCurrentUsersTasks, updateTask } from "../../api";
import { Panel, PanelContent, PanelTitle, ShowMoreButton } from "../../dashboard-styled";
import { PaginationTypes } from "../../filter/PaginationSelect";
import { atlassianRestService } from "../../../../../../Service/AtlassianRestService";
import { useAtlassianLocale } from "../../../../../../Shared/Hooks/useAtlassianLocale";
import { useSidebarStore } from "../edit/sidebar/sidebar-store";
import { useCorporateIdentityStore } from "../../../Settings/General/BrandAndColors/corporate-identity-store";
import { COLOR_TYPES } from "../../../Settings/General/BrandAndColors/color-types";

const TasksContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 22px;

  .user {
    background-color: rgba(9, 30, 66, 0.08);
    padding: 0px 0.3em 2px 0.23em;
    border-radius: 20px;
  }

  .current {
    background-color: #0052cc;
    color: #fff;
  }

  .date-time {
    background-color: rgba(9, 30, 66, 0.08);
    border-radius: 3px;
    padding: 0px 4px;
    margin: 0px 1px;
  }
`;

const NoTasks = styled.div`
  display: grid;
  place-content: center;
  text-align: center;
  row-gap: 12px;
  padding: 32px 0px;

  span {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: ${({ theme }) => theme.emptyState.secondaryText};
  }
`;

const TaskWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  column-gap: 9px;
`;

const Task = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: ${({ theme }) => theme.assignedToMe.task};
  margin: 2px 0px 0px 0px;
`;

const dummyTasks = [
  {
    id: "task-1",
    status: "incomplete",
    body: "Contact Alana Grant to decide on the name of project space",
  },
  {
    id: "task-2",
    status: "incomplete",
    body: "Finish creating the new project space by 10 Feb 2023",
  },
  {
    id: "task-3",
    status: "incomplete",
    body: "Contact Emma McRae and find out about venues for offsite and prepare costings for at least three different options by 27 Feb 2023",
  },
  {
    id: "task-4",
    status: "incomplete",
    body: "Distribute agenda and marketing material for offsite by 02 Mar 2023",
  },
];

const findNested = (children, predicate) => {
  const found = [];

  for (const node of children) {
    if (node["children"]) {
      found.push(...findNested(node["children"], predicate));
    }

    if (predicate(node)) {
      found.push(node);
    }
  }

  return found;
};

function Tasks({ panelTitle, data, position }) {
  const [isInit, setIsInit] = useState(false);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [allTasks, setAllTasks] = useState([]);
  const [visibleTasks, setVisibleTasks] = useState([]);
  const [foundUsers, setFoundUsers] = useState([]);

  const [locale] = useAtlassianLocale();

  const tasksContainerRef = useRef();

  const currentUser = useCurrentUserStore((state) => state.currentUser);

  const colors = useCorporateIdentityStore((state) => state.colors);
  const primaryColor = colors ? colors[COLOR_TYPES.PRIMARY] : "#0065ff";

  const setDisableClickOutside = useSidebarStore((state) => state.setDisableClickOutside);

  const getAllUsersFromTaskBodies = (formattedTasks) => {
    const foundAccountIds = [];
    formattedTasks.forEach((res) => {
      const parsed = parse(res.body);
      findNested(parsed, (x) => x.attributes && x.attributes.some((y) => y.key === "accountId"))
        .map((x) => x.attributes)
        .flat()
        .filter((x) => x.key === "accountId")
        .forEach((x) => foundAccountIds.push(x.value));
    });

    if (!foundAccountIds?.length) {
      setFoundUsers([]);
      return;
    }

    const uniqueAccountIds = [...new Set(foundAccountIds)];

    const promises = uniqueAccountIds.map(async (u) => atlassianRestService.getUserByAccountId(u));
    Promise.all(promises)
      .then((res) => {
        if (res) {
          setFoundUsers([
            ...res.map((u) => ({
              accountId: u.accountId,
              name: `@${u.displayName}`,
              profile: `${window.AP._hostOrigin}/wiki/people/${u.accountId}`,
            })),
          ]);
        }
      })
      .catch(() => setFoundUsers([]));
  };

  const formatBodyForResults = (results) => {
    const formattedResults = results.map((res) => {
      const newBody = (res?.body?.storage?.value || "")
        .replaceAll("<ac:link>", `<span class="link">`)
        .replaceAll("</ac:link>", "</span>")
        .replaceAll("<ri:user", `<span class="user"`)
        .replaceAll("ri:account-id=", "accountId=");

      return { ...res, body: newBody };
    });
    getAllUsersFromTaskBodies(formattedResults);

    return formattedResults;
  };

  useEffect(() => {
    if (!!currentUser?.accountId && !data.isDummy && !isInit) {
      (async () => {
        try {
          const data = await getCurrentUsersTasks(currentUser.accountId, "incomplete");
          if (data?.results) {
            const formattedResults = await formatBodyForResults(data.results);
            setIsInit(true);
            setAllTasks([...formattedResults]);
          }
          setLoading(false);
        } catch (error) {}
      })();
    }
  }, [currentUser, data]);

  useEffect(() => {
    if (!!allTasks?.length) {
      setVisibleTasks(allTasks.slice(0, data.maxNumberOfItems || 4 * page));
    }
  }, [allTasks, page]);

  const updateStatusOfTaks = (taskId, newStatus) => {
    const newTasks = visibleTasks.map((task) => {
      return task.id === taskId ? { ...task, status: newStatus } : task;
    });
    setVisibleTasks([...newTasks]);

    updateTask(taskId, newStatus).catch((e) => console.log(e));
  };

  useEffect(() => {
    if (!!tasksContainerRef?.current) {
      const taskElements = Array.from(tasksContainerRef.current.getElementsByClassName("task-container"));
      taskElements.forEach((taskEl) => {
        const dateElements = Array.from(taskEl.getElementsByTagName("time"));

        if (!!dateElements?.length) {
          dateElements.forEach((dateEl) => {
            const value = dateEl.getAttribute("datetime");
            const formattedValue = new Date(value).toLocaleDateString(locale);

            const newSpan = document.createElement("span");
            newSpan.classList.add("date-time");
            newSpan.innerText = formattedValue;
            dateEl.prepend(newSpan);
          });
        }

        if (!!foundUsers?.length) {
          const userElements = Array.from(taskEl.getElementsByClassName("user"));
          if (!!userElements?.length) {
            userElements.forEach((userEl) => {
              const accountId = userEl.getAttribute("accountid");

              const foundUser = foundUsers.find((u) => u.accountId === accountId);
              if (!foundUser) return;

              if (foundUser.accountId === currentUser.accountId) {
                userEl.classList.add("current");
              }

              userEl.innerText = foundUser.name;
            });
          }
        }
      });
    }
  }, [tasksContainerRef?.current, foundUsers]);

  return (
    <Panel>
      <PanelTitle panelPosition={position}>{panelTitle}</PanelTitle>
      <PanelContent>
        {!data.isDummy && loading && (
          <div style={{ display: "grid", placeContent: "center" }}>
            <Spinner size="large" />
          </div>
        )}

        {!loading && !visibleTasks.length && !data.isDummy && (
          <NoTasks>
            <img src="/images/hub/dashboard/dashboard-no-tasks.png" alt="" style={{ justifySelf: "center" }} />
            <span>There are no tasks at the moment.</span>
          </NoTasks>
        )}

        <TasksContainer ref={tasksContainerRef}>
          {!loading &&
            !data.isDummy &&
            !!visibleTasks?.length &&
            visibleTasks?.map((task) => (
              <TaskWrapper key={task.id}>
                <Checkbox
                  isChecked={task.status === "complete"}
                  onChange={(e) =>
                    updateStatusOfTaks(task.id, task.status === "incomplete" ? "complete" : "incomplete")
                  }
                />
                <Task className="task-container" dangerouslySetInnerHTML={{ __html: task.body }} />
              </TaskWrapper>
            ))}

          {data.isDummy &&
            dummyTasks?.map((task) => (
              <TaskWrapper key={task.id}>
                <Checkbox isChecked={task.status === "complete"} />
                <Task dangerouslySetInnerHTML={{ __html: task.body }} />
              </TaskWrapper>
            ))}
        </TasksContainer>
      </PanelContent>

      {!!visibleTasks.length &&
        visibleTasks.length < allTasks.length &&
        data.pagination === PaginationTypes.INFINITE && (
          <ShowMoreButton
            primaryColor={primaryColor}
            onMouseEnter={(e) => {
              e.preventDefault();
              setDisableClickOutside(true);
            }}
            onMouseLeave={(e) => {
              e.preventDefault();
              setDisableClickOutside(false);
            }}
            onClick={() => {
              if (page === 1) {
                setPage(page + 1);
                return;
              }

              window.open(`${window.AP._hostOrigin}/wiki/plugins/inlinetasks/mytasks.action`, "_blank");
            }}
          >
            {page === 1 && "Show more"}
            {page > 1 && "View all"}
          </ShowMoreButton>
        )}
    </Panel>
  );
}

export default Tasks;
