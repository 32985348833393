import React from "react";
import { Box } from "@atlaskit/primitives";
import { useNavigationStore } from "../../../../../../store";
import { useMacroSuiteConnectionStore } from "../../../../../../../../Shared/store/macrosuite-connection-store";
import { SubEntryColors } from "../../../../../shared/MacrosuitePoweredColors";
import { BannerWrapper } from "../../../../../../../Hub/Components/Dashboard/panels/edit/types/styled";
import MacroSuiteInstallationBanner from "../../../../../../../ContentBuilder/Shared/MacroSuiteInstallationBanner";

function Design() {
  const { editingEntry, updateEntry } = useNavigationStore((state) => ({
    editingEntry: state.editingEntry,
    updateEntry: state.updateEntry,
  }));

  const isMacroSuiteInstalled = useMacroSuiteConnectionStore((state) => state.isMacroSuiteInstalled);

  return (
    <Box style={{ width: "100%", display: "grid", gridTemplateRows: "auto 162px" }}>
      <SubEntryColors
        menuStyle={editingEntry?.menuStyle}
        menuStyleType={editingEntry?.data?.type}
        isIconDisplayed={editingEntry?.data.displayIcons}
        isDisabled={!isMacroSuiteInstalled}
        colors={editingEntry.data.colors}
        setColor={(value, property, variant) => {
          const colors = {
            ...editingEntry.data.colors,
            [variant]: {
              ...editingEntry.data.colors[variant],
              [property]: value,
            },
          };

          updateEntry(editingEntry.id, "colors", colors, true);
        }}
      />

      {!isMacroSuiteInstalled && (
        <BannerWrapper style={{ flex: 1 }}>
          <MacroSuiteInstallationBanner />
        </BannerWrapper>
      )}
    </Box>
  );
}

export default Design;
