import React, { useRef, useState } from "react";
import { components } from "@atlaskit/select";
import styled, { useTheme } from "styled-components";
import SearchIcon from "@atlaskit/icon/glyph/search";
import { useClickOutside } from "../../../../../Shared/Hooks/useClickOutside";

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
`;

const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  position: absolute;
  opacity: 0;
  cursor: pointer;
`;

const CustomCheckbox = styled.div`
  position: relative;
  width: 11px;
  height: 11px;
  border-radius: 2px;
  border: 1px solid ${({ theme, checked, checkboxColor }) => (checked ? checkboxColor : theme.shared.form.input.border)};
  background-color: ${({ checked, checkboxColor, theme }) =>
    checked ? checkboxColor : theme.shared.form.input.backgorund};
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ checked, theme }) =>
    !checked &&
    `
  &:hover {
    background-color: ${theme.shared.form.select.hover}; 
    border-color:${theme.shared.form.select.hover}; 
  }
`}
`;

const Checkmark = styled.span`
  width: 3px;
  height: 7px;
  border: solid ${({ color }) => color};
  border-width: 0 2px 2px 0;
  transform: rotate(40deg) translateY(-1px) translateX(-1px);
  display: ${({ checked }) => (checked ? "block" : "none")};
`;

const Label = styled.label`
  margin-left: 4px;
  flex: 1;
  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ color }) => color};
`;

const Menu = styled.div`
  background-color: white;
  box-shadow:
    0 0 0 1px hsla(218, 50%, 10%, 0.1),
    0 4px 11px hsla(218, 50%, 10%, 0.1);
  margin-top: 4;
  position: absolute;
  z-index: 2;
  width: 100%;

  ${({ hideMenu }) => (hideMenu ? "height: 0px;" : "")};

  & > .select-search:first-child {
    width: 100%;
  }
`;

const Blanket = styled.div`
  bottom: 0;
  left: 0;
  top: 0;
  right: 0;
  position: fixed;
  z-index: 1;
`;

export const OptionLabel = styled.div`
  display: grid;
  align-items: center;

  grid-template-columns: 16px auto;
  column-gap: 5px;
  margin-left: 5px;
`;

export const OptionIcon = styled.img`
  width: 16px;
  height: 16px;
  border-radius: 50%;
`;

const DropdownWrapper = styled.div`
  position: relative;
  font-size: 14px;
  background: ${({ theme }) => theme.sidebar.filterSelectBackground};

  button {
    color: ${({ color, isOpen }) => (isOpen ? "white" : color)} !important;
  }
`;

export const Dropdown = ({ children, isOpen, target, onClose, style = {}, containerStyle = {}, hideMenu = false }) => {
  const dropdownRef = useRef();
  const theme = useTheme();

  useClickOutside(dropdownRef, () => onClose());

  return (
    <DropdownWrapper
      ref={dropdownRef}
      isOpen={isOpen}
      color={theme.shared.form.select.value}
      style={{ ...containerStyle }}
    >
      {target}
      {isOpen && (
        <>
          <Menu hideMenu={hideMenu} style={style}>
            {children}
          </Menu>
        </>
      )}
    </DropdownWrapper>
  );
};

export const CustomOption = ({
  getStyles,
  Icon,
  isDisabled,
  isFocused,
  isSelected,
  children,
  innerProps,
  isMulti,
  checkboxColor = "#0065ff",
  fontColor = "inherit",
  ...rest
}) => {
  const { Option } = components;

  const [backgroundColor, setBackgroundColor] = useState("transparent");

  const theme = useTheme();

  const changeStyling = (backgroundColor) => {
    setBackgroundColor(backgroundColor);
  };

  const style = {
    alignItems: "center",
    backgroundColor: backgroundColor,
    color: theme.shared.form.select.option,
    display: "flex",
    boxShadow: isSelected
      ? `inset 2px 0px 0px ${checkboxColor}`
      : isFocused
      ? `inset 2px 0px 0px ${checkboxColor}`
      : "none",
    accentColor: checkboxColor,
  };

  const props = {
    ...innerProps,
    style,
  };
  return (
    <div
      onMouseOver={() => changeStyling(theme.shared.settings.database.filtersBackgroundColor)}
      onMouseOut={() => changeStyling("transparent")}
    >
      <Option {...rest} isDisabled={isDisabled} isSelected={isSelected} getStyles={getStyles} innerProps={props}>
        {isMulti && !rest.data.__isNew__ && (
          <CheckboxContainer>
            <HiddenCheckbox type="checkbox" checked={isSelected} readOnly />
            <CustomCheckbox checked={isSelected} checkboxColor={checkboxColor}>
              <Checkmark checked={isSelected} color={theme.background} />
            </CustomCheckbox>
          </CheckboxContainer>
        )}
        <Label color={fontColor}>{children}</Label>
      </Option>
    </div>
  );
};

export const CustomDropdownIndicator = () => {
  return <SearchIcon size="small" primaryColor="#7a869a" />;
};

const { Placeholder } = components;

export const CustomPlaceholder = ({ item = [], count = 0, isMulti, fieldName, children, ...props }) => {
  const theme = useTheme();
  const placeholder = count > 1 ? `${item} + ${count - 1} more` : count === 1 ? item : fieldName;

  const shortSelectedItem = () => {
    const longPlaceholder = `${item} + ${count - 1} more`;

    if (count > 1 && longPlaceholder?.length > 25 && item?.length > 15) return item?.slice(0, 12) + "...";
    return item;
  };
  const displayedValue =
    (isMulti && !count && (placeholder ? placeholder : "All")) ||
    (!isMulti && !count && (placeholder ? placeholder : "All")) ||
    (isMulti && count > 1 && `${shortSelectedItem()} + ${count - 1} more`) ||
    (isMulti && count === 1 && `${shortSelectedItem()}`) ||
    (!isMulti && count && `${shortSelectedItem()}`);

  const customStyles = {
    fontWeight: count >= 1 ? 600 : 400,
    color: count >= 1 && theme.shared.form.select.value,
  };
  return (
    <Placeholder {...props}>
      <div style={customStyles}> {displayedValue} </div>
    </Placeholder>
  );
};
