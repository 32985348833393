import React, { useRef, useState } from "react";
import Textfield from "@atlaskit/textfield";
import SearchIcon from "@atlaskit/icon/glyph/search";
import styled from "styled-components";
import { AppLinks } from "./appLinks";
import { useCorporateIdentityStore } from "../../Settings/General/BrandAndColors/corporate-identity-store";
import { COLOR_TYPES } from "../../Settings/General/BrandAndColors/color-types";
import AddIcon from "@atlaskit/icon/glyph/add";
import RemoveIcon from "@atlaskit/icon/glyph/editor/remove";
import EditIcon from "@atlaskit/icon/glyph/editor/edit";
import SidebarTransition from "../../../../../Shared/Components/SidebarTransition";
import SidebarAppAddPicker from "./SidebarAppAddPicker";
import { usePanelsStore } from "../store/panels-store";
import { setMacroSuiteStyleguide } from "../api";

const AppIconWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 15px;
  margin-bottom: 55px;
`;

const AppIconHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const AppIconPreview = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 0.25rem;
  padding: 12px;
  border-radius: 10px;
  transition:
    transform 0.1s ease-in,
    background 0.1s ease-in;

  &:hover {
    background: rgba(0, 0, 0, 0.05);
    transform: scale(0.95);
    cursor: pointer;

    ${({ hasModifiers }) =>
      hasModifiers &&
      `
      div:first-child {
        display: flex;
      }
    `}
  }

  ${({ isSelected }) => {
    if (isSelected) {
      return "background: #DEEBFF !important";
    }
  }}
`;

const AddButtonWrapper = styled.button`
  width: ${({ width }) => width && `${width}`};
  height: ${({ height }) => height && `${height}`};
  display: grid;
  place-items: center;
  align-self: center;
  justify-self: center;
  border-radius: 8px;
  border: 1px solid #d8dce3;
  background: white;
  cursor: pointer;
  color: #0052cc;
  position: relative;

  &:hover {
    background: rgba(0, 0, 0, 0.05);
    transform: scale(0.95);
  }
`;

const AppIconActions = styled.div`
  display: none;
  position: absolute;
  align-self: flex-end;
  top: 5px;
  left: 5px;
  background: white;
  border-radius: 5px;
  cursor: pointer;

  span {
    color: rgba(0, 0, 0, 0.3);
    transition: color 0.1s ease-in-out;

    &:hover {
      color: rgba(0, 0, 0, 0.8);
    }
  }
`;

const AppIcon = styled.div`
  img {
    height: 32px;
    max-width: 80px;
    width: auto;
  }
`;

const AppIconTitle = styled.div`
  font-size: 14px;
`;

const InfoMessage = styled.div`
  display: flex;
  position: fixed;
  font-size: 12px;
  color: #7a869a;
  text-align: center;
  bottom: 10px;
  right: 20px;
`;

const LinkMessage = styled.a`
  color: #0052cc;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const FieldIcon = () => (
  <span style={{ marginRight: "2px" }}>
    <SearchIcon primaryColor="#d5d6d8" />
  </span>
);

export const formatNewPath = (path) => {
  const baseUrl = "https://caelor-apps.web.app/images/icons/";
  const fileName = path.substring(path.lastIndexOf("/") + 1);

  return baseUrl + fileName;
};

function SidebarAppPicker({
  customIcons = undefined,
  setCustomIcons,
  appIcon,
  onIconSelect,
  isMacroSuiteInstalled,
  hasModifiers = true,
}) {
  const [searchValue, setSearchValue] = useState(window.searchTerm || "");
  const [dialogAdd, setDialogAdd] = useState(true);

  const colors = useCorporateIdentityStore((state) => state.colors);
  const primaryColor = colors[COLOR_TYPES.PRIMARY] || "#0065ff";
  const slicedPrimaryColor = primaryColor.length > 7 ? primaryColor.slice(0, -2) : primaryColor;

  const iconsToUse = customIcons || AppLinks;
  const editingAppIcon = useRef(null);

  const macrosuiteStyleguideLink = `${window.AP._hostOrigin}/wiki/plugins/servlet/ac/net.veniture.confluence.cloud.macrosuite/macrosuite-styleguide-caelor`;

  const saveAppIcons = async (data) => {
    setMacroSuiteStyleguide("appicons", data)
      .then(() => {})
      .catch(() =>
        window.AP.flag.create({
          title: "Failed to save changes",
          body: "Something went wrong, and your changes weren't saved. Please refresh and try again.",
          type: "error",
          close: "auto",
        }),
      );
  };

  const deleteAppIcon = (id) => {
    setCustomIcons([...customIcons.filter((appIcon) => appIcon.id !== id)]);
    saveAppIcons([...customIcons.filter((appIcon) => appIcon.id !== id)]);
  };

  const shouldOpenDialogHandler = () => {
    setDialogAdd(false);
    setIsEditingAppAddIcon(true);
  };

  const { isEditingAppAddIcon, setIsEditingAppAddIcon } = usePanelsStore((state) => ({
    isEditingAppAddIcon: state.isEditingAppAddIcon,
    setIsEditingAppAddIcon: state.setIsEditingAppAddIcon,
  }));

  return (
    <>
      <AppIconHeader>
        <Textfield
          placeholder="Search"
          value={searchValue}
          elemBeforeInput={
            <div style={{ padding: "5px" }}>
              <FieldIcon />
            </div>
          }
          onChange={(e) => setSearchValue(e.target.value)}
        />
      </AppIconHeader>
      <AppIconWrapper>
        {isMacroSuiteInstalled && (
          <AddButtonWrapper
            onClick={() => {
              setDialogAdd(true);
              setIsEditingAppAddIcon(true);
            }}
            width="45px"
            height="45px"
          >
            <AddIcon size="medium" primaryColor="#0052CC" />
          </AddButtonWrapper>
        )}
        {iconsToUse
          .filter((icon) => (searchValue ? icon.title.toLowerCase().includes(searchValue.toLowerCase()) : true))
          .map((icon) => (
            <AppIconPreview
              hasModifiers={hasModifiers}
              isSelected={appIcon === icon.path}
              onClick={() => onIconSelect(icon)}
              key={icon.id}
            >
              {hasModifiers && (
                <AppIconActions>
                  <div
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      editingAppIcon.current = { ...icon };
                      shouldOpenDialogHandler();
                    }}
                  >
                    <EditIcon />
                  </div>
                  <div
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      deleteAppIcon(icon.id);
                    }}
                  >
                    <RemoveIcon />
                  </div>
                </AppIconActions>
              )}

              <AppIcon>
                <img
                  src={icon.path.includes("admin.caelor.com") ? formatNewPath(icon.path) : icon.path}
                  alt={icon.title}
                />
              </AppIcon>
              <AppIconTitle>{icon.title}</AppIconTitle>
            </AppIconPreview>
          ))}
        {isMacroSuiteInstalled && (
          <InfoMessage>
            Changes you make here will apply to&nbsp;
            <LinkMessage href={macrosuiteStyleguideLink} target="_blank">
              Optics Styleguide.
            </LinkMessage>
          </InfoMessage>
        )}
      </AppIconWrapper>
      <SidebarTransition
        key="app-links-app-add-icon"
        isOpen={isEditingAppAddIcon}
        close={() => setIsEditingAppAddIcon(false)}
        width={360}
        title={dialogAdd ? "Add App Icon" : "Update App Icon"}
        containerStyles={{ zIndex: "10015" }}
        onArrowBackClick={() => setIsEditingAppAddIcon(false)}
        useArrowBack
      >
        <SidebarAppAddPicker
          customIcons={iconsToUse}
          setCustomIcons={setCustomIcons}
          updateIcon={dialogAdd ? null : editingAppIcon.current}
          dialogAdd={dialogAdd}
        />
      </SidebarTransition>
    </>
  );
}

export default SidebarAppPicker;
