import React from "react";
import Card from "../type/Card";
import { PanelPositioning } from "../../../../panelTypes";
import { Grid } from "../styled";

function CardView({ data, elementsToDisplay, position, locale }) {
  const isPositionedInSide = position === PanelPositioning.SIDE;

  return (
    <Grid isPositionedInSide={isPositionedInSide}>
      {data?.map((page) =>
        page?.results?.map((data) => (
          <Card
            key={data.diagram.id}
            content={data.content}
            diagram={data.diagram}
            elementsToDisplay={elementsToDisplay}
            locale={locale}
            isPositionedInSide={isPositionedInSide}
          />
        )),
      )}
    </Grid>
  );
}

export default CardView;
