import { useEffect, useMemo, useState } from "react";

import Spinner from "@atlaskit/spinner";
import { useQuery } from "react-query";
import styled, { useTheme } from "styled-components";

import {
  buildCollectionFilters,
  extractAllParentEntries,
  getAllCollectionsFromEntries,
  getSimulatedCollectionsFromEntries,
  hideEntriesFromAnonymousUser,
  separateNonExistingCollections,
} from "./handlePermissionData";
import { useNavigationThemeColorSetStore } from "../store";
import { fetchCollections } from "../../Collections/api";
import { useMacroSuiteConnectionStore } from "../../../Shared/store/macrosuite-connection-store";
import { checkMacroSuiteLicense } from "../../../Shared/Functions/checkMacrosuiteLicense";
import { initResizeObserver, initResizeOnErrorObserver } from "../../ContentBuilder/macro-service";
import {
  NAVIGATION_DEFAULT_COLORS,
  NAVIGATION_DEFAULT_COLORS_DARK,
  NAVIGATION_DEFAULT_COLOR_SCHEME,
} from "../functions/generateNewEntry";
import { getUserCollections } from "../../Hub/api";
import CardMenu from "./layout/CardMenu";
import { useFetchNavigation } from "../hooks/fetchNavigation";

const NavigationWrapper = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.navigation.london.publishedMenuBorder};

  @media screen and (max-width: 767px) {
    display: none;
  }
`;

const NavigationMenu = ({ openOverContent, isHub }) => {
  const [data, setData] = useState(null);

  const [isViewMode, setIsViewMode] = useState(false);
  const [contextSpaceKey, setContextSpaceKey] = useState("");
  const [loadingPermissions, setLoadingPermissions] = useState(false);

  const [loadingUser, setLoadingUser] = useState(false);
  const [isUserAnonymous, setIsUserAnonymous] = useState(false);

  const [menuStructure, setMenuStructure] = useState([]);
  const [colors, setColors] = useState();

  const theme = useTheme();

  const { returnThemeColorsSet, setThemeColorsSet } = useNavigationThemeColorSetStore((state) => {
    return {
      returnThemeColorsSet: state.returnThemeColorsSet,
      setThemeColorsSet: state.setThemeColorsSet,
    };
  });

  const [isLoading, isError] = useFetchNavigation({
    setNavigationData: (data) => {
      setData({ ...data, inactive: !data?.active });
    },
  });

  const { isLoading: isLoadingCollections, data: collectionsData } = useQuery("collections-for-nav", fetchCollections, {
    cacheTime: 0,
    retry: 0,
    select: (response) => {
      const { data } = response;
      return { collections: data || [] };
    },
  });

  const isNavLoading = useMemo(
    () => isLoading || isLoadingCollections || loadingPermissions,
    [isLoading, isLoadingCollections, loadingPermissions],
  );

  const { isMacroSuiteInstalled, setIsMacroSuiteInstalled } = useMacroSuiteConnectionStore((state) => {
    return {
      isMacroSuiteInstalled: state.isMacroSuiteInstalled,
      setIsMacroSuiteInstalled: state.setIsMacroSuiteInstalled,
    };
  });

  useEffect(() => {
    (async () => {
      const isMacroSuiteInstalledRes = await checkMacroSuiteLicense({ isInNavigation: true });
      setIsMacroSuiteInstalled(!!isMacroSuiteInstalledRes);
    })();
  }, []);

  useEffect(() => {
    window.AP.navigator.getLocation(function (location) {
      setContextSpaceKey(location?.context?.spaceKey || "");
      if (
        location.target === "contentedit" ||
        location.target === "contentcreate" ||
        location.href.includes("/servicedesk/") ||
        (location.href.includes("/whiteboard/") && location.target === "unknown") ||
        (!isHub && location.target === "addonmodule")
      ) {
        setIsViewMode(false);
        initResizeOnErrorObserver();
      } else if (!isError && !isNavLoading) {
        setIsViewMode(true);
        initResizeObserver(undefined, true);
      }
    });
  }, [isHub, isNavLoading, isError]);

  useEffect(() => {
    (async () => {
      setLoadingUser(true);
      const isAnonymous = await window.AP.request("/rest/api/user/current?expand=operations,isExternalCollaborator")
        .then((payload) => JSON.parse(payload.body))
        .then(async (user) => {
          return user?.type === "anonymous";
        })
        .catch(() => {
          return true;
        });
      setIsUserAnonymous(isAnonymous);
      setLoadingUser(false);
    })();
  }, []);

  const emitNavigationStatusUpdate = (activeStatus) =>
    window.AP.events.emit("caelor-navigation-status-update", { isActive: activeStatus });

  useEffect(() => {
    if (data?.publishedStructure?.length && !data?.inactive && !isLoading && !isLoadingCollections && !loadingUser) {
      if (!colors) {
        if (!("dark" in data && "light" in data)) {
          setColors({ dark: data?.colors, light: data?.colors });
        } else {
          setColors({ dark: data?.dark, light: data?.light });
        }

        if (!data?.colors && !data.dark && !data.light) {
          setColors({ dark: NAVIGATION_DEFAULT_COLORS_DARK, light: NAVIGATION_DEFAULT_COLORS });
        }
      }
      setLoadingPermissions(true);

      (async () => {
        const { publishedStructure } = data;
        const parentEntries = extractAllParentEntries(publishedStructure);

        const { collections } = collectionsData || { collections: [] };

        const allCollectionsFromEntries = getAllCollectionsFromEntries(parentEntries);
        const simulatedCollectionsFromEntries = getSimulatedCollectionsFromEntries(parentEntries);

        const [withoutDeletedCollections, deletedCollections] = separateNonExistingCollections(
          allCollectionsFromEntries,
          collections,
        );

        const foundCollectionsWithFilters = buildCollectionFilters(withoutDeletedCollections, collections);
        let collectionsUserBelongsTo = [];
        try {
          const response = await getUserCollections([
            ...foundCollectionsWithFilters,
            ...simulatedCollectionsFromEntries,
          ]);
          collectionsUserBelongsTo = response?.data?.length ? response.data : [];
        } catch {
          collectionsUserBelongsTo = [];
        }

        const availableParentEntries = [];
        parentEntries.forEach((entry) => {
          const hasSpacePermissionsSet = !!entry?.spaces?.length;
          const hasCollectionPermissionsSet = !!entry?.collections?.length;
          const hasFiltersPermissionsSet = !!entry?.filters?.length;

          if (!hasSpacePermissionsSet && !hasCollectionPermissionsSet && !hasFiltersPermissionsSet) {
            availableParentEntries.push(entry);
            setLoadingPermissions(false);
            return;
          }

          if (!contextSpaceKey && hasSpacePermissionsSet) {
            setLoadingPermissions(false);
            return;
          }

          if (contextSpaceKey && hasSpacePermissionsSet) {
            const isMenuInAvailableSpace = entry.spaces.includes(contextSpaceKey);
            if (!isMenuInAvailableSpace) {
              setLoadingPermissions(false);
              return;
            }
          }

          if (!hasCollectionPermissionsSet && !hasFiltersPermissionsSet) {
            availableParentEntries.push(entry);
            setLoadingPermissions(false);
            return;
          }

          const collectionThatMatchesEntry = simulatedCollectionsFromEntries?.find(
            (clc) => clc.entryId === entry.id,
          )?.id;

          const availableCollectionsInEntry =
            entry?.collections?.filter((collectionId) => !deletedCollections.find((id) => id === collectionId)) || [];

          const matchAgainst = collectionThatMatchesEntry
            ? [...availableCollectionsInEntry, collectionThatMatchesEntry]
            : availableCollectionsInEntry;

          if (
            (!availableCollectionsInEntry.length && !collectionThatMatchesEntry) ||
            matchAgainst.some((collectionId) => collectionsUserBelongsTo.includes(collectionId))
          ) {
            availableParentEntries.push(entry);
            setLoadingPermissions(false);
            return;
          }
        });

        const availableEntries = [];
        availableParentEntries.forEach((entry) => {
          availableEntries.push(entry);
          const children = publishedStructure.filter((originalEntry) => originalEntry.parent === entry.id);
          if (children?.length) {
            availableEntries.push(...children);
          }
        });

        setMenuStructure(hideEntriesFromAnonymousUser([...availableEntries], isUserAnonymous));
        emitNavigationStatusUpdate(!!availableEntries?.length);

        setLoadingPermissions(false);
      })();
    }
  }, [data, isLoading, isLoadingCollections, collectionsData, contextSpaceKey, loadingUser, isUserAnonymous, colors]);

  useEffect(() => {
    if (!isError && !data) {
      return;
    }
    if (isError || !!data?.inactive) {
      initResizeOnErrorObserver();
    }
  }, [isError, data, isViewMode]);

  useEffect(() => {
    if (colors) {
      const navigationNotCustomized =
        colors?.dark.menuBackgroundColor === "#ffffffff" &&
        colors?.dark.menuTextColor === "#42526e" &&
        colors?.dark.menuTextHover === "#42526e" &&
        colors?.light.menuBackgroundColor === "#ffffffff" &&
        colors?.light.menuTextColor === "#42526e" &&
        colors?.light.menuTextHover === "#42526e";

      if (navigationNotCustomized) {
        setColors(NAVIGATION_DEFAULT_COLOR_SCHEME);
      } else {
        setColors(colors);
      }
    }
  }, [theme, colors, setThemeColorsSet]);

  if (isError || !isViewMode) {
    return <span />;
  }

  if (isNavLoading) {
    return (
      <div style={{ textAlign: "center" }}>
        <Spinner size="xlarge" />
      </div>
    );
  }

  if (data.inactive) {
    return <span />;
  }

  return (
    <NavigationWrapper>
      <CardMenu
        isHub={isHub}
        colors={isMacroSuiteInstalled ? colors : NAVIGATION_DEFAULT_COLOR_SCHEME}
        structure={menuStructure ?? []}
        openOverContent={openOverContent}
        themeColorsSet={returnThemeColorsSet()}
        temporaryColorScheme={theme.global.name}
      />
    </NavigationWrapper>
  );
};

export default NavigationMenu;
